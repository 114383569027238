<div class='recruiter-results' *ngIf="results.length > 0 || isLoading()">
    <div class='loading' *ngIf="isLoading()">{{'user-lookup.loading' | translate}}</div>
    <table [ngStyle]="{'width.em': width || null}" *ngIf="!isLoading()">
        <thead>
            <tr>
                <th><h4 mr-4>{{'user-lookup.first_name' | translate}}</h4></th>
                <th><h4 mr-4>{{'user-lookup.last_name' | translate}}</h4></th>
                <th *ngIf="includePhone"><h4 mr-4>{{'user-lookup.phone' | translate}}</h4></th>
                <th style="width: 100%;"><h4>{{'user-lookup.email_address' | translate}}</h4></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let user of results" (click)="selectUser(user)">
                <td><h4 class="gray-text">{{user.FirstName}}</h4></td>
                <td><h4 class="gray-text">{{user.LastName}}</h4></td>
                <td *ngIf="includePhone"><h4 class="gray-text">{{user.PrimaryPhone}}</h4></td>
                <td><h4 class="gray-text">{{user.EmailAddr}}</h4></td>
            </tr>
        </tbody>
    </table>
</div>
