import { Applications } from '@allbirds-ui/allbirds-types';
import { createModel, Mapping, Model } from '../model';
import { IsoToMoment } from '../serializers/moment.serializer';
import { RawClientSubmission } from './client-submission.model';
import { Moment } from 'moment';
import { Serial } from '../decorators/serial.decorator';
export import RawClientSubmission = Applications.ClientSubmission;

class ClientSubmissionMapping extends Mapping<RawClientSubmission> {
  @Serial(IsoToMoment) submissionDate?: Moment;
  @Serial(IsoToMoment) outcomeDate?: Moment;
  @Serial(IsoToMoment) skippedDate?: Moment;
}

export interface ClientSubmission extends Model<ClientSubmissionMapping> {}

export const ClientSubmission = createModel<ClientSubmission>(ClientSubmissionMapping);
