import { Appointments } from "../misc.model";
export import RawAppointmentInvite = Appointments.AppointmentInvite;
import { Mapping, Model, createModel } from "../model";
import { IsoToMoment} from "../serializers/moment.serializer";
import { Serial } from "../decorators/serial.decorator";
import { Moment } from "moment";


/**
 * appointmentInvite property
 * @class {AppointmentInviteMapping}
 */
class AppointmentInviteMapping extends Mapping<RawAppointmentInvite> {
  @Serial(IsoToMoment) timestamp?: Moment;
}

export interface AppointmentInvite extends Model<AppointmentInviteMapping>{}
export const AppointmentInvite = createModel<AppointmentInvite>(AppointmentInviteMapping);
