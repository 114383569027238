<div class="container cardContainer" *ngIf="cardType === 'big'">
  <div class="row" style="flex-wrap: nowrap;">
    <div class="col-2 p-0 m-0" style="min-width: 77.609px;">
      <div class="typeIcon justify-content-center align-self-center app-centerContent text-center cursor-pointer" style="margin-top: 0mm;" (click)="redirect()">
        <img src="assets/icons/document.svg" alt="alert" *ngIf="attachment.type === 'file' || attachment.type === 'resume'" />
        <img src="assets/icons/insert-link.svg" alt="alert" *ngIf="attachment.type === 'link'" />
      </div>
    </div>
    <div class="col-9">
      <div class="row">
        <div class="col name overflow-ellipsis">
          <span [tooltip]="attachment.type === 'file' || attachment.type === 'resume' ? attachment.name : attachment.path" container="body" placement="left">
            {{attachment.type === 'file' || attachment.type === 'resume' ? attachment.name : attachment.path}}
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col date-section">
          {{momentDate | abDatetime}} {{ attachment.attached_by_user ? 'by:' : ''}} {{attachment.attached_by_user}}
        </div>
      </div>
      <div class="row">
        <div class="col">
          {{ attachment.descript }}
        </div>
      </div>
      <div class="row mt-1 align-items-center">
        <div class="col-9 mr-0 pr-0">
          <div *ngIf="attachment.type !== 'resume'">
            <label for="attachment-type-dropdown"></label>
            <select #categorySelect id="attachment-type-dropdown" (change)="updateClick()" [(ngModel)]="attachment.category" class="custom-select" style="height: 40px; border-radius: 4px;">
              <option [ngValue]="''">Select a file type</option>
              <option *ngFor="let category of categories" [value]="category.name">
                {{category.name}}
              </option>
            </select>
          </div>
          {{ attachment.resumeType }}
        </div>

        <div *ngIf="attachment.type !== 'resume'" class="blue14 mt-2 ml-2" [ngClass]="{'col-7': isLink}" >
          <div *ngIf="!hideDelete">
            <img src="assets/icons/trash.svg" alt="trash" />
            <span (click)="deleteClick()">{{ isLink ? 'Delete web link' : 'Delete File' }}</span>
          </div>


          <a *ngIf="attachment.type !== 'link'" [attr.href]="attachment.path" target="_blank" class="blue14 ml-2">
            <img src="assets/icons/download.svg" alt="download" />
            <span>Download</span>
          </a>
        </div>

      </div>

      <div *ngIf="attachment.type === 'resume'"  class="row" style="margin-left: -4px; margin-top: 5px;">

        <div *ngIf="showDelete" class="blue14 mr-4" (click)="deleteClick()">
          <img src="assets/icons/trash.svg" alt="trash" />
          <span>Delete Resume</span>
        </div>

        <a [attr.href]="attachment.path" target="_blank" class="blue14">
          <img src="assets/icons/download.svg" alt="download" />
          <span>Download</span>
        </a>
      </div>

    </div>
  </div>
</div>

<div class="container p-0 m-0 cardContainerSmall cursor-pointer" *ngIf="cardType === 'small'" (click)="redirect()">
  <div class="row h-100 align-items-center mt-3 p-0 m-0">
    <div class="col-1 h-100 app-centerContent">
      <img height="30" src="assets/icons/document.svg" alt="alert" *ngIf="attachment.type === 'file' || attachment.type === 'resume'" />
      <img src="assets/icons/insert-link.svg" alt="alert" *ngIf="attachment.type === 'link'" />
    </div>
    <div class="col-10">
      <div class="row attachmentNameSmall p-0 m-0">
        <div class="col-9 p-0 m-0 overflow-ellipsis" [tooltip]="attachment.name || attachment.path">
          {{attachment.name || attachment.path}}
        </div>
      </div>
      <div class="row p-0 m-0 descriptionSmall">
        <div class="col p-0 m-0">
          {{attachment.category}} - {{momentDate ? (momentDate | abDatetime) : ''}} {{ attachment.attached_by_user ? '- by:' : ''}} {{attachment.attached_by_user}}
        </div>
      </div>
    </div>
    <div *ngIf="!hideDelete" class="col p-0 m-0 delete app-centerContent" (click)="$event.stopPropagation();deleteClick()">
      <img src="assets/icons/trash-gray.svg" alt="trash" />
    </div>
  </div>
</div>
