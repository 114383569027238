import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-personal-details-modal',
  templateUrl: './personal-details-modal.component.html',
  styleUrls: ['./personal-details-modal.component.scss']
})
export class PersonalDetailsModalComponent implements OnInit {

  //These properties are being passed from the talent-detail-pane component
  talentStatus: string;
  talentName: string;
  talentLocation: string;
  email: string;
  talentPhoneRecord: any;

  mobilePhoneNumber: number | string;
  homePhoneNumber: number | string;
  workPhoneNumber: number | string;

  //These properties are being set in this component
  primaryPhoneNumber: number | string;
  alternativePhoneNumber: number[];

  get mobilePhone() { return this.mobilePhoneNumber ? String(this.mobilePhoneNumber) : 'None'; }
  get homePhone() { return this.homePhoneNumber ? String(this.homePhoneNumber) : 'None'; }
  get workPhone() { return this.workPhoneNumber ? String(this.workPhoneNumber) : 'None'; }
  get primaryPhone() { return this.mobilePhoneNumber ? 'Mobile' : this.homePhoneNumber ? 'Home' : this.workPhoneNumber ? 'Work' : 'None'; }

  constructor(private _bsModalRef: BsModalRef) { }

  ngOnInit() {
    // this.setTalentPhoneNumbers();
    this.setPhoneNumbers();
  }

  setPhoneNumbers() {
    if (this.talentPhoneRecord && this.talentPhoneRecord.length) {
      console.log('Phone Records: ', this.talentPhoneRecord);
      this.talentPhoneRecord.forEach((record: any) => {
        if ((record.type === 'PHONE_TYPE_UNSPECIFIED' && record.usage === 'WORK') || (record.type === 'LANDLINE' && record.usage === 'WORK')) {
          this.workPhoneNumber = record.number;
        } else if (record.type === 'MOBILE') {
          this.mobilePhoneNumber = record.number;
        } else {
          // record.type === 'LANDLINE'
          this.homePhoneNumber = record.number;
        }
      });
    }
  }

  // setTalentPhoneNumbers(): void {
  //   let phoneNumber: string | number = null;
  //   let alternativeNumbers: any[] = [];
  //
  //   if (this.talentPhoneRecord && this.talentPhoneRecord.length) {
  //
  //     this.talentPhoneRecord.forEach((record: any) => {
  //       if (record.type === 'MOBILE') {
  //         if (!phoneNumber) phoneNumber = record.number
  //         else alternativeNumbers.push(record.number)
  //       } else if (record.type === 'LANDLINE') {
  //         alternativeNumbers.push(record.number)
  //       }
  //     });
  //
  //
  //     if (phoneNumber) {
  //       this.primaryPhoneNumber = phoneNumber;
  //       this.alternativePhoneNumber = alternativeNumbers;
  //     }
  //
  //     if (!phoneNumber && alternativeNumbers.length) {
  //       this.primaryPhoneNumber = alternativeNumbers[0];
  //       this.alternativePhoneNumber = alternativeNumbers.slice(1);
  //     }
  //
  //   }
  // }

  isPrimary(phoneType: string) {

    if (this.primaryPhone === phoneType) {
      return '(Primary)';
    }
    return '';
  }

  // setTalentPhoneNumbers(): void {
  //   let phoneNumber: string | number = null;
  //   let alternativeNumbers: any[] = [];
  //
  //   if (this.talentPhoneRecord && this.talentPhoneRecord.length) {
  //
  //     this.talentPhoneRecord.forEach((record: any) => {
  //       if (record.type === 'MOBILE') {
  //         if (!phoneNumber) phoneNumber = record.number
  //         else alternativeNumbers.push(record.number)
  //       } else if (record.type === 'LANDLINE') {
  //         alternativeNumbers.push(record.number)
  //       }
  //     });
  //
  //
  //     if (phoneNumber) {
  //       this.primaryPhoneNumber = phoneNumber;
  //       this.alternativePhoneNumber = alternativeNumbers;
  //     }
  //
  //     if (!phoneNumber && alternativeNumbers.length) {
  //       this.primaryPhoneNumber = alternativeNumbers[0];
  //       this.alternativePhoneNumber = alternativeNumbers.slice(1);
  //     }
  //
  //   }
  // }

  closeDialog(): void {
    this._bsModalRef.hide();
  }

}
