import { Serial } from '../external/decorators/serial.decorator';
import { GoogleToMoment, GrpcToMoment } from '../external/serializers/moment.serializer';
import { Moment } from 'moment';
import { Mapping } from '../external/model';
import { AvailabilitySignalType, DegreeType, EmployerFilterMode, TelecommutePreference } from '../external/misc.model';
import { Google } from '@allbirds-ui/allbirds-types';
import TimeField = Google.Talent.TimeFilter.TimeField;
import { PrimarySecondaryRecruiter } from './primary-secondary-recruiter.interface';

class ApplicationDateFilter extends Mapping<any> {
  @Serial(GoogleToMoment) startDate: Moment;
  @Serial(GoogleToMoment) endDate: Moment;
}

export class TimestampRange extends Mapping<any> {
  @Serial(GrpcToMoment) startTime: Moment;
  @Serial(GrpcToMoment) endTime: Moment;
}

export class AvailabilityFilter extends Mapping<any> {
  signalType: AvailabilitySignalType;
  @Serial(TimestampRange) range: TimestampRange;
  required?: boolean;
}

export class ProfileQuery extends Mapping<any> {
  query?: string;
  locationFilters?: LocationFilters[];
  jobTitleFilters?: JobTitleFilter[];
  employerFilters?: EmployerFilter[];
  educationFilters?: EducationFilter[];
  skillFilters?: SkillFilter[];
  workExperienceFilter?: WorkExperienceFilter[];
  timeFilters?: TimeFilter[];
  hirableFilter?: boolean;
  @Serial([ApplicationDateFilter]) applicationDateFilters?: ApplicationDateFilter[];
  applicationOutcomeNotesFilters?: ApplicationOutcomeNotesFilter[];
  applicationJobFilters?: ApplicationJobFilter[];
  customAttributeFilter?: string;
  candidateAvailabilityFilter?: CandidateAvailabilityFilter;
  @Serial([AvailabilityFilter]) availabilityFilters?: AvailabilityFilter[];
  phraseFilters?: PhraseFilter[];
  personNameFilters?: PersonNameFilter[];
  talentStatus?: string[];
}

export class NewProfileQuery extends Mapping<any> {
  query?: string;
  allbirdsJobID?: string;
  keywordFilter?: string[];
  radius?: number;
  streetAddress?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: string;
  excludeOnTempAssignment?: boolean;
  excludeOnPermAssignment?: boolean;
  excludeRedFlags?: boolean;
  recruitmentLadder?: string[];
  backgroundCheck?: BackgroundCheckFilter[];
  contactRecency?: number;
  submittalRecency?: number;
  resumeRecency?: number;
  engagementRecency?: number;
  recentlyViewedFilter?: number;
  applicationRecency?: number;
  candidateName?: string;
  offset?: number;
  size?: number;
  employeeTypes?: string[];
  talentStatus?: string[];
  lineOfBusiness?: string[];
  workAuthorizations?: string[];
  location?: string;
  fn?: string;
  ln?: string;
  talentId?: string;
  latLong?: string;
  displayLocation?: string;
  company: any;
  jobTitles: any;
  skills: any;
  noteTypes: any;
  noteTypeUpdatedWithin: number;
  activityTypes: any;
  activityTypeUpdatedWithin: number;
  byUsers: any;
  vaccinationDataFilter: boolean;
  portfolioLinkFilter: string;
  primaryRecruiter: PrimarySecondaryRecruiter[];
  secondaryRecruiter: PrimarySecondaryRecruiter[];
}

interface PersonNameFilter {
  personName: string;
}

interface LocationFilters {
  address: string;
  regionCode: string;
  latLng?: LatLng;
  distanceInMiles: number;
  telecommutePreference?: TelecommutePreference;
  negated: boolean;
}

interface LatLng {
  latitude: number;
  longitude: number;
}

export interface JobTitleFilter {
  jobTitle: string;
  negated: boolean;
}

export interface NoteTypeFilter {
  noteTypeId: string; 
  noteTypeText: string;
  excluded: boolean;
}

export interface ActivityTypeFilter {
  activityTypeId: string; 
  activityTypeText: string;
  excluded: boolean;
}

interface EmployerFilter {
  employer: string;
  mode: EmployerFilterMode;
  negated: boolean;
}

interface EducationFilter {
  school: string;
  fieldOfStudy: string;
  degreeType: DegreeType;
  negated: boolean;
}

export interface SkillFilter {
  skill: string;
  negated: boolean;
}

interface WorkExperienceFilter {
  minExperience: string;
  maxExperience: string;
}

interface TimeFilter {
  startTime: string;
  endTime: string;
  timeField: TimeField;
}

interface ApplicationOutcomeNotesFilter {
  outcomeNotes: string;
  negated: boolean;
}

interface ApplicationJobFilter {
  jobRequisitionId: string;
  jobTitle: string;
  negated: boolean;
}

// DEPRECATED. Use AvailabilityFilter instead.
interface CandidateAvailabilityFilter {
  negated: boolean;
}

interface PhraseFilter {
  phrases: string[];
  negated: boolean;
}

export interface BackgroundCheckFilter {
  screeningType: string;
  screenDate: number;
}
