import {FormArray} from '@angular/forms';
import {Appointments, ChatBots} from '@allbirds-ui/allbirds-types';
import {AllbirdsAppointment} from 'src/app/shared/models/external/appointment.model';
import {AppointmentStatusProvider} from 'src/app/shared/services/appt-invite/appt-invite-status-providers';
import { UserService } from '../user/user.service';
import AppointmentGoal = Appointments.AppointmentGoal;
import InterviewType = ChatBots.InterviewType;
import InterviewStatus = ChatBots.InterviewStatus;
import { User } from '../../models/external/user.model';

export interface ApptSettingsFormControls {
  apptGoal: AppointmentGoal[];
  apptType: FormArray;
  interviewDuration: string[];
}

/**
 * Values for Appointment type
 * The order here is important! Should match
 * the boolean array order for parentForm.apptSettingsForm.apptType in
 * @link `src/app/shared/components/sched-appointment-modal.component.ts`
 */
export const ApptTypeValues = [
  InterviewType.IN_PERSON,
  InterviewType.VIDEO,
  InterviewType.AUDIO
];

/**
 * This provides the ability to define a ApptStatusBannerMessages property as a function
 * inside appt-invite-status-providers (AppointmentStatusProvider)
 * @see {AppointmentStatusProvider}
 */
export type ApptLabelFormatter = (information: AppointmentStatusProvider) => string;

class AppointmentStatusOptions {
  /**
   * this should be html but can also be plain text. The value here will be placed in [innerHTML]
   * @type {string | ApptLabelFormatter}
   */
  banner?: string | ApptLabelFormatter;
  /**
   * this should be html but can also be plain text. The value here will be placed in [innerHTML]
   * @type {string | ApptLabelFormatter}
   */
  info?: string | ApptLabelFormatter;
}

/**
 * Each status value in ApptStatusBannerMessages. Provides a means for programmatically
 * determining a status message along with any helper methods
 * @class {AppointmentStatusLabels}
 */
export class AppointmentStatusLabels extends AppointmentStatusOptions {
  constructor(options: AppointmentStatusOptions) {
    super();
    Object.assign(this, options);
  }

  /**
   * Get a label value. If the property in ApptStatusBannerMessages is a function, then use the
   * appropriate ApptLabelFormatter to get the label value
   * @param {AllbirdsAppointment} appt
   * @param {string 'info' | 'banner'} labelKey
   * @param interviewer
   */
  getLabel(appt: AllbirdsAppointment, labelKey: 'info' | 'banner', interviewer?: User) {
    if (appt && labelKey && this[labelKey] && typeof this[labelKey] === 'function') {
      const statusProvider = new AppointmentStatusProvider(appt, interviewer);
      return (this[labelKey] as ApptLabelFormatter)(statusProvider);
    }
    return this[labelKey];
  }
}

/**
 * Type to drive positive typeahead functionality for appointment status banners and info labels
 * @type {tAppointmentStatus}
 */
export type tAppointmentStatus = {
  [key in InterviewStatus]?: AppointmentStatusLabels;
};

/**
 * Defines the banner and info text for talent with whom an appointment has been made. The
 * banner and info properties can be methods whose arguments match getters in AppointmentStatusProvider
 * @type {tAppointmentStatus}
 */
export const ApptStatusMessages: tAppointmentStatus = {
  [InterviewStatus.INVITE_SENT]: new AppointmentStatusLabels({
    banner: `<span class="bannerText">Waiting for talent to choose a date</span>`,
    info: ({inviteSentDateTime}: any) => {
      let html = `<div class="message-container flex-column">`;
      html += `<div class="flex-row message">`;
      html += `<img src="assets/icons/calendar.svg" alt="calendar icon">`;
      html += `<span>Waiting for candidate response to interview invitation\n\nChatbot interview invitation was sent on ${inviteSentDateTime}. Waiting for candidate action.</span>`;
      html += `</div></div>`;
      return html;
    }
  }),
  [InterviewStatus.NO_SLOT_SELECTED]: new AppointmentStatusLabels({
    banner: `<span class="bannerText">Contact Candidate to Schedule Interview</span>`,
    info: () => {
      let html = `<div class="message-container flex-column">`;
      html += `<div class="flex-row message">`;
      html += `<img src="assets/icons/alert-red.svg" alt="alert icon">`;
      html += `<span>Talent did not select any time slot offered via chatbot. Please re-send the invite via chatbot or log an interview.</span>`;
      html += `</div></div>`;
      return html;
    }
  }),
  [InterviewStatus.SCHEDULED]: new AppointmentStatusLabels({
    banner: `<span class="bannerText">Waiting for the interview to take place</span>`,
    info: ({inviteScheduledFor, interviewType, locationAddress, interviewer}) => `Log Interview Outcome\n\n${interviewType} interview is scheduled${interviewer && ` with ${interviewer}`} on\n${inviteScheduledFor}\n${locationAddress}`
  }),
  [InterviewStatus.RESCHEDULED]: new AppointmentStatusLabels({
    banner: `<span class="bannerText">Waiting for the interview to take place</span>`,
    info: ({inviteScheduledFor, interviewType, locationAddress}) => `Log Interview Outcome\n\n${interviewType} interview is scheduled for\n${inviteScheduledFor}\n${locationAddress}`
  }),
  [InterviewStatus.CANCELLED]: new AppointmentStatusLabels({
    banner: `<span class="bannerText">Plan an interview with the candidate</span>`,
    info: ({inviteScheduledFor}) => `The interview planned for ${inviteScheduledFor} was cancelled by the candidate. Try scheduling a new interview.`
  }),
  [InterviewStatus.RECRUITER_CANCELLED]: new AppointmentStatusLabels({
    banner: `<span class="bannerText">Plan an interview with the candidate</span>`,
    info: ({inviteScheduledFor, cancelledBy}) => `The interview planned for ${inviteScheduledFor} was cancelled by ${cancelledBy}. Try scheduling a new interview.`
  }),
  [InterviewStatus.COMPLETE]: new AppointmentStatusLabels({
    banner: `<span class="bannerText">Log interview results</span>`,
    info: 'Decide whether to continue with the candidate or not.'
  })
};

