<!--
  The proper TemplateRef is rendered in the following <div> depending on what sub-step the talent
  is on as well as what the role of the user is.
-->
<div class="phase" [ngTemplateOutlet]="shownPhase"></div>

<ng-template #sendOffer>
  <p>{{statusLabel}}</p>
  <div #ctaContainerBoundry id="ctaContainerBoundry"></div>
  <div #ctaContainer class="action-buttons w-100 text-right">
    <div>
      <app-talent-process-cta
        [id]="'offer-rejected-button'"
        [invertColor]="true"
        (execute)="handleNotFitClick($event)">
        {{'offer.offer_rejected' | translate}}
      </app-talent-process-cta>
    </div>
    <div class="pl-2" >
      <app-talent-process-cta
        [id]="'offer-accepted-button'"
        (execute)="submit()">
        {{'offer.offer_accepted' | translate}}
      </app-talent-process-cta>
    </div>
  </div>
</ng-template>

<ng-template #acceptedFinalView>
  <p>{{statusLabel}}</p>
  <div #ctaContainer class="action-buttons w-100 text-right">
      <app-talent-process-cta
        [id]="'offer-rejected-button'"
        [invertColor]="true"
        (execute)="handleNotFitClick($event)">
        {{'offer.offer_rejected' | translate}}
      </app-talent-process-cta>
    </div>
</ng-template>

