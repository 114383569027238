import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TalentHistoryFilter } from './talent-history.types';
import { UserService } from 'src/app/shared/services/user/user.service';
import { TalentProcessService } from 'src/app/shared/services/talent-process/talent-process.service';
import { ApplicationUpdateEvent } from '../../../models/internal/step-change-event.interface';
import { Profile } from '../../../models/external/profile.model';
import { Application } from '../../../models/external/application.model';
import { Assignment } from '../../../models/external/assignment.model';
import { Job } from '../../../models/external/job.model';
import { ApiService } from '../../../services/api/api.service';
import { ResponseBody } from '../../../models/api/application-response.interface';
import { TalentActivity } from '../../../models/external/talent-activity.model';
import TalentHistoryResponse = ResponseBody.TalentHistoryResponse;
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFirePerformance } from '@angular/fire/performance';

@Injectable({
  providedIn: 'root'
})
export class TalentHistoryService implements OnDestroy {
  //Use for the activities infinity scroll
  fetching: boolean;
  keepFetching: boolean;

  // Currently active filters.
  private activeFilter: TalentHistoryFilter = TalentHistoryFilter.ASSIGNMENTS;

  // All applications retrieved from the Elastic index.
  private applications: Application[] = [];

  // All active jobs (lastProcessStep === 'offer' for now).
  private assignments: Assignment[] = [];

  // All talent activity (notes, etc.);
  private activities: TalentActivity[] = [];

  // Applications that should be visible (depends upon active filters).
  private visibleActivities: (Application | Assignment | TalentActivity)[] = [];

  //Used in pagination for activities infinity scroll
  private totalActivities: number = 0;

  // Emits all of the data needed on the talent-history.component.
  private dataSubject = new BehaviorSubject<{
    activeFilter: TalentHistoryFilter,
    visibleActivities: (Application | Assignment | TalentActivity)[],
    totalActivities: number
  }>({
    activeFilter: this.activeFilter,
    visibleActivities: this.visibleActivities,
    totalActivities: this.totalActivities
  });

  // Publicly-subscribable observable of our data subject.
  public data = this.dataSubject.asObservable();

  // Holds our job data.
  public jobs: { [key: string]: Job } = {};

  // We store jobs in the job object above using the Allbirds Job ID. Since
  // assignments use the Google job id, we use this to map from a Google Job ID
  // to an Allbirds Job ID so we can retrieve a job.
  public googleJobsMap: { [key: string]: string } = {};

  // Used in the component to determine when to show loading spinners.
  public isLoading = false;

  // Used in the component to determine when to show loading spinners.
  public fetchingMoreActivities = false;

  // Holds back office IDs of users so we can query for their profile.
  private backOfficeIDs: { [key: string]: boolean } = {};

  // Listens for any updates to an application that may be in the pane.
  private updatedApplicationSub: Subscription;

  // Flag for displaying history error on UI.
  public errors: any = {};
  queryParamsSubscription: any;

  loadHistoryTrace: any;

  constructor(
    private _api: ApiService,
    private _talentProcess: TalentProcessService,
    private _user: UserService,
    private _route: ActivatedRoute,
    private performance: AngularFirePerformance
  ) {
    this.listenToUpdatedApplication();
    this.selectTabFromRouterParam();
  }

  selectTabFromRouterParam() {
    setTimeout(() => {
      this.queryParamsSubscription = this._route.queryParams.subscribe(params => {
      let subtab: string = params['subtab'];
      subtab = subtab ? subtab.toUpperCase(): 'ASSIGNMENTS';
      this.activeFilter = (<any>TalentHistoryFilter)[subtab];
    }), 0});
  }

  ngOnDestroy(): void {
    this.jobs = {};
    this.googleJobsMap = {};
    this.applications = undefined;
    this.assignments = undefined;
    this.activities = undefined;
    if (this.updatedApplicationSub) {
      this.updatedApplicationSub.unsubscribe();
    }
  }

  get hasErrors(): boolean {
    return Object.keys(this.errors).length > 0;
  }

  /**
   * Retrieves the applications for the inputted talent profile ID
   * and then updates the visible applications based on active filters.
   * @param profile - request that implements TalentHistoryRequest interface.
   */
  async fetchHistory(profile: Profile, from = 0,  filter?: string): Promise<void> {
    if (profile) {
      this.loadHistoryTrace = await this.performance.trace('job-dashboard-loading');
      this.loadHistoryTrace.start(); // firebase performance monitoring
      this.fetching = true;
      this.keepFetching = false;
      if (from != 0) {
        this.fetchingMoreActivities = true;
      } else {
        this.isLoading = true;
      }
      this.clearHistory();
      this.extractAssignmentsFromProfile(profile);
      const filters = filter == 'all'? []: [filter];
      const body = {
        talentGoogleId: profile.name,
        talentBackOfficeId: profile.externalId,
        from,
        filters: filters
      };
      this._api.getUserHistory(body)
        .subscribe((res) => {
          if (res) {
            this.handleHistoryResponse(res);
          } else {
            this.errors = { 'failedCall': true };
            this.isLoading = false;
            this.fetchingMoreActivities = false;
            this.fetching = false;
          }
        }, err => {
          console.log('[fetchHistory] error:', err);
          this.errors = { 'failedCall': true };
          this.isLoading = false;
          this.fetchingMoreActivities = false;
          this.fetching = false;
        });
    }
    this.fetching = false;
  }

  updateKeepFetching(from: number, total: number): void {
    this.keepFetching = from < total;
  }

  extractBackOfficeIDs() {
    for (const activity of this.activities) {
      const id = activity.userId || activity.oprId || '';
      if (!!id && !this._user.getFromCache(id)) {
        this.backOfficeIDs[id] = true;
      }
    }
  }

  handleHistoryResponse(res: TalentHistoryResponse) {
    console.log('handleHistoryResponse : ', this.activeFilter);
    this.applications = res.applications.map(Application.deserialize);
    this.activities = res.activities.map(v => TalentActivity.deserialize(v._source));
    this.totalActivities = res.totalActivities;
    this.extractBackOfficeIDs();

    if (res.postings && res.postings.length) {
      let i = res.postings.length;
      while (i--) {
        if (
          res.postings[i] &&
          res.postings[i].allbirds_metadata &&
          res.postings[i].allbirds_metadata.allbirds_job_id
        ) {
          this.jobs[res.postings[i].allbirds_metadata.allbirds_job_id] = Job.deserialize(res.postings[i]);
          if (
            res.postings[i] &&
            res.postings[i].name
          ) {
            this.googleJobsMap[res.postings[i].name] = res.postings[i].allbirds_metadata.allbirds_job_id;
          }
        }
      }
    }

    if (this.applications && this.applications.length) {
      let i = this.applications.length;
      while (i--) {
        if (
          this.applications[i] &&
          this.applications[i].randstad_process &&
          this.applications[i].randstad_process.jobElasticID &&
          this.jobs[this.applications[i].randstad_process.jobElasticID] &&
          this.jobs[this.applications[i].randstad_process.jobElasticID].allbirds_metadata &&
          this.jobs[this.applications[i].randstad_process.jobElasticID].allbirds_metadata.lob
        ) {
          TalentProcessService.setTalentStepNumber(
            this.applications[i],
            this.jobs[this.applications[i].randstad_process.jobElasticID].allbirds_metadata.lob
          );
        } else {
          // Remove application from UI if it doesn't have an associated jobElasticID (most likely indicates bad data).
          this.applications.splice(i, 1);
        }
      }
    }

    const backOfficeIDs = Object.keys(this.backOfficeIDs);
    if (backOfficeIDs.length) {
      this._user.getUsersById(backOfficeIDs)
        .catch(err => {
          console.log('[handleHistoryResponse] > [getUsersById]', err);
        })
        .finally(() => {
          this.updateDataSubject();
          this.backOfficeIDs = {};
          this.isLoading = false;
          this.fetchingMoreActivities = false;
        });
    } else {
      this.updateDataSubject();
      this.isLoading = false;
      this.fetchingMoreActivities = false;
    }
  }

  /**
   * Resets the data structures holding any history-related data
   * to an empty state.
   */
  clearHistory(): void {
    this.jobs = {};
    this.googleJobsMap = {};
    this.applications = [];
    this.assignments = [];
    this.activities = [];
    this.errors = {};
    this.visibleActivities = [];
  }

  /**
   * Activates/deactivates a filter.
   * @param filterKey
   */
  toggleFilter(filterKey: TalentHistoryFilter): void {
    this.activeFilter = filterKey;
    this.updateDataSubject();
  }

  /**
   * Updates the data subject with the most current values.
   */
  updateDataSubject(): void {
    console.log('updateDataSubject', this.activeFilter);
    this.dataSubject.next({
      activeFilter: this.activeFilter,
      visibleActivities: this[this.activeFilter],
      totalActivities: this.totalActivities
    });
    if (this.loadHistoryTrace) {
      this.loadHistoryTrace.stop(); // firebase performance monitoring
      this.loadHistoryTrace = null;
    }
  }

  addNoteToFeed(noteActivity: TalentActivity) {
    this.activities.unshift(noteActivity);
    this.updateDataSubject();
  }

  addAssignmentToFeed(assignmentActivity: any) {
    this.assignments.unshift(assignmentActivity);
    this.updateDataSubject();
  }

  addApplicationToFeed(applicationActivity: any) {
    this.applications.unshift(applicationActivity);
    this.updateDataSubject();
  }

  /**
   * Updates the application in the in-memory array whenever an update occurs to it.
   */
  listenToUpdatedApplication() {
    this.updatedApplicationSub = this._talentProcess.updatedApplication
      .subscribe((event: ApplicationUpdateEvent) => {
        const { application } = event;
        const appIndex = this.applications.findIndex(a => a.profile === application.profile);
        const visIndex = this.visibleActivities.findIndex(a => 'profile' in a && a.profile === application.profile);
        if (appIndex !== -1) {
          this.applications[appIndex] = application;
        }
        if (visIndex !== -1) {
          this.visibleActivities[visIndex] = application;
        }
        this.updateDataSubject();
      });
  }

  /**
   * Extracts all assignments from a profile object and converts it into
   * a form that is suitable for display in the UI.
   * @param profile
   */
  extractAssignmentsFromProfile(profile: Profile) {
    let assignments: Assignment[] = [];
    if (
      profile &&
      profile.allbirds_metadata &&
      profile.allbirds_metadata.assignments
    ) {
      // Sorts assignments in descending order based on start date.
      assignments = [...profile.allbirds_metadata.assignments].sort((a, b) => b.startDate.diff(a.startDate));
    }
    this.assignments = assignments;
  }
}
