import { Pipe, PipeTransform } from '@angular/core';
import { Moment } from 'moment';

@Pipe({
  name: 'abTime'
})
export class ABTimePipe implements PipeTransform {
  transform(input: Moment, format?: string): any {
    if (!input) {
      return 'None';
    }
    return input.formatTime(format);
  }
}
