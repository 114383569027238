<div id="toastContainer"
     [ngStyle]="toastPosition"
     [class.show]="isActive"
     [class.hide]="!isActive">
  <div class="alert {{ alertClass }}"
       [style.position]="isCentered ? 'relative' : null"
       [style.left]="isCentered ? '-50%': null">
    <div class="row">
      <div *ngIf="showIcon" class="col icon-col">
        <div class="v-align-a text-center">
          <i class="{{ iconClass }} v-align-b"></i>
        </div>
      </div>
      <div class="col-auto">
        <div class="v-align-a">
          <p class="v-align-b">{{ message }}</p>
        </div>
      </div>
      <div *ngIf="image">
        <img style="width: 40px; height: 40px; border-radius: 5px;" [src]="image">
      </div>
      <div *ngIf="actionLinks.length > 0" class="col">
        <div class="v-align-a">
          <ul class="action-list v-align-b">
            <li *ngFor="let link of actionLinks">
              <a *ngIf="link.routerLink" [routerLink]="link.routerLink">
                {{ link.text }}
              </a>
              <a *ngIf="link.action" (click)="link.action()">{{ link.text }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col icon-col">
        <div class="v-align-a">
          <i class="icon-WL_icon_close v-align-b" style="cursor: pointer;" (click)="closeToast()"></i>
        </div>
      </div>
    </div> <!-- / .row -->
  </div> <!-- / .alert -->
</div> <!-- / #toastContainer -->
