import { Pipe, PipeTransform } from '@angular/core';
import { TitleCasePipe } from '@angular/common';

@Pipe({
  name: 'array'
})
export class ArrayPipe implements PipeTransform {

  tranformContent(arr: string[] = [], args: ('sansunderscore' | 'titlecase')[] = []) {
    const titleCasePipe = new TitleCasePipe();
    return arr.map((val) => {
      if (args.includes('sansunderscore')) {
        val = val.replace(/_/g, ' ');
      }
      if (args.includes('titlecase')) {
        val = titleCasePipe.transform(val);
      }
      return val;
    });
  }

  transform(arr: Array<any>, args?: any): string {
    const tranformedContent = this.tranformContent(arr, args);

    let string = '';
    if (tranformedContent.length > 2) {
      for (let i = 0; i < tranformedContent.length; i++) {
        if (i === tranformedContent.length - 1) {
          string += `and ${tranformedContent[i]}`;
        } else {
          string += `${tranformedContent[i]}, `;
        }
      }
    } else if (tranformedContent.length === 2) {
      string = `${tranformedContent[0]} and ${tranformedContent[1]}`;
    } else {
      string = tranformedContent.toString();
    }
    return string;
  }

}
