<!-- modal header -->
<div class="modal-header">
  <h2 style="margin-bottom: inherit; padding-left: inherit;">Manage Roles</h2>
  <div style="justify-content: flex-end;">
    <button
      type="button"
      class="close-btn"
      aria-label="Close"
      (click)="_bsModalRef.hide()"
    >
      <i class="icon-WL_icon_close"></i>
    </button>
  </div>
</div>

<!-- job info -->
<div class="modal-body">
  <table>
    <tr>
      <td><h4>{{ "send-user-email.job_title" | translate }}</h4></td>
      <td><h4 class="gray-text ml-3">{{title}}</h4></td>
    </tr>
    <tr>
      <td><h4>{{ ((isClientOrder && customer_name) ? "send-user-email.company" : customer_priority ? "send-user-email.priority" : '') | translate }}</h4></td>
      <td><h4 class="gray-text ml-3">{{isClientOrder ? customer_name : ''}} <span class="priority" *ngIf="customer_priority">P{{customer_priority}}</span> </h4></td>
    </tr>
    <tr>
      <td><h4>{{ "send-user-email.job_id" | translate }}</h4></td>
      <td><h4 class="gray-text ml-3">{{front_office_id}}</h4></td>
    </tr>
  </table>

  <!-- manage roles -->
  <app-manage-roles [modalView]=true (roles)="setRoles($event)" [jobData]="jobData"></app-manage-roles>

</div>

<!-- modal footer cancel/confirm -->
<div id="assign-job-footer" class="modal-footer mt-2">
  <button (click)="_bsModalRef.hide()" class="btn rds--button--primary">
    {{ "send-user-email.cancel" | translate }}
  </button>
  <button
  *ngIf="!saving"
  (click)="confirmRoles()"
  class="btn rds--button--primary">
    {{ "change-owner-modal.confirm" | translate }}
  </button>
  <button
  *ngIf="saving"
  class="btn rds--button--primary">
    {{ "change-owner-modal.confirm_(saving)" | translate }}
  </button>
</div>


