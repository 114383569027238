<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div [formGroup]="form" class="modal-body">
  <h2>{{'interview-modal.manual_interview_scheduler' | translate}}</h2>
  <div class="w-100 mt-4">
    <label class="d-block">{{'interview-modal.interview_type' | translate}}</label>
    <div class="float-left mt-2">
      <input id="inperson" (click)="check('inperson')" [checked]=" form.controls.type.value === 'inperson' "
        class="rds--radio-button" type="radio" />
      <label for="inperson" class="rds--radio-button__label">
        <span class="rds--radio-button__appearance"></span>
        {{'interview-modal.in_person' | translate}}
      </label>
    </div>
    <div class="float-left mt-2">
      <input id="video" (click)="check('video')" [checked]=" form.controls.type.value === 'video' " class="rds--radio-button"
        type="radio" />
      <label for="video" class="rds--radio-button__label">
        <span class="rds--radio-button__appearance"></span>
        {{'interview-modal.video' | translate}}
      </label>
    </div>
    <div class="float-left mt-2">
      <input id="tele" (click)="check('tele')" [checked]=" form.controls.type.value === 'tele' " class="rds--radio-button"
        type="radio" />
      <label for="tele" class="rds--radio-button__label">
        <span class="rds--radio-button__appearance"></span>
        {{'interview-modal.telephone' | translate}}
      </label>
    </div>
  </div>
  <div *ngIf="form.controls.type.value === 'inperson'">
    <div class="row w-100">
      <div class="col mt-4">
        <label class="d-block">{{'interview-modal.in_person_interview' | translate}}</label>
        <input formControlName="address" type="text" class="form-control w-50" />
        <form-error *ngIf="form.controls" [fieldAlias]="'Address'" [control]="form.controls.address"></form-error>
      </div>
    </div>
    <div class="row w-100 mt-4">
      <div class="col">
        <label class="d-block">{{'interview-modal.date' | translate}}</label>
        <input formControlName="date" type="text" placeholder="{{'interview-modal.dateformat' | translate}}" class="form-control"
        bsDatepicker placement="top" [bsConfig]="datePickerConfig" />
        <form-error *ngIf="form.controls" [fieldAlias]="'Date'" [control]="form.controls.date"></form-error>
      </div>
      <div class="col">
        <label class="d-block">{{'interview-modal.start' | translate}}</label>
        <input formControlName="start" type="time" class="form-control" />
        <form-error *ngIf="form.controls" [fieldAlias]="'Start time'" [control]="form.controls.start"></form-error>
      </div>
      <div class="col">
        <label class="d-block">{{'interview-modal.end' | translate}}</label>
        <input formControlName="end" type="time" class="form-control" />
        <form-error *ngIf="form.controls" [fieldAlias]="'End time'" [control]="form.controls.end"></form-error>
      </div>
      <div class="col">
        <label class="d-block">{{'interview-modal.timezone' | translate}}</label>
        <select formControlName="timezone" class="custom-select" required>
          <option value="EST">EST</option>
          <option value="CST">CST</option>
        </select>
        <form-error *ngIf="form.controls" [fieldAlias]="'Timezone'" [control]="form.controls.timezone"></form-error>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="w-100 text-right">
    <span class="pr-3">
      <button type="button" class="btn btn rds--button--primary" aria-label="Close" (click)="modalRef.hide()">
        {{'interview-modal.cancel' | translate}}
      </button>
    </span>
    <button (click)="submit()" type="button" class="btn rds--button--secondary">
      {{'interview-modal.save' | translate}}
    </button>
  </div>
</div>