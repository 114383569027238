import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormValidationService } from '../../../../../../services/form-validation/form-validation.service';
import { KeyValue } from '@angular/common';
import { Subscription } from 'rxjs';
import { ApiService } from '../../../../../../services/api/api.service';
import { LoadingSpinnerService } from '../../../../../../services/loading-spinner/loading-spinner.service';
import {catchError, finalize} from 'rxjs/operators';
import { JobDetailsShortlistService } from '../../../../../../services/job-details-shortlist/job-details-shortlist.service';
import { TalentProcessService } from '../../../../../../services/talent-process/talent-process.service';
import { Application } from '../../../../../../models/external/application.model';
import { Job } from '../../../../../../models/external/job.model';
import { ProcessStatus } from 'src/app/shared/models/external/misc.model';
import { RequestBody } from '../../../../../../models/api/application-request.interface';
import { Offer } from 'src/app/shared/models/external/process/offer.model';
import { INTG_STEPS } from 'src/app/shared/models/internal/process.model';
import {UtilityService} from '../../../../../../services/utility/utility.service';
import {NotificationData, NotificationTypes} from '../../../../../../models/internal/notifications-data.model';
import {JobOrderService} from '../../../../../../services/job-order/job-order.service';
import {Profile} from '../../../../../../models/external/profile.model';
import {NotificationsService} from '../../../../../../services/notifications/notifications.service';
import {AuthService} from '../../../../../../services/auth/auth.service';

export enum RFORejectCodes {
  'D1' = 'Undesirable Location',
  'D2' = 'Undesirable Company',
  'D3' = 'Undesirable Pay',
  'D4' = 'Undesirable Benefits',
  'D5' = 'Undesirable Hours',
  'D6' = 'Undesirable Position',
  'D7' = 'Assignment too Long',
  'D8' = 'Assignment too Short',
  'D9' = 'Need More Notice',
  'D10' = 'No Longer Available for Work',
  'O' = 'Other'
}

export enum RFORejectContactMethods {
  'C' = 'Telephone Call',
  'E' = 'E-mail',
  'P' = 'In-Person',
  'V' = 'Verbal',
  'W' = 'In-Writing'
}

@Component({
  selector: 'app-reject-offer-modal',
  templateUrl: './reject-offer-modal.component.html',
  styleUrls: ['./reject-offer-modal.component.scss']
})
export class RejectOfferModalComponent implements OnInit, OnDestroy {

  // Talent application.
  application: Application;

  // Allbirds job order.
  job: Job;

  // Form.
  form: FormGroup;

  // All possible rejection options.
  rejectReasonOpts = RFORejectCodes;

  // All possible contact method options.
  contactMethodOpts = RFORejectContactMethods;

  // Subscription for "Pay rate offered" changes.
  offeredPayRateSub: Subscription;

  // Flag for whether or not the pay rate is hourly or salary.
  payRateIsHourly: boolean;

  jobOrder: Job;

  profile: Profile;

  profileSub: Subscription;

  constructor(
    public _bsModalRef: BsModalRef,
    private _formBuilder: FormBuilder,
    private _api: ApiService,
    private _loading: LoadingSpinnerService,
    private _shortlist: JobDetailsShortlistService,
    private _process: TalentProcessService,
    private _jobOrder: JobOrderService,
    private _notificationService: NotificationsService,
    private _utility: UtilityService,
    private auth: AuthService,
  ) { }

  ngOnInit(): void {
    this.jobOrder = this._jobOrder.storedJobOrder;
    this.setupView();
    this.profileSub = this._process.selectedProfile.subscribe(profile => {
      this.profile = profile;
    });
  }

  ngOnDestroy(): void {
    if (this.offeredPayRateSub) {
      this.offeredPayRateSub.unsubscribe();
    }
  }

  /**
   * If properly supplied a job and application, instantiates the FormGroup.
   */
  private setupView(): void {
    if (this.application && this.job) {
      this.form = this.generateForm(this.job);
      this.payRateIsHourly = this.isPayHourly(this.job);
      this.offeredPayRateSub = this.form.controls.offeredPayRate.valueChanges.subscribe(this.validatePayRate.bind(this));
      // Uncomment below when testing to see form changes.
      // this.form.valueChanges.subscribe(form => {
      //   console.log('Form updated!', form);
      // });

    } else {
      // TODO: display an error? This would only ever occur due to code error..
      // specifically, if someone launches the modal without supplying a job and application.
    }
  }

  /**
   * Given a job order, returns the Offer Rejection form with pay rate pre-filled.
   * @param job - Allbirds job order
   */
  private generateForm(job: Job): FormGroup {
    return this._formBuilder.group({
      contactMethod: ['', [Validators.required, FormValidationService.validateNonEmptyString]],
      offeredPayRate: [job.allbirds_metadata.internal_max_pay_rate || null, [Validators.required]],
      rejectReason: ['', [Validators.required, FormValidationService.validateNonEmptyString]],
      rejectNote: ['', [Validators.required, FormValidationService.validateNonEmptyString]],
      workPreviouslyPerformed: [null, [Validators.required]]
    });
  }

  /**
   * Comparator function for the keyvalue pipe that sorts the options alphabetically
   * by VALUE, not KEY. In our case, sorts the dropdown options in alphabetical order.
   * @param a - key value pair
   * @param b - key value pair
   */
  sortAlphabetically(a: KeyValue<string, string>, b: KeyValue<string, string>): number {
    const aValue = a.value.toLowerCase();
    const bValue = b.value.toLowerCase();
    if (aValue > bValue) {
      return 1;
    }
    if (aValue < bValue) {
      return -1;
    }
    return 0;
  }

  /**
   * Given an Allbirds job order, parses the compensation info to determine if the
   * supplied job order has an hourly or yearly pay rate.
   * @param job - Allbirds job order
   */
  private isPayHourly(job: Job): boolean {
    let payRateIsHourly = false;
    if (
      job &&
      job.compensationInfo &&
      job.compensationInfo.entries &&
      job.compensationInfo.entries.length
    ) {
      payRateIsHourly = (job.compensationInfo.entries[0].unit || '').toLowerCase() !== 'yearly';
    }
    return payRateIsHourly;
  }

  /**
   * Validator function fired on valueChanges for the offeredPayRate form control.
   * @param payRate - value emitted by FormControl.valueChanges
   */
  private validatePayRate(payRate: number) {
    const { offeredPayRate } = this.form.controls;
    if (this.payRateIsHourly) {
      if (payRate < 1 || payRate > 999) {
        FormValidationService.setError(offeredPayRate, 'payRate1-999');
      }
    } else {
      if (payRate < 1 || payRate > 999999) {
        FormValidationService.setError(offeredPayRate, 'payRate1-999999');
      }
    }
    FormValidationService.markAsTouched(offeredPayRate);
  }

  /**
   * Iterates over every form control and "touches" it -- inherently triggering the
   * form validation. Returns the validity of the form based on the validators described
   * in the generator function.
   * @param form - FormGroup object
   */
  private formIsValid(form: FormGroup): boolean {
    // Manually validate the pay rate.
    this.validatePayRate(this.form.controls.offeredPayRate.value);
    // Touch every control thereby firing every registered validator for the given control.
    for (const control in form.controls) {
      if (form.controls.hasOwnProperty(control)) {
        FormValidationService.markAsTouched(form.controls[control]);
      }
    }
    return form.valid;
  }

  /**
   * Given a talent's application and the form, constructs a request that abides by
   * the RejectCandidateRequest interface.
   * @param application - talent application
   * @param form - the form defined in this modal
   */
  private constructRejectRequest(application: Application, form: FormGroup): RequestBody.AddToRejectedRequest {
    const { contactMethod, offeredPayRate, rejectReason, rejectNote, workPreviouslyPerformed } = form.controls;
    return {
      applicationId: application.randstad_process._id,
      rejectReason: rejectReason.value,
      rejectNote: rejectNote.value,
      lastProcessStatus: ProcessStatus.O_REJECTED,
      offer: new Offer({
        offeredPayRate: `$${offeredPayRate.value} - ${this.payRateIsHourly ? 'hourly' : 'annually'}`,
        contactMethod: contactMethod.value,
        workPreviouslyPerformed: workPreviouslyPerformed.value
      }),
      intgSteps: [INTG_STEPS.SUBMISSION]
    };
  }

  /**
   * Fired on submitting the form. Calls form validation and if valid, makes the API
   * call that updates the talent's application.
   */
  submit(): void {
    // Check that form is valid.
    const isValid = this.formIsValid(this.form);
    if (isValid) {
      const body = this.constructRejectRequest(this.application, this.form);
      // Make the API call.
      this._loading.show();
      const profileInfo = this.application?.randstad_process?.profileInfo;
      this._api.addToRejected(body, true)
        .pipe(
          finalize(() => {
            this._loading.hide();
            this._bsModalRef.hide();
          })
        )
        .subscribe(res => {
          if (res && res.application) {
            if (!res.application.randstad_process?.profileInfo) {
              res.application.randstad_process.profileInfo = profileInfo;
            }
            this._shortlist.updateInMemoryApplications([res.application]);
            this._process.selectApplication(res.application);
            this.sendNotifications(res.application)
          }
          console.log('[reject] submit res:', res);
        }, err => {
          console.error('[reject] reject err:', err);
        });
    }
    return null;
  }

  sendNotifications(application: Application) {
    const values = this.form.getRawValue();
    const mentions = UtilityService.getMentionsRecipients(values.rejectNote);
    const app = application.clone();
    const rejectReason: string = this.rejectReasonOpts[values.rejectReason as keyof typeof RFORejectCodes]; // https://stackoverflow.com/a/41970976
    app.randstad_process.apply({
      rejectReason: rejectReason
    });
    const emailAddressOnToLine = mentions ? mentions.splice(0,1) : []
    const notificationBody: NotificationData = {
      notificationType: NotificationTypes.OFFER_REJECTED,
      notificationObject: {
        title: this.jobOrder.internalTitle,
        candidateFullName: `${this.profile.personNames[0].structuredName.givenName} ${this.profile.personNames[0].structuredName.familyName}`,
        published_by_user: this.jobOrder.allbirds_metadata.published_by_user,
        published_by_user_email: this.jobOrder.allbirds_metadata.published_by_user_email,
        allbirds_job_id: this.jobOrder.allbirds_metadata.allbirds_job_id,
        executing_action_user: this.auth.user.FullName,
        executing_action_user_email: this.auth.user.EmailAddr,
        customer_name: this.jobOrder.allbirds_metadata.customer_name,
        front_office_id: this.jobOrder.allbirds_metadata.front_office_id,
        user: {...this.auth.user},
        emailRecipients: emailAddressOnToLine,
        emailCc:mentions,
        // noteType: this.getFriendlyNoteType(noteType),
        noteComment: values.rejectNote,
        contextUrl: this._utility.getContextUrl(location.pathname, location.search),
        applicationObject: app,
        vms_req_id: this.jobOrder.allbirds_metadata.vms_req_id,
        hiringManager: this.jobOrder.hiringManager
      }
    };

    // this._loading.show();
    const sub = this._notificationService.sendNotification(notificationBody)
    .pipe(catchError((err) => {
      console.error(err);
      // this._loading.hide();
      return err;
    }))
    .subscribe(() => {
      sub.unsubscribe();
      // this._loading.hide();
    });
  }
}
