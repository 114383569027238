import { DropdownFilter } from '../../../shared/models/internal/job-form.interface';
export enum VMSJob {
  vmsOrdDttmSent = 'Release Date',
  customer_name = 'Customer Name',
  vmsOrderId = 'VMS Order ID',
  vmsOrdStatus = 'VMS Status',
  vmsJobTitle = 'Job Title',
  worksite_location = 'Worksite Location',
  vmsOrdRegBillRate = 'Bill Rate',
  hiring_manager = 'Hiring Manager',
}

export interface VmsJobRequest {
  searching?: boolean;
  vmsClientIds?: string[];
  vmsClientId?: string;
  backOfficeID?: string;
  lob?: string;
  from?: number;
  customer_name?: string;
  employmentTypes?: EmploymentTypes[];
  hiring_manager?: string;
  vmsJobTitle?: string;
  ownerName?: string;
  view: 'My Jobs' | 'All Jobs';
  vmsOrderId?: string;
  vmsOrdStatus?: VmsOrdStatus[];
  worksiteLoc?: {
    City?: string;
    State?: string;
    Zip?: string;
  };
}

interface VmsOrdStatus extends DropdownFilter {
  itemName: 'Submitted'
  | 'Pending Approval'
  | 'Active'
  | 'Halted'
  | 'Under Contract'
  | 'Closed'
  | 'Closed to New Matches'
  | 'Cancelled'
  | 'Declined'
  | 'Archived';
}

interface EmploymentTypes extends DropdownFilter {
  itemName: 'Permanent' | 'Contract' | 'Contract to Hire';
}

export interface VmsSearchView extends DropdownFilter {
  itemName: 'All Jobs' | 'My Jobs';
}
