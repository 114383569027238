import { User } from './../../models/external/user.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { VMSJob, VmsJobRequest } from '../../models/internal/vms.interface';
import { FirestoreService } from '../firestore/firestore.service';


const SERVER_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class VmsJobService {
  private VMSOrderSource = new BehaviorSubject<string[]>(Object.keys(VMSJob));
  VMSOrder = this.VMSOrderSource.asObservable();
  private VMSJobsSource = new BehaviorSubject<{vmsJobs: [], total: number, view: string}>({vmsJobs: null, total: null, view: 'My Jobs'});
  VMSJobs = this.VMSJobsSource.asObservable();
  private VMSJobSource = new BehaviorSubject<any>({});
  VMSJob = this.VMSJobSource.asObservable();
  private currentVmsRequestSource = new BehaviorSubject<VmsJobRequest>({vmsClientIds: [], view: 'My Jobs'});
  currentVmsRequest = this.currentVmsRequestSource.asObservable();
  private initializeCompleteSource = new BehaviorSubject<boolean>(false);
  initializeComplete = this.initializeCompleteSource.asObservable();

  public VMSCount = 0;
  keepFetching = true;
  from = 0;
  FETCH_COUNT = 10;
  total: any;
  isFetching = false;
  user: User;
  clients: string[] = [];

  constructor(
    private _auth: AuthService,
    private _apiService: ApiService,
    private _firestoreService: FirestoreService
  ) {
    this.initialize();
  }

  getVmsJobs(vmsRequest: VmsJobRequest, init = false) {
    if (this.keepFetching && !this.isFetching) {
      this.isFetching = true;
      vmsRequest.lob = this._auth.user.Source;
      vmsRequest.from = this.from;
      vmsRequest.vmsClientIds = this.clients;
      vmsRequest.backOfficeID = this._auth.user.BackOfficeID;
      this._apiService.getVMSJobs(vmsRequest)
      .subscribe((res: any) => {
        if (res) {
          const { total, vmsJobs } = res;
          if (!init) {
            // console.log(vmsJobs);
            // console.log('how many came back: ', vmsJobs.length);
            this.VMSJobsSource.next({total, vmsJobs, view: vmsRequest.view });
            //if the sidebar icon is clicked, we update the vms counter
            if(!vmsRequest.searching && !this.from) this.VMSCount = total;
            this.from += this.FETCH_COUNT;
            this.total = total ? total : 0;
            this.keepFetching = this.from < this.total;
            this.isFetching = false;
            this.currentVmsRequestSource.next(vmsRequest);
          } else {
            this.VMSCount = total;
            this.isFetching = false;
            this.initializeCompleteSource.next(true);
          }
        }
      });
    }
  }

  getVmsJob(vmsOrderId: string) {
    this._apiService.getVMSJob(vmsOrderId)
    .subscribe(vmsJob => {
      this.VMSJobSource.next(vmsJob);
    }, (err: HttpErrorResponse) => {
      console.error(`Server Status: ${err.status}, Error Message: ${err.error}`);
    });
  }

  searchVmsJobs(vmsRequest: VmsJobRequest) {
    this.destroyVmsJobs(vmsRequest.view);
    this.getVmsJobs(vmsRequest);
  }

  newVMSOrder(newOrder: string[]) {
    this.VMSOrderSource.next(newOrder);
  }

  destroyVmsJobs(view: 'My Jobs' | 'All Jobs') {
    this.total = 0;
    this.from = 0;
    this.keepFetching = true;
    this.isFetching = false;
    this.VMSJobsSource.next({vmsJobs: null, total: null, view});
  }

  destroyVmsRequest(view: 'My Jobs' | 'All Jobs') {
    this.currentVmsRequestSource.next({ vmsClientIds: [], view });
  }

  abstractClientIds(vms: any) {
    const vms_settings = ((vms || {} as any).Preferences || {}).vms_settings;
    const clients = (vms_settings || {}).clients || {};
    this.clients = Object.keys(clients);
  }

  setNewJobOrder(vms: any) {
    const vms_settings = ((vms || {} as any).Preferences || {}).vms_settings;
    const vmsJobColumnOrder = (vms_settings || {}).inbox_order || [];
    if (vmsJobColumnOrder.length) {
      if(vmsJobColumnOrder.includes('vmsOrdDttmCreated')){
        const index = vmsJobColumnOrder.indexOf('vmsOrdDttmCreated');
        vmsJobColumnOrder[index] = 'vmsOrdDttmSent';
      }
      this.VMSOrderSource.next(vmsJobColumnOrder);
    }
  }

  setColumnOrder(columnOrder: string[]) {
    this.VMSOrderSource.next(columnOrder);
    this._apiService.setVmsInboxOrder(columnOrder).toPromise()
    .then( () => {
      console.log('success!');
    });
  }

  initialize() {
    this.user = this._auth.user;
    this._firestoreService.getVmsSettings(this.user.BackOfficeID).subscribe( vms => {
      this.abstractClientIds(vms);
      this.setNewJobOrder(vms);
      this.getVmsJobs({ view: 'My Jobs' }, true);
    });
  }

  getVmsCount() {
    this._apiService.getVMSJobs({
      view: 'My Jobs',
      lob: this._auth.user.Source,
      from: 0,
      vmsClientIds: this.clients,
      backOfficeID: this._auth.user.BackOfficeID,
    })
    .subscribe((res: any) => {
        this.VMSCount = res.total || this.VMSCount;
    });
  }

}
