import {Moment} from "moment";
import {Serial} from "../decorators/serial.decorator";
import {Appointments} from "../misc.model";
import {createModel, Mapping, Model} from "../model";
import {IsoToMoment} from "../serializers/moment.serializer";
export import RawAppointmentFeedback = Appointments.AppointmentFeedback;

/**
 * appointmentFeedback property
 * @class {AppointmentFeedbackMapping}
 */
class AppointFeedbackMapping extends Mapping<RawAppointmentFeedback> {
  @Serial(IsoToMoment) completionDate?: Moment;
  @Serial(IsoToMoment) startDate?: Moment;
}

export interface AppointmentFeedback extends Model<AppointFeedbackMapping> {}
export const AppointmentFeedback = createModel<AppointmentFeedback>(AppointFeedbackMapping);
