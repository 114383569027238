import { VmsJobService } from 'src/app/shared/services/vms-job/vms-job.service';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from '../../services/auth/auth.service';
import { PlatformAlertService } from '../../services/platform-alert/platform-alert.service';
import { PlatformAlert } from '../../models/internal/platform-alert.model';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { UrlStateService } from '../../services/url-state/url-state.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public _opened = true;
  leftAttr = '220px';
  marginLeftAttr: string;
  activeTab: string;
  notifications: any[] = [];
  showAdmin = false;
  showAlertBanner = false;
  hasVmsPermission = false;

  get userLob() { return this.auth.user && this.auth.user.Source; }

  constructor(
    private location: Location,
    public auth: AuthService,
    private _alertPlatform: PlatformAlertService,
    public _vmsService: VmsJobService,
    private _url: UrlStateService) {
    this.toggleSidebar();
    this.setActiveTab();
    this.checkVmsPermission();
  }

  ngOnInit() {
    this.auth.authenticate().then(user => {
      if (user.Permissions && user.Permissions.admin) {
        this.showAdmin = true;
      }
    });
    this.notifications = [];

    this._alertPlatform.alertObservable$
      .subscribe((data: PlatformAlert) => {
        if (data === null) this.showAlertBanner = false;
        else this.showAlertBanner = true;
      })
  }

  toggleSidebar() {
    if (!this._opened) {
      this.leftAttr = '250px';
      this.marginLeftAttr = '0';
    } else {
      this.leftAttr = '65px';
      this.marginLeftAttr = '155px';
    }
    this._opened = !this._opened;
  }

  setActiveTab() {
    this.activeTab = this.location.path();
  }

  checkVmsPermission() {
    if (this.userLob.checkLob('RT', 'RE','CR')
      && (this.auth.user.Roles.includes('Full Desk - RT')
          || this.auth.user.Roles.includes('Account Manager'))) {
      this.hasVmsPermission = true;
    }
  }

  reloadVmsInbox() {
    this._vmsService.searchVmsJobs({view: 'My Jobs'});
  }

  removeUrlState() {
    this._url.remove('st', true)
  }

}
