import { Application } from './../../models/external/application.model';

import { Injectable } from '@angular/core';
import {Profile} from 'src/app/shared/models/external/profile.model';

import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { TranslateService } from '@ngx-translate/core';
import { MassActionService } from 'src/app/shared/services/mass-action/mass-action.service';
import { EnhancedProfile } from 'src/app/shared/models/internal/enhanced-profile.interface';

import { EmailInput, EmailIssueInfo, EmailPreference, EmailPreferences, EMAIL_ISSUE } from 'src/app/shared/models/internal/mass-email.model';
import { MassEmail } from '@allbirds-ui/allbirds-types/dist/types/MassEmail';
import { Google } from '@allbirds-ui/allbirds-types/dist/external/Google';
import { ListTalent } from 'src/app/shared/models/external/list-talent.model';
import { MASS_OPERATION_CONTEXT, MASS_ACTION_TYPE } from './mass-operation-context';
import { RecentActivityListCardDetails } from '../../models/internal/recent-activities-list.model';
import { MassSms } from '../../models/internal/mass-sms';
import { SelectedProfiles, SelectedApplications, SelectedPipelineTalents, SelectedRecentActivities } from '../../models/internal/mass-action.model';
import { Profiles } from '@allbirds-ui/allbirds-types';

@Injectable({
   providedIn: 'root'
})
export class MassOperationRecipientService  {


    //Email that are selected in bulk from the backend, final list after removing problematic ones
    finalEmailList : EmailInput[] = [];

    //This map helps get the name of user from email for filtering
    emailNameMap: Map<string, string> = new Map<string,string>();

    //Enables getting recipient data of the user easily as a map
    emailRecipientMap: Map<string, MassEmail.RecipientData> = new Map<string,  MassEmail.RecipientData>();

    //Emails which cant be sent due to some problem
    problemusers: EmailIssueInfo[] = [];

    action?: MASS_ACTION_TYPE;

    isDeletedProfile: boolean;

    textRecipientMap: Map<string, MassSms.RecipientData> = new Map<string,  MassSms.RecipientData>();

    recruiterLOB: string = 'RT';


    constructor(
        private _auth: AuthService,
        private _api: ApiService,
        private _translate: TranslateService,
        private _massAction: MassActionService

    ) {

     }

     clearSelections(): void {
        this.finalEmailList = [];
        this.emailNameMap = new Map<string,string>();
        this.emailRecipientMap = new Map<string,  MassEmail.RecipientData>();
        this.problemusers = [];
        this.textRecipientMap = new Map<string,  MassSms.RecipientData>();
    }



    async initialize(context : MASS_OPERATION_CONTEXT, action?: MASS_ACTION_TYPE, profiles?: EnhancedProfile[] | Profile[] ): Promise<Recipients | TextRecipients> {
        this.emailNameMap = new Map();
        this.problemusers = [];
        this.action = action;
        const emails: EmailInput[] = [];
        //Checks whether profiles object was passed in or not
        if (!profiles) {
          await this.extractSelectedTalent(context, emails, action);
        } else if(profiles){
          try {
            const talentObj: { [id: string]: Profile } = {};
            profiles.forEach((profile) => {
              if (profile) {
                talentObj[profile.name] = profile;
              }
            });
            await this.extractSelectedTalent(context, emails, action, profiles);
          } catch (e) {
            console.error(e);
          }
        }

        if(action == MASS_ACTION_TYPE.email){
            const prefs = await this._api.getEmailPreferences(Array.from( this.emailNameMap.keys())).toPromise();
            this.extractTalentEmailPrefs(prefs);
            this.finalEmailList = emails.filter(input => {
                if (this.emailRecipientMap.get(input.email) )
                    return true;
                else
                    return false;
            });
        }
        if(action == MASS_ACTION_TYPE.text) {
            const frontOfficeIds: string[] = [];
            if(this._auth){
                this.recruiterLOB = this._auth.isLob('RT') ? 'RT' : 'RGS';
            }
            this.textRecipientMap.forEach((value: MassSms.RecipientData, key: string) => {
                frontOfficeIds.push('US' + this.recruiterLOB + value.foEmplId);
            });
          /*
          * size check below is added to ensure that at least one user has been processed without failures
          * background: issue with duplicate profile being the only one added to mass-action which caused no frontOfficeIds to be passed into getSmsOpInPreferences
          */
          if (this.textRecipientMap.size > 0) {
              const prefs = await this._api.getSmsOptInPreferences(frontOfficeIds).toPromise();
              this.extractTalentTextPrefs(prefs);
          }
          this.finalEmailList = emails.filter(input => {
              if (this.textRecipientMap.get(input.email) )
                  return true;
              else
                  return false;
          });

        }


        if(action && action == MASS_ACTION_TYPE.text) {
            return {
                'emailList': this.finalEmailList,
                'recipients': this.textRecipientMap,
                'issue': this.problemusers
            };
        }
        else {
            return {
                'emailList': this.finalEmailList,
                'recipients': this.emailRecipientMap,
                'issue': this.problemusers
            };
        }
    }
    extractTalentTextPrefs(prefs: any) {

        const preferencesMap : Map<string, string> = new Map<string,string>();

        prefs.preferences.forEach((pref: any) => {
          preferencesMap.set(pref.frontOfficeID, pref.smsOptInPhoneNumber);
        })
        this.textRecipientMap.forEach((value: MassSms.RecipientData, key: string) => {

            const frontOfficeEmplId = 'US' + this.recruiterLOB + value.foEmplId;
            const textPref = preferencesMap.get(frontOfficeEmplId);

            if(textPref == 'NA' || textPref == '') {
                const info: EmailIssueInfo = this.getProblemInfo(value.email, this.emailNameMap.get(value.email), EMAIL_ISSUE.NOT_OPT_IN_TEXT);
                this.problemusers.push(info);
                this.textRecipientMap.delete(key);
            }
            else {
                if(textPref){
                    value.phoneNumber = '1' + textPref.trim();
                }
                //Otherwise continue with the phoneNumber present in elastic profile or enhanced_profile index
            }
            if(!value.phoneNumber || value.phoneNumber.trim() == '') {
              this.handleInvalidPhone(value.email, this.emailNameMap.get(value.email), value.phoneNumber);
              this.textRecipientMap.delete(key);
            }
        });
    }

    private extractTalentEmailPrefs(prefs: EmailPreferences) {
        prefs.emailPreferences.forEach((emailPref: EmailPreference) => {
          if (emailPref.markedspam) {
                const info: EmailIssueInfo = this.getProblemInfo(emailPref._id, this.emailNameMap.get(emailPref._id), EMAIL_ISSUE.MARKED_SPAM);
                this.problemusers.push(info);
                this.emailRecipientMap.delete(info.email);
            }
            else if (emailPref.optout_j) {
                const info: EmailIssueInfo = this.getProblemInfo(emailPref._id, this.emailNameMap.get(emailPref._id), EMAIL_ISSUE.OPT_OUT);
                this.problemusers.push(info);
                this.emailRecipientMap.delete(info.email);
            }
        });
    }

    private async extractSelectedTalent(context: MASS_OPERATION_CONTEXT, emails: EmailInput[], action?: MASS_ACTION_TYPE, selectedProfiles?: EnhancedProfile[] | Profile[]) {
      let selectedProfileNames: string[] = [];
      let nonDeletedProfiles: Profile[] = [];
      let disqualifiedProfiles: Profile[] = [];
      let skipDeletedDisqualifiedProfValidation: boolean = false;
      let filterDisqualifiedSearchedTalent: boolean = false
      if (!selectedProfiles) {
        switch (context) {
          case MASS_OPERATION_CONTEXT.pipeline:
            selectedProfileNames = Object.keys(this._massAction.selectedPipelineTalents);
            break;
          case MASS_OPERATION_CONTEXT.modelsPotential:
          case MASS_OPERATION_CONTEXT.jobPotential:
            //No need to run profiles to check if they're deleted since ModelS filters out deleted profiles
            skipDeletedDisqualifiedProfValidation = true;
            let nonDeletedProfs = Object.values(this._massAction.selectedProfiles);

            //Need to filter out disqualified profile from known talent search
            nonDeletedProfs.map(profile => {
              if(profile.isDisqualified){
                disqualifiedProfiles.push(profile)
              } else {
                nonDeletedProfiles.push(profile)
              }
            })
            break;
          case MASS_OPERATION_CONTEXT.recentActivities:
            let selectedProfileVal = Object.values(this._massAction.selectedRecentActivities);
            selectedProfileVal.map(profiles => selectedProfileNames.push(profiles.profile.name))
            break;
          case MASS_OPERATION_CONTEXT.application:
            selectedProfileNames = Object.keys(this._massAction.selectedApplications);
            break;
        }
      } else {
        if (context == 1 || context == 5) {
          //Coming from talent-search or talent-details page so no need to run deletedProf validation but still need to remove disqualified talent
          skipDeletedDisqualifiedProfValidation = true;
          let nonDeletedProfs = Object.values(selectedProfiles);
          nonDeletedProfs.map(profile => {
            if(profile.isDisqualified){
              disqualifiedProfiles.push(profile)
            } else {
              nonDeletedProfiles.push(profile)
            }
          })
        } else {
          //Mass-appointments coming from private list so validation must be ran
          let selectedProfileVal = Object.values(selectedProfiles);
          selectedProfileVal.map(profiles => selectedProfileNames.push(profiles.name))
        }
      }
      if (!skipDeletedDisqualifiedProfValidation){
          let { deletedProfs , nonDeletedProfs, disqualifiedProfs } = await this._massAction.getDeletedProfiles(selectedProfileNames)
        
        //Deleted profiles and disqualified profiles should run their own validation seperate from the others
        if (deletedProfs) {
          deletedProfs.map((profs: { emailAddresses: { emailAddress: string; }[]; personNames: any[]; }) => {
            let email, name, fullName;
            email = profs && profs.emailAddresses[0].emailAddress.toLowerCase() || '';
            name = profs.personNames[0];
            fullName = `${name.structuredName.givenName} ${name.structuredName.familyName}`;
            this.handleDeletedProfile(email, fullName);
          })
        }

        disqualifiedProfiles = disqualifiedProfs? disqualifiedProfs : []
        nonDeletedProfiles = nonDeletedProfs
      }


      if (disqualifiedProfiles) {
        disqualifiedProfiles.map((profs: { emailAddresses: { emailAddress: string; }[]; personNames: any[]; }) => {
          let email, name, fullName;
          if(profs.emailAddresses){
            email = profs && profs.emailAddresses[0]?.emailAddress.toLowerCase() || '';
          }
          name = profs.personNames[0];
          fullName = `${name.structuredName.givenName} ${name.structuredName.familyName}`;
          this.handleDisqualifiedProfile(email, fullName);
        })
      }


      nonDeletedProfiles.forEach(profile => {
        // Based on profile type get the recipient data from profile/application/enhanced-profile
        let { email, fullName, name, profileId, foEmplId, opco, phoneNumber, appointments } = this.getContextualEmailAndTextInfo(context, profile);
        if(!email && !name ) return;
        if (!email || email == "") this.handleInvalidEmail(fullName);
        if (!this.validateEmailAddress(email)) this.handleInvalidEmailAddress(email,fullName)
        else {
          if(!this.emailRecipientMap.get(email) || !this.textRecipientMap.get(email)) {
              if(this.action == MASS_ACTION_TYPE.text) {
                this.textRecipientMap.set(email, this.generateRecipientDataForText(name, profileId, email, foEmplId, phoneNumber, opco))
              }
              else {
                this.emailRecipientMap.set(email, this.generateRecipientData(name, profileId, email, foEmplId));
              }
          }
          const inp: EmailInput = getEmailInput(email, name);
          if (!this._auth.isOpco(opco)) {
              this.handleLobMismatch(email, inp, foEmplId);
          }
          if(appointments && appointments.length > 0){
              this.handleAppointments(email, inp, foEmplId)
          }
          else {
              this.emailNameMap.set(email, inp.name);
              emails.push(inp);
          }
        }
        // Many activities dont have profiles
        /*else if(!profileId || profileId == "" || profileId == "null" ) {
            const info: EmailIssueInfo = this.getProblemInfo(email, fullName, EMAIL_ISSUE.INVALID_PROFILE);
            this.problemusers.push(info);
        }*/
      })
    }

    private getContextualEmailAndTextInfo(context: MASS_OPERATION_CONTEXT, profile: any) {
      let name, email, foEmplId, opco, profileId, fullName, phoneNumber, appointments;
      if (context === MASS_OPERATION_CONTEXT.modelsPotential
          || context === MASS_OPERATION_CONTEXT.jobPotential ||
          context === MASS_OPERATION_CONTEXT.individualEnhancedProfile) {
          profile = (profile as EnhancedProfile);
          if(!profile) {
              return {};
          }
          name = profile.personNames[0];
          foEmplId = profile && profile.externalId && profile.externalId.replace(/\D/g, '');
          profileId = profile.name;
          fullName = `${name.structuredName.givenName} ${name.structuredName.familyName}`;
          phoneNumber = profile.normPhone && profile.normPhone[0] || '';
          //Part of enhanced profile
          email = profile && profile.normEmail && profile.normEmail[0]?.toLowerCase() || '';
          opco = profile && profile.opco;
          appointments = profile.appointments || [];
      } else if (profile) {
        profileId = profile.name;
        name = profile.personNames[0];
        email = profile && profile.emailAddresses[0].emailAddress?.toLowerCase() || '';
        opco = profile.externalSystem;
        foEmplId = profile && profile.externalId && profile.externalId.replace(opco, '');
        opco === 'USRT' ? opco = 'RT' : opco = 'RGS';
        fullName = `${name.structuredName.givenName} ${name.structuredName.familyName}`;
        phoneNumber = this.getPhoneNumber(profile);
      } else return {};

      return { email, fullName, name, profileId, foEmplId, opco, phoneNumber, appointments };
    }


    private getPhoneNumber(profile: any) {

        const phoneNumbers = profile.phoneNumbers;
        let phone = '';
        //Mobile takes precedence ?
        if (phoneNumbers && phoneNumbers.length > 0) {
            phoneNumbers.forEach((phNo: any) => {
                if (phNo.type == 'MOBILE') {
                    phone = phNo.number;
                }
            });
            if (phone == '') {
                phone = phoneNumbers[0].number;
            }
        }
        return phone;
    }

    private handleInvalidPhone(email: string, fullName: string, phoneNumber: string) {
        const info: EmailIssueInfo = this.getPhoneProblemInfo(email, fullName, EMAIL_ISSUE.INVALID_PHONE, phoneNumber);
        this.problemusers.push(info);
    }

    private handleDeletedProfile(email: string, fullName: string) {
        const info: EmailIssueInfo = this.getProblemInfo(email, fullName, EMAIL_ISSUE.HAS_DELETED_PROFILE);
        this.problemusers.push(info);
    }

    private handleDisqualifiedProfile(email: string, fullName: string) {
      const info: EmailIssueInfo = this.getProblemInfo(email, fullName, EMAIL_ISSUE.DISQUALIFIED_USER);
      this.problemusers.push(info);
  }

    private handleInvalidEmail(fullName: string) {
        const info: EmailIssueInfo = this.getProblemInfo("", fullName, EMAIL_ISSUE.NO_EMAIL);
        this.problemusers.push(info);
    }

    private handleInvalidEmailAddress(email:string,fullName:string){
      const info: EmailIssueInfo = this.getProblemInfo(email, fullName, EMAIL_ISSUE.INVALID_EMAIL)
      this.problemusers.push(info)
    }

    private validateEmailAddress(email:string){
      const re = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      return re.test(email);
    }

    private handleLobMismatch(email: string, inp: EmailInput, foEmplId: string) {
        const info: EmailIssueInfo = this.getProblemInfo(email, inp.name, EMAIL_ISSUE.DIFFERENT_LOB);
        this.problemusers.push(info);
        if(this.emailRecipientMap.get(email)) {
            const recipientData: MassEmail.RecipientData = this.emailRecipientMap.get(email);
            if(recipientData.foEmplId == foEmplId) {
                this.emailRecipientMap.delete(email);
            }
        }
    }

    private handleAppointments(email: string, inp: EmailInput, foEmplId: string) {
        const info: EmailIssueInfo = this.getProblemInfo(email, inp.name, EMAIL_ISSUE.APPOINTMENT_EXIST);
        this.problemusers.push(info);
        if(this.emailRecipientMap.get(email)) {
            const recipientData: MassEmail.RecipientData = this.emailRecipientMap.get(email);
            if(recipientData.foEmplId == foEmplId) {
                this.emailRecipientMap.delete(email);
            }
        }
    }


    private getProblemInfo(email: string, name: string, issue: EMAIL_ISSUE): EmailIssueInfo {
        return  {
            email,
            name,
            issue
        };
    }

    private getPhoneProblemInfo(email: string, name: string, issue: EMAIL_ISSUE, phoneNumber: string): EmailIssueInfo {
        return  {
            email: phoneNumber,
            name,
            issue
        };
    }

    private generateRecipientData(name: Google.Talent.PersonName, profileId: string, email: string, foEmplId: string): MassEmail.RecipientData {
        let firstName: MassEmail.Attr = {
          key: 'firstname',
          value: name.structuredName.givenName
        };
        let lastName: MassEmail.Attr = {
          key: 'lastname',
          value: name.structuredName.familyName
        };
        let profileAttr: MassEmail.Attr = {
          key: 'profileId',
          value: profileId
        };
        let attrs: MassEmail.Attr[] = [firstName, lastName, profileAttr];
        let recData: MassEmail.RecipientData = {
          'email': email,
          'foEmplId': foEmplId,
          'attrs': attrs

        };
        return recData;
    }


    private generateRecipientDataForText(name: Google.Talent.PersonName, profileId: string, email: string, foEmplId: string, phoneNumber: string, opco: string): MassSms.RecipientData {
        const phoneRegEx = /^\d{10}$/;
        if(phoneNumber) {
          phoneNumber = phoneNumber.trim().replace(/[^0-9]/g, '');
        }
        if(phoneNumber && phoneNumber.match(phoneRegEx)) {
           phoneNumber = '+1' + phoneNumber;
        }
        else {
            phoneNumber= phoneNumber?.replace('/','');
            phoneNumber= phoneNumber?.replace('-','');
            phoneNumber = '+1' + phoneNumber;
        }

        let firstName: MassSms.Attr = {
          key: 'firstname',
          value: name.structuredName.givenName
        };
        let lastName: MassSms.Attr = {
          key: 'lastname',
          value: name.structuredName.familyName
        };
        let profileAttr: MassSms.Attr = {
          key: 'profileId',
          value: profileId
        };
        let attrs: MassSms.Attr[] = [firstName, lastName, profileAttr];

        let recData: MassSms.RecipientData = {
          'email': email,
          'foEmplId': foEmplId,
          'phoneNumber': phoneNumber
        };
        return recData;
    }

}




function getEmailInput(email: string, name: Google.Talent.PersonName): EmailInput {
    return {
        email: email,
        name: `${name.structuredName.givenName} ${name.structuredName.familyName}`
    };
}


export interface Recipients {
    emailList: EmailInput[],
    recipients: Map<string, MassEmail.RecipientData>
    issue: EmailIssueInfo[]
}

export interface TextRecipients {
    emailList: EmailInput[],
    recipients: Map<string, MassSms.RecipientData>
    issue: EmailIssueInfo[]
}
