import { Profiles } from '@allbirds-ui/allbirds-types';
import { createModel, Mapping, Model } from './model';
import { Serial } from './decorators/serial.decorator';
import { EmploymentRecord } from './employment-record.model';
import { Internal } from './decorators/internal.decorator';
import { ITalent } from './talent.interface';
import { Profile } from './profile.model';
import { Application } from './application.model';
import { EducationRecord } from './education-record.model';
export import RawGoogleProfile = Profiles.GoogleProfile;
import { Action } from './user-actions.model';

class GoogleProfileMapping extends Mapping<RawGoogleProfile> implements ITalent {
  @Serial([EmploymentRecord]) employmentRecords?: EmploymentRecord[];
  @Serial([EducationRecord]) educationRecords?: EducationRecord[];
  @Internal() index: number;
  @Internal() score: number;
  @Internal() likelyAvailable: boolean;
  @Internal() references?: any[];
  externalSystem: any;
  externalSystemLob: any;
  externalId: string;
  name: string;
  actionsOnProfile: Action;

  isGoogleProfile(): this is GoogleProfile {
    return true;
  }

  isProfile(): this is Profile {
    return false;
  }

  isApplication(): this is Application {
    return false;
  }

  get lob(): string {
    if(this.externalSystem) {
      return this.externalSystem;
    }
    else {
      return this.externalId;
    }
  }

  get profileId(): string {
    return this.name;
  }
}

export interface GoogleProfile extends Model<GoogleProfileMapping> {}

export const GoogleProfile = createModel<GoogleProfile>(GoogleProfileMapping);
