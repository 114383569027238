import { SendUserEmailModalComponent } from '../../../modules/jobs/pages/job-details/send-email-modal/send-user-email-modal.component';
import { UpdateJobStatusModalComponent} from "../update-job-status-modal/update-job-status-modal.component";
import {Component, Input, OnInit} from '@angular/core';
import {ClosedStatus} from '../../models/internal/process-statuses.interface';
import { Job } from '../../models/external/job.model';
import {AdManagementModalComponent} from '../../../modules/jobs/pages/job-details/ad-management-modal/ad-management-modal.component';
import {ModalService} from '../../services/modal/modal.service';
import {UserService} from '../../services/user/user.service';
import {AuthService} from '../../services/auth/auth.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../models/external/user.model';
import {AssignRecruiterModalComponent} from '../../../modules/jobs/pages/job-details/assign-recruiter-modal/assign-recruiter-modal.component';
import {ChangeOwnerModalComponent} from '../../../modules/jobs/pages/job-details/change-owner-modal/change-owner-modal.component';
import {CloseJobModalComponent} from '../../../modules/jobs/pages/job-details/close-job-modal/close-job-modal.component';
import {ExtendJobModalComponent} from '../../../modules/jobs/pages/job-details/extend-job-modal/extend-job-modal.component';
import { ManageRolesModalComponent } from './../../../modules/jobs/pages/job-details/manage-roles-modal/manage-roles-modal.component';
import { UtilityService } from '../../services/utility/utility.service';
import { SuggestService } from 'src/app/shared/services/suggest/suggest.service';
import { LoadingSpinnerService } from 'src/app/shared/services/loading-spinner/loading-spinner.service';
import { ToastService, ToastClass } from 'src/app/shared/services/toast';
import { OrderType } from '../../models/internal/job-form.interface';
import moment from "moment";
import {JobOrderService} from "../../services/job-order/job-order.service";
import {UrlStateService} from "../../services/url-state/url-state.service";


@Component({
  selector: 'app-job-action-menu',
  templateUrl: './job-action-menu.component.html',
  styleUrls: ['./job-action-menu.component.scss']
})
export class JobActionMenuComponent implements OnInit {
  @Input() jobData: Job;
  @Input() container: string;
  @Input() alignment: string;
  @Input() view: 'card-view' | 'list-view';
  user: any;
  modalRef: BsModalRef;
  viewCloneJobLink = false;
  viewAssignRecruiterLink = false;
  permissionToExtendJob = false;
  isRt = false;
  isClosed = false;
  isPublished = false;

  get isClientOrder(): boolean { return this.jobData.allbirds_metadata.order_type !== OrderType.PIPELINE; }

  constructor(
    private router: Router,
    private _route: ActivatedRoute,
    private modalService: ModalService,
    private _user: UserService,
    private auth: AuthService,
    private _utility: UtilityService,
    private suggest: SuggestService,
    private loading: LoadingSpinnerService,
    private toast: ToastService,
    private _jobOrder: JobOrderService,
    private _urlState: UrlStateService
    ) {
    this.user = this.auth.user;
  }

  get userLob(): string { return this.auth.user && this.auth.user.Source; }

  ngOnInit() {

    if (this.user && this.auth.user && this.userLob.checkLob('RE', 'RT',"CR")) {
      this.setRt(this.auth.user);
    }
    if (this.auth && this.auth.user) {
      this.setRecruiterLink(this.auth.user);
      if (this.auth.user.Permissions.assignJobToRecruiter) {
        this.viewAssignRecruiterLink = true;
      }
      if (this.auth.user.Permissions.createJob) {
        this.viewCloneJobLink = true;
      }
      if(this.auth.user.Permissions.extendJobExpirationDate) {
        this.permissionToExtendJob = true;
      }
    }
    if(ClosedStatus.includes(this.jobData && this.jobData.allbirds_metadata.order_status)) this.isClosed = true;
    if (this._jobOrder && this.jobData) {
      this.isPublished = this._jobOrder.publishedStatus(this.jobData);
    }
  }

  setRt(user: User) {
    if (this.userLob.checkLob('RT', 'RE','CR')) {
      this.isRt = true;
    }
  }

  setRecruiterLink(user: User) {
    if (user.Role === 'RD/ARD' || user.Role === 'Business Support') {
      this.viewAssignRecruiterLink = true;
    }
  }

  manageAdBtnClick() {
    const orderStatus = this.jobData?.allbirds_metadata?.order_status || '';
    const orderType = this.jobData?.allbirds_metadata?.order_type || '';
    if (!this.jobData.allbirds_metadata.front_office_id) {
      this._utility.launchFeatureUnavailableModal('feature-unavailable.JOB_NO_FO_ID');
    } else if (ClosedStatus.includes(orderStatus) && this.isRt) {
      this._utility.launchFeatureUnavailableModal('feature-unavailable.JOB_CLOSED');
    } else if (!this.isRt
      && ((this.isJobExpired(this.jobData) || (orderStatus === 'Active' || orderStatus === 'Closed') && orderType !== 'Pipeline')
        || (!this.isPublished && orderType === 'Pipeline'))) {
      this._utility.launchFeatureUnavailableModal('feature-unavailable.JOB_CLOSED_ACTIVE_EXPIRED');
    } else {
      this.openAdManagementModal();
    }
  }

  openAdManagementModal() {
    const initialState = {
      allbirds_job_id: this.jobData.allbirds_metadata.allbirds_job_id,
      description: this.jobData.description,
      jobData: this.jobData
    };
    this.modalRef = this.modalService.show(AdManagementModalComponent, { initialState });
  }

  editBtnClick() {
    const orderStatus = this.jobData?.allbirds_metadata?.order_status || '';
    const orderType = this.jobData?.allbirds_metadata?.order_type || '';
    if (!this.jobData.allbirds_metadata.front_office_id) {
      this._utility.launchFeatureUnavailableModal('feature-unavailable.JOB_NO_FO_ID');
    } else if (!this.isRt
      && ((this.isJobExpired(this.jobData) || (orderStatus === 'Active' || orderStatus === 'Closed') && orderType !== 'Pipeline')
        || (!this.isPublished && orderType === 'Pipeline'))) {
      this._utility.launchFeatureUnavailableModal('feature-unavailable.JOB_CLOSED_ACTIVE_EXPIRED');
    } else {
      this.goEdit();
    }
  }

  goEdit() { this.router.navigate([`/jobs/${this.jobData.allbirds_metadata.allbirds_job_id}/edit`]); }

  assignRecruiterClick() {
    const ACTIVE_STATUS_INDICATOR = ['Accepting Candidates', 'On Hold', 'Covered'];
    if (!this.jobData.allbirds_metadata.front_office_id) {
      this._utility.launchFeatureUnavailableModal('feature-unavailable.JOB_NO_FO_ID');
    } else if (!ACTIVE_STATUS_INDICATOR.includes(this.jobData.allbirds_metadata.order_status)) {
      this._utility.launchFeatureUnavailableModal('feature-unavailable.JOB_UNQUALIFIED');
    } else if (this.jobData.allbirds_metadata.lob.checkLob('RT', 'RE','CR')) {
      this._utility.launchFeatureUnavailableModal('feature-unavailable.JOB_NOT_RD');
    } else {
      this.openAssignRecruiterModal();
    }
  }

  openAssignRecruiterModal() {
    const initialState = {
      allbirds_job_id: this.jobData.allbirds_metadata.allbirds_job_id,
      description: this.jobData.description,
      jobData: this.jobData,
      modalRef: this.modalRef,
      assignRecruiter: () => {
        this.modalRef.hide();
      }
    };
    this.modalRef = this.modalService.show(AssignRecruiterModalComponent, { initialState });
  }

  sendEmailBtnClick() {
    const orderStatus = this.jobData?.allbirds_metadata?.order_status || '';
    const orderType = this.jobData?.allbirds_metadata?.order_type || '';
    if (!this.jobData.allbirds_metadata.front_office_id) {
      this._utility.launchFeatureUnavailableModal('feature-unavailable.JOB_NO_FO_ID');
    } else if (ClosedStatus.includes(this.jobData.allbirds_metadata.order_status) && this.isRt) {
      this._utility.launchFeatureUnavailableModal('feature-unavailable.JOB_CLOSED');
    } else if (!this.isRt
      && ((this.isJobExpired(this.jobData) || (orderStatus === 'Active' || orderStatus === 'Closed') && orderType !== 'Pipeline')
        || (!this.isPublished && orderType === 'Pipeline'))) {
      this._utility.launchFeatureUnavailableModal('feature-unavailable.JOB_CLOSED_ACTIVE_EXPIRED');
    } else {
      this.openSendEmailModal();
    }
  }

  openSendEmailModal() {
    const initialState = {
      allbirds_job_id: this.jobData.allbirds_metadata.allbirds_job_id,
      description: this.jobData.description,
      jobData: this.jobData,
      modalRef: this.modalRef,
      sendEmail: () => {
        this.modalRef.hide();
      }
    };
    this.modalRef = this.modalService.show(SendUserEmailModalComponent, { initialState });
  }

  openUpdateJobStatusModal() {
    const initialState = {
      jobData: this.jobData,
      modalRef: this.modalRef,
      updateJobStatus: () => {
        this.modalRef.hide();
      }
    };
    this.modalRef = this.modalService.show(UpdateJobStatusModalComponent, { initialState });
  }

  manageRolesBtnClick() {
    const orderStatus = this.jobData?.allbirds_metadata?.order_status || '';
    const orderType = this.jobData?.allbirds_metadata?.order_type || '';
    if (!this.jobData.allbirds_metadata.front_office_id) {
      this._utility.launchFeatureUnavailableModal('feature-unavailable.JOB_NO_FO_ID');
    } else if (ClosedStatus.includes(this.jobData.allbirds_metadata.order_status) && this.isRt) {
      this._utility.launchFeatureUnavailableModal('feature-unavailable.JOB_CLOSED');
    } else if (!this.isRt
      && ((this.isJobExpired(this.jobData) || (orderStatus === 'Active' || orderStatus === 'Closed') && orderType !== 'Pipeline')
        || (!this.isPublished && orderType === 'Pipeline'))) {
      this._utility.launchFeatureUnavailableModal('feature-unavailable.JOB_CLOSED_ACTIVE_EXPIRED');
    } else {
      this.openManageRolesModal();
    }
  }

  openManageRolesModal() {
    const initialState = {
      allbirds_job_id: this.jobData.allbirds_metadata.allbirds_job_id,
      description: this.jobData.description,
      jobData: this.jobData,
      modalRef: this.modalRef,
      sendEmail: () => {
        this.modalRef.hide();
      }
    };
    this.modalRef = this.modalService.show(ManageRolesModalComponent, { initialState, class: 'modal-sm' });
  }

  changeJobOwnerClick() {
    if (!this.jobData.allbirds_metadata.front_office_id) {
      this._utility.launchFeatureUnavailableModal('feature-unavailable.JOB_NO_FO_ID');
    } else if (ClosedStatus.includes(this.jobData.allbirds_metadata.order_status)) {
      this._utility.launchFeatureUnavailableModal('feature-unavailable.JOB_CLOSED');
    } else if (this.jobData.allbirds_metadata.lob.checkLob('RT', 'RE','CR')) {
      this._utility.launchFeatureUnavailableModal('feature-unavailable.JOB_NOT_RD');
    } else {
      this.openChangeJobOwnerModal();
    }
  }

  openChangeJobOwnerModal() {
    const initialState = {
      allbirds_job_id: this.jobData.allbirds_metadata.allbirds_job_id,
      description: this.jobData.description,
      jobData: this.jobData,
      modalRef: this.modalRef,
      changeJobOwner: () => {
        this.modalRef.hide();
      }
    };
    this.modalRef = this.modalService.show(ChangeOwnerModalComponent, { initialState });
  }

  closeJobBtnClick() {
    if (!this.jobData.allbirds_metadata.front_office_id) {
      this._utility.launchFeatureUnavailableModal('feature-unavailable.JOB_NO_FO_ID');
    } else if (!this.jobData.allbirds_metadata.lob.checkLob('RT', 'RE','CR')) {
      this._utility.launchFeatureUnavailableModal('feature-unavailable.JOB_NOT_RD');
    } else {
      this.openCloseJobModal();
    }
  }

  openCloseJobModal() {
    const initialState = {
      allbirds_job_id: this.jobData.allbirds_metadata.allbirds_job_id,
      linked_ab_job_id: this.jobData.allbirds_metadata.linked_ab_job_id,
      jobData: this.jobData
    };
    this.modalRef = this.modalService.show(CloseJobModalComponent, { initialState });
  }

  extendJobBtnClick() {
    if (!this.jobData.allbirds_metadata.front_office_id) {
      this._utility.launchFeatureUnavailableModal('feature-unavailable.JOB_NO_FO_ID');
    } else if (!this.jobData.allbirds_metadata.lob.checkLob('RT', 'RE','CR')) {
      this._utility.launchFeatureUnavailableModal('feature-unavailable.JOB_NOT_RD');
    } else if (!this.permissionToExtendJob) {
      this._utility.launchFeatureUnavailableModal('feature-unavailable.USER_NOT_FDRT_OR_RD');
    } else {
      this.openExtendJobModal();
    }
  }

  openExtendJobModal() {
    const initialState = {
      allbirds_job_id: this.jobData.allbirds_metadata.allbirds_job_id,
      jobData: this.jobData
    };
    this.modalRef = this.modalService.show(ExtendJobModalComponent, { initialState });
  }

  checkCreditFlagOnClone(){
    if(!this.isClientOrder) { this.router.navigate(['/jobs/new'], { queryParams: { cloneId: this.jobData.allbirds_metadata.allbirds_job_id }}); return; }
    this.loading.show();
    const contactId = this.jobData.allbirds_metadata.contact_id;
    this.suggest.contact(contactId).subscribe(res => {
      if (res) {
        const contactObj = res.suggests.find(sugg => sugg.ContactID === contactId);
        // Check that the customer status is valid.
        const validStatus = contactObj && contactObj.CUST_STATUS && contactObj.CUST_STATUS !== 'Do Not Service';
        // Check that the credit flag is valid.
        const validCredit = contactObj && typeof contactObj.CREDIT_FLAG === 'string' && (contactObj.CREDIT_FLAG === 'Y' || contactObj.CREDIT_FLAG === '');
        if(!validStatus || !validCredit){
          this.toast.showToast('This job cannot be cloned due to invalid customer', { cssClass: ToastClass.DANGER });
        }
        else {
          this.router.navigate(['/jobs/new'], { queryParams: { cloneId: this.jobData.allbirds_metadata.allbirds_job_id }});
        }
      }
      this.loading.hide();
    }, err => {
      this.toast.showToast('Failed to validate customer for cloning', { cssClass: ToastClass.DANGER })
      this.loading.hide();
      console.log(err);
    });
  }

  isJobExpired(job: Job) {
    const expire_date = job.expirationDate;
    return expire_date ? expire_date.isBefore(moment()) : false;
  }
}
