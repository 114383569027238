<div class="modal-header">
  <button type="button" class="close-btn" aria-label="Close" (click)="bsModalRef.hide()">
    <i class="icon-WL_icon_close"></i>
  </button>
</div>

<div class="modal-body">
  <h2 *ngIf="!updateSubmission" class="mb-4">{{'not-fit-modal.why_is_this' | translate}}</h2>
  <h2 *ngIf="internalSubmission" class="mb-4">{{'not-a-fit-modal.update_submission' | translate}}</h2>
  <h2 *ngIf="clientSubmission" class="mb-4">{{'not-a-fit-modal.update_client_submission' | translate}}</h2>
  <h2 *ngIf="clientInterview" class="mb-4">{{'not-a-fit-modal.update_client_interview' | translate}}</h2>

  <div class="note-form">
    <div class="form-group" *ngIf="showingNotAFitReason">
      <label for="noteType">{{'not-fit-modal.tell_us_why' | translate}}</label>
      <select [(ngModel)]="obj.rejectReason" id="noteType" class="form-control">
        <option [ngValue]="null">{{'not-fit-modal.select_a_reason' | translate}}</option>
        <option *ngFor="let value of menu" [ngValue]="value">{{value}}</option>
      </select>
      <div class="invalid-feedback" *ngIf="showError && !obj.rejectReason">{{'not-fit-modal.reason_is_required' | translate}}</div>
    </div>
    <div *ngIf="updateSubmission" style="float: right;">       
      <h4>Submitted by : {{submittedBy}}</h4>
     </div>
    <div *ngIf="obj.rejectReason == 'Additional Interview Requested' " class="row mt-0">
      <h4 style="padding-left:17px;">{{statusLabel}}</h4>
      <div class="col-lg-5">
        <label for="interviewDate">{{'interview-hiring-manager.date' | translate}}</label>
        <div class="input-group search">
          <input
            [(ngModel)]="interviewDate"
            id="interviewDate"
            type="text"
            placeholder="{{'interview-hiring-manager.date_format' | translate}}"
            [minDate]="minDate.toDate()"
            [bsConfig]="datePickerConfig"
            (bsValueChange)="onDatePicked($event)"
            class="form-control calendar-input"
            bsDatepicker />
          <i class="icon icon-WL_icon_schedule color-primary calendar-icon"></i>
          <div *ngIf="showError && !this.interviewDate" class="invalid-feedback">{{'interview-hiring-manager.interview_date_is' | translate}}</div>
        </div>
      </div>
      <div class="col-3">
        <ng-template #popTemplate>
          <timepicker removeMeridian
            [(ngModel)]="interviewDate"
            [ngModelOptions]="{standalone: true}"
            [showMeridian]="true"
            minuteStep="15">
          </timepicker>
        </ng-template>
        <label for="timePicker">{{'interview-hiring-manager.time' | translate}}</label>
        <div class="input-group search">
          <input
            type="text"
            mdInput
            [readonly]="true"
            id="timePicker"
            [ngModel]="interviewDate | date: 'h:mm' "
            [popover]="popTemplate"
            class="form-control timePicker"
            [outsideClick]="true"
            placement="bottom"
            container="body"  />
          <i class="icon icon-WL_icon_time color-primary"></i>
          <div *ngIf="showErrorDate" class="invalid-feedback">{{'interview-hiring-manager.interview_date_is' | translate}}</div>
        </div>
      </div>
      <div class="col-3 paddingT32">
        <div class="btn-group" btnRadioGroup>
          <label btnRadio="AM" class="btn btn-primary meridianBtn" (click)="changeMeridian(interviewDate,'AM')"
                 [ngClass]="{'selected': (interviewDate | date: 'a') === 'AM'}" tabindex="0" role="button">{{'interview-hiring-manager.am' | translate}}</label>
          <label btnRadio="PM" class="btn btn-primary meridianBtn" (click)="changeMeridian(interviewDate,'PM')"
                 [ngClass]="{'selected': (interviewDate | date: 'a') === 'PM'}" tabindex="0" role="button">{{'interview-hiring-manager.pm' | translate}}</label>
        </div>
      </div>
    </div>
    <div class="form-group">
      <form [formGroup]="hmForm">
        <label *ngIf="updateSubmission" for="submittalFeedback">{{'not-a-fit-modal.feedback' | translate}}</label>
        <app-ckeditor
          id="hmAvailability"
          class="text-box w-100"
          [parentForm]="hmForm"
          formConName="rejectNote"
          height="120"
          [enableMentions]="true">
        </app-ckeditor>
      </form>

        <div class="invalid-feedback" *ngIf="showError && !obj.rejectNote">
          {{'not-fit-modal.note_is_required' | translate}}
        </div>
      </div>
  </div>
</div> <!-- / .modal-body -->

<div class="modal-footer">
  <ul class="hz-btn-list">
    <li>
      <button type="button" class="btn rds--button--primary" aria-label="Cancel" (click)="bsModalRef.hide()">
        {{'not-fit-modal.cancel' | translate}}
      </button>
    </li>
    <li>
      <button type="button" class="btn rds--button--secondary" aria-label="Submit" (click)="onSaveClick()">
        {{'not-fit-modal.apply' | translate}}
      </button>
    </li>
  </ul>
</div>
