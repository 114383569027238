import { Component, Input, OnInit } from '@angular/core';
import { Activity, ActivityFeedTypes } from '../../../../../models/internal/activity.interface';

@Component({
  selector: 'activity-feed-action',
  templateUrl: './activity-feed-action.component.html',
  styleUrls: ['./activity-feed-action.component.scss']
})
export class ActivityFeedActionComponent implements OnInit {

  @Input() activity: Activity;
  @Input() candidateFullName: string;
  ActivityFeedTypes: typeof ActivityFeedTypes = ActivityFeedTypes;

  constructor() { }

  ngOnInit() { }

}
