export const phrases: { [phrase: string]: string[] } = {
  '1-2 years out of college': ['Block', 'Degree (or diploma) required, at least 2 years of college required, entry level, candidate should have no more than 2 years experience in XYZ field'],
  'All-American': ['Block', 'professional appearance, neat, well groomed, personable, possess excellent customer relations skills'],
  'Any recent degree': ['Block', 'Degree (or diploma) required, at least 2 years of college required, entry level'],
  'athletic': ['Warning', 'able to lift 20 - 40 pounds frequently throughout the day'],
  'Athletically inclined': ['Warning', 'able to lift 20 - 40 pounds frequently throughout the day'],
  'Attractive': ['Warning', 'professional appearance, neat, well groomed, personable, possess excellent customer relations skills'],
  'Bilingual': ['Warning', 'bilingual preferred but not required, Spanish a plus but successful candidate need not be bilingual'],
  'Burly': ['Warning', 'able to lift 20 - 40 pounds frequently throughout the day'],
  'Clean-cut': ['Warning', 'professional appearance, neat, well groomed, personable, possess excellent customer relations skills'],
  'college student': ['Block', 'Degree (or diploma) required, at least 2 years of college required, entry level, candidate should have no more than 2 years experience in XYZ field'],
  'convicted felons': ['Block', 'Do not use. There are no suggested alternatives.'],
  'criminal record': ['Block', 'Do not use. There are no suggested alternatives.'],
  'cute': ['Warning', 'professional appearance, neat, well groomed, personable, possess excellent customer relations skills'],
  'disabled': ['Block', 'Do not use. There are no suggested alternatives.'],
  'excellent first job': ['Block', 'Degree (or diploma) required, at least 2 years of college required, entry level, candidate should have no more than 2 years experience in XYZ field'],
  'Family oriented': ['Warning', 'There are no suggested alternatives.'],
  'Family person': ['Warning', 'There are no suggested alternatives.'],
  'felon': ['Block', 'Do not use. There are no suggested alternatives.'],
  'felony': ['Block', 'Do not use. There are no suggested alternatives.'],
  'Foreman': ['Warning', 'supervisor', 'crew, shift or team leader'],
  'Green Card Holder': ['Warning', 'supervisor', 'crew, shift or team leader'],
  'Handsome': ['Warning', 'professional appearance, neat, well groomed, personable, possess excellent customer relations skills'],
  'housewife': ['Block', 'part-time', 'short hours'],
  'Lead man': ['Warning', 'supervisor, crew, shift or team leader'],
  'Mature candidate': ['Warning', 'All inquiries welcome. Individuals of all ages sought. Excellent secondary source of income for retirees, at least 5 years of experience in XYZ field required'],
  'misdemeanor': ['Block', 'Do not use. There are no suggested alternatives'],
  'mother': ['Block', 'part-time', 'short hours'],
  'Permanent Resident': ['Warning', 'Must be legally authorized to work in the U.S. without sponsorship.'],
  'pregnant': ['Block', 'Do not use. There are no suggested alternatives'],
  'Pretty': ['Warning', 'professional appearance, neat, well groomed, personable, possess excellent customer relations skills'],
  'recent college graduate': ['Block', 'Degree (or diploma) required, at least 2 years of college required, entry level, candidate should have no more than 2 years experience in XYZ field'],
  'recent graduate': ['Block', 'Degree (or diploma) required, at least 2 years of college required, entry level, candidate should have no more than 2 years experience in XYZ field'],
  'recent high school graduate': ['Block', 'Degree (or diploma) required, at least 2 years of college required, entry level, candidate should have no more than 2 years experience in XYZ field'],
  'Retired Person': ['Warning', 'All inquiries welcome. Individuals of all ages sought. Excellent secondary source of income for retirees, at least 5 years of experience in XYZ field required'],
  'Spanish speaker': ['Warning', 'bilingual preferred but not required, Spanish a plus but successful candidate need not be bilingual'],
  'Strong': ['Warning', 'able to lift 20 - 40 pounds frequently throughout the day'],
  'student': ['Block', 'Degree (or diploma) required, at least 2 years of college required, entry level, candidate should have no more than 2 years experience in XYZ field'],
  'Supplement your pension': ['Warning', 'All inquiries welcome. Individuals of all ages sought. Excellent secondary source of income for retirees, at least 5 years of experience in XYZ field required'],
  'U.S. Citizen': ['Warning', 'Must be legally authorized to work in the U.S. without sponsorship.'],
  'unemployed need not apply': ['Block', 'Do not use. There are no suggested alternatives'],
  'Women and minorities encouraged to apply': ['Warning', 'equal opportunity employer'],
  'young': ['Block', 'willing to work at entry-level, beginner, trainee'],
  'youthful': ['Block', 'willing to work at entry-level, beginner, trainee'],
  'youthful appearance': ['Block', 'willing to work at entry-level, beginner, trainee'],
  'wells fargo': ['Block', 'Do not use. There are no suggested alternatives.']
};

