<div class="modal-header">
  <button type="button" class="btn-reset" aria-label="Close" (click)="hide()">
    <i class="icon-WL_icon_close"></i>
  </button>
</div>

<div class="modal-body">
  <h2>{{ isLink ? 'Delete web link' : 'Delete File' }}</h2>
  <span class="modalText">Are you sure you want to delete <span class="modalHighlightText"> {{attachment?.name || attachment?.path}}</span>
	</span>
</div>

<div class="modal-footer">
  <ul class="hz-btn-list">
    <li>
      <button type="button" class="btn rds--button--primary" aria-label="cancel" (click)="hide()">
        {{'talent-management-confirm-delete-modal.cancel' | translate}}
      </button>
    </li>
    <li>
      <button type="button" class="btn rds--button--primary" aria-label="confirm" (click)="confirmDelete()">
        {{'talent-management-confirm-delete-modal.yes_delete' | translate}}
      </button>
    </li>
  </ul>
</div>
