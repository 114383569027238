<!-- Resume action bar. -->
<div class="resume-actions row" *ngIf="!(errors && (errors.notFound || errors.generic))">
  <ul class="hz-btn-list">
    <li *ngIf="resumeLastUpdatedDate" class='last-uploaded'>Uploaded on {{resumeLastUpdatedDate | abDate}}</li>
    <li *ngIf="!resumeLastUpdatedDate" class='last-uploaded'>&nbsp;</li>
    <li>
      <button class="btn btn-reset margin-fix" (click)="download(currentTalent)">
        <div *ngIf="showSpinner" class="spinner-border custom-spinner text-primary" role="status">
          <span class="sr-only text-center">{{'talent-resume.loading' | translate}}</span>
        </div>
        <i *ngIf="!showSpinner" class="icon icon-WL_icon_download color-primary"></i>{{'talent-resume.download' | translate}}
      </button>
    </li>
    <li>
      <button class="btn btn-reset" (click)="openModal()">
        <img src="assets/icons/full-screen.svg"  />{{'talent-resume.full_screen' | translate}}
      </button>
    </li>
  </ul>
</div>

<!-- PDF Viewer -->
<div *ngIf="!errors && pdf" class="pdf-container">
  <ng2-pdfjs-viewer #pdfViewer viewerId="pdfViewer" (onDocumentLoad)="pdfLoaded($event)"></ng2-pdfjs-viewer>
</div>

<!-- Loading circle. -->
<div *ngIf="!errors && !pdf" class="w-100 mx-auto text-center mt-5">
  <div class="spinner-border text-primary mx-auto text-center" role="status">
    <span class="sr-only text-center">{{'talent-resume.loading' | translate}}</span>
  </div>
</div>

<!-- Error box. -->
<div class="resume-error" *ngIf="errors">
  <vertical-align>
    <img src="assets/images/resume.png"  />
    <!-- Shown when the server returns a 500 error. -->
    <p *ngIf="errors.fatalError">{{'talent-resume.currently_we_cant' | translate}}</p>
    <!-- Shown when the server returns a 404 error. -->
    <p *ngIf="errors.notFound">{{'talent-resume.no_resume_on' | translate}}</p>
    <!-- Shown for any other errors. -->
    <p *ngIf="errors.generic">{{'talent-resume.currently_we_cant' | translate}}</p>
  </vertical-align>
</div>
