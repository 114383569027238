<!-- Allows us to scroll to top on talent change. -->
<div style="height: 1px;" #scrollTop></div>

<div class="h-100" *ngIf="isLoading && !isError && !isDeletedProfile && !isDisqualifiedProfile">
  <vertical-align>
    <div class="text-center">
      <div class="spinner-border custom-spinner text-primary" role="status">
        <span class="sr-only">{{'talent-detail-pane.loading' | translate}}</span>
      </div>
    </div>
  </vertical-align>
</div>

<div class="pane-error h-100" *ngIf="isError">
  <vertical-align>
    <div class="pane-error text-center">
      <img src="assets/icons/alert-red.svg"/>
      <p>{{'talent-detail-pane.this_profile_is' | translate}}
        <br/>
        {{'talent-detail-pane.please_try_again' | translate}}
      </p>
    </div>
  </vertical-align>
</div>

<div class="pane-error h-100" *ngIf="isDeletedProfile">
  <vertical-align>
    <div class="pane-error text-center">
      <img src="assets/icons/alert-red.svg"/>
      <p>{{'talent-detail-pane.this_profile_is_merged_into_rfo' | translate}}
        <br/>
        Use the following Empl ID: <b>{{mergedProfileId | translate}}</b> to continue with managing this candidate. 
      </p>
    </div>
  </vertical-align>
</div>

<div class="pane-error h-100" *ngIf="isDisqualifiedProfile">
  <vertical-align>
    <div class="pane-error text-center">
      <img src="assets/icons/alert-red.svg"/>
      <p>{{'talent-detail-pane.disqualified' | translate}}
      </p>
    </div>
  </vertical-align>
</div>

<div *ngIf="!isLoading && profile && !profile?.isDeleted && !profile?.isDisqualified" [ngClass]="{'container': !isInSplitView}" class="w-100 overflow-hidden">
  <div class="mt-3">
    <!-- Begin talent pane header -->
    <div class="header-container flex flex-row">
      <h2 class="name" *ngIf="profile.personNames">
        {{ profile.personNames[0].structuredName.givenName | titlecase }}
        {{ profile.personNames[0].structuredName.familyName | titlecase }}
      </h2>
      <span *ngIf="lob.checkLob('RT', 'RE', 'RGS') && !disableActions" (click)="editProfile()" class="edit-icon"> <!--TODO: include || lob.includes('RGS')  for RGS edit -->
        <img class="edit-pencil" src="/assets/icons/edit-dark-blue.svg" alt="edit pencil">
      </span>
      <span class="spacer"></span>
      <div *ngIf="!disableActions" class="button-container flex flex-column">
        <!-- Button icons in top-right corner of the pane. -->
        <ul class="pane-options hz-btn-list">
          <li>
            <div class="btn-group" dropdown #dropdown="bs-dropdown" container="body" [insideClick]="true">
              <div (click)="checkTalentLob()" dropdownToggle id="attachments-dropdown" aria-controls="attachments-dropdown">
                  <img src="assets/icons/paper-clip.svg" class="mr-1" alt="">
                  <span class="mr-1">{{attachments.length || 0}}</span>
                  <span class="btn btn-reset icon-WL_icon_chevron-down" style="padding: inherit;"></span>
              </div>
              <ul *dropdownMenu class="rds-dropdown dropdown-menu dropdown-menu-right"
                  role="menu" aria-labelledby="attachments-dropdown" style="width: 575px; max-height: 40em; overflow-y: auto;">
                  <div class="d-flex">
                    <div [ngClass]="{'disable-attachment-upload': !isEditable }" class="mr-5" style="padding: 2.5%; cursor: pointer;">
                      <img (click)="openAttachmentOptionModal()" src="assets/icons/plus.svg" alt="add attachment">
                      <span (click)="dropdown.hide(); openAttachmentOptionModal()"> Upload new file </span>
                    </div>
                  </div>
                  <div *ngIf="attachments && attachments.length">
                    <app-attachment-list [attachment]="attachment" [index]="i"
                      (deleteAttachment)="deleteAttachment(attachment, i)" *ngFor="let attachment of attachments; let i = index;">
                    </app-attachment-list>
                  </div>
                  <div *ngIf="attachments && !attachments.length">
                    <div class="ml-3">
                      <hr style="margin-top: 0mm;">
                      No Files Added
                    </div>
                  </div>
              </ul>
            </div>
          </li>
          <li *ngIf="showJobInfoButton && jobInfo" class="mr-0">
            <button class="btn-reset color-primary" (click)="openJobInfoModal()">
              {{'talent-detail-pane.job_information' | translate}}<img src="assets/icons/chevron.svg" alt=">"></button>
          </li>
          <li>
            <div class="btn-group" dropdown>
              <button dropdownToggle id="actionDropdown" type="button" class="btn-reset"><img
                src="/assets/icons/more-options-gray.svg" alt="actions"/></button>
              <ul *dropdownMenu class="rds-dropdown dropdown-menu dropdown-menu-right" role="menu"
                  aria-labelledby="actionDropdown">
                <li role="menuitem" class="rds-dropdown-item">
                  <button class="btn-reset" (click)="launchExternalProfile()">
                    <span class="pt-2">{{'talent-detail-pane.view_external_profile' | translate}}</span>
                  </button>
                </li>
                <li role="menuitem" class="rds-dropdown-item">
                  <button class="btn-reset" (click)="openViewDetailsModal()">
                    <span class="pt-2">{{'talent-detail-pane.view_personal_details' | translate}}</span>
                  </button>
                </li>
                <li *ngIf="lob.checkLob('RT', 'RE', 'RGS')" role="menuitem" class="rds-dropdown-item">
                  <button class="btn-reset" (click)="editProfile()">
                    <span class="pt-2">{{'talent-detail-pane.edit_profile' | translate}}</span>
                  </button>
                </li>
              </ul>
            </div>
          </li>
          <li *ngIf="showClose">
            <i class="icon icon-WL_icon_close hover" (click)="emitClose()"></i>
          </li>
        </ul> <!-- /.pane-options -->
        <p class="foTalentStatus" *ngIf="profile.allbirds_metadata && showFOTalentStatus">
          {{profile.allbirds_metadata.FOTalentStatus}}
        </p>
      </div>
    </div>

    <div class="content-container flex flex-row" #content
    [ngStyle]="{'flex-wrap': content.offsetWidth < 602 ? 'wrap' : 'nowrap', 'margin-bottom': content.offsetWidth < 602 ? '1rem' : '0px'}">
      <div [ngClass]="{'flex': !isInSplitView, 'flex-column': !isInSplitView}" class="content-left">
        <p [ngClass]="{'split-view-p': isInSplitView, 'p-tag-space': isInSplitView}" class="phone" *ngIf="profile.phoneNumbers && profile.phoneNumbers[0]">{{ getTalentPhoneNumber() }}</p>
        <p [ngClass]="{'split-view-p': isInSplitView, 'p-tag-space': isInSplitView}" class="mb-0" *ngIf="profile.emailAddresses">
          <a href="mailto:{{ profile?.emailAddresses[0]?.emailAddress }}"
             target="_blank">{{ profile?.emailAddresses[0]?.emailAddress | lowercase }}</a>
        </p>
        <p [ngClass]="{'split-view-p': isInSplitView, 'p-tag-space': isInSplitView}" class="address mb-0" *ngIf="profile.addresses && profile.addresses.length > 0">
          {{ getAddress(profile) }}
        </p>
                <!-- action buttons -->
        <ul *ngIf="content.offsetWidth > 1000 && !disableActions" style="margin-top: 1em !important;" class="talent-action-buttons action-buttons hz-btn-list">
          <li>
            <button [class.disabled]="disabled" [isDisabled]="!disabled"
                    tooltip="You cannot add a note because the talent is not from your line of business."
                    class="btn rds--button--primary log-a-note"
                    (click)="openLogNoteModal()">
              <img src="assets/icons/note.svg" alt=">">
            </button>
          </li>

          <li *ngIf="actions.length">
            <div class="btn-group dropdown" dropdown>
              <button [class.disabled]="disabled" [isDisabled]="!disabled"
                      tooltip="You cannot {{actions[0].label}} because the talent is not from your line of business."
                      class="btn rds--button--secondary add-to-job-btn"
                      (click)="actions[0].onClick()">
                <span class="icon"></span>
                <span>{{actions[0].label}}</span>
              </button>
              <button *ngIf="disabled" type="button"
                      class="btn btn-primary dropdown-toggle dropdown-toggle-split disabled" aria-controls="add-dropdown">
                <span class="sr-only">{{'talent-detail-pane.toggle_dropdown' | translate}}</span>
              </button>
              <button *ngIf="!disabled" type="button" dropdownToggle
                      class="btn btn-primary dropdown-toggle dropdown-toggle-split" aria-controls="add-dropdown">
                <span class="sr-only">{{'talent-detail-pane.toggle_dropdown' | translate}}</span>
              </button>
              <ul id="add-dropdown" *dropdownMenu class="dropdown-menu">
                <li class="dropdown-item" (click)="action.onClick()" *ngFor="let action of actions.slice(1)">
                  {{action.label}}
                </li>
              </ul>
            </div>
          </li>

          <li *ngIf="isListDetailsPage && !checkIfUserIsInRecruiterInterviewBucketList()">
            <button class="btn rds--button--primary" (click)="openRemoveFromListModal()">
              {{'talent-detail-pane.remove_from_list' | translate}}
            </button>
          </li>

          <li *ngIf="!isListDetailsPage && !isShortlistTalent && !isHiddenTalent && !isRejectedTalent">
            <button class="btn rds--button--primary" (click)="openNotAFitModal($event)">
              {{'talent-detail-pane.not_a_fit' | translate}}
            </button>
          </li>
        </ul>
      </div>

      <!-- metrics -->
      <div class="content-right shadow-sm flex">
        <app-talent-metrics [profile]="profile"></app-talent-metrics>
      </div>
    </div>


    <ul *ngIf="content.offsetWidth < 1000 && !disableActions" class="talent-action-buttons action-buttons hz-btn-list">
      <li>
        <button [class.disabled]="disabled" [isDisabled]="!disabled"
                tooltip="You cannot add a note because the talent is not from your line of business."
                class="btn rds--button--primary log-a-note"
                (click)="openLogNoteModal()">
          <img src="assets/icons/note.svg" alt=">">
        </button>
      </li>

      <li *ngIf="actions.length">
        <div class="btn-group dropdown" dropdown>
          <button [class.disabled]="disabled" [isDisabled]="!disabled"
                  tooltip="You cannot {{actions[0].label}} because the talent is not from your line of business."
                  class="btn rds--button--secondary add-to-job-btn"
                  (click)="actions[0].onClick()">
            <span class="icon"></span>
            <span>{{actions[0].label}}</span>
          </button>
          <button *ngIf="disabled" type="button"
                  class="btn btn-primary dropdown-toggle dropdown-toggle-split disabled" aria-controls="add-dropdown">
            <span class="sr-only">{{'talent-detail-pane.toggle_dropdown' | translate}}</span>
          </button>
          <button *ngIf="!disabled" type="button" dropdownToggle
                  class="btn btn-primary dropdown-toggle dropdown-toggle-split" aria-controls="add-dropdown">
            <span class="sr-only">{{'talent-detail-pane.toggle_dropdown' | translate}}</span>
          </button>
          <ul id="add-dropdown" *dropdownMenu class="dropdown-menu">
            <li class="dropdown-item" (click)="action.onClick()" *ngFor="let action of actions.slice(1)">
              {{action.label}}
            </li>
          </ul>
        </div>
      </li>

      <li *ngIf="isListDetailsPage && !checkIfUserIsInRecruiterInterviewBucketList()">
        <button class="btn rds--button--primary" (click)="openRemoveFromListModal()">
          {{'talent-detail-pane.remove_from_list' | translate}}
        </button>
      </li>

      <li *ngIf="!isListDetailsPage && !isShortlistTalent && !isHiddenTalent && !isRejectedTalent">
        <button class="btn rds--button--primary" (click)="openNotAFitModal($event)">
          {{'talent-detail-pane.not_a_fit' | translate}}
        </button>
      </li>
    </ul>

    <ng-container *ngIf="showStatusBanners">
      <div class="alert alert-warning talent-status-alert" *ngIf="isShortlistTalent && !isHiddenTalent && !isRejectedTalent">
        <img src="assets/icons/alert-yellow.svg"/>
        <p class="rejected-text">{{'talent-detail-pane.shortlisted_candidate' | translate}}</p>
      </div>

      <div class="alert alert-warning talent-status-alert" *ngIf="!isShortlistTalent && isHiddenTalent && !isRejectedTalent">
        <img src="assets/icons/alert-yellow.svg"/>
        <p class="rejected-text">{{'talent-detail-pane.prescreen_candidate' | translate}}</p>
      </div>

      <div class="alert alert-warning talent-status-alert" *ngIf="isRejectedTalent">
        <img src="assets/icons/alert-yellow.svg"/>
        <p class="rejected-text">{{'talent-detail-pane.rejected_candidate' | translate}}</p>
      </div>
    </ng-container>

    <!--
      SHOWN WHEN A POTENTIAL TALENT CARD IS CLICKED.

      We repeat ourselves with the tabset because otherwise the "Process" tab will be
      forced into being the last tab instead of the first if the conditional evaluates to true.
    -->
    <tabset class="tabset"
            #tabs3
            [justified]="true"
            *ngIf="!isListDetailsPage && ( (profile && !talent) ||
            (profile && talent && (!talent.isApplication() || !talent.randstad_process.lastProcessStep)))">

      <tab id="resume" (selectTab)="onSelect($event)">
        <ng-template tabHeading>
          <h3 class="text-center">{{'talent-detail-pane.resume' | translate}}</h3>
        </ng-template>
        <ng-container *ngIf="activeTabId=='resume'">
          <app-talent-resume
            (resumeError)="resumeError()"
            [currentTalent]="talent"
            [nextTalent]="nextTalent"
            [previousTalent]="previousTalent">
          </app-talent-resume>
      </ng-container>
      </tab>

      <tab [active]="resumeError" id="profile" (selectTab)="onSelect($event)">
        <ng-template class="text-center" tabHeading>
          <h3 class="text-center">{{'talent-detail-pane.profile' | translate}}</h3>
        </ng-template>
        <ng-container *ngIf="activeTabId=='profile'">
          <app-talent-profile></app-talent-profile>
        </ng-container>
      </tab>

      <tab id="history" (selectTab)="onSelect($event)">
        <ng-template class="text-center" tabHeading>
          <div class="history-tab">
            <h3 class="tab-title">{{'talent-detail-pane.history' | translate}}
              <span *ngIf="showWarning" class="warning-indicator"></span>
              <span *ngIf="showNotification" class="notification-indicator"></span>
            </h3>
          </div>
        </ng-template>
        <ng-container *ngIf="activeTabId=='history'">
          <app-talent-history [talentProfile]="profile" [job]="jobInfo"></app-talent-history>
        </ng-container>
      </tab>
    </tabset>

    <!--
      SHOWN WHEN A SHORTLIST CARD IS CLICKED.

      We repeat ourselves with the tabset because otherwise the "Process" tab will be
      forced into being the last tab instead of the first if the conditional evaluates to true.
    -->
    <tabset class="tabset" #tabs3 [justified]="true"
            *ngIf="talent && talent.isApplication() && talent.randstad_process.lastProcessStep && !isListDetailsPage">
      <tab tab id="process" (selectTab)="onSelect($event)">
        <ng-template tabHeading>
          <h3 class="text-center">{{'talent-detail-pane.process' | translate}}</h3>
        </ng-template>
        <ng-container *ngIf="activeTabId=='process'">
          <app-talent-process [talentProfile]="profile" (onReject)="emitReject($event)" [job]="jobInfo" (refresh)="setupView1()"></app-talent-process>
        </ng-container>
      </tab>

      <tab id="resume2" (selectTab)="onSelect($event)">
        <ng-template tabHeading>
          <h3 class="text-center">{{'talent-detail-pane.resume' | translate}}</h3>
        </ng-template>
        <ng-container *ngIf="activeTabId=='resume2'">
        <app-talent-resume [currentTalent]="talent"
                           [nextTalent]="nextTalent"
                           [previousTalent]="previousTalent">
        </app-talent-resume>
      </ng-container>
      </tab>

      <tab id="profile2" (selectTab)="onSelect($event)">
        <ng-template class="text-center" tabHeading>
          <h3 class="text-center">{{'talent-detail-pane.profile' | translate}}</h3>
        </ng-template>
        <ng-container *ngIf="activeTabId=='profile2'">
        <app-talent-profile></app-talent-profile>
      </ng-container>
      </tab>

      <tab id="history2" (selectTab)="onSelect($event)">
        <ng-template class="text-center" tabHeading>
          <div class="history-tab">
            <h3 class="tab-title">{{'talent-detail-pane.history' | translate}}
              <span *ngIf="showWarning" class="warning-indicator"></span>
              <span *ngIf="showNotification" class="notification-indicator"></span>
            </h3>
          </div>
        </ng-template>
        <ng-container *ngIf="activeTabId=='history2'">
        <app-talent-history [talentProfile]="profile" [job]="jobInfo"></app-talent-history>
      </ng-container>
      </tab>
    </tabset>


    <!--
     LIST DETAILS TABSET.

      We repeat ourselves with the tabset because otherwise the "Last info" tab will be
      forced into being the last tab instead of the first if the conditional evaluates to true.
    -->
    <tabset class="tabset" #tabs3 [justified]="true" *ngIf="isListDetailsPage">
      <tab id="list_info" (selectTab)="onSelect($event)">
        <ng-template tabHeading>
          <h3 class="text-center">{{'talent-detail-pane.list_info' | translate}}</h3>
        </ng-template>
        <ng-container *ngIf="activeTabId=='list_info'">
          <list-info></list-info>
        </ng-container>
      </tab>

      <tab id="resume3" (selectTab)="onSelect($event)">
        <ng-template tabHeading>
          <h3 class="text-center">{{'talent-detail-pane.resume' | translate}}</h3>
        </ng-template>
        <ng-container *ngIf="activeTabId=='resume3'">
        <app-talent-resume
          [currentTalent]="talent"
          [nextTalent]="nextTalent"
          [previousTalent]="previousTalent">
        </app-talent-resume>
      </ng-container>
      </tab>

      <tab id="profile3" (selectTab)="onSelect($event)">
        <ng-template class="text-center" tabHeading>
          <h3 class="text-center">{{'talent-detail-pane.profile' | translate}}</h3>
        </ng-template>
        <ng-container *ngIf="activeTabId=='profile3'">
          <app-talent-profile></app-talent-profile>
        </ng-container>
      </tab>

      <tab id="history3" (selectTab)="onSelect($event)">
        <ng-template class="text-center" tabHeading>
          <div class="history-tab">
              <h3 class="text-center">{{'talent-detail-pane.history' | translate}}
              <span *ngIf="showWarning" class="warning-indicator"></span>
              <span *ngIf="showNotification" class="notification-indicator"></span>
            </h3>
          </div>
        </ng-template>
        <ng-container *ngIf="activeTabId=='history3'">
        <app-talent-history [talentProfile]="profile" [job]="jobInfo"></app-talent-history>
      </ng-container>
      </tab>
    </tabset>

    <div *ngIf="!profile" class="w-100 mt-5 mx-auto text-center">
      <div class="spinner-border text-primary mx-auto text-center" role="status">
        <span class="sr-only text-center">{{'talent-detail-pane.loading' | translate}}</span>
      </div>
    </div>
  </div>
</div>
