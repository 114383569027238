<!--
  Activity Detail Modal has two different views, which both get rendered within div.modal-body.
    (1) The activityFeedView displays the activity feed similar to that of the talent process tab.
    (2) The activityDetailView drills down into an individual activity to display more detailed information.
  Both views are defined within this HTML file and are denoted by <ng-template> tags.
-->

<div class="modal-header">
  <div class="row">
    <div class="col-6">
      <button *ngIf="showReturnBtn" type="button" class="btn-reset" (click)="showActivityFeed()" id="returnBtn">
        <img src="assets/icons/chevron-left.svg" alt="<"  />{{'activity-detail-modal.return' | translate}}
      </button>
    </div>
    <div class="col-6 pr-0 text-right">
      <button type="button" class="btn-reset" aria-label="Close" (click)="_bsModalRef.hide()">
        <i class="icon-WL_icon_close"></i>
      </button>
    </div>
  </div>
</div>

<div class="modal-body" [ngTemplateOutlet]="shownTemplate"></div>

<div class="modal-footer">
  <ul class="hz-btn-list text-right">
    <li>
      <button type="button" class="btn rds--button--primary" aria-label="Close" (click)="_bsModalRef.hide()">
        {{'activity-detail-modal.close' | translate}}
      </button>
    </li>
  </ul>
</div>

<!-- shownTemplate === activityFeedView -->
<ng-template #activityFeed>
  <div class="template-body">
    <h3 class="text-primary mb-4">{{'activity-detail-modal.activity' | translate}}</h3>
    <!-- Shown if the activity detail modal was given a job. -->
    <div class="job-details" *ngIf="job">
      <a [href]="'jobs/' + job.allbirds_metadata.allbirds_job_id" target="_blank">
        {{ job.title }}
        <img src="assets/icons/external.svg" alt="(click to view)"  />
      </a>
      <p>
        {{ job.allbirds_metadata.order_type !== 'Pipeline' ? job.allbirds_metadata.customer_name : '' }}
        <span class="circle-with-text">P{{ job.allbirds_metadata.customer_priority }}</span>
      </p>
    </div>

    <ng-container *ngIf="talentProcess?.randstad_process">
      <!-- Shown when the talent has no activity (no last process step) -->
      <p *ngIf="!talentProcess?.randstad_process && !talentProcess?.randstad_process.lastProcessStep">
        {{'activity-detail-modal.no_activity_has' | translate}}<span class="text-primary">{{ candidateFullName }}'s</span>{{'activity-detail-modal.application_yet' | translate}}
      </p>
      <!-- Activity feed -->
      <app-activity-feed [talentProcess]="talentProcess" [candidateFullName]="candidateFullName" [job]="job" (activityDetailClick)="showActivityDetail($event)"></app-activity-feed>
    </ng-container>

  </div>
</ng-template>


<!-- shownTemplate === activityDetailView -->
<ng-template #activityDetail>
  <div class="template-body">
    <h3 class="text-primary m-0">{{'activity-detail-modal.activity_detail' | translate}}</h3>

    <div class="activity-modal-section">
      <div class="row no-gutters">
        <div class="col-auto">
          <vertical-align>
            <img *ngIf="activity && activity.author" class="activity-icon" [src]="activity?.author?.avatar" [alt]="activity?.author?.name"  />
            <img *ngIf="activity && !activity.author" class="activity-icon" src="assets/icons/notification.svg"  />
          </vertical-align>
        </div>
        <div class="col pl-3">
          <vertical-align>
            <!-- If typeof activity === Activity, use the component. -->
            <activity-feed-action *ngIf="isOfTypeActivity(activity)" [activity]="activity" [candidateFullName]="candidateFullName"></activity-feed-action>
            <!-- Else, its a manually logged note. -->
            <span *ngIf="activity &&!isOfTypeActivity(activity) && activity.author">
              <span *ngIf="activity.author" class="color-primary" [popover]="activity.author.title" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">
                {{ activity.author.name }}
              </span>
              &nbsp;&nbsp;{{'activity-detail-modal.manually_logged_a' | translate}}
            </span>
          </vertical-align>
        </div>
      </div>
    </div>

    <div class="activity-modal-section activity-metadata">
      <ul>
        <!-- Only shown for logged notes. -->
        <ng-container *ngIf="activity && !isOfTypeActivity(activity) && activity?.activityType">
          <li>
            <span class="label">{{'activity-detail-modal.note_type' | translate}}</span>
            <span class="value">{{ noteTypes[activity?.activityType] || activity?.activityType }}</span>
          </li>
        </ng-container>

        <li *ngIf="activity && activity.author">
          <span class="label">{{'activity-detail-modal.action_taken_by' | translate}}:</span>
          <span class="value">{{ activity.author.name }}</span>
        </li>
        <li *ngIf="activity && (activity.date || activity.activityTimestamp || activity.timestamp)">
          <span class="label">{{'activity-detail-modal.date_and_time' | translate}}:</span>
          <span class="value">{{ (activity.date || activity.activityTimestamp || activity.timestamp) | abDatetime }}</span>
        </li>
        <li *ngIf="activity?.type === 12 && payRate">
          <span class="label">Pay rate:</span>
          <span class="value">{{payRate | currency}} {{payType}}</span>
        </li>
        <li *ngIf="activity?.type === 12 && source">
          <span class="label">Submission source:</span>
          <span class="value">{{ source }}</span>
        </li>
        <li *ngIf="activity && activity.notes">
          <span class="label">{{'activity-detail-modal.notes' | translate}}:</span>
          <span class="value"  [innerHTML]="activity.notes | safeHtml"></span>
        </li>
        <ng-container *ngIf="[12, 13, 14, 15].includes(activity?.type) && attachments?.length > 0">
          <div class="attachments">
            <span class="label">{{'review-account-manager.attachment' | translate}}:</span>
            <div class="row">
              <app-attachment-card-internal *ngFor="let attachment of attachments" [hideDelete]="true" cardType="small" [attachment]="attachment"></app-attachment-card-internal>
            </div>
          </div>
        </ng-container>
      </ul>
    </div>

    <!-- Shown under metadata for activities of type Activity. -->
    <div class="activity-modal-section activity-details" *ngIf="activity && isOfTypeActivity(activity)">

      <ng-container [ngSwitch]="activity?.type">
        <manual-prescreening-results *ngSwitchCase="ActivityFeedTypes.ManualScreeningCompleted" [application]="talentProcess"></manual-prescreening-results>
        <recruiter-interview-manual-questions *ngSwitchCase="ActivityFeedTypes.RecruiterInterviewPerformed" [application]="talentProcess"></recruiter-interview-manual-questions>
        <prescreening-questions *ngSwitchCase="ActivityFeedTypes.CEScreeningCompleted"  [conversationID]="conversationID"></prescreening-questions>
        <prescreening-questions *ngSwitchCase="ActivityFeedTypes.RecruiterInterviewPerformed" [conversationID]="conversationID"></prescreening-questions>
        <ng-container *ngSwitchCase="ActivityFeedTypes.ClientInterviewScheduled">
          <div class="activity-metadata">
            <ul>
              <li>
                <span class="label">{{'activity-detail-modal.interview_date' | translate}}</span>
                <span class="value">{{ talentProcess?.randstad_process?.clientInterview?.interviewDate | abDatetime }}</span>
              </li>
            </ul>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="ActivityFeedTypes.OfferRejected">
          <div class="activity-metadata" *ngIf="activity && activity.details">
            <ul>
              <li *ngIf="activity.details.rejectReason">
                <span class="label">{{'activity-detail-modal.reject_reason' | translate}}</span>
                <span class="value">{{ activity.details.rejectReason | stringclean: ['rfoOfferRejectCode'] }}</span>
              </li>
              <li *ngIf="activity.details.contactMethod">
                <span class="label">{{'activity-detail-modal.contact_method' | translate}}</span>
                <span class="value">{{ activity.details.contactMethod | stringclean: ['rfoContactMethod'] }}</span>
              </li>
              <li *ngIf="activity.details.offeredPayRate">
                <span class="label">{{'activity-detail-modal.offered_pay_rate' | translate}}</span>
                <span class="value">{{ activity.details.offeredPayRate | currency: 'USD' }}</span>
              </li>
              <li *ngIf="activity.details.workPreviouslyPerformed !== null">
                <span class="label">{{'activity-detail-modal.work_previously_performed?' | translate}}</span>
                <span class="value">{{ activity.details.workPreviouslyPerformed | boolstring }}</span>
              </li>
            </ul>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="ActivityFeedTypes.OfferRejected">
          <div class="activity-metadata" *ngIf="activity && activity.details">
            <ul>
              <li *ngIf="activity.details.rejectReason">
                <span class="label">{{'activity-detail-modal.reject_reason' | translate}}</span>
                <span class="value">{{ activity.details.rejectReason | stringclean: ['rfoOfferRejectCode'] }}</span>
              </li>
              <li *ngIf="activity.details.contactMethod">
                <span class="label">{{'activity-detail-modal.contact_method' | translate}}</span>
                <span class="value">{{ activity.details.contactMethod | stringclean: ['rfoContactMethod'] }}</span>
              </li>
              <li *ngIf="activity.details.offeredPayRate">
                <span class="label">{{'activity-detail-modal.offered_pay_rate' | translate}}</span>
                <span class="value">{{ activity.details.offeredPayRate | currency: 'USD' }}</span>
              </li>
              <li *ngIf="activity.details.workPreviouslyPerformed !== null">
                <span class="label">{{'activity-detail-modal.work_previously_performed?' | translate}}</span>
                <span class="value">{{ activity.details.workPreviouslyPerformed | boolstring }}</span>
              </li>
            </ul>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <!-- Shown under metadata for logged notes. -->
    <div class="activity-modal-section" *ngIf="activity && activity.comments">
      <div class="activity-modal--html" [innerHtml]="activity.comments | safeHtml"></div>
    </div>
  </div>
</ng-template>
