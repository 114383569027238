<div class="circle-container" *ngIf="user">
  <vertical-align>
    <img *ngIf="user?.avatar"
         [src]="user?.avatar"
         [alt]="initials"
         [popover]="userHoverPopover"
         [adaptivePosition]="adaptivePosition || popoverPlacement !== 'bottom'"
         [placement]="popoverPlacement"
         containerClass="rds-tooltip--dark tooltip--md"
         triggers="mouseenter:mouseleave"
         data-container="{{ useBodyAsContainer ? 'body' : '.circle-container' }}"
    />
    <p *ngIf="!user?.avatar"
       class="silver-circle"
       [popover]="userHoverPopover"
       [adaptivePosition]="adaptivePosition || popoverPlacement !== 'bottom'"
       [placement]="popoverPlacement"
       containerClass="rds-tooltip--dark tooltip--md"
       triggers="mouseenter:mouseleave"
       data-container="{{ useBodyAsContainer ? 'body' : '.circle-container' }}"
    >
      <vertical-align>
        <span>{{ initials }}</span>
      </vertical-align>
    </p>
  </vertical-align>
</div>

<div class="circle-container" *ngIf="!user && usersNumber">
  <vertical-align>
    <p *ngIf="showPopover" class="silver-circle"
       [popover]="usersHoverPopover"
       [adaptivePosition]="adaptivePosition || popoverPlacement !== 'bottom'"
       [placement]="popoverPlacement"
       containerClass="rds-tooltip--dark tooltip--sm"
       triggers="mouseenter:mouseleave"
       data-container="{{ useBodyAsContainer ? 'body' : '.circle-container' }}"
    >
      <vertical-align>
        <span>+ {{ usersNumber }}</span>
      </vertical-align>
    </p>
    <p *ngIf="!showPopover" class="silver-circle">
      <vertical-align>
        <span>+ {{ usersNumber }}</span>
      </vertical-align>
    </p>
  </vertical-align>
</div>

<!--
  Using .trim() in the check because the values for some
  of the fields of the User collection for certain users
  are literally a single blank space..   -_-'
-->
<ng-template #userHoverPopover>
  <table class="user-details">
    <tbody>
    <tr *ngIf="user.FullName && user.FullName.trim()">
      <td>{{'user-circle.name' | translate}}</td>
      <td>{{ user.FullName }}</td>
    </tr>
    <tr *ngIf="user.Roles && user.Roles.length">
      <td>{{'user-circle.role' | translate}}</td>
      <td>{{ user.Roles.join(', ') }}</td>
    </tr>
    <tr *ngIf="user.PrimaryPhone && user.PrimaryPhone.trim()">
      <td>{{'user-circle.primary_phone' | translate}}</td>
      <td>{{ user.PrimaryPhone }}</td>
    </tr>
    <tr *ngIf="user.EmailAddr && user.EmailAddr.trim()">
      <td>{{'user-circle.email' | translate}}</td>
      <td>{{ user.EmailAddr | lowercase }}</td>
    </tr>
    <tr *ngIf="user.Source && user.Source.trim()">
      <td>{{'user-circle.branch' | translate}}</td>
      <td>{{ user.Source }}</td>
    </tr>
    </tbody>
  </table>
</ng-template>

<ng-template #usersHoverPopover>
  <span>View all users</span>
</ng-template>
