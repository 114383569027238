export const SINGLE_OPTION_MENU = [
  'Candidate Lead Rejected'
];

export const OFFER_OPTION_MENU = [
  'Offer Rejected'
];

export const MULTI_OPTION_MENU = [
  'Resume Reformat',
  'More Info Needed-Candidate',
  'More Info Needed-Client',
  'Max Resumes Received',
  'No 3rd Parties',
  'Candidate Already Presented',
  'Candidate Not Available',
  'Candidate Not Local',
  'Candidate $ Too High',
  'Candidate Not a Fit',
  'Backup Candidate',
  'Client Rejected',
];

export const UPDATE_OPTION_MENU = [
  'Backup Candidate',
  'Resume Reformat',
  'More Info Needed-Candidate',
  'Candidate Not Local',
  'Candidate $ Too High',
  'Max Resumes Received',
  'No 3rd Parties',
  'Candidate Already Presented',
  'Candidate Not Available',
];

export const UPDATE_OPTION_MENU_HM = [
  'Backup Candidate',
  'Resume Reformat',
  'More Info Needed-Candidate',
  'More Info Needed-Client',
  'Candidate Not Local',
  'Candidate $ Too High',
  'Client Shortlisted',
  'Max Resumes Received',
  'No 3rd Parties',
  'Candidate Already Presented',
  'Candidate Not Available',
];

export const UPDATE_OPTION_MENU_IH = [
  'Backup Candidate',
  'Resume Reformat',
  'More Info Needed-Candidate',
  'More Info Needed-Client',
  'Candidate Not Local',
  'Candidate $ Too High',
  'Client Shortlisted',
  'Max Resumes Received',
  'No 3rd Parties',
  'Candidate Already Presented',
  'Candidate Not Available',
  'Additional Interview Requested'
];

export const NOT_FIT_OPTION_MENU = [
  'Candidate Not a Fit',
  'Client Rejected',
];