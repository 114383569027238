import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { RecruiterHierarchy } from '../../models/internal/recruiter-hierarchy.model';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import Scope = RecruiterHierarchy.Scope;
import FilterOption = RecruiterHierarchy.FilterOption;
import SuggestType = RecruiterHierarchy.SuggestType;
import Recruiter = RecruiterHierarchy.Recruiter;
import { RequestBody } from '../../models/api/application-request.interface';
import GetTalentMetricsRequest = RequestBody.GetTalentMetricsRequest;
import { ResponseBody } from '../../models/api/application-response.interface';
import GetTalentMetricsResponse = ResponseBody.GetTalentMetricsResponse;
import { User } from '../../models/external/user.model';


@Injectable({
  providedIn: 'root'
})
export class TalentDashboardService {

  // Recruiter details used for scope (my/other branch, unit, talent)
  private currentRecruiter: Recruiter;

  // Currently selected "my branch, my unit".
  private _activeRecruiterFilter: FilterOption;

  // Used to emit changes in the active recruiter filter.
  private activeScopeSubject = new BehaviorSubject<Scope>(null);
  public activeScopeChanges = this.activeScopeSubject.asObservable();

  constructor(
    private _api: ApiService,
    private _auth: AuthService,
    private _translate: TranslateService
  ) {
    this.initialize();
  }

  /**
   * Fetches the recruiter-hierarchy document for the current user and applies the result
   * to the currentRecruiter class property for later use.
   */
  initialize(): void {
    if (this._auth && this._auth.user) {
      const backOfficeID = this._auth.user.BackOfficeID;
      this._api.getRecruiterHierarchy({
        operator: 'AND',
        ...(backOfficeID && { backOfficeIds: [backOfficeID] }),
      }).subscribe((res: RecruiterHierarchy.GetResponse) => {
        const recruiter = (res && res.recruiters && res.recruiters.length) ? res.recruiters[0] : {};
        // Set currentRecruiter property using user from Mongo & details from RecruiterHierarchy (if needed).
        this.setCurrentRecruiter(this._auth.user, recruiter);
        // Set the default scope if there isn't one.
        const scope = this.activeScopeSubject.getValue();
        if (!this.activeRecruiterFilter || !scope) {
          this.activeRecruiterFilter = (this.activeRecruiterFilter || this.recruiterHierarchyFilterOptions[0]);
        }
      });
    }
  }

  /**
   * Returns the recruiter-hierarchy filter options to display on the UI.
   */
  get recruiterHierarchyFilterOptions(): RecruiterHierarchy.FilterOption[] {
    return [
      {
        label: this._translate.instant('recruiter-hierarchy.my_talent'),
        type: RecruiterHierarchy.SuggestType.MY_TALENT
      },
      {
        label: this._translate.instant('recruiter-hierarchy.my_team'),
        type: RecruiterHierarchy.SuggestType.MY_TEAM
      },
      {
        label: this._translate.instant('recruiter-hierarchy.my_branch'),
        type: RecruiterHierarchy.SuggestType.MY_BRANCH
      },
      {
        label: this._translate.instant('recruiter-hierarchy.my_unit'),
        type: RecruiterHierarchy.SuggestType.MY_UNIT
      },
      {
        label: this._translate.instant('recruiter-hierarchy.other_users'),
        type: RecruiterHierarchy.SuggestType.RECRUITER
      },
      {
        label: this._translate.instant('recruiter-hierarchy.other_branch'),
        type: RecruiterHierarchy.SuggestType.BRANCH
      },
      {
        label: this._translate.instant('recruiter-hierarchy.other_unit'),
        type: RecruiterHierarchy.SuggestType.UNIT
      },
    ];
  }

  get activeRecruiterFilter (): FilterOption {
    return this._activeRecruiterFilter || null;
  }

  set activeRecruiterFilter (filter: FilterOption) {
    this._activeRecruiterFilter = filter;
    this.updateScopeByFilter(filter);
  }

  /**
   * Called after setting a new active recruiter filter; converts the filter into a Scope object
   * and updates the activeScope subject.
   * @param filter
   */
  private async updateScopeByFilter(filter: FilterOption) {
    const scope = await this.convertRecruiterFilterToScope(filter, this.currentRecruiter);
    this.activeScopeSubject.next(scope);
  }

  /**
   * Given a RecruiterHierarchy.FilterOption the current recruiter (logged-in user's recruiter-hierarchy document),
   * reduces the filter/recruiter combo into a "Scope" for use with the POST /aggregate/metrics/talent call.
   * @param filter
   * @param recruiter
   */
  async convertRecruiterFilterToScope(filter: FilterOption, recruiter: Recruiter) {
    const scope: Scope = {};
    switch (filter.type) {
      case SuggestType.BRANCH:
        scope.branches = filter.selected.map(f => !!f.businessUnit && f.businessUnit);
        break;
      case SuggestType.UNIT:
        scope.units = filter.selected.map(f => f.subUnit);
        break;
      case SuggestType.MY_TEAM:
        scope.recruiters = [
          {
            backOfficeID: recruiter.backOfficeID || '',
            frontOfficeID: recruiter.frontOfficeID || '',
            email: recruiter.email || ''
          },
          ...filter.selected.map(f => ({
            ...(f.backOfficeID && { backOfficeID: f.backOfficeID }),
            ...(f.frontOfficeID && { frontOfficeID: f.frontOfficeID }),
            ...(f.email && { email: f.email }),
          }))
        ];
        break;
      case SuggestType.RECRUITER:
        scope.recruiters = filter.selected.map(f => ({
          ...(f.backOfficeID && {backOfficeID: f.backOfficeID}),
          ...(f.frontOfficeID && {frontOfficeID: f.frontOfficeID}),
          ...(f.email && {email: f.email}),
        }));
        break;
      case SuggestType.MY_BRANCH:
        scope.branches = [recruiter.businessUnit];
        break;
      case SuggestType.MY_UNIT:
        scope.units = [recruiter.subUnit];
        break;
      case SuggestType.MY_TALENT:
        scope.recruiters = [{
          backOfficeID: recruiter.backOfficeID || '',
          frontOfficeID: recruiter.frontOfficeID || '',
          email: recruiter.email || '',
        }];
        break;
    }
    return scope;
  }

  getDashboardTalent (body: GetTalentMetricsRequest): Observable<GetTalentMetricsResponse> {
    // Add the current scope to the body.
    return this._api.getTalentMetricsNew(body);
  }

  /**
   * Given a user object from Mongo and a recruiter from the RecruiterHierarchy
   * index, sets the recruiter whose details we'll use for scope changes.
   * @param user - user object from Mongo
   * @param recruiter - recruiter from RecruiterHierarchy index
   */
  setCurrentRecruiter (user: User, recruiter: Recruiter): void {
    if (user && recruiter) {
      const backOfficeID = user.BackOfficeID || recruiter.backOfficeID || '';
      const frontOfficeID = user.Oprid || recruiter.frontOfficeID || '';
      const email = user.EmailAddr || recruiter.email || '';
      const businessUnit = user.BusinessUnit || recruiter.businessUnit || '';
      const subUnit = (user.Unit && user.Unit.trim()) || recruiter.subUnit || '';
      const recruitingDirector_rollup_Email = recruiter.recruitingDirector_rollup_Email || [];
      const recruitingDirectorEmail = recruiter.recruitingDirectorEmail || '';
      this.currentRecruiter = { backOfficeID, frontOfficeID, email, businessUnit, subUnit, recruitingDirectorEmail, recruitingDirector_rollup_Email };
    }
  }
}
