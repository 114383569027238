<section class="shared-with-section">
    <div *ngFor="let user of recruiterList" class="recruiter-row-container">
        <div class="recruiter-row">
            <div class="circle-name">
                <app-user-circle [user]="user" ></app-user-circle>
                <p>{{user.FullName}}</p>
            </div>
            <img class="trash-icon" src="../../../../assets/icons/trash.svg" (click)="emitRemovedUserID(user)">
        </div>
        <div class="separator"></div>
    </div>
</section>