import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FileUploadModalComponent } from '../../../../file-upload-modal/file-upload-modal.component';
import { LogFeedbackModalComponent } from '../../talent-process-modals/log-feedback-modal/log-feedback-modal.component';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { TalentProcessService } from 'src/app/shared/services/talent-process/talent-process.service';
import { ToastClass, ToastService } from 'src/app/shared/services/toast';
import { CacheService } from 'src/app/shared/services/cache/cache.service';
import { MetricsService } from 'src/app/shared/services/metrics/metrics.service';
import { JobOrderService } from 'src/app/shared/services/job-order/job-order.service';
import { Application } from 'src/app/shared/models/external/application.model';
import { ModalService } from '../../../../../services/modal/modal.service';
import { ProcessStatus, ProcessStep } from 'src/app/shared/models/external/misc.model';
import moment from 'moment';
import { ClientSubmission } from 'src/app/shared/models/external/process/client-submission.model';
import { Job } from 'src/app/shared/models/external/job.model';
import { INTG_STEPS } from 'src/app/shared/models/internal/process.model';
import { SubmitToClientModalComponent } from '../../talent-process-modals/submit-to-client-modal/submit-to-client-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SubmitToClientModalConfig } from '../../talent-process-modals/submit-to-client-modal/submit-to-client-modal.config';
import { ApiService } from '../../../../../services/api/api.service';
import { UtilityService } from '../../../../../services/utility/utility.service';
import { NotificationData, NotificationTypes } from '../../../../../models/internal/notifications-data.model';
import { NotificationsService } from '../../../../../services/notifications/notifications.service';
import { LoadingSpinnerService } from '../../../../../services/loading-spinner/loading-spinner.service';
import { RecruitmentPhasesService } from '../recruitment-phases.service'
import { GoogleProfile } from 'src/app/shared/models/external/google-profile.model';
import { DupTalentModalComponent } from 'src/app/shared/components/dup-talent-modal/dup-talent-modal.component';
import { Profile } from 'src/app/shared/models/external/profile.model';
import { ShortlistActionResponse } from 'src/app/shared/models/internal/shortlist-action-response.interface';
import { JobDetailsShortlistService } from 'src/app/shared/services/job-details-shortlist/job-details-shortlist.service';


@Component({
  selector: 'review-hiring-manager-phase',
  templateUrl: './review-hiring-manager.component.html',
  styleUrls: [
    './review-hiring-manager.component.scss',
    '../recruitment-phases.component.scss'
  ],
})
export class ReviewHiringManagerComponent implements OnInit, OnDestroy, AfterViewChecked {

  // Determines which template to show.
  shownPhase: TemplateRef<any>;
  previousShownPhase: TemplateRef<any>;

  //Determines if the caution modal for interviewed candidates
  showingCautionModal: boolean = false

  // Selected application.
  applicationSub: Subscription;
  application: Application;
  statusLabel: string;
  originalSubmissionIndex: number;


  // Form input
  submissionForm: FormGroup;

  // Resume information.
  hasResume = false;
  resumeURL = '';
  resumeFilename = '';

  // Files sent through internal submission
  attachments: any[];

  @Input() job: Job;
  @Input() talentInterviewStatus: boolean;

  // Emits an event that triggers the markAsUnfit() call in recruitment-phases component.
  @Output() unfitClick = new EventEmitter();

  modalRef: BsModalRef;
  bsmodalRef: BsModalRef;

  // The different templates for the different phases.
  @ViewChild('prepareSubmission', { static: true }) prepareSubmissionView: TemplateRef<any>;
  @ViewChild('submittedRecruiterView', { static: true }) submittedRecruiterView: TemplateRef<any>;
  @ViewChild('submittedView', { static: true }) submittedView: TemplateRef<any>;
  @ViewChild('submittedViewUpdate', { static: true }) submittedViewUpdate: TemplateRef<any>;
  @ViewChild('accountManagerAcceptedView', { static: true }) accountManagerAcceptedView: TemplateRef<any>;
  @ViewChild('ctaContainer', { static: false }) ctaContainer: ElementRef;
  @ViewChild('ctaContainerBoundry', { static: false }) ctaContainerBoundry: ElementRef;
  searchResults: { total: number; };
  skippedApplication: Application;

  get submissionAttachmentURIs() {
    return this.application?.randstad_process?.clientSubmission?.submissionAttachmentURI?.split(',');
  }

  constructor(
    private _authService: AuthService,
    private _talentProcess: TalentProcessService,
    private _bsModalService: ModalService,
    private _formBuilder: FormBuilder,
    private _toast: ToastService,
    private _cache: CacheService,
    private zone: NgZone,
    private _shortlist: JobDetailsShortlistService,
    private _metrics: MetricsService,
    private _jobOrder: JobOrderService,
    private _api: ApiService,
    private _notification: NotificationsService,
    private _loading: LoadingSpinnerService,
    private _utility: UtilityService,
    private _recruitmentPhasesService: RecruitmentPhasesService,
  ) { }

  ngOnInit() {
    this.createForm();
    this.listenToApplication();
  }

  ngOnDestroy() {
    this.applicationSub.unsubscribe();
  }


  ngAfterViewChecked() {
    if (this.previousShownPhase !== this.shownPhase) {
      this.previousShownPhase = this.shownPhase;
      if (this.ctaContainer && this.ctaContainer.nativeElement) {
        const elements = [
          this.ctaContainer.nativeElement,
          this.ctaContainerBoundry.nativeElement
        ];
        this._talentProcess.setupIntersectionObserver(elements);
      }
    }
  }

  createForm() {
    this.submissionForm = this._formBuilder.group({
      submission: [null, [Validators.required]]
    });
  }

  listenToApplication() {
    this.applicationSub = this._talentProcess.selectedApplication
      .subscribe((app: any) => {
        if (app && app.randstad_process) {
          this.application = app;
          this.setSubmissionText();
          this.setResumeLink();
          this.statusLabel = this._talentProcess.getLabel(app, this._authService.user.Source, 'process', this._jobOrder.storedJobOrder);

          const { clientSubmission } = app.randstad_process;

          this.attachments = app.randstad_process?.attachments
          console.log('attachments in randstadProcess: ', app.randstad_process.attachments);
          this.attachments = this._utility.extractAttachments(app);
          console.log('attachements after extraction: ', this.attachments);
          if (this.job.employmentTypes.toString() == 'SOLUTIONS') {
            this.setViewForSolutionsJob(clientSubmission);
          }
          else {

            if (clientSubmission && clientSubmission.outcome) {
              this.setViewByStatus(clientSubmission.outcome);
            } else {
              if (this._authService.user?.Permissions?.submitCandidateToHiringManager) {
                this.shownPhase = this.prepareSubmissionView;
              } else {
                this.shownPhase = this.accountManagerAcceptedView;
              }
            }
          }
        }
      });
  }

  setSubmissionText() {
    const submission = this.application && this.application.randstad_process && this.application.randstad_process.internalSubmission
      ? this.application.randstad_process.internalSubmission.submission
      : null;
    this.submissionForm.patchValue({ submission });
  }
  get userLob(): string { return this._authService.user && this._authService.user.Source; }

  setResumeLink() {
    if (
      this.application &&
      this.application.randstad_process &&
      this.application.randstad_process.clientSubmission &&
      this.application.randstad_process.clientSubmission.submissionAttachmentURI &&
      this.application.randstad_process.clientSubmission.submissionAttachmentURI.length
    ) {
      this.hasResume = true;
      this.resumeURL = this.application.randstad_process.clientSubmission.submissionAttachmentURI;
      this.resumeFilename = UtilityService.parseFilename(this.resumeURL);
    }
  }
  /**
   * Only change for solution view is that the permissions are same for both recuiter and RT full desk
   * The recuiter is defined by submitCandidateToAccountManager and full desk is submitCandidateToHiringManager
   * @param clientSubmission
   */
  setViewForSolutionsJob(clientSubmission: any) {
    const { Permissions } = this._authService.user;
    if (clientSubmission && clientSubmission.outcome) {
      if (Permissions?.submitCandidateToHiringManager || Permissions?.submitCandidateToAccountManager) {
        switch (clientSubmission.outcome) {
          case 'submitted':
            this.shownPhase = this.submittedView;
            if (this.application?.randstad_process?.clientSubmission?.priorSubmissions?.length > 0 && this.application?.randstad_process?.lastProcessStatus === 'RH_UPDATE_SUB') {
              this.shownPhase = this.submittedViewUpdate;
              this.originalSubmissionIndex = this.application.randstad_process.clientSubmission.priorSubmissions.length - 1;
            }
            break;
          default:
            this.shownPhase = this.prepareSubmissionView;
        }
      } else {
        switch (clientSubmission.outcome) {
          case 'submitted':
            this.shownPhase = this.submittedView;
            if (this.application?.randstad_process?.clientSubmission?.priorSubmissions?.length > 0 && this.application?.randstad_process?.lastProcessStatus === 'RH_UPDATE_SUB') {
              this.shownPhase = this.submittedViewUpdate;
              this.originalSubmissionIndex = this.application.randstad_process.clientSubmission.priorSubmissions.length - 1;
            }
            break;
          default:
            this.shownPhase = this.accountManagerAcceptedView;
        }
      }
    }
    else {
      if (Permissions?.submitCandidateToHiringManager || Permissions?.submitCandidateToAccountManager) {
        this.shownPhase = this.prepareSubmissionView;
      } else {
        this.shownPhase = this.accountManagerAcceptedView;
      }
    }

  }


  setViewByStatus(outcome: string) {
    const { Permissions } = this._authService.user;
    if (Permissions?.submitCandidateToHiringManager) {
      switch (outcome) {
        case 'submitted':
          this.shownPhase = this.submittedView;
          if (this.application?.randstad_process?.clientSubmission?.priorSubmissions?.length > 0 && this.application?.randstad_process?.lastProcessStatus === 'RH_UPDATE_SUB') {
            this.shownPhase = this.submittedViewUpdate;
            this.originalSubmissionIndex = this.application.randstad_process.clientSubmission.priorSubmissions.length - 1;
          }
          break;
        default:
          this.shownPhase = this.prepareSubmissionView;
      }
    } else {
      switch (outcome) {
        case 'submitted':
          this.shownPhase = this.submittedView;
          if (this.application?.randstad_process?.clientSubmission?.priorSubmissions?.length > 0 && this.application?.randstad_process?.lastProcessStatus === 'RH_UPDATE_SUB') {
            this.shownPhase = this.submittedViewUpdate;
            this.originalSubmissionIndex = this.application.randstad_process.clientSubmission.priorSubmissions.length - 1;
          }
          break;
        default:
          this.shownPhase = this.accountManagerAcceptedView;
      }
    }
  }

  emitUnfitClick() {
    this.unfitClick.emit();
  }

  emitUpdateSubmissionClick(isSolutions: boolean = false) {
    this.unfitClick.emit({ solutions: isSolutions, updateSubmission: true, clientSubmission: true });
  }

  openMailClient(e: MouseEvent) {
    e.preventDefault();
    const submission = this.submissionForm.controls.submission.value.trim();
    // TODO: Replace with actual email once this data is accessible.
    const recipient = 'test@placeholder.com';
    const subject = encodeURIComponent(`Talent Recommendation: ${this.application.randstad_process.candidateFullName}`);
    const body = submission.length ? encodeURIComponent(this.submissionForm.controls.submission.value) : '';
    const mailto = `mailto:${recipient}?subject=${subject}&body=${body}`;
    window.open(mailto);
  }

  openFileUploadModal() {
    this._bsModalService.show(FileUploadModalComponent);
  }

  /**
   * Not required since below ticket removes this modal from this part of the process
   * @deprecated - By ticket DF044-1800 https://randstadglobal.atlassian.net/browse/DF044-1800
   */
  // openLogFeedbackModal() {
  //   const initialState = {
  //     textareaLabel: 'Feedback from the hiring manager',
  //     acceptFn: this.accept.bind(this),
  //     rejectFn: this.reject.bind(this)
  //   };
  //   this._bsModalService.show(LogFeedbackModalComponent, { initialState });
  // }

  /**
   * @deprecated Deprecated by DF044-3615; see handleModalSubmit function below.
   */
  // submitToHiringManager() {
  //   this.submissionForm.controls.submission.markAsTouched();
  //   if (this.submissionForm.invalid) {
  //     this._toast.showToast('review-hiring-manager.submission_invalid', { cssClass: ToastClass.DANGER });
  //     return;
  //   }
  //
  //   const { submission } = this.submissionForm.controls;
  //   const body = this.application.clone();
  //   body.randstad_process.apply({
  //     lastProcessStatus: ProcessStatus.RH_AWAITING_CLIENT_REVIEW,
  //     clientSubmission: new ClientSubmission({
  //       submission: submission.value,
  //       submittedByID: this._authService.user.BackOfficeID,
  //       submittedTo: { fullName: this._jobOrder.storedJobOrder.hiringManager, jobTitle: 'Hiring Manager' },
  //       submissionDate: moment(),
  //       outcome: 'submitted'
  //     })
  //   });
  //
  //   const key = this.application.randstad_process.lastProcessStep;
  //   const intgSteps : INTG_STEPS[] = [INTG_STEPS.SUBMISSION];
  //   this._talentProcess.updateApplication(body, intgSteps, key)
  //     .then(() => {
  //       this._metrics.addEventToQueue(this.application, `candidate was submitted to the hiring manager`);
  //       this._toast.showToast('review-hiring-manager.success', { cssClass: ToastClass.SUCCESS });
  //     })
  //     .catch(err => {
  //       this._toast.showToast('review-hiring-manager.error', { cssClass: ToastClass.DANGER });
  //       console.error(err);
  //     });
  // }

  /**
   * Move the talent to the "Client Interview" state. Previously this was fired from the
   * log-feedback-modal component.
   * @listens <interview requested>:click
   */
  accept() {
    const key = this.application.randstad_process.lastProcessStep;
    const now = moment();
    const body = this.application.clone();
    body.randstad_process.apply({
      lastProcessStep: ProcessStep.INTERVIEW_HM,
      lastProcessStepNum: this.application.randstad_process.lastProcessStepNum + 1,
      lastProcessStepDate: now,
      lastProcessStatus: ProcessStatus.IH_SCHEDULE_INTERVIEW
    });
    body.randstad_process.clientSubmission.apply({
      outcome: 'proceed',
      outcomeDate: now
    });
    const intgSteps: INTG_STEPS[] = [];
    this._talentProcess.updateApplication(body, intgSteps, key)
      .then(() => {
        this._toast.showToast('review-hiring-manager.candidate_advance', { cssClass: ToastClass.SUCCESS });
      })
      .catch(err => {
        this._toast.showToast('review-hiring-manager.candidate_advance.error', { cssClass: ToastClass.DANGER });
        console.error(err);
      });
  }

  /**
   * No longer used since it was previously called from log-feedback-modal component
   * @deprecated - By ticket DF044-1800 https://randstadglobal.atlassian.net/browse/DF044-1800
   */
  reject(feedback: string) {
    const key = this.application.randstad_process.lastProcessStep;
    const now = moment();
    const body = this.application.clone();
    body.randstad_process.apply({
      rejected: true,
      rejectedTimestamp: now,
      rejectedByAgentID: this._authService.user.BackOfficeID
    });
    body.randstad_process.clientSubmission.apply({
      hiringManagerFeedback: feedback,
      outcome: 'rejected',
      outcomeDate: now
    });
    const intgSteps: INTG_STEPS[] = [];
    this._talentProcess.updateApplication(body, intgSteps, key)
      .then(() => {
        this._metrics.addEventToQueue(this.application, `candidate was rejected as not a fit at the review hiring manager phase`);
        const trvariable = { 'value': this.application.randstad_process.candidateFullName };
        this._toast.showToastWithVariables('review-hiring-manager.not_fit', trvariable, { cssClass: ToastClass.SUCCESS });
        this.unfitClick.emit();
      })
      .catch(err => {
        this._toast.showToast('review-hiring-manager.not_fit_error', { cssClass: ToastClass.DANGER });
        console.error(err);
      });
  }

  openSubmitModal() {
    // all internal submission attachments should be checked for submission when modal is opened
    if (this._recruitmentPhasesService.talentInterviewStatus) {
      this._recruitmentPhasesService.openWarningModal()
    } else {
      const isProfileValid = this._recruitmentPhasesService.checkIfProfileIsDataValid();
      if (isProfileValid === true) {
        this.openDupTalentModal('clientSubmission');
      }
    }
  }

  completeClientSubmission() {
    let attachments = this.attachments?.map(attachment => ({ ...attachment, willSubmit: true }));
    this.modalRef = this._bsModalService.show(SubmitToClientModalComponent, {
      initialState: {
        submitHandler: this.handleModalSubmit.bind(this),
        attachments
      }
    });
    this.modalRef.setClass('modal-sm');
  }

  async skipToStep(talent: Profile) {
    let isSolutionsJob: boolean = this.job && this.job.employmentTypes &&
      this.job.employmentTypes.toString() === 'SOLUTIONS';
    this._metrics.addEventToQueue(this.skippedApplication, `candidate was manually advanced to the Recruiter interview phase.`);
    const skipStep = this.userLob.checkLob('RGS') ? 2 : 3;
    return this._talentProcess.skipStepAndGetTalentApplication(talent.profileId, this.job.allbirds_metadata.allbirds_job_id, this.skippedApplication, skipStep, isSolutionsJob)
      .then((app: Application) => {
        this.application = app;
        this.completeClientSubmission();
        this._loading.hide();
      })
  }



  addToShortlist(talent: any) {
    this.zone.run(() => {
      this._loading.show();
      this._shortlist.add(talent)
        .then((data: ShortlistActionResponse) => {
          this._shortlist.updateInMemoryApplications([data.application]);
          this.skippedApplication = data.application;
          this._toast.showToastNoTranslation(data.message);
          this.skipToStep(talent);
          // this.close();
        })
        .catch((err: any) => {
          this._loading.hide();
          this._toast.showToastNoTranslation(err.message);
        });
    });
  }
  fetchProfile(profileId: string) {
    return this._cache.loadProfile(profileId);
  }

  openDupTalentModal(type: string) {
    this._loading.show();
    const profile = this._talentProcess.selectedProfileSubject.value;

    if (profile.isProfile()) {
      const familyName = profile.familyName;
      const givenName = profile.givenName;
      const lob = profile.lob;
      const phoneNumbers = profile.candidatePhoneNumber;
      const talentId = profile.talentId;
      const emailAddresses = profile?.emailAddresses?.length > 0 && profile?.emailAddresses[0].emailAddress || [];
      const body = { familyName, givenName, lob, phoneNumbers, talentId, emailAddresses: [emailAddresses] }

        
      this._api.getDuplicateTalent(body)
        .subscribe(data => {
          this._loading.hide();
          if ((data.length === 0 || data.length === 1) && type == 'clientSubmission') {
            this.completeClientSubmission();
            return;
          }
          this.searchResults = {
            total: data.length
          }
          const selectedProfile = data.find((p) => p.externalId === profile.externalId);
          const initialState = { talent: selectedProfile, searchResults: this.searchResults, duplicateTalent: data, type };
          this.bsmodalRef = this._bsModalService.show(DupTalentModalComponent, { initialState, class: 'modal-xl' });
          this.bsmodalRef.content.clientSubmissionEvent.subscribe((res: any) => {
            if (res.profileId === this.application.profileId) {
              this.completeClientSubmission();
              return;
            }
            const selectedProfile = this.fetchProfile(res.profileId);
            this.addToShortlist(selectedProfile);

          });
        });
    }
  }

  /**
   * Called after the email modal is submitted.
   * @param params - hm submission email/integration parameters
   */
  async handleModalSubmit(params: SubmitToClientModalConfig.SubmitHandlerParams): Promise<void> {
    // Show the loading spinner.
    this._loading.show();

    // Either clone the application's existing client submission object or a instantiate new one.
    const clientSubmission = (this.application.randstad_process && this.application.randstad_process.clientSubmission)
      ? this.application.randstad_process.clientSubmission.clone()
      : new ClientSubmission();

    // Update fields for both send with and without email options.
    this.application.randstad_process.apply({ lastProcessStatus: ProcessStatus.RH_AWAITING_CLIENT_REVIEW });
    clientSubmission.submission = params.form.message;
    clientSubmission.submissionDate = moment();
    clientSubmission.submittedByID = this._authService.user.BackOfficeID;
    clientSubmission.submittedTo = { fullName: this._jobOrder.storedJobOrder.hiringManager, jobTitle: 'Hiring Manager' };
    clientSubmission.outcome = 'submitted';

    // If an email needs to be sent, handle file upload and sending the email notification.
    try {
      if (params.withEmail) {
        clientSubmission.submissionEmailTo = params.form.to;
        clientSubmission.submissionEmailCc = params.form.cc;
        clientSubmission.submissionEmailBcc = params.form.bcc;
        clientSubmission.submissionEmailSubject = params.form.subject;
        // Upload attachment (if any).
        if (params.submissionAttachments) {
          const fileURIs = params.submissionAttachments?.map(file => file?.path);
          clientSubmission.submissionAttachmentURI = fileURIs?.join(',');
          // commented this because it seems we attached all the attachments to the application instead of the attachments from submission
          // and also send all the attachments by email instead of the selected ones.
          // this.application.randstad_process.attachments = params.allAttachments;
          this.application.randstad_process.attachments = params.submissionAttachments;
        }
        // Send email out.
        const notification = await this._notification.sendNotification(this.getNotificationPayload(clientSubmission, this.application)).toPromise();
      } else {
        const mentions = UtilityService.getMentionsRecipients(clientSubmission.submission);

        if (mentions && mentions.length) {
          const emailAddressOnToLine = mentions.splice(0, 1)
          const payload = this.getNotificationPayload(clientSubmission, this.application);
          payload.notificationObject.emailRecipients = emailAddressOnToLine;
          payload.notificationObject.emailCc = mentions
          payload.notificationType = NotificationTypes.CLIENT_SUBMISSION_NOTE;
          payload.notificationObject.submission = clientSubmission.submission;

          delete payload.notificationObject.emailBcc;
          // delete payload.notificationObject.emailCc;
          delete payload.notificationObject.emailSignature;
          delete payload.notificationObject.emailBcc;
          delete payload.notificationObject.emailHtml;
          await this._notification.sendNotification(payload).toPromise();
        }
      }
    } catch (e) {
      console.error(e);
      this._toast.showToast('review-hiring-manager.email-error', { cssClass: ToastClass.DANGER });
    }
    // Apply modified client submission to current application.
    this.application.randstad_process.apply({ clientSubmission });
    // Update application as per usual.
    // console.log('Updated application:', this.application);
    const key = this.application.randstad_process.lastProcessStep;
    const intgSteps: INTG_STEPS[] = [INTG_STEPS.SUBMISSION];
    this._talentProcess.updateApplication(this.application, intgSteps, key)
      .then(() => {
        this._metrics.addEventToQueue(this.application, `candidate was submitted to the hiring manager`);
        this._toast.showToast('review-hiring-manager.success', { cssClass: ToastClass.SUCCESS });
      })
      .catch(err => {
        this._toast.showToast('review-hiring-manager.error', { cssClass: ToastClass.DANGER });
        console.error(err);
      })
      .finally(() => {
        this._loading.hide();
        this.modalRef.hide();
      });
  }
  /**
   * Returns the payload needed for the sendNotification API call.
   */
  getNotificationPayload(clientSubmission: ClientSubmission, application?: Application): NotificationData {
    const { submission, submissionEmailTo, submissionEmailCc, submissionEmailBcc, submissionEmailSubject, submissionAttachmentURI } = clientSubmission;
    return {
      notificationType: NotificationTypes.CLIENT_SUBMISSION,
      notificationObject: {
        title: this._jobOrder.storedJobOrder.internalTitle,
        candidateFullName: this.application.randstad_process.candidateFullName,
        published_by_user: this._jobOrder.storedJobOrder.allbirds_metadata.published_by_user,
        published_by_user_email: this._jobOrder.storedJobOrder.allbirds_metadata.published_by_user_front_office_id,
        allbirds_job_id: this._jobOrder.storedJobOrder.allbirds_metadata.allbirds_job_id,
        executing_action_user: this._authService.user.FullName,
        executing_action_user_email: this._authService.user.EmailAddr,
        customer_name: this._jobOrder.storedJobOrder.allbirds_metadata.customer_name,
        front_office_id: this._jobOrder.storedJobOrder.allbirds_metadata.front_office_id,
        submissionResumeUrl: submissionAttachmentURI ? encodeURI(submissionAttachmentURI) : '',
        user: { ...this._authService.user },
        emailRecipients: (submissionEmailTo || []).map((s: string) => ({ recipientEmail: s })),
        emailCc: (submissionEmailCc || []).map((s: string) => ({ recipientEmail: s })),
        emailBcc: (submissionEmailBcc || []).map((s: string) => ({ recipientEmail: s })),
        emailSubject: submissionEmailSubject,
        emailHtml: submission,
        emailSignature: (this._authService.user && this._authService.user.Preferences && this._authService.user.Preferences.EmailSignature) || '',
        contextUrl: this._utility.getContextUrl(location.pathname, location.search),
        applicationObject: application,
        vms_req_id: this.job.allbirds_metadata.vms_req_id,
        hiringManager: this.job.hiringManager
      }
    };
  }
}
