// Email regex from https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript.

import { ProcessStep } from "../../models/external/misc.model";

// tslint:disable-next-line:max-line-length
export const EMAIL_REGEX: RegExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

export const ABBREVIATION_TO_STATE: { [state: string]: string } = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
};

export const BACKGROUND_CHECK_OPTIONS: { id: string; itemName: string }[] = [
  { id: "Bkgrnd Chk By Other Vendor", itemName: "Bkgrnd Chk By Other Vendor" },
  { id: "Civil Records by Infomart", itemName: "Civil Records by Infomart" },
  {
    id: "Criminal History by Infomart",
    itemName: "Criminal History by Infomart",
  },
  { id: "Education Verif Inhouse", itemName: "Education Verif Inhouse" },
  {
    id: "Education Verif Other Vendor",
    itemName: "Education Verif Other Vendor",
  },
  {
    id: "Education Verif by Infomart",
    itemName: "Education Verif by Infomart",
  },
  { id: "Employment Verif Inhouse", itemName: "Employment Verif Inhouse" },
  {
    id: "Employment Verif Other Vendor",
    itemName: "Employment Verif Other Vendor",
  },
  {
    id: "Employment Verif by Infomart",
    itemName: "Employment Verif by Infomart",
  },
  {
    id: "Fed Criminal Hist by Infomart",
    itemName: "Fed Criminal Hist by Infomart",
  },
  { id: "GSA Infomart", itemName: "GSA Infomart" },
  { id: "Multi State by Infomart", itemName: "Multi State by Infomart" },
  { id: "OFAC Infomart", itemName: "OFAC Infomart" },
  { id: "OIG Infomart", itemName: "OIG Infomart" },
  { id: "Patriot Act by Infomart", itemName: "Patriot Act by Infomart" },
  {
    id: "SFN Conv. Security Clearance",
    itemName: "SFN Conv. Security Clearance",
  },
  { id: "SFN Conversion CRIMA", itemName: "SFN Conversion CRIMA" },
  {
    id: "SFN Conversion FED Crim Hist",
    itemName: "SFN Conversion FED Crim Hist",
  },
];

export const DRUG_SCREEN_OPTIONS: { id: string; itemName: string }[] = [
  { id: "3 Panel Inhouse", itemName: "3 Panel Inhouse" },
  { id: "4 Panel Inhouse - No THC", itemName: "4 Panel Inhouse - No THC" },
  { id: "4 Panel Lab- No THC", itemName: "4 Panel Lab- No THC" },
  { id: "5 Panel Inhouse", itemName: "5 Panel Inhouse" },
  { id: "5 Panel Inhouse - No THC", itemName: "5 Panel Inhouse - No THC" },
  { id: "5 Panel Lab", itemName: "5 Panel Lab" },
  { id: "6 Panel Inhouse", itemName: "6 Panel Inhouse" },
  { id: "6 Panel Lab", itemName: "6 Panel Lab" },
  { id: "6-panel w/MDMA Lab", itemName: "6-panel w/MDMA Lab" },
  { id: "7 Panel Inhouse", itemName: "7 Panel Inhouse" },
  { id: "7 Panel Lab", itemName: "7 Panel Lab" },
  { id: "8 Panel Inhouse", itemName: "8 Panel Inhouse" },
  { id: "8 Panel Lab - No THC", itemName: "8 Panel Lab - No THC" },
  { id: "9 Panel Inhouse", itemName: "9 Panel Inhouse" },
  { id: "9 Panel Inhouse - No THC", itemName: "9 Panel Inhouse - No THC" },
  { id: "9 Panel Lab", itemName: "9 Panel Lab" },
  { id: "9 Panel Lab- No THC", itemName: "9 Panel Lab- No THC" },
  { id: "10 Panel Inhouse", itemName: "10 Panel Inhouse" },
  { id: "10 Panel Lab", itemName: "10 Panel Lab" },
  { id: "10-panel w/ MDMA Inhouse", itemName: "10-panel w/ MDMA Inhouse" },
  { id: "10-panel w/MDMA Lab", itemName: "10-panel w/MDMA Lab" },
  { id: "11 Panel Lab", itemName: "11 Panel Lab" },
  { id: "12 Panel Inhouse", itemName: "12 Panel Inhouse" },
  { id: "12 Panel Inhouse - No THC", itemName: "12 Panel Inhouse - No THC" },
  { id: "12 Panel Lab", itemName: "12 Panel Lab" },
  { id: "HHS DOT Mirror- Lab", itemName: "HHS DOT Mirror- Lab" },
  {
    id: "HHS DOT Mirror- Lab- No THC",
    itemName: "HHS DOT Mirror- Lab- No THC",
  },
  { id: "Hair Sample Lab", itemName: "Hair Sample Lab" },
  { id: "Nicotine Screen Inhouse", itemName: "Nicotine Screen Inhouse" },
  { id: "Nicotine Screen Lab", itemName: "Nicotine Screen Lab" },
  { id: "Post Accident", itemName: "Post Accident" },
  { id: "SFN Conversion Drug Screen", itemName: "SFN Conversion Drug Screen" },
];

export const RT_TALENT_SEARCH_NOTE_TYPE_OPTIONS: { noteTypeId: string; noteTypeText: string }[] = [
  { noteTypeId: "Applicant Feedback", noteTypeText: "Applicant feedback" },
  { noteTypeId: "Availability Call", noteTypeText: "Availability call" },
  { noteTypeId: "Connect", noteTypeText: "Connect" },
  { noteTypeId: "Email", noteTypeText: "Email" },
  { noteTypeId: "Important Info", noteTypeText: "Important Info" },
  { noteTypeId: "Interview Prep", noteTypeText: "Interview prep" },
  { noteTypeId: "Left Message", noteTypeText: "Left Message" },  
  { noteTypeId: "Modern Hire Interview", noteTypeText: "Modern Hire interview" },
  { noteTypeId: "MPC Recruiter", noteTypeText: "MPC Recruiter" },
  { noteTypeId: "MPC Sales Action", noteTypeText: "MPC Sales Action" },
  { noteTypeId: "MPC-Strategic Account", noteTypeText: "MPC - Strategic Account" },
  { noteTypeId: "RADAR Match", noteTypeText: "RADAR Match" },
  { noteTypeId: "Redeployment Comments", noteTypeText: "Redeployment Comments" },
  { noteTypeId: "Reference", noteTypeText: "Reference" },
  { noteTypeId: "DBC Notes", noteTypeText: "RITS Notes" },
  { noteTypeId: "DBC Offer", noteTypeText: "RITS Offer" },
  { noteTypeId: "DBC Screening Info", noteTypeText: "RITS Screening Info" },
  { noteTypeId: "DBC Submission", noteTypeText: "RITS Submission" },
  { noteTypeId: "Sales Lead", noteTypeText: "Sales Lead" },
  { noteTypeId: "Skills Assessment/Test Results", noteTypeText: "Skills Assessment/Test Results" },
  { noteTypeId: "Talent Care In-Person", noteTypeText: "Talent care (in-person)" },
  { noteTypeId: "Talent Care Touchbase", noteTypeText: "Talent care (touch base)" }
];

export const RGS_TALENT_SEARCH_NOTE_TYPE_OPTIONS: { noteTypeId: string; noteTypeText: string }[] = [
  { noteTypeId: "GENCM", noteTypeText: "General Comment" },
  { noteTypeId: "TEML", noteTypeText: "Email/Mass Email" },
  { noteTypeId: "TTXT", noteTypeText: "Text/Mass SMS" },
  { noteTypeId: "RNCD", noteTypeText: "Randstad National Career Day" },
  { noteTypeId: "REVNT", noteTypeText: "Recruiting - Event" },
  { noteTypeId: "RFAIR", noteTypeText: "Recruiting - Job Fair" },
  { noteTypeId: "AVAIL", noteTypeText: "Talent - Call-in Available" },
  { noteTypeId: "TCALL", noteTypeText: "Talent - Call/Left Message" },
  { noteTypeId: "TENGM", noteTypeText: "Talent - Employee Engagement" },
  { noteTypeId: "TRDIR", noteTypeText: "Talent - Redirect" },
  { noteTypeId: "TSOC", noteTypeText: "Talent - Social Connect" },
  { noteTypeId: "TSMPC", noteTypeText: "Talent - Social Most Placeable Candidate" },
  { noteTypeId: "TSOCO", noteTypeText: "Talent - Social One Way Comm" },
  { noteTypeId: "TVIST", noteTypeText: "Talent - Visit" }
];


export const TALENT_SEARCH_ACTIVITY_STAGE_OPTIONS: { activityTypeId: string; activityTypeText: string }[] = [
  { activityTypeId: ProcessStep.PRESCREENING, activityTypeText: "Pre-Screening" },
  { activityTypeId: ProcessStep.INTERVIEW_RECRUITER, activityTypeText: "Recruiter Interview" },
  { activityTypeId: ProcessStep.REVIEW_AM, activityTypeText: "Internal Submission" },
  { activityTypeId: ProcessStep.REVIEW_HM, activityTypeText: "Client Submission" },
  { activityTypeId: ProcessStep.INTERVIEW_HM, activityTypeText: "Client Interview" },
  { activityTypeId: ProcessStep.OFFER, activityTypeText: "Offer" },
];





