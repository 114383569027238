import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ElasticSavedSearch, SavedSearch, TalentSearchModel } from './user-searches.types';
import { TalentService } from '../talent/talent.service';
import { AuthService } from '../auth/auth.service';
import { JobTitleFilter, ProfileQuery, SkillFilter, NewProfileQuery, NoteTypeFilter } from '../../models/internal/profile-query.models';
import { ApiService } from '../api/api.service';
import moment from 'moment';
import { Talent } from '../../models/external/talent.interface';
import { BACKGROUND_CHECK_OPTIONS, DRUG_SCREEN_OPTIONS } from '../utility/constants';

@Injectable({
  providedIn: 'root'
})
export class TalentManagementService {

  // Subjects/observables for the navigation bar text.
  // private headerSubject = new BehaviorSubject<any>({ headerTitle: 'Talent', showBackButton: false });
  // public headerObservable = this.headerSubject.asObservable();

  public talentManagementProfileSearchResults: Talent[] = [];
  recentResultsSub = new BehaviorSubject([]);
  savedResultsSub = new BehaviorSubject([]);

  constructor(
    public _api: ApiService,
    private _talent: TalentService,
    private _auth: AuthService
  ) { }

  /**
   * Fetch the saved/recent searches for the current user.
   * Will update the recentResultsSub which in turn should notify
   * any subscribers to recentResultsSub
   */
  getRecentSearchResults() {
    this._api.getRecentSearches(this._auth.user.EmailAddr)
      .subscribe((recentSearchesRes) => {
        this.recentResultsSub.next(recentSearchesRes);
      });
  }

  /**
   * Fetch the saved/recent searches for the current user.
   * Will update the savedResultsSub which in turn should notify
   * any subscribers to savedResultsSub
   */
  getSavedSearchResults() {
    this._api.getSavedSearches(this._auth.user.EmailAddr)
      .subscribe((savedSearchesRes) => {
        this.savedResultsSub.next(savedSearchesRes);
      });
  }

  /**
   * Delete a saved search from the db
   * @param _id {string}
   */
  deleteSavedSearch(_id: string) {
    this._api.deleteSavedSearch(_id)
      .subscribe(deleted => {
        let savedSearches = this.savedResultsSub.getValue();
        savedSearches = savedSearches.filter(srch => srch._id !== deleted);
        this.savedResultsSub.next(savedSearches);
      });
  }

  /**
   * Save a search to the db
   * @param newSearch {SavedSearch}
   */
  addSavedSearch(newSearch: SavedSearch, skillFilters: SkillFilter[], jobFilters: JobTitleFilter[]) {
    const payload: ElasticSavedSearch = new ElasticSavedSearch({
      searchId: null,
      title: newSearch.displayTitle,
      owner: this._auth.user.EmailAddr,
      create_time: moment(),
      last_update_time: moment(),
      last_update_user: this._auth.user.EmailAddr,
      viewers: [],
      editors: [this._auth.user.EmailAddr],
      profileQuery: this.searchFormModelToProfileQuery(newSearch.fields, skillFilters, jobFilters)
    });
    return this._api.createSavedSearch(payload)
      .pipe(map(newSavedSearch => {
        try {
          const savedSearches = this.savedResultsSub.getValue();
          savedSearches.unshift(newSavedSearch);
          this.savedResultsSub.next(savedSearches);
          return newSavedSearch;
        } catch (e) {
          console.error(`An error occurred saving a search`);
          return null;
        }
      }));
  }

  /**
   * Updates a search in the db
   * @param updatedSearch {SavedSearch}
   * @param skillFilters {SkillFilter[]}
   * @param jobTitleFilters {JobTitleFilter[]}
   */
  updateSavedSearch(updatedSearch: SavedSearch, skillFilters: SkillFilter[], jobTitleFilters: JobTitleFilter[]) {
    const payload: ElasticSavedSearch = new ElasticSavedSearch({
      _id: updatedSearch._id,
      searchId: updatedSearch.searchId,
      title: updatedSearch.displayTitle,
      owner: this._auth.user.EmailAddr,
      create_time: updatedSearch.createdDate,
      last_update_time: moment(),
      last_update_user: this._auth.user.EmailAddr,
      viewers: [],
      editors: [this._auth.user.EmailAddr],
      profileQuery: this.searchFormModelToProfileQuery(updatedSearch.fields, skillFilters, jobTitleFilters)
    });
    return this._api.updateSavedSearch(payload)
      .pipe(map(updatedSearch => {
        try {
          const savedSearches = this.savedResultsSub.getValue();
          const savedSearchIdx = savedSearches.findIndex(srch => srch._id === updatedSearch._id);
          if (savedSearchIdx > -1) {
            savedSearches.splice(savedSearchIdx, 1, updatedSearch);
          }
          this.savedResultsSub.next(savedSearches);
          return updatedSearch;
        } catch (e) {
          console.error('An error occurred updating a search');
          return null;
        }
      }));
  }

  /**
   * Convert a profile query to a search form model that can be passed
   * to the search form to update it's displayed values
   * @param profileQuery {ProfileQuery}
   */
  profileQueryToSearchFormModel(profileQuery: any): TalentSearchModel {
    let talentSrch: TalentSearchModel
    talentSrch = {
      keyword: profileQuery.query,
      ladderStatus: profileQuery.recruitmentLadder,
      talentStatus: profileQuery.talentStatus,
      lineOfBusiness: profileQuery.lineOfBusiness,
      location: profileQuery.displayLocation,
      state: profileQuery.state,
      latLong: profileQuery.latLong,
      displayLocation: profileQuery.displayLocation,
      backgroundCheck: profileQuery.backgroundCheck,
      skills: (profileQuery.skillFilters && profileQuery.skillFilters.length) ? profileQuery.skillFilters.map((filter: any) => filter.skill).join(',') :
        (profileQuery.skills && profileQuery.skills.length) ? profileQuery.skills.map((job: any) => job).join(',') : '',
      jobTitles: profileQuery.jobTitles,
      noteTypes: profileQuery.noteTypes,
      noteTypeUpdatedWithin: profileQuery.noteTypeUpdatedWithin,
      activityTypes: profileQuery.activityTypes,
      activityTypeUpdatedWithin: profileQuery.activityTypeUpdatedWithin,
      byUsers: profileQuery.byUsers,
      radius: profileQuery.radius,
      excludeOnTempAssignment: profileQuery.excludeOnTempAssignment,
      excludeOnPermAssignment: profileQuery.excludeOnPermAssignment,
      excludeRedFlags: profileQuery.excludeRedFlags,
      engagementRecency: profileQuery.engagementRecency,
      employeeTypes: profileQuery.employeeTypes,
      workAuthorizations: profileQuery.workAuthorizations,
      distance: profileQuery.radius,
      title: (profileQuery.jobTitleFilters && profileQuery.jobTitleFilters.length) ? profileQuery.jobTitleFilters.map((filter: any) => filter.jobTitle).join(',') :
        (profileQuery.jobTitles && profileQuery.jobTitles.length) ? profileQuery.jobTitles.map((job: any) => job).join(',') : '',
      skill: profileQuery.skill,
      jobTitle: profileQuery.jobTitle,
      company: profileQuery.company,
      vaccinationDataFilter: profileQuery.vaccinationDataFilter ? true : profileQuery.vaccinationDataFilter === false ? false : "All",
      portfolioLinkFilter: profileQuery.portfolioLinkFilter,
      primaryRecruiter: profileQuery.primaryRecruiter,
      secondaryRecruiter: profileQuery.secondaryRecruiter
    };
    if (profileQuery.locationFilters && profileQuery.locationFilters.length) {
      talentSrch.displayLocation = profileQuery.locationFilters.map((filter: any) => filter.address).join(',');
      talentSrch.distance = profileQuery.locationFilters[0].distanceInMiles;
    }

    if (profileQuery.customAttributeFilter) talentSrch.ladderStatus = profileQuery.customAttributeFilter;
    if (profileQuery.jobTitleFilters) talentSrch.jobTitles = profileQuery.jobTitleFilters;

    // Check if the saved search is in the old format or if it contains an empty keyword field. If so, we mark it to auto update after searching
    if (
        (profileQuery.locationFilters && profileQuery.locationFilters.length) ||
        (!profileQuery.query.trim().length)
    ) {
      talentSrch.shouldAutoUpdate = true;
    }

    if(profileQuery.backgroundCheck && profileQuery.backgroundCheck.length > 0) {
      const backgroundCheckFilter :string[] = [];
      const drugScreenFilter :string[] = [];
      let bgCheckUpdated, drugScreenUpdated;
      profileQuery.backgroundCheck.forEach((check: any) => {
        const isBackground = BACKGROUND_CHECK_OPTIONS.find((bgCheck) => bgCheck.id === check.screeningType );
        // const isDrugScreen = DRUG_SCREEN_OPTIONS.find((drug) => drug.id === check.screeningType);
        if(isBackground) {
          bgCheckUpdated = check.screenDate;
          backgroundCheckFilter.push(check.screeningType)
        }
        else {
          drugScreenUpdated = check.screenDate;
          drugScreenFilter.push(check.screeningType)
        }
      });
      talentSrch.backgroundCheck = backgroundCheckFilter;
      talentSrch.drugScreen = drugScreenFilter;
      talentSrch.bgCheckUpdated = bgCheckUpdated;
      talentSrch.drugCheckUpdated = drugScreenUpdated;
    }
    // Join the arrays and it will be parsed and the jobSkills and jobTitles properties will be set
    if(profileQuery.vaccinationDataFilter){
      talentSrch.vaccinationDataFilter = profileQuery.vaccinationDataFilter
    }
    return talentSrch;
  }

  /**
   * Convert a search form model into a profile query
   * @param searchFormModel {TalentSearchModel}
   * @param skillFilters {SkillFilter[]}
   * @param jobTitleFilters {JobTitleFilter[]}
   */
  searchFormModelToProfileQuery(searchFormModel: TalentSearchModel, skillFilters: SkillFilter[], jobTitleFilters: JobTitleFilter[]): ProfileQuery {
    console.log(searchFormModel)
    const profileQuery = new NewProfileQuery({
      query: searchFormModel.keyword,
      radius: searchFormModel.radius || searchFormModel.distance,
      state: searchFormModel.state,
      jobTitles: [],
      skills: [],
      noteTypes: searchFormModel.noteTypes,
      noteTypeUpdatedWithin: searchFormModel.noteTypeUpdatedWithin,
      activityTypes: searchFormModel.activityTypes,
      activityTypeUpdatedWithin: searchFormModel.activityTypeUpdatedWithin,
      byUsers: searchFormModel.byUsers,
      excludeOnTempAssignment: searchFormModel.excludeOnTempAssignment,
      excludeOnPermAssignment: searchFormModel.excludeOnPermAssignment,
      excludeRedFlags: searchFormModel.excludeRedFlags,
      recruitmentLadder: [],
      engagementRecency: searchFormModel.engagementRecency,
      backgroundCheck: [],
      employeeTypes: [],
      workAuthorizations: searchFormModel.workAuthorizations,
      location: searchFormModel.location,
      displayLocation: searchFormModel.displayLocation,
      latLong: searchFormModel.latLong,
      jobTitle: searchFormModel.jobTitle,
      company: searchFormModel.company,
      talentStatus: [],
      lineOfBusiness: [],
      skill: searchFormModel.skill,
      vaccinationDataFilter: searchFormModel.vaccinationDataFilter,
      portfolioLinkFilter: searchFormModel.portfolioLinkFilter,
      primaryRecruiter: searchFormModel.primaryRecruiter,
      secondaryRecruiter: searchFormModel.secondaryRecruiter,
    });

    if (searchFormModel.ladderStatus && searchFormModel.ladderStatus.length) {
      searchFormModel.ladderStatus.forEach((status: any) => {
        profileQuery.recruitmentLadder.push(status.id);
      });
    }

    if (searchFormModel.backgroundCheck && searchFormModel.backgroundCheck.length) {
      searchFormModel.backgroundCheck.forEach((status: any) => {
        profileQuery.backgroundCheck.push({screeningType:status.id, screenDate: Number(searchFormModel.bgCheckUpdated)});
      });
    }

    if (searchFormModel.drugScreen && searchFormModel.drugScreen.length) {
      searchFormModel.drugScreen.forEach((status: any) => {
        profileQuery.backgroundCheck.push({screeningType:status.id, screenDate: Number(searchFormModel.drugCheckUpdated)});

      });
    }

    if (searchFormModel.jobTitles && searchFormModel.jobTitles.length) {
      searchFormModel.jobTitles.forEach((title: any) => {
        profileQuery.jobTitles.push(title.jobTitle);
      });
    }

    if (searchFormModel.jobSkills && searchFormModel.jobSkills.length) {
      searchFormModel.jobSkills.forEach((jobSkill: any) => {
        profileQuery.skills.push(jobSkill.skill);
      });
    }

    if (searchFormModel.employeeTypes && searchFormModel.employeeTypes.length) {
      let tmpArr: any = [];

      searchFormModel.employeeTypes.forEach((empType: any) => {
        tmpArr.push(empType.id);
      });
      profileQuery.employeeTypes = tmpArr.flat();
    }

    if (searchFormModel.talentStatus && searchFormModel.talentStatus.length) {
      let tmpArr: any = [];

      searchFormModel.talentStatus.forEach((talentStatus: any) => {
        tmpArr.push(talentStatus.id);
      });
      profileQuery.talentStatus = tmpArr.flat();
    }

    if (searchFormModel.lineOfBusiness && searchFormModel.lineOfBusiness.length) {
      let tmpArr: any = [];

      searchFormModel.lineOfBusiness.forEach((lineOfBusiness: any) => {
        tmpArr.push(lineOfBusiness.id);
      });
      profileQuery.lineOfBusiness = tmpArr.flat();
    }

    if (searchFormModel.workAuthorizations && searchFormModel.workAuthorizations.length) {
      let tmpArr: any = [];

      searchFormModel.workAuthorizations.forEach((workAuth: any) => {
        tmpArr.push(workAuth.id);
      });
      profileQuery.workAuthorizations = tmpArr.flat();
    }
    return profileQuery;
  }


}
