import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * Used with [innerHTML]; bypass security and trust the given value to be safe HTML.
 * Only use this when the bound HTML is unsafe (e.g. contains <script> tags) and the
 * code should be executed. The sanitizer will leave safe HTML intact, so in most
 * situations this method should not be used.
 *
 * See [DOMSanitizer](https://angular.io/api/platform-browser/DomSanitizer) documentation.
 */
@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(
    private _sanitizer: DomSanitizer
  ) { }

  transform(value: any, args?: any): any {
    const txt = document.createElement('textarea');
    txt.innerHTML = value;
    // above section added because of a bug https://global-jira.randstadservices.com/browse/DF044-8364
    return this._sanitizer.bypassSecurityTrustHtml(txt.value);
  }

}
