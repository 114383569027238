import { Injectable } from '@angular/core';
import {Router, ActivatedRoute, DefaultUrlSerializer, NavigationEnd, NavigationStart} from '@angular/router';
import {Subject} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UrlStateService {
  serializer = new DefaultUrlSerializer();
  private state: any = {};
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;
  private subject = new Subject();
  public events = this.subject.asObservable();

  constructor(
    private _route: ActivatedRoute,
    private _router: Router
  ) {
    this.loadParams();
    this.currentUrl = this.historyUrl;
    (_router && _router.events || this.events).subscribe(event => {
      // console.log(event);
      if (event instanceof NavigationStart) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        if (this.previousUrl === this.currentUrl) {
          this.previousUrl = this.previousUrlLS;
        } else {
          this.previousUrlLS = this.previousUrl;
        }
      }
      if (event instanceof NavigationEnd) {
        if (!this.previousUrl || this.previousUrl === this.currentUrl) {
          this.previousUrl = this.previousUrlLS;
        }
      }
    });
  }

  overwrite (params: any, newHistoryEntry: boolean) {
    this.state = params;
    this.execute(newHistoryEntry);
  }

  patch (params: any, newHistoryEntry: boolean) {
    this.state = Object.assign(this.state, params);
    this.execute(newHistoryEntry);
  }

  remove (param: any, newHistoryEntry: boolean) {
    delete this.state[param];
    this.execute(newHistoryEntry);
  }

  execute (newHistoryEntry: boolean) {
    const url = this.historyUrl;
    this.currentUrl = url;
    if (newHistoryEntry) {
      window.history.pushState({}, '', url);
    } else {
      window.history.replaceState({}, '', url);
    }
  }

  get historyUrl() {
    for (let [key, value] of Object.entries(this.state || {})) {
      // yes I am deliberately using == instead of ===, in order to catch both null and undefined - JDS
      if (
        value == null || value === 'null' || value === 'undefined' ||
        (typeof value === 'string' && !value.trim()) ||
        (Array.isArray(value) && !value.length) || value === '[]' ||
        (value.constructor === Object && Object.keys(value).length === 0) || value === '{}'
      ) {
        delete this.state[key];
      }
    }
    return `${window.location.pathname}?${new URLSearchParams(this.state).toString()}`;
  }

  loadParams () {
    this._route.queryParamMap.subscribe(params => {
      this.state = JSON.parse(JSON.stringify(params)).params;
    });
  }

  getParams () {
    return JSON.parse(JSON.stringify(this.state));
  }

  getPreviousUrl() {
    return this.previousUrl;
  }

  get previousUrlLS() { return localStorage.getItem('previousUrl'); }

  set previousUrlLS(value: any) {localStorage.setItem('previousUrl', value); }
}
