import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { phrases } from '../../../../assets/files/restricted_phrases';
import {JobSkill} from "../../models/external/misc.model";

@Injectable({
  providedIn: 'root'
})
export class FormValidationService {

  temp: any;
  phraseWorker: Worker;
  found: any;
  phrases: any;

  constructor() {}

  static getErrorMessage(fieldAlias: string, validatorName: string, validatorValue?: any) {
    const errors: { [type: string]: string } = {
      // Generic, reusable errors.
      'required': 'is required',
      'zero': 'cannot be zero',
      'null': 'cannot be empty',
      'pastDate': 'cannot be in the past',
      'min': 'value is too low',
      'max': 'value is too high',
      'actualValue': `value is too low`,
      'requiredValue': `should be at least ${validatorValue}`,
      'length': 'must be 300 characters long',
      'blocked-word': 'has blocked word(s)',
      'notfound': 'was not found',
      // Specific errors.
      'rgsJobTitleTooLong': 'cannot exceed 50 characters',
      'rtJobTitleTooLong': 'cannot exceed 99 characters',
      'numOfOpenings': 'must be between 1 and 999',
      'minDate': fieldAlias === 'Start date' ? 'should be at least 180 ago' : 'should be after the start date',
      'maxDate': 'should be before the start date',
      'minCompGreaterThanMax': 'should be less than the maximum compensation',
      'minCompHourlyBaseline': 'should be $7.25 or above',
      'minCompYearlyBaseline': 'should be $20,000 or above',
      'maxCompHourlyBaseline': 'should be $7.26 or above',
      'maxCompYearlyBaseline': 'should be $20,001 or above',
      'maxCompensationHourlyRange': 'should be between [Min. Compensation - 999]',
      'maxCompensationYearlyRange': 'should be between [Min. Compensation - 999999.99]',
      'yearsOfExperience': 'must be less than 20',
      'numOfLinkedJobs': 'must be less than 30',
      'billRateLessThanMax': 'should be greater than the max pay rate',
      'maxRateGreaterThanBill': 'should be less than the bill rate',
      'maxRateMoreThanMaximumAcceptable': 'should be less than or equal to $999.00',
      'targetSalaryLessThanMax': 'should be greater than or equal to target salary',
      'targetSalaryGreaterThanMax': 'should be less than or equal to max salary',
      'targetSalaryLessThanMinimumAcceptable': 'should be greater than or equal to $10,000',
      'targetSalaryMoreThanMaximumAcceptable': 'should be less than $999,999.99',
      'maxSalaryGreaterThanTarget': 'should be less than or equal to max salary',
      'maxSalaryLessThanTarget': 'should be greater than or equal to target salary',
      // 'bullhorn_required': 'must include at least one bullhorn skill',
      'markupLessThanApproved': 'standard goal is 1.65. Anything below 1.60 requires manager approval',
      'heavyLiftingWeight': 'must be between 1 and 100 lbs',
      'standingHours': 'must be between 1 and 12 hours',
      'expectedOvertime': 'should be between 0 and 128 hours',
      'typeOfWorkEnvLength': 'should not exceed 300 characters',
      'zip': 'should be at least 5 numbers',
      'invalidWorkEnvironmentCharacter': 'invalid character only alphanumeric, slashes, spaces, and dashes are valid',
      'emptyString': 'is required',
      'payRate1-999': 'should be between 1 and 999',
      'payRate1-999999': 'should be between 1 and 999,999',
      'internalDescriptionLength': 'should not exceed 100000 characters',
      'rgsInternalNotesLength': 'should not exceed 254 characters',
      'rtInternalNotesLength': 'should not exceed 200000 characters',
      'rgsResponsibilitiesLength': 'should not exceed 1500 characters',
      'rtResponsibilitiesLength': 'should not exceed 9000 characters',
      'email': 'should be a valid email address',
      'emptyArray': 'should have one or more entries',
      'shouldBeUpdated': 'should be modified',
      'mustBeShorter': 'should be less than 160 characters'
    };

    // Returns the error message that you want displayed in the UI.
    return `${fieldAlias} ${errors[validatorName]}.`;
  }

  static setError(control: AbstractControl, key: string) {
    control.setErrors({
      ...control.errors,
      [key]: true
    });
  }

  static removeError(control: AbstractControl, key: any) {
    const errors = { ...control.errors };
    if (delete errors[key]) {
      if (Object.keys(errors).length) {
        control.setErrors(errors);
      } else {
        control.setErrors(null);
      }
    }
  }

  static markAsTouched(...controls: AbstractControl[]) {
    // Accepts a variable amount of controls as parameter(s).
    for (let i = 0; i < controls.length; i++) {
      controls[i].markAsTouched();
    }
  }

  static clearErrors(...controls: AbstractControl[]) {
    // Accepts a variable amount of controls as parameter(s).
    for (let i = 0; i < controls.length; i++) {
      controls[i].setErrors(null);
    }
  }

  static clearValidators(...controls: AbstractControl[]) {
    // Accepts a variable amount of controls as parameter(s).
    for (let i = 0; i < controls.length; i++) {
      controls[i].setValidators(null);
      controls[i].updateValueAndValidity();
    }
  }

  static setValidators(control: any, validators: ValidatorFn[]) {
    if (control.constructor === Array) {
      for (let i = 0; i < control.length; i++) {
        control[i].setValidators(Validators.compose(validators));
        control[i].updateValueAndValidity();
      }
    } else {
      control.setValidators(Validators.compose(validators));
      control.updateValueAndValidity();
    }
  }

  static validateNull(control: AbstractControl) {
    return (control.value === null)
      ? { 'null': true }
      : null;
  }

  static validateZero(control: AbstractControl) {
    return (control.value === 0)
      ? { zero: true }
      : null;
  }

  static validateSummary(control: AbstractControl) {
    return (control.value && control.value.length < 300)
      ? { tooShort: true }
      : null;
  }

  static validateNumOfOpenings(control: AbstractControl) {
    return (control.value < 1 || control.value > 999)
      ? { numOfOpenings: true }
      : null;
  }

  static validateNumOfLinkedJobs(control: AbstractControl) {
    return (control.value < 0 || control.value > 30)
      ? { numOfLinkedJobs: true }
      : null;
  }

  static validateZip(control: AbstractControl) {
    return (control.value && control.value.length < 5)
      ? { zip: true }
      : null;
  }

  // The static method is preventing importation of the object without node type definitions.
  static validateRestricted(control: AbstractControl) {
    if (control.value) {
      const found = Object.entries(phrases).some(([phrase, value]) => {
        const rx = new RegExp('\\b' + (phrase.toLowerCase()) + '\\b', 'gi');
        return control.value.toLowerCase().match(rx) && value[0] === 'Block';
      });
      return found ? { 'blocked-word': true } : null;
    }
  }

  static validateYearsOfExp(control: AbstractControl) {
    return (control.value > 20)
      ? { yearsOfExperience: true }
      : null;
  }

  static validateNonEmptyTrimmedString(control: AbstractControl) {
    return (!control.value || control.value.length == 0 || control.value.trim().length == 0)
      ? { emptyString: true }
      : null;
  }

  static validateNonEmptyString(control: AbstractControl) {
    return (control.value && !control.value.length)
      ? { emptyString: true }
      : null;
  }

  static validateTitleLength(lob: string) {
    return (control: AbstractControl) => {
      if (lob.checkLob('RGS')) {
        return (control.value?.length > 50)
          ? { rgsJobTitleTooLong: true }
          : null;
      } else if (lob.checkLob('RT', 'RE','CR')) {
        return (control.value?.length > 99)
        ? { rtJobTitleTooLong: true }
        : null;
      } else {
        return null;
      }
    };
  }

  static validateOneBhSkill(control: AbstractControl) {
    // const skills: JobSkill[] = control.value && control.value.length ? control.value : []
    return (control.value && control.value.length && control.value.find((skill: JobSkill) => skill.bullhorn_skill === true) === undefined)
      ? { bullhorn_required: true }
      : null
  }

  static validateNonEmptyArray(control: AbstractControl) {
    return (control.value && !control.value.length)
      ? { emptyArray: true }
      : null;
  }

  static touchAllFields(form: FormGroup): void {
    if (form && form.controls) {
      const controlKeys = Object.keys(form.controls);
      for (const c of controlKeys) {
        const control = form.controls[c];
        if (control) {
          FormValidationService.markAsTouched(control);
        }
      }
    }
  }

  static validateSpecialAndForbiddenChars(control: AbstractControl) {
    const special = /[?!$%@\^&\*;:{}='_`~\[\]]/g;
    const forbidden = /\bcareer fair\b|\bjob fair\b|\bopen house\b|\bcareer expo\b|\bopen interviews\b|\btemp\b|\btemp to hire\b|\bdirect hire\b|\bperm\b|\bpermanent\b|\bposition\b|\bpositions\b|\bnow\b|\bneeded\b|\bopportunity\b|\bopportunities\b|\bimmediate\b|\bimmediately\b|\bhiring\b|\bjobs\b|\bnow hiring\b|\blevel i\b|\blevel i or ii\b|\blevel ii\b|\bapply\b/g;
    let controlError = null;
    if(control.value){
       const foundChar = control.value.match(special) || [];
       const foundWord = control.value.match(forbidden) || [];
       if(foundChar.length || foundWord.length){
         controlError =  { forbiddenOrSpecial: true };
       }
    }
    return controlError;
  }

}
