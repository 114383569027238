import { Component, Input, OnInit } from '@angular/core';
import { TalentProcessService } from 'src/app/shared/services/talent-process/talent-process.service';
import { Application } from '../../../../../../shared/models/external/application.model';
import { Applications } from '@allbirds-ui/allbirds-types';
import ProcessStep = Applications.ProcessStep;

@Component({
  selector: 'app-process-stepper',
  templateUrl: './process-stepper.component.html',
  styleUrls: ['./process-stepper.component.scss']
})
export class ProcessStepperComponent implements OnInit {

  @Input() talent: Application;
  @Input() unfit = false;
  steps: any;
  tooltip: any;
  show = false;

  constructor(
    private _talentProcess: TalentProcessService
  ) { }

  ngOnInit() {
    if (this._talentProcess) {
      const talentProc = this._talentProcess;
      this.tooltip = talentProc.staticSteps ? talentProc.staticSteps.get(this.talent.randstad_process.lastProcessStep).stepTitle : undefined;
      this.steps = talentProc.staticStepsArray;
      this.show = true;
    }
  }

  isCurrentStep(stepField: ProcessStep) {
    return this.talent.randstad_process.lastProcessStep === stepField;
  }

  isSkipped(stepField: ProcessStep) {
    return this.talent.randstad_process[stepField] && this.talent.randstad_process[stepField].skipped;
  }

}

