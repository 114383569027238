import { createModel, Mapping, Model } from './model';
import { Prompts } from '@allbirds-ui/allbirds-types';
import { Serial } from './decorators/serial.decorator';
export import RawJobPrompt = Prompts.JobPrompt;

class JobPromptMapping extends Mapping<RawJobPrompt> {
  @Serial([JobPromptMapping]) children: JobPrompt[];
}

export interface JobPrompt extends Model<JobPromptMapping> {}

export const JobPrompt = createModel<JobPrompt>(JobPromptMapping);
