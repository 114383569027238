import {
  AfterViewChecked,
  AfterViewInit,
  Component, ElementRef,
  EventEmitter,
  Input, NgZone, OnChanges,
  OnDestroy,
  OnInit,
  Output, QueryList, SimpleChanges,
  TemplateRef,
  ViewChild, ViewChildren
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Applications} from '@allbirds-ui/allbirds-types';
import moment from 'moment';
import {Subscription} from 'rxjs';
import {IntersectionObserverEvent} from 'src/app/shared/components/talent-detail-pane/talent-process/recruitment-phases/recruitment-phases.interface';
import {SubmissionDetails} from 'src/app/shared/components/talent-detail-pane/talent-process/talent-process-modals/submit-to-am-modal/submit-to-am.types';
import {Application} from 'src/app/shared/models/external/application.model';
import {ProcessStatus, ProcessStep, UserPermissions} from 'src/app/shared/models/external/misc.model';
import {InternalSubmission} from 'src/app/shared/models/external/process/internal-submission.model';
import {NotificationData, NotificationTypes} from 'src/app/shared/models/internal/notifications-data.model';
import {ApiService} from 'src/app/shared/services/api/api.service';
import {AuthService} from 'src/app/shared/services/auth/auth.service';
import {CachedResume, CacheService} from 'src/app/shared/services/cache/cache.service';
import {MetricsService} from 'src/app/shared/services/metrics/metrics.service';
import {NotificationsService} from 'src/app/shared/services/notifications/notifications.service';
import {TalentProcessService} from 'src/app/shared/services/talent-process/talent-process.service';
import {ToastClass, ToastService} from 'src/app/shared/services/toast';
import {Job} from '../../../../../models/external/job.model';
import { BsModalRef } from "ngx-bootstrap/modal";
import {Profile} from '../../../../../models/external/profile.model';
import {ModalService} from '../../../../../services/modal/modal.service';
import {UtilityService} from '../../../../../services/utility/utility.service';
import {ConfirmModalComponent} from '../../../../confirm-modal/confirm-modal.component';
import {ConfirmModalConfig} from '../../../../confirm-modal/confirm-modal.interface';
import {FileUploadModalComponent} from '../../../../file-upload-modal/file-upload-modal.component';
import {SubmitToAmModalComponent} from '../../talent-process-modals/submit-to-am-modal/submit-to-am-modal.component';
import { INTG_STEPS } from 'src/app/shared/models/internal/process.model';
import SubmissionPayType = Applications.SubmissionPayType;
import SubmissionSource = Applications.SubmissionSource;
import {LoadingSpinnerService} from '../../../../../services/loading-spinner/loading-spinner.service';
import {RecruitmentPhasesService} from '../recruitment-phases.service'
import { GoogleProfile } from 'src/app/shared/models/external/google-profile.model';
import { DupTalentModalComponent } from 'src/app/shared/components/dup-talent-modal/dup-talent-modal.component';
import { JobDetailsShortlistService } from 'src/app/shared/services/job-details-shortlist/job-details-shortlist.service';
import { ShortlistActionResponse } from 'src/app/shared/models/internal/shortlist-action-response.interface';

@Component({
  selector: 'review-account-manager-phase',
  templateUrl: './review-account-manager.component.html',
  styleUrls: [
    './review-account-manager.component.scss',
    '../recruitment-phases.component.scss'
  ],
})
export class ReviewAccountManagerComponent implements OnInit, OnChanges, OnDestroy, AfterViewChecked {
  // Determines which template to show.
  shownPhase: TemplateRef<any>;
  previousShownPhase: TemplateRef<any>;

  //Determines if the caution modal for interviewed candidates
  showingCautionModal:boolean = false

  // Selected application.
  applicationSub: Subscription;
  application: Application;
  originalSubmissionIndex: number;
  bsModalRef: BsModalRef;

  // Form.
  /**
   * @deprecated by DF044-1836
   */
  submissionForm: FormGroup;

  resumeFilename = '';
  resume: File;

  statusLabel: string;
  // submissionDetails: SubmissionDetails;

  attachments: any;
  @Input() talentInterviewStatus:boolean
  @Input() job: Job;
  // Emits an event that triggers the markAsUnfit() call in recruitment-phases component.
  @Output() unfitClick = new EventEmitter();
  // Emits an event that triggers the skipToOffer() call in recruitment-phases component.
  @Output() skipToInterviewHMClick = new EventEmitter();

  // The different templates for the different phases.
  @ViewChild('amAwaitingSubmission', { static: true })
  amAwaitingSubmissionView: TemplateRef<any>;
  @ViewChild('prepareSubmission', { static: true })
  prepareSubmissionView: TemplateRef<any>;
  @ViewChild('prepareSolutionsSubmission', { static: true })
  prepareSolutionsSubmissionView: TemplateRef<any>;
  @ViewChild('submittedSolutionsRecruiterView', { static: true })
  submittedSolutionsRecruiterView: TemplateRef<any>;
  @ViewChild('submittedRecruiterView', { static: true })
  submittedRecruiterView: TemplateRef<any>;
  @ViewChild('submittedAMView', { static: true })
  submittedAMView: TemplateRef<any>;
  @ViewChild('submittedAMViewUpdate', { static: true })
  submittedAMViewUpdate: TemplateRef<any>;
  @ViewChild('submittedRecruiterViewUpdate', { static: true })
  submittedRecruiterViewUpdate: TemplateRef<any>;
  @ViewChild('submittedSolutionsView', { static: true })
  submittedSolutionsView: TemplateRef<any>;
  @ViewChild('submittedSolutionsViewUpdate', { static: true })
  submittedSolutionsViewUpdate: TemplateRef<any>;
  @ViewChild('postSolutionsViewUpdate', { static: true })
  postSolutionsViewUpdate: TemplateRef<any>;
  @ViewChild('postSolutionsReview', { static: true })
  postSolutionsReview: TemplateRef<any>;
  @ViewChild('ctaContainer', {static: false}) ctaContainer: ElementRef;
  @ViewChild('ctaContainerBoundry', {static: false}) ctaContainerBoundry: ElementRef;
  searchResults: { total: number; };
  skippedApplication: Application;

  get submissionAttachmentURIs() {
    return this.application?.randstad_process?.internalSubmission?.submissionAttachmentURI?.split(',');
  }

  constructor(
    private _authService: AuthService,
    private _talentProcess: TalentProcessService,
    private _bsModalService: ModalService,
    private _formBuilder: FormBuilder,
    private _toast: ToastService,
    private zone: NgZone,
    private _shortlist: JobDetailsShortlistService,
    private _cache: CacheService,
    private _metrics: MetricsService,
    private _notificationService: NotificationsService,
    private _api: ApiService,
    private _router: Router,
    private _loading: LoadingSpinnerService,
    private _utility: UtilityService,
    private _recruitmentPhasesService: RecruitmentPhasesService,
  ) {}

  ngOnInit() {
    // this.createForm();
    this.listenToApplication();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.job && !changes.job.firstChange) {
      this.job = changes.job.currentValue;
      this.listenToApplication();
    }
  }

  ngOnDestroy() {
    this.applicationSub.unsubscribe();
  }

  ngAfterViewChecked() {
    if (this.previousShownPhase !== this.shownPhase) {
      this.previousShownPhase = this.shownPhase;
      if (this.ctaContainer && this.ctaContainer.nativeElement) {
        const elements = [
          this.ctaContainer.nativeElement,
          this.ctaContainerBoundry.nativeElement
        ];
        this._talentProcess.setupIntersectionObserver(elements);
      }
    }
  }

  /**
   * @deprecated by DF044-1836
   */
  createForm() {
    this.submissionForm = this._formBuilder.group({
      submission: [null, [Validators.required]]
    });
  }

  listenToApplication() {
    this.applicationSub = this._talentProcess.selectedApplication.subscribe(
      (app: any) => {
        if (app) {
          this.application = app;
          if (app.randstad_process) {
            const { internalSubmission } = app.randstad_process;
            this.attachments = this._utility.extractAttachments(app);

            this.statusLabel = this._talentProcess.getLabel(
              app,
              this._authService.user.Source,
              'process',
              this.job
            );

            if(this.job.employmentTypes.toString() == 'SOLUTIONS') {
               this.setViewForSolutionsJob(internalSubmission, this._authService.user.Permissions,
                     this.application.randstad_process.lastProcessStatus);
            } else {
              if (internalSubmission && internalSubmission.outcome) {
                this.setViewByStatus(internalSubmission.outcome);
              } else {
                if (
                  this._authService.user.Permissions.submitCandidateToAccountManager
                ) {
                  this.shownPhase = this.prepareSubmissionView;
                } else {
                  this.shownPhase = this.amAwaitingSubmissionView;
                }
              }
            }
          }
          if (app.randstad_process.internalSubmission && app.randstad_process.internalSubmission.submissionAttachmentURI) {
            this.setResumeLink(app.randstad_process.internalSubmission.submissionAttachmentURI);
          }
        }
      }
    );
  }

  setViewForSolutionsJob(internalSubmission: any, permission: UserPermissions, lastProcessStatus: ProcessStatus ) {
    if (this.shownPhase) {
      this.shownPhase = null;
    }
    let shownPhaseText = "";
    //There is no outcome so this is the first view
    if(!internalSubmission || !internalSubmission.outcome) { //Recruiter and RT can see this step
      if (permission.submitCandidateToAccountManager) {
        this.shownPhase = this.prepareSolutionsSubmissionView;
        shownPhaseText = "prepareSolutionsSubmissionView";
      }
    }
    else {
		  const outcome = internalSubmission.outcome;
		  //There is an outcome so this is step 2
		  //The view is decided based on outcome and user permissions
		  /* There are 3 possible solutions status:  */
		  if (permission.createJob && permission.submitCandidateToAccountManager) { //RT Full Desk View
		    if (outcome === 'submitted') {
          const isReviewCompleted = this.application?.randstad_process?.internalSubmission?.priorSubmissions?.find((p) => p.externalId === ProcessStatus.SS_REVIEW_COMPLETED) || false;
          switch(lastProcessStatus) {
            case ProcessStatus.RA_WAITING_AM_FEEDBACK:
            case ProcessStatus.RA_WAITING_SS_FEEDBACK:
              this.shownPhase = this.submittedSolutionsView;
              shownPhaseText = "submittedSolutionsView";
              break;
            case ProcessStatus.RA_AM_UPDATE_SUB:
                if(this.application?.randstad_process?.internalSubmission?.priorSubmissions?.length > 0 && !isReviewCompleted){
                  this.shownPhase = this.submittedSolutionsViewUpdate;
                  this.originalSubmissionIndex = this.application.randstad_process.internalSubmission.priorSubmissions.length - 1;
                }
                else {
                  this.shownPhase = this.postSolutionsViewUpdate;
                  this.originalSubmissionIndex = this.application.randstad_process.internalSubmission.priorSubmissions.length - 1;
                }
              break;
            case ProcessStatus.SS_REVIEW_COMPLETED:
              this.shownPhase = this.postSolutionsReview;
              shownPhaseText = "postSolutionsReview";
              break;
			    }
		    }
		    else {
          this.shownPhase = this.amAwaitingSubmissionView;
          shownPhaseText = "amAwaitingSubmissionView";
		    }
		  }
		  else if (permission.submitCandidateToAccountManager || permission.submitCandidateToHiringManager) { // Recruiter && AM view View
		    if (outcome === 'submitted') {
          const isReviewCompleted = this.application?.randstad_process?.internalSubmission?.priorSubmissions?.find((p) => p.externalId === ProcessStatus.SS_REVIEW_COMPLETED) || false;
			    switch(lastProcessStatus) {
			      case ProcessStatus.RA_WAITING_AM_FEEDBACK:
            case ProcessStatus.RA_WAITING_SS_FEEDBACK:
              this.shownPhase = this.submittedSolutionsRecruiterView;
              shownPhaseText = "submittedSolutionsRecruiterView";
              break;
            case ProcessStatus.RA_AM_UPDATE_SUB:
              if(this.application?.randstad_process?.internalSubmission?.priorSubmissions?.length > 0 && !isReviewCompleted){
                this.shownPhase = this.submittedRecruiterViewUpdate;
                this.originalSubmissionIndex = this.application.randstad_process.internalSubmission.priorSubmissions.length - 1;
              }
              else {
                this.shownPhase = this.postSolutionsViewUpdate;
                this.originalSubmissionIndex = this.application.randstad_process.internalSubmission.priorSubmissions.length - 1;
              }
              break;
            case ProcessStatus.SS_REVIEW_COMPLETED:
              this.shownPhase = this.postSolutionsReview;
              shownPhaseText = "postSolutionsReview";
              break;
          }

        }

		  }
		  else {    // This is the general view for any other permissions
        this.shownPhase = this.amAwaitingSubmissionView;
        shownPhaseText = "amAwaitingSubmissionView";
		  }
    }
  }

  setViewByStatus(outcome: string) {
    const { Permissions } = this._authService.user;
    if (Permissions.createJob && Permissions.submitCandidateToAccountManager) { /*Full Desk RT, only role with this combination of permissions */
      switch (outcome) {
        case 'submitted':
          this.shownPhase = this.submittedAMView;
          if(this.application?.randstad_process?.internalSubmission?.priorSubmissions?.length > 0 && this.application?.randstad_process?.lastProcessStatus==='RA_AM_UPDATE_SUB'){
            this.shownPhase = this.submittedAMViewUpdate;
            this.originalSubmissionIndex = this.application.randstad_process.internalSubmission.priorSubmissions.length - 1;
          }
          break;
        default:
          this.shownPhase = this.amAwaitingSubmissionView;
      }
    } else if (Permissions.submitCandidateToAccountManager) {  /* Recruiter permission */
      switch (outcome) {
        case 'submitted':
          this.shownPhase = this.submittedRecruiterView;
          if(this.application?.randstad_process?.internalSubmission?.priorSubmissions?.length > 0 && this.application?.randstad_process?.lastProcessStatus==='RA_AM_UPDATE_SUB'){
            this.shownPhase = this.submittedRecruiterViewUpdate;
            this.originalSubmissionIndex = this.application.randstad_process.internalSubmission.priorSubmissions.length - 1;
          }
          break;
        default:
          this.shownPhase = this.prepareSubmissionView;
      }
    } else {
      switch (outcome) {
        case 'submitted':
          this.shownPhase = this.submittedAMView;
          if(this.application?.randstad_process?.internalSubmission?.priorSubmissions?.length > 0 && this.application?.randstad_process?.lastProcessStatus==='RA_AM_UPDATE_SUB'){
            this.shownPhase = this.submittedAMViewUpdate;
            this.originalSubmissionIndex = this.application.randstad_process.internalSubmission.priorSubmissions.length - 1;
          }
          break;
        default:
          this.shownPhase = this.amAwaitingSubmissionView;
      }
    }
  }

  setResumeLink(resumeUrl: string) {
    if (resumeUrl) {
      const fileIdx = resumeUrl.lastIndexOf('/');
      const fullFileName = resumeUrl.substring(fileIdx + 1);
      this.resumeFilename = fullFileName.replace(/^((?:[0-9]*)_(?:[0-9]*)_)/, '');
    }
  }

  emitUnfitClick() {
    this.unfitClick.emit();
  }

  /**
   * @deprecated by DF044-1836
   */
  openFileUploadModal() {
    this._bsModalService.show(FileUploadModalComponent);
  }

  getFullnameFromProfile(profile: Profile) {
    // comment
    let fullName: string;
    // const profile = this._talentProcess.selectedProfileSubject.value;
    if (profile.personNames && profile.personNames.length) {
      fullName = `${profile.personNames[0].structuredName.givenName} ${profile.personNames[0].structuredName.familyName}`;
    }
    return fullName;
  }

  fetchProfile(profileId: string) {
    return this._cache.loadProfile(profileId);
  }

  async skipToStep(talent: Profile) {
    let isSolutionsJob: boolean = this.job && this.job.employmentTypes &&
      this.job.employmentTypes.toString() === 'SOLUTIONS';
    this._metrics.addEventToQueue(this.skippedApplication, `candidate was manually advanced to the Recruiter interview phase.`);
    return this._talentProcess.skipStepAndGetTalentApplication(talent.profileId, this.job.allbirds_metadata.allbirds_job_id, this.skippedApplication, 2, isSolutionsJob)
      .then((app: Application) => {
        this.application = app;
        this.completeAccountManagerSubmission(talent);
        this._loading.hide();
      })
  }


  addToShortlist(talent: any) {
    this.zone.run(() => {
      this._loading.show();
      this._shortlist.add(talent)
        .then((data: ShortlistActionResponse) => {
          this._shortlist.updateInMemoryApplications([data.application]);
          this.skippedApplication = data.application;
          this._toast.showToastNoTranslation(data.message);
          this.skipToStep(talent);
          // this.close();
        })
        .catch((err: any) => {
          this._loading.hide();
          this._toast.showToastNoTranslation(err.message);
        });
    });
  }

  completeAccountManagerSubmission(profile: any) {
    const resumeInfo = this._getFakeProfileResumeFileFromLink(profile);
    const candidateFullName = this.application.randstad_process.profileInfo
      ? this.application.randstad_process.profileInfo.candidateFullName
      : this.getFullnameFromProfile(profile);
    const initialState = {
      submitFn: this.handleModalSubmission.bind(this),
      candidateFullName: candidateFullName,
      customer_name: this.job.allbirds_metadata.customer_name,
      hiringManager: this.job.hiringManager,
      title: this.job.title,
      jobId: this.job.allbirds_metadata.front_office_id,
      addedBy: this._authService.user.FullName,
      resumeFromLink: resumeInfo
        ? {
          name: resumeInfo ? resumeInfo.fileName : '',
          type: resumeInfo ? resumeInfo.fileType : ''
        }
        : null
    };
    this._bsModalService.show(SubmitToAmModalComponent, { initialState });

  }

  submitToAccountManagerClick() {
    if (this._recruitmentPhasesService.talentInterviewStatus) {
      this._recruitmentPhasesService.openWarningModal()
    } else {
      if (this._authService.user.Source.checkLob('RT', 'RE','CR')) {
        const isProfileValid = this._recruitmentPhasesService.checkIfProfileIsDataValid();
        if (isProfileValid === true) {
          return this.openDupTalentModal('accountManager');
        }
      }
    }
  }

  openDupTalentModal(type: string) {
    this._loading.show();
    const profile = this._talentProcess.selectedProfileSubject.value;

    if (profile.isProfile()) {
      const familyName = profile.familyName;
      const givenName = profile.givenName;
      const lob = profile.lob;
      const phoneNumbers = profile.candidatePhoneNumber;
      const talentId = profile.talentId;
      const emailAddresses = profile?.emailAddresses?.length > 0 && profile?.emailAddresses[0].emailAddress || [];
        const body = { familyName, givenName, lob, phoneNumbers, talentId, emailAddresses: [emailAddresses] }
        
      this._api.getDuplicateTalent(body)
        .subscribe(data => {
          this._loading.hide();
          if ((data.length === 0 || data.length === 1) && type == 'accountManager') {
            this.completeAccountManagerSubmission(profile);
            return;
          }
          this.searchResults = {
            total: data.length
          }
          const selectedProfile = data.find((p) => p.externalId === profile.externalId);
          const initialState = { talent: selectedProfile, searchResults: this.searchResults, duplicateTalent: data, type };
          this.bsModalRef = this._bsModalService.show(DupTalentModalComponent, { initialState, class: 'modal-xl' });
          this.bsModalRef.content.submitAccountManagerEvent.subscribe((res: any) => {
            if (res.profileId === this.application.profileId) {
              this.completeAccountManagerSubmission(profile);
              return;
            }
            const selectedProfile = this.fetchProfile(res.profileId);
            this.addToShortlist(selectedProfile);

          });
        });
    }
  }

  /**
   * If the talent profile has a resume link, create the object needed for
   * display in the submit-to-am-modal
   * @returns {null | {fileName: string, fileType: string}}
   */
  _getFakeProfileResumeFileFromLink(profile: Profile) {
    // const profile = this._talentProcess.selectedProfileSubject.value;
    const resumeLink = profile.allbirds_metadata.resumeLink;
    if (resumeLink) {
      const resumeLinkArr = resumeLink.split('/');
      const fileName = resumeLinkArr.pop();
      const fileType = `application/${fileName.split('.').pop()}`;
      return {fileName, fileType};
    }
    return null;
  }

  handleModalSubmission(payload: SubmissionDetails) {
    this._loading.show();
    const {internalSubmission} = this.application.randstad_process;
    this.application.randstad_process.internalSubmission = internalSubmission ? internalSubmission.clone() : new InternalSubmission();
    this.application.randstad_process.internalSubmission.submissionPayRate = payload.payRate;
    this.application.randstad_process.internalSubmission.submissionPayType = payload.payType as SubmissionPayType;
    this.application.randstad_process.internalSubmission.submissionSource = payload.submissionSource as SubmissionSource;
    this.application.randstad_process.internalSubmission.submission = payload.emailBody;

    const fileURIs = payload?.files?.map(file => file?.path);

    // Join URIs into one string separated by commas because submissionAttachmentURI is typed as 'string'
    this.application.randstad_process.internalSubmission.submissionAttachmentURI = fileURIs?.join(',');
    this.application.randstad_process.attachments = this.application.randstad_process.attachments || [];
    try {
      this.application.randstad_process.attachments.push(...payload?.files);
    } catch (e) {
      console.log("handleModalSubmission production issue" + e);
      console.log("handleModalSubmission production issue application object" + this.application);
    }

    this.submitToAccountManager();
  }

  submitToAccountManager(pass?: boolean) {
    const isProfileValid = this._recruitmentPhasesService.checkIfProfileIsDataValid();
    if (isProfileValid === true || pass) {
      const key = this.application.randstad_process.lastProcessStep;

      let submittedToID = '';
      if (
        this.job &&
        this.job.allbirds_metadata &&
        this.job.allbirds_metadata.published_by_user_front_office_id
      ) {
        submittedToID = this.job.allbirds_metadata.published_by_user_front_office_id;
      }

      const body = this.getUpdateApplicationSubmissionBody(submittedToID);
      // console.log('ReviewAccountManager.submitToAccountManager, body=', body);
      if (this.job.employmentTypes.toString() === 'SOLUTIONS') {
        body.randstad_process.apply({
          lastProcessStatus: ProcessStatus.RA_WAITING_SS_FEEDBACK
        });
      }
      const msgs = {
        success: body.randstad_process.lastProcessStatus === ProcessStatus.RA_WAITING_SS_FEEDBACK
          ? 'review-account-manager.reasoning_solution_support'
          : 'review-account-manager.submission_success',
        error: 'review-account-manager.submission_error'
      };
      this.updateApplication(body, key, true, true, msgs, true);
    }
  }

  getUpdateApplicationSubmissionBody(submittedToID: string) {
    // console.log('ReviewAccountManger.getUpdateApplicationSubmissionBody, submittedToID=', submittedToID);
    const now = moment();
    const body = this.application.clone();
    body.randstad_process.apply({
      lastProcessStatus: ProcessStatus.RA_WAITING_AM_FEEDBACK,
      internalSubmission: new InternalSubmission({
        submission: this.application.randstad_process.internalSubmission.submission,
        submittedByID: this._authService.user.BackOfficeID,
        // submittedTo: { fullName: this.job.allbirds_metadata.published_by_user, jobTitle: 'Account Manager' },
        ...(submittedToID && {submittedToID}),
        submissionDate: now,
        submissionAttachmentURI: this.application.randstad_process.internalSubmission.submissionAttachmentURI,
        outcome: 'submitted',
        outcomeDate: now,
        submissionSource: this.application.randstad_process.internalSubmission.submissionSource,
        submissionPayType: this.application.randstad_process.internalSubmission.submissionPayType,
        submissionPayRate: this.application.randstad_process.internalSubmission.submissionPayRate
      })
    });

    return body;
  }

  updateApplication(
    body: Application,
    key: Applications.ProcessStep,
    sendNotification: boolean,
    updateMetrics: boolean,
    msgs: {success: string, error: string},
    doSubmission: boolean
  ) {
    const intgSteps: INTG_STEPS[] = doSubmission ? [INTG_STEPS.SUBMISSION] : [];
    this._talentProcess
      .updateApplication(body, intgSteps, key)
      .then(() => {
        if (updateMetrics) {
          //need to account this for update_submission
          this._metrics.addEventToQueue(this.application, `candidate was submitted to the account manager`);
        }
        this._toast.showToast(msgs.success, {cssClass: ToastClass.SUCCESS});
        if (sendNotification) {
          this.sendNotification();
        }
      })
      .catch(err => {
        this._toast.showToast(msgs.error, {cssClass: ToastClass.DANGER});
        console.error(err);
      }).finally(() => this._loading.hide());
  }

  sendNotification() {
    const {
      published_by_user,
      published_by_user_email,
      front_office_id
    } = this.job.allbirds_metadata;
    const mentions = UtilityService.getMentionsRecipients(this.application.randstad_process.internalSubmission.submission);
    if (
     (published_by_user !== this._authService.user.FullName && this._authService.user.Source.checkLob('RT', 'RE','CR') && front_office_id)
     || (mentions && mentions.length)) {
      const body = this.getNotificationObj(published_by_user, published_by_user_email);
      const sub = this._notificationService
        .sendNotification(body)
        .subscribe(() => {
          console.log('sent');
          sub.unsubscribe();
        });
    }
  }

  getNotificationObj(published_by_user: string, published_by_user_email: string): NotificationData {
    const profile = this._talentProcess.selectedProfileSubject.value;
    const currentOrFirstEmployer = this._recruitmentPhasesService.getCurrentOrFirstEmployer();
    const mentions = UtilityService.getMentionsRecipients(this.application.randstad_process.internalSubmission.submission);
    for (let i = mentions.length; i--;) {
      if (mentions[i].recipientEmail === published_by_user_email) {
        mentions.splice(i, 1);
      }
    }
    let emailAddressOnToLine = this.job.allbirds_metadata.published_by_user? [{
      recipient: this.job.allbirds_metadata.published_by_user,
      recipientEmail: this.job.allbirds_metadata.published_by_user_email
    }]: mentions.splice(0,1)
    return {
      notificationType: NotificationTypes.SUBMISSION_ADDED,
      notificationObject: {
        title: this.job.internalTitle,
        candidateFullName: this.application.randstad_process.candidateFullName,
        published_by_user,
        published_by_user_email,
        allbirds_job_id: this.job.allbirds_metadata.allbirds_job_id,
        executing_action_user: this._authService.user.FullName,
        executing_action_user_email: this._authService.user.EmailAddr,
        customer_name: this.job.allbirds_metadata.customer_name,
        front_office_id: this.job.allbirds_metadata.front_office_id,
        submission: this.application.randstad_process.internalSubmission.submission,
        submissionSource: this.application.randstad_process.internalSubmission.submissionSource,
        submissionPayType: this.application.randstad_process.internalSubmission.submissionPayType,
        submissionPayRate: this.application.randstad_process.internalSubmission.submissionPayRate,
        submissionResumeUrl: this.application.randstad_process.internalSubmission.submissionAttachmentURI ? encodeURI(this.application.randstad_process.internalSubmission.submissionAttachmentURI) : '',
        user: {...this._authService.user},
        emailRecipients: emailAddressOnToLine,
        emailCc:[
          ...mentions
        ],
        employeeType: profile.workPreference.employeeTypes && profile.workPreference.employeeTypes.length ? profile.workPreference.employeeTypes[0] : '',
        vendorCompanyName: profile.vendorCompanyName,
        vendorCompanyRandstadApproved: profile.vendorCompanyRandstadApproved,
        transferToDBC: profile.transferToDbc,
        desiredLocation: profile.workPreference.jobAddresses && profile.workPreference.jobAddresses.length ? profile.workPreference.jobAddresses[0].structuredAddress.administrativeArea : '',
        dateAvailable: profile.dateAvailable,
        currentCompany: currentOrFirstEmployer ? currentOrFirstEmployer.employmentRecord.employerName : '',
        jobTitle: currentOrFirstEmployer ? currentOrFirstEmployer.employmentRecord.jobTitle : '',
        yearsOfExperience: profile.yearsExperience,
        applicationObject: this.application,
        contextUrl: this._utility.getContextUrl(location.pathname, location.search),
        vms_req_id: this.job.allbirds_metadata.vms_req_id, 
        hiringManager: this.job.hiringManager,
      }
    };
  }

  advanceStep(isSolutions: boolean = false) {
    const key = this.application.randstad_process.lastProcessStep;
    const now = moment();
    const body = this.application.clone();
    body.randstad_process.apply({
      lastProcessStep: ProcessStep.REVIEW_HM, // "clientSubmission",
      lastProcessStepNum: this.application.randstad_process.lastProcessStepNum + 1,
      lastProcessStepDate: now,
      lastProcessStatus: ProcessStatus.RH_SUBMIT_TO_HM
    });
    if (isSolutions) {
      body.randstad_process.apply({
        lastProcessStatus: ProcessStatus.SS_SUBMIT_TO_HM
      });
    }
    body.randstad_process.internalSubmission.apply({
      submittedTo: { fullName: this.job.allbirds_metadata.published_by_user,
      jobTitle: 'Account Manager' },
      outcome: 'proceed',
      outcomeDate: now
    });

    const msgs = {
      success: 'review-account-manager.candidate_client',
      error: 'review-account-manager.candidate_client_error'
    };
    this.updateApplication(body, key, false, false, msgs, false);
  }

  emitUpdateSubmissionClick(isSolutions: boolean = false) {
    this.unfitClick.emit({solutions: isSolutions, updateSubmission: true, internalSubmission: true});
}


  skipToInterviewHMStep(){
    const isProfileValid = this._recruitmentPhasesService.checkIfProfileIsDataValid();
    if (isProfileValid === true) {
      this.skipToInterviewHMClick.emit();
    }
  }

  advanceSolutionsStatus() {
    const key = this.application.randstad_process.lastProcessStep;
    const now = moment();
    const body = this.application.clone();
    body.randstad_process.apply({
      lastProcessStatus: ProcessStatus.SS_REVIEW_COMPLETED
    });
    body.randstad_process.internalSubmission.apply({
      submittedTo: { fullName: this.job.allbirds_metadata.published_by_user, jobTitle: 'Account Manager' },
      outcome: 'submitted',
      outcomeDate: now
    });
    const intgSteps : INTG_STEPS[] = [INTG_STEPS.SUBMISSION];
    this._talentProcess
      .updateApplication(body, intgSteps, key)
      .then(() => {
        this._toast.showToast('review-account-manager.review_solutions_done', { cssClass: ToastClass.SUCCESS });
      })
      .catch(err => {
        this._toast.showToast('review-account-manager.candidate_client_error', { cssClass: ToastClass.DANGER });
        console.error(err);
      });
  }
}
