import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'possessive'
})
export class PossessivePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (value.charAt(value.length - 1) === 's') {
      return `${value}'`;
    } else {
      return `${value}'s`;
    }
  }

}
