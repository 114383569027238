<h3 class="text-primary mt-4" *ngIf="loading">
  {{'conversation.loading_questions' | translate}}
</h3>

<div class="spinner-container" *ngIf="loading">
  <vertical-align>
    <div class="spinner-border custom-spinner text-primary" role="status">
      <span class="sr-only text-center">
        {{'conversation.loading...' | translate}}</span>
    </div>
  </vertical-align>
</div>

<div *ngIf="!loading">
  <div *ngIf="knockoutPrompts && knockoutPrompts.length">
    <h3 class="text-primary mt-4">
      {{ 'conversation.knockout_questions' | translate}}
      <ng-container *ngTemplateOutlet="conversationBadge"></ng-container>
    </h3>
    <div [className]="modal ? 'modal-view' : 'inline-view'">
      <div *ngFor="let prompt of knockoutPrompts; let i = index">
        <ng-container *ngTemplateOutlet="conversationPrompt; context: {prompt: prompt, i: i}"></ng-container>
      </div>
    </div>
  </div>

  <h3 class="text-primary mt-4">
    {{'conversation.screening_questions' | translate}}
    <!-- RCE logic -->
    <ng-container *ngIf="conversation.rce">
      <span [class]="badgeClass + ' screening-badge'">{{ badgeStatus }}</span>
    </ng-container>
    <!-- End RCE logic -->

    <!--
      This logic is for GCE conversations...
      TODO: Should be removed once GCE is fully phased out and we reach a reasonable point where all viewed applications are RCE.
    -->
    <ng-container *ngIf="!conversation.rce">
      <span
        *ngIf="
      !conversation.knockoutTriggered &&
      conversation.status === conversationStatuses.FINISHED
    "
        class="badge-success screening-badge"
      >{{'conversation.pass' | translate}}</span>
      <span *ngIf="
      conversation.knockoutTriggered &&
      conversation.status === conversationStatuses.FINISHED
    "
            class="badge-danger screening-badge"
      >{{'conversation.fail' | translate}}</span>
      <span *ngIf="conversation.status !== conversationStatuses.FINISHED"
            class="badge-warning screening-badge"
      >{{'conversation.in_progress' | translate}}</span>
    </ng-container>
  </h3>
  <!-- End GCE logic -->

  <div [className]="modal ? 'modal-view' : 'inline-view'">
    <div *ngFor="let prompt of conversationPrompts; let i = index">
      <ng-container *ngTemplateOutlet="conversationPrompt; context: {prompt: prompt, i: i}"></ng-container>
    </div>
  </div>
</div>

<ng-template #conversationBadge>
  <span
    *ngIf="!conversation.knockoutTriggered && conversation.status === conversationStatuses.FINISHED"
    class="badge-success screening-badge">
      pass
    </span>
  <span
    *ngIf="conversation.knockoutTriggered && conversation.status === conversationStatuses.FINISHED"
    class="badge-danger screening-badge">
      fail
    </span>
  <span
    *ngIf="conversation.status !== conversationStatuses.FINISHED"
    class="badge-warning screening-badge">
      in progress
    </span>
</ng-template>

<ng-template #conversationPrompt let-prompt="prompt" let-i="i">
  <img
    *ngIf="prompt.promptResponses.length > 0 && !toggledQuestions[i]"
    class="toggle-icon"
    src="assets/icons/plus.svg"
    alt="toggle"
    (click)="toggleQuestion(i)"/>
  <img
    *ngIf="prompt.promptResponses.length > 0 && toggledQuestions[i]"
    class="toggle-icon"
    src="assets/icons/min.svg"
    alt="toggle"
    (click)="toggleQuestion(i)"/>
  <div
    *ngIf="!removeCEPrompt(prompt.promptTemplateName)"
    [className]="i > 0 ? 'divider' : ''"
    class="question">
    {{ prompt.text }}
  </div>

  <div *ngIf="prompt.responseTexts.length > 0">
    <img
      *ngIf="prompt.knockoutTriggered"
      class="answer-icon"
      src="assets/icons/alert-red.svg"
      alt="knockoutTriggered"/>
    <img
      *ngIf="!prompt.knockoutTriggered && prompt.promptResponses.length === 0"
      class="answer-icon"
      src="assets/icons/bullet.svg"
      alt="last"
     />
     <img
      *ngIf="!prompt.knockoutTriggered && prompt.promptResponses.length > 0"
      class="answer-icon"
      src="assets/icons/check-green.svg"
      alt="completed"
    />
    <div class="answer">{{ prompt.responseTexts | array }}</div>

    <div *ngIf="prompt.failedFollowups > 0" class="failed-count text-danger">
      {{ prompt.failedFollowups }} wrong follow-up question.
    </div>

    <div [className]="toggledQuestions[i] ? 'expanded' : 'collapsed'"
      *ngFor="let childPrompt of prompt.promptResponses; let j = index"
    >
      <div class="question">{{ childPrompt.text }}</div>
      <div
      *ngIf="
        childPrompt.responseTexts && childPrompt.responseTexts.length > 0
      "
    >
      <img
        *ngIf="childPrompt.knockoutTriggered"
          class="answer-icon"
          src="assets/icons/alert-red.svg"
          alt="knockoutTriggered"
         />
        <img
          *ngIf="
            !childPrompt.knockoutTriggered &&
            prompt.promptResponses.length - 1 === j
          "
          class="answer-icon"
          src="assets/icons/bullet.svg"
          alt="last"
         />
         <img
         *ngIf="
           !childPrompt.knockoutTriggered &&
           prompt.promptResponses.length - 1 > j
         "
         class="answer-icon"
         src="assets/icons/check-green.svg"
         alt="completed"
       />
       <div class="answer">{{ childPrompt.responseTexts | array }}</div>
      </div>
    </div>
  </div>
</ng-template>
