<div class="modal-header">
  <button type="button" class="btn-reset" aria-label="Close" (click)="bsModalRef.hide()">
    <i class="icon-WL_icon_close"></i>
  </button>
</div>

<div class="modal-body flex-column" style="padding-top: 0!important;">
  <div style="width: 103%; position: sticky; top:0;position: -webkit-sticky; z-index: 10000; padding-bottom: 10px; background-color: white">
    <h2 class="mb-4 title">
      {{personName | titlecase | possessive}} {{'talent-metrics-modal-title' | translate}}
    </h2>
    <div class="body-container flex-column">
      <div class="filter-container flex-row">
        <button
          *ngFor="let filter of filters"
          class="btn btn-round"
          [class.active]="filter.label === activeFilter"
          (click)="toggleFilter(filter.label)">
          {{filter.label}} ({{filter.count}})
        </button>
      </div>
    </div>
  </div>
  <ng-container [ngTemplateOutlet]="getActivitiesTemplate()"></ng-container>
  <!--    <ng-container [ngTemplateOutlet]="getActivitiesTemplate()"></ng-container>-->


</div>

<!-- Top level templates -->
<!-- No Activities Template. Shown when there are no activities to show -->
<ng-template #NoActivities>
  <div class="no-metrics-container flex-column">
    <img src="assets/images/ghost.svg" class="no-metrics-ghost">
    <h1>No history</h1>
    <div class="no-metrics-sub-title">
      {{'talent-metrics-modal-no-activities' | translate}}
    </div>
  </div>
</ng-template>


<ng-template #DateTime let-activity="activity" let-idx="idx">
  <span *ngIf="activity.type !== 'Assignment'">
    {{activity.activityTimestamp | date : "mediumDate"}}  {{'at '+ (activity.activityTimestamp | date : "shortTime")}}
  </span>
  <span *ngIf="activity.type === 'Assignment'">
   {{activity.data.startDate | abDate}} - {{activity.data.endDate | abDate}}
  </span>
</ng-template>

<ng-template #JobClientName let-activity="activity" let-idx="idx">
  <div [ngClass]="isActivityAssignment(activity) ? 'activity-job-noLink' : 'activity-job'" (click)="goToJobDetails(activity)">
    <div class="job-client">
      <div>
        <span [ngClass]="isActivityAssignment(activity) ? 'activity-job-title-noLink' : 'activity-job-title'" [appLimitHtml]="20" [tooltip]="activity.jobTitle ? activity.jobTitle : 'Job details are not available'" placement="bottom">{{activity.jobTitle ? activity.jobTitle : '-'}}</span>
        <span class="activity-job-employer" [appLimitHtml]="20" [tooltip]="activity.jobEmployer ? activity.jobEmployer : 'Job details are not available'"  placement="bottom">{{activity.jobEmployer ? 'at ' + activity.jobEmployer : '-'}}</span>
      </div>
      <div *ngIf="isAssignmentRawLabel(activity)" class="raw-label">
        RaW
      </div>
    </div>
  </div>
</ng-template>

<ng-template #Recruiter let-activity="activity" let-idx="idx">
  <div class="container-fluid no-gutters" style="width: 100px" *ngIf="activity.user">
    <div class="row no-gutters">
      <div class="col-6">
        <app-user-circle [user]="activity.user || {}" [useBodyAsContainer]="true" showPopover popoverPlacement="top"></app-user-circle>
      </div>
      <div class="col-6 align-content-center">
        <span class="activity-user-name">{{activity.user ? activity.user.FirstName + " " + activity.user.LastName : 'user unknown'}}</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #Note let-activity="activity" let-idx="idx">

  <div class="container no-gutters" style="width: 400px;">
    <div #NoteBody class="row no-gutters overflow-auto" hideChevron [ngClass]="{'expanded': activity.expanded}">
      <div class="col-11 overflow-hidden">
        <span class="overflow-hidden float-left" [innerHTML]="activity.comment.trim()"></span>
      </div>
      <div class="col-1">
        <span>
          <i *ngIf="!activity.expanded" class="color-primary icon icon-WL_icon_chevron-down" (click)="expandActivityClick(idx, NoteBody)"></i>
          <i *ngIf="activity.expanded" class="color-primary icon icon-WL_icon_chevron-up" (click)="expandActivityClick(idx, NoteBody)"></i>
        </span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #WorkSiteLocation let-activity="activity" let-idx="idx">
    <span >{{activity.workSiteLocation || "-"}}</span>
</ng-template>

<ng-template #JobStatus let-activity="activity" let-idx="idx">
    <span >{{activity.jobStatus || "-"}}</span>
</ng-template>

<ng-template #PayRate let-activity="activity" let-idx="idx">
    <span >{{activity.internal_max_pay_rate | currency: 'USD' || "-"}}{{activity.internal_max_pay_rate ? "/" + activity.salary_type: ""}}</span>
</ng-template>

<ng-template #BillRate let-activity="activity" let-idx="idx">
    <span >{{activity.bill_rate | currency: 'USD' || "-"}}{{activity.bill_rate ? "/" +activity.salary_type : ""}}</span>
</ng-template>

<ng-template #Salary let-activity="activity" let-idx="idx">
  {{activity.data.payRate | currency}}
</ng-template>
<ng-template #ReasonForEnding let-activity="activity" let-idx="idx">
  {{activity.data.endReason}}
</ng-template>

<!-- List of Activities template. Shown when there are activities -->
<ng-template #Activities>
  <div class="activities-container flex-column">
    <div *ngFor="let activity of displayActivities; let idx=index" class="activity-container flex-row">
      <div class="icon-container">
        <img src="assets/icons/randstad_klein_primary.svg" alt="randstad logo">
      </div>
      <div class="activity-content-container flex-column">
        <div class="activity-header flex-row">
          <span  [ngClass]="isActivityAssignment(activity) ? 'activity-job-noLink' : 'activity-job'">{{activity.jobTitle ? activity.jobTitle : 'job unknown'}}</span>
          <span [ngClass]="isActivityAssignment(activity) ? 'activity-job-title-noLink' : 'activity-job-title'">{{activity.jobEmployer ? activity.jobEmployer : 'client unknown'}}</span>
          <span *ngIf="activity.type === 'Assignment'" class="activity-date-range">
              ({{activity.data.startDate | date : "MMMM yyyy"}} - {{activity.data.endDate | date : 'MMMM yyyy'}})
            </span>
          <span class="spacer"></span>
          <span *ngIf="activity.notAFitReason" class="not-a-fit-reason"></span>
        </div>
        <div class="activity-content flex-row">
          <ng-container
            [ngTemplateOutlet]="getActivityTemplate(activity)"
            [ngTemplateOutletContext]="{activity: activity, idx: idx}">
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #ActivitiesNew>

  <table class="table table-borderless-bottom" style="border: none!important; cursor: default!important;">
    <thead>
    <tr>
      <th *ngFor="let column of columnList" scope="col">{{column.header}}</th>
    </tr>
    </thead>
    <tbody style="color: black">
    <ng-container *ngFor="let activity of displayActivities; index as idx">
      <tr *ngIf="activity.type === 'ScheduledRecruiterInterview' && checkDateSeparator(idx)">
        <div style="width: 200px; margin: 20px 20px 20px 0;">
          <span class="font-weight-bold">Past scheduled rec. interviews</span>
        </div>

      </tr>
      <tr>
        <td align="left" *ngFor="let column of columnList">
          <ng-container [ngTemplateOutlet]="column.template" [ngTemplateOutletContext]="{activity: activity, idx: idx}"></ng-container>
        </td>
      </tr>
    </ng-container>

    </tbody>
  </table>

</ng-template>

<ng-template #Loading>
  <div class="w-100 mx-auto text-center loading-container">
    <div class="spinner-border text-primary mx-auto text-center" role="status">
      <span class="sr-only text-center">{{'job-details-shortlist.loading...' | translate}}</span>
    </div>
  </div>
</ng-template>
<!-- End Top level templates -->

<!-- Activity Templates -->
<!-- This template is used for every type of activity except Assignment -->
<ng-template #CommonActivity let-activity="activity" let-idx="idx">
  <span class="activity-avatar">
    <app-user-circle [user]="activity.user || {}" [useBodyAsContainer]="true" showPopover popoverPlacement="top"></app-user-circle>
  </span>
  <span class="activity-user-name">{{activity.user ? activity.user.FirstName + " " + activity.user.LastName : 'user unknown'}}</span>
  <ng-container
    [ngTemplateOutlet]="getActivityActionTemplate(activity)"
    [ngTemplateOutletContext]="{activity: activity}">
  </ng-container>
  <span class="activity-notes" #ActivityNoteBody [ngClass]="{'expanded': activity.expanded}" [innerHTML]="activity.comment"></span>
  <span class="activity-date">
    {{activity.activityTimestamp | date : "longDate"}} at {{activity.activityTimestamp | date : "shortTime"}}
  </span>
  <span class="activity-collapse-button">
    <i *ngIf="!activity.expanded" class="color-primary icon icon-WL_icon_chevron-down" (click)="expandActivityClick(idx, ActivityNoteBody)"></i>
    <i *ngIf="activity.expanded" class="color-primary icon icon-WL_icon_chevron-up" (click)="expandActivityClick(idx, ActivityNoteBody)"></i>
  </span>
</ng-template>

<!-- This template is used for an Assignment activity -->
<ng-template #AssignmentActivity let-activity="activity">
  <span class="activity-pay-rate">
    Salary: {{activity.data.payRate | currency}}
  </span>
  <span class="spacer"></span>
  <span class="activity-end-reason">{{activity.data.endReason}}</span>
</ng-template>
<!-- End Activity Templates -->

<!-- Activity action templates -->
<!-- RecruiterInterview Action -->
<ng-template #RecruiterInterviewAction>
  <span class="activity-action">
    : {{'talent-metrics-modal-RI-action' | translate}}
  </span>
</ng-template>

<!-- ReviewByAM Action -->
<ng-template #ReviewByAMAction>
  <span class="activity-action">
    : {{'talent-metrics-modal-RAM-action' | translate}}
  </span>
</ng-template>

<!-- ReviewByClient Action -->
<ng-template #ReviewByClientAction>
  <span class="activity-action">
    : {{'talent-metrics-modal-RC-action' | translate: {'name': personName | titlecase} }}
  </span>
</ng-template>

<!-- ClientInterview Action -->
<ng-template #ClientInterviewAction>
  <span class="activity-action">
    : {{'talent-metrics-modal-CI-action' | translate}}
  </span>
</ng-template>
<!-- End activity action templates -->
