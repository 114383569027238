<div class="modal-header">
    <button type="button" class="close-btn" aria-label="Close" (click)="closeDialog()">
        <i class="icon-WL_icon_close"></i>
    </button>
</div>

<div class="modal-body">
    <div class="modal-title">
        <img src="assets/icons/alert-yellow.svg">
        <h2 class="modal-title">{{title}}</h2>
    </div>
    <p>{{explanation}}</p>
</div>
