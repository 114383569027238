<div class="modal-header">
    <button type="button" class="close-btn" aria-label="Close" (click)="closeDialog()">
        <i class="icon-WL_icon_close"></i>
    </button>
</div>

<div class="modal-body">
    <h2>{{ 'personal-details-modal.personal_details' | translate }}</h2>

    <div class="field">
        <h3>{{ 'personal-details-modal.talent_status' | translate }}</h3>
        <p>{{talentStatus}}</p>
    </div>

    <div class="field">
        <h3>{{ 'personal-details-modal.legal_name' | translate }}</h3>
        <p> {{ talentName | titlecase }}
        </p>
    </div>

    <div class="field">
        <h3>{{ 'personal-details-modal.location' | translate }}</h3>
        <p>{{ talentLocation }}</p>
    </div>

    <div class="field">
        <h3>{{ 'personal-details-modal.mobile' | translate }}{{ isPrimary('Mobile') }} {{ 'personal-details-modal.phone_number' | translate }}</h3>
        <p>{{ mobilePhone }}</p>
    </div>

    <div class="field">
        <h3>{{ 'personal-details-modal.home' | translate }}{{ isPrimary('Home') }} {{ 'personal-details-modal.phone_number' | translate }}</h3>
        <p>{{ homePhone }}</p>
    </div>

    <div class="field">
      <h3>{{ 'personal-details-modal.work' | translate }}{{ isPrimary('Work') }} {{ 'personal-details-modal.phone_number' | translate }}</h3>
      <p>{{ workPhone }}</p>
    </div>

    <div class="field">
        <h3>{{ 'personal-details-modal.e_mail' | translate }}</h3>
        <p>{{ email }}</p>
    </div>

</div>
