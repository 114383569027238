<!-- job creator -->
<div [ngClass]="{'remove-left-padding': modalView}" class="container">
    <label style="display: block;">{{ "manage-roles.job_creator" | translate}}</label>
    <div class="mt-3">
      <span *ngIf="createdByUserFullName" class="align-name" style="margin: 0px;">
        {{createdByUserFullName}}
        ({{createdByUserEmail}})
      </span>
    </div>
</div>

<!-- job owner input -->
<div *ngIf="isRT" [ngClass]="{'remove-left-padding': modalView}" class="container mt-4">
  <label style="display: block;">{{ "manage-roles.job_owner" | translate }}</label>
  <div>
    <div *ngIf="publishedByUser && publishedByUser.fullName && publishedByUser.EmailAddr" class="input-icon">
      <span class="align-name">{{publishedByUser.fullName}} ({{publishedByUser.EmailAddr}})</span>
    </div>
    <input
    [(ngModel)]="jobOwnerTerm"
    (focus)="removeRole('publishedByUser')"
    type="text"
    class="form-control"
    style="width: 36em;"
    [placeholder]="!publishedByUser || publishedByUser && !publishedByUser.backOfficeId  ? 'Search recruiter by first name, last name or email address' : ''"
    />
    <div class="invalid-feedback" *ngIf="jobOwnerWarning">No results match the search</div>
    <p style="color: red;" *ngIf="warning && warning.show">{{warning.message}}</p>
  </div>
</div>
<div [ngStyle]="{'padding-left': modalView ? '0px' : '15px'}">
  <app-user-lookup
  [width]=36
  [searchTerm]="jobOwnerTerm"
  (userResults)="checkResults($event, 'job_owner')"
  (userSelected)="setUser($event, 'job owner')"
  ></app-user-lookup>
</div>

<!-- job assignee input -->
<div *ngIf="isRT && showAssignee"
[ngClass]="{'remove-left-padding': modalView}" class="container mt-3">
  <label style="display: block;">{{ "manage-roles.assignee" | translate }}</label>
  <div *ngIf="disableAssignee" [popover]="inputDisablePopover"
    [placement]="'top'"
    containerClass="rds-tooltip--dark tooltip--sm"
    triggers="mouseenter:mouseleave">
    <div *ngIf="assignedToUser && assignedToUser.fullName && assignedToUser.EmailAddr" class="input-icon">
      <span class="align-name">{{assignedToUser.fullName}} ({{assignedToUser.EmailAddr}})</span>
    </div>
    <input
    [(ngModel)]="jobAssigneeTerm"
    [disabled]="disableAssignee"
    (focus)="removeRole('assignedToUser')"
    type="text"
    class="form-control"
    style="width: 36em;"
    [placeholder]="!assignedToUser || assignedToUser && !assignedToUser.backOfficeId ? 'Search recruiter by first name, last name or email address' : ''"
    />
    <div class="invalid-feedback" *ngIf="jobAssigneeWarning">No results match the search</div>
  </div>
  <div *ngIf="!disableAssignee">
    <div *ngIf="assignedToUser && assignedToUser.fullName && assignedToUser.EmailAddr" class="input-icon">
      <span class="align-name">{{assignedToUser.fullName}} ({{assignedToUser.EmailAddr}})</span>
    </div>
    <input
    [(ngModel)]="jobAssigneeTerm"
    [disabled]="disableAssignee"
    (focus)="removeRole('assignedToUser')"
    type="text"
    class="form-control"
    style="width: 36em;"
    [placeholder]="!assignedToUser || assignedToUser && !assignedToUser.backOfficeId  ? 'Search recruiter by first name, last name or email address' : ''"
    />
    <div class="invalid-feedback" *ngIf="jobAssigneeWarning">No results match the search</div>
  </div>
</div>
<div [ngStyle]="{'padding-left': modalView ? '0px' : '15px'}">
  <app-user-lookup
  [width]=36
  [searchTerm]="jobAssigneeTerm"
  (userResults)="checkResults($event, 'job_assignee')"
  (userSelected)="setUser($event, 'assignee')"
  ></app-user-lookup>
</div>

<!-- job interviewer input -->

<!-- If user is from a CR lob, do not show them job-interviewer field (DF044-9543)  -->
<div *ngIf="!isCR">
  <div [ngClass]="{'remove-left-padding': modalView}" class="container mt-3">
    <label style="display: block;">{{ "manage-roles.add_job_interviewer" | translate }}</label>
    <div>
      <input
      [(ngModel)]="jobInterviewerTerm"
      type="text"
      class="form-control"
      placeholder="Search by name or email"
      style="width: 36em;"
      />
      <div class="invalid-feedback" *ngIf="interviewerUserWarning">No results match the search</div>
      <div class="invalid-feedback" *ngIf="maxInterviewerWarning" style="width: 48em;">
        Maximum amount of two interviewers reached. Remove an interviewer before attempting to add a new one.
      </div>
    </div>
  </div>
  <div [ngStyle]="{'padding-left': modalView ? '0px' : '15px'}">
    <app-user-lookup
    [width]=36
    [searchTerm]="jobInterviewerTerm"
    [excludeUsers]="interviewerUsers"
    (userResults)="checkResults($event, 'interviewer_users')"
    (userSelected)="setUser($event, 'interviewer')"
    ></app-user-lookup>
  </div>
<!-- interviewer user -->
  <div class="mt-4">
    <div *ngFor="let user of interviewerUsersObjects; let i = index;">
      <ng-container
        [ngTemplateOutlet]="interviewerUser"
        [ngTemplateOutletContext]="{user:user, index: i}"
      >
      </ng-container>
    </div>
  </div>

<!-- interviewer user template -->
  <ng-template #interviewerUser let-user="user" let-index="index">
    <div [ngClass]="{'collab-user-details': !modalView}" *ngIf="user" class="container mt-2" style="padding: 0px">
      <div style="margin-left: 10px;">
        <app-user-circle [user]="user.interviewerUser" [useBodyAsContainer]=true>
        </app-user-circle>
        <span class="align-name">{{user.interviewerUser.FullName}}</span>
      </div>
      <button (click)="removeInterviewerUser(user, index)" id="trash-icon"></button>
      <hr style="margin: auto; opacity: 1;">
    </div>
  </ng-template>
  
</div>

<!-- job collaborator input -->
<div [ngClass]="{'remove-left-padding': modalView}" class="container mt-3">
  <label style="display: block;">{{ "manage-roles.add_job_collaborator" | translate }}</label>
  <div>
    <input
    [(ngModel)]="searchTerm"
    type="text"
    class="form-control"
    placeholder="Search by name or email"
    style="width: 36em;"
    />
    <div class="invalid-feedback" *ngIf="collabUserWarning">No results match the search</div>
  </div>
</div>
<div [ngStyle]="{'padding-left': modalView ? '0px' : '15px'}">
  <app-user-lookup
  [width]=36
  [searchTerm]="searchTerm"
  [excludeUsers]="collabUsers"
  (userResults)="checkResults($event, 'collab_users')"
  (userSelected)="setUser($event, 'collab')"
  ></app-user-lookup>
</div>

<!-- collab user -->
<div class="mt-4">
  <div *ngFor="let user of collabUsersObjects; let i = index;">
    <ng-container
      [ngTemplateOutlet]="collabUser"
      [ngTemplateOutletContext]="{user:user, index: i}"
    >
    </ng-container>
  </div>
</div>

<!-- collab user template -->
<ng-template #collabUser let-user="user" let-index="index">
  <div [ngClass]="{'collab-user-details': !modalView}" *ngIf="user" class="container mt-2" style="padding: 0px">
    <div style="margin-left: 10px;">
      <app-user-circle [user]="user.collabUser" [useBodyAsContainer]=true>
      </app-user-circle>
      <span class="align-name">{{user.collabUser.FullName}}</span>
    </div>
    <button (click)="removeCollabUser(user, index)" id="trash-icon"></button>
    <hr style="margin: auto; opacity: 1;">
  </div>
</ng-template>

<ng-template #inputDisablePopover>
  <span style="font-size: 12.5px;">Assignment restricted to your delivery leader. If you are a delivery leader, please check that job is not in unqualified status</span>
</ng-template>
