import { createModel, Mapping, Model } from './model';
import { GoogleToMoment } from './serializers/moment.serializer';
import { Moment } from 'moment';
import { Serial } from './decorators/serial.decorator';
import { Google } from '@allbirds-ui/allbirds-types';
export import RawEducationRecord = Google.Talent.EducationRecord;

class EducationRecordMapping extends Mapping<RawEducationRecord> {
  @Serial(GoogleToMoment) startDate?: Moment;
  @Serial(GoogleToMoment) endDate?: Moment;
}

export interface EducationRecord extends Model<EducationRecordMapping> {}

export const EducationRecord = createModel<EducationRecord>(EducationRecordMapping);
