import { JobTitleFilter, SkillFilter, NewProfileQuery, NoteTypeFilter, ActivityTypeFilter } from '../../models/internal/profile-query.models';
import { Moment } from 'moment';
import { Mapping } from '../../models/external/model';
import { Serial } from '../../models/external/decorators/serial.decorator';
import { IsoToMoment } from '../../models/external/serializers/moment.serializer';
import { PrimarySecondaryRecruiter } from '../../models/internal/primary-secondary-recruiter.interface';
import { User } from '../../models/external/user.model';

export interface TalentSearchModel {
  distance?: string;
  keyword?: string;
  ladderStatus?: any;
  noteTypes?: any;
  activityTypes?: any;
  backgroundCheck?: any;
  drugScreen?: any;
  noteTypeFilter?: NoteTypeFilter[] | string[];
  activityTypeFilter?: ActivityTypeFilter[] | string[];
  byUsers?: User[];
  location?: string;
  name?: string; //to support old saved searches
  skills?: string | string[];
  jobSkills?: SkillFilter[];
  title?: string;
  jobTitles?: JobTitleFilter[] | string[];
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  talentId?: string;
  talentLocation?: string;
  radius?: number;
  excludeOnTempAssignment?: boolean;
  excludeOnPermAssignment?: boolean;
  excludeRedFlags?: boolean;
  bgCheckUpdated?: number;
  drugCheckUpdated?: number;
  noteTypeUpdatedWithin?: number;
  activityTypeUpdatedWithin?: number;
  engagementRecency?: number;
  recentlyViewedFilter?: number;
  employeeTypes?: string[];
  talentStatus?: string[];
  workAuthorizations?: string[];
  state?: string;
  city?: string;
  latLong?: string;
  displayLocation?: string;
  shouldAutoUpdate?: boolean;
  jobTitle?: any;
  company?: any;
  skill?: any;
  vaccinationDataFilter?: any;
  portfolioLinkFilter?: string;
  lineOfBusiness?:string[];
  primaryRecruiter?: PrimarySecondaryRecruiter[];
  secondaryRecruiter?: PrimarySecondaryRecruiter[];
}

export interface RecentSearch {
  _id?: string;
  createdDate: string;
  fields: TalentSearchModel;
}

export interface SavedSearch {
  _id?: string;
  searchId?: string;
  createdDate: Moment;
  displayTitle: string;
  fields: TalentSearchModel;
  shouldAutoUpdate?: boolean;
}

export class ElasticRecentSearch extends Mapping<any> {
  _id: string;
  @Serial(NewProfileQuery) profileQuery: NewProfileQuery;
  @Serial(IsoToMoment) timestamp: Moment;
}

export class ElasticSavedSearch extends Mapping<any> {
  _id?: string;
  @Serial(IsoToMoment) create_time: Moment;
  editors: string[];
  @Serial(IsoToMoment) last_update_time: Moment;
  last_update_user: string;
  owner: string;
  @Serial(NewProfileQuery) profileQuery: NewProfileQuery;
  searchId: string;
  title: string;
  viewers: string[];
}
