<!--
  The proper TemplateRef is rendered in the following <div> depending on what sub-step the talent
  is on as well as what the role of the user is.
-->
<div class="phase" [ngTemplateOutlet]="shownPhase"></div>

<!-- Shortlisted phase. -->
<ng-template #engageViaChatbot>
  <p class="phase-subtitle">{{'shortlisted.our_chatbot_can' | translate}}</p>
  <div class="action-buttons row">
    <div class="col">
      <button class="btn rds--button--primary">{{'shortlisted.reject_candidate' | translate}}</button>
    </div>
    <div class="col text-right">
      <button class="btn rds--button--secondary">{{'shortlisted.pre-screen_through_chatbot' | translate}}</button>
    </div>
  </div>
</ng-template>