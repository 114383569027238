<div class="modal-header">
    <h4 class="modal-title pull-left"></h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="_bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <ng-container *ngTemplateOutlet="composeView"></ng-container>
  </div>



    <ng-template #composeView>
    <div>
    <form [formGroup]="smsForm"  >
    <h3 class="header">Send SMS/Text</h3>
    <label class="fromLabel">{{'mass-email-modal.from' | translate}}</label>
    <div> {{fromEmail}}</div>
    <input type="text" id="txtFName" hidden=true  formControlName="from" />
    <label class="toLabel">{{'mass-email-modal.to' | translate}}</label>
    <div class="details">An sms/text will be sent to each talent separately</div>
    <app-email-name-multi-input [label]="''"
                         [control]="smsForm.controls.to"
                         [canChangeInput]="false"
                         [showPills]="showPills"
                         [emails]="finalEmailList"
                         (onEmailInput)="handleEmailInput(smsForm.controls.to, $event)"
                         (onEmailRemove)="handleEmailRemove(smsForm.controls.to, $event)"
                         (expandPillsEmit)="handleExpandPill($event)">
    </app-email-name-multi-input>
    <form-error [fieldAlias]="'Email recipients '" [control]="smsForm.controls.to"></form-error>

    <ng-container *ngIf="showPills == true">
      <button type="button" class="btn-reset text-primary" (click)="togglePills()">{{'mass-email-modal.close' | translate}}<img src="/assets/icons/chevron-up-s.svg" alt="many"   />
      </button>
    </ng-container>
    <div class="warning-banner" *ngIf="problemusers.length > 0">
      <div class="icon">
        <img class="warning-banner-icon" src="assets/icons/alert-circle-red.svg" alt="error"/>
      </div>
      <div class="warning-user-container">
          <span class="warning-banner-label">{{'mass-email-modal.nbspnbsp_40' | translate: trVariable1}}</span>
          <span class="problemdetail" (click)="showDetailCantMail()">{{cantMailVerbiage}}</span>
        <div *ngIf="showCantMailDetails" class="problem-user-list">

          <ul class="hz-btn-list ">
            <li *ngFor="let issue of problemusers">

              <div class="some-container">
                <button class="btn btn-sm btn-outline-danger" [popover]="popTemplate"
                  triggers="mouseenter:mouseleave" placement="bottom">
                {{ issue.name }}
                </button>
              </div>
              <ng-template #popTemplate>
                <div class="issue-name">{{issue.name}}</div>
                <div class="issue-email">{{issue.email}}</div>
                <div class="issue-message-bg">
                  <img class="issue-message-text" src="/assets/icons/alert-circle-red.svg" alt="error"  /> <span>{{issue.issue}} </span>
                </div>
                </ng-template>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div><label>Message</label></div>
    <textarea class="form-control" type="text" id="textmsg" formControlName="textmsg"  maxlength="160" ></textarea>
    <span class="chars-remaining">
      Characters left: {{ smsCharsLeft }}
      <!-- {{'list-info.characters_left' | translate: trVariable1}} -->
    </span>
    <form-error [fieldAlias]="'TextMsg'" [control]="smsForm.controls.textmsg"></form-error>


    </form>
  </div>
</ng-template>







  <div class="modal-footer">
    <ul class="hz-btn-list">
      <li>
        <button type="button" class="btn btn-outline-primary" aria-label="cancel" [disabled]="isBusy"  (click)="_bsModalRef.hide()">{{'mass-email-modal.discard' | translate}}</button>
      </li>
      <li>
        <button type="submit" class="btn btn-outline-primary" aria-label="submit" [disabled]="isBusy"  (click)="submitMassSms()">{{'mass-email-modal.send' | translate}}</button>
      </li>
    </ul>
  </div>


  <div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1"
  role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title pull-left"></h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="hideChild()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

          <h3 class="header"> <img src="/assets/icons/alert-yellow.svg" alt="error"  /> {{errorModalTitle}}</h3>
          <p>{{errorModalDesc}}</p>
        </div>
      </div>
    </div>
  </div>
