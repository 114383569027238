import {createModel, Mapping, Model} from '../model';
import {Google} from '@allbirds-ui/allbirds-types';

interface RawTalentlistProfile {
  frontOfficeID?: string;
  addresses?: Google.Talent.Address[];
  personNames?: Google.Talent.PersonName[];
  phoneNumbers?: Google.Talent.Phone[];
  emailAddresses?: Google.Talent.Email[];
  externalSystem?: string;
  externalId?: string;
}

class TalentListProfileMapping extends Mapping<RawTalentlistProfile> {

  frontOfficeID?: string;
  addresses?: Google.Talent.Address[];
  personNames?: Google.Talent.PersonName[];
  phoneNumbers?: Google.Talent.Phone[];
  emailAddresses?: Google.Talent.Email[];
  externalSystem?: string;
  externalId?: string;

  get candidateFullName(): string {
    if (this.personNames && this.personNames.length) {
      const {givenName, familyName} = this.personNames[0].structuredName;
      if (givenName != undefined && familyName != undefined) {
        return `${givenName} ${familyName}`;
      }
    }
    return '';
  }

  get candidateCityStateZip(): string {
    if (this.addresses && this.addresses.length) {
      if (this.addresses[0].structuredAddress) {
        const {administrativeArea, locality, postalCode} = this.addresses[0].structuredAddress;
        if (administrativeArea || locality || postalCode) {
          let returnVal = '';
          if (locality) {
            returnVal += `${locality}, `;
          }
          returnVal += `${administrativeArea} ${postalCode}`;
          return returnVal;
        }
      }
    }
    return 'No location info found';
  }

  get candidateEmail(): string {
    return this.emailAddresses && this.emailAddresses.length && this.emailAddresses[0].emailAddress ? this.emailAddresses[0].emailAddress : 'No email address found';
  }

  get candidatePhoneNumber(): string {
    return this.phoneNumbers && this.phoneNumbers.length && this.phoneNumbers[0].number ? this.phoneNumbers[0].number : 'No phone number found';
  }

  get profileId(): string {
    return this.frontOfficeID ? this.frontOfficeID : '';
  }
}

export interface TalentListProfileInfo extends Model<TalentListProfileMapping> {}

export const TalentListProfileInfo = createModel<TalentListProfileInfo>(TalentListProfileMapping);
