<!--
  The proper TemplateRef is rendered in the following <div> depending on what sub-step the talent
  is on as well as what the role of the user is.
-->
<div class="phase" [ngTemplateOutlet]="shownPhase"></div>

<ng-template #accountManagerAcceptedView>
  <div class="phase-body-section pt-0">
    <p>{{statusLabel}}</p>
  </div>
  <div #ctaContainerBoundry id="ctaContainerBoundry"></div>
  <ul #ctaContainer class="action-buttons hz-btn-list">
    <li>
      <app-talent-process-cta
        [id]="'reviewHm-not-a-fit'"
        [invertColor]="true"
        (execute)="emitUnfitClick()">
        {{'review-hiring-manager.not_a_fit' | translate}}
      </app-talent-process-cta>
    </li>
  </ul>
</ng-template>

<ng-template #prepareSubmission>
  <div class="phase-body-section pt-0">
    <p>{{statusLabel}}</p>
  </div>
  <div #ctaContainerBoundry id="ctaContainerBoundry"></div>
  <ul #ctaContainer class="action-buttons hz-btn-list">
    <li>
      <app-talent-process-cta
        [id]="'reviewHm-not-a-fit'"
        [invertColor]="true"
        (execute)="emitUnfitClick()">
        {{'review-hiring-manager.not_a_fit' | translate}}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        [id]="'log-submission-button'"
        (execute)="openSubmitModal()">
        {{'review-hiring-manager.log_submission' | translate}}
      </app-talent-process-cta>
    </li>
  </ul>
</ng-template>

<ng-template #submittedView>
  <div class="phase-body-section pt-0">
    <p>{{statusLabel}}</p>
  </div>
  <div class="phase-body-section">
    <h4 class="phase-body-title">{{'review-hiring-manager.submission_details' | translate}}</h4>
    <p class="input-text mb-3">Submitted on
      {{ application?.randstad_process?.clientSubmission.submissionDate | abDatetime }}</p>
    <div class="input-text" [innerHTML]="application?.randstad_process?.clientSubmission.submission"></div>
    <!-- Old resume display deprecated per DF044-6449 -->
    <!-- <div class="attachments" *ngIf="hasResume">
      <h4>Resume</h4>
      <div class="row">
        <div class="col">
          <img src="assets/icons/document.svg" />
          <a [href]="resumeURL" target="_blank">{{ resumeFilename }}</a>
          <span>{{'review-hiring-manager.original' | translate}}</span>
        </div>
      </div>
    </div> -->
    <div class="attachments" *ngIf="attachments.length">
      <h4>{{'review-account-manager.attachment' | translate}}:</h4>
      <div class="row">
        <app-attachment-card-internal *ngFor="let attachment of attachments" [hideDelete]="true" cardType="small" [attachment]="attachment"></app-attachment-card-internal>
      </div>
    </div>
  </div>
  <div #ctaContainerBoundry id="ctaContainerBoundry"></div>
  <ul #ctaContainer class="action-buttons hz-btn-list">
    <li>
      <app-talent-process-cta 
        *ngIf="!userLob.checkLob('RGS')"
        [id]="'reviewHm-update-client-submission-button'"
        [invertColor]="true"
        (execute)="emitUpdateSubmissionClick()">
        {{ 'review-hiring-manager.update_client_submission' | translate }}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        [id]="'reviewHm-not-a-fit'"
        [invertColor]="true"
        (execute)="emitUnfitClick()">
        {{'review-hiring-manager.not_a_fit' | translate}}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        [id]="'interview-requested-button'"
        (execute)="accept()">
        {{'review-hiring-manager.interview_requested' | translate}}
      </app-talent-process-cta>
  </ul>
</ng-template>

<ng-template #submittedViewUpdate>
  <div class="phase-body-section pt-0">
    <p>{{statusLabel}}</p>
  </div>
  <div *ngIf="application?.randstad_process?.clientSubmission" class="phase-body-section">
    <div class="submissionDetails">
      <h4 class="phase-body-title"><b>Feedback from hiring manager</b></h4>
      <p>{{ application?.randstad_process?.clientSubmission?.submissionType }}</p>
      <div class="emailBody" [innerHTML]="application.randstad_process.clientSubmission.submission"></div>
       <h4 class="phase-body-title"><b>        {{ 'review-account-manager.original_submission' | translate }} </b></h4>
       <div class="emailBody" [innerHTML]="application.randstad_process.clientSubmission.priorSubmissions[originalSubmissionIndex].submission"></div>
    </div>
  </div>
  <div #ctaContainerBoundry id="ctaContainerBoundry"></div>
  <ul #ctaContainer class="action-buttons hz-btn-list">
    <li>
      <app-talent-process-cta
        *ngIf="!userLob.checkLob('RGS')"
        [id]="'reviewHm-update-client-submission-button'"
        [invertColor]="true"
        (execute)="emitUpdateSubmissionClick()">
        {{ 'review-hiring-manager.update_client_submission' | translate }}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        [id]="'reviewHm-not-a-fit'"
        [invertColor]="true"
        (execute)="emitUnfitClick()">
        {{'review-hiring-manager.not_a_fit' | translate}}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        [id]="'interview-requested-button'"
        (execute)="accept()">
        {{'review-hiring-manager.interview_requested' | translate}}
      </app-talent-process-cta>
  </ul>

</ng-template>
