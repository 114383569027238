<!-- todo: Implement translation of text -->
<div class="modal-header">
  <button type="button" class="btn-reset" aria-label="Close" (click)="_bsModalRef.hide()">
    <i class="icon-WL_icon_close"></i>
  </button>
</div>

<div class="modal-body">
  <div id="modalTop" style="position: absolute; top: 0; left: 0;"></div>
  <h3>{{'submit-to-client-modal.title' | translate}}</h3>

  <p>{{'submit-to-client-modal.call_to_action' | translate}}</p>

  <div class="row option-row">
    <div class="col-6">
      <button class="btn-reset radio-btn-hybrid" [class.active]="!isComposeView">
        <input id="already-submitted-option" class="rds--radio-button" type="radio" [(ngModel)]="isComposeView" (ngModelChange)="setShownView(false)" [value]="false" name="radio-button" tabindex="0">
        <label for="already-submitted-option" class="rds--radio-button__label">
          <span class="rds--radio-button__appearance"></span>
          {{'submit-to-client-modal.log_submission_notes' | translate}}
        </label>
      </button>
    </div>
    <div class="col-6">
      <button class="btn-reset radio-btn-hybrid" [class.active]="isComposeView">
        <input id="compose-option" class="rds--radio-button" type="radio" [(ngModel)]="isComposeView" (ngModelChange)="setShownView(true)" [value]="true" name="radio-button" tabindex="0">
        <label for="compose-option" class="rds--radio-button__label">
          <span class="rds--radio-button__appearance"></span>
          {{'submit-to-client-modal.submit_via_email' | translate}}
        </label>
      </button>
    </div>
  </div>

  <ng-container *ngTemplateOutlet="shownView"></ng-container>
</div>

<div class="modal-footer">
  <ul class="hz-btn-list text-right" *ngIf="shownView !== noSignatureView">
    <li>
      <button type="button" class="btn rds--button--primary" aria-label="discard" (click)="_bsModalRef.hide()">
        {{'submit-to-client-modal.discard' | translate}}
      </button>
    </li>
    <li>
      <button type="button" class="btn rds--button--secondary" aria-label="Save" (click)="handleSubmit()">
        {{'submit-to-client-modal.submit' | translate}}
      </button>
    </li>
  </ul>
</div>

<!--
  Shown when 'send with email' is selected (composeView) but the user hasn't set up
  their signature yet.
-->
<ng-template #noSignatureView>
  <div class="signature-alert">
    <img src="assets/icons/alert-red.svg" />
    <p>
      In order to leverage this feature, please <span class="faux-link" (click)="goToEmailSettings()">click here</span> to setup your email signature.
    </p>
  </div>
</ng-template>


<!--
  Shown when 'send with email' is selected.
-->
<ng-template #composeView>
  <app-email-multi-input [label]="'To'"
                         [control]="emailForm.controls.to"
                         (onEmailInput)="handleEmailInput(emailForm.controls.to, $event)"
                         (onEmailRemove)="handleEmailRemove(emailForm.controls.to, $event)">
  </app-email-multi-input>

  <app-email-multi-input [label]="'CC'"
                         [control]="emailForm.controls.cc"
                         (onEmailInput)="handleEmailInput(emailForm.controls.cc, $event)"
                         (onEmailRemove)="handleEmailRemove(emailForm.controls.cc, $event)">
  </app-email-multi-input>

  <app-email-multi-input [label]="'BCC'"
                         [control]="emailForm.controls.bcc"
                         (onEmailInput)="handleEmailInput(emailForm.controls.bcc, $event)"
                         (onEmailRemove)="handleEmailRemove(emailForm.controls.bcc, $event)">
  </app-email-multi-input>

  <form [formGroup]="emailForm">
    <div class="form-group">
      <label for="emailSubject">Subject</label>
      <input type="text"
             class="form-control"
             id="emailSubject"
             placeholder="Enter the subject of the email"
             formControlName="subject"
      />
      <form-error [fieldAlias]="'Subject'" [control]="emailForm.controls.subject"></form-error>
    </div>
  </form>

  <!-- Deprecated after file input was changed to multiple per DF044-6449 -->
  <!-- <app-file-input [label]="'Attachment'"
                  [fileTypes]="['.pdf', '.doc', '.docx']"
                  (onFileSelect)="handleAttachment($event)"
                  (onFileRemove)="handleAttachmentRemove()">
  </app-file-input> -->

  <!-- Displays attachments from profile and internal submission -->
  <ng-container *ngIf="attachments.length > 0">
    <h4>Attach files</h4>
    <div *ngFor="let attachment of attachments" class="attachment-row">
      <div class="form-check-container">
        <input class="form-check-input" type="checkbox" [checked]="attachment?.willSubmit" />
        <span class="checkmark" (click)="handleWillSubmit(attachment)"></span>
      </div>
      <div class="attachment-info">
        <p class="attachment-name">{{ attachment?.name ? attachment.name : attachment?.path }}</p>
        <p class="attachment-upload">{{ attachment?.category }} &#x2022; Uploaded {{ formatDatetime(attachment?.lastUpdatedDateTime) }} {{ attachment?.attached_by_user ? "by" : ""}} {{ attachment?.attached_by_user }}</p>
      </div>
    </div>
  </ng-container>

  <!-- Upload new attachments form -->
  <div *ngIf="!isUploadFormVisible" class="upload-btn-container">
    <button class="upload-btn" (click)="showUploadForm()">+</button>
    <span>Upload new file</span>
  </div>
  <div class="form-group" *ngIf="isUploadFormVisible">
    <app-add-files-modal 
      [initialState]="initialState" 
      (addToUploadedAttachments)="handleAddToUploadedAttachments($event)"
      (removeUploadedAttachment)="handleRemoveFromUploadedAttachments($event)"
    ></app-add-files-modal>
  </div>

  <div class="form-group mt-3">
    <label>Message</label>
    <app-ckeditor [parentForm]="emailForm"
                  [formConName]="'message'"
                  [cssClass]="'m-0 p-0'"
                  [config]="emailCkConfig"
                  [startupHtml]="userSignature"
                  (onEditorReady)="handleEditorReady($event)">
    </app-ckeditor>
    <form-error [fieldAlias]="'Message body'" [control]="emailForm.controls.message"></form-error>
  </div>
</ng-template>

<!--
  Shown when 'send without email' is selected.
-->
<ng-template #submittedView>
  <p>You've submitted this candidate already through VMS, phone or another external channel.</p>
  <form [formGroup]="emailForm">
    <div class="form-group">
      <label for="submittalNotes">Submittal notes</label>
      <app-ckeditor
        id="submittalNotes"
        class="text-box w-100"
        [parentForm]="emailForm"
        formConName="message"
        height="120"
        [enableMentions]="true">
      </app-ckeditor>
      <form-error [fieldAlias]="'Submittal notes'" [control]="emailForm.controls.message"></form-error>
    </div>
  </form>
</ng-template>
