<ng-container *ngIf="activity"
  [ngSwitch]="activity.type">
  <span *ngSwitchCase="ActivityFeedTypes.Apply">
    <span class="color-primary" popover="Candidate" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ candidateFullName }}</span>
    {{'activity-feed-action.applied_to_this' | translate}}
  </span>

  <span *ngSwitchCase="ActivityFeedTypes.Shortlist">
    <span *ngIf="activity.author" class="color-primary" [popover]="activity.author.title" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ activity.author.name }}</span>
    {{'activity-feed-action.shortlisted' | translate}}
    <span class="color-primary" popover="Candidate" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ candidateFullName }}</span>.
    </span>

  <span *ngSwitchCase="ActivityFeedTypes.SkippedStep">
    <span *ngIf="activity.author" class="color-primary" [popover]="activity.author.title" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ activity.author.name }}</span>
    {{'activity-feed-action.skipped' | translate}}
    <span class="color-primary" popover="Candidate" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ candidateFullName }}</span>
     {{'activity-feed-action.to_the_next' | translate}}
  </span>

  <span *ngSwitchCase="ActivityFeedTypes.Rejection">
    <span class="color-primary" popover="Talent" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ candidateFullName }}</span>
    {{'activity-feed-action.was_marked_as' | translate}}
    <span *ngIf="activity.author" class="color-primary" [popover]="activity.author.title" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ activity.author.name }}</span>
  </span>

  <span *ngSwitchCase="ActivityFeedTypes.CEScreeningSent">
    {{'activity-feed-action.chatbot_prescreening_was' | translate}} <span *ngIf="activity.author" class="color-primary" [popover]="activity.author.title" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ activity.author.name }}</span>.
  </span>

  <span *ngSwitchCase="ActivityFeedTypes.CEScreeningCompleted">
    <span class="color-primary" popover="Candidate" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ candidateFullName }}</span>
    {{'activity-feed-action.completed_pre-screening.' | translate}}
  </span>

  <span *ngSwitchCase="ActivityFeedTypes.ManualScreeningCompleted">
    <span *ngIf="activity.author" class="color-primary" [popover]="activity.author.title" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ activity.author.name }}</span>
    {{'activity-feed-action.screened' | translate}}
    <span class="color-primary" popover="Candidate" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ candidateFullName }}</span>
    {{'activity-feed-action.by_phone' | translate}}
    </span>

  <span *ngSwitchCase="ActivityFeedTypes.CEInterviewScheduleSent">
    <span *ngIf="activity.author" class="color-primary" [popover]="activity.author.title" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ activity.author.name }}</span>
    {{'activity-feed-action.sent_an_interview' | translate}}
    <span class="color-primary" popover="Candidate" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ candidateFullName }}</span>
    {{'activity-feed-action.via_chatbot' | translate}}
  </span>

  <span *ngSwitchCase="ActivityFeedTypes.CEInterviewScheduleConfirmed">
    <span class="color-primary" popover="Candidate" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ candidateFullName }}</span>
    {{'activity-feed-action.scheduled_an_interview' | translate}}
    <span *ngIf="activity.author" class="color-primary" [popover]="activity.author.title" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ activity.author.name }}</span>
    {{'activity-feed-action.via_the_chatbot' | translate}}
  </span>

  <span *ngSwitchCase="ActivityFeedTypes.CEInterviewCancelled">
    <span class="color-primary" popover="Candidate" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ candidateFullName }}</span>
    {{'activity-feed-action.canceled_the_recruiter' | translate}}
  </span>

  
  <span *ngSwitchCase="ActivityFeedTypes.RecruiterInterviewCancelled">
    <span *ngIf="activity.author" class="color-primary" [popover]="activity.author.title" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ activity.author.name }}</span>
    {{'activity-feed-action.canceled_the_recruiter' | translate}}
  </span>

  <span *ngSwitchCase="ActivityFeedTypes.CEInterviewRescheduled">
    <span class="color-primary" popover="Candidate" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ candidateFullName }}</span>
    {{'activity-feed-action.rescheduled_the_recruiter' | translate}}
  </span>

  <span *ngSwitchCase="ActivityFeedTypes.RecruiterInterviewPerformed">
    <span *ngIf="activity.author" class="color-primary" [popover]="activity.author.title" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ activity.author.name }}</span>
    {{'activity-feed-action.interviewed' | translate}}
    <span class="color-primary" popover="Candidate" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ candidateFullName }}</span>.
   </span>

  <span *ngSwitchCase="ActivityFeedTypes.RecruiterAMSubmission">
    <span *ngIf="activity.author" class="color-primary" [popover]="activity.author.title" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ activity.author.name }}</span>
    {{'activity-feed-action.submitted' | translate}}
    <span class="color-primary" popover="Candidate" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ candidateFullName }}</span>
    {{'activity-feed-action.to' | translate}}
    <span *ngIf="activity.details && activity.details.recipient" class="color-primary" [popover]="activity.details.recipient.title" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ activity.details.recipient.name }}</span>.
  </span>

  <span *ngSwitchCase="ActivityFeedTypes.UpdateSubmission">
    <span *ngIf="activity.author" class="color-primary" [popover]="activity.author.title" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ activity.author.name }}</span>
    updated submission for
    <span class="color-primary" popover="Candidate" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ candidateFullName }}</span>
    to
    <span *ngIf="activity.activityType" class="color-primary" [popover]="activity.activityType" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ activity.activityType }}</span>.
  </span>

  <span *ngSwitchCase="ActivityFeedTypes.ClientSubmissionSent">
    <span *ngIf="activity.author" class="color-primary" [popover]="activity.author.title" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ activity.author.name }}</span>
    {{'activity-feed-action.submitted' | translate}}
    <span class="color-primary" popover="Candidate" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ candidateFullName }}</span>
    {{'activity-feed-action.to_the_client.' | translate}}

    </span>

  <span *ngSwitchCase="ActivityFeedTypes.ClientInterviewScheduled">
    <span *ngIf="activity.author" class="color-primary" [popover]="activity.author.title" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ activity.author.name }}</span>
    {{'activity-feed-action.scheduled_an_interview' | translate}}
    <span class="color-primary" popover="Candidate" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ candidateFullName }}</span>
    {{'activity-feed-action.with_the_client.' | translate}}
  </span>

  <span *ngSwitchCase="ActivityFeedTypes.ClientInterviewRescheduled">
    <span *ngIf="activity.author" class="color-primary" [popover]="activity.author.title" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ activity.author.name }}</span>
    {{'activity-feed-action.rescheduled_an_interview' | translate}}
    <span class="color-primary" popover="Candidate" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ candidateFullName }}</span>
    {{'activity-feed-action.with_the_client.' | translate}}

    </span>

  <span *ngSwitchCase="ActivityFeedTypes.ClientInterviewFeedback">
    <span *ngIf="activity.author" class="color-primary" [popover]="activity.author.title" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ activity.author.name }}</span>
    {{'activity-feed-action.logged_the_hiring' | translate}}
    <span class="color-primary" popover="Candidate" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ candidateFullName }}</span>

    </span>

  <span *ngSwitchCase="ActivityFeedTypes.OfferSent">
    <span *ngIf="activity.author" class="color-primary" [popover]="activity.author.title" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ activity.author.name }}</span>
    {{'activity-feed-action.sent_an_offer' | translate}}
      <span class="color-primary" popover="Candidate" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ candidateFullName }}</span>.
  </span>
  <span *ngSwitchCase="ActivityFeedTypes.OfferRejected">
    <span class="color-primary" popover="Candidate" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ candidateFullName }}</span>
    {{'activity-feed-action.rejected_the_offer' | translate}}
    <span *ngIf="activity.author" class="color-primary" [popover]="activity.author.title" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ activity.author.name }}</span>.
    </span>
</ng-container>