import { Injectable, NgZone } from '@angular/core';
import { FirestoreService } from '../firestore/firestore.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from 'src/app/shared/models/external/user.model';
import { ApiService } from '../api/api.service';
import { AngularFirePerformance } from '@angular/fire/performance';

declare global {
  interface Window {allbirds_exports: any;}
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: User | null;
  authenticatingPromise: Promise<User>;

  constructor(
    private _api: ApiService,
    private firestore: FirestoreService,
    private firestoreAuth: AngularFireAuth,
    private zone: NgZone,
    private performance: AngularFirePerformance
  ) { }

  async authenticate() {
    if (!this.authenticatingPromise) {
      let trace = await this.performance.trace('initial-user-authentication');
      trace.start(); // firebase performance monitoring
      this.authenticatingPromise = new Promise((resolve, reject) => {
        this._api.getUser()
          .subscribe(user => {
            console.log('[getUser]', user);
            if (user) {
              this.user = user;
              /*
              Removed via DF044-3644
              // add user information to usabilla
              this.zone.run(() => {
                const custom = {
                  user_email: user.EmailAddr,
                  user_lob: user.Source
                };
                this.usabilla.addCustom(custom);
              });*/

              // expose user roles to global javascript so whatfix can read them
             /*  const whatfixUser = Object.freeze({
                roles: [this.user.Roles],
                email: this.user.EmailAddr,
                id: this.user.BackOfficeID,
                lob: this.user.Source,
                jobTitle: this.user.JobTitle,
                firstName: this.user.FirstName,
                lastName: this.user.LastName
              });
              window.allbirds_exports = { user: whatfixUser }; */

              this._api.getFirestoreToken().subscribe(res => {
                if (res) {
                  this.firestoreAuth.signInWithCustomToken(res)
                    .then(() => {
                      if (trace) {
                        trace.stop(); // firebase performance monitoring
                        trace = null;
                      }
                      resolve(this.user);
                      // console.log('Successfully authenticated with Firebase!');
                    }).catch(function (error) {
                    // Handle Errors here.
                    const errorCode = error.code;
                    delete this.authenticatingPromise;
                    if (errorCode === 'auth/invalid-custom-token') {
                      alert('The token you provided is not valid.');
                    } else {
                      console.error(error);
                    }
                  });
                }
              }, err => {
                console.log(err);
                delete this.authenticatingPromise;
              });
            } else {
              this.user = null;
              delete this.authenticatingPromise;
            }
          });
      });
    }
    return this.authenticatingPromise;
  }

  async auth0Authenticate(id: string, email:string) {
    const users = await this._api.getUsersByIds([],true,true, [email])
    this.user = users[0] as User;
    return this.authenticate();
  }

  isLob(lob: string) {
    if (!this.user) { return false; }
    return this.user.Source.includes(lob);
  }

  isOpco(opco: string): boolean {
    if (
      this.user &&
      this.user.Source &&
      this.user.Source.length &&
      opco &&
      opco.length
    ) {
      return (
        (this.user.Source.abIncludesLob('RT', 'RE','CR') && opco.abIncludesLob('RT', 'RE','CR')) ||
        (this.user.Source.abIncludesLob('RGS') && opco.abIncludesLob('RGS'))
      );
    }
    return false;
  }

  emailSignatureExists(): boolean {
    return false;
  }

}

