import {ResponseBody} from '../../../models/api/application-response.interface';
import {Profile} from '../../../models/external/profile.model';
import {User} from '../../../models/external/user.model';
import TalentHistoryResponse = ResponseBody.TalentHistoryResponse;

/**
 * Used for filters and tooltip labels in the metrics chart
 */
export enum TalentMetricsFilterTypeLabels {
  Applies = 'Applies',
  ScheduledRecruiterInterview = 'Scheduled recruiter interview',
  RecruiterInterview = 'Recruiter interview',
  ReviewByAM = 'Internal Submission',
  ReviewByClient = 'Client Submission',
  ClientInterview = 'Interview with client',
  Assignment = 'Assignment'
}

export const TalentMetricsFilterTypeLabelsObject: any = {
    "Applies" : 'Applies',
    "ScheduledRecruiterInterview" :'Scheduled recruiter interview',
    "RecruiterInterview" : 'Recruiter interview',
    "ReviewByAM" : 'Internal Submission',
    "ReviewByClient" : 'Client Submission',
    "ClientInterview" : 'Interview with client',
    "Assignment" : 'Assignment'
};

/**
 * Used for displaying the proper ng-template
 */
export enum TalentMetricsActivityTypes {
  Applies = 'Applies',
  ScheduledRecruiterInterview = 'ScheduledRecruiterInterview',
  RecruiterInterview = 'RecruiterInterview',
  ReviewByAM = 'ReviewByAM',
  ReviewByClient = 'ReviewByClient',
  ClientInterview = 'ClientInterview',
  Assignment = 'Assignment'
}

/**
 * The properties to set as the initial modal configuration
 */
export interface MetricsModalConfig {
  activeFilter: TalentMetricsFilterTypeLabels;
  profile: Profile;
}

/**
 * The object which is created to display the activities
 * in the talent-metrics-modal component
 */
export interface TalentMetricsActivity<T> {
  type: TalentMetricsActivityTypes;
  filterLabel: TalentMetricsFilterTypeLabels;
  jobTitle: string;
  jobEmployer: string;
  userName?: string;
  userAvatar?: string;
  user?: User;
  data: T;
  notAFitReason?: string;
  expanded?: boolean;
  comment?: string;
  activityTimestamp?: string;
  workSiteLocation?: string;
  internal_max_pay_rate?: string;
  published_may_pay_rate?: string;
  bill_rate?: string;
  jobStatus?: string;
}

/**
 * The Talent Metrics modal filter display data
 */
export interface TalentMetricsFilterDisplay {
  label: TalentMetricsFilterTypeLabels;
  count: number;
}

/**
 * The item returned for metrics
 */
export interface TalentMetricsTypeCount {
  key: string;
  doc_count: number;
}

/**
 * The request body sent to retrieve the metrics
 */
export interface TalentMetricsRequest {
  talentBackOfficeId: string;
}

/**
 * Labels for TalentMetrics filters and activities
 * @type {{RT: TalentMetricsFilterTypeLabels[], RGS: TalentMetricsFilterTypeLabels[]}}
 */
export const TalentMetricsLabels: {RT: TalentMetricsFilterTypeLabels[], RGS: TalentMetricsFilterTypeLabels[]} = {
  RT: [
    TalentMetricsFilterTypeLabels.Applies,
    TalentMetricsFilterTypeLabels.ScheduledRecruiterInterview,
    TalentMetricsFilterTypeLabels.RecruiterInterview,
    TalentMetricsFilterTypeLabels.ReviewByAM,
    TalentMetricsFilterTypeLabels.ReviewByClient,
    TalentMetricsFilterTypeLabels.ClientInterview,
    TalentMetricsFilterTypeLabels.Assignment
  ],
  RGS: [
    TalentMetricsFilterTypeLabels.Applies,
    TalentMetricsFilterTypeLabels.ScheduledRecruiterInterview,
    TalentMetricsFilterTypeLabels.RecruiterInterview,
    TalentMetricsFilterTypeLabels.ReviewByClient,
    TalentMetricsFilterTypeLabels.ClientInterview,
    TalentMetricsFilterTypeLabels.Assignment
  ]
};

/**
 * history activityType mapping to ng-template name/TalentMetricsActivityTypes
 */
export const TalentMetricsActivityMapping: {[key: string]: {[key: string]: string[]}} = {
  RT: {
    RecruiterInterview: ['Recruiter Phone Screen', 'Modern Hire Interview'],
    ReviewByAM: ['Solutions Review', 'Submitted'],
    ReviewByClient: ['Client Submission'],
    ClientInterview: ['Modern Hire Interview', 'Interview Scheduled'],
    Applies: ['Applies'],
    ScheduledRecruiterInterview: ['Scheduled recruiter interview'],
    Assignment:['Assignment']

  },
  RGS: {
    RecruiterInterview: ['TINTV'],
    ReviewByClient: ['CSUB'],
    ClientInterview: ['SENDO'],
    Applies: ['Applies'],
    ScheduledRecruiterInterview: ['Scheduled recruiter interview'],
    Assignment:['Assignment']
  }
};
