import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { JobOrderService } from 'src/app/shared/services/job-order/job-order.service';
import { ToastClass, ToastService } from 'src/app/shared/services/toast';
import { Job } from '../../../../../shared/models/external/job.model';
import { User } from '../../../../../shared/models/external/user.model';
import { ApiService } from '../../../../../shared/services/api/api.service';
import { JobForm } from '../../../../../shared/models/external/job-form.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-owner-modal',
  templateUrl: './change-owner-modal.component.html',
  styleUrls: ['./change-owner-modal.component.scss']
})
export class ChangeOwnerModalComponent implements OnInit {
  saving = false;
  title = '';
  customer_name = '';
  front_office_id = '';
  customer_priority?: number;
  owner_name = '';
  jobData: any;
  assign: any;
  searchTerm = '';
  loading = false;
  selectedOwner: User;
  elasticJobOrder: Job; /* The Job The Way It Is In Elastic */
  formJobOrder: any; /* The Job The Way It Is In The Form */

  constructor(
    private _api: ApiService,
    public _bsModalRef: BsModalRef,
    private jobOrderService: JobOrderService,
    private toastService: ToastService,
    private translate: TranslateService) { }

  ngOnInit() {
    this.setData(this.jobData);
    this.setElasticJobOrder(this.jobData);
  }

  setElasticJobOrder = (job: Job) => {
    this.elasticJobOrder = job;
    if (this.elasticJobOrder && this.elasticJobOrder.allbirds_metadata) {
      this._api.getJob(this.elasticJobOrder.allbirds_metadata.allbirds_job_id, true).toPromise().then(this.setFormJobOrder);
    }
  };

  setFormJobOrder = (jobForm: JobForm) => {
    this.formJobOrder = jobForm;
  };

  setData(jobData: Job) {
    this.searchTerm = '';
    if (jobData) {
      if (jobData.title) {
        this.title = jobData.title;
      }

      if (jobData.allbirds_metadata) {
        this.customer_name = jobData.allbirds_metadata.customer_name ? jobData.allbirds_metadata.customer_name : '';
        this.customer_priority = jobData.allbirds_metadata.customer_priority;
        this.front_office_id = jobData.allbirds_metadata.front_office_id;
        this.owner_name = jobData.allbirds_metadata.published_by_user;
      }
    }
  }

  setOwner(recruiter: User) {
    this.selectedOwner = recruiter;
    this.searchTerm = `${this.selectedOwner.FirstName} ${this.selectedOwner.LastName} (${this.selectedOwner.EmailAddr})`;
  }

  changeOwner() {
    this.saving = true;
    this.formJobOrder.published_by_user = `${this.selectedOwner.FirstName} ${this.selectedOwner.LastName}`;
    this.formJobOrder.published_by_user_email = this.selectedOwner.EmailAddr;
    this.formJobOrder.published_by_user_back_office_id = this.selectedOwner.BackOfficeID;
    this.formJobOrder.published_by_user_front_office_id = this.selectedOwner.Oprid;

    const changedFields = [];
    changedFields.push('published_by_user');
    changedFields.push('published_by_user_email');
    changedFields.push('published_by_user_back_office_id');
    changedFields.push('published_by_user_front_office_id');

    this._api.updateJob(this.formJobOrder, changedFields).subscribe(this.publishJob, err => {
      this.saving = false;
      alert('Assign Owner Update Failed');
    });
  }

  publishJob = () => {
    const interviewObj = this.jobOrderService.getInterviewObject(this.elasticJobOrder, this.selectedOwner);
    this._api.publishJob(this.formJobOrder.allbirds_job_id, interviewObj)
      .subscribe(this.updateJobSuccess, err => {
        this.saving = false;
        alert('Job Republish Failed');
        console.log('job republish failed.', err);
      });
  };

  updateJobSuccess = () => {
    const trVariable = { 'value1': this.selectedOwner.FirstName, 'value2': this.selectedOwner.LastName };
    this.toastService.showToastWithVariables('change-owner-modal.transfer_success', trVariable, { cssClass: ToastClass.SUCCESS });
    this.elasticJobOrder.allbirds_metadata.published_by_user = `${this.selectedOwner.FirstName} ${this.selectedOwner.LastName}`;
    this.elasticJobOrder.allbirds_metadata.published_by_user_email = this.selectedOwner.EmailAddr;
    this.elasticJobOrder.allbirds_metadata.published_by_user_back_office_id = this.selectedOwner.BackOfficeID;
    this.elasticJobOrder.allbirds_metadata.published_by_user_front_office_id = this.selectedOwner.Oprid;
    this.saving = false;
    this.jobOrderService.storeJobDetails(this.elasticJobOrder);
    this.searchTerm = '';
    if (this._bsModalRef) {
      this._bsModalRef.hide();
    }
  };
}
