import { Injectable } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { LaneCondition } from '../../../models/internal/lane-condition.model';
import { Lane } from 'src/app/shared/models/internal/lane.model';
import { User } from '../../../models/external/user.model';
import { Job } from '../../../models/external/job.model';
import { ClosedStatus } from '../../../models/internal/process-statuses.interface';
import { JobOrderService } from '../../../services/job-order/job-order.service';
import { OrderType } from 'src/app/shared/models/internal/job-form.interface';

const sortByCondition = Symbol('sortByCondition'); // in order to create a private method

@Injectable({
  providedIn: 'root'
})
export class LaneConditionService {
  user: User;
  conditions: LaneCondition[];

  constructor(
    private _auth: AuthService,
    private _jobOrder: JobOrderService
  ) {
    this.user = _auth.user;
    this.conditions = [
      // new LaneCondition('closed_published', this.closedPublishedRGS, 'RGS'),
      new LaneCondition('closed', this.closed, null),
      new LaneCondition('drafts', this.draft, null),
      new LaneCondition('pipeline_postings', this.pipelinePostingsRGS, 'RGS'),
      new LaneCondition('unqualified', this.unqualified, null),
      new LaneCondition('unassigned', this.unassigned, null),
      new LaneCondition('sourcing_talent', this.sourcingTalentRT, 'BH'),
      new LaneCondition('sourcing_talent', this.sourcingTalentRGS, 'RGS'),
      new LaneCondition('review_account_manager', this.reviewAccountManager, 'BH'),
      new LaneCondition('review_hiring_manager', this.reviewHiringManagerRT, 'BH'),
      new LaneCondition('review_hiring_manager', this.reviewHiringManagerRGS, 'RGS'),
    ].filter(condition => (this.user && ( this.user.Source.checkLob(condition.lob) ||
      !condition.lob)));
    if (this.user) {
      this.setLanesByUser();
      this[sortByCondition](this.user.lanes);
      // console.log(this.user._lanes);
    }

  }

  getRule(name: string): LaneCondition {
    return this.conditions.find(condition => {
      return condition.name === name && (
        this.user['Source'].checkLob(condition.lob) ||
        !condition.lob
      );
    });
  }

  getConditions() {
    return this.conditions;
  }

  [sortByCondition](lanes: Lane[]) {
    // we need to sort the conditions by looking how many parameter it has desc order.
    // the reason for this, if an order both matches to condition ( A , B) and (A , B , C ), we need to first check (A , B , C) condition
    lanes.sort(function (a, b) {
      // if user does not have correct permissions then they will not get the condition loaded. Here we are trying to prevent that error
      // this should only be happening in QA, PROD should have correct permissions..
      if (!a.condition || !b.condition) { return -1; }
      // gets the string version of the condition then separates them by || OR && to get the parameter count
      return (b.condition.length - a.condition.length);
    });
  }

  //just show RGS jobs in 'Closed' status that have been republished and have an active timer
  // closedPublishedRGS = (jobOrder: Job) => (this._jobOrder.publishedStatus(jobOrder) && ClosedStatus.includes(jobOrder.allbirds_metadata.order_status));

  closed = (jobOrder: Job) => (ClosedStatus.includes(jobOrder.allbirds_metadata.order_status));

  draft = (jobOrder: Job) => (jobOrder.allbirds_metadata.order_created === false);

  pipelinePostingsRGS = (jobOrder: Job) =>
    jobOrder.allbirds_metadata.order_created &&
    jobOrder.allbirds_metadata.order_status === 'Unfilled' &&
    jobOrder.allbirds_metadata.order_type === OrderType.PIPELINE &&
    this._jobOrder.publishedStatus(jobOrder);

  unassigned = (jobOrder: Job, user: User) => jobOrder.allbirds_metadata.order_created && !jobOrder.allbirds_metadata.assigned_to_user &&
    jobOrder.allbirds_metadata.order_status === 'Accepting Candidates';

  unqualified = (jobOrder: Job, user: User) => jobOrder.allbirds_metadata.order_created &&
    jobOrder.allbirds_metadata.order_status === 'Unqualified';

  sourcingTalentRT = (jobOrder: Job, user: User) => {
    if (jobOrder && jobOrder.allbirds_metadata) {
      return (jobOrder.allbirds_metadata.order_created &&
        ['Accepting Candidates', 'On Hold', 'Covered'].includes(jobOrder.allbirds_metadata.order_status) &&
        jobOrder.allbirds_metadata.assigned_to_user && !(jobOrder.metrics && jobOrder.metrics.clientSubmission)
        && !(jobOrder.metrics && jobOrder.metrics.internalSubmission));
    }
    return false;
  }

  sourcingTalentRGS = (jobOrder: Job, user: User) => (
    jobOrder.allbirds_metadata.order_created &&
    jobOrder.allbirds_metadata.order_type !== OrderType.PIPELINE &&
    ['Unfilled'].includes(jobOrder.allbirds_metadata.order_status
  ));

  reviewAccountManager = (jobOrder: Job, user: User) => {
    if (jobOrder && jobOrder.allbirds_metadata) {
      return jobOrder.allbirds_metadata.order_created &&
        ['Accepting Candidates', 'On Hold', 'Covered'].includes(jobOrder.allbirds_metadata.order_status) &&
        jobOrder.allbirds_metadata.assigned_to_user && (jobOrder.metrics && jobOrder.metrics.internalSubmission) > 0 &&
        !(jobOrder.metrics && jobOrder.metrics.clientSubmission);
    }
    return false;
  };

  reviewHiringManagerRT = (jobOrder: Job, user: User) => {
    if (jobOrder && jobOrder.allbirds_metadata) {
      return jobOrder.allbirds_metadata.order_created &&
        ['Accepting Candidates', 'On Hold', 'Covered'].includes(jobOrder.allbirds_metadata.order_status) &&
        jobOrder.allbirds_metadata.assigned_to_user && (jobOrder.metrics && jobOrder.metrics.clientSubmission) > 0;
    }
    return false;
  };

  reviewHiringManagerRGS = (jobOrder: Job, user: User) => {
    if (jobOrder && jobOrder.allbirds_metadata) {
      return jobOrder.allbirds_metadata.order_created &&
        jobOrder.allbirds_metadata.order_type !== OrderType.PIPELINE &&
        jobOrder.allbirds_metadata.order_status === 'Unfilled' && (jobOrder.metrics && jobOrder.metrics.clientSubmission) > 0;
    }
    return false;
  };

  setLanesByUser() {
    const _this = this; // capture component scope for inner functions

    const { Permissions } = _this.user;
    _this.user.lanes = [];

    let lanePosition = 1;
    if (Permissions.createJob) {
      addLane('Drafts', 'drafts');
    }
    if (_this.user['Source'].checkLob('RGS')) {
      addLane('Pipeline Postings', 'pipeline_postings');
    }
    if (Permissions.viewUnQualifiedJobs) {
      addLane('Unqualified', 'unqualified');
    }
    if (Permissions.viewUnassignedJobs) {
      addLane('Unassigned', 'unassigned');
    }
    // all roles see the Sourcing Talent lane
    addLane('Sourcing Talent', 'sourcing_talent');
    if (Permissions.viewReviewAccountManager) {
      addLane('Internal Submission', 'review_account_manager');
    }
    // all roles see the Review Hiring Manager and Filled lanes
    addLane('Client Submission', 'review_hiring_manager');
    // if (_this.user['Source'].checkLob('RGS')) {
    //   addLane('Closed Published', 'closed_published');
    // }

    function addLane(title: string, rule: string) {
      _this.user.lanes.push(new Lane(title, lanePosition, _this.getRule(rule)));
      lanePosition++;
    }
  }
}
