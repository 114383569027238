import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { AuthService } from '../auth/auth.service';
import { CacheService } from '../cache/cache.service';
import { IterableService } from '../iterable/iterable.service';
import { TalentProcessService } from '../talent-process/talent-process.service';
import { Application } from '../../models/external/application.model';
import { Job } from '../../models/external/job.model';
import { Profile } from '../../models/external/profile.model';
import { RecruiterEvent } from '../../models/external/misc.model';
import { INTG_STEPS, UpdateManyApplicationsRequest } from 'src/app/shared/models/internal/process.model';
import { ListTalent } from 'src/app/shared/models/external/list-talent.model';
import { RecentActivityListCardDetails } from '../../models/internal/recent-activities-list.model';
import { JobDetailsShortlistService } from '../job-details-shortlist/job-details-shortlist.service';
import { SelectedProfiles, SelectedApplications, SelectedPipelineTalents, SelectedRecentActivities } from '../../models/internal/mass-action.model';

export interface MassSelectTalent {
  applications?: Application[];
  profiles?: Profile[];
  pipelineTalents?: ListTalent[];
  recentActivities?: RecentActivityListCardDetails[];
}


@Injectable({
  providedIn: 'root'
})
export class MassActionService {

  selectedProfiles: SelectedProfiles = {};
  selectedApplications: SelectedApplications = {};
  selectedPipelineTalents: SelectedPipelineTalents = {};
  selectedRecentActivities: SelectedRecentActivities= {};

  private massSelectTalent = new BehaviorSubject<MassSelectTalent>({ profiles: [],
    applications: [], pipelineTalents: [] });
  public massSelectTalentObs: Observable<MassSelectTalent> = this.massSelectTalent.asObservable();

  constructor(
    private _api: ApiService,
    private _auth: AuthService,
    private _cache: CacheService,
    private _process: TalentProcessService
  ) { }

  
  private emitSelectedTalent(): void {
    const talent: MassSelectTalent = {
      profiles: Object.values(this.selectedProfiles),
      applications: Object.values(this.selectedApplications),
      pipelineTalents: Object.values(this.selectedPipelineTalents),
      recentActivities: Object.values(this.selectedRecentActivities)
    };
    return this.massSelectTalent.next(talent);
  }



  /**
   * Resets selected talent.
   * @param list - if given, only resets that specific list
   */
  reset(list?: 'applications' | 'profiles' | 'pipelineTalents' | 'recentActivities') {
    this.selectedProfiles = (!list || list === 'profiles') ? {} : this.selectedProfiles;
    this.selectedApplications = (!list || list === 'applications') ? {} : this.selectedApplications;
    this.selectedPipelineTalents = (!list || list === 'pipelineTalents') ? {} : this.selectedPipelineTalents;
    this.selectedRecentActivities =( !list || list === 'recentActivities') ? {} : this.selectedRecentActivities;
    return this.emitSelectedTalent();
  }

  public isSelected(talent: Application | Profile | any ): boolean {
    if(!talent){
      return
    }
    return talent.isApplication()
      ? !!this.selectedApplications[talent.profile]
      : !!this.selectedProfiles[talent.name];
  }

  public isPipelineTalentSelected(talent: ListTalent): boolean  {
    return !!this.selectedPipelineTalents[talent.google_id];
  }

  public isRecentActivitySelected(activityInfo: RecentActivityListCardDetails): boolean {
    return !!this.selectedRecentActivities[activityInfo.activity.activityElasticId];
  }

  public getMassTalentSize(): number {
    return this._auth.user?.Source?.checkLob('RGS') ? 1000 : 50;
  }

  /**
   * Calls the appropriate handler depending on if the card of the clicked checkbox
   * was a potential talent (profile) or shortlist talent (application).
   * @param talent - application or profile object
   * @param toggleSelect
   */
  handleSelect(talent: Application | Profile , toggleSelect?: boolean): void {
    return talent.isApplication()
      ? this.handleApplicationSelect(talent, toggleSelect)
      : this.handleProfileSelect(talent, toggleSelect);
  }

  /**
   * If a profile that is already selected is clicked again, we deselect
   * the profile and vice-versa.
   * @param profile - profile object
   * @param toggleSelect
   */
  handleProfileSelect(profile: Profile, toggleSelect?: boolean) {
    const id = profile.name;
    if (this.selectedProfiles[id] && toggleSelect != false) {
      delete this.selectedProfiles[id];
    } else {
      this.selectedProfiles[id] = profile;
    }
    return this.emitSelectedTalent();
  }

  /**
   * If an application that is already selected is clicked again, we deselect
   * the application and vice-versa.
   * @param application - application object
   */
  handleApplicationSelect(application: Application, toggleSelect?: boolean) {
    const id = application.profile;
    if (this.selectedApplications[id] && toggleSelect != false) {
      delete this.selectedApplications[id];
    } else {
      this.selectedApplications[id] = application;
    }
    return this.emitSelectedTalent();
  }

  handleListTalentSelect(talent: ListTalent, toggleSelect?: boolean ) {
    const id = talent.google_id;
    if (this.selectedPipelineTalents[id] && toggleSelect != false) {
      delete this.selectedPipelineTalents[id];
    } else {
      this.selectedPipelineTalents[id] = talent;
    }
    return this.emitSelectedTalent();
  }

  handleRecentActivitySelect(activityInfo: RecentActivityListCardDetails, toggleSelect?: boolean) {
    const id = activityInfo.activity.activityElasticId;
    console.log("selected id = ", id);
    if (this.selectedRecentActivities[id] && toggleSelect != false) {
      delete this.selectedRecentActivities[id];
    } else {
      this.selectedRecentActivities[id] = activityInfo;
    }
    return this.emitSelectedTalent();
  }

  bulkShortlist(profiles: Profile[], job: Job): Promise<any> {
    return new Promise((resolve, reject) => {
      profiles = profiles.filter((p: Profile) => JobDetailsShortlistService.opcoDoesMatch(p, job, this._auth.user));
      if (profiles.length) {
        // Construct the request body.
        const body = {
          profiles,
          ids: {
            google_job_id: job.name,
            front_office_id: job.allbirds_metadata.front_office_id,
            allbirds_job_id: job.allbirds_metadata.allbirds_job_id,
            job_customer_id: job.allbirds_metadata.customer_id,
            job_contact_id: job.allbirds_metadata.contact_id,
            job_user_branch_id: job.allbirds_metadata.user_branch_id
          }
        };
        // Make the API call.
        this._api.addBulkToShortlist(body)
          .subscribe(res => {
            resolve(res);
          }, err => {
            reject(err);
          });
      } else {
        return reject({ reason: 'mass-shortlist.xopco-error' });
      }
    });
  }

  bulkRejection(profiles: Profile[], applications: Application[], job: Job): Promise<any> {
    if (applications && applications.length) {
      TalentProcessService.updateManyLastProcessStepDates(applications);
    }
    return new Promise((resolve, reject) => {
      const body = {
        profiles,
        applications,
        rejectReason: '',
        rejectNote: 'Candidate was rejected via mass actions.',
        rejectedByAgentID: (
          this._auth &&
          this._auth.user &&
          this._auth.user.BackOfficeID
        ) ? this._auth.user.BackOfficeID : '',
        ids: {
          google_job_id: job.name,
          front_office_id: job.allbirds_metadata.front_office_id,
          allbirds_job_id: job.allbirds_metadata.allbirds_job_id,
          job_customer_id: job.allbirds_metadata.customer_id,
          job_contact_id: job.allbirds_metadata.contact_id,
          job_user_branch_id: job.allbirds_metadata.user_branch_id
        }
      };
      this._api.rejectManyTalent(body)
        .subscribe(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  bulkUpdate(applications: Application[], job?: Job): Promise<any> {
    const pipelineFlag = (job && job.allbirds_metadata.order_type == 'Pipeline') ? true : false;

    return new Promise((resolve, reject) => {
      if (applications && applications.length) {
        TalentProcessService.updateManyLastProcessStepDates(applications);
      }
      const intgSteps: INTG_STEPS[] = [];
      const body: UpdateManyApplicationsRequest = {
        applications: applications,
        intgSteps: intgSteps,
        pipeline: pipelineFlag

      }
      this._api.updateManyApplications(body)
        .subscribe((res: any) => {
          if (res && res.applications) {
            resolve(res);
          }
        }, err => {
          reject(err);
        });
    });
  }

  bulkPrescreen(applications: any[], job: any, profiles?: any[]): Promise<any> {
    return new Promise((resolve, reject) => {
      if (profiles) {
        this.handlePrescreenProfileResponse(applications, profiles, job)
          .then(res => resolve(res))
          .catch(err => reject(err));
      } else {
        // To construct the request body as required by Iterable, we need to include information from both
        // the application object and profile object. Since we don't always have the profile object on hand,
        // we have to fetch the profile information before we can make the call to our API route that interfaces
        // with Integrations & Iterable.
        const externalIds = applications.map(a => a.randstad_process.candidateFrontOfficeID);
        this._api.getProfilesById({ externalIds })
          .subscribe(profiles => {
              console.log('[bulkPrescreen] getProfilesById', profiles);
              this.handlePrescreenProfileResponse(applications, profiles, job)
                .then(res => resolve(res))
                .catch(err => reject(err));
            }, err => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  handlePrescreenProfileResponse(applications: Application[], profiles: Profile[], job: Job): Promise<boolean> {
    const pipelineFlag = (job.allbirds_metadata.order_type == 'Pipeline') ? true : false;
    return new Promise((resolve, reject) => {
      if (!profiles || !profiles.length) {
        // console.log('[handlePrescreenProfileResponse] no profiles returned.');
        return reject(false);
      }
      // Cache the received profiles.
      let i = profiles.length;
      while (i--) {
        if (
          profiles[i] &&
          profiles[i].externalId
        ) {
          this._cache.cacheProfile(profiles[i].name, profiles[i]);
        }
      }
      // Map all selected talent application objects into a form that is expected by Integrations/Iterable.
      const updateBody: Application[] = [];
      const iterableBody: RecruiterEvent[] = [];
      let j = applications.length;
      while (j--) {
        if (
          applications[j] &&
          applications[j].randstad_process &&
          applications[j].randstad_process.candidateFrontOfficeID &&
          this._cache.loadProfile(applications[j].profile)
        ) {
          IterableService.applyPrescreeningScheduleToApplication(applications[j], this._auth.user);
          updateBody.push(applications[j]);
          iterableBody.push(
            IterableService.constructIterableEvent(
              applications[j],
              this._cache.loadProfile(applications[j].profile),
              job,
              this._auth.user
            )
          );

        }
      }
      let intgSteps: INTG_STEPS[] = [];
      //chatbot integration steps
      intgSteps.push(INTG_STEPS.ACTIVITY);
      const body: UpdateManyApplicationsRequest = {
        applications : updateBody,
        intgSteps: intgSteps,
        pipeline: pipelineFlag
      }

      forkJoin([
        this._api.updateManyApplications(body),
        this._api.schedulePrescreening(iterableBody)
      ]).subscribe(([appRes, iterRes]) => {
        this._process.propagateApplicationUpdates(updateBody);
        // console.log('[handlePrescreenProfileResponse] update response:', appRes);
        // console.log('[handlePrescreenProfileResponse] iterable response:', iterRes);
        resolve(true);
      }, err => {
        console.log('[err]', err);
        reject(false);
      });
    });
  }

  /**
 * @typedef {Object} DeletedProfiles
 * @property {Profile[]} deletedProfs - The deleted profiles
 * @property {(string[] | Profile[])} nonDeletedProfs - The non-deleted profiles
 */
  /**
   * Converts profile id strings to profile objects and returns deletedProfiles, 
   * that have a truthy isDeleted value and non-deleted profiles that have a falsy isDeleted value 
   * or no isDeleted field at all.
   * @param {string[]} profiles - An array of strings composed of profile id's
   * @param {boolean} [isAddtoJob] - Returns non-deleted profile ids (array of strings) rather than objects of type Profile
   * @returns {DeletedProfiles} Deleted and non deleted profiles
   */
  async getDeletedProfiles(profiles: string[], isAddToJob?: boolean): Promise<any> {
    let deletedProfs: Profile[] = [];
    let nonDeletedProfs: any[] = [];
    let disqualifiedProfs : any[] = [];
    const mappedProfiles = await this._api.getProfilesById({ names: profiles }).toPromise();
    mappedProfiles.map(profile => {
      //Using short-circuit evaluation
      //If it's deleted, push to deletedProfs array to show that the user has been merged 
      //to RFO error validation message
      //If it's disqualified, push to disqualifiedProfs to show error message
      if (isAddToJob) !profile.isDeleted && !profile.isDisqualified && nonDeletedProfs.push(profile.name) || profile.isDeleted && deletedProfs.push(profile)
       else {
        if (profile.isDeleted) {
          deletedProfs.push(profile)
        } else if (profile.isDisqualified) {
          disqualifiedProfs.push(profile)
        } else {
          nonDeletedProfs.push(profile)
        }
      }
    })
    return { deletedProfs, nonDeletedProfs, disqualifiedProfs }
  }
}
