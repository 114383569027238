import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
// import { RecruiterResults } from '../../models/recruiter.model';
import { debounceTime } from 'rxjs/operators';
import { SearchTerms } from '../../models/internal/search-terms.model';
import { User } from '../../models/external/user.model';
import { ApiService } from '../../services/api/api.service';
import { AuthService} from '../../services/auth/auth.service';

@Component({
  selector: 'app-user-lookup',
  templateUrl: './user-lookup.component.html',
  styleUrls: ['./user-lookup.component.scss']
})
export class UserLookupComponent implements OnChanges, OnInit {

  @Input() searchTerm: string;
  @Input() includePhone: boolean = true;
  @Input() allActiveUsers = false;
  @Input() width: number;
  @Input() excludeUsers: any[] = [];
  @Output() userSelected = new EventEmitter();
  @Output() userResults = new EventEmitter();

  termChange: Subject<string> = new Subject<string>(); /* Publishes when the search term changes */
  results: Array<User> = [];
  resultsChange: Subject<User[]> = new Subject(); /* Publishes when the results changes */
  loading = false;

  constructor(
    private _api: ApiService,
    private _auth: AuthService
    ) { }

  ngOnInit() {
    this.termChange.pipe(debounceTime(500)).subscribe(this.updateSearch);
    this.resultsChange.subscribe(this.updateResults);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.searchTerm) {
      this.searchTerm = changes.searchTerm.currentValue;
      this.termChange.next(this.searchTerm);
    }
    if(changes.excludeUsers) {
      this.excludeUsers = changes.excludeUsers.currentValue;
    }
  }

  isLoading() {
    return this.loading;
  }

  clear = () => {
    this.results.length = 0;
    this.searchTerm = '';
  }

  updateResults = (results: User[]) => {
    this.results.length = 0;
    results.forEach(
      (result) => {
        this.results.push(result);
      }
    );
    this.userResults.emit({results: this.results, searchTerm: this.searchTerm});
  }

  updateSearch = (searchTerm: string): void => {
    if (searchTerm === '' || searchTerm.length < 3) {
      this.loading = false;
      this.updateResultsListing([]);
      this.clear();
    } else {
      this.loading = true;
      const convertedSearchTerm = this.searchTermBuilder(searchTerm); /* Converts the user's input into an object so we can know if first and last name are present */
      const firstName = convertedSearchTerm.firstName;
      const lastName = convertedSearchTerm.lastName;
      const term = convertedSearchTerm.term;
      if (this.allActiveUsers) {
        this._api.searchUsers(searchTerm, true).toPromise().then(this.updateResultsListing);
      } else {
        const lob = this._auth.user.Source;
        const recruiterIds = this.excludeUsers && this.excludeUsers.length ? this.excludeUsers.map(user => user.user_back_office_id) : null;
        this._api.getRecruiters(term, recruiterIds ? { recruiterIds } : null, firstName, lastName, lob).toPromise().then(this.updateResultsListing);
      }
    }
  };

  searchTermBuilder = (searchTerm: string): SearchTerms => {
    const searchTermArr = searchTerm.split(' ').filter(term => term); /* Splits the string and removes empty spaces */

    if (searchTermArr.length > 1) {
      return {
        firstName: searchTermArr[0],
        lastName: searchTermArr[1],
        term: ''
      };
    } else {
      return {
        firstName: '',
        lastName: '',
        term: searchTermArr[0]
      };
    }
  };

  updateResultsListing = (users: User[]) => {
    if (this.allActiveUsers) {
      this.resultsChange.next(users);
    } else {
      const clonedUsers = users.map(user => user.clone());
      this.resultsChange.next(clonedUsers);
    }
    this.loading = false;
  };

  selectUser = (recruiter: User) => {
    this.userSelected.emit(recruiter);
    if (this.allActiveUsers) { this.clear(); }
  };

}
