import { Application } from "../external/application.model";

export interface UpdateApplicationRequest {
    application: Application,
    intgSteps?: INTG_STEPS[],
    pipeline?: Boolean
}

export enum INTG_STEPS {
    SUBMISSION = "SUBMISSION",
    ACTIVITY = "ACTIVITY"
}

export interface UpdateManyApplicationsRequest {
    applications: Application[],
    intgSteps?: INTG_STEPS[],
    pipeline?: Boolean
}
  

