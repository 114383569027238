import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface Filter {
  id: string;
  name: string;
}

export interface ModifiedFilter extends Filter {
  add: boolean;
}

const MOCK_FILTERS: Filter[] = [
  { id: 'f1', name: 'Some Filter' },
  { id: 'f2', name: 'Another Filter' },
  { id: 'f3', name: 'Wild Filter' },
  { id: 'f4', name: 'Crazy Sort' }
];

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  private filterSubject = new BehaviorSubject<Filter[]>(MOCK_FILTERS);
  private activeFilterSubject = new BehaviorSubject<Filter[]>([]);

  public filters = this.filterSubject.asObservable();
  public activeFilters = this.activeFilterSubject.asObservable();

  constructor() { }

  get filterValues() { return this.filterSubject.getValue(); }

  get activeFilterValues() { return this.activeFilterSubject.getValue(); }

  setFilters(modifiedFilters: ModifiedFilter[]): Promise<any> {
    return new Promise((resolve, reject) => {
      if (modifiedFilters.length === 0) {
        reject({ message: 'No filters were modified!' });
      } else {
        const filters = this.filterSubject.getValue();
        const activeFilters = this.activeFilterSubject.getValue();
        for (const filter of modifiedFilters) {
          if (filter.add) {
            console.log(`Adding filter: ${filter.name}.`);
            const filterIndex = filters.findIndex(f => f.id === filter.id);
            if (filterIndex !== -1) {
              activeFilters.push(filter);
              filters.splice(filterIndex, 1);
            }
          } else {
            console.log(`Removing filter: ${filter.name}.`);
            const filterIndex = activeFilters.findIndex(f => f.id === filter.id);
            if (filterIndex !== -1) {
              filters.push(filter);
              activeFilters.splice(filterIndex, 1);
            }
          }
        }
        this.filterSubject.next(filters);
        this.activeFilterSubject.next(activeFilters);
        resolve({ message: `Filters successfully applied.` });
      }
    });
  }

}
