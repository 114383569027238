import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {EmailRecipient} from '../../models/internal/notifications-data.model';
import { AuthService } from '../auth/auth.service';
import { ABBREVIATION_TO_STATE, EMAIL_REGEX } from './constants';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BarChangerService } from '../barChanger/bar-changer.service';
import { FeatureUnavailableModalComponent } from '../../components/feature-unavailable-modal/feature-unavailable-modal.component';
import { ModalService } from '../modal/modal.service';
import { Job } from '../../models/external/job.model';
import moment, { Moment } from 'moment';
import { PostalAddress } from '../../models/external/misc.model';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Profile } from '../../models/external/profile.model';
import {htmlToElement} from './formatters';
import {Application} from '../../models/external/application.model';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(
    public auth: AuthService,
    private _barChanger: BarChangerService,
    private _bsModal: ModalService,
    private _router: Router,
    private _translate: TranslateService,
    private _modal: ModalService
  ) { }

  bsModalRef: BsModalRef;

  /**
   * Given a front office id, returns an object containing the prefix
   * defining the LOB, and the unique number assigned to the talent (as a string).
   * e.g. USRT1293102 => { prefix: 'USRT', idNumber: '1293102' };
   *
   * @param id - a valid Randstad front office id in the format ([A-Z]*[0-9]*)
   */
  static splitFrontOfficeId(id: string) {
    if (
      !id ||
      !id.length ||
      id.constructor !== String
    ) {
      return null;
    }
    let i = 0;
    while (isNaN(Number(id.charAt(i)))) {
      i++;
    }
    return {
      prefix: id.substring(0, i),
      idNumber: id.substring(i)
    };
  }

    /**
   * Compare 2 objects. Return true if they are the same (includes prototype checking)
   * @param {any} a
   * @param {any} b
   * @returns {boolean}
   */
  static isSame(a: any, b: any): boolean {
    return Object.keys(a).length === Object.keys(b).length &&
      Object.keys(a).every(key =>
        b.hasOwnProperty(key) && a[key] === b[key]
      );
  }

  static getStateNameFromAbbreviation(abbreviation: string) {
    return ABBREVIATION_TO_STATE[abbreviation] || '';
  }

  static toTitleCase(str: string): string {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  static removeUnderscore(str: string): string {
    return str.replace(/_/g, ' ');
  }

  static camelCase(str: string) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index)
    {
        return index == 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}

  static buildAddressString(add: Partial<PostalAddress>): string {
    if (add && add.addressLines) {
      const addressLines = add.addressLines.length > 1 ? add.addressLines.join(', ') : add.addressLines[0];
      return `${addressLines}, ${add.locality}, ${add.administrativeArea} ${add.postalCode}`;
    } else {
      return '';
    }
  }

  public launchFeatureUnavailableModal(reason: string): void {
    const translatedReason = this._translate.instant(reason);
    const initialState: any = { reason: translatedReason };
    this.bsModalRef = this._modal.show(FeatureUnavailableModalComponent, { initialState });
    this.bsModalRef.setClass('modal-sm');
  }

  public redirectTo404() {
    this._router.navigate(['/404']);
  }

  /**
   * Checks if the passed field value is same as one of the value passed into this function
   * Can be used with *ngIf to check if the UI conditions are met
   *
   * @param {String|Array} value - value that we are looking for. Can be Array of values or a single value
   * @param {String|Array} field - field that we are searching against
   * @return {boolean} - if one of the values is same as the field value return true
   * @example
   * instead of doing this : *ngIf="object.employmentTypes === 'Contract' || object.employmentTypes === 'Contract to Hire'"
   * now we can do this:
   * *ngIf="isValueInField(['Contract','Contract to Hire'],object.employmentTypes)"
   * OR with String
   * *ngIf="isValueInField('Permanent',object.employmentTypes)"
   * todo: improve for AND OR operations
   * */
  public isValueInField(value: any, field: any) {
    if (!value || !field) {
      return false;
    }

    if (value.constructor.name === 'Array') {
      if (field.constructor.name === 'String') {
        for (let i = value.length; i--;) {
          if (value[i] === field) {
            return true;
          }
        }
      } else if (field.constructor.name === 'Array') {
        for (let i = value.length; i--;) {
          if ((field || []).includes(value[i])) {
            return true;
          }
        }
      }
    } else if (value.constructor.name === 'String') {
      if (field.constructor.name === 'String') {
        return field === value;
      } else if (field.constructor.name === 'Array') {
        for (let i = value.length; i--;) {
          if ((field || []).includes(value)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  public calcNumberOfDays(job: Job): number {
    const expirationDate = job.expirationDate;
    if (expirationDate) {
      const day = expirationDate.diff(moment(), 'days', true);
      return Math.max(0, Math.ceil(day));
    } else {
      // console.log("No Compare date");
    }
  }

  public getInitialsFromEmail(email: string): any {
    if (!email) { return { initials: '', name: '' }; }
    const splitArr = email.split('.');
    if (splitArr[0] && splitArr[1]) {
      return {
        initials: splitArr[0][0].toUpperCase() + splitArr[1][0].toUpperCase(),
        name: splitArr[0] + ' ' + splitArr[1].split('@')[0]
      };
    }

    return { initials: '', name: '' };
  }

  public getInitialsFromName(name: string): any {
    if (!name) { return { initials: '', name: '' }; }
    return {
      initials: name.match(/\b\w/g).join(''),
      name
    };
  }

  public precision(a: number): number {
    /* determines the number of decimal places */
    if (!isFinite(a)) {
      return 0;
    }
    let e = 1, p = 0;
    while (Math.round(a * e) / e !== a) {
      e *= 10;
      p++;
    }
    return p;
  }

  static validateEmail(value: string): boolean {
    return EMAIL_REGEX.test(value);
  }

  /**
   * Given an array of strings, returns an object where each string of the
   * array is a key and the value is true (boolean).
   * @param stringArr - array of strings
   */
  static transformStringArrayIntoMap(stringArr: string[]) {
    if (!stringArr || !stringArr.length) {
      return null;
    }
    return stringArr.reduce<{ [key: string]: boolean }>((result, str) => {
      result[str] = true;
      return result;
    }, {});
  }

  /**
   * Given a url, returns the relative path after the last '/'.
   * Ex: https://abc.com/123.php will return '123.php'
   * @param url
   */
  static extractUrlSuffix(url: string) {
    let suffix = '';
    const lastSlashIdx = url.lastIndexOf('/');
    if (lastSlashIdx !== -1) {
      suffix = url.substring(lastSlashIdx + 1);
    }
    return suffix;
  }

  static destroySubscription(subscription: Subscription): void {
    if (subscription && subscription.unsubscribe) {
      subscription.unsubscribe();
    }
  }

  static getNameFromTalentProfile (profile: Profile): string {
    let name = '';
    if (
      profile &&
      profile.personNames &&
      profile.personNames.length
    ) {
      const nameObj = profile.personNames[0];
      if (nameObj && nameObj.structuredName) {
        name = nameObj.structuredName.givenName || '';
        name += nameObj.structuredName.familyName ? ` ${nameObj.structuredName.familyName}` : '';
      }
    }
    return name;
  }

  static parseFilename(fileName: string): string {
    const fileIdx = fileName.lastIndexOf('/');
    const fullFileName = fileName.substring(fileIdx + 1);
    return fullFileName.replace(/^((?:[0-9]*)_(?:[0-9]*)_)/, '');
  }
  static formatJobDesc(jobDesc: string, ordDesc: string): string{
    if(jobDesc && ordDesc){
      return jobDesc + '<br>' + ordDesc;
    }
    return jobDesc || ordDesc || null;
  }

  /**
   * Take the output html of the ckeditor and pull the mentions data out of it
   * @param {string} html
   */
  static getMentionsRecipients(html: string): EmailRecipient[] {
    const mentions: EmailRecipient[] = [];
    const rawHtml = htmlToElement(html);
    const mentionElements = rawHtml.querySelectorAll('.ckeditor-mentions-output-item');
    mentionElements.forEach((span: Element) => {
      mentions.push({
        recipient: span.getAttribute('title'),
        recipientEmail: span.getAttribute('data-id')
      });
    });
    return mentions;
  }

  /**
   * Get the plain text of the ckeditor
   * @param {string} html
   */
  static getCkEditorTextOnly(html: string): string {
    const rawHtml = htmlToElement(html);
    return rawHtml.innerText;
  }

  static getTitleRestrictedMsg(title: string) {
    const errorMessages = ['', ''];
    if(title){
      const special = /[?!$%@\^&\*;:{}='_`~\[\]]/g;
      const forbidden = /\bcareer fair\b|\bjob fair\b|\bopen house\b|\bcareer expo\b|\bopen interviews\b|\btemp\b|\btemp to hire\b|\bdirect hire\b|\bperm\b|\bpermanent\b|\bposition\b|\bpositions\b|\bnow\b|\bneeded\b|\bopportunity\b|\bopportunities\b|\bimmediate\b|\bimmediately\b|\bhiring\b|\bjobs\b|\bnow hiring\b|\blevel i\b|\blevel i or ii\b|\blevel ii\b|\bapply\b/g;
      const foundChar = title.match(special) || [];
      const foundWord = title.match(forbidden) || [];
      if (foundChar.length) {
        let errorString = foundChar[0];
        let long = false;
        if (foundChar.length > 1) {
          long = true;
          const end = foundChar.length - 1;
          errorString = `${foundChar.slice(0, end).join(', ')} and ${foundChar[end]}`;
        }
        errorMessages[0] = `The special character${long ? 's' : ''} ${errorString} ${long ? 'are' : 'is'} not allowed.`;
      }
      if (foundWord.length) {
        let errorString = foundWord[0];
        let long = false;
        if (foundWord.length > 1) {
          long = true;
          const end = foundWord.length - 1;
          errorString = `${foundWord.slice(0, end).join(', ')} and ${foundWord[end]}`;
        }
        errorMessages[1] = `The term${long ? 's' : ''} ${errorString} ${long ? 'are' : 'is'} not allowed.`;
      }
    }
    return errorMessages;
  }

  getContextUrl(pathName: string, queryString: string) {
    let contextUrl = pathName.replace(pathName.charAt(0), '');
    const searchParams = new URLSearchParams(queryString);
    if (searchParams.has('st')) {
      if (searchParams.has('shortlist_step')) {
        searchParams.delete('shortlist_step');
      }
      let count = 0;
      searchParams.forEach((value, key) => {
        let urlPart = count > 0 ? '&' : '?';
        urlPart += `${key}=${value}`;
        contextUrl += urlPart;
        count++;
      });
    }
    // console.log('contextUrl=', contextUrl);
    return contextUrl;
  }

  /**
   * Handles retrieval of file attachments used in internal submission, and checks for duplicates.
   * Made to accommodate older jobs where only submissionURI was saved.
   * @param app - application
   * @returns array of file attachments
   */
  extractAttachments = (app: Application) => {
    try {
      const submissionURI = app?.randstad_process.internalSubmission?.submissionAttachmentURI;
      const submissionUrlAttachments = submissionURI ? app?.randstad_process.internalSubmission?.submissionAttachmentURI?.split(',') : [];
      const applicationAttachments = app?.randstad_process?.attachments || [];
      if (submissionUrlAttachments.length && applicationAttachments.length) {
        for (let i = applicationAttachments.length; i--;) {
          for (let j = submissionUrlAttachments.length; j--;) {
            if (submissionUrlAttachments[j] === applicationAttachments[i].path) {
              submissionUrlAttachments.splice(j, 1);
            }
          }
        }
      }
      let attachments = [...applicationAttachments, ...submissionUrlAttachments.map((x) => { return {'path': x, 'name': x};})];
      return attachments;
    } catch (e) {
      console.log("Attachment ERROR" + e);
      return [];
    }
  }

  // return RT or RE or RGS on a prefix like USRT USRGS USRE
  static extractTalentLobFromTalentId(talentId: string, externalSystem?: string, externalSystemLob?: string): string {
    const prefix: string = talentId && talentId.length ? UtilityService.splitFrontOfficeId(talentId).prefix : '';
    if(externalSystem == 'CR' || externalSystemLob == 'CR') {
      return 'CR';
    }
    if (prefix.includes('RT')) {
      return 'RD';
    } else if (prefix.includes('RGS')) {
      return 'RGS';
    } else if (prefix.includes('RE')) {
      return 'RE';
    } else if (prefix.includes('CR')) {
      return 'CR';
    }

    return prefix;
  }

}
