<div class="modal-header" style="justify-content: flex-end;">
    <button type="button" class="close-btn" aria-label="Close" (click)="_bsModalRef.hide()">
      <i class="icon-WL_icon_close"></i>
    </button>
</div>

<div class="modal-body">
  <h2 class="mb-2">{{'extend-job-modal.extend_job' | translate}}</h2>

  <!-- job details -->
  <table>
    <tr>
      <td><h4>{{ "send-user-email.job_title" | translate }}</h4></td>
      <td><h4 class="gray-text ml-3">{{title}}</h4></td>
    </tr>
    <tr>
      <td><h4>{{ "send-user-email.company" | translate }}</h4></td>
      <td><h4 class="gray-text ml-3">{{customer_name}} <span class="priority">P{{customer_priority}}</span> </h4></td>
    </tr>
    <tr>
      <td><h4>{{ "send-user-email.job_id" | translate }}</h4></td>
      <td><h4 class="gray-text ml-3">{{front_office_id}}</h4></td>
    </tr>
  </table>

  <h2 class="mb-2">{{'extend-job-modal.are_you_sure' | translate}}</h2>
  <div *ngIf="errorMsg" class="errorMsgContainer">
      <img class="menu-button-icon pr-1" src="../../../assets/icons/alert-yellow.svg" style="margin-top: -4px; min-width: 24px"/>
      <h4 style="margin: 0; width: auto;">
        {{'extend-job-modal.button_disabled' | translate}}
    </h4>
  </div>
</div>

<div id="extend-job-footer" class="modal-footer">
    <button (click)="_bsModalRef.hide()" class="btn rds--button--primary">{{'extend-job-modal.cancel' | translate}}</button>
    <!-- hover over message for disabled button removed from scope -->
    <button *ngIf="!saving" (click)="extendJob()" class="btn rds--button--primary" [disabled]="errorMsg">{{'extend-job-modal.extend_reopen_job' | translate}}</button>
    <button *ngIf="saving" class="btn rds--button--primary">{{'extend-job-modal.extend_job_saving' | translate}}</button>
</div>
