export const SUBMISSION_SOURCE_TYPES = {
  Bullhorn: 'Bullhorn',
  'CareerBuilder Resume Search': 'CareerBuilder Resume Search',
  ClearanceJobs: 'ClearanceJobs',
  Craigslist: 'Craigslist',
  Dice: 'Dice',
  'Dice Resume Search': 'Dice Resume Search',
  Elite: 'Elite',
  Facebook: 'Facebook',
  Github: 'Github',
  Google: 'Google',
  'ilabor-direct':'ilabor-direct',
  Indeed: 'Indeed',
  Linkedin: 'Linkedin',
  'Linkedin Recruiter': 'Linkedin Recruiter',
  'Marketing Campaign': 'Marketing Campaign',
  'Monster Resume Search': 'Monster Resume Search', // removing because confirmed by Robert that should not be here: https://global-jira.randstadservices.com/browse/DF044-10556
  'Networking event': 'Networking event',
  'Payroll Deal': 'Payroll Deal',
  'Randstad Revenue Sharing': 'Randstad Revenue Sharing',
  Referral: 'Referral',
  TalentBin: 'TalentBin',
  'Third Party': 'Third Party',
  Twitter: 'Twitter',
  WITI: 'WITI',
  'Xplore Match': 'Xplore Match',
  'Xplore Search': 'Xplore Search',
  // 'Dice Open Web': 'Dice Open Web',
  // 'Job Fair': 'Job Fair',
  // Networking: 'Networking',
  // 'Recruiter Workbench': 'Recruiter Workbench',
  // 'Stack Overflow': 'Stack Overflow',
  // 'Talent Gear': 'Talent Gear',
  // 'Texas Workforce Commission': 'Texas Workforce Commission',
  Other: 'Other'
};

export interface SubmissionDetails {
  payRate: number;
  payType: string;
  submissionSource: string;
  emailBody: string;
  resume?: File;
  //existingFiles?: any[];
  //uploadedFiles?: any[]
  files?: any[];
}
