import { AfterViewInit, Component, OnInit, ContentChild, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Talent } from "src/app/shared/models/external/talent.interface";
import { ApiService } from 'src/app/shared/services/api/api.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { TalentManagementSearchResultsComponent } from '../../../modules/talent/pages/talent-management-search/talent-management-search-results/talent-management-search-results.component';
import { GoogleProfile } from 'src/app/shared/models/external/google-profile.model';
import { PaginateService } from 'src/app/shared/services/paginate/paginate.service';
import { TalentService } from 'src/app/shared/services/talent/talent.service';
import { Router } from '@angular/router';
import { LoadingSpinnerService } from 'src/app/shared/services/loading-spinner/loading-spinner.service';


@Component({
    selector: 'app-dup-talent-modal',
    templateUrl: './dup-talent-modal.component.html',
    styleUrls: ['./dup-talent-modal.component.scss'],
    providers: [TalentManagementSearchResultsComponent],
    encapsulation: ViewEncapsulation.None
})

export class DupTalentModalComponent implements OnInit, AfterViewInit {
  name: string;
  type: string;
  talent: any;
  profiles: any;
  duplicateTalent: any;
  @ContentChild('talentManagementSearchResultsComponent') talentManagementSearchResultsComponent: any;
  public triggerShortlistEvent = new EventEmitter<any>();
  public triggerLogNoteEvent = new EventEmitter<any>();
  public logInterviewEvent = new EventEmitter<any>();
  public submitAccountManagerEvent = new EventEmitter<any>();
  public clientSubmissionEvent = new EventEmitter<any>();
  loading: boolean;
  searchResults: any;
  isTalentSelected: boolean = false;
  selectedTalent: any;
  surfaceModal: boolean = false;
  get isUserRgs() { return this._auth.user.Source.checkLob('RGS'); }

  get userLob(): string {
    return this._auth.user && this._auth.user.Source;
  }
  constructor(
    public _bsModalRef: BsModalRef,
    public talentService: TalentService,
    public translate: TranslateService,
    private _api: ApiService,
    private _auth: AuthService,
    private _router: Router,
    private spinner: LoadingSpinnerService,
    public paginate: PaginateService<GoogleProfile>,
  ) { }

  ngOnInit() {
    console.log('DupTalentModalComponent', this.talent, this.duplicateTalent, this.searchResults, this.type);
    this._bsModalRef.setClass('modal-xl');
    this.setPotentialTalentDupes();
  }

  ngAfterViewInit() {
  }

  checkForPotentialTalentDupes() {
    this.loading = true;
    console.log(this.talent.isProfile(), this.talent.isGoogleProfile(), this.talent.isApplication())
    if (this.talent.isProfile()) {
      const familyName = this.talent.familyName;
      const givenName = this.talent.givenName;
      this.name = `${givenName} ${familyName}`;
      const lob = this.talent.lob;
      const phoneNumbers = this.talent.candidatePhoneNumber;
      const talentId = this.talent.talentId;
      const body = { familyName, givenName, lob, phoneNumbers, talentId }
      this._api.getDuplicateTalent(body)
        .subscribe(data => {
          this.loading = false;
          this.spinner.hide();
          this.selectedTalent = this.talent;
          this.searchResults = {
            total: data.length
          }
          if (data.length <= 1) {
            this.submitForm();
            return;
          }
          this.profiles = data;
          console.log('this.profiles', this.profiles);
          this.surfaceModal = true;
        });
    }


  }

  setPotentialTalentDupes() {
    console.log('this.talent in setPotentialTalentDupes', this.talent);
    const familyName = this.talent?.personNames[0]?.structuredName?.familyName;
    const givenName = this.talent?.personNames[0]?.structuredName?.givenName;
    this.name = `${givenName} ${familyName}`;
    this.selectedTalent = this.talent;
    this.loading = false;
    const index =  this.duplicateTalent.indexOf(this.talent);
    this.duplicateTalent.splice(index,1);
    this.profiles =  this.duplicateTalent;
    this.surfaceModal = true;
  }

  talentSelected(talent: any) {
    this.selectedTalent = talent;
    this.isTalentSelected = true;
    this.paginate.selectTalent(talent);
    this.talentService.talentSelectionComplete = true;
  }

  isSelectedItem(talent: any) {
    return this.selectedTalent === talent;
  };

  submitForm() {
    this._bsModalRef.hide();
    if (this.type == 'addToJob') {
      let filters = [];
      filters.push({
        value: this._auth.user._id,
        field: "user",
        type: "string",
        display: `${this._auth.user.FirstName} ${this._auth.user.LastName}`,
      });
      filters = [
        ...filters,
        ...[
          {
            value: "Accepting Candidates",
            field: "allbirds_metadata.order_status",
            type: "string",
            lob: this.userLob,
          },
          {
            value: "Unqualified",
            field: "allbirds_metadata.order_status",
            type: "string",
            lob: this.userLob,
          },
          {
            value: "Covered",
            field: "allbirds_metadata.order_status",
            type: "string",
            lob: this.userLob,
          },
          {
            value: "On Hold",
            field: "allbirds_metadata.order_status",
            type: "string",
            lob: this.userLob,
          },
          {
            value: this.userLob,
            field: "allbirds_metadata.lob",
            type: "string",
          },
        ],
      ];


      const url = `/talent/add-to-job/search?sourceType=all&filters=${JSON.stringify(
        filters
      )}&profile_id=${this.selectedTalent.profileId}`;
      this._router.navigateByUrl(url, { state: { url: this._router.url } });
    } else if (this.type === 'shortlist') {
      this.triggerShortlistEvent.emit(this.selectedTalent);
    } else if (this.type === 'logNote') {
      this.triggerLogNoteEvent.emit(this.selectedTalent);
    } else if (this.type === 'logInterview') {
      this.logInterviewEvent.emit(this.selectedTalent);
      //check whether the talent has to be added to shortlist and skipped to current process step and log the interview results.
    } else if (this.type === 'accountManager') {
      this.submitAccountManagerEvent.emit(this.selectedTalent);
      //check whether the talent has to be added to shortlist and skipped to current process step and log the interview results.
    } else if (this.type === 'clientSubmission') {
      this.clientSubmissionEvent.emit(this.selectedTalent);
      //check whether the talent has to be added to shortlist and skipped to current process step and log the interview results.
    }

  }
}



