import { Applications } from '@allbirds-ui/allbirds-types';
import { IsoToMoment } from '../serializers/moment.serializer';
import { RawInternalSubmission } from './internal-submission.model';
import { Moment } from 'moment';
import { createModel, Mapping, Model } from '../model';
import { Serial } from '../decorators/serial.decorator';
import { Internal } from '../decorators/internal.decorator';
import SubmittedTo = Applications.SubmittedTo;
export import RawInternalSubmission = Applications.InternalSubmission;

class InternalSubmissionMapping extends Mapping<RawInternalSubmission> {
  @Serial(IsoToMoment) submissionDate?: Moment;
  @Serial(IsoToMoment) outcomeDate?: Moment;
  @Serial(IsoToMoment) skippedDate?: Moment;
  @Internal() submittedTo?: SubmittedTo;
  submissionAttachmentURIs?: string[];
}

export interface InternalSubmission extends Model<InternalSubmissionMapping> {}

export const InternalSubmission = createModel<InternalSubmission>(InternalSubmissionMapping);
