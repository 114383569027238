<ng-container *ngIf="application && application.randstad_process && application.randstad_process.interview">
  <ul class="screening-results">
    <li>
      <span class="question">{{'recruiter-interview-manual-questions.interview_channel' | translate}}</span>
      <span class="answer">{{ application.randstad_process.interview.channel | stringclean: ['titlecase', 'sansunderscore'] }}</span>
    </li>
    <li>
      <span class="question">{{'recruiter-interview-manual-questions.minimum_payrate' | translate}}</span>
      <span class="answer">{{ application.randstad_process.interview.minPay.value | currency: 'USD' }}/{{ application.randstad_process.interview.minPay.type | lowercase }}</span>
    </li>
    <li>
      <span class="question">{{'recruiter-interview-manual-questions.what_kind_of' | translate}}</span>
      <span class="answer">{{ application.randstad_process.interview.opportunities }}</span>
    </li>
    <li>
      <span class="question">{{'recruiter-interview-manual-questions.when_is_the' | translate}}</span>
      <span class="answer">{{ application.randstad_process.interview.startDate }}</span>
    </li>
    <li>
      <span class="question">{{'recruiter-interview-manual-questions.willing_to_travel?' | translate}}</span>
      <span class="answer">{{ application.randstad_process.interview.travel }}</span>
    </li>
    <li>
      <span class="question">{{'recruiter-interview-manual-questions.skills' | translate}}</span>
      <span class="answer" *ngIf="!application.randstad_process.interview.skills.length">
        {{'recruiter-interview-manual-questions.not_specified.' | translate}}
      </span>
      <span class="answer" *ngFor="let skill of application.randstad_process.interview.skills">
        - {{ skill.displayName }}
      </span>
    </li>
    <li>
      <span class="question">{{'recruiter-interview-manual-questions.notes' | translate}}</span>
      <span class="answer" [innerHTML]="application.randstad_process.interview.notes"></span>
    </li>
  </ul>
</ng-container>
