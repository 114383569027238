import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SidebarComponent } from '../../components/sidebar/sidebar.component';

@Injectable({
  providedIn: 'root'
})
export class BarChangerService {

  private navBarSource = new BehaviorSubject<any>(null);
  navBarComponent = this.navBarSource.asObservable();

  private sideBarSource = new BehaviorSubject<any>(null);
  sideBarComponent = this.sideBarSource.asObservable();

  private injectorSource = new BehaviorSubject<any>(null);
  injector = this.injectorSource.asObservable();

  constructor() {
    this.sideBarSource.next(SidebarComponent);
  }

  changeNavBar(component: any) {
    // workaround because of a bug
    // https://github.com/angular/angular/issues/20473
    window.setTimeout(
      () => this.navBarSource.next(component),
      0);
  }

  changeSideBar(component: any) {

    // workaround because of a bug
    // https://github.com/angular/angular/issues/20473
    window.setTimeout(
      () => this.sideBarSource.next(component),
      0);
  }

  injectData ( data: any) {
    window.setTimeout(
      () => this.injectorSource.next(data),
      0);
  }
}
