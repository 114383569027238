import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastClass, ToastLink, ToastService } from '../../services/toast';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit, OnDestroy {

  toastSub: Subscription;
  message: string;
  alertClass: string;
  toastPosition: any;
  showIcon = true;
  activeTimeout: any;
  isActive = false;
  isCentered = true;
  iconClass = '';
  actionLinks: ToastLink[] = [];
  image: string;

  constructor(
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.toastSub = this.toastService.toastEvent
      .subscribe(evt => {
        this.closeToast();
        this.configureToast(evt);
        this.triggerToast(evt.duration);
      });
  }

  ngOnDestroy(): void {
    this.toastSub.unsubscribe();
  }

  closeToast(): void {
    this.isActive = false;
    clearTimeout(this.activeTimeout);
  }

  configureToast(config: any): void {
    this.message = config.message;
    this.alertClass = config.cssClass;
    this.iconClass = this.getIcon(config.cssClass);
    this.toastPosition = this.getPosition(config.position);
    this.showIcon = config.showIcon;
    this.actionLinks = config.actionLinks;
    this.image = config.image;
  }

  triggerToast(duration: number): void {
    this.isActive = true;
    this.activeTimeout = window.setTimeout(() => {
      this.isActive = false;
    }, duration);
  }

  getIcon(cssClass: ToastClass): string {
    switch (cssClass) {
      case ToastClass.SUCCESS:
        return 'icon-WL_icon_check';
      case ToastClass.DANGER:
        return 'icon-WL_icon_warning';
      case ToastClass.WARNING:
        return 'icon-WL_icon_alert';
      case ToastClass.SECONDARY:
      default:
        return 'icon-WL_icon_info';
    }
  }

  /* tslint:disable-next-line */
  getPosition(pos: string): any {
    const positions = pos.split('-');
    const verticalPos = positions[0];
    const horizontalPos = positions[1];
    const result = {
      [verticalPos]: (verticalPos === 'top') ? '82px' : '12px'
    };

    if (horizontalPos === 'center') {
      this.isCentered = true;
      result['left'] = '50%';
    } else {
      this.isCentered = false;
      result[horizontalPos] = horizontalPos === 'left' ? '77px' : '32px';
    }
    return result;
  }
}


