export class LobBasedActivityFiltersService {

  constructor() { }

  getFilters(lob: string) {
    if(lob === 'RGS') {
      return [
          {'key':'all', 'displayval':'all'},
          {'key':'phone', 'displayval':'phone'}, 
          {'key':'text','displayval':'text'},
          {'key':'email','displayval':'email'}, 
          {'key':'chatbot','displayval':'chatbot'},
          {'key':'general comment', 'displayval': 'general comment'},
          {'key':'talent - schedule interview', 'displayval':'talent - schedule interview'},
          {'key':'client submission', 'displayval':'client submission'},
          {'key':'client interview', 'displayval':'client interview'}
      ];
    }
    else {
      return [
        {'key':'all', 'displayval':'all'},
        {'key':'phone','displayval':'phone'}, 
        {'key':'email','displayval':'email'}, 
        {'key':'chatbot','displayval':'chatbot'},
        {'key':'dbc notes','displayval':'RITS notes'},
        {'key':'talent care', 'displayval':'talent care'},
        {'key':'candidate lead', 'displayval':'candidate lead'},
        {'key':'recruiter phone screen', 'displayval':'recruiter phone screen'},
        {'key':'modern hire interview', 'displayval':'modern hire interview'},
        {'key':'submitted', 'displayval':'submitted'},
        {'key':'solutions review', 'displayval':'solutions review'},
        {'key':'client submission', 'displayval':'client submission'},
        {'key':'interview scheduled', 'displayval':'interview scheduled'}
      ];
    }
  }
}

export interface ActivityFilterInfo  {
  key: string,
  displayval: string
}
  