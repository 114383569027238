<ng-container *ngIf="talentProfile && talentApplication">
  <div class="tab-section">
    <div class="alert alert-warning talent-status-alert" *ngIf="applicationChanged">
      <img src="assets/icons/alert-yellow.svg"/>
      <p class="rejected-text">{{'talent-process.application_state_changed' | translate}}</p>
    </div>
    <app-recruitment-phases [talentProfile]="talentProfile"></app-recruitment-phases>
  </div>

  <div class="tab-section" *ngIf="!talentApplication?.randstad_process.rejected">
    <onboarding-phase></onboarding-phase>
  </div>

  <div class="tab-section">
    <h3 class="text-primary mb-0">{{'talent-process.activity' | translate}}</h3>
    <p class="mt-2" *ngIf="!talentApplication?.randstad_process && !talentApplication?.randstad_process.lastProcessStep">
      {{'talent-process.no_activity_has' | translate}}<span class="text-primary">{{ talentName }}</span>
      {{'talent-process.application_yet' | translate}}
    </p>
    <app-activity-feed [talentProcess]="talentApplication"
                       [talentProfile]="talentProfile"
                       [job]="job"
                       (activityDetailClick)="openActivityDetailModal($event)">
    </app-activity-feed>
  </div>
</ng-container>
