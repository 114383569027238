import { Injectable, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions, ModalContainerComponent } from 'ngx-bootstrap/modal';

// overriding ngx-bootstrap's hide function to implement `shouldHide` feature
// preferably removed once the library itself supports this feature
(function () {
  const originalHide = ModalContainerComponent.prototype.hide;
  ModalContainerComponent.prototype.hide = function () {
    const { shouldHide } = this.config;
    if (!shouldHide || shouldHide()) {
      originalHide.bind(this)();
    }
  };
})();

export interface ShouldWarnUnsaved {
  isUnsaved(): boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ModalService extends BsModalService {
  show(content: string | TemplateRef<any> | any, config?: ModalOptions<any>): BsModalRef {
    // console.log('content', content);
    // console.log('content type', typeof content);
    //console.log('show called in modal.service!');
    if(!config) {
      config = new ModalOptions();
    }
    config.ignoreBackdropClick = true;
    const shouldHide = () => {
      // if (modalRef.content /*&& modalRef.content.isUnsaved && modalRef.content.isUnsaved()*/) {
      //   return window.confirm('Are you sure you want to discard the changes?');
      // }
      return true;
    };
    // const initialState = { /* values to pass go here */ };
    // const modalRef = super.show(content, <ModalOptions>{ ...(config || {}), shouldHide, initialState });
    const modalRef = super.show(content, <ModalOptions>{ ...(config || {}), shouldHide });
    console.log('modalRef', modalRef);
    return modalRef;
  }
}
