<div *ngIf="!elasticData" class="w-100 mt-5 mx-auto text-center">
  <div class="spinner-border text-primary mx-auto text-center" role="status">
    <span class="sr-only text-center">{{'talent-profile.loading...' | translate}}</span>
  </div>
</div>

<ng-container *ngIf="elasticData">
  <ul class="hz-btn-list filter-btn-list">
    <li *ngFor="let filter of filterTypes">
      <button class="btn btn-round" [class.active]="filter === activeFilter" (click)="toggleFilter(filter)">
        {{ filter }}
      </button>
    </li>
  </ul>

  <div *ngIf="activeFilter === 'all' || activeFilter === 'qualifications'">
    <div class="row no-gutters">
      <div class="col">
        <h2 class="mb-0 color-blue">{{'talent-profile.qualifications' | translate}}</h2>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col">
        <h3 class="mb-0 color-blue">{{'talent-profile.skills' | translate}}</h3>
        <h4 *ngIf="!skillsHighlightObjects || !skillsHighlightObjects.length">{{'talent-profile.not_specified' | translate}}</h4>
        <div *ngIf="skillsHighlightObjects && skillsHighlightObjects.length" class="skills-pills-container">
          <div *ngFor="let record of skillsHighlightObjects; index as i;" class="skills-pills" [class.hide-pill]="i > vissiblePillsLength - 1 && !skillsMoreToggle" [class.highlight-pill]="record.highlight">
            <span *ngIf="record && record.displayName">
              <div *ngIf="i < vissiblePillsLength" >
                <span>{{ record.displayName }}</span>
              </div>
              <div *ngIf="i > vissiblePillsLength - 1 && skillsMoreToggle">
                <span>{{ record.displayName }}</span>
              </div>
            </span>
          </div>
          <div *ngIf="!skillsMoreToggle && (skills.length - vissiblePillsLength) > 0" class="see-more-toggle" (click)="toggleSkills()">
            <h4 class="mb-1 mt-1 description">Load {{ skills.length - vissiblePillsLength }} more skills <i
              class="icon-WL_icon_chevron-down" style="font-size: 10px !important;"></i></h4>
          </div>
          <div *ngIf="skillsMoreToggle && (skills.length - vissiblePillsLength) > 0" class="see-more-toggle" (click)="toggleSkills()">
            <h4 class="mb-1 mt-1 description">{{'talent-profile.see_less_skills' | translate}}<i
              class="icon-WL_icon_chevron-up"
              style="font-size: 10px !important;"></i></h4>
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col">
        <h3 class="mb-0 color-blue">{{'talent-profile.prior_work_experience' | translate}}</h3>
        <h4 *ngIf="!workExperience || !workExperience.length">{{'talent-profile.not_specified' | translate}}</h4>
        <span *ngIf="workExperience && workExperience.length">
          <div *ngFor="let record of workExperience;index as i;">
            <!-- (i > 0)?'top-border':'' - do not show the border for the first element -->
            <h3 [ngClass]="(i > 0)?'top-border':''" class="mb-0 title" [innerHTML]="highlightText(record.jobTitle, keywordsToHighlight)"></h3>
            <h4 class="mb-1 mt-1 description" [innerHTML]="highlightText(record.employerName, keywordsToHighlight)"></h4>
            <h4 class="mb-3 description">
              {{formattedSpanningDates(record)}}
            </h4>
            <h4 class="mb-3 description" *ngIf="record.jobDescription">
              <span *ngIf="record.workExperienceDescriptionToggle" [innerHTML]="highlightText(record.jobDescription, keywordsToHighlight)"></span>
              <div (click)="toggleWorkExperienceDescription(i)" *ngIf="!record.workExperienceDescriptionToggle"
                   class="mb-1 mt-1 description job-description see-more-toggle">{{'talent-profile.see_more' | translate}}
                <i class="icon-WL_icon_chevron-down" style="font-size: 10px !important;"></i></div>
              <div (click)="toggleWorkExperienceDescription(i)" *ngIf="record.workExperienceDescriptionToggle"
                   class="mb-1 mt-1 description job-description see-more-toggle">{{'talent-profile.see_less' | translate}}
                <i class="icon-WL_icon_chevron-down" style="font-size: 10px !important;"></i></div>
            </h4>
          </div>
        </span>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col">
        <h3 class="mb-0 title">{{'talent-profile.industry_experience' | translate}}</h3>
        <h4
          *ngIf="!industryExperience || !industryExperience.length">{{'talent-profile.not_specified' | translate}}</h4>
        <span *ngIf="industryExperience && industryExperience.length">
          <div *ngFor="let record of industryExperience;index as i;">
            <!-- (i > 0)?'top-border':'' - do not show the border for the first element -->
            <h4 class="mb-1 mt-1 description">- {{ record.industry_name }} ({{record.years}})</h4>
          </div>
        </span>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col">
        <h3 class="mb-0 title">{{'talent-profile.work_environment_experience' | translate}}</h3>
        <h4
          *ngIf="!environmentExperience || !environmentExperience.length">{{'talent-profile.not_specified' | translate}}</h4>
        <span *ngIf="environmentExperience && environmentExperience.length">
          <div *ngFor="let record of environmentExperience;index as i;">
            <!-- (i > 0)?'top-border':'' - do not show the border for the first element -->
            <h4 class="mb-1 mt-1 description">- {{ record }} </h4>
          </div>
        </span>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col">
        <h3 class="mb-0 color-blue">{{'talent-profile.education' | translate}}</h3>
        <h4 *ngIf="!educationRecords || !educationRecords.length">{{'talent-profile.not_specified' | translate}}</h4>
        <span *ngIf="educationRecords && educationRecords.length">
          <div *ngFor="let record of educationRecords;index as i;">
            <!-- (i > 0)?'top-border':'' - do not show the border for the first element -->
            <h3 [ngClass]="(i > 0)?'top-border':''" class="mb-0 title">
              {{record?.structuredDegree?.degreeType | stringclean: ['mapeducationprofile']}}</h3>
            <h4 class="mb-1 mt-1 description">{{ record.schoolName }}</h4>
            <h4 class="mb-1 mt-1 description">{{ record.gpa }}</h4>
            <h4 class="mb-3 description">
              {{formattedSpanningDates(record)}}
            </h4>
            <h4 class="mb-3 description" *ngIf="record.jobDescription">
              <span *ngIf="record.workExperienceDescriptionToggle">{{record.jobDescription}}</span>
              <div (click)="toggleWorkExperienceDescription(i)" *ngIf="!record.workExperienceDescriptionToggle"
                   class="mb-1 mt-1 description job-description see-more-toggle">{{'talent-profile.see_more' | translate}}
                <i class="icon-WL_icon_chevron-down" style="font-size: 10px !important;"></i></div>
              <div (click)="toggleWorkExperienceDescription(i)" *ngIf="record.workExperienceDescriptionToggle"
                   class="mb-1 mt-1 description job-description see-more-toggle">{{'talent-profile.see_less' | translate}}
                <i class="icon-WL_icon_chevron-down" style="font-size: 10px !important;"></i></div>
            </h4>
          </div>
        </span>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col">
        <h3 class="mb-0 title">{{'talent-profile.certifications' | translate}}</h3>
        <h4 *ngIf="!certifications || !certifications.length">{{'talent-profile.not_specified' | translate}}</h4>
        <span *ngIf="certifications && certifications.length">
          <div *ngFor="let record of certifications; index as i;">
            <!-- (i > 0)?'top-border':'' - do not show the border for the first element -->
            <h4 class="mb-1 mt-1 description">{{ record.name }}</h4>
          </div>
        </span>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col">
        <h3 class="mb-0 color-blue">{{'talent-profile.legal' | translate}}</h3>
        <h3 class="mb-0 title">{{'talent-profile.work_authorizations' | translate}}</h3>
        <h4 *ngIf="!authorizations?.length">{{'talent-profile.not_specified' | translate}}</h4>
        <h4 *ngIf="authorizations?.length" class="mb-1 mt-1 description">
          {{ authorizations | array: ['sansunderscore', 'titlecase'] }}</h4>
        <h3 class="mb-1 title">{{'talent-profile.security_clearance_level' | translate}}</h3>
        <h4 *ngIf="!clearance">{{'talent-profile.not_specified' | translate}}</h4>
        <h4 *ngIf="clearance" class="mb-1 mt-1 description">{{ clearance.level }}</h4>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col">
        <h3 class="mb-0 color-blue">{{'talent-profile.references' | translate}}</h3>
        <h4 *ngIf="!references || !references.length">{{'talent-profile.not_specified' | translate}}</h4>
        <span *ngIf="references && references.length">
          <div *ngFor="let record of references;index as i;">
            <!-- (i > 0)?'top-border':'' - do not show the border for the first element -->
            <h3 [ngClass]="(i > 0)?'top-border':''" class="mb-0 title">
              {{ record.referencePersonId }}
            </h3>
            <h4 class="mb-1 description">{{ record.notes }}</h4>
          </div>
        </span>
      </div>
    </div>
  </div>

  <div *ngIf="activeFilter === 'all' || activeFilter === 'recruitment info'">
    <hr/>
    <div class="row no-gutters">
      <div class="col">
        <h2 class="mb-0 color-blue">{{'talent-profile.recruitment_info' | translate}}</h2>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col">
        <h3 class="mb-0 color-blue">{{'talent-profile.internal_information' | translate}}</h3>

        <h3 class="mb-0 title">{{'talent-profile.external_profile_id' | translate}}</h3>
        <h4 class="mb-1 mt-1 description">{{ elasticData.name }}</h4>

        <h3 class="mb-0 title" *ngIf=" elasticData.externalSystem !== 'USRT' ">{{'talent-profile.line_of_business' | translate}}</h3>
        <h4 class="mb-1 mt-1 description" *ngIf=" elasticData.externalSystem !== 'USRT' " >{{ elasticData.externalSystem }}</h4>

        <h3 class="mb-0 title">{{'talent-profile.linkedin_profile_url' | translate}}</h3>
        <h4 class="mb-1 mt-1 description">{{ elasticData.linkedinProfileUrl || 'talent-profile.not_specified' | translate }}</h4>

        <h3 class="mb-0 title">{{'talent-profile.immigration_specialist' | translate}}</h3>
        <h4 class="mb-1 mt-1 description">{{ elasticData.immigrationSpecialist || 'talent-profile.not_specified' | translate }}</h4>

        <h3 class="mb-0 title">{{(isRGSTalent ? 'talent-profile.dbc_qualified' : 'talent-profile.rits_qualified') | translate}}</h3>
        <h4 class="mb-1 mt-1 description">{{ elasticData.dbcQualified || 'talent-profile.not_specified' | translate }}</h4>

        <h3 class="mb-0 title">{{'talent-profile.security_clearance' | translate}}</h3>
        <h4 class="mb-1 mt-1 description">{{ elasticData.securityClearances && elasticData.securityClearances[0]?.level ? elasticData.securityClearances[0]?.level : 'talent-profile.not_specified' | translate }}</h4>

        <h3 class="mb-0 title">{{'talent-profile.military_veteran' | translate}}</h3>
        <h4 class="mb-1 mt-1 description">{{ elasticData.militaryVeteran ? 'Yes' : 'No' }}</h4>

        <h3 class="mb-0 title">{{'talent-profile.referred_by' | translate}}</h3>
        <h4 class="mb-1 mt-1 description">{{ elasticData.referral?.firstName }} {{ elasticData.referral?.lastName }}</h4>

        <h3 class="mb-0 title">{{'talent-profile.referral_notes' | translate}}</h3>
        <h4 class="mb-1 mt-1 description">{{ elasticData.referral?.referralNotes ||  'talent-profile.not_specified' | translate }}</h4>

        <h3 *ngIf="elasticData.externalSystem === 'USRT'"
            class="mb-1 title">{{'talent-profile.prime_status' | translate}}</h3>
        <h4
          *ngIf="!primeStatus && elasticData.externalSystem === 'USRT'">{{'talent-profile.not_specified' | translate}}</h4>
        <div *ngIf="primeStatus && elasticData.externalSystem === 'USRT'">
          <h4 class="mb-1 mt-1 description">{{ primeStatus }}</h4>
        </div>

        <h3 *ngIf="elasticData.externalSystem === 'USRT'"
            class="mb-0 title">{{'talent-profile.prime_recruiter' | translate}}</h3>
        <h4
          *ngIf="!primeRecruiterName  && elasticData.externalSystem === 'USRT'">{{'talent-profile.not_specified' | translate}}</h4>
        <div *ngIf="primeRecruiterName  && elasticData.externalSystem === 'USRT'">
          <h4 class="mb-1 mt-1 description">{{ primeRecruiterName }}</h4>
        </div>

        <!-- Primary recruiter -->
        <div *ngIf="elasticData.externalSystem === 'USRT'">
          <h3 class="mb-0 title">{{'talent-profile.primary_recruiter' | translate}}</h3>
          <h4 *ngIf="!primaryRecruiterName">{{'talent-profile.not_specified' | translate}}</h4>
          <div *ngIf="primaryRecruiterName">
            <h4 class="mb-1 mt-1 description">{{ primaryRecruiterName }}</h4>
          </div>
        </div>
        
        <!-- Secondary recruiter -->
        <div *ngIf="elasticData.externalSystem === 'USRT'">
          <h3 class="mb-0 title">{{'talent-profile.secondary_recruiter' | translate}}</h3>
          <h4 *ngIf="!(secondaryRecruiters.length > 0)">{{'talent-profile.not_specified' | translate}}</h4>
          <div *ngIf="secondaryRecruiters.length > 0">
            <div *ngFor="let secondaryRecruiter of secondaryRecruiters">
              <h4 class="mb-1 mt-1 description">{{ secondaryRecruiter.user_name }}</h4>
            </div>
          </div>
        </div>

        <!-- Criminal background check -->
        <h3 class="mb-0 color-blue">{{'talent-profile.criminal_background_check' | translate}}</h3>
        <h4
          *ngIf="!criminalBackgroundChecks || !criminalBackgroundChecks.length">{{'talent-profile.not_specified' | translate}}</h4>
        <span *ngIf="criminalBackgroundChecks && criminalBackgroundChecks.length">
          <div *ngFor="let record of criminalBackgroundChecks; index as i">
            <h3 class="mb-0 title">{{record.screeningType}}</h3>
            <h4 class="mb-1 mt-1 description">{{ record.passOrFail }}</h4>
            <h4 class="mb-1 mt-1 description"> {{ formatUTCDate(record.screenDate) }} </h4>
          </div>
        </span>

        <h3 class="mb-0 color-blue">{{'talent-profile.drug_screen' | translate}}</h3>
        <h4 *ngIf="!drugScreens || !drugScreens.length">{{'talent-profile.not_specified' | translate}}</h4>
        <span *ngIf="drugScreens && drugScreens.length">
          <div *ngFor="let record of drugScreens; index as i">
            <h3 class="mb-0 title">{{record.screeningType}}</h3>
            <h4 class="mb-1 mt-1 description">{{ record.passOrFail }}</h4>
            <h4 class="mb-1 mt-1 description"> {{ formatUTCDate(record.screenDate) }} </h4>
          </div>
        </span>

        <h3 class="mb-0 color-blue">{{'talent-profile.skill_test' | translate}}</h3>
        <h4 *ngIf="!skillTests || !skillTests.length">{{'talent-profile.not_specified' | translate}}</h4>
        <span *ngIf="skillTests && skillTests.length">
          <div *ngFor="let record of skillTests; index as i">
            <h3 class="mb-0 title">{{record.testDescription}}</h3>
            <h4 class="mb-1 mt-1 description" *ngIf="record.score || record.score === 0">{{ record.score }}</h4>
            <h4 class="mb-1 mt-1 description" *ngIf="record.dateCompleted"> {{ formatUTCDate(record.dateCompleted) }}
            </h4>
          </div>
        </span>
      </div>
    </div>
  </div>

  <div *ngIf="activeFilter === 'all' || activeFilter === 'preferences'">
    <hr/>
    <div class="row no-gutters">
      <div class="col">
        <h2 class="mb-0 color-blue">{{'talent-profile.preferences' | translate}}</h2>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col">
        <h3 class="mb-0 title">{{'talent-profile.employment_type' | translate}}</h3>
        <h4 *ngIf="!employmentTypes || !employmentTypes.length">{{'talent-profile.not_specified' | translate}}</h4>
        <h4 *ngIf="employmentTypes && employmentTypes.length" class="mb-1 mt-1 description">
          {{ employmentTypes | array: ['sansunderscore', 'titlecase'] }}</h4>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col">
        <h3 class="mb-0 title">{{'talent-profile.shifts' | translate}}</h3>
        <h4 *ngIf="!shifts || !shifts.length">{{'talent-profile.not_specified' | translate}}</h4>
        <h4 *ngIf="shifts && shifts.length" class="mb-1 mt-0 description">
          {{shifts | array: ['sansunderscore', 'titlecase']}}</h4>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col">
        <h3 class="mb-0 title">{{'talent-profile.compensation' | translate}}</h3>
        <h4
          *ngIf="!compensation || (!compensation.minimum && !compensation.desired && !compensation.preferred)">{{'talent-profile.not_specified' | translate}}</h4>
        <span *ngIf="compensation">
          <h4 *ngIf="compensation.minimum" class="mb-1 mt-0 description">- Minimum: {{compensation.minimum}}</h4>
          <h4 *ngIf="compensation.desired" class="mb-1 mt-0 description">- Desired: {{compensation.desired}}</h4>
          <h4 *ngIf="compensation.preferred" class="mb-1 mt-0 description">
            - Preferred: {{compensation.preferred | currency: 'USD'}}<span
            *ngIf="compensation.unit">/{{compensation.unit}}</span>
          </h4>
        </span>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col">
        <h3 class="mb-0 title">{{'talent-profile.willing_to_travel' | translate}}</h3>
        <h4 *ngIf="!jobInterestsContains('PERCENT_TRAVEL')">{{'talent-profile.not_specified' | translate}}</h4>
        <span *ngIf="jobInterestsContains('PERCENT_TRAVEL')">
          <div *ngFor="let record of jobInterests; index as i;">
            <h4 class="mb-1 mt-0 description"
                *ngIf="record.jobRequirement && record.jobRequirement.jobRequirementType === 'PERCENT_TRAVEL'">
              {{record.jobRequirement.context}}
            </h4>
          </div>
        </span>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col">
        <h3 class="mb-0 title">{{'talent-profile.commute' | translate}}</h3>
        <h4
          *ngIf="!commute || (!commute.max && !commute.preferred && !commute.time)">{{'talent-profile.not_specified' | translate}}</h4>
        <span *ngIf="commute">
          <h4 *ngIf="commute.max" class="mb-1 mt-0 description">- Maximum: {{commute.max}} miles</h4>
          <h4 *ngIf="commute.preferred" class="mb-1 mt-0 description">- Preferred: {{commute.preferred}} miles</h4>
          <h4 *ngIf="commute.time" class="mb-1 mt-0 description">- Preferred: {{commute.time}}</h4>
        </span>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col">
        <h3 class="mb-0 title">{{'talent-profile.willing_to_relocate' | translate}}</h3>
        <h4 *ngIf="canRelocate === ''">{{'talent-profile.not_specified' | translate}}</h4>
        <h4 *ngIf="canRelocate !== ''" class="mb-1 mt-0 description">{{canRelocate ? 'Yes' : 'No'}}</h4>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col">
        <h3 class="mb-0 title">{{'talent-profile.preferred_work_environment' | translate}}</h3>
        <h4
          *ngIf="!jobInterestsContains('NO_CLIMATE_CONTROL', 'SMOKE_FREE', 'STEELTOE_SHOES')">{{'talent-profile.not_specified' | translate}}</h4>
        <span *ngIf="jobInterestsContains('NO_CLIMATE_CONTROL', 'SMOKE_FREE', 'STEELTOE_SHOES')">
          <div *ngFor="let record of jobInterests; index as i;">
            <h4 class="mb-1 mt-0 description"
                *ngIf="record.jobRequirement && record.jobRequirement.jobRequirementType === 'NO_CLIMATE_CONTROL' && record.isInterested">
              {{'talent-profile.no_climate_control' | translate}}</h4>
            <h4 class="mb-1 mt-0 description"
                *ngIf="record.jobRequirement && record.jobRequirement.jobRequirementType === 'SMOKE_FREE' && record.isInterested">
              {{'talent-profile.smoke_free' | translate}}</h4>
            <h4 class="mb-1 mt-0 description"
                *ngIf="record.jobRequirement && record.jobRequirement.jobRequirementType === 'STEELTOE_SHOES' && record.isInterested">
              {{'talent-profile.steel_to_shoes' | translate}}</h4>
          </div>
        </span>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col">
        <h3 class="mb-0 title">{{'talent-profile.physical_preferences' | translate}}</h3>
        <h4
          *ngIf="!jobInterestsContains('PHYSICAL_LIFT', 'PHYSICAL_STAND')">{{'talent-profile.not_specified' | translate}}</h4>
        <span *ngIf="jobInterestsContains('PHYSICAL_LIFT', 'PHYSICAL_STAND')">
          <div *ngFor="let record of jobInterests; index as i;">
            <h4 class="mb-1 mt-0 description"
                *ngIf="record.jobRequirement && record.jobRequirement.jobRequirementType === 'PHYSICAL_LIFT' && record.isInterested">
              {{'talent-profile.open_to_lift' | translate}}</h4>
            <h4 class="mb-1 mt-0 description"
                *ngIf="record.jobRequirement && record.jobRequirement.jobRequirementType === 'PHYSICAL_STAND' && record.isInterested">
              {{'talent-profile.open_to_stand' | translate}}</h4>
          </div>
        </span>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col">

        <h3 class="mb-0 title">{{'talent-profile.other_working_hours' | translate}}</h3>
        <h4
          *ngIf="!jobInterestsContains('WEEKEND_WORK', 'OVERTIME_WORK')">{{'talent-profile.not_specified' | translate}}</h4>
        <span *ngIf="jobInterestsContains('WEEKEND_WORK', 'OVERTIME_WORK')">
          <div *ngFor="let record of jobInterests; index as i;">
            <h4 class="mb-1 mt-0 description"
                *ngIf="record.jobRequirement && record.jobRequirement.jobRequirementType === 'WEEKEND_WORK' && record.isInterested">
              {{'talent-profile.open_to_weekend' | translate}}</h4>
            <h4 class="mb-1 mt-0 description"
                *ngIf="record.jobRequirement && record.jobRequirement.jobRequirementType === 'OVERTIME_WORK' && record.isInterested">
              {{'talent-profile.open_for_overtime' | translate}}</h4>
          </div>
        </span>

      </div>
    </div>

    <div class="row no-gutters">
      <div class="col">
        <h3 class="mb-0 title">{{'talent-profile.preferred_contact_method' | translate}}</h3>
        <h4 class="mb-1 mt-0 description">{{ preferredContactType }}</h4>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col">
        <h3 class="mb-0 title">{{'talent-profile.preferred_job_titles' | translate}}</h3>
        <h4 *ngIf="!jobTitlePreferences || !jobTitlePreferences.length">{{'talent-profile.not_specified' | translate}}</h4>
        <h4 *ngIf="jobTitlePreferences && jobTitlePreferences.length">{{ jobTitlePreferences }}</h4>
      </div>
    </div>

    <div *ngIf="talentLob === 'CR'" class="row no-gutters">
      <div class="col">
        <h3 class="mb-0 title">{{'talent-profile.preferred_pronoun' | translate}}</h3>
        <h4 *ngIf="!preferredPronoun || !preferredPronoun.length">{{'talent-profile.not_specified' | translate}}</h4>
        <h4 *ngIf="preferredPronoun && preferredPronoun.length">{{ preferredPronoun }}</h4>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col">
        <h3 class="mb-0 title">{{'talent-profile.key_motivators' | translate}}</h3>
        <h4 *ngIf="!motivators">{{'talent-profile.not_specified' | translate}}</h4>
        <div *ngIf="motivators">
          <h4 class="mb-1 mt-0 description">{{ motivators }}</h4>
        </div>
      </div>
    </div>

    <div class="row no-gutters mb-2">
      <div class="col">
        <h3 class="mb-0 title">{{'talent-profile.availability' | translate}}</h3>
        <h4 *ngIf="!availability || !availability.length">{{'talent-profile.not_specified' | translate}}</h4>
        <h4 *ngIf="availability && availability.length">{{ availability }}</h4>
      </div>
    </div>
  </div>
</ng-container>
