import { Moment } from 'moment';
import { Prescreening } from '../external/process/prescreening.model';
import { Offer } from '../external/process/offer.model';

export interface Activity {
  type: ActivityFeedTypes;
  date?: Moment;
  author?: ActivityAuthor | null;
  details?: ScreeningActivity | SubmissionActivity | InterviewActivity | Prescreening | OfferActivity | null;
  notes?: string;
  activityType?: string; // certain DE records have this field populated.
  moEmplId?: string;
  moOrderId?: string;
}

export interface ActivityAuthor {
  name: string;
  email?: string;
  title?: string;
  backOfficeId?: string;
  frontOfficeId?: string;
  avatar?: string;
}

export interface ScreeningActivity {
  screeningType: 'manual' | 'chatbot';
  isUnavailable?: boolean;
  isUnavailableFor?: any;
}

export interface SubmissionActivity {
  recipient: ActivityAuthor;
}

export interface InterviewActivity {
  interviewType: string;
}

export interface OfferActivity extends Offer {
  rejectReason?: string;
}

export enum ActivityFeedTypes {
  Apply,
  Shortlist,
  SkippedStep,
  Rejection,
  CEScreeningSent,
  CEScreeningCompleted,
  ManualScreeningCompleted,
  CEInterviewScheduleSent, // TODO: get verbiage for this case from UX team.
  CEInterviewScheduleConfirmed,
  CEInterviewCancelled,
  RecruiterInterviewCancelled,
  CEInterviewRescheduled, // TODO: Analyze how to determine regular interview scheduled vs. a rescheduled interview.
  RecruiterInterviewPerformed,
  RecruiterAMSubmission,
  UpdateSubmission,
  RecruiterAMSubmissionRewriteRequested,
  ClientSubmissionSent,
  CandidateMarkedAsBackup,
  ClientInterviewScheduled,
  ClientInterviewRescheduled,
  ClientInterviewFeedback,
  OfferSent,
  OfferRejected,
  OfferAccepted,
  PhaseSkipped,
  CandidateRejected,
  CandidateReinstated,
  JobUpdated,
  ProfileUpdated
}
