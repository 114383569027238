import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-feature-unavailable-modal',
  templateUrl: './feature-unavailable-modal.component.html',
  styleUrls: ['./feature-unavailable-modal.component.scss']
})
export class FeatureUnavailableModalComponent implements OnInit {

  reason: string;

  constructor(
    public _bsModalRef: BsModalRef
  ) { }

  ngOnInit() { }

}
