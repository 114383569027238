import {AllbirdsAppointment} from './appointment.model';
import { createModel, Mapping, Model } from './model';
import { IsoToMoment } from './serializers/moment.serializer';
import { Lists } from '@allbirds-ui/allbirds-types';
import { Moment } from 'moment';
import { Serial } from './decorators/serial.decorator';
export import RawListTalent = Lists.Talent;
import { Internal } from './decorators/internal.decorator';
import { TalentListProfileInfo } from './process/talent-list-profile.model';

class ListTalentMapping extends Mapping<RawListTalent> {
  @Serial(IsoToMoment) date_added: Moment;
  @Serial(IsoToMoment) date_updated: Moment;
  @Serial(AllbirdsAppointment) appointment: AllbirdsAppointment;
  @Internal() @Serial(TalentListProfileInfo) profileInfo?: TalentListProfileInfo;
}

export interface ListTalent extends Model<ListTalentMapping> {}
export const ListTalent = createModel<ListTalent>(ListTalentMapping);
