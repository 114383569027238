<div class="modal-header" style="justify-content: flex-end;">
    <button type="button" class="close-btn" aria-label="Close" (click)="_bsModalRef.hide()">
      <i class="icon-WL_icon_close"></i>
    </button>
</div>
  
<div class="modal-body">
    <h2>{{'change-owner-modal.transfer_ownership' | translate}}</h2>
    <table class="modal-body-form">
        <tr>
            <td><h4>{{'change-owner-modal.job_title' | translate}}</h4></td>
            <td><h4 class="gray-text ml-3">{{title}}</h4></td>
        </tr>
        <tr>
            <td><h4>{{'change-owner-modal.company' | translate}}</h4></td>
            <td><h4 class="gray-text ml-3">{{customer_name}} <span class="priority">P{{customer_priority}}</span> </h4></td>
        </tr>
        <tr>
            <td><h4>{{'change-owner-modal.job_id' | translate}}</h4></td>
            <td><h4 class="gray-text ml-3">{{front_office_id}}</h4></td>
        </tr>
        <tr>
            <td><h4>{{'change-owner-modal.owner' | translate}}</h4></td>
            <td><h4 class="gray-text ml-3">{{owner_name}}</h4></td>
        </tr>
        <tr><td colspan="2"><h4>{{'change-owner-modal.who_do_you' | translate}}</h4></td></tr>
        <tr>
            <td colspan="2">
                <input [(ngModel)]="searchTerm"
                    type="text" 
                    placeholder="{{'change-owner-modal.search_by_first' | translate}}" 
                    class="form-control" />
            </td>
        </tr>
    </table>
    <app-user-lookup [searchTerm]="searchTerm"
        (userSelected)="setOwner($event)"
    ></app-user-lookup>
</div>

<div id="assign-owner-footer" class="modal-footer">
    <button (click)="_bsModalRef.hide()" class="btn rds--button--primary">{{'change-owner-modal.cancel' | translate}}</button>
    <button *ngIf="!saving" (click)="changeOwner(selectedUser, _bsModalRef)" class="btn rds--button--primary">{{'change-owner-modal.confirm' | translate}}</button>
    <button *ngIf="saving" class="btn rds--button--primary">{{'change-owner-modal.confirm_(saving)' | translate}}</button>
</div>