<!-- Resume modal -->
<div class="modal-header">
    <h2 *ngIf="paginateService.currentTalent" class="mb-none">
      {{paginateService.currentTalent.randstad_process ?
        paginateService.currentTalent.randstad_process.candidateFullName :
            (paginateService.currentTalent.personNames[0].preferredName ||
            paginateService.currentTalent.personNames[0]?.structuredName?.givenName + ' ' + paginateService.currentTalent.personNames[0]?.structuredName?.familyName)}}
    </h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body resume-body">
    <!-- Loading circle. -->
    <div *ngIf="!errors && !pdf" class="h-100 w-100 mx-auto text-center">
      <vertical-align>
        <div class="spinner-border text-primary mx-auto text-center" role="status">
          <span class="sr-only text-center">{{'talent-resume-modal.loading' | translate}}</span>
        </div>
      </vertical-align>
    </div>

    <!-- Error box. -->
    <div class="resume-error" *ngIf="errors">
      <vertical-align>
        <img src="assets/images/resume.png"  />
        <!-- Shown when the server returns a 500 error. -->
        <p *ngIf="errors.fatalError">{{'talent-resume-modal.currently_we_cant' | translate}}</p>
        <!-- Shown when the server returns a 404 error. -->
        <p *ngIf="errors.notFound">{{'talent-resume-modal.no_resume_on' | translate}}</p>
        <!-- Shown for any other errors. -->
        <p *ngIf="errors.generic">{{'talent-resume-modal.currently_we_cant' | translate}}</p>
      </vertical-align>
    </div>

    <!-- .PDF reader -->
    <div class="pdf-container-modal">
      <ng2-pdfjs-viewer #pdfViewerModal viewerId="pdfViewerModal" (onDocumentLoad)="pdfLoaded($event)"></ng2-pdfjs-viewer>
    </div>
  </div>

  <div class="modal-footer">
  </div>
