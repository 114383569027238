import {Component, OnInit} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ShouldWarnUnsaved } from '../../services/modal/modal.service';
import { RejectEvent } from '../talent-detail-pane/talent-detail-pane.component';
import {FormBuilder, FormGroup} from '@angular/forms';

/**
 * The reasons why a candidate may not be a fit for a job. These keys are
 * stored in the rejectReason field of the application
 */
export enum NotAFitReason {
  UNQUALIFIED_SKILLS = 'Unqualified on skills',
  UNQUALIFIED_EXPERIENCE = 'Unqualified on experience',
  UNQUALIFIED_EDUCATION = 'Unqualified on education',
  OVERQUALIFIED = 'Overqualified',
  OUTDATED_RESUME_OR_PROFILE = 'Outdated resume/profile',
  EXPERIENCE_RECENCY = 'Recency of experience',
  IRRELEVANT_JOB_TITLE = 'Most recent job title not relevant',
  NOT_AVAILABLE = 'Availability does not align',
  INCOMPATIBLE_PREFERENCES = 'No match with candidate preferences',
  NEGATIVE_HISTORY = 'Negative assignment history',
  FAKE_PROFILE = 'This looks like a fake profile',
  OTHER = 'Other'
}

export type Reason = keyof typeof NotAFitReason;

@Component({
  selector: 'app-not-a-fit-modal',
  templateUrl: './not-a-fit-modal.component.html',
  styleUrls: ['./not-a-fit-modal.component.scss']
})
export class NotAFitModalComponent implements ShouldWarnUnsaved, OnInit {
  NotAFitReason = NotAFitReason;

  /**
   * The talent profile
   */
  talentProfile: any;
  /**
   * The function to call for the rejection. This should be a function defined
   * in this component's parent
   */
  emitReject: (e: RejectEvent) => void;
  /**
   * The reasons to display as selectable items
   */
  reasons = <Reason[]>Object.keys(NotAFitReason);
  /**
   * The selected reasons
   */
  selectedReasons: Reason[] = [];
  /**
   * If "OTHER" is selected, a text field will be displayed. This property
   * is for the value typed in the text field
   */
  get reasonNote() { return this.hmForm.getRawValue().reasonNote; }
  set reasonNote(value) { this.hmForm.controls.reasonNote.setValue(value); }
  /**
   * True if the reasonNote field should be hidden
   */
  hideOtherNoteField = true;

  hmForm: FormGroup;

  constructor(
    public _bsModalRef: BsModalRef,
    private _fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.hmForm = this._fb.group({
      reasonNote: [['']],
    });
  }

  reset() {
    this.selectedReasons = [];
    this.reasonNote = '';
  }

  /**
   * The submit event handler. Will call the function passed along from the parent component
   * then hide the modal
   */
  submit(evt: MouseEvent) {
    const values = this.hmForm.getRawValue();
    this.emitReject({ profile: this.talentProfile, reason: this.selectedReasons, note: values.reasonNote });
    this.reset();
    this._bsModalRef.hide();
  }

  /**
   * Called from the template to determine if a reason should be selected
   */
  isChecked(reason: Reason) {
    if (this.selectedReasons && this.selectedReasons.length) {
      return this.selectedReasons.includes(reason);
    }
    return false;
  }

  /**
   * Called from the template to add/remove items from selectedReasons
   */
  updateSelectedReasons(evt: Event) {
    const target = <HTMLInputElement>evt.target;
    if (target && target.checked) {
      this.selectedReasons.push(<Reason>target.value);
    } else {
      this.selectedReasons = this.selectedReasons.filter(reason => reason !== target.value);
    }
    this.hideOtherNoteField = this.selectedReasons.indexOf('OTHER') === -1;
  }

  isUnsaved(): boolean {
    return this.selectedReasons.length > 0 || this.reasonNote.length > 0;
  }
}
