import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {Subscription} from 'rxjs';
import {catchError} from 'rxjs/operators';
import { phrases } from '../../../../assets/files/restricted_phrases';
import { CKEditor4 } from 'ckeditor4-angular';

import './custom-build-ckeditor4/ckeditor.js';
import {ResponseBody} from '../../models/api/application-response.interface';
import {User} from '../../models/external/user.model';
import {DistributionList} from '../../models/internal/distribution-list.model';
import {ApiService} from '../../services/api/api.service';
import {AuthService} from '../../services/auth/auth.service';
import {SuggestService} from '../../services/suggest/suggest.service';
import SuggestsResponse = ResponseBody.SuggestsResponse;

export interface SidebarEvent {
  found: any;
  returned: any;
}

export interface MentionDefinition {
  displayName: string;
  id: string|number;
  name: string;
  email: string;
  avatar: string;
  initials: string;
  outputStyle: string;
  isDist: boolean;
}

/**
 * to add plugins to the ckeditor:
 * Visit https://ckeditor.com/cke4/builder
 * Upload the ./custom-build-ckeditor4/build-config.js file
 * Add the required plugin
 * Download the new build
 * Replace ./custom-build-ckeditor4 directory with the content of the downloaded zip file
 */
@Component({
  selector: 'app-ckeditor',
  templateUrl: './ckeditor.component.html',
  styleUrls: ['./ckeditor.component.scss']
})
export class CkeditorComponent implements OnInit, OnDestroy {
  @ViewChild('editor') editorComponent: CKEditor4.Editor;
  @Input() parentForm: FormGroup;
  @Input() formConName: string; // Changed the type because it made this impossible to test
  // @Input() formConName: FormControlName;
  @Input() height: number;
  @Input() maxChar: number;
  @Input() restrictedPhrases: boolean;
  @Input() enableMentions: boolean = false;
  @Input() enableImageSupport: boolean = false;
  @Input() additionalToolbarIcons: string[] = [];
  @Input() config: CKEditor4.Config;
  @Input() cssClass: string;
  @Input() startupHtml: string;
  @Output() setSidebar = new EventEmitter<SidebarEvent>();
  control: FormControl;
  phraseWorker: Worker;
  found: any;
  returned: any;
  files: File[] = [];
  subscribers: Subscription = new Subscription();
  private _currentEditorDialog: any;

  public ckConfig: CKEditor4.Config;

  // Allows parent components to know when the editor is ready.
  @Output() onEditorReady: EventEmitter<CKEditor4.Editor> = new EventEmitter<CKEditor4.Editor>();

  constructor(
    private _api: ApiService,
    private _auth: AuthService,
    private _suggest: SuggestService
  ) {
    this.phraseWorker = new Worker('../../../assets/workers/phraseWorker.js');
  }

  ngOnInit() {
    this.control = this.parentForm.controls[String(this.formConName)] as FormControl;
    this.ckConfig = this.getConfig();

    if (this.config) {
      this.overrideDefaultConfig(this.config);
    }
  }

  ngOnDestroy() {
    this.phraseWorker.terminate();
    this.subscribers.unsubscribe();
  }

  getConfig() {
    const ckConfig: CKEditor4.Config = {
      toolbar: [['Format', '-', 'Bold', 'BulletedList', 'Indent', 'Outdent', '-', 'Undo', 'Redo', '-', ...this.additionalToolbarIcons]],
      format_tags: 'p',
      resize_enabled: true,
      tabSpaces: 0,
      bodyId: 'ckeditor' + this.formConName,
      fillEmptyBlocks: false,
      extraPlugins: 'wordcount, textmatch, textwatcher',
      allowedContent: 'ul; b; li; p; span(*)[data-id,title]; img[!src,alt,width,height]; a[!href];',
      coreStyles_bold: { element: 'b', overrides: 'strong' },
      wordcount: {
        showWordCount: false,
        showParagraphs: false,
        countHTML: false,
        countSpacesAsChars: true,
        showCharCount: true,
        maxCharCount: this.maxChar
      },
      font_names: '"Graphik", sans-serif',
      contentsCss: ['body {font-size: 16px; font-family: Graphik, sans-serif; font-weight: 400; line-height: 1.5} .blocked { background: red; color: white!important;} .warning { background: orange; color: white!important;}'],
      height: this.height,
      language: 'en' // for localization
    };

    if (this.enableImageSupport) {
      ckConfig.filebrowserImageUploadUrl = '/';
      ckConfig.filebrowserUploadMethod = 'xhr';
    }
    if (this.enableMentions) {
      // styles for itemTemplate are in styles.scss because it is moved outside the app
      // styles for outputTemplate are inline since it is placed inside the editor which is in an iFrame
      ckConfig.mentions = [{
        feed: this.getMentionUsersFeed.bind(this),
        minChars: 2,
        marker: '@',
        itemTemplate: `<li data-id="{id}" class="ckeditor-mentions-autocomplete-item"><img alt="{initials}" src="{avatar}" />{displayName}</li>`,
        outputTemplate: '<span data-id="{email}" class="ckeditor-mentions-output-item" style="{outputStyle}" title="{displayName}">@{displayName}</span>&nbsp;', // need the &nbsp at the end so additional content isn't placed inside the span
        pattern: /(@{1}[\w.@]*)/,
        caseSensitive: false
      }];
    }
    return ckConfig;
  }

  overrideDefaultConfig(config: CKEditor4.Config): void {
    this.ckConfig = Object.assign({}, this.ckConfig, config);
    console.log('Default configuration was overriden!', this.ckConfig);
  }

  onReady($event: CKEditor4.Editor) {
    this.worker(); // worker for notifying the sidebar component
    const CKEDITOR: CKEditor4.Editor = (<any>window).CKEDITOR; // CKEDITOR library in order to build textMatch plugin
    function matchCallback(text: any, offset: any) { // callback for textMatch. After change in CKEDITOR it will call this function
      const editor = this.editorComponent.instance;
      const testCurrentHtml = editor.document.getBody().getHtml();
      const parser = new DOMParser();
      const parsedHtml = parser.parseFromString(testCurrentHtml, 'text/html');
      unwrap(parsedHtml);
      const testChangedHtml = this.processRestrictedPhrases(parsedHtml.body.innerHTML);

      if (testCurrentHtml !== testChangedHtml) {
        const editor = this.editorComponent.instance;
        const bookmark = editor.getSelection().createBookmarks(true);
        let currentHtml = editor.document.getBody().getHtml();
        const parser = new DOMParser();
        const parsedHtml = parser.parseFromString(currentHtml, 'text/html');
        unwrap(parsedHtml);
        currentHtml = parsedHtml.body.innerHTML;
        const changedHtml = this.processRestrictedPhrases(currentHtml);
        editor.document.getBody().setHtml(changedHtml);
        editor.getSelection().selectBookmarks(bookmark);
      }
      return false;
    }

    function unwrap(parsedHtml: any) {
      let wrapper;
      while (parsedHtml.querySelector('.blocked') || parsedHtml.querySelector('.warning')) {
        wrapper = parsedHtml.querySelector('.blocked') || parsedHtml.querySelector('.warning');
        // place childNodes in document fragment
        const docFrag = document.createDocumentFragment();
        while (wrapper.firstChild) {
          const child = wrapper.removeChild(wrapper.firstChild);
          docFrag.appendChild(child);
        }

        // replace wrapper with document fragment
        wrapper.parentNode.replaceChild(docFrag, wrapper);
      }
    }

    function textTestCallback(range: any) {
      // You do not want to check a non-empty selection.
      if (!range.collapsed) {
        return null;
      }

      // Use the text match plugin which does the tricky job of doing
      // a text search in the DOM. The matchCallback function should return
      // a matching fragment of the text.
      return CKEDITOR.plugins.textMatch.match(range, matchCallback.bind(this));
    }

    if (CKEDITOR && this.restrictedPhrases) { // if CKEDITOR exist then start listening matches
      const textWatcher = new CKEDITOR.plugins.textWatcher(this.editorComponent.instance, textTestCallback.bind(this));
      textWatcher.attach();

      // Handle text matching.
      textWatcher.on('matched', function (evt: any) {

      });
    }
    if (this.enableImageSupport) {
      this.addImageDialogEvents();
    }

    if (this.startupHtml) {
      const editor = this.editorComponent.instance;
      editor.insertHtml('<br/>');
      // // Create and insert the startup HTML.
      const element = new CKEDITOR.dom.element.createFromHtml(this.startupHtml);
      element.unselectable();
      editor.insertElement(element);
    }

    this.onEditorReady.emit(this.editorComponent);
  }

  /**
   * Sets up the worker listener to update the sidebar
   */
  worker() {
    this.phraseWorker.onmessage = (e) => {
      if (e.data.length) {
        this.found = e.data[1];
        this.returned = this.found.slice(0, 1);
        // if we need to set values on sidebar
        if (this.setSidebar) {
          this.setSidebar.emit({ found: this.found, returned: this.returned });
        }
      }
    };
  }

  preg_quote(str: string) {
    return (str).toString().replace(/([\\\.\+\*\?\[\^\]\$\(\)\{\}\=\!\<\>\|\:])/g, '\\$1');
  }

  /**
   * Build html based on the passed in phrase.
   * @param phrase {string[]} The phrase value array for p
   * @param p {string} The phrase key (what was typed and found in the editor)
   * @param spanId {string} optional - the id to assign to the span
   */
  html(phrase: string[], p: string, spanId?: string): string {

    if (phrase[0] === 'Block') {
      return '<span class="blocked">' + p.toLowerCase() + '</span>';
    } else {
      return '<span class="warning">' + p.toLowerCase() + '</span>';
    }

  }

  /**
   * If isForSearch is false, update the control's value, check for disallowed phrases, wrap them and then
   * return the relevant html.
   * @param html {string}
   * @returns {string}
   */
  processRestrictedPhrases(html: string): string {
    // Send off change for sidebar
    this.phraseWorker.postMessage([html, phrases]);
    // set highlighting
    let s = html;
    const p = Object.keys(phrases);
    for (let i = 0; i < p.length; i++) {
      s = s.replace(new RegExp('\\b' + this.preg_quote(p[i].toLowerCase()) + '\\b', 'gi'), this.html(phrases[p[i]], p[i]));
    }
    return s;
  }

  touched() {
    this.control.markAsTouched();
  }

  onDataChange($event: any) {
    // const changedHtml = this.processRestrictedPhrases($event.editor.getData())
    // $event.editor.setData(changedHtml);
    // console.log(changedHtml);
  }

  /**
   * Starts the process of fetching the users, user avatars and distribution lists. First call in the suggestion chain
   * @param options {query: string, marker: string} passed by ckeditor
   * @param callback {Function} passed by ckeditor call with what should be in the suggestion list
   */
  getMentionUsersFeed(options: { query: string, marker: string }, callback: any) {
    const query = options.query;
    this.subscribers.add(this._api.searchUsers(query, true)
      .pipe(catchError((err) => {
        callback([]);
        return [];
      }))
      .subscribe(async (rawUsers) => {
        try {
          const users = rawUsers.filter((user: User) => user.Source.abIncludesLob(this._auth.user.Source) && !/(?:\S)+(?:[*_]+)$/.test(user.EmailAddr)).filter(Boolean);
          const boIds = users.map((user: User) => user.BackOfficeID).filter(Boolean);
          let results: MentionDefinition[] = [];
          if (boIds.length) {
            results = results.concat(await this.getMentionUsersAvatars(boIds));
          }
          if (query.length) {
            results = results.concat(await this.getMentionDistributionList(query));
          }
          if (results.length) {
            results = results.sort((a, b) => a.displayName < b.displayName ? -1 : a.displayName > b.displayName ? 1 : 0);
          }
          callback(results);
        } catch (err) {
          callback([]);
        }
    }));
  }

  /**
   * Get the avatars for the users found. 2nd call in the suggestion chain
   * @param boIds {string[]}
   */
  async getMentionUsersAvatars(boIds: string[]): Promise<MentionDefinition[]> {

      const fullUsers = await this._api.getUsersByIds(boIds, false);
      const suggestions = fullUsers.map((user: User) => {
        return {
          displayName: user.FullName,
          id: user._id,
          name: user.FirstName,
          email: user.EmailAddr,
          avatar: user.Preferences ? user.Preferences.Avatar : user.avatar ? user.avatar : '',
          initials: `${user.FirstName.charAt(0)}${user.LastName.charAt(0)}`,
          outputStyle: `color: #0f74a8;`,
          isDist: false
        };
      });
      return suggestions;

  }

  /**
   * Get the distribution lists. 3rd and last call in the suggestion chain
   * @param searchTerm {string}
   */
  getMentionDistributionList(searchTerm: string): Promise<MentionDefinition[]> {
    return new Promise((resolve, reject) => {
      this.subscribers.add(this._suggest.emailDistributionList(searchTerm)
        .pipe(catchError((err) => {
          reject(err);
          return [];
        }))
        .subscribe((resp: SuggestsResponse<DistributionList>) => {
          const suggests = resp.suggests;
          const distSuggests = suggests.map((suggest) => {
            const beforeAt = suggest.email.split('@')[0];
            const beforeAtArr = beforeAt.split('-');
            let initials = beforeAt.charAt(0).toUpperCase();
            if (beforeAtArr && beforeAtArr.length > 1) {
              initials = `${beforeAtArr[0].charAt(0).toUpperCase()}${beforeAtArr[1].charAt(0).toUpperCase()}`;
            }
            const name = suggest.email.substring(0, suggest.email.indexOf('@'));
            return {
              displayName: name,
              id: Math.floor(Math.random() * Math.floor(new Date().getTime())),
              name: suggest.email,
              email: suggest.email,
              avatar: '',
              initials: initials,
              outputStyle: 'color: #0f74a8;',
              isDist: true
            };
          });
          resolve(distSuggests);
        }));
    });
  }

  /**
   * Setup various image dialog event listeners
   * @event CKEditor4#dialogShow
   * @event CKEditor4#fileUploadRequest
   * @event CKEditor4#paste
   */
  addImageDialogEvents() {
    let dialog: any;
    let dialogDef: any;
    /**
     * Fired when the dialog is shown. Hide the link tab. If the urlField has a value
     * select the info tab. If it doesn't have a value select the Upload tab.
     * @event CKEditor4#dialogShow
     */
    this.editorComponent.instance.on('dialogShow', (evt: CKEditor4.EventInfo) => {
      dialog = evt.data;
      if (dialog._.name === 'image') {
        dialogDef = dialog.definition;
        this._currentEditorDialog = dialogDef;
        dialog.hidePage('Link');
        const urlField = dialog.getContentElement('info', 'txtUrl');
        if (!urlField.getValue()) {
          dialog.selectPage('Upload');
        } else {
          dialog.selectPage('info');
        }
      }
    });
    /**
     * Fired when the "Send to server" button is clicked". Cancel the xhr request attempted by ckeditor
     * and do our own thing
     * @event CKEditor4#fileUploadRequest
     */
    this.editorComponent.instance.on('fileUploadRequest', (evt: CKEditor4.EventInfo) => {
      // console.log('fileUploadRequest, evt=', evt);
      // console.log('fileUploadRequest, dialog=', dialog);
      evt.cancel();
      const file: File = evt.data.requestData.upload.file;
      if (file.type.indexOf('image/') > -1) {
        this._api.uploadImage(file)
          .subscribe((res: {url: string}) => {
            const urlField = dialog.getContentElement('info', 'txtUrl');
            urlField.setValue(res.url);
            dialog.selectPage('info');
          });
      } else {
        const fileField = dialog.getContentElement('Upload', 'upload');
        // console.log('fileUploadRequest, fileField=', fileField);
        fileField.setValue(null);
        alert('You can only include images (.png, .jpg, .jpeg, .gif)');
      }
    });
    /**
     * Fired when an image is pasted into the editor. Convert the file to base64, wrap
     * in an img tag and insert into the editor.
     * @event CKEditor4#paste
     */
    this.editorComponent.instance.on('paste', (evt: CKEditor4.EventInfo) => {
      if (evt.data.type === 'html') {
        const dataTransfer: any = evt.data.dataTransfer;
        const files: File[] = dataTransfer._.files;
        if (files && files.length && files[0].type.indexOf('image/') > -1) {
          const FR = new FileReader();
          FR.addEventListener('load', (e: any) => {
            const html = `<img src="${e.target.result}" alt=${files[0].name}>`;
            this.editorComponent.instance.insertHtml(html);
          });
          FR.readAsDataURL((files[0]));
        }
      }
    });
  }
}
