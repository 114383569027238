import {Jobs, Times} from '@allbirds-ui/allbirds-types';
import date = Times.date;
import { v4 as uuidv4 } from 'uuid';

interface IBookmarkUser {
  front_office_id: string;
  back_office_id: string;
  name: string;
  email: string;
}

export interface IBookmarkJob {
  id: string;
  title: string;
  priority: number;
  customer_name: string;
}

export interface IBookmarkTalent {
  candidateFullName: string;
  candidateFrontOfficeID: string;
  profile: string;
}


interface IBookmark {
  bookmark_id: string;
  bookmark_url: string;
  user: IBookmarkUser;
  create_date_time?: date;
  last_visit_date_time: date;
  job: IBookmarkJob;
  talent: IBookmarkTalent;
  image_url: any;
}

export class Bookmark implements IBookmark {

  constructor(public bookmark_id: string, public bookmark_url: string, public create_date_time: date, public last_visit_date_time: date,
              public user: IBookmarkUser, public job: any, public talent: IBookmarkTalent, public image_url: any) {
    this.bookmark_id = bookmark_id || uuidv4();
    this.bookmark_url = bookmark_url;
    this.user = user;
    this.create_date_time = create_date_time || new Date().toUTCString();
    this.last_visit_date_time = last_visit_date_time;
    this.job = job ? { id: job.allbirds_metadata.allbirds_job_id, title: job.title, priority: job.allbirds_metadata.customer_priority,
      customer_name: job.allbirds_metadata.customer_name} : null;
    this.talent = talent ? { candidateFullName: talent.candidateFullName, candidateFrontOfficeID: talent.candidateFrontOfficeID, profile: talent.profile} : null;
    this.image_url = image_url;
  }

}
