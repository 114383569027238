<span *ngIf="type === 'list'" class="badge badge-danger up" style="top: 25px; left: 8px">{{_bookmark.new_bookmark_count}}</span>
<div style="width: 56px">
  <!--section for list view and button-->
  <ng-container *ngIf="type === 'list'">
    <span id="bookmarkIcon" style="color: white; cursor: pointer" (click)="open()" tooltip="Bookmarks" placement="right">
      <img src="../../../../assets/icons/bookmark-white.svg" class="p-3" />
    </span>
    <div #smallWindow id="smallWindow" *ngIf="isOpen && !isFullScreen" class="position-fixed">
      <div class="container-fluid px-0">
        <div id="smallHeader" class="row border-dark no-gutters p-0 m-0 align-items-center">
          <div class="col-2 pl-3 mr-auto text-center justify-content-center align-self-center">
            <span>Bookmarks</span>
          </div>
          <div class="col-1 mr-3">
            <img (click)="makeFullScreen()" style="cursor: pointer" src="../../../../assets/icons/full-screen-gray.svg"  />
          </div>
        </div>
          <div *ngIf="bookmarkList.length" style="height: 360px; overflow-y: scroll; overflow-x: hidden ">
            <div class="row no-gutters p-0 m-0" *ngFor="let bookmark of bookmarkList; let i = index">
              <app-bookmark-card [bookmark]="bookmark" (closeWindow)="close()" type="small" [contextMenu]="basicMenu" [contextMenuSubject]="bookmark">
              </app-bookmark-card>
            </div>
          </div>

        <ng-container *ngIf="!bookmarkList.length">
          <div class="text-center align-self-center align-items-center justify-content-center h-100">
            <div class="text-center align-self-center align-items-center justify-content-center centerContent w-100">
              <img height="102" class="w-100" src="assets/images/Rocket_illustration_UseBackgroundWhite_RGB.svg" title="rocket"  />
              <span class="nothingToSee w-100 mt-3" style="font-size: 20px!important;">Nothing to see here</span>
              <span class="description w-100 mt-3"  style="font-size: 10px!important;">Bookmark pages to switch easily between different views</span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div *ngIf="isOpen && isFullScreen" class="bigWindowDrop" (click)="close()">

    </div>
    <div id="bigWindow" *ngIf="isOpen && isFullScreen" class="position-fixed">
      <div class="container-fluid px-0 h-100">
        <div id="bigHeader" class="row border-dark no-gutters p-0 m-0 align-items-center">
          <div class="col-2 pl-3 mr-auto text-center justify-content-center align-self-center">
            <span style="font-size: 24px">Bookmarks</span>
          </div>
          <div class="col-2 border-right">
            <span *ngIf="bookmarkList.length" (click)="openDeleteAllModal()" style="font-size: 14px; cursor: pointer">Remove all</span>
          </div>
          <div class="col-1 mr-3">
            <img (click)="switchOpen()" style="cursor: pointer" src="../../../../assets/icons/close-gray.svg"  />
          </div>
        </div>
        <ng-container *ngIf="bookmarkList.length">
          <cdk-virtual-scroll-viewport style="height: calc(100vh - 110px); overflow-x: hidden" itemSize="170">
            <div class="row" *cdkVirtualFor="let bookmark of bookmarkList; let i = index">
              <app-bookmark-card [bookmark]="bookmark" (closeWindow)="close()" type="big" [contextMenu]="basicMenu" [contextMenuSubject]="bookmark">
              </app-bookmark-card>
            </div>
          </cdk-virtual-scroll-viewport>
        </ng-container>
        <ng-container *ngIf="!bookmarkList.length">
          <div class="text-center align-self-center align-items-center justify-content-center h-100">
            <div class="text-center align-self-center align-items-center justify-content-center centerContent w-100">
              <img height="204" class="w-100" src="assets/images/Rocket_illustration_UseBackgroundWhite_RGB.svg" title="rocket"  />
              <span class="nothingToSee w-100 mt-3">Nothing to see here</span>
              <span class="description w-100 mt-3" >Bookmark pages to switch easily between different views</span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

  </ng-container>


  <!-- section for headers, add/remove bookmark action button-->
  <ng-container *ngIf="type === 'action'">
  <span *ngIf="!bookmarkExist()" style="color: white; cursor: pointer" (click)="addBookmark()">
    <img src="../../../../assets/icons/bookmark.svg" class="p-3" />
  </span>

  <span *ngIf="bookmarkExist()" style="color: white; cursor: pointer" (click)="deleteBookmark()">
    <img src="../../../../assets/icons/bookmark-filled.svg" class="p-3" />
  </span>

  </ng-container>
</div>


<ng-template #bookmarkMenu let-bookmark>
  <section class="user-menu">
    <div (click)="redirectFromMenu(bookmark)">Open in new tab</div>
  </section>
</ng-template>


<context-menu>
  <ng-template contextMenuItem let-bookmark (execute)="redirectFromMenu($event.item)">
   Open in New Tab
  </ng-template>
</context-menu>





