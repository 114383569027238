import {
  AfterContentInit, AfterViewInit,
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { INTERVIEW_TYPES } from './activity.types';
import { ActivityFeedService } from 'src/app/shared/services/activity-feed/activity-feed.service';
import { ModalService } from '../../../../services/modal/modal.service';
import { Activity, ActivityFeedTypes } from 'src/app/shared/models/internal/activity.interface';
import { Application } from '../../../../models/external/application.model';
import { Profile } from '../../../../models/external/profile.model';
import { UtilityService } from '../../../../services/utility/utility.service';
import { Job } from '../../../../models/external/job.model';

@Component({
  selector: 'app-activity-feed',
  templateUrl: './activity-feed.component.html',
  styleUrls: ['./activity-feed.component.scss']
})
export class ActivityFeedComponent implements OnInit, OnChanges {

  // The talent's application from ES.
  @Input() talentProcess: Application;

  // The talent's profile from ES.
  @Input() talentProfile: Profile;

  // Job information.
  @Input() job: Job = null;

  // Clicking the caret that appears when hovering over an activity
  // uses this event emitter to let the parent component determine how
  // it should handle the behavior.
  @Output() activityDetailClick = new EventEmitter<Activity>();

  // The array of step information and their respective activities.
  stepArray: any;

  // Talent's current step and name.
  @Input() candidateFullName = '';
  lastProcessStep = '';
  lastProcessStepNum: number;

  // Determines if view is ready to be rendered.
  showFeed = false;

  // Modal ref.
  bsModalRef: BsModalRef;

  // For displaying errors.
  error = false;

  // Map for interviewType enum -> label.
  interviewTypes: any = INTERVIEW_TYPES;

  // Enum for activity types.
  ActivityFeedTypes: typeof ActivityFeedTypes = ActivityFeedTypes;

  constructor(
    public _activityFeed: ActivityFeedService,
    private _bsModal: ModalService
  ) { }

  /**
   * Retrieves the activity feed on initialization.
   */
  ngOnInit(): void {
    this.getFeed(this.talentProcess, this.job);
  }

  /**
   * Every time a binded input property changes, if the change was the
   * talent process object, then update the values and retrieve the
   * feed for the newly passed talent process.
   * @param changes - all binded input properties that have changed.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.talentProcess && !changes.talentProcess.firstChange) {
      this.showFeed = false;
      this.talentProcess = changes.talentProcess.currentValue;
      this.getFeed(this.talentProcess, this.job);
    }
  }

  /**
   * Retrieves a formatted activity feed from the activity feed service.
   * @param application - talent's application object.
   */
  getFeed(application: Application, job: Job): void {
    this._activityFeed.generateFeed(application, job)
      .then((feed: any) => {
        if (!feed) { return this.error = true; }
        const { application, showFeed, activities } = feed;
        const name = UtilityService.getNameFromTalentProfile(this.talentProfile) || this.candidateFullName;
        this.candidateFullName = name || (application.randstad_process || {}).candidateFullName || '';
        this.lastProcessStep = application.randstad_process.lastProcessStep;
        this.lastProcessStepNum = application.randstad_process.lastProcessStepNum;
        this.stepArray = Array.from(activities.values()).reverse();
        this.showFeed = showFeed;
        this.error = false;
      })
      .catch(err => {
        this.error = true;
        console.log('[getFeed]', err);
      });
  }

  /**
   * Returns true if the passed string is equal to the talent's last process step.
   * @param stepField
   */
  isCurrentStep(stepField: string): boolean {
    return stepField === this.lastProcessStep;
  }

  /**
   * Emits the activity whose caret was clicked on.
   * @param activity - activity object
   */
  detailOnClick(activity: Activity): void {
    this.activityDetailClick.emit(activity);
  }
}


@Directive({
  selector: '[appLimitHtml]'
})
export class LimitHtmlDirective implements AfterViewInit {
  @Input('appLimitHtml') length: any;
  constructor(private el: ElementRef) {
  }
  ngAfterViewInit() {
    if (this.el.nativeElement.innerText.length > (this.length || 100)) {
      this.el.nativeElement.innerText = this.el.nativeElement.innerText.substring(0, this.length || 100) + '...'
    }
  }
}
