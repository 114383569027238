import {
  AfterViewChecked,
  AfterViewInit,
  Component, ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output, QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { Subscription } from 'rxjs';
import {IntersectionObserverEvent} from 'src/app/shared/components/talent-detail-pane/talent-process/recruitment-phases/recruitment-phases.interface';
import { TalentProcessService } from 'src/app/shared/services/talent-process/talent-process.service';
import { ToastService } from 'src/app/shared/services/toast';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { MetricsService } from 'src/app/shared/services/metrics/metrics.service';
import { Application } from 'src/app/shared/models/external/application.model';
import { ProcessStatus } from 'src/app/shared/models/external/misc.model';
import { ModalService } from '../../../../../services/modal/modal.service';
import { RejectOfferModalComponent } from './reject-offer-modal/reject-offer-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Job } from '../../../../../models/external/job.model';
import moment from 'moment';
import { Offer } from 'src/app/shared/models/external/process/offer.model';
import { INTG_STEPS } from 'src/app/shared/models/internal/process.model';
import {RecruitmentPhasesService} from '../recruitment-phases.service'

@Component({
  selector: 'offer-phase',
  templateUrl: './offer.component.html',
  styleUrls: [
    './offer.component.scss',
    '../recruitment-phases.component.scss'
  ]
})
export class OfferComponent implements OnInit, AfterViewChecked {

  // Determines which template to show.
  shownPhase: TemplateRef<any>;
  previousShownPhase: TemplateRef<any>;
  appSub: Subscription;
  application: Application;
  statusLabel: string;
  // The different templates for the different phases.
  @ViewChild('sendOffer', { static: true }) sendOfferView: TemplateRef<any>;
  @ViewChild('acceptedFinalView', { static: true }) acceptedFinalView: TemplateRef<any>;
  @ViewChild('ctaContainer', {static: false}) ctaContainer: ElementRef;
  @ViewChild('ctaContainerBoundry', {static: false}) ctaContainerBoundry: ElementRef;

  @Output() unfitClick = new EventEmitter();

  bsModalRef: BsModalRef;
  @Input() job: Job;
  @Input() talentInterviewStatus: boolean

  constructor(
    private _talentProcess: TalentProcessService,
    private toast: ToastService,
    private auth: AuthService,
    private _metrics: MetricsService,
    private _modal: ModalService,
    private _recruitmentPhasesService: RecruitmentPhasesService,
  ) { }

  ngOnInit() {
    this.listenToSelectedApplication();
  }

  ngAfterViewChecked() {
    if (this.previousShownPhase !== this.shownPhase) {
      this.previousShownPhase = this.shownPhase;
      if (this.ctaContainer && this.ctaContainer.nativeElement) {
        const elements = [
          this.ctaContainer.nativeElement,
          this.ctaContainerBoundry.nativeElement
        ];
        this._talentProcess.setupIntersectionObserver(elements);
      }
    }
  }

  listenToSelectedApplication() {
    this.appSub = this._talentProcess.selectedApplication
      .subscribe(application => {
        this.application = application ? application.clone() : null;
        if (application) {
          const lob = this.auth && this.auth.user ? this.auth.user.Source : null;
          if (lob) {
            this.statusLabel = this._talentProcess.getLabel(this.application, lob, 'process');
          }
        }
        if (
          application &&
          application.randstad_process &&
          application.randstad_process.lastProcessStatus &&
          [ProcessStatus.O_ACCEPTED, ProcessStatus.O_REJECTED].includes(application.randstad_process.lastProcessStatus)
        ) {
          if(application.randstad_process.lastProcessStatus === ProcessStatus.O_ACCEPTED)
          {
            this.shownPhase = this.acceptedFinalView;
          }
        } else {
          this.shownPhase = this.sendOfferView;
        }
      });
  }

  // "offer accepted" button called
  submit() {
    if (this._recruitmentPhasesService?.talentInterviewStatus){
      this._recruitmentPhasesService.openWarningModal()
    } else {
      const isProfileValid = this._recruitmentPhasesService.checkIfProfileIsDataValid();
      if (isProfileValid === true) {
        const key = this.application.randstad_process.lastProcessStep;
        const body = this.constructBody();
        const intgSteps: INTG_STEPS[] = [INTG_STEPS.SUBMISSION];
        this._talentProcess.updateApplication(body, intgSteps, key).then((data) => {
          if (data) {
            this._metrics.addEventToQueue(this.application, `an offer was made to the candidate`);
            this.toast.showToast('offer.success_logged');
          } else {
            this.toast.showToast('offer.error_logged');
          }
        }, err => {
          console.log(err);
          this.toast.showToast('offer.error_logged');
        });
      }
    }
  }

  constructBody(): Application {
    const clonedApp = this.application.clone();
    clonedApp.randstad_process.apply({
      lastProcessStatus: ProcessStatus.O_ACCEPTED,
      offer: new Offer({
        offerDate: moment(),
        agentID: this.auth.user.BackOfficeID
      })
    });
    return clonedApp;
  }

  /**
   * Handles clicking the "Not a Fit" button. For RGS jobs, launches the
   * RGS offer rejection modal. Else, it emits an event that's handled
   * by the parent component (recruitment-phases.component.ts) where a
   * generic rejection modal is launched.
   * @param e - click event
   */
  handleNotFitClick(e: MouseEvent) {
    if(this._recruitmentPhasesService?.talentInterviewStatus){
      this._recruitmentPhasesService.openWarningModal()
    }else{
      return (this.job.allbirds_metadata.lob.includes('RGS'))
      ? this.openRejectOfferModal()
      : this.emitUnfitClick(e);
    }

  }

  /**
   * Emits an event to the parent component that causes the standard
   * reject modal to pop up
   * @param e - click event
   */
  emitUnfitClick(e: MouseEvent) {
    e.preventDefault();
    this.unfitClick.emit();
  }

  /**
   * Launches the RGS offer rejection modal.
   */
  openRejectOfferModal() {
    const state = {
      initialState: {
        application: this.application,
        job: this.job
      }
    };
    this.bsModalRef = this._modal.show(RejectOfferModalComponent, state);
    this.bsModalRef.setClass('modal-sm');
  }

}
