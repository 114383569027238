import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { JobDetailsService } from '../job-details/job-details.service';
import { TalentManagementService } from '../talent-management/talent-management.service';
import { TalentManagementListingsService } from 'src/app/modules/talent/pages/talent-management-listings/talent-management-listings.service';
import { JobDetailsShortlistService } from '../job-details-shortlist/job-details-shortlist.service';
import { Talent } from '../../models/external/talent.interface';
import { UrlStateService } from 'src/app/shared/services/url-state/url-state.service';
import { Application } from '../../models/external/application.model';
import { extractIdFromName } from '../utility/formatters';

@Injectable({
  providedIn: 'root'
})
export class PaginateService<T extends Talent> {
  resumeSource = new BehaviorSubject<Blob>(null);
  resumeObservable = this.resumeSource.asObservable();
  currentTalent: T | Application;
  previousTalent: T | Application;
  nextTalent: T | Application;
  currentIndex: number;
  worker: Worker;
  loadingNext: boolean;

  // Emits the selected talent.
  private selectTalentEventSubject = new Subject<T | Application>();
  public selectTalentEvent = this.selectTalentEventSubject.asObservable();

  constructor(
    private talentListing: TalentManagementListingsService,
    private _jobDetails: JobDetailsService,
    private talentManagementService: TalentManagementService,
    private _shortlist: JobDetailsShortlistService,
    private _url: UrlStateService
  ) {
    this.worker = new Worker('assets/workers/indexFinder.js');
    this.listenToWorker();
  }

  resetTalent(resetJobDetails = true) {
    this.currentTalent = null;
    this.nextTalent = null;
    this.previousTalent = null;
    this.currentIndex = null;
    this.loadingNext = null;
    if (resetJobDetails) {
      this._jobDetails.resetTalent();
    }
    this.talentListing.talentSubject.next({'applications':[], 'listTalent': []});
    this.talentManagementService.talentManagementProfileSearchResults = [];
  }

  selectTalent(talent: any) { // explicitly using "any" here because there are three possible types being passed
  this.setCurrentIndex(talent);
    this._jobDetails.sectionOneActive = false;
    this.selectTalentEventSubject.next(talent);
  }

  paginate(direction: 'right' | 'left') {
    if (direction === 'right' && this.nextTalent) {
      this.selectTalent(this.nextTalent);
    } else if (direction === 'left' && this.previousTalent) {
      this.selectTalent(this.previousTalent);
    }
  }

  setCurrentIndex(talent: T | Application) {
    this.currentTalent = talent;
    this.currentIndex = talent.index; // TODO: if the talent doesn't have an "index" property, how do we get index? Should we pass it?
    // Update URL to reflect the selected talent. Passing "true" as the second parameter creates a new browser history entry.
    this._url.patch({
      st: extractIdFromName(talent.profileId || talent.name),
      // sti:  this.currentIndex // not currently in use due to index variability with both matching engine results and the shortlist
    }, true);
    const clone = (v: any) => JSON.parse(JSON.stringify(v));

    if (this._shortlist.talentSubject.value
      && this._shortlist.talentSubject.value.length) {
      this.worker.postMessage({ list: clone(this._shortlist.talentSubject.value), talent: clone(talent) });
    }

    if (this._shortlist.rejectedSubject.value
      && this._shortlist.rejectedSubject.value.length) {
      this.worker.postMessage({ list: clone(this._shortlist.rejectedSubject.value), talent: clone(talent) });
    }

    if (this._jobDetails.potentialTalent.value
      && this._jobDetails.potentialTalent.value.length) {
      this.worker.postMessage({ list: clone(this._jobDetails.potentialTalent.value), talent: clone(talent) });
    }

    if (this.talentListing.talentSubject.value
      && this.talentListing.talentSubject.value.applications.length) {
      this.worker.postMessage({ list: clone(this.talentListing.talentSubject.value), talent: clone(talent) });
    }

    if (this.talentManagementService.talentManagementProfileSearchResults
      && this.talentManagementService.talentManagementProfileSearchResults.length) {
      this.worker.postMessage({
        list: clone(this.talentManagementService.talentManagementProfileSearchResults),
        talent: clone(talent)
      });
    }
  }

  listenToWorker() {
    this.worker.onmessage = (e) => {
      if (e.data) {
        this.currentIndex = e.data.currentIndex;
        this.previousTalent = e.data.previousTalent;
        this.nextTalent = e.data.nextTalent;
      }
    };
  }

}
