import { Theme } from './symbols';

export const lightTheme: Theme = {
  name: 'light',
  properties: {
    '--color-bg': '#f3f3f3',
    // Functiontal
    '--color-transparent': ' transparent',
    '--color-green': '#5eac00',
    '--color-green-dark': '#3d6c00',
    '--color-green-light': '#e0efce',
    '--color-red': '#e62a00',
    '--color-red-dark': '#b82900',
    '--color-red-light': '#f7c0b5',
    '--color-yellow': '#f8b200',
    '--color-yellow-dark': '#c39300',
    '--color-yellow-light': '#fde9b4',
    // Grayscale
    '--color-black': '#2a2a2a',
    '--color-gray': '#6c6c6c',
    '--color-silver': '#d8d8d8',
    '--color-white': '#fff',
    // Primary
    '--color-primary': '#2175d9',
    '--color-secondary': '#0f1941',
    '--color-tetriary': '#1e3077',
    // Shadow
    '--color-shadow': 'rgba(42, 42, 42, .1)',
  }
};

export const darkTheme: Theme = {
  name: 'dark',
  properties: {
    '--color-bg': 'dimgrey',
    // Functiontal
    '--color-transparent': ' transparent',
    '--color-green': '#5eac00',
    '--color-green-dark': '#3d6c00',
    '--color-green-light': '#e0efce',
    '--color-red': '#e62a00',
    '--color-red-dark': '#b82900',
    '--color-red-light': '#f7c0b5',
    '--color-yellow': '#f8b200',
    '--color-yellow-dark': '#c39300',
    '--color-yellow-light': '#fde9b4',
    // Grayscale
    '--color-black': '#2a2a2a',
    '--color-gray': 'dimgray',
    '--color-silver': 'gray',
    '--color-white': '#fff',
    // Primary
    '--color-primary': '#e8492e',
    '--color-secondary': '#761607',
    '--color-tetriary': '#b7260e',
    // Shadow
    '--color-shadow': 'rgba(42, 42, 42, .1)',
  }
};
