import { Injectable, Injector, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { MetricsEvent } from './metrics.types';
import { JobDetailsService } from '../job-details/job-details.service';
import { JobDetailsShortlistService } from '../job-details-shortlist/job-details-shortlist.service';
import { ApiService } from '../api/api.service';
import { CacheService } from '../cache/cache.service';

@Injectable({
  providedIn: 'root'
})
export class MetricsService implements OnDestroy {
  eventQueueSubject = new BehaviorSubject<MetricsEvent[]>([]);
  eventQueue = this.eventQueueSubject.asObservable();
  eventQueueSub: Subscription;

  impressionSubject = new BehaviorSubject<any>({});
  impressions = this.impressionSubject.asObservable();
  impressionSub: Subscription;

  scrollTimeout: any = null;
  scrollTimeoutSubject = new Subject();

  jobDetailSub: Subscription;
  talentEventSub: Subscription;

  constructor(
    private _api: ApiService,
    private _auth: AuthService,
    private injector: Injector,
    private _cache: CacheService
  ) {
    this.listenToEventQueue();
    this.listenToImpressionQueue();
    this.listenToJobDetailEvents();
  }

  ngOnDestroy(): void {
    const events = this.eventQueueSubject.getValue();
    if (events.length) { this.sendEvents(events); }
    if (this.eventQueueSub) { this.eventQueueSub.unsubscribe(); }
    if (this.impressionSub) { this.impressionSub.unsubscribe(); }
    if (this.jobDetailSub) { this.jobDetailSub.unsubscribe(); }
  }

  listenToEventQueue(): void {
    this.eventQueueSub = this.eventQueue
      .pipe(debounceTime(2000))
      .subscribe(value => {
        if (value.length) {
          this.sendEvents(value);
          this.eventQueueSubject.next([]);
        }
      });
  }

  listenToImpressionQueue(): void {
    this.impressionSub = this.impressions
      .pipe(debounceTime(2000))
      .subscribe(impressions => {
        const profiles = Object.keys(impressions);
        if (profiles.length) {
          const newEvent = this.generateEventData(
            'profile impression in matching engine results',
            profiles,
            null,
            impressions[profiles[0]],
            null
          );
          const eventQueue = this.eventQueueSubject.getValue();
          eventQueue.push(newEvent);
          this.eventQueueSubject.next(eventQueue);
          this.impressionSubject.next({});
        }
      });
  }

  listenToJobDetailEvents(): void {
    // DEPRECATED AFTER DF044-1714
    // const _jobDetails = this.injector.get(JobDetailsService);
    // this.jobDetailSub = _jobDetails.clientEvent
    //   .subscribe((evt: EventMetadata) => {
    //     evt.eventType = this.replaceEventType(evt.eventType);
    //     this.addEventToQueue({ profile: evt.profile, eventType: evt.eventType });
    //   });
    const _shortlist = this.injector.get(JobDetailsShortlistService);
    this.jobDetailSub = _shortlist.clientEvent
      .subscribe(evt => {
        const isModalShortlist = evt.eventType === 'MODAL_PROFILE_SHORTLIST' ? true : false;
        evt.eventType = this.replaceEventType(evt.eventType);
        this.addEventToQueue(evt.profile, evt.eventType, isModalShortlist);
      });
  }

  replaceEventType(type: string): string {
    if (type === 'PROFILE_SHORTLIST' || type === 'MODAL_PROFILE_SHORTLIST') { return 'talent was shortlisted'; }
    if (type === 'NEGATIVE_FEEDBACK') { return 'candidate was marked not a fit'; }
    return type;
  }

  sendEvents(events: MetricsEvent[]): void {
    // console.log('[sendMetricsEvents] sent: ', events);
    this._api.postMetrics(events)
      .subscribe(res => {
        // console.log('[sendEMetricsEvents] response: ', res);
      }, err => {
        console.log('[sendMetricsEvents] error: ', err);
      });
  }

  addEventToQueue(talent: any /* Talent */, eventType: string, isModalShortlist = false): void {
    const eventQueue = this.eventQueueSubject.getValue();
    let talentId = null;
    if(talent.isApplication()) {
      if (talent.randstad_process && talent.randstad_process.candidateFrontOfficeID) {
        // console.log('WARNING: talent is an application - filling externalId field with talent.randstad_process.candidateFrontOfficeID')
        talentId = talent.randstad_process.candidateFrontOfficeID;
      } else {
        // console.log('WARNING: talent is an application without randstad_process - retrieving profile externalId from cache: ', talent)
        if (this._cache.loadProfile(talent.profile)) {
          talentId = this._cache.loadProfile(talent.profile).externalId;
        }
      }
    } else {
      if (talent.externalId) {
        talentId = talent.externalId.includes('USRGS') || talent.externalId.includes('USRT') ?
        `${talent.externalId}` :
        `${talent.lob}${talent.externalId}`;
      }
    }

    const { score, likelyAvailable } = talent;
    const newEvent = this.generateEventData(
      eventType,
      [talentId],
      score ? [score] : null,
      talent.index,
      likelyAvailable ? [likelyAvailable] : null,
      isModalShortlist
    );
    eventQueue.push(newEvent);
    this.eventQueueSubject.next(eventQueue);
  }

  addToImpressionQueue(talentName: string, index: number): void {
    const impressions = this.impressionSubject.getValue();
    impressions[talentName] = index;
    this.impressionSubject.next(impressions);
  }

  generateEventData(eventType: string, profiles: string[], scores: number[], index: number, likelyAvailable: boolean[], isModalShortlist = false): MetricsEvent {
    const _jobDetails = this.injector.get(JobDetailsService);
    const job = isModalShortlist ? 'addToJobModalJob' : 'job';
    let jobId = (!_jobDetails || !_jobDetails[job] || !_jobDetails[job].allbirds_metadata || !_jobDetails[job].allbirds_metadata.front_office_id) ? null : 'in';
    if(jobId === 'in'){
        jobId = _jobDetails[job].allbirds_metadata.front_office_id
    }
    return {
      event_type: eventType,
      event_details: JSON.stringify({
        profiles,
        scores,
        likelyAvailable,
        jobs: jobId ? [jobId] : null,
        index: index ? index : null,
        requestId: _jobDetails.requestId
      })
    };
  }
}
