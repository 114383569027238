import { Injectable } from '@angular/core';
import { Profile } from '../../../models/external/profile.model';

@Injectable({
  providedIn: 'root'
})
export class TalentProfileService {

  constructor() { }

  getWorkAvailability(elasticData: Profile): string {
    let workAvailability = 'not specified';
    if (elasticData && elasticData.workAvailability && elasticData.workAvailability.length) {
      elasticData.workAvailability.forEach(
        (availability) => {
          if (availability.available === true && availability.notes && availability.notes.length) {
            workAvailability = availability.notes;
          }
        }
      );
    }
    return workAvailability;
  }
}
