import { Applications } from '@allbirds-ui/allbirds-types';
import { createModel, Mapping, Model } from '../model';
import { IsoToMoment } from '../serializers/moment.serializer';
import { Moment } from 'moment';
import { Serial } from '../decorators/serial.decorator';
export import RawPrescreening = Applications.Prescreening;

class PrescreeningMapping extends Mapping<RawPrescreening> {
  @Serial(IsoToMoment) completionDate?: Moment;
  @Serial(IsoToMoment) initiatedDate?: Moment;
  @Serial(IsoToMoment) skippedDate?: Moment;
}

export interface Prescreening extends Model<PrescreeningMapping> {}

export const Prescreening = createModel<Prescreening>(PrescreeningMapping);
