import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators'
import { environment } from 'src/environments/environment';
import { PlatformAlert } from '../../models/internal/platform-alert.model';
import { RawConversation } from '../../models/external/conversation.model';
import { IDManagers } from '../../models/external/misc.model';
import ApplicationIDManager = IDManagers.ApplicationIDManager;

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor(
    private db: AngularFirestore,
    private _http: HttpClient
  ) { }

  listenApplication(applicationID: string) {
    return this.db.collection<ApplicationIDManager>(environment.collections.ApplicationIDManager,
      ref => ref.where('allbirdsElasticId', '==', applicationID)).valueChanges();
  }

  listenJob(jobID: string) {
    return this.db.doc(`${environment.collections.IDManager}/${jobID}`).valueChanges();
  }

  listenVMSOrder(jobID: string) {
    return this.db.doc(`${environment.collections.VMSJob}/${jobID}`).valueChanges();
  }

  listenStep3InitialUserSave(jobID: string) {
    return this.db.doc(`${environment.collections.SelectedPrompts}/${jobID}`).valueChanges();
  }

  public getConversation(googleJobID: string, googleTalentID: string) {
    return this.db.collection<RawConversation>(environment.collections.Conversations,
      ref => ref.where('job', '==', googleJobID).where('profile', '==', googleTalentID)
    );
  }

  public getConversationForID(conversationID: string) {
    if (conversationID) {
      return this.db.collection<RawConversation>(environment.collections.Conversations,
        ref => ref.where('name', '==', conversationID)
      );
    }
    return null;
  }

  public async getCompanyURI(companyId: string, lob: string): Promise<any> {
    companyId = lob.checkLob('RT', 'RE','CR') ? 'USRT' + companyId : 'USRGS' + companyId;
    // https://stackoverflow.com/questions/41885219/angular-2-firebase-observable-to-promise-doesnt-return-anything
    // in order to use await we needed to add first and to promise
    return this.db.doc(`${environment.collections.CompanyIDManager}/${companyId}`).valueChanges().pipe(first()).toPromise();
  }

  public listenToAlert() {
    return this.db.collection<PlatformAlert>(environment.collections.PlatformAlerts,
      ref => ref.orderBy('date', 'desc').limit(1)).valueChanges();
  }

  public createNewAlert(alert: PlatformAlert, id: string): Promise<any> {
    return this.db.collection(environment.collections.PlatformAlerts).doc(id).set(alert);
  }

  public removeAlert(id: string): Promise<any> {
    return this.db.doc(`${environment.collections.PlatformAlerts}/${id}`).update({ isActive: false });
  }

  public editAlert(newAlert: PlatformAlert): Promise<any> {
    return this.db.doc(`${environment.collections.PlatformAlerts}/${newAlert.id}`).update(newAlert);
  }

  public async getDashboardView(backOfficeID: any) : Promise<any> {
    return this.db.doc(`UserPreferences/${backOfficeID}`).valueChanges().pipe(first()).toPromise();
  }

  public getVmsSettings(backOfficeID: any) {
    return this.db.doc(`UserPreferences/${backOfficeID}`).valueChanges();
  }

  public setDashboardView(backOfficeID: any, view: any) {
    this.db
    .collection('UserPreferences')
    .doc(backOfficeID)
    .set({
      defaultDashboardView: view
    }, { merge: true }).then((data) => {
      console.log(data);
    }).catch(e => console.log(e));
  }
}
