import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { JobOrderService } from 'src/app/shared/services/job-order/job-order.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { ToastClass, ToastService } from 'src/app/shared/services/toast';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormValidationService } from 'src/app/shared/services/form-validation/form-validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Job } from 'src/app/shared/models/external/job.model';
import { ApiService } from '../../../../../shared/services/api/api.service';
import { JobForm } from '../../../../../shared/models/external/job-form.model';
import { ClosedStatus } from '../../../../../shared/models/internal/process-statuses.interface';

/**
 * Values used as the control form value that is sent to BH.
 * Keys used to create translated values to display on UI.
 */
enum CloseJobTypes {
  Closed_Filled_Internally = 'Closed Filled Internally',
  Closed_Client_Cancelled = 'Closed Client Cancelled',
  Closed_Lost_Funding = 'Closed Lost Funding',
  Closed_LTC = 'Closed LTC',
  Closed_Manager_Unresponsive = 'Closed Manager Unresponsive',
  Closed_Unfilled = 'Closed Unfilled',
  Closed_Unknown = 'Closed Unknown',
  Closed_VMS_Unresponsive = 'Closed VMS Unresponsive',
  Closed_Duration = 'Closed Duration',
  Closed_Skill = 'Closed Skill',
  Closed_Rate = 'Closed Rate',
  Closed_Location = 'Closed Location',
  Closed_Prefill = 'Closed Prefill',
  Declined_Known_Prefill = 'Declined Known Prefill',
  Declined_Suspected_Prefill = 'Declined Suspected Prefill',
  Declined_VMS_Admin = 'Declined VMS Admin',
  Declined_Rate = 'Declined Rate',
  Declined_Skill = 'Declined Skill',
  Closed_COVID19 = 'Closed COVID-19',
  Declined_VMS_Worked_Via_AB = 'Declined VMS worked via AB'
}

@Component({
  selector: 'app-close-job-modal',
  templateUrl: './close-job-modal.component.html',
  styleUrls: ['./close-job-modal.component.scss']
})
export class CloseJobModalComponent implements OnInit {
  @ViewChild('closeLinkedJobsElem') closeLinkedJobsElem: ElementRef;
  
  jobData: any;
  allbirds_job_id: string;

  jobOrder: Job; /* The Job The Way It Is In Elastic */
  formJobOrder: JobForm; /* The Job The Way It Is In The Form */
  closeJobTypes: object = null;
  saving: boolean = false;
  closeJobForm: FormGroup;
  isSubmitted: boolean = false;
  customer_name: any;
  customer_priority: any;
  front_office_id: any;
  title: any;
  isClosed: boolean = false;
  originalCloseReason: string;
  disableUpdate: boolean = false;
  closeAllJobs: boolean = false;
  linkedJobsSub: any;
  
  get closeJobType() {
    return this.closeJobForm.controls.closeJobType;
  }
  get isLinkedJob (): boolean { return !!this.jobOrder?.allbirds_metadata?.linked_ab_job_id || this.jobOrder?.allbirds_metadata?.number_of_linked_jobs > 0; }
  get linkedJobID (): string { return this.jobOrder?.allbirds_metadata?.linked_ab_job_id || this.jobOrder?.allbirds_metadata?.allbirds_job_id; }
  
  constructor(
    private _renderer: Renderer2,
    private _api: ApiService,
    public _bsModalRef: BsModalRef,
    private _auth: AuthService,
    private _formBuilder: FormBuilder,
    private toastService: ToastService,
    private jobOrderService: JobOrderService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.createForm();
    this.setJobOrder(this.jobData);
    const keys = Object.keys(CloseJobTypes);
    this.closeJobTypes = keys.map((key: keyof typeof CloseJobTypes) => {
      return { key: CloseJobTypes[key], value: this.translate.instant(`close-job-modal.${key}`) };
    });

    this.closeJobTypes = Object.values(this.closeJobTypes).filter((item) => this._auth?.user?.Source !== 'RT' && item?.key === 'Declined VMS worked via AB' ? undefined : item);

    if (this.jobData && this.jobData.title) {
      this.title = this.jobData.title;
    }

    if (this.jobData && this.jobData?.allbirds_metadata) {
      const allBirds = this.jobData?.allbirds_metadata;
      this.customer_name = allBirds.customer_name || '';
      this.customer_priority = allBirds.customer_priority;
      this.front_office_id = allBirds.front_office_id;
    }
  }

  createForm() {
    this.closeJobForm = this._formBuilder.group({
      closeJobType: [null, Validators.compose([Validators.required, FormValidationService.validateNull])]
    });
  }

  setJobOrder = (job: Job) => {
    this.jobOrder = job;
    if (this.jobOrder && this.jobOrder?.allbirds_metadata) {
      this._api.getJob(this.jobOrder?.allbirds_metadata?.allbirds_job_id, true).toPromise().then(this.setFormJobOrder);
    }
  };

  setFormJobOrder = (job: JobForm) => {
    this.formJobOrder = job;
    if(ClosedStatus.includes(this.formJobOrder.order_status)) {
      this.isClosed = true;
      this.originalCloseReason = this.formJobOrder.order_status;
      if((Object.values(CloseJobTypes) as string[]).includes(this.originalCloseReason)) this.closeJobForm.patchValue({ closeJobType: this.originalCloseReason });
      this.disableUpdate = true;
    }
  };

  resetSubmit(value: string) {
    if (value) {
      this.isSubmitted = false;
      if(this.isClosed){
        this.disableUpdate = value === this.originalCloseReason ? true : false;
      }
    }
  }

  closeJob() {
    this.isSubmitted = true;
    if (!this.closeJobForm.valid) {
      return;
    }
    this.saving = true;

    this.formJobOrder.order_status = this.closeJobType.value;
    this.formJobOrder.publish_immediately = false;
    this.formJobOrder.published_status = false;

    this.jobOrder.allbirds_metadata.order_status = this.closeJobType.value;
    this.jobOrder.allbirds_metadata.publish_immediately = false;
    this.jobOrder.allbirds_metadata.published_status = false;

    let fieldsUpdated = ['order_status', 'publish_immediately', 'published_status'];
    if (this.closeAllJobs ) { fieldsUpdated.push('closeAllJobs'); }
    this._api.updateJob(this.formJobOrder, fieldsUpdated)
      .subscribe(this.publishJob, err => {
        this.saving = false;
        this.toastService.showToast('Close Job Update Failed', { cssClass: ToastClass.DANGER });
        console.log('job update failed.', err);
      });
  }

  publishJob = () => {
    const interviewObj = this.jobOrderService.getInterviewObject(this.jobOrder, this._auth.user);
    let fieldsUpdated = ['order_status', 'publish_immediately', 'published_status'];
    if (this.closeAllJobs ) { fieldsUpdated.push('closeAllJobs'); }
    this._api.publishJob(this.formJobOrder.allbirds_job_id, interviewObj, fieldsUpdated)
      .subscribe(this.updateJobSuccess, err => {
        this.saving = false;
        this.toastService.showToast('Job Republish Failed', { cssClass: ToastClass.DANGER });
        console.log('job republish failed.', err);
      });
  };

  updateJobSuccess = () => {
    this.toastService.showToast(`The job status is successfully updated to ${this.closeJobType.value}.`, { cssClass: ToastClass.SUCCESS });
    this.jobOrderService.storeJobDetails(this.jobOrder);
    this.saving = false;
    this._bsModalRef.hide();
  };

  onFocusEvent($event: FocusEvent) {
    this._renderer.setStyle(this.closeLinkedJobsElem.nativeElement, 'box-shadow', '0 0 0 0.2rem rgb(33 117 217 / 25%)');
  }

  onBlurEvent($event: FocusEvent) {
    this._renderer.removeStyle(this.closeLinkedJobsElem.nativeElement, 'box-shadow');
  }

}
