import { HttpErrorResponse } from "@angular/common/http";
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { TabDirective, TabsetComponent } from "ngx-bootstrap/tabs";
import { Subscription } from "rxjs";
import { AddToListAModalComponent } from "src/app/modules/talent/pages/list-details/add-to-list-a-modal/add-to-list-a-modal.component";
import { RemoveFromListModalComponent } from "src/app/modules/talent/pages/list-details/remove-from-list-modal/remove-from-list-modal.component";
import { FeatureUnavailableModalComponent } from "src/app/shared/components/feature-unavailable-modal/feature-unavailable-modal.component";
import { MASS_OPERATION_CONTEXT } from "src/app/shared/components/mass-email-modal/mass-operation-context";
import { SchedAppointmentModalComponent } from "src/app/shared/components/sched-appointment-modal/sched-appointment-modal.component";
import { SchedAppointmentInterviewModalComponent } from "src/app/shared/components/sched-appointment-interview-modal/sched-appointment-interview-modal.component";
import { ApptInviteService } from "src/app/shared/services/appt-invite/appt-invite.service";
import { UrlStateService } from "src/app/shared/services/url-state/url-state.service";
import { Application } from "../../models/external/application.model";
import { GoogleProfile } from "../../models/external/google-profile.model";
import { Profile } from "../../models/external/profile.model";
import { Talent } from "../../models/external/talent.interface";
import { ApiService } from "../../services/api/api.service";
import { AuthService } from "../../services/auth/auth.service";
import { CacheService } from "../../services/cache/cache.service";
// import { ClientEventService } from '../../services/client-event/client-event.service';
import { CLIENT_EVENT_TYPES } from "../../services/client-event/client-event.types";
import { IterableService } from "../../services/iterable/iterable.service";
import { JobDetailsShortlistService } from "../../services/job-details-shortlist/job-details-shortlist.service";
import { JobDetailsService } from "../../services/job-details/job-details.service";
import { LoadingSpinnerService } from "../../services/loading-spinner/loading-spinner.service";
import { MetricsService } from "../../services/metrics/metrics.service";
import { ModalService } from "../../services/modal/modal.service";
import { PaginateService } from "../../services/paginate/paginate.service";
import { ResolveService } from "../../services/resolve/resolve.service";
import { TalentProcessService } from "../../services/talent-process/talent-process.service";
import { TalentService } from "../../services/talent/talent.service";
import { ToastClass, ToastService } from "../../services/toast";
import { UtilityService } from "../../services/utility/utility.service";
import { JobDetailsModalComponent } from "../job-details-modal/job-details-modal.component";
import {
  NotAFitModalComponent,
  Reason,
} from "../not-a-fit-modal/not-a-fit-modal.component";
import { PersonalDetailsModalComponent } from "../personal-details-modal/personal-details-modal.component";
import { TALENT_DETAIL_CONTEXT } from "./talent-detail-pane.config";
import { AddToJobComponent } from "./talent-process/talent-process-modals/add-to-job/add-to-job.component";
import { LogNoteModalComponent } from "./talent-process/talent-process-modals/log-note-modal/log-note-modal.component";
import {DupTalentModalComponent} from '../dup-talent-modal/dup-talent-modal.component';
import {
  LogInterviewForm,
  LogInterviewModalComponent,
  LogInterviewModalConfig,
} from "./talent-process/talent-process-modals/log-interview-modal/log-interview-modal.component";
import { ChatBots, Google } from "@allbirds-ui/allbirds-types";
import { InterviewStatus } from "../../models/external/misc.model";
import { catchError, finalize } from "rxjs/operators";
import {
  EmailRecipient,
  NotificationData,
  NotificationTypes,
} from "../../models/internal/notifications-data.model";
import { Job } from "../../models/external/job.model";
import { JobOrderService } from "../../services/job-order/job-order.service";
import { NotificationsService } from "../../services/notifications/notifications.service";
import { AddFilesModalComponent } from "../attach-files/add-files-modal/add-files-modal.component";
import { AddUrlModalComponent } from "../attach-files/add-url-modal/add-url-modal.component";
import { AttachmentOptionsModalComponent } from "../attachment-options-modal/attachment-options-modal.component";
import { SuggestService } from "../../services/suggest/suggest.service";
import { dateToTimestamp } from "../../services/utility/formatters";
import { FocusKeyManager } from "@angular/cdk/a11y";
import { AttachmentListComponent } from "../attachment-list/attachment-list.component";

export interface RejectEvent {
  profile: Profile;
  reason: Reason[];
  note: string;
}

interface TalentAction {
  label: string;
  onClick: (...args: any[]) => any;
}

export interface ReinstateEvent {
  application: Application;
  profile: Profile;
}

@Component({
  selector: "app-talent-detail-pane",
  templateUrl: "./talent-detail-pane.component.html",
  styleUrls: ["./talent-detail-pane.component.scss"],
})
export class TalentDetailPaneComponent<T extends Talent>
  implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  // Context allows us to define different contexts in which this component is used.
  @Input() context: string = TALENT_DETAIL_CONTEXT.GENERAL;
  @Input() contextOptions: any = null;

  // Talent profile object.
  @Input() talent: T;
  @Input() nextTalent: T;
  @Input() previousTalent: T;
  @Input() disableActions: boolean = false;

  profile: Profile;

  // If this input is given, displays a job information button in the top-right corner.
  // Should be a JobOrder object.
  @Input() jobInfo: any = null;

  // If jobInfo is supplied and showJobInfoButton is true, displays job information button
  // in the top-right corner.
  @Input() showJobInfoButton = false;

  // Whether or not the job has an FO ID.
  @Input() jobActive = false;

  // Whether component is in split view component
  @Input() isInSplitView: boolean;

  // Emits an event when the corner "X" is clicked (parent component should handle this).
  @Output() onClose = new EventEmitter();

  // Emits an event for each respective talent action.
  @Output() onReinstate = new EventEmitter<ReinstateEvent>();
  @Output() onShortlist = new EventEmitter<Profile>();
  @Output() onReject = new EventEmitter<RejectEvent>();

  // Flags for showing/hiding buttons depending on if corresponding event emitter has an observer.
  showClose = false;
  showReinstate = false;
  showShortlist = false;
  showReject = false;
  showLogInterview = false;

  showNotification = false;
  showWarning = false;
  selectedTalent:any;
  searchResults:any;
  // Used for resume pagination.
  @Output() infinityScroll = new EventEmitter();

  pdf: Blob;
  timeout: number;
  original_resume_link: string;

  // To interact with ngx-bootstrap modal API.
  bsModalRef: BsModalRef;

  // Flag for showing the loading spinner.
  isLoading = true;

  // Flag for showing error message.
  isError = false;

  // Flag for showing deleted profile error message
  isDeletedProfile = false;
  mergedProfileId = '';

  // Flag for showing disqualified profile error message
  isDisqualifiedProfile = false;


  // Flags for showing buttons.
  showShortlistButtons = false;
  showStatusBanners = false;
  isRejectedTalent = false;
  isHiddenTalent = false;
  isShortlistTalent = false;
  isTalentRoute = false;
  isPipelines = false;
  disabled: boolean;

  // list of actions
  actions: TalentAction[] = [];

  // flag for showing FO status
  showFOTalentStatus = true;
  isListDetailsPage = false;

  // flag for showing personal details option
  showPersonalDetailsOption = false;

  // Used for scrolling back to top on talent change.
  @ViewChild("scrollTop", { static: true }) scrollTopDiv: ElementRef;

  // Holds the id of the previously active talent. This is done
  // to prevent spamming VIEW events when repeatedly clicking a card.
  previousTalentId: string;

  @ViewChildren("tabs3") tabsets: QueryList<TabsetComponent>;
  queryParams?: Params;
  tabset?: TabsetComponent;
  queryParamsSubscription: Subscription;
  tabsetsSubscription: Subscription;
  activeTabId: string;
  defaultTabChanged = false;
  /* The manual selection is to prevent reading from Router
   URL Service when user selects a particular tab.*/
  manualSelectionOfTabs = false;
  resumeErrorSubscription: Subscription;
  resumeError: boolean;
  listId: string;

  jobOrder: Job;
  isTalentSearchPage: boolean;
  attachments: any[] = [];
  _bsModalRef: any;
  arrowkeyLocation: number = 0;
  isEditable: boolean;
  editTalentSub: Subscription;
  savedTalent: any;

  constructor(
    private _api: ApiService,
    private _cache: CacheService,
    private _modal: ModalService,
    private _talent: TalentService,
    private _talentProcess: TalentProcessService,
    private _toast: ToastService,
    private _utility: UtilityService,
    // private _clientEvent: ClientEventService,
    private _metrics: MetricsService,
    private _sanitizer: DomSanitizer,
    public _paginate: PaginateService<T>,
    private _auth: AuthService,
    private spinner: LoadingSpinnerService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _suggestService: SuggestService,
    // The following are used in different contexts.
    private _jobDetails: JobDetailsService,
    private _shortlist: JobDetailsShortlistService,
    private _translate: TranslateService,
    private _url: UrlStateService,
    private _apptInvite: ApptInviteService,
    private _jobOrder: JobOrderService,
    private _notificationService: NotificationsService,
  ) {}

  get userLob(): string {
    return this._auth.user && this._auth.user.Source;
  }

  // used from tc & e for now
  get lob(): string {
    return this.userLob.checkLob("RGS") ? "RGS" : "RT";
  }

  get profileLob(): string {
    return this.profile.lob.checkLob("RGS") ? "RGS" : "RT";
  }

  ngOnInit(): void {
    this.manualSelectionOfTabs = false;
    this.setupView();
    this.checkObservers();
    this._route.params.subscribe((data) => {
      this.listId = data.listId || null;
    });
    this.jobOrder = this._jobOrder.storedJobOrder;
  }

  ngOnChanges(changes: SimpleChanges): void {
    let doSetup = false;
    this.manualSelectionOfTabs = false;
    if (changes.talent && !changes.talent.firstChange) {
      this.talent = changes.talent.currentValue.clone();

      doSetup = true;
    }

    if (doSetup) {
      this.setupView();
      this.checkObservers();
    }
    const { Source } = this._auth.user;
    const userLob = Source.includes("RGS") ? "RGS" : "RT";
    const talentLob = this.talent.lob === 'CR' ? 'RT' : this.talent.lob;
     this.disabled = !talentLob.includes(userLob);
  }

  ngOnDestroy(): void {
    this.talent = undefined;
    this.profile = undefined;
    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }
    if (this.tabsetsSubscription) {
      this.tabsetsSubscription.unsubscribe();
    }

    if (this.resumeErrorSubscription) {
      this.resumeErrorSubscription.unsubscribe();
    }

    if (this.editTalentSub) {
      this.editTalentSub.unsubscribe();
    }
  }
  setupView1(): void
  {
    this.setupContext();
  }
  setupView(): void {
    // Reset the flags.
    this.isLoading = true;
    this.isError = false;
    this.isDeletedProfile = false;
    this.isDisqualifiedProfile = false;
    // Reset the .pdf.
    this.pdf = null;
    this._talent.clearResumeErrors();
    this._paginate.resumeSource.next(null);
    // Setup IDs.
    // Potential talent.
    if (this.talent) {
      if (this.talent.isApplication()) {
        this._talentProcess.selectApplication(this.talent);
      }
      const profileID = this.talent.profileId;
      if (this._cache.loadProfile(profileID)) {
        this.profile = this._cache.loadProfile(profileID);
        this.handleProfileChange();
      } else {
        this.fetchProfile(profileID);
      }
    }

    // check if it is talent page
    if (this._router.url.includes("talent/search")) {
      this.isTalentRoute = true;
    }

    if (this._router.url.includes("talent/list")) {
      this.isPipelines = true;
    }

    if (this._router.url.includes("talent/list")) {
      this.isPipelines = true;
    }

    if (this._router.url.includes("talent/list")) {
      this.isPipelines = true;
    }

    if (this._router.url.includes("talent/list")) {
      this.isPipelines = true;
    }
    if (this._router.url.includes("talent/list")) {
      this.isPipelines = true;
    }

    if (this._router.url.includes("talent/list")) {
      this.isPipelines = true;
    }
    this.queryParamsSubscription = this._route.queryParams.subscribe(
      (params) => {
        this.queryParams = params;
        setTimeout(() => {
          this.selectTabFromQuery();
        }, 0);
      }
    );

    this.resumeErrorSubscription = this._talent.resumeErrorObservable.subscribe(
      (errors) => {
        this.resumeError = !!errors;
      }
    );
  }

  ngAfterViewInit(): void {
    this.tabsetsSubscription = this.tabsets.changes.subscribe(() => {
      this.tabset = this.tabsets.first;
      setTimeout(() => {
        this.selectTabFromQuery();
      }, 0);
    });
  }

  selectTabFromQuery() {
    if (this.manualSelectionOfTabs) {
      return;
    }
    if (!this.tabset && !this.queryParams) {
      // Show list info component after saving new remarks
      this.activeTabId = this.isPipelines ? "list_info" : "process";
      return;
    }
    if (this.queryParams && !this.tabset) {
      const { talent_tab = "" } = this.queryParams;
      this.activeTabId = talent_tab;
      return;
    }

    const { talent_tab = "" } = this.queryParams;
    // console.log( 'talent_tab=', talent_tab);
    if (this.queryParams && !this.tabset) {
      this.activeTabId = talent_tab;
      return;
    }
    const tab = this.tabset.tabs.find((tab) => tab.id.startsWith(talent_tab));
    if (tab) {
      this.activeTabId = tab.id;
      tab.active = true;
      this._url.patch({ talent_tab: this.activeTabId }, false);
    } else {
      if (!this.activeTabId || this.activeTabId == "") {
        this.activeTabId = "process";
      } else {
        this._url.patch({ talent_tab: this.activeTabId }, false);
      }
    }
  }

  checkObservers() {
    this.showClose = this.onClose.observers.length > 0;
    // this.showReject = this.onReject.observers.length > 0;
    this.showReinstate = this.onReinstate.observers.length > 0;
    this.showShortlist = this.onShortlist.observers.length > 0;
  }

  /**
   * Given a profile id and a front office id, fetches the talent profile
   * that matches and adds it to the cache.
   * @param profileID - google profile id
   */
  fetchProfile(profileID: string): void {
    // this._talent.shouldBypassCache = false;
    this._api.getApplicant({ profileID }).subscribe(
      (data) => {
        if (data && data.length > 0) {

          // Last object in Obj.assign is to attach Google profile ID to
          // the talent profile from Elastic since only 24k/2.4m have a val in Elastic.
          this.profile = data[0].clone().apply({ name: profileID });

          // Does data have an isDeleted=true flag? If so, go ahead and show the isDeletedProfile error page
          if (this.profile?.isDeleted) {
            //Remove the prefix (USRGS) and just show the empl id number
            const { idNumber } = UtilityService.splitFrontOfficeId(this.profile.MergedIntoFoEmplID)
            this.mergedProfileId = idNumber;
            return this.isDeletedProfile = true;
          }
          if (this.profile?.isDisqualified) {
            return this.isDisqualifiedProfile = true;
          }

          this._cache.cacheProfile(this.profile.name, this.profile);
          this.handleProfileChange();
        } else {
          this._toast.showToast("talent-detail-pane.not_found", {
            cssClass: ToastClass.DANGER,
          });
          this.isError = true;
        }
      },
      (err) => {
        console.trace(err);
        this._toast.showToast("talent-detail-pane.error_profile", {
          cssClass: ToastClass.DANGER,
        });
        this.isError = true;
      }
    );
  }

  handleProfileChange() {
    this._talentProcess.selectProfile(this.profile);
    if (this.profile?.isDisqualified) {
      return this.isDisqualifiedProfile = true;
    }
    this.setAttachmentsToComponent(this.profile)
    this.getResumePreview(this.profile);
    this.setupContext();
    this.emitScroll();
    this.setWarningIndicator();
    this.setNotificationIndicator();
    this.isLoading = false;
    this.scrollTopDiv.nativeElement.scrollIntoView({ behavior: "smooth" });
    if (
      this.profile.externalId &&
      this.profile.externalId !== this.previousTalentId
    ) {
      this.logViewClientEvent();
    }
    this.previousTalentId = this.profile.externalId;
  }

  setAttachmentsToComponent(profile: any) {
    if (profile && profile.attachments && profile.attachments.length || profile.resume) {
      this.attachments = [];
      let primaryResume: any = {};
      const attachments: any = profile?.attachments?.length
      ? profile.attachments.map((attachment: any)=> {
        const attachmentObj: any = {
          ...attachment
        };
        if (attachment && attachment.path) {
          attachmentObj.fileName = attachment.name
          attachmentObj.isPrimary = false;
          const uploadTime = attachment.upload_time;
          attachmentObj.upload_time = moment(uploadTime).format('MMMM Do YYYY, h:mm a');
          attachmentObj.userComments = attachment.descript;
          attachmentObj.uploadedBy = attachment.attached_by_user;

        }
        return attachmentObj;
      })
      : []
      if (profile && profile.resume && profile.allbirds_metadata.resumeLink) {
      const resumeUrl = Array.isArray(profile.allbirds_metadata.resumeLink)
        ? profile.allbirds_metadata.resumeLink[0]
        : profile.allbirds_metadata.resumeLink;
        const fileName = resumeUrl ? resumeUrl.substring(resumeUrl.lastIndexOf('/') + 1) : '';
        const resumeUploadTime = profile.resumeUpdateTime && profile.resumeUpdateTime.seconds ?
          moment(profile.resumeUpdateTime.seconds * 1000) : profile.allbirds_metadata.resumeLastUpdatedDate ?
          moment(profile.allbirds_metadata.resumeLastUpdatedDate) : moment();
        primaryResume = {
          ...profile.resume,
          path: resumeUrl,
          fileName,
          upload_time: moment(resumeUploadTime).format('MMMM Do YYYY, h:mm a'),
          isPrimary: true,
          category: 'Primary',
          descript: profile.resume.userComments,
          uploadedBy: profile.modifiedByUser
        }
      }

      if (primaryResume.path) {
        this.attachments.push(primaryResume, ...attachments);
      } else {
        this.attachments.push(...attachments);
      }
      // console.log('attachments: ', this.attachments)
      // console.log('profile: ', profile)
    }
  }

  emitScroll() {
    this.infinityScroll.emit({ endIndex: this._paginate.currentIndex });
  }

  getResumePreview(profile: Profile) {
    // console.log("GET_RESUME_PREVIEW: ", profile.name);
    // Setting appropriate local variables depending on if given talent is an application or profile.
    const externalId = profile.externalId;
    this.original_resume_link =
      (profile.allbirds_metadata && profile.allbirds_metadata.resumeLink) || "";

    // If no resume link found, attempt to fetch one by external ID.
    if (!this.original_resume_link) {
      return this._api
        .getResumePreviewByExternalID(externalId)
        .subscribe(
          this.handleResumeResponse.bind(this),
          this.handleResumeError.bind(this)
        );
    }

    // Check the cache in case we already have it on hand.
    if (this._cache.getResumeFromCache(profile)) {
      console.log("GET_RESUME_FROM_CACHE: ", profile.name);
      let profileCacheResume = this._cache.getResumeFromCache(profile);
      console.log('profileCacheResume', profileCacheResume);
      if(this.profile.allbirds_metadata.resumeLink == profileCacheResume.url){
        this.pdf = profileCacheResume.safeUrl;
        console.log('this.pdf', this.pdf);
        return this._paginate.resumeSource.next(this.pdf);
      }
    }

    // Lastly, just get it by the link.
    if (this.original_resume_link) {
      clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        this._paginate.resumeSource.next(null);
        if (this.original_resume_link) {
          this._api
            .getResumePreview(this.original_resume_link)
            .subscribe(
              this.handleResumeResponse.bind(this),
              this.handleResumeError.bind(this)
            );
        }
      });
    }
  }

  handleResumeResponse(response: ArrayBuffer) {
    try {
      // construct a blob and create a trusted url resource
      const file = new window.Blob([response], { type: "application/pdf" });
      // const objectURL = window.URL.createObjectURL(file);
      // const safeurl = this._sanitizer.bypassSecurityTrustResourceUrl(objectURL);
      // this.displayResume(safeurl);
      this.displayResume(file);
      // if we only want to cache then dont update dom
      if (
        this.original_resume_link !== undefined &&
        !this._cache.getResumeFromCache(this.profile)
      ) {
        const id = this.profile && this.profile.name;
        this._cache.cacheResume(id, {
          url: this.original_resume_link,
          filename: UtilityService.extractUrlSuffix(this.original_resume_link),
          // safeUrl: safeurl
          safeUrl: file,
        });
      }
      this._talent.clearResumeErrors();
    } catch (e) {
      console.error("[handleResumeResponse]", e);
      this._talent.setResumeError("fatalError");
    }
  }

  handleResumeError(error: HttpErrorResponse) {
    console.log("[handleResumeError]", error);
    if (error && error.status) {
      let errorKey = "generic";
      switch (error.status) {
        case 404:
          errorKey = "notFound";
          break;
        case 500:
          errorKey = "fatalError";
          break;
      }
      this._talent.setResumeError(errorKey);
    }
  }

  displayResume(url: Blob) {
    this.pdf = url;
    this._paginate.resumeSource.next(url);
  }

  setupContext() {
    const talent = this.profile;
    switch (this.context) {
      case TALENT_DETAIL_CONTEXT.JOB_DETAILS:
        this.isRejectedTalent = this._shortlist.isRejected(talent);
        this.isShortlistTalent = this._shortlist.isShortlisted(talent);
        this.isHiddenTalent = this._shortlist.isHidden(talent);
        this.showShortlistButtons =
          (!this._shortlist.isShortlisted(talent) ||
            !this._shortlist.isHidden(talent)) &&
          this.contextOptions.activeTab === "POTENTIAL";
        this.showStatusBanners =
          this.contextOptions &&
          this.contextOptions.activeTab &&
          this.contextOptions.activeTab === "POTENTIAL";
        this.isListDetailsPage = false;
        this.showLogInterview = false;
        break;
      case TALENT_DETAIL_CONTEXT.TALENT_LISTING:
        this.isRejectedTalent = this._shortlist.isRejected(talent);
        this.isShortlistTalent = !!(
          this.talent &&
          this.talent.isApplication() &&
          this.talent.randstad_process.lastProcessStep
        );
        this.showStatusBanners = false;
        this.showShortlistButtons = true;
        this.showLogInterview = false;
        this.isListDetailsPage = false;
        this.activeTabId = "process";
        this.showReinstate = true;
        break;
      case TALENT_DETAIL_CONTEXT.LIST_DETAILS:
        this.isRejectedTalent = false;
        this.isShortlistTalent = false;
        this.showStatusBanners = false;
        this.showShortlistButtons = false;
        this.showFOTalentStatus = true;
        this.showPersonalDetailsOption = true;
        this.isListDetailsPage = true;
        this.showLogInterview = true;
        this.activeTabId = this.isListDetailsPage ? "list_info" : "process"; // Checking if this will fix the problem of tab not loading
        break;
      case TALENT_DETAIL_CONTEXT.TALENT_SEARCH:
      // TODO: Add context options depending on if buttons need to be shown.
      case TALENT_DETAIL_CONTEXT.GENERAL:
        this.isTalentSearchPage = true;

        // defaults moved from below to add explicit end
        this.isRejectedTalent = false;
        this.isShortlistTalent = true;
        this.showStatusBanners = false;
        this.showShortlistButtons = false;
        this.showLogInterview = true;
        break;
      default:
        // These settings make it so that nothing appears by default.
        this.isRejectedTalent = false;
        this.isShortlistTalent = true;
        this.showStatusBanners = false;
        this.showShortlistButtons = false;
        this.showLogInterview = true;
    }
    const trShortlist = this._translate.instant("talent-detail-pane.shortlist");
    const trReinstate = this._translate.instant("talent-detail-pane.reinstate");
    const trAddJob = this._translate.instant("talent-detail-pane.add_job");
    const trList = this._translate.instant("talent-detail-pane.add_list");
    const trLogInterview = this._translate.instant(
      "talent-detail-pane.log-interview"
    );
    const schedAppt = "schedule appointment or interview"; // TODO: Keith Translate
    this.actions = [
      !this.isListDetailsPage &&
        this.showShortlist &&
        !this.isShortlistTalent &&
        !this.isRejectedTalent &&
        !this.isHiddenTalent && {
          label: trShortlist,
          onClick: () => this.openDupTalentModal('shortlist') //this.emitShortlist(this.profile),
        },
      !this.isListDetailsPage &&
        this.showReinstate &&
        this.isRejectedTalent && {
          label: trReinstate,
          onClick: () => this.emitReinstate(),
        },
      {
        label: trAddJob,
        onClick: () => this.openDupTalentModal('addToJob'),
      },
      {
        label: trList,
        onClick: () => this.openAddToAListModal(),
      },
      !(this.userLob.checkLob('CR')) && this.isListDetailsPage && {
        label: schedAppt,
        onClick: this.openScheduleInterviewModal.bind(this),
      },
      !(this.userLob.checkLob('CR')) && this.isTalentSearchPage && {
        label: schedAppt,
        onClick: this.openTalentScheduleInterviewModal.bind(this),
      },
      this.showLogInterview && {
        label: trLogInterview,
        onClick: () => this.openLogInterviewModal(),
      },
    ].filter((v) => v);
  }

  openJobInfoModal() {
    if (!this.jobInfo) {
      return;
    }
    const initialState = {
      jobId: this.jobInfo.allbirds_metadata.allbirds_job_id,
    };
    this.bsModalRef = this._modal.show(JobDetailsModalComponent, {
      initialState,
    });
    this.bsModalRef.setClass("modal-sm");
  }

  emitClose() {
    this._url.patch({ st: null }, false);
    this.onClose.emit({});
  }

  emitShortlist(profile: Profile) {
    this.onShortlist.emit(profile);
  }

  openNotAFitModal(evt: MouseEvent) {
    const initialState = {
      talentProfile: this.profile,
      emitReject: this.emitReject.bind(this),
    };
    this.bsModalRef = this._modal.show(NotAFitModalComponent, { initialState });
    this.bsModalRef.setClass("modal-sm");
  }

  /**
   * Reject this talent
   * @param profile {any} talentProfile
   * @deprecated?
   */
  emitReject(evtObj: RejectEvent) {
    // Don't allow if job has no FO id.
    if (
      !this.jobActive &&
      this.context !== TALENT_DETAIL_CONTEXT.TALENT_LISTING
    ) {
      return this._utility.launchFeatureUnavailableModal(
        "feature-unavailable.JOB_NO_FO_ID"
      );
    }
    this.onReject.emit(evtObj);
    const application: Application = {} as Application;
    application.randstad_process.apply({
      rejected: true,
      rejectedTimestamp: moment(),
      rejectedByAgentID: this._auth.user.BackOfficeID,
      rejectReason: evtObj.reason[0],
      rejectNote: evtObj.note,
    });
    const mentions = UtilityService.getMentionsRecipients(evtObj.note);
    this.sendNotifications(mentions, evtObj.note, application);
  }

  sendNotifications(
    recipients: EmailRecipient[],
    comment: string,
    application: Application
  ) {
    const notificationBody: NotificationData = {
      notificationType: NotificationTypes.OFFER_REJECTED,
      notificationObject: {
        title: this.jobOrder.internalTitle,
        candidateFullName: `${this.profile.personNames[0].structuredName.givenName} ${this.profile.personNames[0].structuredName.familyName}`,
        published_by_user: this.jobOrder.allbirds_metadata.published_by_user,
        published_by_user_email: this.jobOrder.allbirds_metadata
          .published_by_user_email,
        allbirds_job_id: this.jobOrder.allbirds_metadata.allbirds_job_id,
        executing_action_user: this._auth.user.FullName,
        executing_action_user_email: this._auth.user.EmailAddr,
        customer_name: this.jobOrder.allbirds_metadata.customer_name,
        front_office_id: this.jobOrder.allbirds_metadata.front_office_id,
        user: { ...this._auth.user },
        emailRecipients: recipients,
        // noteType: this.getFriendlyNoteType(noteType),
        noteComment: comment,
        contextUrl: this._utility.getContextUrl(
          location.pathname,
          location.search
        ),
        applicationObject: application,
        vms_req_id: this.jobOrder.allbirds_metadata.vms_req_id,
        hiringManager: this.jobOrder.hiringManager
      },
    };

    // this._loading.show();
    const sub = this._notificationService
      .sendNotification(notificationBody)
      .pipe(
        catchError((err) => {
          console.error(err);
          // this._loading.hide();
          return err;
        })
      )
      .subscribe(() => {
        sub.unsubscribe();
        // this._loading.hide();
      });
  }

  emitReinstate() {
    // Don't allow if talent is not in same OPCO as user.
    if (!this._auth.isOpco(this.profile.externalId)) {
      return this.launchCrossOpcoModal();
    } else {
      // Emit to parent component.
      this.onReinstate.emit({
        application:
          this.talent && this.talent.isApplication
            ? (this.talent as Application)
            : null,
        profile: this.profile || null,
      });
      // this.onReinstate.emit(this.profile);
      this._metrics.addEventToQueue(this.profile, "talent was reinstated");
    }
  }

  logViewClientEvent() {
    // Only log Google client event if we're on the Job Details page.
    // if (this.profile && this.context === TALENT_DETAIL_CONTEXT.JOB_DETAILS) {
    //   this._clientEvent.addEventToQueue(this.profile, CLIENT_EVENT_TYPES.VIEW);
    // }
    // Internal metrics logging.
    const talent = this.talent;
    if (talent) {
      talent.index = this._paginate.currentIndex || 0;
      talent.score = this._paginate.currentTalent.score;
      talent.likelyAvailable =
        // TODO(jason): why typescript doesn't narrow down the type?
        this._paginate.currentTalent.isGoogleProfile() &&
        (<GoogleProfile>this._paginate.currentTalent).availability
          ? (<GoogleProfile>this._paginate.currentTalent).availability.available
          : null;
      this._metrics.addEventToQueue(
        talent,
        this.talent.isApplication()
          ? "talent profile was viewed (process tab)"
          : "talent profile was viewed (resume tab)"
      );
    }
    // console.log('talent: ', talent);
  }

  onSelect($event: TabDirective) {
    const talent_tab = $event.id.replace(new RegExp("[0-9]"), "");
    if (this.activeTabId !== $event.id) {
      this._url.patch({ talent_tab: $event.id }, false);
    }
    this._metrics.addEventToQueue(
      this.profile,
      `talent profile was viewed (${talent_tab} tab)`
    );
    this.activeTabId = $event.id;
    this.manualSelectionOfTabs = true;
  }

  openLogNoteModal() {
    // Don't allow if talent is not in same OPCO as user.
    // if (!this._auth.isOpco(this.talentProfile.externalId)) {
    //   return this.launchCrossOpcoModal();
    // }
    if (this.disabled) {
      return;
    }
    // const initialState = { talent: this.profile };
    this.openDupTalentModal('logNote');
  }

  triggerLogNote(profile: any){
    const initialState = { talent: profile };
    this._modal.show(LogNoteModalComponent, { initialState });
  }

  /**
   * Launches the "Log Interview" modal (interview feedback without a job).
   */
  openLogInterviewModal() {
    this.openDupTalentModal('logInterview');
  }

  triggerLogInterviewModal(profile: any) {
    const initialState: LogInterviewModalConfig = {
      isAppointment: true,
      formValues: { type: "COMPLETED" },
      disableTypeControl: true,
      profileInfo: profile,
      submitHandler: this.handleLogAppointment.bind(this),
    };
    this._talentProcess.selectApplication(undefined);
    this._talentProcess.selectProfile(profile);
    this._modal.show(LogInterviewModalComponent, { initialState });
  }

  /**
   * Submit handler for the LogInterviewModalComponent.
   * @param formValues values from LogInterviewModal form
   */
  handleLogAppointment(formValues: LogInterviewForm): void {
    const activity = ApptInviteService.constructInterviewActivity(
      this.profile,
      this._auth.user,
      formValues,
      this.jobOrder.allbirds_metadata.order_type
    );
    if (this.jobOrder.allbirds_metadata.order_type === 'Pipeline') {
      activity.moOrderId = this.jobOrder.allbirds_metadata.front_office_id;
      activity.moCustomerId = this.jobOrder.allbirds_metadata.customer_id;
      activity.moContactId = this.jobOrder.allbirds_metadata.contact_id;
    }
    const isRGS = this.profile.externalId.abIncludesLob("RGS");
    // activity.activityType is empty for activities we're not logging.
    if (activity.activityType) {
      this.spinner.show();
      const meta = isRGS ? { interviewChannel: formValues.channel } : undefined;
      this._api
        .logTalentActivity(activity, meta)
        .pipe(finalize(() => this.spinner.hide()))
        .subscribe(
          (res: any) => {
            this._toast.showToast("log-interview-modal.logged");
          },
          (err) => {
            this._toast.showToast("log-interview-modal.logging_error", {
              cssClass: ToastClass.DANGER,
            });
          }
        );
    }
  }

  getAddress(profile: Profile) {
    let address = "";
    if (
      profile.addresses &&
      profile.addresses.length > 0 &&
      profile.addresses[0].structuredAddress
    ) {
      address = this._talent.constructAddressString(
        profile.addresses[0].structuredAddress
      );
    }
    return address;
  }

  launchExternalProfile() {
    this._talent.launchExternalProfile(this.userLob, this.profile);
  }

  openViewDetailsModal(): void {
    const initialState = {
      talentStatus: this.profile.allbirds_metadata.FOTalentStatus,
      talentName: `${this.profile.personNames[0].structuredName.givenName} ${this.profile.personNames[0].structuredName.familyName}`,
      talentLocation: this.getAddress(this.profile),
      email: this.profile.emailAddresses[0].emailAddress,
      talentPhoneRecord: this.profile.phoneNumbers,
    };

    this.bsModalRef = this._modal.show(PersonalDetailsModalComponent, {
      initialState,
    });
    this.bsModalRef.setClass("modal-sm");
  }

  editProfile() {
    const uuidRegex = /[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/;
    if (this.talent.isApplication()) {
      const profileID = this.talent.profileId;
      this._api
        .getApplicant({ profileID })
        .toPromise()
        .then((profileArray: Profile[]) => {
          const externalId = profileArray[0].externalId;
          if (externalId && !uuidRegex.test(externalId)) {
            this._router.navigate([`/talent/edit`], {
              queryParams: { talentId: this.talent.profileId, talent: JSON.stringify(profileArray[0])  },
            });
          } else {
            this._utility.launchFeatureUnavailableModal(
              "feature-unavailable.PROFILE_NO_EXTERNAL_ID"
            );
          }
        })
        .catch((e) => {
          console.error(e);
        });
    } else if (
      !this.talent.externalId ||
      uuidRegex.test(this.talent.externalId)
    ) {
      // TODO: need to clean up this code and refactor somethings around the current logic
      const talent: any = { ...this.talent };
      // this.talent can come from application index, then talent's externalId located in randstad_process
      if (
        talent.randstad_process &&
        talent.randstad_process.candidateFrontOfficeID &&
        !uuidRegex.test(talent.randstad_process.candidateFrontOfficeID)
      ) {
        if (!this.talent.lob.includes(this.lob) && !this.talent.lob.includes(this.userLob)) {
          this._utility.launchFeatureUnavailableModal(
            "feature-unavailable.TALENT_LOB_DIFFERENT"
          );
        } else {
          this._router.navigate([`/talent/edit`], {
            queryParams: { talentId: this.talent.profileId, talent: JSON.stringify(this.talent) },
          });
        }
      } else {
        this._utility.launchFeatureUnavailableModal(
          "feature-unavailable.PROFILE_NO_EXTERNAL_ID"
        );
      }
    } else if (!this.talent.lob.includes(this.lob) && !this.talent.lob.includes(this.userLob)) {
      this._utility.launchFeatureUnavailableModal(
        "feature-unavailable.TALENT_LOB_DIFFERENT"
      );
    } else {
      this._router.navigate([`/talent/edit`], {
        queryParams: { talentId: this.talent.profileId, talent: JSON.stringify(this.talent) },
      });
    }
  }

  launchCrossOpcoModal() {
    if (this.userLob.checkLob("RT", "RE")) {
      return this._utility.launchFeatureUnavailableModal(
        "feature-unavailable.X_OPCO_RD_USER"
      );
    } else {
      return this._utility.launchFeatureUnavailableModal(
        "feature-unavailable.X_OPCO_RGS_USER"
      );
    }
  }

  getTalentPhoneNumber() {
    let phoneNumber = "";
    if (this.profile.phoneNumbers && this.profile.phoneNumbers.length) {
      phoneNumber = IterableService.extractPhoneNumber(
        this.profile.phoneNumbers
      );
    }
    return phoneNumber;
  }

   openDupTalentModal(type: string) {
    if (this.disabled) {
      return;
    }
      this.spinner.show();

      
    if (this.profile.isProfile()) {
        const familyName = this.profile.familyName;
        const givenName = this.profile.givenName;
        const lob = this.profile.lob;
        const phoneNumbers = this.profile.candidatePhoneNumber;
        const talentId = this.profile.talentId;
        const emailAddresses = this.profile?.emailAddresses?.length > 0 && this.profile?.emailAddresses[0].emailAddress || [];
        const body = { familyName, givenName, lob, phoneNumbers, talentId, emailAddresses: [emailAddresses] }
        this._api.getDuplicateTalent(body)
            .subscribe(data => {
              console.log('getDuplicateTalent', data);
                this.spinner.hide();
                  if((data.length === 0 || data.length === 1) && type == 'addToJob'){
                    this.openAddToJobModal();
                    return;
                  }
                  if( (data.length === 0 || data.length === 1) && type == 'shortlist') {
                    this.emitShortlist(this.profile);
                    return;
                  }
                  if((data.length === 0 || data.length === 1) && type == 'logNote') {
                    this.triggerLogNote(this.profile);
                    return;
                  }
                  if((data.length === 0 || data.length === 1) && type == 'logInterview') {
                    this.triggerLogInterviewModal(this.profile);
                    return;
                  }
                this.searchResults = {
                    total: data.length
                }
                const selectedProfile = data.find((p) => p.externalId === this.profile.externalId);
                const initialState = { talent: selectedProfile, searchResults:this.searchResults , duplicateTalent: data, type };
                this.bsModalRef = this._modal.show(DupTalentModalComponent, { initialState, class: 'modal-xl' });
                this.bsModalRef.content.triggerShortlistEvent.subscribe((res: any) => {
                  this.emitShortlist(res);
                });
                this.bsModalRef.content.triggerLogNoteEvent.subscribe((res: any) => {
                  this.triggerLogNote(res);
                });
                this.bsModalRef.content.logInterviewEvent.subscribe((res: any) => {
                  this.triggerLogInterviewModal(res);
                });
            });
    }


}

  openAddToJobModal() {
    // const config: ModalOptions<any> = { initialState: { talent: this.profile }, class: 'modal-add-talent-to-job' };
    // !this.disabled ? this._modal.show(AddToJobComponent, config) : null;
    if (this.disabled) {
      return;
    }
    let filters = [];
    filters.push({
      value: this._auth.user._id,
      field: "user",
      type: "string",
      display: `${this._auth.user.FirstName} ${this._auth.user.LastName}`,
    });
    if (this.userLob.checkLob("RGS")) {
      filters = [
        ...filters,
        ...[
          {
            value: "Unfilled",
            field: "allbirds_metadata.order_status",
            type: "string",
            lob: "RGS",
          },
          {'value': 'Client Order', 'field': 'allbirds_metadata.order_type', 'type': 'string'}
        ],
      ];
    } else if (this.userLob.checkLob("RT", "RE")) {
      filters = [
        ...filters,
        ...[
          {
            value: "Accepting Candidates",
            field: "allbirds_metadata.order_status",
            type: "string",
            lob: this.userLob,
          },
          {
            value: "Unqualified",
            field: "allbirds_metadata.order_status",
            type: "string",
            lob: this.userLob,
          },
          {
            value: "Covered",
            field: "allbirds_metadata.order_status",
            type: "string",
            lob: this.userLob,
          },
          {
            value: "On Hold",
            field: "allbirds_metadata.order_status",
            type: "string",
            lob: this.userLob,
          },
          {
            value: this.userLob,
            field: "allbirds_metadata.lob",
            type: "string",
          },
        ],
      ];
    }

    const url = `/talent/add-to-job/search?sourceType=all&filters=${JSON.stringify(
      filters
    )}&profile_id=${this.profile.profileId}`;
    this._router.navigateByUrl(url, { state: { url: this._router.url } });
  }

  openRemoveFromListModal(): void {
    const currentListId = this._route.snapshot.paramMap.get("listId");
    const config: ModalOptions<any> = {
      initialState: { talent: this.profile, listId: currentListId },
    };

    this.bsModalRef = this._modal.show(RemoveFromListModalComponent, config);
    this.bsModalRef.setClass("modal-sm");
  }

  checkIfUserIsInRecruiterInterviewBucketList() {
    return this._route?.snapshot?.url?.[0]?.path === 'recruiter-interview';
  }

  openAddToAListModal(): void {
    const currentId = this._route.snapshot.paramMap.get("listId");
    const config: ModalOptions<any> = {
      initialState: {
        talent: this.profile,
        currentId,
      },
      class: "modal-add-talent-to-job",
    };
    // console.log('opening add to list modal: ', AddToListAModalComponent)
    this.bsModalRef = this._modal.show(AddToListAModalComponent, config);
  }

  openScheduleInterviewModal(): void {
    const appt = this._apptInvite.getAppointmentForTalent(
      this.profile.profileId
    );
    if (this._auth.user.Source === "CR"){
      const initialState = {
        reason:
          "You cannot send an invite as the user/job is from Cella line of business.",
      };
      this.bsModalRef = this._modal.show(FeatureUnavailableModalComponent, {
        initialState,
      });
    } else if (
      appt &&
      appt.appointment.interviewStatus !== InterviewStatus.CANCELLED
    ) {
      const initialState = {
        reason:
          "This action is not available at this time as this talent has an interview scheduling invitation in progress for this list.",
      };
      this.bsModalRef = this._modal.show(FeatureUnavailableModalComponent, {
        initialState,
      });
    } else if (this._auth.user.Source === 'CR') {
      const initialState = {
        reason:
          "This action is not available at this time as the user/job is from Cella line of business",
      };
      this.bsModalRef = this._modal.show(FeatureUnavailableModalComponent, {
        initialState,
      });
    } else {
      const config: ModalOptions<any> = {
        initialState: {
          selectedTalent: [this.profile],
          job: this.jobInfo,
          listId: this.listId,
          operationContext: MASS_OPERATION_CONTEXT.individualEnhancedProfile,
          currentAppointment: appt || null,
        },
        class: "modal-sched-appointment",
      };
      this.bsModalRef = this._modal.show(
        SchedAppointmentModalComponent,
        config
      );
    }
  }

  openTalentScheduleInterviewModal(): void {

    if (this._auth.user.Source === 'CR') {
      const initialState = {
        reason:
          "This action is not available at this time as the user/job is from Cella line of business",
      };
      this.bsModalRef = this._modal.show(FeatureUnavailableModalComponent, {
        initialState,
      });
    } else {
      const config: ModalOptions<any> = {
        initialState: {
          selectedTalent: [this.profile],
          job: this.jobInfo,
          listId: this.listId,
          isMassAddToList: false,
          operationContext: MASS_OPERATION_CONTEXT.individualEnhancedProfile,
          currentAppointment: null,
        },
        class: "modal-add-talent-to-job",
      };
      this.bsModalRef = this._modal.show(
        SchedAppointmentInterviewModalComponent,
        config
      );
    }

  }

  setWarningIndicator(): void {
    // need to convert to unknown because ProfileLabels is an Array and we want to keep also the old customAttributes state
    // because we still use some of them.
    let profileLabels = (this.profile?.customAttributes?.ProfileLabels as unknown as Google.Talent.CustomAttribute[])

    this.showWarning = !!(profileLabels && profileLabels.length && profileLabels.find((profileLabel) => {
      return profileLabel.stringValues.includes('RedFlags');
    }));
  }

  setNotificationIndicator(): void {
    const process = [
      "interview",
      "interviewSchedule",
      "internalSubmission",
      "clientSubmission",
      "clientInterview",
      "offer",
    ];

    this._api
      .getTalentApplication(this.profile.profileId, true)
      .subscribe((app: Application) => {
        if (app) {
          if (process.includes(app.randstad_process?.lastProcessStep)) {
            this.showNotification = true;
          }
        }
      });
    this.showNotification = false;
  }

  // adds attachments to the Talent Detail Pane
  async addOtherAttachments(attachments: any, type: string) {
    try {
      if(this.profile && this.profile.allbirds_metadata && this.profile.allbirds_metadata.elastic_id) {
        this.profile = this.checkForAttachmentsProperty();
        await this.addAttachmentsToProfile(attachments, type);
        this.checkFormatOfcandidateUpdateAndCreateTime();
        this.setUserLOB();
        this.checkEmploymentRecords(this.profile);
        this.addModifiedUser(this.profile);
        this.spinner.show();
        this.editTalentSub = this._api.editTalentProfile({...this.profile}).subscribe((res:any) => {
          this.setAttachmentsToComponent(this.profile);
          this.spinner.hide();
        },(error: any)=>{
          this.spinner.hide();
          console.log(error)
          this._toast.showToastNoTranslation(`An error occurred while trying to attach the document.`, { cssClass: ToastClass.DANGER });
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  addModifiedUser(profile: Profile) {
    this.profile.modifiedByUser = this._auth.user.FullName
    this.profile.modifiedByUserBackOfficeId = this._auth.user.BackOfficeID
    this.profile.modifiedByUserEmail = this._auth.user.EmailAddr
    this.profile.modifiedByUserFrontOfficeId = this._auth.user.Oprid

  }
  checkEmploymentRecords(profile: Profile) {
    profile?.employmentRecords?.forEach((record: any) => {
      record.isCurrent = { value: record?.isCurrent?.value === "True" ? true : false }
    })
  }

  async addAttachmentsToProfile(uploadedAttachments: any, type: any) {
    // to store primary resume if one
    let primaryResume: any;
    // to store current primary resume if new primary added
    let nonPrimaryResume: any = [];
    try {
      if (uploadedAttachments && uploadedAttachments.length) {
        uploadedAttachments.forEach((element: any, index: number) => {
          element.type = uploadedAttachments.type;
          element.attached_by_user = this._auth.user.FullName;
          element.attached_by_user_back_office_id = this._auth.user.BackOfficeID;
          element.attached_by_user_front_office_id = this._auth.user.EmplID;
          element.attached_by_user_email = this._auth.user.EmailAddr;
          element.upload_time = element.upload_time || moment().toISOString();
          element.type = type;
          element.lastUpdatedDateTime = moment().toISOString();
          // for link attachments the file path is the file name
          if (type === 'link') {
            element.fileName = element.path
            element.name = element.path
            this.profile.attachments.unshift(element);
          } else if (type === 'resume' && element.category !== 'Primary') {
            // if file type is resume but category is not primary resume
            // we push resume into a nonPrimaryResume array to keep track
            // and handle later in the method
            nonPrimaryResume.push(element)
          } else if (type === 'resume' && element.category === 'Primary') {
            const currentPrimaryResume = this.attachments.filter((attachment) => attachment.category === 'Primary');
            // if there already is a primary resume remove it from this.attachments (always first element)
            // and save it in nonPrimaryResume array
            if (currentPrimaryResume && currentPrimaryResume.length) {
              const previousPrimaryResume = currentPrimaryResume[0];
              previousPrimaryResume.category = 'Previous Primary Resume';
              previousPrimaryResume.name = previousPrimaryResume.fileName;
              previousPrimaryResume.descript = previousPrimaryResume.userComments;
              previousPrimaryResume.upload_time = moment(this.profile?.resumeUpdateTime?.seconds * 1000).toISOString();
              previousPrimaryResume.type = 'resume';
              previousPrimaryResume.resumeType = 'Previous Primary Resume';
              nonPrimaryResume.push(previousPrimaryResume);
              this.attachments.splice(0, 1)
              primaryResume = element;
            } else {
              primaryResume = element;
            }
          } else {
            this.profile.attachments.unshift(element);
          }
        });
      }
      this.profile.attachments.unshift(...nonPrimaryResume);
      await this.setPrimaryResumeToProfile(primaryResume);
    } catch (error) {
      console.log(error);
    }
  }

  checkForAttachmentsProperty(): Profile {
    return this.profile?.attachments
      ? this.profile
      : {
        ...this.profile,
        attachments: []
      } as Profile
  }

  checkFormatOfcandidateUpdateAndCreateTime() {
    if (this.profile.candidateUpdateTime && moment.isMoment(this.profile.candidateUpdateTime)) {
      this.profile.candidateUpdateTime = dateToTimestamp(this.profile?.candidateUpdateTime.toDate().toISOString());
    }
    if (this.profile.createTime && moment.isMoment(this.profile.createTime)) {
      this.profile.createTime = dateToTimestamp(this.profile?.createTime.toDate().toISOString());
    }
  }

  formatDate(date: any) {
    if (!date) {
      return undefined;
    } else {
      return date.month && date.year
      ? { month: date.month,
        day: date.day,
        year: date.year
      }
      : '';
    }
  }

  async setPrimaryResumeToProfile(primaryResume: any) {
    try {
      // console.log(primaryResume?.parsedData?.payload?.educationRecords)
      if (primaryResume) {
        this.profile.resume = {
          structuredResume: primaryResume.parsedData.payload.resume.structuredResume,
          resumeType: 'OTHER_RESUME_TYPE',
          userComments: primaryResume.descript
        }

        if (primaryResume?.parsedData?.payload?.educationRecords && primaryResume?.parsedData?.payload?.educationRecords.length) {
          const eRecords = primaryResume?.parsedData?.payload?.educationRecords;
          this.profile.educationRecords = eRecords.map((record: any) => {
            // console.log(this.formatDate(record.endDate))
            return {
              schoolInstitutionName: record.schoolName || undefined,
              degreeType: record.structuredDegree && record.structuredDegree.degreeType || undefined,
              startDate: this.formatDate(record.startDate),
              endDate: this.formatDate(record.endDate) || undefined,
              administrativeArea: record.address && record.address.structuredAddress && record.address.structuredAddress.administrativeArea
                ? record.address.structuredAddress.administrativeArea
                : undefined,
              locality: record.address && record.address.structuredAddress && record.address.structuredAddress.locality
                ? record.address.structuredAddress.locality
                : undefined
            }
          });
        }
        this.profile.allbirds_metadata.resumeLink = primaryResume.parsedData.link.path;
        this.profile.resumeUpdateTime = primaryResume?.parsedData?.payload?.resumeUpdateTime;
        // this.profile.educationRecords = primaryResume?.parsedData?.payload?.educationRecords
        this.profile.employmentRecords = primaryResume?.parsedData?.payload?.employmentRecords;
        this.profile.candidateUpdateTime = primaryResume?.parsedData?.payload?.candidateUpdateTime;
        this.profile.createTime = this.profile?.createTime ? dateToTimestamp(this.profile?.createTime?.toDate().toISOString())
        : dateToTimestamp(moment().toDate().toISOString());
        await this.setSkills(primaryResume?.parsedData?.payload);
      }
    } catch (error) {
      console.log(error);
    }

  }

  async setSkills(resume: any) {
    let currentSkills: any[] = [];
    // filters out empty skill objects
    if (resume && resume.skills && resume.skills.length) {
      const formattedSkills = resume.skills.filter((skills: any) => {
        return skills.displayName;
      });
      if (formattedSkills.length) {
          currentSkills = this.profile?.skills?.length ? this.profile.skills : [];
        if (this.lob === 'RT') {
          const skillsObj: any = await this._suggestService.getBullhornSkills(formattedSkills, this.userLob).toPromise()
          if (skillsObj) {
            this.profile.skills = this.appendSkills(skillsObj.skills, currentSkills);
          }
        } else if (this.userLob?.checkLob('RGS')) {
          this.profile.skills = this.appendSkills(formattedSkills, currentSkills);
        }
      }
    }
  }

  // takes in an array of skills and appends them to
  // talents existing skills
  appendSkills(skills: any[], currentSkills: any[]): any[] {
    let bigArray: any[];
    let smallArray: any[];
    if (skills.length > currentSkills.length) {
      bigArray = skills;
      smallArray = currentSkills;
    } else {
      bigArray = currentSkills;
      smallArray = skills;
    }

    // function logic passed to reduce method
    const appendSkills = (a: any[], c: any) => {
      const found = smallArray.find((skill: any) => {
        if (skill.bullhorn_skill
          && skill.displayName === c.displayName
          && skill.specialty !== c.specialty) {
          return;
        } else {
          return skill.displayName === c.displayName;
        }
      });
      if (found === undefined) {
        smallArray.push(c);
      }
      a = smallArray;
      return a;
    };

    // if there are existing skills separateAndSortSkills called
    return currentSkills.length
      ? this.separateAndSortSkills(bigArray.reduce(appendSkills, []))
      : bigArray.reduce(appendSkills, []);
  }

  // separates out the bullhorn skills and regular skills
  // sorts them individually alphabetically, then concats
  // them together with bullhorn skills first.
  separateAndSortSkills(skills: any[]): any[] {
    const bullhornSkills = skills.filter((skill: any) => skill.bullhorn_skill);
    const otherSkills = skills.filter((skill: any) => !skill.bullhorn_skill);

    const sortSkills = (a: any, b: any) => {
      const nameA = a.displayName;
      const nameB = b.displayName;
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    };

    return bullhornSkills.sort(sortSkills).concat(otherSkills.sort(sortSkills));
  }

  setUserLOB() {
    const userSource = this._auth.user && this._auth.user.Source;
    if (userSource.checkLob('RT', 'RE','CR')) { this.profile.userLob = 'RT'; } else if (userSource.checkLob('RGS')) { this.profile.userLob = 'RGS'; }
  }

  openAttachmentOptionModal() {

    if (this.isEditable) {
      const initialState: any = {
        addAttachments: this.addOtherAttachments.bind(this),
        attachments: this.attachments
      };
      this._bsModalRef = this._modal.show(AttachmentOptionsModalComponent, {initialState});
      this._bsModalRef.setClass('modal-sm');
    }
  }

  openAttachmentModal(linkOrAttachment: string) {
    const categories = [
      {name: 'Company Info'},
      {name: 'Contract'},
      {name: 'Fee Agreement'},
      {name: 'Other'},
      {name: 'Vendor Details'},
      {name: 'Benefits'},
      {name: 'Client Onboarding, and Right to Represent'}
    ];
    if (linkOrAttachment === 'link') {
      const initialState: any = {
        save: this.addOtherAttachments.bind(this),
        categories: categories,
        attachments: this.attachments,
        additionalParameters: {otherFile: true, talentDetailsPane: true},
        allowDuplicate: false
      };
      this._bsModalRef = this._modal.show(AddUrlModalComponent, { initialState});
      this._bsModalRef.setClass('modal-sm');
    } else {
      const initialState: any = {
        save: this.addOtherAttachments.bind(this),
        categories: categories,
        WindowClass: 'modal-attachment',
        attachments: this.attachments,
        additionalParameters: {otherFile: true, talentDetailsPane: true},
        // JDS - removed file type validation temporarily to unblock chromebook users
        // allowedMimeTypes: this.allowedMimeTypes,
        allowDuplicate: false,
        uploadURL: this._api.uploadURL(),
        parseResume: false,
        isTalentCreation: false
      };
      this._bsModalRef = this._modal.show(AddFilesModalComponent, { class: 'modal-attachment', initialState  });
    }
  }

  async deleteAttachment(attachment: any, index: number) {
    try {
      if (attachment && attachment.path) {
        let newProfile: any = {};

        if (attachment.type !== 'link') {
          const res: any = await this._api.deleteFile(attachment.path).toPromise()
          .then((res: any)=> {
            if (res && res.deleted) {
              this.attachments.splice(index,1);
              this.profile.attachments.splice(index-1, 1);
              this.setUserLOB();
            }
          })
        } else {
          this.attachments.splice(index,1);
          this.profile.attachments.splice(index-1, 1);
          this.setUserLOB();
        }

        this.profile.createTime = moment.isMoment(this.profile.createTime)
          ? dateToTimestamp(this.profile?.createTime.toDate().toISOString())
          : this.profile.createTime
        this.profile.candidateUpdateTime = moment.isMoment(this.profile.candidateUpdateTime)
          ? dateToTimestamp(this.profile?.candidateUpdateTime.toDate().toISOString())
          : this.profile.candidateUpdateTime
        newProfile = {
          ...this.profile
        }
        const res = await this._api.editTalentProfile(newProfile).toPromise()
          if (res) {
            this._toast.showToastNoTranslation(`The attachment was deleted.`, { cssClass: ToastClass.SUCCESS })
          }
      }
    } catch (error) {
      this._toast.showToastNoTranslation(`An error occurred while trying to delete the attachment.`, { cssClass: ToastClass.DANGER })
    }

  }

  checkTalentLob() {
    this.isEditable = this.talent.lob.includes(this.lob);
  }

  // setWarningIndicator(): void {
  //   this.showWarning = this.profile && this.profile.customAttributes && this.profile.customAttributes.RedFlags &&
  //     (this.profile.customAttributes.RedFlags.stringValues.includes('Tier 1') ||
  //       this.profile.customAttributes.RedFlags.stringValues.includes('Tier 2'));
  //   // console.log('ATTRIBUTES: ', this.profile.customAttributes.RedFlags);
  // }
}
