<div class="form-group">
  <label [for]="inputId">{{ label }}</label>
  <input type="text"
         class="form-control"
         [id]="inputId"
         placeholder="Enter one or more email addresses (comma separated)"
         [(ngModel)]="input"
         (keypress)="handleKeypress($event)"
         (blur)="handleBlur($event)"
         autocomplete="offoff"
  />
  <form-error [fieldAlias]="label + ' field'" [control]="control"></form-error>
</div>

<ul class="hz-btn-list">
  <li *ngFor="let email of control?.value">
    <button class="btn btn-sm btn-outline-primary">
      {{ email }}
      <i class="icon-WL_icon_close text-primary" (click)="handlePillClick(email)"></i>
    </button>
  </li>
</ul>
