<button [id]="id"
        [attr.data-puppeteer]="id"
        [class]="getCssClass()"
        [class.disabled]="state && state.disabled"
        [tooltip]="state && state.disabledMsg"
        [isDisabled]="state && !state.disabled"
        [hidden]="state && state.hidden"
        (click)="attemptExecute($event)">
  <ng-content></ng-content>
</button>
