import { MASS_ACTION_TYPE } from './../mass-email-modal/mass-operation-context';

import { Component, OnInit, ViewChild  } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FormValidationService } from 'src/app/shared/services/form-validation/form-validation.service';
import { ModalService } from 'src/app/shared/services/modal/modal.service';

import { ApiService } from '../../services/api/api.service';

import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { ToastService, ToastClass } from 'src/app/shared/services/toast';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { EmailInput, EmailIssueInfo } from 'src/app/shared/models/internal/mass-email.model';
import { MassOperationRecipientService, TextRecipients } from '../mass-email-modal/mass-operation-recipient.service';
import { Router } from '@angular/router';
import { MASS_OPERATION_CONTEXT } from '../mass-email-modal/mass-operation-context';
import { MassSms } from '../../models/internal/mass-sms';
import { Subscription } from 'rxjs';
import {LoadingSpinnerService} from '../../services/loading-spinner/loading-spinner.service';
import { MassEmail } from '@allbirds-ui/allbirds-types';



@Component({
  selector: 'app-mass-sms-modal',
  templateUrl: './mass-sms-modal.component.html',
  styleUrls: ['./mass-sms-modal.component.scss']
})
export class MassSmsModalComponent implements OnInit {
  //enhanced profile or profile or application
  context: MASS_OPERATION_CONTEXT;

  // FormGroup where we actually pull the values for the request from.
  smsForm: FormGroup;

  //From email
  fromEmail: string;

  //Email that are selected in bulk from the backend, final list after removing problematic ones
  finalEmailList : EmailInput[] = [];

  //Enables getting recipient data of the user easily as a map
  textRecipientMap: Map<string, MassSms.RecipientData> = new Map<string,  MassSms.RecipientData>();

  //Emails which cant be sent due to some problem
  problemusers: EmailIssueInfo[] = [];

  //Show Pills on the modal. Start with false
  showPills: boolean = false;

  //Show the cant mail list of users
  showCantMailDetails: boolean = false;
  cantMailVerbiage: string = "Show details";
  trVariable1: any;

  isBusy: boolean = false;

  exceedingCount: number;

  smsCharsLeft = 160;
  maxCharLength = 160;
  smsSub: Subscription;

  @ViewChild('childModal') childModal: ModalDirective;
  errorModalTitle: string;
  errorModalDesc: string;
  maxEmails: number = 0;


  constructor(
    public _bsModalRef: BsModalRef,
    private _fb: FormBuilder,
    private _bsModalService: ModalService,
    private _api: ApiService,
    private _auth: AuthService,
    private _toast: ToastService,
    private _recipientService: MassOperationRecipientService,
    private _router: Router,
    private _loading: LoadingSpinnerService
    ) { }

  ngOnInit() {
    this.init();
    this.setupForm();
  }

  ngOnDestroy() {
    this.smsSub.unsubscribe();
    this._recipientService.clearSelections();
  }

  togglePills() {
    this.showPills = false;
  }

  async init() {
    // let t0 = performance.now();
    this._loading.show();
    const result =  await this._recipientService.initialize(this.context, MASS_ACTION_TYPE.text);
    // let t1 = performance.now();
    // console.log("_recipientService" + (t1 - t0) + " milliseconds.");

    const textResult = result as TextRecipients;
    this.finalEmailList = textResult.emailList;
    this.textRecipientMap = textResult.recipients;
    this.problemusers = textResult.issue;
    this.fromEmail = this._auth && this._auth.user && this._auth.user.EmailAddr || "";
    //this.trVariable1 = {"value" : this.problemusers.length}
    // console.log('this problem users' ,  this.problemusers);
    this.setupForm();
    this.calculateCharsLeft();
    this.trVariable1 = {"value" : this.problemusers.length};
    this._loading.hide();
  }

  setupForm(): void {
    // console.log('setupForm called in massSms');
    // console.log('this final email list before filter' , this.finalEmailList);
    // let t0 = performance.now();
    this.finalEmailList = this.finalEmailList.filter(user => !this.isProblemUser(user.email));
    // let t1 = performance.now();
    // console.log("isProblemUser" + (t1 - t0) + " milliseconds.");
    // console.log('this final email list after filter' , this.finalEmailList);
    this.smsForm = this._fb.group({
      from: [this._auth.user.EmailAddr, [Validators.required, FormValidationService.validateNonEmptyString]],
      to: [this.finalEmailList, [Validators.required, FormValidationService.validateNonEmptyArray]],
      textmsg: ['', [Validators.required, FormValidationService.validateNonEmptyTrimmedString]],
    });
  }

  isProblemUser(email: string): boolean {
    for (let user of this.problemusers) {
      if (email === user.email) {
        return true;
      }
    }

    return false;
  }

  handleExpandPill(expandPill: boolean) {
    this.showPills = true
  }

  hideChild() {
    this.childModal.hide();
  }

  showDetailCantMail() {
    this.showCantMailDetails = !this.showCantMailDetails;
    if(this.showCantMailDetails) {
      this.cantMailVerbiage = "Hide details";
    }
    else {
      this.cantMailVerbiage = "Show details";
    }

  }

  calculateCharsLeft(): void {
    this.smsSub = this.smsForm.controls.textmsg.valueChanges
      .subscribe(val => {
        // this.smsForm.controls.textmsg.value
        this.smsCharsLeft = this.maxCharLength - val.length;
        if(this.smsCharsLeft <= 0) {
          FormValidationService.setError(this.smsForm.controls.textmsg, 'mustBeShorter');
        }
      }, err => console.error(err));


  }

  submitMassSms() {
    //console.log('submitMassEmail');

    FormValidationService.touchAllFields(this.smsForm);
    const textMessage = JSON.stringify(this.smsForm.controls.textmsg.value.trim());
    if (textMessage === '') {
      FormValidationService.setError(this.smsForm.controls.textmsg, 'shouldBeUpdated');
      this.isBusy = false;
    }
    if (this.smsCharsLeft <= 0 ) {
      FormValidationService.setError(this.smsForm.controls.textmsg, 'mustBeShorter');
      this.isBusy = false;
    }


    if (this.smsForm.valid) {
      let maxEmails = 1000;
      const curSelectedEmails: EmailInput[] = Array.from(this.smsForm.controls['to'].value);
      //console.log(this.emailForm.controls['to'].value, "size =", this.emailForm.controls['to'].value.length);
      this.exceedingCount = curSelectedEmails.length - maxEmails;
      if(curSelectedEmails.length > maxEmails) {
        this.errorModalTitle = 'Selection limit exceeded';
        this.errorModalDesc = 'The maximum limit of ' + this.maxEmails + ' candidates for mass email ' +
          'emailing has been exceeded by ' + this.exceedingCount;
        this.childModal.show();
        return;
      }
      else {
        this.isBusy = true;
        const body: MassSms.MassSmsRequest = this.getRequestBody(this.smsForm.controls.textmsg.value);
        this._api.postMassText(body).subscribe(
          res => {
            const trVariable = { 'value': curSelectedEmails.length};
            this._toast.showToastWithVariables ('mass-sms.success', trVariable);
            this._bsModalRef.hide();
            this.isBusy = false;
          },
          error => {
            const trVariable = { 'value': error};
            this._toast.showToastWithVariables ('mass-sms.failure', trVariable, { cssClass: ToastClass.DANGER });
            this._bsModalRef.hide();
            this.isBusy = false;
          }

        );
      }

    }



  }

  getRequestBody(textContent : string) {
    const curSelectedEmails: EmailInput[] = Array.from(this.smsForm.controls['to'].value);
    const curRecipientData: MassSms.RecipientData[] = [];
    curSelectedEmails.forEach(inp =>{
      curRecipientData.push(this.textRecipientMap.get(inp.email));
    });


    let context = <MassEmail.MassOperationContext> <unknown> this.context;


    let req: MassSms.MassSmsRequest = {
      'body': textContent,
      'senderData': {
        'email': this._auth.user.EmailAddr,
        'oprId': this._auth.user._id,
        'firstName': this._auth.user.FirstName,
        'lastName': this._auth.user.LastName,
        'lob': this._auth.user.Source,
        'businessUnit': this._auth.user.BusinessUnit,
        'phoneNumber': this._auth.user.PrimaryPhone
      },
      'recipientData' : curRecipientData,
      'context': context
    };

    return req;

  }


  handleEmailInput(control: AbstractControl, input: string): void {
    FormValidationService.clearErrors(control);
    control.patchValue((control.value && control.value.length)
      ? control.value.concat([input])
      : [input]
    );
  }

  handleEmailRemove(control: AbstractControl, input: string): void {
    control.patchValue((control.value && control.value.length)
      ? control.value.filter((email: string) => email !== input)
      : []
    );

  }


}
