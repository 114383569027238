import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { JobOrderService } from 'src/app/shared/services/job-order/job-order.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { ToastClass, ToastService } from 'src/app/shared/services/toast';
import { Job } from '../../../../../shared/models/external/job.model';
import { User } from '../../../../../shared/models/external/user.model';
import { ApiService } from '../../../../../shared/services/api/api.service';
import { JobForm } from '../../../../../shared/models/external/job-form.model';
import moment from 'moment';
import { NotificationData, NotificationTypes } from 'src/app/shared/models/internal/notifications-data.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-assign-recruiter-modal',
  templateUrl: './assign-recruiter-modal.component.html',
  styleUrls: ['./assign-recruiter-modal.component.scss']
})
export class AssignRecruiterModalComponent implements OnInit {
  title = '';
  customer_name = '';
  front_office_id = '';
  customer_priority?: number;
  jobData: any;
  assign: any;
  recruiters: User[] = [];
  searchTerm = '';
  loading = false;
  selectedRecruiter: User;
  elasticJobOrder: Job; /* The Job The Way It Is In Elastic */
  formJobOrder: JobForm; /* The Job The Way It Is In The Form */
  saving = false;
  currentRecruiter: string;

  constructor(
    private _api: ApiService,
    public _bsModalRef: BsModalRef,
    private jobOrderService: JobOrderService,
    private toastService: ToastService,
    private translate: TranslateService,
    private _notificationService: NotificationsService,
    private _auth: AuthService
  ) {}

  get userLob (): string { return this._auth.user && this._auth.user.Source; }

  ngOnInit() {
    this.currentRecruiter = this.jobData && this.jobData.allbirds_metadata ? this.jobData.allbirds_metadata.assigned_to_user : '';
    this.setData(this.jobData);
    this.setElasticJobOrder(this.jobData);
  }

  setElasticJobOrder = (job: Job) => {
    this.elasticJobOrder = job;
    if (this.elasticJobOrder && this.elasticJobOrder.allbirds_metadata) {
      this._api.getJob(this.elasticJobOrder.allbirds_metadata.allbirds_job_id, true).toPromise().then(this.setFormJobOrder);
    }
  };

  setFormJobOrder = (jobForm: JobForm) => {
    this.formJobOrder = jobForm;
  };

  setData(jobData: Job) {
    this.searchTerm = '';
    if (jobData) {
      if (jobData.internalTitle) {
        this.title = jobData.internalTitle;
      }

      if (jobData.allbirds_metadata) {
        this.customer_name = jobData.allbirds_metadata.customer_name || '';
        this.customer_priority = jobData.allbirds_metadata.customer_priority;
        this.front_office_id = jobData.allbirds_metadata.front_office_id;
      }
    }
  }

  setRecruiter(recruiter: User) {
    this.selectedRecruiter = recruiter;
    this.searchTerm = `${recruiter.FirstName} ${recruiter.LastName} (${recruiter.EmailAddr})`;
  }

  assignJob = (recruiter: User) => {
    if (!recruiter) {
      /* Ignore the assignment if there is no recruiter */
      return;
    }
    this.saving = true;

    const changedFields: string[] = [];
    if (this.isFirstAssignment(this.formJobOrder)) {
      this.formJobOrder.job_expire_date = moment().add(15, 'days');
      this.elasticJobOrder.allbirds_metadata.job_expire_date = this.formJobOrder.job_expire_date;
      changedFields.push('job_expire_date');
    }
    this.formJobOrder.assigned_to_user = `${recruiter.FirstName} ${recruiter.LastName}`;
    this.formJobOrder.assigned_to_user_back_office_id = recruiter.BackOfficeID;
    this.formJobOrder.assigned_to_user_email = recruiter.EmailAddr;
    this.formJobOrder.assigned_to_user_front_office_id = recruiter.Oprid;

    this.elasticJobOrder.allbirds_metadata.assigned_to_user = `${recruiter.FirstName} ${recruiter.LastName}`;
    this.elasticJobOrder.allbirds_metadata.assigned_to_user_back_office_id = recruiter.BackOfficeID;
    this.elasticJobOrder.allbirds_metadata.assigned_to_user_email = recruiter.EmailAddr;
    this.elasticJobOrder.allbirds_metadata.assigned_to_user_front_office_id = recruiter.Oprid;

    changedFields.push('assigned_to_user');
    changedFields.push('assigned_to_user_back_office_id');
    changedFields.push('assigned_to_user_email');
    changedFields.push('assigned_to_user_front_office_id');
    this._api.updateJob(this.formJobOrder, changedFields)
      .subscribe(() => this.publishJob(changedFields), err => {
          this.saving = false;
          const trError = this.translate.instant('assign-recuiter-modal.assign_recuiter');
          this.toastService.showToastNoTranslation(trError, { cssClass: ToastClass.DANGER });
          // alert('Assign Recruiter Update Failed');
          console.log('job update failed.', err);
        }
      );
  };

  publishJob = (changedFields: string[]) => {
    const interviewObj = this.jobOrderService.getInterviewObject(this.elasticJobOrder, this.selectedRecruiter);
    this._api.publishJob(this.formJobOrder.allbirds_job_id, interviewObj, changedFields)
      .subscribe(this.updateJobSuccess, err => {
        console.log(err);

        this.saving = false;
        const trError = this.translate.instant('assign-recuiter-modal.job_republish_failed');
        this.toastService.showToastNoTranslation(trError, { cssClass: ToastClass.DANGER });
        // alert('Job Republish Failed');
        console.log('job republish failed.', err);
      });
  };

  updateJobSuccess = () => {
    const trSuccess = this.translate.instant('assign-recuiter-modal.job_success', {
      'value1': this.selectedRecruiter.FirstName,
      'value2': this.selectedRecruiter.LastName
    });
    this.toastService.showToast(trSuccess, { cssClass: ToastClass.SUCCESS });
    const selectedRecruiterName = `${this.selectedRecruiter.FirstName} ${this.selectedRecruiter.LastName}`;
    this.jobOrderService.storeJobDetails(this.elasticJobOrder);
    this.saving = false;
    this.searchTerm = '';
    this._bsModalRef.hide();
    if (this.jobData && this.front_office_id) {
      const {
        published_by_user,
        published_by_user_email
      } = this.jobData.allbirds_metadata;
      if (
        this.userLob.checkLob('RT', 'RE',"CR") &&
        selectedRecruiterName !== this.currentRecruiter
      ) {
        const emailRecipients = [
          {
            recipient: selectedRecruiterName,
            recipientEmail: this.selectedRecruiter.EmailAddr
          },
          {
            recipient: published_by_user,
            recipientEmail: published_by_user_email
          }
        ].filter(r => r.recipient !== this._auth.user.FullName);
        const notificationObject: NotificationData = {
          notificationType: NotificationTypes.JOB_ASSIGNMENT_MADE,
          notificationObject: {
            title: this.title,
            assigned_to_user: selectedRecruiterName,
            assigned_to_user_email: this.selectedRecruiter.EmailAddr,
            published_by_user,
            published_by_user_email,
            front_office_id: this.front_office_id,
            customer_name: this.customer_name,
            allbirds_job_id: this.jobData.allbirds_metadata.allbirds_job_id,
            executing_action_user: this._auth.user.FullName,
            executing_action_user_email: this._auth.user.EmailAddr,
            user: {...this._auth.user},
            emailRecipients,
            vms_req_id: this.jobData.allbirds_metadata.vms_req_id,
            hiringManager: this.jobData.hiringManager
          }
        };
        const sub = this._notificationService
          .sendNotification(notificationObject)
          .subscribe(() => {
            sub.unsubscribe();
          });
      }
    }
  }

  isFirstAssignment = (formJob: any): boolean => {
    const assigned_to_user = (formJob.assigned_to_user || '').trim();
    return (
      (!assigned_to_user && formJob.assigned_to_user_flag === false) || // new job F-A =first assignment
      (assigned_to_user && formJob.assigned_to_user_flag === false) || // new job but assignment failed /F-A
      (!assigned_to_user && formJob.assigned_to_user_flag === null) // old job not assigned /F-A
    );
  }
}
