<ng-template #pageTemplate let-page let-disabled="disabled" let-currentPage="currentPage">
  <div class="d-flex justify-content-center" style="width: 17px!important;">
    {{ page.number}}
  </div>
</ng-template>

<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
  <ng-container>
    <i class="icon icon-WL_icon_chevron-left mr-3 color-primary"></i>
  </ng-container>
</ng-template>

<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
  <ng-container>
    <i class="icon icon-WL_icon_chevron-right mr-3 color-primary"></i>
  </ng-container>
</ng-template>


<div *ngIf="loading">
  <div class="w-100 text-center">
    <div class=" mt-4 pt-4 spinner-border text-primary mx-auto text-center" role="status">
      <span class="sr-only text-center">{{'talent-management-search-results.loading' | translate}}</span>
    </div>
  </div>
</div>
<!-- New Model S api POC -->
<div *ngIf="!loading && searchResults && searchResults?.total === 0">
  <div class="mt-5">
    <div class="text-center mt-3">
      <div class="mt-5"><img width="226px" src="/assets/images/ghost.svg" /></div>
      <h3>{{'talent-management-search-results.there_are_currently' | translate}}</h3>
      <p class="gray-text">{{'talent-management-search-results.try_changing_your' | translate}}</p>
    </div>
  </div>
</div>

<div *ngIf="potentialTalents && potentialTalents.length > 0 && enableMassSelect"
  class="results mr-3 mb-1" style="margin-left: 41px">
  <div class="form-check-container">
    <input class="form-check-input" type="checkbox" (click)="selectPaginateTalents()"
    [checked]="massSelectBoxChecked"
    [indeterminate]="checkBoxIndeterminate"/>
    <span class="checkmark" [ngClass]="{ 'disabled': isSelectionDisabled }" [tooltip]="'You have max selection already.'" placement="right"
          [isDisabled]="!isSelectionDisabled"  (click)="selectPaginateTalents()"></span>

  </div>


  <span *ngIf="totalSelected >= 1"> {{totalSelected}} of {{searchResults?.total}} talent</span>
  <span *ngIf="totalSelected == 0">{{ startIndex+ 1}} - {{startIndex + 25 < searchResults?.total ? startIndex + 25 : searchResults?.total}} of  {{searchResults?.total}}</span>
  <span class="faux-link text-decoration-none" style="font-size: 13px" *ngIf="!this.maxSelectionReached" (click)="selectAllTalents()"> max selection </span>
  <span *ngIf="totalSelected >= 1 && !this.maxSelectionReached">- </span>
  <span class="faux-link text-decoration-none" style="font-size: 13px" *ngIf="totalSelected >= 1" (click)="resetAllTalent()"> unselect all</span>

  &nbsp;&nbsp;
  <span class="button-container">
    <img
      class="menu-button-icon"
      src="assets/icons/mail.svg" alt="mail"
      (click)="massEmail()"
      popover="send email" placement="bottom"
      triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark"
     />
  </span>

  <span *ngIf="isRGSLOB == true" class="button-container">
    &nbsp;&nbsp;
    <img
      class="menu-button-icon"
      src="assets/icons/sms.svg" alt="mail"
      (click)="massSMS()"
      popover="send sms/text" placement="bottom"
      triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark"
  />
  </span>
  &nbsp;&nbsp;
  <span class="button-container">
    <img
      class="menu-button-icon"
      src="assets/icons/briefcase-add.svg" alt="mail"
      (click)="massAddToJobClick()"
      popover="add to job" placement="bottom"
      triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark"
     />
  </span>
  &nbsp;&nbsp;
  <span class="button-container">
    <img
      class="menu-button-icon"
      src="assets/icons/list-add.svg" alt="mail"
      (click)="massAddToListClick()"
      popover="add to list" placement="bottom"
      triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark"
  />


  </span>
  &nbsp;&nbsp;
  <span class="button-container" *ngIf="!hideMassSchedAppt">
   <input
    type="image"
    [ngClass]= "disableMassSchedAppt ? 'disable-mass-action': 'menu-button-icon'"
    src="assets/icons/calendar.svg" alt="mail"
    (click)="massScheduleAppointmentClick()"
    [popover]="massSchedApptMsg"
    triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark"/>
  </span>
  &nbsp;&nbsp;
  <div class="d-flex" style="float: right; width: 100px; height: 25px; margin-right: 20px;">
    <pagination [totalItems]="searchResults?.total"
                [customPreviousTemplate]="prevTemplate"
                [customNextTemplate]="nextTemplate"
                [itemsPerPage] = "25"
                [directionLinks]="true"
                [rotate]="true"
                style="width: 200px; height: 50px"
                ngDefaultControl
                [(ngModel)]="paginationCurrentPage"
                [customPageTemplate]="pageTemplate"
                class="my-pagination"
                [maxSize]="1">
    </pagination>
  </div>

</div>

<div *ngIf="!loading">

  <div class="container">
    <div class="row overflow-auto" style="height: 80vh;">
      <div class="col">
        <app-talent-card
          *ngFor="let talent of potentialTalents | slice: startIndex:(startIndex+25); let index = index;"
          [talent]="talent"
          [attr.id]=" 'id_' + talent.externalId"
          [active]="(
        paginate?.currentTalent &&
        paginate?.currentTalent?.externalId &&
        paginate?.currentTalent?.externalId === talent?.externalId
      )"
          [maxSelectionReached] = "maxSelectionReached"
          [shortlistCard]=false
          [isKnownTalent]="isKnownTalentSearchActive"
          [rejected]=false
          (selectTalentEmit)="selectTalent($event)"
          [i]="index"
          (inViewportChange)="onInViewportChange($event, talent.name, talent.externalId, index || 0)"
          snInViewport
          [enableMassSelect]="enableMassSelect"
          [massSelected]= "_massAction.isSelected(talent)"
          (onMassSelect)="handleMassSelect($event)"
          (selectedTalentIdEmit)="storeSelectedTalentId($event)"
          [newRVDate] = "rvTalent?.id === talent.externalId && rvTalent?.newDate"
          [rvTalentSelected] = "rvTalentSelected"
          [currentSelectedTalent]="selectedTalentID">
        </app-talent-card>
      </div>
    </div>
    <div class="row">
      <div class="col d-flex justify-content-center">
        <pagination [totalItems]="searchResults?.total"
                    [customPreviousTemplate]="prevTemplate"
                    [customNextTemplate]="nextTemplate"
                    [itemsPerPage] = "25"
                    (pageChanged)="pageChanged($event)"
                    [directionLinks]="true"
                    [rotate]="true"
                    ngDefaultControl
                    [(ngModel)]="paginationCurrentPage"
                    [customPageTemplate]="pageTemplate"
                    [maxSize]="15">
        </pagination>
      </div>
    </div>
  </div>


<!--  <virtual-scroller-->
<!--    #scroll-->
<!--    [items]="potentialTalents"-->
<!--    (vsEnd)="infinityScroll($event)"-->
<!--    class="card-section mt-4 ml-3 mr-3"-->
<!--    [attr.id]="'cardScrollView'"-->
<!--  >-->
<!--    &lt;!&ndash; talent cards &ndash;&gt;-->
<!--    <app-talent-card-->
<!--      *ngFor="let talent of scroll.viewPortItems; let index = index;"-->
<!--      [talent]="talent"-->
<!--      [attr.id]=" 'id_' + talent.externalId"-->
<!--      [active]="(-->
<!--        paginate.currentTalent &&-->
<!--        paginate.currentTalent.externalId &&-->
<!--        paginate.currentTalent.externalId === talent.externalId-->
<!--      )"-->
<!--      [shortlistCard]=false-->
<!--      [isKnownTalent]="isKnownTalentSearchActive"-->
<!--      [rejected]=false-->
<!--      (selectTalentEmit)="selectTalent($event)"-->
<!--      [i]="index"-->
<!--      (inViewportChange)="onInViewportChange($event, talent.name, talent.externalId, index || 0)"-->
<!--      snInViewport-->
<!--      [enableMassSelect]="true"-->
<!--      [massSelected]= "_massAction.isSelected(talent) || selectedAllTalents == true"-->
<!--      (onMassSelect)="handleMassSelect($event)"-->
<!--      (selectedTalentIdEmit)="storeSelectedTalentId($event)"-->
<!--      [currentSelectedTalent]="selectedTalentID">-->
<!--    </app-talent-card>-->
<!--    &lt;!&ndash; loader for infinity scroll &ndash;&gt;-->
<!--    &lt;!&ndash; New Model S api POC &ndash;&gt;-->
<!--    <div *ngIf="potentialTalents && searchResults && (potentialTalents.length > 0 && searchResults.total > potentialTalents.length)"-->
<!--         class="mt-5 mb-5 text-center">-->
<!--      <div class="w-100 mx-auto text-center">-->
<!--        <div id='results-loading-icon' class="spinner-border text-primary mx-auto text-center" role="status">-->
<!--          <span class="sr-only text-center">{{'talent-management-search-results.loading' | translate}}</span>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </virtual-scroller>-->
</div>
