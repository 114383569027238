import {Component, Input, OnInit} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {AuthService} from '../../../services/auth/auth.service';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-add-url-modal',
  templateUrl: './add-url-modal.component.html',
  styleUrls: ['./add-url-modal.component.scss']
})
export class AddUrlModalComponent implements OnInit {

  urlFormGroup: FormGroup;
  urlFormArray: FormArray;
  save: any;
  categories: any;
  additionalParameters: any;
  attachments: any = [];

  pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  formGroup = {
    path: ['', [Validators.required, Validators.pattern(this.pattern), this.duplicateValidator()]],
    category: ['', Validators.required],
    descript: ['']
  };

  urls = [{'path': '' , 'category': '' }];

  constructor( public _bsModalRef: BsModalRef, private _auth: AuthService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.urlFormArray = new FormArray ([this.formBuilder.group(this.formGroup)]);
    this.urlFormGroup = this.formBuilder.group({
      formAt: this.urlFormArray
    });
  }

  closeDialog(): void {
    this._bsModalRef.hide();
  }

  // convenience getters for easy access to form fields
  get form() { return this.urlFormGroup.controls; }
  get urlForm() { return this.form.formAt as FormArray; }

  addNew() {
    this.triggerValidation();
    this.urlForm.push(this.formBuilder.group(this.formGroup));
    // this.urls.push({'path': '' , 'category': '' });
  }

  validUrls () {
    const filteredResult =  this.urlForm.controls.filter((x: any) => x.value.path && x.value.category && this.validURL(x.value.path) && x.status === 'VALID');
    return filteredResult.map((x: any) => x.value);
  }

  addLinks() {
    if (this.additionalParameters?.talentDetailsPane) {
      this.save(this.validUrls(), 'link')
    } else {
      this.save.emit({attachments: this.validUrls(), type: 'link'});
    }
  }

  validURL(str: any) {
    if (!str) { return false; }
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }

  duplicateValidator() {
    return (control: AbstractControl): {[key: string]: boolean} | null => {
      if (!this.urlFormGroup) return null;
      const currentAttachments = [...this.attachments, ...this.urlFormGroup.value.formAt];
      if (currentAttachments.some( attachment => (attachment.path && control.value ) && (attachment.path.toLowerCase() === control.value.toLowerCase())) ) {
        return {'duplicateValidator': true};
      }
      return null;
    };
  }

  triggerValidation() {
    for (let i = 0; i < this.urlForm.controls.length; i++) {
      const form = this.urlForm.controls[i] as FormGroup;
      // this.urlForm.controls[i].controls.path.updateValueAndValidity();
      form.markAllAsTouched();
      // form.get('category').updateValueAndValidity();
      // form.get('path').updateValueAndValidity();
    }
  }

}


