<div class="modal-header">
  <button type="button" class="btn-reset" aria-label="Close" (click)="_bsModalRef.hide()">
    <i class="icon-WL_icon_close"></i>
  </button>
</div>

<div class="modal-body">
  <div class="h-100" *ngIf="!job">
    <vertical-align>
      <div class="text-center">
        <div class="spinner-border custom-spinner text-primary" role="status">
          <span class="sr-only">{{'job-details-modal.loading' | translate}}</span>
        </div>
      </div>
    </vertical-align>
  </div>

  <div class="job-details-container" *ngIf="job">
    <app-job-details-pane [job]="job"></app-job-details-pane>
  </div>
</div> <!-- / .modal-body -->

<div class="modal-footer">
  <ul class="hz-btn-list">
    <li>
      <button type="button" class="btn rds--button--primary" aria-label="cancel" (click)="_bsModalRef.hide()">
        {{'job-details-modal.close' | translate}}
      </button>
    </li>
  </ul>
</div>