export const ClosedStatus = [
  'Closed Client Cancelled',
  'Closed Filled Internally',
  'Closed Lost Funding',
  'Closed LTC',
  'Closed Manager Unresponsive',
  'Closed Unfilled',
  'Closed Duration',
  'Closed Skill',
  'Closed VMS Unresponsive',
  'Closed Rate',
  'Closed Location',
  'Closed Unknown',
  'Closed Prefill',
  'Declined Known Prefill',
  'Declined Suspected Prefill',
  'Declined VMS Admin',
  'Declined Rate',
  'Declined Skill',
  'Archive',
  'Closed Expired',
  'Closed',
  'Declined VMS worked via AB',
  'Placed',
  'Closed COVID-19'
];

export const ActiveStatus = [
  'Accepting Candidates',
  'On Hold',
  'Unqualified',
  'Covered'
];
