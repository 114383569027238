import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ObjectUtilitiesService {

  constructor() { }


  /**
   * returns a boolean to indicate that a path exists in an object
   *
   * @param {object} - the object to inspect
   * @param {levels} - a series that describes the path the inspect
   * @return {exists} - indicates that the path exists on the object
   * @example
   * */
  checkNested(...args: any[]): boolean {
    const obj = args[0];
    const level = args[1];
    const rest = args.slice(2);

    if (obj === undefined) {
      return false;
    }
    if (obj === null) {
      return false;
    }
    if (rest.length == 0 && obj.hasOwnProperty(level)) {
      return true;
    }
    return this.checkNested(obj[level], ...rest);
  }

  /**
   * returns a boolean to indicate that a path exists in an object
   *
   * @param {object} - the object to inspect
   * @param {path} - a string that represents the path being search on
   * @return {exists} - indicates that the path exists on the object
   * @example
   * */
  checkNestedForPath(obj: any, path: string) {
    const argumentList = path.split('.');
    argumentList.unshift(obj);
    if (obj === null) {
      return false;
    } else {
      return this.checkNested(...argumentList);
    }
  }

  /**
   * Returns true if the given parameter is a plain object.
   * @param obj any object
   */
  static isPlainObject(obj: any): boolean {
    return Object(obj) === obj && !Array.isArray(obj);
  }

  /**
   * Given two objects, returns true if the values of all properties are equivalent.
   * Order of passed objects is important here -- this function only checks against the properties
   * found on the baseObj parameter to allow for partial checks.
   * @param baseObj - the object whose keys will be used to check for changes
   * @param compObj - the object compared against
   */
  static checkDeepEqual(baseObj: any, compObj: any) {
    for (const k in baseObj) {
      if (baseObj.hasOwnProperty(k)) {
        const baseVal = baseObj[k];
        const compVal = compObj[k];
        const isObjects = ObjectUtilitiesService.isPlainObject(baseVal) && ObjectUtilitiesService.isPlainObject(compVal);
        if ((isObjects && !ObjectUtilitiesService.checkDeepEqual(baseVal, compVal)) || (!isObjects && baseVal !== compVal)) {
          return false;
        }
      }
    }
    return true;
  }

}
