import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { INTERVIEW_TYPES } from '../talent-detail-pane/talent-process/activity-feed/activity.types';
import { OUTCOME } from '../../services/activity-feed/activity-feed.config';
import { ALL_NOTE_TYPES } from '../talent-detail-pane/talent-process/talent-process-modals/log-note-modal/note.types';
import { Activity, ActivityFeedTypes } from '../../models/internal/activity.interface';
import { Application } from '../../models/external/application.model';
import { Job } from '../../models/external/job.model';

@Component({
  selector: 'app-activity-detail-modal',
  templateUrl: './activity-detail-modal.component.html',
  styleUrls: ['./activity-detail-modal.component.scss']
})
export class ActivityDetailModalComponent implements OnInit {

  // Flag on whether to show the feed or not.
  showFeed = false;

  // The talent's process.
  talentProcess?: Application;

  // The job order (if applicable).
  job?: Job;

  // The activity that was opened.
  activity?: Activity;

  // Candidate's name.
  candidateFullName: string;

  // Show feed.
  showReturnBtn = false;

  // CSS Class
  class: string;

  // Outcome types.
  outcomeTypes = OUTCOME;

  // Note types.
  noteTypes: any = ALL_NOTE_TYPES;

  // Interview types.
  interviewTypes: any = INTERVIEW_TYPES;

  // Activity feed types.
  ActivityFeedTypes: typeof ActivityFeedTypes = ActivityFeedTypes;

  // ID for prescreen qusetions.
  conversationID: string;

  // Files used in internal submission
  attachments: any[];

  shownTemplate: TemplateRef<any>;
  @ViewChild('activityDetail', { static: true }) activityDetailView: TemplateRef<any>;
  @ViewChild('activityFeed', { static: true }) activityFeedView: TemplateRef<any>;

  constructor(
    public _bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
    if (this.talentProcess && this.talentProcess.randstad_process.prescreening) {
      this.conversationID = this.talentProcess.randstad_process.prescreening.conversationID;
    }
    this._bsModalRef.setClass('modal-sm');
    this.shownTemplate = this.showFeed ? this.activityFeedView : this.activityDetailView;
  }

  showActivityDetail(activity: Activity) {
    this.activity = activity;
    this.shownTemplate = this.activityDetailView;
    this.showReturnBtn = true;
  }

  showActivityFeed() {
    this.shownTemplate = this.activityFeedView;
    this.showReturnBtn = false;
  }

  isOfTypeActivity(obj: any): boolean {
    return typeof obj === 'object' && obj !== null && Object.values(ActivityFeedTypes).includes(obj?.type);
  }

  get commentIsHtml(): boolean {
    const type = this.activity?.type || this.activity?.activityType || '';
    return ['TEML', 'EMAL', ActivityFeedTypes.ClientSubmissionSent].includes(type);
  }

  /**
   * Returns pay rate from internal submission
   */
  get payRate(): number {
    if (this.talentProcess.randstad_process.internalSubmission && this.talentProcess.randstad_process.internalSubmission?.submissionPayRate) {
      return this.talentProcess.randstad_process.internalSubmission?.submissionPayRate;
    } else {
      return null;
    }
  }

  /**
   * Returns pay type (hourly or yearly) from internal submission
   */
  get payType(): string {
    if (this.talentProcess.randstad_process.internalSubmission && this.talentProcess.randstad_process.internalSubmission?.submissionPayType) {
      return this.talentProcess.randstad_process.internalSubmission?.submissionPayType;
    } else {
      return '';
    }
  }

  /**
   * Returns submission source from internal submission
   */
  get source(): string {
    if (this.talentProcess.randstad_process.internalSubmission && this.talentProcess.randstad_process.internalSubmission?.submissionSource) {
      return this.talentProcess.randstad_process.internalSubmission.submissionSource;
    } else {
      return '';
    }
  }

}
