import { createModel, Mapping, Model } from './model';
import { TimeOfDayToMoment } from './serializers/moment.serializer';
import { Moment } from 'moment';
import { Serial } from './decorators/serial.decorator';
import { Google } from '@allbirds-ui/allbirds-types';
export import RawTimeSegment = Google.Talent.TimeSegment;

class TimeSegmentMapping extends Mapping<RawTimeSegment> {
  @Serial(TimeOfDayToMoment) endTime: Moment;
  @Serial(TimeOfDayToMoment) startTime: Moment;
}

export interface TimeSegment extends Model<TimeSegmentMapping> {}

export const TimeSegment = createModel<TimeSegment>(TimeSegmentMapping);
