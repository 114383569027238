export const drugScreenTypes = {
  rna_scrn_type: 'rna_scrn_type',
  DRG19: 'DRG19',
  EC10: 'EC10',
  DRG01: 'DRG01',
  DRG02: 'DRG02',
  DRG03: 'DRG03',
  DRG04: 'DRG04',
  DRG05: 'DRG05',
  DRG06: 'DRG06',
  DRG07: 'DRG07',
  DRG08: 'DRG08',
  DRG09: 'DRG09',
  DRG10: 'DRG10',
  DRG11: 'DRG11',
  DRG12: 'DRG12',
  DRG13: 'DRG13',
  DRG14: 'DRG14',
  DRG15: 'DRG15',
  DRG16: 'DRG16',
  DRG17: 'DRG17',
  DRG18: 'DRG18',
  DRG20: 'DRG20',
  DRG21: 'DRG21',
  DRG22: 'DRG22',
  DRG23: 'DRG23',
  DRG24: 'DRG24',
  DRG25: 'DRG25',
  DRGSF: 'DRGSF',
  DRU13: 'DRU13',
  DRU14: 'DRU14',
  'E-CUP': 'E-CUP',
  ORL05: 'ORL05',
  ORL06: 'ORL06',
  ORL10: 'ORL10',
  RND01: 'RND01',
  RND02: 'RND02',
  RND03: 'RND03',
  RND04: 'RND04',
  RND05: 'RND05',
  'X-CUP': 'X-CUP'
};
