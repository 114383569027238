import { Jobs } from '@allbirds-ui/allbirds-types';
import { createModel, Mapping, Model } from './model';
import { JobMetadata } from './job-metadata.model';
import { GrpcToMoment } from './serializers/moment.serializer';
import { Moment } from 'moment';
import { Serial } from './decorators/serial.decorator';
import { TimeSegment } from './time-segment.model';
import { JobMetrics } from './job-metrics.model';
import { Internal } from './decorators/internal.decorator';
export import RawJob = Jobs.AllbirdsJob;

class JobMapping extends Mapping<RawJob> {
  @Serial(JobMetadata) allbirds_metadata: JobMetadata;
  @Serial(GrpcToMoment) jobStartTime?: Moment;
  @Serial(GrpcToMoment) jobEndTime?: Moment;
  @Serial(GrpcToMoment) postingPublishTime?: Moment;
  @Serial(GrpcToMoment) postingExpireTime?: Moment;
  @Serial([TimeSegment]) workHours?: TimeSegment[];
  @Serial(JobMetrics) metrics?: JobMetrics;
  @Internal() published_status?: boolean;

  get hasExpireDate(): boolean {
    /* For RT Expire Date Is Not Set Until A Recruiter Is Assigned In BH */
    return !!this.expirationDate;
  }

  get getLob (): string { return this.allbirds_metadata && this.allbirds_metadata.lob; }

  get expirationDate(): Moment {
    if (this.allbirds_metadata.lob.checkLob('RGS')) {
      return this.postingExpireTime;
    } else if (this.allbirds_metadata.lob.checkLob('RT', 'RE','CR')) {
      return this.allbirds_metadata.job_expire_date;
    }
    return null;
  }
}

export interface Job extends Model<JobMapping> {}

export const Job = createModel<Job>(JobMapping);

export const defaultJob: Job = new Job().applyRaw({
  addresses: [],
  aggregatorName: '',
  certifications: [],
  classification: '',
  companyDisplayName: '',
  customAttributes: {},
  department: '',
  expectedAssignmentDays: 0,
  incentives: '',
  jobBenefits: [],
  jobLevel: 'JOB_LEVEL_UNSPECIFIED',
  languageCode: '',
  languageFluencies: [],
  name: '',
  postingRegion: 'POSTING_REGION_UNSPECIFIED',
  promotionValue: 0,
  recruiters: [],
  recruitingNotes: [],
  requisitionState: 'REQUISITION_STATE_UNSPECIFIED',
  securityClearances: [],
  visibility: 'VISIBILITY_UNSPECIFIED',
  workEnvironments: [],
  allbirds_metadata: {
    allbirds_job_id: '',
    allow_remote_work: '',
    assigned_to_user: '',
    assigned_to_user_email: '',
    assigned_to_user_back_office_id: '',
    assigned_to_user_front_office_id: '',
    bill_to_location: '',
    client_description: '',
    client_interview_type: '',
    consistently_standing: false,
    country: '',
    contact_id: '',
    contact_address1: '',
    customer_name: '',
    customer_id: '',
    customer_industry: '',
    work_location: '', // unstructured address
    work_address: '', // structured address
    work_zip: '', // structured address
    work_city: '', // structured address
    work_state: '', // structured address
    work_country: '', // structured address
    draft_creation_date: '',
    draft_update_date: '',
    google_job_id: '',
    heavy_lifting: false,
    internal_job_code: '',
    internal_job_title: '',
    interview_info: '',
    interview_info_rt_perm: false,
    lob: '',
    experience_level: '',
    assigned_to_user_flag: false,
    order_status: '',
    order_source: '',
    published_by_user_back_office_id: '',
    published_by_user_front_office_id: '',
    published_by_user_email: '',
    published_by_user: '',
    skills: [],
    smoke_free_environment: false,
    steel_toe_shoes_required: false,
    submission_deadline: '',
    client_interview_date: '',
    temperature_controlled: false,
    travel_requirements: '',
    user_branch_id: '',
    user_empl_id: '',
    user_branch_name: '',
    user_office_address: '',
    user_office_city: '',
    user_office_state: '',
    user_office_zip: '',
    user_phone: '',
    user_unit: '',
    weekend_work_required: false,
    weekly_hours: '',
    allow_auto_scheduler: true,
    max_auto_schedules_allowed: 999,
    auto_scheduler_interview_channel: [],
    auto_scheduler_location: '',
    unpublished_by_user_back_office_id: '',
    unpublished_by_user_front_office_id: '',
    unpublished_by_user_email: '',
    unpublished_by_user: '',
    front_office_id: '',
    job_expire_date: '',
    vms_req_id: '',
    vms_created_job: false,
    attachments : [],
    interview_duration: 0,
    job_interviewer: [],
    job_extension_counter: 0,
    drug_screening: false,
    order_type: 'Client Order',
    pipeline_id: '',
    number_of_linked_jobs: 0,
    linked_ab_job_id: '',
    order_of_linked_job: 0,
    service_line: '',
    sub_service_line: '',
    client_partner: '',
    client_partner_id: ''
  },
  structuredAddresses: [],
  company: '',
  classifications: [],
  compensationInfo: {
    entries: [],
  },
  degreeTypes: [],
  description: '',
  employmentTypes: [],
  externalSystem: '',
  hiringManager: '',
  internalDescription: '',
  internalTitle: '',
  jobRequirements: [],
  jobAuthor: '',
  qualifications: '',
  responsibilities: '',
  requisitionId: '',
  skills: [],
  title: '',
  workHours: [],
  workEnvironment: [],
});
