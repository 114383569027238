import { Pipe, PipeTransform } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import {
  RFORejectCodes,
  RFORejectContactMethods
} from '../../components/talent-detail-pane/talent-process/recruitment-phases/offer/reject-offer-modal/reject-offer-modal.component';

type StringCleanOption =
  'sansunderscore'
  | 'titlecase'
  | 'sansjobcode'
  | 'mapeducationprofile'
  | 'mapeducation'
  | 'mapemploymenttype'
  | 'rfoOfferRejectCode'
  | 'rfoContactMethod';

@Pipe({
  name: 'stringclean'
})
export class StringCleanPipe implements PipeTransform {

  transform(str: string, args?: StringCleanOption[]): string {
    const titleCasePipe = new TitleCasePipe();
    let string = str;
    if (args.includes('sansunderscore')) {
      string = str.replace(/_/g, ' ');
    }
    if (args.includes('titlecase')) {
      string = titleCasePipe.transform(string);
    }
    if (args.includes('sansjobcode')) {
      /*
       * Internal Job Titles Have The Following Format:
       * ******-<jobtitle>
       * the intention of this is to remove the ******- part
       */
      string = string.replace(/^(.+?)-/, '');
    }

    if (args.includes('mapeducationprofile')) {
      switch (string) {
        case 'DEGREE_TYPE_UNSPECIFIED':
          return '';
        case 'UPPER_SECONDARY_EDUCATION':
          return 'High School or GED';
        case 'ADULT_REMEDIAL_EDUCATION':
          return 'Trade School';
        case 'ASSOCIATES_OR_EQUIVALENT':
          return 'Associates';
        case 'BACHELORS_OR_EQUIVALENT':
          return 'Bachelors';
        case 'MASTERS_OR_EQUIVALENT':
          return 'Masters';
        case 'DOCTORAL_OR_EQUIVALENT':
          return 'Doctorate';
        default:
          return '';
      }
    }

    if (args.includes('mapeducation')) {
      switch (string) {
        case 'DEGREE_TYPE_UNSPECIFIED':
          return 'No degree required';
        case 'UPPER_SECONDARY_EDUCATION':
          return 'High School or GED Equivalent';
        case 'ADULT_REMEDIAL_EDUCATION':
          return 'Trade School';
        case 'ASSOCIATES_OR_EQUIVALENT':
          return 'Associates';
        case 'BACHELORS_OR_EQUIVALENT':
          return 'Bachelors';
        case 'MASTERS_OR_EQUIVALENT':
          return 'Masters';
        case 'DOCTORAL_OR_EQUIVALENT':
          return 'Doctorate';
      }
    }


    if (args.includes('mapemploymenttype')) {
      switch (string) {
        case 'CONTRACTOR':
          return 'Contract';
        case 'PERMANENT':
          return 'Permanent';
        case 'CONTRACT_TO_HIRE':
          return 'Contract To Hire';
        case 'SOLUTIONS':
          return 'Solutions';
      }
    }

    if (args.includes('rfoOfferRejectCode')) {
      return RFORejectCodes[<keyof typeof RFORejectCodes>string] || string;
    }

    if (args.includes('rfoContactMethod')) {
      return RFORejectContactMethods[<keyof typeof RFORejectContactMethods>string] || string;
    }

    return string;

  }

}
