import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-banner-learn-more-modal',
  templateUrl: './banner-learn-more-modal.component.html',
  styleUrls: ['./banner-learn-more-modal.component.scss']
})
export class BannerLearnMoreModalComponent implements OnInit {
  title: string;
  explanation: string;

  constructor(private _bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

  closeDialog(): void {
    this._bsModalRef.hide();
  }

}
