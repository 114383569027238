import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Card, CardType } from '../../../../../../shared/models/internal/card.model';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { User } from 'src/app/shared/models/external/user.model';
import { interval, Subscription } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { LaneConditionService } from 'src/app/shared/services/job-management/lanes/lane-condition.service';
import { UtilityService } from 'src/app/shared/services/utility/utility.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { FirestoreService } from 'src/app/shared/services/firestore/firestore.service';
import { ToastClass, ToastService } from 'src/app/shared/services/toast';
import { JobOrderService } from 'src/app/shared/services/job-order/job-order.service';
import { Job } from 'src/app/shared/models/external/job.model';
import { ApiService } from '../../../../../../shared/services/api/api.service';
import { Moment } from 'moment';
import { PostalAddress } from 'src/app/shared/models/external/misc.model';
import { TranslateService } from '@ngx-translate/core';
import { ClosedStatus } from '../../../../../../shared/models/internal/process-statuses.interface';
import { OrderType } from 'src/app/shared/models/internal/job-form.interface';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  styles: [
      `
      :host(.tooltip-inner) {
        background-color: #22294B;
        color: white;
        font-size: 14px;
      }

      :host(.tooltip.top .tooltip-arrow:before,
       .tooltip-arrow) {
        border-top-color: #22294B;
      }
    `
  ]
})
export class CardComponent implements OnInit, OnDestroy {
  @Input() card: Card;
  @Input() index = 1;
  @Output() selectEvent = new EventEmitter();
  headerText: string;
  jobStatusText: string;
  user: User;
  dayText: string;
  days: number;
  tooltipDeadlineHtml: string;
  tooltipPublishedByHtml: string;
  buildAddressString: any;
  cardType = CardType;
  secondsCounter = interval(30000);
  addressString = '';

  fetchingUserInfo = true;
  publishedByUser: User = null;
  assignedToUser: User = null;
  collabUsers: any[] = [];
  interviewerUsers: any[] = [];
  assigneeAndCollabUsers: any[] = [];
  hasExpireDate = true;

  firestoreSub: Subscription;
  // job_order
  min_pay_rate = 0;
  max_pay_rate = 0;
  employmentTypes: string[] = [];
  structuredAddresses: PostalAddress[] = [];
  allbirdsNumOpenings = 0;
  allbirdsCustName: string;
  internalTitle: string;
  allbirdsCustPriority: number;
  prescreeningDocCount = 0;
  interviewDocCount = 0;
  internalSubmissionDocCount = 0;
  clientSubmissionDocCount = 0;
  clientInterviewDocCount = 0;
  webApplicantsDocCount = 0;
  interviewSlotsDocCount = 0;
  interviewSlotStatus: string;
  currencyCode = 'USD';
  currencyUnit: string = '';
  currencyAmountUnits: number = undefined;
  frontOfficeId: string;
  postingCreateTime: Moment;
  hiringManager: string;
  jobPublishStatus: boolean;
  secondUser: User;
  jobSub: Subscription;
  linked_ab_job_id: string;
  number_of_linked_jobs = 0;
  order_of_linked_job = 0;
  hasJobCardLobLabel: boolean;
  TPVeligible: boolean;

  constructor(
    private _auth: AuthService,
    private _api: ApiService,
    private _conditions: LaneConditionService,
    private sanitizer: DomSanitizer,
    private _router: Router,
    private util: UtilityService,
    private _user: UserService,
    private _firestore: FirestoreService,
    private _toast: ToastService,
    private _jobOrder: JobOrderService,
    private translate: TranslateService
  ) {
    this.user = this._auth.user;
  }

  ngOnInit() {
    if (this.card) {
      // console.log(`${this.card.job_order.title}`, this.card)
      this.setJobOrderValues();
      const translateObj = { 'value': this.getDeadlineDateText() || ('not found') };
      const trStr = this.translate.instant('card.deadlinetext', translateObj);
      const trAcc = this.translate.instant('card.accmgr');
      this.tooltipDeadlineHtml = '<span>' + trStr + '</span>';
      this.tooltipPublishedByHtml ='<span>' + trAcc + '<br>' + (this.card.published_by_user_name ? this.card.published_by_user_name.toLowerCase() : '')
          .split(' ')
          .map(function (word) {
            if (word && word[0]) {
              return word[0].toUpperCase() + word.substr(1);
            }
            return '';
          })
          .join(' ') + '</span>';
      this.days = this.util.calcNumberOfDays(this.card.job_order);
      if (this.hasExpireDate) {
        this.setDayStatus();
      } else {
        this.dayText = 'Unassigned';
        this.days = 100;
      }
      this.jobPublishStatus = this._jobOrder.publishedStatus(this.card.job_order);
      //Only add CR label to jobs in the job search pages (everything but jobs/dashboard)
      if (!this._router.url.includes('/jobs/dashboard')) this.hasJobCardLobLabel = this.card.job_order.allbirds_metadata.lob.checkLob('RT', 'RE', 'CR') || false;
          

      // Activate the firestore listener and enable the in progress header if
      // the job is created but there is no FO id.
      if (
        this.card.job_order &&
        this.card.job_order.allbirds_metadata &&
        this.card.job_order.allbirds_metadata.order_created &&
        !this.card.job_order.allbirds_metadata.front_office_id
      ) {
        this.listenForFrontOfficeID();
        return;
      }

      if (this.card && this.card.header) {
        this.setConditions();
      }
      if(Array.isArray(this.structuredAddresses)){
        this.addressString = UtilityService.buildAddressString(this.structuredAddresses[0]);
      }

      this.jobSub = this._jobOrder.jobOrderObservable.subscribe(data => {
        if(data && data.allbirds_metadata){
          if (
            this.card.job_order &&
            this.card.job_order.allbirds_metadata &&
            this.card.job_order.allbirds_metadata.order_created &&
            this.card.job_order.allbirds_metadata.front_office_id &&
            this.card.job_order.allbirds_metadata.front_office_id === data.allbirds_metadata.front_office_id
          ){
            console.log('updated card: ', data.allbirds_metadata.front_office_id);
            this.interviewerUsers = [];
            this.collabUsers = [];
            this.assigneeAndCollabUsers = [];
            this.getUsers(data);
            // this.secondUser = this.setSecondUser();
          }
        }
      })
    }
  }

  setJobOrderValues() {
    if (this.card && this.card.job_order) {
      const jobOrder: Job = this.card.job_order;
      this.internalTitle = jobOrder.internalTitle;
      this.structuredAddresses = jobOrder.structuredAddresses;
      this.employmentTypes = jobOrder.employmentTypes || [];
      this.getUsers(jobOrder);
      this.hasExpireDate = jobOrder.hasExpireDate;
      this.hiringManager = jobOrder.hiringManager;
      this.postingCreateTime = jobOrder.allbirds_metadata && jobOrder.allbirds_metadata.draft_creation_date;
      this.linked_ab_job_id = jobOrder.allbirds_metadata.linked_ab_job_id;
      this.number_of_linked_jobs = jobOrder.allbirds_metadata.number_of_linked_jobs;
      this.order_of_linked_job = jobOrder.allbirds_metadata.order_of_linked_job;
      // this.secondUser = this.setSecondUser();
      // this.postingCreateTime = jobOrder.postingCreateTime && jobOrder.postingCreateTime.seconds ?
      //   jobOrder.postingCreateTime.seconds * 1000 :
      //   null;
      if (jobOrder.compensationInfo) {
        const compInfo = jobOrder.compensationInfo;
        if (compInfo && compInfo.entries && compInfo.entries.length) {
          this.currencyCode = compInfo.entries[0].amount.currencyCode;
          this.currencyUnit = compInfo.entries[0].unit;
          this.currencyAmountUnits = compInfo.entries[0].amount.units;
          /*
            Since the getAllJobPostings route doesnt get put through the map_fields methods
            we are never setting the min_pay_rate in the correct format. To prevent looping over
            every job in the getAllJobPostings method, I decided to convert it on the frontend.
          */
          this.min_pay_rate = Number(compInfo.entries[0].amount.units) + (compInfo.entries[0].amount.nanos / 1000000000);
        }
        if (jobOrder.allbirds_metadata) {
          const allBirds = jobOrder.allbirds_metadata;
          this.allbirdsCustName = allBirds.customer_name;
          this.allbirdsCustPriority = allBirds.customer_priority;
          this.allbirdsNumOpenings = allBirds.number_of_openings;
          this.max_pay_rate = allBirds.published_max_pay_rate;
          this.frontOfficeId = allBirds.front_office_id;
          this.TPVeligible = allBirds.allow_third_party_vendors
        }
        if (jobOrder.metrics) {
          const metrics = jobOrder.metrics;
          this.prescreeningDocCount = metrics.prescreening;
          this.interviewDocCount = metrics.interview;
          this.internalSubmissionDocCount = metrics.internalSubmission;
          this.clientSubmissionDocCount = metrics.clientSubmission;
          this.clientInterviewDocCount = metrics.clientInterview;
          this.webApplicantsDocCount = metrics.webApplicants;
          this.interviewSlotsDocCount = metrics.interviewScheduled;
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.firestoreSub) { this.firestoreSub.unsubscribe(); }
    if (this.jobSub) { this.jobSub.unsubscribe(); }
  }

  get jobMetrics() { return ((this.card || {}).job_order || {}).metrics; }

  @HostListener('click') onClick() {
    if (this.card && CardType && this.card.cardType === CardType.TALENT_ADD_TO_JOB) {
      this.selectEvent.emit(this.card);
    } else {
      this.redirectToDetails();
    }

  }

  setConditions() {
    // console.log('[setConditions] fired for ', this.card.job_order);
    const conditions = this._conditions.getConditions();
    for (const condition of conditions) {
      if (condition.rule(this.card.job_order, this.user)) {
        this.headerText = condition.name.toLowerCase()
          .split('_')
          .map(function (word) {
            return word[0].toUpperCase() + word.substr(1);
          })
          .join(' ');
        break; // break from the for loop so only the first condition met is used if multiple apply
      }
    }
  }

  public redirectToDetails() {
    if (this.card.job_order.allbirds_metadata.order_created) {
      this._router.navigate(['../../jobs', this.card.job_order.allbirds_metadata.allbirds_job_id]);
    } else {
      this._router.navigate(['../../jobs', this.card.job_order.allbirds_metadata.allbirds_job_id, 'edit']);
    }
  }

  getColoredClock() {
    if (this.cssCondDays4()) {
      return ('assets/icons/clock-white.svg');
    } else {
      return ('assets/icons/clock.svg');
    }
  }

  setDayStatus() { // please ask before changing / do not use localization on this yet / eyyup
    this.dayText = this._jobOrder.jobTimerText(this.card.job_order, 'h');
    this.secondsCounter.subscribe(n => this.dayText = this._jobOrder.jobTimerText(this.card.job_order, 'h'));
  }

  getDeadlineDate(): Moment | undefined {
    if (
      this.card.job_order.allbirds_metadata.lob.checkLob('RT', 'RE') &&
      this.card.job_order &&
      this.card.job_order.allbirds_metadata &&
      this.card.job_order.allbirds_metadata.job_expire_date
    ) {
      return this.card.job_order.allbirds_metadata.job_expire_date;
    } else if (
      this.card.job_order.allbirds_metadata.lob.checkLob('RGS') &&
      this.card.job_order &&
      this.card.job_order.postingExpireTime
    ) {
      return this.card.job_order.postingExpireTime;
    }
  }

  getTooltip(end_date: any) {
    const trDeadline = this.translate.instant('card.deadline', { value: end_date });
    if (end_date) {
      return trDeadline;
    }
    return '';
  }

  getDeadlineDateText(): string {
    const date = this.getDeadlineDate();
    let deadlineText = this.translate.instant('card.deadlineTxt');

    if (this.hasExpireDate && date) {
      try {
        deadlineText = date.formatDate();
      } catch (err) {
        console.log('Unexpected Date Format', err);
      }
    }

    return deadlineText;
  }

  isLob(lob: string) {
    if (!this.user) { return false; }
    return this.user.Source.includes(lob);
  }

  get userLob() { return this.user && this.user.Source; }
  get jobLob() { return this.card && this.card.job_order && this.card.job_order.allbirds_metadata.lob; }
  get isClientOrder(): boolean { return this.card?.job_order?.allbirds_metadata?.order_type !== OrderType.PIPELINE; }

  getUsers(jobOrder: Job) {
    // Get the associated user back office IDs.
    const publishedByUserId = jobOrder.allbirds_metadata.published_by_user_back_office_id || null;
    const assignedToUserId = jobOrder.allbirds_metadata.assigned_to_user_front_office_id || null;
    const collabUserIds = jobOrder.allbirds_metadata.job_collaborators?.map((collaborator: any) => {
      return collaborator.user_back_office_id;
    }).filter(x => x);
    const interviewerUserIds = jobOrder.allbirds_metadata.job_interviewer?.map((interviewer: any) => {
      return interviewer.user_back_office_id;
    }).filter(x => x);

    // Removes cached users
    if (!assignedToUserId) this.assignedToUser = null;
    if (!publishedByUserId) this.publishedByUser = null;
    if (!collabUserIds) this.collabUsers = [];
    if(!interviewerUserIds) this.interviewerUsers = [];

    // If there was ID(s), then attempt to get it from the cache.
    if (publishedByUserId) { this.publishedByUser = this._user.getFromCache(publishedByUserId); }
    if (assignedToUserId) {
      this.assignedToUser = this._user.getFromCache(assignedToUserId);
      // if (this.assignedToUser) {
      //   this.assigneeAndCollabUsers.push(this.assignedToUser.FullName);
      // }
    }
    this.updateOtherUsers(collabUserIds, 'collabUser');
    this.updateOtherUsers(interviewerUserIds, 'interviewerUser');

    // If there was IDs, but the user was not yet stored in the cache, then we must retrieve them.
    // We do this by first storing the IDs as keys of an object, converting said keys into an array,
    // and then passing the array to a function in the UserService that retrieves and caches the profile.
    const queryIDs: { [id: string]: boolean } = {};
    if (publishedByUserId && !this.publishedByUser) { queryIDs[publishedByUserId] = true; }
    if (assignedToUserId && !this.assignedToUser) { queryIDs[assignedToUserId] = true; }
    if (collabUserIds && collabUserIds.length && this.collabUsers && this.collabUsers.length) {
      this.collabUsers.forEach((user: any, index) => {
        if (!user) {
          queryIDs[collabUserIds[index]] = true;
        }
      });
    }
    if (interviewerUserIds && interviewerUserIds.length && this.interviewerUsers && this.interviewerUsers.length) {
      this.interviewerUsers.forEach((user: any, index) => {
        if (!user) {
          queryIDs[interviewerUserIds[index]] = true;
        }
      });
    }
    const queryIDArr = Object.keys(queryIDs);
    if (!queryIDArr || !queryIDArr.length) {
      this.fetchingUserInfo = false;
    } else {
      this._user.getUsersById(queryIDArr)
        .then(() => {
          this.publishedByUser = this._user.getFromCache(publishedByUserId);
          this.assignedToUser = this._user.getFromCache(assignedToUserId);
          // if (this.assignedToUser && !this.assigneeAndCollabUsers.includes(this.assignedToUser.FullName)) {
          //   this.assigneeAndCollabUsers.push(this.assignedToUser);
          // }
          this.updateOtherUsers(collabUserIds, 'collabUser');
          this.updateOtherUsers(interviewerUserIds, 'interviewerUser');
          //fixes async setting of secondUser when not in cache
          // this.secondUser = this.setSecondUser();
          this.fetchingUserInfo = false;
        })
        .catch(() => {
          console.log('[getUsers] An error occurred fetching the users for the job-details-header.');
        });
    }
  }

  updateOtherUsers(otherUserIds: any[], userType: string){
    if (otherUserIds && otherUserIds.length) {
      const otherUsers = otherUserIds.map((id: any) => {
        const user = this._user.getFromCache(id);
        if (user) {
          if (!this.assigneeAndCollabUsers.includes(user.FullName)) {
            this.assigneeAndCollabUsers.push(user.FullName);
          }
          return {
            [userType]: user,
            user_back_office_id: user.BackOfficeID,
            user_email: user.EmailAddr,
            user_front_office_id: user.Oprid,
            user_name: user.FullName
          }
        }
      });
      if(userType === 'collabUser') this.collabUsers = otherUsers;
      else this.interviewerUsers = otherUsers;
   }
  }

  listenForFrontOfficeID() {
    const { allbirds_job_id } = this.card.job_order.allbirds_metadata;
    let initialLoad = true;
    // Set card header.
    this.card.header = true;
    const trHeaderText = this.translate.instant('card.progress');
    this.headerText = trHeaderText;
    // Listen to the Firestore document.
    this.firestoreSub = this._firestore.listenJob(allbirds_job_id)
      .subscribe((newVal: any) => {
        if (newVal && newVal.foOrderID) {
          // FO ID received; turn off the header.
          this.card.header = false;
          this.headerText = '';
          this.card.job_order.allbirds_metadata.front_office_id = newVal.foOrderID;
          const trvariable = { 'value1': this.card.job_order.title };
          this._toast.showToastWithVariables('card.success_create', trvariable, { cssClass: ToastClass.SUCCESS });
          // If the user is on the search page when this happens, check to see if the job
          // applies under any other condition which would required a card header.
          if (this._router.url === '/jobs/search') {
            console.log('[router] checking for other conditions...');
            this.card.header = true;
            this.setConditions();
          }
          // If we immediately get an FO ID back from Firestore, this implies that the
          // update step may have failed in Integrations since the Elastic document doesn't
          // have the FO id while Firestore does. Thus, we update the job as a backup.
          if (initialLoad) {
            this._api.updateJob(this.card.job_order, ['front_office_id'])
              .subscribe(() => {
                console.log('[listenForFrontOfficeID] job update successful.');
              }, err => {
                console.log('[listenForFrontOfficeID] job update failed.', err);
              });
          }
        }
        initialLoad = false;
      });
  }

  // setSecondUser() {
  //   if (this.assignedToUser
  //     && (!this.publishedByUser ||
  //       this.assignedToUser.BackOfficeID !== this.publishedByUser.BackOfficeID)
  //     && !this.collabUsers.length
  //     && !this.interviewerUsers.length) {
  //       return this.assignedToUser;
  //     } else if (!this.assignedToUser
  //       && !this.interviewerUsers.length
  //       && this.collabUsers
  //       && this.collabUsers.length === 1
  //       && this.collabUsers[0]
  //       && this.collabUsers[0].collabUser
  //       && (!this.publishedByUser ||
  //         this.collabUsers[0].collabUser.BackOfficeID !== this.publishedByUser.BackOfficeID)) {
  //         return this.collabUsers[0].collabUser;
  //     } else if (!this.assignedToUser
  //       && !this.collabUsers.length
  //       && this.interviewerUsers
  //       && this.interviewerUsers.length === 1
  //       && this.interviewerUsers[0]
  //       && this.interviewerUsers[0].interviewerUser
  //       && (!this.publishedByUser ||
  //         this.interviewerUsers[0].interviewerUser.BackOfficeID !== this.publishedByUser.BackOfficeID)) {
  //         return this.interviewerUsers[0].interviewerUser;
  //     }
  // }

  cssCondDays410() {
    return (this.days >= 4 && this.days <= 10 && this.jobPublishStatus);
  }

  cssCondDays10() {
    return (this.days > 10 && this.jobPublishStatus);
  }

  cssCondDays4() {
    return this.dayText !== 'N/A' &&
      ( (!this.jobLob.checkLob('RGS') ? ( ClosedStatus.includes(this.card.job_order.allbirds_metadata.order_status) && this.hasExpireDate) : false) ||
        (this.days < 4 && this.card.order_created) ||
        (this.days < 4 && !this.jobPublishStatus && this.card.job_order.allbirds_metadata.publish_immediately));

  }

  /**
   * Sets the margin (through ngClass) to how many docs there are for each metric...
   * so the red alert circles can properly display under the number. 
   * It uses ones, tenths, hundreths place, decimal logic to determine the margin
   * '3' doc count will return length of 1
   * '33' doc count will return margin length of 2
   * '333' doc count will return return margin length of 3
  */
  get MetricsDigit() {
    const jobOrder: Job = this.card.job_order;
    if (!jobOrder.metrics) { return 0; }
    let highest = 0;
    for (const key in jobOrder.metrics) {
      /*
      * Context behind why hasOwnProperty is needed when looping through an obj: 
      * https://stackoverflow.com/questions/46480255/is-there-a-benefit-to-use-hasownproperty-when-iterating-through-object-keys
      */
      if (jobOrder.metrics.hasOwnProperty(key)) {
        const metric = (<any>jobOrder.metrics)[key].toString();
        if (metric.length > highest)  {
          highest = metric.length;
        }
      }
    }
    return highest;
  }

  checkInterviewSlots() {
    const maxInterviewsCount: number = this.card.job_order.allbirds_metadata.max_auto_schedules_allowed;
    // if (this.interviewSlotsDocCount < maxInterviewsCount) {
    //   this.interviewSlotStatus = `${this.interviewSlotsDocCount}/${maxInterviewsCount} interviews scheduled`;
    // }
    if (this.interviewSlotsDocCount >= maxInterviewsCount) {
      this.interviewSlotStatus = 'No interview slots';
      return true;
    }
    return false;
  }
  showTPVLabel(){
    if(this.userLob?.checkLob("RT","RE") && this.jobLob?.checkLob('RT','RE') && !this.card?.job_order?.employmentTypes[0]?.includes('PERMANENT')){
      return true
    }
  }

}
