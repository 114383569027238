import { Users } from '@allbirds-ui/allbirds-types';
import { createModel, Mapping, Model } from './model';
import { Lane } from '../internal/lane.model';
import { Internal } from './decorators/internal.decorator';
import { UserPermissions } from './misc.model';
export import RawUser = Users.User;
import {formatAsRecruiterHierarchy, formatFullName, formatInitials} from '../../services/utility/formatters';
import {RecruiterHierarchy} from '../internal/recruiter-hierarchy.model';
import Recruiter = RecruiterHierarchy.Recruiter;

class UserMapping extends Mapping<RawUser> {
  @Internal() lanes: Lane[] = [];
  Permissions: UserPermissions = {};

  get FullName(): string {
    return formatFullName(this as unknown as User);
  }

  get Initials(): string {
    return formatInitials(this as unknown as User);
  }

  get formatRecruiterHierarchy (): Recruiter {
    return formatAsRecruiterHierarchy(this as unknown as User);
  }
}

export interface User extends Model<UserMapping> {}

export const User = createModel<User>(UserMapping);
