import { Injectable } from '@angular/core';
import { Application } from '../../models/external/application.model';
import { Job } from '../../models/external/job.model';
import { User } from '../../models/external/user.model';
import { Profile } from '../../models/external/profile.model';
import moment from 'moment';
import { Phone, PostalAddress, RecruiterEvent } from '../../models/external/misc.model';
import { Prescreening } from '../../models/external/process/prescreening.model';
import { EnhancedProfile } from '../../models/internal/enhanced-profile.interface';


@Injectable({
  providedIn: 'root'
})
export class IterableService {

  constructor() { }

  /**
   * Applies the prescreening schedule to an application object (this is what the state of
   * randstad_process.prescreening is set to after a CE outreach is sent).
   * @param application - allbirds talent application object
   * @param user - auth user object
   */
  public static applyPrescreeningScheduleToApplication(application: Application, user: User): void {
    const { prescreening } = application.randstad_process;
    const data = {
      skipped: false,
      isManualPrescreening: false,
      initiatedDate: moment(),
      agentID: user.BackOfficeID || ''
    };
    if (prescreening) {
      prescreening.apply(data);
    } else {
      application.randstad_process.prescreening = new Prescreening(data);
    }
  }

  /**
   * Generates an Event as defined in the Iterable API documentation.
   * {@link https://api.iterable.com/api/docs#events_track}
   * @param application - allbirds talent application object
   * @param profile - allbirds talent profile object
   * @param job - allbirds job object
   * @param user - auth user object
   */
  public static constructIterableEvent(application: Application, profile: Profile, job: Job, user: User): RecruiterEvent {
    let email = profile && profile.emailAddresses && profile.emailAddresses.length ? profile.emailAddresses[0].emailAddress : '';
    if(email == '') {
      email = ((profile as EnhancedProfile).normEmail[0]) ? (profile as EnhancedProfile).normEmail[0]: '';
    }
    // console.log('[constructIterableEvent]: email = ', email);
    return {
      email: email,
      dataFields: {
        ...this.extractNameFromProfile(profile),
        emailAddress: email,
        profileId: application.profile,
        randstadJobId: application.randstad_process.jobFrontOfficeOrderID || job.allbirds_metadata.front_office_id,
        googleJobId: application.job,
        jobTitle: job.title,
        jobLocation: IterableService.extractAddress(job.structuredAddresses),
        // tslint:disable-next-line:max-line-length
        phoneNumber: (profile && profile.phoneNumbers && profile.phoneNumbers.length) ? IterableService.constructPhoneNumber(IterableService.extractPhoneNumber(profile.phoneNumbers)) : '',
        recruiterName: user.FirstName + ' ' + user.LastName,
        recruiterEmail: user.EmailAddr,
        recruiterPhoneNumber: IterableService.constructPhoneNumber(user.PrimaryPhone),
        atsReferenceNumber: profile.externalId || application.randstad_process.candidateFrontOfficeID,
        lobId: job.allbirds_metadata.lob || this.extractLobFromProfile(profile),
        internalJobCode: job.allbirds_metadata.lob.checkLob('RGS') ? job.allbirds_metadata.internal_job_code : null,
        outreachId: '' // emptry string to get around property missing error
      }
    };
  }

  /**
   * Attempts to retrieve an LOB value from the talent profile
   * @param profile talent profile object
   */
  public static extractLobFromProfile(profile: Profile): string {
    if (profile.externalSystem) {
      if (profile.externalSystem.abIncludesLob('RGS')) { return 'RGS'; }
      if (profile.externalSystem.abIncludesLob('RT', 'RE')) { return 'RT'; }
    }
    if (profile.externalId) {
      if (profile.externalId.abIncludesLob('RGS')) { return 'RGS'; }
      if (profile.externalId.abIncludesLob('RT', 'RE')) { return 'RT'; }
    }
    return '';
  }

  /**
   * Attempts to extract first name & last name from the talent profile.
   * @param profile talent profile object
   */
  public static extractNameFromProfile(profile: Profile): { firstName: string, lastName: string } {
    const result = { firstName: '', lastName: '' };
    const name = (profile.personNames && profile.personNames.length) ? profile.personNames[0] : undefined;
    if (name && name.structuredName) {
      result.firstName = name.structuredName.givenName || '';
      result.lastName = name.structuredName.familyName || '';
    }
    return result;
  }

  /**
   * Given an array of addresses in the Google format, returns an address string.
   * @param addresses - array of addresses in Google profile format
   */
  public static extractAddress(addresses: PostalAddress[]): string {
    let result = '';
    if (addresses && addresses.length) {
      result = this.constructAddressString(addresses[0]);
    }
    return result;
  }

  /**
   * Returns an address as a flattened string given a structured address.
   * @param address - address in Google profile format.
   */
  public static constructAddressString(address: PostalAddress): string {
    let result = '';
    if (address) {
      result += ((address.addressLines && address.addressLines.length) ? `${address.addressLines[0].trim()}, ` : '');
      result += (address.locality ? `${address.locality}, ` : '');
      result += (address.administrativeArea ? `${address.administrativeArea}, ` : '');
      result += (address.postalCode ? address.postalCode : '');
    }
    return result;
  }

  /**
   * Returns the phone number as a flattened string with only numbers and no special characters.
   * @param phoneNumber - phone number in the Google profile format
   */
  public static constructPhoneNumber(phoneNumber: Phone | string) {
    // If the input parameter is a string, then just use it -- otherwise, its a structured phone number.
    const number = typeof phoneNumber === 'string' ? phoneNumber : phoneNumber.number;
    // Regex says "replace everything that isn't a number with nothing"
    let result = (number || '').trim().replace(/[^0-9]/g, '');
    // If the phone number is missing the +1 prefix, add it.
    // TODO: There may be a better way of doing this than just checking the length...
    if (result.length === 10) {
      result = '1' + result;
    }
    return result;
  }

  /**
   * Give an array of structured phone numbers, returns the first as a flattened
   * string with only numbers and no special characters.
   * @param phoneNumbers - phone number in the Google profile format
   */
  public static extractPhoneNumber(phoneNumbers: Phone[]) {
    let phoneNumber = '';
    if (phoneNumbers && phoneNumbers.length) {
      /* Default to the first phone number */
      phoneNumber = phoneNumbers[0].number;
      for (let i = 0; i < phoneNumbers.length; i++) {
        if (phoneNumbers[i].type === 'MOBILE') {
          /* take the mobile and break if available */
          phoneNumber = phoneNumbers[i].number;
          break;
        } else if (phoneNumbers[i].type === 'LANDLINE') {
          /* take the landline and keep looking if available */
          phoneNumber = phoneNumbers[i].number;
        }
      }
    }
    return phoneNumber;
  }
}
