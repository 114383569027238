<!-- todo: Implement translation of text -->
<div class="modal-header">
  <button type="button" class="btn-reset" aria-label="Close" (click)="_bsModalRef.hide()">
    <i class="icon-WL_icon_close"></i>
  </button>
</div>

<div class="modal-body">
  <h3>{{"submit-to-am-modal.submit_to_account_manager" | translate}}</h3>
  <div class="formContainer" [formGroup]="submitForm">
    <div class="row w-100 form-group">
      <div class="col-12 mt-4">
        <label for="payRate" class="d-block">{{"submit-to-am-modal.submission_rate" | translate}}</label>
        <div class="row">
          <div class="col-4">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text color-primary" id="basic-addon1">$</span>
              </div>
              <input
                id="payRate"
                placeholder="99.999"
                formControlName="payRate"
                type="number"
                min="0.00"
                step="0.01"
                class="form-control input-with-icon-left"
                aria-describedby="pay rate" />
            </div>
            <form-error
              *ngIf="submitForm.controls.payRate.invalid"
              [fieldAlias]="'Submission pay rate'"
              [control]="submitForm.controls.payRate"
              [message]="payRateErrorMsg">
            </form-error>
          </div>
          <div class="col-3">
            <select formControlName="payType" class="form-control">
              <option value="YEARLY">{{'log-interview-modal.money_per_year' | translate}}</option>
              <option value="HOURLY">{{'log-interview-modal.money_per_hour' | translate}}</option>
            </select>
            <form-error *ngIf="submitForm.controls" [fieldAlias]="'Pay type'" [control]="submitForm.controls.payType">
            </form-error>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row w-100">
      <div class="col-4">
        <label for="submissionSource" class="d-block">{{"submit-to-am-modal.submission_source" | translate}}</label>
        <select id="submissionSource" formControlName="submissionSource" class="form-control">
          <option [ngValue]="null">select source</option>
          <option *ngFor="let val of submissionSourceVals | keyvalue : preserveOrder" [value]="val.key">{{val.value}}</option>
        </select>
        <form-error *ngIf="submitForm.controls" [fieldAlias]="'Submission source'" [control]="submitForm.controls.submissionSource">
        </form-error>
      </div>
    </div>

    <ng-container *ngIf="attachments.length > 0">
      <h4>Attach files</h4>
      <div *ngFor="let attachment of attachments" class="attachment-row">
        <div class="form-check-container">
          <input class="form-check-input" type="checkbox" [checked]="attachment?.willSubmit" />
          <span class="checkmark" (click)="handleWillSubmit(attachment)"></span>
        </div>
        <div class="attachment-info">
          <p class="attachment-name">{{ attachment?.name ? attachment.name : attachment?.path }}</p>
          <p class="attachment-upload">{{ attachment?.category }} &#x2022; Uploaded {{ formatDatetime(attachment?.lastUpdatedDateTime) }} {{ attachment?.attached_by_user ? "by" : ""}} {{ attachment?.attached_by_user }}</p>
        </div>
      </div>
    </ng-container>

    <div *ngIf="!isUploadFormVisible" class="upload-btn-container">
      <button class="upload-btn" (click)="showUploadForm()">+</button>
      <span>Upload new file</span>
    </div>
    <div class="form-group" *ngIf="isUploadFormVisible">
      <app-add-files-modal 
        [initialState]="initialState" 
        (addToUploadedAttachments)="handleAddToUploadedAttachments($event)"
        (removeUploadedAttachment)="handleRemoveFromUploadedAttachments($event)"
      ></app-add-files-modal>
    </div>

    <div class="form-group">
      <label for="emailBody" class="d-block">{{"submit-to-am-modal.submittal_text" | translate}}</label>
      <app-ckeditor
        id="emailBody"
        class="text-box w-100"
        [parentForm]="submitForm"
        formConName="emailBody"
        height="120"
        [enableMentions]="true">
      </app-ckeditor>
      <form-error [fieldAlias]="'Submittal text'" *ngIf="submitForm.controls" [control]="submitForm.controls.emailBody"></form-error>
    </div>
  </div>
</div>

<div class="modal-footer">
  <ul class="hz-btn-list text-right">
    <li>
      <button type="button" class="btn rds--button--primary" aria-label="discard" (click)="_bsModalRef.hide()">
        {{"submit-to-am-modal.discard" | translate}}
      </button>
    </li>
    <li>
      <button type="button" class="btn rds--button--secondary" aria-label="Save" (click)="submitFormClick()">
        {{"submit-to-am-modal.submit" | translate}}
      </button>
    </li>
  </ul>
</div>