import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Job} from "../../models/external/job.model";
import {JobForm} from "../../models/external/job-form.model";
import {ApiService} from "../../services/api/api.service";
import {JobOrderService} from "../../services/job-order/job-order.service";
import moment from "moment";
import {OrderType} from "../../models/internal/job-form.interface";
import {NotificationTypes} from "../../models/internal/notifications-data.model";
import {AuthService} from "../../services/auth/auth.service";
import {NotificationsService} from "../../services/notifications/notifications.service";
import {ToastClass, ToastService} from "../../services/toast";
import {TranslateService} from "@ngx-translate/core";
import {ActiveStatus} from "../../models/internal/process-statuses.interface";

@Component({
  selector: 'update-job-status-modal',
  templateUrl: './update-job-status-modal.component.html',
  styleUrls: ['./update-job-status-modal.component.scss']
})
export class UpdateJobStatusModalComponent implements OnInit {

  jobData: Job;
  jobTitle: string;
  customerName: string;
  customerPriority: number;
  frontOfficeId: string;
  employmentType: string;
  jobStatus: string;
  elasticJobForm: JobForm;
  updateFailed: boolean = false;
  jobStartDate: string;
  jobEndDate: string;
  isEndDateSameAsStartDate: boolean = false;
  datePickerConfig = {
    dateInputFormat: 'MM/DD/YYYY',
    showWeekNumbers: false
  };
  disableUpdateButton: boolean = false;
  disableEndDateInput: boolean = false;
  jobEndDateRequired: boolean = false;
  changeFields: string[] = [];

  employmentTypesOptions = [
    { value: "PERMANENT", placeholder: "Permanent" },
    { value: "CONTRACT_TO_HIRE", placeholder: "Contract to hire" },
    { value: "CONTRACTOR", placeholder: "Contract" },
    { value: "SOLUTIONS", placeholder: "Solutions" }
  ]

  get isClientOrder(): boolean { return this.jobData?.allbirds_metadata?.order_type !== OrderType.PIPELINE; }
  get startDateAsDateObject(): Date { return new Date(this.jobStartDate); }
  get isJobClosed(): boolean { return !ActiveStatus.includes(this.jobData?.allbirds_metadata?.order_status); }

  constructor(
    public _bsModalRef: BsModalRef,
    private _api: ApiService,
    private _jobOrderService: JobOrderService,
    private _auth: AuthService,
    private _notificationService: NotificationsService,
    private _toastService: ToastService,
    private _translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.initData();
    this.initElasticJobForm();
  }

  ngOnDestroy() {
  }

  initElasticJobForm() {
    if (this.jobData?.allbirds_metadata?.allbirds_job_id) {
      this._api.getJob(this.jobData.allbirds_metadata.allbirds_job_id, true).subscribe(data => {
        if (data) {
          console.log('Loaded job in modal: ', data);
          this.elasticJobForm = data;
        }
      }, err => {
        this.updateFailed = true;
      });
    }
  }

  initData() {
    if (this.jobData?.allbirds_metadata?.order_status) {
      this.jobStatus = this.jobData.allbirds_metadata.order_status;
    }
    if (this.jobData?.jobStartTime) {
      this.jobStartDate = this.jobData.jobStartTime.format('MM/DD/YY');
    }
    if (this.jobData?.jobEndTime) {
      this.jobEndDate = this.jobData.jobEndTime.format('MM/DD/YYYY');
    }
    if (this.jobData?.allbirds_metadata?.customer_name) {
      this.customerName = this.jobData.allbirds_metadata.customer_name;
    }
    if (this.jobData?.allbirds_metadata.customer_priority) {
      this.customerPriority = this.jobData.allbirds_metadata.customer_priority;
    }
    if (this.jobData?.allbirds_metadata.front_office_id) {
      this.frontOfficeId = this.jobData.allbirds_metadata.front_office_id;
    }
    if (this.jobData?.employmentTypes && this.jobData?.employmentTypes[0]) {
      this.employmentType = this.employmentTypesOptions
        .filter(obj => obj.value === this.jobData.employmentTypes[0])
        .map(obj => obj.placeholder)[0];
    }
    if (this.jobData?.title) {
      this.jobTitle = this.jobData.title;
    }

    if (this.employmentType && this.employmentType === 'Permanent') {
      this.disableEndDateInput = true;
    }
  }

  setJobStatus(jobStatusEvent: any) {
    if (this.elasticJobForm) {
      if (jobStatusEvent.target.value !== 'Unqualified' && (!this.jobEndDate || this.jobEndDate.length === 0) && this.employmentType !== 'Permanent') {
        this.disableUpdateButton = true;
        this.jobEndDateRequired = true;
        this.jobStatus = jobStatusEvent.target.value;
      } else {
        this.disableUpdateButton = false;
        this.updateFailed = false;
        this.jobEndDateRequired = false;
        this.jobStatus = jobStatusEvent.target.value;
      }
      this.elasticJobForm.order_status = this.jobStatus;
      this.changeFields.push('order_status');
    } else {
      this.disableUpdateButton = true;
    }
  }

  setStartDate(jobStartDateEvent: any) {
      if (this.elasticJobForm && jobStartDateEvent !== undefined) {
        this.jobStartDate = jobStartDateEvent;
        this.elasticJobForm.jobStartTime = moment(this.jobStartDate).toDate();
        this.changeFields.push('jobStartTime');
        this.disableUpdateButton = false;
        this.updateFailed = false;
      } else {
        this.disableUpdateButton = true;
      }
  }

  setEndDate(jobEndDateEvent: any) {
    if (this.elasticJobForm && jobEndDateEvent !== undefined) {
      this.jobEndDate = jobEndDateEvent;
      this.elasticJobForm.jobEndTime = moment(this.jobEndDate).toDate();
      this.changeFields.push('jobEndTime');
      this.disableUpdateButton = false;
      this.updateFailed = false;
      if (this.jobEndDateRequired) {
        this.elasticJobForm.order_status = this.jobStatus;
        this.changeFields.push('order_status');
      }
      this.isEndDateSameAsStartDate = false;
      const endDateObj = new Date(this.jobEndDate);
      const startDateObj = new Date(this.jobStartDate);

      // Convert Date objects to timestamps for comparison
      const endDateTime = endDateObj.getTime();
      const startDateTime = startDateObj.getTime();

      if (endDateTime <= startDateTime) {
        this.isEndDateSameAsStartDate = true;
        this.disableUpdateButton = true;
      }
      this.jobEndDateRequired = false;
    } else if (jobEndDateEvent === undefined && this.jobStatus === 'Unqualified') {
      this.jobEndDate = jobEndDateEvent;
      this.elasticJobForm.jobEndTime = null;
      this.disableUpdateButton = false;
      this.updateFailed = false;
      this.changeFields.push('jobEndTime');
      this.isEndDateSameAsStartDate = false;
    } else {
      this.disableUpdateButton = true;
    }
  }

  notifyStatusChanged() {
    if (this.changeFields.includes('order_status') && this.elasticJobForm.front_office_id) {
      const notificationObject = this._notificationService.getNotificationObject(this.elasticJobForm, { 'order_status': this.jobStatus });

      const sub = this._notificationService.sendNotification(notificationObject).subscribe(() => {
        sub.unsubscribe();
      });
    }
  }

  updateJob() {
    const changeFieldsUniq = [...new Set(this.changeFields)];
    console.log('Updating job fields: ', changeFieldsUniq);
    if (this.changeFields.length) {
      this._api.updateJob(this.elasticJobForm, changeFieldsUniq).subscribe((data) => {
        if (data) {
          this.jobData.jobStartTime = this.jobStartDate ? moment(this.jobStartDate) : null;
          this.jobData.jobEndTime = this.jobEndDate ? moment(this.jobEndDate) : null;
          this.jobData.allbirds_metadata.order_status = this.jobStatus;
          this.notifyStatusChanged();
          this._jobOrderService.storeJobDetails(this.jobData);
          this.publishJob(changeFieldsUniq);
        }
      }, err => {
        this.failureToast(err);
      });
    } else {
      this.disableUpdateButton = false;
      this.jobEndDateRequired = false;
      this.isEndDateSameAsStartDate = false;
      this._bsModalRef.hide();
    }
  }

  successToast() {
    if (this._bsModalRef) {
      this._bsModalRef.hide();
      const trSuccess = this._translateService.instant('update-job-status-modal.job_update_success', {
        'value': this.jobData.internalTitle || this.jobData.title,
      });
      this._toastService.showToast(trSuccess, { cssClass: ToastClass.SUCCESS });
    }
  }

  failureToast(err: any) {
    this.updateFailed = true;
    this.disableUpdateButton = true;
    const trError = this._translateService.instant('update-job-status-modal.job_update_failure');
    this._toastService.showToastNoTranslation(trError, { cssClass: ToastClass.DANGER });
    console.log('Job update failed', err);
  }

  publishJob(changedFields: string[]) {
    const interviewObj = this._jobOrderService.getInterviewObject(this.jobData, this._auth.user);
    this._api.publishJob(this.elasticJobForm.allbirds_job_id, interviewObj, changedFields).subscribe(() => {
        this.successToast();
      }, (err: any) => {
        this.failureToast(err);
      });
  }

  save() {
    this.updateJob();
  }
}
