import {Component, Input, OnInit} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-remove-all-modal',
  templateUrl: './remove-all-modal.component.html',
  styleUrls: ['./remove-all-modal.component.scss']
})
export class RemoveAllModalComponent implements OnInit {

  @Input() deleteAllBookmarks: any;
  constructor(public _bsModalRef: BsModalRef) { }

  ngOnInit() {

  }

  closeDialog(): void {
    this._bsModalRef.hide();
  }

  remove(): void {
    this.deleteAllBookmarks();
  }

}
