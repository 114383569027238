<!-- This iframe continually refreshes the Google IAP token so that the
    user's session does not time out while the app is in use -->
<!-- see https://cloud.google.com/iap/docs/sessions-howto -->
<div class="loader-container show" *ngIf="loading.loadingSpinner">
    <div class="loader show">
        <div id='loading-logo'></div>
        <div id='loading-wereld'></div>
        <div id='loading-fiets'></div>
    </div>
</div>
<div *ngIf="auth.isLoading$ | async; else loaded">

</div>
<ng-template #loaded>

<section *ngIf="auth.isAuthenticated$ | async">
    <div *ngIf="_auth.user">

    <!-- A sidebar -->
    <div class="sidebar-container">
        <ng-container *ngComponentOutlet="sideBarComponent">
        </ng-container>
    </div>
    <!-- A sidebar END -->

    <!-- A navbar & header -->
    <div>
        <ng-container *ngComponentOutlet="navBarComponent">
        </ng-container>
    </div>
    <!-- A navbar & header END-->

    <!-- Page content -->
    <div class="content" [ngClass]="{'navbarMargin': navBarComponent}">
        <router-outlet></router-outlet>
    </div>

    <!-- Toast container -->
    <app-toast></app-toast>

    <!-- Platform alert banner -->
    <platform-alert-banner></platform-alert-banner>
</div>
</section>
</ng-template>
