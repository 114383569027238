<div class="modal-header">
    <button type="button" class="close-btn" aria-label="Close" (click)="closeDialog()">
        <i class="icon-WL_icon_close"></i>
    </button>
</div>

<div class="modal-body">
    <ng-container *ngTemplateOutlet="isStepOne ? stepOne : stepTwo"></ng-container>
</div>

<div id="assign-job-footer" class="modal-footer">
    <button class="btn rds--button--primary" (click)="closeDialog()">{{'add-to-list-a-modal.cancel' |
        translate}}</button>
    <button *ngIf="isStepOne" class="btn btn-primary" (click)="toggleStep()"
        [disabled]="!listToAddId">{{'add-to-list-a-modal.continue' | translate}}</button>
    <button *ngIf="!isStepOne" class="btn btn-primary" (click)="addToList()">{{'add-to-list-a-modal.add_to_list' |
        translate}}</button>
</div>

<!-- Step one -->
<ng-template #stepOne>
    <div class="title-header">
        <h2 *ngIf="!isMassAddToList">
            <span class="step"> 1/2 </span>{{'add-to-list-a-modal.add' | translate: trVariable1}}
        </h2>
        <h2 *ngIf="isMassAddToList">
            <span class="step"> 1/2 </span>Add talent to a list
        </h2>
        <h3 class="subtitle">{{'add-to-list-a-modal.select_the_list' | translate}}</h3>
    </div>

    <div class="view-search">
        <div class="view mr-3">
            <label>{{'add-to-list-a-modal.view' | translate}}</label>
            <select (change)="setLists($event)" class="custom-select selectDropDown">
                <option value="private">{{'add-to-list-a-modal.private_lists' | translate}}</option>
                <option value="shared">{{'add-to-list-a-modal.shared_lists' | translate}}</option>
            </select>
        </div>
        <div class="list-name">
            <label>{{'add-to-list-a-modal.list_name' | translate}}</label>
            <job-order-input [(inputModel)]="query" [field]="'name'" [metadata]="false" [restrictedValues]="true"
                [useAsIsFlag]="false" [disableOnSelection]="true" [typeAheadField]="'name'" [showResultsOnFocus]="true"
                (setState)="setValue($event)" (suggest)="suggester($event)">
            </job-order-input>
        </div>
        <div> <button class="btn rds--button--primary" (click)="searchListByName()"
                [disabled]="!query || query === '' ">{{'add-to-list-a-modal.search' | translate}}</button>
        </div>

        <div class="new-list-btn">
            <button class="btn rds--button--primary btn-icon" (click)="initiateNewList()">
                <span class="icon"></span>
                <span>create new list</span>
            </button>
        </div>
    </div>

    <div *ngIf="isNewList" class="new-list">
        <p><b>Create a new list</b></p>
        <form [formGroup]="newListForm" class="new-list-form">
            <input type="text" [ngModel]="listName" formControlName="listName" class="form-control"
                placeholder="{{'new-private-list-modal.give_your_list' | translate}}" />            
                <button class="btn rds--button--primary" (click)="cancelNewList()">{{'new-private-list-modal.cancel' |
                translate}}</button>
            <button class="btn btn-primary" [disabled]="!newListForm.valid"
                (click)="saveNewList()">{{'new-private-list-modal.save' | translate}}</button>
        </form>
        <div class="alert alert-danger mt-3" *ngIf="newListForm.controls['listName'].hasError('maxlength')">
            List name should be less than 30 characters.
        </div>
        <div class="alert alert-danger mt-3" *ngIf="newListForm.controls['listName'].hasError('pattern')">
            Special characters are not allowed</div>
    </div>


    <div *ngIf="displayedLists && displayedLists.length > 0" class="cards-container">
        <div *ngFor="let list of displayedLists" class="list-card-container"
            [tooltip]="isMassAddToList ? list.tooltip : 'The candidate is already included in this list'"
            [isDisabled]="isMassAddToList ? !list.tooltip : !disabledLists.includes(list.id)">
            <div (click)="selectListToAdd(list.id)"
                [ngClass]="{'selected-list': list.id === listToAddId, 'list-disabled': disabledLists.includes(list.id)}">
                <recruitment-list-card [cardType]="'custom'" [title]="list.name" [listID]="list.id"
                    [currentSelectedListId]="currentId" [count]="list.talent_count" [date]="list.creation_date"
                    [cancelNavigation]="true" [showToolTip]="false">
                </recruitment-list-card>
            </div>
        </div>
    </div>
</ng-template>

<!-- Step two -->
<ng-template #stepTwo>
    <div class="title-header">
        <h2 *ngIf="!isMassAddToList">
            <span><i (click)="toggleStep()" class="icon icon-WL_icon_chevron-left mr-3 color-primary"></i></span>
            <span class="step">2/2</span>{{'add-to-list-a-modal.add' | translate: trVariable1}}
        </h2>
        <h2 *ngIf="isMassAddToList">
            <span><i (click)="toggleStep()" class="icon icon-WL_icon_chevron-left mr-3 color-primary"></i></span>
            <span class="step"> 2/2 </span>Add talent to a list
        </h2>
        <h3 class="subtitle">{{'add-to-list-a-modal.add_any_remarks' | translate}}</h3>
    </div>
    <list-info [isModal]="'true'" (formValue)="setRemarksValue($event)"></list-info>
</ng-template>

<!-- drop down template -->
<ng-template #default let-query="query">
    <div class="">
        <div id="suggest-box" class="">
            <div class="" container="body">
                <span>{{query}}</span>
            </div>
        </div>
    </div>
</ng-template>