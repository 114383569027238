import { TalentListProfileInfo } from './../../../../shared/models/external/process/talent-list-profile.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { List } from '../../../../shared/models/external/list.model';
import { ListTalent } from '../../../../shared/models/external/list-talent.model';
import moment from 'moment';
import { ApiService } from '../../../../shared/services/api/api.service';
import { RecentActivityList } from 'src/app/shared/models/internal/recent-activities-list.model';

@Injectable({
  providedIn: 'root'
})
export class CustomListService {
  private refreshSource = new Subject<number>();
  refreshObservable = this.refreshSource.asObservable();

  private listSource = new Subject<List>();
  listObservable = this.listSource.asObservable();

  private listOfListsSource = new BehaviorSubject<List[]>(null);
  listOfListsObservable = this.listOfListsSource.asObservable();

  private sharedListSource = new BehaviorSubject<List[]>(null);
  sharedListObservable = this.sharedListSource.asObservable();

  private recActivityListSource = new Subject<RecentActivityList>();
  recentActivityListObservable = this.recActivityListSource.asObservable();


  private talentSource = new BehaviorSubject<ListTalent>(null);
  talentObservable = this.talentSource.asObservable();

  constructor(public _api: ApiService) { }

  buildNewListObject(listName?: string): List {
    return new List({
      id: null,
      name: listName || '',
      owner_full_name: '',
      owner_first_name: '',
      owner_last_name: '',
      owner_back_office_id: '',
      creation_date: null,
      last_update_date: null,
      status: null,
      talent_count: 0,
      sharing: {
        collaborators_count: 0,
        collaborators: null
      },
      talent: null
    });
  }

  setCurrentList(list: List): void {
    this.listSource.next(list);
  }

  setCurrentActivityList(list: RecentActivityList): void {
    console.log('setCurrentActivityList');
    this.recActivityListSource.next(list);
  }

  refresh() {
    this.refreshSource.next(Math.random());
  }

  setCurrentListOfLists(): void {
    const searchListSubs = this._api.searchAllPrivateLists()
      .subscribe(data => {
        this.listOfListsSource.next(data);
        searchListSubs.unsubscribe();
      }, err => console.log(err));
  }

  setCurrentSharedListOfLists(): void {
    const searchSharedListSubs = this._api.searchAllSharedLists()
      .subscribe(data => {
        this.sharedListSource.next(data);
        searchSharedListSubs.unsubscribe();
      }, err => console.log(err));
  }

  setRecentActivityListOfLists(countOnly: boolean): void {
    const body = {
      'countOnly': countOnly
    }
    const recentActivityListSubs = this._api.getRecentActivityLists(body)
      .subscribe(data => {

        //this.recActivityListSource.next(data);
        recentActivityListSubs.unsubscribe();
      }, err => console.log(err));
  }

  setCurentTalent(talent: ListTalent): void {
    this.talentSource.next(talent);
  }

  buildNewTalent(talentObj: any): ListTalent {
    return new ListTalent({
      google_id: talentObj.name,
      external_id: talentObj.externalId,
      date_added: moment(),
      date_updated: moment(),
      general_notes: '',
      next_action: '',
      first_name: talentObj.personNames[0].structuredName.givenName,
      last_name: talentObj.personNames[0].structuredName.familyName
    });
  }

  buildNewTalentFromProfile(talentObj: TalentListProfileInfo): ListTalent {
    return new ListTalent({
      google_id: talentObj.profileId,
      external_id: talentObj.externalId,
      date_added: moment(),
      date_updated: moment(),
      general_notes: '',
      next_action: '',
      first_name: talentObj.personNames[0].structuredName.givenName,
      last_name: talentObj.personNames[0].structuredName.familyName
    });
  }

}
