import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ApplicationState } from '../../../../models/internal/application-state.interface';
import { TalentProcessService } from '../../../../services/talent-process/talent-process.service';
import { Subscription } from 'rxjs';
import { UtilityService } from '../../../../services/utility/utility.service';

@Component({
  selector: 'app-talent-process-cta',
  templateUrl: './talent-process-cta.component.html',
  styleUrls: ['./talent-process-cta.component.scss']
})
export class TalentProcessCtaComponent implements OnInit, OnChanges, OnDestroy {

  /*
   Allows you to pass in a specific disabled state.
  */
  @Input() state: ApplicationState;
  @Input() id?: string;
  @Input() label: string;
  @Input() cssClasses: string;
  @Input() invertColor: boolean;
  @Input() useButton = true;
  @Output() execute = new EventEmitter<MouseEvent>();

  // Subscription for the global selected application state.
  state$: Subscription;

  constructor(
    private _process: TalentProcessService
  ) { }

  ngOnInit(): void {
    this.listenToSelectedApplicationState();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.state) {
      const givenState = changes.state.currentValue;
      const globalState = this._process.selectedApplicationStateSubject.getValue();
      if (givenState.disabled) {
        this.state = givenState;
      } else {
        this.state = globalState;
      }
    }
  }

  ngOnDestroy(): void {
    UtilityService.destroySubscription(this.state$);
  }

  /**
   * Listen to the global application state.
   */
  listenToSelectedApplicationState(): void {
    this.state$ = this._process.selectedApplicationState
      .subscribe((state: ApplicationState) => {
        this.state = Object.assign({}, state);
      });
  }

  getCssClass() {
    let c;
    if (this.useButton) {
      c = 'btn ';
      c += this.invertColor ? 'rds--button--primary' : 'rds--button--secondary';
    }
    if (this.cssClasses) {
      c += ` ${this.cssClasses}`;
    }
    return c;
  }

  attemptExecute(event: MouseEvent) {
    if (this.state && this.state.disabled) {
      return;
    }
    this.execute.emit(event);
  }

}
