import { Component, Input, NgZone, OnChanges, OnInit, SimpleChanges, ViewChildren, AfterViewInit, QueryList, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { PdfJsViewerComponent} from 'ng2-pdfjs-viewer';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TalentResumeModalComponent } from './talent-resume-modal/talent-resume-modal.component';
import { TalentProcessService } from 'src/app/shared/services/talent-process/talent-process.service';

import { finalize } from 'rxjs/operators';
import { TalentService } from 'src/app/shared/services/talent/talent.service';
import { PaginateService } from 'src/app/shared/services/paginate/paginate.service';
import { ToastClass, ToastService } from 'src/app/shared/services/toast';
import { CacheService } from 'src/app/shared/services/cache/cache.service';
import { ModalService } from '../../../services/modal/modal.service';
import moment, { Moment } from 'moment';
import { Profile } from '../../../models/external/profile.model';
import { Application } from '../../../models/external/application.model';
import { ApiService } from '../../../services/api/api.service';
import { ExplainService } from 'src/app/shared/services/explain/explain.service';
import { EventEmitter } from '@angular/core';
import { AngularFirePerformance } from '@angular/fire/performance';


@Component({
  selector: 'app-talent-resume',
  templateUrl: './talent-resume.component.html',
  styleUrls: ['./talent-resume.component.scss']
})
export class TalentResumeComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChildren('pdfViewer') public viewers: QueryList<PdfJsViewerComponent>;
  private pdfViewer: PdfJsViewerComponent;
  @Input() currentTalent: Profile | Application;
  @Input() nextTalent: any;
  @Input() previousTalent: any;
  @Output() resumeError = new EventEmitter();
  pdf: Blob;
  modalRef: BsModalRef;
  showSpinner: boolean;

  errors: any = null;
  errorSub: Subscription;
  resumeLastUpdatedDate: Moment;
  resumeLoadingTrace: any;

  constructor(
    private _api: ApiService,
    public talentService: TalentService,
    private modalService: ModalService,
    private zone: NgZone,
    private paginate: PaginateService<Application | Profile>,
    private _toast: ToastService,
    private _cache: CacheService,
    private talentProcess: TalentProcessService,
    private _explain: ExplainService,
    private performance: AngularFirePerformance
  ) { }

  async ngOnInit() {
    // console.log('initializing talent resume component')
    // console.log('RESUME_TALENT(previous): ', this.previousTalent.name);
    // console.log('RESUME_TALENT(current): ', this.currentTalent.name);
    // console.log('RESUME_TALENT(next): ', this.nextTalent.name);
    this.listenForPdf();
    this.listenForResumeErrors();
    this.listenForResumeLastUpdatedDate();
    this.resumeLoadingTrace = await this.performance.trace('talent-resume-loading');
    this.resumeLoadingTrace.start(); // firebase performance monitoring
  }

  public ngAfterViewInit (): void {
    this.viewers.changes.subscribe((vs: QueryList <any>) => {
      // console.log('update to resume link in viewer')
      this.pdfViewer = vs.first;
      if (this.pdf) this.displayPDF(this.pdf);
    });
  }

  getCachedProfile(application: Application): Profile | undefined {
    return this._cache.loadProfile(application.profile);
  }

  listenForResumeLastUpdatedDate () {
    // I saw that differet processes update these date fields different from FOs, APIs etc
    // that is why instead of picking one depending on existence, we should select the most recent update date
    this.talentProcess.selectedProfile.subscribe(data => {
      let date1 = null;
      let date2 = null;
      // Extract the first date if it exists
      if (data.allbirds_metadata?.resumeLastUpdatedDate) {
        date1 = moment(data.allbirds_metadata.resumeLastUpdatedDate);
      }
      // Extract the second date if it exists
      if (data.resumeUpdateTime && data.resumeUpdateTime.seconds) {
        date2 = moment(data.resumeUpdateTime.seconds * 1000);
      }
      // Compare the dates and assign the most recent one
      if (date1 && date2) {
        this.resumeLastUpdatedDate = date1.isAfter(date2) ? date1 : date2;
      } else {
        this.resumeLastUpdatedDate = date1 || date2;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {}

  listenForPdf() {
    this.paginate.resumeObservable.subscribe(url => {
      if (url) {
        this.displayPDF(url);
      }
    });
  }

  displayPDF (blob: Blob) {
    // postponing the resume viewer update to the next iteration of the event loop seems to solve
    // a display bug. Not sure why. - JDS
    // console.log('DISPLAY_PDF: ', ((this.paginate || {} as PaginateService<any>).currentTalent || {}).name);
    setTimeout(() => {
      this.pdf = blob;
      if (this.pdfViewer) {
        this.pdfViewer.pdfSrc = blob;
        this.pdfViewer.refresh();
      }
    });
  }

  // highlight search terms
  pdfLoaded ($event: any) {
    // use 'findagain' instead of 'find' to avoid debounce since we don't need it here - JDS
    // https://github.com/mozilla/pdf.js/blob/master/web/pdf_find_controller.js#L286
    if (this._explain.keywords && this._explain.keywords.length) {
      this.pdfViewer.PDFViewerApplication.findController.executeCommand('findagain', {
        caseSensitive: false,
        findPrevious: undefined,
        highlightAll: true,
        phraseSearch: false,
        query: this._explain.keywords.join(' ')
      });
      if (this.resumeLoadingTrace) {
        this.resumeLoadingTrace.stop() // firebase performance monitoring
        this.resumeLoadingTrace = null;
      }
      // console.log(this.pdfViewer.PDFViewerApplication.findController.state())
      // TODO: how better to force viewer to display the first page instead of the first search hit?
      // timeouts suck.
      // setTimeout(() => this.pdfViewer.page = 1, 1000);
    }
    this.pdfViewer.PDFViewerApplication.eventBus.on('updatefindcontrolstate', (data:any) => {
       data.source._selected.pageIdx = 0
      })
  }

  listenForResumeErrors() {
    this.talentService.resumeErrorObservable
      .subscribe(errors => {
        this.errors = errors;
        if (this.errors) {
          this.resumeError.emit(this.errors);
        }
      });
  }

  removeBoolean (searchQuery: string) {
    if (!searchQuery || typeof searchQuery !== 'string') return searchQuery;
    let parsed = searchQuery.replace(' AND ', ' ').replace(' OR ', ' ')
      .replace(' NOT ', ' ').replace('(', ' ').replace(')', ' ').trim();
    return parsed;
  }

  openModal() {
    this.modalRef = this.modalService.show(TalentResumeModalComponent, {
      initialState: {
        curentTalent: this.currentTalent,
        nextTalent: this.nextTalent,
        previousTalent: this.previousTalent,
        pdf: this.pdf
      }
    });
    this.modalRef.content.closeBtnName = 'Close';
  }

  download(talent: Profile | Application): void {
    console.log('talent for downloading resume: ', talent)
    if (talent.isApplication()) {
      console.log('talent is an application')
      // If the selected talent is an application object, fetch the corresponding profile
      // object and recursively call this method on the retrieved profile.
      if (talent.randstad_process.candidateFrontOfficeID) {
        console.log('loading talent from the cache')
        const talentProfile = this._cache.loadProfile(talent.profile);
        if (talentProfile) {
          console.log('found talent in cache: ', talentProfile)
          return this.download(talentProfile);
        }
      }
    }
    // If we made it here, then we couldn't find a talent profile in the cache for the application passed in.
    const talentId = talent.externalId;
    console.log('moved past caching, using talent ID for download: ', talentId)
    if (talentId) {
      console.log('fetching download url')
      return this.fetchDownloadURL(talentId);
    }
    // If all else fails...
    return this.showResumeErrorToast();
  }

  fetchDownloadURL(talentExternalId?: string) {
    // Fetch only the resume link and if one exists, open it.
    this.showSpinner = true;
    this._api.getResumePreviewByExternalID(
      talentExternalId,
      true
    ).pipe(
      finalize(() => this.showSpinner = false)
    ).subscribe((res: any) => {
      if (res && res.resumeUrl) {
        return window.open(res.resumeUrl);
      } else {
        return this.showResumeErrorToast();
      }
    }, err => {
      // console.log('[fetchDownloadURL]', err);
      return this.showResumeErrorToast();
    });
  }

  showResumeErrorToast(msg?: string): void {

    const errorMsg = msg || 'talent-resume.error';
    const toastCfg = {
      cssClass: ToastClass.DANGER
    };
    this._toast.showToast(errorMsg, toastCfg);
  }

}
