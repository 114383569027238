import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightText'
})
export class HighlightTextPipe implements PipeTransform {

  transform(str: string, query: string[]): string {
    const t0 = performance.now();
    if (str) {
      const queryStr = query.join(' ');
      const strNew = str.slice();
      const queryIndex = str.toLocaleLowerCase().indexOf(queryStr);
      if (queryIndex > -1) {
        const left_str = strNew.substring(0, queryIndex);
        const right_str = strNew.substring(str.substring(0, queryIndex).length + queryStr.length, str.length);
        const result = left_str + '<span class="highlight">' + strNew.substring(queryIndex, queryIndex + queryStr.length) + '</span>' + right_str;
        const t1 = performance.now();
        console.log(t1 - t0, 'milliseconds');
        return result;
      } else {
        return str;
      }
    }
    return null;
  }

}
