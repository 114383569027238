import { createModel, Mapping, Model } from './model';
import { Profiles } from '@allbirds-ui/allbirds-types';
import { Assignment } from './assignment.model';
import { Serial } from './decorators/serial.decorator';
import { IsoToMoment } from './serializers/moment.serializer';
import { Moment } from 'moment';
export import RawProfileMetadata = Profiles.AllbirdsProfileMetadata;
import { Internal } from './decorators/internal.decorator';

class ProfileMetadataMapping extends Mapping<RawProfileMetadata> {
  @Serial([Assignment]) assignments?: Assignment[];
  @Serial(IsoToMoment) resumeLastUpdatedDate?: Moment;
  @Internal() FOTalentStatus?: string;
}

export interface ProfileMetadata extends Model<ProfileMetadataMapping> {}

export const ProfileMetadata = createModel<ProfileMetadata>(ProfileMetadataMapping);
