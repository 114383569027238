<div class="modal-header">
  <button type="button" class="btn-reset" aria-label="Close" (click)="_bsModalRef.hide()">
    <i class="icon-WL_icon_close"></i>
  </button>
</div>

<div class="modal-body">
  <h2>{{'reject-offer-modal.why_is_the' | translate}}</h2>
  <div class="reject-offer-form" [formGroup]="form">
    <div class="form-group">
      <label for="rejectReason">{{'reject-offer-modal.tell_us_why' | translate}}</label>
      <select class="form-control mw-350" id="rejectReason" formControlName="rejectReason">
        <option value="" disabled selected hidden>{{'reject-offer-modal.select_the_reason' | translate}}</option>
        <option *ngFor="let item of rejectReasonOpts | keyvalue: sortAlphabetically" value="{{ item.key }}">
          {{ item.value }}
        </option>
      </select>
      <form-error [fieldAlias]="'Rejection reason'" [control]="form.controls.rejectReason"></form-error>
    </div>
    <div class="form-group">
      <label for="contactMethod">{{'reject-offer-modal.contact_method' | translate}}</label>
      <select class="form-control mw-350" id="contactMethod" formControlName="contactMethod">
        <option value="" disabled selected hidden>{{'reject-offer-modal.select_a_method' | translate}}</option>
        <option *ngFor="let item of contactMethodOpts | keyvalue: sortAlphabetically" value="{{ item.key }}">
          {{ item.value }}
        </option>
      </select>
      <form-error [fieldAlias]="'Contact method'" [control]="form.controls.contactMethod"></form-error>
    </div>
    <div class="form-group">
      <label for="payRateOffered">{{'reject-offer-modal.pay_rate_offered' | translate}}</label>
      <input type="number" class="form-control mw-150" id="payRateOffered" placeholder="{{'reject-offer-modal.input_amount' | translate}}" formControlName="offeredPayRate" />
      <form-error [fieldAlias]="'Pay rate offered'" [control]="form.controls.offeredPayRate"></form-error>
    </div>
    <div class="form-group">
      <label>{{'reject-offer-modal.has_talent_previously' | translate}}</label>
      <input id="radio-button-1" class="rds--radio-button" type="radio" [value]="true" tabindex="0" formControlName="workPreviouslyPerformed" />
      <label for="radio-button-1" class="rds--radio-button__label">
        <span class="rds--radio-button__appearance"></span>
        {{'reject-offer-modal.yes' | translate}}
      </label>
      <input id="radio-button-2" class="rds--radio-button" type="radio" [value]="false" tabindex="0" formControlName="workPreviouslyPerformed" />
      <label for="radio-button-2" class="rds--radio-button__label">
        <span class="rds--radio-button__appearance"></span>
        {{'reject-offer-modal.no' | translate}}
      </label>
      <form-error [fieldAlias]="'Whether or not this work was previously performed'" [control]="form.controls.workPreviouslyPerformed"></form-error>
    </div>
    <div class="form-group">
      <label for="rejectNote">{{'reject-offer-modal.notes' | translate}}</label>
      <app-ckeditor
        id="hmAvailability"
        class="text-box w-100"
        [parentForm]="form"
        formConName="rejectNote"
        height="120"
        [enableMentions]="true">
      </app-ckeditor>
      <form-error [fieldAlias]="'Rejection note'" [control]="form.controls.rejectNote"></form-error>
    </div>
  </div>
</div>

<div class="modal-footer">
  <ul class="hz-btn-list">
    <li>
      <button type="button" class="btn rds--button--primary" aria-label="cancel" (click)="_bsModalRef.hide()">
        {{'reject-offer-modal.cancel' | translate}}
      </button>
    </li>
    <li>
      <button type="button" class="btn rds--button--secondary" aria-label="submit" (click)="submit()">
        {{'reject-offer-modal.submit' | translate}}
      </button>
    </li>
  </ul>
</div>
