<div class="modal-header">
  <button type="button" class="btn-reset" aria-label="Close" (click)="_bsModalRef.hide()">
    <i class="icon-WL_icon_close"></i>
  </button>
</div>

<div class="modal-body">
  <h2 class="mb-4"><img src="assets/icons/alert-yellow.svg" />{{'feature-unavailable-modal.this_feature_is' | translate}}</h2>
  <p>{{ reason || 'This feature is unavailable.' }}</p>
</div> <!-- / .modal-body -->

<div class="modal-footer">
  <ul class="hz-btn-list">
    <li>
      <button type="button" class="btn rds--button--primary" aria-label="cancel" (click)="_bsModalRef.hide()">{{'feature-unavailable-modal.close' | translate}}</button>
    </li>
  </ul>
</div>