import moment, { Moment } from 'moment';
import { Times } from '@allbirds-ui/allbirds-types';
import { Serial } from './serial';

export interface MomentSerializer<Raw> extends Serial<Raw, Moment> {
}

export interface DateSerializer<Raw> extends Serial<Raw, Date> {
}

const isEmpty = (obj: any) => obj === undefined || obj === null || obj === '';

export const SecToMoment: MomentSerializer<number> = {
  deserialize(raw?) {
    if (isEmpty(raw)) {
      return undefined;
    }
    return moment.unix(raw).utcOffset(UTC_OFFSET);
  },
  serialize(mapped?) {
    if (isEmpty(mapped)) {
      return undefined;
    }
    return mapped.unix();
  }
};

export const IsoToMoment: MomentSerializer<string> = {
  deserialize(raw?) {
    if (isEmpty(raw)) {
      return undefined;
    }
    return moment(raw).utcOffset(UTC_OFFSET);
  },
  serialize(mapped?) {
    /*
     * Checking if mapped is a valid moment object. The scenario where it isn't is for manually logging an interview.
     * The startDate field on log-interview-modal is a text field, not a dedicated date field. This allows for the user
     * to put something like 'anytime' 'asap', etc. They may even type in a valid date. Either way since startDate is
     * defined as an iso_date, and it's really a string the ApplicationMapping isn't able to provide a proper moment
     * object. In these cases, we'll try to convert it to a moment object and if we can't just pass through the value.
     */
    if (isEmpty(mapped)) {
      return undefined;
    } else if (mapped.isValid && mapped.isValid()) {
      return mapped.toISOString();
    }
    const returnVal = moment(mapped);
    return returnVal.isValid() ? returnVal.toISOString() : mapped.toString();
  }
};

export const IsoToDate: DateSerializer<string> = {
  deserialize(raw?) {
    const m = IsoToMoment.deserialize(raw);
    return m && m.toDate();
  },
  serialize(mapped?) {
    const m = mapped && moment(mapped);
    return IsoToMoment.serialize(m);
  }
};

export const TimeOfDayToMoment: MomentSerializer<Times.TimeOfDay> = {
  deserialize(raw?) {
    if (isEmpty(raw)) {
      return undefined;
    }
    return moment(raw).utcOffset(UTC_OFFSET);
  },
  serialize(mapped?) {
    if (isEmpty(mapped)) {
      return undefined;
    }
    return {
      hours: mapped.hours(),
      minutes: mapped.minutes(),
      seconds: mapped.seconds(),
      nanos: 0
    };
  }
};

export const GrpcToMoment: MomentSerializer<Times.GrpcTimestamp> = {
  deserialize(raw?) {
    if (isEmpty(raw)) {
      return undefined;
    }
    return moment.unix(raw.seconds).utcOffset(UTC_OFFSET);
  },
  serialize(mapped?) {
    if (isEmpty(mapped)) {
      return undefined;
    }
    return {
      seconds: mapped.unix(),
      nanos: 0
    };
  }
};

export const GrpcToDate: DateSerializer<Times.GrpcTimestamp> = {
  deserialize(raw?) {
    const m = GrpcToMoment.deserialize(raw);
    return m && m.toDate();
  },
  serialize(mapped?) {
    const m = mapped && moment(mapped);
    return GrpcToMoment.serialize(m);
  }
};

export const GoogleToMoment: MomentSerializer<Times.GoogleDate> = {
  deserialize(raw?) {
    if (isEmpty(raw)) {
      return undefined;
    }
    return moment({
      year: raw.year,
      month: raw.month - 1,
      date: raw.day
    }).utcOffset(UTC_OFFSET);
  },
  serialize(mapped?) {
    if (isEmpty(mapped)) {
      return undefined;
    }
    return {
      year: mapped.year(),
      month: mapped.month() + 1,
      day: mapped.date()
    };
  }
};

// TODO(jason): where should below be?
// TODO(jason): technically moment already converts the parsed timestamp to local timezone, so no need UTC_OFFSET??
const UTC_OFFSET = moment().utcOffset();

/**
 * TODO: Evaluate how to handle with internationalization and
 * countries that don't recognize DST (if there are any that
 * will be supporting AB).
 */
export const OFFSET_TO_TIMEZONE: { [offset: string]: string } = moment().isDST() ? {
  '-04:00': 'EST',
  '-05:00': 'CST',
  '-06:00': 'MST',
  '-07:00': 'PST'
} : {
  '-05:00': 'EST',
  '-06:00': 'CST',
  '-07:00': 'MST',
  '-08:00': 'PST'
};

declare module 'moment' {
  export interface Moment {
    formatDate: (format?: string) => string;
    formatTime: (format?: string) => string;
    formatDatetime: (dateFormat?: string, timeFormat?: string) => string;
  }
}

Object.assign(moment.fn, {
  formatDate(format = 'MMM D, YYYY'): string {
    return this.format(format);
  },

  formatTime(format = 'h:mm A'): string {
    return this.format(format);
  },

  formatDatetime(dateFormat?: string, timeFormat?: string): string {
    const date = this.formatDate(dateFormat);
    const time = this.formatTime(timeFormat);
    const offset = this.format('Z');
    const timezone = OFFSET_TO_TIMEZONE[offset] || `UTC${offset}`;
    return `${date} at ${time} ${timezone}`;
  }
});
