import { AppointmentSchedule } from './appointment/appointment-schedule.model';
import { AppointmentInvite } from './appointment/appointment-invite.model';
import { AppointmentFeedback}  from './appointment/appointment-feedback.model';
import { GrpcToMoment } from './serializers/moment.serializer';
import {Moment} from 'moment';
import {Serial} from 'src/app/shared/models/external/decorators/serial.decorator';
import {createModel, Mapping, Model} from 'src/app/shared/models/external/model';
import {Appointments} from '@allbirds-ui/allbirds-types';
import {IsoToMoment} from 'src/app/shared/models/external/serializers/moment.serializer';
import RawAllbirdsAppointment = Appointments.AllbirdsAppointment;
import { TalentListProfileInfo } from './process/talent-list-profile.model';
import { Internal } from './decorators/internal.decorator';

/**
 * An Allbirds appointment stored in the appointment index
 * @class {AllbirdsAppointmentMapping}
 */
class AllbirdsAppointmentMapping extends Mapping<RawAllbirdsAppointment> {
  @Serial(GrpcToMoment) appointmentDate?: Moment;
  @Serial(AppointmentSchedule) appointment?: AppointmentSchedule;
  @Serial(AppointmentInvite) appointmentInvite?: AppointmentInvite;
  @Serial(AppointmentFeedback) appointmentFeedback?: AppointmentFeedback;
  @Serial(IsoToMoment) lastUpdated?: Moment;
  @Internal() @Serial(TalentListProfileInfo) profileInfo: TalentListProfileInfo;
  profile: string;

  isAppointment(): this is AllbirdsAppointment {
    return true;
  }

  get profileId(): string {
    return this.profile;
  }
}
export interface AllbirdsAppointment extends Model<AllbirdsAppointmentMapping> {}
export const AllbirdsAppointment = createModel<AllbirdsAppointment>(AllbirdsAppointmentMapping);
