import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { TalentProcessService } from '../talent-process/talent-process.service';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../user/user.service';
import { MetricsService } from '../metrics/metrics.service';
import { Activity, ActivityAuthor, ActivityFeedTypes, OfferActivity } from '../../models/internal/activity.interface';
import { GoogleConversationService } from '../google-conversation/google-conversation.service';
import { RT_STEPS, StepInfo } from '../talent-process/process-steps';
import { Application } from '../../models/external/application.model';
import { ClientInterview } from '../../models/external/process/client-interview.model';
import { Prescreening } from '../../models/external/process/prescreening.model';
import { InterviewSchedule } from '../../models/external/process/interview-schedule.model';
import { Interview } from '../../models/external/process/interview.model';
import { InternalSubmission } from '../../models/external/process/internal-submission.model';
import { ClientSubmission } from '../../models/external/process/client-submission.model';
import { Conversation } from '../../models/external/conversation.model';
import { Job } from '../../models/external/job.model';
import { Jobs } from '@allbirds-ui/allbirds-types';
import AllbirdsJobMetadata = Jobs.AllbirdsJobMetadata;
import { JobMetadata } from '../../models/external/job-metadata.model';
import moment from 'moment';

/**
 * For specifics regarding what details are needed for each activity, see the
 * [Confluence page]{@link https://randstadglobal.atlassian.net/wiki/spaces/AL/pages/905773110/Activity+feed}.
 */

export interface ActivityFeedState {
  application: Application;
  activities: Map<string, StepInfo>;
  showFeed: boolean;
}

// Path to fallback image for when there's no user avatar.
const FALLBACK_AVATAR = 'assets/images/avatar/google-default.jpg';

@Injectable({
  providedIn: 'root'
})
export class ActivityFeedService {

  constructor(
    private _talentProcess: TalentProcessService,
    private _auth: AuthService,
    private _user: UserService,
    private _metrics: MetricsService,
    private _googleConversation: GoogleConversationService
  ) { }

  // Used for Google conversation.
  conversationSub: Subscription;

  // If we don't explicitly instantiate our ENUM this way, then
  // it will be undefined at runtime.
  ActivityFeedTypes: typeof ActivityFeedTypes = ActivityFeedTypes;

  /**
   * Sorts activities from newest to oldest.
   * @param activities - array of Activity objects
   */
  static sortActivitiesByDate(activities: Activity[]): void {
    activities.sort((a, b) => b.date?.diff(a.date));
  }

  /**
   * Given a talent application, generates the activity feed by setting up
   * the needed "steps" of the application, fetching and retrieving the profile
   * of users who performed actions for the given application, and then calling
   * the extraction method to parse the given fields into a valid activity feed.
   * @param application - talent application object
   */
  async generateFeed(application: Application, job: Job): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        /**
         * Context: We need to display CE conversation details in the activity feed
         * if it exists and is completed. This information lives in Firestore.
         */
        let conversation;
        if (
          application &&
          application.randstad_process &&
          application.randstad_process.prescreening &&
          application.randstad_process.prescreening.conversationID
        ) {
          // Retrieve the conversation.
          conversation = await this.getConversation(application.randstad_process.prescreening.conversationID);

          // Only display conversations with status = "FINISHED"
          conversation = (
            conversation &&
            conversation.status &&
            conversation.status.toLowerCase() === 'finished'
          ) ? conversation : undefined;
        }

        // Extract all user IDs found on the application object.
        if (application) {
          const backOfficeIDs = this.extractBackOfficeIDs(application, job);
          // If any user IDs were found, we need to query for it.
          if (backOfficeIDs.length) {
            await this._user.getUsersById(backOfficeIDs);
          }
          // Resolve the converted application.
          resolve(this.generateActivityMap(application, job, conversation));
        } else {
          throw new Error('ActivityFeedService.generateFeed: No Application Object');
        }


      } catch (e) {
        reject({ message: e });
      }
    });
  }

  /**
   * Returns a Google CE conversation should one exist for the given conversationId.
   * @param conversationId - Google CE conversation ID
   */
  async getConversation(conversationId: string): Promise<any> {
    /**
     * We wrap this in a Promise so we can use async/await.
     */
    return new Promise((resolve, reject) => {
      this._googleConversation.getConversationForID(conversationId, (conversation: Conversation) => {
        if (typeof conversation === 'object' && conversation != null && Object.keys(conversation).length) {
          return resolve(conversation);
        } else {
          return resolve(undefined);
        }
      });
    });
  }


  /**
   * Given a BackOfficeID, attempts to fetch a user from the cache and then
   * maps the result to an object that abides by the ActivityAuthor interface.
   * @param backOfficeId
   */
  getAuthorDetails(backOfficeId: string): ActivityAuthor {
    const result = {} as ActivityAuthor;
    const author = (backOfficeId && this._user.getFromCache(backOfficeId)) || null;
    if (author) {
      result.name = author.FullName;
      result.email = author.EmailAddr || '';
      result.title = author.JobTitle || 'Recruiter';
      result.backOfficeId = author.BackOfficeID || backOfficeId || '';
      result.frontOfficeId = author.Oprid || '';
      result.avatar = author.avatar || FALLBACK_AVATAR;
    } else {
      /*
       * Before this function is ever executed, we extract all known agent IDs from
       * the application and attempt to fetch the user profile should it not already
       * exist in the cache. That being said, if we reach this else block, then there
       * is either (1) an issue with fetching & caching profiles or (2) a data issue
       * (e.g. an invalid or no backOfficeId; a non-existent profile).
       */
      result.name = backOfficeId || '';
      result.email = backOfficeId || '';
      result.title = backOfficeId || '';
      result.backOfficeId = backOfficeId || '';
      result.frontOfficeId = backOfficeId || '';
      result.avatar = FALLBACK_AVATAR;
    }
    return result;
  }

  /**
   * Returns a Map object containing information for each process
   * step as well as an empty activities array.
   */
  getEmptyFeedMap(): Map<string, StepInfo> {
    /**
     * We use the RT steps even for RGS because there will never be an activity
     * to be extracted from the extra RT step for a RGS talent so the step will
     * never be displayed since its activities.length will equal 0.
     */
    const feed = new Map(RT_STEPS);
    feed.forEach((value, key) => {
      feed.set(key, {
        ...value,
        activities: []
      });
    });
    return feed;
  }

  /**
   * Generates an object containing the activity feed as well as relevant metadata.
   * @param application - talent application object
   * @param conversation - Google conversation object
   */
  generateActivityMap(application: Application, job: Job, conversation?: Conversation): ActivityFeedState {
    const feed = this.getEmptyFeedMap();
    let hasActivity = false;
    if (application && application.randstad_process) {
      const {
        prescreening,
        interviewSchedule,
        interview,
        internalSubmission,
        clientSubmission,
        clientInterview,
        offer,
        lastProcessStep,
        rejected
      } = application.randstad_process;
      // Reused for each step of the map; gets assigned reference to map values.
      let step;
      /**
       * From here on out, we check the truthiness of the nested objects for
       * each process step within [application].randstad_process and extract
       * activities should the object exist.
       */
      if (application) {
        step = feed.get('prescreening');
        step.activities = this.extractTopLevel(application);
        hasActivity = hasActivity || !!step.activities.length;
      }
      if (prescreening) {
        step = feed.get('prescreening');
        step.activities = [...this.extractPrescreening(prescreening, conversation), ...step.activities];
        hasActivity = hasActivity || !!step.activities.length;
      }
      if (interview || interviewSchedule) {
        step = feed.get('interview');
        step.activities = [...this.extractInterview(interview, interviewSchedule), ...step.activities];
        hasActivity = hasActivity || !!step.activities.length;
      }
      if (internalSubmission) {
        step = feed.get('internalSubmission');
        step.activities = this.extractInternalSubmission(internalSubmission, job);
        hasActivity = hasActivity || !!step.activities.length;
      }
      if (clientSubmission) {
        step = feed.get('clientSubmission');
        step.activities = this.extractClientSubmission(clientSubmission);
        hasActivity = hasActivity || !!step.activities.length;
      }
      if (clientInterview) {
        step = feed.get('clientInterview');
        step.activities = this.extractClientInterview(clientInterview);
        hasActivity = hasActivity || !!step.activities.length;
      }
      if (offer) {
        step = feed.get('offer');
        step.activities = this.extractOffer(application);
        hasActivity = hasActivity || !!step.activities.length;
      }
      if (rejected) {
        step = feed.get(lastProcessStep);
        step.activities = [...this.extractRejection(application), ...step.activities];
        hasActivity = hasActivity || !!step.activities.length;
      }
    }
    return {
      application: application,
      activities: feed,
      showFeed: hasActivity
    };
  }

  /**
   * Extracts all top-level (outside of a specific process step) information that
   * constitutes an activity for the activity feed.
   * @param application - talent application object
   */
  extractTopLevel(application: Application): Activity[] {
    const activities: Activity[] = [];
    if (application.randstad_process.addedByAgentID) {
      activities.push({
        type: ActivityFeedTypes.Shortlist,
        author: this.getAuthorDetails(application.randstad_process.addedByAgentID),
        date: application.randstad_process.addedTimestamp
      });
    } else {
      activities.push({
        type: ActivityFeedTypes.Apply,
        date: application.randstad_process.addedTimestamp
      });
    }
    ActivityFeedService.sortActivitiesByDate(activities);
    return activities;
  }

  /**
   * Extracts all information that constitutes an activity for the activity
   * feed from the prescreening step.
   * @param prescreening - [application].randstad_process.prescreening object
   * @param conversation - Google conversation object
   */
  extractPrescreening(prescreening: Prescreening, conversation?: any): Activity[] {
    const activities: Activity[] = [];
    // Skip activity.
    if (prescreening.skipped) {
      activities.push({
        type: ActivityFeedTypes.SkippedStep,
        author: this.getAuthorDetails(prescreening.skippedBy),
        date: prescreening.skippedDate
      });
    }
    // CE Screening sent.
    if (prescreening.initiatedDate) {
      activities.push({
        type: ActivityFeedTypes.CEScreeningSent,
        author: this.getAuthorDetails(prescreening.agentID),
        date: prescreening.initiatedDate,
        details: {
          screeningType: 'chatbot'
        }
      });
    }
    // Manual prescreening.
    if (
      prescreening.isManualPrescreening &&
      prescreening.manualScreeningAnswers
    ) {
      activities.push({
        type: ActivityFeedTypes.ManualScreeningCompleted,
        author: this.getAuthorDetails(prescreening.agentID),
        date: prescreening.completionDate,
        notes: prescreening.manualScreeningAnswers.notes
      });
    }
    // Conversation completed.
    if (
      conversation &&
      conversation.status &&
      conversation.status.toLowerCase() === 'finished'
    ) {
      // TODO: Add details.
      activities.push({
        type: ActivityFeedTypes.CEScreeningCompleted,
        date: conversation.endTime
      });
    }

    ActivityFeedService.sortActivitiesByDate(activities);
    return activities;
  }

  /**
   * Extracts all information that constitutes an activity for the activity
   * feed from the interview and interviewSchedule steps.
   * @param interview - [application].randstad_process.interview object
   * @param interviewSchedule - [application].randstad_process.interviewSchedule object
   */
  extractInterview(interview: Interview, interviewSchedule: InterviewSchedule): Activity[] {
    const activities: Activity[] = [];
    if (interviewSchedule) {
      // CE interview schedule skipped.
      if (interviewSchedule.skipped) {
        activities.push({
          type: ActivityFeedTypes.SkippedStep,
          author: this.getAuthorDetails(interviewSchedule.skippedBy),
          date: interviewSchedule.skippedDate
        });
      }
      // CE interview schedule cancelled.
      if ((interviewSchedule.interviewStatus || '').toLowerCase() === 'cancelled') {
        activities.push({
          type: ActivityFeedTypes.CEInterviewCancelled,
          author: this.getAuthorDetails(interviewSchedule.agentID),
          date: interviewSchedule.canceledDate
        });
      }
      // Added it as part of Recruiter Interview Cancellation
      // Ticket: DF044-6724
      if ((interviewSchedule?.interviewStatus || '') === 'RECRUITER_CANCELLED') {
        activities.push({
          type: ActivityFeedTypes.RecruiterInterviewCancelled,
          author: this.getAuthorDetails(interviewSchedule.cancelledBy),
          date: interviewSchedule.canceledDate
        });
      }
      // CE interview scheduled.
      // if (interviewSchedule.interviewScheduledOn) {
      //   activities.push({
      //     type: ActivityFeedTypes.CEInterviewScheduleSent,
      //     author: this.getNewAuthorDetails(interviewSchedule.agentID),
      //     date: interviewSchedule.interviewScheduledOn
      //   });
      // }
      // Interview schedule was confirmed.
      // changed it since inviteOptionFollowingTimestampWhenScheduled is being changed when the interview was cancelled
      if (interviewSchedule.interviewScheduledOn) {
        activities.push({
          type: ActivityFeedTypes.CEInterviewScheduleConfirmed,
          author: this.getAuthorDetails(interviewSchedule.agentID),
          date: interviewSchedule.interviewScheduledOn
        });
      }
    }

    if (interview) {
      // Interview was conducted.
      if (interview.completionDate) {
        activities.push({
          type: ActivityFeedTypes.RecruiterInterviewPerformed,
          author: this.getAuthorDetails(interview.agentID),
          date: interview.completionDate,
          notes: interview.notes,
          details: {
            interviewType: interview.channel
          }
        });
      }
      // Interview was skipped.
      if (interview.skipped) {
        activities.push({
          type: ActivityFeedTypes.SkippedStep,
          author: this.getAuthorDetails(interview.skippedBy),
          date: interview.skippedDate
        });
      }
    }

    ActivityFeedService.sortActivitiesByDate(activities);
    return activities;
  }

  /**
   * Extracts all information that constitutes an activity for the activity
   * feed from the internal submission step.
   * @param internalSubmission - [application].randstad_process.internalSubmission step
   */
  extractInternalSubmission(internalSubmission: InternalSubmission, job: Job): Activity[] {
    const activities: Activity[] = [];

    if (internalSubmission.skipped) {
      activities.push({
        type: ActivityFeedTypes.SkippedStep,
        author: this.getAuthorDetails(internalSubmission.skippedBy),
        date: internalSubmission.skippedDate
      });
    }

    // if (internalSubmission.outcome === 'proceed') {
    //   activities.push({
    //     type: 'outcome',
    //     outcome: internalSubmission.outcome,
    //     submittedTo: internalSubmission.submittedTo,
    //     activityAuthor: this.getAuthorDetails(internalSubmission.submittedByID),
    //     activityDate: internalSubmission.outcomeDate
    //   }));
    // }

    if (internalSubmission.submission) {
      const recipient = internalSubmission.submittedToID || (job && (job.allbirds_metadata || {} as AllbirdsJobMetadata).published_by_user_front_office_id) || '';
      if(internalSubmission.submissionType)
      {
        activities.push({
          type: ActivityFeedTypes.UpdateSubmission,
          author: this.getAuthorDetails(internalSubmission.submittedByID),
          date: internalSubmission.submissionDate,
          notes: internalSubmission.submission,
          details: {
            recipient: this.getAuthorDetails(recipient)
          },
          activityType:internalSubmission.submissionType
        });
      }
      else
      {
      activities.push({
        type: ActivityFeedTypes.RecruiterAMSubmission,
        author: this.getAuthorDetails(internalSubmission.submittedByID),
        date: internalSubmission.submissionDate,
        notes: internalSubmission.submission,
        details: {
          recipient: this.getAuthorDetails(recipient)
        }
      });
    }
    if(internalSubmission?.priorSubmissions?.length>0)
    {
      internalSubmission?.priorSubmissions.forEach((sub) => {
        const recipient = sub.submittedToID || (job && (job.allbirds_metadata || {} as AllbirdsJobMetadata).published_by_user_front_office_id) || '';
        if(sub.submissionType)
        {
          activities.push({
            type: ActivityFeedTypes.UpdateSubmission,
            author: this.getAuthorDetails(sub.submittedByID),
            date: moment(sub.submissionDate),
            notes: sub.submission,
            details: {
              recipient: this.getAuthorDetails(recipient)
            },
            activityType:sub.submissionType
          });
        }
        else
        {
        activities.push({
          type: ActivityFeedTypes.RecruiterAMSubmission,
          author: this.getAuthorDetails(sub.submittedByID),
          date: moment(sub.submissionDate),
          notes: sub.submission,
          details: {
            recipient: this.getAuthorDetails(recipient)
          }
        });
      }

      })
    }
    }

    ActivityFeedService.sortActivitiesByDate(activities);
    return activities;
  }

  /**
   * Extracts all information that constitutes an activity for the activity
   * feed from the clientSubmission step.
   * @param clientSubmission - [application].randstad_process.clientSubmission object
   */
  extractClientSubmission(clientSubmission: ClientSubmission): Activity[] {
    const activities: Activity[] = [];

    // Client submission was skipped.
    if (clientSubmission.skipped) {
      activities.push({
        type: ActivityFeedTypes.SkippedStep,
        author: this.getAuthorDetails(clientSubmission.skippedBy),
        date: clientSubmission.skippedDate
      });
    }

    // AM/Recruiter submitted to client HM.
    if (clientSubmission.submission) {
      if(clientSubmission.submissionType)
      {
        activities.push({
          type: ActivityFeedTypes.UpdateSubmission,
          author: this.getAuthorDetails(clientSubmission.submittedByID),
          date: clientSubmission.submissionDate,
          notes: clientSubmission.submission,
          activityType:clientSubmission.submissionType
        });
      }
      else
      {
      activities.push({
        type: ActivityFeedTypes.ClientSubmissionSent,
        author: this.getAuthorDetails(clientSubmission.submittedByID),
        date: clientSubmission.submissionDate,
        notes: clientSubmission.submission
      });
    }

    if(clientSubmission?.priorSubmissions?.length>0){
      clientSubmission?.priorSubmissions.forEach((sub) => {
        if(sub.submissionType)
        {
          activities.push({
            type: ActivityFeedTypes.UpdateSubmission,
            author: this.getAuthorDetails(sub.submittedByID),
            date: moment(sub.submissionDate),
            notes: sub.submission,
            activityType:sub.submissionType
          });
        }
        else
        {
        activities.push({
          type: ActivityFeedTypes.ClientSubmissionSent,
          author: this.getAuthorDetails(sub.submittedByID),
          date: moment(sub.submissionDate),
          notes: sub.submission
        });
      }
      })
    }
    }

    // TODO: Add "Mark as Backup" event if it gets implemented.

    ActivityFeedService.sortActivitiesByDate(activities);
    return activities;
  }

  /**
   * Extracts all information that constitutes an activity for the activity
   * feed from the clientInterview step.
   * @param clientInterview - [application].randstad_process.clientInterview object
   */
  extractClientInterview(clientInterview: ClientInterview): Activity[] {
    const activities: Activity[] = [];
    // Client interview was skipped.
    if (clientInterview.skipped) {
      activities.push({
        type: ActivityFeedTypes.SkippedStep,
        author: this.getAuthorDetails(clientInterview.skippedBy),
        date: clientInterview.skippedDate
      });
    }

    if (clientInterview.submission) {
      if(clientInterview.submissionType){
        activities.push({
          type: ActivityFeedTypes.UpdateSubmission,
          author: this.getAuthorDetails(clientInterview.agentID),
          date: clientInterview.submissionDate,
          notes: clientInterview.submission,
          activityType:clientInterview.submissionType
        });
      }
      else
      {
      activities.push({
        type: ActivityFeedTypes.ClientInterviewScheduled,
        author: this.getAuthorDetails(clientInterview.agentID),
        date: clientInterview.submissionDate,
        notes: clientInterview.submission
      });
    }
    }

    if (clientInterview.hiringManagerFeedback) {
      activities.push({
        type: ActivityFeedTypes.ClientInterviewFeedback,
        author: this.getAuthorDetails(clientInterview.agentID),
        date: clientInterview.outcomeDate,
        notes: clientInterview.hiringManagerFeedback
      });
    }
    if(clientInterview?.priorSubmissions?.length>0){
    clientInterview?.priorSubmissions.forEach((sub) => {
      if(sub.submissionType)
      {
      activities.push({
        type: ActivityFeedTypes.UpdateSubmission,
        author: this.getAuthorDetails(sub.agentID),
        date: moment(sub.submissionDate),
        notes: sub.submission,
        activityType:sub.submissionType
      });
      }
      else
      {
        activities.push({
          type: ActivityFeedTypes.ClientInterviewFeedback,
          author: this.getAuthorDetails(sub.agentID),
          date: moment(sub.submissionDate),
          notes: sub.submission,
        });
      }
    })
  }
    ActivityFeedService.sortActivitiesByDate(activities);
    return activities;
  }

  /**
   * Extracts all information that constitutes an activity for the activity
   * feed from the offer step.
   * @param offer - [application].randstad_process.offer object
   * @param rejected - [application].randstad_process.rejected value
   */
  extractOffer(application: Application): Activity[] {
    const { offer } = application.randstad_process;
    const activities: Activity[] = [];

    if (offer.offerDate && offer.agentID) {
      if (application.randstad_process.rejected) {
        activities.push({
          type: ActivityFeedTypes.OfferRejected,
          author: this.getAuthorDetails(offer.agentID),
          date: offer.offerDate,
          details: (<OfferActivity>offer.clone()).apply({ rejectReason: application.randstad_process.rejectReason || '' }),
          notes: application.randstad_process.rejectNote
        });
      } else {
        activities.push({
          type: ActivityFeedTypes.OfferSent,
          author: this.getAuthorDetails(offer.agentID),
          date: offer.offerDate,
          details: offer
        });
      }
    }

    ActivityFeedService.sortActivitiesByDate(activities);
    return activities;
  }

  /**
   * Extracts rejection from the application if the application is rejected.
   * @param application - talent application object
   */
  extractRejection(application: Application): Activity[] {
    const activities: Activity[] = [];
    if (
      application.randstad_process &&
      application.randstad_process.rejected &&
      application.randstad_process.rejectedByAgentID &&
      application.randstad_process.rejectedTimestamp
    ) {
      activities.push({
        type: ActivityFeedTypes.Rejection,
        author: this.getAuthorDetails(application.randstad_process.rejectedByAgentID),
        date: application.randstad_process.rejectedTimestamp,
        notes: application.randstad_process.rejectNote
      });
    }
    return activities;
  }

  /**
   * Extracts all back office IDs that exist from a talent application.
   * @param application - talent application
   */
  extractBackOfficeIDs(application: Application, job: Job): Array<string> {
    const {
      prescreening,
      interview,
      interviewSchedule,
      internalSubmission,
      clientSubmission,
      offer,
      rejectedByAgentID,
      addedByAgentID
    } = application.randstad_process;

    const ids: { [key: string]: boolean } = {};
    if (rejectedByAgentID) {
      ids[rejectedByAgentID] = true;
    }
    if (addedByAgentID) {
      ids[addedByAgentID] = true;
    }
    if (prescreening) {
      if (prescreening.agentID) {
        ids[prescreening.agentID] = true;
      }
      if (prescreening.skippedBy) {
        ids[prescreening.skippedBy] = true;
      }
    }
    if (interviewSchedule) {
      if (interviewSchedule.agentID) {
        ids[interviewSchedule.agentID] = true;
      }
      if (interviewSchedule.skippedBy) {
        ids[interviewSchedule.skippedBy] = true;
      }
    }
    if (interview) {
      if (interview.agentID) {
        ids[interview.agentID] = true;
      }
      if (interview.skippedBy) {
        ids[interview.skippedBy] = true;
      }
    }
    if (internalSubmission) {
      if (internalSubmission.submittedByID) {
        ids[internalSubmission.submittedByID] = true;
      }
      if (internalSubmission.submission) {
        if (internalSubmission.submittedToID) {
          ids[internalSubmission.submittedToID] = true;
        } else {
          // We default to the AM for old applications that don't have the submittedToID filled.
          const publishedByUser = (job && (job.allbirds_metadata || {} as JobMetadata).published_by_user_front_office_id) || '';
          if (publishedByUser) {
            ids[publishedByUser] = true;
          }
        }
      }
      if (internalSubmission.skippedBy) {
        ids[internalSubmission.skippedBy] = true;
      }
    }
    if (clientSubmission) {
      if (clientSubmission.submittedByID) {
        ids[clientSubmission.submittedByID] = true;
      }
      if (clientSubmission.skippedBy) {
        ids[clientSubmission.skippedBy] = true;
      }
    }
    if (offer && offer.agentID) {
      ids[offer.agentID] = true;
    }
    return Object.keys(ids).filter(Boolean);
  }
}
