import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ActivityDetailModalComponent } from '../../activity-detail-modal/activity-detail-modal.component';
import { Subscription } from 'rxjs';
import { TalentProcessService } from 'src/app/shared/services/talent-process/talent-process.service';
import { ModalService } from '../../../services/modal/modal.service';
import { Activity } from '../../../models/internal/activity.interface';
import { UtilityService } from '../../../services/utility/utility.service';
import { Job } from '../../../models/external/job.model';
import { ApplicationEntityChangeService } from '../../../services/entity-change/application-entity-change.service';
import { TranslateService } from '@ngx-translate/core';
import { Application } from 'src/app/shared/models/external/application.model';



@Component({
  selector: 'app-talent-process',
  templateUrl: './talent-process.component.html',
  styleUrls: ['./talent-process.component.scss']
})
export class TalentProcessComponent implements OnInit, OnDestroy {

  @Input() talentProfile: any;
  // @Input() talentApplication: any;
  @Input() job: Job = null;
 
  @Output() refresh: EventEmitter<string> = new EventEmitter();

  bsModalRef: BsModalRef;

  talentApplication: any;
  applicationSub: Subscription;

  applicationChanged = false;
  applicationChangeSub: Subscription;

  attachments: any[];

  constructor(
    private _modalService: ModalService,
    private _talentProcess: TalentProcessService,
    private _applicationChange: ApplicationEntityChangeService,
    private _translate: TranslateService,
    private _utility: UtilityService,
  ) { }

  ngOnInit() {
    this.listenForApplicationEntityChanges();
    this.listenToSelectedApplication();
  }

  ngOnDestroy(): void {
    UtilityService.destroySubscription(this.applicationSub);
    UtilityService.destroySubscription(this.applicationChangeSub);
  }



  /**
   * Handles when the selected application changes.
   */
  listenToSelectedApplication() {
    UtilityService.destroySubscription(this.applicationSub);
    this.applicationSub = this._talentProcess.selectedApplication
      .subscribe(app => {
        this.talentApplication = app;
        this.attachments = this._utility.extractAttachments(app);
        this.checkIfApplicationChanged();
        /*Do not remove as this helps debugging and getting the elastic id*/
        console.debug("Application = ", app);
      });
  }

  /**
   * Listens for application entity changes from Firestore.
   */
  listenForApplicationEntityChanges() {
    UtilityService.destroySubscription(this.applicationChangeSub);
    this.applicationChangeSub = this._applicationChange.onChanges
      .subscribe(() => {
        this.checkIfApplicationChanged();
      });
  }

  /**
   * Checks if the current talent application has changed and sets the status accordingly.
   */
  checkIfApplicationChanged(): void {
    this.applicationChanged = this._applicationChange.didChange(this.talentApplication);
    if (this.applicationChanged) {
      this._talentProcess.setSelectedApplicationState({
        disabled: true,
        disabledMsg: this._translate.instant('application-disabled.further_state'),
        hidden: false
      });
      this.refresh.emit();
    } else {
      this._talentProcess.setSelectedApplicationState({ disabled: false, hidden: false });
    }
  }

  openActivityDetailModal(activity: Activity) {
    const name = UtilityService.getNameFromTalentProfile(this.talentProfile);
    const initialState = {
      activity: activity,
      talentProcess: this.talentApplication,
      talentProfile: this.talentProfile,
      candidateFullName: name,
      showFeed: false,
      attachments: this.attachments
    };
    this.bsModalRef = this._modalService.show(ActivityDetailModalComponent, { initialState });
    this.bsModalRef.setClass('modal-sm');
  }

  get talentName(): string {
    return UtilityService.getNameFromTalentProfile(this.talentProfile);
  }
}
