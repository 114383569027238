// NOTE: this file is not currently being used because of the iframe solution, but should still be kept for future use

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AuthService } from '@auth0/auth0-angular';
import { Injectable } from '@angular/core';

const withCredentials = environment.env !== 'dev' && environment.env !== 'local';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  email: string;
  avatar: string;
  googleId: string;

  constructor(private auth: AuthService) {}

  //@ts-ignore
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<void | HttpEvent<any>> {

    if(!this.auth.isAuthenticated$){
      return this.auth.loginWithRedirect()
    }

    if(req.url.includes('/api/')){
  return  this.auth.getAccessTokenSilently().pipe(
      mergeMap(token => {
        if(token){
        const tokenReq = req.clone({

          setHeaders: { Authorization: `Bearer ${token}` }

        });
        localStorage.setItem('access_token', `Bearer ${token}`);

        return next.handle(tokenReq);
      }
      return this.auth.loginWithRedirect();


      }),

      catchError(err => {
        console.log('errrrr'+err, err);
        if(err.message === 'Login required'){
          return this.auth.loginWithRedirect();
        }
        return throwError(err)
      })
    )
    }
    else {
      return next.handle(req);
    }
  }
}

