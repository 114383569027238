import { Component, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { GoogleConversationService } from 'src/app/shared/services/google-conversation/google-conversation.service';
import { BadgeStyleClasses, ConversationStatusesDisplay } from 'src/app/shared/models/internal/conversation.status.model';
import { ModalService } from '../../../../../../services/modal/modal.service';
import { Conversation } from '../../../../../../models/external/conversation.model';
import { ConversationStatus } from '../../../../../../models/external/misc.model';

@Component({
  selector: 'prescreening-questions',
  templateUrl: './prescreening-questions.component.html',
  styleUrls: ['./prescreening-questions.component.scss']
})
export class PrescreeningQuestionsComponent implements OnChanges {
  @Input() collapsible?: boolean;
  @Input() conversationID?: string;
  modalRef: BsModalRef;

  conversation: Conversation | {} = {};
  conversationSub: any;
  conversationObs: any;
  conversationStatusDisplay: ConversationStatusesDisplay = ConversationStatusesDisplay.LOADING;
  badgeClass: string[] = [BadgeStyleClasses.LOADING, 'badge-status'];
  showConversation = true;

  constructor(
    private _modalService: ModalService,
    private googleConversationService: GoogleConversationService
  ) { }

  
  ngOnInit() {
    this.getConversation();
  }
  
  ngOnDestory() {
    if (this.conversationSub) {
      this.conversationSub.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    /*
     * In some cases this component is displayed without being reinitialized so
     * the conversation has to be set up again.
     */
    if (changes.conversationID && changes.conversationID.firstChange === false) {
      this.getConversation();
    }
  }
  
  getConversation() {
    /* Unsubscribe The Previous Subscription */
    if (this.conversationSub) {
      this.conversationSub.unsubscribe();
    }
    this.conversationObs = this.googleConversationService.getConversationForID(this.conversationID);

    this.conversationSub = this.conversationObs.subscribe(
      (conversation: Conversation) => {
        if (conversation) {
          this.conversation = conversation;
          this.setConversationStatusBadge(conversation);
        }
      }
    );
  }

  

  openConversationModal(conversationTemplate: string | TemplateRef<any>) {
    if (![ConversationStatusesDisplay.LOADING, ConversationStatusesDisplay.NOT_STARTED].includes(this.conversationStatusDisplay)) {
      this.modalRef = this._modalService.show(conversationTemplate);
    }
    return;
  }

  /**
   * Setup the values for the conversation status badge
   * @param conversation {Conversation}
   */
  setConversationStatusBadge(conversation: Conversation) {
    let badgeClass = this.badgeClass[0] || BadgeStyleClasses.LOADING;
    let conversationStatusDisplay = this.conversationStatusDisplay || ConversationStatusesDisplay.LOADING;
    if (conversation.rce) {
      conversationStatusDisplay = this.googleConversationService.getConversationStatus(conversation);
      badgeClass = this.googleConversationService.getConversationStatusStyles(conversation);
      this.showConversation = conversationStatusDisplay !== ConversationStatusesDisplay.NOT_STARTED;
    } else {
      /*
       * TODO: Remove this once GCE is fully phased out and we reach a reasonable point
       *  to stop supporting old conversation formats.
       */
      if (conversation.knockoutTriggered === true) {
        conversationStatusDisplay = ConversationStatusesDisplay.FAIL;
        badgeClass = BadgeStyleClasses.FAIL;
      } else {
        if (conversation.status === ConversationStatus.FINISHED) {
          conversationStatusDisplay = ConversationStatusesDisplay.SUCCESS;
          badgeClass = BadgeStyleClasses.SUCCESS;
        } else {
          conversationStatusDisplay = ConversationStatusesDisplay.IN_PROGRESS;
          badgeClass = BadgeStyleClasses.IN_PROGRESS;
        }
      }
    }
    this.conversationStatusDisplay = conversationStatusDisplay;
    this.badgeClass = [badgeClass, 'badge-status'];
  }

}
