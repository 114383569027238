<div class="onboarding container-fluid">
  <div class="onboarding--title clickable row no-gutters" (click)="show = !show">
    <h3 class="col-11">{{'onboarding.onboarding:' | translate}}<span *ngIf="application && application.randstad_process && application.randstad_process.onboarding.status === 'not_started'">{{'onboarding.not_started' | translate}}</span>
      <span *ngIf="application && application.randstad_process && application.randstad_process.onboarding.status === 'started'">{{'onboarding.started' | translate}}</span>
      <span *ngIf="application && application.randstad_process && application.randstad_process.onboarding.status === 'complete'">{{'onboarding.complete' | translate}}</span>
    </h3>
    <span class="col-1 text-right" *ngIf="collapsible">
      <vertical-align>
        <i *ngIf="!show"  class="color-primary icon icon-WL_icon_chevron-down"></i>
        <i *ngIf="show" class="color-primary icon icon-WL_icon_chevron-up"></i>
      </vertical-align>
    </span>
  </div>
  <div *ngIf="show" class="onboarding--list container-fluid">
    <div *ngFor="let item of items | keyvalue" class="onboarding-item form-check"
      [ngClass]="{ hidden: application.randstad_process.onboarding.status === 'complete' && !application.randstad_process.onboarding[item.key] }">
      <span [popover]="application.randstad_process.onboarding[item.key] ? ('Marked complete on ' + (application.randstad_process.onboarding[item.key] | date :'shortDate')) : ''"
        triggers="mouseenter:mouseleave"
        data-placement="bottom"
        container="body"
      >
        <div class="form-check-container"
             [ngClass]="{ complete: application.randstad_process.onboarding.status === 'complete' }">
          <input [attr.disabled]="application.randstad_process.onboarding.status === 'complete' ? '' : null"
                 class="form-check-input" type="checkbox" value="" id={{item.key}}
                 [checked]="application.randstad_process.onboarding[item.key]" />
          <span class="checkmark" (click)="change(item.key)"></span>
        </div>
        <span [ngClass]="{ gray: application.randstad_process.onboarding.status === 'complete' }">
          {{ item.value }}
        </span>
      </span>
    </div>
  </div>
  <div *ngIf="show" class="action-buttons">
      <button *ngIf="application.randstad_process.onboarding.status !== 'complete'" 
        class="btn rds--button--primary pull-right"
        (click)="completeOnboarding()">{{'onboarding.onboarding_is_completed' | translate}}</button>
      <button *ngIf="application.randstad_process.onboarding.status === 'complete'"
        class="btn rds--button--primary pull-right"
        (click)="reopenOnboarding()">{{'onboarding.reopen_onboarding' | translate}}</button>
  </div>
</div>