import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { GoogleProfile } from '../../models/external/google-profile.model';
import { Action, BuildUA, UserActionActivity } from '../../models/external/user-actions.model';
import { ActionByUser, ActionInfo, UserAction } from '../../models/internal/user-action.interface';
import { ApiService } from '../api/api.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})

export class UserActionsService implements OnDestroy {

  private createUserActionsSub: Subscription;
  private updateUserActionsSub: Subscription;
  private getUserActionSub: Subscription;


  get timestamp7DaysAgo() { return new Date().getTime() - (7 * 24 * 60 * 60 * 1000)};

  private actionByUser: ActionByUser = {
      action_by_user_back_office_id: this._auth.user.BackOfficeID,
      action_by_user_front_office_id: this._auth.user.Oprid,
      action_by_user_email: this._auth.user.EmailAddr,
      action_by_user: `${this._auth.user.FirstName} ${this._auth.user.LastName}`,
      action_by_user_lob: this._auth.user.Source,
  }
  constructor(    
    private _api: ApiService,
    private _auth: AuthService
  ) {}

  ngOnDestroy(): void {
    this.createUserActionsSub.unsubscribe();
    this.updateUserActionsSub.unsubscribe();
    this.getUserActionSub.unsubscribe();
  }

 /**
 * Returns the profiles that have exceeded 7 days. Otherwise, if none are found, it returns null.
 * @param  {UserActionActivity} userActions  All of the actions related to multiple profiles
 */
 userActivityPast7Days(userActions: UserActionActivity): UserActionActivity | null {
    if (!userActions) return null;

    const filteredActions = userActions.actions_on_profile.filter((user: { action_date: string | number | Date; }) => {
      const actionDate = new Date(user.action_date).getTime();
      //Filter users whose action timestamps exceed 7 days ago (older dates have lower values)
      return actionDate < this.timestamp7DaysAgo;
    });
    return filteredActions.length > 0 ? new UserActionActivity({...userActions, actions_on_profile: filteredActions}) : null;
  }
  /**
 * Updates the timestamp (if it's exceeded 7 days) of the action that the user performed before 
 * @param  {UserActionActivity} userActions  All of the ***existing*** actions related to the user that performed them
 * @param  {ActionInfo} newActionInfo  The new action info (i.e. new location action took place, type, category) that needs to get updated
 */
  updateUserActions(userActions: UserActionActivity, newActionInfo: ActionInfo): void {
    const users = this.userActivityPast7Days(userActions);
    if (users) {
      const extractedIds = users.actions_on_profile.map((user: { profile_elastic_id: any; }) => user.profile_elastic_id);
      const updateUserActions = new BuildUA(this.actionByUser, newActionInfo,extractedIds)
      this.updateUserActionsSub = this._api.updateUserActions(updateUserActions).subscribe();
    }
  }

  /**
 * Creates user actions that havent been in ES before
 * @param  {string[]} newUAEntries           All of the users that need new ES user action entries
 * @param  {ActionInfo} actionInfo            Category, event type, and location of the action (i.e. recently_viewed, click, talent_search)
 * @returns void
  */
  createUserActions(newUAEntries: string[], actionInfo: ActionInfo): void {
    const newActions = new BuildUA(this.actionByUser, actionInfo, newUAEntries)
    this.createUserActionsSub = this._api.createUserActions(newActions).subscribe();
  }

  /**
 * Updates or creates user actions in ES
 * @param  {string[]} usersAffected           An array of user IDs affected by the actions.
 * @param  {ActionInfo} actionInfo            Information about the actions (i.e. recently_viewed, click, talent_search)
 */
  saveUserAction(usersAffected: string[], actionInfo: ActionInfo): void {
    const action = new BuildUA(this.actionByUser, actionInfo, usersAffected);
    this.getUserActionSub = this._api.getUserActions(action)
      .subscribe(existingUA => {
        this.updateOrCreateUserActions(usersAffected, actionInfo, existingUA);
      });
  }
  
  /**
 * Given an array of user IDs and an ActionInfo object, updates existing UserActionActivity records and/or creates new UserActionActivity records for the given user IDs
 * @param {string[]} usersAffected - An array of user IDs
 * @param {ActionInfo} actionInfo - An ActionInfo object containing information about the action taken
 * @param {UserActionActivity} [existingUA] - An existing UserActionActivity object
 */
  updateOrCreateUserActions(usersAffected: string[], actionInfo: ActionInfo, existingUA?: UserActionActivity): void {
    const newUAEntries = this.getNewUAEntries(usersAffected, existingUA);
    if (newUAEntries.length > 0) {
      this.createUserActions(newUAEntries, actionInfo);
    }
    if (existingUA) {
      this.updateUserActions(existingUA, actionInfo);
    }
  }
  
  /**
 * Given an existing UserActionActivity and an array of user IDs, returns an array of user IDs that are not found in the existing UserActionActivity
 * @param {string[]} usersAffected - An array of user IDs
 * @param {UserActionActivity} [existingUA] - An existing UserActionActivity object
 * @returns {string[]} - An array of user IDs that are not found in the existing UserActionActivity
 */
  getNewUAEntries(usersAffected: string[], existingUA?: UserActionActivity): string[] {
    let newUAEntries;
    //Filters out existing User Actions provided from getUserActions (incase some users are updates but others are new entries)
    //return users not found in existingUA as 'newUAEntries'
    if (existingUA) newUAEntries = usersAffected.filter(userId => !existingUA.actions_on_profile.some((profile: { profile_elastic_id: string; }) => profile.profile_elastic_id == userId ));
    else newUAEntries = usersAffected;
    return newUAEntries;
  }


  /**
 * Sets a temporary date for recently viewed candidates (for UX; Instant feedback as soon as they click on card they see indicator).
 * @param {string} talentId - talent external Id
 * @param {Action} actionsOnProfile - existing action
 * @returns {string | boolean} rvTalent - An object with the talent id the new date (false if date is not > 7 days) the profile was viewed.
 */
  setRVTempDate(talentId: string, actionsOnProfile: Action) {
   const rvTalent: {id: string, newDate: string | boolean} = {
      id: talentId,
      newDate: false
    };
    //actionsOnProfile obj doesn't exist or action_date is greater than 7 days ago so we put in a temp date as todays date
    if (!actionsOnProfile || new Date(actionsOnProfile?.action_date).getTime() < this.timestamp7DaysAgo) rvTalent.newDate = new Date().toISOString();
    return rvTalent;
  }
}
