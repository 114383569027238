import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomListService } from '../../list-details/custom-list.service';
import { Subscription } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { LoadingSpinnerService } from 'src/app/shared/services/loading-spinner/loading-spinner.service';
import { List } from 'src/app/shared/models/external/list.model';
import moment from 'moment';
import { ApiService } from '../../../../../shared/services/api/api.service';
import { User } from 'src/app/shared/models/external/user.model';
import { ToastClass, ToastService } from 'src/app/shared/services/toast';

@Component({
  selector: 'app-edit-list-modal',
  templateUrl: './edit-list-modal.component.html',
  styleUrls: ['./edit-list-modal.component.scss']
})
export class EditListModalComponent implements OnInit {
  title: string;
  id: string;
  currentSelectedListId: string;
  recruitersList: User[];
  allowNameEdit: boolean;
  listPattern = /^[a-zA-Z0-9 ]*$/;

  listSubs: Subscription;
  currentList: List;
  setNewCurrentList: boolean;

  deletedUser: User;

  editListForm = new FormGroup({
    listName: new FormControl('', [
      Validators.required,
      Validators.maxLength(30),
      Validators.pattern(this.listPattern)
    ]),
    shareWith: new FormControl('')
  });

  constructor(
    private _api: ApiService,
    private _bsModalRef: BsModalRef,
    private _listService: CustomListService,
    private _loading: LoadingSpinnerService,
    private _toast: ToastService) { }

  ngOnInit() {
    const listName = this.editListForm.controls.listName;
    listName.patchValue(this.title);
    if (!this.allowNameEdit) {
      listName.disable();
    }
  }

  closeDialog(): void {
    this._bsModalRef.hide();
  }

  saveList() {
    this._loading.show();
    this.closeDialog();

    this._api.searchList(this.id)
      .pipe(
        flatMap(list => {
          const updatedList = this.updateList(list);
          return this._api.updateList(updatedList, { logEvent: 'pipeline was updated' });
        }))
      .subscribe(data => {
        this._listService.setCurrentListOfLists();
        this._listService.setCurrentSharedListOfLists();
        if (this.setNewCurrentList || (this.currentSelectedListId === this.id)) {
          this._listService.refresh();
        }

        this._loading.hide();
      }, err => {
        console.error(err);
      });
  }

  setUserInFocus(user: User) {
    this.editListForm.controls.shareWith.patchValue('');

    if (this.recruitersList.some(recruiter => recruiter.BackOfficeID === user.BackOfficeID)) {
      return
    }

    this.recruitersList.push(user);
  }

  updateRecruiterList(user: User): void {
    const deletedUser = user;
    this.recruitersList = this.recruitersList.filter((recruiter) => {
      return recruiter.BackOfficeID !== user.BackOfficeID
    });

    const toastConfig = {
      cssClass: ToastClass.SUCCESS,
      actionLinks: [
        {
          text: 'Undo', action: () => {
            this.undo(deletedUser);
          }
        },
      ]
    }

    this._toast.showToast(`${deletedUser.FullName} no longer has access to this list`, toastConfig);
  }

  private updateList(list: List): List {
    let updatedList = list.clone();
    updatedList = this.setCollaborators(updatedList);
    updatedList.name = this.editListForm.controls.listName.value;
    updatedList.last_update_date = moment();
    return updatedList;
  }

  private setCollaborators(list: List): any {
    let newList = list.clone();
    newList.sharing.collaborators = [];

    this.recruitersList.forEach(recruiter => {
      newList.sharing.collaborators.push({
        first_name: recruiter.FirstName,
        last_name: recruiter.LastName,
        back_office_id: recruiter.BackOfficeID,
        added_by_email_address: recruiter.EmailAddr,
        permissions: {
          edit: true,
          view: true
        },
        date_added: new Date().toISOString()
      })
    })

    newList.sharing.collaborators_count = newList.sharing.collaborators.length;

    return newList
  }

  undo(user: User): void {
    this.setUserInFocus(user);
  }

}
