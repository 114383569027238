import {
  Component,
  OnDestroy,
  OnInit,
  EventEmitter
} from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FormValidationService } from "../../../../../../services/form-validation/form-validation.service";
import { KeyValue } from "@angular/common";
import { ApiService } from "../../../../../../services/api/api.service";
import { LoadingSpinnerService } from "../../../../../../services/loading-spinner/loading-spinner.service";
import moment from "moment";
import { finalize } from "rxjs/operators";
import { Application } from "../../../../../../models/external/application.model";
import { AuthService } from "../../../../../../services/auth/auth.service";
import { ProcessInformationProvider } from "src/app/shared/services/talent-process/process-information-provider";
import { Job } from "src/app/shared/models/external/job.model";
import { JobDetailsShortlistService } from "src/app/shared/services/job-details-shortlist/job-details-shortlist.service";
import { TalentProcessService } from "src/app/shared/services/talent-process/talent-process.service";
import { ToastClass, ToastService } from "src/app/shared/services/toast";
import { ListTalent } from "src/app/shared/models/external/list-talent.model";
import { ApptInviteService } from "src/app/shared/services/appt-invite/appt-invite.service";
import { AllbirdsAppointment } from "src/app/shared/models/external/appointment.model";

interface ICancellationReason {
  reschedule: string;
  talentCancel: string;
  interviewDone: string;
  jobUnavailable: string;
  duplicate: string;
}

enum CancellationType {
  interview = "INTERVIEW",
  appointment = "APPOINTMENT",
  appointment_int = "APPOINTMENT_INT",
}
@Component({
  selector: "app-cancel-interview-modal",
  templateUrl: "./cancel-interview-modal.component.html",
  styleUrls: ["./cancel-interview-modal.component.scss"],
})
export class CancelInterviewModalComponent implements OnInit, OnDestroy {
  // Talent application.
  application: Application;
  label: string;
  job: Job;
  type: CancellationType;
  cancelReason: ICancellationReason = {
    reschedule: "Randstad reschedule",
    talentCancel: "Talent cancellation or reschedule",
    interviewDone: "Interview has already taken place",
    jobUnavailable: "Job is no longer available",
    duplicate: "Duplicate interview"
  };
  cancelReasonMap = new Map([
    ['reschedule', 'Talent rescheduled'],
    ['talentCancel', 'Talent cancellation'],
    ['interviewDone', 'Interview has already taken place'],
    ['jobUnavailable', 'Job is no longer available'],
    ['duplicate', 'Duplicate interview']
  ]);
  name: string;
  talent: ListTalent;
  appointment: AllbirdsAppointment;
  listId: string;
  cancelClicked:boolean = false;
  public message: string;
  // Form.
  form: FormGroup;

  // Subscription for "Pay rate offered" changes.
  public saved: EventEmitter<any> = new EventEmitter();

  constructor(
    public _bsModalRef: BsModalRef,
    private _formBuilder: FormBuilder,
    private _api: ApiService,
    private _shortlist: JobDetailsShortlistService,
    private _process: TalentProcessService,
    private _apptInvite: ApptInviteService,
    private _loading: LoadingSpinnerService,
    private auth: AuthService,
    private _toast: ToastService
  ) {}

  ngOnInit(): void {
    if (this.type !== CancellationType["appointment"]) {
      this.name = this.application.randstad_process.candidateFullName ?  this.application.randstad_process.candidateFullName: `${this.application.randstad_process.profileInfo?.personNames[0]?.structuredName?.givenName} ${this.application.randstad_process.profileInfo?.personNames[0]?.structuredName?.familyName} ` ;
    } else {
      this.name = (this.talent?.profileInfo) ? this.talent?.profileInfo?.candidateFullName : `${this.talent?.first_name} ${this.talent?.last_name}`;
      console.log('this.name', this.name);
    }
    this.setupView();
  }

  ngOnDestroy(): void {}

  private setupView(): void {
    this.form = this.generateForm();
    // Uncomment below when testing to see form changes.
    // this.form.valueChanges.subscribe(form => {
    //   console.log('Form updated!', form);
    // });
  }

  /**
   * Given a job order, returns the Offer Rejection form with pay rate pre-filled.
   * @param job - Allbirds job order
   */
  private generateForm(): FormGroup {
    return this._formBuilder.group({
      cancelReason: [
        null,
        [Validators.required, FormValidationService.validateNonEmptyString],
      ],
    });
  }

  setMessage(value: string) {
    this.message = null;
    switch (value) {
      case "reschedule": {
        this.message = `
              <p>Dear ${this.name}</p>
              <p>Due to unforeseen circumstances, I need to reschedule your upcoming interview. I have canceled your current appointment time. Please click here to select a new time to meet. I apologize for any inconvenience this has caused.</p>
              ${this.auth.user.Preferences.EmailSignature}
              `;
        break;
      }
      case "talentCancel": {
        this.message = `
              <p>Dear ${this.name}</p>
              <p>Thank you for informing me of your need to cancel and/or reschedule your upcoming interview. I have canceled your current appointment time. If you would like to reschedule, please click here.</p>
              ${this.auth.user.Preferences.EmailSignature}
              `;
        break;
      }
      case "interviewDone": {
        let information;
        let jobTitle = "";
        if (this.type !== CancellationType["appointment"]) {
          information = new ProcessInformationProvider(
            this.application.randstad_process
          );
          jobTitle = this.job.title;
        } else {
          information = {
            interviewDatetime:
              this.talent.appointment?.appointment?.timeSlotSelected.format(
                "MMMM Do YYYY, h:mm a"
              ),
          };
          if (!information.interviewDatetime) {
            information.interviewDatetime =
              this.appointment?.appointment?.timeSlotSelected.format(
                "MMMM Do YYYY, h:mm a"
              );
          }
        }

        this.message = `
            <p>Dear ${this.name}</p>
            <p>I understand you have interviewed with us recently for a position.  We value your time and will consider you for any and all positions that match your preferences and skills without the need to interview again for the next 30 days. Therefore, I’ve canceled your upcoming interview on
             ${information?.interviewDatetime} for the ${jobTitle} position.  I can be reached by phone or email to answer any questions. </p>
             ${this.auth.user.Preferences.EmailSignature}
            `;
        break;
      }
      case "jobUnavailable": {
        let information;
        let jobTitle = "";
        if (this.type !== CancellationType["appointment"]) {
          information = new ProcessInformationProvider(
            this.application.randstad_process
          );
          jobTitle = this.job.title;
        } else {
          information = {
            interviewDatetime:
              this.talent.appointment?.appointment?.timeSlotSelected.format(
                "MMMM Do YYYY, h:mm a"
              ),
          };
          if (!information.interviewDatetime) {
            information.interviewDatetime =
              this.appointment?.appointment?.timeSlotSelected.format(
                "MMMM Do YYYY, h:mm a"
              );
          }
        }

        this.message = `
            <p>Dear ${this.name}</p>
            <p>The ${jobTitle} position you are scheduled to interview for on
             ${information?.interviewDatetime} is no longer available. To be respectful of your time, we are canceling your interview.  However, we may have other jobs that could interest you.  You can find a full list of our currently available positions here. </p>
             ${this.auth.user.Preferences.EmailSignature}
            `;
        break;
      }

      case "duplicate": {
        let information;
        let jobTitle = "";
        if (this.type !== CancellationType["appointment"]) {
          information = new ProcessInformationProvider(
            this.application.randstad_process
          );
          jobTitle = this.job.title;
        } else {
          information = {
            interviewDatetime:
              this.talent.appointment?.appointment?.timeSlotSelected.format(
                "MMMM Do YYYY, h:mm a"
              ),
          };
          if (!information.interviewDatetime) {
            information.interviewDatetime =
              this.appointment?.appointment?.timeSlotSelected.format(
                "MMMM Do YYYY, h:mm a"
              );
          }
        }

        this.message = `
            <p>Dear ${this.name}</p>
            <p>It seems you have scheduled multiple interviews with the same Randstad representative or office.  Each representative can consider you for any of the open jobs that may fit your preferences and skills.  We value your time, so we have canceled the unnecessary interview scheduled
              for ${information?.interviewDatetime} for the ${jobTitle} position.  We look forward to talking with you about all positions of interest during your upcoming interview.  Be on the lookout for reminders of the confirmed interview time. </p>
             ${this.auth.user.Preferences.EmailSignature}
            `;
        break;
      }
    }
  }

  submitForm() {
    this.cancelClicked = true;
    // Touch all fields.
    for (const property in this.form.controls) {
      if (this.form.controls.hasOwnProperty(property)) {
        this.form.controls[property].markAsTouched();
      }
    }
    // If form isn't valid, cancel execution.
    if (!this.form.valid) {
      this.cancelClicked = false;
      return;
    }
    this._loading.show();
    const reason: keyof ICancellationReason =
      this.form.get("cancelReason").value;
    const value = this.cancelReasonMap.get(reason);
    if (this.type !== CancellationType["appointment"]) {
      const body = {
        application: this.application,
        interviewId:
          this.application?.randstad_process?.interviewSchedule?.interviewId,
        cancellationReason: value,
        cancelledBy: this.auth.user.BackOfficeID,
        cancelledDate: moment().toISOString(),
      };


      this._api
        .sendCancellationRequest(body)
        .pipe(
          finalize(() => {
            this.saved.emit(true);
            this._loading.hide();
            this._bsModalRef.hide();
            this._toast.showToast(
              `The interview with ${this.application.randstad_process.candidateFullName} has been cancelled. An email is sent to the talent.`,
              { cssClass: ToastClass.SUCCESS }
            );
          })
        )
        .subscribe(
          (res) => {
            // this.saved.emit(true);
            if (res && res.applications) {
              this._process.selectApplication(res.applications[0]);
              this._shortlist.updateInMemoryApplications(
                [res.applications[0]],
                this.job
              );
            }
            console.log("[cancel] submit res:", res);
          },
          (err) => {
            this._toast.showToast(
              `Failed to cancel the interview with ${this.application.randstad_process.candidateFullName}. Please, try again later.`,
              { cssClass: ToastClass.DANGER }
            );
            console.log("[cancel] reject err:", err);
          }
        );
    } else {
      if (this.talent.appointment?.appointment?.timeSlotSelected) {
        const body = {
          listId: this.listId,
          talent: this.talent,
          cancellationReason: value,
          cancelledBy: this.auth.user.BackOfficeID,
          cancelledDate: moment().toISOString(),
        };
        this._loading.show();
        this._api
          .sendCancellationAppointmentRequest(body)
          .pipe(
            finalize(() => {
              this._loading.hide();
              this._bsModalRef.hide();
            })
          )
          .subscribe(
            (res) => {
              this._toast.showToast(
                `The interview with ${this.talent.first_name} ${this.talent.last_name} has been cancelled. An email is sent to the talent.`,
                { cssClass: ToastClass.SUCCESS }
              );
              this.saved.emit(true);
              this._apptInvite.setCurrentTalent(res?.talent);

              console.log("[cancel] submit res:", res);
            },
            (err) => {
              this._toast.showToast(
                `Failed to cancel the interview with ${this.talent.first_name} ${this.talent.last_name}. Please, try again later.`,
                { cssClass: ToastClass.DANGER }
              );
              console.log("[cancel] reject err:", err);
            }
          );
      } else {
        const body = {
          appointment: this.appointment,
          cancellationReason: value,
          cancelledBy: this.auth.user.BackOfficeID,
          cancelledDate: moment().toISOString(),
        };

        this._loading.show();
        this._api
          .sendRecruiterCancellationAppointmentRequest(body)
          .pipe(
            finalize(() => {
              this._loading.hide();
              this._bsModalRef.hide();
            })
          )
          .subscribe(
            (res) => {
              this._toast.showToast(
                `The interview with ${this.name} has been cancelled. An email is sent to the talent.`,
                { cssClass: ToastClass.SUCCESS }
              );
              // this.saved.emit(true);
              console.log("[cancel] submit res:", res);
              //Setup a delay of 0.6s for page refresh trigger in  to help the database fully updated before fetching interviewcards again
              setTimeout(()=>{ this.saved.emit(true)}, 600);

            },
            (err) => {
              this._toast.showToast(
                `Failed to cancel the interview with ${this.name}. Please, try again later.`,
                { cssClass: ToastClass.DANGER }
              );
              console.log("[cancel] reject err:", err);
            }
          );
      }
    }
  }
  /**
   * Comparator function for the keyvalue pipe that sorts the notes alphabetically by value instead of key.
   * @param a note
   * @param b note
   */
  noteSortAlphabetical(
    a: KeyValue<string, string>,
    b: KeyValue<string, string>
  ): number {
    return a.value.localeCompare(b.value);
  }
}
