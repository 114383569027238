<li role="menuitem" class="rds-dropdown-item" >
    <hr style="margin-top: 0mm;">
    <div class="d-flex" style="width: 40em;">
    <span [tooltip]="attachment.fileName" class="dropdown-item" style="font-weight: 600; margin: 0; width: 20em;">
        {{attachment.fileName}}
    </span>
    <span *ngIf="attachment.category === 'Primary'" style="background-color: #cef2e2; padding: 8px; border-radius: 0.5em; width: 5em; height: 35px; margin-right: 5em">
        {{attachment.category}}
    </span>
    <span class="ml-auto mr-2">
        <a *ngIf="attachment.type !== 'link'" class="mr-2" href={{attachment.path}}>
        <img  src="assets/icons/download.svg" alt="download attachment">
        </a>
        <a *ngIf="attachment.type === 'link'" class="mr-2 primary" href={{attachment.path}}>
        <img  src="assets/icons/insert-link.svg" alt="download attachment">
        </a>
    </span>
    </div>
    <div class="dropdown-item" style="margin-bottom: 0mm;">{{attachment.category}} - Uploaded {{ attachment.upload_time }} <span *ngIf="attachment.uploadedBy">by: {{ attachment.uploadedBy }}</span> </div>
    <div *ngIf="attachment && attachment.userComments" class="dropdown-item" style="margin-bottom: 0mm; font-size: 15px;">{{attachment.userComments}}</div>
</li>
