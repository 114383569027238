export enum MASS_OPERATION_CONTEXT {
    'jobPotential' ,
    'modelsPotential',
    'shortlisted',
    'pipeline',
    'recentActivities',
    'individualEnhancedProfile',
    'application'
}

export enum MASS_ACTION_TYPE {
    'email',
    'text'
}
