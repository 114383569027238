<!-- Recruitment steps card -->
<div class="talent-dashboard-card" [class.disabled]="disabled" (click)="onCardClick(key)"
    *ngIf="cardType && cardType === 'steps'">
    <!-- Hide on hover doesn't do anything unless card is disabled. -->

    <div class="processStep">
        <img class="processStep-img" src="{{ icon }}"  />
        <span >{{ title | titlecase }}</span>
        <span >&nbsp;({{ count }})</span>
    </div>

    <div class="subStatus" (click)="onStatusClick(key, subStatus.status)" *ngFor="let  subStatus of subStatusMetricsList"  >
        <span>{{subStatus.count}} </span>
        <span>{{subStatus.title}} </span>
        <img class="warning-img"  *ngIf="isWarning(subStatus)" src="assets/icons/alert-yellow.svg"  />
        <img class="subStatus-img" src="assets/icons/chevron.svg"  />

    </div>


</div>

<div class="talent-dashboard-card custom-list-card" *ngIf="cardType && cardType === 'custom'" (click)="onListCardClick()">
    <ng-template [ngTemplateOutlet]="customCardTemplate">
    </ng-template>
</div>

<div  *ngIf="cardType && cardType === 'recent-activity'" (click)="onRecentActivityCardClick()">
    <ng-template [ngTemplateOutlet]="recentActivityCardTemplate">
    </ng-template>
</div>

<!-- Custom lists card -->
<ng-template #customCardTemplate>

    <div class="title-and-dots">
        <span class="step-title" [tooltip]="title | titlecase" *ngIf="showToolTip == true">{{ title | titlecase }} </span>
        <span class="step-title"  *ngIf="showToolTip == false">{{ title | titlecase }}</span>
        <div ngbDropdown class="d-inline-block" (click)="preventRouterAction($event)">
            <div class="three-dots" ngbDropdownToggle>&#8942;</div>
            <div class="custom-dropdown-menu" ngbDropdownMenu>
                <button ngbDropdownItem (click)="openEditListModal()">{{'recruitment-list-card.edit_list' | translate}}</button>
                <button ngbDropdownItem (click)="openDeleteListModal()" [disabled]="!allowDelete">{{'recruitment-list-card.delete_list' | translate}}</button>
            </div>
        </div>
    </div>
    <div *ngIf="showLabel">
        <span class="label">{{labelMsg}}</span>
    </div>
    <div class="icon-count-and-date">
        <div class="custom-icon-and-count">
            <img class="custom-list-img" src="../../../../../../assets/icons/account.svg"  />
            <span class="custom-list-count">{{ count }}</span>
        </div>
        <div class="date-created"> <span class="created-tag">{{'recruitment-list-card.created_on' | translate}}</span> {{date | abDate}}</div>
    </div>

</ng-template>


<ng-template #recentActivityCardTemplate>

    <div class="recent-activity-card" [class.disabled]="disabled"  >
    <!-- Hide on hover doesn't do anything unless card is disabled. -->

    <div class="processStep">
        <img class="processStep-img" src="{{ icon }}"  />
        <span >{{ title | titlecase }}</span>
        <span >&nbsp;({{ count }})</span>
    </div>




    </div>


</ng-template>

