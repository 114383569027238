import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { JobOrderService } from '../../services/job-order/job-order.service';
import { LoadingSpinnerService } from '../../services/loading-spinner/loading-spinner.service';
import { AuthService } from '../../services/auth/auth.service';
import { RGS_SOURCE, RT_SOURCE } from 'src/app/shared/services/job-order/job-order-details.config';
import { JobUrlService } from '../../services/job-url/job-url.service';
import { ModalService } from '../../services/modal/modal.service';
import { Job } from '../../models/external/job.model';
import { ApiService } from '../../services/api/api.service';
import { ResolveService } from '../../services/resolve/resolve.service';
import { Moment } from 'moment';
import { InterviewScheduleInformation } from '../../models/external/misc.model';
import { UrlStateService } from 'src/app/shared/services/url-state/url-state.service';
import { UtilityService } from '../../services/utility/utility.service';
import {FirestoreService} from '../../services/firestore/firestore.service';
import {Attachment} from '../../models/internal/attachment.model';
import {DeleteAttachmentModalComponent} from '../attach-files/delete-attachment-modal/delete-attachment-modal.component';
import { OrderType } from '../../models/internal/job-form.interface';
import {UserService} from '../../services/user/user.service';

@Component({
  selector: 'app-job-details-pane',
  templateUrl: './job-details-pane.component.html',
  styleUrls: ['./job-details-pane.component.scss']
})
export class JobDetailsPaneComponent implements OnInit, OnChanges, OnDestroy {

  // Job order object.
  @Input() job: Job = null;
  @Input() isTalentShortlisted: any;
  @Input() addToJob: any;
  @Input() shortlist: any;
  @Input() selectedCardTooltip: any;

  // Used when updating the job.
  updateJobSub: Subscription;
  jobOrderSub: Subscription;
  orderStatus: string;
  queryParamsSubscription: Subscription;
  BULLHORN_URL = environment.bullhornUrl;
  work_hours_end: Moment;
  work_hours_start: Moment;
  min_pay_rate: number;
  order_source_title = '';
  modalRef: BsModalRef;
  // Flag for showing loading spinner on front office ID.

  trVariable1: any;
  trVariable2: any;
  trVariable3: any;
  trVariable4: any;
  trVariable5: any;

  vmsStatus: any;
  firestoreSub: any;
  keywords: string[] = [];
  linkedJobs: Job[] = [];
  linkedJobsSub: any;
  linkedJobDefaultLimit = 3;
  isCRLob: boolean = false;

  // Tabset.
  @ViewChild('tabSet') tabset: TabsetComponent;

  constructor(
    private _auth: AuthService,
    private _router: Router,
    private _api: ApiService,
    private _jobOrder: JobOrderService,
    private loading: LoadingSpinnerService,
    private modalService: ModalService,
    private jobUrlService: JobUrlService,
    private _firestore: FirestoreService,
    private _url: UrlStateService,
    private route: ActivatedRoute,
    private _utility: UtilityService,
    public _user: UserService,
  ) { }

  ngOnInit() {
    if (this.job && this.job.workHours && this.job.workHours.length) {
      if (this.job.workHours[0].endTime) {
        this.work_hours_end = this.job.workHours[0].endTime;
      } else {
        this.job.workHours[0].endTime = null;
      }

      if (this.job.workHours[0].startTime) {
        this.work_hours_start = this.job.workHours[0].startTime;
      } else {
        this.job.workHours[0].startTime = null;
      }
    }

    if (this.job && this.job.compensationInfo
      && this.job.compensationInfo.entries
      && this.job.compensationInfo.entries.length) {
      this.min_pay_rate = Number(this.job.compensationInfo.entries[0].amount.units) + (this.job.compensationInfo.entries[0].amount.nanos / 1000000000);
    }
    this.order_source_title = this.job ? this.getOrderSourceTitleForValue(this.job.allbirds_metadata.order_source) : '';
    if (this.job && this.job.allbirds_metadata) {
      this.trVariable1 = { 'value': this.job.allbirds_metadata.minimum_years_of_experience };
      this.trVariable2 = { 'value': this.job.allbirds_metadata.heavy_lifting_weight };
      this.trVariable3 = { 'value': this.job.allbirds_metadata.consistently_standing_hours };
      this.trVariable5 = { 'value': this.job.allbirds_metadata.expected_overtime_hours };
      this.isCRLob = this.job.allbirds_metadata?.lob == 'CR' || false;
    }
    if (this.job && this.job.employmentTypes) {
      this.trVariable4 = { 'value': (this.job.employmentTypes.indexOf('FULL_TIME') !== -1) ? 'Full-Time' : 'Part-Time' };
    }

    this.getVmsStatus();
    this.job?.allbirds_metadata?.attachments?.sort((a, b) => new Date(b.upload_time).getTime() - new Date(a.upload_time).getTime());
    this.jobOrderSub = this._jobOrder.jobOrderObservable.subscribe(data => {
      if(data && data.allbirds_metadata) this.orderStatus = data.allbirds_metadata?.order_status;
    });
    this.linkedJobsSub = this._api.getLinkedJobs(this.job?.allbirds_metadata?.linked_ab_job_id || this.job?.allbirds_metadata?.allbirds_job_id)
    .subscribe((data: any[]) => {
      if (data) {
        this.linkedJobs = data;
        this.linkedJobs.sort((a, b) => a.allbirds_metadata['order_of_linked_job'] > b.allbirds_metadata['order_of_linked_job'] ? 1 : a.allbirds_metadata['order_of_linked_job'] === b.allbirds_metadata['order_of_linked_job'] ? 0 : -1);
        const userIds = [];
        for (let i = 0; i < this.linkedJobs.length; i++) {
          const job = this.linkedJobs[i];
          userIds.push(job.allbirds_metadata?.published_by_user_back_office_id);
          userIds.push(job.allbirds_metadata?.assigned_to_user_front_office_id);
        }
        const result = this._user.getUsersById(userIds);
      }
    }, err => {
      console.log(err);
    });
  }

  getUser(userId: any) {
    return this._user.getFromCache(userId);
  }

  navigateToJob (allbirds_job_id: any) {
    window.open(`/jobs/${allbirds_job_id}`, '_blank');
  }

  ngAfterViewInit () {
    this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
      let tab = ["job_details", "client_details"].indexOf(params['left_tab']);
      setTimeout(() => {
        if (this.tabset?.tabs[tab]) {
          this.tabset.tabs[tab].active = true
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // FEATURE/DF044-5338 - removed job details parsing from feature release. Leaving here for possible future implementation
    // if(this.addToJob && Object.keys(changes).includes('job')) {
    //   const params = this._url.getParams();
    //   if(params['filters']) {
    //     const filters = JSON.parse(params['filters']);
    //     const keyword = filters.find((f: any) => f.field === 'keyword');
    //     if(keyword) this.keywords = this.extractKeywords(keyword.value);
    //   }
    // }
    if (changes.job && !changes.job.firstChange) {
      this.job = changes.job.currentValue;
    }
  }

  ngOnDestroy() {
    if (this.updateJobSub) {
      this.updateJobSub.unsubscribe();
    }
    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }
    if(this.jobOrderSub) this.jobOrderSub.unsubscribe();
  }

  tabSelect (tab: string) {
    this._url.patch({ left_tab: tab }, true);
  }

  gotoBullhorn(jobID?: any) {
    const url = this.BULLHORN_URL + '?VIEW=edit&ENTITY=JobOrder&id=' + (jobID || this.job.allbirds_metadata.front_office_id);
    window.open(url, '_blank');
  }

  gotoRfo() {
    const orderId = this.job.allbirds_metadata.front_office_id; /* Front Office ID */
    const businessUnit = this.job.allbirds_metadata.user_branch_id; /* Business Unit (users branch id) */
    let orderType = 'T'; /* Temp and Temp to Hire */
    if (this.job.employmentTypes && (this.job.employmentTypes[0].toUpperCase() === 'PERMANENT')) {
      orderType = 'C'; /* Career/Perm */
    }
    // Construct the link to go to RFO.
    const urlSuffix = `/ORDER.ORDER.GBL?BUSINESS_UNIT=${businessUnit}&ORDER_ID=${orderId}&ORDER_TYPE=${orderType}`;
    ResolveService.goToRfo(urlSuffix)
      .catch(err => {
        console.error('[ResolveService] Error occurred trying to launch RFO.', err);
      });
  }

  isLob(lob: string): boolean {
    return (
      this.job.allbirds_metadata &&
      this.job.allbirds_metadata.lob &&
      this.job.allbirds_metadata.lob.includes(lob)
    );
  }

  get jobLob(): string {return ( this.job.allbirds_metadata && this.job.allbirds_metadata.lob); }
  get isClientOrder() { return this.job?.allbirds_metadata?.order_type !== OrderType.PIPELINE }

  getJobCategoryByCode(code: string) {
    return code.split(';')[1];
  }

  goTo(param?: string) {
    if (!this.job.allbirds_metadata || !this.job.allbirds_metadata.front_office_id) {
      this._utility.launchFeatureUnavailableModal('feature-unavailable.JOB_NO_FO_ID');
    } else {
      this._router.navigate([`/jobs/${this.job.allbirds_metadata.allbirds_job_id}/edit`, { queryParams: param }]);
    }
  }
  
  preventDefault(event: Event) {
    event.preventDefault();
  }

  toggleAutoScheduler() {
    const newVal = !this.job.allbirds_metadata.allow_auto_scheduler;
    const update: any = {
      allbirds_metadata: {
        allow_auto_scheduler: newVal
      }
    };
    if(!this.job.allbirds_metadata.interview_duration) update.allbirds_metadata.interview_duration = 30;

    const recruiterEmail = this.job.allbirds_metadata.lob.checkLob('BH') ?
      // if it is an rt job, send email as assigned_to if it exists
      (this.job.allbirds_metadata.assigned_to_user_email ? this.job.allbirds_metadata.assigned_to_user_email : null)
      :
      // otherwise if rgs, send email as published_user (if it exists, otherwise send current user email).
      (this.job.allbirds_metadata.published_by_user_email ? this.job.allbirds_metadata.published_by_user_email : this._auth.user.EmailAddr);

    const interviewerEmails = this.job.allbirds_metadata.job_interviewer
      ? this.job.allbirds_metadata.job_interviewer.filter(x=> x.user_email).map(interviewer => interviewer.user_email)
      : [];

    const interviewObj: InterviewScheduleInformation = {
      jobReference: this.job.name,
      interviewScheduling: {
        isSchedulable: newVal,
        maxAllowedSlots: this.job.allbirds_metadata.max_auto_schedules_allowed,
        interviewOptions: this.job.allbirds_metadata.auto_scheduler_interview_channel,
        location: {
          addr1: this.job.allbirds_metadata.auto_scheduler_location
        },
        clientNameId: this.job.allbirds_metadata.customer_name,
        abJobUrl: this.jobUrlService.getJobUrl(this.job.allbirds_metadata.allbirds_job_id),
        recruiterEmail:recruiterEmail,
        interviewDuration: this.job.allbirds_metadata.interview_duration || 30,
        interviewCollaborators: interviewerEmails
      }
    };
    this.loading.show();
    this.updateJobSub = this._api.updateChatbotPresets(update, this.job.allbirds_metadata.allbirds_job_id, interviewObj)
      .subscribe((data) => {
        this.loading.hide();
        if (data) {
          this.job.allbirds_metadata.allow_auto_scheduler = newVal;
        }
      }, err => {
        this.loading.hide();
        console.log(err);
      });
  }

  getOrderSourceTitleForValue(value: string) {
    /*
     * RT_SOURCE - list of RT sources
     * RGS_SOURCE - list of RGS sources
    */
    let title = value;
    let sourceValues = RT_SOURCE;
    if (this.jobLob.checkLob('RGS')) {
      sourceValues = RGS_SOURCE;
    }

    for (let i = 0; i < sourceValues.length; i++) {
      if (sourceValues[i].value === value) {
        title = sourceValues[i].title;
        break;
      }
    }

    return title;
  }

  isMonths(yearsExperience: any){
    if([.25, .5, 1.5].includes(yearsExperience)) return true;
    return false;
  }

  getVmsStatus () {
    if (this.job?.allbirds_metadata?.vms_req_id) {
      this.firestoreSub = this._firestore.listenVMSOrder(this.job?.allbirds_metadata?.vms_req_id)
      .subscribe((newVal: any) => {
        if (newVal && newVal.vmsOrdStatus) {
          this.vmsStatus = newVal.vmsOrdStatus;
        }
      });
    }
  }

  deleteAttachments($event: any) {
    let attachments: any = this.job.allbirds_metadata.attachments || [];
    attachments.splice(this.getAttachmentIndex($event), 1);
    this.job.allbirds_metadata.attachments = attachments;
    this.updateElasticAttachment();
  }

  openConfirmDialogDeleteAttachment(attachment: any) {
    const initialState = {
      attachment,
      deleteAttachment: this.deleteAttachments.bind(this)
    };
    this.modalRef = this.modalService.show(DeleteAttachmentModalComponent, { initialState });
    this.modalRef.setClass('modal-sm');
  }

  getAttachmentIndex (attachment: any) {
    const attachments: Attachment[] = this.job.allbirds_metadata.attachments || [];
    return attachments.findIndex( (att: any) => att.path === attachment.path);
  }

  updateElasticAttachment () {
    const jobAttachment = { job_id: this.job.allbirds_metadata.allbirds_job_id, attachments: this.job.allbirds_metadata.attachments };
    this._api.updateAttachment(jobAttachment).subscribe( (response: any) => {
      console.log(response);
    }, error => {
      console.log(error);
    });
  }

  openJobUrl() {
    window.open(`${this.jobUrlService.getJobUrl(this.job.allbirds_metadata.allbirds_job_id)}`, '_blank');
  }

  // FEATURE/DF044-5338 - originally planned to add job detail parsing to mimic resume parsing on talent side, but this has been pulled
  // for the initial release of this feature. May need this in the future.
  // recalculates keywords everytime the selected job changes in add-to-job-v2
  extractKeywords(keywords: string) {
    let output: string[] = [];
    let keywordsWithoutBoolean = this.removeBoolean(keywords).toLowerCase();

    // matches a word(defined as a one or more none whitespace/quote characters adjacent to eachother) OR
    // phrases(defined as a double quoted set of chars that doesn't include other double quotes)
    const regex = /[^\s"]+|"([^"]*)"/gi;
    let match = regex.exec(keywordsWithoutBoolean);
    //max to 50 keywords for highlighting
    let count = 0;
    while (match !== null && count < 50) {
      output.push(match[1] ? match[1] : match[0]); // prioritizes captured group over matched text
      match = regex.exec(keywordsWithoutBoolean); // will return next regex match
      count++;
    }

    // remove filler and off-limit words
    const fillerWords = ['a', 'an', 'and', 'of', 'the', 'for', 'in', 'at', 'as', 'on', 'to', 'with', 'or', 'not', 'span', 'class', 'kw'];
    output = output.map(w => fillerWords.indexOf(w) === -1 ? w : '');
    output = output.filter(x => x);
    console.log('extractedKeywords: ', output);
    return output;
  }

  removeBoolean (searchQuery: string) {
    if (!searchQuery || typeof searchQuery !== 'string') return searchQuery;
    let parsed = searchQuery.split(/\s+(?:AND|OR|NOT|\|)\s+|\(|\)/).join(' ').trim();
    return parsed;
  }

  // bind to innerHTML of whatever field you want to highlight
  highlightField(str: string, keywords: string[]): string {
    try {
      if (str && keywords.length) {
        let lowerCaseStr = str.toLocaleLowerCase();
        let modifiedStr = str.slice();

        keywords.forEach(kw => {
          const regex = new RegExp(`\\b${kw}\\b`);
          let match = regex.exec(lowerCaseStr);
          if(match && match[0]){
            const kwIndex = match.index;

            const left_str = modifiedStr.substring(0, kwIndex);
            const right_str = modifiedStr.substring(left_str.length + kw.length);
            modifiedStr = left_str + '<span class="kw">' + modifiedStr.substring(kwIndex, kwIndex + kw.length) + '</span>' + right_str;

            // keep the lowercased copy in sync with modified str
            const left_str_lowercase = lowerCaseStr.substring(0, kwIndex);
            const right_str_lowercase = lowerCaseStr.substring(left_str_lowercase.length + kw.length);
            lowerCaseStr = left_str_lowercase + '<span class="kw">' + lowerCaseStr.substring(kwIndex, kwIndex + kw.length) + '</span>' + right_str_lowercase;
          }
        })

        return modifiedStr;
      } else return str;
    } catch(err) {
      return str;
    }
  }

}
