<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div [formGroup]="form" class="modal-body" style="padding: 0!important">
  <div class="row w-100" style="margin:0px!important">
    <div [ngClass]="{
      'col-lg-7': conversation,
      'col-lg-12': !conversation
    }" class="custom-padding">
      <ng-container *ngTemplateOutlet="interviewTypeTemplate; context: {form: form}"></ng-container>
      <div *ngIf="
          form.controls.type.value === 'COMPLETED'
          || form.controls.type.value === 'AVAILABLE'
          || form.controls.type.value === 'OPEN'">
        <ng-container *ngTemplateOutlet="interviewChannel; context: {form: form}"></ng-container>
        <div class="row w-100">
          <div class="col-12 mt-4">
            <label class="d-block">{{'log-interview-modal.what_is_their' | translate}}</label>
            <div class="row">
              <div class="col-5">
                <select formControlName="minPay_type" class="form-control">
                  <option value="YEARLY">{{'log-interview-modal.money_per_year' | translate}}</option>
                  <option value="HOURLY">{{'log-interview-modal.money_per_hour' | translate}}</option>
                </select>
                <form-error *ngIf="form.controls" [fieldAlias]="'Min pay type'" [control]="form.controls.minPay_type">
                </form-error>
              </div>
              <div class="col-6">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text color-primary" id="basic-addon1">$</span>
                  </div>
                  <input formControlName="minPay_value" type="number" min="0" class="form-control input-with-icon-left"
                    aria-describedby="basic-addon1" />
                </div>
                <form-error *ngIf="form.controls" [fieldAlias]="'Min pay value'" [control]="form.controls.minPay_value">
                </form-error>
              </div>
            </div>
          </div>
        </div>
        <div class="row w-100">
          <div class="col-9 mt-4">
            <label class="d-block">{{'log-interview-modal.what_kind_of' | translate}}</label>
            <input formControlName="opportunities" placeholder="{{'log-interview-modal.e.g._key_motivators,' | translate}}" type="text" class="form-control" />
            <form-error *ngIf="form.controls" [fieldAlias]="'Opportunities'" [control]="form.controls.opportunities">
            </form-error>
          </div>
        </div>
        <div class="row w-100">
          <div class="col-9 mt-4">
            <label class="d-block">{{'log-interview-modal.when_is_the' | translate}}</label>
            <input formControlName="startDate" type="text" class="form-control" />
            <form-error *ngIf="form.controls" [fieldAlias]="'Start date is required'"
              [control]="form.controls.startDate"></form-error>
          </div>
        </div>
        <div class="row w-100">
            <div class="col-5 mt-4">
              <label class="d-block">{{'log-interview-modal.preferred_commute_distance' | translate}}</label>
              <input formControlName="commuteDistance" type="number" min="0" class="form-control" placeholder="{{'log-interview-modal.e.g._20' | translate}}" />
              <form-error *ngIf="form.controls" [fieldAlias]="'Commute distance'"
                [control]="form.controls.commuteDistance"></form-error>
            </div>
          </div>
        <div class="row w-100">
          <div class="col-6 mt-4">
            <label class="d-block">{{'log-interview-modal.willing_to_travel' | translate}}</label>
            <select formControlName="travel" class="form-control">
              <option value="0">0</option>
              <option value="<25%">< 25%</option>
              <option value="25-50%">25-50%</option>
              <option value="51-75%">51-75%</option>
              <option value=">75%">> 75%</option>
            </select>
            <form-error *ngIf="form.controls" [fieldAlias]="'Amount of travel'" [control]="form.controls.travel">
            </form-error>
          </div>
        </div>
        <div class="row w-100">
          <div class="col-9 mt-4">
            <label class="d-block">{{'log-interview-modal.talents_skills' | translate}}</label>
            <input formControlName="skill" placeholder="{{'log-interview-modal.type_in_skill' | translate}}" type="text" class="form-control mb-2" (keydown.enter)="addSkill()" />
            <div class="searchTag mb-1" style="float:left" *ngFor="let skill of skills">
              {{skill.displayName}}
              <span class="deleteTag" (click)="removeSkill(skill)">X</span>
            </div>
            <div *ngIf="showSkillsError && !skills.length" class="invalid-feedback">{{'log-interview-modal.skills_are_required' | translate}}</div>
          </div>
        </div>
      </div>
      <div class="row w-100">
        <div class="col-12 mt-4">
          <label class="d-block">{{'log-interview-modal.notes' | translate}}</label>
          <app-ckeditor
            id="emailBody"
            class="text-box w-100"
            [parentForm]="form"
            formConName="notes"
            height="120"
            [enableMentions]="true">
          </app-ckeditor>
          <form-error [fieldAlias]="'Notes'" [control]="form.controls.notes"></form-error>
        </div>
      </div>
    </div>
    <div *ngIf="conversationID" [ngClass]="{
      'col-lg-5': isInterviewContext,
      'd-none': isPrescreeningContext
      }" style="background:#F7F7F7; position:relative">

      <app-conversation [conversationID]="conversationID"></app-conversation>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="w-100 text-right">
    <span class="pr-3">
      <button type="button" class="btn btn rds--button--primary" aria-label="Close" (click)="modalRef.hide()">
        {{'log-interview-modal.cancel' | translate}}
      </button>
    </span>
    <button (click)="submit()" type="button" class="btn rds--button--secondary" data-puppeteer="manual-interview-continue-button">
      {{'log-interview-modal.continue' | translate}}
    </button>
  </div>
</div>

<!-- Prescreening -->
<ng-template #prescreening let-form="form">
  <h2>{{'log-interview-modal.log_prescreening_results' | translate}}</h2>
  <div class="w-100 mt-4" [formGroup]="form">
    <label class="d-block">{{'log-interview-modal.availability' | translate}}</label>
    <div class="mt-2">
      <input id="avail" (click)="checked('AVAILABLE')" [checked]=" form.controls.type.value === 'AVAILABLE' "
             class="rds&#45;&#45;radio-button" type="radio" />
      <label for="avail" class="rds&#45;&#45;radio-button__label">
        <span class="rds&#45;&#45;radio-button__appearance"></span>
        {{'log-interview-modal.available_and_interested' | translate}}
      </label>
    </div>
    <div class="mt-2">
      <input id="open" (click)="checked('OPEN')"
             [checked]=" form.controls.type.value === 'OPEN' " class="rds&#45;&#45;radio-button" type="radio" />
      <label for="open" class="rds&#45;&#45;radio-button__label">
        <span class="rds&#45;&#45;radio-button__appearance"></span>
        {{'log-interview-modal.not_interested_in' | translate}}
      </label>
    </div>
    <div class="mt-2">
      <input id="not available" (click)="checked('NOT_AVAILABLE')"
             [checked]=" form.controls.type.value === 'NOT_AVAILABLE' " class="rds&#45;&#45;radio-button" type="radio" />
      <label for="not available" class="rds&#45;&#45;radio-button__label">
        <span class="rds&#45;&#45;radio-button__appearance"></span>
        {{'log-interview-modal.not_available_for' | translate}}
        <span class="pl-2">
              <select formControlName="not_available_for" class="form-control">
                <option value="Week">{{'log-interview-modal.week' | translate}}</option>
                <option value="Month">{{'log-interview-modal.month' | translate}}</option>
                <option value="3 Months">{{'log-interview-modal.three_months' | translate}}</option>
                <option value="6 Months">{{'log-interview-modal.six_months' | translate}}</option>
                <option value="Year">{{'log-interview-modal.year' | translate}}</option>
              </select>
            </span>
      </label>
    </div>
    <form-error [fieldAlias]="'Availability'" [control]="form.controls.type"></form-error>
  </div>
</ng-template>

<!-- Recruiter interview -->
<ng-template #interview let-form="form">
  <h2>{{'log-interview-modal.log_an_interview' | translate}}</h2>
  <div class="w-100 mt-4" [formGroup]="form">
    <label class="d-block">{{'log-interview-modal.interview_outcome' | translate}}</label>
    <div class="mt-2">
      <input id="carriedout" (click)="checked('COMPLETED')" [checked]=" form.controls.type.value === 'COMPLETED' "
             class="rds&#45;&#45;radio-button" type="radio" />
      <label for="carriedout" class="rds&#45;&#45;radio-button__label">
        <span class="rds&#45;&#45;radio-button__appearance"></span>
        {{'log-interview-modal.interview_complete' | translate}}
      </label>
    </div>
    <div class="mt-2">
      <input *ngIf="!form.controls.type.disabled" id="canceled" (click)="checked('CANCELLED')" [checked]=" form.controls.type.value === 'CANCELLED' "
             class="rds&#45;&#45;radio-button" type="radio" />
      <input *ngIf="form.controls.type.disabled" id="canceled" [disabled]="true" [checked]=" form.controls.type.value === 'CANCELLED' "
             class="rds&#45;&#45;radio-button" type="radio" />
      <label for="canceled" class="rds&#45;&#45;radio-button__label">
        <span class="rds&#45;&#45;radio-button__appearance"></span>
        {{'log-interview-modal.interview_was_canceled' | translate}}
      </label>
    </div>
    <div class="mt-2">
      <input *ngIf="!form.controls.type.disabled" id="noshow" (click)="checked('NO_SHOW')" [checked]=" form.controls.type.value === 'NO_SHOW' "
             class="rds&#45;&#45;radio-button" type="radio" />
      <input *ngIf="form.controls.type.disabled" id="noshow" [disabled]="true" [checked]=" form.controls.type.value === 'NO_SHOW' "
             class="rds&#45;&#45;radio-button" type="radio" />
      <label for="noshow" class="rds&#45;&#45;radio-button__label">
        <span class="rds&#45;&#45;radio-button__appearance"></span>
        {{'log-interview-modal.no_show' | translate}}
      </label>
      <div class="text-danger" *ngIf="form.controls.type.value === 'NO_SHOW' && !isAppointment">{{'log-interview-modal.no_show_candidates' | translate}}</div>
    </div>
    <form-error [fieldAlias]="'Interview type'" [control]="form.controls.type"></form-error>
  </div>
</ng-template>

<!-- Interview Channel selection -->
<ng-template #interviewChannel let-form="form">
  <div
    class="row w-100"
    [formGroup]="form"
    *ngIf="showChannel">
    <div class="col-6 mt-4">
      <label class="d-block">{{'log-interview-modal.interview_channel' | translate}}</label>
      <select formControlName="channel" class="form-control">
        <option value="IN_PERSON">{{'log-interview-modal.in_person' | translate}}</option>
        <option value="PHONE">{{'log-interview-modal.phone' | translate}}</option>
        <option value="VIDEO">{{'log-interview-modal.video' | translate}}</option>
      </select>
      <form-error *ngIf="form.controls" [fieldAlias]="'Interview channel'" [control]="form.controls.channel">
      </form-error>
    </div>
  </div>
</ng-template>
