export const steps = {
  RGS: {
    skill_assessment: 'Skill Assessment',
    background_check: 'Background Check',
    drug_screen: 'Drug Screen',
    client_specific_onboarding: 'Client-Specific Onboarding',
    e_onboarding_and_i9: 'E-Onboarding and I9',
    reference_check: 'Reference Check'
  },
  RT: {
    skill_assessment: 'Skill Assessment',
    background_check: 'Background Check',
    drug_screen: 'Drug Screen',
    client_specific_onboarding: 'Client-Specific Onboarding',
    e_onboarding_and_i9: 'E-Onboarding and I9',
    reference_check: 'Reference Check'
  }
};
