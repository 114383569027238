/**
 * For these types, the [key] value is the value stored in ElasticSearch OR
 * the ENUM that is used when sending to FO systems.
 * The [value] of each key-value pair is what is displayed on the UI.
 */

/**
 * Since the RT/RGS_NOTE_TYPES objects directly power the dropdown we use for
 * logging notes, we have to separate these if we want to be able to translate
 * the ENUM without affecting the list of actionable activity types in the
 * Log Note modal dropdown.
 */

export const RT_NOTE_TYPES = {
  'ACE Sourced': 'ACE Sourced',
  'Applicant Feedback': 'Applicant feedback',
  'Availability Call': 'Availability call',
  'Background Check': 'Background check',
  'Call Attempt': 'Call attempt',
  'Connect': 'Connect',
  'Connect- PR Increase': 'Connect - PR Increase',
  'Resume (Dice)': 'Dice Resume Feed',
  'DNC/DNW': 'DNC/DNW',
  'DBC Notes': 'RITS Notes',
  'DBC Offer': 'RITS Offer',
  'DBC Screening Info': 'RITS Screening Info',
  'DBC Submission': 'RITS Submission',
  'Elite Sourced': 'Elite Sourced',
  'Email': 'Email',
  'Important Info': 'Important info',
  'Interview Prep': 'Interview prep',
  'Left Message': 'Left message',
  'Modern Hire Interview': 'Modern Hire Interview',
  'MPC Sales Action': 'MPC Sales Action',
  'MPC Recruiter': 'MPC Recruiter',
  'MPC-Strategic Account': 'MPC - Strategic Account',
  'RADAR': 'RADAR',
  'RADAR Request': 'RADAR Request',
  'RADAR Match': 'RADAR Match',
  'RADAR Match Rejected': 'RADAR Match Rejected',
  'RADAR Sweeps': 'RADAR Sweeps',
  'Redeployment Comments': 'Redeployment comments',
  'Reference': 'Reference',
  'Resume (Monster)': 'Monster Resume Feed',
  'Sales Lead': 'Sales Lead',
  'Skills Assessment/Test Results': 'Skills Assessment/Test Results',
  'Talent Care In-Person': 'Talent care (in-person)',
  'Talent Care Touchbase': 'Talent care (touchbase)',
  'Talent Intelligence': 'Talent Intelligence',
  'Talent Visit ExistingCandidate': 'Talent visit (existing candidate)',
  'Talent Visit New Candidate': 'Talent visit (new candidate)'
};

export const RGS_NOTE_TYPES = {
  'GENCM': 'General Comment',
  'RNCD': 'Randstad National Career Day',
  'REVNT': 'Recruiting - Event',
  'RFAIR': 'Recruiting - Job Fair',
  'TCALL': 'Talent - Call/Left Message',
  'AVAIL': 'Talent - Call-in Available',
  'TENGM': 'Talent - Employee Engagement',
  'TSOC': 'Talent - Social Connect',
  'TSOCO': 'Talent - Social One Way Comm',
  'TSMPC': 'Talent - Social Most Placeable Candidate',
  'TRDIR': 'Talent - Redirect',
  'TVIST': 'Talent - Visit'
};

/**
 * The note types below are displayed in the UI when we fetch note history
 * but cannot actually be logged within the AB UI.
 */

export const OTHER_NOTE_TYPES = {
  'ACR': 'Contracts',
  'ACS': 'Customer Requirements',
  'ACT': 'Active',
  'AMDQ': 'AM Disqualified',
  'AONBD': 'Abort Onboard',
  'APO': 'Purchase Order Monitoring',
  'ARCAL': 'A/R - Client Collection Call',
  'ASEP': 'Talent - Assignment Sep Sent',
  'ASGD': 'Assigned',
  'ASGE': 'Assignment Exists',
  'AUTOM': 'Automatched',
  'AWRD': 'Awarded',
  'BEEDQ': 'Beeline Disqualified',
  'BEEQ': 'Beeline Qualified',
  'BILCK': 'Bill Arrival Check',
  'CAPPT': 'Client Dev - Future Sched Appt',
  'CAPRD': 'Candidate Approved',
  'CCALL': 'Client Development - Call',
  'CCFEE': 'Client Fee Payers - Call',
  'CCFOR': 'Client Hire Forecast - Call',
  'CCLD': 'Cancelled',
  'CCCMDQ': 'CCM Team Disqualified',
  'CCOLD': 'Client Development - Cold Call',
  'CDROP': 'Client Development - Drop-By',
  'CEML': 'Client Development - Email',
  'CEMLD': 'Client Development - Email Dialogue',
  'CIMD': 'Integrated Market Development',
  'CINTV': 'Client - Schedule Interview',
  'Client Phone Screen': 'Client phone screen',
  'CLNCH': 'Client Develop- Lnch/Entertain',
  'CLOS': 'Closed',
  'CLTR': 'Client Development - Letter',
  'CCNOTR': 'Candidate Not Ready to Work',
  'CNSTL': 'Client New Start Letter',
  'CPROP': 'Client Dev - 2nd Appt Proposal',
  'CRDIT': 'Client Credit Check',
  'CRIM': 'Do Not Use This - Use TCRIM',
  'CSHLT': 'VMS - Shortlist Tracking',
  'CSKMK': 'Client Develop - Skill Market',
  'CSUB': 'Client Submit Resume/Portfolio',
  'CTAMK': 'Client Develop - Talent Market',
  'CTNOW': 'Specialty - Talent Now',
  'CTTHC': 'Temp-to-Hire Client Call',
  'CTTHP': 'Temp-to-Hire Presentation',
  'CTTHS': 'Temp-to-Hire Contract Signed',
  'CVIST': 'Client Development - Appt',
  'CVML': 'Client Development - Voicemail',
  'CWPDQ': 'CWP Disqualified',
  'CWPQ': 'Qualified',
  'CWPRJ': 'CWP Rejected',
  'DECAI': 'Declined After Interview',
  'DECL': 'Declined',
  'DECLO': 'Declined by Onsite',
  'DECPV': 'Declined by Primary Vendor',
  'DEL': 'Deleted',
  'DISAP': 'Disapproved',
  'DISQ': 'Disqualified',
  'DRAFT': 'Draft',
  'DRRM': 'Draft Removed',
  'EMAIL': 'Mass Email',
  'EMAL': 'Mass Email Campaign',
  'EMDQ': 'EM Disqualified',
  'ENDC': 'End Communication',
  'ENGD': 'Engaged',
  'ENGNA': 'Engagement - Not Approved',
  'ENGPA': 'Engaged - Pending Approval',
  'ENGPV': 'Engaged - Pending Vend Accept',
  'ENGR': 'Engagement Requested',
  'FALL': 'Fall Off',
  'FAX': 'General Faxes',
  'FDCSP': 'FDC Security Pending',
  'FILL': 'Filled',
  'FREQ': 'Fill Requisition',
  'HIRE': 'Hired',
  'HMC': 'HM Considering',
  'HMDC': 'Hiring Manager Declined',
  'HMDQ': 'Hiring Manager Disqualified',
  'HMNI': 'Hiring Manager Not Interested',
  'HMOH': 'On Hold by HM',
  'HMQ': 'HM Qualified',
  'HMREJ': 'HM Rejected',
  'INACT': 'Inactive',
  'INCD': 'Incomplete Data',
  'INEC': 'Ineligible Candidate',
  'IREJ': 'Interview Rejected',
  'JCCLD': 'Job Cancelled',
  'JSDEC': 'Job Seeker Declined',
  'JSIT': 'Job Seeker Interested',
  'JSNIT': 'Job Seeker Not Interested',
  'MDECL': 'Mutually Declined',
  'MKTA1': 'Appt./2002 Client Offer Progm',
  'MKTE': 'Marketing - Email',
  'MKTM1': 'Marketing Mailer',
  'MKTV1': 'Visit /2002 Client Offer Progm',
  'MKTV5': 'Visit / 2005 Spring Blitz',
  'MSPDQ': 'MSP Disqualified',
  'MSPQ': 'MSP Qualified',
  'NEGO': 'Negotiated',
  'NEGOC': 'Negociated Closed',
  'NEW': 'New',
  'NINFO': 'Need Info',
  'NINT': 'Neither Interested',
  'NLENG': 'No longer engaged',
  'NOCNT': 'No Contact',
  'NOTA': 'Not Assigned',
  'NRTP': 'New Rate Proposed',
  'NSHOW': 'No Show',
  'ODECL': 'Offer Declined',
  'OEML': 'Outlook Email',
  'OFCLD': 'Offer Cancelled',
  'OFFA': 'Offer Accepted',
  'OFFER': 'Talent - Make Offer',
  'ONBD': 'Talent Onboarded',
  'ONBDA': 'Onboarding Aborted',
  'ONOH': 'On Hold by Onsite',
  'ORDRD': 'Order Received',
  'OOREJ': 'Offer rejected',
  'ORES': 'Offer Rescinded',
  'OTCAN': 'Ownership Change',
  'OWITH': 'Offer Withdrawn',
  'PBRTD': 'Proposed Bill Rate Declined',
  'PCPID': 'Pending Client Person ID',
  'PDDC': 'Pending Due Diligence Check',
  'PEND': 'Pending',
  'PFREQ': 'Propose Fill Requisition',
  'PIDEC': 'Post Interview declination',
  'PMDQ': 'PM disqualified',
  'PNDHM': 'Pending Hiring Manager',
  'PNDHR': 'Pending HR Staffing Screening',
  'PNDOC': 'Pending Onboarding Compliance',
  'PNDOR': 'Pending Override',
  'PNDVM': 'Pending Vendor Manager',
  'PNDWU': 'Pending Worker Upload',
  'POHLD': 'Put On Hold',
  'PONBD': 'Pending Onboarding',
  'PORDR': 'Purchase order',
  'PPOS': 'Pending Program Office Screening',
  'PRACC': 'Proposed Rate Accepted',
  'PREQ': 'Prequalified',
  'PRESM': 'Presented to Manager',
  'PROC': 'In Process',
  'PSAFE': 'Pending SAFE Approval',
  'PSUPA': 'Pending Supplier Approval',
  'PUID': 'Pending Username',
  'PUREQ': ' Purchase Requsition',
  'QARRV': 'Quality Control- Arrival',
  'QB1DA': 'Quality Control-End of 1st Day',
  'QC2DA': 'Quality Control- 2nd Day Check',
  'QD1WK': 'First Week Check',
  'QD2WK': 'Second Week Check',
  'QD3WK': 'Third Week Check',
  'QDTCA': 'Quality Control - Call Talent',
  'QE30D': '30 Days from Start',
  'QF60D': '60 Days from Start',
  'QG90D': '90 Days from Start',
  'QHEXT': 'Extension Check',
  'QI10D': '10 Days from Start',
  'QUAL': 'Quality Assurance',
  'QUALT': 'Talent Qualified',
  'QUFBK': 'Quality Feedback - Complete form',
  'RCDIQ': 'Resource Center Disqualified',
  'RCMCM': 'RCS Monthly Client Meet',
  'RCQBR': 'RCS Quarterly Business Review',
  'RCVIS': 'RCS Client Visit',
  'RCVST': 'RCS Client Visit',
  'Recruiter Phone Screen': 'Recruiter phone screen',
  'REJ': 'Talent Rejected',
  'REJDU': 'Reject - Duplicate',
  'REJII': 'Reject - Inadequate Info',
  'REJNA': 'Reject - Not Available',
  'REJNQ': 'Reject - Not Qualified',
  'RREJNS': 'Rejected No Show',
  'REJON': 'Reject - Onboarding',
  'REJRC': 'Reject - Req Closed/Filled',
  'REJRI': 'Reject - Rate Issue',
  'REJSC': 'Reject - Stronger Cand',
  'REJVA': 'Rejected - Vend Accept Process',
  'RELPO': 'Released to Program Office',
  'REPLY': 'Reply',
  'REQ': 'Requisitioned',
  'REQC': 'Requistion Complete',
  'REREQ': 'Release to Requestor',
  'RHIRE': 'Rehired',
  'RHRK': 'General RHRk Management Issues',
  'RIKPI': 'RIS Client KPI Summary',
  'RIMCM': 'RIS Monthly Client Meeting',
  'RIQBR': 'RIS Quarterly Business Review',
  'RISK': 'General Risk Management Issues',
  'RIVIS': 'RIS Client visit',
  'ROFFR': 'Ready for Offer',
  'RPLC': 'Replaced',
  'RQUO': 'Requoted',
  'RQUOC': 'Requoted Closed',
  'RRTRQ': 'Refused Release to Requestor',
  'RSMAQ': 'Talent - Resume Update',
  'RTXT': 'Text',
  'RUBPA': 'Randstad U - Branch Pay Course',
  'RUTPA': 'Randstad U - Talent Pay Course',
  'SAPPR': 'Security Approved',
  'SDISA': 'Security Disapproved',
  'SELCT': 'Selected',
  'SENDO': 'App/Emp sent to client interview',
  'SHOR': 'Talent Shortlisted',
  'SSAVL': 'Self Service Availability',
  'STRT': 'Assigned',
  'SWITH': 'SYS Withdrawn',
  'TALER': 'Specialty - Talent Job Alert',
  'TCOUT': 'Talent - Call Out',
  'TCRED': 'Talent - Credit Check',
  'TCRIM': 'Talent - Criminal Background Check',
  'TDRUG': 'Talent - Drug Screen',
  'TEARL': 'Talent - Left early',
  'TEML': 'Talent - Email',
  'TEND': 'Talent - Assignment Ending',
  'TERM': 'Talent - Terminated',
  'TFING': 'Talent - Fingerprint Check',
  'TI9': 'Talent - I9 Issues',
  'TINTC': 'Interview Cancelled',
  'TINTV': 'Talent - Schedule Interview',
  'TISSU': 'Talent - Issue',
  'TLATE': 'Talent - Late to work',
  'TLTR': 'Talent - Letter',
  'TNCNS': 'Talent - No Call, No Show',
  'TPRIV': 'Talent - HIPAA Privacy Practice',
  'TREF': 'Talent - Reference Check',
  'TREV': 'Talent - Review',
  'TSASS': 'Talent - Skill Assessment',
  'TSCTO': 'Talent - Scheduled time off',
  'TTXT': 'Text Sent',
  'TWA': 'Talent - Expired Work Authorization',
  'TWA30': 'Talent - Work Auth. Expires in 30 days',
  'UNAVL': 'Unavailable',
  'UNEMP': 'Risk - Unemployment',
  'UNREM': 'Unavailable - Removed by',
  'VALID': 'Valid Contact',
  'VINTC': 'Video Interview Request Complete',
  'VINTS': 'Video Interview Request Sent',
  'VMODQ': 'VMO Disqualified',
  'VVMOQ': 'VMO Qualified',
  'VMORD': 'VMO Recruiter Disqualified',
  'WCOMP': 'Risk - Workers Comp',
  'WCT': 'Warm Call Transfer',
  'Webcam Interview': 'Webcam interview',
  'WEVAL': 'Worksite Evaluation',
  'WITH': 'Talent Withdrawn',
  'WOACC': 'Work Order Accepted',
  'WOACT': 'Work Order Activated',
  'WOCLS': 'Work Order Closed',
  'WOCRT': 'Work Order Created',
  'WODEC': 'Work Order Declined',
  'WODFT': 'Work Order Draft',
  'WONSW': 'Work Order No Show',
  'WOPAP': 'Work Order Pending Approval',
  'WOREJ': 'Work Order Rejected',
  'WPINT': 'Withdrawn: Pre-Interview',
  'WPOIN': 'Withdrawn Post Interview',
  'XVISA': 'Talent - Expired Visa'
};


/**
 * Social media types for RFO/RGS
 */

export const SOCIAL_MEDIA_TYPES = {
  'FB': 'Facebook',
  'TW': 'Twitter',
  'LI': 'Linkedin',
  'OTH': 'Other',

};

export const ALL_NOTE_TYPES = Object.assign({}, RGS_NOTE_TYPES, RT_NOTE_TYPES, OTHER_NOTE_TYPES, SOCIAL_MEDIA_TYPES);
