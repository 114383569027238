import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FormValidationService } from '../../../services/form-validation/form-validation.service';
import { UtilityService } from '../../../services/utility/utility.service';

@Component({
  selector: 'app-email-multi-input',
  templateUrl: './email-multi-input.component.html',
  styleUrls: ['./email-multi-input.component.scss']
})
export class EmailMultiInputComponent {

  // The label of the form control (displayed on UI & used to construct id).
  @Input() label: string = '';

  // The FormControl element from a parent form group.
  @Input() control: AbstractControl;

  // Emits an email value when email input is received.
  @Output() onEmailInput: EventEmitter<string> = new EventEmitter<string>();

  // Emits an email value when an email pill is clicked (denotes removal).
  @Output() onEmailRemove: EventEmitter<string> = new EventEmitter<string>();

  // Used for the input model.
  input: string = '';

  constructor() { }

  /**
   * Returns an id for use with the input in the template.
   */
  get inputId(): string {
    return `email-multi-input-${this.label}`;
  }

  /**
   * Used specifically to detect 'Enter' keypresses (which should act as breaking).
   * @param event
   */
  handleKeypress(event: KeyboardEvent) {
    if (['Enter', ','].includes(event.key)) {
      this.processValue(this.input);
    }
  }

  /**
   * Handles the current value of the input when focus is lost.
   * @param event
   */
  handleBlur(event: FocusEvent) {
    const target = event && (event.target as HTMLInputElement) || null;
    if (target && target.value && target.value.length) {
      this.processValue(target.value);
    }
  }

  /**
   * Emits the email value of the pill that was clicked.
   * @param email
   */
  handlePillClick(email: string) {
    this.onEmailRemove.emit(email);
  }

  /**
   * Parses value to determine if its a valid email. If so, emits the email value
   * and clears the input. If not, registers an error on the form control.
   * @param value
   */
  processValue(value: string) {
    const email = value.toLowerCase();
    if (UtilityService.validateEmail(email)) {
      this.onEmailInput.emit(email);
      setTimeout(() => {
        this.input = '';
      });
    } else {
      FormValidationService.markAsTouched(this.control);
      FormValidationService.clearErrors(this.control);
      FormValidationService.setError(this.control, 'email');
    }
  }

}
