import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShouldWarnUnsaved } from '../../../../../services/modal/modal.service';

@Component({
  selector: 'app-log-feedback-modal',
  templateUrl: './log-feedback-modal.component.html',
  styleUrls: ['./log-feedback-modal.component.scss']
})
export class LogFeedbackModalComponent implements OnInit, ShouldWarnUnsaved {

  textareaLabel: string;
  acceptFn: Function;
  rejectFn: Function;
  feedbackForm: FormGroup;

  constructor(
    public _bsModalRef: BsModalRef,
    private _formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.feedbackForm = this._formBuilder.group({
      hiringManagerFeedback: [null, [Validators.required]]
    });
  }

  accept() {
    this.feedbackForm.controls.hiringManagerFeedback.markAsTouched();
    if (this.feedbackForm.invalid) {
      return;
    }
    this.acceptFn(this.feedbackForm.controls.hiringManagerFeedback.value);
    this.feedbackForm.reset();
    this._bsModalRef.hide();
  }

  reject() {
    this.feedbackForm.controls.hiringManagerFeedback.markAsTouched();
    if (this.feedbackForm.invalid) {
      return;
    }
    this.rejectFn(this.feedbackForm.controls.hiringManagerFeedback.value);
    this.feedbackForm.reset();
    this._bsModalRef.hide();
  }

  isUnsaved(): boolean {
    return this.feedbackForm.dirty;
  }

}
