import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { AdminRouteGuard } from './admin-route.guard';
import { AuthRouteGuard } from './auth-route.guard';
// Import the authentication guard
import { AuthGuard } from '@auth0/auth0-angular';

const routes: Routes = [
  { path: '', redirectTo: 'jobs/dashboard', pathMatch: 'full' },
  { path: 'jobs', canActivate: [AuthGuard, AuthRouteGuard], loadChildren: () => import('./modules/jobs/jobs.module').then(mod => mod.JobsModule) },
  { path: 'talent', canActivate: [AuthGuard, AuthRouteGuard], loadChildren: () => import('./modules/talent/talent.module').then(mod => mod.TalentModule) },
  { path: 'vms', canActivate: [AuthGuard, AuthRouteGuard], loadChildren: () => import('./modules/vms-jobs/vms-jobs.module').then(mod => mod.VmsJobsModule) },
  {
    path: 'admin',
    canActivate: [AdminRouteGuard], // require users to be admin to load this route
    loadChildren: () => import('./modules/admin/admin.module').then(mod => mod.AdminModule)
  },
  { path: 'settings', canActivate: [AuthGuard, AuthRouteGuard], loadChildren: () => import('./modules/settings/settings.module').then(mod => mod.SettingsModule) },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: 'jobs/dashboard' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
