import { Applications } from '@allbirds-ui/allbirds-types';
import ProcessStep = Applications.ProcessStep;

export const KEY_TO_ROUTE: { [key in ProcessStep | 'unanswered' | 'onboarding']?: string } = {
  unanswered: 'unanswered-applicants',
  onboarding: 'onboarding',
  [ProcessStep.PRESCREENING]: 'prescreening',
  [ProcessStep.INTERVIEW_RECRUITER]: 'recruiter-interview',
  [ProcessStep.REVIEW_AM]: 'review-account-manager',
  [ProcessStep.REVIEW_HM]: 'review-hiring-manager',
  [ProcessStep.INTERVIEW_HM]: 'interview-hiring-manager',
  [ProcessStep.OFFER]: 'offer'
};

export const ROUTE_TO_KEY: { [key: string]: ProcessStep | 'unanswered' | 'onboarding' } = {
  'unanswered-applicants': 'unanswered',
  'onboarding': 'onboarding',
  'prescreening': ProcessStep.PRESCREENING,
  'recruiter-interview': ProcessStep.INTERVIEW_RECRUITER,
  'review-account-manager': ProcessStep.REVIEW_AM,
  'review-hiring-manager': ProcessStep.REVIEW_HM,
  'interview-hiring-manager': ProcessStep.INTERVIEW_HM,
  'offer': ProcessStep.OFFER
};

export const KEY_TO_LABEL: { [key in ProcessStep | 'unanswered' | 'onboarding']?: string } = {
  unanswered: 'Applicants without pre-screening',
  onboarding: 'Onboarding',
  [ProcessStep.PRESCREENING]: 'Pre-screening',
  [ProcessStep.INTERVIEW_RECRUITER]: 'Recruiter interview',
  [ProcessStep.REVIEW_AM]: 'Internal Submission',
 [ProcessStep.REVIEW_HM]: 'Client Submission',
 [ProcessStep.INTERVIEW_HM]: 'Client Interview',
  [ProcessStep.OFFER]: 'Offer'
};
