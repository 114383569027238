import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { LoadingSpinnerService } from 'src/app/shared/services/loading-spinner/loading-spinner.service';
import { JobOrderService } from 'src/app/shared/services/job-order/job-order.service';
import { AdManagementService } from 'src/app/shared/services/ad-management/ad-management.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { ToastClass, ToastService } from 'src/app/shared/services/toast';
import { Job } from '../../../../../shared/models/external/job.model';
import { ApiService } from '../../../../../shared/services/api/api.service';
import { Moment } from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { OrderType } from 'src/app/shared/models/internal/job-form.interface';
import {UtilityService} from "../../../../../shared/services/utility/utility.service";

@Component({
  selector: 'app-ad-management-modal',
  templateUrl: './ad-management-modal.component.html',
  styleUrls: ['./ad-management-modal.component.scss']
})
export class AdManagementModalComponent implements OnInit, OnDestroy {
  user: any;
  allbirds_job_id: string;
  description: string;
  adSubscription: any;
  adData: any;
  jobData: Job;

  // jobData values
  unpublishedByUser: string;
  publishedByUser: string;
  postingExpireTimeAsDate: Moment;
  postingPublishTimeAsDate: Moment;
  publishedStatus: boolean;
  logicmelonUrl: string;
  compellingIntro: string;
  trVariable1: any;
  customer_name: string;
  customer_priority: number;
  front_office_id: string;
  title: string;
  rusa_web_url: string;
  get conditionWebUrl(): boolean { return Boolean(this.rusa_web_url); }
  get isClientOrder(): boolean { return this.jobData?.allbirds_metadata?.order_type !== OrderType.PIPELINE; }

  constructor(
    private _api: ApiService,
    public _bsModalRef: BsModalRef,
    private _auth: AuthService,
    private _adManagementService: AdManagementService,
    private jobOrderService: JobOrderService,
    private _router: Router,
    private _loadingSpinner: LoadingSpinnerService,
    private toastService: ToastService,
    private translate: TranslateService,
    private _utility: UtilityService
  ) {}

  ngOnInit() {
    this.user = this._auth.user;
    this.adSubscription = this._adManagementService.adDataObservable.subscribe(
      ads => {
        this.adData = ads;
      }
    );
    this.setJobDataValues();
    this.trVariable1 = { value: this.unpublishedByUser };
  }

  get userLob(): string { return this.user && this.user.Source; }

  ngOnDestroy() {
    this.adSubscription.unsubscribe();
  }

  setJobDataValues() {
    if (this.jobData) {
      const jobData: Job = this.jobData;
      // jobData cast to any because these 2 properties are added where the modal is shown from
      // and are not part of the JobOrder interface
      this.postingPublishTimeAsDate = jobData.postingPublishTime;
      this.postingExpireTimeAsDate = jobData.postingExpireTime;
      if (jobData.title) {
        this.title = jobData.title;
      }

      if (jobData.allbirds_metadata) {
        const allBirds = jobData.allbirds_metadata;
        this.publishedStatus = this.jobOrderService.publishedStatus(jobData);
        this.unpublishedByUser = allBirds.unpublished_by_user;
        this.publishedByUser = allBirds.published_by_user;
        this.logicmelonUrl = allBirds.logicmelon_url;
        this.compellingIntro = allBirds.compelling_intro;
        this.customer_name = allBirds.customer_name || '';
        this.customer_priority = allBirds.customer_priority;
        this.front_office_id = allBirds.front_office_id;
        this.rusa_web_url = allBirds.rusa_web_url;
      }
    }
  }

  onEditClick() {
    this._bsModalRef.hide();
    this._router.navigateByUrl(`/jobs/${this.allbirds_job_id}/edit?tab=2`);
  }

  onPreviewClick() {
    this._bsModalRef.hide();
    // this._router.navigateByUrl(`/jobs/${this.allbirds_job_id}/edit?tab=2&preview=true`);
    window.open(`/jobs/${this.allbirds_job_id}/edit?tab=2&preview=true`);
  }

  onViewClick() {
    if (this.rusa_web_url) {
      window.open(this.rusa_web_url);
    } else {
      this.onPreviewClick();
    }
  }

  onPublishClick() {
    this._bsModalRef.hide();
    const orderType = this.jobData?.allbirds_metadata.order_type || '';
    const orderCreated = this.jobData?.allbirds_metadata.order_created || '';
    const publishedStatus = this.jobData?.allbirds_metadata.published_status || false;

    if (this.userLob?.checkLob('RGS')
      && orderType === 'Pipeline'
      && !publishedStatus
      && orderCreated) {
      this._utility.launchFeatureUnavailableModal('feature-unavailable.JOB_CLOSED_ACTIVE_EXPIRED');
    } else {
      this._router.navigateByUrl(
        `/jobs/${this.allbirds_job_id}/edit?tab=2&publish=true`
      );
    }
  }

  onUnpublishClick() {
    this._loadingSpinner.show();
    const interviewObj = this.jobOrderService.getInterviewObject(this.jobData, this._auth.user);
    this._api.unpublishJob(`${this.allbirds_job_id}`, interviewObj)
      .subscribe(this.unpublishJobSuccess,
        (err: any) => {
          console.log(err);
          this._loadingSpinner.hide();
          this.toastService.showToast('An error occurred trying to unpublish the job.', { cssClass: ToastClass.DANGER });
        });
  }

  unpublishJobSuccess = () => {
    this.toastService.showToast(`The job has been successfully updated.`, { cssClass: ToastClass.SUCCESS });
    this.jobData.allbirds_metadata.published_status = false;
    this.jobData.allbirds_metadata.publish_immediately = false;
    if(!this.isClientOrder) this.jobData.allbirds_metadata.order_status = "Closed";
    this.setJobDataValues();
    this.jobOrderService.storeJobDetails(this.jobData);
    this._loadingSpinner.hide();
  };
}
