import { Injectable, OnDestroy } from '@angular/core';
import { User } from '../../models/external/user.model';
import { ApiService } from '../api/api.service';
import { Job } from '../../models/external/job.model';
import { environment } from 'src/environments/environment';

const SERVER_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnDestroy {
  // User profile storage.
  private cache = new Map<string, User>();

  // Maps back office IDs to front office IDs.
  idMap: { [key: string]: string } = {};

  //Maps emails to front office IDs.
  emailIdMap: {[key: string]: string} = {};

  constructor(private _api: ApiService) { }

  ngOnDestroy(): void {
    this.cache.clear();
    this.idMap = {};
  }

  /**
   * Given an array of back office IDs, retrieves the profile associated with said
   * ID and adds it to the cache map object.
   * @param userIDs - array of back office IDs and/or front office IDs
   */
 async getUsersById(userIDs: string[], includeRoles = false): Promise<boolean> {
    // Filter out the user IDs that already exist in the cache.
    userIDs = userIDs.filter(id => !!id && !this.getFromCache(id));
    if (!userIDs.length) {
      return true;
    }

      const users = await this._api.getUsersByIds(userIDs, includeRoles, false, []);
      if (users) {
        for (const user of users) {
          this.addToCache(user as User);
        }
        return true;
      } else {
        return false;
      }
  }

  async getUsersByEmail(emails: string[], includeRoles = false): Promise<boolean> {
    // Filter out the user IDs that already exist in the cache.
    emails = emails.filter(id => !!id && !this.getFromCacheUsingEmail(id));
    if (!emails.length) {
      return true;
    }
    // If we've hit this, then we have profiles to query for.
    try {
      const users =  await this._api.getUsersByIds([], includeRoles, false, emails);
      if (users) {
        for (const user of users) {
          this.addToCache(user as User);
        }
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e);
      return false;
    }


  }

  /**
   * Given an array of job orders, extracts all of the associated back office IDs, queries
   * for the user profiles, and then adds them to the cache.
   * @param jobOrders - array of job order objects
   */
  extractUsersFromJobOrders(jobOrders: Job[]): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!jobOrders || !jobOrders.length) {
        return resolve(true);
      }
      // We use an object to store the ids we need to query so that we don't run into duplicates.
      const queryIds: { [key: string]: boolean } = {};
      // Extract all the IDs from the job orders.
      let i = jobOrders.length;
      while (i--) {
        const jo = jobOrders[i];
        if (jo.allbirds_metadata.published_by_user_back_office_id) {
          queryIds[
            jo.allbirds_metadata.published_by_user_back_office_id
            ] = true;
        }
        if (jo.allbirds_metadata.published_by_user_front_office_id) {
          queryIds[
            jo.allbirds_metadata.published_by_user_front_office_id
            ] = true;
        }
        if (jo.allbirds_metadata.assigned_to_user_back_office_id) {
          queryIds[jo.allbirds_metadata.assigned_to_user_back_office_id] = true;
        }
        if (jo.allbirds_metadata.assigned_to_user_front_office_id) {
          queryIds[
            jo.allbirds_metadata.assigned_to_user_front_office_id
            ] = true;
        }
      }
      // Filter out the IDs of users already in the cache.
      const idArr = Object.keys(queryIds).filter(id => !this.getFromCache(id));
      // Resolve if no profiles need to be queried for; else, fetch profiles.
      if (!idArr.length) {
        return resolve(true);
      }
      this.getUsersById(idArr)
        .then(() => resolve(true))
        .catch(err => reject(err));
    });
  }

  /**
   * Adds a user's profile to the cache.
   * @param user {any} Should be a user object, but NOT an instance of the User class
   */
  addToCache(user: User): void {
    // For RGS, BackOfficeID === Oprid. In the case where
    // a user has no Oprid, we just default to BackOfficeID.
    const frontOfficeId = user.Oprid || '';
    const backOfficeId = user.BackOfficeID || '';
    const email = user.EmailAddr || '';

    if (backOfficeId) {
      this.idMap[backOfficeId] = frontOfficeId || backOfficeId;
    } else if (frontOfficeId) {
      this.idMap[frontOfficeId] = frontOfficeId;
    }
    if(email) {
      this.emailIdMap[email] =  frontOfficeId || backOfficeId;
    }

    const id = frontOfficeId || backOfficeId || '';
    if (id) {
      this.cache.set(id, user);
    }
  }




  /**
   * Retrieves a user's profile from the cache.
   * @param id - front office id
   */
  getFromCache(id: string): User | undefined {
    // If the ID is not a number, we know its the back office
    // id, so retrieve the proper front office id to retrieve the
    // value since our map uses front office id as the key.
    const frontOfficeId = id in this.idMap ? this.idMap[id] : id;
    // console.log(`Attempting to get ${id} from cache:`, this.cache);
    return this.cache.get(frontOfficeId);
  }

  getFromCacheUsingEmail(email: string) {
    const frontOfficeId = email in this.emailIdMap? this.emailIdMap[email] : email;
    return this.cache.get(frontOfficeId);
  }

  updatePreferences(user: User) {
    return this._api.updateUserPreferences(user);
  }
}
