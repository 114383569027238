<div class="modal-header" style="justify-content: flex-end;">
  <button type="button" class="close-btn" aria-label="Close" (click)="_bsModalRef.hide()">
    <i class="icon-WL_icon_close"></i>
  </button>
</div>

<div class="modal-body">

  <h2 class="mb-2">{{'ad-management-modal.manage_ad' | translate}}</h2>

  <!-- job details -->
  <table>
    <tr>
      <td><h4>{{ "send-user-email.job_title" | translate }}</h4></td>
      <td><h4 class="gray-text ml-3">{{title}}</h4></td>
    </tr>
    <tr>
      <td><h4>{{ ((isClientOrder && customer_name) ? "send-user-email.company" : customer_priority ? "send-user-email.priority" : '') | translate }}</h4></td>
      <td><h4 class="gray-text ml-3">{{isClientOrder ? customer_name : ''}} <span class="priority" *ngIf="customer_priority">P{{customer_priority}}</span> </h4></td>
    </tr>
    <tr>
      <td><h4>{{ "send-user-email.job_id" | translate }}</h4></td>
      <td>
        <h4 class="gray-text ml-3" >{{front_office_id}}</h4>
      </td>
    </tr>
  </table>

  <div class="row">
    <div class="col-md-6">
      <!-- Publications section -->
      <div class="modal-body-section">
        <h3>{{'ad-management-modal.publications' | translate}}</h3>
        <div class="ad-list">
          <div class="not-published-notice" *ngIf="!publishedStatus">
            <p>{{'ad-management-modal.this_job_is' | translate}}</p>
            <p *ngIf="unpublishedByUser !== ''">{{'ad-management-modal.unpublished_by' | translate: trVariable1}}</p>
            <ul class="hz-btn-list">
              <li><button class="btn rds--button--primary" (click)="onPreviewClick()">{{'ad-management-modal.preview_ad' | translate}}</button></li>
              <li><button class="btn rds--button--primary" (click)="onPublishClick()" data-puppeteer="publish-button">{{'ad-management-modal.publish' | translate}}</button></li>
            </ul>
          </div> <!-- / .not-published-notice -->
          <table *ngIf="publishedStatus">
            <tbody>
              <tr>
                <td>{{'ad-management-modal.published_by' | translate}}</td>
                <td>{{ publishedByUser }}</td>
              </tr>
              <tr>
                <td>{{'ad-management-modal.publish_period' | translate}}</td>
                <td>{{ postingPublishTimeAsDate | date:'mediumDate' }} - {{ postingExpireTimeAsDate | date:'mediumDate' }}</td>
              </tr>
              <tr>
                <td colspan="2">
                  <ul class="hz-btn-list">
                    <li><button class="btn rds--button--primary" (click)="onViewClick()">{{'ad-management-modal.view_ad' | translate}}</button></li>
                    <li><button class="btn rds--button--primary" (click)="onUnpublishClick()" data-puppeteer="unpublish-button">{{'ad-management-modal.unpublish' | translate}}</button></li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- / .ad-list-item -->
        </div> <!-- / .ad-list -->
      </div> <!-- / .modal-body-section -->

      <!-- External ads section. -->
      <div class="modal-body-section" *ngIf="userLob?.checkLob('RGS') && publishedStatus">
        <h3>{{'ad-management-modal.external_ads' | translate}}</h3>
        <p class="mb-2">{{'ad-management-modal.do_you_want' | translate}}</p>
        <a target="_blank" [href]="logicmelonUrl" class="btn rds--button--primary">{{'ad-management-modal.publish_to_other' | translate}}</a>
      </div>
    </div> <!-- / .col-6 -->

    <!-- Right half -->
    <div class="col-md-6">
      <!-- Compelling intro section. -->
      <div class="modal-body-section h-100">
        <h3>{{'ad-management-modal.job_advertisement' | translate}}<i class="icon icon-WL_icon_edit ml-2" (click)="onEditClick()"></i></h3>
        <p class="compelling-intro gray-text" [innerHTML]="compellingIntro">
        </p>
      </div>
    </div> <!-- / .col-6 -->
  </div> <!-- / .row -->
</div> <!-- / .modal-body -->
