<ng-container *ngIf="type === 'small'">
  <div class="bookmarkCardSmall" (click)="redirect($event)">
    <div class="leftSection" *ngIf="bookmark.last_visit_date_time === bookmark.create_date_time">
      <div class="marginContainer">
        <span class="dot"></span>
      </div>
    </div>
    <div class="rightSection" [ngClass]="{ 'leftMarginWhenOld': bookmark.last_visit_date_time !== bookmark.create_date_time}">
      <div style="margin-top:13px">
        <div tooltip="{{bookmark?.job?.title}} {{bookmark?.job && bookmark?.talent  ? '-': ''}} {{ bookmark?.talent?.candidateFullName}}" style="color: #0F1941; width: 100%; font-size: 14px; height: 25px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis">
          {{bookmark?.job?.title}} {{bookmark?.job && bookmark?.talent  ? '-': ''}} {{ bookmark?.talent?.candidateFullName}}
        </div>
        <div style="color:#6C6C6C; font-size: 12px; width: 100%; height: 25px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis">
          Last visited: {{bookmark.last_visit_date_time | date: 'mediumDate'}}, {{bookmark.last_visit_date_time | date: 'shortTime'}}
        </div>
      </div>
    </div>
    <div  class="position-absolute border delete p-1" >
      <img src="../../../../../assets/icons/trash-gray.svg" (click)="$event.stopPropagation();delete()" class="p-0 m-0" />
    </div>
  </div>
</ng-container>
<ng-container *ngIf="type === 'big'">
  <div #bookmarkCardBig id="bookmarkCardBig" (click)="redirect($event)">
    <div class="leftSection" style="width: 24px !important;">
      <div class="marginContainer" style="top: 50%;transform: translate(-50%, -50%);">
        <span *ngIf="bookmark.last_visit_date_time === bookmark.create_date_time"  class="dot"></span>
      </div>
    </div>
    <div class="position-relative float-left h-100 text-left" >
      <div class="position-relative float-left h-100" style="width: 150px;">
        <div style="width: 144px; height: 88px;" class="border centerContent">
          <img width="144" height="88" [src]="bookmark.image_url">
        </div>
      </div>
      <div class="position-relative float-left h-10 p-4" style="width: 440px; padding-left: 20px!important;">
        <div class="w-100" style="height: 24px; font-size: 14px; color: #6C6C6C; white-space: nowrap">
          <div class="position-relative float-left" *ngFor="let titleSection of titleArray;  index as i; first as isFirst; last as isLast;"
               [style.width.px] ="titleSection.calculatedWidth + (!isLast ? 20: 0)"
               tooltip="{{titleSection.title}}"
               [ngClass]="{'font-weight-bold': isLast}"
               style="height: 24px; min-width: 25px;">
            <span style="float: left; white-space: nowrap; overflow: hidden; text-overflow: ellipsis" [style.width.px] ="titleSection.calculatedWidth" *ngIf="titleSection.calculatedWidth > 25">{{titleSection.title}}</span>
            <span *ngIf="titleSection.calculatedWidth <= 25">...</span>
            <img style="margin-left: -5px; width: 20px!important;" *ngIf="!isLast" src="assets/icons/chevron-8.svg" />
          </div>
        </div>
        <div *ngIf="bookmark.job" class="w-100 mt-2 pl-3 border-left" style="height: 24px; color: #6C6C6C; font-size: 14px;">
          <span>{{bookmark.job.customer_name}} </span>
          <span class="priority" [ngClass]="{ 'high': bookmark?.job?.priority === 1, 'low': bookmark.job.priority === 2 }">P{{bookmark.job.priority}}</span>
        </div>
        <div class="w-100 mt-2" style="color:#6C6C6C; font-size: 12px; width: 100%; height: 25px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis">
          Last visited: {{bookmark.last_visit_date_time | date: 'mediumDate'}}, {{bookmark.last_visit_date_time | date: 'shortTime'}}
        </div>
      </div>
      <div  class="position-absolute delete p-4 mr-4" >
        <img src="../../../../../assets/icons/trash-gray.svg" (click)="$event.stopPropagation();delete()" class="p-0 m-0" />
      </div>
    </div>
  </div>

</ng-container>

<div #testDiv style="z-index: -1"></div>





