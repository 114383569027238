<div class="modal-header">
    <button type="button" class="btn-reset" aria-label="Close" (click)="_bsModalRef.hide()">
      <i class="icon-WL_icon_close"></i>
    </button>
  </div>
  
  <div class="modal-body">
    <h2>{{'cancel-recruiter-interview-modal.cancel_interview' | translate}} {{name}}</h2>
    <div class="gray-box">
        <div class="img-container">
          <vertical-align>
            <img src="assets/icons/calendar.svg"  />
          </vertical-align>
        </div>
        <div class="text-container">
          <vertical-align>
            <p class="statusLabel"> {{label}}
            </p>
            <p *ngIf="application?.randstad_process?.interviewSchedule.inPersonInterviewLocation">
              <img src="assets/icons/location.svg" class="location-small"  />
              {{application?.randstad_process?.interviewSchedule.inPersonInterviewLocation}}
            </p>
          </vertical-align>
        </div>
      </div>

    <div class="reject-offer-form" [formGroup]="form">
      <div class="form-group">
        <label for="cancelReason">{{'cancel-recruiter-interview-modal.cancel_reason' | translate}}</label>
        <select class="form-control mw-350" id="cancelReason" formControlName="cancelReason" (change)="setMessage($event.target.value)">
          <option [ngValue]="null" selected>{{'reject-offer-modal.select_the_reason' | translate}}</option>
          <option *ngFor="let item of cancelReason | keyvalue" [value]="item.key">
            {{ item.value }}
          </option>
        </select>
        <form-error [fieldAlias]="'Cancellation reason'" [control]="form.controls.cancelReason"></form-error>
      </div>

      <div *ngIf="form.controls.cancelReason.value">
          <div class="cancel-message" [innerHTML]="message"></div>
      </div>
    </div>
  </div>
  
  <div class="modal-footer">
    <ul class="hz-btn-list">
      <li>
        <button type="button" class="btn rds--button--primary" aria-label="cancel" (click)="_bsModalRef.hide()">
          {{'reject-offer-modal.cancel' | translate}}
        </button>
      </li>
      <li>
        <button type="button" class="btn rds--button--secondary" aria-label="cancel interview" [disabled]="cancelClicked" (click)="submitForm()">
          {{ 'cancel-recruiter-interview-modal.send_cancellation' | translate }}
        </button>
      </li>
    </ul>
  </div>
  