import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {Attachment} from '../../../models/internal/attachment.model';

@Component({
  selector: 'app-delete-attachment-modal',
  templateUrl: './delete-attachment-modal.component.html',
  styleUrls: ['./delete-attachment-modal.component.scss']
})
export class DeleteAttachmentModalComponent implements OnInit {

  attachment: Attachment;
  deleteAttachment: any;

  constructor(
    private _bsModalRef: BsModalRef
  ) {

  }

  ngOnInit() {

  }

  confirmDelete() {
    this.deleteAttachment(this.attachment);
    this._bsModalRef.hide();
  }

  hide() {
    this._bsModalRef.hide();
  }

  get isLink (): any {
    return (this.attachment || {} as Attachment)['type'] === 'link';
  }

}
