<div class="modal-header">
    <button type="button" class="close-btn" aria-label="Close" (click)="closeDialog()">
        <i class="icon-WL_icon_close"></i>
    </button>
</div>

<div class="modal-body">
    <h2>{{'edit-list-modal.manage_your_list' | translate}}</h2>

    <form [formGroup]="editListForm" class="edit-list-form">
        <label>
            {{'edit-list-modal.list_name' | translate}}
            <input type="text" formControlName="listName" placeholder="{{'edit-list-modal.give_your_list' | translate}}"/>
        </label>
        <div class="alert alert-danger mt-3" *ngIf="editListForm.controls['listName'].hasError('maxlength')">
            List name should be less than 30 characters.
        </div>
        <div class="alert alert-danger mt-3" *ngIf="editListForm.controls['listName'].hasError('pattern')">
            Special characters are not allowed</div>

        <label>
            Share with
            <input type="text" formControlName="shareWith" id="shareWith" placeholder="Search by name or email" />
            <app-user-lookup [searchTerm]="editListForm.controls.shareWith.value" (userSelected)="setUserInFocus($event)" [allActiveUsers]="true"></app-user-lookup>
        </label>
    </form>

    <shared-user-lists [recruiterList]="recruitersList" (updatedRecruiterList)="updateRecruiterList($event)" ></shared-user-lists>

</div>

<div id="assign-job-footer" class="modal-footer">
    <button class="btn rds--button--primary" (click)="closeDialog()">{{'edit-list-modal.cancel' | translate}}</button>
    <button class="btn btn-primary" [disabled]="!editListForm.valid" (click)="saveList()">{{'edit-list-modal.save' |
        translate}}</button>
</div>
