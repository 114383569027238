import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { FormValidationService } from '../form-validation/form-validation.service';
import { UtilityService } from '../utility/utility.service';
import { AuthService } from '../auth/auth.service';
import { Subscription } from 'rxjs';
import { VALIDATED_FORM_FIELDS } from './validated-form-fields';
import { OrderType } from 'src/app/shared/models/internal/job-form.interface';

export interface ValidationError {
  message: string;
  errorAnchor: string;
}

@Injectable({
  providedIn: 'root'
})
export class JobOrderValidationService {

  private user: any;
  private jobOrderForm: FormGroup;
  private formSubs: Subscription[] = [];
  found: any[] = [];
  quillErrors: any[] = [];

  constructor(
    private auth: AuthService,
    private utils: UtilityService
  ) {
    this.found = [];
    this.user = this.auth.user;
  }

  listen(jobOrderForm: FormGroup) {
    this.jobOrderForm = jobOrderForm;
    // All our listener functions return a subscription, which we
    // push to an array because the unsubscribe method loops through
    // this array to unsubscribe from them all (avoid mem. leaks).
    this.formSubs.push(
      // Step 1
      this.listenToPayRateLow(),
      this.listenToPayRateHigh(),
      this.listenToHeavyLifting(),
      this.listenToHeavyLiftingWeight(),
      this.listenToConsistentlyStanding(),
      this.listenToConsistentlyStandingHours(),
      this.listenToExpectedOvertimeHours(),
      this.listenToTypeOfWorkEnvironment(),
      this.listenToSkills(),
      this.listenToInternalNotes(),
      this.listenToResponsibilities(),
      this.listenToVisaSponsored(),
      this.listenToIndependentContractor(),
      this.listenToThirdParty(),
      // Step 2
      this.listenToCompellingIntro(),
      this.listenToPublishImmediately(),
      // N/A for Pipeline postings
      ...( this.isClientOrder() ?
        [
          this.listenToInternalMaxPayRate(),
          this.listenToTargetSalary(),
          this.listenToOrderStatus(),
          this.listenToBillRate(),
          this.listenToEmploymentType(),
          this.listenToJobStartTime(),
          this.listenToJobEndTime(),
          this.listenToSalaryType(),
          this.listenToPriority(),
          this.listenToCalculatedGP(),
          this.listenToMarkup(),
          this.listenToSubmissionDeadline(),
          this.listenToPermFee(),
          this.listenToInternalDescription(),
        ] : [] )
      // this.listenToVms() // disabled because of requirement
    );
  }

  unsubscribe() {
    for (let i = 0; i < this.formSubs.length; i++) {
      this.formSubs[i].unsubscribe();
    }
  }

  isClientOrder() { return this.jobOrderForm?.controls?.order_type?.value !== OrderType.PIPELINE }

  /*
    Listeners
  */

  listenToVms() {
    const { vms_req_id } = this.jobOrderForm.controls;

    return vms_req_id.valueChanges.subscribe(
      () => {
        this.handleVmsValidation(vms_req_id);
      }
    );
  }

  listenToSkills() {
    const { skills } = this.jobOrderForm.controls;
    return skills.valueChanges.subscribe(() => {
      this.handleSkillsErrors();
    });
  }


  listenToVisaSponsored() {
    const {allow_dbc_candidates} = this.jobOrderForm.controls;
    return allow_dbc_candidates.valueChanges.subscribe(()=>{
      this.handleCandidateType(["allow_dbc_candidates"])
    })
  }

  listenToIndependentContractor() {
    const {allow_independent_contractors} = this.jobOrderForm.controls;
    return allow_independent_contractors.valueChanges.subscribe(()=>{
      this.handleCandidateType(["allow_independent_contractors"])
    })
  }

  listenToThirdParty() {
    const {allow_third_party_vendors} = this.jobOrderForm.controls;
    return allow_third_party_vendors.valueChanges.subscribe(()=>{
      this.handleCandidateType(["allow_third_party_vendors"])
    })
  }



  isCreated() {
    const { order_created, jobStartTime } = this.jobOrderForm.controls;
    return order_created.valueChanges
      .subscribe((value) => {
        if (value) {
          FormValidationService.clearErrors(jobStartTime);
          FormValidationService.clearValidators(jobStartTime);
        }
      });
  }

  listenToOrderStatus(): Subscription {
    const { order_status, jobEndTime, employmentTypes } = this.jobOrderForm.controls;
    return order_status.valueChanges
      .subscribe(() => {
        this.handleOrderStatus();
        if (order_status.value === 'Unqualified') {
          FormValidationService.clearValidators(jobEndTime);
          FormValidationService.removeError(jobEndTime, 'required');
        } else {
          if (employmentTypes.value && employmentTypes.value !== 'PERMANENT') {
            FormValidationService.setValidators(jobEndTime, [Validators.required]);
            FormValidationService.markAsTouched(jobEndTime);
          }
        }
      });
  }


  listenToCompellingIntro(): Subscription {
    const { compelling_intro } = this.jobOrderForm.controls;
    return compelling_intro.valueChanges
      .subscribe(() => {
        this.handleCompellingIntro();
      });
  }

  listenToInternalDescription(): Subscription {
    const { internalDescription } = this.jobOrderForm.controls;
    return internalDescription.valueChanges
      .subscribe(() => {
        this.handleInternalDescription();
      });
  }

  listenToInternalNotes(): Subscription {
    const { internal_notes } = this.jobOrderForm.controls;
    return internal_notes.valueChanges
      .subscribe(() => {
        this.handleInternalNotes();
      });
  }

  listenToResponsibilities(): Subscription {
    const { responsibilities } = this.jobOrderForm.controls;
    return responsibilities.valueChanges
      .subscribe(() => {
        this.handleResponsibilities();
      });
  }

  listenToPublishImmediately(): Subscription {
    const { publish_immediately, compelling_intro } = this.jobOrderForm.controls;
    return publish_immediately.valueChanges
      .subscribe(() => {
        if (!publish_immediately.value) {
          FormValidationService.clearValidators(compelling_intro);
        }
        this.handleCompellingIntro();
      });
  }

  listenToEmploymentType(): Subscription {
    const { employmentTypes } = this.jobOrderForm.controls;
    return employmentTypes.valueChanges
      .subscribe(value => {
        // For RT only
        this.handleClientInterview();
        // Other logic
        const { jobEndTime, order_status, calculated_gp, target_salary, bill_rate } = this.jobOrderForm.controls;
        if (value === 'PERMANENT') {
          FormValidationService.clearValidators(jobEndTime, calculated_gp, bill_rate);
          FormValidationService.clearErrors(jobEndTime, calculated_gp, bill_rate);
          if (jobEndTime.value !== null) {
            this.jobOrderForm.patchValue({ jobEndTime: null });
          }
        } else {
          FormValidationService.clearValidators(target_salary);
          FormValidationService.clearErrors(target_salary);
          if (order_status.value !== 'Unqualified') {
            FormValidationService.setValidators(jobEndTime, [Validators.required]);
            FormValidationService.markAsTouched(jobEndTime);
          }
        }
      });
  }

  listenToJobStartTime(): Subscription {
    const { jobStartTime, submission_deadline } = this.jobOrderForm.controls;
    return jobStartTime.valueChanges
      .subscribe(() => {
        this.handleDateErrors();
        // console.log(work_hours_start.value, submission_deadline.value, work_hours_start.value < submission_deadline.value);
        if (jobStartTime.value && submission_deadline.value) {
          if (jobStartTime.value < submission_deadline.value) {
            FormValidationService.setError(submission_deadline, 'maxDate');
            FormValidationService.markAsTouched(submission_deadline);
          } else {
            FormValidationService.clearErrors(submission_deadline);
          }
        }
      });
  }

  listenToJobEndTime(): Subscription {
    const { jobEndTime } = this.jobOrderForm.controls;
    return jobEndTime.valueChanges
      .subscribe(() => {
        this.handleDateErrors();
      });
  }

  listenToSubmissionDeadline(): Subscription {
    const { submission_deadline } = this.jobOrderForm.controls;
    return submission_deadline.valueChanges
      .subscribe(() => {
        this.handleDeadlineErrors();
      });
  }

  listenToSalaryType(): Subscription {
    const { salary_type } = this.jobOrderForm.controls;
    return salary_type.valueChanges
      .subscribe(salaryTypeValue => {
        const { published_max_pay_rate, bill_rate, target_salary } = this.jobOrderForm.controls;
        const isHourly = salaryTypeValue?.toLowerCase() === 'hourly';
        const isSalary = salaryTypeValue?.toLowerCase() === 'yearly';
        FormValidationService.clearErrors(published_max_pay_rate);
        if (isHourly) {
          FormValidationService.clearErrors(target_salary);
          if (target_salary.value !== null && target_salary.value !== 0) {
            this.jobOrderForm.patchValue({
              target_salary: null,
              internal_max_pay_rate: null
            });
          }
          if (published_max_pay_rate.value > 999) {
            FormValidationService.setError(published_max_pay_rate, 'maxCompensationHourlyRange');
            FormValidationService.markAsTouched(published_max_pay_rate);
          }
        } else if (isSalary) {
          FormValidationService.clearErrors(bill_rate);
          if (target_salary.value == 0) {
            this.jobOrderForm.patchValue({ target_salary: null });
          }
          if (bill_rate.value !== null && bill_rate.value !== '') {
            this.jobOrderForm.patchValue({
              bill_rate: null,
              internal_max_pay_rate: null,
              calculated_gp: null
            });
          }
          if (published_max_pay_rate.value > 999999.99) {
            FormValidationService.setError(published_max_pay_rate, 'maxCompensationYearlyRange');
            FormValidationService.markAsTouched(published_max_pay_rate);
          }
        }
        this.handleDateErrors();
        this.handleOrderStatus();
      });
  }

  listenToPayRateLow(): Subscription {
    const { published_min_pay_rate } = this.jobOrderForm.controls;
    return published_min_pay_rate.valueChanges
      .subscribe(() => {
        this.handlePayRateLow();
      });
  }

  listenToPayRateHigh(): Subscription {
    const { published_max_pay_rate } = this.jobOrderForm.controls;
    return published_max_pay_rate.valueChanges
      .subscribe(() => {
        this.handlePayRateHigh();
      });
  }

  listenToBillRate(): Subscription {
    const { bill_rate } = this.jobOrderForm.controls;

    if (typeof bill_rate.value === 'number' && this.utils.precision(bill_rate.value) > 2) {
      /* Ensure That Bill Rate Isn't More That 2 Decimal Places */
      const val = bill_rate.value.toFixed(2);
      this.jobOrderForm.patchValue({ bill_rate: val });
    }


    return bill_rate.valueChanges
      .subscribe((billRateValue) => {
        const { internal_max_pay_rate, salary_type, order_status } = this.jobOrderForm.controls;
        let errorsDetected = false;

        if (typeof bill_rate.value === 'number' && this.utils.precision(bill_rate.value) > 2) {
          /* Ensure That Internal Max Salary Isn't More That 2 Decimal Places */
          const val = bill_rate.value.toFixed(2);
          this.jobOrderForm.patchValue({ bill_rate: val });
        }

        if (salary_type.value?.toLowerCase() === 'yearly') {
          FormValidationService.clearErrors(bill_rate);
          if (bill_rate.value !== null) {
            this.jobOrderForm.patchValue({ bill_rate: null });
          }
        } else {
          if (order_status.value === 'Accepting Candidates' && billRateValue === null) {
            FormValidationService.setError(bill_rate, 'required');
            errorsDetected = true;
          } else if (billRateValue === null && internal_max_pay_rate.value === null) {
            FormValidationService.clearErrors(bill_rate, internal_max_pay_rate);
          } else if (billRateValue === 0) {
            FormValidationService.setError(bill_rate, 'zero');
            errorsDetected = true;
          } else if (billRateValue <= internal_max_pay_rate.value) {
            FormValidationService.setError(bill_rate, 'billRateLessThanMax');
            errorsDetected = true;
          } else if (billRateValue > 999) {
            FormValidationService.setError(bill_rate, 'maxRateMoreThanMaximumAcceptable');
            errorsDetected = true;
          }
        }
        if (!errorsDetected) {
          FormValidationService.clearErrors(bill_rate);
          if (!isNaN(internal_max_pay_rate.value) && internal_max_pay_rate.value !== 0) {
            FormValidationService.clearErrors(internal_max_pay_rate);
          }
        }
      });
  }

  listenToInternalMaxPayRate(): Subscription {
    const { internal_max_pay_rate } = this.jobOrderForm.controls;
    return internal_max_pay_rate.valueChanges
      .subscribe((maxSalaryValue) => {
        this.handleInternalMaxPayRate();
      });
  }

  listenToTargetSalary(): Subscription {
    const { target_salary } = this.jobOrderForm.controls;
    return target_salary.valueChanges
      .subscribe((targetSalaryValue) => {
        const { salary_type, internal_max_pay_rate, order_status } = this.jobOrderForm.controls;
        if (salary_type.value?.toLowerCase() === 'hourly') {
          FormValidationService.clearErrors(target_salary);
          if (target_salary.value !== null) {
            this.jobOrderForm.patchValue({ target_salary: null });
          }
        } else {
          let errorsDetected = false;

          if (order_status.value === 'Accepting Candidates' && targetSalaryValue === null) {
            FormValidationService.markAsTouched(target_salary);
            FormValidationService.setError(target_salary, 'required');
            errorsDetected = true;
          } else if (targetSalaryValue === 0) {
            FormValidationService.markAsTouched(target_salary);
            FormValidationService.setError(target_salary, 'zero');
            errorsDetected = true;
          } else if (targetSalaryValue < 10000) {
            FormValidationService.markAsTouched(target_salary);
            FormValidationService.setError(target_salary, 'targetSalaryLessThanMinimumAcceptable');
            errorsDetected = true;
          } else if (targetSalaryValue > internal_max_pay_rate.value) {
            FormValidationService.markAsTouched(target_salary);
            FormValidationService.setError(target_salary, 'targetSalaryGreaterThanMax');
            errorsDetected = true;
          }

          if (!errorsDetected) {
            FormValidationService.clearErrors(target_salary);
            if (!isNaN(internal_max_pay_rate.value)) {
              FormValidationService.clearErrors(internal_max_pay_rate);
            }
          }
        }
      });
  }

  listenToPermFee(): Subscription {
    const { perm_fee } = this.jobOrderForm.controls;
    return perm_fee.valueChanges
      .subscribe(() => {
        this.handlePermFee();
      });
  }

  listenToPriority(): Subscription {
    const { customer_priority } = this.jobOrderForm.controls;
    return customer_priority.valueChanges
      .subscribe(priority => {
        this.handlePriorityErrors();
      });
  }

  listenToCalculatedGP(): Subscription {
    const { calculated_gp, employmentTypes } = this.jobOrderForm.controls;
    return calculated_gp.valueChanges
      .subscribe(calculatedGpValue => {
        const { order_status } = this.jobOrderForm.controls;
        if (
          order_status.value === 'Accepting Candidates' &&
          calculatedGpValue === null &&
          employmentTypes.value?.toLowerCase() !== 'permanent'
        ) {
          FormValidationService.setError(calculated_gp, 'required');
        } else if (calculatedGpValue === 0) {
          FormValidationService.setError(calculated_gp, 'zero');
        }
      });
  }

  get userLob(): string { return this.user && this.user.Source; }

  listenToMarkup(): Subscription {
    const { mark_up } = this.jobOrderForm.controls;
    return mark_up.valueChanges
      .subscribe(markup => {
        const { bill_rate, internal_max_pay_rate } = this.jobOrderForm.controls;
        if (bill_rate.value
          && internal_max_pay_rate.value
          && this.userLob?.checkLob('RT', 'RE','CR')
          && markup < 1.6) {
          FormValidationService.setError(mark_up, 'markupLessThanApproved');
          FormValidationService.markAsTouched(mark_up);
        } else {
          FormValidationService.clearErrors(mark_up);
        }
      });
  }

  listenToHeavyLifting(): Subscription {
    const { heavy_lifting } = this.jobOrderForm.controls;
    return heavy_lifting.valueChanges
      .subscribe(heavyLifting => {
        const { heavy_lifting_weight } = this.jobOrderForm.controls;
        if (heavyLifting) {
          FormValidationService.setValidators(heavy_lifting_weight, [Validators.required]);
          FormValidationService.markAsTouched(heavy_lifting_weight);
        } else {
          if (heavy_lifting_weight.value !== null) {
            this.jobOrderForm.patchValue({ heavy_lifting_weight: null });
          }
          FormValidationService.clearValidators(heavy_lifting_weight);
          FormValidationService.clearErrors(heavy_lifting_weight);
        }
      });
  }

  listenToHeavyLiftingWeight(): Subscription {
    const { heavy_lifting_weight } = this.jobOrderForm.controls;
    return heavy_lifting_weight.valueChanges
      .subscribe(weight => {
        if (weight === null) {
          FormValidationService.setError(heavy_lifting_weight, 'required');
        } else if (weight > 100 || weight <= 0) {
          FormValidationService.setError(heavy_lifting_weight, 'heavyLiftingWeight');
        } else {
          FormValidationService.clearErrors(heavy_lifting_weight);
        }
      });
  }

  listenToConsistentlyStanding(): Subscription {
    const { consistently_standing } = this.jobOrderForm.controls;
    return consistently_standing.valueChanges
      .subscribe(consistentlyStanding => {
        const { consistently_standing_hours } = this.jobOrderForm.controls;
        if (consistentlyStanding) {
          FormValidationService.setValidators(consistently_standing_hours, [Validators.required]);
          FormValidationService.markAsTouched(consistently_standing_hours);
        } else {
          if (consistently_standing_hours.value !== null) {
            this.jobOrderForm.patchValue({ consistently_standing_hours: null });
          }
          FormValidationService.clearValidators(consistently_standing_hours);
          FormValidationService.clearErrors(consistently_standing_hours);
        }
      });
  }

  listenToConsistentlyStandingHours(): Subscription {
    const { consistently_standing_hours } = this.jobOrderForm.controls;
    return consistently_standing_hours.valueChanges
      .subscribe(hours => {
        if (hours === null) {
          FormValidationService.setError(consistently_standing_hours, 'required');
        } else if (hours > 12 || hours <= 0) {
          FormValidationService.setError(consistently_standing_hours, 'standingHours');
        } else {
          FormValidationService.clearErrors(consistently_standing_hours);
        }
      });
  }

  listenToExpectedOvertimeHours(): Subscription {
    const { expected_overtime_hours } = this.jobOrderForm.controls;
    return expected_overtime_hours.valueChanges
      .subscribe(hours => {
        if (hours > 128 || hours < 0) {
          FormValidationService.setError(expected_overtime_hours, 'expectedOvertime');
        } else {
          FormValidationService.clearErrors(expected_overtime_hours);
        }
      });
  }

  listenToTypeOfWorkEnvironment(): Subscription {
    const { type_of_work_environment } = this.jobOrderForm.controls;
    return type_of_work_environment.valueChanges
      .subscribe(workEnv => {
        FormValidationService.markAsTouched(type_of_work_environment);
        if (workEnv.length > 300) {
          FormValidationService.setError(type_of_work_environment, 'typeOfWorkEnvLength');
        } else if (this.hasInvalidWorkEnvironmentCharacters(workEnv)) {
          FormValidationService.setError(type_of_work_environment, 'invalidWorkEnvironmentCharacter');
        } else {
          FormValidationService.clearErrors(type_of_work_environment);
        }
      });
  }

  /*
    Handlers
  */

  handleVmsValidation(vms_req_id: AbstractControl) {
    const validatedText = vms_req_id.value.replace(/[^0-9a-zA-Z]/g, '');
    if (validatedText !== vms_req_id.value) {
      vms_req_id.setValue(validatedText);
    }
  }

  handlePayRateLow() {
    const { salary_type, published_max_pay_rate, published_min_pay_rate } = this.jobOrderForm.controls;
    const payRateHigh = +published_max_pay_rate.value;
    const payRateLow = +published_min_pay_rate.value;
    let errorsDetected = false;
    if (payRateLow === null) {
      FormValidationService.setError(published_min_pay_rate, 'required');
      errorsDetected = true;
    } else if (payRateLow === 0) {
      FormValidationService.setError(published_min_pay_rate, 'zero');
      errorsDetected = true;
    } else if (payRateLow < 7.25 && salary_type.value === 'hourly'){
      FormValidationService.setError(published_min_pay_rate, 'minCompHourlyBaseline');
      errorsDetected = true;
    } else if (payRateLow < 20000 && salary_type.value === 'yearly'){
      FormValidationService.setError(published_min_pay_rate, 'minCompYearlyBaseline');
      errorsDetected = true;
    } else if (payRateLow >= payRateHigh) {
      FormValidationService.setError(published_min_pay_rate, 'minCompGreaterThanMax');
      FormValidationService.setError(published_max_pay_rate,(salary_type.value === 'hourly') ? 'maxCompensationHourlyRange' : 'maxCompensationYearlyRange');
      FormValidationService.markAsTouched(published_max_pay_rate);
      errorsDetected = true;
    }

    if (!errorsDetected) {
      FormValidationService.clearErrors(published_min_pay_rate);
      if (!isNaN(published_max_pay_rate.value)) {
        if ((salary_type.value === 'hourly' && payRateHigh < 999)
          || (salary_type.value === 'yearly' && payRateHigh < 999999.99)) {
          FormValidationService.clearErrors(published_max_pay_rate);
        }
        // SEEMS LIKE THIS WAS CAUSING A BUG
        // if (published_max_pay_rate.value > published_min_pay_rate.value && ) {
        //   FormValidationService.removeError(published_max_pay_rate, 'maxCompensationYearly');
        //   FormValidationService.removeError(published_max_pay_rate, 'maxCompensationHourly');
        // }
      }
    }
  }

  handlePayRateHigh() {
    const { published_max_pay_rate, published_min_pay_rate, salary_type } = this.jobOrderForm.controls;
    const payRateHigh = +published_max_pay_rate.value;
    const payRateLow = +published_min_pay_rate.value;
    let errorDetected = false;
    if (payRateHigh === null) {
      FormValidationService.setError(published_max_pay_rate, 'required');
      errorDetected = true;
    } else if (payRateHigh === 0) {
      FormValidationService.setError(published_max_pay_rate, 'zero');
      errorDetected = true;
    } else if ((payRateHigh > 999 || payRateHigh < 7.26 ) && salary_type.value === 'hourly') {
      if (payRateHigh > 999) {
        FormValidationService.setError(published_max_pay_rate, 'maxCompensationHourlyRange');
        errorDetected = true;
      } else {
        //Pay rate is less than hourly baseline
        FormValidationService.setError(published_max_pay_rate, 'maxCompHourlyBaseline');
        errorDetected = true;
      }
    } else if ((payRateHigh > 999999.99 || payRateHigh < 20001 ) && salary_type.value === 'yearly') {
      if (payRateHigh > 999999.99) {
        FormValidationService.setError(published_max_pay_rate, 'maxCompensationYearlyRange');
        errorDetected = true;
      } else {
        //Pay rate is less than yearly baseline
        FormValidationService.setError(published_max_pay_rate, 'maxCompYearlyBaseline');
        errorDetected = true;
      }
    } else if (payRateHigh <= payRateLow) {
      FormValidationService.setError(published_max_pay_rate,
        (salary_type.value === 'hourly') ? 'maxCompensationHourlyRange' : 'maxCompensationYearlyRange');
      FormValidationService.markAsTouched(published_min_pay_rate);
      errorDetected = true;
    }
    if (!errorDetected) {
      FormValidationService.clearErrors(published_max_pay_rate);
      if (!isNaN(payRateLow)) {
        //Handles clearing field form bug where:
        //published_max_pay_rate is valid but published_min_pay_rate value isn't greater than the hr/yr baseline values
        if ((salary_type.value === 'hourly' && payRateLow > 7.25)
          || (salary_type.value === 'yearly' && payRateLow > 20000)) {
          FormValidationService.clearErrors(published_min_pay_rate);
        }
      }
    }
  }

  handleInternalMaxPayRate(): void {
    const { internal_max_pay_rate, salary_type, bill_rate, target_salary, order_status } = this.jobOrderForm.controls;
    const isSalary = salary_type.value?.toLowerCase() === 'yearly';

    if (typeof internal_max_pay_rate.value === 'number' && this.utils.precision(internal_max_pay_rate.value) > 2) {
      /* Ensure That Internal Max Salary Isn't More That 2 Decimal Places */
      const val = internal_max_pay_rate.value.toFixed(2);
      this.jobOrderForm.patchValue({ internal_max_pay_rate: val });
    }
    const maxSalaryValue = internal_max_pay_rate.value;

    let errorsDetected = false;

    if (this.userLob?.checkLob('RT', 'RE','CR') && order_status.value === 'Accepting Candidates' && maxSalaryValue === null) {
      FormValidationService.markAsTouched(internal_max_pay_rate);
      FormValidationService.setError(internal_max_pay_rate, 'required');
      errorsDetected = true;
    }

    if (this.userLob?.checkLob('RGS') && maxSalaryValue === null) {
      FormValidationService.markAsTouched(internal_max_pay_rate);
      FormValidationService.setError(internal_max_pay_rate, 'required');
      errorsDetected = true;
    }

    if (maxSalaryValue === 0) {
      FormValidationService.setError(internal_max_pay_rate, 'zero');
      errorsDetected = true;
    } else if (maxSalaryValue >= 1000000 && isSalary) {
      FormValidationService.setError(internal_max_pay_rate, 'targetSalaryMoreThanMaximumAcceptable');
      errorsDetected = true;
    } else if (maxSalaryValue > 999 && !isSalary) {
      FormValidationService.setError(internal_max_pay_rate, 'maxRateMoreThanMaximumAcceptable');
      errorsDetected = true;
    } else if (isSalary) {
      if (target_salary.value > maxSalaryValue) {
        FormValidationService.markAsTouched(internal_max_pay_rate);
        FormValidationService.setError(internal_max_pay_rate, 'maxSalaryLessThanTarget');
        errorsDetected = true;
      }
    } else {
      if (bill_rate.value < maxSalaryValue) {
        FormValidationService.markAsTouched(internal_max_pay_rate);
        FormValidationService.setError(internal_max_pay_rate, 'maxRateGreaterThanBill');
        errorsDetected = true;
      }
    }

    if (!errorsDetected) {
      FormValidationService.clearErrors(internal_max_pay_rate);
      if (!isSalary && !isNaN(bill_rate.value) && (bill_rate.value !== 0)) {
        FormValidationService.clearErrors(bill_rate);
      }
      if (isSalary && !isNaN(target_salary.value) && (target_salary.value !== 0) && (target_salary.value >= 10000)) {
        FormValidationService.clearErrors(target_salary);
      }
    }
  }

  handlePermFee(): void {
    const {
      perm_fee
    } = this.jobOrderForm.controls;

    const { salary_type } = this.jobOrderForm.controls;
    if (salary_type.value?.toLowerCase() === 'hourly') {
      FormValidationService.clearErrors(perm_fee);
      if (perm_fee.value !== null) {
        this.jobOrderForm.patchValue({ perm_fee: null });
      }
    } else {
      if (perm_fee.value === 0) {
        FormValidationService.markAsTouched(perm_fee);
        FormValidationService.setError(perm_fee, 'zero');
      }
    }
  }

  handleCompellingIntro(): void {
    const {
      compelling_intro,
      publish_immediately
    } = this.jobOrderForm.controls;
    if (compelling_intro.value && this.countCharacters(compelling_intro.value) < 300 && publish_immediately.value) {
      FormValidationService.setError(compelling_intro, 'length');
      FormValidationService.markAsTouched(compelling_intro);
    } else {
      FormValidationService.removeError(compelling_intro, 'length');
    }
  }

  handleInternalDescription(): void {
    const { internalDescription } = this.jobOrderForm.controls;
    if (internalDescription.value && this.countCharacters(internalDescription.value) > 100000) {
      FormValidationService.setError(internalDescription, 'internalDescriptionLength');
      FormValidationService.markAsTouched(internalDescription);
    } else {
      FormValidationService.removeError(internalDescription, 'internalDescriptionLength');
    }
  }

  handleInternalNotes(): void {
    const { internal_notes } = this.jobOrderForm.controls;
    const isRGS = this.userLob?.checkLob('RGS');
    if (isRGS && internal_notes.value && this.countCharacters(internal_notes.value) > 254) {
      FormValidationService.setError(internal_notes, 'rgsInternalNotesLength');
      FormValidationService.markAsTouched(internal_notes);
    }
    else if(!isRGS && internal_notes.value && this.countCharacters(internal_notes.value) > 200000){
      FormValidationService.setError(internal_notes, 'rtInternalNotesLength');
      FormValidationService.markAsTouched(internal_notes);
    }
    else {
      FormValidationService.removeError(internal_notes, isRGS ? 'rgsInternalNotesLength' : 'rtInternalNotesLength');
    }
  }

  handleResponsibilities(): void {
    const { responsibilities } = this.jobOrderForm.controls;
    const isRGS = this.userLob?.checkLob('RGS');
    if (isRGS && responsibilities.value && this.countCharacters(responsibilities.value) > 1500){
      FormValidationService.setError(responsibilities, 'rgsResponsibilitiesLength');
      FormValidationService.markAsTouched(responsibilities);
    }
    else if (!isRGS && responsibilities.value && this.countCharacters(responsibilities.value) > 9000){
      FormValidationService.setError(responsibilities, 'rtResponsibilitiesLength');
      FormValidationService.markAsTouched(responsibilities);
    }
    else {
      FormValidationService.removeError(responsibilities, isRGS ? 'rgsResponsibilitiesLength' : 'rtResponsibilitiesLength');
    }
  }

  countCharacters(text: string) {
    //remove line breaks and replace HTML entity for non-breaking space
    text = text.replace(/(\r\n|\n|\r)/gm, "").replace(/&nbsp;/gi, " ");

    //returns textContent of temporary div
    let tmp = document.createElement("div");
    tmp.innerHTML = text;
    if (tmp.textContent == "" && typeof tmp.innerText == "undefined") text = "";
    else text = tmp.textContent || tmp.innerText;
    text = text.replace(/^([\t\r\n]*)$/, "");
    return text.length;
  }

  handleDeadlineErrors(): void {
    const {
      jobStartTime,
      submission_deadline,
      order_created
    } = this.jobOrderForm.controls;
    // console.log(jobStartTime.value < submission_deadline.value);
    if ((jobStartTime.value < submission_deadline.value) && !order_created) {
      FormValidationService.setError(submission_deadline, 'maxDate');
      FormValidationService.markAsTouched(submission_deadline);
    } else {
      FormValidationService.clearErrors(submission_deadline);
    }
  }

  handleDateErrors(): void {
    const {
      jobStartTime,
      employmentTypes,
      jobEndTime,
      order_status
    } = this.jobOrderForm.controls;
    if (employmentTypes.value?.toLowerCase() === 'permanent') {
      // console.log('Handle date errors, hit permanent block');
      FormValidationService.removeError(jobEndTime, 'required');
      if (jobEndTime.value !== null) {
        this.jobOrderForm.patchValue({ jobEndTime: null });
      }
    } else {
      // console.log('Handle date errors, hit contract block');
      if (jobEndTime.value === null || jobStartTime.value === null) {
        if (jobStartTime.value === null) {
          FormValidationService.setError(jobStartTime, 'required');
          FormValidationService.markAsTouched(jobStartTime);
        }
        if (jobEndTime.value === null && order_status.value !== 'Unqualified') {
          FormValidationService.setError(jobEndTime, 'required');
          FormValidationService.markAsTouched(jobEndTime);
        }
      } else {
        const startDate = new Date(jobStartTime.value);
        const endDate = new Date(jobEndTime.value);
        if (startDate > endDate) {
          FormValidationService.setError(jobEndTime, 'minDate');
        }
      }
    }
  }

  handlePriorityErrors(): void {
    const { customer_priority } = this.jobOrderForm.controls;
    if (customer_priority.value === null || typeof customer_priority.value === 'undefined') {
      FormValidationService.setError(customer_priority, 'required');
    } else {
      if (customer_priority.value === 0 || customer_priority.value < 1 || customer_priority.value > 5) {
        FormValidationService.setError(customer_priority, 'required');
      } else {
        FormValidationService.clearErrors(customer_priority);
      }
    }
  }

  handleOrderStatus(): void {
    // TODO: RE-CHECK
    const { order_status, salary_type } = this.jobOrderForm.controls;
    const fields = [];
    if (salary_type.value) {
      if (salary_type.value?.toLowerCase() === 'yearly') {
        fields.push('target_salary');
        fields.push('internal_max_pay_rate');
        if (this.userLob?.checkLob('RT', 'RE','CR')) {
          fields.push('perm_fee');
        }
      } else if (salary_type.value?.toLowerCase() === 'hourly') {
        fields.push('bill_rate');
        fields.push('internal_max_pay_rate');
        fields.push('jobEndTime');
        if (this.userLob?.checkLob('RT', 'RE','CR')) {
          fields.push('mark_up');
          fields.push('calculated_gp');
        }
      }
    }
    if (order_status.value === 'Unqualified') {
      for (let i = 0; i < fields.length; i++) {
        const control = this.jobOrderForm.controls[fields[i]];
        FormValidationService.clearValidators(control);
        FormValidationService.removeError(control, 'required');
      }
    } else if (order_status.value === 'Accepting Candidates') {
      for (let i = 0; i < fields.length; i++) {
        const control = this.jobOrderForm.controls[fields[i]];
        FormValidationService.setValidators(control, [Validators.required]);
        if (control.value === null) {
          FormValidationService.setError(control, 'required');
        }
        FormValidationService.markAsTouched(control);
      }
    }
  }

  handleSkillsErrors(): void {
    // set skill requried for RT
    if (!this.jobOrderForm.controls.skills.value || !this.jobOrderForm.controls.skills.value.length) {
      const control = this.jobOrderForm.controls.skills;
      FormValidationService.setError(control, 'required');
      FormValidationService.markAsTouched(control);
    }
  }

  handleCandidateType(types:string[]) :void {
    const { employmentTypes , lob  } = this.jobOrderForm.controls
    if(types.length){
      for ( const type of types){
        const control = this.jobOrderForm.controls[type]
        if(lob.value === "RGS" || employmentTypes.value === "PERMANENT"){
          FormValidationService.clearErrors(control)
        } else {
          if(control.value === null){
            FormValidationService.setError(control, 'required');
            FormValidationService.markAsTouched(control);
          }
        }
      }
    }
  }

  hasInvalidWorkEnvironmentCharacters(workEnvironment: string): boolean {
    const re = /^[a-zA-Z0-9-/\\ ]+$/;
    let found = false;
    if (typeof workEnvironment === 'string' && workEnvironment.length > 0 && workEnvironment.search(re)) {
      found = true;
    }
    return found;
  }

  handleJobLobChanges() {
    const { lob } = this.jobOrderForm.controls;
    if (!lob.value.checkLob('RT')) {
      //Remove validators if job lob is not 'RT'
      FormValidationService.clearValidators(
        this.jobOrderForm.controls.service_line,
        this.jobOrderForm.controls.sub_service_line,
        this.jobOrderForm.controls.client_partner,
        this.jobOrderForm.controls.client_partner_id
      )
    } else {
      //Add validators if job lob is 'RT'
      this.jobOrderForm.controls.service_line.setValidators([Validators.required]);
        this.jobOrderForm.controls.sub_service_line.setValidators([Validators.required]);
        this.jobOrderForm.controls.client_partner_id.setValidators([Validators.required]);
    }
  }
  handleClientInterview() {
    const { interview_info, employmentTypes } = this.jobOrderForm.controls;
    if (this.userLob?.checkLob('RT', 'RE','CR') && employmentTypes.value === 'PERMANENT') {
      FormValidationService.clearValidators(interview_info);
    } else {
      FormValidationService.setValidators(interview_info, [Validators.required]);
    }
  }

  handleClientPartner() {
      if (this.jobOrderForm.controls.lob.value.checkLob('RT')) {
        if (!this.jobOrderForm.controls.client_partner.value) {
            this.jobOrderForm.controls.client_partner.setValue('');
        }

        if (!this.jobOrderForm.controls.client_partner_id.value) {
            this.jobOrderForm.controls.client_partner_id.setValue('');
        }
      }
  }

  clearMarkupErrors() {
    const { mark_up } = this.jobOrderForm.controls;
    FormValidationService.clearErrors(mark_up);
    FormValidationService.markAsTouched(mark_up);
  }


  validate(): Promise<any> {
    console.log('JobOrderValidationService.validate, jobOrderForm=', this.jobOrderForm);
    if(this.isClientOrder()){ // final validation on none pipeline postings
      this.handleInternalMaxPayRate();
      this.handlePermFee();
      this.handleDeadlineErrors();
      this.handleDateErrors();
      this.handlePriorityErrors();
      this.handleOrderStatus();
      this.handleClientInterview();
    }
    this.handleJobLobChanges();
    this.handleClientPartner();
    this.handlePayRateHigh();
    this.handlePayRateLow();
    this.handleCompellingIntro();
    this.handleSkillsErrors();
    this.handleCandidateType(["allow_independent_contractors","allow_dbc_candidates","allow_third_party_vendors"]);
    // Markup errors should display on the UI when user is keying
    // in bill rate, etc. but should not prevent publishing. This
    // removes the errors from the markup field.
    this.clearMarkupErrors();
    // If errors are detected in the form, it will reject with an
    // object containing the index of the form control so that it
    // may be used in the component to navigate to the error point.
    this.jobOrderForm.updateValueAndValidity();
    return new Promise((resolve, reject) => {
      if (this.jobOrderForm.invalid) {
        let pageFound = false;
        let error;
        for (let i = 0; i < VALIDATED_FORM_FIELDS.length; i++) {
          const { fieldName } = VALIDATED_FORM_FIELDS[i];
          if (this.jobOrderForm.controls[fieldName]) {
            this.jobOrderForm.controls[fieldName].markAsTouched();
            if (!pageFound && this.jobOrderForm.controls[fieldName].invalid) {
              error = VALIDATED_FORM_FIELDS[i];
              pageFound = true;
            }
          }
        }
        if (pageFound && error) {
          reject(<ValidationError>{
            message: `Invalid field: ${error.errorLabel?.toLowerCase()}.`,
            errorAnchor: error.anchor
          });
        }
      } else {
        resolve(true);
      }
    });
  }
}
