<div class="modal-header" style="justify-content: flex-end;">
    <button type="button" class="close-btn" aria-label="Close" (click)="_bsModalRef.hide()">
      <i class="icon-WL_icon_close"></i>
    </button>
</div>

<div class="modal-body">
  <h2 class="mb-2">{{(isClosed ? 'close-job-modal.update_close_job' : 'close-job-modal.close_job') | translate}}</h2>

  <!-- job details -->
  <table>
    <tr>
      <td><h4>{{ "send-user-email.job_title" | translate }}</h4></td>
      <td><h4 class="gray-text ml-3">{{title}}</h4></td>
    </tr>
    <tr>
      <td><h4>{{ "send-user-email.company" | translate }}</h4></td>
      <td><h4 class="gray-text ml-3">{{customer_name}} <span class="priority">P{{customer_priority}}</span> </h4></td>
    </tr>
    <tr>
      <td><h4>{{ "send-user-email.job_id" | translate }}</h4></td>
      <td><h4 class="gray-text ml-3">{{front_office_id}}</h4></td>
    </tr>
    <tr *ngIf="isLinkedJob">
      <div class="pt-2 pl-0">
        <input (focus)="onFocusEvent($event)" (blur)="onBlurEvent($event)" type="checkbox" class="rds--checkbox" id="rds--checkbox--close-lj" name="closeAllJobs" ngDefaultControl [(ngModel)]="closeAllJobs" />
        <label #closeLinkedJobsElem class="rds--checkbox__label" for="rds--checkbox--close-lj">Would you like to close all other linked jobs?</label>
      </div>
    </tr>
  </table>

  <form [formGroup]='closeJobForm' >
      <label for="closeJob">{{(isClosed ? 'close-job-modal.are_you_sure_update' : 'close-job-modal.are_you_sure') | translate}}</label>
      <select formControlName="closeJobType" class="form-control" id="closeJob" (change)="resetSubmit(closeJobType.value)">
          <option [ngValue]="null">{{'close-job-modal.select_a_reason' | translate}}</option>
          <option *ngFor="let type of closeJobTypes" [value]="type.key">{{ type.value }}</option>
      </select>
      <div class="text-danger" *ngIf="closeJobType.errors && isSubmitted">{{'close-job-modal.please_select_a' | translate}}</div>
  </form>
</div>

<div id="close-job-footer" class="modal-footer">
    <button (click)="_bsModalRef.hide()" class="btn rds--button--primary">{{'close-job-modal.cancel' | translate}}</button>
    <button *ngIf="!saving" (click)="closeJob()" class="btn rds--button--primary" [disabled]="disableUpdate">
      {{(isClosed ? 'close-job-modal.update' : 'close-job-modal.close_job') | translate}}
    </button>
    <button *ngIf="saving" class="btn rds--button--primary">{{'close-job-modal.close_job_saving' | translate}}</button>
</div>

