import { SuggestService } from "./../../../../../shared/services/suggest/suggest.service";
import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { AuthService } from "src/app/shared/services/auth/auth.service";
import { JobOrderService } from "src/app/shared/services/job-order/job-order.service";
import { NotificationsService } from "src/app/shared/services/notifications/notifications.service";
import { ToastClass, ToastService } from "src/app/shared/services/toast";
import { Job } from "../../../../../shared/models/external/job.model";
import { ApiService } from "../../../../../shared/services/api/api.service";
import { JobForm } from "../../../../../shared/models/external/job-form.model";
import {
  NotificationData,
  NotificationTypes,
  EmailRecipient
} from "src/app/shared/models/internal/notifications-data.model";
import { TranslateService } from "@ngx-translate/core";
import { DistributionList } from "src/app/shared/models/internal/distribution-list.model";
import { OrderType } from "src/app/shared/models/internal/job-form.interface";

@Component({
  selector: "app-send-user-email-modal",
  templateUrl: "./send-user-email-modal.component.html",
  styleUrls: ["./send-user-email-modal.component.scss"]
})
export class SendUserEmailModalComponent implements OnInit {
  title = "";
  customer_name = "";
  front_office_id = "";
  customer_priority?: number;
  jobData: any;
  searchTerm = "";
  dListTerm = "";
  dListData: { searchTerm: string; results: [] };
  elasticJobOrder: Job; /* The Job The Way It Is In Elastic */
  formJobOrder: JobForm; /* The Job The Way It Is In The Form */
  sending = false;
  individualEmailRecipients: EmailRecipient[] = [];
  distributionListRecipients: EmailRecipient[] = [];
  emailRecipients: EmailRecipient[] = [];
  useAsIs = false;
  noDistributionListResults = false;
  noResults = false;

  constructor(
    private _api: ApiService,
    public _bsModalRef: BsModalRef,
    private jobOrderService: JobOrderService,
    private _suggestService: SuggestService,
    private toastService: ToastService,
    private translate: TranslateService,
    private _notificationService: NotificationsService,
    private _auth: AuthService
  ) {}

  get isClientOrder(): boolean { return this.jobData?.allbirds_metadata?.order_type !== OrderType.PIPELINE; }

  ngOnInit() {
    this.setData(this.jobData);
  }

  setData(jobData: Job) {
    this.searchTerm = "";
    if (jobData) {
      if (jobData.title) {
        this.title = jobData.title;
      }

      if (jobData.allbirds_metadata) {
        this.customer_name = jobData.allbirds_metadata.customer_name || "";
        this.customer_priority = jobData.allbirds_metadata.customer_priority;
        this.front_office_id = jobData.allbirds_metadata.front_office_id;
      }
    }
  }

  setRecipient(recruiter: DistributionList) {
    let hasEmail = false;
    if (
      this[
        recruiter.dList
          ? "distributionListRecipients"
          : "individualEmailRecipients"
      ].length > 0
    ) {
      this[
        recruiter.dList
          ? "distributionListRecipients"
          : "individualEmailRecipients"
      ].forEach(r => {
        const email = recruiter.dList ? recruiter.email : recruiter.EmailAddr;
        if (r.recipientEmail === email) {
          hasEmail = true;
        }
      });
    }
    if (!hasEmail) {
      this[
        recruiter.dList
          ? "distributionListRecipients"
          : "individualEmailRecipients"
      ].push({
        recipient: recruiter.FullName,
        recipientEmail: recruiter.dList ? recruiter.email : recruiter.EmailAddr
      });
    }
    this.searchTerm = "";
    this.dListTerm = "";
  }

  checkResults(data: {searchTerm: string, results: []}, lookup: 'distributionList' | 'user') {
    if (!data.results.length && data.searchTerm.length > 0) {
      if (lookup === 'distributionList') {
        this.noDistributionListResults = true;
      } else {
        this.noResults = true;
      }
    } else {
      if (lookup === 'distributionList') {
        this.noDistributionListResults = false;
      } else {
        this.noResults = false;
      }
    }
  }

  validateEmail(data: {searchTerm: string, results: []}) {
    this.checkResults(data, 'distributionList');
    const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regex.test(data.searchTerm.trim()) && !data.results.length) {
      if (data.searchTerm.includes("@randstadusa.com") || data.searchTerm.includes("@randstaddigital.com")) {
        this.useAsIs = true;
        this.dListData = data;
      } else {
        this.useAsIs = false;
      }
    } else {
      this.useAsIs = false;
    }
  }

  saveDistributionList() {
    const { searchTerm } = this.dListData;
    this._suggestService.addEmailDistributionList(searchTerm.trim()).toPromise().then((data: {message: string}) => {
      const { message } = data;
      if (message === 'Distribution list added.') {
        this.useAsIs = false;
        this.distributionListRecipients.push({
          recipient: "",
          recipientEmail: searchTerm
        });
        this.dListTerm = "";
      } else {
        const trError = this.translate.instant("send-user-email-modal.error_message")
        this.toastService.showToast(trError, {
          cssClass: ToastClass.WARNING
        });
      }
    });
  }

  sendUserEmail() {
    console.log(this.jobData);
    this.emailRecipients = this.individualEmailRecipients.concat(
      this.distributionListRecipients
    );
    const jobData = {
      employmentTypes: this.jobData.employmentTypes[0]
        .replace(/_/g, " ")
        .toLowerCase(),
      customer_name: this.jobData.allbirds_metadata.customer_name,
      order_status: this.jobData.allbirds_metadata.order_status,
      city: this.jobData.structuredAddresses[0].locality,
      state: this.jobData.structuredAddresses[0].administrativeArea,
      pay_rate: `$${this.jobData.allbirds_metadata.internal_max_pay_rate.toFixed(2)}`,
      // target_bill_rate must show up in email thats why string is '  '. when notification service loops thru data value will be truthy.
      target_bill_rate: this.jobData.allbirds_metadata.bill_rate ? `$${this.jobData.allbirds_metadata.bill_rate.toFixed(2)}` : '   ',
      description: this.jobData.internalDescription
    };
    if (this.emailRecipients.length > 0 && this.jobData.allbirds_metadata.front_office_id) {
      const notificationData: NotificationData = {
        notificationType: NotificationTypes.SEND_USER_EMAIL,
        notificationObject: {
          front_office_id: this.jobData.allbirds_metadata.front_office_id,
          allbirds_job_id: this.jobData.allbirds_metadata.allbirds_job_id,
          title: this.jobData.internalTitle,
          executing_action_user: this._auth.user.FullName,
          executing_action_user_email: this._auth.user.EmailAddr,
          user: { ...this._auth.user },
          emailRecipients: this.emailRecipients || [],
          jobData
        }
      };
      this._notificationService
        .sendNotification(notificationData)
        .toPromise()
        .then(() => {
          const trSuccess = this.translate.instant(
            "send-user-email-modal.email_sent",
            {
              value1:
                this.emailRecipients.length > 1 ? "Emails were" : "An email was"
            }
          );
          this.toastService.showToast(trSuccess, {
            cssClass: ToastClass.SUCCESS
          });
        });
    }
    this._bsModalRef.hide();
  }
}
