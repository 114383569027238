import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { EntityChangeService } from './entity-change.service';
import { Application } from '../../models/external/application.model';
import { AngularFirestore } from '@angular/fire/firestore';
import { EntityChanges } from '@allbirds-ui/allbirds-types';
import ChangedEntity = EntityChanges.ChangedEntity;
import { ObjectUtilitiesService } from '../utility/object-utilities.service';

const JobApplicationManagerCollection = environment.collections.ApplicationChangeManager;

@Injectable({
  providedIn: 'root'
})
export class ApplicationEntityChangeService extends EntityChangeService<Application> {

  constructor(_fs: AngularFirestore) {
    super(JobApplicationManagerCollection, _fs);
  }

  /**
   * Given an application, returns true if the FS entity has different values than the in-memory entity.
   * @param application
   */
  didChange(application: Application): boolean {
    if (application) {
      const id = (application.randstad_process && application.randstad_process._id) || undefined;
      if (id) {
        const changedEntity: ChangedEntity<Application> = super.getEntityChange(id);
        if (changedEntity) {
          const changedApp: Partial<Application> = changedEntity.data;
          const currentApp: any = {
            externalId: application.externalId,
            randstad_process: {
              lastProcessStep: application.randstad_process.lastProcessStep,
              lastProcessStatus: application.randstad_process.lastProcessStatus,
              rejected: application.randstad_process.rejected || false
            }
          };
          return !ObjectUtilitiesService.checkDeepEqual(changedApp, currentApp);
        }
      } else {
        console.warn('Checked for application changes on an application missing its doc id.. Returning false.');
        return false;
      }
    }
    return false;
  }

}
