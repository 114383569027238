import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  Input,
} from '@angular/core';
import {Subscription} from 'rxjs';
import {LogFeedbackModalComponent} from '../../talent-process-modals/log-feedback-modal/log-feedback-modal.component';
import {AuthService} from 'src/app/shared/services/auth/auth.service';
import {TalentProcessService} from 'src/app/shared/services/talent-process/talent-process.service';
import {ToastClass, ToastService} from 'src/app/shared/services/toast';
import {MetricsService} from 'src/app/shared/services/metrics/metrics.service';
import {OUTCOME} from 'src/app/shared/services/activity-feed/activity-feed.config';
import {JobOrderService} from 'src/app/shared/services/job-order/job-order.service';
import {Application} from 'src/app/shared/models/external/application.model';
import {ModalService} from '../../../../../services/modal/modal.service';
import {ProcessStatus, ProcessStep} from 'src/app/shared/models/external/misc.model';
import {ClientInterview} from 'src/app/shared/models/external/process/client-interview.model';
import moment from 'moment';
import {INTG_STEPS} from 'src/app/shared/models/internal/process.model';
import {FormBuilder, FormGroup} from '@angular/forms';
import {UtilityService} from '../../../../../services/utility/utility.service';
import {EmailRecipient, NotificationData, NotificationTypes} from '../../../../../models/internal/notifications-data.model';
import {Job} from '../../../../../models/external/job.model';
import {Profile} from '../../../../../models/external/profile.model';
import {catchError} from 'rxjs/operators';
import {NotificationsService} from '../../../../../services/notifications/notifications.service';
import {RecruitmentPhasesService} from '../recruitment-phases.service'


@Component({
  selector: 'interview-hiring-manager-phase',
  templateUrl: './interview-hiring-manager.component.html',
  styleUrls: [
    './interview-hiring-manager.component.scss',
    '../recruitment-phases.component.scss'
  ]
})
export class InterviewHiringManagerComponent implements OnInit, OnDestroy, AfterViewChecked {

  // Determines which template to show.
  shownPhase: TemplateRef<any>;
  previousShownPhase: TemplateRef<any>;
  jobOrder: Job;

  //Determines if the caution modal for interviewed candidates
  showingCautionModal:boolean = false

  // Selected application.
  applicationSub: Subscription;
  application: Application;
  profile: Profile;
  profileSub: Subscription;
  statusLabel: string;
  interview = '';
  interviewDate: Date;
  showErrorNote = false;
  showErrorDate = false;
  hmForm: FormGroup;
  notificationType = NotificationTypes.CLIENT_INTERVIEW;
  originalSubmissionIndex: number;
  priorInterviewDate: any;
  @Input() talentInterviewStatus:boolean
  // Emits an event that triggers the markAsUnfit() call in recruitment-phases component.
  @Output() unfitClick = new EventEmitter();

  datePickerConfig = {
    dateInputFormat: 'MMM/DD/YYYY',
    showWeekNumbers: false
  };
  minDate = moment();

  // The different templates for the different phases.
  @ViewChild('planInterview', { static: true }) planInterviewView: TemplateRef<any>;
  @ViewChild('awaitingHMFeedback', { static: true }) awaitingHMFeedbackView: TemplateRef<any>;
  @ViewChild('ctaContainer', {static: false}) ctaContainer: ElementRef;
  @ViewChild('ctaContainerBoundry', {static: false}) ctaContainerBoundry: ElementRef;
  @ViewChild('submittedViewUpdate', { static: true }) submittedViewUpdate: TemplateRef<any>;

  constructor(
    private _authService: AuthService,
    private _talentProcess: TalentProcessService,
    private _bsModalService: ModalService,
    private _toast: ToastService,
    private _metrics: MetricsService,
    private _jobOrder: JobOrderService,
    private zone: NgZone,
    private _fb: FormBuilder,
    private _notificationService: NotificationsService,
    private _utility: UtilityService,
    private auth: AuthService,
    private _recruitmentPhasesService: RecruitmentPhasesService,
  ) { }

  ngOnInit() {
    this.listenToApplication();
    this.hmForm = this._fb.group({
      interview: [['']],
    });
    this.jobOrder = this._jobOrder.storedJobOrder;
    this.profileSub = this._talentProcess.selectedProfile.subscribe(profile => {
      this.profile = profile;
    });

  }


  ngOnDestroy() {
    this.applicationSub.unsubscribe();
    this.profileSub.unsubscribe();
  }

  ngAfterViewChecked() {
    if (this.previousShownPhase !== this.shownPhase) {
      this.previousShownPhase = this.shownPhase;
      if (this.ctaContainer && this.ctaContainer.nativeElement) {
        const elements = [
          this.ctaContainer.nativeElement,
          this.ctaContainerBoundry.nativeElement
        ];
        this._talentProcess.setupIntersectionObserver(elements);
      }
    }
  }

  emitUnfitClick() {
    this.unfitClick.emit();
  }

  emitUpdateSubmissionClick(isSolutions: boolean = false) {
    this.unfitClick.emit({solutions: isSolutions, updateSubmission: true, clientInterview: true});
}
get userLob (): string { return this.auth.user && this.auth.user.Source; }


  listenToApplication() {
    this.applicationSub = this._talentProcess.selectedApplication
      .subscribe(app => {
        this.application = app;
        if (app && this._authService.user) {
          this.statusLabel = this._talentProcess.getLabel(app, this._authService.user.Source, 'process', this._jobOrder.storedJobOrder);
        }
        this.interview = '';
        if (app && app.randstad_process) {
          const { clientInterview } = app.randstad_process;
          this.zone.run(() => {
            if (clientInterview && clientInterview.submission && !clientInterview.editing) {
              if(this.application?.randstad_process?.clientInterview?.priorSubmissions?.length > 0){
                this.shownPhase = this.submittedViewUpdate;
                this.originalSubmissionIndex = this.application.randstad_process.clientInterview.priorSubmissions.length - 1;
                this.priorInterviewDate= moment(clientInterview.priorSubmissions[this.originalSubmissionIndex].interviewDate).formatDatetime();
              }
              else{
                this.shownPhase = this.awaitingHMFeedbackView;
              }
            } else {
              if (clientInterview && clientInterview.editing) {
                if(this.application?.randstad_process?.clientInterview?.priorSubmissions?.length > 0)
                {
                  this.originalSubmissionIndex = this.application.randstad_process.clientInterview.priorSubmissions.length - 1;
                  this.interview = clientInterview.priorSubmissions[this.originalSubmissionIndex].submission;
                  this.interviewDate = moment
                  (clientInterview.priorSubmissions[this.originalSubmissionIndex].interviewDate).toDate();
                }
                else{
                if (clientInterview.submission) {
                  this.interview = clientInterview.submission;
                }
                if (clientInterview.interviewDate) {
                  /* We are storing interviewDate as UTC to database, but when we come back to UI we need to show that in local time*/
                  /* Any other way around causes problems for integration and BH, please talk with Eyyup before doing change*/
                  this.interviewDate = clientInterview.interviewDate.toDate();
                }
              }
              }
              this.shownPhase = this.planInterviewView;
            }
          });
        }
      });
  }

  openLogFeedbackModal() {
    const initialState = {
      textareaLabel: 'Hiring manager feedback',
      acceptFn: this.acceptFeedback.bind(this),
      rejectFn: this.reject.bind(this)
    };
    this._bsModalService.show(LogFeedbackModalComponent, { initialState });
  }

  editInterviewSchedule() {
    this.submitInterview(true);
  }

  /**
   * Called from Log Feedback Modal. Will send an update to the server
   * @param {string} feedback
   * @listens LogFeedbackModalComponent#accept
   */
  acceptFeedback(feedback: string) {

    const app = this.application.clone();
    app.randstad_process.lastProcessStatus = ProcessStatus.IH_FEEDBACK_ADDED;
    app.randstad_process.clientInterview.hiringManagerFeedback = feedback;

    const mentions = UtilityService.getMentionsRecipients(feedback);

    this._talentProcess.updateApplication(app)
      .then(() => {
        this.notificationType = NotificationTypes.HM_FEEDBACK;
        this.sendNotifications(mentions, feedback, this.application);
        this._toast.showToast('interview-hiring-manager.success_added_hiring', {cssClass: ToastClass.SUCCESS});
      })
      .catch((err: Error) => {
        this._toast.showToast('interview-hiring-manager.error_hiring_manager_feedback', {cssClass: ToastClass.DANGER});
        console.error(err);
      });
  }

  /**
   * Called from the "continue to offer" button
   * @listens click#save-interview-button
   * pass variable is for test to ignore profile validation
   */
  accept(pass?: boolean) {
    const isProfileValid = this._recruitmentPhasesService.checkIfProfileIsDataValid();
    if (isProfileValid === true || pass) {
      const key = this.application.randstad_process.lastProcessStep;
      const now = moment();
      const body = this.application.clone();
      body.randstad_process.apply({
        lastProcessStep: ProcessStep.OFFER,
        lastProcessStepNum: this.application.randstad_process.lastProcessStepNum + 1,
        lastProcessStepDate: now,
        lastProcessStatus: ProcessStatus.O_SENT
      });
      body.randstad_process.clientInterview.apply({
        outcome: OUTCOME.PROCEED,
        outcomeDate: now,
        agentID: this._authService.user.BackOfficeID
      });
      const intgSteps: INTG_STEPS[] = [];
      this._talentProcess.updateApplication(body, intgSteps, key)
        .then(() => {
          this._metrics.addEventToQueue(this.application, `candidate advanced to offer stage`);
          this._toast.showToast('interview-hiring-manager.advance_success', {cssClass: ToastClass.SUCCESS});
        })
        .catch(err => {
          this._toast.showToast('interview-hiring-manager.advance_error', {cssClass: ToastClass.DANGER});
          console.error(err);
        });
    }
    // no else needed since it should throw a modal in case of a talent detail containing an error
  }

  submitInterview(bypass = false) {
    if(this._recruitmentPhasesService.talentInterviewStatus){
      this._recruitmentPhasesService.openWarningModal()
    }else{
      this.showErrorNote = false;
    this.showErrorDate = false;
    const values = this.hmForm.getRawValue();
    this.interview = values.interview;
    if ((this.interview && this.interviewDate) || bypass) {
      const key = this.application.randstad_process.lastProcessStep;
      let interviewDate;
      const body = this.application.clone();
      body.randstad_process.apply({
        lastProcessStatus: ProcessStatus.IH_INTERVIEW_SCHEDULED
      });
      let clientInterviewModel;
      if(this.application?.randstad_process?.clientInterview?.priorSubmissions?.length > 0)
      {
        this.originalSubmissionIndex = this.application.randstad_process.clientInterview.priorSubmissions.length - 1;
        interviewDate = bypass ? this.application.randstad_process.clientInterview.priorSubmissions[this.originalSubmissionIndex].interviewDate || null : this.interviewDate || null;

        const priorSubmissions = this.application.randstad_process.clientInterview.priorSubmissions || [];
        priorSubmissions[this.originalSubmissionIndex].submission = bypass ? this.application.randstad_process.clientInterview.submission : this.interview;
        priorSubmissions[this.originalSubmissionIndex].interviewDate = moment(interviewDate).toISOString();

          clientInterviewModel = {
          editing: bypass,
          agentID: this._authService.user.BackOfficeID,
          submittedTo: { fullName: this._jobOrder.storedJobOrder.hiringManager, jobTitle: 'Hiring Manager' },
          submissionDate: moment(),
          priorSubmissions,
        };
      }
      else
      {
        interviewDate = bypass ? this.application.randstad_process.clientInterview.interviewDate || null : this.interviewDate || null;
          clientInterviewModel = {
          editing: bypass,
          interviewDate: moment(interviewDate),
          submission: bypass ? this.application.randstad_process.clientInterview.submission : this.interview,
          agentID: this._authService.user.BackOfficeID,
          submittedTo: { fullName: this._jobOrder.storedJobOrder.hiringManager, jobTitle: 'Hiring Manager' },
          submissionDate: moment()
        };

      }
     body.randstad_process.clientInterview ? body.randstad_process.clientInterview.apply(clientInterviewModel) : body.randstad_process.apply({clientInterview: new ClientInterview(clientInterviewModel)});
     const intgSteps : INTG_STEPS[] = [];

      if(!this.application.randstad_process.clientInterview || this.application.randstad_process.clientInterview.editing) {
        intgSteps.push(INTG_STEPS.SUBMISSION);
      }

      const values = this.hmForm.getRawValue();
      const mentions = UtilityService.getMentionsRecipients(values.interview);

      this._talentProcess.updateApplication(body, intgSteps, key)
        .then(() => {
          this._metrics.addEventToQueue(this.application, bypass ? `interview with hiring manager being editted` : `interview with hiring manager scheduled`);
          if (!bypass) {
            this._toast.showToast('interview-hiring-manager.client_interview_success', { cssClass: ToastClass.SUCCESS });
          }
          this.sendNotifications(mentions, values.interview, this.application);
        })
        .catch(err => {
          if (!bypass) {
            this._toast.showToast('interview-hiring-manager.client_interview_error', { cssClass: ToastClass.DANGER });
          }
          console.error(err);
        });
    } else {
      this.showErrorNote = !Boolean(this.interview);
      this.showErrorDate = !Boolean(this.interviewDate);
    }
    }
  }

  sendNotifications(recipients: EmailRecipient[], comment: string, application: Application) {
    const emailAddressOnToLine = recipients? recipients.splice(0,1) : []
    const notificationBody: NotificationData = {
      notificationType: this.notificationType,
      notificationObject: {
        title: this.jobOrder.internalTitle,
        candidateFullName: `${this.profile.personNames[0].structuredName.givenName} ${this.profile.personNames[0].structuredName.familyName}`,
        published_by_user: this.jobOrder.allbirds_metadata.published_by_user,
        published_by_user_email: this.jobOrder.allbirds_metadata.published_by_user_email,
        allbirds_job_id: this.jobOrder.allbirds_metadata.allbirds_job_id,
        executing_action_user: this.auth.user.FullName,
        executing_action_user_email: this.auth.user.EmailAddr,
        customer_name: this.jobOrder.allbirds_metadata.customer_name,
        front_office_id: this.jobOrder.allbirds_metadata.front_office_id,
        user: {...this.auth.user},
        emailRecipients: emailAddressOnToLine,
        emailCc:recipients,
        // noteType: this.getFriendlyNoteType(noteType),
        noteComment: comment,
        contextUrl: this._utility.getContextUrl(location.pathname, location.search),
        applicationObject: application,
        vms_req_id: this.jobOrder.allbirds_metadata.vms_req_id,
        hiringManager: this.jobOrder.hiringManager
      }
    };

    // this._loading.show();
    const sub = this._notificationService.sendNotification(notificationBody)
    .pipe(catchError((err) => {
      console.error(err);
      // this._loading.hide();
      return err;
    }))
    .subscribe(() => {
      sub.unsubscribe();
      // this._loading.hide();
    });
  }

  reject(feedback: string) {
    const key = this.application.randstad_process.lastProcessStep;
    const now = moment();
    const body = this.application.clone();
    body.randstad_process.apply({
      rejected: true,
      rejectedTimestamp: now,
      rejectedByAgentID: this._authService.user.BackOfficeID
    });
    body.randstad_process.clientInterview.apply({
      outcome: OUTCOME.REJECTED,
      hiringManagerFeedback: feedback,
      outcomeDate: now
    });
    const intgSteps: INTG_STEPS[] = [];
    this._talentProcess.updateApplication(body, intgSteps, key)
      .then(() => {
        this._metrics.addEventToQueue(this.application, `hiring manager feedback entered for candidate`);
        this._toast.showToast('interview-hiring-manager.hiring_manager_success', { cssClass: ToastClass.SUCCESS });
      })
      .catch(err => {
        this._toast.showToast('interview-hiring-manager.hiring_manager_error', { cssClass: ToastClass.DANGER });
        console.error(err);
      });
  }

  changeMeridian(time: Date, type: string) {
    let hours = time.getHours();
    const minutes = time.getMinutes();
    if ((type === 'PM')) {
      hours = hours + 12;
    } else if (type === 'AM') {
      hours = hours - 12;
    }
    this.interviewDate = new Date(time.setHours(hours, minutes));
  }

  /**
   * Round to the nearest 15 minutes when a date is selected
   * @param {Date} value - Date from date picker
   * @listens bsValueChange#interviewDate
   */
  onDatePicked(value: Date) {
    if (value && !this.interviewDate) {
      // round time to nearest 15 minutes
      const start = moment(value);
      const remainder = 15 - (start.minute() % 15);
      // What a hack, however, without the timeout this happens before the model is set from the datepicker component
      setTimeout(() => {
        this.interviewDate = moment(start).add(remainder, 'minutes').toDate();
      }, 1);
    }
  }

}
