<ul class="hz-btn-list filter-btn-list">
  <li *ngFor="let filter of filters">
    <button class="btn btn-round"
            [class.active]="filter === activeFilter"
            (click)="_talentHistory.toggleFilter(filter); isActivitiesToggle(filter)">
      {{ filter }}
    </button>
  </li>
</ul>

<div class="spinner-container" *ngIf="_talentHistory.isLoading && !_talentHistory.hasErrors && !_talentHistory.fetchingMoreActivities">
  <vertical-align>
    <div class="spinner-border custom-spinner text-primary" role="status">
      <span class="sr-only text-center">{{'talent-history.loading' | translate}}</span>
    </div>
  </vertical-align>
</div>

<div class="error" *ngIf="_talentHistory.hasErrors">
  <p>{{'talent-history.an_error_occurred' | translate}}</p>
</div>




<app-talent-history-feed *ngIf="!_talentHistory.isLoading && !_talentHistory.hasErrors"
                         [activities]="visibleActivities"
                         [talentProfile]="talentProfile"
                          [jobInfo]="job"
                          [activeFilter]="activeFilter"
                          [preselectedActivityFilter]="selectedActivityFilter"
                          (infinityScroll)="infinityScroll()"
                          (changeFilter)="changeFilter($event)">
</app-talent-history-feed>
