import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FirestoreService } from '../firestore/firestore.service';
import { PlatformAlert } from '../../models/internal/platform-alert.model';
import { v4 as uuidv4 } from 'uuid';
import { ToastClass, ToastService } from '../toast';

@Injectable({
  providedIn: 'root'
})
export class PlatformAlertService {

  private alertIstActive = false;
  private alertSource = new BehaviorSubject<PlatformAlert>(null);
  public alertObservable$ = this.alertSource.asObservable();
  public alertID = '';


  constructor(
    private _firestore: FirestoreService,
    private toastService: ToastService) {
    this._firestore.listenToAlert()
      .subscribe(data => {
        const alert = data[0];

        if (alert && alert.isActive) {
          this.setNewAlert(alert);
        } else if (alert && !alert.isActive && this.alertIstActive) {
          this.removeAlert();
        }
      });
  }

  setNewAlert(alert: PlatformAlert): void {
    this.alertSource.next(alert);
    this.alertIstActive = true;
    this.alertID = alert.id;
  }

  removeAlert(): void {
    this.alertIstActive = false;

    this._firestore.removeAlert(this.alertID)
      .then(data => {
        this.alertSource.next(null);
      }).catch(err => {
      console.error(err);
      this.toastService.showToast(`There was an error removing the alert.`, { cssClass: ToastClass.DANGER });
    });
  }

  createNewAlert(alert: PlatformAlert): void {
    const id = uuidv4();
    alert.id = id;

    this._firestore.createNewAlert(alert, id)
      .then(data => {
        this.setNewAlert(alert);
      }).catch(err => {
      console.error(err);
      this.toastService.showToast(`There was an error creating the alert.`, { cssClass: ToastClass.DANGER });
    });
  }

  updateAlert(alert: PlatformAlert): void {
    alert.id = this.alertID;
    this._firestore.editAlert(alert)
      .then(data => {
        this.setNewAlert(alert);
      }).catch(err => {
      console.error(err);
      this.toastService.showToast(`There was an error updating the alert.`, { cssClass: ToastClass.DANGER });
    });
  }
}
