<div id="recruitmentPhases">
  <ng-container *ngIf="currentStep">
    <div class="alert alert-warning border-0" *ngIf="talentApplication?.randstad_process.rejected">
      <img src="assets/icons/alert.svg"  />
      <p class="rejected-text">{{ getRejectedText() }}</p>
    </div>

    <div class="phase-header row no-gutters">
      <div class="col">
        <h3 class="mb-0" *ngIf="talentApplication && !applicationIsComplete(talentApplication)">
          <img src="{{ currentStep?.stepIcon }}"  />
          {{ currentStep?.stepTitle }}
        </h3>
        <h3 class="mb-0" *ngIf="talentApplication && applicationIsComplete(talentApplication)">
          <img src="assets/icons/check.svg"  />{{'recruitment-phases.completed' | translate}}</h3>
      </div>
      <div class="col text-right"
            *ngIf="currentStep.stepId !== steps.size  && talentApplication.randstad_process.lastProcessStep !== 'offer'">
        <vertical-align>
          <ul class="hz-btn-list" [class.disabled]="talentApplication?.randstad_process.rejected || !talentApplication?.randstad_process?.candidateFrontOfficeID || (talentApplication && applicationDidChange(talentApplication))">
            <li>
              <div class="btn-group" *ngIf="skippableSteps?.length > 0" dropdown
                [isDisabled]="talentApplication?.randstad_process.rejected || !talentApplication?.randstad_process?.candidateFrontOfficeID || (talentApplication && applicationDidChange(talentApplication))">
                <button dropdownToggle id="skipDropdown" type="button" class="btn-reset text-primary">{{'recruitment-phases.skip' |
                  translate}}
                  <img src="/assets/icons/chevron-down-s.svg" alt="many"  />
                </button>

                <ul *dropdownMenu class="rds-dropdown dropdown-menu dropdown-menu-right" role="menu"
                  aria-labelledby="skipDropdown">
                  <li class="rds-dropdown-title" role="menuitem">{{'recruitment-phases.skip_to:' | translate}}</li>
                  <li class="dropdown-divider"></li>
                  <li role="menuitem" class="rds-dropdown-item" *ngFor="let step of skippableSteps"  [attr.data-puppeteer]="step.stepTitle">
                    <button class="btn-reset " (click)="skipToStep(talentApplication, step)">
                      <img src="{{ step.stepIcon }}"  />
                      <span class="pt-2">{{ step.stepTitle | lowercase }}</span>
                    </button>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </vertical-align>
      </div>
    </div> <!-- / .phases-header -->

    <div class="mt-3" *ngIf="talentApplication?.randstad_process.rejected">
      <p *ngIf="jobOrder && talentApplication" class="m-0 pb-4 phase-subtitle">{{getStatusLabel()}}</p>

      <div *ngIf="!talentApplication?.randstad_process.rejected && talentApplication?.randstad_process.prescreening.isManualPrescreening">
        <manual-prescreening-results [application]="talentApplication"></manual-prescreening-results>
      </div>

      <p class="m-0 pb-4 pt-4 phase-subtitle" *ngIf="conversationID">
        <prescreening-questions [conversationID]="conversationID"></prescreening-questions>
      </p>

      <div class="mt-4">
        <h4 class="mb-1">{{'recruitment-phases.rejection_reason' | translate}}</h4>
        <h4 class="text-gray">{{ (talentApplication?.randstad_process.rejectReason || 'Not specified.') | stringclean: ['rfoOfferRejectCode'] }}</h4>
      </div>

      <div>
        <h4 class="mb-1">{{'recruitment-phases.note' | translate}}</h4>
        <h4 class="text-gray mb-0" [innerHTML]="talentApplication?.randstad_process.rejectNote || 'Not specified.'"></h4>
      </div>
      <div *ngIf="talentApplication.randstad_process.lastProcessStep === 'offer' && this._auth.user.Source.checkLob('RT', 'RE','CR')" #ctaContainer class="action-buttons w-100 text-right">
          <app-talent-process-cta
            [id]="'offer-accepted-button'"
            (execute)="reinstate()">
            {{'offer.offer_accepted' | translate}}
          </app-talent-process-cta>
        </div>

    </div>

    <div *ngIf="!talentApplication.randstad_process.rejected">
      <ng-container [ngSwitch]="talentApplication.randstad_process.lastProcessStep">
        <shortlisted-phase *ngSwitchCase="'shortlisted'"></shortlisted-phase>
        <prescreening-phase *ngSwitchCase="'prescreening'"
                            (unfitClick)="openNotFitModal()"
                            [talentInterviewStatus]="talentInterviewStatus">
        </prescreening-phase>
        <virtual-interview-phase *ngSwitchCase="'interview'"
                                 [job]="jobOrder"
                                 (unfitClick)="openNotFitModal()"
                                 [talentInterviewStatus]="talentInterviewStatus">
        </virtual-interview-phase>
        <review-account-manager-phase *ngSwitchCase="'internalSubmission'"
                                      [job]="jobOrder"
                                      (unfitClick)="openNotFitModal($event)"
                                      (skipToInterviewHMClick)="skipToInterviewHM(talentApplication)"
                                      [talentInterviewStatus]="talentInterviewStatus">
        </review-account-manager-phase>
        <review-hiring-manager-phase *ngSwitchCase="'clientSubmission'"
                                     [job]="jobOrder"
                                     (unfitClick)="openNotFitModal($event)"
                                     [talentInterviewStatus]="talentInterviewStatus">
        </review-hiring-manager-phase>
        <interview-hiring-manager-phase *ngSwitchCase="'clientInterview'"
                                        (unfitClick)="openNotFitModal($event)"
                                        [talentInterviewStatus]="talentInterviewStatus">
        </interview-hiring-manager-phase>
        <offer-phase *ngSwitchCase="'offer'"
                     [job]="jobOrder"
                     (unfitClick)="openNotFitModal()"
                     [talentInterviewStatus]="talentInterviewStatus">
        </offer-phase>
      </ng-container>
    </div>
  </ng-container>
</div>
