import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import { Subscription } from 'rxjs';
import { TalentHistoryService } from './talent-history.service';
import { Profile } from '../../../models/external/profile.model';
import { TalentHistoryFilter } from './talent-history.types';
import { Application } from '../../../models/external/application.model';
import { Assignment } from '../../../models/external/assignment.model';
import { TalentActivity } from '../../../models/external/talent-activity.model';
import { Job } from '../../../models/external/job.model';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'app-talent-history',
  templateUrl: './talent-history.component.html',
  styleUrls: ['./talent-history.component.scss']
})
export class TalentHistoryComponent implements OnInit, OnChanges, OnDestroy {

  // Talent profile object.
  @Input() talentProfile: Profile;

  // Job order.
  @Input() job: Job;

  // Filters (used to iterate over & display in UI.)
  filters: TalentHistoryFilter[] = Object.values(TalentHistoryFilter);

  // Subscription for talent history data.
  historyDataSub: Subscription;

  // These data points are updated by the historyData subscription.
  activeFilter?: TalentHistoryFilter;

  // Activities to be rendered in the view.
  visibleActivities: (Application | Assignment | TalentActivity)[] = [];

  //Pagination for infinity scroll calls for activities
  from: number;

  selectedActivityFilter: string;

  constructor(
    public _talentHistory: TalentHistoryService,
    private _auth: AuthService,
  ) { }

  ngOnInit() {
    this.from = 0;
    this._talentHistory.clearHistory();
    this._talentHistory.updateDataSubject();
    this.listenToHistoryData();
    this._talentHistory.fetchHistory(this.talentProfile, 0, this.selectedActivityFilter);
  }



  ngOnChanges(changes: SimpleChanges): void {
    if (changes.talentProfile) {
      this.from = 0;
      this.activeFilter = undefined;
      this.visibleActivities = [];
    }
    if (changes.talentProfile && !changes.talentProfile.firstChange) {
      this.talentProfile = changes.talentProfile.currentValue.clone();
      this._talentHistory.fetchHistory(this.talentProfile, 0, this.selectedActivityFilter);
    }
  }

  ngOnDestroy() {
    this.visibleActivities = undefined;
    this.from = 0;
    this._talentHistory.clearHistory();
    if (this.historyDataSub) {
      this.historyDataSub.unsubscribe();
    }
  }






  listenToHistoryData() {
    this.historyDataSub = this._talentHistory.data
      .subscribe((data: any) => {
        if (data.activeFilter === 'activities') {
          // If current filter is activities, then we want to concat the new results.
          if (this.activeFilter === 'activities' && JSON.stringify(this.visibleActivities) !== JSON.stringify(data.visibleActivities) && this.from !== 0) {
            this.visibleActivities = this.visibleActivities.concat(data.visibleActivities);
          }
          else {
            this.activeFilter = data.activeFilter;
            this.visibleActivities = data.visibleActivities;
          }
          this.from = this.visibleActivities.length;
          this._talentHistory.updateKeepFetching(this.from, data.totalActivities);
        } else {
          this.activeFilter = data.activeFilter;
          this.visibleActivities = data.visibleActivities;
        }
      });
  }

  infinityScroll() {
    this._talentHistory.fetchHistory(this.talentProfile, this.from, this.selectedActivityFilter);
  }

  isActivitiesToggle(filterKey: TalentHistoryFilter) {
    if (filterKey === 'activities') {
      this.from = 0;
      this._talentHistory.fetchHistory(this.talentProfile, 0, this.selectedActivityFilter);

    }
  }

  changeFilter(filter: string) {
    this.visibleActivities = [];
    this.selectedActivityFilter = filter;
    this._talentHistory.fetchHistory(this.talentProfile, 0, filter);
  }
}
