<div class="modal-header" style="justify-content: flex-end">
  <button type="button" class="btn-reset" aria-label="Close" (click)="_bsModalRef.hide()">
    <i class="icon-WL_icon_close"></i>
  </button>
</div>

<div class="modal-body" style="overflow: auto">
  <h2>{{'log-feedback-modal.log_feedback' | translate}}</h2>
  <div class="form-group" [formGroup]="feedbackForm">
    <label for="feedbackInput">
      {{ textareaLabel }}
    </label>
    <app-ckeditor
      id="hmAvailability"
      class="text-box w-100"
      [parentForm]="feedbackForm"
      formConName="hiringManagerFeedback"
      height="120"
      [enableMentions]="true">
    </app-ckeditor>
    <form-error [fieldAlias]="'Hiring manager feedback'" [control]="feedbackForm.controls.hiringManagerFeedback"></form-error>
  </div>
</div> <!-- / .modal-body -->

<div class="modal-footer" style="justify-content: flex-end">
  <ul class="hz-btn-list text-right">
    <li>
      <button type="button" class="btn rds--button--primary" aria-label="cancel" (click)="_bsModalRef.hide()">
        {{'log-feedback-modal.cancel' | translate}}
      </button>
    </li>
    <li>
      <button type="button" class="btn rds--button--secondary" aria-label="cancel" (click)="accept()" data-puppeteer="hiring-manager-feedback-continue-button">
        {{'log-feedback-modal.continue' | translate}}
      </button>
    </li>
  </ul>
</div>
