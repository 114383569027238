import { UserService } from 'src/app/shared/services/user/user.service';
import { UtilityService } from '../utility/utility.service';
import { User } from '../../models/external/user.model';
import { Job } from '../../models/external/job.model';
import { Process } from '../../models/external/process.model';
import { Application } from '../../models/external/application.model';

export class ProcessInformationProvider {
  constructor(
    public process: Process,
    public job?: Job,
    public user?: User,    
    private _interviewLocation?: string,
    private _userService?: UserService,
    private application?: Application
  ) {
  }

  get interviewer() {
    const interviewerEmail = this.process.interviewSchedule.recruiterEmail;
    const interviewer = this._userService.getFromCacheUsingEmail(interviewerEmail);
    return interviewer ? interviewer.FullName : '';
    
  }

  get interviewType() {
    const { interviewType } = this.process.interviewSchedule;
    return UtilityService.toTitleCase(UtilityService.removeUnderscore(interviewType));
  }

  get interviewDatetime() {
    if (this.process.interviewSchedule) {
      const { timeSlotSelected, interviewScheduledOn } = this.process.interviewSchedule;
      const slotsSelected = timeSlotSelected.isValid(); // when not 'NO_SLOTS_SELECTED'
      return (slotsSelected ? timeSlotSelected : interviewScheduledOn).formatDatetime();
    }
    return '';
  }

  get interviewLocation() {
    if (this._interviewLocation) {
      return this._interviewLocation;
    }
    const { inPersonInterviewLocation, interviewType } = this.process.interviewSchedule;
    const jobAddr = UtilityService.buildAddressString(this.job.structuredAddresses[0]);
    return interviewType === 'IN_PERSON' ? inPersonInterviewLocation || jobAddr : '';
  }

  get lastProcessStepDate() {
    return this.process.lastProcessStepDate.formatDate();
  }

  get rejectedTimestamp() {
    return this.process.rejectedTimestamp.formatDate();
  }

  get userFullName() {
    return this.user ? `${this.user.FirstName} ${this.user.LastName}` : '';
  }

  get jobTitle() {
    return this.job.title;
  }

  get submissionDatetime() {
    return this.process.clientSubmission.submissionDate.formatDatetime();
  }

  get candidateFullName() {
    return this.process.candidateFullName && this.process.candidateFullName != "" ? this.process.candidateFullName : this.application.randstad_;
  }

  get initiatedDatetime() {
    return (this.process.prescreening.initiatedDate && this.process.prescreening.initiatedDate.formatDatetime()) || null;
  }


}
