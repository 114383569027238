import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { JobOrderService } from '../../services/job-order/job-order.service';
import { UtilityService } from '../../services/utility/utility.service';
import { Job } from '../../models/external/job.model';
import { ApiService } from '../../services/api/api.service';

@Component({
  selector: 'app-job-details-modal',
  templateUrl: './job-details-modal.component.html',
  styleUrls: ['./job-details-modal.component.scss']
})
export class JobDetailsModalComponent implements OnInit {

  job?: Job;
  jobId: string;

  constructor(
    private _api: ApiService,
    public _bsModalRef: BsModalRef,
    private _jobOrder: JobOrderService,
    private _utility: UtilityService
  ) { }

  ngOnInit() {
    if (this.jobId) {
      this.loadJob(this.jobId);
    }
  }

  loadJob(allbirdsJobId: string) {
    const obs = this._api.getJob(allbirdsJobId);
    console.log('[loadJob] fetching job with id:', allbirdsJobId);
    obs.subscribe((res) => {
      this.job = this.formatJobData(res);
      console.log('[loadJob] setting job:', this.job);
    });
  }

  formatJobData(jobData: Job): Job {
    return jobData.clone().apply({
      addresses: [UtilityService.buildAddressString(jobData.structuredAddresses[0])]
    });
  }
}
