import { Injectable } from '@angular/core';
import { TalentSortTypes } from '../../../modules/talent/pages/talent-management-listings/TalentSortTypes';
import { TalentSortInput } from './sorting.models';
import { SHORTLIST_SORT_TYPES } from './sorting.types';
import { Application } from '../../models/external/application.model';
import { SourceOption } from '../job-order/job-order-details.config';
import { ListTalent } from 'src/app/shared/models/external/list-talent.model';

@Injectable({
  providedIn: 'root'
})
export class SortingService {

  constructor() { }

  /**
   * Given an array of applications and a sort type, returns a sorted version
   * of the array.
   * @param applications - array of application objects
   * @param sort - talent sort type
   */
  static sortApplicationArray(applications: Application[], sort: TalentSortTypes) {
    switch (sort) {
      case TalentSortTypes.NAME_A_TO_Z:
        return applications.sort(SortingService.shortlistNameAToZ);
      case TalentSortTypes.NAME_Z_TO_A:
        return applications.sort(SortingService.shortlistNameZToA);
      case TalentSortTypes.LAST_UPDATED_ASCENDING:
        return applications.sort(SortingService.shortlistDateAscending);
      case TalentSortTypes.LAST_UPDATED_DESCENDING:
      default:
        return applications.sort(SortingService.shortlistDateDescending);
    }
  }

  /**
   * Sorts talents based on the inputted sortType -- which is simply the name
   * of the function to be called within Array.sort(). The types are defined in
   * sorting.types.ts.
   *
   * @param talent - an object that abides by the TalentSortInput interface
   * @param sortType - string that is equal to the name of the desired sorting function
   */
  static sortTalent(talent: TalentSortInput, sortType: SHORTLIST_SORT_TYPES): any {
    const shortlist = talent.shortlist || [];
    const rejected = talent.rejected || [];
    shortlist.sort(SortingService[sortType]);
    rejected.sort(SortingService[sortType]);
    return { shortlist, rejected };
  }

  /*************************************************************************
   * Shortlisted talent sorting methods to be called within Array.sort().
   ************************************************************************/

  /**
   * Sort applications by last updated time from oldest to newest.
   * @param a - application object
   * @param b - application object
   */
  static shortlistDateAscending(a: Application, b: Application) {
    return a.randstad_process.lastProcessStepDate.diff(b.randstad_process.lastProcessStepDate);
  }

  /**
   * Sort applications by last updated time from newest to oldest.
   * @param a - application object
   * @param b - application object
   */
  static shortlistDateDescending(a: Application, b: Application) {
    return -SortingService.shortlistDateAscending(a, b);
  }

  /**
   * Sort talent by last updated time from oldest to newest.
   * @param a - pipelineTalent object
   * @param b - pipelineTalent object
   */
  static pipelineTalentListDateAscending(a: ListTalent, b: ListTalent): number {
    return a.date_updated.diff(b.date_updated);
  }

  /**
   * Sort talent by last updated time from oldest to newest.
   * @param a - pipelineTalent object
   * @param b - pipelineTalent object
   */
  static pipelineTalentListDateDescending(a: ListTalent, b: ListTalent): number {
    return -SortingService.pipelineTalentListDateAscending(a, b);
  }

  /**
   * Sort applications by name from A to Z.
   * @param a - application object
   * @param b - application object
   */
  static shortlistNameAToZ(a: Application | ListTalent, b: Application | ListTalent) {
    let aName, bName;
    if ('randstad_process' in a && 'randstad_process' in b) {
      aName = a.randstad_process.candidateFullName.toLowerCase();
      bName = b.randstad_process.candidateFullName.toLowerCase();
    } else if ('first_name' in a && 'first_name' in b) {
      aName = `${a.first_name} ${a.last_name}`.toLowerCase();
      bName = `${b.first_name} ${b.last_name}`.toLowerCase();
    } else {
      return 0;
    }
    return aName.localeCompare(bName);
  }

  /**
   * Sort applications by name from Z to A.
   * @param a - application object
   * @param b - application object
   */
  static shortlistNameZToA(a: Application | ListTalent, b: Application | ListTalent) {
    return -SortingService.shortlistNameAToZ(a, b);
  }

  /**
   * Sort sourceOptions by title from A to Z.
   * @param a - application object
   * @param b - application object
   */
  public sourceOptionsSort(a: SourceOption, b: SourceOption) {
    let res = 1;
    if (a.title < b.title) {
      res = -1;
    }
    return (res);
  }
}
