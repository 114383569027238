import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GoogleConversationService } from '../../services/google-conversation/google-conversation.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Conversation } from '../../models/external/conversation.model';
import { ConversationStatus } from '../../models/external/misc.model';
import { ConversationPrompt } from '../../models/external/conversation-prompt.model';
import { BadgeStyleClasses, ConversationStatusesDisplay } from '../../models/internal/conversation.status.model';
import { UtilityService } from '../../services/utility/utility.service';

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss']
})
export class ConversationComponent implements OnInit, OnDestroy {
  @Input() conversationID?: string;
  @Input() modal?: boolean;
  conversation: Conversation;
  conversationObs: BehaviorSubject<Conversation>;
  conversationSub: Subscription;
  loading = true;
  conversationStatuses = ConversationStatus;
  knockoutPrompts: ConversationPrompt[] = [];
  conversationPrompts: ConversationPrompt[] = [];

  toggledQuestions: boolean[] = [];

  constructor(private googleConversationService: GoogleConversationService) {}

  ngOnInit() {
    this.conversationObs = this.googleConversationService.getConversationForID(this.conversationID);

    this.conversationSub = this.conversationObs.subscribe((conversation: Conversation) => {
      /* The first response is always an empty object */
      if (conversation && conversation.answeredPrompts) {
        this.setConversationPrompts(conversation);
        this.loading = false;
      }
    });
  }

  setConversationPrompts(conversation: Conversation) {
    if (conversation) {
      const { completedPrompts } = conversation;
      this.knockoutPrompts = completedPrompts.filter(prompt => prompt.knockoutTriggered);
      // Below commented code is for testing without having to actually get an application to the required state
      // if (completedPrompts && completedPrompts.length > 3) {
      //   const tempPrompts = [completedPrompts[0], completedPrompts[2]];
      //   tempPrompts[0].knockoutTriggered = true;
      //   tempPrompts[1].knockoutTriggered = true;
      //   this.knockoutPrompts = tempPrompts;
      // }
      this.conversationPrompts = completedPrompts.filter(prompt => !prompt.knockoutTriggered);
      this.conversation = conversation;
    }
  }

  toggleQuestion(position: number) {
    this.toggledQuestions[position] = !this.toggledQuestions[position];
  }

  ngOnDestroy(): void {
    UtilityService.destroySubscription(this.conversationSub);
  }

  removeCEPrompt(promptTemplateName: string): Boolean {
    if (promptTemplateName) {
      return promptTemplateName.includes('_success') || promptTemplateName.includes('default_connection_phrase');
    }
    return true;
  }

  get badgeStatus(): ConversationStatusesDisplay {
    return this.googleConversationService.getConversationStatus(this.conversation);
  }

  get badgeClass(): BadgeStyleClasses {
    return this.googleConversationService.getConversationStatusStyles(this.conversation);
  }
}
