// tslint:disable:max-line-length

/**
 * @deprecated
 */
import { ProcessInformationProvider } from './process-information-provider';
import { ProcessStatus, ProcessStep, UserPermissions } from 'src/app/shared/models/external/misc.model';

export const PROCESS_PERMISSIONS = {
  /**
   * User Permissions that pertain to the talent process.
   */
  REVIEW_TALENT: 'reviewSubmittedTalent',
  SUBMIT_AM: 'submitCandidateToAccountManager'
};

/**
 * Key is value stored in lastProcessStep
 * value is the {@link ProcessStep}
 * This is so we can reference the ProcessStep via
 * the value stored in elastic
 * @example
 * PROCESS_STATUS_VALUES['prescreening']; // will return ProcessStep.PRESCREENING
 */
export enum ProcessStepInverse {
  prescreening = 'PRESCREENING',
  interview = 'INTERVIEW_RECRUITER',
  internalSubmission = 'REVIEW_AM',
  interviewSchedule = 'INTERVIEW_SCHEDULE',
  clientSubmission = 'REVIEW_HM',
  clientInterview = 'INTERVIEW_HM',
  offer = 'OFFER'
}

/**
 * Interface defining the items that show up in the Shortlist Filter (app-filter-shortlist-modal)
 */
export interface ShortlistFilterProps {
  pillText: string;
  dashboardText?: string;
  status: (ProcessStatus | 'APPLICANT' | 'ADDED_BY_ME' | 'ALL' | 'ILABOR')[];
  step: ProcessStep;
  id?: string; // Fixes an old implementation that probably isn't used anymore
  displayWarning?: boolean;
  visibleShortlist?: boolean;
  visibleDashboard?: boolean;
}

export interface OnboardingFilterProps {
  dashboardText: string;
  pillText: string;
  visibleDashboard: boolean;
  visibleShortlist: boolean;
  status: string[];
}

export enum OnboardingTypeKey {
  STARTED = 'started',
  NOT_STARTED = 'not_started',
  COMPLETE = 'complete'
}

export enum UnansweredTypeKey {
  UNA_NOT_CONTACTED = 'una_not_contacted',
  UNA_CONTACTED = 'una_contacted'
}

export type LabelFormatter = (information: ProcessInformationProvider) => string;

export interface LabelGroup {
  cardLabel?: string;
  processLabel?: string | LabelFormatter;
}

class ProcessStatusOptions {
  common?: LabelGroup;
  rgs?: LabelGroup;
  rt?: LabelGroup;
  cr?: LabelGroup;
  /**
   * True if action is required (i.e. "ACTION NEEDED")
   */
  actionRequired?: boolean;
  /**
   * The icon to show
   */
  icon?: string;
  /**
   * Properties for the filter-shortlist-modal items. If not provided, it is not filterable
   */
  filterProps?: ShortlistFilterProps;
  /**
   * If there is separate properties for recruiter and account manager
   * this will be the properties for the account manager
   */
  acctMgr?: ProcessStatusProps;
  /**
   * The assigned lastProcessStatus
   * @type {ProcessStatus}
   */
  lastProcessStatus?: ProcessStatus;

  // TODO(jason): adding this field to resolve typescript issue, but can't find anywhere it being written to.
  permissionRequiredToAct?: keyof UserPermissions;
}

/**
 * This object holds the text, icons and actionRequired information
 * for each sub-status that controls what shows up on the shortlist
 * card and process details panel.
 */
export class ProcessStatusProps extends ProcessStatusOptions {
  constructor(options: ProcessStatusOptions) {
    super();
    Object.assign(this, options);
  }

  get cardPrefix(): string {
    return this.actionRequired ? 'ACTION NEEDED\n' : '';
  }

  get rgsCardLabel(): string {
    const cardLabel = this.rgs && this.rgs.cardLabel || this.common && this.common.cardLabel;
    return this.cardPrefix + (cardLabel || '');
  }

  get rgsProcessLabel(): string | LabelFormatter {
    const processLabel = this.rgs && this.rgs.processLabel || this.common && this.common.processLabel;
    return processLabel || '';
  }

  get rtCardLabel(): string {
    const cardLabel = this.rt && this.rt.cardLabel || this.common && this.common.cardLabel;
    return this.cardPrefix + (cardLabel || '');
  }

  get rtProcessLabel(): string | LabelFormatter {
    const processLabel = this.rt && this.rt.processLabel || this.common && this.common.processLabel;
    return processLabel || '';
  }
  get crCardLabel(): string {
    const cardLabel = this.cr && this.cr.cardLabel || this.common && this.common.cardLabel;
    return this.cardPrefix + (cardLabel || '');
  }

  get crProcessLabel(): string | LabelFormatter {
    const processLabel = this.cr && this.cr.processLabel || this.common && this.common.processLabel;
    return processLabel || '';
  }
}

/**
 * Defines structure of the sub-status of a shortlisted candidate
 */
export type tProcessSubStatus = {
  /**
   * Key is a SUB_STATUS enum value
   */
  [key in ProcessStatus]?: ProcessStatusProps;
};
/**
 * Defines object structure of the process_status constant
 */
export type tProcess_Status = {
  /**
   * Key is a STATUS enum value
   */
  [key in ProcessStep]: tProcessSubStatus;
};

/**
 * Object defining the shortlist card and process tab label content
 * based on application {@link STATUS} and {@link SUB_STATUS} values.
 * This Object also contains information for display of filter options in
 * the filter-shortlist-modal
 * STATUS is the value stored in lastProcessStep,
 * SUB_STATUS is the value stored in lastProcessStatus
 */
export const PROCESS_STATUS_INFO: tProcess_Status = {
  [ProcessStep.PRESCREENING]: {
    [ProcessStatus.P_START]: new ProcessStatusProps({
      rgs: {
        cardLabel: 'Start Pre-screening',
        processLabel: 'Our chatbot can assess a candidate\'s interest in the position. If their response is positive, we\'ll ask prescreening questions and plan an interview.'
      },
      rt: {
        cardLabel: 'Start Pre-screening',
        processLabel: 'Our chatbot can assess a candidate\'s interest in the position via pre-screening. If their response is positive then we will plan a recruiter interview.'
      },
      cr: {
        cardLabel: 'Start Pre-screening',
        processLabel: 'Pre-screen candidate\'s interest in the position manually and log the results.'
      },
      lastProcessStatus: ProcessStatus.P_START,
      actionRequired: true,
      filterProps: {
        pillText: 'not started',
        status: [ProcessStatus.P_START],
        step: ProcessStep.PRESCREENING,
        visibleDashboard: true,
        visibleShortlist: true
      }
    }),
    [ProcessStatus.P_SENT]: new ProcessStatusProps({
      common: {
        cardLabel: 'FOLLOW UP\nWaiting for pre-screening results',
        processLabel: ({ initiatedDatetime }) => {
          return `Waiting for candidate\'s reaction to pre-screening.\nInvitation to pre-screen sent\nPre-screening questions have been sent to the candidate per chatbot${initiatedDatetime ? ` on ${initiatedDatetime}` : ''}.`;
        }
      },
      lastProcessStatus: ProcessStatus.P_SENT,
      filterProps: {
        pillText: 'invitation sent',
        status: [ProcessStatus.P_SENT],
        step: ProcessStep.PRESCREENING,
        visibleDashboard: true,
        visibleShortlist: true
      }
    }),
    [ProcessStatus.P_IN_PROGRESS]: new ProcessStatusProps({
      common: {
        cardLabel: 'FOLLOW UP\nPre-screening in progress',
        processLabel: 'Pre-screening is in progress. Please review answered questions.'
      },
      lastProcessStatus: ProcessStatus.P_IN_PROGRESS,
      filterProps: {
        pillText: 'in progress',
        status: [ProcessStatus.P_IN_PROGRESS],
        step: ProcessStep.PRESCREENING,
        visibleDashboard: true,
        visibleShortlist: true
      }
    }),
    [ProcessStatus.P_REVIEW_QUESTIONS]: new ProcessStatusProps({
      common: {
        cardLabel: 'Review pre-screening questions',
        processLabel: 'Review pre-screening questions.'
      },
      lastProcessStatus: ProcessStatus.P_REVIEW_QUESTIONS,
      actionRequired: true,
      filterProps: {
        pillText: 'ready for review',
        status: [ProcessStatus.P_REVIEW_QUESTIONS],
        step: ProcessStep.PRESCREENING,
        visibleDashboard: true,
        visibleShortlist: true
      },
    }),
    [ProcessStatus.P_AWAITING_RESULTS]: new ProcessStatusProps({
      common: {
        cardLabel: 'FOLLOW UP\nWaiting for pre-screening results',
        processLabel: ({ initiatedDatetime }) =>
          `Waiting for candidate\'s reaction to pre-screening.\nInvitation to pre-screen sent\nPre-screening questions have been sent to the candidate per chatbot on ${initiatedDatetime}.`
      },
      lastProcessStatus: ProcessStatus.P_AWAITING_RESULTS
    }),
    [ProcessStatus.P_DECIDE_PROCEED]: new ProcessStatusProps({
      common: {
        cardLabel: 'Proceed with the candidate?',
        processLabel: 'Decide whether to continue with the candidate or not.'
      },
      lastProcessStatus: ProcessStatus.P_DECIDE_PROCEED,
      actionRequired: true
    }),
    [ProcessStatus.P_NOT_A_FIT]: new ProcessStatusProps({
      common: {
        cardLabel: 'Not a fit',
        processLabel: ({ rejectedTimestamp, userFullName, jobTitle, candidateFullName }) =>
          `${candidateFullName} was rejected for the job ${jobTitle}${userFullName ? ` by ${userFullName} ` : ''} on ${rejectedTimestamp}.\n${candidateFullName} was rejected in this phase.`
      },
      lastProcessStatus: ProcessStatus.P_NOT_A_FIT
    })
  },
  [ProcessStep.INTERVIEW_RECRUITER]: {
    [ProcessStatus.IR_PLAN_INTERVIEW]: new ProcessStatusProps({
      common: {
        cardLabel: 'Plan an interview with the candidate',
        processLabel: 'Schedule an interview manually or let our chatbot set one up for you'
      },
      cr: {
        cardLabel: 'Log interview with the candidate',
        processLabel: 'Log the manual interview feedback'
      },
      lastProcessStatus: ProcessStatus.IR_PLAN_INTERVIEW,
      actionRequired: true,
      filterProps: {
        pillText: 'plan',
        status: [ProcessStatus.IR_PLAN_INTERVIEW],
        step: ProcessStep.INTERVIEW_RECRUITER,
        visibleDashboard: true,
        visibleShortlist: true
      }
    }),
    [ProcessStatus.IR_INTERVIEW_SCHEDULED]: new ProcessStatusProps({
      common: {
        cardLabel: 'Interview scheduled',
        processLabel: ({ interviewType, interviewDatetime, interviewLocation, interviewer }) => {
          if (interviewType === 'In Person') {return `Log interview Outcome\n${interviewType} interview is scheduled with ${interviewer} on ${interviewDatetime}\n${interviewLocation}`;}
          return `Log interview Outcome\n${interviewType} interview is scheduled with ${interviewer} on ${interviewDatetime}`;
        }
      },
      lastProcessStatus: ProcessStatus.IR_INTERVIEW_SCHEDULED,
      actionRequired: true,
      filterProps: {
        pillText: 'scheduled',
        status: [ProcessStatus.IR_INTERVIEW_SCHEDULED],
        step: ProcessStep.INTERVIEW_RECRUITER,
        visibleDashboard: true,
        visibleShortlist: true
      }
    }),
    [ProcessStatus.IR_AWAITING_RESPONSE]: new ProcessStatusProps({
      common: {
        cardLabel: 'FOLLOW UP\nNo Scheduled Interview',
        processLabel: ({ lastProcessStepDate }) =>
          `Waiting for candidate response to interview invitation.\nChatbot interview invitation was sent on ${lastProcessStepDate}. Waiting for candidate action.`
      },
      lastProcessStatus: ProcessStatus.IR_AWAITING_RESPONSE,
      filterProps: {
        pillText: 'invitation sent',
        status: [ProcessStatus.IR_AWAITING_RESPONSE],
        step: ProcessStep.INTERVIEW_RECRUITER,
        visibleDashboard: true,
        visibleShortlist: true
      }
    }),
    [ProcessStatus.IR_RESCHEDULE_DECLINED_INTERVIEW]: new ProcessStatusProps({
      rgs: {
        cardLabel: 'Contact Candidate To Schedule Interview',
        processLabel: 'Candidate did not select a time offered by chatbot.\nPlease re-send the invite via chatbot or contact the candidate to confirm an interview.'
      },
      rt: {
        cardLabel: 'Contact Candidate to Schedule Interview',
        processLabel: 'Candidate did not select a time offered by chatbot.\nPlease contact the candidate to schedule an interview.'
      },
      cr: {
        cardLabel: 'Contact Candidate to Schedule Interview',
        processLabel: 'Candidate did not select a time offered by chatbot.\nPlease contact the candidate to schedule an interview.'
      },
      lastProcessStatus: ProcessStatus.IR_RESCHEDULE_DECLINED_INTERVIEW,
      actionRequired: true,
      filterProps: {
        pillText: 'issue scheduling',
        status: [ProcessStatus.IR_RESCHEDULE_DECLINED_INTERVIEW, ProcessStatus.IR_RESCHEDULE_INCONVENIENT_INTERVIEW],
        step: ProcessStep.INTERVIEW_RECRUITER,
        visibleDashboard: true,
        visibleShortlist: true
      }
    }),
    [ProcessStatus.IR_RESCHEDULE_INCONVENIENT_INTERVIEW]: new ProcessStatusProps({
      common: {
        cardLabel: 'Contact Candidate To Schedule Interview',
        processLabel: 'Candidate could not find a suitable time offered by chatbot.\nPlease contact the candidate to confirm an interview.'
      },
      lastProcessStatus: ProcessStatus.IR_RESCHEDULE_INCONVENIENT_INTERVIEW,
      actionRequired: true
    }),
    [ProcessStatus.IR_INTERVIEW_RESCHEDULED]: new ProcessStatusProps({
      common: {
        cardLabel: 'Interview Rescheduled',
        processLabel: ({ interviewType, interviewDatetime, interviewLocation }) =>
          `Interview rescheduled by the candidate. Waiting for the interview to occur.\n${interviewType} interview scheduled for ${interviewDatetime}\n${interviewLocation}`
      },
      lastProcessStatus: ProcessStatus.IR_INTERVIEW_RESCHEDULED,
      actionRequired: true
    }),
    [ProcessStatus.IR_INTERVIEW_CANCELLED]: new ProcessStatusProps({
      common: {
        cardLabel: 'Contact Candidate To Reschedule Interview',
        processLabel: ({ interviewDatetime }) =>
          `The interview${interviewDatetime ? ' scheduled for ' + interviewDatetime : ''} was cancelled by the candidate. Try scheduling a new interview` // recruiter can also cancel if logged manually
      },
      lastProcessStatus: ProcessStatus.IR_INTERVIEW_CANCELLED,
      actionRequired: true,
      filterProps: {
        pillText: 'cancelled',
        status:[ ProcessStatus.IR_INTERVIEW_CANCELLED],
        step: ProcessStep.INTERVIEW_RECRUITER,
        visibleDashboard: true,
        visibleShortlist: true
      }
    }),
    [ProcessStatus.IR_AWAITING_INTERVIEW]: new ProcessStatusProps({
      common: {
        cardLabel: 'Interview scheduled',
        processLabel: ({ interviewDatetime, interviewLocation }) =>
          `Interview scheduled by the candidate. Waiting for the interview to occur.\nIn person interview scheduled for ${interviewDatetime}\n${interviewLocation}`
      },
      lastProcessStatus: ProcessStatus.IR_AWAITING_INTERVIEW,
      actionRequired: true
    }),
    [ProcessStatus.IR_CONSIDER_RESULTS]: new ProcessStatusProps({
      common: {
        cardLabel: 'Decide Next Steps',
        processLabel: 'Decide whether to continue with the candidate or not'
      },
      lastProcessStatus: ProcessStatus.IR_CONSIDER_RESULTS,
      actionRequired: true,
      filterProps: {
        pillText: 'completed',
        status: [ProcessStatus.IR_CONSIDER_RESULTS],
        step: ProcessStep.INTERVIEW_RECRUITER,
        visibleDashboard: true,
        visibleShortlist: true
      }
    }),
    [ProcessStatus.IR_NOT_A_FIT]: new ProcessStatusProps({
      common: {
        cardLabel: 'Not a fit',
        processLabel: ({ rejectedTimestamp, userFullName, jobTitle, candidateFullName }) =>
          `${candidateFullName} was rejected for the job ${jobTitle} by ${userFullName} on ${rejectedTimestamp}.\n${candidateFullName} was rejected in this phase`
      },
      lastProcessStatus: ProcessStatus.IR_NOT_A_FIT
    })
  },
  [ProcessStep.INTERVIEW_SCHEDULE]: {},
  [ProcessStep.REVIEW_AM]: {
    [ProcessStatus.RA_SUBMIT_TO_AM]: new ProcessStatusProps({
      rt: {
        cardLabel: 'Submit the candidate to the AM',
        processLabel: 'Prepare the submission to account manager'
      },
      cr: {
        cardLabel: 'Submit the candidate to the AM',
        processLabel: 'Prepare the submission to account manager'
      },
      lastProcessStatus: ProcessStatus.RA_SUBMIT_TO_AM,
      actionRequired: true,
      filterProps: {
        pillText: 'Ready to submit',
        status: [ProcessStatus.RA_SUBMIT_TO_AM],
        step: ProcessStep.REVIEW_AM,
        visibleDashboard: true,
        visibleShortlist: false
      }
    }),
    [ProcessStatus.RA_WAITING_AM_FEEDBACK]: new ProcessStatusProps({
      rt: {
        cardLabel: 'FOLLOW UP\nWaiting for AM feedback',
        processLabel: 'Waiting for feedback from the account manager'
      },
      cr: {
        cardLabel: 'FOLLOW UP\nWaiting for AM feedback',
        processLabel: 'Waiting for feedback from the account manager'
      },
      lastProcessStatus: ProcessStatus.RA_WAITING_AM_FEEDBACK,
      acctMgr: new ProcessStatusProps({
        rt: {
          cardLabel: 'Review the candidate',
          processLabel: 'Review if the submitted talent is a fit for the job'
        },
        cr: {
          cardLabel: 'Review the candidate',
          processLabel: 'Review if the submitted talent is a fit for the job'
        },
        lastProcessStatus: ProcessStatus.RA_WAITING_AM_FEEDBACK,
        actionRequired: true
      }),
      filterProps: {
        pillText: 'Ready to review',
        status: [ProcessStatus.RA_WAITING_AM_FEEDBACK, ProcessStatus.RA_WAITING_SS_FEEDBACK],
        step: ProcessStep.REVIEW_AM,
        visibleDashboard: true,
        visibleShortlist: false
      }
    }),
    [ProcessStatus.RA_AM_UPDATE_SUB]: new ProcessStatusProps({
      rt: {
        cardLabel: 'Update Submission',
        processLabel: 'The account manager requested an update of your submission',
      },
      cr: {
        cardLabel: 'Update Submission',
        processLabel: 'The account manager requested an update of your submission',
      },
      actionRequired: true,
      lastProcessStatus: ProcessStatus.RA_WAITING_AM_FEEDBACK,
      acctMgr: new ProcessStatusProps({
        rt: {
          cardLabel: 'FOLLOW UP\nWaiting for the submission update',
          processLabel: 'Requested an update on submission'
        },
        cr: {
          cardLabel: 'FOLLOW UP\nWaiting for the submission update',
          processLabel: 'Requested an update on submission'
        },
        lastProcessStatus: ProcessStatus.RA_AM_UPDATE_SUB,
        actionRequired: false
      }),
      filterProps: {
        pillText: 'Ready to review',
        status: [ProcessStatus.RA_WAITING_AM_FEEDBACK, ProcessStatus.RA_WAITING_SS_FEEDBACK],
        step: ProcessStep.REVIEW_AM,
        visibleDashboard: true,
        visibleShortlist: false
      }
    }),
    [ProcessStatus.RA_RECRUITER_NOT_A_FIT]: new ProcessStatusProps({
      rt: {
        cardLabel: 'Not a fit',
        processLabel: ({ candidateFullName, rejectedTimestamp, userFullName, jobTitle }) =>
          `${candidateFullName} was rejected for the job ${jobTitle} by ${userFullName} on ${rejectedTimestamp}.\n${candidateFullName} was rejected in this phase.`
      },
      cr: {
        cardLabel: 'Not a fit',
        processLabel: ({ candidateFullName, rejectedTimestamp, userFullName, jobTitle }) =>
          `${candidateFullName} was rejected for the job ${jobTitle} by ${userFullName} on ${rejectedTimestamp}.\n${candidateFullName} was rejected in this phase.`
      },
      lastProcessStatus: ProcessStatus.RA_RECRUITER_NOT_A_FIT
    }),
    [ProcessStatus.RA_AM_NOT_A_FIT]: new ProcessStatusProps({
      rt: {
        cardLabel: 'Not a fit',
        processLabel: ({ candidateFullName, rejectedTimestamp, userFullName, jobTitle }) =>
          `${candidateFullName} was rejected for the job ${jobTitle} by ${userFullName} on ${rejectedTimestamp}.\n${candidateFullName} was rejected in this phase.`
      },
      cr: {
        cardLabel: 'Not a fit',
        processLabel: ({ candidateFullName, rejectedTimestamp, userFullName, jobTitle }) =>
          `${candidateFullName} was rejected for the job ${jobTitle} by ${userFullName} on ${rejectedTimestamp}.\n${candidateFullName} was rejected in this phase.`
      },
      lastProcessStatus: ProcessStatus.RA_AM_NOT_A_FIT
    }),
    [ProcessStatus.RA_SUBMIT_TO_SS]: new ProcessStatusProps({
      rt: {
        cardLabel: 'Submit the candidate to solutions',
        processLabel: 'Prepare the submission to solutions support'
      },
      cr: {
        cardLabel: 'Submit the candidate to solutions',
        processLabel: 'Prepare the submission to solutions support'
      },
      lastProcessStatus: ProcessStatus.RA_SUBMIT_TO_SS,
      acctMgr: new ProcessStatusProps({
        rt: {
          cardLabel: 'Review the candidate',
          processLabel: 'Review if the submitted talent is a fit for the job'
        },
        cr: {
          cardLabel: 'Review the candidate',
          processLabel: 'Review if the submitted talent is a fit for the job'
        },
        lastProcessStatus: ProcessStatus.RA_WAITING_SS_FEEDBACK,
        actionRequired: true
      }),
      actionRequired: true
    }),
    [ProcessStatus.RA_WAITING_SS_FEEDBACK]: new ProcessStatusProps({
      rt: {
        cardLabel: 'FOLLOW UP\nWaiting for solutions feedback',
        processLabel: 'Waiting for feedback from the solutions support'
      },
      cr: {
        cardLabel: 'FOLLOW UP\nWaiting for solutions feedback',
        processLabel: 'Waiting for feedback from the solutions support'
      },
      lastProcessStatus: ProcessStatus.RA_WAITING_SS_FEEDBACK,
      acctMgr: new ProcessStatusProps({
        rt: {
          cardLabel: 'Review the candidate',
          processLabel: 'Review if the submitted talent is a fit for the job'
        },
        cr: {
          cardLabel: 'Review the candidate',
          processLabel: 'Review if the submitted talent is a fit for the job'
        },
        lastProcessStatus: ProcessStatus.RA_WAITING_SS_FEEDBACK,
        actionRequired: true
      })
    }),

    [ProcessStatus.SS_REVIEW_COMPLETED]: new ProcessStatusProps({
      rt: {
        cardLabel: 'Waiting for solutions feedback',
        processLabel: 'Waiting for feedback from the solutions support'
      },
      cr: {
        cardLabel: 'Waiting for solutions feedback',
        processLabel: 'Waiting for feedback from the solutions support'
      },
      lastProcessStatus: ProcessStatus.SS_REVIEW_COMPLETED,
      actionRequired: true
    })
  },
  [ProcessStep.REVIEW_HM]: {
    [ProcessStatus.RH_SUBMIT_TO_HM]: new ProcessStatusProps({
      common: {
        cardLabel: 'Submission to hiring manager',
        processLabel: 'Prepare submission to hiring manager.'
      },
      lastProcessStatus: ProcessStatus.RH_SUBMIT_TO_HM,
      actionRequired: true,
      filterProps: {
        pillText: 'Ready to submit',
        status: [ProcessStatus.RH_SUBMIT_TO_HM],
        step: ProcessStep.REVIEW_HM,
        visibleDashboard: true,
        visibleShortlist: false
      }
    }),
    [ProcessStatus.SS_SUBMIT_TO_HM]: new ProcessStatusProps({
      common: {
        cardLabel: 'Submission to hiring manager',
        processLabel: 'Prepare submission to hiring manager.'
      },
      lastProcessStatus: ProcessStatus.SS_SUBMIT_TO_HM,
      actionRequired: true
    }),
    [ProcessStatus.RH_AWAITING_CLIENT_REVIEW]: new ProcessStatusProps({
      common: {
        cardLabel: 'FOLLOW UP\nWaiting for client\'s feedback',
        processLabel: ({ submissionDatetime }) =>
          `Waiting for the client to review the submitted candidate.\nSubmission sent\nCandidate details were submitted to the Hiring manager on ${submissionDatetime}`
      },
      lastProcessStatus: ProcessStatus.RH_AWAITING_CLIENT_REVIEW,
      filterProps: {
        pillText: 'Ready to review',
        status: [ProcessStatus.RH_AWAITING_CLIENT_REVIEW],
        step: ProcessStep.REVIEW_HM,
        visibleDashboard: true,
        visibleShortlist: false
      }
    }),
    [ProcessStatus.RH_UPDATE_SUB]: new ProcessStatusProps({
      common: {
        cardLabel: 'Update Submission',
        processLabel: 'The hiring manager requested an update of your submission'
      },
      lastProcessStatus: ProcessStatus.RH_AWAITING_CLIENT_REVIEW,
      actionRequired: true,
      filterProps: {
        pillText: 'Ready to review',
        status: [ProcessStatus.RH_AWAITING_CLIENT_REVIEW],
        step: ProcessStep.REVIEW_HM,
        visibleDashboard: true,
        visibleShortlist: false
      }
    }),
    [ProcessStatus.RH_AWAITING_CLIENT_BACKUP_REVIEW]: new ProcessStatusProps({
      common: {
        cardLabel: 'FOLLOW UP\nWaiting for client\'s feedback',
        processLabel: ({ submissionDatetime }) =>
          `Waiting for the client to review the submitted candidate.\nSubmission sent\nCandidate details were submitted to the Hiring manager on ${submissionDatetime}`
      },
      lastProcessStatus: ProcessStatus.RH_AWAITING_CLIENT_BACKUP_REVIEW
    }),
    [ProcessStatus.RH_AM_PICK_BACKUP]: new ProcessStatusProps({
      common: {
        cardLabel: 'Make decision on backup candidate',
        processLabel: 'The candidate has been marked as a Backup candidate'
      },
      lastProcessStatus: ProcessStatus.RH_AM_PICK_BACKUP,
      actionRequired: true
    }),
    [ProcessStatus.RH_RECRUITER_PICK_BACKUP]: new ProcessStatusProps({
      rt: {
        cardLabel: 'FOLLOW UP\nMake decision on backup candidate',
        processLabel: 'The candidate has been marked as a Backup candidate'
      },
      cr: {
        cardLabel: 'FOLLOW UP\nMake decision on backup candidate',
        processLabel: 'The candidate has been marked as a Backup candidate'
      },
      lastProcessStatus: ProcessStatus.RH_RECRUITER_PICK_BACKUP
    }),
    [ProcessStatus.RH_NOT_A_FIT]: new ProcessStatusProps({
      rt: {
        cardLabel: 'Not a fit',
        processLabel: ({ candidateFullName, rejectedTimestamp, userFullName, jobTitle }) =>
          `${candidateFullName} was rejected for the job ${jobTitle} by ${userFullName} on ${rejectedTimestamp}.\n${candidateFullName} was rejected in this phase.`
      },
      cr: {
        cardLabel: 'Not a fit',
        processLabel: ({ candidateFullName, rejectedTimestamp, userFullName, jobTitle }) =>
          `${candidateFullName} was rejected for the job ${jobTitle} by ${userFullName} on ${rejectedTimestamp}.\n${candidateFullName} was rejected in this phase.`
      },
      lastProcessStatus: ProcessStatus.RH_NOT_A_FIT
    })
  },
  [ProcessStep.INTERVIEW_HM]: {
    [ProcessStatus.IH_SCHEDULE_INTERVIEW]: new ProcessStatusProps({
      rgs: {
        cardLabel: 'Schedule a meeting with the client',
        processLabel: 'Log the date, time and any relevant information about the interview between the candidate and the client'
      },
      rt: {
        cardLabel: 'Plan client interview or reject candidate',
        processLabel: 'Log the date, time and any relevant information about the interview between the candidate and the client or reject the candidate'
      },
      cr: {
        cardLabel: 'Plan client interview or reject candidate',
        processLabel: 'Log the date, time and any relevant information about the interview between the candidate and the client or reject the candidate'
      },
      lastProcessStatus: ProcessStatus.IH_SCHEDULE_INTERVIEW,
      actionRequired: true,
      filterProps: {
        pillText: 'Plan',
        status: [ProcessStatus.IH_SCHEDULE_INTERVIEW],
        step: ProcessStep.REVIEW_HM,
        visibleDashboard: true,
        visibleShortlist: false
      }
    }),
    [ProcessStatus.IH_INTERVIEW_SCHEDULED]: new ProcessStatusProps({
      rgs: {
        cardLabel: 'STAND BY\nWaiting for the meeting to take place',
        processLabel: 'Log the date, time and any relevant information about the meeting between the candidate and the client'
      },
      rt: {
        cardLabel: 'FOLLOW UP\nWaiting for the client interview to take place',
        processLabel: 'Log the date, time and any relevant information about the meeting between the candidate and the client'
      },
      cr: {
        cardLabel: 'FOLLOW UP\nWaiting for the client interview to take place',
        processLabel: 'Log the date, time and any relevant information about the meeting between the candidate and the client'
      },
      lastProcessStatus: ProcessStatus.IH_INTERVIEW_SCHEDULED,
      filterProps: {
        pillText: 'Scheduled',
        status: [ProcessStatus.IH_INTERVIEW_SCHEDULED],
        step: ProcessStep.REVIEW_HM,
        visibleDashboard: true,
        visibleShortlist: false
      }
    }),
    [ProcessStatus.IH_UPDATE_SUB]: new ProcessStatusProps({
      rgs: {
        cardLabel: 'STAND BY\nWaiting for the meeting to take place',
        processLabel: 'Log the date, time and any relevant information about the meeting between the candidate and the client'
      },
      rt: {
        cardLabel: 'FOLLOW UP\nWaiting for the submission update',
        processLabel: 'Log the date, time and any relevant information about the meeting between the candidate and the client'
      },
      cr: {
        cardLabel: 'FOLLOW UP\nWaiting for the submission update',
        processLabel: 'Log the date, time and any relevant information about the meeting between the candidate and the client'
      },
      lastProcessStatus: ProcessStatus.IH_UPDATE_SUB,
      filterProps: {
        pillText: 'Scheduled',
        status: [ProcessStatus.IH_UPDATE_SUB],
        step: ProcessStep.REVIEW_HM,
        visibleDashboard: true,
        visibleShortlist: false
      }
    }),
    [ProcessStatus.IH_INTERVIEW_COMPLETED]: new ProcessStatusProps({
      rgs: {
        cardLabel: 'Log meeting feedback',
        processLabel: ({ interviewDatetime, interviewLocation }) =>
          `The hiring manager will interview the talent.\nInterview scheduled\n${interviewDatetime}\n${interviewLocation}`
      },
      rt: {
        cardLabel: 'Waiting for the client interview to take place',
        processLabel: ({ interviewDatetime, interviewLocation }) =>
          `The hiring manager will interview the talent.\nInterview scheduled\n${interviewDatetime}\n${interviewLocation}`
      },
      cr: {
        cardLabel: 'Waiting for the client interview to take place',
        processLabel: ({ interviewDatetime, interviewLocation }) =>
          `The hiring manager will interview the talent.\nInterview scheduled\n${interviewDatetime}\n${interviewLocation}`
      },
      lastProcessStatus: ProcessStatus.IH_INTERVIEW_COMPLETED,
      actionRequired: true
    }),
    [ProcessStatus.IH_FEEDBACK_ADDED]: new ProcessStatusProps({
      common: {
        cardLabel: 'FOLLOW UP\nAwaiting Client Decision',
        processLabel: 'Waiting for the client to request offer or reject candidate'
      },
      lastProcessStatus: ProcessStatus.IH_FEEDBACK_ADDED,
      filterProps: {
        pillText: 'Completed',
        status: [ProcessStatus.IH_FEEDBACK_ADDED],
        step: ProcessStep.REVIEW_HM,
        visibleDashboard: true,
        visibleShortlist: false
      }
    }),
    [ProcessStatus.IH_NOT_A_FIT]: new ProcessStatusProps({
      common: {
        cardLabel: 'Not a fit',
        processLabel: ({ candidateFullName, jobTitle, userFullName, rejectedTimestamp }) =>
          `${candidateFullName} was rejected for the job ${jobTitle} by ${userFullName} on ${rejectedTimestamp}.\n${candidateFullName} was rejected in this phase`
      },
      lastProcessStatus: ProcessStatus.IH_NOT_A_FIT
    })
  },
  [ProcessStep.OFFER]: {
    [ProcessStatus.O_SENT]: new ProcessStatusProps({
      rgs: {
        cardLabel: 'Take Steps to Hire',
        processLabel: 'Send an offer for the job to the candidate in your front office system, and once feedback has been received, please log it here.'
      },
      rt: {
        cardLabel: 'Extend offer',
        processLabel: 'Extend offer'
      },
      cr: {
        cardLabel: 'Extend offer',
        processLabel: 'Extend offer'
      },
      lastProcessStatus: ProcessStatus.O_SENT,
      actionRequired: true,
      filterProps: {
        pillText: 'Extended',
        status: [ProcessStatus.O_SENT],
        step: ProcessStep.OFFER,
        visibleDashboard: true,
        visibleShortlist: false
      }
    }),
    [ProcessStatus.O_ACCEPTED]: new ProcessStatusProps({
      common: {
        cardLabel: 'Offer accepted',
        processLabel: 'This talent has completed all the process steps. Onboarding steps can be completed in your front office system.'
      },
      lastProcessStatus: ProcessStatus.O_ACCEPTED,
      actionRequired: true,
      filterProps: {
        pillText: 'Accepted',
        status: [ProcessStatus.O_ACCEPTED],
        step: ProcessStep.OFFER,
        visibleDashboard: true,
        visibleShortlist: false
      }
    }),
    [ProcessStatus.O_REJECTED]: new ProcessStatusProps({
      common: {
        cardLabel: 'Offer rejected',
        processLabel: ({ rejectedTimestamp, candidateFullName }) =>
          `Offer was rejected by ${candidateFullName} on ${rejectedTimestamp}`
      },
      lastProcessStatus: ProcessStatus.O_REJECTED,
      actionRequired: true
    })
  }
};

/**
 * Redo of filters to allow for multiple filters for a single ProcessStatus
 * @type {Map<ProcessStep, ShortlistFilterProps[]>}
 */
export const TALENT_DASHBOARD_STATUS_METRICS = new Map<ProcessStep, ShortlistFilterProps[]>();
// Reason for having the redundant step is ease of object comparision used in Set operations
TALENT_DASHBOARD_STATUS_METRICS.set(ProcessStep.PRESCREENING,  [
  {dashboardText: 'not started', pillText: 'not started', status: [ProcessStatus.P_START], step: ProcessStep.PRESCREENING, visibleDashboard: true, visibleShortlist: true},
  {dashboardText: 'invitation sent', pillText: 'invitation sent', status: [ProcessStatus.P_SENT], step: ProcessStep.PRESCREENING, visibleDashboard: true, visibleShortlist: true},
  {dashboardText: 'in progress', pillText: 'in progress', status: [ProcessStatus.P_IN_PROGRESS], step: ProcessStep.PRESCREENING, visibleDashboard: true, visibleShortlist: true},
  {dashboardText: 'ready for review', pillText: 'ready for review', status: [ProcessStatus.P_REVIEW_QUESTIONS], step: ProcessStep.PRESCREENING, displayWarning: true, visibleDashboard: true, visibleShortlist: true}
]);
TALENT_DASHBOARD_STATUS_METRICS.set(ProcessStep.INTERVIEW_RECRUITER,  [
  {dashboardText: 'not completed', pillText: 'not completed', status: [ProcessStatus.IR_PLAN_INTERVIEW], step: ProcessStep.INTERVIEW_RECRUITER, visibleDashboard: true, visibleShortlist: true},
  {dashboardText: 'scheduled', pillText: 'scheduled', status: [ProcessStatus.IR_INTERVIEW_SCHEDULED], step: ProcessStep.INTERVIEW_RECRUITER, visibleDashboard: true, visibleShortlist: true },
  {dashboardText: 'invitation sent', pillText: 'invitation sent', status: [ProcessStatus.IR_AWAITING_RESPONSE], step: ProcessStep.INTERVIEW_RECRUITER, visibleDashboard: true, visibleShortlist: true},
  {dashboardText: 'issue scheduling', pillText: 'issue scheduling', status: [ProcessStatus.IR_RESCHEDULE_INCONVENIENT_INTERVIEW,
                    ProcessStatus.IR_RESCHEDULE_DECLINED_INTERVIEW], step: ProcessStep.INTERVIEW_RECRUITER, displayWarning: true, visibleDashboard: true, visibleShortlist: true},
  {dashboardText: 'cancelled', pillText: 'cancelled', status: [ProcessStatus.IR_INTERVIEW_CANCELLED],
            step: ProcessStep.INTERVIEW_RECRUITER, displayWarning: true, visibleDashboard: true, visibleShortlist: true},
  {dashboardText: 'completed', pillText: 'completed', status: [ProcessStatus.IR_CONSIDER_RESULTS], step: ProcessStep.INTERVIEW_RECRUITER, visibleDashboard: true, visibleShortlist: true}
]);
TALENT_DASHBOARD_STATUS_METRICS.set(ProcessStep.REVIEW_AM,  [
  {dashboardText: 'recruiter submission pending', pillText: 'ready to submit',  status: [ProcessStatus.RA_SUBMIT_TO_AM,
        ProcessStatus.RA_SUBMIT_TO_SS], step: ProcessStep.REVIEW_AM, visibleDashboard: true, visibleShortlist: false},
  {dashboardText: 'waiting for review', pillText: 'ready to review', status: [ProcessStatus.RA_WAITING_AM_FEEDBACK, ProcessStatus.RA_AM_UPDATE_SUB,
    ProcessStatus.RA_WAITING_SS_FEEDBACK, ProcessStatus.SS_REVIEW_COMPLETED], step: ProcessStep.REVIEW_AM, visibleDashboard: true, visibleShortlist: false}
]);
TALENT_DASHBOARD_STATUS_METRICS.set(ProcessStep.REVIEW_HM,  [
  {dashboardText: 'ready to submit', pillText: 'ready to submit',  status: [ProcessStatus.RH_SUBMIT_TO_HM], step: ProcessStep.REVIEW_HM, visibleDashboard: true, visibleShortlist: false},
  {dashboardText: 'waiting for client feedback', pillText: 'ready to review', status: [ProcessStatus.RH_AWAITING_CLIENT_REVIEW, ProcessStatus.RH_UPDATE_SUB],
          step: ProcessStep.REVIEW_HM, visibleDashboard: true, visibleShortlist: false}
]);
TALENT_DASHBOARD_STATUS_METRICS.set(ProcessStep.INTERVIEW_HM,  [
  {dashboardText: 'plan client interview', pillText: 'plan',  status: [ProcessStatus.IH_SCHEDULE_INTERVIEW], step:  ProcessStep.INTERVIEW_HM, visibleDashboard: true, visibleShortlist: false},
  {dashboardText: 'scheduled', pillText: 'scheduled', status: [ProcessStatus.IH_INTERVIEW_SCHEDULED], step: ProcessStep.INTERVIEW_HM, visibleDashboard: true, visibleShortlist: false},
  {dashboardText: 'waiting for client decision', pillText: 'completed', status: [ProcessStatus.IH_FEEDBACK_ADDED, ProcessStatus.IH_UPDATE_SUB], step: ProcessStep.INTERVIEW_HM, visibleDashboard: true, visibleShortlist: false}
]);
TALENT_DASHBOARD_STATUS_METRICS.set(ProcessStep.OFFER,  [
  {dashboardText: 'offer extended', pillText: 'extended',  status: [ProcessStatus.O_SENT], step: ProcessStep.OFFER, visibleDashboard: true, visibleShortlist: false},
  {dashboardText: 'offer accepted', pillText: 'accepted',  status: [ProcessStatus.O_ACCEPTED], step: ProcessStep.OFFER, visibleDashboard: true, visibleShortlist: false}
]);

/**
 * Similar to TALENT_SASHBOARD_STATUS_METRICS but this is based on onboarding
 * @type {Map<OnboardingTypeKey, OnboardingTypeKey>}
 */
export const TALENT_DASHBOARD_ONBOARDING_METRICS = new Map<OnboardingTypeKey, OnboardingFilterProps>();
TALENT_DASHBOARD_ONBOARDING_METRICS.set(OnboardingTypeKey.NOT_STARTED, {dashboardText: 'Start onboarding', pillText: 'Not Started', status: ['not_started'], visibleDashboard: true, visibleShortlist: false});
TALENT_DASHBOARD_ONBOARDING_METRICS.set(OnboardingTypeKey.STARTED, {dashboardText: 'Complete onboarding', pillText: 'Started', status: ['started'], visibleDashboard: true, visibleShortlist: false});
TALENT_DASHBOARD_ONBOARDING_METRICS.set(OnboardingTypeKey.COMPLETE, {dashboardText: 'Onboarding finished', pillText: 'Completed', status: ['complete'], visibleDashboard: true, visibleShortlist: false});


/**
 * Similar to TALENT_DASHBOARD_STATUS_METRICS but this is based on unanswered
 * @type {Map<UnansweredTypeKey, OnboardingTypeKey>}
 */
 export const TALENT_DASHBOARD_UNANSWERED_METRICS = new Map<UnansweredTypeKey, OnboardingFilterProps>();
 TALENT_DASHBOARD_UNANSWERED_METRICS.set(UnansweredTypeKey.UNA_NOT_CONTACTED, {dashboardText: 'not contacted', pillText: 'not contacted', status: ['una_not_contacted'], visibleDashboard: true, visibleShortlist: false});
 TALENT_DASHBOARD_UNANSWERED_METRICS.set(UnansweredTypeKey.UNA_CONTACTED, {dashboardText: 'contacted', pillText: 'contacted', status: ['una_contacted'], visibleDashboard: true, visibleShortlist: false});
