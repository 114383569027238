import { Application } from './../../../../../models/external/application.model';
import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Profile } from 'src/app/shared/models/external/profile.model';
import { ListTalent } from '../../../../../models/external/list-talent.model';
import { JobManagementService } from '../../../../../services/job-management/job-management.service';
import { Card, CardType } from '../../../../../models/internal/card.model';
import { ApiService } from '../../../../../services/api/api.service';
import { ToastClass, ToastService } from '../../../../../services/toast';
import { LoadingSpinnerService } from '../../../../../services/loading-spinner/loading-spinner.service';
import { Job } from '../../../../../models/external/job.model';
import { JobDetailsShortlistService } from 'src/app/shared/services/job-details-shortlist/job-details-shortlist.service';
import { CLIENT_EVENT_TYPES } from '../../../../../services/client-event/client-event.types';
import { JobDetailsService } from 'src/app/shared/services/job-details/job-details.service';
import { interval, noop } from 'rxjs';
import { MASS_OPERATION_CONTEXT } from 'src/app/shared/components/mass-email-modal/mass-operation-context';
import { MassActionService } from 'src/app/shared/services/mass-action/mass-action.service';
import { RecentActivityListCardDetails } from 'src/app/shared/models/internal/recent-activities-list.model';
import { AuthService } from '../../../../../services/auth/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-to-job',
  templateUrl: './add-to-job.component.html',
  styleUrls: ['./add-to-job.component.scss'],
  styles: [
      `
      :host(.tooltip-inner) {
        background-color: #0E1841;
        color: white;
        max-width: 350px !important;
        font-size: 14px;
      }

      :host(.tooltip.top .tooltip-arrow:before,
      .tooltip.top .tooltip-arrow) {
        border-top-color: #0E1841;
      }
    `
  ]
})
export class AddToJobComponent implements OnInit, OnDestroy {

  loading = true;
  talent: Profile;
  cards: Card[];
  selectedCard: Card;
  jobTitle: any;
  jobId: any;
  clientName: any;
  shortlistedCache: { [key: string]: boolean } = {};
  dummyCard: Card = new Card({});
  categoryDropDown = 1;
  trVariable1: any;
  cardEndIndex = 20;
  cardStartIndex = 0;
  lastScrollTop = 0;
  isBottom = false;
  secondsCounter = interval(200);

  /**
   * The context enables looking at different data points for mass actions
   * The various contexts has their own inputs that can be
   * looked up from the mass-action-service.
   * To add profiles to shortlist for job -- Need profiles and jobs. Once that list is obtained
   * the rest of the operations are the same regardless of the context.
   */
  context: MASS_OPERATION_CONTEXT;
  /**
   * List of talent from a list to add to a job
   * @type {{[p: string]: ListTalent}}
   */
  talentList: {[p: string]: ListTalent};
  /**
   * true if this is for a mass action
   */
  isMassAddToJob: boolean = false;
  /**
   * Array of google_ids that are NOT already in the
   * selected job's shortlist
   * @type {string[]}
   */
  notShortlistedTalentIds: string[] = [];
  /**
   * The tooltip for the selected/disabled card
   * @type {string}
   */
  selectedCardTooltip: string;
  /**
   * Method passed in the initialState to handle clicking
   * the shortlist button
   * @type {Function}
   */
  shortlistHandler: any;
  isItBottom: number;


  constructor(public _bsModalRef: BsModalRef,
              private toastService: ToastService,
              private _api: ApiService,
              private _loading: LoadingSpinnerService,
              private _jobManagement: JobManagementService,
              private _shortlist: JobDetailsShortlistService,
              private injector: Injector,
              private _loadingSpinner: LoadingSpinnerService,
              private _massAction: MassActionService,
              private _auth: AuthService,
              private _translate: TranslateService
  ) { }

  ngOnInit() {
    this.loading = true;
    this._jobManagement.updateAddToTalentParams({ excludeInProgress: true }, 'myJobs');
    this._jobManagement.jobTalentSearchData.subscribe((data) => {
      if (data && Array.isArray(data)) {
        this.cards = data.map((jobOrder) => new Card(jobOrder, false, CardType.TALENT_ADD_TO_JOB));
      }
      this.loading = false;
    });
    if (this.talent && !this.isMassAddToJob) {
      this.trVariable1 = {
        'value1': this.talent.personNames[0].structuredName.givenName,
        'value2': this.talent.personNames[0].structuredName.familyName
      };
    }
    window.addEventListener('scroll', this.onWindowScroll.bind(this), true); //third parameter
  }

  ngOnDestroy() {
    this._jobManagement.destroyAddToTalentParams();
    window.removeEventListener('scroll', this.onWindowScroll, true);
  }

  setSelectedCard($event: Card) {
    if (this.cacheCheck($event.job_order)) { // do not do anything if we already know that the candidate shortlisted for job
      // check if some how current selected job is the shortlisted/wrong one
      if ($event.job_order.name === (this.selectedCard || this.dummyCard).job_order.name) {
        this.selectedCard.selected = false; // remove the selection
        this.selectedCard = null; // remove the wrong selected card
      }
      return;
    }
    // clicked on same selected card, unselect and return
    if (this.selectedCard && $event.job_order.name === (this.selectedCard || this.dummyCard).job_order.name) {
      this.selectedCard.selected = false;
      this.selectedCard = null;
      return;
    } else if (this.selectedCard) { // clicked on a new card, unselect the current one and continue with checks
      this.selectedCard.selected = false;
      this.selectedCard = null;
    }
    // make call to backend to check if the current talent shortlisted for this job already
    this.checkShortlisted($event);
  }

  /**
   * Check if the selected talent or talents are already in a shortlist
   * @param {Card} $event
   */
  checkShortlisted($event: Card) {

    this._loading.show();
    if (this.talent && !this.isMassAddToJob) {
      this._api.checkShortlisted($event.job_order.allbirds_metadata.allbirds_job_id, this.talent.name)
        .subscribe(response => {
          if (response.check && !response.isRejectedPotential) { // already shortlisted, put in cache
            this.shortlistedCache[$event.job_order.allbirds_metadata.allbirds_job_id + this.talent.name] = true;
            this.selectedCardTooltip = 'The candidate is already shortlisted for this job';
          } else {
            this.selectedCard = $event; // set the selected card
            this.selectedCard.selected = true; // select the card for css
          }
          this._loading.hide();
        });
    } else if (this.isMassAddToJob) {
      let profileIds: string[] = [];

      if(this.context === MASS_OPERATION_CONTEXT.pipeline) {
        profileIds = Object.keys(this.talentList);
      }
      if(this.context === MASS_OPERATION_CONTEXT.recentActivities) {
        const selectedActs = this._massAction.selectedRecentActivities;
        Object.keys(selectedActs).forEach(function(key) {
          const card: RecentActivityListCardDetails = selectedActs[key];
          card.profile && card.profile.name ? profileIds.push(card.profile.name) : noop;
        });

      }
      if(this.context === MASS_OPERATION_CONTEXT.application) {
        const selectedActs = this._massAction.selectedApplications;
        Object.keys(selectedActs).forEach(function(key) {
          const app: Application = selectedActs[key];
          app.profile ? profileIds.push(app.profile) : noop;
        });
      }
      if(this.context === MASS_OPERATION_CONTEXT.modelsPotential) {
        const selectedActs = this._massAction.selectedProfiles;
        Object.keys(selectedActs).forEach(function(key) {
          const app: Profile = selectedActs[key];
          app.name ? profileIds.push(app.name) : noop;
        });
      }

      this._api.checkManyShortlisted($event.job_order.allbirds_metadata.allbirds_job_id, profileIds)
        .subscribe(response => {
          //console.log('response ', response.check);
          const shortlistedTalentIds = profileIds.filter(id => response.check.indexOf(id) > -1);
          //console.log('shortlistedTalentIds', shortlistedTalentIds)
          const countInShortlist = shortlistedTalentIds.length;
          const notShortlistedTalentIds = profileIds.filter(id => response.check.indexOf(id) === -1);
          const countNotInShortlist = notShortlistedTalentIds.length;
          // already shortlisted
          if (shortlistedTalentIds && shortlistedTalentIds.length) {
            // Only put in cache IF countInShortlist === profileIds.length. Disables the card and NOT the tooltip
            if (countInShortlist === profileIds.length) {
              this.shortlistedCache[$event.job_order.allbirds_metadata.allbirds_job_id] = true;
            }
          }
          // set the selected card
          if (notShortlistedTalentIds && notShortlistedTalentIds.length) {
            this.selectedCard = $event;
            this.selectedCard.selected = true;
            this.notShortlistedTalentIds = notShortlistedTalentIds;
          }
          // Add a label to the card
          const cardListCard = this.cards.find(card => card.job_order.allbirds_metadata.allbirds_job_id === $event.job_order.allbirds_metadata.allbirds_job_id);
          if (cardListCard) {
            cardListCard.label = this.getToolTipString(countNotInShortlist, countInShortlist);
          }
          this._loading.hide();
        });
    }
  }

  getToolTipString(countNotInShortlist: number, countInShortlist: number) {
    let toolTip = '';
    toolTip += countNotInShortlist > 1 ?  this._translate.instant('add-to-job.add_many',  { 'value1': countNotInShortlist }  ) + ' '
            : this._translate.instant('add-to-job.add_one',  { 'value1': countNotInShortlist }  ) + ' ';
    toolTip += countInShortlist > 1 ? this._translate.instant('add-to-job.already_many',  { 'value1': countInShortlist }  )
            : this._translate.instant('add-to-job.already_one',  { 'value1': countInShortlist }  );
    return toolTip;
  }

  cacheCheck(job: Job): boolean {
    if (this.talent && !this.isMassAddToJob) {
      return this.shortlistedCache[job.allbirds_metadata.allbirds_job_id + this.talent.name];
    } else if (this.isMassAddToJob) {
      // This is only relevant for disabling the card NOT the tooltip
      // and should only be here if the countInShortlist === talentListCount
      return this.shortlistedCache[job.allbirds_metadata.allbirds_job_id];
    }
    return false;
  }

  search() {
    this.loading = true;
    this.cardEndIndex = 20;
    this.isBottom = false;
    this.lastScrollTop = 0;
    if (this.clientName || this.jobTitle || this.jobId) {
      this.categoryDropDown = 2;
      this._jobManagement.updateAddToTalentParams(
        { excludeInProgress: true, jobTitle: this.jobTitle, clientName: this.clientName, jobId: this.jobId }, 'all');
    } else {
      this.categoryDropDown = 1;
      this._jobManagement.updateAddToTalentParams({ excludeInProgress: true }, 'myJobs');
    }
  }

  /**
   * Adds a talent to the shortlist.
   */
  shortlist(): any {
    if (!this.selectedCard) {
      this.toastService.showToast('add-to-job.select_job', { cssClass: ToastClass.DANGER });
      return;
    }
    const job = this.selectedCard.job_order;
    const body: any = {
      ...this.talent,
      ids: {
        google_job_id: job.name,
        front_office_id: job.allbirds_metadata.front_office_id,
        allbirds_job_id: job.allbirds_metadata.allbirds_job_id,
        job_customer_id: job.allbirds_metadata.customer_id,
        job_contact_id: job.allbirds_metadata.contact_id,
        job_user_branch_id: job.allbirds_metadata.user_branch_id
      }
    };

    if (!JobDetailsShortlistService.opcoDoesMatch(this.talent, job, this._auth.user)) {
      this.toastService.showToast('profile.job-lob-mismatch', { cssClass: ToastClass.DANGER });
    }

    this._api.addToShortlist(body)
      .subscribe((res: any) => {
        if (res) {
          this._bsModalRef.hide();
          this.toastService.showToast(`${this.talent.personNames[0].structuredName.givenName} ${this.talent.personNames[0].structuredName.familyName} has been shortlisted.`, {});
          const _jobDetails = this.injector.get(JobDetailsService);
          if (_jobDetails) _jobDetails.addToJobModalJob = job;
          this._shortlist.emitClientEvent(CLIENT_EVENT_TYPES.MODAL_PROFILE_SHORTLIST, this.talent);
          this.refreshShortlist(job);
        } else {
          this.toastService.showToast('add-to-job.attempt_shortlist', {});
        }
      }, err => {
        console.log('[shortlist]', err);
        this.toastService.showToast('add-to-job.error_shortlist', {});
      });
  }

  /**
   * If isMassAddToJob then call the passed in handler at this.shortlistHandler
   * otherwise keep the original functionality of calling this.shortlist
   */
  handleShortlistClick() {
    if (!this.selectedCard) {
      this.toastService.showToast('add-to-job.select_job', { cssClass: ToastClass.DANGER });
      return;
    }
    if (this.shortlistHandler && this.isMassAddToJob) {
      this.shortlistHandler(this.selectedCard, this.notShortlistedTalentIds);
    } else {
      this.shortlist();
    }
  }

  onWindowScroll($event: any) {

    const target = $event.target as HTMLElement;
    // this tells you if you reached the bottom of a div. If it is bottom it will be 0 or less than 0
    this.isItBottom = target.scrollHeight - target.scrollTop - target.clientHeight;

    if (this.isItBottom < 1 && !this.isBottom && this.cards.length > this.cardEndIndex) {
      this.cardEndIndex += 20;
      this.isBottom = true;
      this._loadingSpinner.show();
      const sub = this.secondsCounter.subscribe(n => {
        if ( target.scrollHeight - target.scrollTop - target.clientHeight > 0 ) {
          this._loadingSpinner.hide();
          sub.unsubscribe();
        }
      });
    } else {
      this.isBottom = false;
    }
  }

  /**
   * Called if the add to job action was performed from the job details page of the
   * job we're adding the talent to. Refreshes the shortlist.
   * @param job
   */
  refreshShortlist(job: Job): void {
    const _router = this.injector.get(Router);
    if (
      job.allbirds_metadata &&
      job.allbirds_metadata.allbirds_job_id &&
      _router.url.includes('jobs') &&
      _router.url.includes(job.allbirds_metadata.allbirds_job_id)
    ) {
      this._shortlist.initialize(job);
    }
  }
}
