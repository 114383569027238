<ng-container *ngIf="job && job.allbirds_metadata">
  <tabset class="tabset" #tabSet [justified]="true">
    <tab id="job_details" (selectTab)="tabSelect('job_details')">
      <ng-template lass="text-center" tabHeading>
        <div class="text-center">
          <h3>{{'job-details-pane.job_details' | translate}}</h3>
        </div>
      </ng-template>
      <div class="row" style="margin-top:-12px">
        <div class="col-12 text-right small" style="color: var(--color-red)">
          {{'job-details-pane.required_fields' | translate}}<img class="required_bubble" src="../../../assets/icons/gamification-red.svg"   />
        </div>
      </div>
      <div id="job-info-section">
        <!-- Job Information Section -->
        <i class="icon icon-WL_icon_edit circle float-right" (click)="goTo('#openings')"></i>
        <h2 class="mt-0">{{'job-details-pane.job_details' | translate}}</h2>
        <div *ngIf="isClientOrder" class="mt-3">
          <h4>{{'job-details-pane.client_job_description' | translate}}</h4>
          <h4 class="text-gray" [innerHTML]="job?.internalDescription" data-puppeteer="client-job-description"></h4>
        </div>
        <div class="mt-3">
          <h4>{{'job-details-pane.job_id' | translate}}</h4>
          <div *ngIf="!job?.allbirds_metadata?.front_office_id" class="custom-spinner spinner-border text-primary" role="status">
            <span class="sr-only text-center">{{'job-details-pane.loading' | translate}}</span>
          </div>
          <h4 *ngIf="job?.allbirds_metadata?.front_office_id" class="text-gray">
            <a *ngIf="jobLob.checkLob('RT', 'RE','CR')" (click)="gotoBullhorn()" data-puppeteer="job-details-id" href="#" role="button" (click)="preventDefault($event)">{{job.allbirds_metadata.front_office_id}}</a>
            <a *ngIf="jobLob.checkLob('RGS')" (click)="gotoRfo()" data-puppeteer="job-details-id" href="#" role="button" (click)="preventDefault($event)">{{job.allbirds_metadata.front_office_id}}</a>
          </h4>
        </div>
        <div class="mt-3">
          <h4>{{'job-details-pane.job_status' | translate}}</h4>
          <h4 class="text-gray">{{orderStatus || job.allbirds_metadata.order_status}}</h4>
        </div>
        <div *ngIf="isClientOrder" class="mt-3">
          <h4>{{'job-details-pane.vms_id' | translate}}</h4>
          <h4 class="text-gray">{{job.allbirds_metadata.vms_req_id || '-' }}</h4>
        </div>
        <div *ngIf="isClientOrder" class="mt-3">
          <h4>{{'job-details-pane.vms_status' | translate}}</h4>
          <h4 class="text-gray">{{vmsStatus || '-' }}</h4>
        </div>
        <ng-container *ngIf="jobLob.checkLob('RT', 'RE','CR')">
          <div *ngIf="job.allbirds_metadata.linked_ab_job_id || job.allbirds_metadata.number_of_linked_jobs" class="mt-3">
            <div class="row">
              <div class="col-3">
                <h4>Linked Jobs - ({{linkedJobs.length}})</h4>
              </div>
              <ng-container *ngIf="linkedJobs.length > 3">
                <div class="col-3 ml-auto faux-link" *ngIf="linkedJobDefaultLimit != linkedJobs.length" (click)="linkedJobDefaultLimit = linkedJobs.length">
                  Show more ({{linkedJobs.length - 3}})
                </div>
                <div class="col-3 ml-auto faux-link" *ngIf="linkedJobDefaultLimit == linkedJobs.length" (click)="linkedJobDefaultLimit = 3">
                  Show less
                </div>
              </ng-container>

            </div>

            <div class="flex-container" style="font-size: 14px">
              <div class="d-flex w-100 border-bottom no-gutters cursor-pointer" *ngFor="let linkedJob of linkedJobs | slice: 0 : linkedJobDefaultLimit; let i = index;" (click)="navigateToJob(linkedJob.allbirds_metadata.allbirds_job_id)">
                <div class="col-2">
                  <img alt="child-prompt" src="./assets/icons/child-prompt.svg" class="p-2" /> <ng-container>{{linkedJob.allbirds_metadata.order_of_linked_job}} / {{linkedJob.allbirds_metadata.number_of_linked_jobs}}</ng-container>
                </div>
                <div class="col-1 text-center justify-content-center align-self-center">
                  {{linkedJob.allbirds_metadata.front_office_id}}
                </div>
                <div class="col-4 text-center justify-content-center align-self-center">
                  {{linkedJob.title}}
                </div>
                <div class="col-2 ml-auto text-center justify-content-center align-self-center">
                  {{linkedJob.allbirds_metadata.order_status}}
                </div>
                <div class="col-1 ml-auto text-center justify-content-center align-self-center" >
                  <app-user-circle
                    *ngIf="linkedJob?.allbirds_metadata?.published_by_user_back_office_id"
                    [user]="getUser(linkedJob.allbirds_metadata.published_by_user_back_office_id)"
                    [useBodyAsContainer]="true">
                  </app-user-circle>
                </div>
                <div class="col-1 ml-auto text-center justify-content-center align-self-center">
                  <app-user-circle *ngIf="linkedJob?.allbirds_metadata?.assigned_to_user_front_office_id"
                    [user]="getUser(linkedJob.allbirds_metadata.assigned_to_user_front_office_id)"
                    [useBodyAsContainer]="true">
                  </app-user-circle>
                </div>
                <div class="col-1 ml-auto text-center justify-content-center align-self-center cursor-pointer">
                  <i class="icon icon-WL_icon_chevron-right mr-3 color-primary"></i>
                </div>
              </div>
            </div>
          </div>
        </ng-container>


        <div class="mt-3">
          <h4>{{'job-details-pane.job_category' | translate}}</h4>
          <h4 class="text-gray">{{ getJobCategoryByCode(job.classifications[0]) }}</h4>
        </div>
        <div class="mt-3">
          <h4>{{'job-details-pane.employment_type' | translate}}</h4>
          <h4 class="text-gray">
            {{ job.employmentTypes.length ? (job.employmentTypes[0] | stringclean: ['mapemploymenttype']) : 'N/A' }}
          </h4>
        </div>
        <div class="mt-3">
          <h4>{{'job-details-pane.number_of_openings' | translate}}</h4>
          <h4 class="text-gray">{{ job.allbirds_metadata.number_of_openings }}</h4>
        </div>
        <!-- <div class="mt-3" *ngIf="jobLob.checkLob('RT', 'RE','CR')">
          <h4>{{'job-details-pane.svg_strategic_job' | translate}}</h4>
          <h4 class="text-gray">{{ job.allbirds_metadata.svg_strategic_job | boolstring }}</h4>
        </div> -->
        <div class="mt-3">
          <h4>{{'job-details-pane.internal_notes' | translate}}</h4>
          <h4 class="text-gray" [innerHTML]="job.allbirds_metadata.internal_notes"></h4>
        </div>
        <div *ngIf="isClientOrder" class="mt-3">
          <h4>{{'job-details-pane.attached_files' | translate}}</h4>
          <ng-container *ngIf="!job.allbirds_metadata.attachments.length">
            <h4 class="text-gray">-</h4>
          </ng-container>
          <ng-container *ngIf="job.allbirds_metadata.attachments.length">
            <app-attachment-card-internal *ngFor="let attachment of job.allbirds_metadata.attachments" cardType="small" [attachment]="attachment" (delete)="openConfirmDialogDeleteAttachment(attachment)"></app-attachment-card-internal>
          </ng-container>
        </div>
        <!-- divider -->
        <hr />
        <ng-container *ngIf="job && job.workHours.length && job.workHours[0].shifts.length">
          <div class="mt-3">
            <h4>{{'job-details-pane.shifts' | translate}}</h4>
            <h4 class="text-gray">{{ job && job.workHours[0].shifts[0] + ' Shift' }}</h4>
          </div>
          <div class="mt-3" *ngIf="work_hours_start">
            <h4>{{'job-details-pane.work_hours_start' | translate}}</h4>
            <h4 class="text-gray">{{ work_hours_start | abTime }}</h4>
          </div>
          <div class="mt-3" *ngIf="work_hours_end">
            <h4>{{'job-details-pane.work_hours_end' | translate}}</h4>
            <h4 class="text-gray">{{ work_hours_end | abTime }}</h4>
          </div>
          <div class="mt-3" *ngIf="jobLob.checkLob('RGS')">
            <h4>{{'job-details-pane.fullpart_time' | translate}}</h4>
            <h4 class="text-gray">{{ (job.employmentTypes.indexOf('FULL_TIME') !== -1) ? 'Full-Time' : 'Part-Time' }} hours</h4>
          </div>
          <div class="mt-3">
            <h4>{{'job-details-pane.expected_overtime' | translate}}</h4>
            <h4 class="text-gray">{{ job.allbirds_metadata.expected_overtime_hours}} hour(s)</h4>
          </div>
          <div class="mt-3">
            <h4>{{'job-details-pane.weekend_work_required' | translate}}</h4>
            <h4 class="text-gray">{{ job.allbirds_metadata.weekend_work_required | boolstring }}</h4>
          </div>
          <hr /> <!-- divider -->
        </ng-container>

        <ng-container *ngIf="job && job.compensationInfo.entries.length">
          <div class="mt-3">
            <h4>{{'job-details-pane.salary_type' | translate}}</h4>
            <h4 class="text-gray">{{ job.compensationInfo.entries[0].unit | titlecase }}</h4>
          </div>
          <div class="mt-3">
            <h4>{{'job-details-pane.pay_rate_low' | translate}}</h4>
            <h4 class="text-gray">
              {{ min_pay_rate | currency: job.compensationInfo.entries[0].amount?.currencyCode }}
            </h4>
          </div>
          <div class="mt-3">
            <h4>{{'job-details-pane.pay_rate_high' | translate}}</h4>
            <h4 class="text-gray">
              {{ job.allbirds_metadata.published_max_pay_rate | currency: job.compensationInfo.entries[0].amount?.currencyCode }}
            </h4>
          </div>
          <div class="mt-3">
            <h4>{{'job-details-pane.work_location' | translate}}</h4>
            <h4 class="text-gray">
              <div class="address" title="{{ job.addresses && job.addresses[0] }}">{{ job.addresses && job.addresses[0] }}</div>
            </h4>
          </div>
          <div class="mt-3">
            <h4>{{'job-details-pane.cross_lob_revenue' | translate}}</h4>
            <h4 class="text-gray">{{ job.allbirds_metadata.rev_share_flag | boolstring }}</h4>
          </div>
        </ng-container>

        <!-- Qualifications Section -->
        <div class="mt-5">
          <i class="icon icon-WL_icon_edit circle float-right" (click)="goTo('#skills')"></i>
          <h2>{{'job-details-pane.qualifications' | translate}}</h2>
        </div>
        <div class="mt-3">
          <div *ngIf="job.allbirds_metadata.education_must_have" class="float-right">
            <img class="required_bubble" src="../../../assets/icons/gamification-red.svg"   />
          </div>
          <h4>{{'job-details-pane.education_requirement' | translate}}</h4>
          <h4 class="text-gray" *ngIf="job.degreeTypes.length">
            {{ job.degreeTypes[0] | stringclean: ['mapeducation'] }}</h4>
          <h4 class="text-gray" *ngIf="!job.degreeTypes.length">{{'job-details-pane.na' | translate}}</h4>
        </div>
        <!-- divider -->
        <hr />
        <div class="mt-3">
          <h4>{{'job-details-pane.years_of_experience' | translate}}</h4>
          <h4 class="text-gray">{{ job.allbirds_metadata.minimum_years_of_experience }} year(s)</h4>
        </div>
        <div class="mt-3">
          <h4>{{'job-details-pane.experience_level' | translate}}</h4>
          <h4 class="text-gray">{{ job.jobLevel | stringclean: ['titlecase', 'sansunderscore'] }}</h4>
        </div>
        <hr /> <!-- divider -->
        <!-- skill sub section -->
        <ng-container *ngIf="job.allbirds_metadata.skills && job.allbirds_metadata.skills.length">
          <h3 class="mt-3">{{'job-details-pane.skills' | translate}}</h3>
          <div *ngFor="let skill of job.allbirds_metadata.skills">
            <div *ngIf="skill.required" class="float-right">
              <img class="required_bubble" src="../../../assets/icons/gamification-red.svg"   />
            </div>
            <div class="mt-2">
              <h4>{{skill.name}}</h4>
              <h4 *ngIf="skill.years_of_experience && isMonths(skill.years_of_experience)" class="text-gray">
                {{ skill.years_of_experience * 12}} months
              </h4>
              <h4 *ngIf="skill.years_of_experience && !isMonths(skill.years_of_experience)" class="text-gray">
                {{ skill.years_of_experience }} year{{ skill.years_of_experience > 1 ? 's' : '' }}
              </h4>
            </div>
          </div>
        </ng-container>
        <!-- Service line & sub service line -->
        <ng-container *ngIf="job.allbirds_metadata.service_line && job.allbirds_metadata.service_line.length">
          <h3 class="mt-3">{{'job-details-pane.service_line' | translate}}</h3>
            <div class="mt-2">
              <h4>{{job.allbirds_metadata.service_line}}</h4>
            </div>
        </ng-container>
        <ng-container *ngIf="job.allbirds_metadata.sub_service_line && job.allbirds_metadata.sub_service_line.length">
          <h3 class="mt-3">{{'job-details-pane.sub_service_line' | translate}}</h3>
            <div class="mt-2">
              <h4>{{job.allbirds_metadata.sub_service_line}}</h4>
            </div>
        </ng-container>
        <!-- divider -->

        <hr />
        <!-- responsibilites sub section -->
        <div *ngIf="job.responsibilities && job.responsibilities.length">
          <h3 class="mt-3">{{'job-details-pane.responsibilities' | translate}}</h3>
          <h4 class="no-wrap text-gray w-100" [innerHTML]="job.responsibilities"></h4>
        </div>
        <hr /> <!-- divider -->
        <!-- Physical requirements sub section -->
        <h3 class="mt-3">{{'job-details-pane.physical_requirements' | translate}}</h3>
        <div *ngIf="job.allbirds_metadata.heavy_lifting">
          <h4 class="mt-3">{{'job-details-pane.heavy_lifting' | translate}}</h4>
          <h4 class="text-gray">{{job.allbirds_metadata.heavy_lifting_weight}} (lbs)</h4>
        </div>
        <div class="mt-3" *ngIf="job.allbirds_metadata.consistently_standing">
          <h4>{{'job-details-pane.consistently_standing' | translate}}</h4>
          <h4 class="text-gray">{{job.allbirds_metadata.consistently_standing_hours}} hour(s)</h4>
        </div>
        <div class="mt-3">
          <h4>{{'job-details-pane.non-temperature_controlled_environment' | translate}}</h4>
          <h4 class="text-gray">{{job.allbirds_metadata.temperature_controlled | boolstring}}</h4>
        </div>
        <div class="mt-3">
          <h4>{{'job-details-pane.smoke_free_environment' | translate}}</h4>
          <h4 class="text-gray">{{job.allbirds_metadata.smoke_free_environment | boolstring}}</h4>
        </div>
        <div class="mt-3">
          <h4>{{'job-details-pane.steel_toe_shoes' | translate}}</h4>
          <h4 class="text-gray">{{job.allbirds_metadata.steel_toe_shoes_required | boolstring}}</h4>
        </div>
        <div *ngIf="jobLob.checkLob('RGS')" class="mt-3">
          <h4>{{'job-details-pane.drug_screening' | translate}}</h4>
          <h4 class="text-gray">{{job.allbirds_metadata.drug_screening | boolstring}}</h4>
        </div>
        <div class="mt-3" *ngIf="job.workEnvironments && job.workEnvironments.length">
          <h4>{{'job-details-pane.work_environment' | translate}}</h4>
          <h4 class="text-gray">{{job.workEnvironments | array}}</h4>
        </div>
        <!-- Auto Scheduler Section -->
        <div *ngIf="!isCRLob" class="mt-5">
          <i class="icon icon-WL_icon_edit circle float-right" (click)="goTo('#interview')"></i>
          <h2>{{'job-details-pane.procedural_information' | translate}}</h2>
        </div>
        <!-- Interview configuration sub section -->
        <ng-container *ngIf="!isCRLob" id="interview-config">
          <h3 class="mt-3">{{'job-details-pane.interview_configuration' | translate}}</h3>
          <div class="mt-3">
            <h4 class="d-inline">{{'job-details-pane.auto_schedule_interviews' | translate}}</h4>
            <span id="toggles" class="float-right">
                      <input [attr.checked]="job.allbirds_metadata.allow_auto_scheduler ? 'checked' : null"
                            type="checkbox" id="checkbox1" class="ios-toggle"   />
                      <label class="checkbox-label" (click)="toggleAutoScheduler()"></label>
                    </span>
          </div>
          <hr /> <!-- divider -->
          <span *ngIf="job.allbirds_metadata.allow_auto_scheduler">
            <div class="mt-3">
              <h4>{{'job-details-pane.interview_type' | translate}}</h4>
              <h4 class="text-gray">
                {{job.allbirds_metadata.auto_scheduler_interview_channel | array: ['sansunderscore','titlecase']}}
              </h4>
            </div>
            <div class="mt-3" *ngIf="job.allbirds_metadata.auto_scheduler_location">
              <h4>{{'job-details-pane.in_person_interview' | translate}}</h4>
              <h4 class="text-gray">{{job.allbirds_metadata.auto_scheduler_location}}</h4>
            </div>          
          </span>
        </ng-container>
        <div *ngIf="!isCRLob" class="mt-3">
          <h4>{{'job-details-pane.external_job_id' | translate}}</h4>
          <h4 class="text-gray">{{job.name}}</h4>
        </div>
      </div> <!-- end -->
    </tab>
    <tab id="client_details" (selectTab)="tabSelect('client_details')">
      <ng-template class="text-center" tabHeading>
        <div class="text-center">
          <h3>{{'job-details-pane.client_details' | translate}}</h3>
        </div>
      </ng-template>
      <div id="client-info-section">
        <!-- client Section -->
        <h2>{{'job-details-pane.client_details' | translate}}</h2>
        <div class="mt-3">
          <h4>{{'job-details-pane.client_job_title' | translate}}</h4>
          <h4 class="text-gray">{{job.internalTitle}}</h4>
        </div>
        <div *ngIf="isClientOrder" class="mt-3">
          <h4>{{'job-details-pane.customer_id' | translate}}</h4>
          <h4 class="text-gray">{{job.allbirds_metadata.customer_id}}</h4>
        </div>
        <div *ngIf="isClientOrder" class="mt-3">
          <h4>{{'job-details-pane.contact_name' | translate}}</h4>
          <h4 class="text-gray">{{job.hiringManager}}</h4>
        </div>
        <div *ngIf="isClientOrder" class="mt-3">
          <h4>{{'job-details-pane.contact_id' | translate}}</h4>
          <h4 class="text-gray">{{job.allbirds_metadata.contact_id}}</h4>
        </div>
        <div *ngIf="isClientOrder" class="mt-3">
          <h4>{{'job-details-pane.source' | translate}}</h4>
          <h4 class="text-gray">{{order_source_title}}</h4>
        </div>
        <div class="mt-3" *ngIf="jobLob.checkLob('RGS')">
          <h4>{{'job-details-pane.internal_job_code' | translate}}</h4>
          <h4 class="text-gray">{{job.allbirds_metadata.internal_job_code}}</h4>
        </div>
        <div class="mt-3" *ngIf="jobLob.checkLob('RGS')">
          <h4>{{'job-details-pane.internal_job_title' | translate}}</h4>
          <h4 class="text-gray">{{job.allbirds_metadata.internal_job_title | stringclean: ['sansjobcode']}}
          </h4>
        </div>
        <div *ngIf="isClientOrder" class="mt-3">
          <h4>{{'job-details-pane.start_date' | translate}}</h4>
          <h4 class="text-gray">{{ job.jobStartTime | abDate  }}</h4>
        </div>
        <div class="mt-3" *ngIf="job.jobEndTime && isClientOrder">
          <h4>{{'job-details-pane.estimate_end_date' | translate}}</h4>
          <h4 class="text-gray">{{job.jobEndTime | abDate}}</h4>
        </div>
        <div class="mt-3" *ngIf="job.employmentTypes[0].includes('CONTRACT') && isClientOrder">
          <h4>{{'job-details-pane.bill_rate' | translate}}</h4>
          <h4 class="text-gray">{{job.allbirds_metadata.bill_rate | currency: 'USD' }}</h4>
        </div>
        <div class="mt-3" *ngIf="job.employmentTypes[0].includes('CONTRACT') && isClientOrder">
          <h4>{{'job-details-pane.max_pay_rate' | translate}}</h4>
          <h4 class="text-gray">{{job.allbirds_metadata.internal_max_pay_rate | currency: 'USD' }}</h4>
        </div>
        <div *ngIf="isClientOrder" class="mt-3">
          <h4>{{'job-details-pane.interview_information' | translate}}</h4>
          <h4 class="text-gray">{{job.allbirds_metadata.interview_info}}</h4>
        </div>
        <div class="mt-3" *ngIf="jobLob.checkLob('RT', 'RE','CR') && job.employmentTypes[0].includes('CONTRACT')">
          <h4>{{'job-details-pane.mark_up' | translate}}</h4>
          <h4 class="text-gray">{{job.allbirds_metadata.mark_up | number: '1.0-2'}}</h4>
        </div>
        <div class="mt-3" *ngIf="jobLob.checkLob('RT', 'RE','CR') && job.employmentTypes[0].includes('CONTRACT')">
          <h4>{{'job-details-pane.calculated_gp' | translate}}</h4>
          <h4 class="text-gray">{{job.allbirds_metadata.calculated_gp}}</h4>
        </div>
        <div class="mt-3" *ngIf="!job.employmentTypes[0].includes('CONTRACT') && isClientOrder">
          <h4>{{'job-details-pane.target_salary' | translate}}</h4>
          <h4 class="text-gray">{{job.allbirds_metadata.target_salary | currency: 'USD' }}</h4>
        </div>
        <div class="mt-3" *ngIf="!job.employmentTypes[0].includes('CONTRACT') && isClientOrder">
          <h4>{{'job-details-pane.max_salary' | translate}}</h4>
          <h4 class="text-gray">{{job.allbirds_metadata.internal_max_pay_rate | currency: 'USD'}}</h4>
        </div>
        <div class="mt-3" *ngIf="jobLob.checkLob('RT', 'RE','CR') && job.employmentTypes[0].includes('PERMANENT')">
          <h4>{{'job-details-pane.perm_fee' | translate}}</h4>
          <h4 class="text-gray">{{job.allbirds_metadata.perm_fee / 100 | percent }}</h4>
        </div>
        <div class="mt-3" *ngIf="jobLob.checkLob('RT', 'RE','CR')">
          <h4>{{'job-details-pane.exclusive_req' | translate}}</h4>
          <h4 class="text-gray">{{job.allbirds_metadata.exclusive_req | boolstring}}</h4>
        </div>
        <div class="mt-3">
          <h4>{{'job-details-pane.remote_work_allowed' | translate}}</h4>
          <h4 class="text-gray">{{job.allbirds_metadata.allow_remote_work}}</h4>
        </div>
        <div class="mt-3" *ngIf="jobLob.checkLob('RT', 'RE','CR')">
          <h4>{{'job-details-pane.travel_requirements' | translate}}</h4>
          <h4 class="text-gray">{{job.allbirds_metadata.travel_requirements}}</h4>
        </div>
        <div class="mt-3" *ngIf="jobLob.checkLob('RGS') && isClientOrder">
          <h4>{{'job-details-pane.bill_location' | translate}}</h4>
          <h4 class="text-gray">{{job.allbirds_metadata.bill_to_location}}</h4>
        </div>
        <div class="mt-3" *ngIf="jobLob.checkLob('RT', 'RE','CR') && job.employmentTypes[0].includes('CONTRACT')">
          <h4>{{'job-details-pane.exempt' | translate}}</h4>
          <h4 class="text-gray">{{job.allbirds_metadata.exempt | boolstring}}</h4>
        </div>
        <div class="mt-3" *ngIf="jobLob.checkLob('RT', 'RE','CR') && job.employmentTypes[0].includes('CONTRACT')">
          <h4>{{'job-details-pane.dbc_candidates_allowed' | translate}}</h4>
          <h4 class="text-gray">{{job.allbirds_metadata.allow_dbc_candidates | boolstring}}</h4>
        </div>
        <div class="mt-3" *ngIf="jobLob.checkLob('RT', 'RE','CR') && job.employmentTypes[0].includes('CONTRACT')">
          <h4>{{'job-details-pane.3rd_party_vendors' | translate}}</h4>
          <h4 class="text-gray">{{job.allbirds_metadata.allow_third_party_vendors | boolstring}}</h4>
        </div>
        <div class="mt-3" *ngIf="jobLob.checkLob('RT', 'RE','CR') && job.employmentTypes[0].includes('CONTRACT')">
          <h4>{{'job-details-pane.independent_contractors_allowed' | translate}}</h4>
          <h4 class="text-gray">{{job.allbirds_metadata.allow_independent_contractors | boolstring}}</h4>
        </div>
        <div class="mt-3" *ngIf="jobLob.checkLob('RT', 'RE','CR')">
          <h4>{{'job-details-pane.will_relocate' | translate}}</h4>
          <h4 class="text-gray">{{job.allbirds_metadata.will_relocate | boolstring}}</h4>
        </div>
        <div class="mt-3" *ngIf="jobLob.checkLob('RT', 'RE','CR') && !job.employmentTypes[0].includes('CONTRACT')">
          <h4>{{'job-details-pane.visa_sponsorship_provided' | translate}}</h4>
          <h4 class="text-gray">{{job.allbirds_metadata.will_sponsor_visa | boolstring}}</h4>
        </div>
        <div *ngIf="isClientOrder" class="mt-3">
          <h4>{{'job-details-pane.submission_deadline' | translate}}</h4>
          <h4 class="text-gray">{{ job.allbirds_metadata.submission_deadline | abDate }}</h4>
        </div>

        <!-- divider -->
        <hr />
        <!-- end -->
      </div>
    </tab>

    <!-- placeholders to push tabs left -->
    <tab [disabled]="true"></tab>
  </tabset>

  <div class="sticky-cta mt-3" *ngIf="addToJob">
    <div class="text-right col buttonContainer">

      <span class="d-inline-block mr-3">
        <button type="btn rds--button--secondary add-to-job-btn" class="btn rds--button--primary" (click)="openJobUrl()">
          View job
        </button>
      </span>

      <span class="d-inline-block" [tooltip]="selectedCardTooltip" [isDisabled]="!isTalentShortlisted">
        <button
          [disabled]="isTalentShortlisted" type="btn rds--button--secondary add-to-job-btn" class="btn rds--button--primary" (click)="shortlist()">
          add to job
        </button>
      </span>


    </div>
  </div>
</ng-container>
