<div class="modal-header">
  <button type="button" class="btn-reset" aria-label="Close" (click)="_bsModalRef.hide()">
    <i class="icon-WL_icon_close"></i>
  </button>
</div>

<div class="modal-body">
  <h2 class="mb-2">{{'update-job-status-modal.update_job_status' | translate}}</h2>

  <table>
    <tr>
      <td><h4>{{ "update-job-status-modal.job_title" | translate }}</h4></td>
      <td><h4 class="gray-text ml-3">{{jobTitle}}</h4></td>
    </tr>
    <tr>
      <td><h4>{{ ((isClientOrder && customerName) ? "update-job-status-modal.company" : customerPriority ? "update-job-status-modal.priority" : '') | translate }}</h4></td>
      <td><h4 class="gray-text ml-3">{{isClientOrder ? customerName : ''}} <span class="priority" *ngIf="customerPriority">P{{customerPriority}}</span> </h4></td>
    </tr>
    <tr>
      <td><h4>{{ "update-job-status-modal.job_id" | translate }}</h4></td>
      <td>
        <h4 class="gray-text ml-3" >{{frontOfficeId}}</h4>
      </td>
    </tr>
  </table>
  <br>
  <div class="row">
    <div class="col">
      <label>{{'update-job-status-modal.update_job_status' | translate}}</label>
      <select [(ngModel)]="jobStatus" (change)="setJobStatus($event)" class="custom-select" [disabled]="isJobClosed">
        <option value="Unqualified">{{'update-job-status-modal.unqualified' | translate}}</option>
        <option value="Accepting Candidates">{{'update-job-status-modal.accepting_candidates' | translate}}</option>
        <option value="Covered">{{'update-job-status-modal.covered' | translate}}</option>
        <option value="On Hold">{{'update-job-status-modal.on_hold' | translate}}</option>
      </select>
    </div>
    <div class="col">
      <label>{{'update-job-status-modal.employment_type' | translate}}</label>
      <input type="text" [(ngModel)]="employmentType" disabled class="form-control vms_input" />
    </div>
  </div>
  <br/>
  <div class="row">
    <div class="col">
      <label>{{'update-job-status-modal.start_date' | translate}}</label>
      <div class="input-group search">
        <input [(ngModel)]="jobStartDate" type="text" disabled placeholder="mmm/dd/yyyy" class="form-control calendar-input disabled"
               bsDatepicker [bsConfig]="datePickerConfig" (ngModelChange)="setStartDate($event)"/>
          <i class="icon icon-WL_icon_schedule color-primary calendar-icon"></i>
      </div>
      <div class="error" *ngIf="!jobStartDate || jobStartDate.length === 0">
        <p>{{'update-job-status-modal.start_date_required' | translate}}</p>
      </div>
    </div>
    <div class="col">
      <label>{{'update-job-status-modal.end_date' | translate}}</label>
      <div class="input-group search">
        <input [(ngModel)]="jobEndDate" type="text" placeholder="mmm/dd/yyyy" [ngClass]="isJobClosed ? 'form-control calendar-input disabled' : 'form-control calendar-input'"
               bsDatepicker [bsConfig]="datePickerConfig" [minDate]="startDateAsDateObject" [disabled]="disableEndDateInput || isJobClosed" (ngModelChange)="setEndDate($event)"/>
        <i class="icon icon-WL_icon_schedule color-primary calendar-icon"></i>
      </div>
      <div class="error">
        <p class="error-message" *ngIf="(((!jobEndDate || jobEndDate.length === 0) && jobStatus !== 'Unqualified') && !disableEndDateInput) || jobEndDateRequired">
          {{'update-job-status-modal.end_date_required' | translate}}
        </p>
        <p class="error-message" *ngIf="isEndDateSameAsStartDate">{{'update-job-status-modal.end_date_same_as_start_date' | translate}}</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col job-closed">
      <img *ngIf="isJobClosed" class="menu-button-icon pr-1" src="../../../assets/icons/alert-yellow.svg" style="margin-top: -4px; min-width: 24px;"/>
      <p *ngIf="isJobClosed" class="gray-text" style="margin: 5px;">{{ 'update-job-status-modal.job_closed' | translate }}</p>
    </div>
  </div>
</div>
<!-- / .modal-body -->

<div class="row modal-footer" style="margin: 0 0 0 0;">
  <div *ngIf="updateFailed && !isJobClosed" class="text-danger flex-column">{{'update-job-status-modal.update_failed' | translate}}</div>
  <div class="flex-column footer-buttons">
    <button type="button" class="btn rds--button--primary" aria-label="cancel" (click)=_bsModalRef.hide()>{{'update-job-status-modal.cancel' | translate}}</button>
    <button type="button" class="btn rds--button--primary" style="margin-left: 10px;" aria-label="update" [disabled]="disableUpdateButton || updateFailed || isJobClosed" (click)=save()>{{'update-job-status-modal.update' | translate}}</button>
  </div>
</div>
