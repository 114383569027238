import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TalentSortTypes } from '../../../modules/talent/pages/talent-management-listings/TalentSortTypes';
import {
  TalentMetricsActivity,
  TalentMetricsRequest, TalentMetricsTypeCount
} from '../../components/talent-detail-pane/talent-metrics/talent-metrics.interface';
import { Application, RawApplication } from '../../models/external/application.model';
import { Profile, RawProfile } from '../../models/external/profile.model';
import { ActivityNote } from '../../models/external/activity.model';
import { RequestBody } from '../../models/api/application-request.interface';
import {Observable, pipe, throwError} from 'rxjs';
import { map } from 'rxjs/operators';
import { Job, RawJob } from '../../models/external/job.model';
import { JobForm, RawJobForm } from '../../models/external/job-form.model';
import { ResponseBody } from '../../models/api/application-response.interface';
import { JobPrompt, RawJobPrompt } from '../../models/external/job-prompt.model';
import {
  Appointments,
  InterviewScheduleInformation,
  RecruiterEvent,
  ScheduleCandidate
} from '../../models/external/misc.model';
import { RecursivePartial } from '../../models/external/model';
import { GoogleProfile } from '../../models/external/google-profile.model';
import { ElasticRecentSearch, ElasticSavedSearch } from '../talent-management/user-searches.types';
import { List, RawList } from '../../models/external/list.model';
import { RawUser, User } from '../../models/external/user.model';
import { MetricsEvent } from '../metrics/metrics.types';
import { JobMetrics } from '../../models/external/job-metrics.model';
// import { ClientEvent } from '../client-event/client-event.types';
import { TalentActivity } from '../../models/external/talent-activity.model';
import SearchTalentResponse = ResponseBody.SearchTalentResponse;
import CreatedResponse = ResponseBody.CreatedResponse;
import UpdatedResponse = ResponseBody.UpdatedResponse;
import DeletedResponse = ResponseBody.DeletedResponse;
import AddToRejectedResponse = ResponseBody.AddToRejectedResponse;
import SuggestsResponse = ResponseBody.SuggestsResponse;
import ScheduleChatbotPrescreeningRequest = RequestBody.ScheduleChatbotPrescreeningRequest;
import TalentHistoryRequest = RequestBody.TalentHistoryRequest;
import TalentHistoryResponse = ResponseBody.TalentHistoryResponse;
import ShortlistMetadataResponse = ResponseBody.ShortlistMetadataResponse;
import GetApplicationsResponse = ResponseBody.GetApplicationsResponse;
import ElasticData = ResponseBody.ElasticData;
import GetSearchJobsResponse = ResponseBody.GetSearchJobsResponse;
import PaginationOptions = RequestBody.PaginationOptions;
import { RecruiterHierarchy } from '../../models/internal/recruiter-hierarchy.model';
import GetTalentMetricsRequest = RequestBody.GetTalentMetricsRequest;
import GetTalentMetricsResponse = ResponseBody.GetTalentMetricsResponse;
import { UpdateApplicationRequest } from '../../models/internal/process.model';
import { VmsJobRequest } from '../../models/internal/vms.interface';
import { EmailPreferences } from '../../models/internal/mass-email.model';
import { MassEmail } from '@allbirds-ui/allbirds-types/dist/types/MassEmail';
import { MassSms } from '../../models/internal/mass-sms';
import { INTG_STEPS, UpdateManyApplicationsRequest } from 'src/app/shared/models/internal/process.model';
import { Metrics, RecentActivityListResponse, RawRecentActivityListResponse } from '../../models/internal/recent-activities-list.model';
import {AllbirdsAppointment, AllbirdsAppointment as Appointment} from '../../models/external/appointment.model';
import SendInviteToRCERequest = Appointments.SendInviteToRCERequest;
import {UserActionActivity } from '../../models/external/user-actions.model';
import { UserAction } from '../../models/internal/user-action.interface';


const SERVER_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private _http: HttpClient
  ) { }

  /* TODO(Jason):
      1. type all the api calls
      2. wrap responses using Job.deserialize(...), etc.
      2. **follow the method usages to fix implicit any's**
   */
  logTalentActivity(activity: TalentActivity, meta?: any) {
    console.log('logTalentActivity', activity);
    let endpoint = `${SERVER_URL}/talent/activity`;
    if (meta && meta.interviewChannel) {
      endpoint += `?interviewChannel=${meta.interviewChannel}`;
    }
    return this._http.post(endpoint, activity);
  }

  getTalentByStep(body: RequestBody.GetTalentByStepRequest) {
    const stepKeys = body.stepKeys.join(',');
    return this._http.get(`${SERVER_URL}/aggregate/talentByStep` +
      `?stepKeys=${stepKeys}` +
      `&from=${body.from}` +
      `&includePostings=${body.includePostings}`
    );
  }

  reinstate(body: RequestBody.ReinstateRequest) {
    return this._http.post<RawApplication>(`${SERVER_URL}/process/reinstate`, body)
    .pipe(map(Application.deserialize));
  }

  getPotentialTalent(body: RequestBody.GetPotentialTalentRequest) {
    return this._http.post<SearchTalentResponse>(`${SERVER_URL}/talent/recommended`, body)
    .pipe(map(res => ({
      ...res,
      profiles: res.profiles.map(GoogleProfile.deserialize)
    })));
  }

  getShortlist(allbirdsJobId: string) {
    return this._http.get<RawApplication[]>(`${SERVER_URL}/process/${allbirdsJobId}`)
    .pipe(map((apps: any) => apps.map(Application.deserialize)));
  }

  addToShortlist(body: RequestBody.AddToShortlistRequest) {
    return this._http.post<RawApplication>(`${SERVER_URL}/process`, body)
    .pipe(map(Application.deserialize));
  }

  addBulkToShortlist(body: any) {
    return this._http.post(`${SERVER_URL}/process/bulk/shortlist`, body)
    .pipe(map((res: any) => ({
      ...res,
      applications: (res.applications && res.applications.map(Application.deserialize)) || []
    })));
  }

  addToRejected(body: RequestBody.AddToRejectedRequest, includeApplication: boolean = false) {
    if (includeApplication) {
      body.includeApplication = true;
    }
    return this._http.post<AddToRejectedResponse>(`${SERVER_URL}/process/reject`, body)
    .pipe(map(res => ({
      ...res,
      application: Application.deserialize(res.application)
    })));
  }

  sendCancellationRequest(body: RequestBody.CancellationRequest) {
    return this._http.post(`${SERVER_URL}/process/cancel`, body)
    .pipe(map((res: any) => ({
      ...res,
      applications: (res.applications && res.applications.map(Application.deserialize)) || []
    })));
  }

  sendCancellationAppointmentRequest(body: RequestBody.CancellationAppointmentRequest) {
    return this._http.post(`${SERVER_URL}/talent/cancel`, body)
    .pipe(map((res: any) => ({
      ...res
    })));
  }

  sendRecruiterCancellationAppointmentRequest(body: RequestBody.RecruiterCancellationAppointmentRequest) {
    return this._http.post(`${SERVER_URL}/talent/cancel/appointment`, body)
    .pipe(map((res: any) => ({
      ...res
    })));
  }

  rejectManyTalent(body: any) {
    return this._http.post(`${SERVER_URL}/process/bulk/reject`, body)
    .pipe(map((res: any) => ({
      ...res,
      applications: (res.applications && res.applications.map(Application.deserialize)) || []
    })));
  }

  updateManyApplications(body: UpdateManyApplicationsRequest) {
    return this._http.post(`${SERVER_URL}/process/bulk/update`, body)
    .pipe(map((res: any) => ({
      ...res,
      applications: (res.applications && res.applications.map(Application.deserialize)) || []
    })));
  }

  getProfilesById(body: RequestBody.GetProfilesByIdRequest) {
    return this._http.post<RawProfile[]>(`${SERVER_URL}/talent/profilesById`, body)
    .pipe(map(profiles => profiles.map(Profile.deserialize)));

  }

  schedulePrescreening(events: RecruiterEvent[]) {
    return this._http.post<CreatedResponse>(`${SERVER_URL}/talent/schedule/prescreening`, { events });
  }

  getTalentMetrics(body: RequestBody.GetTalentMetricsRequest) {
    const stepKeys = body.stepKeys.join(',');
    return this._http.get<ResponseBody.GetTalentMetricsResponse>(`${SERVER_URL}/aggregate/metrics/talent` +
      `?stepKeys=${stepKeys}` +
      `&includePostings=${body.includePostings}` +
      `&includeApplications=${body.includeApplications}` +
      `&applicationsFrom=${body.applicationsFrom}` +
      `&appointmentsFrom=${body.applicationsFrom}` +
      `&filters=${body.filters}` +
      `&sort=${body.sort || TalentSortTypes.LAST_UPDATED_DESCENDING}`)
      .pipe(map(res => {
        console.log(res);
        return {
          metrics: new JobMetrics().apply(res.metrics),
          applications: res.applications && res.applications.map(Application.deserialize),
          postings: res.postings && res.postings.map(Job.deserialize),
        };
      }));
  }

  getRecentContacts() {
    return this._http.get<SuggestsResponse<any>>(`${SERVER_URL}/aggregate/contacts/recently_used?field_name=hiringManager`);
  }

  /**
   * Create a new job
   * route: {POST} /postings
   */
  public createJob(jobObject: any): Observable<CreatedResponse> {
    return this._http.post<CreatedResponse>(`${SERVER_URL}/postings`, jobObject);
  }

  /**
   * Get a job by it's id
   * route: {GET} /postings/:id?formVersion=<boolean>
   */
  public getJob(id: string, formVersion: true): Observable<JobForm>;
  public getJob(id: string, formVersion?: false): Observable<Job>;
  public getJob(id: string, formVersion = false): Observable<JobForm | Job> {
    if (formVersion) {
      const params = new HttpParams().set('formVersion', 'true');
      return this._http.get<RawJobForm>(`${SERVER_URL}/postings/${id}`, { params })
      .pipe(map(JobForm.deserialize));
    } else {
      const params = new HttpParams().set('formVersion', 'false');
      return this._http.get<RawJob>(`${SERVER_URL}/postings/${id}`, { params })
      .pipe(map(Job.deserialize));
    }
  }

  public updateJob(jobObject: any, fieldsUpdated: string[] = null): Observable<UpdatedResponse> {
    return this._http.put<UpdatedResponse>(`${SERVER_URL}/postings`, { job: jobObject, fieldsUpdated });
  }

  public publishJob(_id: string, interviewObj: InterviewScheduleInformation, fieldsUpdated: string[] = [], lob: string = null) {
    return this._http.post<ResponseBody.UpdateJobResponse>(`${SERVER_URL}/postings/publish`, { _id, interviewObj, fieldsUpdated, lob })
    .pipe(map(res => ({
      ...res,
      prompts: res.prompts && res.prompts.prompts && res.prompts.prompts.map(JobPrompt.deserialize)
    })));
  }

  public unpublishJob(_id: string, interviewObj: InterviewScheduleInformation) {
    return this._http.post<ResponseBody.UpdateJobResponse>(`${SERVER_URL}/postings/unpublish`, { _id, interviewObj, fieldsUpdated: ['publish_immediately'] })
    .pipe(map(res => ({
      ...res,
      prompts: res.prompts && res.prompts.prompts && res.prompts.prompts.map(JobPrompt.deserialize)
    })));
  }

  public getJobs(paramsList: any): Observable<Job[]> {
    return this._http.post<RawJob[]>(`${SERVER_URL}/postings/all`, paramsList)
    .pipe(map(jobs => jobs.map(Job.deserialize)));
  }

  public getSearchJobs(paramsList: any) {
    return this._http
      .post<GetSearchJobsResponse>(
        `${SERVER_URL}/postings/search_jobs`,
        paramsList
      )
      .pipe(map((res) => ({
        ...res,
        jobs: res.jobs.map(Job.deserialize),
      })));
  }

  public getVMSJobs(vmsRequest: VmsJobRequest) {
    return this._http.post(`${SERVER_URL}/postings/vms_jobs`, vmsRequest);
  }

  public getVMSJob(vmsOrderId: string) {
    return this._http.post(`${SERVER_URL}/postings/vms_job`, {vmsOrderId});
  }

  public updateVMSJob(vmsActionFields: any, id: string) {
    return this._http.put(`${SERVER_URL}/postings/vms_job`, { ...vmsActionFields, vmsOrderId: id });
  }

  public deleteJob(id: string): Observable<DeletedResponse> {
    return this._http.delete<DeletedResponse>(`${SERVER_URL}/postings?id=${id}`);
  }

  public postJobPrompt(google_job_id: string, prompts: JobPrompt[]) {
    return this._http.post<CreatedResponse>(`${SERVER_URL}/postings/set_questions`, { google_job_id, prompts });
  }

  // lob passed until RT can consume changes from ticket DF044-1917.
  public getJobPrompts(google_job_id: string, lob: string) {
    return this._http.post<{ prompts: RawJobPrompt[] }>(`${SERVER_URL}/postings/get_questions`, { google_job_id, lob })
      .pipe(map(res => ({
        ...res,
        prompts: res.prompts.map(JobPrompt.deserialize)
      })));
  }

  public getRecentTitles(fieldName: string) {
    let params = new HttpParams();
    params = params.append('fieldName', fieldName);
    return this._http.get<RawJob[]>(`${SERVER_URL}/postings/recent_titles`, { params: params })
      .pipe(map(jobs => jobs.map(Job.deserialize)));
  }

  public getCloneSuggestions(body: any) {
    return this._http.post(`${SERVER_URL}/postings/clone_suggestions`, body);
  }

  updateChatbotPresets(job: Partial<JobForm> | RecursivePartial<Job>, id: string, interviewObj: InterviewScheduleInformation) {
    console.log('SCHEDULER: ', interviewObj);
    return this._http.post(`${SERVER_URL}/postings/chatbot`, { job, id, interviewObj });
  }

  getShortlistByJobId(body: RequestBody.GetShortlistRequest) {
    return this._http.post<GetApplicationsResponse>(`${SERVER_URL}/process/shortlist/${body.jobId}`, body)
      .pipe(map(res => ({
        ...res,
        applications: res.applications.map(Application.deserialize)
      })));
  }

  getShortlistMetadata(jobId: string) {
    return this._http.get<ShortlistMetadataResponse>(`${SERVER_URL}/process/shortlist/${jobId}/metadata`)
      .pipe(map(res => ({
        ...res,
        metrics: new JobMetrics().apply(res.metrics)
      })));
  }

  // latestNotification is used to get the last application that trigger the yellow badge
  // on the history tab in the talent detail pane
  getTalentApplication(profileId: string, latestNotification: boolean, jobId?: string) {
    let url = `${SERVER_URL}/process/talent?id=${profileId}`;
    if (latestNotification) {
      url += `&latestNotification=${latestNotification}`;
    } else if (jobId) {
      url += `&jobId=${jobId}`;
    }
    return this._http.get<{ applications: RawApplication[] }>(url)
      .pipe(map(res => res.applications.length > 0 && Application.deserialize(res.applications[0])));
  }

  getResumePreview(link: String) {
    return this._http.get(`${SERVER_URL}/talent/resume/?link=${link}`, { responseType: 'arraybuffer' });
  }

  getResumePreviewByExternalID(externalId: string, linkOnly: true): Observable<{ resumeUrl: string }>;
  getResumePreviewByExternalID(externalId: string, linkOnly?: false): Observable<ArrayBuffer>;
  getResumePreviewByExternalID(externalId: string, linkOnly = false) {
    return linkOnly
      ? this._http.get(`${SERVER_URL}/talent/resume_by_id?externalId=${externalId}&linkOnly=true`)
      : this._http.get(`${SERVER_URL}/talent/resume_by_id?externalId=${externalId}`, { responseType: 'arraybuffer' });
  }

  scheduleChatbotInterview(interview: ScheduleCandidate) {
    return this._http.post(`${SERVER_URL}/talent/schedule/interview`, interview);
  }

  scheduleChatbotPrescreening(prescreening: ScheduleChatbotPrescreeningRequest) {
    return this._http.post(`${SERVER_URL}/talent/schedule/prescreening`, prescreening);
  }

  getApplicant(ids: { profileID: string, frontOfficeID?: string }) {
    return this._http.post<ElasticData<RawProfile>[]>(`${SERVER_URL}/talent/getProfile`, ids)
      .pipe(map(res => res.map(e => Profile.deserialize(e._source))));
  }

  //Getting existing user actions - DF044-9338
  getUserActions(userAction: UserAction) {
    return this._http.post<UserActionActivity>(`${SERVER_URL}/talent/getUserActions`, userAction);
  }

  //Updating existing actions on profiles - DF044-9338
  updateUserActions(updateUserActions: UserAction) {
    return this._http.post<UserActionActivity>(`${SERVER_URL}/talent/updateUserActions`, updateUserActions);
  }
  //Creating new actions on profiles - DF044-9338
  createUserActions(createNewActions: UserAction) {
    return this._http.post<UserActionActivity>(`${SERVER_URL}/talent/createUserActions`, createNewActions);
  }

  // searchProfiles(query: RequestBody.SearchProfilesRequest) {
  //   return this._http.post<SearchTalentResponse>(`${SERVER_URL}/talent/search`, query)
  //     .map(res => ({
  //       ...res,
  //       profiles: res.profiles.map(GoogleProfile.deserialize)
  //     }));
  // }

  checkShortlisted(jobId: string, profileId: string) {
    return this._http.post<{ check: boolean, isRejectedPotential: boolean }>(`${SERVER_URL}/process/talent/check`, { jobId, profileId });
  }

  checkManyShortlisted(jobId: string, profileIds: string[]) {
    return this._http.post<{ check: string[] }>(`${SERVER_URL}/process/talent/checkmany`, { jobId, profileIds });
  }

  updateProfile(update: Partial<Profile>, id: string) {
    return this._http.post(`${SERVER_URL}/talent/updateProfile`, { update, id });
  }

  //! Currently unused function
  public getMetrics(paramsList: any): Observable<any> {
    return this._http.post(`${SERVER_URL}/talent/metrics`, paramsList);
  }

  /**
   * Get the recent searches for the provided email address
   * @param email {string}
   * @returns {Promise<ElasticRecentSearch[]}
   */
  getRecentSearches(email: string, type: string = 'talentSearch') {
    if (type === 'jobSearch') {
      return this._http
        .get<any[]>(`${SERVER_URL}/postings/searches/recent/${email}`)
    } else {
      return this._http.get<any[]>(`${SERVER_URL}/talent/searches/recent/${email}`)
        .pipe(map(results => results.map(v => ElasticRecentSearch.deserialize(v))));
    }
  }

  /**
   * Create a saved search
   * @param payload {ElasticSavedSearch}
   */
  createSavedSearch(payload: ElasticSavedSearch) {
    return this._http.post(`${SERVER_URL}/talent/searches/saved`, payload)
      .pipe(map(res => ElasticSavedSearch.deserialize(res)));
  }

  createJobSavedSearch(payload: any) {
    return this._http.post(`${SERVER_URL}/postings/searches/saved`, payload);
  }

  /**
   * Update a saved search
   * @param payload {ElasticSavedSearch}
   * @return {Promise<ElasticSavedSearch}
   */
  updateSavedSearch(payload: ElasticSavedSearch) {
    return this._http.put(`${SERVER_URL}/talent/searches/saved`, payload)
      .pipe(map(res => ElasticSavedSearch.deserialize(res)));
  }

  updateJobSavedSearch(payload: any) {
    return this._http.put(`${SERVER_URL}/postings/searches/saved`, payload)
  }

  /**
   * Get saved searches for the provided email address
   * @param email {string}
   * @returns {Promise<ElastSavedSearch[]>}
   */
  getSavedSearches(email: string, type: string = "talentSearches") {
    if (type === "jobSearches") {
      return this._http.get<any[]>(
        `${SERVER_URL}/postings/searches/saved/${email}`
      );
    } else {
      return this._http
        .get<any[]>(`${SERVER_URL}/talent/searches/saved/${email}`)
        .pipe(map((results) =>
        results.map((v) => ElasticSavedSearch.deserialize(v))
      ));
    }
  }

 /**
  * Get Potential Talent Dupes
  * @param payload { familyName, givenName, lob}
  *
  */
  getDuplicateTalent(payload: any) {
    return this._http.post<any[]>(`${SERVER_URL}/talent/profile/getDuplicateProfiles`, payload)
    .pipe(map(res => res.map(GoogleProfile.deserialize)));
   }



  /**
   * Delete the saved search with the provided id
   * @param id {string}
   */
  deleteSavedSearch(id: string, type: string = "talentSearch") {
    if (type === "jobSearch") {
      return this._http.delete(`${SERVER_URL}/postings/searches/saved/${id}`);
    } else {
      return this._http
        .delete<DeletedResponse>(`${SERVER_URL}/talent/searches/${id}`)
        .pipe(map((res) => res._id));
    }
  }

  updateApplication(application: UpdateApplicationRequest) {
    let url = `${SERVER_URL}/process/update`;
    return this._http.put(url, application);
  }

  createNewList(listObject: any) {
    return this._http.post<CreatedResponse>(`${SERVER_URL}/talent/createList`, listObject);
  }

  searchList(listId: string, options?: PaginationOptions): Observable<List> {
    let query = '';
    if (options) {
      query = '?' + (<(keyof PaginationOptions)[]>Object.keys(options)).map(key => `${key}=${options[key]}`).join('&');
    }
    return this._http.get<RawList>(`${SERVER_URL}/talent/getList/${listId}${query}`)
      .pipe(map(List.deserialize));
  }

  searchAllPrivateLists(): Observable<List[]> {
    return this._http.get<RawList[]>(`${SERVER_URL}/talent/getAllLists`)
      .pipe(map(lists => lists.map(List.deserialize)));
  }

  searchAllSharedLists(): Observable<List[]> {
    return this._http.get<RawList[]>(`${SERVER_URL}/talent/getAllSharedLists`)
      .pipe(map(lists => lists.map(List.deserialize)));
  }

  searchRecentActivityLists(activityRequest: any): Observable<any> {
    return this._http.post(`${SERVER_URL}/talent/getAllSharedLists`, activityRequest);

  }


  getRecentActivityLists(activityRequest: any): Observable<RecentActivityListResponse>  {

    return this._http.post<RawRecentActivityListResponse>(`${SERVER_URL}/aggregate/recentActivitiesLists`, activityRequest)
      .pipe(map(function(res) {
        return {
        ...res,
          profiles: res.profiles.map(Profile.deserialize),
          activities: res.activities.map(ActivityNote.deserialize)
        };
      } ));
  }

  deleteList(id: string, listName: string) {
    return this._http.delete<DeletedResponse>(`${SERVER_URL}/talent?id=${id}&name=${listName}`);
  }

  updateList(updatedList: List, event: any): Observable<any> {
    const body = { updatedList, event };

    return this._http.put(`${SERVER_URL}/talent/`, body);
  }

  getListNamesSuggestions(query: string, list: string) {
    return this._http.get<{ pipelineNames: { name: string }[] }>(`${SERVER_URL}/talent/listNameSuggest/${query}?list=${list}`)
      .pipe(map(res => res && res.pipelineNames));
  }

  getListByName(query: string, list: string) {
    return this._http.get<{ pipelines: RawList[] }>(`${SERVER_URL}/talent/getListByName/${query}?list=${list}`)
      .pipe(map(res => res && res.pipelines.map(List.deserialize)));
  }

  getRecruiters(term: string, options: {recruiterIds?: string[]} = null, firstName: string, lastName: string, lob: string = 'RT') {
    let params = new HttpParams();
    if (options && options.recruiterIds) params = params.append('recruiterIds', `${options.recruiterIds}`);
    return this._http.get<{ userObjects: RawUser[] }>
      (`${SERVER_URL}/users/recruiters?searchTerm=${term}&firstName=${firstName}&lastName=${lastName}&lob=${lob}`, { params })
      .pipe(map(res => res.userObjects.map(User.deserialize)));
  }

  updateUser(user: User) {
    return this._http.post(`${SERVER_URL}/users/update`, user);
  }

  searchUsers(searchTerm: string, activeOnly: boolean = false) {
    return this._http.get<{ userObjects: RawUser[] }>(`${SERVER_URL}/users/search?term=${searchTerm}&activeOnly=${activeOnly}`)
      .pipe(map(res => res.userObjects.map(User.deserialize)));
  }

  async getUsersByIds(userIDs: string[], includeRoles: boolean, activeOnly: boolean = false, emails?: string[]) {
    const response = await this._http.post<{ profiles: RawUser[] }>(`${environment.apiUrl}/users/getUsersById`, { userIDs, emails, includeRoles }).toPromise();
    console.log(response);
    return response.profiles.map(User.deserialize) || [];
    //return response.profiles || [];
      // .pipe(map(res => res && res.profiles ? res.profiles.map(User.deserialize) : []));
  }

  /**
   * API call to retrieve all applications given a talent profile ID.
   * @param body - request that implements TalentHistoryRequest interface.
   */
  getUserHistory(body: TalentHistoryRequest) {
    return this._http.post<TalentHistoryResponse>(`${SERVER_URL}/aggregate/history`, body);
  }

  /**
   * API call to retrieve recruitment metrics information
   * @param {TalentHistoryRequest} body
   */
  getUserRecruitmentHistory(body: TalentHistoryRequest) {
    return this._http.post<{ metrics: TalentMetricsActivity<any>[] }>(`${SERVER_URL}/aggregate/history/recruitment`, body);
  }

  /**
   * API call to retrieve the recruitment metrics numbers only
   * @param {TalentMetricsRequest} body
   */
  getUserRecruitmentMetrics(body: TalentMetricsRequest) {
    return this._http.post<{ metrics: TalentMetricsTypeCount[] }>(`${SERVER_URL}/aggregate/history/recruitment/metrics`, body);
  }

  getFirestoreToken() {
    return this._http.post<string>(`${SERVER_URL}/firestore/token`, {});
  }

  getUser() {
    return this._http.get<RawUser>(`${environment.apiUrl}/users`)
      .pipe(map(User.deserialize));
  }

  postMetrics(events: MetricsEvent[]) {
    return this._http.post(`${environment.apiUrl}/metrics`, { events });
  }

  // postEvents(events: ClientEvent[]) {
  //   return this._http.post(`${environment.apiUrl}/events`, { clientEvents: events });
  // }

  // New Model S api POC
  searchProfiles(body: any, selectedTalent?: string) {
    return this._http.post<SearchTalentResponse>(
      `${SERVER_URL}/talent/searchProfiles`, Object.assign({ selectedTalent }, body)
    ).pipe(map(res => ({
      ...res,
      profiles: res.profiles.map(GoogleProfile.deserialize),
      explain: res.explain ? JSON.parse(res.explain) : ''
    })));
    /*return this._http.get<any[]>('http://localhost:4200/assets/profiledata.json').map(
      (res: any) => ({
        ...res,
        profiles: res.profiles.map(GoogleProfile.deserialize)
      })
    ) */
  }

  getExplainCard(body: string) {
    return this._http.post(`${SERVER_URL}/talent/profile/getExplainCard`, { body })
  }

  // //New Model S api POC
  // searchProfilesPOC(query: RequestBody.SearchProfilesRequest) {
  //   return this._http.post<SearchTalentResponse>(`${SERVER_URL}/talent/searchProfiles`, query)
  //     .map(res => ({
  //       ...res,
  //       profiles: res.profiles.map(GoogleProfile.deserialize)
  //     }));
  // }

  // //New Model S api POC
  // searchKnownTalent(body: any) {
  //   return this._http.post<SearchTalentResponse>(`${SERVER_URL}/talent/searchProfiles`, body)
  //     .map(res => ({
  //       ...res,
  //       profiles: res.profiles.map(GoogleProfile.deserialize)
  //     }));
  // }

  /**
   * Access point to the GET /users/recruiter-hierarchy endpoint.
   * @param params
   */
  getRecruiterHierarchy(params: RecruiterHierarchy.GetRequest) {
    const baseUri = `${environment.apiUrl}/users/recruiter-hierarchy`;
    let query = `?operator=${params.operator}`;

    if (params.backOfficeIds && params.backOfficeIds.length) {
      query = `${query}&backOfficeIds=${params.backOfficeIds.join(',')}`;
    }

    if (params.frontOfficeIds && params.frontOfficeIds.length) {
      query = `${query}&frontOfficeIds=${params.frontOfficeIds.join(',')}`;
    }

    if (params.emailAddresses && params.emailAddresses.length) {
      query = `${query}&emailAddresses=${params.emailAddresses.join(',')}`;
    }

    if (params.businessUnits && params.businessUnits.length) {
      query = `${query}&businessUnits=${params.businessUnits.join(',')}`;
    }

    if (params.subUnits && params.subUnits.length) {
      query = `${query}&subUnits=${params.subUnits.join(',')}`;
    }

    if (params.recruitingDirectorEmails && params.recruitingDirectorEmails.length) {
      query = `${query}&recruitingDirectorEmails=${params.recruitingDirectorEmails.join(',')}`;
    }

    return this._http.get<RecruiterHierarchy.GetResponse>(`${baseUri}${query}`);
  }

  getTalentMetricsNew(body: GetTalentMetricsRequest): Observable<any> {
    return this._http.post<any>(`${environment.apiUrl}/aggregate/metrics/talent`, body)
      .pipe(map(res => {
        return {
          metrics: new JobMetrics().apply(res.metrics),
          applications: res.applications && res.applications.map(Application.deserialize),
          postings: res.postings && res.postings.map(Job.deserialize),
          appointments: res.appointments && res.appointments.map(Appointment.deserialize),
          pipelines: res.pipelines && res.pipelines.map(List.deserialize)
        }
      }));
  }

  uploadResume(file: File) {
    const formData = new FormData();
    formData.append('resume', file, file.name);
    return this._http.post(`${environment.apiUrl}/upload/resume`, formData);
  }

  uploadImage(file: File) {
    const formData = new FormData();
    formData.append('image', file, file.name);
    return this._http.post(`${environment.apiUrl}/upload/image`, formData);
  }

  updateUserPreferences(user: User) {
    return this._http.post<RawUser>(`${environment.apiUrl}/users/updatePreferences`, user)
      .pipe(map(User.deserialize));
  }

  vmsSettingsAddClient ( client: any) {
    return this._http.post(`${environment.apiUrl}/postings/vms/settings`, client);
  }

  vmsSettingsDeleteClient (client: any) {
    return this._http.delete(`${environment.apiUrl}/postings/vms/settings?id=${client.CUST_ID}`);
  }

  setVmsInboxOrder(newOrder: string[]) {
    return this._http.post(`${environment.apiUrl}/postings/vms/set_column_order`, newOrder);
  }

  getEmailPreferences(emailList: string[]): Observable<EmailPreferences> {
    const formData = { '_id': emailList };
    return this._http.post<any>(`${environment.apiUrl}/users/emailPreferences`, formData);
    //return this._http.get<EmailPreferences>('http://localhost:4200/assets/emailPrefData.json');
  }

  postMassEmail(emailReq: MassEmail.MassEmailRequest ) {
    return this._http.post<any>(`${environment.apiUrl}/notification/massEmail`, emailReq);
  }

  postMassText(emailReq: MassSms.MassSmsRequest ) {
    return this._http.post<any>(`${environment.apiUrl}/notification/massSms`, emailReq);
  }

  postMassAppointment(emailReq: SendInviteToRCERequest) {
    return this._http.post<SendInviteToRCERequest>(`${environment.apiUrl}/notification/massAppointment`, emailReq);
  }

  updateAttachment(jobAttachment: any) {
    return this._http.post<any>(`${environment.apiUrl}/postings/attachment/update`, jobAttachment);
  }

  deleteAttachment(jobAttachment: any) {
    return this._http.post<any>(`${environment.apiUrl}/postings/attachment/delete`, jobAttachment);
  }

  uploadJobAttachment() {
    return `${environment.apiUrl}/postings/attachment/upload`;
  }

  uploadURL() {
    return `${environment.apiUrl}/talent/uploadFile`;
  }

  deleteFile(url: string) {
    // console.log('deleting resume with url: ', url)
    return this._http.post(`${environment.apiUrl}/talent/resume/delete`, { url });
  }

  createTalentProfile(profile: any) {
    return this._http.post(`${environment.apiUrl}/talent/profile/create`, profile)
  }

  editTalentProfile(profile: any) {
    // when we edit profile should always have a name
    if (profile.name) { //  Attempt to fix production issue https://global-jira.randstadservices.com/browse/DF044-8997
      return this._http.patch(`${environment.apiUrl}/talent/profile/edit`, profile);
    } else {
      return throwError(new HttpErrorResponse({error: 'Seems like there is an issue with this profile. Please refresh the page and try again'}));
    }
  }

  getSmsOptInPreferences(frontOfficeIDs: string[]) {
    return this._http.post(`${environment.apiUrl}/talent/profile/smsOptInPreference`, {'frontOfficeIDs': frontOfficeIDs});
  }

  createAppointments(appointments: AllbirdsAppointment[]) {
    const url = `${environment.apiUrl}/process/createAppointments`;
    return this._http.post(url, appointments)
      .pipe(map((res: any) => ({
        ...res,
        appointments: res.appointments.map(AllbirdsAppointment.deserialize)
      })));
  }

  updateAppointmentNotes(updateRequest: {id: string, notes: string}) {
    const url = `${environment.apiUrl}/process/appointment/feedback`;
    const value = this._http.put(url, updateRequest);
    return value;
  }

  updateAppointment(appt: Appointment) {
    const url = `${environment.apiUrl}/process/appointment`;
    return this._http.put(url, appt);
  }

  checkEmailDomain(emailDomain: string) {
    return this._http.get(`${environment.apiUrl}/talent/checkEmailDomain?emailDomain=${emailDomain}`);
  }

  updateFSPrompts ( fsPromptObj: any, allbirdsId: string) {
    return this._http.post(`${environment.apiUrl}/postings/set_fs_questions`, { fsPromptObj, allbirdsId });
  }

  getLinkedJobs ( linked_ab_job_id: any, formVersion?: any) {
    return this._http.post(`${environment.apiUrl}/postings/linked_jobs`, { linked_ab_job_id, formVersion });
  }

}


