import { Applications } from '@allbirds-ui/allbirds-types';
import { createModel, Mapping, Model } from '../model';
import { IsoToMoment } from '../serializers/moment.serializer';
import { Moment } from 'moment';
import { Serial } from '../decorators/serial.decorator';
export import RawInterviewSchedule = Applications.InterviewSchedule;

class InterviewScheduleMapping extends Mapping<RawInterviewSchedule> {
  @Serial(IsoToMoment) skippedDate?: Moment;
  @Serial(IsoToMoment) interviewScheduledOn?: Moment;
  @Serial(IsoToMoment) interviewDate?: Moment;
  @Serial(IsoToMoment) startTime?: Moment;
  @Serial(IsoToMoment) endTime?: Moment;
  @Serial(IsoToMoment) timeSlotSelected?: Moment;
  @Serial(IsoToMoment) inviteOptionFollowingTimestampWhenScheduled?: Moment;
  @Serial(IsoToMoment) canceledDate?: Moment;
}

export interface InterviewSchedule extends Model<InterviewScheduleMapping> {}

export const InterviewSchedule = createModel<InterviewSchedule>(InterviewScheduleMapping);
