
<!--<div class="flex-column talent-metrics-container">-->
<!--  <div *ngIf="loading" class="loading">{{'talent-history.loading' | translate}}</div>-->
<!--  <div class="metrics">-->
<!--&lt;!&ndash;    <canvas baseChart&ndash;&gt;-->
<!--&lt;!&ndash;      [datasets]="barChartData"&ndash;&gt;-->
<!--&lt;!&ndash;      [labels]="barChartLabels"&ndash;&gt;-->
<!--&lt;!&ndash;      [options]="barChartOptions"&ndash;&gt;-->
<!--&lt;!&ndash;      [plugins]="barChartPlugins"&ndash;&gt;-->
<!--&lt;!&ndash;      [legend]="barChartLegend"&ndash;&gt;-->
<!--&lt;!&ndash;      [chartType]="barChartType"&ndash;&gt;-->
<!--&lt;!&ndash;      [colors]="barChartColors"&ndash;&gt;-->
<!--&lt;!&ndash;      (chartClick)="barChartClick($event)">&ndash;&gt;-->
<!--&lt;!&ndash;    </canvas>&ndash;&gt;-->

<!--    <div style="width: 300px; height: 200px; background-color: #F7F7F7; border: solid black 1px">-->
<!--      <div>-->

<!--      </div>-->

<!--    </div>-->
<!--  </div>-->

<!--&lt;!&ndash;  <div class="metrics-footer">&ndash;&gt;-->
<!--&lt;!&ndash;    <a class="openDetailsLink" (click)="openMetricsDetailsClick()" title="See Talent Metrics details">{{'talent-metrics-open-details' | translate}}</a>&ndash;&gt;-->
<!--&lt;!&ndash;  </div>&ndash;&gt;-->
<!--</div>-->
<div class="container rounded"  style="position: relative; max-width: 332px; min-width: 225px; background-color: #F7F7F7; font-size: 14px; float: left">
  <div *ngIf="loading" class="loading">{{'talent-history.loading' | translate}}</div>
  <div class="row mb-2 mt-2" style="color: #6C6C6C;">
    <div class="col">
      HISTORY SUMMARY
    </div>
  </div>
  <div class="row ml-1 border-bottom no-gutters cursor-pointer historyItem" *ngFor="let metrics of this.sortedActivityMetrics" (click)="historyClick(metrics?.name)" [tooltip]="metrics?.name">
    <div class="col-2 float-left"> {{metrics?.count}} </div>
    <div class="col-9 text-truncate">{{metrics?.name}}</div>
    <div class="col-1 float-right"><i class="icon icon-WL_icon_chevron-right mr-3" style="font-size: 12px!important;"></i>  </div>
  </div>
</div>
