<div class="row" *ngIf="show" [class.unfit]="unfit">
  <div *ngFor="let step of steps; let first = first; let last = last;"
       class="step col">

    <!-- Circle & icon for current step. -->
    <div class="icon-circle" *ngIf="step.stepId === talent.randstad_process.lastProcessStepNum">
      <div class="icon">
        <img *ngIf="!unfit" [tooltip]="tooltip" [src]="step.stepIcon" [alt]="step.stepTitle"  />
        <img *ngIf="unfit" src="assets/icons/close-gray.svg" alt="X"  />
      </div>
    </div>

    <!-- Gray circle (for when step not reached) -->
    <div class="future-step-circle" *ngIf="step.stepId > talent.randstad_process.lastProcessStepNum"></div>


    <!-- Blue circle with check mark (completed/skipped step) -->
    <div class="past-step-circle"
         *ngIf="step.stepId < talent.randstad_process.lastProcessStepNum">
        <img class="mt-check" *ngIf="!isSkipped(step.stepField)" src="assets/icons/check-white.svg"  />
        <img class="mt-min" *ngIf="isSkipped(step.stepField)" src="assets/icons/min-white.svg"  />
    </div>

    <!-- The thin bar that extends to the left of a step circle -->
    <div class="bar-left"
         *ngIf="!first"
         [class.bar-unfit]="talent.randstad_process.rejected && step.stepId <= talent.randstad_process.lastProcessStepNum"
         [class.bar-blue]="!talent.randstad_process.rejected && step.stepId <= talent.randstad_process.lastProcessStepNum">
    </div>

    <!-- The thin bar that extends to the right of a step circle -->
    <div class="bar-right"
         *ngIf="!last"
         [class.bar-unfit]="talent.randstad_process.rejected && step.stepId < talent.randstad_process.lastProcessStepNum"
         [class.bar-blue]="!talent.randstad_process.rejected && step.stepId < talent.randstad_process.lastProcessStepNum">
    </div>
  </div> <!-- / .step.col -->
</div> <!-- / .row -->