<div class="modal-header">
  <button type="button" class="btn-reset" aria-label="Close" (click)="_bsModalRef.hide()">
    <i class="icon-WL_icon_close"></i>
  </button>
</div>

<div class="modal-body">

  <h2 class="mb-4">

  <span><img src="../../../../../assets/icons/alert-yellow.svg"
    alt="alert yellow"
    style="display: inherit; margin-bottom: 6.4px; height: 30px;"></span>   Remove all bookmarks ?
  </h2>


  You will not be able to recover these.

</div>

<div id="assign-job-footer" class="modal-footer">
  <button class="btn rds--button--primary" (click)="_bsModalRef.hide()" >cancel</button>
  <button class="btn btn-primary" (click)="remove(); _bsModalRef.hide()" >remove</button>
</div>
