<form [formGroup]="parentForm">
  <ckeditor #editor
    [className]="cssClass || 'custom-padding'"
    [id]="'ckeditor' + formConName"
    (ready)="onReady($event)"
    (change)="onDataChange($event)"
    [config]="ckConfig"
    [formControlName]="formConName">
  </ckeditor>
</form>
