import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Google} from '@allbirds-ui/allbirds-types';
import moment from 'moment';
import {BsModalRef} from 'ngx-bootstrap/modal';
import { Subscription, noop } from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ApiService} from 'src/app/shared/services/api/api.service';
import {AuthService} from 'src/app/shared/services/auth/auth.service';
import {FormValidationService} from 'src/app/shared/services/form-validation/form-validation.service';
import {JobOrderService} from 'src/app/shared/services/job-order/job-order.service';
import {LoadingSpinnerService} from 'src/app/shared/services/loading-spinner/loading-spinner.service';
import {ToastClass, ToastService} from 'src/app/shared/services/toast';
import {Job} from '../../../../../models/external/job.model';
import {TalentActivity} from '../../../../../models/external/talent-activity.model';
import {
  EmailRecipient,
  NotificationData,
  NotificationTypes
} from '../../../../../models/internal/notifications-data.model';
import {ShouldWarnUnsaved} from '../../../../../services/modal/modal.service';
import {NotificationsService} from '../../../../../services/notifications/notifications.service';
import {htmlToElement} from 'src/app/shared/services/utility/formatters';
import {UtilityService} from '../../../../../services/utility/utility.service';
import {TalentHistoryService} from '../../../talent-history/talent-history.service';
import {RGS_NOTE_TYPES, RT_NOTE_TYPES, SOCIAL_MEDIA_TYPES} from './note.types';
import { ROUTE_TO_KEY as TalentDrilldownRoutes } from '../../../../../../modules/talent/pages/talent-management-listings/talent-management.mappings';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'app-log-note-modal',
  templateUrl: './log-note-modal.component.html',
  styleUrls: ['./log-note-modal.component.scss']
})
export class LogNoteModalComponent implements OnInit, ShouldWarnUnsaved, AfterViewInit {

  talent: any;
  jobOrder: Job;
  noteForm: FormGroup;
  noteFormSub: Subscription;
  noteTypes: any = null;
  socialMediaTypes: any = null;
  isAppointment: boolean = false;
  appointmentHandler: any;
  /* The default note type is required
  for preselection of note type for appointments */

  defaultNoteType: string;

  /* This is a function which adds appointment feedback notes to appointments
   * Used only in case of adding appointments
   */
  handleAddNotesToAppointment: any;
  appointmentId: string;

  get isUserRgs() {return this.auth.user.Source.checkLob('RGS'); }


  constructor(
    public _bsModalRef: BsModalRef,
    private _formBuilder: FormBuilder,
    private _api: ApiService,
    private _jobOrder: JobOrderService,
    private _talentHistory: TalentHistoryService,
    private _toast: ToastService,
    private _loading: LoadingSpinnerService,
    private auth: AuthService,
    private _notificationService: NotificationsService
  ) { }

  ngOnInit() {
    console.log('LogNoteModalComponent', this.talent, this.handleAddNotesToAppointment, this.appointmentId);
    this.jobOrder = this._jobOrder.storedJobOrder;
    this.setNoteTypes();
    this.createForm();
    this.setSocialMediaTypes();
  }

  ngAfterViewInit() {
    if (this.isAppointment) {
      if (this.auth.user.Source.checkLob('RT', 'RE','CR')) {
        this.noteForm.get('activityType').setValue('Connect');
      } else {
        this.noteForm.get('activityType').setValue('TCALL');
      }
    }
    this.defaultNoteType ? this.noteForm.controls['activityType'].setValue(this.defaultNoteType): noop;
  }

  setNoteTypes() {
    this.noteTypes = this.auth.user.Source.checkLob('RT', 'RE','CR') ? RT_NOTE_TYPES : RGS_NOTE_TYPES;
  }

  setSocialMediaTypes() {
    this.socialMediaTypes = this.auth.user.Source.checkLob('RGS') ?  SOCIAL_MEDIA_TYPES : null;
  }

  createForm() {
    this.noteForm = this._formBuilder.group({
      activityType: [null, Validators.compose([Validators.required, FormValidationService.validateNull])],
      socialMediaType: [null],
      socialMediaOtherInput: [null],
      comments: [null, [Validators.required]]
    });

    if (this.auth.user.Source.checkLob('RGS')) {

      this.noteForm.controls.activityType.valueChanges.subscribe(value => {
        if (this.isSocialType(value)) {
          this.noteForm.controls.socialMediaType.setValidators(Validators.compose([Validators.required, FormValidationService.validateNull]));
          this.noteForm.controls.socialMediaType.updateValueAndValidity();
        } else {
          this.noteForm.controls.socialMediaType.clearValidators();
          this.noteForm.controls.socialMediaType.updateValueAndValidity();
        }
      });

      this.noteForm.controls.socialMediaType.valueChanges.subscribe(value => {
        if (this.isSocialTypeOther(value)) {
          this.noteForm.controls.socialMediaOtherInput.setValidators(Validators.compose([Validators.required, FormValidationService.validateNull]));
          this.noteForm.controls.socialMediaOtherInput.updateValueAndValidity();
        } else {
          this.noteForm.controls.socialMediaOtherInput.clearValidators();
          this.noteForm.controls.socialMediaOtherInput.updateValueAndValidity();
        }
      });
    }

  }

  isUnsaved() {
    return this.noteForm.dirty;
  }

  get requestBody(): {body: TalentActivity, mentions: EmailRecipient[]} {
    const now = moment();
    const { activityType: activityTypeControl, socialMediaType: socialMediaTypeControl, socialMediaOtherInput: socialMediaOtherControl } = this.noteForm.controls;
    const activityType: string = activityTypeControl.value && activityTypeControl.value.includes('Resume') ? 'Resume' : activityTypeControl.value;
    const socialMediaType: string = socialMediaTypeControl.value;
    const socialMediaOther: string = socialMediaOtherControl.value;
    const rawBody: any = new TalentActivity(this.noteForm.getRawValue()).apply({
      activityType,
      moEmplId: this.isAppointment ? this.talent.external_id : this.talent.externalId,
      moCustomerId: this.jobOrder.allbirds_metadata.customer_id,
      // conditionally apply the moOrderId based on if this is in the context of a job.
      ...(this.isJobContext() && { moOrderId: this.jobOrder.allbirds_metadata.front_office_id }),
      moContactId: this.jobOrder.allbirds_metadata.contact_id,
      pipeline: (this.jobOrder.allbirds_metadata.order_type == 'Pipeline') ? true : false,
      branch: this.jobOrder.allbirds_metadata.user_branch_id,
      timestamp: now,
      activityTimestamp: now
    });
    rawBody.social_media = socialMediaType || null;
    rawBody.social_media_oth = socialMediaOther || null;
    const mentions = UtilityService.getMentionsRecipients(rawBody.comments);
    rawBody.comments = UtilityService.getCkEditorTextOnly(rawBody.comments);
    return {body: rawBody, mentions: mentions};
  }

  submitForm() {
    // Touch all fields.
    for (const property in this.noteForm.controls) {
      if (this.noteForm.controls.hasOwnProperty(property)) {
        this.noteForm.controls[property].markAsTouched();
      }
    }
    // If form isn't valid, cancel execution.
    if (!this.noteForm.valid) {
      return;
    }
    // Get the request body.
    const requestBody = this.requestBody;
    const mentions = requestBody.mentions;
    const body = requestBody.body;
    const noteType = this.noteForm.controls.activityType.value;
    // Make the API call.
    this._loading.show();
    this._api.logTalentActivity(body)
      .subscribe(res => {
        this._talentHistory.addNoteToFeed(body);
        if(this.handleAddNotesToAppointment) {
          this.handleAddNotesToAppointment(this.appointmentId, body.comments);
        }
        this._toast.showToast('log-note-modal.success', { cssClass: ToastClass.SUCCESS });
        if (this.isAppointment && this.appointmentHandler) {
          this.appointmentHandler(this.noteForm.value);
        }
        this.noteForm.reset();
        this._bsModalRef.hide();
        this._loading.hide();
        if (mentions && mentions.length) {
          this.sendNotifications(mentions, body.comments, noteType);
        }
        this._toast.showToast('The note is successfully logged', {cssClass: ToastClass.SUCCESS});
      }, err => {
        this._toast.showToast('log-note-modal.error', { cssClass: ToastClass.DANGER });
        console.error(err);
        this._loading.hide();
      });
  }

  sendNotifications(recipients: EmailRecipient[], comment: string, noteType: string) {
    const emailAddressOnToLine = recipients? recipients.splice(0,1) : []
    const notificationBody: NotificationData = {
      notificationType: NotificationTypes.LOG_A_NOTE,
      notificationObject: {
        title: this.jobOrder.internalTitle,
        candidateFullName: `${this.talent.personNames[0].structuredName.givenName} ${this.talent.personNames[0].structuredName.familyName}`,
        published_by_user: this.jobOrder.allbirds_metadata.published_by_user,
        published_by_user_email: this.jobOrder.allbirds_metadata.published_by_user_email,
        allbirds_job_id: this.jobOrder.allbirds_metadata.allbirds_job_id,
        executing_action_user: this.auth.user.FullName,
        executing_action_user_email: this.auth.user.EmailAddr,
        customer_name: this.jobOrder.allbirds_metadata.customer_name,
        front_office_id: this.jobOrder.allbirds_metadata.front_office_id,
        user: {...this.auth.user},
        emailRecipients: emailAddressOnToLine,
        emailCc:recipients,
        noteType: this.getFriendlyNoteType(noteType),
        noteComment: comment,
        contextUrl: this.getContextUrl(location.pathname, location.search),
      }
    };
    this._loading.show();
    const sub = this._notificationService.sendNotification(notificationBody)
      .pipe(catchError((err) => {
        console.error(err);
        this._loading.hide();
        return err;
      }))
      .subscribe(() => {
        sub.unsubscribe();
        this._loading.hide();
      });
  }

  getFriendlyNoteType(noteType: string) {
    return this.noteTypes[noteType];
  }

  getContextUrl(pathName: string, queryString: string) {
    let contextUrl = pathName.replace(pathName.charAt(0), '');
    const searchParams = new URLSearchParams(queryString);
    if (searchParams.has('st')) {
      if (searchParams.has('shortlist_step')) {
        searchParams.delete('shortlist_step');
      }
      let count = 0;
      searchParams.forEach((value, key) => {
        let urlPart = count > 0 ? '&' : '?';
        urlPart += `${key}=${value}`;
        contextUrl += urlPart;
        count++;
      });
    }
    // console.log('contextUrl=', contextUrl);
    return contextUrl;
  }

  /**
   * Returns true if the modal was opened in a context that would tie it to a job.
   * Needed to determine whether we should send a job reference ID or not in our payload to intg.
   */
  isJobContext(): boolean {
    let result = false;
    const url = this.getContextUrl(location.pathname, location.search);
    const jobRelatedRoutes = Array.from(Object.keys(TalentDrilldownRoutes)).concat(['jobs']);
    for (const r of jobRelatedRoutes) {
      if (url.includes(r)) {
        result = true;
        break;
      }
    }
    return result;
  }

  /**
   * Comparator function for the keyvalue pipe that sorts the notes alphabetically by value instead of key.
   * @param a note
   * @param b note
   */
  noteSortAlphabetical(a: KeyValue<string, string>, b: KeyValue<string, string>): number {
    return a.value.localeCompare(b.value);
  }

  isSocialType (type: any) {
    return ['TSOC', 'TSOCO', 'TSMPC'].includes(type);
  }

  isSocialTypeOther (type: any) {
    return type === 'OTH';
  }
}



