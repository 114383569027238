import { Pipe, PipeTransform } from '@angular/core';
import { Moment } from 'moment';

@Pipe({
  name: 'abDate'
})
export class ABDatePipe implements PipeTransform {
  transform(input: Moment, format?: string): string {
    if (!input) {
      return 'None';
    }
    return input.formatDate(format);
  }
}
