import { Component, OnInit, Input } from "@angular/core";
import { EmailRecipient } from "../../models/internal/notifications-data.model";

@Component({
  selector: "app-email-recipients-pill",
  templateUrl: "./email-recipients-pill.component.html",
  styleUrls: ["./email-recipients-pill.component.scss"]
})
export class EmailRecipientsPillComponent implements OnInit {
  @Input() emailRecipient: EmailRecipient;
  @Input() emailRecipients: EmailRecipient[];
  @Input() index: number;

  constructor() {}

  ngOnInit() {}

  deleteRecipient() {
    this.emailRecipients.splice(this.index, 1);
  }
}
