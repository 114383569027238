export enum TALENT_CREATION_FORM_SECTIONS {
  PHONE = 'PHONE',
  WORK_EXPERIENCE = 'WORK_EXPERIENCE',
  PREFERENCES = 'PREFERENCES',
  PRIOR_WORK_HISTORY = 'PRIOR_WORK_HISTORY',
  EDUCATION = 'EDUCATION'
}

export const WORK_AUTH_STATUS: WORK_AUTH_STATUS = {
'US Citizen': 'US without sponsorship',
'Green Card': 'US without sponsorship',
'H1B Visa': 'US with sponsorship',
'EAD - Category C09': 'US without sponsorship',
' EAD - Others': 'US without sponsorship',
'TN Visa': 'US without sponsorship',
'F1 - CPT': 'US with sponsorship',
'F1 Student Visa': 'US with sponsorship',
'OPT': 'US with sponsorship',
'E3': 'US with sponsorship',
'Requires Sponsorship': 'US with sponsorship',
'Unknown': 'Unknown',
};

interface WORK_AUTH_STATUS {
[prop: string]: string;
}

export const desiredLocationStates: string[] = [
'Alabama',
'Alaska',
'Arizona',
'Arkansas',
'California',
'Colorado',
'Connecticut',
'Delaware',
'District of Columbia',
'Florida',
'Georgia',
'Hawaii',
'Idaho',
'Illinois',
'Indiana',
'Iowa',
'Kansas',
'Kentucky',
'Louisiana',
'Maine',
'Maryland',
'Massachusetts',
'Michigan',
'Minnesota',
'Mississippi',
'Missouri',
'Montana',
'Nebraska',
'Nevada',
'New Hampshire',
'New Jersey',
'New Mexico',
'New York',
'North Dakota',
'North Carolina',
'Ohio',
'Oklahoma',
'Oregon',
'Pennsylvania',
'Rhode Island',
'South Carolina',
'South Dakota',
'Tennessee',
'Texas',
'Utah',
'Vermont',
'Virginia',
'Washington',
'West Virginia',
'Wisconsin',
'Wyoming',
'Anywhere',
'Remote'
];

interface UsStates {
[key: string]: string;
}

export const usStates: UsStates = {
'Alabama' : 'AL',
'Alaska' : 'AK',
'American Samoa' : 'AS',
'Arizona' : 'AZ',
'Arkansas' : 'AR',
'California' : 'CA',
'Colorado' : 'CO',
'Connecticut' : 'CT',
'Delaware' : 'DE',
'District Of Columbia' : 'DC',
'Federated States Of Micronesia' : 'FM',
'Florida' : 'FL',
'Georgia' : 'GA',
'Guam' : 'GU',
'Hawaii' : 'HI',
'Idaho' : 'ID',
'Illinois' : 'IL',
'Indiana' : 'IN',
'Iowa' : 'IA',
'Kansas' : 'KS',
'Kentucky' : 'KY',
'Louisiana' : 'LA',
'Maine' : 'ME',
'Marshall Islands' : 'MH',
'Maryland' : 'MD',
'Massachusetts' : 'MA',
'Michigan' : 'MI',
'Minnesota' : 'MN',
'Mississippi' : 'MS',
'Missouri' : 'MO',
'Montana' : 'MT',
'Nebraska' : 'NE',
'Nevada' : 'NV',
'New Hampshire' : 'NH',
'New Jersey' : 'NJ',
'New Mexico' : 'NM',
'New York' : 'NY',
'North Carolina' : 'NC',
'North Dakota' : 'ND',
'Northern Mariana Islands' : 'MP',
'Ohio' : 'OH',
'Oklahoma' : 'OK',
'Oregon' : 'OR',
'Palau' : 'PW',
'Pennsylvania' : 'PA',
'Puerto Rico' : 'PR',
'Rhode Island' : 'RI',
'South Carolina' : 'SC',
'South Dakota' : 'SD',
'Tennessee' : 'TN',
'Texas' : 'TX',
'Utah' : 'UT',
'Vermont' : 'VT',
'Virgin Islands' : 'VI',
'Virginia' : 'VA',
'Washington' : 'WA',
'West Virginia' : 'WV',
'Wisconsin' : 'WI',
'Wyoming' : 'WY'
};

export const BH_SOURCES: string[] = [
'Bullhorn',
'Monster Resume Search',
'Dice Resume Feed',
'LinkedIn',
'LinkedIn Recruiter',
'Craigslist',
'Facebook',
'Github',
'Indeed',
'Job Fair',
'Marketing Campaign',
'Networking Event',
'Payroll Deal',
'Referral',
'Randstad Revenue Sharing',
'Recruiter Workbench',
'Stack Overflow',
'Texas Workforce Commission',
'Third Party',
'Twitter',
'WITI',
'TalentBin',
'Dice',
'Dice Open Web',
'CareerBuilder Resume Search',
'ClearanceJobs',
'Elite',
'VCOE',
'VCOE Wounded Warrior Project',
'VCOE MOAA',
'VCOE Vetlanta',
'VCOE Cisco',
'VCOE Hire Military',
'VCOE Hiring our Heroes',
'VCOE MSSA',
'VCOE ACI',
'Xplore Search',
'Xplore Match',
'Transcend',
'Other',
];

export const BH_DISABLED_SOURCES: string[]=[
  'VCOE MOAA',
  'VCOE Vetlanta',
  'VCOE Hire Military'
]

export const RFO_SOURCES: string[] = [
  'Indeed',
  'Indeed Search',
  'Indeed Ad',
  'Social Media',
  'CareerBuilder',
  'CareerBuilder Search',
  'Linkedin',
  'LinkedIn Search',
  'LinkedIn Profile Post',
  'LinkedIn Ad',
  'ZipRecruiter',
  'ZipRecruiter Resume Search',
  'ZipRecruiter Ad',
  'Monster Search',
  'Monster Resume Feed',
  'Monster',
  'Xplore Search',
  'Xplore Match',
  'Referral',
  'Walk In',
  'Hiring Event/Job Fair',
  'Transcend'
];

export const RFO_DISABLED_SOURCES: string[] =[
  'Indeed',
  'CareerBuilder',
  'Linkedin',
  'ZipRecruiter',
  'Monster Resume Feed',
  'Monster',
  'Xplore Search',
  'Xplore Match',
  'Monster Resume Search'
]

/** From this requirements doc:
 * https://docs.google.com/document/d/1d9d2hRFfBd2J4WtxnyWZsb5ifjyf2o8gdF86oWmiiVc/edit.
 * API needs to map these fields to the accepted RFO options...
 * https://docs.google.com/spreadsheets/d/1eueSIPd1P_2IyoqK8oQ37RxvzDh1Gu7wC0wF6Tek5Js/edit#gid=0
 */
export type RecruitingSources = 'Customer' | 'Outside Agency' | 'Randstad Other OPCO' | 'Randstad Talent';

export const RFO_RECRUITING_SOURCES: RecruitingSources[] = [
  'Customer',
  'Outside Agency',
  'Randstad Other OPCO',
  'Randstad Talent'
];

export interface ValidatedFormFields {
  fieldName: string;
  anchor: string;
  errorLabel: string;
  talentFormPath?: string;
}

export const VALIDATED_FORM_FIELDS: ValidatedFormFields[] = [
  { fieldName: 'email', anchor: '#emailHeader', errorLabel: 'Email address' },
  { fieldName: 'firstName', anchor: '#firstSection', errorLabel: 'First name' },
  { fieldName: 'lastName', anchor: '#firstSection', errorLabel: 'Last name' },
  { fieldName: 'employeeType', anchor: '#firstSection', errorLabel: 'Employee Type' },
  { fieldName: 'source', anchor: '#firstSection', errorLabel: 'Source' },
  { fieldName: 'recruitingSource', anchor: '#firstSection', errorLabel: 'Recruiting source' },
  { fieldName: 'recruitingSourceId', anchor: '#firstSection', errorLabel: 'Recruiting source id' },
  { fieldName: 'referredBy', anchor: '#firstSection', errorLabel: 'Referrer name' },
  { fieldName: 'primaryAddress', anchor: '#secondSection', errorLabel: 'Primary Address' },
  { fieldName: 'cityStateZipControl', anchor: '#secondSection', errorLabel: 'City / State / Zip' },
  { fieldName: 'thirdSection', anchor: '#thirdSection', errorLabel: 'Phone Section' },
  { fieldName: 'yearsOfExperienceControl', anchor: '#yearsOfExperience', errorLabel: 'Years of experience' },
  { fieldName: 'priorWorkHistory', anchor: '#priorWorkHistory', errorLabel: 'Prior Work History' },
  { fieldName: 'desiredLocation', anchor: '#preferences', errorLabel: 'Desired location' },
  { fieldName: 'dateAvailable', anchor: '#preferences', errorLabel: 'Date Available' },
  // { fieldName: 'schoolInstitutionName', anchor: '#educationSection', errorLabel: 'School / Institution / Name' },
];

export const VALIDATED_SUBMISSION_VENDOR_FIELDS: ValidatedFormFields[] = [
  { fieldName: 'vendorCompanyName', anchor: '#fifthSection', errorLabel: 'Vendor company name' },
  { fieldName: 'vendorCompanyRandstadApproved', anchor: '#fifthSection', errorLabel: 'Vendor company randstad approved' },
  { fieldName: 'vendorContactPhoneNumber', anchor: '#fifthSection', errorLabel: 'vendor Contact Phone Number' },
  { fieldName: 'willingToTransferDBC', anchor: '#preferences', errorLabel: 'Willing to transfer RITS' },
];

export const VALIDATED_SUBMISSION_FIELDS: ValidatedFormFields[] = [
  ...VALIDATED_FORM_FIELDS,
  { fieldName: 'desiredLocation', anchor: '#preferences', errorLabel: 'Desired location' },
  { fieldName: 'dateAvailable', anchor: '#preferences', errorLabel: 'Date available' },
];


export const RGS_UNAVAILABLE_DOMAINS: string[] = [
  'us.randstad.com',
  'placementpros.com',
  'tempforce.com',
  'tempforce.net',
  'accustaff.com',
  'randstadusa.com'
];

/** RT and RGS include the option to create a talent with
 * source === 'Referral'. If an RGS referral, additional
 * fields are required. Additional fields are optional for RT.
 */
export interface RequiredReferralFields {
  source: boolean; // talent source is always required, both RT and RGS
  recruitingSource: boolean; // RGS - Required if source = 'Referral'
  recruitingSourceId: boolean; // RGS - Required if recruitingSource is 'Customer' or 'Outside Agency' or 'Randstad Other OPCO'
  referredBy: boolean; // RGS - Required if recruitingSource = 'Randstad Talent', optional on RT
  referralNotes: boolean; // RT - Optional
}
