<div class="activity-feed">
  <div style="margin-top:10px;">
    <ng-container *ngIf="activeFilter == 'activities'">
      <span class="contact-type">contact type</span>
    </ng-container>
    <div class="btn-group"  dropdown *ngIf="activeFilter == 'activities'">

      <button class="btn rds--button--ghost btn-sm" id="sortByBtn" aria-controls="dropdown-basic" dropdownToggle>
          {{selectedActivityFilter.displayval}}
        <i class="icon icon-WL_icon_chevron-down pl-1"></i>
      </button>

      <ul id="sortByDropdown" *dropdownMenu class="dropdown-menu"  role="menu" aria-labelledby="button-basic">

        <li role="menuitem" *ngFor="let filter of filters">
          <button class="dropdown-item" (click)="sendFilter(filter)">{{filter.displayval}}
            <ng-container *ngIf="selectedActivityFilter.key == filter.key">
              <img src="assets/icons/check.svg" alt="selected"/>
            </ng-container>
          </button>
        </li>

      </ul>
    </div>
  </div>
  <div *ngIf="displayWarningOrCautionBanner()" class="warning-banner">
    <div class="icon">
      <img class="warning-banner-icon" src="assets/icons/alert-circle-red.svg"/>
    </div>
    <span class="warning-banner-label">{{ 'talent-history-feed.talent_unsatisfactory_ending' | translate }}</span>
  </div>
  <div *ngIf="displayNotificationBanner()" class="notification-banner">
    <div class="icon">
      <img class="notification-banner-icon" src="assets/icons/alert-yellow.svg"/>
    </div>
    <span class="notification-banner-label" >{{ bannerNotificationMessage }}</span>
  </div>
  <p class="no-activity" *ngIf="activities && !activities.length">{{'talent-history-feed.there_is_no' | translate}}</p>
  <!-- For each step of the process... -->
  <ng-container *ngIf="activities && activities.length && activities">
    <div>
    <!-- The vertical line that runs through user avatars. -->
    <div class="line-through"></div>

    <div class="container-fluid" *ngFor="let activity of activities; let first = first;">
      <!-- Rendered for recruitment processes. -->
      <div class="row activity-item" [ngClass]="{ 'pt-0': first }" *ngIf="activity.randstad_process && activity.randstad_process.lastProcessStep">
        <ng-container *ngTemplateOutlet="processActivity;
          context: {
            process: activity,
            step: getStepInfo(activity.randstad_process.lastProcessStep),
            job: getJobInfo(activity.randstad_process.jobElasticID)
        }"></ng-container>
      </div>

      <!-- Rendered for assignments. -->
      <div class="row activity-item" [ngClass]="{ 'pt-0': first }" *ngIf="activity.randstadJobTitle || activity.clientJobTitle">
        <ng-container *ngTemplateOutlet="assignmentActivity;
          context: {
            assignment: activity
        }"></ng-container>
      </div>

    </div>




    </div> <!-- / .container-fluid -->

    <!-- Below is for the activities tab on history -->
    <div class="container-fluid activities-container" *ngIf="activities[0] && activities[0].activityType ">



      <virtual-scroller #scroll
                        [items]="activities"
                        (vsEnd)="handleInfinityScroll($event)">
        <div *ngFor="let activity of scroll.viewPortItems; let first = first;">
          <div class="row activity-item" [ngClass]="{ 'pt-0': first }">
            <ng-container *ngTemplateOutlet="noteActivity;
              context: {
                activity: activity,
                user: getUserInfo(activity.userId)
              }
            "></ng-container>
          </div>
        </div>

        <div class="spinner-container" *ngIf="!_talentHistory.hasErrors && _talentHistory.fetchingMoreActivities">
          <vertical-align>
            <div class="spinner-border custom-spinner text-primary" role="status">
              <span class="sr-only text-center">{{'talent-history.loading' | translate}}</span>
            </div>
          </vertical-align>
        </div>

      </virtual-scroller>
    </div>


  </ng-container>
</div> <!-- / .activity-feed -->

<!-- This template is shown when the activity is related to a user's application process. -->
<ng-template #processActivity let-step="step" let-process="process" let-job="job">
  <div class="col-1 p-0">
    <div class="icon-circle">
      <div class="icon">
        <img *ngIf="!process.randstad_process.rejected && !process.randstad_process.offer" [src]="step?.stepIcon" [alt]="step?.stepTitle"  />
        <img *ngIf="process.randstad_process.rejected" src="assets/icons/close.svg" alt="X"  />
        <img *ngIf="process.randstad_process.offer" src="assets/icons/check.svg"   />
      </div>
    </div>
  </div>
  <div class="col-11">
    <div class="activity-item--body">
      <div class="activity-item-infos">
        <h3 class="activity-item-infos-title color-primary">{{ job?.title || 'Not specified' }}</h3>
        <span *ngIf="isApplicant(process)" class="applicant-label">{{'talent-history-feed.applicant_label' | translate}}</span>
        <span *ngIf="displayNotificationLabel(process)" class="notification-label">{{ getNotificationLabelMessage(process) }}</span>
      </div>
        <h4 class="mb-3">{{ job?.allbirds_metadata.customer_name || 'Not specified' }}</h4>
        <p class="activity-item--status" *ngIf="!process.randstad_process.offer">{{'talent-history-feed.in_process:' | translate}}<span class="color-primary">{{ step?.stepTitle || 'Not specified' }}</span></p>
        <div class="stepper-spacing"></div>
        <app-process-stepper [talent]="process"></app-process-stepper>
    </div>
  </div>
  <div class="modal-launch--container">
    <vertical-align>
      <button class="btn-reset" (click)="openProcessDetailModal(process, job)">
        <img src="assets/icons/chevron.svg" alt="view"  />
      </button>
    </vertical-align>
  </div> <!-- / .modal-launch--container -->
</ng-template>


<!-- This template is shown when the activity is a live assignment. -->
<ng-template #assignmentActivity let-assignment="assignment">
  <div class="col-1 p-0">
    <div class="icon-circle">
      <div class="icon">
        <img src="assets/icons/randstad_klein_primary.svg" alt="RS"  />
      </div>
    </div>
  </div>
  <div class="col-11">
    <div class="activity-item--body">
      <div class="activity-item-infos">
        <h3 class="activity-item-infos-title color-primary">{{ assignment.randstadJobTitle || assignment.clientJobTitle || 'not specified' }}</h3>
        <span *ngIf="isAssignmentRawLabel(assignment)" class="raw-label">RaW</span>
        <span *ngIf="isAssignmentCautionLabel(assignment)" class="caution-label">Caution</span>
      </div>
      <h4 class="mb-3">{{ assignment.client || 'not specified' }}</h4>
      <ul class="job-details-list">
        <li><img src="assets/icons/calendar-gray.svg"  /> {{ getAssignmentDateString(assignment) }}</li>
        <li>
          <img src="assets/icons/pay-gray.svg"  />
          <span *ngIf="assignment.payRate">{{ assignment.payRate | currency }} per {{ assignment.payRate > 999 ? 'year' : 'hour' }}</span>
          <span *ngIf="!assignment.payRate">{{'talent-history-feed.not_specified' | translate}}</span>
        </li>
        <li><img src="assets/icons/calendar-check-gray.svg"  /> {{ (assignment.endReason || 'not specified') | lowercase }} </li>
      </ul>
    </div>
  </div>
</ng-template>

<!-- This template is shown for general talent activity -->
<ng-template #noteActivity let-activity="activity" let-user="user">
  <div class="col-1 p-0">
    <div class="icon-circle icon-circle-sm">
      <div class="icon">
        <img src="assets/icons/notification.svg"  />
      </div>
    </div>
  </div>
  <div class="col-11">
    <div class="activity-item--body">
      <span class="chip chip-info d-inline-block mb-2" *ngIf="activity.activityType">
        {{ (noteTypes[activity.activityType] || activity.activityType) | lowercase }}
      </span>

      <span *ngIf="user" class="d-block">
        <span class="color-primary" [popover]="user.JobTitle" triggers="mouseenter:mouseleave" containerClass="rds-tooltip--dark">{{ user.FirstName }} {{ user.LastName }}</span>&nbsp;{{'talent-history-feed.manually_logged_a' | translate}}</span>

      <span *ngIf="!user" class="d-block">{{'talent-history-feed.a_note_was' | translate}}</span>

      <span class="activity-item--note" *ngIf="activity.comments && activity.activityType !== 'TEML' && activity.activityType !== 'EMAL'" [innerHTML]="activity.comments | snippet: 100">
      </span>

      <!-- Timestamp -->
      <p class="timestamp" *ngIf="activity.activityTimestamp || activity.timestamp">{{ activity.activityTimestamp || activity.timestamp | abDatetime }}</p>
    </div>
  </div>
  <div class="modal-launch--container">
    <vertical-align>
      <button class="btn-reset" (click)="openActivityDetailModal(activity, job, user)">
        <img src="assets/icons/chevron.svg" alt="view"  />
      </button>
    </vertical-align>
  </div> <!-- / .modal-launch--container -->
</ng-template>
