<div *ngIf="results.length > 0 || isLoading()">
  <div class='loading' *ngIf="isLoading()">{{'distribution-list-lookup.loading' | translate}}</div>
  <table *ngIf="!isLoading()">
      <thead>
          <tr>
            <th style="width: 100%;"><h4>{{'distribution-list-lookup.list' | translate}}</h4></th>
          </tr>
      </thead>
      <tbody>
          <tr *ngFor="let list of results" (click)="selectList(list)">
              <td><h4 class="gray-text">{{ list.email }}</h4></td>
          </tr>
      </tbody>
  </table>
</div>

