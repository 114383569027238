import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Appointments, ChatBots } from '@allbirds-ui/allbirds-types';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { filter, flatMap } from 'rxjs/operators';
import {
  LogInterviewForm,
  LogInterviewModalComponent,
  LogInterviewModalConfig
} from 'src/app/shared/components/talent-detail-pane/talent-process/talent-process-modals/log-interview-modal/log-interview-modal.component';
import { LogNoteModalComponent } from 'src/app/shared/components/talent-detail-pane/talent-process/talent-process-modals/log-note-modal/log-note-modal.component';
import { BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { AppointmentFeedback as ApptFeedback } from 'src/app/shared/models/external/appointment/appointment-feedback.model';
import { ListTalent } from 'src/app/shared/models/external/list-talent.model';
import { List } from 'src/app/shared/models/external/list.model';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { AppointmentStatusLabels, ApptStatusMessages } from 'src/app/shared/services/appt-invite/appt-invite.interface';
import { ApptInviteService } from 'src/app/shared/services/appt-invite/appt-invite.service';
import { LoadingSpinnerService } from 'src/app/shared/services/loading-spinner/loading-spinner.service';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { CustomListService } from '../custom-list.service';
import { UserService } from '../../../../../shared/services/user/user.service';
import AppointmentFeedback = Appointments.AppointmentFeedback;
import InterviewStatus = ChatBots.InterviewStatus;
import { AllbirdsAppointment } from 'src/app/shared/models/external/appointment.model';
import { Job } from 'src/app/shared/models/external/job.model';
import { JobOrderService } from 'src/app/shared/services/job-order/job-order.service';
import { CancelInterviewModalComponent } from '../../../../../../app/shared/components/talent-detail-pane/talent-process/recruitment-phases/virtual-interview/cancel-interview-modal/cancel-interview-modal.component';


@Component({
  selector: 'list-info',
  templateUrl: './list-info.component.html',
  styleUrls: ['./list-info.component.scss'],
  encapsulation: ViewEncapsulation.None // For styling [innerHTML]
})
export class ListInfoComponent implements OnInit, OnDestroy, AfterViewInit {
  remarksForm: FormGroup;
  formChangeSub: Subscription;

  @Input() isModal: boolean;
  @Output() formValue = new EventEmitter();

  generalNotesCharsLeft = 140;
  nextActionCharsLeft = 140;
  maxCharLength = 140;
  jobOrder: Job;
  generalNotesSub: Subscription;
  nextActionSub: Subscription;
  bsModalRef: BsModalRef;
  talentSub: Subscription;
  currentTalent: ListTalent;
  trVariable1: any;
  trVariable2: any;
  statusBannerMessage: string;
  statusInfoMessage: string;
  currentTalentSub: Subscription;

  constructor(
    private _api: ApiService,
    private _listService: CustomListService,
    private _jobOrder: JobOrderService,
    private _loading: LoadingSpinnerService,
    private _route: ActivatedRoute,
    private _modalService: ModalService,
    private _apptInvite: ApptInviteService,
    private _user: UserService) { }

  ngOnInit() {
    this.jobOrder = this._jobOrder.storedJobOrder;
    this.remarksForm = new FormGroup({
      generalNotes: new FormControl(''),
      nextAction: new FormControl('')
    });

    this.calculateCharsLeft();
    if (!this.isModal) {
      this.updateFormValuesFromCurrentTalent();
    }
    if (this.isModal) {
      this.onChanges();
    }
    this.trVariable1 = { 'value': this.generalNotesCharsLeft };
    this.trVariable2 = { 'value': this.nextActionCharsLeft };
    this.listenAppointments();
  }

  ngAfterViewInit() {
    this.listenAppointments();
  }

  ngOnDestroy(): void {
    if (this.formChangeSub) {
      this.formChangeSub.unsubscribe();
    }
    if (this.talentSub) {
      this.talentSub.unsubscribe();
    }
    this.generalNotesSub.unsubscribe();
    this.nextActionSub.unsubscribe();
    if(this.currentTalentSub) {
      this.currentTalentSub.unsubscribe();
    }
    if(!this.isModal) this._apptInvite.destroyCurrentTalent();
  }

  onChanges(): void {
    this.formChangeSub = this.remarksForm.valueChanges
      .subscribe(val => {
        this.formValue.emit(val);
      }, err => console.error(err));
  }

  /**
   * Listen to appointments. Mainly for updating the currentTalent when an appointment
   * is made.
   */
  listenAppointments() {
    this._apptInvite.appointments
      .subscribe((appts: AllbirdsAppointment[]) => {
        if (this.currentTalent && appts && appts.length) {
          const foId = this.currentTalent.external_id;
          const newAppt = appts.find(appt => appt.profileFrontOfficeID === foId);
          if (newAppt) {
            this.currentTalent.appointment = newAppt;
            this.setAppointmentStatusMessage();
          }
        }
      });
  }

  calculateCharsLeft(): void {
    this.generalNotesSub = this.remarksForm.controls.generalNotes.valueChanges
      .subscribe(val => {
        this.generalNotesCharsLeft = this.maxCharLength - val.length;
      }, err => console.error(err));

    this.nextActionSub = this.remarksForm.controls.nextAction.valueChanges
      .subscribe(val => {
        this.nextActionCharsLeft = this.maxCharLength - val.length;
      }, err => console.error(err));
  }

  updateFormValuesFromCurrentTalent(): void {
    const updateProps = (talent: ListTalent) => {
      this.currentTalent = talent;
      this.remarksForm.controls.generalNotes.setValue(talent.general_notes);
      this.remarksForm.controls.nextAction.setValue(talent.next_action);
      if (this.currentTalent.appointment) {
        this.setAppointmentStatusMessage();
      }
    };
    // For a list only
    this.talentSub = this._listService.talentObservable
      .pipe(filter(data => !!data))
      .subscribe((talent: ListTalent) => {
        if (talent) {
          updateProps(talent);
        }
      });
    // If we're not coming from a list
    this.currentTalentSub = this._apptInvite.currentTalent
      .subscribe((talent) => {
        if (talent) {
          updateProps(talent);
        }
      });
  }

  saveRemarks(): void {
    let listId = this._route.snapshot.paramMap.get('listId');
    // If view is not currently a list
    if (!listId && this._apptInvite.getCurrentTalent()) {
      if (this._apptInvite.getCurrentTalent().appointment) {
        listId = this._apptInvite.getCurrentTalent().appointment.listID;
      }
    }
    if (listId) {
      this._loading.show();

      this._api.searchList(listId)
        .pipe(flatMap(list => {
          const updatedList = this.updateListWithNewTalentRemarks(list);
          // Update currentTalent in appt-invite.service so lists in something other than a private/shared list is updated
          const updatedListItem = updatedList.talent.find(tal => tal.external_id === this.currentTalent.external_id);
          if (updatedListItem) {
            this._apptInvite.setCurrentTalent(updatedListItem);
          }
          const eventLog = {
            logEvent: 'pipeline talent notes updated',
            talentName: `${this.currentTalent.first_name} ${this.currentTalent.last_name}`,
            talentId: this.currentTalent.external_id || this.currentTalent.google_id,
            general_notes: this.remarksForm.controls.generalNotes.value,
            next_action: this.remarksForm.controls.nextAction.value
          };
          return this._api.updateList(updatedList, eventLog);
        }))
        .subscribe(data => {
          this._loading.hide();
          this._listService.refresh();
        }, err => {
          this._loading.hide();
          console.error(err);
        });
    } else {
      console.error('Unable to determine the listId');
    }
  }

  updateListWithNewTalentRemarks(list: List): List {
    const updatedList = list.clone();
    updatedList.talent.map(tal => {
      if (tal.external_id === this.currentTalent.external_id) {
        tal.general_notes = this.remarksForm.controls.generalNotes.value;
        tal.next_action = this.remarksForm.controls.nextAction.value;
        tal.date_updated = moment();
      }

      return tal;
    });

    return updatedList;
  }

  /**
   * Called from the UI to display the appointment status info message
   * @see ApptStatusMessages
   * @param msgType
   * @deprecated we are now using 'setAppointmentStatusMessage'.
   */
  getAppointmentStatusMessage(msgType: 'info' | 'banner') {
    if (this.currentTalent && this.currentTalent.appointment) {
      const {appointment} = this.currentTalent;
      const statusProps: AppointmentStatusLabels = ApptStatusMessages[appointment.appointment.interviewStatus];
      return statusProps.getLabel(appointment, msgType);
    }
  }

  /**
   * Sets the status banner / info message for appointments if applicable.
   */
  setAppointmentStatusMessage(): void {
    if (this.currentTalent && this.currentTalent.appointment) {
      const { appointment } = this.currentTalent;
      const { appointment: interview } = appointment;
      const statusProps: AppointmentStatusLabels = ApptStatusMessages[appointment.appointment.interviewStatus];
      // For status messages that require displaying recruiter information, fetch user from Mongo before returning statuses.
      if (interview.interviewStatus === InterviewStatus.SCHEDULED && interview.recruiterEmail) {
        this._user.getUsersByEmail([interview.recruiterEmail])
          .then(() => {
            const interviewer = this._user.getFromCacheUsingEmail(interview.recruiterEmail);
            this.statusBannerMessage = statusProps.getLabel(appointment, 'banner', interviewer) as string;
            this.statusInfoMessage = statusProps.getLabel(appointment, 'info', interviewer) as string;
          })
          .catch(err => {
            console.error('An error occurred fetching user information by email.', err);
            // If getting user fails for some reason, the status message will still display but without the recruiter's name.
            this.statusBannerMessage = statusProps.getLabel(appointment, 'banner') as string;
            this.statusInfoMessage = statusProps.getLabel(appointment, 'info') as string;
          });
      }
      else if(interview.interviewStatus === InterviewStatus.RECRUITER_CANCELLED) {
        this.statusBannerMessage = statusProps.getLabel(appointment, 'banner') as string;
        this.statusInfoMessage = statusProps.getLabel(appointment, 'info') as string;
        if(appointment?.cancelledBy !== ''){
          const author = (appointment?.cancelledBy && this._user.getFromCache(appointment?.cancelledBy)) || null;
          if(author){
            const recruiterName = author.FullName;
            this.statusInfoMessage = this.statusInfoMessage.replace(appointment?.cancelledBy, recruiterName);
          }
        }

      }
      else {
        this.statusBannerMessage = statusProps.getLabel(appointment, 'banner') as string;
        this.statusInfoMessage = statusProps.getLabel(appointment, 'info') as string;
      }
    } else {
      this.statusBannerMessage = '';
      this.statusInfoMessage = '';
    }
  }

  /**
   * click handler for the "log connect" button
   * @listens log connect#click
   */
  logConnectClick() {
    const initialState: any = {
      isAppointment: true,
      appointmentHandler: this.handleLogConnectSubmission.bind(this),
      talent: this.currentTalent
    };
    this._modalService.show(LogNoteModalComponent, {initialState});
  }

  /**
   * click handler for the "log as interview" button
   * @listens log as interview#click
   */
  logInterviewClick() {
    const initialState: LogInterviewModalConfig = {
      listTalent: this.currentTalent,
      isAppointment: true,
      submitHandler: this.handleLogInterviewSubmission.bind(this)
    };
    this._modalService.show(LogInterviewModalComponent, {initialState});
  }

  cancelInterviewModal() {
    const config: ModalOptions<any> = {
      initialState: {
        label: this.statusInfoMessage,
        type: "APPOINTMENT",
        talent: this.currentTalent,
        listId : this._route.snapshot.paramMap.get('listId')
      }
    };
    this.bsModalRef = this._modalService.show(CancelInterviewModalComponent, config);
    this.bsModalRef.setClass('modal-sm');
  }

  /**
   * Handler fired from the LogInterviewModalComponent
   * @param {LogInterviewForm} formValues
   */
  handleLogInterviewSubmission(formValues: LogInterviewForm) {
    const {appointment} = this.currentTalent;
    if (formValues) {
      const statusFromType = formValues.type === 'CANCELLED' || formValues.type === 'NO_SHOW'
          ? InterviewStatus.CANCELLED
          : InterviewStatus.COMPLETE;
      const appointmentFeedback: AppointmentFeedback = {
        channel: formValues.channel,
        commuteDistance: formValues.commuteDistance,
        completionDate: moment().toISOString(),
        minPay: {type: formValues.minPay_type, value: formValues.minPay_value},
        notes: formValues.notes,
        opportunities: formValues.opportunities,
        skills: formValues.skills,
        startDate: formValues.startDate,
        travel: formValues.travel,
        type: statusFromType
      };
      appointment.appointmentFeedback = ApptFeedback.deserialize(appointmentFeedback);
      appointment.appointment.interviewStatus = statusFromType;
      appointment.lastUpdated = moment();
      this._apptInvite.updateAppointment(appointment)
        .subscribe(() => {
          if (appointment.appointment.interviewStatus === InterviewStatus.COMPLETE) {
            this.currentTalent.appointment = null;
            this.handleAppointmentFeedbackIntegration(appointment);
          } else {
            this.currentTalent.appointment = appointment;
          }
        });
    }
  }

  /**
   * Converts the appointment into an activity and then makes a HTTP call
   * to the Talent.LogActivity RPC for integrations.
   * @param appointment allbirds appointment
   */
  handleAppointmentFeedbackIntegration(appointment: AllbirdsAppointment): void {
    const activity = ApptInviteService.constructActivityFromAppointment(appointment);
    const isRGS = appointment.profileFrontOfficeID.abIncludesLob('RGS');
    const meta = isRGS ? { interviewChannel: appointment.appointmentFeedback.channel } : undefined;
    this._api.logTalentActivity(activity, meta)
      .subscribe((res: any) => {
        // this._toast.showToast('log-interview-modal.logged');
      }, err => {
        // this._toast.showToast('log-interview-modal.logging_error', { cssClass: ToastClass.DANGER });
      });
  }

  /**
   * Handler fired from the LogNoteModalComponent. The comments property will be HTML from
   * the ckeditor
   * @param {activityType: string, comments: string} formValues
   */
  handleLogConnectSubmission(formValues: any) {
    if (this.currentTalent) {
      const {appointment} = this.currentTalent;
      appointment.appointment.interviewStatus = InterviewStatus.COMPLETE;
      this._apptInvite.updateAppointment(appointment)
        .subscribe((response: any) => {
          if (response) {
            this.currentTalent.appointment = null;
          }
        });
    }
  }

}
