import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl } from '@angular/forms';
import {
  MASS_ACTION_TYPE,
  MASS_OPERATION_CONTEXT
} from 'src/app/shared/components/mass-email-modal/mass-operation-context';
import {
  MassOperationRecipientService,
  Recipients
} from 'src/app/shared/components/mass-email-modal/mass-operation-recipient.service';
import {Profile} from 'src/app/shared/models/external/profile.model';
import {EmailInput, EmailIssueInfo} from '../../../models/internal/mass-email.model';
import {FormValidationService} from '../../../services/form-validation/form-validation.service';
import {UtilityService} from '../../../services/utility/utility.service';

@Component({
  selector: 'app-email-name-multi-input',
  templateUrl: './email-name-multi-input.component.html',
  styleUrls: ['./email-name-multi-input.component.scss']
})
export class EmailNameMultiInputComponent implements OnInit, AfterViewInit {

  @Input() label: string = '';
  @Input() control: AbstractControl;
  @Input() canChangeInput: boolean;
  @Input() showPills: boolean;
  @Input() emails: EmailInput[];
  @Input() handleValidation: boolean = false;
  @Input() selectedProfiles: Profile[] = [];
  @Input() operationContext: MASS_OPERATION_CONTEXT;
  // @Input() massAppointments: boolean = false;
  @Output() onEmailInput: EventEmitter<string> = new EventEmitter<string>();
  @Output() onEmailRemove: EventEmitter<string> = new EventEmitter<string>();
  @Output() expandPillsEmit: EventEmitter<boolean> = new EventEmitter<boolean>();
  showCantMailDetails: boolean = false;
  input: string = '';
  finalEmailList: EmailInput[] = [];
  problemUsers: EmailIssueInfo[] = [];
  trVariable1: any;
  cantMailVerbiage: string = 'Show details';

  constructor(
    private _maRecipient: MassOperationRecipientService
  ) { }

  ngOnInit() {
    if (!this.canChangeInput) {
      this.emails?.forEach((each: EmailInput) => {
        this.input = each.email + ',';
      });
      this.input = this.input.substring(0, this.input.length - 1);
      if (this.control) {
        this.control.markAsTouched();
      }
    }
  }

  ngAfterViewInit() {
    if (this.handleValidation) {
      try {
        this.getValidatedProfiles();
      } catch (e) {
        console.error('getValidatedProfiles failed', e);
      }
    }
  }

  get inputId(): string {
    return `email-multi-input-${this.label}`;
  }

  async getValidatedProfiles() {
    if (this.selectedProfiles && this.selectedProfiles.length) {
      let result;
      if(this.operationContext === MASS_OPERATION_CONTEXT.individualEnhancedProfile) {
        const selectedProfiles = this.selectedProfiles.map(profile => profile.enhancedProfile);
        result = await this._maRecipient.initialize(this.operationContext, MASS_ACTION_TYPE.email, selectedProfiles);
      }
      else {
        result = await this._maRecipient.initialize(this.operationContext, MASS_ACTION_TYPE.email, this.selectedProfiles);
      }
      const emailResult = result as Recipients;
      this.finalEmailList = emailResult.emailList;
      this.problemUsers = result.issue;
      this.trVariable1 = {value: this.problemUsers.length};
      this.control.setValue(this.finalEmailList);
    }
  }

  expandPills() {
    this.expandPillsEmit.emit(true);
  }

  handleInputChange(input: string) {
    // console.log('EmailNameMultiInput.handleInputChange, input=', input);
    const breakDetected = input.slice(-1) === ',';
    if (breakDetected) {
      // Remove the comma from the email input.
      const email = input.slice(0, -1);
      this.processValue(email);
    }
  }

  handleBlur(event: FocusEvent) {
    // console.log('EmailNameMultiInput.handleBlur', event);
    const target = event && (event.target as HTMLInputElement) || null;
    if (target && target.value && target.value.length) {
      this.processValue(target.value);
    }
  }

  handlePillClick(email: string) {
    this.onEmailRemove.emit(email);
  }

  processValue(value: string) {
    // console.log('EmailNameMultiInput.processValue, value=', value);
    const email = value.toLowerCase();
    if (UtilityService.validateEmail(email)) {
      this.onEmailInput.emit(email);
      if (this.handleValidation) {
        const emailInput = {
          email,
          name: value.substring(0, value.indexOf('@')).split('.').join(' ')
        };
        this.finalEmailList.push(emailInput);
        this.control.setValue(this.finalEmailList);
      }
      setTimeout(() => {
        this.input = '';
      });
    } else {
      FormValidationService.markAsTouched(this.control);
      FormValidationService.clearErrors(this.control);
      FormValidationService.setError(this.control, 'email');
    }
  }

  showDetailCantMail() {
    this.showCantMailDetails = !this.showCantMailDetails;
    if (this.showCantMailDetails) {
      this.cantMailVerbiage = 'Hide details';
    } else {
      this.cantMailVerbiage = 'Show details';
    }
  }
}
