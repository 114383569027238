import { createModel, Mapping, Model } from './model';
import { Jobs } from '@allbirds-ui/allbirds-types';
import { GrpcToDate, IsoToDate, IsoToMoment } from './serializers/moment.serializer';
import { Moment } from 'moment';
import { Serial } from './decorators/serial.decorator';
export import RawJobForm = Jobs.JobForm;

class JobFormMapping extends Mapping<RawJobForm> {
  @Serial(IsoToMoment) draft_creation_date: Moment = null;
  @Serial(IsoToMoment) draft_update_date: Moment = null;
  // TODO(jason): ideally, make angular form handle Moment instead of Date
  @Serial(IsoToDate) client_interview_date: Moment = null;
  @Serial(IsoToDate) submission_deadline: Date = null;
  @Serial(IsoToMoment) job_expire_date?: Moment = null;
  @Serial(GrpcToDate) jobStartTime: Date = null;
  @Serial(GrpcToDate) jobEndTime: Date = null;
  work_location?: string;
}

export interface JobForm extends Model<JobFormMapping> {}

export const JobForm = createModel<JobForm>(JobFormMapping);
