import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {ChatBots} from '@allbirds-ui/allbirds-types';
import InterviewType = ChatBots.InterviewType;
import { FormValidationService } from '../../services/form-validation/form-validation.service';

@Component({
  selector: 'app-appointment-settings',
  templateUrl: './appointment-settings.component.html',
  styleUrls: ['./appointment-settings.component.scss']
})
export class AppointmentSettingsComponent implements OnInit {
  @Input() apptSettingsForm: FormGroup;
  /**
   * Order is important here. It should match ApptTypeValues in
   * @link 'src/app/shared/appt-settings/appt-settings.interface.ts'
   */
  apptTypeValues = [
    {value: InterviewType.IN_PERSON, label: 'In person'},
    {value: InterviewType.VIDEO, label: 'Video'},
    {value: InterviewType.AUDIO, label: 'Telephone'}
  ];
  showAddress: boolean = false;

  constructor() { }

  ngOnInit() {
    const apptType = this.apptSettingsForm.get('apptType').value;
    this.showAddress = apptType && apptType.length && apptType[0] === true ? true : false;
    this.setValidations(apptType);
    this.apptSettingsForm.get('apptType').valueChanges
      .subscribe((val) => {
        this.setValidations(val);
      });
  }

  private setValidations(apptTypes: any) {
    if(apptTypes && apptTypes.length && !apptTypes.includes(true)){
      FormValidationService.setError(this.apptSettingsForm.controls.apptType, 'required');
      FormValidationService.markAsTouched(this.apptSettingsForm.controls.apptType);
    } else {
      FormValidationService.clearErrors(this.apptSettingsForm.controls.apptType)
    }

    if (apptTypes && apptTypes.length && apptTypes[0] === true) {
      this.showAddress = true;
      FormValidationService.setValidators(this.apptSettingsForm.controls['apptAddress'], [Validators.required, FormValidationService.validateNull]);
      FormValidationService.markAsTouched(this.apptSettingsForm.controls.apptAddress);
    } else {
      this.showAddress = false;
      FormValidationService.clearValidators(this.apptSettingsForm.controls['apptAddress']);
    }
  }
}
