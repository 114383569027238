<!--
  The proper TemplateRef is rendered in the following <div> depending on what sub-step the talent
  is on as well as what the role of the user is.
-->
<div class="phase" [ngTemplateOutlet]="shownPhase"></div>

<ng-template #selectAction>
  <div class="phase-body-section pt-0">
    <p>{{statusLabel}}</p>
  </div>
  <div #ctaContainerBoundry id="ctaContainerBoundry"></div>
  <ul #ctaContainer class="action-buttons hz-btn-list" [class.disabled]="application?.randstad_process?.rejected">
    <li>
      <app-talent-process-cta
        [id]="'prescreening-not-a-fit-button'"
        [invertColor]="true"
        (execute)="emitUnfitClick()">
        {{ 'prescreening.not_a_fit' | translate }}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        [id]="'prescreen-by-call-button'"
        [invertColor]="true"
        (execute)="openLogModal()">
        {{'prescreening.by_calling' | translate}}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        [id]="'prescreen-by-chatbot-button'"
        (execute)="attemptChatbot()"
        [state]="chatbotDisabledState">
        {{ 'prescreening.by_chatbot' | translate }}
      </app-talent-process-cta>
    </li>
  </ul>
</ng-template>

<ng-template #questionsSent>
  <div class="phase-body-section">
    <div class="gray-box">
      <div class="img-container">
        <img src="assets/icons/pre-screening.svg"  />
      </div>
      <div class="text-container">
        <vertical-align>
          <p>{{statusLabel}}</p>
        </vertical-align>
      </div>
    </div>
    <div class="phase-body-section" *ngIf="conversationID">
      <prescreening-questions [conversationID]="conversationID"></prescreening-questions>
    </div>
  </div>
  <div #ctaContainerBoundry id="ctaContainerBoundry"></div>
  <ul #ctaContainer class="action-buttons hz-btn-list" [class.disabled]="application?.randstad_process?.rejected">
    <li>
      <app-talent-process-cta
        [id]="'prescreening-not-a-fit-button'"
        [invertColor]="true"
        (execute)="emitUnfitClick()">
        {{ 'prescreening.not_a_fit' | translate }}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        [id]="'prescreen-by-call-button'"
        (execute)="openLogModal()">
        {{'prescreening.by_calling' | translate}}
      </app-talent-process-cta>
    </li>
  </ul>
</ng-template>

<ng-template #questionsReceived>
  <p>{{statusLabel}}</p>
  <div class="phase-body-section">
    <prescreening-questions [conversationID]="conversationID"></prescreening-questions>
  </div>
  <div #ctaContainerBoundry id="ctaContainerBoundry"></div>
  <ul #ctaContainer class="action-buttons hz-btn-list" [class.disabled]="application?.randstad_process?.rejected">
    <li>
      <app-talent-process-cta
        [id]="'prescreening-not-a-fit-button'"
        [invertColor]="true"
        (execute)="emitUnfitClick()">
        {{ 'prescreening.not_a_fit' | translate }}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        [id]="'plan-interview-button'"
        (execute)="submit(true)">
        {{ 'prescreening.plan_interview' | translate }}
      </app-talent-process-cta>
    </li>
  </ul>
</ng-template>

<ng-template #manualPresceen>
  <div *ngIf="application && application.randstad_process && application.randstad_process.prescreening && application.randstad_process.prescreening.isManualPrescreening">
    <manual-prescreening-results [application]="application"></manual-prescreening-results>
  </div>
  <div #ctaContainerBoundry id="ctaContainerBoundry"></div>
  <ul #ctaContainer class="action-buttons hz-btn-list" [class.disabled]="application?.randstad_process?.rejected">
    <li>
      <app-talent-process-cta
        [id]="'prescreening-not-a-fit-button'"
        [invertColor]="true"
        (execute)="emitUnfitClick()">
        {{ 'prescreening.not_a_fit' | translate }}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        [id]="'plan-interview-button'"
        [invertColor]="true"
        (execute)="submit(true)">
        {{ (isRT ? 'virtual-interview.prepare_submission' : 'prescreening.plan_interview') | translate }}
      </app-talent-process-cta>
    </li>
  </ul>
</ng-template>

<ng-template #reviewInterview>
    <div>
      <h4 class="phase-body-title pb-2">
        <p>{{statusLabel}}</p>
        <!-- prescreen complete -->
      </h4>
    </div>

    <div class="mt-4" *ngIf="conversationID && !(application && application.randstad_process && application.randstad_process.prescreening && application.randstad_process.prescreening.manualScreeningAnswers)">
      <prescreening-questions [conversationID]="conversationID"></prescreening-questions>
    </div>
    <div #ctaContainerBoundry id="ctaContainerBoundry"></div>
    <ul #ctaContainer class="action-buttons hz-btn-list" [class.disabled]="application?.randstad_process?.rejected">
      <li>
        <app-talent-process-cta
          [id]="'prescreening-not-a-fit-button'"
          [invertColor]="true"
          (execute)="emitUnfitClick()">
          {{ 'prescreening.not_a_fit' | translate }}
        </app-talent-process-cta>
      </li>
      <li>
        <app-talent-process-cta
          [id]="'plan-interview-button'"
          (execute)="submit(true)">
          {{ 'prescreening.plan_interview' | translate }}
        </app-talent-process-cta>
      </li>
    </ul>
  </ng-template>
