import { createModel, Mapping, Model } from './model';
import { GoogleToMoment } from './serializers/moment.serializer';
import { Moment } from 'moment';
import { Serial } from './decorators/serial.decorator';
import { Google } from '@allbirds-ui/allbirds-types';
import { Internal } from './decorators/internal.decorator';
export import RawEmploymentRecord = Google.Talent.EmploymentRecord;

class EmploymentRecordMapping extends Mapping<RawEmploymentRecord> {
  @Serial(GoogleToMoment) startDate?: Moment;
  @Serial(GoogleToMoment) endDate?: Moment;
  @Internal() workExperienceDescriptionToggle?: boolean;
}

export interface EmploymentRecord extends Model<EmploymentRecordMapping> {}

export const EmploymentRecord = createModel<EmploymentRecord>(EmploymentRecordMapping);
