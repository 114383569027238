import { Component, OnInit, TemplateRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastService } from '../../../../../services/toast';
import { FormValidationService } from '../../../../../services/form-validation/form-validation.service';
import { CKEditor4 } from 'ckeditor4-angular';
import { SubmitToClientModalConfig } from './submit-to-client-modal.config';
import { AuthService } from '../../../../../services/auth/auth.service';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { LoadingSpinnerService } from '../../../../../services/loading-spinner/loading-spinner.service';
import { TalentProcessService } from '../../../../../services/talent-process/talent-process.service';
import moment from 'moment';

@Component({
  selector: 'app-submit-to-client-modal',
  templateUrl: './submit-to-client-modal.component.html',
  styleUrls: ['./submit-to-client-modal.component.scss']
})
export class SubmitToClientModalComponent implements OnInit {

  // Passed as part of the initialState for launching this modal; handles an emitted value from the handleSubmit function.
  submitHandler: SubmitToClientModalConfig.SubmitHandler;

  // FormGroup where we actually pull the values for the request from.
  emailForm: FormGroup;

  // Where the file will be stored (if any).
  attachment: any;

  // CKEditor config for the message input.
  emailCkConfig: CKEditor4.Config = {
    toolbar: [['Format', '-', 'Bold', 'BulletedList', 'Indent', 'Outdent', '-', 'Undo', 'Redo']]
  };

  // Flag for determining whether to show the 'compose' or 'submitted' view.
  isComposeView = false;

  // Determines which TemplateRef to show on the UI.
  shownView: TemplateRef<any>;

  // Attachments on talent profile and internal submission
  attachments: any[];

  // Talent profile
  profile: any;

  // Attachments uploaded in modal
  uploadedAttachments: any[] = [];

  // Determines if add file section is visible
  isUploadFormVisible: boolean = false;

  // Categories for uploaded files
  categories: any[];

  // Required for add files section to work
  additionalParameters: object;
  allowDuplicate: boolean = false;
  parseResume: boolean = false;
  isTalentCreation: boolean = true;
  initialState: object;

  // "Send with email" view template.
  @ViewChild('composeView', { static: true }) composeView: TemplateRef<any>;

  // Shown when 'send with email' is selected but no signature is set.
  @ViewChild('noSignatureView', { static: true }) noSignatureView: TemplateRef<any>;

  // "Send without email" view template.
  @ViewChild('submittedView', { static: true }) submittedView: TemplateRef<any>;

  @Output() save = new EventEmitter();

  get uploadURL() { return this._api.uploadURL(); }

  constructor(
    public _bsModalRef: BsModalRef,
    private _fb: FormBuilder,
    private _toast: ToastService,
    private _auth: AuthService,
    private _router: Router,
    private _api: ApiService,
    private _loading: LoadingSpinnerService,
    private _talentProcess: TalentProcessService
  ) { }

  ngOnInit() {
    this.setupForm();
    this.setShownView(this.isComposeView);
    this.getProfile();
  }

  get userSignature(): string {
    let signature = '';
    const { user } = this._auth;
    if (user && user.Preferences && user.Preferences.EmailSignature && user.Preferences.EmailSignature.length) {
      signature = `<div class="signature" contenteditable="false">${user.Preferences.EmailSignature}</div>`;
    }
    return signature;
  }

 /**
  * Retrieves talent profile and attachments
  */
  getProfile(): void {
    const profile = this._talentProcess?.selectedProfileSubject?.value;
    const profileID = profile?.name;
    this._api.getApplicant({ profileID })
      .toPromise()
      .then(response => {
        const profile = response[0];
        this.profile = profile;
        const profileAndSubmittedAttachments = [...this.attachments, ...this.profile?.attachments];
        this.attachments = this.removeDuplicateAttachments(profileAndSubmittedAttachments);
      })
      .catch(e => {
        console.error(e);
      });
  } 

  /**
   * Removes duplicate attachments from array
   * @param attachments
   * @returns unique array of attachments
   */
  removeDuplicateAttachments(attachments: any[]): any[] {
    return attachments.filter((attachment, index, self) =>
      index === self.findIndex(a => (a.path === attachment.path)
    ));
  }

  /**
   * Instantiates the emailForm FormGroup object.
   */
  setupForm(): void {
    this.emailForm = this._fb.group({
      to: [[]],
      subject: [''],
      message: [''],
      cc: [[]],
      bcc: [[]],
    });
    this.setValidators(this.isComposeView);
  }

  /**
   * Sets which view to show based on if we want compose view or not. Also triggers
   * the setup of the form since they're different for the two views.
   * @param isComposeView
   */
  setShownView(isComposeView: boolean) {
    if (isComposeView) {
      this.shownView = (
        this._auth.user &&
        this._auth.user.Preferences &&
        this._auth.user.Preferences.EmailSignature &&
        this._auth.user.Preferences.EmailSignature.length
      ) ? this.composeView : this.noSignatureView;
    } else {
      this.shownView = this.submittedView;
    }
    this.setupForm();
  }

  setValidators(isComposeView: boolean) {
    const { to, subject, message } = this.emailForm.controls;
    // If is compose view (send with email) ensure to & subject fields are validated too.
    if (isComposeView) {
      if (to) {
        to.setValidators(FormValidationService.validateNonEmptyArray);
      }
      if (subject) {
        subject.setValidators(Validators.required);
      }
    }
    // Message should be validated for both views.
    if (message) {
      message.setValidators(Validators.required);
    }
  }

  handleEmailInput(control: AbstractControl, input: string): void {
    FormValidationService.clearErrors(control);
    const emails = control.value || [];
    if (emails.findIndex((e: string) => e === input) < 0) {
      emails.push(input);
    }
    control.patchValue(emails);
  }

  handleEmailRemove(control: AbstractControl, input: string): void {
    control.patchValue((control.value && control.value.length)
      ? control.value.filter((email: string) => email !== input)
      : []
    );
  }

  // Deprecated after file input was changed to multiple per DF044-6449
  // handleAttachment(event: any) {
  //   this.attachment = event;
  // }

  // handleAttachmentRemove() {
  //   this.attachment = null;
  // }

  handleSubmit(): void {
    FormValidationService.touchAllFields(this.emailForm);
    if (this.emailForm.valid) {
      const { to, cc, bcc, subject, message } = this.emailForm.controls;
      let form: SubmitToClientModalConfig.FormProps;

      // If 'send w/ email' view, add other form control values to emitted payload.
      if (this.isComposeView) {
        form = {
          ...form,
          to: to.value,
          cc: cc.value,
          bcc: bcc.value,
          subject: subject.value,
          message: this.getMessageFromValue(message.value)
        };
      } else {
        // Ensuring attachment is null if not in compose view.
        this.attachment = null;
        form = {
          message: message.value
        };
      }

      // Call the passed in function that handles submission values if it exists.
      if (this.submitHandler) {
        this._loading.show();
        this.uploadedAttachments = this.setUploadedFileType([...this.uploadedAttachments]);
        const submittableInternalFiles = this.checkFilesToSubmit(this.attachments);
        const submittableUploadedFiles = this.checkFilesToSubmit(this.uploadedAttachments);
        // checks if any uploaded files will be saved to profile
        const newFilesAddedToProfile = submittableUploadedFiles.some(file => file?.willSaveToProfile);
        this.profile.attachments = this.checkFilesToSave();
        this.profile.userLob = 'RT';

        const payload = {
          form,
          submissionAttachments: [...submittableInternalFiles, ...submittableUploadedFiles],
          allAttachments: [...this.attachments, ...this.uploadedAttachments],
          withEmail: this.isComposeView
        }

        setTimeout((function() {
          if (newFilesAddedToProfile) {
            // saves attachments to talent profile before submitting
            this._api.editTalentProfile(this.profile).subscribe((res: any) => {
              console.log('profile edit successful', res);
              this.submitHandler(payload);
              this._loading.hide();
              this._bsModalRef.hide();
            },
            (err: any) => {
              console.log('error saving profile', err);
              this._loading.hide();
              this._bsModalRef.hide();
            });
          } else {
            this.submitHandler(payload);
            this._loading.hide();
            this._bsModalRef.hide();
          }
        }).bind(this), 1000);
      }
    }
  }

  /**
   * Removes the signature from the message body.
   * @param value - value of the message FormControl
   */
  getMessageFromValue(value: string): string {
    const [body] = value.split('<div class="signature" contenteditable="false" style="user-select:none">');
    return body;
  }

  /**
   * Scrolls the modal back to the top. Without this, the modal will scroll to the bottom
   * (where the CKEditor is) once the CKEditor emits its ready event.
   * @param editor
   */
  handleEditorReady(editor: CKEditor4.Editor) {
    const d = document.getElementById('modalTop');
    d.scrollIntoView({ behavior: 'smooth' });
  }

  /**
   * Closes the modal & navigates to the email settings page.
   */
  goToEmailSettings(): void {
    this._bsModalRef.hide();
    this._router.navigate(['/settings/email']).catch(console.error);
  }

  /**
   * Instantiates form to upload attachments
   */
  showUploadForm(): void {
    this.isUploadFormVisible = true;
    this.categories = [
      { name: 'Formatted with logo' },
      { name: 'Formatted with no logo' },
      { name: 'Application' },
      { name: 'Bill Rate Confirmation' },
      { name: 'Certificate' },
      { name: 'Contract' },
      { name: 'Job Description' },
      { name: 'Reference' },
      { name: 'Right to Represent' },
      { name: 'Test Results' },
      { name: 'Portfolio/Work samples' },
      { name: 'Other' }
    ] 
    ;
    this.initialState = {
      save: this.save,
      categories: this.categories,
      attachments: this.attachments,
      additionalParameters: this.additionalParameters,
      allowDuplicate: this.allowDuplicate,
      uploadURL: this.uploadURL,
      parseResume: this.parseResume,
      isTalentCreation: this.isTalentCreation
    }
  }

  /**
   * Toggles property that determines if attachment will be included in submission
   * @param attachment - file whose checkbox has been clicked
   */
  handleWillSubmit(attachment: any): void {
    attachment.willSubmit = !attachment.willSubmit;
  }

  /**
   * Adds additional properties to uploaded file
   * @param item - file uploaded
   */
  handleAddToUploadedAttachments(item: any) {
    item.attached_by_user = this._auth.user.FullName;
    item.attached_by_user_back_office_id = this._auth.user.BackOfficeID;
    item.attached_by_user_front_office_id = this._auth.user.EmplID;
    item.attached_by_user_email = this._auth.user.EmailAddr;
    item.upload_time = moment().toISOString();
    item.lastUpdatedDateTime = moment().toISOString();
    item.type = 'file';
    this.uploadedAttachments.push(item);
  }

  /**
   * Removes file from uploadedAttachments array if delete button was clicked
   * @param fileIndex - position of file in uploadedAttachments array
   */
  handleRemoveFromUploadedAttachments(fileIndex: number) {
    this.uploadedAttachments.splice(fileIndex, 1);
  }

  /**
   * Determines which files will be included in submission
   * @param files
   * @returns array of files ready for submission
   */
  checkFilesToSubmit(files: any[]) {
    return files.filter(file => file?.willSubmit === true);
  }

  /**
   * Determines which files will be saved to profile after submission (files already on profile will always be included)
   * @returns array of files to be saved to profile
   */
  checkFilesToSave() {
    let filesToSave = this.uploadedAttachments.filter(file => file?.willSaveToProfile);
    return [...this.attachments, ...filesToSave];
  }

  formatDatetime(datetime: any) {
    return moment(datetime);
  }

  /**
   * Adds type property to uploaded files
   * @param files - files being uploaded
   * @returns array of files with type property added
   */
   setUploadedFileType(files: any): any[] {
    return files.map((file: any) => {
      if (file?.category?.includes('Formatted') || file?.category?.includes('resume') || file?.category?.includes('Other')) {
        return { ...file, type: 'resume' };
      } else {
        return { ...file, type: 'file' };
      }
    });
  }
}
