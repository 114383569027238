<div class="modal-header">
  <div class="modal-header-text">
    Schedule an appointment
  </div>
  <button class="btn btn-reset" (click)="bsModalRef.hide()">
    <i class="icon-WL_icon_close"></i>
  </button>
</div>
<div class="modal-body">
  <div class="titleHeader">
    <i *ngIf="stepOneComplete && !isStepOne" class="material-icons">check</i>
    <h2 *ngIf="isStepOne" class="header">1/3 Select a list - <span>2/3 Appointment settings</span> - <span>3/3 Write the
        email</span> </h2>
    <h2 *ngIf="!isStepOne && stepOneComplete && !stepTwoComplete" class="header"><span>1/3 Select a list</span> - 2/3
      Appointment settings - <span>3/3 Write the email</span> </h2>
    <h2 *ngIf="!isStepOne && stepOneComplete && stepTwoComplete" class="header"><span>1/3 Select a list</span> -
      <span>2/3 Appointment settings</span> - 3/3 Write the email
    </h2>
  </div>
  <ng-container
    *ngTemplateOutlet="isStepOne && !isStepOneTwo ? stepOne : isStepOneTwo ? stepOneTwo : isStepTwo ? stepTwo : stepThree">
  </ng-container>
</div>
<div class="modal-footer flex-row">
  <button class="btn rds--button--primary" (click)="bsModalRef.hide()">
    cancel
  </button>
  <button *ngIf="isStepOne || isStepOneTwo || isStepTwo || isStepThree" class="btn btn-primary" (click)="toggleStep()"
    [disabled]="!listToAddId">{{'add-to-list-a-modal.continue' | translate}}</button>

</div>

<!-- Step one -->
<ng-template #stepOne>

  <div class="view-search">
    <div class="view mr-3">
      <label>{{'add-to-list-a-modal.view' | translate}}</label>
      <select (change)="setLists($event)" class="custom-select selectDropDown">
        <option value="private">{{'add-to-list-a-modal.private_lists' | translate}}</option>
        <option value="shared">{{'add-to-list-a-modal.shared_lists' | translate}}</option>
      </select>
    </div>
    <div class="list-name">
      <label>{{'add-to-list-a-modal.list_name' | translate}}</label>
      <job-order-input [(inputModel)]="query" [field]="'name'" [metadata]="false" [restrictedValues]="true"
        [useAsIsFlag]="false" [disableOnSelection]="true" [typeAheadField]="'name'" [showResultsOnFocus]="true"
        (setState)="setValue($event)" (suggest)="suggester($event)">
      </job-order-input>
    </div>
    <div> <button class="btn rds--button--primary" (click)="searchListByName()"
        [disabled]="!query || query === '' ">{{'add-to-list-a-modal.search' | translate}}</button>
    </div>

    <div class="new-list-btn">
      <button class="btn rds--button--primary btn-icon" (click)="initiateNewList()">
        <span class="icon"></span>
        <span>create new list</span>
      </button>
    </div>
  </div>

  <div *ngIf="isNewList" class="new-list">
    <p><b>Create a new list</b></p>
    <form [formGroup]="newListForm" class="new-list-form">
      <input type="text" [ngModel]="listName" formControlName="listName" class="form-control"
        placeholder="{{'new-private-list-modal.give_your_list' | translate}}" />
      <button class="btn rds--button--primary" (click)="cancelNewList()">{{'new-private-list-modal.cancel' |
        translate}}</button>
      <button class="btn btn-primary" [disabled]="!newListForm.valid"
        (click)="saveNewList()">{{'new-private-list-modal.save' | translate}}</button>
    </form>
    <div class="alert alert-danger mt-3" *ngIf="newListForm.controls['listName'].hasError('maxlength')">
      List name should be less than 30 characters.
    </div>
    <div class="alert alert-danger mt-3" *ngIf="newListForm.controls['listName'].hasError('pattern')">
      Special characters are not allowed</div>
  </div>

  <div *ngIf="displayedLists && displayedLists.length > 0" class="cards-container">
    <div *ngFor="let list of displayedLists" class="list-card-container" [tooltip]="isMassAddToList ? list.tooltip : ''">
      <div (click)="selectListToAdd(list.id)"
        [ngClass]="{'selected-list': list.id === listToAddId, 'list-disabled': checkTalent(list)}">
        <recruitment-list-card [cardType]="'custom'" [title]="list.name" [listID]="list.id"
          [currentSelectedListId]="currentId" [count]="list.talent_count" [date]="list.creation_date"
          [cancelNavigation]="true" [showLabel]="checkTalent(list)" [labelMsg]="'candidate is in this list'"
          [showToolTip]="false">
        </recruitment-list-card>
      </div>
    </div>
  </div>
</ng-template>

<!-- Step One Two -->
<ng-template #stepOneTwo>
  <div>
    <h3 class="subtitle">{{'add-to-list-a-modal.add_any_remarks' | translate}}</h3>
  </div>
  <list-info [isModal]="'true'" (formValue)="setRemarksValue($event)"></list-info>

</ng-template>

<!-- Step Two -->
<ng-template #stepTwo>
  <div class="parentFormGroup flex-column" [formGroup]="parentForm">
    <div class="recipients-list">
      <label class="recipientLabel" for="recipientList">
        {{'Selected talent (' + selectedTalent.length + ')'}}
      </label>
      <app-email-name-multi-input id="recipientList" label="Selected talent"
        [control]="parentForm.get('emailRecipients')" [canChangeInput]="false" [showPills]="true"
        [handleValidation]="true" [selectedProfiles]="selectedTalent" [emails]="recipientEmails" 
        [operationContext]="operationContext">
      </app-email-name-multi-input>
    </div>
    <app-appointment-settings [apptSettingsForm]="parentForm.controls.apptSettingsForm">
    </app-appointment-settings>
  </div>
</ng-template>

<!-- Step Three -->
<ng-template #stepThree>
  <app-appointment-email [emailMessageForm]="parentForm.controls.emailMessageForm">
  </app-appointment-email>
</ng-template>