import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { TalentService } from 'src/app/shared/services/talent/talent.service';
import { TalentProcessService } from 'src/app/shared/services/talent-process/talent-process.service';
import { LoadingSpinnerService } from 'src/app/shared/services/loading-spinner/loading-spinner.service';
import { ToastClass, ToastService } from 'src/app/shared/services/toast';
import { MetricsService } from 'src/app/shared/services/metrics/metrics.service';
import { Application } from '../../../../../models/external/application.model';
import moment from 'moment';
import { InterviewSchedule } from '../../../../../models/external/process/interview-schedule.model';
import { INTG_STEPS } from 'src/app/shared/models/internal/process.model';

@Component({
  selector: 'app-interview-modal',
  templateUrl: './interview-modal.component.html',
  styleUrls: ['./interview-modal.component.scss']
})
export class InterviewModalComponent implements OnInit {
  datePickerConfig = {
    dateInputFormat: 'MMM/DD/YYYY',
    showWeekNumbers: false
  };
  application: Application;
  form: FormGroup;

  constructor(
    public modalRef: BsModalRef,
    private talentService: TalentService,
    private talentProcess: TalentProcessService,
    private fb: FormBuilder,
    private auth: AuthService,
    private spinner: LoadingSpinnerService,
    private toast: ToastService,
    private _metrics: MetricsService
  ) {
    this.form = this.fb.group({
      type: ['inperson', [Validators.required]],
      address: ['', [Validators.required]],
      date: ['', [Validators.required]],
      start: ['', [Validators.required]],
      end: ['', [Validators.required]],
      timezone: ['', [Validators.required]]
    });
  }

  ngOnInit() {
    this.talentProcess.selectedApplication.subscribe((data) => {
      this.application = data;
    });
  }

  check(type: string) {
    this.form.patchValue({ type });
  }

  submit() {
    if (this.form.valid) {
      const key = this.application.randstad_process.lastProcessStep;
      const body = this.constructBody();
      const intgSteps: INTG_STEPS[] = [];
      this.spinner.show();
      this.talentProcess.updateApplication(body, intgSteps, key).then((data) => {
        if (data) {
          this._metrics.addEventToQueue(this.application, `candidate was scheduled for an interview`);
          // console.log(data);
          this.toast.showToast('interview-modal.success');
        } else {
          console.log('err');
          this.toast.showToast('interview-modal.error');
        }
        // these are done regardless of outcom
        this.modalRef.hide();
        this.spinner.hide();
      }, err => {
        this.spinner.hide();
        console.log(err);
      });
    } else {
      this.showAllErrors();
      this.toast.showToast('interview-modal.invalid', { cssClass: ToastClass.WARNING });
    }
  }

  showAllErrors() {
    const keys = Object.keys(this.form.value);
    for (let i = 0; i < keys.length; i++) {
      this.form.controls[keys[i]].markAsTouched();
    }
  }

  constructBody(): Application {
    const form = this.form.getRawValue();
    console.log(this.auth.user);
    if (form.type === 'inperson') {
      const app = this.application.clone();
      app.randstad_process.interviewSchedule = new InterviewSchedule({
        skipped: false,
        startTime: form.start,
        endTime: form.end,
        timezone: form.timezone,
        interviewType: form.type,
        interviewDate: form.date,
        inPersonInterviewLocation: form.address,
        agentID: this.auth.user.BackOfficeID,
        interviewScheduledOn: moment()
      });
      return app;
    }
  }
}
