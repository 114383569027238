import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { map } from 'rxjs/operators'
import { FirestoreService } from '../firestore/firestore.service';
import { Conversation } from '../../models/external/conversation.model';
import { Conversations } from '@allbirds-ui/allbirds-types';
import { BadgeStyleClasses, ConversationStatusesDisplay } from '../../models/internal/conversation.status.model';
import RCEStage = Conversations.RCEStage;

// TODO(jason): let cache service handle below
@Injectable({
  providedIn: 'root'
})
export class GoogleConversationService {
  /*
   * Example google conversation based on https://cloud.google.com/talent-solution/conversation-engine/docs/reference/rest/v4beta1/projects.tenants.conversations
   * note 1: this data is not real... it's a best guess
   * note 2: this assumes two level depth conversations.
   * note 3: it's not specifically identified how google will identify sub-prompts - there appears to be several possiblities.  The UI expects a prompt to have a list of promt responses on it.
   */

  private conversationsForID: { [id: string]: BehaviorSubject<Conversation> } = {}; /* hash of conversation observers that uses the applicationId as the key */
  private conversations: { [id: string]: { [id: string]: BehaviorSubject<Conversation> } } = {};

  constructor(private firestore: FirestoreService, private firestoreAuth: AngularFireAuth) {}

  /**
   * This function generally returns a BehaviorSubject that holds the Conversation value.
   * In cases where you need the value of the conversation immediately, pass the optional
   * callback (which gets called with a single parameter of the conversation value).
   * @param conversationID google conversation id
   * @param callback optional callback that passes the raw conversation
   */

  getConversationForID(conversationID: string, callback?: (conversation: Conversation) => any) {
    // Handle falsy conversation id.
    if (!conversationID) {
      return new BehaviorSubject<Conversation>(undefined);
    }
    // Handle existing lookup.
    if (conversationID in this.conversationsForID) {
      /* Return The Cached Converstation BehaviorSubject */
      if (callback) {
        return callback(this.conversationsForID[conversationID].getValue());
      }
      return this.conversationsForID[conversationID];
    }
    // Attempt to fetch.
    const conversationSubject = new BehaviorSubject<Conversation>(undefined);
    const fsConversation = this.firestore.getConversationForID(conversationID);
    this.conversationsForID[conversationID] = conversationSubject;
    fsConversation.valueChanges()
    .pipe(map(data => data && data.length > 0 && Conversation.deserialize(data[0])))
      .subscribe((data: Conversation) => {
        conversationSubject.next(data);
        if (callback) {
          callback(conversationSubject.getValue());
        }
      });
    return conversationSubject;
  }

  getConversation(googleJobID: string, googleTalentID: string) {
    if (!(googleJobID in this.conversations)) {
      this.conversations[googleJobID] = {};
    }
    // console.log(`Retrieving conversation data for ${googleJobID} ${googleTalentID}`);
    if (googleTalentID in this.conversations[googleJobID]) {
      return this.conversations[googleJobID][googleTalentID];
    } else {
      const conversationSubject = new BehaviorSubject<Conversation>(undefined);
      const fsConversation = this.firestore.getConversation(googleJobID, googleTalentID);
      this.conversations[googleJobID][googleTalentID] = conversationSubject;
      fsConversation.valueChanges()
      .pipe(map(data => data && data.length > 0 && Conversation.deserialize(data[0])))
        .subscribe(conversationSubject);
      return conversationSubject;
    }
  }

  /**
   * Given a CE conversation, returns true if any of the prompts triggered a knockout (fail).
   * @param conversation RCE chatbot conversation
   */
  isKnockedOut(conversation: Conversation): boolean {
    return conversation.knockoutTriggered;
  }

  /**
   * Given an RCE conversation, returns the status to be displayed in the UI based on the conditions defined in
   * [this spreadsheet]{@link https://docs.google.com/spreadsheets/d/1if7n-VXD5CpgNsUzyH1pvpP2sKYtYqWM0iONLyhXTkA/}.
   * @param conversation chatbot conversation
   */
  getConversationStatus(conversation: Conversation): ConversationStatusesDisplay {
    const stagesReached = (conversation.rce && conversation.rce.stages_reached) || [];
    const isApproved = (conversation.approved && conversation.approved.value === true) || false;
    if (stagesReached.length) {
      // Convert the conversation's stages_reached string array into Map<K, V> where K = stage enum; V = true.
      const stagesMap = new Map(stagesReached.map((stage: RCEStage) => [stage, true]));
      const isKnockedOut = this.isKnockedOut(conversation);
      // All 'pass' conditions.
      if (
        stagesMap.has(RCEStage.INTERVIEW_SCHEDULE_SUCCEEDED) ||
        stagesMap.has(RCEStage.INTERVIEW_SCHEDULE_ATTEMPTED) ||
        (stagesMap.has(RCEStage.SCREENING_COMPLETED) && !isKnockedOut)
      ) {
        return ConversationStatusesDisplay.SUCCESS;
      }
      // If screening started and a knockout is detected, return FAIL; else, return IN_PROGRESS.
      if (stagesMap.has(RCEStage.SCREENING_STARTED)) {
        return isKnockedOut ? ConversationStatusesDisplay.FAIL : ConversationStatusesDisplay.IN_PROGRESS;
      }
      // If only first input received and the conversation was not approved, return DECLINED.
      if (stagesMap.has(RCEStage.FIRST_INPUT_RECEIVED) && !isApproved) {
        return ConversationStatusesDisplay.DECLINED;
      }
    }
    return ConversationStatusesDisplay.NOT_STARTED;
  }

  /**
   * Given a RCE conversation, returns the css class to be used with the badge.
   * @param conversation chatbot conversation
   */
  getConversationStatusStyles(conversation: Conversation): BadgeStyleClasses {
    const status = this.getConversationStatus(conversation);
    switch (status) {
      case ConversationStatusesDisplay.SUCCESS:
        return BadgeStyleClasses.SUCCESS;
      case ConversationStatusesDisplay.IN_PROGRESS:
        return BadgeStyleClasses.IN_PROGRESS;
      case ConversationStatusesDisplay.FAIL:
      case ConversationStatusesDisplay.DECLINED:
        return BadgeStyleClasses.FAIL;
      case ConversationStatusesDisplay.NOT_STARTED:
      default:
        return BadgeStyleClasses.NOT_STARTED;
    }
  }

}
