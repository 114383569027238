<div class="phase-body-section pt-0">
  <h4>{{statusLabel}}</h4>
  <ul class="screening-results">
    <ng-container *ngIf="manualScreeningType === 'NOT_AVAILABLE'">
      <li>
        <span class="question">{{'manual-prescreening-results.availability' | translate}}</span>
        <span class="answer">{{'manual-prescreening-results.not_available' | translate: trVariable1}}</span>
      </li>
    </ng-container>

    <ng-container *ngIf="manualScreeningType !== 'NOT_AVAILABLE'">
      <li>
        <span class="question">{{'manual-prescreening-results.availability' | translate}}</span>
        <span class="answer">
          {{ manualScreeningType | stringclean: ['sansunderscore', 'titlecase'] }}
        </span>
      </li>
      <li>
        <span class="question">{{'manual-prescreening-results.minimum_payrate' | translate}}</span>
        <span class="answer">
          {{ manualScreeningMinPayVal | currency: 'USD' }}/{{ manualScreeningMinPayType | lowercase }}
        </span>
      </li>
      <li>
        <span class="question">{{'manual-prescreening-results.what_kind_of' | translate}}</span>
        <span class="answer">
          {{ manualScreeningOpps }}
        </span>
      </li>
      <li>
        <span class="question">{{'manual-prescreening-results.when_is_the' | translate}}</span>
        <span class="answer">{{ manualScreeningStartDate }}</span>
      </li>
      <li>
        <span class="question">{{'manual-prescreening-results.willing_to_travel' | translate}}</span>
        <span class="answer">{{ manualScreeningTravel }}</span>
      </li>
      <li>
        <span class="question">{{'manual-prescreening-results.skills' | translate}}</span>
        <span class="answer" *ngIf="!manualScreeningSkills.length">
          {{'manual-prescreening-results.not_specified.' | translate}}
        </span>
        <span class="answer" *ngFor="let skill of manualScreeningSkills">
          - {{ skill.displayName }}
        </span>
      </li>
    </ng-container>

    <li>
      <span class="question">{{'manual-prescreening-results.notes' | translate}}</span>
      <span class="answer" [innerHTML]="manualScreeningNotes"></span>
    </li>
  </ul>
</div>
