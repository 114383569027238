import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdManagementService {

  private adData = new BehaviorSubject({
    published: 2,
    inactive: 2,
    ads: [
      {
        ad_id: '12393j103kf',
        ad_published_by: 'David Brent',
        ad_jobStartTime: new Date(2019, 3, 22),
        ad_end_date: new Date(2019, 5, 1),
        ad_url: '#',
        isPublished: true
      },
      {
        ad_id: 'bvxb33103kf',
        ad_published_by: 'David Brent',
        ad_jobStartTime: new Date(2019, 2, 22),
        ad_end_date: new Date(2019, 4, 14),
        ad_url: '#',
        isPublished: true
      },
      {
        ad_id: '125235123kf',
        ad_published_by: 'David Brent',
        ad_jobStartTime: new Date(2019, 1, 11),
        ad_end_date: new Date(2019, 3, 20),
        ad_url: '#',
        isPublished: false
      },
      {
        ad_id: '12asdfasdfk',
        ad_published_by: 'David Brent',
        ad_jobStartTime: new Date(2019, 1, 2),
        ad_end_date: new Date(2019, 3, 25),
        ad_url: '#',
        isPublished: false
      }
    ]
  });
  public adDataObservable = this.adData.asObservable();

  constructor() { }

  getLogicMelonLink(id: string) {
    // TO-DO: Get link that allows users to post to external job boards.
  }

  getAdsByJobID(id: string) {
    // TO-DO: Make a call to the API to retrieve all ads by job id.
  }

  publishAd(): any {
    // TO-DO: Make a call to the API to publish an ad.
  }

  unpublishAd(id: string) {
    // TO-DO: Make a call to the API to unpublish an ad.
  }

  sortAdsByStatus(ads: any[]): any[] {
    return [...ads].sort((a, b) => {
      if (!a.isPublished && b.isPublished) {
        return 1;
      }
      if (a.isPublished && !b.isPublished) {
        return -1;
      }
      return 0;
    });
  }
}
