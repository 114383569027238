<div [class.modal-remarks]="isModal">

  <div *ngIf="currentTalent?.appointment && statusBannerMessage && statusInfoMessage" class="appointmentInfoContainer">
    <h3 class="section-title">Talent appointment</h3>
    <span class="appointmentBannerMessage" [innerHTML]="statusBannerMessage"></span>
    <div class="appointmentStatusMessage flex-row">
      <div class="statusMessage" [innerHTML]="statusInfoMessage"></div>
      <div class="currentTalent?.appointment?.appointment?.interviewStatus === 'SCHEDULED'">
        <span *ngIf="currentTalent?.appointment?.appointment?.interviewStatus === 'SCHEDULED'" id="cancel" (click)="cancelInterviewModal()">cancel appointment</span>
      </div>
    </div>
    <div class="appointmentButtonContainer flex-row" *ngIf="currentTalent?.appointment.appointment.interviewStatus !== 'COMPLETE'">
      <button
        class="btn"
        [class.btn-primary]="currentTalent?.appointment?.appointment?.interviewStatus !== 'SCHEDULED'"
        [class.rds--button--primary]="currentTalent?.appointment?.appointment?.interviewStatus === 'SCHEDULED'"
        (click)="logConnectClick()">
        log connect
      </button>
      <button
        class="btn"
        [class.rds--button--primary]="currentTalent?.appointment?.appointment?.interviewStatus !== 'SCHEDULED'"
        [class.btn-primary]="currentTalent?.appointment?.appointment?.interviewStatus === 'SCHEDULED'"
        (click)="logInterviewClick()">
        log as interview
      </button>
    </div>
  </div>

  <h3 class="section-title">{{'list-info.remarks' | translate}}</h3>

  <form [formGroup]="remarksForm">
    <div>
      <label>{{'list-info.general_notes' | translate}}<span class="optional">{{'list-info.optional' | translate}}</span></label>
      <textarea formControlName="generalNotes" maxlength="140" class="text-box talent-notes"></textarea>
      <span class="chars-remaining">
                Characters left: {{ generalNotesCharsLeft }}
        <!-- {{'list-info.characters_left' | translate: trVariable1}} -->
            </span>
    </div>

    <div class="mt-4">
      <label>{{'list-info.next_action' | translate}}<span
        class="optional">{{'list-info.optional' | translate}}</span></label>
      <textarea formControlName="nextAction" maxlength="140" class="text-box talent-notes"></textarea>
      <span class="chars-remaining">
                Characters left: {{ nextActionCharsLeft }}
        <!-- {{'list-info.characters_left' | translate: trVariable2}} -->
            </span>
    </div>
  </form>
  <button class="btn btn-primary save-button" (click)="saveRemarks()">{{'list-info.save' | translate}}</button>
</div>
