<div class="main" id="panelContainer" [ngClass]="{'stacked': stacked, 'narrow-view': isNarrowView}">
    <div class="left" [ngClass]="{'stacked': stacked, 'border-bottom': stacked}" #leftPanel>
        <ng-content select="[leftPanel]"></ng-content>
    </div>
    <div [hidden]="stacked" class="separator" [ngClass]="{'dragging': dragging}">
        <div class="menu-button" [ngClass]="{'dragging': dragging}" #separatorBtn>
            <img class="menu-button-icon" [ngClass]="{'dragging': dragging}" [src]="dragging ? '../../../assets/icons/resize-EW.svg' : '../../../assets/icons/resize-EW-white.svg'" />
        </div>
    </div>
    <div class="right" [ngClass]="{'stacked': stacked}" #rightPanel>
        <ng-content select="[rightPanel]"></ng-content>
    </div>
</div>

