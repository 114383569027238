export const serialMetadataKey = Symbol();
export const internalMetadataKey = Symbol();
export const rawKeyMetadataKey = Symbol();
export const modelKeyMetadataKey = Symbol();

export interface SerializationOptions {
  internals?: boolean; // when true, include @Internal() fields in the serialization
}

export interface Serial<Raw, Model> {
  serialize(model?: Model, options?: SerializationOptions): Raw;

  deserialize(raw?: Raw): Model;
}
