import { ProcessStatus, ProcessStep } from 'src/app/shared/models/external/misc.model';
import { Activity } from '../../models/internal/activity.interface';

export interface StepInfo {
  stepId: number;
  stepTitle: string;
  stepIcon: string;
  stepField: string;
  subStatus: ProcessStatus;
  activities?: Activity[];
}

export const RGS_STEPS = new Map<ProcessStep, StepInfo>([
  [
    ProcessStep.PRESCREENING,
    {
      stepId: 0,
      stepTitle: 'Pre-screening',
      stepIcon: 'assets/icons/pre-screening.svg',
      stepField: 'prescreening',
      subStatus: ProcessStatus.P_START
    }
  ],
  [
    ProcessStep.INTERVIEW_RECRUITER,
    {
      stepId: 1,
      stepTitle: 'Recruiter interview',
      stepIcon: 'assets/icons/recruiter-interview.svg',
      stepField: 'interview',
      subStatus: ProcessStatus.IR_PLAN_INTERVIEW
    }
  ],
  [
    ProcessStep.REVIEW_HM,
    {
      stepId: 2,
      stepTitle: 'Client Submission',
      stepIcon: 'assets/icons/hiring-manager.svg',
      stepField: 'clientSubmission',
      subStatus: ProcessStatus.RH_SUBMIT_TO_HM
    }
  ],
  [
    ProcessStep.INTERVIEW_HM,
    {
      stepId: 3,
      stepTitle: 'Client Interview',
      stepIcon: 'assets/icons/interview-hiring-manager.svg',
      stepField: 'clientInterview',
      subStatus: ProcessStatus.IH_SCHEDULE_INTERVIEW
    }
  ],
  [
    ProcessStep.OFFER,
    {
      stepId: 4,
      stepTitle: 'Offer',
      stepIcon: 'assets/icons/bill-rate.svg',
      stepField: 'offer',
      subStatus: ProcessStatus.O_SENT
    }
  ]
]);

export const RT_STEPS = new Map<ProcessStep, StepInfo>([
  [
    ProcessStep.PRESCREENING,
    {
      stepId: 0,
      stepTitle: 'Pre-screening',
      stepIcon: 'assets/icons/pre-screening.svg',
      stepField: 'prescreening',
      subStatus: ProcessStatus.P_START
    }
  ],
  [
    ProcessStep.INTERVIEW_RECRUITER,
    {
      stepId: 1,
      stepTitle: 'Recruiter interview',
      stepIcon: 'assets/icons/recruiter-interview.svg',
      stepField: 'interview',
      subStatus: ProcessStatus.IR_PLAN_INTERVIEW
    }
  ],
  [
    ProcessStep.REVIEW_AM,
    {
      stepId: 2,
      stepTitle: 'Internal Submission',
      stepIcon: 'assets/icons/review-accountmanager.svg',
      stepField: 'internalSubmission',
      subStatus: ProcessStatus.RA_SUBMIT_TO_AM
    }
  ],
  [
    ProcessStep.REVIEW_HM,
    {
      stepId: 3,
      stepTitle: 'Client Submission',
      stepIcon: 'assets/icons/hiring-manager.svg',
      stepField: 'clientSubmission',
      subStatus: ProcessStatus.RH_SUBMIT_TO_HM
    }
  ],
  [
    ProcessStep.INTERVIEW_HM,
    {
      stepId: 4,
      stepTitle: 'Client Interview',
      stepIcon: 'assets/icons/interview-hiring-manager.svg',
      stepField: 'clientInterview',
      subStatus: ProcessStatus.IH_SCHEDULE_INTERVIEW
    }
  ],
  [
    ProcessStep.OFFER,
    {
      stepId: 5,
      stepTitle: 'Offer',
      stepIcon: 'assets/icons/bill-rate.svg',
      stepField: 'offer',
      subStatus: ProcessStatus.O_SENT
    }
  ]
]);

export const PIPELINE_STEPS = new Map<ProcessStep, StepInfo>([
  [
    ProcessStep.PRESCREENING,
    {
      stepId: 0,
      stepTitle: 'Pre-screening',
      stepIcon: 'assets/icons/pre-screening.svg',
      stepField: 'prescreening',
      subStatus: ProcessStatus.P_START
    }
  ],
  [
    ProcessStep.INTERVIEW_RECRUITER,
    {
      stepId: 1,
      stepTitle: 'Recruiter interview',
      stepIcon: 'assets/icons/recruiter-interview.svg',
      stepField: 'interview',
      subStatus: ProcessStatus.IR_PLAN_INTERVIEW
    }
  ]
]);
