import { Jobs } from '@allbirds-ui/allbirds-types';
import { createModel, Mapping, Model } from './model';
import { ProcessStep } from './misc.model';
import { Serial } from './decorators/serial.decorator';
import { Count } from './serializers/count.serializer';
import { Rename } from './decorators/rename.decorator';
import { Internal } from './decorators/internal.decorator';
export import RawJobMetrics = Jobs.Metrics;

type IMetrics = { [key in ProcessStep]: number };

class JobMetricsMapping extends Mapping<RawJobMetrics> implements IMetrics {
  @Serial(Count) clientInterview = 0;
  @Serial(Count) clientSubmission = 0;
  @Serial(Count) internalSubmission = 0;
  @Serial(Count) webApplicants = 0;
  @Serial(Count) interviewScheduled = 0;
  @Serial(Count) @Rename('preScreening') prescreening = 0;
  @Serial(Count) @Rename('recruiterInterview') interview = 0;
  @Serial(Count) @Rename('placement') offer = 0;
  preScreening?: void;
  recruiterInterview?: void;
  placement?: void;
  @Internal() interviewSchedule = 0;
  @Internal() unanswered = 0;
}

export interface JobMetrics extends Model<JobMetricsMapping> {}

export const JobMetrics = createModel<JobMetrics>(JobMetricsMapping);

