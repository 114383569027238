import { Injectable, OnDestroy } from '@angular/core';
import { ApiService } from '../api/api.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ToastClass, ToastService } from '../toast';
import { ProfileQuery } from 'src/app/shared/models/internal/profile-query.models';
import { ExplainService } from 'src/app/shared/services/explain/explain.service';
import { Job } from '../../models/external/job.model';
import { GoogleProfile } from '../../models/external/google-profile.model';
import { v4 as uuidv4 } from 'uuid';
import {MassActionService} from '../mass-action/mass-action.service';
import { Profile } from 'src/app/shared/models/external/profile.model';
import { JobDetailsShortlistService } from 'src/app/shared/services/job-details-shortlist/job-details-shortlist.service';
import { LoadingSpinnerService } from 'src/app/shared/services/loading-spinner/loading-spinner.service';

@Injectable({
  providedIn: 'root'
})
export class JobDetailsService implements OnDestroy {

  // Currently-viewed job.
  public job: Job;

  // Last shortlisted job from add to job modal.
  public addToJobModalJob: Job;

  // Potential talent.
  public potentialTalent = new BehaviorSubject<GoogleProfile[]>([]);
  public potentialTalentObs = this.potentialTalent.asObservable();
  public potentialTalentTotal : number = 0

  // clear filter pills
  public clearPills = new BehaviorSubject(false);
  public clearPillsObs = this.clearPills.asObservable();
  
  // clear filter pills
  public updatePills = new BehaviorSubject({});
  public updatePillsObs = this.updatePills.asObservable();

  // Used to keep track of what "bucket" a talent is currently in.
  public isPotential: { [id: string]: boolean } = {};

  // Used for Google ME fetch call & infinity scroll.
  // loadingMoreResults = false;
  endOfGoogleResults = false;
  pageToken: string;
  resultSetId: string;
  requestId: string;
  // resultCount: 0;
  profileQuery: ProfileQuery;
  sectionOneActive = true;
  explain: string[];

  // Used for infinity scroll with Model S
  resultCount: 0;
  loadingMoreResults = false;
  loadingPriorResults = false;
  endOfResults = false;
  startOfResults = false;
  fetchFrom = 0;
  fetchFromLimit = 240;
  requestSize = 25;
  resultIndexStart = 0;

  //ID to help identify if a pagination is part of the same search in big query
  searchID: string;
  enhancedProfilesReturnFields = ['normEmail', 'normPhone', 'personNames', 'addresses', 'name', 'opco', 'externalId', 'allbirds_metadata', 'skillset', 'employmentRecords','customAttributes.ProfileLabels','covaxData','covaxLink', 'externalSystem', 'externalSystemLob']
  profileIdStore: any = {}

  // Because of a potential circular dependency, we cannot directly inject the ClientEvent service
  // into this service. Thus, we use this subject to emit events to the ClientEvent service.
  private clientEventSubject = new Subject();
  public clientEvent = this.clientEventSubject.asObservable();
  public shortListConversationSubs: Subscription[] = [];
  public conversations: any = [];

  // Keeps track of the active tab.
  public activeTab = '';

  constructor(
    private _api: ApiService,
    private _toast: ToastService,
    // private _clientEvent: ClientEventService,
    private _explain: ExplainService,
    private _massAction: MassActionService,
    private _shortlist: JobDetailsShortlistService,
     //loading service
    private _loading: LoadingSpinnerService
  ) { }

  ngOnDestroy(): void {
    // Resetting talent, etc.
    this.resetTalent();
    this.shortListConversationSubs.forEach(sub => sub.unsubscribe());
    this.profileIdStore = {}
  }

  /**
   * Initialize the service with everything required for it to function properly.
   * Should be called as soon as possible in the job-details-component.
   * @param jobOrder - job order object
   */
  initialize(jobOrder: Job, profileQuery?: ProfileQuery, selectedTalent?: string) {

    if (!profileQuery) {
      // console.log('NO PROFILE QUERY WAS GIVEN!!!');
      // profileQuery = {
      //   locationFilters: [{
      //     distanceInMiles: this.job.allbirds_metadata.lob.includes('RT') ? 25 : 15,
      //     regionCode: 'US',
      //     address: this._utility.buildAddressString(this.job.structuredAddresses[0]),
      //     negated: false
      //   }]
      // };
    }
    this.profileQuery = profileQuery;
    // Reset all the "buckets"
    this.resetTalent();
    // Capture current job information.
    this.job = jobOrder;
    // Flag for ME query.
    // this.endOfGoogleResults = false;
    this.endOfResults = false;
    //Reset pagination in query (offset field)
    this.fetchFrom = 0;
    //Set new searchID
    this.searchID = uuidv4();
    // Retrieve potential and shortlist talent.
    this.fetchPotentialTalentPOC(this.profileQuery, selectedTalent, true); //New Model S api POC
  }

  resetTalent() {
    this.potentialTalent.next([]);
    this.isPotential = {};
    this.profileIdStore = {}
  }

  /**
   * Retrieve potential talent.
   * @param google_job_id
   * @param address
   * @param filters
   */
  public fetchPotentialTalent(google_job_id: string, profileQuery?: ProfileQuery) {
    this.resultCount = 0;
    this.loadingMoreResults = true;
    this.potentialTalent.next([]);
    const body = { google_job_id, profileQuery };
    this.profileQuery = profileQuery;
    // console.log('profile query: ', profileQuery);
    this._api.getPotentialTalent(body)
      .subscribe(res => this.displayNewMatches(res), (err) => this.handleMatchingError(err));
  }

  //New Model S api POC
  //this is run when first loaded
  public fetchPotentialTalentPOC(profileQuery?: any, selectedTalent?: string, initializing?: boolean) {
    this.resultCount = 0;
    this.fetchFrom = 0;
    this.searchID = uuidv4();
    this.loadingMoreResults = true;
    this.potentialTalent.next([]);
    this.profileQuery = profileQuery;
    this._explain.isJobMatch = true;
    this._explain.keyword_filter = profileQuery.keywordFilter || [];

    // if (this.job.title) this._explain.keyword_filter.push(this.job.title);
    if (this.job.title) {
      this._explain.keyword_filter = this._explain.keyword_filter.concat(this.job.title.split(/[ ,\/]+/));
    }
    profileQuery.offset = this.fetchFrom;
    profileQuery.bodySize = undefined;
    profileQuery.searchID = this.searchID;
    profileQuery.returnFields = this.enhancedProfilesReturnFields;
    this._api.searchProfiles(profileQuery, selectedTalent)
      .subscribe(res => this.displayNewMatches(res, initializing), (err) => this.handleMatchingError(err));
  }

  handleMatchingError(error: any) {
    const trError = { 'value': error.error && error.error.error ? error.error.error.details : error.message };
    this._toast.showToastWithVariables(
      'job-details_service.errorcube', trError,
      { cssClass: ToastClass.DANGER }
    );
    console.error('matching engine error: ', error);
  }

  loadPriorResults(profileQuery: any) {
    // console.log('resultSetIndexStart is ', this.resultIndexStart);
    const offset = this.resultIndexStart > this.requestSize ? this.resultIndexStart - this.requestSize : 0;
    let size = this.requestSize;
    if (this.requestSize > offset && this.resultIndexStart < this.requestSize) size = this.resultIndexStart;
    if (offset === 0) {
      this.startOfResults = true;
    }
    const query = Object.assign(JSON.parse(JSON.stringify(profileQuery)), { size, offset });
    // console.log('query: ', query)

    return this._api.searchProfiles(query);
  }

  // New Model S api POC - displayNewMatches(response: { profiles: GoogleProfile[]; nextPageToken: string; requestId: string; resultSetId: string; })
  displayNewMatches(response: any, initializing?: boolean) {
    this.profileIdStore = {}
    if (initializing && response.from) {
      this.fetchFrom = response.from;
      this.resultIndexStart = response.from;
      this.resultCount = response.from;
      this.startOfResults = false;
    }
    // this._clientEvent.initialize(response.requestId, this.job.name); //review
    this.loadingMoreResults = false;
    const profilesTotal = response.total;
    this.potentialTalentTotal = response.total

    if (response.status === 'error') {
      console.warn('CURRENT JOB IS NOT ENHANCED FOR MODEL S');
    }

    for (const candidate of response.profiles) {
      if(!this.profileIdStore[candidate.name]){
        this.profileIdStore[candidate.name] = "added"
      }
      if(candidate){
        this.isPotential[candidate.externalId] = true;
        this.resultCount++;
        candidate.index = this.resultCount - 1;
      }
    }

    if (this.resultCount < this.fetchFromLimit && this.resultCount < profilesTotal
      && this.fetchFrom < profilesTotal) {
      this.endOfResults = false;
      this.fetchFrom = this.resultCount;

    } else {
      this.endOfResults = true;
    }




    this._explain.model_s_keywords_raw = response.explain;
    // this.potentialTalent.next(response.profiles);
    // this.potentialTalent.next([])
    this.potentialTalent.next(this.potentialTalent.getValue().concat(response.profiles));
  }

  addNewloadedTalentsToList(talent : any, startIndex: number){
    // this.potentialTalent.next([])
    // this.potentialTalent.next(this.potentialTalent.getValue().concat(talent));
    // console.log(talent)
  }

  infinityScroll(google_job_id: string, profile?: ProfileQuery) {

    if (profile) {
      this.profileQuery = profile;
    }
    this.loadingMoreResults = true;
    const body = {
      google_job_id,
      profileQuery: this.profileQuery,
      profile,
      pageToken: this.pageToken,
      resultSetId: this.resultSetId
    };

    // return this._api.getPotentialTalent(body);
    return this._api.searchProfiles(body); //New Model S api POC
  }
  //this is run when page change
  loadMoreTalents(profileQuery: any, massLoad?: boolean, totalSelected?: number) {
    if(profileQuery.pagination){
      profileQuery = profileQuery.query
    }
    
    this.loadingMoreResults = true;
    profileQuery.offset = this.fetchFrom;
    if (massLoad) {
      let returnTalentCount = this._massAction.getMassTalentSize(); // reverting 1000 increase for job details page
      if(!(returnTalentCount ===50)){
        returnTalentCount = 250
      }
      profileQuery.bodySize = returnTalentCount;
      profileQuery.offset = 0
    }
    profileQuery.returnFields = this.enhancedProfilesReturnFields;
    if (!this.searchID) this.searchID = uuidv4();
    profileQuery.searchID = this.searchID;
    // return this._api.getPotentialTalent(body);
    // console.log("check if load more talent is run",profileQuery)
    let startIndex = profileQuery.offset + 1
    return this._api.searchProfiles(profileQuery)
  }

  generateStructureForPagination (data: any, startIndex:number) {
    let keys = []
    for (let value of data ){
      keys.push(value.name)
    }
    if (!data || !data?.length) {
      return;
    }
    let index = startIndex;
    let loadedTalent = this.potentialTalent.getValue()
    while (data.length) {
      if (loadedTalent[index] === undefined) {

        let unfilledIndex = loadedTalent.length;
        while (loadedTalent[unfilledIndex] === undefined && unfilledIndex - 1 !== index)
        {
          loadedTalent.push(null);
          unfilledIndex++;
        }
      }
      if (loadedTalent[index] === null) {
        loadedTalent[index] = data.shift();
        if(!this.profileIdStore[loadedTalent[index].name]){
          this.profileIdStore[loadedTalent[index].name] = "added"
        }
      } 
      else if(loadedTalent[index].externalId === data[0].externalId){
        data.shift()
      } ``
      index++;
    }
    this.potentialTalent.next(loadedTalent)
    // this.potentialTalent.next(this.potentialTalent.getValue().concat(loadedTalent));
    // console.log(this.potentialTalent.getValue())

    // for (let i = 0; i <  this.paginationStartIndex + 25; i++) {
    //   if (!data.length) {
    //     return;
    //   }
    //   if (this.paginationStartIndex <= i && i < this.paginationStartIndex + 25 ) {
    //     if (this.profiles[i] === undefined) {
    //       this.profiles.push(null);
    //     }
    //     this.profiles[i] = data.shift();
    //   } else if (this.profiles[i] === undefined) {
    //     this.profiles.push(null);
    //   }
    // }
  }
  
    selectAllTalent( query? : any, talentOffset: number = 0){
    this._loading.show()
    let lobMassTalentSize = this._massAction.getMassTalentSize()
    if(!(lobMassTalentSize === 50)){
      lobMassTalentSize = 250
    }
    // set the bodySize as 250 for both RT and RGS to avoid making too many calls to modelS because of shortlisted Talents
    query.bodySize = 250;
    query.returnFields = this.enhancedProfilesReturnFields; 
    if (!this.searchID) this.searchID = uuidv4();
    query.searchID = this.searchID;
    // console.log(query)
    // console.log("check how many time this is run")
    this._api.searchProfiles(query).subscribe(data=>{
      let totalResult = data.total
      let existedTalent = [...this.potentialTalent.getValue()]
      // let profilesForPaginationStructure = [...data.profiles]
      let index = talentOffset
      while (data?.profiles?.length ) {
        // when mass select, preserve the current selections but pick rest from 0 to limit
        let talent = data.profiles.shift();
        if (Object.keys(this._massAction.selectedProfiles).length !== lobMassTalentSize) {
          if(!(this._shortlist.isRejected(talent) || this._shortlist.isShortlisted(talent) || this._shortlist.isHidden(talent)) && !(talent.name in this._massAction.selectedProfiles )) {
            this._massAction.handleSelect(talent as any as Profile);
          }
        }
        if (!this.profileIdStore[talent.name]) { // if talent is already in this.profiles, just skip
          if (existedTalent[index]) { // if we already have talent on this index
            data.profiles.unshift(talent); // put talent back
            index++;// go to next index to check
            continue;
          }
          if (!existedTalent[index]) { // if this index is undefined, that means we did hit the last element
            existedTalent[index] = talent; // just push the talent
          } else if (existedTalent[index] === null) { // if this index is null, that means we filled this index with null for pagination earlier
            existedTalent[index] = talent; // just assign the talent to this index
          }
          this.profileIdStore[talent.name] = 'added'; // add talent to profileStore object for easy check later
          index++; // go to next index
        }
      }
      // this.generateStructureForPagination(profilesForPaginationStructure,query.offset)
      this.potentialTalent.next(existedTalent)
      query.offset = query.offset + lobMassTalentSize
      if(Object.keys(this._massAction.selectedProfiles).length !== lobMassTalentSize && query.offset < totalResult){
        this.selectAllTalent(query,query.offset)
      } else{
        this._loading.hide()
      }
    })
  }


  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

}
