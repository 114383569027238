import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-appointment-email',
  templateUrl: './appointment-email.component.html',
  styleUrls: ['./appointment-email.component.scss']
})
export class AppointmentEmailComponent implements OnInit {
  @Input() emailMessageForm: FormGroup;

  constructor() { }

  ngOnInit() { }

}
