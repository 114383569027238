import { ApiService } from './../../../../../shared/services/api/api.service';
import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChildren,
  QueryList,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { PaginateService } from 'src/app/shared/services/paginate/paginate.service';
import { TalentService } from 'src/app/shared/services/talent/talent.service';
import { MetricsService } from 'src/app/shared/services/metrics/metrics.service';
import { GoogleProfile } from '../../../../../shared/models/external/google-profile.model';
import { IPageInfo } from 'ngx-virtual-scroller';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';

import { Application } from 'src/app/shared/models/external/application.model';
import { Profile } from 'src/app/shared/models/external/profile.model';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { MassEmailModalComponent } from 'src/app/shared/components/mass-email-modal/mass-email-modal.component';
import { MASS_OPERATION_CONTEXT } from 'src/app/shared/components/mass-email-modal//mass-operation-context';

import { MassActionService } from 'src/app/shared/services/mass-action/mass-action.service';
import { ToastService, ToastClass } from 'src/app/shared/services/toast';
import { Subscription, noop } from 'rxjs';
import { MassSelectTalent } from 'src/app/shared/services/mass-action/mass-action.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { MassSmsModalComponent } from 'src/app/shared/components/mass-sms-modal/mass-sms-modal.component';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { Card } from 'src/app/shared/models/internal/card.model';
import { JobDetailsShortlistService } from 'src/app/shared/services/job-details-shortlist/job-details-shortlist.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AddToListAModalComponent } from '../../list-details/add-to-list-a-modal/add-to-list-a-modal.component';
import { SchedAppointmentInterviewModalComponent } from "src/app/shared/components/sched-appointment-interview-modal/sched-appointment-interview-modal.component";
import {TalentManagementService} from '../../../../../shared/services/talent-management/talent-management.service';
import {LoadingSpinnerService} from '../../../../../shared/services/loading-spinner/loading-spinner.service';
import { ActionInfo } from 'src/app/shared/models/internal/user-action.interface';
import { UserActionsService } from 'src/app/shared/services/user-actions/user-actions.service';


@Component({
  selector: 'app-talent-management-search-results',
  templateUrl: './talent-management-search-results.component.html',
  styleUrls: ['./talent-management-search-results.component.scss']
})
export class TalentManagementSearchResultsComponent implements OnInit, OnChanges {
  @Input() query: any;
  @Input() potentialTalents: GoogleProfile[];
  @Input() searchResults: any;
  @Input() enableMassSelect: boolean = true;
  @Input() loading: boolean;
  @Input() isKnownTalentSearchActive: boolean;
  @Input() massSelectBoxChecked: boolean;
  @Output() massSelectBoxCheckedChange = new EventEmitter<any>(); // you are able to do 2way binding [(massSelectBoxChecked)], if you create xChange output.
  @Input() checkBoxIndeterminate: boolean;
  @Input() startIndex: any;
  @Input() paginationCurrentPage: any;
  @Output() selectTalentEmit = new EventEmitter<GoogleProfile>();
  @Output() nextEmit = new EventEmitter<IPageInfo>();
  @Output() selectAllEmit = new EventEmitter<any>();
  @Output() checkBoxState = new EventEmitter<any>();
  @Output() changePaginationState = new EventEmitter<any>();
  @Output() handleSelectionPaginatedTalent = new EventEmitter<any>();
  @ViewChildren(VirtualScrollerComponent) scrollers: QueryList<VirtualScrollerComponent>
  virtualScroller :VirtualScrollerComponent;
  trVariable1: any;
  isRGSLOB: boolean;
  private totalSelected: number = 0;
  private lobMaxSelectionNumber: number = 0;
  massActionSub: Subscription;
  selectedTalentID: any;
  disableMassSchedAppt: boolean = false;
  hideMassSchedAppt: boolean = false;
  massSchedApptMsg: string = ''

  //Recently Viewed
  rvActionInfo: ActionInfo = {
    action_category: 'recently_viewed',
    action_type: 'click',
    action_location: 'talent_search'
  }
  rvTalent: {id: string, newDate: string | boolean}
  @Input() rvTalentSelected : boolean;

  constructor(
    private talentService: TalentService,
    public paginate: PaginateService<GoogleProfile>,
    // private _clientEvent: ClientEventService,
    private _metrics: MetricsService,
    private _bsModalService: ModalService,
    private _massAction: MassActionService,
    private _toast: ToastService,
    private _auth: AuthService,
    private _shortlist: JobDetailsShortlistService,
    private _api: ApiService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _spinner: LoadingSpinnerService,
    private cd: ChangeDetectorRef,
    private _uaService: UserActionsService
  ) { }

  get userLob(): string { return this._auth.user && this._auth.user.Source || ''; }
  get maxSelectionReached(): boolean { return this.totalSelected >= this.lobMaxSelectionNumber; }
  get isSelectionDisabled(): boolean { return this.maxSelectionReached && (!this.massSelectBoxChecked && !this.checkBoxIndeterminate ); }

  ngOnInit() {
    this.massSelectBoxChecked = false;
    this.isRGSLOB = this.userLob.checkLob('RGS');
    if (this.searchResults !== undefined) {
      if (this.searchResults?.total) { //New Model S api POC
        this.trVariable1 = { 'value': this.searchResults?.total };
      }
      this.lobMaxSelectionNumber = this._massAction.getMassTalentSize();
    }
    this.checkCellaLob();
    if (!this.loading && !this.enableMassSelect) {
      this.paginationCurrentPage = 1;
      this.startIndex = 0;
    }
    this.listenToMassAction();
    this.checkBoxState.emit(this.startIndex);
    // console.log("paginationCurrentPage" +this.paginationCurrentPage)
    // console.log("startIndex" +this.startIndex)
  }

  ngOnDestroy() {
    //this._massAction.handleSelect(profile as any as Profile);
    this.massSelectBoxChecked = false;
    this.totalSelected = 0;
    this.massActionSub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.loading) {
      this.paginationCurrentPage = 1;
      this.startIndex = 0;
    }
    this.checkBoxState.emit(this.startIndex);
    if (this.searchResults && this.searchResults?.total) {
      this.trVariable1 = { 'value': this.searchResults?.total };
      if (!this.startIndex && this.startIndex !== 0) {
        this.startIndex = this.searchResults.from;
        return;
      }
    }
    // if (this.massSelectBoxChecked == true && this.searchResults.total > 0) {
    //   this.fauxSelectedTotal = this.searchResults.total < this.fauxSelectedCount ?
    //       this.searchResults.total : this.fauxSelectedCount;
    // }
  }

  listenToMassAction() {
    this.massActionSub = this._massAction.massSelectTalentObs
      .subscribe((talent: MassSelectTalent) => {
        if (talent) {
          this.totalSelected = Object.keys(this._massAction.selectedProfiles).length;
        }
        // if (this.massSelectBoxChecked == true && this.searchResults.total > 0) {
        //   this.fauxSelectedTotal = this.searchResults.total < this.fauxSelectedCount ?
        //       this.searchResults.total : this.fauxSelectedCount;
        // }
      });
  }

  handleMassSelect(talent: Application | Profile) {
    this._massAction.handleSelect(talent);
    this.checkBoxState.emit(this.startIndex);
  }

  resetAllTalent () {
    this._spinner.show();
    this._massAction.reset();
    this.massSelectBoxChecked = false;
    this.checkBoxIndeterminate = false;
    this._spinner.hide();

  }

  selectAllTalents() {
    if (!this.isSelectionDisabled) {
      this.selectAllEmit.emit(true);
    }
  }

  selectPaginateTalents () {
    if (!this.isSelectionDisabled) {
      if (!this.checkBoxIndeterminate) {
        this.massSelectBoxChecked = !this.massSelectBoxChecked;
      }
      this.massSelectBoxCheckedChange.emit(this.massSelectBoxChecked);
      this.handleSelectionPaginatedTalent.emit({startIndex: this.startIndex, type: 'pagination'});
      this.checkBoxState.emit(this.startIndex);
    }
  }


  massEmail() {
    if ( Object.keys(this._massAction.selectedProfiles).length == 0) {
      this._toast.showToast('talent-management-search-results.no_selection', { cssClass: ToastClass.DANGER });
    } else {
      const initialState = {
        context: MASS_OPERATION_CONTEXT.modelsPotential,
      };
      this._bsModalService.show(MassEmailModalComponent, { initialState });
    }
  }

  massSMS() {
    if(Object.keys(this._massAction.selectedProfiles).length == 0) {
      this._toast.showToast('talent-management-search-results.no_selection', { cssClass: ToastClass.DANGER });
    }
    else {
      const initialState = {
        context: MASS_OPERATION_CONTEXT.modelsPotential,
      };
      this._bsModalService.show(MassSmsModalComponent, { initialState });
    }
  }

  async massAddToJobClick() {
    // No longer using AddToJobComponent
    // const config: ModalOptions<any> = {
    //   initialState: {
    //     shortlistHandler: this.addToShortlists.bind(this),
    //     talentList: this._massAction.selectedProfiles,
    //     isMassAddToJob: true,
    //     context: MASS_OPERATION_CONTEXT.modelsPotential
    //   },
    //   class: 'modal-add-talent-to-job' };
    // this._bsModalService.show(AddToJobComponent, config);
    if (Object.keys(this._massAction.selectedProfiles).length == 0) {
      this._toast.showToast('talent-management-search-results.no_selection_job', { cssClass: ToastClass.DANGER });
      return;
    }
    let filters = [];
    filters.push({'value': this._auth.user._id, 'field': 'user', 'type': 'string', 'display': `${this._auth.user.FirstName} ${this._auth.user.LastName}`});
    if (this.userLob.checkLob('RGS')) {
      filters = [...filters, ...[
        {'value': 'Unfilled', 'field': 'allbirds_metadata.order_status', 'type': 'string', 'lob': 'RGS'},
        {'value': 'Client Order', 'field': 'allbirds_metadata.order_type', 'type': 'string'}
      ]];

    } else if (this.userLob.checkLob('RT', 'RE','CR')) {
      filters = [...filters,
        ...[
          {'value': 'Accepting Candidates', 'field': 'allbirds_metadata.order_status', 'type': 'string', 'lob': this.userLob},
          {'value': 'Unqualified', 'field': 'allbirds_metadata.order_status', 'type': 'string', 'lob': this.userLob},
          {'value': 'Covered', 'field': 'allbirds_metadata.order_status', 'type': 'string', 'lob': this.userLob},
          {'value': 'On Hold', 'field': 'allbirds_metadata.order_status', 'type': 'string', 'lob': this.userLob},
          {'value': this.userLob, 'field': 'allbirds_metadata.lob', 'type': 'string'}]
      ];
    }
    let profileIds: any[] = [];
    let disqualifiedProfiles: any[] = []
    const selectedActs = Object.entries(this._massAction.selectedProfiles);
    for (const [key, value] of selectedActs)  {
      const app: Profile = value;
      if(app.isDisqualified) disqualifiedProfiles.push(app)
      else {
        if (app.name) profileIds.push(app.name)
        else noop
      }
    };
    if (profileIds.length > 0) {
      const url = `/talent/add-to-job/search?sourceType=all&filters=${JSON.stringify(filters)}&profile_ids=${JSON.stringify(profileIds)}&isMassAddToJob=true&context=${MASS_OPERATION_CONTEXT.modelsPotential}`;
      this._router.navigateByUrl(url, {state: { url: this._router.url}});
    } else if (disqualifiedProfiles.length > 0){
      this._toast.showToast('talent-management-search-results.disqualified_talent', { cssClass: ToastClass.DANGER });
      return;
    }
  }

  checkCellaLob() {
    if (this._auth?.user?.Source === 'CR') {
      this.massSchedApptMsg = "You cannot send an invite as the user/job is from Cella line of business"
      this.disableMassSchedAppt = true;
      this.hideMassSchedAppt = true;
      return true;
    }
    else {
      this.massSchedApptMsg = "schedule appointment or interview"
      return false;
    }
  }
  massScheduleAppointmentClick() {
    //Disable this mass action for CR users
    if (this.checkCellaLob()) return;
    if (Object.keys(this._massAction.selectedProfiles).length == 0) {
      this._toast.showToast('talent-management-search-results.no_selection_appointment', { cssClass: ToastClass.DANGER });
      return;
    }
    const selectedMassTalent = Object.keys(this._massAction.selectedProfiles).map((t) => this._massAction.selectedProfiles[t] );
    const currentId = this._route.snapshot.paramMap.get('listId');
    const config: ModalOptions<any> = {
      initialState: {
        selectedTalent: selectedMassTalent,
        isMassAddToList: true,
        listId: currentId,
        operationContext: MASS_OPERATION_CONTEXT.modelsPotential
      },
      class: 'modal-add-talent-to-job'
    };
    this._bsModalService.show(SchedAppointmentInterviewModalComponent, config);
  }

  addToShortlists(selectedCard: Card, notShortlistedIds: string[]) {
    if (selectedCard && notShortlistedIds && notShortlistedIds.length) {
      this._api.getProfilesById({names: notShortlistedIds, 'source': ['addresses','personNames','name','elastic_id','externalId', 'employmentRecords',
      'lastPosition', 'externalSystem', 'emailAddresses', 'phoneNumbers']})
        .subscribe((profiles: Profile[]) => {
          this._massAction.bulkShortlist(profiles, selectedCard.job_order)
            .then((resp: { applications: Application[] }) => {
              this._bsModalService.hide(1);
              // profiles.forEach((profile) => {
              //   this._shortlist.emitClientEvent(CLIENT_EVENT_TYPES.MODAL_PROFILE_SHORTLIST, profile);
              // });
              this._toast.showToast(`${resp.applications.length} candidates have been shortlisted.`, {});
            })
            .catch((err: any) => {
              if (err.reason) {
                this._toast.showToast(err.reason, { cssClass: ToastClass.DANGER });
              } else {
                this._toast.showToastNoTranslation('mass-action.unknown-error', { cssClass: ToastClass.DANGER });
              }
            });
        }, (err: any) => {
          this._toast.showToast('mass-action.unknown-error', { cssClass: ToastClass.DANGER });
        });
    }
  }

  massAddToListClick() {
    const currentId = this._route.snapshot.paramMap.get('listId');
    const config: ModalOptions<any> = {
      initialState: {
        talentList: this._massAction.selectedApplications,
        isMassAddToList: true,
        currentId,
        context: MASS_OPERATION_CONTEXT.modelsPotential
      },
      class: 'modal-add-talent-to-job'
    };
    this._bsModalService.show(AddToListAModalComponent, config);
  }


  // Recently-viewed indicator
  saveRecentlyViewed(talent: GoogleProfile) {
    this.rvTalent = this._uaService.setRVTempDate(talent.externalId, talent?.actionsOnProfile);
    this._uaService.saveUserAction([talent.externalId], this.rvActionInfo);
  }

  selectTalent(talent: GoogleProfile) {
    this.saveRecentlyViewed(talent);
    this.selectTalentEmit.emit(talent);
    this.totalSelected = Object.keys(this._massAction.selectedProfiles).length;
  }

  infinityScroll($event: IPageInfo) {
    this.nextEmit.emit($event);
  }

  scrollBackToCurrentlySelected (talent: GoogleProfile) {
    if (this.virtualScroller) this.virtualScroller.scrollInto(talent);
  }

  onInViewportChange(inViewport: boolean, talentId: string, externalId: string, index: number) {
    if (inViewport) {
      // this._clientEvent.addToImpressionQueue(talentId);
      this._metrics.addToImpressionQueue(externalId, index);
    }
  }

  storeSelectedTalentId(event: any) {
    this.selectedTalentID = event;
  }

  pageChanged($event: any) {
    this._spinner.show();
    console.log($event);
    $event.type = 'pagination';
    this.startIndex = $event.itemsPerPage * ($event.page - 1);
    this.paginationCurrentPage = $event.page;
    $event.startIndex = this.startIndex;
    this.changePaginationState.emit($event); // page number and start index has changed, change this in parent scope as well
    // check if we already have the talents for this page
    const isTalentExist = !!this.potentialTalents.slice(this.startIndex, this.startIndex + 25).filter( x => x).length;
    if (isTalentExist) {
      this._spinner.hide();
      this.checkBoxState.emit(this.startIndex);
      return; // talents already in memory, do not make a call
    }
    this.nextEmit.emit($event);
  }
}
