import { Injectable } from '@angular/core';
import { JobOrderService } from '../job-order/job-order.service';
import { AuthService } from '../auth/auth.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../user/user.service';
import { UrlStateService } from '../url-state/url-state.service';
import { Job } from '../../models/external/job.model';
import { ApiService } from '../api/api.service';
import { JobFilter } from '../../../modules/jobs/pages/job-management/jobs-filter/jobs-filter.component';
import moment from 'moment';
import {Card} from '../../models/internal/card.model';
import {Lane} from '../../models/internal/lane.model';
import { AngularFirePerformance } from '@angular/fire/performance';

export interface AddToTalent {
  excludeInProgress: boolean;
  jobTitle?: string;
  clientName?: string;
  jobId?: string;
}

export interface JobParam {
  search: string;
  filters: JobFilter[];
  sourceType: string;
  addToTalent?: AddToTalent;
  from?: number;
  fields?: any;
  userIds?: any[];
  branchIds?: any[];
}

export interface Filter {
  value: string,
  display: string,
  field: string,
  type: string,
  lob: string
}

@Injectable({
  providedIn: 'root'
})
export class JobManagementService {
  currentPage: string;
  private dataSource = new BehaviorSubject<Job[]>([]);
  jobManagementData = this.dataSource.asObservable();

  private dataSourceSearch = new BehaviorSubject<Job[]>([]);
  jobSearchData = this.dataSourceSearch.asObservable();

  private dataSourceSearchnew = new BehaviorSubject<{jobs: Job[], total: number}>({jobs: null, total: null});
  jobSearchnewData = this.dataSourceSearchnew.asObservable();

  private dataSourceTalentSearch = new Subject<Job[]>();
  jobTalentSearchData = this.dataSourceTalentSearch.asObservable();

  private paramSource = new BehaviorSubject<JobParam>({ search: null, filters: null, sourceType: null });
  jobParams = this.paramSource.asObservable();

  private paramSourceSearch = new BehaviorSubject<JobParam>({ search: null, filters: null, sourceType: null });
  jobParamsSearch = this.paramSourceSearch.asObservable();

  savedSearches = new BehaviorSubject<any>([]);
  recentSearches = new BehaviorSubject<any>([]);
  user: any;

  // Total number of jobs we fetch per API call.
  public FETCH_COUNT = 10;

  // Index of where to begin pulling results from the result set (infinity scroll).
  public from: number;

  // Total number of jobs in the job search.
  total: number;

  // Flag that determines if we need to keep fetching results.
  keepFetching: boolean;

  // Flag that determines if we are currently fetching results.
  isFetching: boolean;

  constructor(
    private _api: ApiService,
    private _jobOrder: JobOrderService,
    private _auth: AuthService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _user: UserService,
    private _url: UrlStateService,
    private performance: AngularFirePerformance
  ) {
    this.user = this._auth.user;
    this.jobParams.subscribe((data) => {
      // if (this._router.url.includes('jobs/search')) {
      //   if (data.search || data.filters) {
      //     data.sourceType = 'all'; // temporary since source section not gonna be in the first release
      //     this.getSearchnewJobs(data, 'all');
      //   }
      // } else if (this._router.url.includes('jobs/search')) {
      //   if (data.search) {
      //     data.sourceType = 'all'; // temporary since source section not gonna be in the first release
      //     this.getSearchJobs(data, 'all');
      //   }
        // data.sourceType = 'all'; // temporary since source section not gonna be in the first release
        // this.getSearchJobs(data, 'all');
      if (data.addToTalent) {
        if (data.addToTalent || data.sourceType) {
          this.getAddTalentJobs(data);
        }
      }
      this.encodeState(data);
    });
    this.jobParamsSearch.subscribe(data => {
      if (this._router.url.includes('jobs/search') || this._router.url.includes('add-to-job/search')) {
        if (data.search || data.filters) {
          data.sourceType = 'all';
          this.getSearchnewJobs(data, 'all');
        }
      }
      this.encodeState(data);
    })
  }

  public initialize() {
    this.keepFetching = true;
    this.isFetching = false;
    this.from = 0;
    this.total = 0;
  }

  encodeState(data: any) {
    const params = {
      search: data.search,
      sourceType: data.sourceType,
      filters: JSON.stringify(data.filters) // yes, this produces an ugly-as-hell URL. I know.
    }
    this._url.overwrite(params, false);
  }

  getMyJobs(params: any, source?: String) {
    params.sourceType = params.sourceType || 'myJobs';
    const paramsList = {
      lob: this.user.Source
      , ...params
    };
    this._api.getJobs(paramsList)
      .subscribe(res => {
        if (res) {
          this._user.extractUsersFromJobOrders(res)
            .catch(err => console.log(err))
            .finally(() => this.dataSource.next(res));
        }
      }, err => {
        console.log(err);
        this.dataSource.next(null);
      });
  }

  async getSearchJobs(params: any, source?: String) {
    const trace = await this.performance.trace('job-search-results');
    trace.start(); // firebase performance monitoring
    const paramsList = {
      lob: this.user.Source
      , ...params
    };
    this._api.getJobs(paramsList).subscribe(res => {
      if (res) {
        this._user.extractUsersFromJobOrders(res)
          .catch(err => console.log(err))
          .finally(() => {
            this.dataSourceSearch.next(res);
            trace.stop;
          });
      }
    });
  }

  getSearchnewJobs(params: any, source: String): any {
    if (this.isFetching || !this.keepFetching) {
      // return this.dataSource Searchnew.next({jobs: null, total: null});
    }
    if (this.keepFetching) {
      this.isFetching = true;
      const paramsList = {
        user_email: this._auth.user.EmailAddr,
        lob: this.user.Source,
        from: this.from
        , ...params
      };
      this._api.getSearchJobs(paramsList).subscribe(res => {
        if (res) {
          this._user.extractUsersFromJobOrders(res.jobs)
            .catch(err => console.log(err))
            .finally(() => this.dataSourceSearchnew.next(res));
          this.from += this.FETCH_COUNT;
          this.total = res.total ? res.total : 0;
          this.keepFetching = this.from < this.total;
          this.isFetching = false;
        }
      });
    }
  }

  getAddTalentJobs(params: JobParam) {
    const paramsList = {
      user_back_office_id: this.user.BackOfficeID,
      lob: this.user.Source
      , ...params
    };
    this._api.getJobs(paramsList).subscribe(res => {
      if (res) {
        this.dataSourceTalentSearch.next(res);
      }
    }, err => {
      console.log(err);
    });
  }

  async updateSavedSearch(payload: any) {
    try {
      const updatedSearchResponse: any = await this._api.updateJobSavedSearch(payload).toPromise();
      const currentSavedSearches = this.savedSearches.getValue();
      const savedSearchIdx = currentSavedSearches.findIndex( (srch:any) => srch._id === updatedSearchResponse._id);
      if (savedSearchIdx > -1) {
        currentSavedSearches.splice(savedSearchIdx, 1, updatedSearchResponse);
      }
      this.savedSearches.next(currentSavedSearches);
      return updatedSearchResponse;
    } catch (error) {
      console.error('An error occurred updating a search');
      return null;
    }

  }

  async addSavedSearch(savedSearch: any) {
    const payload: any = {
      searchId: null,
      title: savedSearch.displayTitle,
      owner: this._auth.user.EmailAddr,
      create_time: moment(),
      last_update_time: moment(),
      last_update_user: this._auth.user.EmailAddr,
      viewers: [],
      editors: [this._auth.user.EmailAddr],
      jobSearchQuery: {
        candidateType: savedSearch.fields.candidateTypes,
        employmentType: savedSearch.fields.employmentTypes,
        jobStatus: savedSearch.fields.jobStatuses,
        remoteWork: savedSearch.fields.remoteWork,
        jobOwner: savedSearch.fields.ownership,
        customerName: savedSearch.fields.client,
        jobTitle: savedSearch.fields.title,
        locationFilters: {
          city: savedSearch.fields.city,
          state: savedSearch.fields.state,
          zip: savedSearch.fields.zip
        },
        skills: savedSearch.fields.jobSkills,
        businessUnits: savedSearch.fields.businessUnits,
        nsos: savedSearch.fields.nsos,
        dateFrom: savedSearch.fields.dateFrom,
        hiringManager: savedSearch.fields.hiringManager,
        lobs: savedSearch.fields.lobs,
        keyword: savedSearch.fields.keyword,
        location: savedSearch.fields.location,
        latLong: savedSearch.fields.latLong,
        distance: savedSearch.fields.distance,
        orderType: savedSearch.fields.orderType,
        serviceLines: savedSearch.fields.serviceLines,
        subServiceLines: savedSearch.fields.subServiceLines,
        clientPartner: savedSearch.fields.clientPartner,
        collaboratorList: savedSearch.fields.collaboratorList,
        ...( (savedSearch.fields.includeLinkedJobs !== null || savedSearch.fields.includeLinkedJobs !== undefined)  && { includeLinkedJobs: savedSearch.fields.includeLinkedJobs })
      }
    };
    const savedSearchResponse = await this._api.createJobSavedSearch(payload).toPromise();
    const currentSavedSearches = this.savedSearches.getValue();
    currentSavedSearches.unshift(savedSearchResponse);
    this.savedSearches.next(currentSavedSearches);
    return savedSearchResponse;
  }

  getSavedSearches() {
    this._api.getSavedSearches(this._auth.user.EmailAddr, 'jobSearches')
    .subscribe( savedSearchesResponse => {
      this.savedSearches.next(savedSearchesResponse);
    });
  }

  getRecentSearches() {
    this._api.getRecentSearches(this._auth.user.EmailAddr, 'jobSearch')
      .subscribe((recentSearchesRes) => {
        console.log(recentSearchesRes);
        this.recentSearches.next(recentSearchesRes);
    });
  }

  async deleteSavedSearch(id: string) {
    try {
    const updatedSearchResponse: any = await this._api.deleteSavedSearch(id, 'jobSearch').toPromise();
    let savedSearches = this.savedSearches.getValue();
    savedSearches = savedSearches.filter( (srch: { _id: string }) => srch._id !== updatedSearchResponse._id);
    this.savedSearches.next(savedSearches);
    return updatedSearchResponse;
    } catch (error) {
      console.error('An error occurred deleting a search');
      return null;
    }
  }

  destroySearchJobs() {
    this.updateSearch(null);
    this.dataSourceSearch.next([]);
  }

  destroySearchnewJobs() {
    this.updateSearchnew(null, null, null, null);
    this.dataSourceSearchnew.next({jobs: null, total: null});
  }

  destroySearchnewJobsNoParams() {
    this.dataSourceSearchnew.next({jobs: null, total: null});
  }

  destroyAddToTalentParams() {
    this.updateAddToTalentParams(null, null);
  }

  updateSourceType(value: string) {
    this.paramSource.next({ ...this.paramSource.value, sourceType: value });
  }

  updateFilters(filters: any) {
    this.paramSource.next({ ...this.paramSource.value, filters: filters });
  }

  updateSearch(value: string) {
    this.paramSource.next({ ...this.paramSource.value, search: value });
  }

  updateFiltersAndSearch(filters: any = [], search: string = null){
    this.paramSource.next({...this.paramSource.value, filters, search})
  }

  updateSearchnew(value: string, filters: any, fields: any = {}, userIds?: any) {
    // if userIds is passed as null or array, update the param field
    if(userIds === null || userIds){
      this.paramSourceSearch.next({ ...this.paramSource.value, search: value, filters: filters, fields, userIds });
    }
    // if userIds is not passed, do not change
    else {
      this.paramSourceSearch.next({ ...this.paramSource.value, search: value, filters: filters, fields });
    }
  }

  updateAddToTalentParams(addToTalent: AddToTalent, sourceType: string) {
    let obj = { ...this.paramSource.value, addToTalent, sourceType };
    if (sourceType === 'all') { obj.userIds = []}
    if (sourceType === 'myJobs') { obj.userIds = [this.user._id]}
    this.paramSource.next(obj);
    this.paramSourceSearch.next(obj);
  }

  updateRecruiterValues(userIds: any, sourceType?: string, branchIds?: any) {
    this.paramSource.next({ ...this.paramSource.value, userIds: userIds, sourceType: sourceType, branchIds: branchIds });
  }

  destroyUserIds(){
    this.paramSource.next({ ...this.paramSource.value, userIds: null });
  }

  // It will distribute orders into lanes as a card. It goes through orders array only once.
  // Puts the order into lane if it meets with the required condition
  distributeOrders(orders: Job[], lanes: Lane[]) {
    let t0 = performance.now()
    // clear out old data before
    for (const lane of lanes) {
      lane.cards = [];
    }
    if (orders && orders.length) {
      const dataClone = orders.slice(0); // get copy of the data object
      whileLoop:
        while (dataClone && dataClone.length) { // go through every order only once
          const jobOrder = dataClone[dataClone.length - 1];
          for (const lane of lanes) { // for the current order, check if it fits with any lanes condition
            try {
              if (!lane.condition || !lane.condition.rule(jobOrder, this.user)) {
                continue; // if it does not meet the condition of the lane, skip to the next lane
              }
            } catch (e) {
              console.log(e);
            }
            // console.log('made it');

            lane.cards.unshift(new Card(jobOrder)); // condition met. unshift the job order into the lane as a card
            dataClone.pop(); // remove that order from the array
            continue whileLoop; // continue with next order
          }
          dataClone.pop(); // order did not meet any lanes condition, just remove it from the orders array
        }
    }
    let t1 = performance.now();
    console.log("distributeOrders took " + (t1 - t0) + " milliseconds.")
  }

}
