import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ExplainService {
  model_s_keywords_raw: any;
  keyword_filter: string[] = [];
  public isJobMatch: boolean = false;
  constructor() { }

  // return a simple array of strings for passing to the pdf.js viewer 'search' function for highlighting
  // NOTE: as of now, we simply pass a single string because pdf.js has no concept of multiple phrase search
  // so our only option is to do a term search for each term, regardless of whether some of them are part of a phrase.
  // There is a pull request to implement multi-phrase search. Worth checking later: https://github.com/mozilla/pdf.js/pull/11749
  get keywords(): string[] {
    let output: string[] = [];
    // combine Model S explain field with keyword filters/job title on the UI
    if (this.model_s_keywords_raw) {
      output = this.extractKeywords(this.model_s_keywords_raw, output);
    }
    if (this.keyword_filter && this.keyword_filter.length) {
      output = output.concat(this.keyword_filter);
    }
    // normalize
    output = output.map(w => w.toLowerCase().trim()
      .replace('/', '').replace('(', '').replace(')', '').replace(':', '')
      .replace('-', '').replace('!', '').replace('.', '').replace(',', '')
    );
    // remove filler words
    // TODO: add more filler words (articles, prepositions, conjunctions, etc.) to this list? - JDS
    const fillerWords = ['a', 'an', 'and', 'of', 'the', 'for', 'in', 'at', 'as', 'on', 'to', 'with', 'or', 'not'];
    output = output.map(w => fillerWords.indexOf(w) === -1 ? w : '');
    // deduplicate
    output = Array.from(new Set(output));
    // clean up values that were removed
    output = output.filter(x => x);
    // console.log('explain keywords: ', output)
    return output;
  }

  // takes in Model S "explain" object and returns a simple array of strings
  // the strings being any "filter" terms or "red" (highest) tier keywords
  // NOTE: this logic is rather fragile and will probably break if the format
  // of the Model S "explain" field significantly changes - JDS
  extractKeywords(input: any, output: string[] = []) {
    //Temporary array to store first 10 yellow tier keywords (retults should come sorted from model s)
    let yellowStack: string[] = [];
    // console.log(JSON.stringify(input, null, 2))
    Object.keys(input).map(key1 => {
      Object.keys(input[key1]).map(key2 => {
        if (key1 === 'filter' || input[key1][key2].tier === 'red') output.push(key2);
        if (input[key1][key2].tier === 'yellow' && yellowStack.length < 10 && this.isJobMatch) yellowStack.push(key2);
      })
    })

    output = output.concat(yellowStack);

    return output;
  }
}
