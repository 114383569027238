<ng-container *ngIf="canChangeInput">
  <div class="form-group">
    <label [for]="inputId">{{ label }}</label>
    <input type="text"
      class="form-control"
      [id]="inputId"
      placeholder="Enter one or more email addresses (comma separated)"
      [(ngModel)]="input"
      (ngModelChange)="handleInputChange($event)"
      (blur)="handleBlur($event)"/>
    <form-error [fieldAlias]="label + ' field'" [control]="control"></form-error>
  </div>
</ng-container>

<ng-container *ngIf="showPills == true">
  <!-- The valid email pills -->
  <ul class="hz-btn-list">
    <li *ngFor="let email of control.value">
      <button class="btn btn-sm btn-outline-primary" (click)="handlePillClick(email)">
        <ng-container *ngIf="this.control.value && this.control.value.length > 0">
          {{ email.name }}
        </ng-container>
        <ng-container *ngIf="!this.control.value">
          {{ email }}
        </ng-container>
        <i class="icon-WL_icon_close"></i>
      </button>
    </li>
  </ul>
  <form-error [fieldAlias]="label + ' field'" [control]="control"></form-error>



  <!-- The invalid email pills -->
  <div class="warning-banner" *ngIf="problemUsers.length > 0 && handleValidation">
      <div class="icon">
        <img class="warning-banner-icon" src="assets/icons/alert-circle-red.svg" alt="error"/>
      </div>
      <div class="warning-user-container">
          <span class="warning-banner-label">{{'mass-email-modal.nbspnbsp_40' | translate: trVariable1}}</span>
          <span class="problemdetail" (click)="showDetailCantMail()">{{cantMailVerbiage}}</span>
          <div *ngIf="showCantMailDetails" class="problem-user-list">
            <ul class="hz-btn-list ">
              <li *ngFor="let issue of problemUsers">
                <div class="some-container">
                  <button
                    class="btn btn-sm btn-outline-danger"
                    [popover]="popTemplate"
                    triggers="mouseenter:mouseleave"
                    placement="bottom">
                    {{ issue.name }}
                  </button>
                </div>
                <ng-template #popTemplate>
                  <div class="issue-name">{{issue.name}}</div>
                  <div class="issue-email">{{issue.email}}</div>
                  <div class="issue-message-bg">
                    <img class="issue-message-text" src="/assets/icons/alert-circle-red.svg" alt="error"/>
                    <span>{{issue.issue}}</span>
                  </div>
                </ng-template>
              </li>
            </ul>
        </div>
      </div>
  </div>
</ng-container>

<ng-container *ngIf="showPills == false">
  <ul class="hz-btn-list">
    <li *ngFor="let email of control.value.slice(0,5)">
      <button class="btn btn-sm btn-outline-primary" (click)="handlePillClick(email)">
        <ng-container *ngIf="control.value && control.value.length > 0">
          {{ email.name }}
        </ng-container>
        <ng-container *ngIf="!control.value">
          {{ email }}
        </ng-container>
        <i class="icon-WL_icon_close"></i>
      </button>
    </li>
    <ng-container *ngIf="control.value.length > 5">
      <button type="button" class="btn-reset text-primary" (click)="expandPills()">
        + {{control.value.length - 5 }} extra<img src="/assets/icons/chevron-down-s.svg" alt="many"/>
      </button>
    </ng-container>
    <ng-container *ngIf="control.value.length == 0">
      No recipient found
    </ng-container>
  </ul>
</ng-container>
