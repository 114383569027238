<div class="modal-header" style="justify-content: flex-end">
  <button type="button" class="btn-reset" aria-label="Close" (click)="_bsModalRef.hide()">
    <i class="icon-WL_icon_close"></i>
  </button>
</div>

<div class="modal-body" style="overflow: auto">
  <div class="row">
    <div class="col">
      <h2 *ngIf="!isMassAddToJob" class="mb-4">{{'add-to-job.add' | translate: trVariable1}}</h2>
      <h2 *ngIf="isMassAddToJob" class="mb-4">{{'add-to-job.add-talent-list' | translate}}</h2>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <span>{{'add-to-job.select_the_job' | translate}}</span>
    </div>
  </div>
  <div class="row mt-4 no-gutters">
    <div class="col staticColumnDropDown">
      <label>{{'add-to-job.view' | translate}}</label>
      <select [(ngModel)]="categoryDropDown" class="custom-select selectDropDown" disabled>
        <option value="1">{{'add-to-job.my_jobs' | translate}}</option>
        <option value="2">{{'add-to-job.all_jobs' | translate}}</option>
      </select>
    </div>
    <div class="col staticColumn">
      <label>{{'add-to-job.job_title' | translate}}</label>
      <input [(ngModel)]="jobTitle" (keyup.enter)="search()" class="form-control inputBoxes" />
    </div>
    <div class="col staticColumn">
      <label>{{'add-to-job.job_id' | translate}}</label>
      <input [(ngModel)]="jobId"  (keyup.enter)="search()" class="form-control inputBoxes" />
    </div>
    <div class="col staticColumn">
      <label>{{'add-to-job.client_name' | translate}}</label>
      <input [(ngModel)]="clientName" (keyup.enter)="search()" class="form-control inputBoxes" />
    </div>
    <div class="col staticColumn">
      <button type="button" id="searchButton" name="searchButton" class="btn rds--button--primary searchBox mt-32" (click)="search()">{{'add-to-job.search' | translate}}</button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div *ngIf="loading">
        <div class="w-100 text-center">
          <div class=" mt-4 pt-4 spinner-border text-primary mx-auto text-center" role="status">
            <span class="sr-only text-center">{{'add-to-job.loading' | translate}}</span>
          </div>
        </div>
      </div>
      <div *ngIf="!loading && cards && cards.length === 0">
        <div class="mt-5">
          <div class="text-center mt-3">
            <div class="mt-5"><img width="226px" src="/assets/images/ghost.svg" /></div>
            <h3>{{'add-to-job.there_are_currently' | translate}}</h3>
            <p *ngIf="categoryDropDown === 2" class="gray-text">{{'add-to-job.try_changing_your' | translate}}</p>
            <p *ngIf="categoryDropDown === 1" class="gray-text">{{'add-to-job.you_do_not' | translate}}</p>
          </div>
        </div>
      </div>
      <div *ngIf="!loading" >
        <ng-masonry-grid
          [masonryOptions]="{ transitionDuration: '0s', gutter: 0, columnWidth: 10 }"
          [useAnimation]="false"
          [useImagesLoaded]="true">
          <!-- Masonry Grid Item -->
          <ng-masonry-grid-item
            id="{{'masonry-item-'+i}}"
            *ngFor="let card of cards | slice: cardStartIndex:cardEndIndex; let i = index;"
            [tooltip]="isMassAddToJob ? card.label : selectedCardTooltip"
            [isDisabled]="!isMassAddToJob ? !cacheCheck(card.job_order) : isMassAddToJob && !!!card.label">
            <app-card
              class="laneCards"
              [ngClass]="[card.selected ? 'selected' : '',cacheCheck(card.job_order) ? 'shortlisted' : '']"
              [card]="card"
              (selectEvent)="setSelectedCard($event)">
            </app-card>
          </ng-masonry-grid-item>
        </ng-masonry-grid>
      </div>
    </div>
  </div>

</div> <!-- / .modal-body -->

<div class="modal-footer" style="justify-content: flex-end">
  <ul class="hz-btn-list text-right">
    <li>
      <button type="button" class="btn rds--button--primary" aria-label="cancel" (click)="_bsModalRef.hide()">{{'add-to-job.cancel' | translate}}</button>
    </li>
    <li>
      <button type="button" class="btn rds--button--secondary" aria-label="cancel" (click)="handleShortlistClick()">{{'add-to-job.shortlist' | translate}}</button>
    </li>
  </ul>
</div>
