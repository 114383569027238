import { Card } from './card.model';
import { LaneCondition } from './lane-condition.model';

export class Lane {
  cards: Card[] = [];

  // conditionLength: number;
  constructor(public title: string, public position: number, public condition: LaneCondition) {
    // this.conditionLength = Object.keys(this.condition()).length;
  }
}
