import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {TalentHistoryService} from '../talent-history.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {ALL_NOTE_TYPES} from '../../talent-process/talent-process-modals/log-note-modal/note.types';
import {UserService} from 'src/app/shared/services/user/user.service';
import {CacheService} from 'src/app/shared/services/cache/cache.service';
import {TalentProcessService} from 'src/app/shared/services/talent-process/talent-process.service';
import {ActivityDetailModalComponent} from '../../../activity-detail-modal/activity-detail-modal.component';
import {ActivityFeedService} from '../../../../services/activity-feed/activity-feed.service';
import {ModalService} from '../../../../services/modal/modal.service';
import {ProcessStep} from 'src/app/shared/models/external/misc.model';
import {Activity} from '../../../../models/internal/activity.interface';
import {Job} from '../../../../models/external/job.model';
import {User} from '../../../../models/external/user.model';
import {Application} from '../../../../models/external/application.model';
import {Assignment} from '../../../../models/external/assignment.model';
import {TalentActivity} from '../../../../models/external/talent-activity.model';
import {Profile} from '../../../../models/external/profile.model';
import {UtilityService} from '../../../../services/utility/utility.service';
import {IPageInfo} from 'ngx-virtual-scroller';
import {AuthService} from 'src/app/shared/services/auth/auth.service';
import {TalentHistoryFilter} from '../talent-history.types';
import {ActivityFilterInfo, LobBasedActivityFiltersService} from './talent-history-activities-filter';
import moment from 'moment';
import {Applications, Google} from '@allbirds-ui/allbirds-types';
import AppSource = Applications.AppSource;
import {isArray} from "angular-pipes/utils/utils";
import { Subscription } from 'rxjs';
import { ApplicationEntityChangeService } from '../../../../services/entity-change/application-entity-change.service';
import { TranslateService } from '@ngx-translate/core';

export interface TalentHistoryFeedState {
  talentProcess?: Application;
  job?: Job;
  activity?: Activity;
  showFeed: boolean;
  candidateFullName: string;
  attachments?: any[];
}

@Component({
  selector: 'app-talent-history-feed',
  templateUrl: './talent-history-feed.component.html',
  styleUrls: ['./talent-history-feed.component.scss']
})
export class TalentHistoryFeedComponent implements OnInit, OnChanges, OnDestroy {

  activityDetailModal: BsModalRef;
  @Input() activities: (Application | Assignment | TalentActivity)[] = [];
  noteTypes: any = ALL_NOTE_TYPES;
  @Input() talentProfile: Profile;
  @Input() jobInfo: Job = null;
  @Input() activeFilter: TalentHistoryFilter;
  @Input() preselectedActivityFilter: string;
  //infinityScroll emitter
  @Output() infinityScroll = new EventEmitter<any>();
  @Output() changeFilter = new EventEmitter<string>();

  //Only applicable for activities on the particular talent[DF044-3317]
  filters: ActivityFilterInfo[] = [];

  //Keeping selected activity filter in memory for display
  selectedActivityFilter: ActivityFilterInfo;

  lob: string;

  bannerNotificationMessage: string = '';

  talentApplication: any;
  applicationSub: Subscription;

  applicationChanged = false;
  applicationChangeSub: Subscription;

  attachments: any[];


  constructor(
    public _talentHistory: TalentHistoryService,
    private _talentProcess: TalentProcessService,
    private _cache: CacheService,
    private _user: UserService,
    private _bsModal: ModalService,
    private _activityFeed: ActivityFeedService,
    private _auth: AuthService,
    private _applicationChange: ApplicationEntityChangeService,
    private _translate: TranslateService,
    private _utility: UtilityService
  ) { }

  get userLob(): string { return this._auth.user && this._auth.user.Source; }

  ngOnInit() {
    if (this.activeFilter == TalentHistoryFilter.ACTIVITIES) {
      this.lob = 'RGS';
      if (this.userLob.checkLob('RT', 'RE')) {
        this.lob = 'RT';
      }
      this.filters = new LobBasedActivityFiltersService().getFilters(this.lob);
      if (!this.preselectedActivityFilter || this.preselectedActivityFilter == '') {
        this.selectedActivityFilter = {'key': 'all', 'displayval': 'all'};
      } else {
        //Modified to show displayval. It was showing key earlier and was causing issue for DBC rename ticket.
        const preSelectedFilterDisplayVal = this.filters?.find(x=>x.key == this.preselectedActivityFilter)?.displayval;
        this.selectedActivityFilter = 
          {'key': this.preselectedActivityFilter, 'displayval': 
            (preSelectedFilterDisplayVal === undefined || preSelectedFilterDisplayVal === '') ? 
            this.preselectedActivityFilter : preSelectedFilterDisplayVal
          };
      }
    }
    this.listenForApplicationEntityChanges();
    this.listenToSelectedApplication();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activities) {
      this.activities = changes.activities.currentValue;
    }
    if (changes.talentProfile) {
      this.talentProfile = changes.talentProfile.currentValue;
    }
    if (this.activeFilter == TalentHistoryFilter.ACTIVITIES ){
      this.filters = new LobBasedActivityFiltersService().getFilters(this.lob);
    }
    console.log('talentProfile', this.talentProfile);
    console.log('activities', this.activities);
  }

  ngOnDestroy() {
    this.activities = undefined;
  }

  /**
   * Handles when the selected application changes.
   */
   listenToSelectedApplication() {
    UtilityService.destroySubscription(this.applicationSub);
    this.applicationSub = this._talentProcess.selectedApplication
      .subscribe(app => {
        this.talentApplication = app;
        this.attachments = this._utility.extractAttachments(app);
        this.checkIfApplicationChanged();
        /*Do not remove as this helps debugging and getting the elastic id*/
        console.debug("Application = ", app);
      });
  }

  /**
   * Listens for application entity changes from Firestore.
   */
  listenForApplicationEntityChanges() {
    UtilityService.destroySubscription(this.applicationChangeSub);
    this.applicationChangeSub = this._applicationChange.onChanges
      .subscribe(() => {
        this.checkIfApplicationChanged();
      });
  }

  /**
   * Checks if the current talent application has changed and sets the status accordingly.
   */
   checkIfApplicationChanged(): void {
    this.applicationChanged = this._applicationChange.didChange(this.talentApplication);
    if (this.applicationChanged) {
      this._talentProcess.setSelectedApplicationState({
        disabled: true,
        disabledMsg: this._translate.instant('application-disabled.further_state'),
        hidden: false
      });
    } else {
      this._talentProcess.setSelectedApplicationState({ disabled: false, hidden: false });
    }
  }

  openProcessDetailModal(talentProcess: Application, job: Job) {
    this.showDetailModal({
      talentProcess: talentProcess,
      job: job,
      candidateFullName: UtilityService.getNameFromTalentProfile(this.talentProfile),
      showFeed: true,
      attachments: this.attachments
    });
  }

  openActivityDetailModal(activity: Activity, job: Job, user?: User) {
    if (user) {
      activity.author = this._activityFeed.getAuthorDetails(user.BackOfficeID);
    }
    this.showDetailModal({
      talentProcess: this.talentApplication,
      activity: activity,
      job: job,
      candidateFullName: UtilityService.getNameFromTalentProfile(this.talentProfile),
      showFeed: false,
      attachments: this.attachments
    });
  }

  showDetailModal(initialState: TalentHistoryFeedState) {
    this.activityDetailModal = this._bsModal.show(
      ActivityDetailModalComponent,
      { initialState }
    );
    this.activityDetailModal.setClass('modal-sm');
  }

  getStepInfo(stepField: ProcessStep): any {
    const step = stepField === 'interviewSchedule'
      ? ProcessStep.INTERVIEW_RECRUITER
      : stepField;

    if (!this._talentProcess.steps) {
      this._talentProcess.setupSteps();
    }

    const steps = this._talentProcess.steps.get(step);
    return steps;
  }

  getJobInfo(jobId: string, isGoogleJobId: boolean = false): any {
    return (isGoogleJobId)
      ? this._talentHistory.jobs[this._talentHistory.googleJobsMap[jobId]]
      : this._talentHistory.jobs[jobId];
  }

  getUserInfo(backOfficeId: string): any {
    return this._user.getFromCache(backOfficeId);
  }

  getAssignmentDateString(assignment: Assignment): string {
    if (
      assignment &&
      assignment.startDate &&
      (assignment.actualEndDate || assignment.endDate)
    ) {
      const startDate = assignment.startDate;
      const endDate = assignment.actualEndDate || assignment.endDate;
      return `${startDate ? startDate.formatDate() : 'not specified'} - ${endDate ? endDate.formatDate() : 'not specified'}`;
    }
    return 'not specified';
  }

  getPayRateString(payRate: number): string {
    return payRate ? `$${payRate} per ${payRate > 999 ? 'year' : 'hour'}` : 'not specified';
  }

  handleInfinityScroll(event: IPageInfo): void {
    if (event.endIndex === this.activities.length - 1 && !this._talentHistory.fetching && this._talentHistory.keepFetching) {
      this.infinityScroll.emit();
    }
  }
  sendFilter(filter: ActivityFilterInfo) {

    this.selectedActivityFilter = filter;
    this.changeFilter.emit(filter.key);
  }

  // isAssignmentWarningLabel(assignment: Assignment): boolean {
  //
  //   const warningReasons = [
  //     'Unsatisfact: Insubord/Miscond',
  //     'Unsatisfact: Viol Policy/Proc',
  //     'terminated due to performance',
  //     'terminated due to performances',
  //     'termination - lack of skill',
  //     'termination - misconduct: absence/tardiness',
  //     'termination - misconduct: insubordination/theft/violation of policy',
  //     'termination - misconduct: other (explain)',
  //     'termination - performance issues'
  //   ]
  //
  //   if (this.activeFilter === TalentHistoryFilter.ASSIGNMENTS) {
  //     if (assignment.endReason && warningReasons.includes(assignment.endReason)) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }

  isAssignmentCautionLabel(assignment: Assignment): boolean {

    const cautionReasons = [
      'Unsat: VQ no notice other job',
      'Unsatisfact: 1st day no show',
      'Unsatisfact: Performance/Skill',
      'Unsatisfactory: No Show Refl Rq',
      'Unsatisfact: Walked off',
      'Unsatisfact: Attendance',
      'Unsatisfactory: Refill Req\'d',
      'Unsatisfact: Attdnce No Refl Rq',
      'Unsatisfact: No Show',
      'Unsatisfact: Perf/Skill No Refl',
      'Unsatisfact: Resign w/o Notice',
      'Unsatisfact: Vol Quit Refl Rq',
      'Unsatisfact: Walked Off No Refl',
      'Unsatisfact: Lost Transport',
      'never started - candidate cancelled',
      'never started - candidate took another job',
      'quit',
      'quit (explain)',
      'quit dissatisfied with job',
      'quit dissatisfied with job (explain)',
      'quit took another job'
    ]

    if (this.activeFilter === TalentHistoryFilter.ASSIGNMENTS) {
      if (assignment.endReason && cautionReasons.includes(assignment.endReason)) {
        return true;
      }
    }
    return false;
  }

  isAssignmentRawLabel(assignment: Assignment): boolean {
    if (assignment?.rawAssignFlag === "Y") return true
    return false;
  }

  displayNotificationLabel(process: Application): boolean {
    return !!(process.randstad_process.lastProcessStep && moment().diff(process.randstad_process.lastProcessStepDate, 'days') <= 30 && this.getNotificationLabelMessage(process));
  }

  getNotificationLabelMessage(process: Application): string {
    switch (process.randstad_process.lastProcessStep) {
      case 'interview':
      case 'interviewSchedule':
        return 'Interview';
      case 'internalSubmission':
        return 'Internal submission';
      case 'clientSubmission':
        return 'Client submission'
      case 'clientInterview':
        return 'Client interview';
      case 'offer':
        return 'Offer Made';
      default:
        return null;
    }
  }

  displayWarningOrCautionBanner(): boolean {
    // const assignments: Assignment[] = (this.activities as Assignment[]) || [];
    //
    // const isBannerDisplayed = assignments.filter(assignment =>
    //   (moment(Date.now()).diff(assignment.actualEndDate, 'days') < 365)
    //   && this.isAssignmentCautionLabel(assignment));

    let profileLabels = (this.talentProfile?.customAttributes?.ProfileLabels as unknown as Google.Talent.CustomAttribute[])
    return !!(profileLabels && profileLabels.length && profileLabels.find((profileLabel) => {
      return profileLabel.stringValues.includes('RedFlags');
    }));
  }


  displayNotificationBanner(): boolean {
    const applicationsHistory = this._talentHistory['applications'];
    // The banner displayed is only available if the last process step was made in the last 30days
    const applications = applicationsHistory.filter(application => application.randstad_process.lastProcessStep && (moment().diff(application.randstad_process.lastProcessStepDate, 'days') <= 30));

    if (applications.length) {
      const application = applications.reduce((previousApplication, currentApplication) => {
        return currentApplication && currentApplication.randstad_process.lastProcessStepDate.isAfter(previousApplication.randstad_process.lastProcessStepDate) ? currentApplication : previousApplication;
      });
      switch (application.randstad_process.lastProcessStep) {
        case 'interview':
        case 'interviewSchedule':
          this.bannerNotificationMessage = 'The talent has been recently interviewed';
          break;
        case 'internalSubmission':
          this.bannerNotificationMessage =  'The talent has been recently submitted internally';
          break;
        case 'clientSubmission':
          this.bannerNotificationMessage =  'The talent has been recently submitted to a client'
          break;
        case 'clientInterview':
          this.bannerNotificationMessage =  'The talent has been recently scheduled for a client interview';
          break;
        case 'offer':
          this.bannerNotificationMessage =  'The talent has been recently made an offer';
          break;
        default:
          this.bannerNotificationMessage =  null;
          return false;
      }
      return true;
    }
    return false;
  }

  isApplicant(application: any) {
    return application.source === AppSource.APPLY_DIRECT_WEB
      || application.source === AppSource.APPLY_DIRECT_MOBILE_WEB
        || application.source === AppSource.APPLY_DIRECT_MOBILE_APP
          || application.source === AppSource.APPLY_DIRECT_IN_PERSON
            || application.source === AppSource.APPLY_INDIRECT;
  }

}

