import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AuthService} from '../auth/auth.service';
import {User} from '../../models/external/user.model';
import {Bookmark, IBookmarkTalent} from '../../models/internal/bookmark';
import {BehaviorSubject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import html2canvas from 'html2canvas';
import {Profile} from '../../models/external/profile.model';

@Injectable({
  providedIn: 'root'
})
export class BookmarkService {
  user: User;
  current_url: string;
  public new_bookmark_count: number = 0;
  bookmark_object: any = {};

  private dataSource = new BehaviorSubject<Bookmark[]>([]);
  bookmarkData = this.dataSource.asObservable();

  constructor(private db: AngularFirestore,
              private _auth: AuthService,
              private _router: Router,
              private _activated_router: ActivatedRoute,
              private _http: HttpClient) {
    this.user = this._auth.user;
    this.db.collection<any>(environment.collections.Bookmark,
      ref => ref.where('user.back_office_id', '==', (this.user || {} as User).BackOfficeID )).valueChanges().subscribe( (bookmarks: any[]) => {
      this.bookmark_object = {};
      this.new_bookmark_count = 0;
      bookmarks.sort((a, b) =>
        +(b.last_visit_date_time === b.create_date_time) - +(a.last_visit_date_time === a.create_date_time)
        || (new Date(b.last_visit_date_time).getTime()) - (new Date(a.last_visit_date_time).getTime())
      );
      bookmarks.map((bookmark, index) => {
        this.bookmark_object[bookmark.bookmark_url] = index;
        if ( bookmark.last_visit_date_time === bookmark.create_date_time) { this.new_bookmark_count++}
        }, this);
      // || (new Date(a.create_date_time).getTime()) - (new Date(b.create_date_time).getTime())
      this.dataSource.next(bookmarks);
      this.current_url = this.getUrl();
    });
  }

  public async addBookmark(bookmark: Bookmark) {
    bookmark.user = { front_office_id: this.user.Oprid, back_office_id: this.user.BackOfficeID, name: this.user.FullName, email: this.user.EmailAddr};
    bookmark.image_url = bookmark.image_url || await this.makeScreenshot();
    // tslint:disable-next-line:no-bitwise
    if (!~bookmark.bookmark_url.indexOf('st')) { // instead of doing !== -1, bitwise does the same thing
      bookmark.talent = null;
    }
    return this.db
    .collection(environment.collections.Bookmark)
    .doc(bookmark.bookmark_id)
    .set({...bookmark}, { merge: true });
  }

  public deleteBookmark (bookmark: Bookmark) {
    return this.db
    .collection(environment.collections.Bookmark)
    .doc(bookmark.bookmark_id).delete();
  }

  public updateLastVisitBookmark(bookmark: Bookmark) {
    this.db
    .collection(environment.collections.Bookmark)
    .doc(bookmark.bookmark_id)
    .set( { last_visit_date_time: new Date().toUTCString()}, { merge: true }).then((data) => {
      console.log(data);
    }).catch(e => console.log(e));
  }

  getBookmarkIndex () {
    if ( Object.keys(this.bookmark_object).length) {
      return this.bookmark_object[this.getUrl()];
    } else {
      return -1;
    }
  }

  getUrl () {
    const url = new URL(window.location.href);
    return `${url.pathname}${url.search}`;
  }

  async makeScreenshot(selector= 'body') {
    return new Promise((resolve, reject) => {
      const target: any = document.body;

      html2canvas(target, {scale: 0.5}).then(function (c: HTMLCanvasElement) {
        const pngUrl = c.toDataURL('image/jpeg');
        resolve(pngUrl);
      });
    });
  }

  /**
   * Profile objects in the list of "Potential talents" and "Shortlist"
   * have different bodies so we set the UI fields accordingly.
   */
  setValuesForTalent( talent: any ) {
    const bookmarkTalent = {} as IBookmarkTalent;
    if (talent && talent.isApplication && talent.isApplication()) {
      bookmarkTalent.profile = talent.profile;
      if (talent.randstad_process.profileInfo && talent.randstad_process.profileInfo.candidateFullName) {
        bookmarkTalent.candidateFullName = talent.randstad_ = talent.randstad_process.profileInfo.candidateFullName;
      } else {
        bookmarkTalent.candidateFullName = talent.randstad_process.candidateFullName;
      }
      if (talent.randstad_process) {
        bookmarkTalent.candidateFrontOfficeID = talent.randstad_process.candidateFrontOfficeID;
      }
    } else {
      if (talent) {
        bookmarkTalent.profile = talent.name;
        const name = (talent as Profile).personNames[0];
        if (name && name.structuredName) {
          bookmarkTalent.candidateFullName = `${name.structuredName.givenName} ${name.structuredName.familyName}`;
        }
        bookmarkTalent.candidateFrontOfficeID = null;
      }
    }
    return bookmarkTalent;
  }

}
