import { Assignments } from '@allbirds-ui/allbirds-types';
import { createModel, Mapping, Model } from './model';
import { IsoToMoment } from './serializers/moment.serializer';
import { Moment } from 'moment';
import { Serial } from './decorators/serial.decorator';
export import RawAssignment = Assignments.Assignment;

class AssignmentMapping extends Mapping<RawAssignment> {
  @Serial(IsoToMoment) startDate?: Moment;
  @Serial(IsoToMoment) endDate?: Moment;
  @Serial(IsoToMoment) actualEndDate?: Moment;
  client?: string;
  clientJobTitle?: string;
  endReason?: string;
  payRate?: string;
  randstadJobTitle?: string;
  rawAssignFlag?: string;
}

export interface Assignment extends Model<AssignmentMapping> {}

export const Assignment = createModel<Assignment>(AssignmentMapping);
