import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from "@angular/core";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { ApiService } from "../../services/api/api.service";
import { SuggestService } from "src/app/shared/services/suggest/suggest.service";
import { DistributionList } from "./../../models/internal/distribution-list.model";
@Component({
  selector: "app-distribution-list-lookup",
  templateUrl: "./distribution-list-lookup.component.html",
  styleUrls: ["./distribution-list-lookup.component.scss"]
})
export class DistributionListLookupComponent implements OnInit, OnChanges {
  @Input() searchTerm: string;
  @Output() distributionListSelected = new EventEmitter();
  @Output() distributionListResults = new EventEmitter();
  termChange: Subject<string> = new Subject<
    string
  >(); /* Publishes when the search term changes */
  results: Array<DistributionList> = [];

  resultsChange: Subject<
    DistributionList[]
  > = new Subject(); /* Publishes when the results changes */
  loading = false;

  constructor(
    private _api: ApiService,
    private _suggestService: SuggestService
  ) {}

  ngOnInit() {
    this.termChange.pipe(debounceTime(500)).subscribe(this.updateSearch);
    this.resultsChange.subscribe(this.updateResults);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.searchTerm) {
      this.searchTerm = changes.searchTerm.currentValue;
      this.termChange.next(this.searchTerm);
    }
  }

  isLoading() {
    return this.loading;
  }

  clear = () => {
    this.results.length = 0;
    this.searchTerm = "";
  };

  // TODO add DistributionList type
  updateResults = (results: DistributionList[]) => {
    this.results.length = 0;
    results.forEach(result => {
      this.results.push(result);
    });
    this.distributionListResults.emit({
      results: this.results,
      searchTerm: this.searchTerm
    });
  };

  updateSearch = (searchTerm: string): void => {
    if (searchTerm === "" || searchTerm.length < 3) {
      this.loading = false;
      this.clear();
      this.updateResultsListing([]);
    } else {
      this.loading = true;
      this._suggestService
        .emailDistributionList(searchTerm).toPromise().then(dList => {
          console.log(dList);
          const dListArray = dList.suggests;
          this.updateResultsListing(dListArray);
        });
    }
  };

  // TODO add DistributionList type to this function
  updateResultsListing = (distributionList: DistributionList[]) => {
    this.resultsChange.next(distributionList);
    this.loading = false;
  };

  // TODO add DistributionList type to this function
  selectList = (distributionList: DistributionList) => {
    this.distributionListSelected.emit({ ...distributionList, dList: true });
    this.clear();
  };
}
