<!--
  The proper TemplateRef is rendered in the following <div> depending on what sub-step the talent
  is on as well as what the role of the user is.
-->
<div class="phase" [ngTemplateOutlet]="shownPhase"></div>

<ng-template #amAwaitingSubmission>
  <div class="phase-body-section pt-0">
    <p>{{statusLabel}}</p>
  </div>
  <div #ctaContainerBoundry id="ctaContainerBoundry"></div>
  <ul #ctaContainer class="action-buttons hz-btn-list">
    <li>
      <app-talent-process-cta
        [id]="'reviewAm-not-a-fit-button'"
        [invertColor]="true"
        (execute)="emitUnfitClick()">
        {{ 'review-account-manager.not_a_fit' | translate }}
      </app-talent-process-cta>
    </li>
  </ul>
</ng-template>


<!-- When a recruiter is preparing a submission to the AM. -->
<ng-template #prepareSubmission>
  <div class="phase-body-section pt-0">
    <p>{{'review-account-manager.prepare_the_submission_to_acc' | translate}}</p>
  </div>
  <div *ngIf="application?.randstad_process?.internalSubmission" class="phase-body-section">
    <div class="submissionDetails">
      <h4 class="phase-body-title">{{'review-account-manager.submission_details' | translate}}</h4>
      <div class="emailBody" [innerHTML]="application.randstad_process.internalSubmission.submission"></div>
      <div class="submissionDetailMeta">
        <div class="metaItem">Pay rate: {{application.randstad_process.internalSubmission.submissionPayRate | currency}} {{application.randstad_process.internalSubmission.submissionPayType | titlecase}}</div>
        <div class="metaItem">Submission source: {{application.randstad_process.internalSubmission.submissionSource}}</div>
      </div>
      <div class="attachments" *ngIf="attachments.length">
        <h4>{{'review-account-manager.attachment' | translate}}:</h4>
        <div class="row">
          <app-attachment-card-internal *ngFor="let attachment of attachments" [hideDelete]="true" cardType="small" [attachment]="attachment"></app-attachment-card-internal>
        </div>
      </div>
    </div>
  </div>
  <div #ctaContainerBoundry id="ctaContainerBoundry"></div>
  <ul #ctaContainer class="action-buttons hz-btn-list">
    <li>
      <app-talent-process-cta
        [id]="'reviewAm-not-a-fit-button'"
        [invertColor]="true"
        (execute)="emitUnfitClick()">
        {{ 'review-account-manager.not_a_fit' | translate }}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        *ngIf="!application?.randstad_process?.internalSubmission"
        (execute)="submitToAccountManagerClick()">
        {{'review-account-manager.submit_to_account' | translate}}
      </app-talent-process-cta>
    </li>
  </ul>
</ng-template>

<!-- When a recruiter is preparing a solutions support submission to the AM. -->
<ng-template #prepareSolutionsSubmission>
  <div class="phase-body-section pt-0">
    <p>{{'review-account-manager.prepare_the_submission' | translate}}</p>
  </div>
  <div *ngIf="application?.randstad_process?.internalSubmission" class="phase-body-section">
    <div class="submissionDetails">
      <h4 class="phase-body-title">{{'review-account-manager.submission_details' | translate}}</h4>
      <div class="emailBody" [innerHTML]="application.randstad_process.internalSubmission.submission"></div>
      <div class="submissionDetailMeta">
        <div class="metaItem">Pay rate: {{application.randstad_process.internalSubmission.submissionPayRate | currency}} {{application.randstad_process.internalSubmission.submissionPayType | titlecase}}</div>
        <div class="metaItem">Submission source: {{application.randstad_process.internalSubmission.submissionSource}}</div>
      </div>
      <div class="attachments" *ngIf="attachments.length">
        <h4>{{'review-account-manager.attachment' | translate}}:</h4>
        <div class="row">
          <app-attachment-card-internal *ngFor="let attachment of attachments" [hideDelete]="true" cardType="small" [attachment]="attachment"></app-attachment-card-internal>

        </div>
      </div>
    </div>
  </div>
  <div #ctaContainerBoundry id="ctaContainerBoundry"></div>
  <ul #ctaContainer class="action-buttons hz-btn-list">
    <li>
      <app-talent-process-cta
        [id]="'reviewAm-not-a-fit-button'"
        [invertColor]="true"
        (execute)="emitUnfitClick()">
        {{ 'review-account-manager.not_a_fit' | translate }}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        (execute)="submitToAccountManagerClick()">
        {{'review-account-manager.submit_to_solutions' | translate }}
      </app-talent-process-cta>
    </li>
  </ul>
</ng-template>

<!-- When a recruiter has made a submission and is waiting on AM action. -->
<ng-template #submittedRecruiterView>
  <div class="phase-body-section pt-0">
    <p>{{statusLabel}}</p>
  </div>
  <div *ngIf="application?.randstad_process?.internalSubmission" class="phase-body-section">
    <div class="submissionDetails">
      <h4 class="phase-body-title">{{'review-account-manager.submission_details' | translate}}</h4>
      <p class="input-text mb-3">
        {{"review-account-manager.submitted_on" | translate}} {{ application?.randstad_process?.internalSubmission?.submissionDate | abDatetime }}
      </p>
      <div class="emailBody" [innerHTML]="application.randstad_process.internalSubmission.submission"></div>
      <div class="submissionDetailMeta">
        <div class="metaItem">Pay rate: {{application.randstad_process.internalSubmission.submissionPayRate | currency}} {{application.randstad_process.internalSubmission.submissionPayType | titlecase}}</div>
        <div class="metaItem">Submission source: {{application.randstad_process.internalSubmission.submissionSource}}</div>
      </div>
      <div class="attachments" *ngIf="attachments.length">
        <h4>{{'review-account-manager.attachment' | translate}}:</h4>
        <div class="row">
          <app-attachment-card-internal *ngFor="let attachment of attachments" [hideDelete]="true" cardType="small" [attachment]="attachment"></app-attachment-card-internal>

        </div>
      </div>
    </div>
  </div>
  <div #ctaContainerBoundry id="ctaContainerBoundry"></div>
  <ul #ctaContainer class="action-buttons hz-btn-list">
    <li>
      <app-talent-process-cta
        [id]="'reviewAm-update-submission-button'"
        [invertColor]="true"
        (execute)="emitUpdateSubmissionClick()">
        {{ 'review-account-manager.update_submission' | translate }}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        [id]="'reviewAm-not-a-fit-button'"
        [invertColor]="true"
        (execute)="emitUnfitClick()">
        {{ 'review-account-manager.not_a_fit' | translate }}
      </app-talent-process-cta>
    </li>
  </ul>
</ng-template>

<ng-template #submittedSolutionsRecruiterView>
  <div class="phase-body-section pt-0">
    <p>{{statusLabel}}</p>
  </div>
  <div *ngIf="application?.randstad_process?.internalSubmission" class="phase-body-section">
    <div class="submissionDetails">
      <h4 class="phase-body-title">{{'review-account-manager.submission_details' | translate}}</h4>
      <p class="input-text mb-3">
        {{"review-account-manager.submitted_on" | translate}} {{ application?.randstad_process?.internalSubmission?.submissionDate | abDatetime }}
      </p>
      <div class="emailBody" [innerHTML]="application.randstad_process.internalSubmission.submission"></div>
      <div class="submissionDetailMeta">
        <div class="metaItem">Pay rate: {{application.randstad_process.internalSubmission.submissionPayRate | currency}} {{application.randstad_process.internalSubmission.submissionPayType | titlecase}}</div>
        <div class="metaItem">Submission source: {{application.randstad_process.internalSubmission.submissionSource}}</div>
      </div>
      <div class="attachments" *ngIf="attachments.length">
        <h4>{{'review-account-manager.attachment' | translate}}:</h4>
        <div class="row">
          <app-attachment-card-internal *ngFor="let attachment of attachments" [hideDelete]="true" cardType="small" [attachment]="attachment"></app-attachment-card-internal>

        </div>
      </div>
    </div>
  </div>
  <div #ctaContainerBoundry id="ctaContainerBoundry"></div>
  <ul #ctaContainer class="action-buttons hz-btn-list">
   
    <li>
      <app-talent-process-cta
        [id]="'reviewAm-not-a-fit-button'"
        [invertColor]="true"
        (execute)="emitUnfitClick()">
        {{ 'review-account-manager.not_a_fit' | translate }}
      </app-talent-process-cta>
    </li>
  </ul>
</ng-template>

<ng-template #submittedAMView>
  <div class="phase-body-section pt-0">
    <p>{{statusLabel}}</p>
  </div>
  <div *ngIf="application?.randstad_process?.internalSubmission" class="phase-body-section">
    <div class="submissionDetails">
      <h4 class="phase-body-title">{{'review-account-manager.submission_details' | translate}}</h4>
      <p class="input-text mb-3">
        {{"review-account-manager.submitted_on" | translate}} {{ application?.randstad_process?.internalSubmission?.submissionDate | abDatetime }}
      </p>
      <div class="emailBody" [innerHTML]="application.randstad_process.internalSubmission.submission"></div>
      <div class="submissionDetailMeta">
        <div class="metaItem">Pay rate: {{application.randstad_process.internalSubmission.submissionPayRate | currency}} {{application.randstad_process.internalSubmission.submissionPayType | titlecase}}</div>
        <div class="metaItem">Submission source: {{application.randstad_process.internalSubmission.submissionSource}}</div>
      </div>
      <div class="attachments" *ngIf="attachments.length">
        <h4>{{'review-account-manager.attachment' | translate}}:</h4>
        <div class="row">
          <app-attachment-card-internal *ngFor="let attachment of attachments" [hideDelete]="true" cardType="small" [attachment]="attachment"></app-attachment-card-internal>

        </div>
      </div>
    </div>
  </div>
  <div #ctaContainerBoundry id="ctaContainerBoundry"></div>
  <ul #ctaContainer class="action-buttons hz-btn-list">
    <li>
      <app-talent-process-cta
        [id]="'reviewAm-update-submission-button'"
        [invertColor]="true"
        (execute)="emitUpdateSubmissionClick()">
        {{ 'review-account-manager.update_submission' | translate }}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        [id]="'reviewAm-not-a-fit-button'"
        [invertColor]="true"
        (execute)="emitUnfitClick()">
        {{ 'review-account-manager.not_a_fit' | translate }}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        [id]="'reviewAm-advance-step'"
        (execute)="advanceStep()">
        {{'review-account-manager.prepare_submission_to' | translate}}
      </app-talent-process-cta>
    </li>
  </ul>
</ng-template>


<ng-template #submittedAMViewUpdate>
  <div class="phase-body-section pt-0">
    <p>{{statusLabel}}</p>
  </div>
  <div *ngIf="application?.randstad_process?.internalSubmission" class="phase-body-section">
    <div class="submissionDetails">
      <h4 class="phase-body-title"><b>{{ application?.randstad_process?.internalSubmission?.submissionType }}</b></h4>
      <div class="emailBody" [innerHTML]="application.randstad_process.internalSubmission.submission"></div>
       <h4 class="phase-body-title"><b>        {{ 'review-account-manager.original_submission' | translate }} </b></h4>
       <div class="emailBody" [innerHTML]="application.randstad_process.internalSubmission.priorSubmissions[originalSubmissionIndex].submission"></div>

      <div class="submissionDetailMeta">
        <div class="metaItem">Pay rate: {{application.randstad_process.internalSubmission.priorSubmissions[originalSubmissionIndex].submissionPayRate | currency}} {{application.randstad_process.internalSubmission.submissionPayType | titlecase}}</div>
        <div class="metaItem">Submission source: {{application.randstad_process.internalSubmission.priorSubmissions[originalSubmissionIndex].submissionSource}}</div>
      </div>
      <div class="attachments" *ngIf="attachments.length">
        <h4>{{'review-account-manager.attachment' | translate}}:</h4>
        <div class="row">
          <app-attachment-card-internal *ngFor="let attachment of attachments" [hideDelete]="true" cardType="small" [attachment]="attachment"></app-attachment-card-internal>

        </div>
      </div>
    </div>
  </div>
  <div #ctaContainerBoundry id="ctaContainerBoundry"></div>
  <ul #ctaContainer class="action-buttons hz-btn-list">
    <li>
      <app-talent-process-cta
        [id]="'reviewAm-update-submission-button'"
        [invertColor]="true"
        (execute)="emitUpdateSubmissionClick()">
        {{ 'review-account-manager.update_submission' | translate }}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        [id]="'reviewAm-not-a-fit-button'"
        [invertColor]="true"
        (execute)="emitUnfitClick()">
        {{ 'review-account-manager.not_a_fit' | translate }}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        [id]="'reviewAm-advance-step'"
        (execute)="advanceStep()">
        {{'review-account-manager.prepare_submission_to' | translate}}
      </app-talent-process-cta>
    </li>
  </ul>
</ng-template>

<ng-template #submittedRecruiterViewUpdate>
  <div class="phase-body-section pt-0">
    <p>{{statusLabel}}</p>
  </div>
  <div *ngIf="application?.randstad_process?.internalSubmission" class="phase-body-section">
    <div class="submissionDetails">
      <h4 class="phase-body-title"><b>{{ application?.randstad_process?.internalSubmission?.submissionType }}</b></h4>
      <div class="emailBody" [innerHTML]="application.randstad_process.internalSubmission.submission"></div>
       <h4 class="phase-body-title"><b>{{'review-account-manager.original_submission' | translate}}</b></h4>
       <div class="emailBody" [innerHTML]="application.randstad_process.internalSubmission.priorSubmissions[originalSubmissionIndex].submission"></div>

      <div class="submissionDetailMeta">
        <div class="metaItem">Pay rate: {{application.randstad_process.internalSubmission.priorSubmissions[originalSubmissionIndex].submissionPayRate | currency}} {{application.randstad_process.internalSubmission.submissionPayType | titlecase}}</div>
        <div class="metaItem">Submission source: {{application.randstad_process.internalSubmission.priorSubmissions[originalSubmissionIndex].submissionSource}}</div>
      </div>
      <div class="attachments" *ngIf="attachments.length">
        <h4>{{'review-account-manager.attachment' | translate}}:</h4>
        <div class="row">
          <app-attachment-card-internal *ngFor="let attachment of attachments" [hideDelete]="true" cardType="small" [attachment]="attachment"></app-attachment-card-internal>

        </div>
      </div>
    </div>
  </div>
  <div #ctaContainerBoundry id="ctaContainerBoundry"></div>
  <ul #ctaContainer class="action-buttons hz-btn-list">
    <li>
      <app-talent-process-cta
        [id]="'reviewAm-update-submission-button'"
        [invertColor]="true"
        (execute)="emitUpdateSubmissionClick()">
        {{ 'review-account-manager.update_submission' | translate }}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        [id]="'reviewAm-not-a-fit-button'"
        [invertColor]="true"
        (execute)="emitUnfitClick()">
        {{ 'review-account-manager.not_a_fit' | translate }}
      </app-talent-process-cta>
    </li>
  </ul>
</ng-template>

<ng-template #submittedSolutionsViewUpdate>
  <div class="phase-body-section pt-0">
    <p>{{statusLabel}}</p>
  </div>
  <div *ngIf="application?.randstad_process?.internalSubmission" class="phase-body-section">
    <div class="submissionDetails">
      <h4 class="phase-body-title"><b>{{ application?.randstad_process?.internalSubmission?.submissionType }}</b></h4>
      <div class="emailBody" [innerHTML]="application.randstad_process.internalSubmission.submission"></div>
       <h4 class="phase-body-title"><b>{{'review-account-manager.original_submission' | translate}}</b></h4>
       <div class="emailBody" [innerHTML]="application.randstad_process.internalSubmission.priorSubmissions[originalSubmissionIndex].submission"></div>

      <div class="submissionDetailMeta">
        <div class="metaItem">Pay rate: {{application.randstad_process.internalSubmission.priorSubmissions[originalSubmissionIndex].submissionPayRate | currency}} {{application.randstad_process.internalSubmission.submissionPayType | titlecase}}</div>
        <div class="metaItem">Submission source: {{application.randstad_process.internalSubmission.priorSubmissions[originalSubmissionIndex].submissionSource}}</div>
      </div>
      <div class="attachments" *ngIf="attachments.length">
        <h4>{{'review-account-manager.attachment' | translate}}:</h4>
        <div class="row">
          <app-attachment-card-internal *ngFor="let attachment of attachments" [hideDelete]="true" cardType="small" [attachment]="attachment"></app-attachment-card-internal>

        </div>
      </div>
    </div>
  </div>
  <div #ctaContainerBoundry id="ctaContainerBoundry"></div>
  <ul #ctaContainer class="action-buttons hz-btn-list">
    <li>
      <app-talent-process-cta
        [id]="'reviewAm-update-submission-button'"
        [invertColor]="true"
        (execute)="emitUpdateSubmissionClick()">
        {{ 'review-account-manager.update_submission' | translate }}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        [id]="'reviewAm-not-a-fit-button'"
        [invertColor]="true"
        (execute)="emitUnfitClick()">
        {{ 'review-account-manager.not_a_fit' | translate }}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        [id]="'reviewAm-solutions-review'"
        (execute)="advanceSolutionsStatus()">
        {{'review-account-manager.solutions_review' | translate}}
      </app-talent-process-cta>
    </li>
  </ul>
</ng-template>

<ng-template #postSolutionsViewUpdate>
  <div class="phase-body-section pt-0">
    <p>{{statusLabel}}</p>
  </div>
  <div *ngIf="application?.randstad_process?.internalSubmission" class="phase-body-section">
    <div class="submissionDetails">
      <h4 class="phase-body-title"><b>{{ application?.randstad_process?.internalSubmission?.submissionType }}</b></h4>
      <div class="emailBody" [innerHTML]="application.randstad_process.internalSubmission.submission"></div>
       <h4 class="phase-body-title"><b>{{'review-account-manager.original_submission' | translate}}</b></h4>
       <div class="emailBody" [innerHTML]="application.randstad_process.internalSubmission.priorSubmissions[originalSubmissionIndex].submission"></div>

      <div class="submissionDetailMeta">
        <div class="metaItem">Pay rate: {{application.randstad_process.internalSubmission.priorSubmissions[originalSubmissionIndex].submissionPayRate | currency}} {{application.randstad_process.internalSubmission.submissionPayType | titlecase}}</div>
        <div class="metaItem">Submission source: {{application.randstad_process.internalSubmission.priorSubmissions[originalSubmissionIndex].submissionSource}}</div>
      </div>
      <div class="attachments" *ngIf="attachments.length">
        <h4>{{'review-account-manager.attachment' | translate}}:</h4>
        <div class="row">
          <app-attachment-card-internal *ngFor="let attachment of attachments" [hideDelete]="true" cardType="small" [attachment]="attachment"></app-attachment-card-internal>

        </div>
      </div>
    </div>
  </div>
  <div #ctaContainerBoundry id="ctaContainerBoundry"></div>
  <ul #ctaContainer class="action-buttons hz-btn-list" style="text-align: left!important">
    <li>
      <app-talent-process-cta
        [id]="'reviewAm-not-a-fit-button'"
        [invertColor]="true"
        (execute)="emitUnfitClick()">
        {{ 'review-account-manager.not_a_fit' | translate }}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        [id]="'reviewAm-update-submission-button'"
        [invertColor]="true"
        (execute)="emitUpdateSubmissionClick()">
        {{'update submission'}}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        [id]="'reviewAm-interview-requested'"
        (execute)="skipToInterviewHMStep()">
        {{'client interview'}}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        [id]="'reviewAm-prepare-submission'"
        (execute)="advanceStep(true)">
        {{'review-account-manager.prep_submission_to' | translate}}
      </app-talent-process-cta>
    </li>
  </ul>
</ng-template>

<ng-template #submittedSolutionsView>
  <div class="phase-body-section pt-0">
    <p>{{statusLabel}}</p>
  </div>
  <div *ngIf="application?.randstad_process?.internalSubmission" class="phase-body-section">
    <div class="submissionDetails">
      <h4 class="phase-body-title">{{'review-account-manager.submission_details' | translate}}</h4>
      <p class="input-text mb-3">
        {{"review-account-manager.submitted_on" | translate}} {{ application?.randstad_process?.internalSubmission?.submissionDate | abDatetime }}
      </p>
      <div class="emailBody" [innerHTML]="application.randstad_process.internalSubmission.submission"></div>
      <div class="submissionDetailMeta">
        <div class="metaItem">Pay rate: {{application.randstad_process.internalSubmission.submissionPayRate | currency}} {{application.randstad_process.internalSubmission.submissionPayType | titlecase}}</div>
        <div class="metaItem">Submission source: {{application.randstad_process.internalSubmission.submissionSource}}</div>
      </div>
      <div class="attachments" *ngIf="attachments.length">
        <h4>{{'review-account-manager.attachment' | translate}}:</h4>
        <div class="row">
          <app-attachment-card-internal *ngFor="let attachment of attachments" cardType="small" [attachment]="attachment"></app-attachment-card-internal>

        </div>
      </div>
    </div>
  </div>
  <div #ctaContainerBoundry id="ctaContainerBoundry"></div>
  <ul #ctaContainer class="action-buttons hz-btn-list">
    <li>
      <app-talent-process-cta
        [id]="'reviewAm-update-submission-button'"
        [invertColor]="true"
        (execute)="emitUpdateSubmissionClick()">
        {{ 'review-account-manager.update_submission' | translate }}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        [id]="'reviewAm-not-a-fit-button'"
        [invertColor]="true"
        (execute)="emitUnfitClick()">
        {{ 'review-account-manager.not_a_fit' | translate }}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        [id]="'reviewAm-solutions-review'"
        (execute)="advanceSolutionsStatus()">
        {{'review-account-manager.solutions_review' | translate}}
      </app-talent-process-cta>
    </li>
  </ul>
</ng-template>

<ng-template #postSolutionsReview>
  <div class="phase-body-section pt-0">
    <p>{{statusLabel}}</p>
  </div>
  <div *ngIf="application?.randstad_process?.internalSubmission" class="phase-body-section">
    <div class="submissionDetails">
      <h4 class="phase-body-title">{{'review-account-manager.submission_details' | translate}}</h4>
      <p class="input-text mb-3">
        {{"review-account-manager.submitted_on" | translate}} {{ application?.randstad_process?.internalSubmission?.submissionDate | abDatetime }}
      </p>
      <div class="emailBody" [innerHTML]="application.randstad_process.internalSubmission.submission"></div>
      <div class="submissionDetailMeta">
        <div class="metaItem">Pay rate: {{application.randstad_process.internalSubmission.submissionPayRate | currency}} {{application.randstad_process.internalSubmission.submissionPayType | titlecase}}</div>
        <div class="metaItem">Submission source: {{application.randstad_process.internalSubmission.submissionSource}}</div>
      </div>
      <div class="attachments" *ngIf="attachments.length">
        <h4>{{'review-account-manager.attachment' | translate}}:</h4>
        <div class="row">
          <app-attachment-card-internal *ngFor="let attachment of attachments" [hideDelete]="true" cardType="small" [attachment]="attachment"></app-attachment-card-internal>

        </div>
      </div>
    </div>
  </div>
  <div #ctaContainerBoundry id="ctaContainerBoundry"></div>
  <ul #ctaContainer class="action-buttons hz-btn-list" style="text-align: left!important">
    <li>
      <app-talent-process-cta
        [id]="'reviewAm-not-a-fit-button'"
        [invertColor]="true"
        (execute)="emitUnfitClick()">
        {{ 'review-account-manager.not_a_fit' | translate }}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        [id]="'reviewAm-update-submission-button'"
        [invertColor]="true"
        (execute)="emitUpdateSubmissionClick()">
        {{'update submission'}}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        [id]="'reviewAm-interview-requested'"
        (execute)="skipToInterviewHMStep()">
        {{'client interview'}}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        [id]="'reviewAm-prepare-submission'"
        (execute)="advanceStep(true)">
        {{'review-account-manager.prep_submission_to' | translate}}
      </app-talent-process-cta>
    </li>
  </ul>
</ng-template>
