import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'shortlisted-phase',
  templateUrl: './shortlisted.component.html',
  styleUrls: [
    './shortlisted.component.scss',
    '../recruitment-phases.component.scss'
  ]
})
export class ShortlistedComponent implements OnInit {

  // Determines which template to show.
  shownPhase: TemplateRef<any>;

  // The different templates for the different phases.
  @ViewChild('engageViaChatbot', { static: true }) engageViaChatbotView: TemplateRef<any>;

  constructor() { }

  ngOnInit() {
    this.shownPhase = this.engageViaChatbotView;
  }

}
