import { Applications } from '@allbirds-ui/allbirds-types';
import { createModel, Mapping, Model } from '../model';
import { IsoToMoment } from '../serializers/moment.serializer';
import { Moment } from 'moment';
import { Serial } from '../decorators/serial.decorator';
export import RawClientInterview = Applications.ClientInterview;

class ClientInterviewMapping extends Mapping<RawClientInterview> {
  @Serial(IsoToMoment) submissionDate?: Moment;
  @Serial(IsoToMoment) outcomeDate?: Moment;
  @Serial(IsoToMoment) skippedDate?: Moment;
  @Serial(IsoToMoment) interviewDate?: Moment;
}

export interface ClientInterview extends Model<ClientInterviewMapping> {}

export const ClientInterview = createModel<ClientInterview>(ClientInterviewMapping);
