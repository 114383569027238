import {IntersectionObserverEvent} from 'src/app/shared/components/talent-detail-pane/talent-process/recruitment-phases/recruitment-phases.interface';
import { UserService } from 'src/app/shared/services/user/user.service';
import {
  AfterViewChecked,
  AfterViewInit,
  Component, ContentChild, ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output, QueryList,
  TemplateRef,
  ViewChild, ViewChildren,
} from '@angular/core';
import { Profile } from "../../../../../models/external/profile.model";
import { InterviewModalComponent } from '../../talent-process-modals/schedule-interview-modal/interview-modal.component';
import { LogInterviewModalComponent } from '../../talent-process-modals/log-interview-modal/log-interview-modal.component';
import { BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { TalentProcessService } from 'src/app/shared/services/talent-process/talent-process.service';
import { CacheService } from 'src/app/shared/services/cache/cache.service';
import { TalentService } from 'src/app/shared/services/talent/talent.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { ToastClass, ToastService } from 'src/app/shared/services/toast';
import { JobOrderService } from 'src/app/shared/services/job-order/job-order.service';
import { MetricsService } from 'src/app/shared/services/metrics/metrics.service';
import { LoadingSpinnerService } from 'src/app/shared/services/loading-spinner/loading-spinner.service';
import { UtilityService } from 'src/app/shared/services/utility/utility.service';
import { environment } from 'src/environments/environment';
import { Application } from 'src/app/shared/models/external/application.model';
import { Subscription } from 'rxjs';
import { ModalService } from '../../../../../services/modal/modal.service';
import { InterviewStatus, ProcessStatus, ProcessStep, ScheduleCandidate } from 'src/app/shared/models/external/misc.model';
import moment from 'moment';
import { ApiService } from '../../../../../services/api/api.service';
import { Job } from '../../../../../models/external/job.model';
import { InterviewSchedule } from 'src/app/shared/models/external/process/interview-schedule.model';
import { INTG_STEPS } from 'src/app/shared/models/internal/process.model';
import { ApplicationState } from '../../../../../models/internal/application-state.interface';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AddToListAModalComponent } from 'src/app/modules/talent/pages/list-details/add-to-list-a-modal/add-to-list-a-modal.component';
import { CancelInterviewModalComponent } from './cancel-interview-modal/cancel-interview-modal.component';
import {RecruitmentPhasesService} from '../recruitment-phases.service'
import { GoogleProfile } from 'src/app/shared/models/external/google-profile.model';
import { DupTalentModalComponent } from 'src/app/shared/components/dup-talent-modal/dup-talent-modal.component';
import { JobDetailsShortlistService } from 'src/app/shared/services/job-details-shortlist/job-details-shortlist.service';
import { ShortlistActionResponse } from 'src/app/shared/models/internal/shortlist-action-response.interface';

interface TalentAction {
  label: string;
  onClick: (...args: any[]) => any;
}
@Component({
  selector: 'virtual-interview-phase',
  templateUrl: './virtual-interview.component.html',
  styleUrls: [
    './virtual-interview.component.scss',
    '../recruitment-phases.component.scss'
  ]
})
export class VirtualInterviewComponent implements OnInit, OnDestroy, AfterViewChecked {

  // Determines which template to show.
  shownPhase: TemplateRef<any>;
  previousShownPhase: TemplateRef<any>;
  application: Application;
  appSub: Subscription;
  statusLabel: string;
  conversationID: string;
  timeSlotSelected: string;
  chatbotDisabled: boolean = false;
  chatbotHidden: boolean = false;
  profile: Profile;
  disabledChatbotMsg: string = '';
  actions: TalentAction[] = [];
  bsModalRef: BsModalRef;
  searchResults: any;
  skippedApplication: Application;

  //Determines if the caution modal for interviewed candidates
  showingCautionModal:boolean = false


  @Input() job: Job;
  @Input() talentInterviewStatus:boolean
  @Output() unfitClick = new EventEmitter;

  // The different templates for the different phases.
  @ViewChild('startInterview', { static: true }) startInterview: TemplateRef<any>;
  @ViewChild('conductInterview', { static: true }) conductInterview: TemplateRef<any>;
  @ViewChild('completeInterview', { static: true }) completeInterview: TemplateRef<any>;
  @ViewChild('completePipelineInterview', { static: true }) completePipelineInterview: TemplateRef<any>;
  @ViewChild('reviewInterview', { static: true }) reviewInterview: TemplateRef<any>;
  @ViewChild('interviewCanceled', { static: true }) interviewCanceled: TemplateRef<any>;
  @ViewChild('noTimeSlot', { static: true }) noTimeSlot: TemplateRef<any>;
  @ViewChild('ctaContainer', {static: false}) ctaContainer: ElementRef;
  @ViewChild('ctaContainerBoundry', {static: false}) ctaContainerBoundry: ElementRef;

  constructor(
    private _api: ApiService,
    private _translate: TranslateService,
    private _modalService: ModalService,
    private talentProcess: TalentProcessService,
    private talentService: TalentService,
    private _shortlist: JobDetailsShortlistService,
    private _router: Router,
    private _route: ActivatedRoute,
    private cache: CacheService,
    private auth: AuthService,
    private zone: NgZone,
    private toast: ToastService,
    private jobOrder: JobOrderService,
    private _metrics: MetricsService,
    private loading: LoadingSpinnerService,
    private _utility: UtilityService,
    private _userService: UserService,
    private _recruitmentPhasesService: RecruitmentPhasesService,
  ) { }

  ngOnInit() {
    const trAddJob = this._translate.instant("talent-detail-pane.add_job");
    const trList = this._translate.instant("talent-detail-pane.add_list");
    this.actions = [
      {
        label: trAddJob,
        onClick: () => this.openAddToJobModal(),
      },
      {
        label: trList,
        onClick: () => this.openAddToAListModal(),
      }
    ];
    this.listenToApplication();
    this.chatbotDisabled = this.checkExpired();
  }

  ngAfterViewChecked() {
    if (this.previousShownPhase !== this.shownPhase) {
      this.previousShownPhase = this.shownPhase;
      if (this.ctaContainer && this.ctaContainer.nativeElement) {
        const elements = [
          this.ctaContainer.nativeElement,
          this.ctaContainerBoundry.nativeElement
        ];
        this.talentProcess.setupIntersectionObserver(elements);
      }
    }
  }

  checkExpired() {
    const chatbotDisabled = this.jobOrder.checkChatbotDisabled(this.job, true);
    this.chatbotDisabled = chatbotDisabled.disabled;
    this.chatbotHidden = chatbotDisabled.hidden;
    this.disabledChatbotMsg = chatbotDisabled.msg;
    return this.chatbotDisabled;
  }

  ngOnDestroy() {
    if (this.appSub) {
      this.appSub.unsubscribe();
    }
  }

  listenToApplication() {
    this.appSub = this.talentProcess.selectedApplication.subscribe(async (data: any) => {
      this.application = data;
      if (this.application && this.auth.user) {
        if(this.application && this.application.randstad_process &&
          this.application.randstad_process.interviewSchedule &&
          this.application.randstad_process.interviewSchedule.candidateEmail) {
          await this._userService.getUsersByEmail([this.application.randstad_process.interviewSchedule.recruiterEmail]);
        }
        this.statusLabel = this.talentProcess.getLabel(this.application, this.auth.user.Source, 'process', this.jobOrder.storedJobOrder, undefined, this.jobOrder.storedJobOrder.allbirds_metadata.auto_scheduler_location);
        if(this.application.randstad_process?.interviewSchedule?.interviewStatus === InterviewStatus.RECRUITER_CANCELLED){
          let author = (this.application.randstad_process?.interviewSchedule?.cancelledBy && this._userService.getFromCache(this.application.randstad_process?.interviewSchedule?.cancelledBy)) || null;
          if (!author) {
            await this._userService.getUsersById([this.application.randstad_process.interviewSchedule.cancelledBy]);
            author = this._userService.getFromCache(this.application.randstad_process?.interviewSchedule?.cancelledBy);
          }
          let recruiterName = '';
          if (author) recruiterName = author.FullName;
          this.statusLabel =  this.statusLabel.replace("the candidate", recruiterName);
        }
      }
      if (this.application
        && this.application.randstad_process
        && this.application.randstad_process.prescreening
        && this.application.randstad_process.prescreening.conversationID
      ) {
        this.conversationID = this.application.randstad_process.prescreening.conversationID;
      } else {
        this.conversationID = null;
      }
      // if there isnt an interview schedule then we are at the start

      if (this.application && this.application.randstad_process && this.application.randstad_process.lastProcessStatus === ProcessStatus.IR_PLAN_INTERVIEW) {
        this.shownPhase = this.startInterview;
        // otherwise if there is then we check if interview is manually completed
      }
      else if (this.application &&
        this.application.randstad_process &&
        this.application.randstad_process.interview &&
        this.application.randstad_process.interview.completionDate && this.job.allbirds_metadata.order_type === 'Pipeline') {
        this.shownPhase = this.completePipelineInterview;
      }
      else if (this.application &&
        this.application.randstad_process &&
        this.application.randstad_process.interview &&
        this.application.randstad_process.interview.completionDate) {
        this.shownPhase = this.completeInterview;
        // if theres a conversation but not a interview scheduled
      } else {
        if (this.application && this.application.randstad_process) {
          if (!this.application.randstad_process.interviewSchedule ||
            !this.application.randstad_process.interviewSchedule.interviewStatus ||
            this.application.randstad_process.interviewSchedule.interviewStatus === InterviewStatus.INVITE_SENT) {
              this.shownPhase = this.conductInterview;
          } else if (this.application.randstad_process.interviewSchedule.interviewStatus === InterviewStatus.NO_SLOT_SELECTED) {  // if nethier then we are conducting the interview
            this.shownPhase = this.noTimeSlot;
          } else if (this.application.randstad_process.interviewSchedule.interviewStatus === InterviewStatus.SCHEDULED
            || this.application.randstad_process.interviewSchedule.interviewStatus === InterviewStatus.RESCHEDULED) {
            this.shownPhase = this.reviewInterview;
          } else if (this.application.randstad_process.interviewSchedule.interviewStatus === InterviewStatus.CANCELLED || this.application.randstad_process.interviewSchedule.interviewStatus === InterviewStatus.RECRUITER_CANCELLED) {
            console.log('hererer changing the status');
            this.shownPhase = this.interviewCanceled;
          } else {
            console.log('[Important] No interview status matched:', this.application.randstad_process.interviewSchedule.interviewStatus);
          }
        }
      }
    });
  }

  openAddToAListModal() {
    const profileId = this.application.profileId;
    this.profile = this.cache.loadProfile(profileId);
    const currentId = this._route.snapshot.paramMap.get("listId");
    const config: ModalOptions<any> = {
      initialState: {
        talent: this.profile,
        currentId,
      },
      class: "modal-add-talent-to-job",
    };
    this.bsModalRef = this._modalService.show(AddToListAModalComponent, config);
  }

  openAddToJobModal() {
    let filters = [];
    filters.push({
      value: this.auth.user._id,
      field: "user",
      type: "string",
      display: `${this.auth.user.FirstName} ${this.auth.user.LastName}`,
    });
    filters = [
      ...filters,
      ...[
        {
          value: "Unfilled",
          field: "allbirds_metadata.order_status",
          type: "string",
          lob: "RGS",
        },
        { 'value': 'Client Order', 'field': 'allbirds_metadata.order_type', 'type': 'string' }
      ],
    ];
    const profileId = this.application.profileId;

    const url = `/talent/add-to-job/search?sourceType=all&filters=${JSON.stringify(
      filters)}&profile_id=${profileId}`;
    this._router.navigateByUrl(url, { state: { url: this._router.url } });
  }

  emitUnfitClick(event:any) {
    this.unfitClick.emit(null);
  }

  cancelInterviewModal() {
    const config: ModalOptions<any> = {
      initialState: {
        application: this.application,
        label: this.statusLabel,
        job: this.job
      }
    };
    this.bsModalRef = this._modalService.show(CancelInterviewModalComponent, config);
    this.bsModalRef.setClass('modal-sm');
  }

  submit() {
    if (this.chatbotDisabled) {
      return;
    }
    const key = this.application.randstad_process.lastProcessStep;
    const body = this.constructBody(false);
    const profile = this.cache.loadProfile(this.application.profile);
    if (!profile) {
      return this.toast.showToast('virtual-interview.issue', { cssClass: ToastClass.DANGER });
    }
    const phoneArr = profile.phoneNumbers as Array<any>;
    let phone;
    // if we dont have a google profile field
    if (!profile.name) {
      this._utility.launchFeatureUnavailableModal('feature-unavailable.PROFILE_NO_ID');
      return;
    }
    if (phoneArr && phoneArr.length) {
      phone = phoneArr.find(x => x.type === 'MOBILE');
      if (!phone) {
        phone = phoneArr.find(x => x.type === 'LANDLINE');
        if (!phone) {
          phone = phoneArr[0];
        }
      }
    } else {
      phone = { number: null };
    }
    const interview: ScheduleCandidate = {
      profileId: profile.name,
      candidateFirstName: (profile.personNames && profile.personNames.length) ? profile.personNames[0].structuredName.givenName : null,
      candidateLastName: (profile.personNames && profile.personNames.length) ? profile.personNames[0].structuredName.familyName : null,
      candidateEmail: (profile.emailAddresses && profile.emailAddresses.length) ? profile.emailAddresses[0].emailAddress : null,
      candidatePhone: environment.env === 'production' ? phone.number : '7703036836',
      jobReference: this.application.job,
      interviewOptions: this.job.allbirds_metadata.auto_scheduler_interview_channel,
      requestType: 'RECRUITER_INVITE'
    };
    this.loading.show();
    const intgSteps : INTG_STEPS[] = [];
    // send to integrations
    this._api.scheduleChatbotInterview(interview).subscribe((data) => {
      this.talentProcess.updateApplication(body, intgSteps, key).then((data) => {
        if (data) {
          this._metrics.addEventToQueue(this.application, `candidate was sent an invitation to conduct a virtual interview`);
          this.toast.showToast('virtual-interview.interview_sent');
        } else {
          this.toast.showToast('virtual-interview.error_sending', { cssClass: ToastClass.DANGER });
        }
      }, err => {
        console.log(err);
        this.toast.showToast('virtual-interview.error_sending', { cssClass: ToastClass.DANGER });
      });
    }, err => {
      this.loading.hide();
      this.toast.showToast('virtual-interview.error_sending', { cssClass: ToastClass.DANGER });
      console.log(err);
    });
  }

  final() {
    const key = this.application.randstad_process.lastProcessStep;
    const body = this.constructBody(true);
    const intgSteps : INTG_STEPS[] = []; // This is correct as this step there is no updates to FO
    this.talentProcess.updateApplication(body, intgSteps, key).then((data) => {
      if (data) {
        this._metrics.addEventToQueue(this.application, `candidate was moved past the virtual interview step`);
        this.toast.showToast('virtual-interview.next_step');
      } else {
        this.toast.showToast('virtual-interview.next_step_error', { cssClass: ToastClass.DANGER });
      }
    }, err => {
      console.log(err);
      this.toast.showToast('virtual-interview.next_step_error', { cssClass: ToastClass.DANGER });
    });
  }

  constructBody(final: boolean): Application {
    const clonedApp = this.application.clone();
    if (final &&
      this.application &&
      this.application.randstad_process &&
      this.jobOrder &&
      this.jobOrder.storedJobOrder &&
      this.jobOrder.storedJobOrder.allbirds_metadata &&
      this.jobOrder.storedJobOrder.allbirds_metadata.lob) {
      // if its the final step
      clonedApp.randstad_process.apply({
        lastProcessStep: this.jobOrder.storedJobOrder.allbirds_metadata.lob.checkLob('RT', 'RE','CR') ? ProcessStep.REVIEW_AM : ProcessStep.REVIEW_HM,
        lastProcessStepDate: moment(),
        lastProcessStatus: this.jobOrder.storedJobOrder.allbirds_metadata.lob.checkLob('RT', 'RE','CR') ?
                              this.getProcessStatusForRTJob() : ProcessStatus.RH_SUBMIT_TO_HM
      });
    } else {
      // otherwise continue with chatbot interview
      clonedApp.randstad_process.apply({
        lastProcessStatus: ProcessStatus.IR_AWAITING_RESPONSE,
        interviewSchedule: new InterviewSchedule({
          agentID: this.auth.user.BackOfficeID,
          skipped: false,
          interviewScheduledOn: moment()
        })
      });
    }
    return clonedApp;
  }

  /**
   * In case of RT jobs, chech if the job type is solutions
   */
  getProcessStatusForRTJob() {
    if (this.job && this.job.employmentTypes && this.job.employmentTypes.toString() === 'SOLUTIONS') {
      return ProcessStatus.RA_SUBMIT_TO_SS;
    } else {
      return ProcessStatus.RA_SUBMIT_TO_AM
    }
  }
  /**
   * @deprecated
   */
  openInterviewModal() {
    this._modalService.show(InterviewModalComponent);
  }

  fetchProfile(profileId: string) {
    return this.cache.loadProfile(profileId);
  }

  openLoggingModal() {
    if (this._recruitmentPhasesService.talentInterviewStatus) {
      this._recruitmentPhasesService.openWarningModal()
    } else {
      this.profile = this.fetchProfile(this.application.profileId);
      this.openDupTalentModal('logInterview');
    }

  }


  addToShortlist(talent: any) {
    this.zone.run(() => {
      this.loading.show();
      this._shortlist.add(talent)
        .then((data: ShortlistActionResponse) => {
          this._shortlist.updateInMemoryApplications([data.application]);
          this.skippedApplication = data.application;
          this.toast.showToastNoTranslation(data.message);
          this.skipToStep(talent);
          this.loading.hide();
        })
        .catch((err: any) => {
          this.loading.hide();
          this.toast.showToastNoTranslation(err.message);
        });
    });
  }

  skipToStep(talent: any) {
    let isSolutionsJob: boolean = this.job && this.job.employmentTypes &&
      this.job.employmentTypes.toString() === 'SOLUTIONS';
    this._metrics.addEventToQueue(this.skippedApplication, `candidate was manually advanced to the Recruiter interview phase.`);
    return this.talentProcess.skipToStep(this.skippedApplication, 1, isSolutionsJob);
  }

  openDupTalentModal(type: string) {
    this.loading.show();
    if (this.profile.isProfile()) {
      const familyName = this.profile.familyName;
      const givenName = this.profile.givenName;
      const lob = this.profile.lob;
      const phoneNumbers = this.profile.candidatePhoneNumber;
      const talentId = this.profile.talentId;
      const emailAddresses = this.profile?.emailAddresses?.length > 0 && this.profile?.emailAddresses[0].emailAddress || [];
      const body = { familyName, givenName, lob, phoneNumbers, talentId, emailAddresses: [emailAddresses] }
      this._api.getDuplicateTalent(body)
        .subscribe(data => {
          this.loading.hide();
          if ((data.length === 0 || data.length === 1) && type == 'logInterview') {
            this._modalService.show(LogInterviewModalComponent);
            return;
          }
          this.searchResults = {
            total: data.length
          }
          const selectedProfile = data.find((p) => p.externalId === this.profile.externalId);
          const initialState = { talent: selectedProfile, searchResults: this.searchResults, duplicateTalent: data, type };
          this.bsModalRef = this._modalService.show(DupTalentModalComponent, { initialState, class: 'modal-xl' });
          this.bsModalRef.content.logInterviewEvent.subscribe((res: any) => {
            if (res.profileId === this.application.profileId) {
              this._modalService.show(LogInterviewModalComponent);
              return;
            }

            const selectedProfile = this.fetchProfile(res.profileId);
            this.addToShortlist(selectedProfile);
          });
        });
    }


  }

  get applicationDisabledState(): ApplicationState {
    return {
      disabled: this.chatbotDisabled,
      disabledMsg: this.disabledChatbotMsg,
      hidden: this.chatbotHidden
    };
  }
}
