import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ChartData, ChartDataSets, ChartOptions, ChartTooltipItem, ChartTooltipModel, ChartType} from 'chart.js';
import {Color, Label} from 'ng2-charts';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {Profile} from 'src/app/shared/models/external/profile.model';
import {ApiService} from '../../../services/api/api.service';
import {AuthService} from '../../../services/auth/auth.service';
import {ModalService} from '../../../services/modal/modal.service';
import {TalentMetricsModalComponent} from './talent-metrics-modal/talent-metrics-modal.component';
import {
  MetricsModalConfig,
  TalentMetricsActivityMapping,
  TalentMetricsActivityTypes,
  TalentMetricsLabels,
  TalentMetricsRequest,
  TalentMetricsTypeCount,
  TalentMetricsFilterTypeLabelsObject
} from './talent-metrics.interface';

@Component({
  selector: 'app-talent-metrics',
  templateUrl: './talent-metrics.component.html',
  styleUrls: ['./talent-metrics.component.scss']
})
export class TalentMetricsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() profile: Profile;
  barChartOptions: ChartOptions;
  barChartType: ChartType = 'bar';
  barChartLegend = false;
  barChartPlugins: any[] = [];
  barChartColors: Color[] = [
    {backgroundColor: '#2175d9', hoverBackgroundColor: '#175197'}
  ];
  barChartLabels: Label[] = [];
  barChartData: ChartDataSets[] = [{data: []}]; // Cannot pass an empty array, get stack trace waterfall
  loading: boolean = false;
  subs: Subscription = new Subscription();
  activityMetrics: any = {};
  sortedActivityMetrics: any = [];

  constructor(
    private _auth: AuthService,
    private _modal: ModalService,
    private _api: ApiService,
    private _translate: TranslateService
  ) { }

  ngOnInit() {
    this.setChartConfig();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    // Update the metrics when the profile changes
    if (changes.profile && changes.profile.currentValue) {
      this.getChartData();
    }
  }

  /**
   * Sets barChartOptions which controls styling and all rendering
   * properties of the talent metrics chart
   */
  setChartConfig() {
    this.barChartOptions = {
      responsive: false,
      title: {
        text: this._translate.instant('talent-metrics-title'),
        display: true,
        position: 'top',
        fontSize: 19,
        fontColor: '#2a2a2a',
        fontFamily: '"Graphik", sans-serif',
        fontStyle: 'normal'
      },
      showLines: false,
      scales: {
        yAxes: [
          {
            display: false,
            ticks: {beginAtZero: true}
          }
        ],
        xAxes: [
          {
            gridLines: {
              drawBorder: false,
              display: false,
              drawTicks: false,
              zeroLineWidth: 0
            },
            ticks: {
              padding: 3,
              fontSize: 16,
              fontFamily: '"Graphik", sans-serif'
            }
          }
        ]
      },
      tooltips: {
        enabled: true,
        callbacks: {
          title: this.tooltipTitleCallback.bind(this),
          label: this.tooltipLabelCallback.bind(this)
        }
      }
    };
  }

  /**
   * Fetch the activity data and construct the chart data
   */
  getChartData() {
    const requestPayload: TalentMetricsRequest = {
      talentBackOfficeId: this.profile.externalId
    };
    this.loading = true;
    this.subs.add(this._api.getUserRecruitmentMetrics(requestPayload)
      .pipe(finalize(() => this.loading = false))
      .subscribe((resp) => {
        const {metrics} = resp;
        if (metrics) {
          const assignments = this.profile.allbirds_metadata.assignments || [];
          const lob = this.profile.lob.abIncludesLob('RT', 'RE') ? 'RT' : 'RGS';
          this.activityMetrics = {};
          for (const item in TalentMetricsActivityTypes) {
            if (item !== TalentMetricsActivityTypes.Assignment) {
              const activityTypeMetric = this.getActivityTypeMetrics(metrics, item, lob);
              const totalCount = activityTypeMetric && activityTypeMetric.length
                ? this.getActivityTypeCount(activityTypeMetric)
                : 0;
              this.buildTalentMetricsObject(item, totalCount);
            } else {
              this.buildTalentMetricsObject(item, assignments.length);
            }
          }
          this.sortedActivityMetrics = [];
          this.sortTalentMetricsArray();
        }
      }));
  }

  buildTalentMetricsObject(metric: any, count: any) {
    if (!this.activityMetrics[metric]) {
      this.activityMetrics[metric] = count;
    } else {
      this.activityMetrics[metric] = this.activityMetrics[metric] + count;
    }
  }

  sortTalentMetricsArray() {
    const activityTypes = Object.keys(TalentMetricsActivityTypes);
    const lob = this.profile.lob.abIncludesLob('RT', 'RE') ? 'RT' : 'RGS';
    for (let i = 0; i < activityTypes.length; i++) {
      const metricKeyMap = TalentMetricsActivityMapping[lob][activityTypes[i]];
      if (metricKeyMap?.length) {
        const name = TalentMetricsFilterTypeLabelsObject[activityTypes[i]];
        const count = this.activityMetrics[activityTypes[i]];
        this.sortedActivityMetrics.push({name, count});
      }
    }
    console.log(this.sortedActivityMetrics);
  }

  /**
   * Some of the metrics returned may be spread across multiple items. This will
   * sum all of those items into single value
   * @param {TalentMetricsTypeCount[]}activityTypeMetric
   * @returns {number}
   */
  getActivityTypeCount(activityTypeMetric: TalentMetricsTypeCount[]) {
    const numbersOnly = activityTypeMetric.map(res => res.doc_count);
    return numbersOnly.reduce((total, curr) => {
      return total + curr;
    });
  }

  /**
   * Will filter the metrics items that have the passed activityType
   * @param {TalentMetricsTypeCount[]} metrics
   * @param {TalentMetricsActivityTypes as string} activityType
   * @param {string} lob
   * @returns {TalentMetricsTypeCount[]}
   */
  getActivityTypeMetrics(metrics: TalentMetricsTypeCount[], activityType: string, lob: string) {
    return metrics.filter((metric) => {
      const metricKeyMap = TalentMetricsActivityMapping[lob][activityType];
      if (metricKeyMap && metricKeyMap.length) {
        return metricKeyMap.includes(metric.key);
      }
      return false;
    });
  }

  /**
   * Set the title of the tooltip when displayed
   * @param {ChartTooltipItem[]} item
   * @param {ChartData} data
   */
  tooltipTitleCallback(item: ChartTooltipItem[], data: ChartData) {
    const lob = this.profile.lob.abIncludesLob('RT', 'RE') ? 'RT' : 'RGS';
    console.log("this is the index")
    return this._getTooltipLabelTranslation(lob, item[0].index);
  }

  /**
   * Get the tooltip label based on the index of the bar in the chart and lob
   * @param {'RT' | 'RGS'} lob
   * @param {number} index
   * @returns {string}
   */
  _getTooltipLabelTranslation(lob: 'RT' | 'RGS', index: number) {
    const activityTypes = Object.keys(TalentMetricsActivityTypes);
    console.log("this is the index")
    const idx = lob === 'RT'
      ? index
      : index > 0
        ? index + 1
        : index;
    return this._translate.instant(`talent-metrics-labels.${activityTypes[idx]}`);
  }

  /**
   * Set the label of the tooltip when displayed. Set to empty string
   * so that the default Charts.js title isn't displayed
   * @param {ChartTooltipItem[]} item
   * @param {ChartData} data
   */
  tooltipLabelCallback(item: ChartTooltipModel[], data: ChartData) {
    return '';
  }

  /**
   * Fired when the "open details" link is clicked. Builds a config
   * object and opens the MetricsModal
   * @listens openDetailsLink#click
   */
  openMetricsDetailsClick() {
    const config: MetricsModalConfig = {
      activeFilter: null,
      profile: this.profile
    };
    this.showMetricsModal(config);
  }

  /**
   * Fired when a column in chart is clicked
   * @param {active: any, event: MouseEvent} evt
   * @listens chart#click
   */
  barChartClick(evt: {active: any[], event: MouseEvent}) {
    if (evt.active.length) {
      console.log("this is the index")
      const label = this.profile.lob.abIncludesLob('RT', 'RE')
        ? TalentMetricsLabels.RT[evt.active[0]._index]
        : TalentMetricsLabels.RGS[evt.active[0]._index];
      const config: MetricsModalConfig = {
        activeFilter: label,
        profile: this.profile
      };
      this.showMetricsModal(config);
    }
  }

  historyClick(historyName: any){
    const config: MetricsModalConfig = {
      activeFilter: this.getTalentMetricLabelEnumFromName(historyName),
      profile: this.profile
    };
    this.showMetricsModal(config);
  }

  getTalentMetricLabelEnumFromName (historyName: any) {
    const labelArray = this.profile.lob.abIncludesLob('RT', 'RE')
      ? TalentMetricsLabels.RT
      : TalentMetricsLabels.RGS;
    for (let i = labelArray.length; i--;) {
      if (labelArray[i] === historyName) {
        return labelArray[i];
      }
    }
  }

  /**
   * Open the Metrics Modal
   * @param {MetricsModalConfig} config
   */
  showMetricsModal(config: MetricsModalConfig) {
    const initialState = config;
    this._modal.show(TalentMetricsModalComponent, {initialState});
  }

}
