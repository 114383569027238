import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ChartsModule, ThemeService} from 'ng2-charts';
// NGX-Bootstrap
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {SidebarModule} from 'ng-sidebar';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {ModalModule} from 'ngx-bootstrap/modal';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {NouisliderModule} from 'ng2-nouislider';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {NgxSpinnerModule} from 'ngx-spinner';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {QuillModule} from 'ngx-quill';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-bootstrap/carousel';
// ng2-validation
import { CustomFormsModule } from 'ng2-validation';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FileUploadModule } from 'ng2-file-upload';
import {AppointmentEmailComponent} from 'src/app/shared/components/appointment-email/appointment-email.component';
import {AppointmentSettingsComponent} from 'src/app/shared/components/appointment-settings/appointment-settings.component';
import {SchedAppointmentModalComponent} from 'src/app/shared/components/sched-appointment-modal/sched-appointment-modal.component';
import {SchedAppointmentInterviewModalComponent} from 'src/app/shared/components/sched-appointment-interview-modal/sched-appointment-interview-modal.component';
import { DupTalentModalComponent } from 'src/app/shared/components/dup-talent-modal/dup-talent-modal.component';

import {ApptInviteService} from 'src/app/shared/services/appt-invite/appt-invite.service';
import {ConfirmModalComponent} from '../../shared/components/confirm-modal/confirm-modal.component';
import { AttachmentOptionsModalComponent } from '../../shared/components/attachment-options-modal/attachment-options-modal.component';

// Firebase
// Components
import { PillButtonComponent } from '../../shared/components/pill-button/pill-button.component';
import { SkillPillComponent } from '../../shared/components/skill-pill/skill-pill.component';
import { MassActionPaneComponent } from '../../shared/components/mass-action-pane/mass-action-pane.component';
import { ReviewAccountManagerComponent } from '../../shared/components/talent-detail-pane/talent-process/recruitment-phases/review-account-manager/review-account-manager.component';
import { PrescreeningComponent } from '../../shared/components/talent-detail-pane/talent-process/recruitment-phases/prescreening/prescreening.component';
import { VirtualInterviewComponent } from '../../shared/components/talent-detail-pane/talent-process/recruitment-phases/virtual-interview/virtual-interview.component';
import { CancelInterviewModalComponent } from '../../shared/components/talent-detail-pane/talent-process/recruitment-phases/virtual-interview/cancel-interview-modal/cancel-interview-modal.component';
import { ReviewHiringManagerComponent } from '../../shared/components/talent-detail-pane/talent-process/recruitment-phases/review-hiring-manager/review-hiring-manager.component';
import { InterviewHiringManagerComponent } from '../../shared/components/talent-detail-pane/talent-process/recruitment-phases/interview-hiring-manager/interview-hiring-manager.component';
import { OfferComponent } from '../../shared/components/talent-detail-pane/talent-process/recruitment-phases/offer/offer.component';
import { OnboardingComponent } from '../../shared/components/talent-detail-pane/talent-process/onboarding/onboarding.component';
import { ShortlistedComponent } from '../../shared/components/talent-detail-pane/talent-process/recruitment-phases/shortlisted/shortlisted.component';
import { PrescreeningQuestionsComponent } from '../../shared/components/talent-detail-pane/talent-process/recruitment-phases/prescreening/prescreening-questions/prescreening-questions.component';
import { ManualPrescreeningResultsComponent } from '../../shared/components/talent-detail-pane/talent-process/recruitment-phases/prescreening/manual-prescreening-results/manual-prescreening-results.component';
import { FileUploadModalComponent } from '../../shared/components/file-upload-modal/file-upload-modal.component';
import { SubmitToAmModalComponent } from '../../shared/components/talent-detail-pane/talent-process/talent-process-modals/submit-to-am-modal/submit-to-am-modal.component';
import { TalentResumeComponent } from '../../shared/components/talent-detail-pane/talent-resume/talent-resume.component';
import { TalentProfileComponent } from '../../shared/components/talent-detail-pane/talent-profile/talent-profile.component';
import { InterviewModalComponent } from '../../shared/components/talent-detail-pane/talent-process/talent-process-modals/schedule-interview-modal/interview-modal.component';
import { LogInterviewModalComponent } from '../../shared/components/talent-detail-pane/talent-process/talent-process-modals/log-interview-modal/log-interview-modal.component';
import { LogFeedbackModalComponent } from '../../shared/components/talent-detail-pane/talent-process/talent-process-modals/log-feedback-modal/log-feedback-modal.component';
import { ActivityDetailModalComponent } from '../../shared/components/activity-detail-modal/activity-detail-modal.component';
import { LogNoteModalComponent } from '../../shared/components/talent-detail-pane/talent-process/talent-process-modals/log-note-modal/log-note-modal.component';
import { TalentHistoryComponent } from '../../shared/components/talent-detail-pane/talent-history/talent-history.component';
import { TalentHistoryFeedComponent } from '../../shared/components/talent-detail-pane/talent-history/talent-history-feed/talent-history-feed.component';
import { UserCircleComponent } from '../../shared/components/user-circle/user-circle.component';
import { FeatureUnavailableModalComponent } from '../../shared/components/feature-unavailable-modal/feature-unavailable-modal.component';
import { ConversationComponent } from '../../shared/components/conversation/conversation.component';
import { TalentDetailPaneComponent } from '../../shared/components/talent-detail-pane/talent-detail-pane.component';
import { TalentResumeModalComponent } from '../../shared/components/talent-detail-pane/talent-resume/talent-resume-modal/talent-resume-modal.component';
import { QuillEditorComponent } from '../../shared/components/quill-editor/quill-editor.component';
import { NotFitModalComponent } from '../../shared/components/talent-detail-pane/talent-process/talent-process-modals/not-fit-modal/not-fit-modal.component';
import {
  ActivityFeedComponent,
  LimitHtmlDirective
} from '../../shared/components/talent-detail-pane/talent-process/activity-feed/activity-feed.component';
import { LogCallModalComponent } from '../../shared/components/talent-detail-pane/talent-process/talent-process-modals/log-call-modal/log-call-modal.component';
import { UserPrefsMenuComponent } from '../../shared/components/user-prefs-menu/user-prefs-menu.component';
import { VerticalAlignComponent } from '../../shared/components/vertical-align/vertical-align.component';
import { SendEmailModalComponent } from '../../shared/components/talent-detail-pane/talent-process/talent-process-modals/send-email-modal/send-email-modal.component';
import { SendTextModalComponent } from '../../shared/components/talent-detail-pane/talent-process/talent-process-modals/send-text-modal/send-text-modal.component';
import { RecruitmentPhasesComponent } from '../../shared/components/talent-detail-pane/talent-process/recruitment-phases/recruitment-phases.component';
import { ToastComponent } from '../../shared/components/toast/toast.component';
import { TalentProcessComponent } from '../../shared/components/talent-detail-pane/talent-process/talent-process.component';
import { CkeditorComponent } from '../../shared/components/ckeditor/ckeditor.component';
import { RemoveMeridianDirective, TimeSelectorComponent } from '../../shared/components/time-selector/time-selector.component';
import { TalentCardComponent } from '../../shared/components/talent-card/talent-card.component';
import { SidebarComponent } from '../../shared/components/sidebar/sidebar.component';
import { NotFoundComponent } from '../../shared/components/not-found/not-found.component';
import { FormErrorComponent } from '../../shared/components/form-error/form-error.component';
import { VmsJobDetailsPaneComponent } from 'src/app/shared/components/vms-job-details-pane/vms-job-details-pane.component';
import { SplitPanelComponent } from 'src/app/shared/components/split-panel/split-panel.component';
import { VmsDashboardHeaderComponent } from '../vms-jobs/headers/vms-dashboard-header/vms-dashboard-header.component';
import {
  HideChevronDirective,
  TalentMetricsModalComponent
} from '../../shared/components/talent-detail-pane/talent-metrics/talent-metrics-modal/talent-metrics-modal.component';
import { TalentMetricsComponent } from '../../shared/components/talent-detail-pane/talent-metrics/talent-metrics.component';
import {PossessivePipe} from '../../shared/pipes/name/possessive.pipe';
import {ProcessStepperComponent} from '../jobs/pages/job-details/job-details-shortlist/process-stepper/process-stepper.component';
import {ShortlistCardContentComponent} from '../jobs/pages/job-details/job-details-shortlist/shortlist-card-content/shortlist-card-content.component';
import {ShortlistCardListComponent} from '../jobs/pages/job-details/job-details-shortlist/shortlist-card-list/shortlist-card-list.component';
import {JobDetailsModalComponent} from 'src/app/shared/components/job-details-modal/job-details-modal.component';
import {JobDetailsPaneComponent} from 'src/app/shared/components/job-details-pane/job-details-pane.component';
import {JobOrderCardComponent} from '../jobs/pages/job-order/job-order-card/job-order-card.component';
import {JobsFilterComponent} from '../jobs/pages/job-management/jobs-filter/jobs-filter.component';
import {JobsFilterNewComponent} from '../jobs/pages/job-management/jobs-filter-new/jobs-filter-new.component';
import {JobsFilterFormComponent} from '../jobs/pages/job-management/jobs-filter-form/jobs-filter-form.component';
import {JobSearchHeaderComponent} from '../jobs/headers/job-search-header/job-search-header.component';
import {JobSearchNewHeaderComponent} from '../jobs/headers/job-search-new-header/job-search-new-header.component';
import {JobDetailsHeaderComponent} from '../jobs/headers/job-details-header/job-details-header.component';
import {JobManagementHeaderComponent} from '../jobs/headers/job-management-header/header.component';
import {JobOrderHeaderComponent} from '../jobs/headers/job-order-header/header.component';
import {UserLookupComponent} from '../../shared/components//user-lookup/user-lookup.component';
import {AddToJobComponent} from '../../shared/components/talent-detail-pane/talent-process/talent-process-modals/add-to-job/add-to-job.component';
import {CardComponent} from '../../modules/jobs/pages/job-management/job-board/card/card.component';
import {NotAFitModalComponent} from '../../shared/components/not-a-fit-modal/not-a-fit-modal.component';
import {JobActionMenuComponent} from '../../shared/components/job-action-menu/job-action-menu.component';
import {JobViewChangerComponent} from '../../shared/components/job-view-changer/job-view-changer.component';
import {ManageRolesComponent} from '../../shared/components/manage-roles/manage-roles.component';
import {BookmarkComponent} from '../../shared/components/bookmark/bookmark.component';
import {BookmarkCardComponent} from '../../shared/components/bookmark/bookmark-card/bookmark-card.component';



// Pipes
import { NgPipesModule } from 'angular-pipes';
import { SnippetPipe } from '../../shared/pipes/snippet/snippet.pipe';
import { BoolstringPipe } from '../../shared/pipes/boolstring/boolstring.pipe';
import { ArrayPipe } from '../../shared/pipes/array/array.pipe';
import { StringCleanPipe } from '../../shared/pipes/array/stringclean.pipe';
import { SafeHtmlPipe } from '../../shared/pipes/safe-html/safe-html.pipe';
import { SafeStylePipe } from '../../shared/pipes/safe-style/safe-style.pipe';
import { FormatPhonePipe } from '../../shared/pipes/format-phone-number/format-phone.pipe';
import { SearchPipe } from '../../shared/pipes/search/search.pipe';
// Directives
import { InViewportModule } from '@thisissoon/angular-inviewport';
import { InViewportDirective } from '../../shared/directives/in-viewport/in-viewport.directive';
import { ClickOutsideDirective } from '../../shared/directives/clickOutside/click-outside.directive';
import { DisableControlDirective } from '../../shared/directives/disable-control/disable-control.directive';
import { IndeterminateCheckboxDirective } from '../../shared/directives/indeterminate-checkbox/indeterminate-checkbox.directive';
// Theme
import {ThemeModule} from '../../shared/theme/theme.module';
import {darkTheme, lightTheme} from '../../shared/theme/themes';
import {SettingsHeaderComponent} from '../settings/headers/settings-header/settings-header.component';
// Env
import { TalentManagementListingsHeaderComponent } from '../talent/headers/talent-management-listings-header/talent-management-listings-header.component';
import { TalentManagementHeaderComponent } from '../talent/headers/talent-management-header/header.component';
import { UserManagementHeaderComponent } from '../admin/headers/user-management-header/header.component';
import { TalentManagementSearchHeaderComponent } from '../talent/headers/talent-management-search-header/talent-management-search-header.component';
import { AdManagementModalComponent } from '../jobs/pages/job-details/ad-management-modal/ad-management-modal.component';
import { AssignRecruiterModalComponent } from '../jobs/pages/job-details/assign-recruiter-modal/assign-recruiter-modal.component';
import { SendUserEmailModalComponent } from '../jobs/pages/job-details/send-email-modal/send-user-email-modal.component';
import { UpdateJobStatusModalComponent} from "../../shared/components/update-job-status-modal/update-job-status-modal.component";
import { CloseJobModalComponent } from '../jobs/pages/job-details/close-job-modal/close-job-modal.component';
import { ExtendJobModalComponent } from '../jobs/pages/job-details/extend-job-modal/extend-job-modal.component';
import { ChangeOwnerModalComponent } from '../jobs/pages/job-details/change-owner-modal/change-owner-modal.component';
import { ActivityFeedActionComponent } from '../../shared/components/talent-detail-pane/talent-process/activity-feed/activity-feed-action/activity-feed-action.component';
import { RecruiterInterviewManualQuestionsComponent } from '../../shared/components/talent-detail-pane/talent-process/recruitment-phases/virtual-interview/recruiter-interview-manual-questions/recruiter-interview-manual-questions.component';
import { NgMasonryGridModule } from 'ng-masonry-grid';
import { CKEditorModule } from 'ckeditor4-angular';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ListDetailsHeaderComponent } from '../talent/headers/list-details-header/list-details-header.component';
import { RecActivityListDetailsHeaderComponent} from '../talent/headers/rec-activity-list-details-header/rec-activity-list-details-header.component';
import {EditListModalComponent } from '../talent/pages/talent-management-landing/edit-list-modal/edit-list-modal.component';
import { ListInfoComponent } from '../talent/pages/list-details/list-info/list-info.component';
import { RemoveFromListModalComponent } from '../talent/pages/list-details/remove-from-list-modal/remove-from-list-modal.component';
import { DeleteListModalComponent } from '../talent/pages/list-details/delete-list-modal/delete-list-modal.component';
import { AddTalentModalComponent } from '../jobs/pages/job-details/job-details-shortlist/add-talent-modal/add-talent-modal.component';
import { RecruitmentListCardComponent } from '../talent/pages/talent-management-landing/recruitment-list-card/recruitment-list-card.component';
import { AddToListAModalComponent } from '../talent/pages/list-details/add-to-list-a-modal/add-to-list-a-modal.component';
import { JobOrderInputComponent } from '../jobs/pages/job-order/job-order-input/job-order-input.component';
import { ModalService } from '../../shared/services/modal/modal.service';
import { ABDatePipe } from '../../shared/pipes/ab-datetime/ab-date.pipe';
import { ABDatetimePipe } from '../../shared/pipes/ab-datetime/ab-datetime.pipe';
import { ABTimePipe } from '../../shared/pipes/ab-datetime/ab-time.pipe';
import { FullNamePipe } from '../../shared/pipes/name/full-name.pipe';
import { InitialsPipe } from '../../shared/pipes/name/initials.pipe';
import { RejectOfferModalComponent } from '../../shared/components/talent-detail-pane/talent-process/recruitment-phases/offer/reject-offer-modal/reject-offer-modal.component';
import { ResolveService } from '../../shared/services/resolve/resolve.service';
import { PlatformAlertBannerComponent } from 'src/app/shared/components/platform-alert-banner/platform-alert-banner.component';
import { TranslateModule } from '@ngx-translate/core';
import { BannerLearnMoreModalComponent } from 'src/app/shared/components/banner-learn-more-modal/banner-learn-more-modal.component';
import { SharedUserListsComponent } from 'src/app/shared/components/shared-user-lists/shared-user-lists.component';
import { DistributionListLookupComponent } from '../../shared/components/distribution-list-lookup/distribution-list-lookup.component';
import { EmailRecipientsPillComponent } from '../../shared/components/email-recipients-pill/email-recipients-pill.component';
import { PersonalDetailsModalComponent } from 'src/app/shared/components/personal-details-modal/personal-details-modal.component';
import { RecruiterHierarchySuggestModalComponent } from '../../shared/components/recruiter-hierachy-filter-dropdown/recruiter-hierarchy-suggest-modal/recruiter-hierarchy-suggest-modal.component';
import { RecruiterHierarchyFilterDropdownComponent } from '../../shared/components/recruiter-hierachy-filter-dropdown/recruiter-hierarchy-filter-dropdown.component';
import { CardViewComponent } from '../jobs/pages/job-management/job-views/card-view/card-view.component';
import { ManageRolesModalComponent } from '../jobs/pages/job-details/manage-roles-modal/manage-roles-modal.component';
import { VmsSearchModalComponent } from './../vms-jobs/pages/vms-search-modal/vms-search-modal.component';
import { RemoveAllModalComponent } from '../../shared/components/bookmark/remove-all-modal/remove-all-modal.component';
import { ContextMenuModule } from 'ngx-contextmenu';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { SubmitToClientModalComponent } from '../../shared/components/talent-detail-pane/talent-process/talent-process-modals/submit-to-client-modal/submit-to-client-modal.component';
import { EmailMultiInputComponent } from '../../shared/components/form/email-multi-input/email-multi-input.component';
import { EmailNameMultiInputComponent } from '../../shared/components/form/email-name-multi-input/email-name-multi-input.component';
import { FileInputComponent } from '../../shared/components/form/file-input/file-input.component';
import { MassEmailModalComponent } from '../../shared/components/mass-email-modal/mass-email-modal.component';
import { TalentCreationHeaderComponent } from '../talent/headers/talent-creation-header/talent-creation-header.component';
import { CancelCreationModalComponent } from '../talent/pages/talent-creation/cancel-creation-modal/cancel-creation-modal.component';
import { CautionModalComponent } from '../talent/pages/talent-creation/caution-modal/caution-modal.component';
import { MassPrescreenModalComponent } from '../../shared/components/mass-prescreen-modal/mass-prescreen-modal.component';
import { GeoLocationSuggestComponent } from '../../shared/components/location-suggest/geo-location-suggest.component';
import { AttachFilesComponent } from '../../shared/components/attach-files/attach-files.component';
import { AddUrlModalComponent} from '../../shared/components/attach-files/add-url-modal/add-url-modal.component';
import { AddFilesModalComponent} from '../../shared/components/attach-files/add-files-modal/add-files-modal.component';
import { AttachmentCardInternalComponent} from '../../shared/components/attach-files/attachment-card-internal/attachment-card-internal.component';
import { DeleteAttachmentModalComponent } from 'src/app/shared/components/attach-files/delete-attachment-modal/delete-attachment-modal.component';
import { TalentProcessCtaComponent } from '../../shared/components/talent-detail-pane/talent-process/talent-process-cta/talent-process-cta.component';
import { MassSmsModalComponent } from '../../shared/components/mass-sms-modal/mass-sms-modal.component';
import { TalentActionBarComponent } from '../../shared/components/talent-action-bar/talent-action-bar.component';
import {AddToJobV2Component} from '../../shared/components/talent-detail-pane/talent-process/talent-process-modals/add-to-job-v2/add-to-job-v2.component';
import {JobSearchNewComponent} from '../jobs/pages/job-management/job-search-new/job-search-new.component';
import {JobManagementUserSearchesComponent} from '../jobs/pages/job-management/job-management-user-searches/job-management-user-searches.component';
import {TalentAddToJobHeaderComponent} from '../talent/headers/talent-add-to-job-header/talent-add-to-job-header.component';
import {QuickSearchInputComponent} from 'src/app/shared/components/quick-search-input/quick-search-input.component';
import {QuickSearchTalentSelectedComponent} from 'src/app/shared/components/quick-search-talent-selected/quick-search-talent-selected.component';
import {QuickSearchSelectedHeaderComponent } from 'src/app/shared/components/quick-search-selected-header/quick-search-selected-header.component';
import {QuickSearchCardComponent} from 'src/app/shared/components/quick-search-card/quick-search-card.component'
import { AttachmentListComponent } from 'src/app/shared/components/attachment-list/attachment-list.component';
import { JobFilterPaneComponent } from 'src/app/shared/components/job-filter-pane/job-filter-pane.component';
import { AddFiltersComponent } from 'src/app/shared/components/add-filters/add-filters.component';
import { HighlightTextPipe } from 'src/app/shared/pipes/highlight-text/highlight-text.pipe';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AngularResizedEventModule } from 'angular-resize-event';
import {HighlightTextDirective} from '../../shared/directives/highlightText/highlight-text.directive';


@NgModule({
  declarations: [
    PillButtonComponent,
    TimeSelectorComponent,
    SkillPillComponent,
    NotFoundComponent,
    SidebarComponent,
    JobDetailsModalComponent,
    CancelInterviewModalComponent,
    FormErrorComponent,
    ConfirmModalComponent,
    AttachmentOptionsModalComponent,
    TalentCardComponent,
    AttachmentCardInternalComponent,
    ToastComponent,
    TalentProcessComponent,
    ProcessStepperComponent,
    NotFitModalComponent,
    QuillEditorComponent,
    CkeditorComponent,
    UserPrefsMenuComponent,
    JobActionMenuComponent,
    ActivityFeedComponent,
    LogCallModalComponent,
    VerticalAlignComponent,
    SendEmailModalComponent,
    DupTalentModalComponent,
    JobDetailsPaneComponent,
    VmsJobDetailsPaneComponent,
    SplitPanelComponent,
    SendTextModalComponent,
    RecruitmentPhasesComponent,
    ReviewAccountManagerComponent,
    PrescreeningComponent,
    VirtualInterviewComponent,
    ReviewHiringManagerComponent,
    ShortlistCardListComponent,
    InterviewHiringManagerComponent,
    OfferComponent,
    OnboardingComponent,
    ShortlistedComponent,
    PrescreeningQuestionsComponent,
    ManualPrescreeningResultsComponent,
    FileUploadModalComponent,
    TalentResumeComponent,
    InterviewModalComponent,
    LogInterviewModalComponent,
    LogFeedbackModalComponent,
    ActivityDetailModalComponent,
    LogNoteModalComponent,
    AddToJobComponent,
    AddToJobV2Component,
    TalentHistoryComponent,
    TalentHistoryFeedComponent,
    TalentProfileComponent,
    UserCircleComponent,
    FeatureUnavailableModalComponent,
    ConversationComponent,
    TalentDetailPaneComponent,
    MassActionPaneComponent,
    TalentResumeModalComponent,
    SnippetPipe,
    BoolstringPipe,
    ArrayPipe,
    StringCleanPipe,
    SafeHtmlPipe,
    SafeStylePipe,
    SearchPipe,
    FormatPhonePipe,
    ABDatePipe,
    ABDatetimePipe,
    ABTimePipe,
    HighlightTextPipe,
    FullNamePipe,
    InitialsPipe,
    PossessivePipe,
    InViewportDirective,
    ClickOutsideDirective,
    HighlightTextDirective,
    DisableControlDirective,
    IndeterminateCheckboxDirective,
    RemoveMeridianDirective,
    LimitHtmlDirective,
    HideChevronDirective,
    JobOrderCardComponent,
    ShortlistCardContentComponent,
    JobsFilterComponent,
    JobsFilterNewComponent,
    JobsFilterFormComponent,
    JobSearchHeaderComponent,
    JobSearchNewHeaderComponent,
    JobManagementHeaderComponent,
    JobOrderHeaderComponent,
    JobDetailsHeaderComponent,
    VmsDashboardHeaderComponent,
    TalentManagementListingsHeaderComponent,
    TalentManagementHeaderComponent,
    TalentManagementSearchHeaderComponent,
    UserManagementHeaderComponent,
    SettingsHeaderComponent,
    AdManagementModalComponent,
    AssignRecruiterModalComponent,
    SendUserEmailModalComponent,
    UpdateJobStatusModalComponent,
    CloseJobModalComponent,
    ExtendJobModalComponent,
    ChangeOwnerModalComponent,
    ActivityFeedActionComponent,
    RecruiterInterviewManualQuestionsComponent,
    UserLookupComponent,
    CardComponent,
    NotAFitModalComponent,
    ListDetailsHeaderComponent,
    RecActivityListDetailsHeaderComponent,
    EditListModalComponent,
    ListInfoComponent,
    RemoveFromListModalComponent,
    DeleteListModalComponent,
    AddTalentModalComponent,
    RecruitmentListCardComponent,
    AddToListAModalComponent,
    JobOrderInputComponent,
    RejectOfferModalComponent,
    PlatformAlertBannerComponent,
    BannerLearnMoreModalComponent,
    SharedUserListsComponent,
    RecruiterHierarchySuggestModalComponent,
    RecruiterHierarchyFilterDropdownComponent,
    DistributionListLookupComponent,
    EmailRecipientsPillComponent,
    PersonalDetailsModalComponent,
    JobViewChangerComponent,
    CardViewComponent,
    SchedAppointmentModalComponent,
    SchedAppointmentInterviewModalComponent,
    AppointmentSettingsComponent,
    AppointmentEmailComponent,
    ManageRolesComponent,
    ManageRolesModalComponent,
    VmsSearchModalComponent,
    SubmitToAmModalComponent,
    BookmarkComponent,
    BookmarkCardComponent,
    RemoveAllModalComponent,
    SubmitToClientModalComponent,
    EmailMultiInputComponent,
    EmailNameMultiInputComponent,
    FileInputComponent,
    MassEmailModalComponent,
    TalentCreationHeaderComponent,
    CancelCreationModalComponent,
    MassPrescreenModalComponent,
    GeoLocationSuggestComponent,
    TalentProcessCtaComponent,
    AttachFilesComponent,
    AddUrlModalComponent,
    AddFilesModalComponent,
    MassPrescreenModalComponent,
    DeleteAttachmentModalComponent,
    TalentMetricsComponent,
    TalentMetricsModalComponent,
    CautionModalComponent,
    MassSmsModalComponent,
    TalentActionBarComponent,
    JobSearchNewComponent,
    JobManagementUserSearchesComponent,
    TalentAddToJobHeaderComponent,
    QuickSearchInputComponent,
    QuickSearchTalentSelectedComponent,
    QuickSearchSelectedHeaderComponent,
    QuickSearchCardComponent,
    AttachmentListComponent,
    JobFilterPaneComponent,
    AddFiltersComponent
  ],
  imports: [
    CommonModule,
    ThemeModule.forRoot({
      themes: [lightTheme, darkTheme],
      active: 'light'
    }),
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    VirtualScrollerModule,
    NgPipesModule,
    CustomFormsModule,
    // DatetimePopupModule,
    QuillModule,
    NgxSpinnerModule,
    ChartsModule,
    TimepickerModule.forRoot(),
    ButtonsModule.forRoot(),
    BsDropdownModule.forRoot(),
    SidebarModule.forRoot(),
    TypeaheadModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    PaginationModule.forRoot(),
    CollapseModule.forRoot(),
    NgbModule,
    NouisliderModule,
    ScrollingModule,
    InViewportModule,
    NgMasonryGridModule,
    CKEditorModule,
    AngularMultiSelectModule,
    AngularResizedEventModule,
    TranslateModule,
    PdfJsViewerModule,
    ContextMenuModule.forRoot({
      useBootstrap4: true,
    }),
    FileUploadModule,
    CarouselModule.forRoot(),
    AccordionModule.forRoot(),
  ],
  providers: [
    ModalService,
    ResolveService,
    ThemeService,
    ApptInviteService
  ],
  entryComponents: [
    CancelInterviewModalComponent,
    SidebarComponent,
    JobDetailsModalComponent,
    JobSearchHeaderComponent,
    JobSearchNewHeaderComponent,
    ConfirmModalComponent,
    AttachmentOptionsModalComponent,
    JobManagementHeaderComponent,
    JobOrderHeaderComponent,
    JobDetailsHeaderComponent,
    VmsDashboardHeaderComponent,
    NotFitModalComponent,
    FileUploadModalComponent,
    TalentResumeComponent,
    InterviewModalComponent,
    LogInterviewModalComponent,
    LogFeedbackModalComponent,
    ActivityDetailModalComponent,
    LogNoteModalComponent,
    AddToJobComponent,
    AddToJobV2Component,
    SchedAppointmentModalComponent,
    SchedAppointmentInterviewModalComponent,
    TalentResumeModalComponent,
    FeatureUnavailableModalComponent,
    TalentManagementListingsHeaderComponent,
    TalentManagementHeaderComponent,
    TalentManagementSearchHeaderComponent,
    UserManagementHeaderComponent,
    SettingsHeaderComponent,
    AdManagementModalComponent,
    AssignRecruiterModalComponent,
    SendUserEmailModalComponent,
    UpdateJobStatusModalComponent,
    CloseJobModalComponent,
    ExtendJobModalComponent,
    ChangeOwnerModalComponent,
    NotAFitModalComponent,
    ListDetailsHeaderComponent,
    RecActivityListDetailsHeaderComponent,
    EditListModalComponent,
    RemoveFromListModalComponent,
    DeleteListModalComponent,
    AddTalentModalComponent,
    RecruitmentListCardComponent,
    AddToListAModalComponent,
    RejectOfferModalComponent,
    BannerLearnMoreModalComponent,
    SubmitToAmModalComponent,
    DupTalentModalComponent,
    PersonalDetailsModalComponent,
    ManageRolesModalComponent,
    VmsSearchModalComponent,
    RecruiterHierarchySuggestModalComponent,
    RemoveAllModalComponent,
    SubmitToClientModalComponent,
    MassEmailModalComponent,
    TalentCreationHeaderComponent,
    CancelCreationModalComponent,
    GeoLocationSuggestComponent,
    MassPrescreenModalComponent,
    TalentMetricsModalComponent,
    AttachFilesComponent,
    AddUrlModalComponent,
    AddFilesModalComponent,
    DeleteAttachmentModalComponent,
    CautionModalComponent,
    MassSmsModalComponent,
    JobSearchNewComponent,
    JobManagementUserSearchesComponent,
    TalentAddToJobHeaderComponent,
    QuickSearchInputComponent,
    QuickSearchTalentSelectedComponent,
    QuickSearchSelectedHeaderComponent,
    QuickSearchCardComponent,
    AttachmentListComponent,
    JobFilterPaneComponent,
    AddFiltersComponent
  ],
  exports: [
    CancelInterviewModalComponent,
    ShortlistCardContentComponent,
    ShortlistCardListComponent,
    PillButtonComponent,
    TimeSelectorComponent,
    SkillPillComponent,
    JobsFilterComponent,
    JobsFilterNewComponent,
    JobsFilterFormComponent,
    ConfirmModalComponent,
    AttachmentOptionsModalComponent,
    NotFoundComponent,
    SidebarComponent,
    FormErrorComponent,
    TalentCardComponent,
    AttachmentCardInternalComponent,
    JobDetailsPaneComponent,
    VmsJobDetailsPaneComponent,
    SplitPanelComponent,
    ToastComponent,
    TalentProcessComponent,
    NotFitModalComponent,
    JobDetailsModalComponent,
    QuillEditorComponent,
    CkeditorComponent,
    UserPrefsMenuComponent,
    JobActionMenuComponent,
    ActivityFeedComponent,
    LogCallModalComponent,
    VerticalAlignComponent,
    SendEmailModalComponent,
    JobOrderCardComponent,
    SendTextModalComponent,
    RecruitmentPhasesComponent,
    ReviewAccountManagerComponent,
    PrescreeningComponent,
    VirtualInterviewComponent,
    ReviewHiringManagerComponent,
    InterviewHiringManagerComponent,
    OfferComponent,
    OnboardingComponent,
    ShortlistedComponent,
    PrescreeningQuestionsComponent,
    FileUploadModalComponent,
    TalentResumeComponent,
    InterviewModalComponent,
    LogInterviewModalComponent,
    LogFeedbackModalComponent,
    ActivityDetailModalComponent,
    LogNoteModalComponent,
    AddToJobComponent,
    AddToJobV2Component,
    TalentHistoryComponent,
    TalentHistoryFeedComponent,
    TalentProfileComponent,
    UserCircleComponent,
    FeatureUnavailableModalComponent,
    ConversationComponent,
    TalentDetailPaneComponent,
    MassActionPaneComponent,
    TalentResumeModalComponent,
    SnippetPipe,
    BoolstringPipe,
    ArrayPipe,
    SearchPipe,
    StringCleanPipe,
    SafeHtmlPipe,
    SafeStylePipe,
    FormatPhonePipe,
    ABDatePipe,
    ABDatetimePipe,
    ABTimePipe,
    HighlightTextPipe,
    FullNamePipe,
    InitialsPipe,
    PossessivePipe,
    InViewportDirective,
    ClickOutsideDirective,
    HighlightTextDirective,
    DisableControlDirective,
    IndeterminateCheckboxDirective,
    RemoveMeridianDirective,
    LimitHtmlDirective,
    HideChevronDirective,
    CommonModule,
    ThemeModule,
    FormsModule,
    ReactiveFormsModule,
    VirtualScrollerModule,
    NgPipesModule,
    CustomFormsModule,
    TimepickerModule,
    // DatetimePopupModule,
    QuillModule,
    NgxSpinnerModule,
    ButtonsModule,
    BsDropdownModule,
    SidebarModule,
    TypeaheadModule,
    ModalModule,
    TabsModule,
    BsDatepickerModule,
    TooltipModule,
    PopoverModule,
    PaginationModule,
    CollapseModule,
    NouisliderModule,
    NgbModule,
    RouterModule,
    ScrollingModule,
    InViewportModule,
    ProcessStepperComponent,
    AdManagementModalComponent,
    CardComponent,
    AssignRecruiterModalComponent,
    SendUserEmailModalComponent,
    UpdateJobStatusModalComponent,
    CloseJobModalComponent,
    ExtendJobModalComponent,
    NotAFitModalComponent,
    UserLookupComponent,
    RecruitmentListCardComponent,
    JobOrderInputComponent,
    PlatformAlertBannerComponent,
    TranslateModule,
    BannerLearnMoreModalComponent,
    TranslateModule,
    SharedUserListsComponent,
    SubmitToAmModalComponent,
    PersonalDetailsModalComponent,
    JobViewChangerComponent,
    CardViewComponent,
    SubmitToAmModalComponent,
    AngularMultiSelectModule,
    ManageRolesComponent,
    ManageRolesModalComponent,
    VmsSearchModalComponent,
    AngularMultiSelectModule,
    BookmarkComponent,
    BookmarkCardComponent,
    RemoveAllModalComponent,
    SubmitToClientModalComponent,
    MassEmailModalComponent,
    EmailMultiInputComponent,
    EmailNameMultiInputComponent,
    CancelCreationModalComponent,
    MassPrescreenModalComponent,
    GeoLocationSuggestComponent,
    AttachFilesComponent,
    AddUrlModalComponent,
    AddFilesModalComponent,
    MassPrescreenModalComponent,
    DeleteAttachmentModalComponent,
    MassSmsModalComponent,
    CautionModalComponent,
    TalentActionBarComponent,
    JobSearchNewComponent,
    JobManagementUserSearchesComponent,
    QuickSearchCardComponent,
    AttachmentListComponent,
    JobFilterPaneComponent,
    AddFiltersComponent
  ]
})
export class SharedModule {
}
