import { Component, Input, OnInit } from '@angular/core';
import { Application } from '../../../../../../models/external/application.model';

@Component({
  selector: 'recruiter-interview-manual-questions',
  templateUrl: './recruiter-interview-manual-questions.component.html',
  styleUrls: ['./recruiter-interview-manual-questions.component.scss']
})
export class RecruiterInterviewManualQuestionsComponent implements OnInit {

  @Input() application: Application;

  constructor() { }

  ngOnInit() {
  }

}
