export const NsoHubOptions = [
    "BAC/Charlotte",
    "Cedar Rapids Area",
    "Connecticut Area",
    "Denver & San Francisco Area",
    "Lower Midwest Area",
    "Milwaukee Area",
    "New England Area",
    "New Jersey Area",
    "New York City Area",
    "NSO Southeast / Walmart",
    "Phoenix Area",
    "Portland, OR & Seattle Area",
    "Raleigh",
    "Rochester Area",
    "Sacramento Area",
    "San Jose Area",
    "SD Mid-Atlantic Area",
    "SoCal Area",
    "Southeast",
    "Southeast CLT Area",
    "Texas Area",
    "Upper Midwest Area",
    "Wells/Charlotte"
  ]

//angular2-multiselect static fields for dropdown values
export interface DropdownFilter {
    id: any;
    itemName: string;
}

//Introduces new type field for use in dropdown filters
export interface SourceTypeDropdownFilter extends DropdownFilter {
    type: string;
}

export interface JobFilter {
    value: string | number | boolean;
    field: string;
    type: string;
    lob?: string[];
    display?: string;
    service_line_type?: string;
}

export const DateFrom: JobFilter[]  = [
    { value: 1, display: "Past 1 day", field: 'allbirds_metadata.draft_creation_date', type: 'number' },
    { value: 7, display: "Past 7 days", field: 'allbirds_metadata.draft_creation_date', type: 'number' },
    { value: 14, display: "Past 14 days", field: 'allbirds_metadata.draft_creation_date', type: 'number' },
    { value: 21, display: "Past 21 days", field: 'allbirds_metadata.draft_creation_date', type: 'number' },
    { value: 30, display: "Past 30 days", field: 'allbirds_metadata.draft_creation_date', type: 'number' },
    { value: 60, display: "Past 60 days", field: 'allbirds_metadata.draft_creation_date', type: 'number' },
    { value: 90, display: "Past 90 days", field: 'allbirds_metadata.draft_creation_date', type: 'number' },
    { value: 180, display: "Past 180 days", field: 'allbirds_metadata.draft_creation_date', type: 'number' },
    { value: 270, display: "Past 270 days", field: 'allbirds_metadata.draft_creation_date', type: 'number' },
    { value: 365, display: "Past 1 year", field: 'allbirds_metadata.draft_creation_date', type: 'number' },
]

export const JobFilterFields: string[] = [
    'jobTitle',
    'businessUnits',
    'lobs',
    'keyword',
    'city',
    'state',
    'zip',
    'location',
    'latLong',
    'distance',
    'customerName',
    'hiringManager',
    'dateFrom',
    'skills',
    'jobOwner',
    'employmentType',
    'candidateType',
    'jobStatus',
    'remoteWork',
    'nsos',
    'orderType'
]

export interface GeolocationItem {
    cityName: string;
    latitude: number;
    longitude: number;
    stateAbbr: string;
    stateName: string;
    zipCode: string;
}

export enum OrderType {
    PIPELINE = 'Pipeline',
    CLIENT = 'Client Order',
}
