import {ConfirmModalConfig} from '../../../confirm-modal/confirm-modal.interface'
import {ConfirmModalComponent} from '../../../confirm-modal/confirm-modal.component'
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { Injectable } from '@angular/core';
import {TalentProcessService} from "../../../../services/talent-process/talent-process.service";
import {Router} from "@angular/router";
import {Profile} from "../../../../models/external/profile.model";
import {AuthService} from "../../../../services/auth/auth.service";

@Injectable({
  providedIn: 'root',
})
export class RecruitmentPhasesService{
    constructor(
        private _modalService: ModalService,
        private _talentProcess: TalentProcessService,
        private _router: Router,
        private _auth: AuthService
    ){}

    talentInterviewStatus = false

    checkTalentFOTalentStatus (profile:any){
        let FOTalentStatus = profile?.allbirds_metadata.FOTalentStatus
        if(FOTalentStatus === "INTERVIEWING"){
          this.talentInterviewStatus = true
        } else{
          this.talentInterviewStatus = false
        }
    }

    // this was moved here since we need to call this in different steps of the recruitment process
  isProfileValid(): Boolean | string[] {
    let invalidFields = [];
    const profile: Profile = this._talentProcess.selectedProfileSubject.value;
    const {
      phoneNumbers,
      addresses,
      workAuthorizations,
      employmentRecords,
      workPreference,
      vendorCompanyName,
      vendorCompanyRandstadApproved,
      transferToDbc,
      dateAvailable,
      yearsExperience
    } = profile || {};
    let isVendor = false;

    // phone number and phone type
    if (!phoneNumbers || !phoneNumbers.length) {
      invalidFields.push('phoneNumbers');
    } else {
      const validPhone = phoneNumbers.find((phone: any) => phone.number && (phone.type === 'MOBILE'));
      if (!validPhone) {
        invalidFields.push('phoneNumbers');
      }
    }
    // address and address parts
    if (addresses && addresses.length) {
      const currAddr = addresses.find((addr) => addr.current && addr.current.value === true);
      if (!currAddr) {
        invalidFields = [...invalidFields, 'locality', 'administrativeArea', 'postalCode'];
      } else {
        if (!currAddr.structuredAddress.locality) {
          invalidFields.push('locality');
        }
        if (!currAddr.structuredAddress.administrativeArea) {
          invalidFields.push('administrativeArea');
        }
        if (!currAddr.structuredAddress.postalCode) {
          invalidFields.push('postalCode');
        }
      }
    } else {
      invalidFields.push('address');
    }
    // RT SPECIFIC
    if (this._auth?.user?.Source?.checkLob('RT', 'RE','CR')) {
      // employee type and related fields
      if (workPreference && workPreference.employeeTypes && workPreference.employeeTypes.length) {
        if (workPreference.employeeTypes[0] === 'Third_Party') {
          isVendor = true;
          if (!vendorCompanyName) {
            invalidFields.push('vendorCompanyName');
          }
          if (!vendorCompanyRandstadApproved) {
            invalidFields.push('vendorCompanyRandstadApproved');
          }
          if (transferToDbc === '' || transferToDbc === undefined || transferToDbc === null) {
            invalidFields.push('transferToDbc');
          }
        } else if (workPreference.employeeTypes[0] === 'Randstad') {
          if (!workAuthorizations || !workAuthorizations.length) {
            invalidFields.push('workAuthorizations');
          }
        } else if (workPreference.employeeTypes[0] === 'unknown') {
          invalidFields.push('employeeTypes');
        }
        // removed else invalid fields to handle Independent Contractor
      } else {
        invalidFields.push('employeeTypes');
      }

      // experience level
      if (!yearsExperience) {
        invalidFields.push('yearsExperience');
      }

      // work authorization
      if (!workAuthorizations || !workAuthorizations.length) {
        if (!isVendor) {
          invalidFields.push('workAuthorizations');
        }
      }
    }
    // desired location
    if (workPreference && workPreference.jobAddresses && workPreference.jobAddresses.length) {
      if (
        !workPreference.jobAddresses[0].structuredAddress ||
        !workPreference.jobAddresses[0].structuredAddress.administrativeArea
      ) {
        invalidFields.push('desiredLocation');
      }
    } else {
      invalidFields.push('desiredLocation');
    }
    // date available
    if (!dateAvailable) {
      invalidFields.push('dateAvailable');
    }
    // current company and job title
    if (employmentRecords && employmentRecords.length) {
      const currentOrFirstEmployer = this.getCurrentOrFirstEmployer();
      const allCurrent = employmentRecords.filter((workHist) => workHist.isCurrent?.value === true);
      if (currentOrFirstEmployer) {
        if (!currentOrFirstEmployer.employmentRecord.employerName) {
          invalidFields.push('employerName');
        }
        if (!currentOrFirstEmployer.employmentRecord.jobTitle) {
          invalidFields.push('jobTitle');
        }
        if (!currentOrFirstEmployer.isCurrent) {
          invalidFields.push('currentEmployer');
        }
      } else {
        invalidFields.push('employerName');
        invalidFields.push('jobTitle');
        invalidFields.push('currentEmployer');
      }
      if (allCurrent && allCurrent.length > 1) {
        invalidFields.push('currentEmployer');
      }
    } else {
      invalidFields.push('employerName');
      invalidFields.push('jobTitle');
      invalidFields.push('currentEmployer');
    }

    console.log('invalid fields: ', invalidFields);
    return invalidFields.length ? invalidFields : true;
  }

  getCurrentOrFirstEmployer() {
    const profile = this._talentProcess.selectedProfileSubject.value;
    if (profile) {
      const {employmentRecords} = profile;
      if (employmentRecords && employmentRecords.length) {
        const currentEmployer = employmentRecords.find(rec => rec.isCurrent?.value === true);
        return currentEmployer
          ? {employmentRecord: currentEmployer, isCurrent: true}
          : {employmentRecord: employmentRecords[0], isCurrent: false};
      }
    }
    return null;
  }

    openWarningModal() {
        const config: ConfirmModalConfig = {
            modalTitleHtml: `<div class="flex-row align-items-center"><img src="/assets/icons/alert-yellow.svg" class="mr-2 h-100"><span>Caution</span></div>`,
            modalTextContent: `This talent is interviewing status for another job. Do you want to proceed?`,
            confirmButtonLabel: 'Close',
            hideCancelButton:true,
            confirmHandler: () => {
              this.talentInterviewStatus= false
            }
          };
          this._modalService.show(ConfirmModalComponent, {initialState: config});
    }

    // moved here to be called in different process steps
    openMissingProfileDataModal(numberOfFieldsWithError: number) {
      console.log("this trigger the modal")
      const config: ConfirmModalConfig = {
        modalTitleHtml: `<div class="flex-row align-items-center"><img src="/assets/icons/alert-yellow.svg" class="mr-2 h-100"><span>Missing talent information</span></div>`,
        modalTextContent: `The talent profile must be complete before submitting to the account manager. Please enter the missing talent information (${numberOfFieldsWithError} fields) to continue.`,
        confirmButtonLabel: 'edit profile',
        cancelButtonLabel: 'cancel',
        confirmHandler: () => {
          const profile = this._talentProcess.selectedProfileSubject.value;
          this._router.navigate([`/talent/edit`], {
            queryParams: {
              talentId: profile.profileId,
              isSubmission: true
            }
          });
        }
      };
      this._modalService.show(ConfirmModalComponent, {initialState: config});
    }

    // kept this logic and added this here to be used in different process steps
    checkIfProfileIsDataValid(): Boolean | string[] {
      if (this._auth?.user?.Source?.checkLob('RT', 'RE','CR')) {
        const isProfileValid = this.isProfileValid();
        if (Array.isArray(isProfileValid)) {
          this.openMissingProfileDataModal(isProfileValid.length);
        } else {
          return true;
        }
      } else {
        console.log('By passed talent validations checks - should be RGS Talent.')
        return true;
      }
    }
}
