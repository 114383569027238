import { Component, OnInit, Input,  TemplateRef,  ViewChild  } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';


import { FormValidationService } from 'src/app/shared/services/form-validation/form-validation.service';
import { CKEditor4 } from 'ckeditor4-angular';
import { ModalService } from 'src/app/shared/services/modal/modal.service';



import { ApiService } from '../../services/api/api.service';
import { MassEmail } from '@allbirds-ui/allbirds-types/dist/types/MassEmail';

import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { ToastService, ToastClass } from 'src/app/shared/services/toast';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { EmailInput, EmailIssueInfo, EMAIL_ISSUE } from 'src/app/shared/models/internal/mass-email.model';
import { MassOperationRecipientService, Recipients } from './mass-operation-recipient.service';
import { Router } from '@angular/router';
import { MASS_OPERATION_CONTEXT, MASS_ACTION_TYPE } from './mass-operation-context';
import {LoadingSpinnerService} from '../../services/loading-spinner/loading-spinner.service';




@Component({
  selector: 'app-mass-email-modal',
  templateUrl: './mass-email-modal.component.html',
  styleUrls: ['./mass-email-modal.component.scss']
})
export class MassEmailModalComponent implements OnInit {

  //enhanced profile or profile or application
  context: MASS_OPERATION_CONTEXT;

  // FormGroup where we actually pull the values for the request from.
  emailForm: FormGroup;

  // CKEditor config for the message input.
  emailCkConfig: CKEditor4.Config = {
    toolbar: [['Format', '-', 'Bold', 'BulletedList', 'Indent', 'Outdent', '-', 'Undo', 'Redo']]
  };

  //Email that are selected in bulk from the backend, final list after removing problematic ones
  finalEmailList : EmailInput[] = [];

  //Enables getting recipient data of the user easily as a map
  emailRecipientMap: Map<string, MassEmail.RecipientData> = new Map<string,  MassEmail.RecipientData>();

  //From email
  fromEmail: string;

  //Emails which cant be sent due to some problem
  problemusers: EmailIssueInfo[] = [];

  //Show Pills on the modal. Start with false
  showPills: boolean = false;

  //Show the cant mail list of users
  showCantMailDetails: boolean = false;

  //Cant mail verbiage
  cantMailVerbiage: string = "Show details";


  @ViewChild('childModal') childModal: ModalDirective;
  errorModalTitle: string;
  errorModalDesc: string;

  maxEmails: number;
  exceedingCount: number;

  // Determines which TemplateRef to show on the UI.
  shownView: TemplateRef<any>;

  // "Send with email" view template.
  @ViewChild('composeView', { static: true }) composeView: TemplateRef<any>;

  // Shown when 'send with email' is selected but no signature is set.
  @ViewChild('noSignatureView', { static: true }) noSignatureView: TemplateRef<any>;

  trVariable1: any;

  //prevent multiple submissions
  isBusy: boolean = false;

  constructor(
    public _bsModalRef: BsModalRef,
    private _fb: FormBuilder,
    private _bsModalService: ModalService,
    private _api: ApiService,
    private _auth: AuthService,
    private _toast: ToastService,
    private _recipientService: MassOperationRecipientService,
    private _router: Router,
    private _loading: LoadingSpinnerService,

  ) { }

  ngOnInit() {

    this.init();
    this.setShownView();
    this.setupForm();


  }

  ngOnDestroy() {
    this._recipientService.clearSelections();
  }

  async init() {
    this._loading.show();
    const result =  await this._recipientService.initialize(this.context, MASS_ACTION_TYPE.email);
    const emailResult = result as Recipients;
    this.finalEmailList = emailResult.emailList;
    this.emailRecipientMap = emailResult.recipients;
    this.problemusers = result.issue;
    this.fromEmail = this._auth && this._auth.user && this._auth.user.EmailAddr || "";
    this.trVariable1 = {"value" : this.problemusers.length}
    this.setupForm();
    this._loading.hide();

  }

  getProblemInfo(email: string, name: string, issue: EMAIL_ISSUE) {
    let info: EmailIssueInfo = {
      email: email,
      name: name,
      issue: issue
    }
    return info;
  }

  /**
   * Sets which view to show based on if we want compose view or not. Also triggers
   * the setup of the form since they're different for the two views.
   * @param isComposeView
   */
  setShownView() {
    this.shownView = (
      this._auth.user &&
      this._auth.user.Preferences &&
      this._auth.user.Preferences.EmailSignature &&
      this._auth.user.Preferences.EmailSignature.length
    ) ? this.composeView : this.noSignatureView;
  }

  getUserSignature(): string {
    let signature = '';
    const { user } = this._auth;

    if (user && user.Preferences && user.Preferences.EmailSignature && user.Preferences.EmailSignature.length) {
      signature = user.Preferences.EmailSignature;
    }
    return signature;
  }

  getDefaultMailBody(): string {
    let defaultBody = "<p>Hi {{firstname}},</p>\n\n<p></p>\n\n" + this.getUserSignature();
    return defaultBody;
  }

  handleExpandPill(expandPill: boolean) {
    this.showPills = true
  }

  togglePills() {
    this.showPills = false;
  }

  setupForm(): void {

    this.emailForm = this._fb.group({
      from: [this._auth.user.EmailAddr, [Validators.required, FormValidationService.validateNonEmptyString]],
      to: [this.finalEmailList, [Validators.required, FormValidationService.validateNonEmptyArray]],
      emailsub: ['', [Validators.required, FormValidationService.validateNonEmptyTrimmedString]],
      message: [this.getDefaultMailBody(), [Validators.required]]
    });

  }

  handleEmailInput(control: AbstractControl, input: string): void {
    FormValidationService.clearErrors(control);
    control.patchValue((control.value && control.value.length)
      ? control.value.concat([input])
      : [input]
    );
  }

  handleEmailRemove(control: AbstractControl, input: string): void {
    control.patchValue((control.value && control.value.length)
      ? control.value.filter((email: string) => email !== input)
      : []
    );

  }

  submitMassEmail() {

    FormValidationService.touchAllFields(this.emailForm);
    const emailBody = JSON.stringify(this.emailForm.controls.message.value.trim());
    const defaultMailBody = JSON.stringify(this.getDefaultMailBody().trim());

    if (emailBody === defaultMailBody) {
      FormValidationService.setError(this.emailForm.controls.message, 'shouldBeUpdated');
      this.isBusy = false;
    }


    if (this.emailForm.valid) {
      let maxEmails = 1000;
      if(this._auth.user.Source.checkLob('RT', 'RE','CR')) maxEmails = 50;
      const curSelectedEmails: EmailInput[] = Array.from(this.emailForm.controls['to'].value);
      this.exceedingCount = curSelectedEmails.length - maxEmails;
      const htmlContent = '<div>' + this.emailForm.controls['message'].value +
      '<br>{{unsubscribeFooter}}' + '</div>';

      if(curSelectedEmails.length > maxEmails) {
        this.errorModalTitle = 'Selection limit exceeded';
        this.errorModalDesc = 'The maximum limit of ' + maxEmails + ' candidates for mass ' +
          'emailing has been exceeded by ' + this.exceedingCount;
        this.childModal.show();
        return;
      }
      else if(new Blob([htmlContent]).size > 31900 ) { // 32 KB is the limit that iterable can handle.
              //Assuming firstname, etc might takes at the most extra 100 bytes
        this.errorModalTitle = 'Email size limit exceeded';
        this.errorModalDesc = 'The maximum body size limit of 32KB email exceeded by ' +
          ((new Blob([htmlContent]).size - 31900) + ' bytes');
        this.childModal.show();

        return;
      }
      else {
        this.isBusy = true;
        const body: MassEmail.MassEmailRequest = this.getRequestBody(htmlContent);
        const massEmailResult = this._api.postMassEmail(body).subscribe(
          res => {
            const trVariable = { 'value': curSelectedEmails.length};
            this._toast.showToastWithVariables ('mass-email.success', trVariable);
            this._bsModalRef.hide();
            this.isBusy = false;
          },
          error => {
            const trVariable = { 'value': error};
            this._toast.showToastWithVariables ('mass-email.failure', trVariable, { cssClass: ToastClass.DANGER });
            this._bsModalRef.hide();
            this.isBusy = false;
          }

        );
      }
    }

  }

  hideChild() {
    this.childModal.hide();
  }
  /**
   * Adding unsubscribed footer to the body of the email as per API request
   */
  getRequestBody(bodyContent : string) {
    const curSelectedEmails: EmailInput[] = Array.from(this.emailForm.controls['to'].value);
    const curRecipientData: MassEmail.RecipientData[] = [];
    curSelectedEmails.forEach(inp =>{
      curRecipientData.push(this.emailRecipientMap.get(inp.email));
    });

    let context : MassEmail.MassOperationContext = <MassEmail.MassOperationContext> <unknown> this.context;

    let req: MassEmail.MassEmailRequest = {
      'body': bodyContent,
      'senderData': {
        'email': this._auth.user.EmailAddr,
        'oprId': this._auth.user._id,
        'firstName': this._auth.user.FirstName,
        'lastName': this._auth.user.LastName,
        'lob': this._auth.user.Source,
        'businessUnit': this._auth.user.BusinessUnit
      },
      'subject': this.emailForm.controls['emailsub'].value,
      'recipientData' : curRecipientData,
      'context': context,
    };

    return req;

  }


  showDetailCantMail() {
    this.showCantMailDetails = !this.showCantMailDetails;
    if(this.showCantMailDetails) {
      this.cantMailVerbiage = "Hide details";
    }
    else {
      this.cantMailVerbiage = "Show details";
    }

  }

   /**
   * Closes the modal & navigates to the email settings page.
   */
  goToEmailSettings(): void {
    this._bsModalRef.hide();
    this._router.navigate(['/settings/email']).catch(console.error);
  }



}



