import { Activities } from '@allbirds-ui/allbirds-types';
import { createModel, Mapping, Model } from './model';
import { IsoToMoment } from './serializers/moment.serializer';
import { Moment } from 'moment';
import { Serial } from './decorators/serial.decorator';

export import RawActivity = Activities.Activity;

class ActivityNoteMapping extends Mapping<RawActivity>  {
  @Serial(IsoToMoment) timestamp?: Moment;
  @Serial(IsoToMoment) activityTimestamp?: Moment;

}

export interface ActivityNote extends Model<ActivityNoteMapping> {}

export const ActivityNote = createModel<ActivityNote>(ActivityNoteMapping);
