import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PostalAddress } from '../../models/external/misc.model';
import { ApiService } from '../api/api.service';
import { extractIdFromName } from '../utility/formatters';
import { GoogleProfile } from '../../models/external/google-profile.model';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { EnhancedProfile } from '../../models/internal/enhanced-profile.interface';
import {UtilityService} from "../utility/utility.service";
import {ResolveService} from "../resolve/resolve.service";
import {Profile} from "../../models/external/profile.model";
import {Talent} from "../../models/external/talent.interface";

@Injectable({
  providedIn: 'root'
})
export class TalentService {
  // Resume errors.
  public resumeErrors: any = null;
  public resumeErrorObservable = new BehaviorSubject<any>(null);
  public parsedTalentResume = new BehaviorSubject<any>(null);
  public talentSelectionComplete = false;
  public showSearchOverlay = false;
  // public shouldBypassCache = false;

  constructor(
    private _api: ApiService,
    private _auth: AuthService,
    private _utility: UtilityService
  ) {
  }

  constructAddressString(sa: PostalAddress) {
    const address = `
      ${sa.addressLines[0] || ''}${sa.addressLines[0] ? ',' : ''}
      ${sa.locality || ''}${sa.locality ? ',' : ''}
      ${sa.administrativeArea || ''} ${sa.postalCode || ''} ${sa.regionCode || ''}`;
    return address;
  }

  clearResumeErrors() {
    this.resumeErrors = null;
    this.resumeErrorObservable.next(null);
  }

  setResumeError(errorKey: string): void {
    this.resumeErrors = Object.assign({}, this.resumeErrors, { [errorKey]: true });
    this.resumeErrorObservable.next(this.resumeErrors);
  }

  public clearTalentSelection() {
    this.talentSelectionComplete = false;
    this.showSearchOverlay = false;
  }

  buildExistingTalentEmailPayload(email: string): any {
    return {
      query: { email },
      searchKnownTalent: true,
      saveRecent: false,
      returnFields: ['normEmail', 'personNames', 'name', 'opco', 'externalId']
    };
  }

  evaluateEmailLookup(email: string, profiles: GoogleProfile[]): any {
    const result = {
      found: false,
      foundInLOB: false,
      talentNames: [] as string[],
      params: undefined as any
    }
    let foundInLobProfiles: any[] = [];

    if (!profiles || !profiles.length) return result;
    result.found = true;
    profiles.map(p => {
      //flag used to check if current profile is found in LOB
      let currentProfileFoundInLob = false;
      // check for LOB match
      if (this._auth.isOpco((p as unknown as EnhancedProfile).opco)) {
        result.foundInLOB = true;
        foundInLobProfiles.push(p);
        currentProfileFoundInLob = true;
      }
      // add name to output
      if (currentProfileFoundInLob) {
        result.talentNames.push(`${p.personNames[0].structuredName.preferredName ||
          p.personNames[0].structuredName.givenName} ${p.personNames[0].structuredName.familyName}`);
      }
    });

    // construct link
    result.params = this.constructSearchParamsForExistingEmail(email, result, foundInLobProfiles);
    return result;
  }

  constructSearchParamsForExistingEmail(email: string, result: any, foundInLobProfiles: any = null): any {
    let params = { searchType: 'known', input: {}, st: undefined as any };
    if (result.foundInLOB && foundInLobProfiles.length === 1) {
      const foundInLobProfile = foundInLobProfiles[0];
      params.input = JSON.stringify({ talentId: foundInLobProfile.externalId })
      params.st = extractIdFromName(foundInLobProfile.name)
    } else {
      params.input = JSON.stringify({ email })
    }
    return params;
  }

  constructSearchParamsForSuccessCreation(res: any): any {
    const talentId = res.payload.externalId;
    const name = res.payload.name

    let params = {
      searchType: 'known',
      input: JSON.stringify({ talentId: talentId }),
      st: extractIdFromName(name)
    }

    return params;
  }

  public checkForExistingTalentEmail(email: string) {
    return new Promise((resolve, reject) => {
      const payload = this.buildExistingTalentEmailPayload(email);
      this._api.searchProfiles(payload).subscribe(({ profiles }) => {
        resolve(this.evaluateEmailLookup(email, profiles));
      });
    });
  }

  setParsedTalentResume(talentResume: any, error = false) {
    if (error) {
      this.parsedTalentResume.next('error');
    } else if (talentResume) {
      this.parsedTalentResume.next(talentResume);
    } else {
      this.parsedTalentResume.next('');
    }
  }

  launchExternalProfile(userLob: string, profile: any) {
    const externalId: string = profile.randstad_process && profile.randstad_process.candidateFrontOfficeID ? profile.randstad_process.candidateFrontOfficeID : profile.externalId ? profile.externalId : null
    const isRT = userLob.checkLob("RT", "RE", "CR");
    const BH_URL =
      "https://cls42.bullhornstaffing.com/BullhornStaffing/OpenWindow.cfm?Entity=Candidate&id=";
    if (externalId && ((!isRT && externalId.includes("RGS")) || (isRT && externalId.includes("RT")))) {
      const foId = UtilityService.splitFrontOfficeId(externalId);
      if (foId) {
        if (isRT) {
          // Launch profile in Bullhorn.
          return window.open(`${BH_URL}${foId.idNumber}`, "_blank");
        } else {
          // Launch profile in RFO.
          ResolveService.goToRfo(
            `/EMPLOYEES.EMPLOYEE_DATA.GBL?EMPLID=${foId.idNumber}`
          ).catch((err) => {
            console.error(
              "[ResolveService] Error occurred trying to go to RFO",
              err
            );
          });
        }
      }
    } else if (
      !externalId.includes("RGS") &&
      !externalId.includes("RT")
    ) {
      return this._utility.launchFeatureUnavailableModal(
        "feature-unavailable.PROFILE_NO_EXTERNAL_ID"
      );
    } else {
      return this._utility.launchFeatureUnavailableModal(
        "feature-unavailable.NON_MATCHING_LOB"
      );
    }
  }

  launchRiskConnect(url:any){
    if(!url){
      return
    }
    const riskConnect_URL = url
    return window.open(`${riskConnect_URL}`,"_blank")
  }

}
