import { Applications } from '@allbirds-ui/allbirds-types';
import { createModel, Mapping, Model } from '../model';
import { IsoToMoment } from '../serializers/moment.serializer';
import { Moment } from 'moment';
import { Serial } from '../decorators/serial.decorator';
export import RawOffer = Applications.Offer;

class OfferMapping extends Mapping<RawOffer> {
  @Serial(IsoToMoment) offerDate?: Moment;
  skipped?: never;
  skippedDate?: never;
  skippedBy?: never;
}

export interface Offer extends Model<OfferMapping> {}

export const Offer = createModel<Offer>(OfferMapping);
