import { Injectable } from '@angular/core';
import {Appointments, Lists} from '@allbirds-ui/allbirds-types';
import {AllbirdsAppointment} from 'src/app/shared/models/external/appointment.model';
import {ListTalent} from 'src/app/shared/models/external/list-talent.model';
import {TalentListProfileInfo} from 'src/app/shared/models/external/process/talent-list-profile.model';
import {
  TalentMetricsTypeCount
} from '../components/talent-detail-pane/talent-metrics/talent-metrics.interface';
import user from './mock-data/user.json';
import rgsUser from './mock-data/user-rgs.json';
import crUser from './mock-data/user-cr.json';
import users from './mock-data/users.json';
import googleAccount from './mock-data/google-account.json';
import postings from './mock-data/postings.json';
import posting0 from './mock-data/posting0.json';
import recommendedTalent from './mock-data/recommended-talent.json';
import applications from './mock-data/applications.json';
import posting1 from './mock-data/posting1.json';
import application0 from './mock-data/application0.json';
import application1 from './mock-data/application1.json';
import talentSearch from './mock-data/talent-search.json';
import recentSearches from './mock-data/recent-searches.json';
import savedSearches from './mock-data/saved-searches.json';
import jobSearchQuery from './mock-data/jobSearchQuery.json';
import jobSearchFilter from './mock-data/jobSearchFilter.json';
import savedSearch from './mock-data/saved-search.json';
import structuredAddress from './mock-data/structured-address.json';
import structuredPhoneNumber from './mock-data/structured-phone-number.json';
import vmsJob from './mock-data/vms-job.json';
import { Application } from '../models/external/application.model';
import { Job } from '../models/external/job.model';
import { ElasticRecentSearch, ElasticSavedSearch } from '../services/talent-management/user-searches.types';
import { Phone, PostalAddress } from '../models/external/misc.model';
import profile from './mock-data/profile.json';
import googleProfile from './mock-data/googleProfile.json';
import rgsProfile0 from './mock-data/profile-rgs0.json';
import rgsProfile1 from './mock-data/profile-rgs1.json';
import rgsProfile2 from './mock-data/profile-rgs2.json';
import conversation from './mock-data/conversation.json';
import { User } from '../models/external/user.model';
import { Conversation } from '../models/external/conversation.model';
import { Profile } from '../models/external/profile.model';
import RecruitmentHistory from './mock-data/recruitment-history.json';
import RecruitmentMetrics from './mock-data/recruitment-metrics.json';
import listTalentAppointments from './mock-data/list-talent-appointment.json';
import jobFormMock from './mock-data/jobForm.json';
import TalentListProfiles from './mock-data/talent-list-profiles.json';
import AppointmentArray from './mock-data/appointments.json';
import attachment from './mock-data/attachment.json'
import { JobForm } from '../models/external/job-form.model';
import { GoogleProfile } from '../models/external/google-profile.model';
@Injectable({
  providedIn: 'root'
})
export class TestingDataService {
  constructor() { }

  get application0(): Application {
    return Application.deserialize(<any>application0);
  }

  get application1(): Application {
    return Application.deserialize(<any>application1);
  }

  get applications(): Application[] {
    return (<any[]>applications).map(Application.deserialize);
  }

  get appointments(): AllbirdsAppointment[] {
    return (<any[]>AppointmentArray).map(AllbirdsAppointment.deserialize);
  }

  get conversation(): Conversation {
    return Conversation.deserialize(<any>conversation);
  }

  get googleAccount() {
    return googleAccount;
  }

  get job0(): Job {
    return Job.deserialize(<any>posting0);
  }

  get job1(): Job {
    return Job.deserialize(<any>posting1);
  }

  get jobs(): Job[] {
    return (<any[]>postings).map(Job.deserialize);
  }

  get listTalentWithAppointments() {
    return listTalentAppointments.map((talent: any) => ListTalent.deserialize(talent));
  }

  get jobSearchQuery(){
    return jobSearchQuery;
  }

  get jobSearchFilter(){
    return jobSearchFilter;
  }
  
  get jobFormMock(){
    return JobForm.deserialize(<any>jobFormMock);
  }
  get recentSearches(): ElasticRecentSearch[] {
    return recentSearches.map(v => ElasticRecentSearch.deserialize(v));
  }

  get profile(): Profile {
    return Profile.deserialize(<any>profile);
  }

  get googleProfile(): GoogleProfile {
    return GoogleProfile.deserialize((<any>googleProfile));
  }
  // isDeleted flag is false (ProfileUniqueness)
  get rgsProfile0(): Profile {
    return Profile.deserialize((<any>rgsProfile0));
  }
  // isDeleted flag is true (ProfileUniqueness)
  get rgsProfile1(): Profile {
    return Profile.deserialize((<any>rgsProfile1));
  }

    // isDisqualifiedflag is true (ProfileUniqueness)
    get rgsProfile2(): Profile {
      return Profile.deserialize((<any>rgsProfile2));
    }

  get recruitmentMetrics(): {metrics: TalentMetricsTypeCount[]} {
    return RecruitmentMetrics;
  }

  get recruitmentHistory() {
    return RecruitmentHistory;
  }

  get recommendedTalent() {
    return recommendedTalent;
  }

  get savedSearch() {
    return savedSearch;
  }

  get savedSearches(): ElasticSavedSearch[] {
    return savedSearches.map(v => ElasticSavedSearch.deserialize(v));
  }

  get talentListProfiles() {
    return TalentListProfiles.map((v: any) => TalentListProfileInfo.deserialize(v));
  }

  get talentSearchResults() {
    return talentSearch;
  }

  get user(): User {
    return User.deserialize(<any>user);
  }
  get crUser(): User {
    return User.deserialize(<any>crUser);
  }
  
  get rgsUser(): User {
    return User.deserialize(<any>rgsUser);
  }

  get users(): User[] {
    return (<any[]>users).map(User.deserialize);
  }

  get structuredAddress(): PostalAddress {
    return <any>structuredAddress;
  }

  get structuredPhoneNumber(): Phone {
    return <Phone>structuredPhoneNumber;
  }

  get vmsJob() {
    return vmsJob;
  }

  get attachment() {
    return attachment;
  }
}
