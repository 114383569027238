<ng-container *ngIf="card.cardType === 'JOB_DASHBOARD'; then dashboardCard else searchCard"></ng-container>

<!-- *************************** dashboard card ************************* -->
<ng-template #dashboardCard>
  <div class="container fontSize" #cardComponent>
    <div *ngIf="card && card.header && headerText" class="row cardHeader align-content-center"
      [class.inactive-status-header]="headerText === 'Closed' || headerText === 'Closed Published'">
      <div class="col">
        <span class="font-sz-12">
          {{headerText}}
        </span>
      </div>
    </div>
    <div class="row" style="padding-left: 14px;background-color: white">
      <div class="col-10">
        <div class="row">
          <div *ngIf="card.job_order.allbirds_metadata.vms_req_id" class="col" style="max-width: 42px;">
            <div class="row cardRow border-0">
              <div class="col-11 cardCol">
                <div class="notPublishedDiv app-centerContent vms-job-status">
                  VMS
                </div>
              </div>
            </div>
          </div>
          
          <!-- Show TPV label for RT/RE user only -->
          <div *ngIf="showTPVLabel()" class="col" [style.max-width]=" TPVeligible? '42px' : '70px'">
            <div>
              <div class="row cardRow border-0">
                <div class="col-11 cardCol">
                  <div class="notPublishedDiv app-centerContent vms-job-status TPV" [ngClass]=" TPVeligible? 'TPV' : 'noTPV' ">
                    {{ TPVeligible? "TPV" : "No TPV"}}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="!isClientOrder" class="col" style="max-width: 57%;">
            <div class="row cardRow border-0">
              <div class="col-11 cardCol">
                <div class="notPublishedDiv app-centerContent pipeline">
                  Pipeline posting
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!jobPublishStatus && card.job_order.allbirds_metadata.order_created" class="col">
            <div *ngIf="!jobPublishStatus && card.job_order.allbirds_metadata.order_created" class="row cardRow border-0">
              <div class="col-11 cardCol">
                <div class="notPublishedDiv app-centerContent">
                  Not published
                </div>
              </div>
            </div>
          </div>
          <!--OUT FOR MVP but dont remove-->
          <!--<div dropdown class="col-1 cardCol text-xl-right" dropdown container="body">-->
          <!--<span dropdownToggle class="threeDotMenu">&#8942;</span>-->
          <!--<ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu"-->
          <!--role="menu" aria-labelledby="basic-link">-->
          <!--<li role="menuitem"><a class="dropdown-item">Assign to a recruiter</a></li>-->
          <!--<li role="menuitem"><a class="dropdown-item" [routerLink]="['../../jobs/new']" [queryParams]="{cloneId: card.job_order.allbirds_job_id}">Clone this job</a></li>-->
          <!--<li role="menuitem"><a class="dropdown-item">Close and archive</a></li>-->
          <!--</ul>-->
          <!--</div>-->
          <div class="col-4" *ngIf="card.job_order.allbirds_metadata.order_status === 'Covered'">
            <div class="row cardRow border-0">
              <div class="col-11 cardCol">
                <div class="notPublishedDiv app-centerContent covered-job-status">
                  Covered
                </div>
              </div>
            </div>
          </div>
          <div class="col-4" *ngIf="card.job_order.allbirds_metadata.order_status === 'On Hold'">
            <div class="row cardRow border-0">
              <div class="col-11 cardCol">
                <div class="notPublishedDiv app-centerContent on-hold-job-status">
                  On Hold
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="checkInterviewSlots()" class="row">
          <div class="col">
            <div class="row cardRow border-dark" style="margin-top: 12px">
              <div class="col-12 cardCol">
                <span class="slot-label">{{interviewSlotStatus}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="row cardRow border-dark" style="margin-top: 12px">
              <div class="col-11 cardCol cardTitle">
                <img *ngIf="linked_ab_job_id || number_of_linked_jobs" alt="child-prompt" src="./assets/icons/child-prompt.svg" class="p-0" /> <ng-container *ngIf="linked_ab_job_id || number_of_linked_jobs">{{order_of_linked_job}} / {{number_of_linked_jobs}}</ng-container> {{internalTitle}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-1 cardCol" *ngIf="card.cardType !== cardType.TALENT_ADD_TO_JOB && card.order_created">
        <div (click)="$event.stopPropagation()" style="padding-top: 5px">
          <app-job-action-menu [container]="'body'" [jobData]="card.job_order"></app-job-action-menu>
        </div>
      </div>
    </div>
    <div class="row" style="padding-bottom: 13px;padding-left: 14px;padding-right: 14px;background-color: white">
      <div class="col">
        <!--COMMON AREAS-->
        <div class="row cardRow app-centerContent">
          <div class="col cardCol app-centerContent">
            <div>
              <span *ngIf="isClientOrder">{{allbirdsCustName}}</span>
              <span *ngIf="allbirdsCustPriority" class="priority"
              [ngClass]="{
                 'high': allbirdsCustPriority === 1,
                 'low': allbirdsCustPriority === 2 || allbirdsCustPriority === 3 || allbirdsCustPriority === 4 || allbirdsCustPriority === 5
                }">P{{allbirdsCustPriority}}</span>
            </div>
          </div>
        </div>

        <div class="row flex-row cardRow">
          <div *ngIf="frontOfficeId"
            class="frontOfficeId align-self-center app-centerContent">
            <span class="label">{{'card.id' | translate}}</span>
            {{frontOfficeId}}
          </div>
          <div *ngIf="postingCreateTime"
            class="createdDate align-self-center app-centerContent">
            <span class="label">{{'card.created_on' | translate}}</span>
            {{postingCreateTime | abDate}}
          </div>
        </div>

        <div class="row cardRow" [ngClass]="{'flex-row': !fetchingUserInfo && publishedByUser && assignedToUser
          && assigneeAndCollabUsers && (!assigneeAndCollabUsers.length || assignedToUser === publishedByUser)
          || !card.order_created}" style="height: 40px;">
          <div [ngClass]="{'days-left-style': !fetchingUserInfo && publishedByUser
          && assignedToUser && assignedToUser !== publishedByUser && assigneeAndCollabUsers
          && assigneeAndCollabUsers.length && card.cardType !== cardType.TALENT_ADD_TO_JOB && card.order_created}" container="body" [tooltip]="tooltipDeadline" [delay]="1000">
            <div class="dayCount align-self-center app-centerContent"
              [ngClass]="{'days410': cssCondDays410(), 'days4': cssCondDays4(), 'days10': cssCondDays10() }">
              <img class="icon-WL_icon_time white" style="color: white; width: 14px; font-size: 14px !important;"
                [src]="getColoredClock()">
              <span class="white pr-2" style="font-size: 13px !important; margin-left: 4px">{{dayText}}</span>
            </div>
          </div>

          <div [ngClass]="{'deadline-style': !fetchingUserInfo && publishedByUser && assignedToUser
          && publishedByUser !== assignedToUser && assigneeAndCollabUsers && assigneeAndCollabUsers.length
          && card.cardType !== cardType.TALENT_ADD_TO_JOB && card.order_created}" container="body" [tooltip]="tooltipDeadline" [delay]="1000">
              <img src="assets/icons/talent-sg.svg" />
              <span class="font-sz-12"> {{allbirdsNumOpenings}} </span>
          </div>

          <ul class="avatar-list" [ngClass]="{'three-avatar-style': !fetchingUserInfo
          && publishedByUser && assignedToUser && assignedToUser !== publishedByUser
          && assigneeAndCollabUsers && assigneeAndCollabUsers.length
          && card.cardType !== cardType.TALENT_ADD_TO_JOB && card.order_created}">
            <li>
              <app-user-circle *ngIf="!fetchingUserInfo && publishedByUser"
                [user]="publishedByUser"
                [popoverPlacement]="index === 0 ? 'bottom' : 'top'"
                [useBodyAsContainer]="true">
              </app-user-circle>
            </li>
            <li>
              <app-user-circle *ngIf="!fetchingUserInfo && assignedToUser && assignedToUser !== publishedByUser"
                [user]="assignedToUser"
                [popoverPlacement]="index === 0 ? 'bottom' : 'top'"
                [useBodyAsContainer]="true">
              </app-user-circle>
            </li>
            <li>
              <app-user-circle *ngIf="!fetchingUserInfo
                && assigneeAndCollabUsers
                && assigneeAndCollabUsers.length"
                [users]="assigneeAndCollabUsers"
                [useBodyAsContainer]="true"
                [showPopover]="false"
                [popoverPlacement]="index === 0 ? 'bottom' : 'top'">
              </app-user-circle>
            </li>
          </ul>
        </div>
        <!--COMMON AREAS-->
      </div>
    </div>
    <div class="row" *ngIf="addressString">
      <div class="col-1">
        <img class="icon-WL_icon_location" style="color: white; width: 20px; font-size: 14px !important;"
          src="assets/icons/location-s.svg" />
      </div>
      <div class="col-10">
        <span class="font-sz-12">{{addressString}}</span>
      </div>
    </div>
    <ng-container *ngIf="card.cardType !== cardType.TALENT_ADD_TO_JOB">
      <div class="row" *ngIf="employmentTypes?.length">
        <div class="col-1">
          <img class="icon-WL_icon_jobtype" style="color: white; width: 14px; font-size: 14px !important; margin-left: 4px;"
               src="assets/icons/jobblack.svg" />
        </div>
        <div class="col-10">
          <span class="font-sz-12">{{employmentTypes[0] | stringclean: ['mapemploymenttype']}}</span>
        </div>
      </div>
      <div class="row" *ngIf="currencyAmountUnits">
        <div class="col-1">
          <img style="color: black; fill: white; width: 14px; font-size: 14px !important; margin-left: 4px;"
               src="assets/icons/pay.svg" />
        </div>
        <div class="col-10">
        <span class="font-sz-12 ">
          {{ min_pay_rate | currency: currencyCode }} -
          {{ max_pay_rate | currency: currencyCode }}
          {{ currencyUnit ? currencyUnit.toLowerCase() : currencyUnit }}</span>
        </div>
      </div>
      <!--Job Metrics-->
      <div class="row">
        <div class="col-1">
          <img style="color: black; fill: white; width: 14px; font-size: 14px !important; margin-left: 4px;"
               src="assets/icons/talent-select.svg" />
        </div>
        <div class="col-10">
          <div class="row no-gutters">
            <div class="col-1 font-sz-12" [ngClass]="{'mr-1': MetricsDigit === 2, 'mr-2': MetricsDigit === 3, 'mr-3': MetricsDigit === 4 }"><span style="color: blue">{{webApplicantsDocCount}}</span></div>
            <div class="col-8 font-sz-12">{{'card.web_applicants' | translate}}</div>
          </div>
          <div class="row no-gutters">
            <div class="col-1 font-sz-12" [ngClass]="{'mr-1': MetricsDigit === 2, 'mr-2': MetricsDigit === 3, 'mr-3': MetricsDigit === 4 }"><span style="color: blue">{{prescreeningDocCount}}</span></div>
            <div class="col-8 font-sz-12">{{'card.pre-screened' | translate}}</div>
          </div>
          <div class="row no-gutters">
            <div class="col-1 font-sz-12" [ngClass]="{'mr-1': MetricsDigit === 2, 'mr-2': MetricsDigit === 3, 'mr-3': MetricsDigit === 4 }"><span style="color: blue">{{interviewDocCount}}</span></div>
            <div class="col-8 font-sz-12">{{'card.recruiter_interview' | translate}}</div>
          </div>
          <div class="row no-gutters" *ngIf="userLob.checkLob('RT', 'RE','CR')">
            <div class="col-1 font-sz-12" [ngClass]="{'mr-1': MetricsDigit === 2, 'mr-2': MetricsDigit === 3, 'mr-3': MetricsDigit === 4 }"><span style="color: blue">{{internalSubmissionDocCount}}</span></div>
            <div class="col-8 font-sz-12">{{'card.internal_submission' | translate}}</div>
          </div>
          <div class="row no-gutters">
            <div class="col-1 font-sz-12" [ngClass]="{'mr-1': MetricsDigit === 2, 'mr-2': MetricsDigit === 3, 'mr-3': MetricsDigit === 4 }"><span style="color: blue">{{clientSubmissionDocCount}}</span></div>
            <div class="col-8 font-sz-12">{{'card.client_submission' | translate}}</div>
          </div>
          <div class="row no-gutters">
            <div class="col-1 font-sz-12" [ngClass]="{'mr-1': MetricsDigit === 2, 'mr-2': MetricsDigit === 3, 'mr-3': MetricsDigit === 4 }"><span style="color: blue">{{clientInterviewDocCount}}</span></div>
            <div class="col-8 font-sz-12">{{'card.client_interview' | translate}}</div>
          </div>
        </div>
        <div *ngIf="hiringManager" class="hiringManager font-sz-12">
          <div class="col-1">
            <img class="hiringMgrImg" src="assets/icons/hiring-manager.svg" />
          </div>
          <div class="col-8 font-sz-12">{{hiringManager}}</div>
        </div>
      </div>
    </ng-container>

  </div>
</ng-template>

<!-- *************************** search card ************************* -->
<ng-template #searchCard>
  <div *ngIf="searchCard" class="container fontSize" #cardComponent>
    <!-- title and status row -->
    <div class="row newCardRow newCardTop" >
        <div style="display:flex; justify-content: flex-start;">
          <div class="newCardItem cardTitle">
            <img *ngIf="linked_ab_job_id || number_of_linked_jobs" alt="child-prompt" src="./assets/icons/child-prompt.svg" class="p-0" /> <ng-container *ngIf="linked_ab_job_id || number_of_linked_jobs">{{order_of_linked_job}} / {{number_of_linked_jobs}}</ng-container> {{internalTitle}}
          </div>
          <div *ngIf="card.job_order.allbirds_metadata.vms_req_id" class="newCardItem row searchCardStatus align-content-center vms-job-status mr-1">
            <span class="font-sz-12">
              VMS
            </span>
          </div>
          <div *ngIf="!isClientOrder" class="newCardItem row searchCardStatus align-content-center pipeline mr-1">
            <span class="font-sz-12">
              Pipeline posting
            </span>
          </div>
          <div *ngIf="card && card.header && headerText" class="newCardItem row searchCardStatus align-content-center"
            [class.inactive-status-header-new]="headerText === 'Closed' || headerText === 'Unqualified' || headerText === 'Unassigned' || headerText === 'Closed Published'"
            [class.filled-status-header]="headerText === 'Filled'">
            <span class="font-sz-12">
              {{headerText}}
            </span>
          </div>

          <!-- Only showing for RT and RGS -->
          <div *ngIf="showTPVLabel()">
            <div *ngIf="TPVeligible; else noTPVSearchCard">
              <div *ngIf="card && card.header && headerText" class="TPV newCardItem row searchCardStatus align-content-center ml-4">
                <span class="font-sz-12"> TPV </span>
              </div>
            </div>
          </div>

          <div *ngIf="true" class="newCardItem row searchCardStatus align-content-center ml-4" style="background-color: transparent;"
            [ngClass]="{'covered-job-status': card.job_order.allbirds_metadata.order_status === 'Covered',
            'on-hold-job-status': card.job_order.allbirds_metadata.order_status === 'On Hold' }">
            {{ card.job_order.allbirds_metadata.order_status }}
          </div>
      </div>
      <div style="display: flex;">
        <div *ngIf="!jobPublishStatus && card.job_order.allbirds_metadata.order_created"
          class="notPublishedDiv app-centerContent">
          Not published
        </div>
        <div *ngIf="hasJobCardLobLabel" class="card-lob-label">
          {{card.job_order.allbirds_metadata.lob === 'RT' ? 'RD' : card.job_order.allbirds_metadata.lob}}
        </div>
        <div *ngIf="card.cardType !== cardType.TALENT_ADD_TO_JOB && card.order_created">
          <div (click)="$event.stopPropagation()" style="padding-top: 5px; margin: -10px -5px 0px 5px;">
            <app-job-action-menu [container]="'body'" [alignment]="'right'" [jobData]="card.job_order"></app-job-action-menu>
          </div>
        </div>
      </div>
    </div>
    <!-- client, priority, ID, creation date and assigned users row -->
    <div class="row newCardRow newCardMiddle">
      <div class="newCustName align-self-center newCardItem">
        <span *ngIf="isClientOrder">{{allbirdsCustName}}</span>
        <span *ngIf="allbirdsCustPriority" class="priority" [ngClass]="{
          'high': allbirdsCustPriority === 1,
          'low': allbirdsCustPriority === 2 || allbirdsCustPriority === 3 || allbirdsCustPriority === 4 || allbirdsCustPriority === 5
        }">P{{allbirdsCustPriority}}</span>
      </div>
      <div *ngIf="frontOfficeId" class="newFrontOfficeId align-self-center newCardItem">
        <span class="label" style="margin-right: 0px;">{{'card.id' | translate}}</span>
        {{frontOfficeId}}
      </div>
      <div *ngIf="postingCreateTime" class="newCreatedDate align-self-center newCardItem">
        <span class="label" style="margin-right: 0px;">Created</span>
        {{postingCreateTime | abDate}}
      </div>
      <ul class="avatar-list newCardItem">
        <li>
          <app-user-circle *ngIf="!fetchingUserInfo && publishedByUser"
            [user]="publishedByUser"
            [popoverPlacement]="index === 0 ? 'bottom' : 'top'"
            [useBodyAsContainer]="true">
          </app-user-circle>
        </li>
        <li>
          <app-user-circle *ngIf="!fetchingUserInfo && assignedToUser && assignedToUser !== publishedByUser"
            [user]="assignedToUser"
            [popoverPlacement]="index === 0 ? 'bottom' : 'top'"
            [useBodyAsContainer]="true">
          </app-user-circle>
        </li>
        <li>
          <app-user-circle *ngIf="!fetchingUserInfo
            && assigneeAndCollabUsers
            && assigneeAndCollabUsers.length"
            [users]="assigneeAndCollabUsers"
            [useBodyAsContainer]="true"
            [showPopover]="false"
            [popoverPlacement]="index === 0 ? 'bottom' : 'top'">
          </app-user-circle>
        </li>
      </ul>
    </div>
    <!-- card bottom section -->
    <div class="row newCardRow newCardBottom">
      <div class="newCardItem" container="body" [tooltip]="tooltipDeadline" [delay]="1000">
        <img class="icon-WL_icon_time white" style="width: 14px; font-size: 14px !important;"
          src="assets/icons/job-search-card/account-grey.svg">
        <span class="white pr-2 bottomItem" style="margin-left: 0px;"> {{allbirdsNumOpenings}} </span>
      </div>
      <div container="body" [tooltip]="tooltipDeadline" [delay]="1000">
        <div class="newCardItem">
          <img class="icon-WL_icon_time white" style="width: 14px; font-size: 14px !important;"
            src="assets/icons/job-search-card/clock-grey.svg">
          <span class="white pr-2 bottomItem" >{{dayText}}</span>
        </div>
      </div>
      <div *ngIf="addressString" class="newCardItem truncated" [tooltip]="addressString">
        <img class="icon-WL_icon_time white" style="width: 14px; font-size: 14px !important;"
          src="assets/icons/job-search-card/location-grey.svg">
        <span class="white pr-2 bottomItem" >{{addressString}}</span>
      </div>
      <div class="newCardItem">
        <img class="icon-WL_icon_time white" style="width: 14px; font-size: 14px !important;"
          src="assets/icons/job-search-card/pay-grey.svg">
        <span class="white pr-2 bottomItem" style="margin-left: -4px">
          {{ min_pay_rate | currency: currencyCode }} -
          {{ max_pay_rate | currency: currencyCode }}
          {{ currencyUnit ? currencyUnit.toLowerCase() : currencyUnit }}</span>
      </div>
    </div>
  </div>
</ng-template>

<!-- common tooltip for deadline -->
<ng-template #tooltipDeadline>
  <div [innerHtml]="tooltipDeadlineHtml"></div>
</ng-template>

<ng-template #noTPVSearchCard >
  <div *ngIf="card && card.header && headerText" class="noTPV newCardItem row searchCardStatus align-content-center ml-4">
    <span class="font-sz-12"> No TPV </span>
  </div>
</ng-template>