import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FormValidationService } from '../../services/form-validation/form-validation.service';

@Component({
  selector: 'form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss']
})
export class FormErrorComponent {

  @Input() fieldAlias: string;
  @Input() control: AbstractControl;
  @Input() message: string;

  constructor() { }

  get errorMessage() {
    if (this.control) {
      for (const propertyName in this.control.errors) {
        if (this.control.errors.hasOwnProperty(propertyName) && this.control.touched) {
          if (this.message) {
            return `${this.fieldAlias} ${this.message}`;
          } else {
            return FormValidationService.getErrorMessage(this.fieldAlias, propertyName, this.control.errors[propertyName]);
          }
        }
      }
    }
  }
}
