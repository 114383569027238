<ng-container *ngIf="noteTypes">
  <div class="modal-header">
    <button type="button" class="btn-reset" aria-label="Close" (click)="_bsModalRef.hide()">
      <i class="icon-WL_icon_close"></i>
    </button>
  </div>

  <div class="modal-body">
    <h2 class="mb-4">{{'log-note-modal.log_a_note' | translate}}</h2>
    <div class="note-form container-fluid" [formGroup]="noteForm">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="noteType">{{'log-note-modal.note_type' | translate}}</label>
            <select formControlName="activityType" class="form-control" id="noteType">
              <option [ngValue]="null">{{'log-note-modal.select_a_note' | translate}}</option>
              <option *ngFor="let type of noteTypes | keyvalue: noteSortAlphabetical" [value]="type.key">{{ type.value }}</option>
            </select>
            <form-error [fieldAlias]="'Note type'" [control]="noteForm.controls.activityType"></form-error>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="isUserRgs">
        <div class="col">
          <div class="form-group" *ngIf="isSocialType(noteForm.controls.activityType.value)">
            <label for="socialMediaType">{{'log-note-modal.social_media_type' | translate}}</label>
            <select formControlName="socialMediaType" class="form-control" id="socialMediaType">
              <option [ngValue]="null">{{'log-note-modal.select_a_social_media' | translate}}</option>
              <option *ngFor="let type of socialMediaTypes | keyvalue" [value]="type.key">{{ type.value }}</option>
            </select>
            <form-error [fieldAlias]="'Social media'" [control]="noteForm.controls.socialMediaType"></form-error>
          </div>
        </div>
        <div class="col">
          <div class="form-group" *ngIf="isSocialTypeOther(noteForm.controls.socialMediaType.value)">
            <label for="socialMediaOtherInput">{{'log-note-modal.please_specify' | translate}}</label>
            <input formControlName="socialMediaOtherInput" title="Salary minimum" id="socialMediaOtherInput"
                   class="form-control input-with-icon-left" aria-describedby="basic-addon4" [maxlength]="30"
                   required />
            <form-error [fieldAlias]="'Social media'" [control]="noteForm.controls.socialMediaOtherInput"></form-error>
          </div>


        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group" *ngIf="noteForm.controls.activityType.value">
            <app-ckeditor
              class="text-box w-100"
              [parentForm]="noteForm"
              formConName="comments"
              height="120"
              maxChar="10000"
              [enableMentions]="true">
            </app-ckeditor>
            <form-error [fieldAlias]="'Note'" [control]="noteForm.controls.comments"></form-error>
          </div>
        </div>

      </div>




    </div>
  </div> <!-- / .modal-body -->

  <div class="modal-footer">
    <ul class="hz-btn-list">
      <li>
        <button type="button" class="btn rds--button--primary" aria-label="cancel" (click)="_bsModalRef.hide()">
          {{'log-note-modal.cancel' | translate}}
        </button>
      </li>
      <li>
        <button type="button" class="btn rds--button--secondary" aria-label="log note" (click)="submitForm()">
          {{'log-note-modal.log_note' | translate}}
        </button>
      </li>
    </ul>
  </div>
</ng-container>
