import { FormatPhonePipe } from './shared/pipes/format-phone-number/format-phone.pipe';
// Angular stuff
import { ErrorHandler, NgModule} from '@angular/core';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AdminRouteGuard } from './admin-route.guard';
import { AuthRouteGuard } from './auth-route.guard';
import { JobCreationRouteGuard } from './modules/jobs/job-creation-route.guard';
import { ViewDraftsRouteGuard } from './modules/jobs/view-drafts-route.guard';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GlobalErrorHandler} from './global-error-handler';
// Services
import { AuthService } from './shared//services/auth/auth.service';
import { ExplainService } from './shared//services/explain/explain.service';
import { AuthGuardService } from './shared//services/auth/auth-guard.service';
import { JobOrderService } from './shared//services/job-order/job-order.service';
import { SuggestService } from './shared//services/suggest/suggest.service';
import { BarChangerService } from './shared//services/barChanger/bar-changer.service';
import { FormValidationService } from './shared//services/form-validation/form-validation.service';
import { JobOrderValidationService } from './shared//services/job-order-validation/job-order-validation.service';
import { TalentService } from './shared//services/talent/talent.service';
import { FilterService } from './shared//services/filter/filter.service';
import { CacheService } from './shared//services/cache/cache.service';
import { JobDetailsService } from './shared//services/job-details/job-details.service';
import { JobDetailsShortlistService } from './shared/services/job-details-shortlist/job-details-shortlist.service';
import { VmsJobService } from './shared/services/vms-job/vms-job.service';
import { TalentCreationService } from './shared/services/talent-creation/talent-creation.service';
// Components
import { AppComponent } from './app.component';
// Modules
import { SharedModule } from './modules/shared/shared.module';
import { CommonModule } from '@angular/common';
import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire';
import { AngularFirePerformanceModule, PerformanceMonitoringService } from '@angular/fire/performance';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';
import { TestingDataService } from 'src/app/shared/testing/testing-data.service';
import { PlatformAlertService } from './shared/services/platform-alert/platform-alert.service';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { ApplicationEntityChangeService } from './shared/services/entity-change/application-entity-change.service';
import { ModalService } from './shared/services/modal/modal.service'
import './shared/services/utility/custom-methods.ts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


// Import the module from the Auth0 Angular SPA SDK
import { AuthModule } from '@auth0/auth0-angular';

// interceptor to handle IAP issues
import { AuthInterceptor } from './auth-interceptor';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

// define a default type of "any" for the return value of providers
// this is done to sidestep an Angular 10 upgrade compatibility issue with
// https://www.npmjs.com/package/ng2-pdfjs-viewer
// For reference see: https://stackoverflow.com/a/62988875
// - JDS
declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
      ngModule: Type<T>;
      providers?: Provider[];
  }
}


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    BrowserModule,
    SharedModule,
    HttpClientModule,
    AppRoutingModule,
    FlexLayoutModule,
    PdfJsViewerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirePerformanceModule, // firebase performance monitoring
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, NgbModule, // imports firebase/storage only needed for storage features,
    // Import the module into the application, with configuration
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      redirectUri: window.location.origin,
      audience: environment.auth0.audience,
      dev:{
        serverUrl: environment.auth0.server
      },
      httpInterceptor: {
        allowedList: ['/*']
      }
    })
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    [{provide: ErrorHandler, useClass: GlobalErrorHandler}],
    // TODO: with this interceptor it is looping the app to login with Auth0.
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, // HTTP interceptor for auth
    DatePipe,
    CurrencyPipe,
    FormatPhonePipe,
    CacheService,
    AuthService,
    AuthGuardService,
    JobOrderService,
    SuggestService,
    BarChangerService,
    JobOrderValidationService,
    FormValidationService,
    TalentService,
    FilterService,
    JobDetailsService,
    // UsabillaService, Removed DF044-3644
    TestingDataService,
    AdminRouteGuard,
    AuthRouteGuard,
    JobCreationRouteGuard,
    ViewDraftsRouteGuard,
    JobDetailsShortlistService,
    PlatformAlertService,
    VmsJobService,
    ExplainService,
    ApplicationEntityChangeService,
    TalentCreationService,
    PerformanceMonitoringService, // firebase performance monitoring,
    ModalService,
],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private afs: AngularFirestore) {
    function setFirestoreMode () {
      if (navigator.userAgent.match(/Windows/) && navigator.userAgent.match(/Chrome/)) {
        const firestore = afs.firestore as any;
        // const howManyMs = 10;
        // if (!firestore || !firestore._config || !firestore._config.settings) {
        //   console.log('firestore settings not initialized yet, trying again in ' + howManyMs + ' ms.');
        //   return setTimeout(setFirestoreMode, howManyMs);
        // }
        if (!firestore._config.settings.forceLongPolling) {
          console.log('running firestore in experimentalForceLongPolling mode');
          afs.firestore.settings({
            experimentalForceLongPolling: true
            // TODO: test using the experimentalAutoDetectLongPolling setting instead of
            // experimentalForceLongPolling/

            /*
            Firebase npm library changelog - Version 7.24.0 - October 15, 2020
            Added a new experimentalAutoDetectLongPolling to FirestoreSettings.
            When enabled, the SDK's underlying transport layer automatically detects
            if long-polling should be used. This is very similar to
            experimentalForceLongPolling, but only uses long-polling if required.
            */
          });
          // afs.firestore.enablePersistence().catch(e => console.log(e));
        }
      }
    }
  }
}
