import {IntersectionObserverEvent} from 'src/app/shared/components/talent-detail-pane/talent-process/recruitment-phases/recruitment-phases.interface';
import { UserService } from 'src/app/shared/services/user/user.service';
import { Injectable, NgZone } from '@angular/core';
import { RGS_STEPS, RT_STEPS, PIPELINE_STEPS, StepInfo } from './process-steps';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { ToastClass, ToastService } from '../toast';
import {
  LabelFormatter,
  PROCESS_STATUS_INFO,
  ProcessStatusProps,
  ProcessStepInverse,
  tProcess_Status,
  tProcessSubStatus
} from './process-statuses';
import { LoadingSpinnerService } from '../loading-spinner/loading-spinner.service';
import moment from 'moment';
import { Application } from '../../models/external/application.model';
import { UpdateApplicationRequest, INTG_STEPS } from '../../models/internal/process.model';
import { ApplicationUpdateEvent } from '../../models/internal/step-change-event.interface';
import { ProcessInformationProvider } from './process-information-provider';
import { User } from '../../models/external/user.model';
import { Job } from '../../models/external/job.model';
import { Profile } from '../../models/external/profile.model';
import { ProcessStatus, ProcessStep } from 'src/app/shared/models/external/misc.model';
import { ApiService } from '../api/api.service';
import { Prescreening } from '../../models/external/process/prescreening.model';
import { Interview } from '../../models/external/process/interview.model';
import { InterviewSchedule } from '../../models/external/process/interview-schedule.model';
import { ClientInterview } from '../../models/external/process/client-interview.model';
import { ClientSubmission } from '../../models/external/process/client-submission.model';
import { InternalSubmission } from '../../models/external/process/internal-submission.model';
import { Offer } from '../../models/external/process/offer.model';
import { ApplicationState } from '../../models/internal/application-state.interface';
import { ApplicationEntityChangeService } from '../entity-change/application-entity-change.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TalentProcessService {

  // The array of steps (includes their title and icon).
  public steps: Map<ProcessStep, StepInfo>;
  public stepNames: ProcessStep[];

  // The selected talent.
  public selectedApplicationSubject = new BehaviorSubject<Application>(undefined);
  public selectedApplication = this.selectedApplicationSubject.asObservable();

  public selectedApplicationStateSubject = new BehaviorSubject<ApplicationState>({ disabled: false, hidden: false });
  public selectedApplicationState = this.selectedApplicationStateSubject.asObservable();

  // For delegating updates to the Talent service.
  private updatedApplicationSubject = new Subject<ApplicationUpdateEvent>();
  public updatedApplication = this.updatedApplicationSubject.asObservable();

  // Used for re-arranging talents when steps are skipped.
  private nextApplicationSubject = new Subject<ApplicationUpdateEvent>();
  public nextApplication = this.nextApplicationSubject.asObservable();

  // the profiles
  public selectedProfileSubject = new BehaviorSubject<Profile>(undefined);
  public selectedProfile = this.selectedProfileSubject.asObservable();

  private applicationStatus: tProcess_Status = PROCESS_STATUS_INFO;
  private ctaBoundryElem: HTMLElement;
  private ctaDomElem: HTMLElement;

  constructor(
    private _api: ApiService,
    private auth: AuthService,
    private toast: ToastService,
    private loading: LoadingSpinnerService,
    private zone: NgZone,
    private _applicationChange: ApplicationEntityChangeService,
    private _translate: TranslateService,
    private _userService: UserService
  ) { }

  get staticSteps() {
    if (!this.steps) {
      this.setupSteps();
    }
    return this.steps;
  }

  get staticStepsArray() {
    let stepValues: StepInfo[] = [];
    if (!this.steps) {
      this.setupSteps();
    }
    const steps = this.steps;
    if (steps) {
      stepValues = Array.from(steps.values());
    }
    return stepValues;
  }

  /**
   * Given an array of talent and the RS line-of-business, add the
   * talent step number
   * @param talent - array of talent application objects
   * @param jobLob - the job order's RS line of business
   */
  static addStepNumsTo(talent: any[], jobLob: string): void {
    for (let i = 0; i < talent.length; i++) {
      TalentProcessService.setTalentStepNumber(talent[i], jobLob);
    }
  }

  /**
   * Adds a key to denote the "step number" associated with a talent's last
   * process step, which is simply a zero-based, chronological index as it
   * pertains to the sequence of the process.
   * @param talent - talent application object
   * @param lob - the job order's RS line of business
   */
  static setTalentStepNumber(talent: Application, lob: string) {
    if (lob.checkLob('RT', 'RE','CR')) {
      switch (talent.randstad_process.lastProcessStep) {
        case 'interview':
        case 'interviewSchedule':
          talent.randstad_process.lastProcessStepNum = 1;
          break;
        case 'internalSubmission':
          talent.randstad_process.lastProcessStepNum = 2;
          break;
        case 'clientSubmission':
          talent.randstad_process.lastProcessStepNum = 3;
          break;
        case 'clientInterview':
          talent.randstad_process.lastProcessStepNum = 4;
          break;
        case 'offer':
          talent.randstad_process.lastProcessStepNum = 5;
          break;
        default:
          talent.randstad_process.lastProcessStepNum = 0;
      }
    } else {
      switch (talent.randstad_process.lastProcessStep) {
        case 'interview':
        case 'interviewSchedule':
          talent.randstad_process.lastProcessStepNum = 1;
          break;
        case 'clientSubmission':
          talent.randstad_process.lastProcessStepNum = 2;
          break;
        case 'clientInterview':
          talent.randstad_process.lastProcessStepNum = 3;
          break;
        case 'offer':
          talent.randstad_process.lastProcessStepNum = 4;
          break;
        default:
          talent.randstad_process.lastProcessStepNum = 0;
      }
    }
  }

  public setupSteps() {
    if (this.auth && this.auth.user && this.auth.user.Source) {
      this.setStepsForLob(this.auth.user.Source);
    }
  }

  /**
   * Sets steps map as the correct set of steps based on the line of business
   * @param jobLob - the job order's RS line of business
   */
  setStepsForLob(jobLob: string, orderType?: string): void {
    // console.debug('[setStepsForLob]', jobLob);
    this.steps = jobLob.checkLob('RT', 'RE','CR') ? RT_STEPS : RGS_STEPS;
    if (orderType && orderType === 'Pipeline') this.steps = PIPELINE_STEPS;
    // console.debug('[setStepsForLob] this.steps=', this.steps);
    this.stepNames = Array.from(this.steps.keys());
    // console.debug('[setStepsForLob] this.stepNames=', this.stepNames);
  }

  /**
   * Notifies all subscribers to the selected application that the selected (or
   * clicked/active) application has changed.
   * @param application - talent application object that is currently selected
   */
  selectApplication(application: Application) {
    this.selectedApplicationSubject.next(application);
    if (this._applicationChange && this._applicationChange.didChange(application)) {
      this.selectedApplicationStateSubject.next({
        disabled: true,
        disabledMsg: this._translate.instant('application-disabled.further_state'),
        hidden: false
      });
    } else {
      this.selectedApplicationStateSubject.next({ disabled: false, hidden: false });
    }
  }

  selectProfile(profile: Profile) {
    this.selectedProfileSubject.next(profile);
  }

  /**
   * Skips a single step of the talent process.
   * @param application {Application} - talent application object
   */
  skipStep(application: Application, isSolutionsJob: boolean = false) {
    return this.skipToStep(application, application.randstad_process.lastProcessStepNum + 1, isSolutionsJob);
  }

  /**
   * Skips the steps for a talent's application for the range [lastProcessStep, stepNumber).
   * @param application {Application} - talent application object
   * @param stepNumber {number} - the number of the step to skip to.
   * @param doUpdate {boolean} - if true, will make the update call to the API.
   */
  skipToStep(application: Application, stepNumber: number, isSolutionsJob: boolean = false, doUpdate: boolean = true): Promise<boolean | void> {
    if (stepNumber === 0) { return; }
    const fromStepKey = application.randstad_process.lastProcessStep;
    const fromStepNum = application.randstad_process.lastProcessStepNum;
    // set current step on object
    const interimStatus = ProcessStepInverse[this.stepNames[stepNumber]];
    const currStep: ProcessStep = ProcessStep[interimStatus] as ProcessStep;
    const stepInfo = this.steps.get(currStep);
    let currSubStatus: ProcessStatus = stepInfo.subStatus;
    if(currSubStatus === ProcessStatus.RA_SUBMIT_TO_AM && isSolutionsJob) {
      currSubStatus = ProcessStatus.RA_SUBMIT_TO_SS;
    }
    application.randstad_process.lastProcessStepNum = stepNumber;
    application.randstad_process.lastProcessStep = currStep;
    application.randstad_process.lastProcessStatus = currSubStatus;
    // set all previous steps to skipped
    for (let i = fromStepNum; i < this.stepNames.length; i++) {
      if (i < stepNumber) {
        const stepName = this.stepNames[i];
        const step = application.randstad_process[stepName];
        if (step) {
          if (!step.skipped) {
            step.skipped = true;
            step.skippedDate = moment();
            step.skippedBy = this.auth.user.BackOfficeID;
          }
        } else if (stepName === ProcessStep.OFFER) {
          application.randstad_process[stepName] = new Offer();
        } else {
          const Class = {
            prescreening: Prescreening,
            interview: Interview,
            interviewSchedule: InterviewSchedule,
            internalSubmission: InternalSubmission,
            clientSubmission: ClientSubmission,
            clientInterview: ClientInterview
          }[stepName];
          application.randstad_process[stepName] = new Class({
            skipped: true,
            skippedDate: moment(),
            skippedBy: this.auth.user.BackOfficeID
          });
        }
      } else {
        break;
      }
    }
    // Update the application.;
    if (doUpdate) {
      const intgSteps: INTG_STEPS[] = [];
     return this.updateApplication(application, intgSteps, fromStepKey)
        .then(() => {
          const currentStepInfo: StepInfo = this.steps.get(application.randstad_process.lastProcessStep);
          const trVariable = { 'value1': application.randstad_process.candidateFullName, 'value2': currentStepInfo.stepTitle };
          const toastString = 'talent-process_service.manual_advance';
          const toastConfig = {
            cssClass: ToastClass.SUCCESS
            // actionLinks: [
            //   {
            //     text: 'Undo', action: () => {
            //       this.undoSkip(application, fromStepNum);
            //     }
            //   },
            // ]
          };
          this.toast.showToastWithVariables(toastString, trVariable, toastConfig);
          return true;
        })
        .catch(err => console.log(err));
    }
  }

  skipStepAndGetTalentApplication(profileId: string, jobId: string, application: Application, stepNumber: number, isSolutionsJob: boolean = false): Promise<Application> {
    return new Promise((resolve, reject) => {
      this.skipToStep(application, stepNumber, isSolutionsJob)
        .then((res: boolean) => {
          this._api.getTalentApplication(profileId, true, jobId)
            .subscribe((app: Application) => { resolve(app); })
        });
    });
  }
  /**
   * Given a talent application and the step they should be reverted back to, will
   * delete all steps in the range [originStepNumber, lastProcessStep].
   * @param application - talent application object
   * @param originStepNumber - the number of the step to revert to.
   */
  undoSkip(application: any, originStepNumber: number): void {
    // Get the current step items (so we know where to delete from).
    const revertFromKey = application.randstad_process.lastProcessStep;
    const revertFromStepNum = application.randstad_process.lastProcessStepNum;
    // Revert application back to original step.
    const stepNameStr = ProcessStepInverse[this.stepNames[originStepNumber]];
    application.randstad_process.lastProcessStep = ProcessStep[stepNameStr];
    application.randstad_process.lastProcessStepNum = originStepNumber;
    // Starting at the pre-skip step, delete all process steps until we
    // reach the step that was skipped to (inclusive).
    for (let i = originStepNumber; i <= revertFromStepNum; i++) {
      if (application.randstad_process[this.stepNames[i]]) {
        delete application.randstad_process[this.stepNames[i]];
      }
    }

    // Update the application.
    this.updateApplication(application, revertFromKey)
      .then(() => {
        const trVariable = { 'value': application.randstad_process.candidateFullName };
        const toastString = 'talent-process_service.skip_action';
        const toastConfig = {
          cssClass: ToastClass.SUCCESS
        };
        this.toast.showToastWithVariables(toastString, trVariable, toastConfig);
      })
      .catch(err => console.log(err));
  }

  /**
   * Sends the application to the API service to be updated in Elastic. On success,
   * will update the application we have stored in memory.
   * @param application - talent application object
   * @param fromStepKey - the key of the step the talent was on when an action took place.
   * @param followTalent - if true, follows the talent through the process by auto
   *                       changing tabs, etc. so the talent card is always in view.
   * @param skipIntegrations - if true, skips integrations logic
   * @param forceActivityWrite - if true, forces Integrations activity write (assuming skipIntegrations is false)
   */
  updateApplication(
    application: Application,
    intgSteps: INTG_STEPS[] = [],
    fromStepKey?: ProcessStep,
    followTalent: boolean = true,
    pipelineFlag: boolean = false
  ): Promise<boolean> {
    this.zone.run(() => { this.loading.show(); });
    const stepKey = fromStepKey || application.randstad_process.lastProcessStep;
    TalentProcessService.updateLastProcessStepDate(application);
    let req: UpdateApplicationRequest = { "application": application, "intgSteps": intgSteps, "pipeline": pipelineFlag };
    return new Promise((resolve, reject) => {
      this._api.updateApplication(req)
        .subscribe(data => {
          this.updateApplicationInMemory(application, stepKey, followTalent);
          this.loading.hide();
          resolve(true);
        }, err => {
          this.loading.hide();
          console.log(err);
          reject(false);
        });
    });
  }

  rejectApplication(application: any) { }

  /**
   * Updates the application in memory and then notifies the subscribers of our subject
   * so that they will update the application in their respective components.
   * @param application - talent application object
   * @param fromKey - the key of the step the talent was on when an action took place
   * @param followTalent - if true -- ensures that the talent card on the UI is followed
   *                       throughout the process.
   */
  updateApplicationInMemory(
    application: Application,
    fromKey?: ProcessStep,
    followTalent: boolean = true
  ) {
    const fromStepKey = fromKey || application.randstad_process.lastProcessStep;
    this.updatedApplicationSubject.next({ application, fromStepKey });
    // if (followTalent) this.nextApplicationSubject.next({ application, fromStepKey });
    // this.updatedApplicationSubject.next(application);
    this.selectApplication(application);
  }

  /**
   * If the inputted application is rejected, shows a toast.
   * @param application - talent application object
   */
  showToastIfRejected(application: Application): boolean {
    if (application.randstad_process.rejected) {
      const trVariable = { value: application.randstad_process.candidateFullName };
      this.toast.showToastWithVariables('talent-process_service.cannot_conduct', trVariable, { cssClass: ToastClass.DANGER });
      return true;
    }
    return false;
  }

  /**
   * Get the label to display in a shortlisted talent card and talent process pane
   * @param lob Line of business usually authService.user.Source
   * @param labelType 'card' indicates card label and 'process' indicates process pane label
   * @param interviewLocation Overriding interview location
   */
  getLabel(app: Application, lob: string, labelType: 'process' | 'card', job?: Job, user?: User, interviewLocation?: string): string {
    let label: string | LabelFormatter = '';
    if (app && lob && labelType) {
      const procStatusProps = this.getTalentStatus(app);
      if (procStatusProps) {
        if (lob.checkLob('RT', 'RE')) {
          label = labelType === 'process' ? procStatusProps.rtProcessLabel : procStatusProps.rtCardLabel;
        } else if (lob === 'CR') {
          label = labelType === 'process' ? procStatusProps.crProcessLabel : procStatusProps.crCardLabel;
        } else {
          label = labelType === 'process' ? procStatusProps.rgsProcessLabel : procStatusProps.rgsCardLabel;
        }
      } else {
        console.warn('TalentProcessService.getLabel, ended up with no properties somehow. app=', app, 'lob=', lob, 'labelType=', labelType);
      }
    }
    if (typeof label === 'function') {
      const information = new ProcessInformationProvider(app.randstad_process, job, user, interviewLocation, this._userService, app);
      return label(information);
    }
    return label;
  }

  /**
   * Get the ProcessStatusProps for the particular process step the application is in
   * @param talentProcess {Application}
   * @returns {ProcessStatusProps}
   */
  getTalentStatus(talentProcess: Application): ProcessStatusProps {
    const { lastProcessStep, rejected, lastProcessStatus } = talentProcess.randstad_process;
    let tempReturnVal: ProcessStatusProps = null;
    const lastProc = lastProcessStep as ProcessStep;
    if (rejected) {
      const subStat = this.getNotAFitSubStatus(lastProc, lastProcessStatus);
      tempReturnVal = this.applicationStatus[lastProcessStep][subStat];
    } else {
      if (!lastProcessStep) {
        return null;
      } else if (lastProcessStep && lastProcessStatus) {
        const procStatus = lastProcessStep as ProcessStep;
        const procSubStatus = ProcessStatus[lastProcessStatus];
        tempReturnVal = this.applicationStatus[procStatus][procSubStatus];
        if (this.auth.user?.Permissions?.submitCandidateToHiringManager && tempReturnVal && tempReturnVal.acctMgr) {
          tempReturnVal = tempReturnVal.acctMgr;
        }
      } else {
        switch (lastProc) {
          case ProcessStep.INTERVIEW_RECRUITER:
            tempReturnVal = this.getInterviewStatus(talentProcess);
            break;
          case 'interviewSchedule':
            tempReturnVal = this.getInterviewStatus(talentProcess);
            break;
          case ProcessStep.REVIEW_AM:
            tempReturnVal = this.getInternalSubmissionStatus(talentProcess);
            // There are 2 separate text displays for recruiter and account manager
            // If the current user is an account manager, we need to return the appropriate
            // status
            if (this.auth.user.Permissions.submitCandidateToHiringManager && tempReturnVal.acctMgr) {
              tempReturnVal = tempReturnVal.acctMgr;
            }
            break;
          case ProcessStep.REVIEW_HM:
            tempReturnVal = this.getClientSubmissionStatus(talentProcess);
            break;
          case ProcessStep.INTERVIEW_HM:
            tempReturnVal = this.getClientSubmissionStatus(talentProcess);
            break;
          case ProcessStep.OFFER:
            tempReturnVal = this.getOfferStatus(talentProcess);
            break;
          default:
            tempReturnVal = this.getPrescreeningStatus(talentProcess);
        }
      }
    }
    return tempReturnVal;
  }

  getPrescreeningStatus(talentProcess: Application): ProcessStatusProps {
    const { prescreening, lastProcessStatus } = talentProcess.randstad_process;
    if (!prescreening || !lastProcessStatus) {
      return this.applicationStatus[ProcessStep.PRESCREENING][ProcessStatus.P_START];
    }
    if (prescreening.conversationID) {
      return this.applicationStatus[ProcessStep.PRESCREENING][ProcessStatus.P_REVIEW_QUESTIONS];
    }
    if (!prescreening.manualScreeningAnswers) {
      return this.applicationStatus[ProcessStep.PRESCREENING][ProcessStatus.P_AWAITING_RESULTS];
    }
    if (prescreening.manualScreeningAnswers) {
      return this.applicationStatus[ProcessStep.PRESCREENING][ProcessStatus.P_DECIDE_PROCEED];
    }

    return this.applicationStatus[ProcessStep.PRESCREENING][ProcessStatus.P_START];
  }

  getInterviewStatus(talentProcess: Application): ProcessStatusProps {
    const { randstad_process } = talentProcess;
    const { interviewSchedule, interview } = randstad_process;
    const { interviewStatus, timeSlotSelected } = interviewSchedule;
    if (!interview) {
      if (!interviewSchedule) {
        return this.applicationStatus[ProcessStep.INTERVIEW_RECRUITER][ProcessStatus.IR_PLAN_INTERVIEW];
      } else if (interviewSchedule && !interviewStatus) {
        return this.applicationStatus[ProcessStep.INTERVIEW_RECRUITER][ProcessStatus.IR_AWAITING_RESPONSE];
      } else if (interviewSchedule && interviewStatus) {
        if (interviewStatus === 'CANCELLED') {
          return this.applicationStatus[ProcessStep.INTERVIEW_RECRUITER][ProcessStatus.IR_INTERVIEW_CANCELLED];
        } else {
          if (timeSlotSelected && timeSlotSelected.isValid()) {
            return this.applicationStatus[ProcessStep.INTERVIEW_RECRUITER][ProcessStatus.IR_AWAITING_INTERVIEW];
          } else if (timeSlotSelected) {
            return this.applicationStatus[ProcessStep.INTERVIEW_RECRUITER][ProcessStatus.IR_RESCHEDULE_INCONVENIENT_INTERVIEW];
          } else {
            return this.applicationStatus[ProcessStep.INTERVIEW_RECRUITER][ProcessStatus.IR_RESCHEDULE_DECLINED_INTERVIEW];
          }
        }
      }
    } else if (interview && interview.completionDate) {
      return this.applicationStatus[ProcessStep.INTERVIEW_RECRUITER][ProcessStatus.IR_CONSIDER_RESULTS];
    } else if (interview && interview.type === 'CANCELLED') {
      return this.applicationStatus[ProcessStep.INTERVIEW_RECRUITER][ProcessStatus.IR_INTERVIEW_CANCELLED];
    }
    return this.applicationStatus[ProcessStep.INTERVIEW_RECRUITER][ProcessStatus.IR_AWAITING_INTERVIEW];
  }

  getInternalSubmissionStatus(talentProcess: Application): ProcessStatusProps {
    const { internalSubmission } = talentProcess.randstad_process;
    if (!internalSubmission) {
      return this.applicationStatus[ProcessStep.REVIEW_AM][ProcessStatus.RA_SUBMIT_TO_AM];
    }
    if (internalSubmission.outcome && internalSubmission.outcome === 'submitted') {
      if(talentProcess.randstad_process.lastProcessStatus === ProcessStatus.RA_WAITING_SS_FEEDBACK) {
        return this.applicationStatus[ProcessStep.REVIEW_AM][ProcessStatus.RA_WAITING_SS_FEEDBACK];
      }
      if (this.auth.user.Permissions.reviewSubmittedTalent) {
        return this.applicationStatus[ProcessStep.REVIEW_AM][ProcessStatus.RA_WAITING_AM_FEEDBACK];
      }
      return this.applicationStatus[ProcessStep.REVIEW_AM][ProcessStatus.RA_WAITING_AM_FEEDBACK];
    }
    return this.applicationStatus[ProcessStep.REVIEW_AM][ProcessStatus.RA_SUBMIT_TO_AM];
  }

  getClientSubmissionStatus(talentProcess: Application): ProcessStatusProps {
    const { clientSubmission } = talentProcess.randstad_process;
    if (!clientSubmission) {
      return this.applicationStatus[ProcessStep.REVIEW_HM][ProcessStatus.RH_SUBMIT_TO_HM];
    }
    if (clientSubmission && clientSubmission.outcome === 'submitted') {
      if (this.auth.user.Permissions.submitCandidateToHiringManager) {
        return this.applicationStatus[ProcessStep.REVIEW_HM][ProcessStatus.RH_AWAITING_CLIENT_REVIEW];
      }
      return this.applicationStatus[ProcessStep.REVIEW_HM][ProcessStatus.RH_AWAITING_CLIENT_REVIEW];
    }
    return this.applicationStatus[ProcessStep.REVIEW_HM][ProcessStatus.RH_SUBMIT_TO_HM];
  }

  getOfferStatus(talentProcess: Application): ProcessStatusProps {
    const { offer, rejected } = talentProcess.randstad_process;
    if (rejected) {
      return this.applicationStatus[ProcessStep.OFFER][ProcessStatus.O_REJECTED];
    }
    if (offer && offer.offerDate) {
      return this.applicationStatus[ProcessStep.OFFER][ProcessStatus.O_ACCEPTED];
    }
    return this.applicationStatus[ProcessStep.OFFER][ProcessStatus.O_SENT];
  }

  /**
   * Get the NOT_A_FIT ProcessStatus based on the application's lastProcessStep
   * @param talentProcess {Application}
   * @returns {ProcessStatus|null}
   */
  getNotAFitSubStatus(lastProcessStep: ProcessStep, lastProcessStatus?: ProcessStatus): ProcessStatus | null {
    let processSubStatus: ProcessStatus | null = null;
    //console.log("Inside not fit substatus" , lastProcessStatus);--
    //TODO figure out why its called many times
    if (lastProcessStep) {
      if (lastProcessStep === ProcessStep.OFFER) {
        return ProcessStatus.O_REJECTED;
      } else if (lastProcessStep === ProcessStep.REVIEW_AM) {
        if(lastProcessStatus) {
          if(lastProcessStatus === ProcessStatus.RA_WAITING_SS_FEEDBACK) {
              return ProcessStatus.RA_WAITING_SS_FEEDBACK_NOT_A_FIT;
          }
          if(lastProcessStatus === ProcessStatus.RA_SUBMIT_TO_SS) {
            return ProcessStatus.RA_SUBMIT_TO_SS_NOT_A_FIT;
          }
          if(lastProcessStatus === ProcessStatus.SS_REVIEW_COMPLETED) {
            return ProcessStatus.SS_REVIEW_COMPLETED_NOT_A_FIT;
          }
        }
        if (this.auth.user.Permissions.viewReviewAccountManager) {
          return ProcessStatus.RA_AM_NOT_A_FIT;
        } else {
          return ProcessStatus.RA_RECRUITER_NOT_A_FIT;
        }
      } else {
        const status: tProcessSubStatus = this.applicationStatus[lastProcessStep];
        const keys = Object.keys(status) as ProcessStatus[];
        for (const key of keys) {
          if (key.indexOf('NOT_A_FIT') > -1) {
            processSubStatus = ProcessStatus[key];
            break;
          }
        }
      }
    }
    return processSubStatus;
  }

  /**
   * Given an application, updates all of the update times.
   * @param application
   * @param date
   */
  static updateLastProcessStepDate(application: Application, date?: string) {
    const now = moment();
    application.updateTime = now;
    application.randstad_process.apply({ lastProcessStepDate: now });
  }

  /**
   * Given an array of applications, updates all of the update times for each application.
   * @param applications
   */
  static updateManyLastProcessStepDates(applications: Application[]) {
    for (const a of applications) {
      TalentProcessService.updateLastProcessStepDate(a);
    }
  }

  /**
   * Used to explicitly emit events from the updated application subject. Currently,
   * this serves the purpose of updating talent cards whenever they move along the process
   * steps.
   * @param applications
   */
  public propagateApplicationUpdates(applications: Application[]) {
    for (const app of applications) {
      this.updatedApplicationSubject.next({
        application: app
      });
    }
  }

  public setSelectedApplicationState(state: ApplicationState) {
    this.selectedApplicationStateSubject.next(state);
  }

  /**
   * Watch the call to action container (ctaContainer). If it is not visible add a float class
   * This is called from each recruitment-phase component
   * @param watchElements The DOM elements to watch for visibility threshold
   * @param callback The callback to run as intersection thresholds are passed
   */
  setupIntersectionObserver(watchElements: Element[]) {
    const intersectionObsOptions: IntersectionObserverInit = {
      root: null as null,
      rootMargin: '0px',
      threshold: 0
    };
    const observer = new IntersectionObserver(this.updateCtaPosition.bind(this), intersectionObsOptions);
    watchElements.forEach((element) => {
      observer.observe(element);
    });
  }

  /**
   * Callback for the IntersectionObserver. Will add the float value to the CTA buttons based on
   * position of the boundry element and whether or not that boundry element is in the viewport
   * @param entries
   * @param observer
   */
  updateCtaPosition(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
    const buttonContainer = entries.find(entry => entry.target.classList.contains('action-buttons'));
    const ctaBoundry = entries.find(entry => entry.target.id === 'ctaContainerBoundry');
    if (buttonContainer && (!this.ctaDomElem || this.ctaDomElem !== buttonContainer.target)) {
      this.ctaDomElem = buttonContainer.target as HTMLElement;
    }
    if (ctaBoundry && (!this.ctaBoundryElem || this.ctaBoundryElem !== ctaBoundry.target)) {
      this.ctaBoundryElem = ctaBoundry.target as HTMLElement;
    }
    const ctaEventConfig: IntersectionObserverEvent = {
      entries,
      observer,
      ctaDomElem: this.ctaDomElem,
      ctaBoundryElem: this.ctaBoundryElem
    };
    this.updateCtaFloat(ctaEventConfig);
  }

  /**
   * Add the float class to the recruitment phase call to action button container. If you are scrolling down
   * and the boundry element scrolls out of the viewport at the top, DO NOT ADD the float class
   * @param evt
   * @listens updateCtaFloat
   */
  updateCtaFloat(evt: IntersectionObserverEvent) {
    const {ctaDomElem, ctaBoundryElem, entries} = evt;
    const ctaBoundryEntry = entries.find(entry => entry.target.id === 'ctaContainerBoundry');
    if (ctaDomElem && ctaBoundryEntry && ctaBoundryEntry.intersectionRatio === 0 && !ctaDomElem.classList.contains('float')) {
      const ctaBoundryTop = ctaBoundryElem.getBoundingClientRect().top;
      const scroller = document.querySelector('.section-3');
      if (scroller) {
        const scrollerTop = scroller.getBoundingClientRect().top;
        const scrollerLeft = scroller.getBoundingClientRect().left;
        if (ctaBoundryTop > scrollerTop) {
          ctaDomElem.classList.add('float');
          ctaDomElem.style.left = `${scrollerLeft}px`;
        }
      } else {
        console.warn('Unable to find the scrolling container');
      }
    } else if (ctaDomElem && ctaBoundryEntry && ctaBoundryEntry.intersectionRatio === 1 && ctaDomElem.classList.contains('float')) {
      ctaDomElem.classList.remove('float');
    }
  }
}

