import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { JobOrderService } from 'src/app/shared/services/job-order/job-order.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { ToastClass, ToastService } from 'src/app/shared/services/toast';
import { Job } from 'src/app/shared/models/external/job.model';
import { ApiService } from '../../../../../shared/services/api/api.service';
import { JobForm } from '../../../../../shared/models/external/job-form.model';
import { Router } from '@angular/router';
import { UtilityService } from 'src/app/shared/services/utility/utility.service';
import { ClosedStatus, ActiveStatus } from 'src/app/shared/models/internal/process-statuses.interface';
import moment from 'moment';
import { NotificationData, NotificationTypes } from 'src/app/shared/models/internal/notifications-data.model';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';

@Component({
  selector: 'extend-job-modal',
  templateUrl: './extend-job-modal.component.html',
  styleUrls: ['./extend-job-modal.component.scss']
})
export class ExtendJobModalComponent implements OnInit {
  jobData: any;
  allbirds_job_id: string;

  elasticJobOrder: Job; /* The Job The Way It Is In Elastic */
  formJobOrder: JobForm; /* The Job The Way It Is In The Form */
  saving: boolean = false;
  customer_name: any;
  customer_priority: any;
  front_office_id: any;
  title: any;
  activeStatuses: string[];

  errorMsg: boolean = true;

  constructor(
    private _api: ApiService,
    public _bsModalRef: BsModalRef,
    private toastService: ToastService,
    private jobOrderService: JobOrderService,
    private _auth: AuthService,
    private _router: Router,
    private util: UtilityService,
    private _notificationService: NotificationsService,
  ) { }

  get userLob(): string { return this._auth.user && this._auth.user.Source; }

  ngOnInit() {
    this.activeStatuses = ActiveStatus.filter(s => s !== 'Unqualified');
    this.setElasticJobOrder(this.jobData);

    //set job data
    if (this.jobData && this.jobData.title) {
      this.title = this.jobData.title;
    }
    if (this.jobData && this.jobData.allbirds_metadata) {
      const allBirds = this.jobData.allbirds_metadata;
      this.customer_name = allBirds.customer_name || '';
      this.customer_priority = allBirds.customer_priority;
      this.front_office_id = allBirds.front_office_id;
    }
  }

  setElasticJobOrder(job: Job) {
    this.elasticJobOrder = job;
    if (this.elasticJobOrder && this.elasticJobOrder.allbirds_metadata) {
      this._api.getJob(this.elasticJobOrder.allbirds_metadata.allbirds_job_id, true).toPromise().then(this.setFormJobOrder);
      this.checkIfExtendable();
    }
  }

  setFormJobOrder = (jobForm: JobForm) => {
    this.formJobOrder = jobForm;
  };

  checkIfExtendable(){
    // if status is one of ClosedStatus => allow re-opening
    if (ClosedStatus.includes(this.elasticJobOrder.allbirds_metadata.order_status)){
      this.errorMsg = false;
    }
    // if accepting candidate, covered, or on hold => check if expiration date is within 3 days
    // note: the RT job must have an assigned recruiter for there to be an expiration date
    else if (
      this.elasticJobOrder.hasExpireDate
      && this.elasticJobOrder.allbirds_metadata.allbirds_job_id
      && this.activeStatuses.includes(this.elasticJobOrder.allbirds_metadata.order_status)
    ) {
      this.errorMsg = this.util.calcNumberOfDays(this.elasticJobOrder) > 3;
    }
  }

  extendJob() {
    this.saving = true;
    const changedFields: string[] = ['order_status', 'job_extension_counter'];

    // if the job is closed, update pub status to false to cover DE updated expired jobs that have pub status of true
    if(ClosedStatus.includes(this.elasticJobOrder.allbirds_metadata.order_status)){
      this.formJobOrder.publish_immediately = false;
      this.formJobOrder.published_status = false;

      this.elasticJobOrder.allbirds_metadata.publish_immediately = false;
      this.elasticJobOrder.allbirds_metadata.published_status = false;
      changedFields.push('publish_immediately', 'published_status');
    }

    // update these fields for all extension/reopen cases
    this.formJobOrder.order_status = 'Accepting Candidates';

    this.elasticJobOrder.allbirds_metadata.order_status = 'Accepting Candidates';

    // update job_expire_date only if the field has a value(ie a recruiter was assigned to the job)
    if (this.elasticJobOrder.hasExpireDate
      || this.elasticJobOrder.expirationDate === null
      || this.elasticJobOrder.expirationDate === undefined
      || (this.elasticJobOrder.expirationDate && this.elasticJobOrder.expirationDate.toString() === null)) {
      this.formJobOrder.job_expire_date = moment().add(15, 'days');

      this.elasticJobOrder.allbirds_metadata.job_expire_date = this.formJobOrder.job_expire_date;
      changedFields.push('job_expire_date');
    }

    this._api.updateJob(this.formJobOrder, changedFields)
      .subscribe(() => this.publishJob(changedFields), err => this.handleErrorResponse(err, 'update'));
  }

  publishJob = (changedFields: string[]) => {
    const interviewObj = this.jobOrderService.getInterviewObject(this.elasticJobOrder, this._auth.user);
    this._api.publishJob(this.formJobOrder.allbirds_job_id, interviewObj, changedFields)
      .subscribe(this.updateJobSuccess, err => this.handleErrorResponse(err, 'republish'));
  };

  updateJobSuccess = () => {
    this.toastService.showToast('The job has been successfully updated', { cssClass: ToastClass.SUCCESS });
    this.jobOrderService.storeJobDetails(this.elasticJobOrder);
    this.saving = false;
    this.sendNotification();
    this._bsModalRef.hide();
    this._router.navigate(['../../jobs', this.elasticJobOrder.allbirds_metadata.allbirds_job_id]);
  };

  handleErrorResponse(err: any, type: string){
    this.saving = false;
    this.toastService.showToast('Error: the job has not been successfully updated', { cssClass: ToastClass.DANGER });
    console.log(`job ${type} failed.`, err);
  }

  sendNotification() {
    if (this.jobData && this.jobData.allbirds_metadata.front_office_id) {
      const {
        published_by_user,
        published_by_user_email,
        assigned_to_user,
        assigned_to_user_email
      } = this.jobData.allbirds_metadata;
      if (this.userLob.checkLob('RT', 'RE',"CR")) {

        const emailRecipients = assigned_to_user !== published_by_user
         ? [
          {
            recipient: assigned_to_user,
            recipientEmail: assigned_to_user_email
          },
          {
            recipient: published_by_user,
            recipientEmail: published_by_user_email
          }
        ].filter(r => r.recipient !== this._auth.user.FullName)
        : [
          {
            recipient: assigned_to_user,
            recipientEmail: assigned_to_user_email
          }
        ].filter(r => r.recipient !== this._auth.user.FullName);
        const notificationObject: NotificationData = {
          notificationType: NotificationTypes.JOB_EXTENSION_MADE,
          notificationObject: {
            title: this.title,
            assigned_to_user,
            assigned_to_user_email,
            published_by_user,
            published_by_user_email,
            front_office_id: this.front_office_id,
            customer_name: this.customer_name,
            allbirds_job_id: this.jobData.allbirds_metadata.allbirds_job_id,
            executing_action_user: this._auth.user.FullName,
            executing_action_user_email: this._auth.user.EmailAddr,
            user: {...this._auth.user},
            emailRecipients,
            hiringManager: this.jobData.hiringManager
          }
        };
        const sub = this._notificationService
          .sendNotification(notificationObject)
          .subscribe(() => {
            sub.unsubscribe();
          });
      }
    }
  }

}
