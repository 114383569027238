<div class="modal-header" style="justify-content: flex-end;">
  <button
    type="button"
    class="close-btn"
    aria-label="Close"
    (click)="_bsModalRef.hide()"
  >
    <i class="icon-WL_icon_close"></i>
  </button>
</div>

<div class="modal-body">
  <h2>{{ "send-user-email.send_user_email" | translate }}</h2>
  <table>
    <tr>
      <td><h4>{{ "send-user-email.job_title" | translate }}</h4></td>
      <td><h4 class="gray-text ml-3">{{title}}</h4></td>
    </tr>
    <tr>
      <td><h4>{{ ((isClientOrder && customer_name) ? "send-user-email.company" : customer_priority ? "send-user-email.priority" : '') | translate }}</h4></td>
      <td><h4 class="gray-text ml-3">{{isClientOrder ? customer_name : ''}} <span class="priority" *ngIf="customer_priority">P{{customer_priority}}</span> </h4></td>
    </tr>
    <tr>
      <td><h4>{{ "send-user-email.job_id" | translate }}</h4></td>
      <td><h4 class="gray-text ml-3">{{front_office_id}}</h4></td>
    </tr>
  </table>
    <h4>{{ "send-user-email.who_do_you_send" | translate }}</h4>
    <div>
      <div class="space">
        <span>Individual(s)</span>
        <input
          [(ngModel)]="searchTerm"
          type="text"
          placeholder="{{ 'send-user-email.individual_email' | translate }}"
          class="form-control"
        />
      </div>
      <app-user-lookup
        [searchTerm]="searchTerm"
        (userResults)="checkResults($event, 'user')"
        (userSelected)="setRecipient($event)"
      >
      </app-user-lookup>
      <div class="invalid-feedback" *ngIf="noResults">{{'job-order-input.no_results_match' | translate}}</div>
      <div class="mt-2" *ngIf="individualEmailRecipients.length">
        <div
          class="d-inline ml-1"
          *ngFor="let recipient of individualEmailRecipients; let i = index"
        >
          <app-email-recipients-pill
            [emailRecipients]="individualEmailRecipients"
            [emailRecipient]="recipient"
            [index]="i"
          >
          </app-email-recipients-pill>
        </div>
      </div>
      <div class="space">
        <span>Distribution List</span>
        <div class="input-group search">
          <input
            [(ngModel)]="dListTerm"
            type="search"
            placeholder="{{ 'send-user-email.distribution_list' | translate }}"
            class="form-control"
          />
          <button
            *ngIf="useAsIs"
            id="useAsIsBtn"
            (click)="saveDistributionList()"
          >
            {{ "job-order-input.use_as_is" | translate }}
          </button>
        </div>
      </div>
      <app-distribution-list-lookup
        [searchTerm]="dListTerm"
        (distributionListSelected)="setRecipient($event)"
        (distributionListResults)="validateEmail($event)"
      >
      </app-distribution-list-lookup>
      <div class="invalid-feedback" *ngIf="noDistributionListResults">{{'job-order-input.no_results_match' | translate}}</div>
      <div class="mt-2 mb-1" *ngIf="distributionListRecipients.length">
        <div
          class="d-inline ml-1"
          *ngFor="let recipient of distributionListRecipients; let i = index"
        >
          <app-email-recipients-pill
            [emailRecipients]="distributionListRecipients"
            [emailRecipient]="recipient"
            [index]="i"
          >
          </app-email-recipients-pill>
        </div>
      </div>
  </div>
</div>

<div id="assign-job-footer" class="modal-footer">
  <button (click)="_bsModalRef.hide()" class="btn rds--button--primary">
    {{ "send-user-email.cancel" | translate }}
  </button>
  <button [disabled]="!individualEmailRecipients.length && !distributionListRecipients.length"
  (click)="sendUserEmail()" class="btn rds--button--primary">
    {{ "send-user-email.send" | translate }}
  </button>
</div>
