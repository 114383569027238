<ng-container>
  <div class="modal-header">
    <h2>Potential Duplicates for {{name}}</h2>
    <button type="button" class="btn-reset" aria-label="Close" (click)="_bsModalRef.hide()">
      <i class="icon-WL_icon_close"></i>
    </button>

  </div>

  <div class="modal-body">
    <div class="duplicates">

    <app-split-panel [minLeft]="528" [minRight]="363">

      <div class="section-2" leftPanel>
        <div class="container">
          <hr/>
          <h2>Original Talent</h2>
          <div class="row pb-1">
            <div class="col">Profile ID</div>
            <div class="col">First Name</div>
            <div class="col">Last Name</div>
            <div class="col">Phone</div>
            <div class="col-3">Email</div>
            <div class="col">Location</div>
            <div class="col">Status</div>
          </div>
          <div class="row hovered" style="background-color: #d7d7d7;" *ngIf="talent.externalId && talent.externalId.length <= 16" (click)="talentSelected(talent)"  [ngClass]="{active: isSelectedItem(talent) }">
            <div class="col suggestFields text-left" [tooltip]="talent?.externalId">{{talent.externalId}}</div>
            <div class="col suggestFields text-left">{{talent?.personNames && talent.personNames.length && talent.personNames[0]?.structuredName?.givenName}}</div>
            <div class="col suggestFields text-left">{{talent?.personNames && talent.personNames.length && talent.personNames[0]?.structuredName?.familyName}}</div>
            <div class="col suggestFields text-left">{{talent?.phoneNumbers && talent.phoneNumbers.length && talent?.phoneNumbers[0]?.number}}</div>
            <div class="col-3 suggestFields text-left" [tooltip]="talent?.emailAddresses[0]?.emailAddress">
              {{talent?.emailAddresses && talent?.emailAddresses.length && talent?.emailAddresses[0]?.emailAddress}}
            </div>
            <div class="col suggestFields text-left">
              {{ (talent?.addresses && talent?.addresses.length && talent.addresses[0]?.structuredAddress?.locality ? talent.addresses[0]?.structuredAddress?.locality + ' ,' : '')  + ( talent?.addresses && talent?.addresses.length && talent.addresses[0]?.structuredAddress?.administrativeArea ? talent.addresses[0]?.structuredAddress?.administrativeArea : '') }}
            </div>
            <div class="col suggestFields text-left" [tooltip]="talent?.allbirds_metadata?.FOTalentStatus || talent?.allbirdsMetadata?.frontOfficeTalentStatus">
              {{talent?.allbirds_metadata?.FOTalentStatus || talent?.allbirdsMetadata?.frontOfficeTalentStatus }}
            </div>
          </div>
        <hr/>
          <h2>Potential Duplicates</h2>
          <div class="row pb-1">
            <div class="col">Profile ID</div>
            <div class="col">First Name</div>
            <div class="col">Last Name</div>
            <div class="col">Phone</div>
            <div class="col-3">Email</div>
            <div class="col">Location</div>
            <div class="col">Status</div>
          </div>
          <div class="w-100" *ngFor="let result of profiles;">
            <div class="row hovered" *ngIf="result.externalId && result.externalId.length <= 16" (click)="talentSelected(result)"  [ngClass]="{active: isSelectedItem(result) }">
              <div class="col suggestFields text-left" [tooltip]="result?.externalId">{{result.externalId}}</div>
              <div class="col suggestFields text-left">{{result && result.personNames?.length && result?.personNames[0]?.structuredName?.givenName}}</div>
              <div class="col suggestFields text-left">{{result && result.personNames?.length && result.personNames[0].structuredName.familyName}}</div>
              <div class="col suggestFields text-left">{{result && result.phoneNumbers?.length && result.phoneNumbers[0].number}}</div>
              <div class="col-3 suggestFields text-left" [tooltip]="result?.emailAddresses[0]?.emailAddress">
                {{result?.emailAddresses && result?.emailAddresses?.length && result.emailAddresses[0].emailAddress}}
              </div>
              <div class="col suggestFields text-left">
                {{ (result?.addresses && result?.addresses.length && result.addresses[0]?.structuredAddress?.locality ? result.addresses[0]?.structuredAddress?.locality + ' ,' : '') + (result?.addresses && result?.addresses.length && result.addresses[0]?.structuredAddress?.administrativeArea ? result.addresses[0]?.structuredAddress?.administrativeArea : '') }}
               </div>
              <div class="col suggestFields text-left" [tooltip]="result?.allbirds_metadata?.FOTalentStatus || result?.allbirdsMetadata?.frontOfficeTalentStatus">
                {{result?.allbirds_metadata?.FOTalentStatus || result?.allbirdsMetadata?.frontOfficeTalentStatus }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section-3" rightPanel>
        <app-talent-detail-pane [isInSplitView]="true" [talent]="selectedTalent" [disableActions]="true">
        </app-talent-detail-pane>
      </div>
    </app-split-panel>
  </div>
  </div>

  <div class="modal-footer">
    <ul class="hz-btn-list">
      <li>
        <button type="button" class="btn rds--button--primary" aria-label="cancel" (click)="_bsModalRef.hide()">
         cancel
        </button>
      </li>
      <li>
        <button type="button" class="btn rds--button--secondary" aria-label="log note" (click)="submitForm()">
          select and continue
        </button>
      </li>
    </ul>
  </div>
</ng-container>