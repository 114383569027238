import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap/modal";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { Subscription } from "rxjs";
import { filter, flatMap, switchMap, take } from "rxjs/operators";
import { LoadingSpinnerService } from "src/app/shared/services/loading-spinner/loading-spinner.service";
import { SuggestEvent } from "src/app/modules/jobs/pages/job-order/job-order-input/job-order-input.component";
import { CustomListService } from "src/app/modules/talent/pages/list-details/custom-list.service";
import { List } from "src/app/shared/models/external/list.model";
import { ApiService } from "../../services/api/api.service";
import { ApptInviteService } from "../../services/appt-invite/appt-invite.service";
import { AuthService } from "../../services/auth/auth.service";
import { FormValidationService } from "../../services/form-validation/form-validation.service";
import { AllbirdsAppointment } from "../../models/external/appointment.model";
import { Appointments, ChatBots } from "@allbirds-ui/allbirds-types";
import AppointmentGoal = Appointments.AppointmentGoal;
import InterviewStatus = ChatBots.InterviewStatus;
import moment from "moment";
import { ListTalent } from "../../models/external/list-talent.model";
import { TranslateService } from "@ngx-translate/core";
import { ToastClass, ToastService } from "../../services/toast";

@Component({
  selector: "app-sched-appointment-interview-modal",
  templateUrl: "./sched-appointment-interview-modal.component.html",
  styleUrls: ["./sched-appointment-interview-modal.component.scss"],
})
export class SchedAppointmentInterviewModalComponent implements OnInit {
  newListForm: FormGroup;
  parentForm: FormGroup;
  talentName: string;
  selectedTalent: any;
  operationContext: any;
  isMassAddToList: any;
  talent: any;
  profileId: string;
  remarksValue: any;
  isStepOne = true;
  isStepOneTwo = false;
  stepOneComplete = false;
  stepTwoComplete = false;
  isNewList = false;

  displayedLists: List[];
  privateLists: List[];
  sharedLists: List[];
  initialListsSub: Subscription;
  sharedListsSub: Subscription;
  query: string;
  suggestSub: Subscription;
  currentList: string = 'private';
  listToAddId: string;
  isStepTwo = false;
  isStepThree = false;
  listPattern = /^[a-zA-Z0-9 ]*$/;

  constructor(
    private _api: ApiService,
    public bsModalRef: BsModalRef,
    private _listService: CustomListService,
    private _loading: LoadingSpinnerService,
    private _formBuilder: FormBuilder,
    private _apptInvite: ApptInviteService,
    private _auth: AuthService,
    private translate: TranslateService,
    private _toast: ToastService
  ) { }

  ngOnInit(): void {
    this.createForm();
    if (!this.isMassAddToList) {
      this.profileId = this.selectedTalent[0].profileId;
      this.talent = this.selectedTalent[0];
      this.setTalentName();
    } else {
      this.selectedTalent = this.selectedTalent;
    }
    this.setInitialLists();
  }

  createForm() {
    const recipients = this.isMassAddToList
      ? this.selectedTalent.slice(0, 250).map((t: any) => {
        return { email: t.normEmail[0], name: this.getFullName(t) };
      })
      : null;
    this.newListForm = new FormGroup({
      listName: new FormControl('',
        [
          Validators.required,
          Validators.maxLength(30),
          Validators.pattern(this.listPattern)
        ])
    });
    this.parentForm = this._formBuilder.group({
      emailRecipients: [
        recipients,
        [Validators.required, FormValidationService.validateNonEmptyArray],
      ],
      apptSettingsForm: this._apptInvite.getApptSettingsForm(),
      emailMessageForm: this._apptInvite.getEmailMessageForm(),
    });
  }

  getFullName(profile: any) {
    let returnVal = "";
    if (profile && profile.personNames && profile.personNames.length) {
      const { structuredName } = profile.personNames[0];
      returnVal = `${structuredName.givenName} ${structuredName.familyName}`;
    }
    return returnVal;
  }

  setTalentName(): void {
    if (this.talent && this.talent.personNames) {
      this.talentName = `${this.talent.personNames[0].structuredName.givenName} ${this.talent.personNames[0].structuredName.familyName}`;
    }
  }

  setInitialLists(): void {
    this.initialListsSub = this._listService.listOfListsObservable.subscribe(
      (data: List[]) => {
        if (data) {
          this.privateLists = data;
          this.displayedLists = this.privateLists;
          this.setDisabledLists();
        } else {
          this._listService.setCurrentListOfLists();
        }
      }
    );

    this.sharedListsSub = this._listService.sharedListObservable.subscribe(
      (data: List[]) => {
        if (data) {
          this.sharedLists = data;
        } else {
          this._listService.setCurrentSharedListOfLists();
        }
      }
    );
  }

  setDisabledLists(): void {
    this.displayedLists.forEach((list) =>
      this.checkIfTalentExistsInList(list.id)
    );
  }

  checkIfTalentExistsInList(listId: string): void {
    let listStatus: any = {};
    let talentIds = this.selectedTalent.map((t: any) => t.externalId);
    const selectedList: any = this.displayedLists.find(
      (list) => list.id === listId
    );
    const inList = selectedList.talent.filter((l: any) =>
      talentIds.includes(l.external_id)
    );
    const inListIds = inList.map((t: any) => t.externalId);
    const notInList = this.selectedTalent.filter(
      (f: any) => !inListIds.includes(f.external_id)
    );
    listStatus.inList = inList;
    listStatus.notInList = notInList;
    selectedList.listStatus = listStatus;
    selectedList.tooltip = this.getToolTipString(
      talentIds.length - inList.length,
      listStatus.inList.length
    );
  }

  getToolTipString(notinListLength: number, inListLength: number) {
    let toolTip = "";
    toolTip +=
      notinListLength > 1
        ? this.translate.instant("add-to-list-a-modal.add_many", {
          value1: notinListLength,
        }) + " "
        : this.translate.instant("add-to-list-a-modal.add_one", {
          value1: notinListLength,
        }) + " ";
    toolTip +=
      inListLength > 1
        ? this.translate.instant("add-to-list-a-modal.already_many", {
          value1: inListLength,
        })
        : this.translate.instant("add-to-list-a-modal.already_one", {
          value1: inListLength,
        });
    return toolTip;
  }

  suggester(event: SuggestEvent<any>): void {
    this.suggestSub = this._api
      .getListNamesSuggestions(event.value, this.currentList)
      .pipe(filter((data) => !!data))
      .subscribe((data) => {
        event.observer.next(data);
      });
  }

  setValue(event: TypeaheadMatch) {
    this.query = event.value;
    if (!this.query && this.currentList === 'private') {
      this.displayedLists = this.privateLists;
      return;
    }
    if (!this.query && this.currentList === 'shared') {
      this.displayedLists = this.sharedLists;
      return;
    }
  }

  // setDisabledLists(): void {
  //   this.displayedLists.forEach(list => this.checkIfTalentExistsInList(list.id));
  // }

  setLists(event: any): void {
    switch (event.target.value) {
      case "private":
        this.displayedLists = this.privateLists;
        this.currentList = 'private';
        // this.setDisabledLists();
        break;
      case "shared":
        this.displayedLists = this.sharedLists;
        this.currentList = 'shared';
        // this.setDisabledLists();
        break;
      default:
        break;
    }
  }

  searchListByName(): void {
    const searchByNameSub = this._api
      .getListByName(this.query, this.currentList)
      .pipe(filter((data) => !!data))
      .subscribe((data) => {
        this.displayedLists = data;
        searchByNameSub.unsubscribe();
      });
  }

  initiateNewList(): void {
    this.isNewList = true;
  }

  cancelNewList(): void {
    this.isNewList = false;
    this.newListForm.controls["listName"].setValue("");
  }

  saveNewList() {
    this._loading.show();
    const newListName = this.newListForm.controls.listName.value;
    let listObject = this._listService.buildNewListObject(newListName);
    this._api.createNewList(listObject).subscribe(
      (data) => {
        this._loading.hide();
        this._listService.setCurrentListOfLists();
        this._listService.setCurrentSharedListOfLists();
        this.newListForm.controls["listName"].setValue("");
        this.isNewList = false;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  selectListToAdd(id: string): void {
    this.listToAddId = id;
  }

  checkTalent(list: List) {
    return list.talent.find((t) => t.google_id === this.profileId);
  }

  setRemarksValue(val: any): void {
    this.remarksValue = val;
  }

  toggleStep() {
    if (this.isStepOne && !this.isStepOneTwo) {
      this.isStepOneTwo = true;
      return;
    }
    if (this.isStepOne && this.isStepOneTwo) {
      this.isStepOne = !this.isStepOne;
      this.isStepOneTwo = !this.isStepOneTwo;
      this.stepOneComplete = true;
      this.isStepTwo = !this.isStepTwo;
      return;
    }
    if (this.isStepTwo) {
      if (this.parentForm.controls.apptSettingsForm.valid) {
        this.isStepTwo = !this.isStepTwo;
        this.stepTwoComplete = true;
        this.isStepThree = !this.isStepThree;
      }
      return;
    }
    if (this.isStepThree) {
      this.onSendClick();
    }
  }

  private updateListWithTalent(
    list: List,
    talent: ListTalent,
    massTalent?: ListTalent[]
  ): List {
    const updatedList = list.clone();
    if (!updatedList.talent) {
      updatedList.talent = [];
    }
    if (!this.isMassAddToList) {
      const updatedTalent = talent.clone();
      if (this.remarksValue && this.remarksValue.generalNotes) {
        updatedTalent.general_notes = this.remarksValue.generalNotes;
      }
      if (this.remarksValue && this.remarksValue.nextAction) {
        updatedTalent.next_action = this.remarksValue.nextAction;
      }
      updatedList.talent.push(updatedTalent);
    } else if (this.isMassAddToList && massTalent && massTalent.length) {
      massTalent.forEach((talent) => {
        if (this.remarksValue && this.remarksValue.generalNotes) {
          talent.general_notes = this.remarksValue.generalNotes;
        }
        if (this.remarksValue && this.remarksValue.nextAction) {
          talent.next_action = this.remarksValue.nextAction;
        }
        updatedList.talent.push(talent);
      });
    }
    updatedList.last_update_date = moment();

    return updatedList;
  }

  updateList(list: List) {
    let updatedList: List;
    let eventLog: any = {
      logEvent: "talent was added from another list",
      originListId: null,
    };
    if (!this.isMassAddToList) {
      const newTalent = this._listService.buildNewTalent(this.talent);
      updatedList = this.updateListWithTalent(list, newTalent);
      if (updatedList.talent) {
        updatedList.talent_count = updatedList.talent.length;
      }
      eventLog = {
        ...eventLog,
        talentName: `${newTalent.first_name} ${newTalent.last_name}`,
        talentId: newTalent.external_id || newTalent.google_id,
      };
    } else {
      const filteredList = this.displayedLists.find((l) => l.id === list.id);
      let filteredTalent: any = [];
      if (filteredList) filteredTalent = filteredList.talent;
      const filteredTalentIds = filteredTalent.map((i: any) => i.external_id);
      const notInList = this.selectedTalent.filter(
        (t: any) => !filteredTalentIds.includes(t.externalId)
      );
      notInList.forEach((e: any) => delete e.appointments);
      const notInListTalent: any = [];
      notInList.forEach((e: any) => {
        const newTalent = this._listService.buildNewTalent(e);
        notInListTalent.push(newTalent);
      });
      updatedList = this.updateListWithTalent(list, null, notInListTalent);
      eventLog = {
        ...eventLog,
        talentName: notInList
          .map((talent: any) => `${talent.first_name} ${talent.last_name}`)
          .join(","),
        talentId: notInList
          .map((talent: any) => talent.external_id || talent.google_id)
          .join(","),
      };
    }
    updatedList.talent_count = updatedList.talent.length;
    return this._api.updateList(updatedList, eventLog);
  }

  addToList(): void {
    this._api
      .searchList(this.listToAddId)
      .pipe(
        flatMap((list) => {
          return this.updateList(list);
        })
      )
      .pipe(take(1))
      .subscribe(
        (data) => {
          this._listService.setCurrentListOfLists(); // If you add a talent and go back to talent dashboard, the counts will update
          this._listService.setCurrentSharedListOfLists();
        },
        (err) => {
          console.error(err);
          this._loading.hide();
        }
      );
  }

  showSuccessToast(id: string): void {
    const trList = this.translate.instant("add-to-list-a-model.see_list");
    const trSuccess = {
      value: (this.isMassAddToList) ? 'candidates ' : this.talentName,
    };
    const toastCfg = {
      cssClass: ToastClass.SUCCESS,
      actionLinks: [
        {
          text: trList,
          routerLink: `talent/list/${id}`,
        },
      ],
    };
    this._toast.showToastWithVariables(
      "add-to-list-a-model.success_added",
      trSuccess,
      toastCfg
    );
  }

  createAppointments(payload: AllbirdsAppointment[]) {
    this._apptInvite
      .createAppointments(payload)
      .pipe(
        switchMap((result: any) => {
          const message = this.parentForm.get("emailMessageForm.message").value;
          const validProfiles = this._apptInvite.getValidRecipientProfiles(
            this.selectedTalent,
            this.parentForm.get("emailRecipients").value
          );
          const recips = this._apptInvite.getRecipientData(
            validProfiles,
            result.appointments
          );
          this._loading.hide();
          this.bsModalRef.hide();
          this.showSuccessToast(this.listToAddId);
          return this._apptInvite.sendInvites(
            recips,
            "interview appointment",
            message
          );
        })
      )
      .subscribe(() => {
        // do nothing
      });
  }

  handleInvalid() {
    const controls = this.parentForm.controls;
    const invalidControls: { [key: string]: FormControl } = {};
    // recursion.... yuck
    const checkControlValidity = (
      control: AbstractControl,
      fieldName: string
    ) => {
      if (control.invalid) {
        if (control instanceof FormGroup) {
          Object.keys(control.controls).forEach((key) => {
            const ctrl = control.controls[key];
            ctrl.markAsTouched();
            ctrl.updateValueAndValidity();
            checkControlValidity(ctrl, key);
          });
        } else if (control instanceof FormControl) {
          invalidControls[fieldName] = control;
        }
      }
    };
    Object.keys(controls).forEach((key) => {
      const control = controls[key];
      checkControlValidity(control, key);
    });
    return invalidControls;
  }

  async normalizeTalent() {
    if (this.isMassAddToList) {
      this.selectedTalent.forEach((t: any) => {
        t.emailAddresses = t.emailAddresses || [];
        if (t.emailAddresses.length === 0) {
          const emailAddress =
            (t.normEmail && t.normEmail.length > 0 && t.normEmail[0]) || "";
          t.emailAddresses = [{ emailAddress }];
        }
      });
    }
    return this.selectedTalent;
  }

  async onSendClick() {
    if (this.parentForm.valid) {
      this._loading.show();
      this.addToList();
      await this.normalizeTalent();
      const payload = await this.getPayload();
      this.createAppointments(payload);
    } else {
      const invalidControls = this.handleInvalid();
      const locationObj: any = {
        page1: [
          "apptAddress",
          "apptGoal",
          "apptType",
          "interviewDuration",
          "emailRecipients",
        ],
        page2: ["message"],
      };
      if (invalidControls && Object.keys(invalidControls).length > 0) {
        if (locationObj.page1.includes(Object.keys(invalidControls)[0])) {
          this.isStepOne = true;
        } else if (
          locationObj.page2.includes(Object.keys(invalidControls)[0])
        ) {
          this.isStepOne = false;
        }
      }
    }
  }

  async getPayload() {
    const payload: AllbirdsAppointment[] = [];
    const validRecipients = this._apptInvite.getValidRecipientProfiles(
      this.selectedTalent,
      this.parentForm.get("emailRecipients").value
    );
    validRecipients.forEach((talent) => {
      const rawAppt: Appointments.AllbirdsAppointment = {
        appointmentInvite: {
          agentID: this._auth.user.BackOfficeID,
          message: this.parentForm.get("emailMessageForm.message").value,
          timestamp: new Date().toISOString(),
        },
        appointment: {
          candidateEmail:
            talent.emailAddresses && talent.emailAddresses.length
              ? talent.emailAddresses[0].emailAddress
              : "",
          interviewDuration: parseInt(
            this.parentForm.get("apptSettingsForm.interviewDuration").value
          ),
          interviewGoal: this.parentForm.get("apptSettingsForm.apptGoal")
            .value as AppointmentGoal,
          interviewStatus: InterviewStatus.INVITE_SENT,
          interviewType: this._apptInvite.getInterviewTypeValue(
            this.parentForm.get("apptSettingsForm.apptType").value
          ),
          interviewAddress: this.parentForm.get("apptSettingsForm.apptAddress")
            .value,
          recruiterEmail: this._auth.user.EmailAddr,
        },
        job: "",
        listID: this.listToAddId,
        jobElasticID: "",
        profile: talent.name,
        profileElasticID: talent.externalId,
        profileFrontOfficeID: talent.externalId,
        lastUpdated: new Date().toISOString(),
        lastUpdatedBy: this._auth.user.BackOfficeID,
      };
      payload.push(AllbirdsAppointment.deserialize(rawAppt));
    });
    return payload;
  }
}
