/**
 * This allows us to smoothly scroll back to the proper anchor when an invalid
 * field is detected on validation.
 *
 * fieldName  => corresponds with the name of the control on the FormGroup object.
 * anchor     => the id on the HTML element of that form control (allows us to scroll to that form control).
 * errorLabel => the name of the field displayed on the toast in the UI when an error occurs.
 *
 * ORDER IS IMPORTANT! This ensures that the smooth scrolling brings the user back
 * to the very first error detected in the form (from step one --> top-to-bottom).
 */

export const VALIDATED_FORM_FIELDS = [
  // [Step 1] Job Details Fields
  { fieldName: 'internalTitle', anchor: '#internalTitle', errorLabel: 'Internal title' },
  { fieldName: 'hiringManager', anchor: '#client', errorLabel: 'Hiring manager' },
  { fieldName: 'customer_name', anchor: '#client', errorLabel: 'Customer name' },
  { fieldName: 'client_partner_id', anchor: '#client', errorLabel: 'Client Partner'},
  { fieldName: 'customer_industry', anchor: '#client', errorLabel: 'Customer industry' },
  { fieldName: 'contact_id', anchor: '#client', errorLabel: 'Contact ID' },
  { fieldName: 'internalDescription', anchor: '#job_description', errorLabel: 'Client job description' },
  { fieldName: 'employmentTypes', anchor: '#candidate', errorLabel: 'Employment type' },
  { fieldName: 'allow_third_party_vendors', anchor: '#candidate', errorLabel: 'Allow third party vendors' },
  { fieldName: 'allow_dbc_candidates', anchor: '#candidate', errorLabel: 'Allow RITS candidates' },
  { fieldName: 'allow_independent_contractors', anchor: '#candidate', errorLabel: 'Allow independent contractors' },
  { fieldName: 'will_sponsor_visa', anchor: '#candidate', errorLabel: 'Will sponsor visa' },
  { fieldName: 'submission_deadline', anchor: '#candidate', errorLabel: 'Submission deadline' },
  { fieldName: 'client_interview_date', anchor: '#candidate', errorLabel: 'Client Interview Date' },
  { fieldName: 'exempt', anchor: '#candidate', errorLabel: 'Exempt' },
  { fieldName: 'number_of_openings', anchor: '#openings', errorLabel: 'Number of openings' },
  { fieldName: 'number_of_linked_jobs', anchor: '#linkedJobs', errorLabel: 'Number of linked jobs' },
  { fieldName: 'customer_priority', anchor: '#openings', errorLabel: 'Customer priority' },
  { fieldName: 'internal_job_title', anchor: '#openings', errorLabel: 'Internal job title' },
  { fieldName: 'order_source', anchor: '#openings', errorLabel: 'Order source' },
  { fieldName: 'order_status', anchor: '#openings', errorLabel: 'Order status' },
  { fieldName: 'jobStartTime', anchor: '#openings', errorLabel: 'Job start time' },
  { fieldName: 'jobEndTime', anchor: '#openings', errorLabel: 'Job end time' },
  { fieldName: 'salary_type', anchor: '#compensation', errorLabel: 'Salary type' },
  { fieldName: 'published_min_pay_rate', anchor: '#compensation', errorLabel: 'Published minimum pay rate' },
  { fieldName: 'published_max_pay_rate', anchor: '#compensation', errorLabel: 'Published maximum pay rate' },
  { fieldName: 'will_relocate', anchor: '#compensation', errorLabel: 'Will relocate' },
  { fieldName: 'shifts', anchor: '#working', errorLabel: 'Shifts' },
  { fieldName: 'work_hours_start', anchor: '#working', errorLabel: 'Work hours start' },
  { fieldName: 'work_hours_end', anchor: '#working', errorLabel: 'Work hours end' },
  { fieldName: 'weekly_hours', anchor: '#working', errorLabel: 'Weekly hours' },
  { fieldName: 'expected_overtime_hours', anchor: '#working', errorLabel: 'Expected overtime hours' },
  { fieldName: 'weekend_work_required', anchor: '#working', errorLabel: 'Weekend work required' },
  { fieldName: 'allow_remote_work', anchor: '#working', errorLabel: 'Allow remote work' },
  { fieldName: 'travel_requirements', anchor: '#working', errorLabel: 'Travel requirements' },
  { fieldName: 'work_location', anchor: '#workLocation', errorLabel: 'Work location' },
  { fieldName: 'work_address', anchor: '#workLocation', errorLabel: 'Work address' },
  { fieldName: 'work_city', anchor: '#workLocation', errorLabel: 'Work city' },
  { fieldName: 'work_state', anchor: '#workLocation', errorLabel: 'Work state' },
  { fieldName: 'work_zip', anchor: '#workLocation', errorLabel: 'Work zip code' },
  { fieldName: 'work_country', anchor: '#workLocation', errorLabel: 'Work country' },
  { fieldName: 'bill_to_location', anchor: '#commercial', errorLabel: 'Bill to location' },
  { fieldName: 'bill_rate', anchor: '#commercial', errorLabel: 'Bill rate' },
  { fieldName: 'internal_max_pay_rate', anchor: '#commercial', errorLabel: 'Max pay rate' },
  { fieldName: 'target_salary', anchor: '#commercial', errorLabel: 'Target salary' },
  { fieldName: 'perm_fee', anchor: '#commercial', errorLabel: 'Perm fee' },
  { fieldName: 'mark_up', anchor: '#commercial', errorLabel: 'Mark up' },
  { fieldName: 'calculated_gp', anchor: '#commercial', errorLabel: 'Calculated GP' },
  { fieldName: 'rev_share_flag', anchor: '#commercial', errorLabel: 'Revenue share flag' },
  { fieldName: 'interview_info', anchor: '#commercial', errorLabel: 'Interview information' },
  { fieldName: 'exclusive_req', anchor: '#commercial', errorLabel: 'Exclusive hire' },
  { fieldName: 'internalDescription', anchor: '#commercial', errorLabel: 'Internal description' },
  { fieldName: 'skills', anchor: '#skills', errorLabel: 'Skills' },
  { fieldName: 'responsibilities', anchor: '#skills', errorLabel: 'Responsibilities' },
  { fieldName: 'service_line', anchor: '#skills', errorLabel: 'Service Line' },
  { fieldName: 'sub_service_line', anchor: '#skills', errorLabel: 'Sub Service Line' },
  { fieldName: 'heavy_lifting', anchor: '#physicalRequirements', errorLabel: 'Heavy lifting' },
  { fieldName: 'heavy_lifting_weight', anchor: '#physicalRequirements', errorLabel: 'Heavy lifting (weight)' },
  { fieldName: 'consistently_standing', anchor: '#physicalRequirements', errorLabel: 'Consistently standing' },
  { fieldName: 'consistently_standing_hours', anchor: '#physicalRequirements', errorLabel: 'Consistently standing (hours)' },
  { fieldName: 'temperature_controlled', anchor: '#physicalRequirements', errorLabel: 'Temperature controlled' },
  { fieldName: 'smoke_free_environment', anchor: '#physicalRequirements', errorLabel: 'Smoke free environment' },
  { fieldName: 'steel_toe_shoes_required', anchor: '#physicalRequirements', errorLabel: 'Steel toe shoes required' },
  { fieldName: 'drug_screening', anchor: '#physicalRequirements', errorLabel: 'Drug screening required' },
  { fieldName: 'type_of_work_environment', anchor: '#physicalRequirements', errorLabel: 'Type of work environment' },
  { fieldName: 'minimum_years_of_experience', anchor: '#experience', errorLabel: 'Minimum years of experience' },
  { fieldName: 'experience_level', anchor: '#experience', errorLabel: 'Experience level' },
  { fieldName: 'education_requirement', anchor: '#experience', errorLabel: 'Education requirement' },
  { fieldName: 'education_must_have', anchor: '#experience', errorLabel: 'Education must have' },
  { fieldName: 'job_category', anchor: '#experience', errorLabel: 'Job category' },
  // [Step 2] Advertise Online Fields
  { fieldName: 'title', anchor: '#published', errorLabel: 'Title' },
  { fieldName: 'compelling_intro', anchor: '#published', errorLabel: 'Job advertisement' },
  // [Step 3] Screening/interview scheduler
  { fieldName: 'max_auto_schedules_allowed', anchor: '#interview', errorLabel: 'Max auto schedules allowed' },
  { fieldName: 'auto_scheduler_interview_channel', anchor: '#interview', errorLabel: 'Auto scheduler interview channel' },
  { fieldName: 'auto_scheduler_location', anchor: '#interview', errorLabel: 'Auto scheduler location' }
];
