// tslint:disable:member-ordering
import { Injectable, OnDestroy } from '@angular/core';
import { TalentSortTypes } from '../../../modules/talent/pages/talent-management-listings/TalentSortTypes';
import { ApiService } from '../api/api.service';
import { BehaviorSubject, noop, Observable, Subject, Subscription } from 'rxjs';
import { TalentProcessService } from '../talent-process/talent-process.service';
import { ShortlistActionResponse } from '../../models/internal/shortlist-action-response.interface';
import { ApplicationRejectionData } from '../../models/internal/application-rejection-data.interface';
import { UtilityService } from '../utility/utility.service';
import { ApplicationUpdateEvent } from '../../models/internal/step-change-event.interface';
import { SortingService } from '../sorting';
import { ShortlistFilterProps } from '../talent-process/process-statuses';
import { SkipEvent } from '../../models/internal/skip-event.interface';
import { CLIENT_EVENT_TYPES } from '../client-event/client-event.types';
import { ProcessStatus, ProcessStep } from 'src/app/shared/models/external/misc.model';
import { Job } from '../../models/external/job.model';
import { Application } from '../../models/external/application.model';
import { Profile } from '../../models/external/profile.model';
import { RequestBody } from '../../models/api/application-request.interface';
import { JobMetrics } from '../../models/external/job-metrics.model';
import { Talent } from '../../models/external/talent.interface';
import { TranslateService } from '@ngx-translate/core';
import AddToShortlistRequest = RequestBody.AddToShortlistRequest;
import { UrlStateService } from 'src/app/shared/services/url-state/url-state.service';
import { INTG_STEPS } from '../../models/internal/process.model';
import { JobOrderService } from '../job-order/job-order.service';
import { User } from '../../models/external/user.model';
import { AuthService } from '../auth/auth.service';
import {LoadingSpinnerService} from '../loading-spinner/loading-spinner.service';

@Injectable({
  providedIn: 'root',
})
export class JobDetailsShortlistService implements OnDestroy {

  // Total number of applications we fetch per API call.
  static readonly FETCH_COUNT = 10;

  // Allbirds job data.
  jobData: Job;

  // Active step.
  activeStep?: ProcessStep;

  // User input query string to search inside the shortlist
  searchQuery: string

  // Active sort enum.
  activeSort: TalentSortTypes;

  // Active filters map.
  activeFilters: { [status: string]: ShortlistFilterProps };

  // Index of where to begin pulling results from the result set (infinity scroll).
  public from: number;

  // Total number of talent in the shortlist.
  total: number;

  // Flag that determines if we need to keep fetching results.
  keepFetching: boolean;

  // Flag that determines if we are currently fetching results.
  isFetching: boolean;

  // Counts for each step of a particular job.
  public metrics: JobMetrics;

  // Map containing the Google IDs of talent already shortlisted.
  public rejectedIds: { [id: string]: boolean };

  // Map containing the Google IDs of talent already rejected.
  public shortlistedIds: { [id: string]: boolean };

  // Map containing the Google IDs of talent that has the pre-screening invities sent out
  public hiddenIds: { [id: string]: boolean };
  // Map containing the Google IDs of talent to exclude from the fetch.
  public excludedIds: { [id: string]: boolean };

  // Holds all visible shortlist talent.
  public talentSubject = new BehaviorSubject<Application[]>([]);
  public talent: Observable<Application[]> = this.talentSubject.asObservable();

  // Holds all visible shortlist rejected talent.
  public rejectedSubject = new BehaviorSubject<Application[]>([]);
  public rejectedTalent: Observable<Application[]> = this.rejectedSubject.asObservable();

  // Holds the applications for rejected potential talent (used in cases like mass action, etc.)
  public rejectedPotentialTalent: { [id: string]: Application };

  // Used to listen and react to the updated application subject (emits events on update).
  private readonly updatedApplicationSub: Subscription;

  // Used to emit client events to various subscribers (metrics & client event services).
  private clientEventSubject = new Subject<{ profile: Profile | Application, eventType: string }>();
  public clientEvent = this.clientEventSubject.asObservable();

  // Used to emit an application that should be updated by the subscribing component
  // instead of by a subject update (chatbot updates).
  private localUpdateSubject = new Subject<Application>();
  public localApplicationUpdate = this.localUpdateSubject.asObservable();

  // Used to listen for when the stored job order changes.
  // This is needed because we only initialize this service with the job on load;
  // thus, job updates that may occur after that will not propagate to this service without this.
  private updatedJobSub: Subscription;

  constructor(
    private _api: ApiService,
    private _process: TalentProcessService,
    // private _clientEvent: ClientEventService,
    private translate: TranslateService,
    private _url: UrlStateService,
    private _job: JobOrderService,
    private _auth: AuthService,
    private _loading: LoadingSpinnerService
  ) {
    this.updatedApplicationSub = this._process.updatedApplication.subscribe(this.handleApplicationUpdate.bind(this));
  }

  ngOnDestroy(): void {
    // Unsubscribe from all used subscriptions.
    if (this.updatedApplicationSub) {
      this.updatedApplicationSub.unsubscribe();
    }
    if (this.updatedJobSub) {
      this.updatedJobSub.unsubscribe();
    }
  }

  /**
   * Returns the active filters as an array (used above the shortlist where
   * we display active filter pills).
   */
  get activeFilterArray(): ShortlistFilterProps[] {
    return this.activeFilters ? Object.values(this.activeFilters) as ShortlistFilterProps[] : [];
  }

  /**
   * Shortlist error handler for API calls.
   * @param err
   */
  static handleError(err: any) {
    /**
     * TODO: This is only used for fatal errors (on fetching metadata and talent).
     * Can eventually implement a flag for displaying error on the UI.
     */
    console.error('[handleShortlistError]', err);
  }

  /**
   * Really just used to help ensure interviewSchedule becomes interview.
   * @param stepKey
   */
  static getNormalizedStepKey(stepKey: ProcessStep): ProcessStep {
    return (stepKey === ProcessStep.INTERVIEW_SCHEDULE) ? ProcessStep.INTERVIEW_RECRUITER : stepKey;
  }

  /**
   * Returns the request body needed for addToShortlist requests.
   * @param profile - talent profile object
   * @param job - AB job object
   */
  static constructAddToShortlistRequestBody(profile: Profile, job: Job): AddToShortlistRequest {
    const body = <AddToShortlistRequest>profile.clone();
    body.ids = {
      google_job_id: job.name,
      front_office_id: job.allbirds_metadata.front_office_id,
      allbirds_job_id: job.allbirds_metadata.allbirds_job_id,
      job_customer_id: job.allbirds_metadata.customer_id,
      job_contact_id: job.allbirds_metadata.contact_id,
      job_user_branch_id: job.allbirds_metadata.user_branch_id
    };
    return body;
  }

  /**
   * Returns the request body needed for rejectPotentialTalent requests.
   * @param profile - talent profile object
   * @param job - AB job object
   * @param rejectionData - object containing rejection details that abide by the ApplicationRejectionData interface
   */
  static getRejectPotentialTalentRequestBody(profile: Profile, job: Job, rejectionData?: ApplicationRejectionData, intgSteps: INTG_STEPS[] = []): any {
    const result = {
      talent: {
        ...profile,
        ids: {
          front_office_id: job.allbirds_metadata.front_office_id,
          allbirds_job_id: job.allbirds_metadata.allbirds_job_id,
          google_job_id: job.allbirds_metadata.google_job_id,
          job_user_branch_id: job.allbirds_metadata.user_branch_id
        }
      },
      rejectReason: (rejectionData || {}).rejectReason || '',
      rejectNote: (rejectionData || {}).rejectNote || ''
    };
    /**
     * We have cases where the reject reason can be an array of strings as well as a string itself.
     * Since this field is defined as a string in the .proto, in cases we're given an array of reasons,
     * we'll construct a string that will eventually be converted back into an array in the microservice.
     */
    if (!result.rejectReason && rejectionData.reason) {
      result.rejectReason = rejectionData.reason.join('%%%');
    }
    return result;
  }

  /**
   * Should be called whenever this service is used for a new job. Essentially
   * resets the state to that of a brand new load.
   * @param job - allbirds job
   */
  public initialize(job: Job) {
    this.jobData = job;
    this.activeSort = TalentSortTypes.LAST_UPDATED_DESCENDING;
    this.activeFilters = {};
    this.keepFetching = true;
    this.isFetching = false;
    this.from = 0;
    this.total = 0;
    this.metrics = new JobMetrics();
    this.rejectedIds = {};
    this.shortlistedIds = {};
    this.excludedIds = {};
    this.rejectedPotentialTalent = {};
    this.talentSubject.next([]);
    this.rejectedSubject.next([]);
    this.fetchMetadata(job.allbirds_metadata.allbirds_job_id);
    this.listenToJobChanges();
  }

  /**
   * Returns the request parameters as an object that the API call
   * function is expecting.
   */
  private getRequestBody(selectedAppId?: string, returnTalentCount?: number): RequestBody.GetShortlistRequest {
    if (this.activeFilters) {
      const filterKeys = Object.keys(this.activeFilters);
      filterKeys.indexOf(ProcessStatus.IR_RESCHEDULE_DECLINED_INTERVIEW) > -1 ? filterKeys.push(ProcessStatus.IR_RESCHEDULE_INCONVENIENT_INTERVIEW) : noop();
      return {
        jobId: this.jobData.allbirds_metadata.allbirds_job_id,
        sort: this.activeSort,
        filters: filterKeys,
        from: this.from,
        stepKeys: !!this.activeStep ? [this.activeStep] : [],
        exclude: Object.keys(this.excludedIds),
        shouldInclude: selectedAppId,
        size: returnTalentCount ? returnTalentCount : 10,
        searchQuery: this.searchQuery || undefined
      };
    }
  }

  /**
   * Fetches the shortlist metadata from the API.
   * @param jobId - Allbirds job id
   */
  private fetchMetadata(jobId: string): Observable<any> {
    const obs = this._api.getShortlistMetadata(jobId);
    obs.subscribe(res => {
      const { metrics, rejectedIds, shortlistedIds, hiddenIds, total } = res;
      this.handleMetadataResponse(metrics, rejectedIds, shortlistedIds, hiddenIds, total);
    }, (err: any) => {
      JobDetailsShortlistService.handleError(err);
    });
    return obs;
  }

  /**
   * Using the state of the sort, filter, steps, etc., fetches shortlist
   * talent and appends the results.
   */
  public fetchTalent(fetchMetrics: boolean = false, forceFetch?: boolean, selectedAppId?: string, returnTalentCount?: number): Observable<any> | null {
    // If activeStep is truthy (not empty string) and the metrics for
    // this step say there's zero talent, then don't even fetch.
    // console.log("this.activeStep", this.activeStep , "forceFetch", forceFetch,
    // "fetchMetrics=", fetchMetrics, "selectedAppId", selectedAppId);
    this._loading.show();
    if (this.activeStep && !this.metrics[this.activeStep] && !forceFetch && !fetchMetrics) {
      this._loading.hide();
      return null;
    }
    // If we're already fetching, don't trigger another load yet.
    if (this.isFetching) {
      this._loading.hide();
      return null;
    }
    // Otherwise, if we should still keep fetching, do so.
    if (this.keepFetching) {
      this.isFetching = true;
      const obs = this._api.getShortlistByJobId(this.getRequestBody(selectedAppId, returnTalentCount));
      obs.subscribe(res => {
        this.handleTalentResponse(res.applications);
      }, (err: any) => {
        this._loading.hide();
        JobDetailsShortlistService.handleError(err);
      });
      return obs;
    }
    this._loading.hide();
    return null;
  }

  /**
   * Handles the response from the fetchTalent call.
   * @param response
   */
  private handleTalentResponse(applications: Application[]) {
    let nonrejected: Application[] = this.talentSubject.getValue();
    let rejected: Application[] = this.rejectedSubject.getValue();
    const alreadyFetched = JobDetailsShortlistService.getApplicationIdMap([...nonrejected, ...rejected]);
    // Iterate over response; add applications to proper bucket based on rejection status.
    for (const app of applications) {
      /**
       * lastProcessStep is falsy ('') for rejected potential talent so we don't add
       * them to the shortlist -- however, we do keep them stored in a map.
       */
      if (
        app.profileId &&
        !alreadyFetched[app.profileId] &&
        app.randstad_process &&
        app.randstad_process.lastProcessStep
      ) {
        TalentProcessService.setTalentStepNumber(app, this.jobData.allbirds_metadata.lob);
        if (app.randstad_process.rejected) {
          rejected.push(app);
        } else {
          nonrejected.push(app);
        }
      } else {
        this.rejectedPotentialTalent[app.profile] = app;
      }
    }
    /**
     * Rejected talent sorting gets messed up in certain cases due to sort taking rejection
     * status as higher priority at the ES query level. Thus, we re-sort in-memory.
     */
    if (nonrejected.length) {
      nonrejected = SortingService.sortApplicationArray(nonrejected, this.activeSort);
    }
    if (rejected.length) {
      rejected = SortingService.sortApplicationArray(rejected, this.activeSort);
    }
    this.talentSubject.next(nonrejected);
    this.rejectedSubject.next(rejected);
    this.from += JobDetailsShortlistService.FETCH_COUNT;
    this.keepFetching = this.from < this.total;
    this.isFetching = false;
    this._loading.hide();
  }

  /**
   * Takes the response from the getShortlistMetadata API call and maps its response
   * over to some of our JobDetailsShortlistService class properties.
   * @param metadata - getShortlistMetadata API response.
   */
  private handleMetadataResponse(metrics: JobMetrics, rejectedIds: string[], shortlistedIds: string[], hiddenIds: string[], total: number): void {
    this.metrics = metrics;
    this.rejectedIds = UtilityService.transformStringArrayIntoMap(rejectedIds) || {};
    this.shortlistedIds = UtilityService.transformStringArrayIntoMap(shortlistedIds) || {};
    this.hiddenIds = UtilityService.transformStringArrayIntoMap(hiddenIds) || {};
    this.total = total;
  }

  /**
   * Sets the active step and begins fetching applications.
   * @param step - step key
   */
  public setActiveStep(step: ProcessStep | null, forceFetch?: boolean): void {
    this._url.patch({ shortlist_step: step }, false);
    this.stageAndFetch(() => {
      this.activeStep = step;
    }, true);
  }

  public setInitialActiveStep(step: ProcessStep | null, forceFetch?: boolean, selectedAppId?: string): void {
    // console.log("[setInitialActiveStep]", selectedAppId);
    this.stageAndFetch(() => {
      this.activeStep = step;
    }, true, true, selectedAppId);
  }

  /**
   * Sets the new search query and fetch applications that matches the user input query.
   * @param query - user's input
   */
  public setNewSearchQuery(query: string) {
    this.stageAndFetch(() => {
      this.searchQuery = query;
    });
  }

  /**
   * Sets the active sort and begins fetching applications.
   * @param sort - sort type
   */
  public setActiveSort(sort: TalentSortTypes) {
    if (sort === this.activeSort) {
      return;
    }
    this.stageAndFetch(() => {
      this.activeSort = sort;
    });
  }

  /**
   * Sets the filters selected and begins fetching applications.
   * @param newFilters - filter array
   */
  public setFilters(newFilters: ShortlistFilterProps[]) {
    // Transform the array of filters into a map.
    const filters: { [status: string]: ShortlistFilterProps } = {};
    //Handle changes for supporting multiple status for each filter
    for (const f of newFilters) {
      filters[f.status.join()] = f;
    }


    // Begin the new search.
    this.stageAndFetch(() => {
      this.activeFilters = filters;
    });
  }

  /**
   * Returns true if the filter is active.
   * @param filter - the key of the filter
   */
  public isFilterActive(filter: ProcessStatus | 'ADDED_BY_ME' | 'APPLICANT' | 'ALL' | 'ILABOR') {
    return this.activeFilters ? !!this.activeFilters[filter] : false;
  }

  /**
   * Given some middleware function, stages a new query by resetting the query parameters
   * that affect infinity scroll, executes the middleware, and then fetches for talent.
   * @param middleware - middleware function to execute after staging the query but before fetching talent.
   */
  public stageAndFetch(middleware?: Function, forceFetch?: boolean, isInitialFetch: boolean = true, selectedAppId?: string) {
    this.stageNewQuery();
    if (middleware) {
      middleware();
    }
    return this.fetchTalent(forceFetch, isInitialFetch, selectedAppId);
  }

  /**
   * Resets the parameters that affects the fetch talent result set to
   * the default state.
   */
  private stageNewQuery(): void {
    this.from = 0;
    this.keepFetching = true;
    this.excludedIds = {};
    this.searchQuery = '';
    this.talentSubject.next([]);
    this.rejectedSubject.next([]);
  }

  /**
   * Given a talent profile or application, returns true/false depending on the shortlisted status.
   * This is contingent on shortlistedIds being properly populated after fetching shortlist metadata.
   * @param talent - application or profile object
   */
  public isShortlisted(talent: Talent): boolean {
    if (!this.shortlistedIds) {
      this.shortlistedIds = {};
    }
      return talent?.profileId in this.shortlistedIds;
  }

  /**
   *  DF044-4487
   * Given a talent profile or application, returns true/false depending on the pre-screening (hidden from shortlist) status.
   * This is contingent on hiddenIds being properly populated after fetching shortlist metadata.
   * @param talent - application or profile object
   */
  public isHidden(talent: Talent): boolean {
    if (!this.hiddenIds) {
      this.hiddenIds = {};
    }
    return talent?.profileId in this.hiddenIds;
  }

  /**
   * Given a talent profile or application, returns true/false depending on the rejection status.
   * This is contingent on rejectedIds being properly populated after fetching shortlist metadata.
   * @param talent - application or profile object
   */
  public isRejected(talent: Talent): boolean {
    if(!talent){
      return false
    }
    if (talent.isApplication()) {
      return (talent.randstad_process && talent.randstad_process.rejected) || false;
    }
    if (!this.rejectedIds) {
      this.rejectedIds = {};
    }
    return talent?.profileId in this.rejectedIds;
  }

  /**
   * Returns the total number of non-rejected talent.
   */
  get totalNonrejected(): number {
    return (
      this.shortlistedIds &&
      this.rejectedIds &&
      (Object.keys(this.shortlistedIds).length - Object.keys(this.rejectedIds).length)
    ) || 0;
  }

  /**
   * Returns the number of applications in the shortlist that are not rejected.
   * This only includes LOADED applications.
   */
  public getNonrejectedCount(): number {
    return this.talentSubject.getValue().length;
  }

  /**
   * Returns the number of applications in the shortlist that are rejected.
   * This only includes LOADED applications.
   */
  public getRejectedCount(includeUnloaded: boolean = false): number {
    if (includeUnloaded) {
      return Object.keys(this.rejectedIds).length;
    }
    return this.rejectedSubject.getValue().length;
  }

  /**
   * Returns the number of applications in the shortlist (both rejected and non-rejected).
   * This only includes LOADED applications.
   */
  public getShortlistCount(): number {
    return this.talentSubject.getValue().length + this.rejectedSubject.getValue().length;
  }

  /**
   * Given a talent profile and job order, adds a talent to that job's shortlist. By default, the job
   * parameter references the service's jobData property that is set on initialization.
   * @param profile - talent profile object
   * @param job - AB job object
   */
  public add(profile: Profile, job: Job = this.jobData): Promise<ShortlistActionResponse> {
    return new Promise((resolve, reject) => {
      // If the talent is already shortlisted, block the action.
      if (this.isShortlisted(profile)) {
        const trShortlisted = this.translate.instant('job-detail-shortlist_service.already_shortlisted');
        return reject({ message: trShortlisted });
      }
      
      //If the talent has disqualified flag  block the action.
      if(profile.isDisqualified){
        const trError = this.translate.instant('job-detail-shortlist_service.isDisqualified');
        return reject({ message: trError });
      }

      // If the talent is not in the same opco as the job & user, block the action.
      if (!JobDetailsShortlistService.opcoDoesMatch(profile, job, this._auth.user)) {
        const trError = this.translate.instant('job-detail-shortlist_service.shortlist-xopco-error');
        return reject({ message: trError });
      }

      // Generate shortlist request body from the given profile and job.
      const body = JobDetailsShortlistService.constructAddToShortlistRequestBody(profile, job);

      // Make API call and handle response..
      this._api.addToShortlist(body)
        .subscribe(res => {
          if (!res) {
            const trInvalid = this.translate.instant('job-detail-shortlist_service.invalid_response_shortlist');
            return reject({
              message: trInvalid
            });
          }
          // Normalize the lastProcessStep before using it (b/c interview and interviewSchedule are technically the same step).
          let { lastProcessStep } = res.randstad_process;
          lastProcessStep = JobDetailsShortlistService.getNormalizedStepKey(lastProcessStep);
          // Increment in-memory metrics since they're only calculated and retrieved on page load.
          this.metrics[lastProcessStep]++;
          this.total++;
          // Add the Google profile id to the shorlistedIds map so UI knows its shortlisted.
          this.shortlistedIds[res.profile] = true;
          delete this.rejectedIds[res.profile];
          // Emit a client event.
          this.emitClientEvent(CLIENT_EVENT_TYPES.PROFILE_SHORTLIST, profile);
          const trVariable = {
            'value1': profile.personNames[0].structuredName.givenName,
            'value2': profile.personNames[0].structuredName.familyName
          };
          const trShortlist = this.translate.instant('job-detail-shortlist_service.added_shortlist', trVariable);

          return resolve({
            application: res,
            message: trShortlist
          });
        }, err => {
          console.error('[addToShortlist]', err);
        });
    });
  }

  /**
   * Returns the rejection function that should be used depending on if the talent is
   * a potential talent (first param is TalentProfile object) or shortlisted talent
   * (first param is Application object).
   * @param talent - talent application or profile object
   * @param rejectionData - rejection data
   */
  public reject(talent: Application | Profile, rejectionData?: ApplicationRejectionData, intgSteps: INTG_STEPS[] = []): Promise<ShortlistActionResponse> {
    return talent.isApplication()
      ? this.rejectShortlistTalent(talent, rejectionData, intgSteps)
      : this.rejectPotentialTalent(talent, rejectionData, intgSteps);
  }

  /**
   * Handles rejecting already-shortlisted talent.
   * @param application - talent application object
   * @param rejectionData - rejection data
   */
  private rejectShortlistTalent(application: Application, rejectionData?: ApplicationRejectionData, intgSteps: INTG_STEPS[] = []): Promise<ShortlistActionResponse> {
    return new Promise((resolve, reject) => {
      // Check if this talent is already rejected.
      const trVariable = { 'value': application.randstad_process.candidateFullName };
      const trNotFit = this.translate.instant('job-detail-shortlist_service.already_not_fit', trVariable);
      if (this.isRejected(application)) {
        return reject({
          message: trNotFit
        });
      }
      // Construct the request body.
      const body = {
        applicationId: application.randstad_process._id,
        ...rejectionData,
        intgSteps
      };
      const profileInfo = application?.randstad_process?.profileInfo;
      // Make the request.
      this._api.addToRejected(body, true).subscribe(res => {

        if (res && res.application) {
          if (!res.application.randstad_process?.profileInfo) {
            res.application.randstad_process.profileInfo = profileInfo;
          }
          // Merge the rejection data onto a new in-memory application.
          this.handleApplicationRejection(res.application);
          // Emit a client event.
          this.emitClientEvent(CLIENT_EVENT_TYPES.NEGATIVE_FEEDBACK, res.application);

          return resolve({
            application: res.application,
            message: `${res.application.randstad_process.candidateFullName} was marked as not a fit.`
          });
        } else {
          const trInvalid = this.translate.instant('job-detail-shortlist_service.invalid_response');
          return reject({
            message: trInvalid
          });
        }
      }, (err: any) => {
        const trError = this.translate.instant('job-detail-shortlist_service.invalid_response', { 'value': application.randstad_process.candidateFullName });
        console.log('[rejectShortlistTalent]', err);
        return reject({ message: trError });
      });
    });
  }

  /**
   * Handles rejecting potential talent.
   * @param profile - talent profile object
   * @param rejectionData - rejection data
   */
  private rejectPotentialTalent(profile: Profile, rejectionData?: ApplicationRejectionData, intgSteps: INTG_STEPS[] = []): Promise<ShortlistActionResponse> {
    return new Promise((resolve, reject) => {
      // Reject if talent is already rejected..
      if (this.isRejected(profile)) {
        const trError = this.translate.instant('job-detail-shortlist_service.rejected');
        return reject({ message: trError });
      }
      // Reject if an application already exists..
      if (this.isShortlisted(profile)) {
        const trShortlist = this.translate.instant('job-detail-shortlist_service.under_shortlisted');
        return reject({ message: trShortlist });
      }
      // Construct rejection body and make request.
      const body = JobDetailsShortlistService.getRejectPotentialTalentRequestBody(profile, this.jobData, rejectionData, intgSteps);
      this._api.addToRejected(body)
        .subscribe((res) => {
          if (!res.id) {
            const trErrorReject = this.translate.instant('job-detail-shortlist_service.invalid_response_reject');
            return reject({
              message: trErrorReject
            });
          }
          this.rejectedIds[profile.name] = true;
          // Emit a client event.
          this.emitClientEvent(CLIENT_EVENT_TYPES.NEGATIVE_FEEDBACK, profile);
          return resolve({
            message: `${profile.personNames[0].structuredName.givenName} ${profile.personNames[0].structuredName.familyName} was marked as not a fit.`
          });
        }, err => {
          console.log('[rejectPotentialTalent]', err);
          const trVariable = {
            'value1': profile.personNames[0].structuredName.givenName,
            'value2': profile.personNames[0].structuredName.familyName
          };
          const trUnknown = this.translate.instant('job-detail-shortlist_service.unknown_error_reject', trVariable);
          return reject({
            message: trUnknown
          });
        });
    });
  }

  /**
   * Returns the reinstate function that should be used depending on if the talent is
   * a potential talent (first param is TalentProfile object) or shortlisted talent
   * (first param is Application object).
   * @param talent - talent application or profile object
   * @param job - Allbirds job object
   */
  public reinstate(talent: Application | Profile, job: Job = this.jobData): Promise<ShortlistActionResponse> | any {
    return talent.isApplication()
      ? this.reinstateShortlistTalent(talent, job)
      : this.reinstatePotentialTalent(talent, job);
  }

  /**
   * Handles reinstating a rejected shortlist talent.
   * @param application - talent application object
   * @param job - Allbirds job object
   */
  private reinstateShortlistTalent(application: Application, job: Job): Promise<ShortlistActionResponse> {
    return new Promise((resolve, reject) => {
      // Construct the request body
      const body = {
        talentId: application.profile,
        jobElasticId: job.allbirds_metadata.allbirds_job_id,
        id: (application.randstad_process && application.randstad_process._id) || ''
      };
      const profileInfo = application?.randstad_process?.profileInfo;
      // Call API.
      this._api.reinstate(body)
        .subscribe(app => {
          // https://global-jira.randstadservices.com/browse/DF044-8376
          if (!app?.randstad_process?.profileInfo) { // If profile info is missing, add it back
            app.randstad_process.profileInfo = profileInfo;
          }
          this.handleReinstate(app);
          // Emit a client event.
          this.emitClientEvent(CLIENT_EVENT_TYPES.UNSET_NEGATIVE_FEEDBACK, app);
          const trReinstated = this.translate.instant('job-detail-shortlist_service.reinstated',
            { 'value': app.randstad_process.candidateFullName });
          return resolve({
            application: app,
            message: trReinstated
          });
        }, (err: any) => {
          const trError = this.translate.instant('job-detail-shortlist_service.unknown_error_reinstate',
            { 'value': application.randstad_process.candidateFullName });
          console.error('[reinstateShortlistTalent]', err);
          return reject({
            message: trError
          });
        });
    });
  }

  /**
   * Handles reinstating a rejected shortlist talent.
   * @param profile - talent profile object
   * @param job - Allbirds job object
   */
  private reinstatePotentialTalent(profile: Profile, job: Job): Promise<ShortlistActionResponse> {
    return new Promise((resolve, reject) => {
      // Construct the request body.
      const body = {
        talentId: profile.name,
        jobElasticId: job.allbirds_metadata.allbirds_job_id
      };
      // Call API.
      this._api.reinstate(body)
        .subscribe(app => {
          console.log('[reinstatePotentialTalent] response:', app);
          const step = JobDetailsShortlistService.getNormalizedStepKey(app.randstad_process.lastProcessStep as ProcessStep);
          this.handleReinstate(app);
          /**
           * The counts for rejected potential talent don't affect shortlist metrics. With that being said, reinstating a
           * rejected potential talent is essentially adding them to the shortlist, so we must update the in-memory metrics.
           */
          this.metrics[step]++;
          this.total++;
          // Emit a client event.
          this.emitClientEvent(CLIENT_EVENT_TYPES.UNSET_NEGATIVE_FEEDBACK, profile);
          return resolve({
            application: app,
            message: `${profile.personNames[0].structuredName.givenName} ${profile.personNames[0].structuredName.familyName} was reinstated and added to the shortlist.`
          });
        }, err => {
          console.error('[reinstatePotentialTalent]', err);
          const trVariable = {
            'value1': profile.personNames[0].structuredName.givenName,
            'value2': profile.personNames[0].structuredName.familyName
          };
          const trError = this.translate.instant('job-detail-shortlist_service.unknown_error_reinstate_potential', trVariable);
          return reject({
            message: trError
          });
        });
    });
  }

  /**
   * Default functionality that should be executed after reinstating a talent
   * regardless of if they're a potential talent or shortlist talent.
   * @param app
   */
  private handleReinstate(app: Application) {
    // Ensure the UI is aware this is a shortlist talent.
    this.shortlistedIds[app.profile] = true;
    // Ensure the UI is aware this is no longer a rejected talent.
    delete this.rejectedIds[app.profile];
  }

  /**
   * When an application is rejected, remove it from the in-memory shortlist and add it to the in-memory
   * rejected shortlist talent to avoid disrupting UX by triggering a new query.
   * @param application
   */
  private handleApplicationRejection(application: Application) {
    if (!application) {
      return;
    }
    if (
      this.jobData &&
      this.jobData.allbirds_metadata &&
      this.jobData.allbirds_metadata.lob
    ) {
      TalentProcessService.setTalentStepNumber(application, this.jobData.allbirds_metadata.lob);
    }
    const nonrejected = this.talentSubject.getValue();
    const index = nonrejected.findIndex((a: Application) => a.profile === application.profile);
    if (index !== -1) {
      nonrejected.splice(index, 1);
    }
    // Re-sort in-memory rejected talent because where to insert the rejected application (as it pertains to the active sort) is unknown.
    const rejected = SortingService.sortApplicationArray([application, ...this.rejectedSubject.getValue()], this.activeSort);
    if (!this.excludedIds) this.excludedIds = {};
    this.excludedIds = this.excludedIds || {};
    this.excludedIds[application.profile] = true;
    this.rejectedIds = this.rejectedIds || {};
    this.rejectedIds[application.profile] = true;
    this._process.propagateApplicationUpdates([application]);
    this.talentSubject.next(nonrejected);
    this.rejectedSubject.next(rejected);
  }

  /**
   * Fired every time the updatedApplication subject from the talent-process-service emits a new value. If the
   * application's step has changed, we modify the in-memory metrics and fire a new query. If the step hasn't changed,
   * we update the in-memory talent array.
   * @param event
   */
  private handleApplicationUpdate(event: ApplicationUpdateEvent) {
    if (this.jobData) {
      TalentProcessService.setTalentStepNumber(event.application, this.jobData.allbirds_metadata.lob);
      const fromStepKey = (event.fromStepKey && JobDetailsShortlistService.getNormalizedStepKey(event.fromStepKey as ProcessStep)) || '';
      const currentStepKey = JobDetailsShortlistService.getNormalizedStepKey(event.application.randstad_process.lastProcessStep as ProcessStep);
      this.updateInMemoryApplications([event.application]);
      /**
       * If the application's lastProcessStep did change, then all we need to do is modify the in-memory metrics because
       * setActiveStep will fire a new query and inherently receive the updated application with the new result set.
       */
      if (fromStepKey && (fromStepKey.toLowerCase() !== currentStepKey.toLowerCase())) {
        this.metrics[fromStepKey]--;
        this.metrics[currentStepKey]++;
        this.setActiveStep(currentStepKey);
      }
    }
  }

  /**
   * Given an array of applications, will update all of the in-memory applications if it exists and if not,
   * pushes the application to the proper list depending on its rejected status.
   * @param applications - array of application objects
   * @param job - job object
   */
  public updateInMemoryApplications(applications: Application[], job: Job = this.jobData): void {
    const nonrejectedTalent = this.talentSubject.getValue();
    const rejectedTalent = this.rejectedSubject.getValue();
    let i = applications.length;
    while (i--) {
      const application = applications[i];
      TalentProcessService.setTalentStepNumber(application, job.allbirds_metadata.lob);
      const isRejected = Boolean(application.randstad_process.rejected);
      // Update the rejected IDs mapping.
      if (isRejected) {
        this.rejectedIds[application.profile] = true;
      } else {
        delete this.rejectedIds[application.profile];
      }

      if (
        application &&
        application.randstad_process &&
        application.randstad_process.lastProcessStep &&
        (!this.activeStep || application.randstad_process.lastProcessStep === this.activeStep)
      ) {

        if(application.randstad_process.isHiddenFromShortlist && application.randstad_process.lastProcessStep === ProcessStep.PRESCREENING) {
          this.hiddenIds[application.profile] = true;
        } else {
          this.shortlistedIds[application.profile] = true;
        }

        const nonrejectedIndex = nonrejectedTalent.findIndex((app: Application) => app.profile === application.profile);
        const rejectedIndex = rejectedTalent.findIndex((app: Application) => app.profile === application.profile);
        /**
         * If the talent is rejected, check if the talent exists in the non-rejected list. If so, remove it from the
         * list of non-rejected and add it to the list of rejected talent. Otherwise, check for the talent in the rejected
         * array. If it exists in the rejected array, update the application in-memory; otherwise, push it into the rejected
         * array.
         */
        if (isRejected) {
          if (nonrejectedIndex !== -1) {
            nonrejectedTalent.splice(nonrejectedIndex, 1);
            rejectedTalent.push(application);
          } else {
            if (rejectedIndex !== -1) {
              rejectedTalent[rejectedIndex] = application.clone();
            } else {
              rejectedTalent.push(application);
            }
          }
        } else {
          /**
           * If the application exists in-memory, update it. Otherwise, push it to the list.
           */
          if (nonrejectedIndex !== -1) {
            nonrejectedTalent[nonrejectedIndex] = application.clone();
          } else {
            nonrejectedTalent.push(application);
          }
          /**
           * Remove the application from rejected list if applicable.
           */
          if (rejectedIndex !== -1) {
            rejectedTalent.splice(rejectedIndex, 1);
          }
        }
      }
    }
    // Sort the talent in-memory since we're not forcing a new fetch.
    const sortedNonrejected = SortingService.sortApplicationArray(nonrejectedTalent, this.activeSort);
    const sortedRejected = SortingService.sortApplicationArray(rejectedTalent, this.activeSort);

    // Update the talent subjects.
    this.talentSubject.next(sortedNonrejected);
    this.rejectedSubject.next(sortedRejected);
  }

  /**
   * Called whenever potential talent are shortlisted via mass actions. Iterates over the given
   * applications and increments counts as necessary.
   * @param applications - talent application objects
   */
  public handleMassShortlist(applications: Application[]) {
    let i = applications.length;
    while (i--) {
      const step: ProcessStep = JobDetailsShortlistService.getNormalizedStepKey(applications[i].randstad_process.lastProcessStep);
      this.shortlistedIds[applications[i].profile] = true;
      delete this.rejectedIds[applications[i].profile];
      this.total++;
      this.metrics[step]++;
    }
  }

  /**
   * Handles updating the step metrics for when step skips occur.
   * @param events - array of skip events
   */
  public handleSkipEvents(events: SkipEvent[]) {
    let i = events.length;
    while (i--) {
      const { application, previousStep } = events[i];
      const currentStep = JobDetailsShortlistService.getNormalizedStepKey(application.randstad_process.lastProcessStep);
      this.metrics[previousStep]--;
      this.metrics[currentStep]++;
    }
  }

  /**
   * Handles updating applications that just completed prescreening by chatbot.
   * @param application
   */
  public handleChatbotApplication(application?: Application) {
    const currentStep: ProcessStep = JobDetailsShortlistService.getNormalizedStepKey(application.randstad_process.lastProcessStep);
    this.updateInMemoryApplications([application]);
    this.metrics[ProcessStep.PRESCREENING]--;
    this.metrics[currentStep]++;
    this.setActiveStep(currentStep);
  }

  /**
   * Given a talent profile and job, returns an Allbirds job application
   * should one exist or null if none is found.
   * @param profile - talent profile
   * @param job - allbirds job order
   */
  public getApplicationByProfile(profile: Profile, job: Job = this.jobData): Promise<Application> {
    return new Promise((resolve, reject) => {
      if (!profile || !job) {
        return reject(null);
      }

      if (this.isShortlisted(profile)) {
        const targetSubject = this.isRejected(profile) ? 'rejectedSubject' : 'talentSubject';
        const idx = this[targetSubject].value.findIndex((app: Application) => app.profile === profile.name);
        if (idx !== -1) {
          return resolve(this[targetSubject].value[idx]);
        }
      }

      this._api.getTalentApplication(profile.name, false, job.allbirds_metadata.allbirds_job_id)
        .subscribe(app => {
          if (app) {
            return resolve(app);
          } else {
            return reject(null);
          }
        }, err => {
          console.error('An error occurred fetching application by profile', err);
          return reject(null);
        });
    });
  }

  /**
   * Emits client events to the metrics and client-event services.
   * @param eventType
   * @param profile
   */
  public emitClientEvent(eventType: CLIENT_EVENT_TYPES, profile: Profile | Application) {
    this.clientEventSubject.next({ profile, eventType });
    // this._clientEvent.addEventToQueue(profile, eventType);
  }

  /**
   * Emits the application to locally-update to job-details-shortlist.component.ts.
   * @param app - talent application object
   */
  public emitLocalApplicationUpdate(app: Application) {
    this.localUpdateSubject.next(app);
  }

  static getApplicationIdMap(applications: Application[]): { [id: string]: boolean } {
    return applications.reduce<any>((result: any, app: Application) => {
      result[app.profileId] = true;
      return result;
    }, {}) as { [id: string]: boolean };
  }

  private listenToJobChanges() {
    this.updatedJobSub = this._job.jobOrderObservable.subscribe((job: Job) => {
      this.jobData = job;
    });
  }

  /**
   * Returns true if the user, job, and profile LOB (opco) all match.
   * @param talent - talent profile
   * @param job - AB job
   * @param user - mongo user
   */
  static opcoDoesMatch(talent: Profile, job: Job, user: User): boolean {
    let talentOpco = (talent && (talent.externalSystem || talent.externalId)) || '';
    const jobOpco = (job && job.allbirds_metadata && job.allbirds_metadata.lob) || '';
    const userOpco = (user && user.Source) || '';
    // FEATURE/8611 for talent with talentOpco as USRT we will change it to BH so that talent can be shorted list by RT/RE/CR user
    if (talentOpco.includes('RT')){
      talentOpco = 'BH'
    }
    if (jobOpco && talentOpco && userOpco) {
      return talentOpco.abIncludesLob(jobOpco) && talentOpco.abIncludesLob(userOpco);
    }
    return false;
  }
}

