// Valid positions for the toast to appear.
import { ToastConfig } from './toast.service';

export enum ToastPosition {
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  TOP_CENTER = 'top-center',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
  BOTTOM_CENTER = 'bottom-center'
}

// The corresponding css classes that can be applied to the
// toast component.
export enum ToastClass {
  SECONDARY = 'alert-secondary',
  SUCCESS = 'alert-success',
  WARNING = 'alert-warning',
  DANGER = 'alert-danger'
}

// If no configuration is passed on calling 'showToast(msg, config?)'
// then this is the default the toast will use.
export const DEFAULT_CONFIG: ToastConfig = {
  duration: 10000,
  cssClass: ToastClass.SECONDARY,
  position: ToastPosition.BOTTOM_LEFT,
  showIcon: true,
  actionLinks: []
};
