import { ListTalent } from './../../../../shared/models/external/list-talent.model';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { TalentService } from 'src/app/shared/services/talent/talent.service';
import { TalentProcessService } from 'src/app/shared/services/talent-process/talent-process.service';
import { KEY_TO_LABEL } from './talent-management.mappings';
import { TalentSortTypes } from './TalentSortTypes';
import { ApplicationUpdateEvent } from '../../../../shared/models/internal/step-change-event.interface';
import { RequestBody } from '../../../../shared/models/api/application-request.interface';
import { Job } from '../../../../shared/models/external/job.model';
import { Application } from '../../../../shared/models/external/application.model';
import { Applications } from '@allbirds-ui/allbirds-types';
import { TranslateService } from '@ngx-translate/core';
import { TalentDashboardService } from '../../../../shared/services/talent-dashboard/talent-dashboard.service';
import { RecruiterHierarchy } from '../../../../shared/models/internal/recruiter-hierarchy.model';
import { INTG_STEPS, UpdateApplicationRequest } from 'src/app/shared/models/internal/process.model';
import GetTalentMetricsRequest = RequestBody.GetTalentMetricsRequest;
import ProcessStep = Applications.ProcessStep;
import ProcessStatus = Applications.ProcessStatus;
import { finalize } from 'rxjs/operators';
import {AllbirdsAppointment as Appointment} from 'src/app/shared/models/external/appointment.model';
import { List } from 'src/app/shared/models/external/list.model';

// Result set size for infinity scroll.
const PAGINATION_SIZE = 250;

@Injectable({
  providedIn: 'root'
})
export class TalentManagementListingsService implements OnDestroy {

  // todo: Virtual scroll in this page does nothing, we always tried to load by 250. That needs to change for performance
  // Required for API call.
  stepKey: ProcessStep | 'unanswered' | 'onboarding';
  recruiterBackOfficeID: string = null;

  // The text displayed on the navigation bar.
  headerText = '';

  // Total number of talent.
  totalTalent = 0;

  // To keep track of what talent we have (this is needed because of the
  // dupe weirdness in combining two aggregate metrics to accomplish the
  // desired application result set.
  talentIds: { [key: string]: boolean } = {};



  // Holds all of the talent for the currently-viewed step.
  public talentSubject = new BehaviorSubject<ApplicationAppointment>({applications: [], listTalent: []});
  public talentObservable = this.talentSubject.asObservable();

  // Used for infinity scroll paging.
  resultFrom = 0;
  keepFetching = true;
  isFetching = false;

  // Stores job order data.
  public jobs: { [key: string]: Job } = {};

  // Store pipeline data for appointments
  public pipelines: {[key: string]: List} = {};

  // To listen and remove talent when action is taken on their card.
  updatedApplicationSub: Subscription;

  // Selected talent index.
  activeIndex = -1;

  // Currently active sort type.
  sortType: TalentSortTypes = TalentSortTypes.LAST_UPDATED_DESCENDING;

  // Currently applied filters
  filters: (ProcessStatus | 'ADDED_BY_ME' | 'APPLICANT' | 'ALL' | 'NOT_CONTACTED' | 'CONTACTED'| 'ILABOR')[] = [];

  // Flag for whether or not talent exist in the list that don't belong to the active step.
  containsOtherStepTalent = false;

  // Holds the id of talent in other steps (used so we only decrement once as they're moved along).
  otherStepTalent: { [id: string]: boolean } = {};

  // Subscription for active scope changes.
  activeScope: RecruiterHierarchy.Scope;

  selectedTalent: string;
  appointmentResultFrom = 0;
  totalAppointments: any;
  totalApplications: any;
  // talent management listing mass select identifier
  private massSelect: boolean;

  constructor(
    private _auth: AuthService,
    private _api: ApiService,
    private _talent: TalentService,
    private _talentProcess: TalentProcessService,
    private translate: TranslateService,
    private _td: TalentDashboardService
  ) { }

  get userLob(): string { return this._auth.user && this._auth.user.Source; }

  ngOnDestroy(): void {
    this.resetEverything();
  }

  /**
   * Given a step key, initializes the page by resetting all previously-existing
   * values, setting the proper header, and fetching talent for the list.
   * @param stepKey - a valid step key string
   * @param filters
   * @param sortType
   * @param scope
   */
   initialize(stepKey: ProcessStep | 'unanswered' | 'onboarding', filters?: (ProcessStatus | 'APPLICANT' | 'ADDED_BY_ME' | 'ALL' | 'NOT_CONTACTED' | 'CONTACTED' | 'ILABOR')[],
      sortType?: TalentSortTypes, scope?: RecruiterHierarchy.Scope, selectedTalent?: string, massSelect?: boolean, searchText ?: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.resetEverything();

      this.stepKey = stepKey;
      this.filters = filters || [];
      this.sortType = sortType || TalentSortTypes.LAST_UPDATED_DESCENDING;
      this.recruiterBackOfficeID = this._auth.user.BackOfficeID;
      this.selectedTalent = selectedTalent;
      if (this.userLob.checkLob('CR') && stepKey === 'unanswered')
      {
        this.setHeaderText('Web Applies');
      } else {
        this.setHeaderText(KEY_TO_LABEL[stepKey]);
      }
      this.listenToUpdatedApplication();
      this.massSelect = massSelect;
      if (scope) {
        this.activeScope = scope;
      }
      this.fetchTalent(massSelect, searchText)
        .then(() => resolve(true))
        .catch(err => reject(false));
    });
  }

  /**
   * Resets all UI labels/indicators to its default state.
   */
  resetEverything(): void {
    this.talentSubject.next({'applications': [], 'listTalent': []});
    this.jobs = {};
    this.talentIds = {};
    this.totalTalent = 0;
    this.resultFrom = 0;
    this.appointmentResultFrom = 0;
    this.keepFetching = true;
    this.sortType = TalentSortTypes.LAST_UPDATED_DESCENDING;
    this.containsOtherStepTalent = false;
    this.otherStepTalent = {};
    if (this.updatedApplicationSub) {
      this.updatedApplicationSub.unsubscribe();
    }
  }

  /**
   * Registers our updated application listener so that we can manipulate
   * the talent list as needed when applications are updated in contexts outside
   * of the talent-management-listings component & service.
   */
  listenToUpdatedApplication() {
    this.updatedApplicationSub = this._talentProcess.updatedApplication
      .subscribe((event: ApplicationUpdateEvent) => {
        const { application } = event;
        if (
          application &&
          application.randstad_process &&
          application.randstad_process.lastProcessStep
        ) {
          const talent = this.talentSubject.getValue();
          const idx = talent.applications.findIndex(t => t.profile === application.profile);
          if (idx !== -1) {
            const lob = this.userLob.checkLob('RT', 'RE') ? 'RT' : 'RGS';
            TalentProcessService.setTalentStepNumber(application, lob);
            talent.applications[idx] = application.clone();
            if (application.randstad_process.lastProcessStep !== this.stepKey || application.randstad_process.rejected) {
              this.containsOtherStepTalent = true;
              if (!this.otherStepTalent[application.randstad_process._id]) {
                this.otherStepTalent[application.randstad_process._id] = true;
                this.totalTalent--;
              }
            }
            this.talentSubject.next(talent);
          }
        }
      });
  }

  /**
   * Changes the text of the header on the top of the talent dashboard.
   * @param headerText - desired text for top header
   */
  setHeaderText(headerText: string) {
    this.headerText = headerText;
  }

  /**
   * Given an Allbirds job id, returns the string that should be displayed on top
   * of the talent card in the talent listings page.
   * @param allbirdsJobId - allbirds Elastic job id
   */
  getJobDescriptionString(allbirdsJobId: string) {
    const job = this.jobs[allbirdsJobId] || null;
    const trDesc = this.translate.instant('talent-management-listings_service.job_desc',
      { 'value1': job.internalTitle, 'value2': job.allbirds_metadata.customer_name });
    const trInvalid = this.translate.instant('talent-management-listings_service.invalid');

    if (
      job &&
      job.title &&
      job.allbirds_metadata &&
      job.allbirds_metadata.customer_name
    ) {
      return trDesc;
    }
    if (
      job &&
      job.title &&
      job.allbirds_metadata &&
      job.allbirds_metadata.order_type === 'Pipeline'
    ) {
      return trDesc;
    }

    return trInvalid;
  }

  /**
   * Returns the request body needed to retrieve talent.
   */
  getRequestBody(): GetTalentMetricsRequest {
    return {
      applicationsFrom: this.resultFrom,
      appointmentsFrom: this.appointmentResultFrom,
      filters: this.filters,
      stepKeys: [this.stepKey],
      includeApplications: true,
      includePostings: true,
      sort: this.sortType,
      scope: this.activeScope,
      st: this.selectedTalent,
      massSelect: this.massSelect
    };
  }

  /**
   * Fetches talent and their associated jobs based on the step key.
   */
  fetchTalent(massSelect?: boolean, searchText?: any ): Promise<boolean> {
    if (this.isFetching) {
      return;
    }
    let reqParams = this.getRequestBody(); // required request parameters.

    if(searchText){
      reqParams = {
        ...reqParams,
        searchText
      }
    }
    return new Promise((resolve, reject) => {
      this._api.getTalentMetricsNew(reqParams)
        .pipe(
          finalize(() => this.isFetching = false)
        )
        .subscribe(res => {
          const applications: Application[] = [];
          const listTalent: ListTalent[] = [];
          // Extract the jobs data.
          if (res && res.postings) {
            res.postings.forEach((posting: any) => {
              this.jobs[posting.allbirds_metadata.allbirds_job_id] = posting;
            });
          }
          if (res && res.pipelines) {

            res.pipelines.forEach((pipeline: List) => {

              this.pipelines[pipeline.id] = pipeline;
            });
          }

          if (res && res.appointments) {
            res.appointments.forEach((app: Appointment) => {
              const listId = app.listID;
              const curPipeline: List = this.pipelines[listId];
              if (curPipeline && curPipeline.talent) { // Causing a bug if it doesn't exist: https://global-jira.randstadservices.com/browse/DF044-10414
                const talentInList: ListTalent[] = curPipeline.talent;
                const talentMap: Map<string, ListTalent>  = new Map<string, ListTalent>();
                talentInList.forEach(talent => {
                  talentMap.set(talent.google_id, talent);
                });
                const talent = talentMap.get(app.profile);
                if (talent) {
                  talent.appointment = app;
                  listTalent.push(talent);
                } else {
                  console.error('talent was undefined in talent-management-listings.service.ts/fetchTalent().')
                  console.log('talentMap: ', talentMap);
                  console.log('appointment: ', app);
                }
              }
            });
          }
          // Extract the applications.
          if (res && res.applications) {
            // Set the talentStepNumber for each retrieved talent.
            res.applications.forEach((app: any) => {
              TalentProcessService.setTalentStepNumber(
                app,
                this.jobs[app.randstad_process.jobElasticID].allbirds_metadata.lob
              );
              if (!this.talentIds[app.randstad_process._id]) {
                applications.push(app);
                this.talentIds[app.randstad_process._id] = true;
              }
            });
          }

          // Append the retrieved talent to the current list of talent.
          const updatedTalent = {
            'applications': [...this.talentSubject.getValue().applications, ...applications],
            'listTalent': [...this.talentSubject.getValue().listTalent, ...listTalent]
            };
          this.talentSubject.next(updatedTalent);
          // Set the total talent count for the top header.
          if (!this.totalTalent) {
            this.totalTalent = res.metrics[this.stepKey].total;
          }
          if (!this.totalApplications) {
            this.totalApplications = res.metrics.applicationsTotal;
          }
          if (!this.totalAppointments) {
            this.totalAppointments = res.metrics.appointmentsTotal;
          }
          // We only receive 10 results at a time as a result of lazy loading. Increment every time.
          this.resultFrom += [TalentSortTypes.NAME_A_TO_Z, TalentSortTypes.NAME_Z_TO_A].includes(this.sortType) ?
              (updatedTalent.applications.length ) : PAGINATION_SIZE;
          this.appointmentResultFrom += [TalentSortTypes.NAME_A_TO_Z, TalentSortTypes.NAME_Z_TO_A].includes(this.sortType) ?
              (updatedTalent.listTalent.length ) : PAGINATION_SIZE;
          console.log('updatedTalent.applications.length', updatedTalent.applications.length);
          // Only keep fetching if our result count is less than the total number of talent.
          this.keepFetching = (updatedTalent.applications.length + updatedTalent.listTalent.length) < this.totalTalent;
          console.log('this.keepFetching', this.keepFetching);
          resolve(true);
        }, err => {
          console.log('[fetchTalent]', err);
          reject(false);
        });
    });
  }


  /**
   * Given an application object, updates the application in Elastic and then removes it from the list.
   * Typically used when a talent application moves to another step or is rejected.
   * @param application - application object
   */
  updateAndRemove(application: Application): Promise<Application> {
    const intgSteps: INTG_STEPS[] = [];

    const req: UpdateApplicationRequest  = {
      'application': application,
      'intgSteps': intgSteps
    };
    return new Promise((resolve, reject) => {
      this._api.updateApplication(req)
        .subscribe(res => {
          const updatedTalentList = this.talentSubject.getValue().applications
            .filter(t => t.profile !== application.profile);
          this.talentSubject.next(
            {
              'applications': updatedTalentList,
              'listTalent': this.talentSubject.getValue().listTalent
            });
          this.totalTalent--;
          resolve(application);
        }, err => {
          console.log('[updateAndRemove]', err);
          reject(err);
        });
    });
  }

  getActiveIndex(id?: string): number {
    if (!id) { return this.activeIndex; }
    const talent = this.talentSubject.getValue();
    const idx = talent.applications.findIndex(t => t.profile.includes(id));
    if (idx) { return idx; } else { return -1; }
  }

  setActiveIndex(idx: number): void {
    this.activeIndex = idx;
  }
}

export class ApplicationAppointment {
  applications: Application[] ;
  listTalent: ListTalent[] ;
}
