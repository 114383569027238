import {
  AfterContentChecked,
  AfterContentInit,
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  RendererStyleFlags2,
  SimpleChanges
} from '@angular/core';
import moment, { Moment } from 'moment';

@Component({
  selector: 'app-time-selector',
  templateUrl: './time-selector.component.html',
  styleUrls: ['./time-selector.component.scss']
})
export class TimeSelectorComponent implements OnInit {
  @Input() title: string;
  @Input() value: Moment;
  @Output() modelChange = new EventEmitter<Moment>();
  model: Moment = moment();
  modelAsDate: Date = new Date();

  constructor() { }

  ngOnInit() {
    if (this.value) {
      this.model = this.value;
      this.modelAsDate.setHours(moment(this.value).hours(), moment(this.value).minutes(), 0);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.value && changes.value.currentValue) {
      this.model = changes.value.currentValue;
      this.modelAsDate = moment(this.model).toDate();
    }
  }

  onChange(event: any) {
    if (this.modelAsDate) {
      this.modelChange.emit(<Moment><unknown>{ hours: this.modelAsDate.getHours(), minutes: this.modelAsDate.getMinutes(), seconds: 0, nanos: 0 });
    } else {
      // default value if there is an error/issue
      this.model = moment(this.value);
      this.modelChange.emit(this.value);
      this.modelAsDate = moment(this.value).toDate();
    }
  }

  changeMeridian(time: Moment, type: string): void {
    const timeToUse = moment(time)
    const hours = timeToUse.hours();
    if (type === 'PM' && (hours >= 0 && hours < 12)) {
      timeToUse.add(12, 'hours');
    } else if (type === 'AM' && (hours >= 12 && hours < 24)) {
      timeToUse.subtract(12, 'hours');
    } else {
      return;
    }
    this.modelChange.emit(<Moment><unknown>{ hours: timeToUse.hours(), minutes: timeToUse.minutes(), seconds: 0, nanos: 0 });
  }
}

@Directive({ selector: '[removeMeridian]' })
export class RemoveMeridianDirective implements AfterContentInit, AfterContentChecked {

  constructor(private el: ElementRef, private renderer: Renderer2) {
    el.nativeElement.style.backgroundColor = 'yellow';
  }

  ngAfterContentInit() {
    // you can get to the element content here
    // console.log(this.el.nativeElement);
  }

  ngAfterContentChecked() {
    const button = this.el.nativeElement.querySelectorAll('button');
    const flags = RendererStyleFlags2.DashCase | RendererStyleFlags2.Important;
    if (button.length) {
      this.renderer.setStyle(button[0], 'display', 'none', flags);
      // button[0].style.display = 'none';
    }
  }
}
