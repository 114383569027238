import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Appointments, ChatBots} from '@allbirds-ui/allbirds-types';
import moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {switchMap} from 'rxjs/operators';
import {MASS_OPERATION_CONTEXT} from 'src/app/shared/components/mass-email-modal/mass-operation-context';
import {AllbirdsAppointment} from 'src/app/shared/models/external/appointment.model';
import {Job} from 'src/app/shared/models/external/job.model';
import {TalentListProfileInfo} from 'src/app/shared/models/external/process/talent-list-profile.model';
import {Profile} from 'src/app/shared/models/external/profile.model';
import {EmailInput} from 'src/app/shared/models/internal/mass-email.model';
import {ApptInviteService} from 'src/app/shared/services/appt-invite/appt-invite.service';
import {AuthService} from 'src/app/shared/services/auth/auth.service';
import {FormValidationService} from 'src/app/shared/services/form-validation/form-validation.service';
import AppointmentGoal = Appointments.AppointmentGoal;
import InterviewStatus = ChatBots.InterviewStatus;

@Component({
  selector: 'app-sched-appointment-modal',
  templateUrl: './sched-appointment-modal.component.html',
  styleUrls: ['./sched-appointment-modal.component.scss']
})
export class SchedAppointmentModalComponent implements OnInit {
  selectedTalent: Profile[] | TalentListProfileInfo[] = [];
  /**
   * This should only ever be a cancelled appointment that we need to mark
   * as completed once a new appointment is scheduled
   */
  currentAppointment: AllbirdsAppointment;
  operationContext = MASS_OPERATION_CONTEXT;
  currentId: string;
  isStepOne = true;
  stepOneComplete = false;
  stepTwoComplete = false;
  parentForm: FormGroup;
  recipientEmails: EmailInput[] = [];
  job: Job;
  listId: string;


  constructor(
    public bsModalRef: BsModalRef,
    private _formBuilder: FormBuilder,
    private _apptInvite: ApptInviteService,
    private _auth: AuthService
  ) { }

  ngOnInit() {
    this.selectedTalent = this.selectedTalent.slice(0, 250);
    this.createForm();
  }

  get _recipientEmailInputs() {
    let returnVal: EmailInput[] = [];
    if (this.selectedTalent && this.selectedTalent.length) {
      this.selectedTalent.forEach((profile: Profile | TalentListProfileInfo) => {
        returnVal.push( {
          email: profile.emailAddresses && profile.emailAddresses.length
            ? profile.emailAddresses[0].emailAddress
            : '',
          name: this.getFullName(profile)
        });
      });
    }
    this.recipientEmails = returnVal;
    // console.log('_recipientEmailInputs : ', this.recipientEmails);
    return returnVal;
  }

  createForm() {
    this.parentForm = this._formBuilder.group({
      emailRecipients: [null, [Validators.required, FormValidationService.validateNonEmptyArray]],
      apptSettingsForm: this._apptInvite.getApptSettingsForm(),
      emailMessageForm: this._apptInvite.getEmailMessageForm()
    });
  }

  onContinueBackClick() {
    if (this.parentForm.controls.apptSettingsForm.valid) {
      this.isStepOne = !this.isStepOne;
    }
  }

  getPayload() {
    const payload: AllbirdsAppointment[] = [];
    const validRecipients = this._apptInvite.getValidRecipientProfiles(this.selectedTalent, this.parentForm.get('emailRecipients').value);
    validRecipients.forEach((talent) => {
      const rawAppt: Appointments.AllbirdsAppointment = {
        appointmentInvite: {
          agentID: this._auth.user.BackOfficeID,
          message: this.parentForm.get('emailMessageForm.message').value,
          timestamp: new Date().toISOString()
        },
        appointment: {
          candidateEmail: talent.emailAddresses && talent.emailAddresses.length
            ? talent.emailAddresses[0].emailAddress
            : '',
          interviewDuration: parseInt(this.parentForm.get('apptSettingsForm.interviewDuration').value),
          interviewGoal: this.parentForm.get('apptSettingsForm.apptGoal').value as AppointmentGoal,
          interviewStatus: InterviewStatus.INVITE_SENT,
          interviewType: this._apptInvite.getInterviewTypeValue(this.parentForm.get('apptSettingsForm.apptType').value),
          interviewAddress: this.parentForm.get('apptSettingsForm.apptAddress').value,
          recruiterEmail: this._auth.user.EmailAddr
        },
        job: this.job ? this.job.name : '' ,
        listID: this.listId,
        jobElasticID: this.job ? this.job.allbirds_metadata.allbirds_job_id : '',
        profile: talent.name,
        profileElasticID: talent.externalId,
        profileFrontOfficeID: talent.externalId,
        lastUpdated: new Date().toISOString(),
        lastUpdatedBy: this._auth.user.BackOfficeID
      };
      payload.push(AllbirdsAppointment.deserialize(rawAppt));
    });
    return payload;
  }

  onSendClick() {
    if (this.parentForm.valid) {
      const payload = this.getPayload();
      if (this.currentAppointment && this.currentAppointment.appointment.interviewStatus === InterviewStatus.CANCELLED) {
        this.currentAppointment.appointment.interviewStatus = InterviewStatus.COMPLETE;
        this.currentAppointment.lastUpdatedBy = this._auth.user.BackOfficeID;
        this.currentAppointment.lastUpdated = moment();
        this._apptInvite.updateAppointment(this.currentAppointment)
          .subscribe((res) => {
            this.currentAppointment = null;
            this.createAppointments(payload);
          });
      } else {
        this.createAppointments(payload);
      }
    } else {
      const invalidControls = this.handleInvalid();
      const locationObj: any = {
        page1: ['apptAddress', 'apptGoal', 'apptType', 'interviewDuration', 'emailRecipients'],
        page2: ['message']
      };
      if (invalidControls && Object.keys(invalidControls).length > 0) {
        if (locationObj.page1.includes(Object.keys(invalidControls)[0])) {
          this.isStepOne = true;
        } else if (locationObj.page2.includes(Object.keys(invalidControls)[0])) {
          this.isStepOne = false;
        }
      }
    }
  }

  createAppointments(payload: AllbirdsAppointment[]) {
    this._apptInvite.createAppointments(payload)
      .pipe(
        switchMap((result: any) => {
          const message = this.parentForm.get('emailMessageForm.message').value;
          const validProfiles = this._apptInvite.getValidRecipientProfiles(this.selectedTalent, this.parentForm.get('emailRecipients').value);
          const recips = this._apptInvite.getRecipientData(validProfiles, result.appointments);
          this.bsModalRef.hide();
          return this._apptInvite.sendInvites(recips, 'interview appointment', message);
        })
      )
      .subscribe(() => {
        // do nothing
      });
  }

  handleInvalid() {
    const controls = this.parentForm.controls;
    const invalidControls: {[key: string]: FormControl} = {};
    // recursion.... yuck
    const checkControlValidity = (control: AbstractControl, fieldName: string) => {
      if (control.invalid) {
        if (control instanceof FormGroup) {
          Object.keys(control.controls).forEach((key) => {
            const ctrl = control.controls[key];
            ctrl.markAsTouched();
            ctrl.updateValueAndValidity();
            checkControlValidity(ctrl, key);
          });
        } else if (control instanceof FormControl) {
          invalidControls[fieldName] = control;
        }
      }
    };
    Object.keys(controls).forEach((key) => {
      const control = controls[key];
      checkControlValidity(control, key);
    });
    return invalidControls;
  }

  getFullName(profile: Profile | TalentListProfileInfo) {
    let returnVal = '';
    if (profile && profile.personNames && profile.personNames.length) {
      const {structuredName} = profile.personNames[0];
      returnVal = `${structuredName.givenName} ${structuredName.familyName}`;
    }
    return returnVal;
  }
}
