import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { BarChangerService } from '../../services/barChanger/bar-changer.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  page: any;
  queryParamsSubscription: Subscription;

  constructor(
    private router: ActivatedRoute,
    private barChanger: BarChangerService
  ) { }

  ngOnInit() {
    this.queryParamsSubscription = this.router.queryParams.subscribe((data) => {
      this.page = data.page;
      this.barChanger.changeNavBar(null);
    });
  }

  ngOnDestroy() {
    this.queryParamsSubscription.unsubscribe();
  }
}
