import { Injectable, OnDestroy } from '@angular/core';
import { Application } from '../../models/external/application.model';
import { Profile } from '../../models/external/profile.model';
import { ApiService } from '../api/api.service';
import { map } from 'rxjs/operators'
export interface CachedResume {
  url: string;
  filename: string;
  safeUrl: Blob;
}

abstract class Cache<T> {
  private map = new Map<string, T>();
  has = this.map.has.bind(this.map);
  cache = this.map.set.bind(this.map);
  load = this.map.get.bind(this.map);
  clear = this.map.clear.bind(this.map);

  constructor(protected _api: ApiService) {
  }
}

// abstract class Cache<T extends Identifiable> {
//   // TODO(jason): come up with efficient way to garbage collect, instead of hard limit?
//   protected map = new Map<string, BehaviorSubject<T>>();
//
//   constructor(protected _api: ApiService) {
//     this.has = this.has.bind(this);
//     this.cache = this.cache.bind(this);
//     this.load = this.load.bind(this);
//     this.clear = this.clear.bind(this);
//   }
//
//   protected abstract fetch(key: string): Observable<T>;
//
//   has(key: string) {
//     return this.map.has(key);
//   }
//
//   // feed a value to the existing behaviorSubject or create a new one with the value
//   cache(key: string, value?: T) {
//     if (this.has(key)) {
//       const cachedValue = this.map.get(key);
//       if (value !== undefined) {
//         cachedValue.next(value);
//       }
//       return cachedValue;
//     } else {
//       const cachedValue = new BehaviorSubject<T>(value);
//       this.map.set(key, cachedValue);
//       return cachedValue;
//     }
//   }
//
//   // get from cache or fetch if not exist.
//   load(key: string) {
//     if (this.has(key)) {
//       return this.map.get(key);
//     } else {
//       const cachedValue = this.cache(key);
//       this.fetch(key).subscribe(cachedValue);
//       return cachedValue;
//     }
//   }
//
//   clear() {
//     this.map.clear();
//   }
// }

class ProfileCache extends Cache<Profile> {
  protected fetch(key: string) {
    return this._api.getProfilesById({ externalIds: [key] })
      .pipe(map((profiles: any) => profiles[0]));
  }
}

class ApplicationCache extends Cache<Application> {
  protected fetch(key: string) {
    return this._api.getTalentApplication(key, false);
  }
}

@Injectable({
  providedIn: 'root'
})
export class CacheService implements OnDestroy {

  // Resume cache key is talent Google ID.
  private resumes: { [key: string]: CachedResume } = {};

  private profiles = new ProfileCache(this._api);
  private applications = new ApplicationCache(this._api);

  // Maximum number of each entity to store in-memory.
  private readonly MAX_RESUMES = 10;

  cacheProfile = this.profiles.cache;
  loadProfile = this.profiles.load;
  clearProfile = this.profiles.clear;
  cacheApplication = this.applications.cache;
  loadApplication = this.applications.load;
  clearApplication = this.applications.clear;

  constructor(private _api: ApiService) {}

  /**
   * Given a map and max number of items, will check if the number of key-value pairs
   * in the map is greater than or equal to the max number of items in which case
   * it will remove a random K-V pair from the map.
   * @param map - JS object
   * @param maxItems - maximum number of items allowed in the map
   */
  static checkMapAllocation(map: any, maxItems: number) {
    const keys = Object.keys(map);
    if (keys.length >= maxItems) {
      delete map[keys.pop()];
    }
  }

  /**
   * Calls all the clear methods to revert all cache maps to an empty state.
   */
  ngOnDestroy(): void {
    this.clearAllCaches();
  }

  /**
   * Given an original resume url (typically [TalentProfile].allbirdsMetadata.resumeURL) and a
   * safe URL (generated by Angular DomSanitizer's bypassSecurityTrustResourceUrl function),
   * will cache the safe URL using the original URL as the key.
   * @param originalUrl - typically [TalentProfile].allbirdsMetadata.resumeURL
   * @param safeUrl - URL generated by feeding the originalUrl into DomSanitizer's bypassSecurityTrustResourceUrl function
   */
  public cacheResume(id: string, resumeData: CachedResume) {
    CacheService.checkMapAllocation(this.resumes, this.MAX_RESUMES);
    this.resumes[id] = resumeData;
  }

  /**
   * Given a talent application or profile, attempts to retrieve a resume from the
   * cache based on the talent's Google profile ID.
   * @param talent - talent application or profile object
   */
  public getResumeFromCache(talent: Application | Profile): CachedResume {
    const id = talent.isApplication() ? talent.profile : talent.name;
    return this.resumes[id] || null;
  }

  /**
   * Resets the resume cache to an empty state.
   */
  public clearResume() {
    this.resumes = {};
  }

  /**
   * Calls all clear functions to reset all caches to an empty state.
   */
  public clearAllCaches(): void {
    this.clearResume();
    this.clearProfile();
    this.clearApplication();
  }
}
