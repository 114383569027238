/**
 * Valid sort types for applications. This list should match the sort types
 * listed in the API Service and Recruiting Process Service.
 */

import TranslateService from '@ngx-translate/core';



export enum TalentSortTypes {
  LAST_UPDATED_ASCENDING = 'LAST_UPDATED_ASCENDING',
  LAST_UPDATED_DESCENDING = 'LAST_UPDATED_DESCENDING',
  NAME_A_TO_Z = 'NAME_A_TO_Z',
  NAME_Z_TO_A = 'NAME_Z_TO_A',
  JOB_TITLE_A_TO_Z = 'JOB_TITLE_A_TO_Z',
  JOB_TITLE_Z_TO_A = 'JOB_TITLE_Z_TO_A',
  JOB_ID_ASCENDING = 'JOB_ID_ASCENDING',
  JOB_ID_DESCENDING = 'JOB_ID_DESCENDING',
  CUSTOMER_NAME_A_TO_Z = 'CUSTOMER_NAME_A_TO_Z',
  CUSTOMER_NAME_Z_TO_A = 'CUSTOMER_NAME_Z_TO_A',
  HIRING_MANAGER_A_TO_Z = 'HIRING_MANAGER_A_TO_Z',
  HIRING_MANAGER_Z_TO_A ='HIRING_MANAGER_Z_TO_A',
  VMS_ID_ASCENDING = 'VMS_ID_ASCENDING',
  VMS_ID_DESCENDING = 'VMS_ID_DESCENDING',
  CHRONOLOGICAL = 'CHRONOLOGICAL'
}

export enum TalentSortLabels{
  LAST_UPDATED_ASCENDING = 'last updated (ascending)',
  LAST_UPDATED_DESCENDING = 'last updated (descending)',
  NAME_A_TO_Z = 'name (a to z)',
  NAME_Z_TO_A = 'name (z to a)',
  JOB_TITLE_A_TO_Z = 'job title (a to z)',
  JOB_TITLE_Z_TO_A = 'job title (z to a)',
  JOB_ID_ASCENDING = 'job id (ascending)',
  JOB_ID_DESCENDING = 'job id (descending)',
  CUSTOMER_NAME_A_TO_Z = 'customer name (a to z)',
  CUSTOMER_NAME_Z_TO_A = 'customer name (z to a)',
  HIRING_MANAGER_A_TO_Z = 'hiring manager (a to z)',
  HIRING_MANAGER_Z_TO_A ='hiring manager (z to a)',
  VMS_ID_ASCENDING = 'vms id (ascending)',
  VMS_ID_DESCENDING = 'vms id (descending)',
  CHRONOLOGICAL = 'CHRONOLOGICAL'
}
