import { Applications } from '@allbirds-ui/allbirds-types';
import { createModel, Mapping, Model } from './model';
import { ClientInterview } from './process/client-interview.model';
import { Prescreening } from './process/prescreening.model';
import { InterviewSchedule } from './process/interview-schedule.model';
import { Interview } from './process/interview.model';
import { InternalSubmission } from './process/internal-submission.model';
import { ClientSubmission } from './process/client-submission.model';
import { Offer } from './process/offer.model';
import { IsoToMoment } from './serializers/moment.serializer';
import { Moment } from 'moment';
import { Serial } from './decorators/serial.decorator';
export import RawProcess = Applications.RandstadProcess;
import {TalentListProfileInfo} from './process/talent-list-profile.model';
import {Internal} from './decorators/internal.decorator';

class ProcessMapping extends Mapping<RawProcess> {
  @Serial(IsoToMoment) lastProcessStepDate?: Moment;
  @Serial(ClientInterview) clientInterview?: ClientInterview;
  @Serial(Prescreening) prescreening?: Prescreening;
  @Serial(InterviewSchedule) interviewSchedule?: InterviewSchedule;
  @Serial(Interview) interview?: Interview;
  @Serial(InternalSubmission) internalSubmission?: InternalSubmission;
  @Serial(ClientSubmission) clientSubmission?: ClientSubmission;
  @Serial(Offer) offer?: Offer;
  @Serial(IsoToMoment) addedTimestamp?: Moment;
  @Serial(IsoToMoment) rejectedTimestamp: Moment;
  @Internal() @Serial(TalentListProfileInfo) profileInfo: TalentListProfileInfo;
}

export interface Process extends Model<ProcessMapping> {}

export const Process = createModel<Process>(ProcessMapping);
