import { UserActions } from '@allbirds-ui/allbirds-types';
import { ActionByUser, ActionInfo } from '../internal/user-action.interface';
import { createModel, Mapping, Model } from "./model";
export import RawUserActionActivity = UserActions.UserActionActivity;
export import Action = UserActions.Action;

class UserActivityMapping extends Mapping<RawUserActionActivity> {}

export class BuildUA {
    actionByUser: ActionByUser;
    actionInfo: ActionInfo;
    usersAffected: string[];
    constructor(actionByUser: ActionByUser, actionInfo: ActionInfo, usersAffected: string[]) {
      this.actionByUser = actionByUser;
      this.actionInfo = actionInfo;
      this.usersAffected = usersAffected;
    }
}

export interface UserActionActivity extends Model<UserActivityMapping> {}

export const UserActionActivity = createModel<UserActionActivity>(UserActivityMapping);
