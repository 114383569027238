import { Serial } from './serial';
import { Jobs } from '@allbirds-ui/allbirds-types';
import RawCount = Jobs.Count;

export const Count: Serial<RawCount, number> = {
  deserialize(raw?: RawCount): number {
    return raw && raw.doc_count || 0;
  },
  serialize(model?: number): RawCount {
    return { doc_count: model || 0 };
  }
};
