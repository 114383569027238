import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Attachment} from '../../../models/internal/attachment.model';
import moment from 'moment';

@Component({
  selector: 'app-attachment-card-internal',
  templateUrl: './attachment-card-internal.component.html',
  styleUrls: ['./attachment-card-internal.component.scss']
})
export class AttachmentCardInternalComponent implements OnInit {

  @Input() cardType: string; // small or big
  @Input() categories: any;
  @Input() attachment: Attachment;
  @Input() hideDelete: boolean;
  @Output() update = new EventEmitter();
  @Output() delete = new EventEmitter();
  showDelete: boolean = true;

  constructor() { }

  ngOnInit() {
    const attachment: any = this.attachment
    if (attachment.resumeType === 'Primary' || attachment.resumeType === 'Previous Primary Resume') {
      this.showDelete = false;
    }
    if (this.hideDelete) {
      this.showDelete = false;
    }
  }

  redirect () {
    window.open(this.attachment.path, '_blank');
  }

  get momentDate () { return this.attachment.upload_time ? moment(this.attachment.upload_time) : null}

  updateClick() {
    this.update.emit(this.attachment);
  }

  deleteClick() {
    this.delete.emit(this.attachment);
  }

  get isLink (): any {
    return (this.attachment || {} as Attachment)['type'] === 'link';
  }
}
