/**
 * The text to display inside a conversation status badge
 */
export enum ConversationStatusesDisplay {
  SUCCESS = 'pass',
  FAIL = 'fail',
  LOADING = 'loading',
  IN_PROGRESS = 'in progress',
  NOT_STARTED = 'not started',
  DECLINED = 'declined'
}

/**
 * Style classes based on the ConversationStatusesDisplay. This will be applied
 * to the conversation status badge
 */
export enum BadgeStyleClasses {
  SUCCESS = 'badge-success',
  FAIL = 'badge-danger',
  LOADING = 'badge-info',
  IN_PROGRESS = 'badge-warning',
  NOT_STARTED = 'badge-info'
}
