import { Injectable } from '@angular/core';
import { FormBuilder , Validators} from '@angular/forms';
import { Appointments, ChatBots } from '@allbirds-ui/allbirds-types';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AllbirdsAppointment } from 'src/app/shared/models/external/appointment.model';
import {ListTalent} from 'src/app/shared/models/external/list-talent.model';
import { Profile } from 'src/app/shared/models/external/profile.model';
import { EmailInput } from 'src/app/shared/models/internal/mass-email.model';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { ApptTypeValues } from 'src/app/shared/services/appt-invite/appt-invite.interface';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { TalentListProfileInfo } from './../../models/external/process/talent-list-profile.model';
import { LogInterviewForm } from '../../components/talent-detail-pane/talent-process/talent-process-modals/log-interview-modal/log-interview-modal.component';
import { TalentActivity } from '../../models/external/talent-activity.model';
import moment from 'moment';
import { User } from '../../models/external/user.model';
import { IterableService } from '../iterable/iterable.service';
import AppointmentGoal = Appointments.AppointmentGoal;
import CandidateInfo = Appointments.CandidateInfo;
import RecruiterInfo = Appointments.RecruiterInfo;
import SendInviteToRCERequest = Appointments.SendInviteToRCERequest;
import InterviewStatus = ChatBots.InterviewStatus;
import InterviewType = ChatBots.InterviewType;

/**
 * This service is for getting the appointment/interview settings
 * form for modal components, managing known appointments for a list,
 * creating and updating appointments
 */
@Injectable()
export class ApptInviteService {

  appointments: BehaviorSubject<AllbirdsAppointment[]> = new BehaviorSubject<AllbirdsAppointment[]>([]);
  currentTalent: BehaviorSubject<ListTalent> = new BehaviorSubject<ListTalent>(null);

  constructor(
    private _formBuilder: FormBuilder,
    private _api: ApiService,
    private _auth: AuthService
  ) { }

  /**
   * Get the appointment-settings form group
   * @returns {FormGroup}
   */
  getApptSettingsForm() {
    const duration = this._auth.user.Source.checkLob('RT', 'RE') ? '30' : '15';
    const apptType = this._auth.user.Source.checkLob('RT', 'RE') ? [false, true, false] : [true, false, false];

    return this._formBuilder.group({
      apptGoal: [AppointmentGoal.RECRUITER_INTERVIEW],
      apptType: this._formBuilder.array(apptType),
      apptAddress: [''],
      interviewDuration: [duration]
    });
  }

  /**
   * Get the InterviewType which corresponds to boolean values passed
   * in apptTypeSelection
   * @param {boolean[]} apptTypeSelection
   * @returns {InterviewType[]}
   */
  getInterviewTypeValue(apptTypeSelection: boolean[]) {
    const returnVal: InterviewType[] = [];
    apptTypeSelection.forEach((apptType, idx) => {
      if (apptType === true) {
        returnVal.push(ApptTypeValues[idx]);
      }
    });
    return returnVal;
  }

  /**
   * Get the email message form
   * @returns {FormGroup}
   */
  getEmailMessageForm() {
    return this._formBuilder.group({message: [null, [Validators.required]]});
  }

  /**
   * Create new appointments for the array of appointments and update the local
   * appointments property
   * @param {AllbirdsAppointment[]} appointments
   */
  createAppointments(appointments: AllbirdsAppointment[]) {
    return this._api.createAppointments(appointments)
      .pipe(tap((res: any) => {
        this.updateAppointments(null, 'add', res.appointments);
        return res;
      }));
  }

  /**
   * Send appointment/interview invites to the api
   * @param {CandidateInfo[]} candidateInfos
   * @param {string} subject
   * @param {string} body
   */
  sendInvites(candidateInfos: CandidateInfo[], subject: string, body: string) {
    const recruiterInfo: RecruiterInfo = {
      firstName: this._auth.user.FirstName,
      lastName: this._auth.user.LastName,
      email: this._auth.user.EmailAddr,
      phone: this._auth.user.PrimaryPhone
    };
    const emailRequest: SendInviteToRCERequest = {
      recruiterInfo,
      candidateInfos,
      customText: body
    };
    return this._api.postMassAppointment(emailRequest);
  }

  getRecipientData(selectedTalent: Profile[] | TalentListProfileInfo[], appointments?: AllbirdsAppointment[]): CandidateInfo[] {
    const recips: CandidateInfo[] = [];
    selectedTalent.forEach((profile: any) => {
      const profileAppt = appointments.find(appt => appt.profileId === profile.profileId);
      const recipData: CandidateInfo = {
        appointmentId: profileAppt?._id,
        profileId: profile.profileId ? profile.profileId : '',
        firstName: profile.personNames && profile.personNames.length ? profile.personNames[0].structuredName.givenName : '',
        lastName: profile.personNames && profile.personNames.length ? profile.personNames[0].structuredName.familyName : '',
        email: profile.emailAddresses && profile.emailAddresses.length ? profile.emailAddresses[0].emailAddress : '',
        phone: profile.candidatePhoneNumber,
        interviewOptions: profileAppt?.appointment?.interviewType as string[],
        interviewDuration: profileAppt?.appointment?.interviewDuration,
      };
      if (profileAppt?.appointment?.interviewAddress) {
        recipData.interviewAddress = {
          addr1: profileAppt?.appointment?.interviewAddress,
          addr2: null,
          city: null,
          state: null,
          zipCode: null,
          description: null
        };
      }
      recips.push(recipData);
    });
    return recips;
  }

  getValidRecipientProfiles(selectedTalent: Profile []  | TalentListProfileInfo[], validEmailInputs: EmailInput[]) {
    const validProfiles: any[]  = [];
    validEmailInputs.forEach((emailInput) => {
      let emailProfile: TalentListProfileInfo | Profile ;

      for (const profile of selectedTalent) {
        if (profile && profile.emailAddresses && profile.emailAddresses.length && profile.emailAddresses[0].emailAddress.toLowerCase() === emailInput.email.toLowerCase()) {
          emailProfile = profile;
          break;
        }
      }
      /* For some reason the find is not accepting profile
        const emailProfile = selectedTalent.find((profile : Profile | TalentListProfileInfo) => {
        return profile.emailAddresses && profile.emailAddresses.length && profile.emailAddresses[0].emailAddress === emailInput.email;
      });*/
      if (emailProfile) {
        validProfiles.push(emailProfile);
      }
    });
    return validProfiles;
  }

  /**
   * Get the appointment for profileId
   * @param {string} profileId
   * @returns {AllbirdsAppointment}
   */
  getAppointmentForTalent(profileId: string) {
    if (this.appointments && this.appointments.value && this.appointments.value.length) {
      return this.appointments.value.find(appt => appt.profileId === profileId);
    }
    return null;
  }

  /**
   * Update the local appointments property with changes
   * @param {string} appointmentId
   * @param {'add'|'delete'|'update'} action
   * @param {AllbirdsAppointment[]} appointments
   * @returns {AllbirdsAppointment[]}
   */
  updateAppointments(appointmentId: string, action: 'add' | 'delete' | 'update', appointments?: AllbirdsAppointment[]) {
    if (this.appointments && this.appointments.value) {
      let updateApptIdx = -1;
      const newAppts = Array.from(this.appointments.value);
      if (appointmentId) {
        newAppts.find((appt,  idx) => {
          if (appt._id === appointmentId) {
            updateApptIdx = idx;
            return true;
          }
          return false;
        });
      }
      if (action === 'add' && appointments && appointments.length) {
        this.appointments.next([...newAppts, ...appointments]);
      } else if (updateApptIdx > -1) {
        newAppts.splice(updateApptIdx, 1);
        if (action === 'delete') {
          this.appointments.next(newAppts);
        } else if (action === 'update') {
          this.appointments.next([...newAppts, ...appointments]);
        }
      }
    }
    return this.appointments.value;
  }

  /**
   * Update the appointment in elastic and then the local appointments property
   * @param {AllbirdsAppointment} appointment
   */
  updateAppointment(appointment: AllbirdsAppointment) {
    return this._api.updateAppointment(appointment)
      .pipe(tap((res) => {
        if (appointment.appointment.interviewStatus === InterviewStatus.COMPLETE) {
          this.updateAppointments(appointment._id, 'delete');
        } else {
          this.updateAppointments(appointment._id, 'update', [appointment]);
        }
        return res;
      }));
  }

  setAppointments(appointments: AllbirdsAppointment[]) {
    this.appointments.next(appointments);
  }

  setCurrentTalent(listTalent: ListTalent) {
    this.currentTalent.next(listTalent);
  }

  getCurrentTalent() {
    return this.currentTalent.value;
  }

  destroyCurrentTalent() {
    this.currentTalent.next(null);
  }

  static constructInterviewActivity(profile: Profile, user: User, formValues: LogInterviewForm, orderType?: string): TalentActivity {
    const lob = IterableService.extractLobFromProfile(profile);
    const isRGS = lob.checkLob('RGS');
    const now = moment();
    const pipeline = (orderType == 'Pipeline') ? true : false;
    return new TalentActivity({
      activityType: isRGS ? ApptInviteService.getRGSActivityType(formValues.type) : ApptInviteService.getRTActivityType(formValues.type, formValues.channel),
      comments: formValues.notes,
      moEmplId: profile.externalId,
      pipeline,
      timestamp: now,
      activityTimestamp: now
    });
  }

  static constructActivityFromAppointment(appointment: AllbirdsAppointment, orderType?: string): TalentActivity {
    const { appointmentFeedback, profileFrontOfficeID } = appointment;
    const lob = profileFrontOfficeID.abIncludesLob('RGS') ? 'RGS' : 'RT';
    const isRGS = lob.checkLob('RGS');
    const now = moment();
    const pipeline = (orderType == 'Pipeline') ? true : false;

    return new TalentActivity({
      activityType: isRGS ? ApptInviteService.getRGSActivityType(appointmentFeedback.type) : ApptInviteService.getRTActivityType(appointmentFeedback.type, appointmentFeedback.channel),
      comments: appointmentFeedback.notes,
      moEmplId: profileFrontOfficeID,
      timestamp: now,
      pipeline,
      activityTimestamp: now
    });
  }

  static getRGSActivityType (status: 'COMPLETE' | 'COMPLETED' | 'CANCELLED' | 'NO_SHOW'): string {
    return (['COMPLETE', 'COMPLETED'].includes(status)) ? 'TINTV' : '';
  }

  static getRTActivityType (status: 'COMPLETE' | 'COMPLETED' | 'CANCELLED' | 'NO_SHOW', channel: string): string {
    if (['COMPLETE', 'COMPLETED'].includes(status)) {
      const types: { [channel: string]: string } = {
        PHONE: 'Recruiter Phone Screen',
        VIDEO: 'Webcam Interview',
        IN_PERSON: 'Recruiter In Person'
      };
      return types[channel] || '';
    }
    return '';
  }

}
