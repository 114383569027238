import { Component, OnDestroy, OnInit, /*ViewChildren,*/ ViewChild, AfterViewInit, QueryList } from '@angular/core';
import { PdfJsViewerComponent} from 'ng2-pdfjs-viewer';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { PaginateService } from 'src/app/shared/services/paginate/paginate.service';
import { TalentService } from 'src/app/shared/services/talent/talent.service';
import { Profile } from '../../../../models/external/profile.model';
import { Application } from '../../../../models/external/application.model';
import { ExplainService } from 'src/app/shared/services/explain/explain.service';

@Component({
  selector: 'app-talent-resume-modal',
  templateUrl: './talent-resume-modal.component.html',
  styleUrls: ['./talent-resume-modal.component.scss']
})
export class TalentResumeModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('pdfViewerModal') private pdfViewer: PdfJsViewerComponent;
  // @ViewChildren('pdfViewerModal') public viewers: QueryList<PdfJsViewerComponent>;
  // private pdfViewer: PdfJsViewerComponent;
  pdf: Blob;
  errors: any;
  resumeSub: Subscription;
  errorSub: Subscription;

  constructor(
    public modalRef: BsModalRef,
    public talentService: TalentService,
    public paginateService: PaginateService<Application | Profile>,
    private _explain: ExplainService
  ) { }

  ngOnInit() {
    this.resumeSub = this.paginateService.resumeObservable
      .subscribe((url) => {
        this.pdf = url;
        this.displayPDF(this.pdf);
      });

    this.errorSub = this.talentService.resumeErrorObservable
      .subscribe((errors) => {
        this.errors = errors;
      });
  }

  public ngAfterViewInit (): void {}

  displayPDF (blob: Blob) {
    // postponing the resume viewer update to the next iteration of the event loop seems to solve
    // a display bug. Not sure why. - JDS
    setTimeout(() => {
      this.pdf = blob;
      this.pdfViewer.pdfSrc = blob;
      this.pdfViewer.refresh();      
    });
  }

  // highlight search terms
  pdfLoaded ($event: any) {
    // use 'findagain' instead of 'find' to avoid debounce since we don't need it here - JDS
    // https://github.com/mozilla/pdf.js/blob/master/web/pdf_find_controller.js#L286
    if (this._explain.keywords && this._explain.keywords.length) {
      this.pdfViewer.PDFViewerApplication.findController.executeCommand('findagain', {
        caseSensitive: false, 
        findPrevious: undefined,
        highlightAll: true, 
        phraseSearch: false, 
        query: this._explain.keywords.join(' ')
      });
    }
  }

  ngOnDestroy() {
    if (this.errorSub) {
      this.errorSub.unsubscribe();
    }
  }

  paginate(direction: 'left' | 'right') {
    this.paginateService.paginate(direction);
  }
}
