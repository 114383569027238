import { createModel, Mapping, Model } from './model';
import { Prompts } from '@allbirds-ui/allbirds-types';
import { Serial } from './decorators/serial.decorator';
import { IsoToMoment } from './serializers/moment.serializer';
import { Moment } from 'moment';
export import RawConversationPrompt = Prompts.ConversationPrompt;

class ConversationPromptMapping extends Mapping<RawConversationPrompt> {
  @Serial(IsoToMoment) startTime: Moment;
  @Serial(IsoToMoment) endTime: Moment;
  failedFollowups = 0;
}

export interface ConversationPrompt extends Model<ConversationPromptMapping> {}

export const ConversationPrompt = createModel<ConversationPrompt>(ConversationPromptMapping);
