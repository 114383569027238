import {
  AfterViewChecked,
  AfterViewInit,
  Component, ContentChild, ContentChildren, Directive, ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output, QueryList,
  TemplateRef,
  ViewChild, ViewChildren,
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {IntersectionObserverEvent} from 'src/app/shared/components/talent-detail-pane/talent-process/recruitment-phases/recruitment-phases.interface';
import { LogInterviewModalComponent } from '../../talent-process-modals/log-interview-modal/log-interview-modal.component';
import { JobOrderService } from 'src/app/shared/services/job-order/job-order.service';
import { UtilityService } from 'src/app/shared/services/utility/utility.service';
import { TalentProcessService } from 'src/app/shared/services/talent-process/talent-process.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { ToastClass, ToastService } from 'src/app/shared/services/toast';
import { TalentService } from 'src/app/shared/services/talent/talent.service';
import { MetricsService } from 'src/app/shared/services/metrics/metrics.service';
import { GoogleConversationService } from 'src/app/shared/services/google-conversation/google-conversation.service';
import { Subscription } from 'rxjs';
import { JobDetailsService } from 'src/app/shared/services/job-details/job-details.service';
import { Application } from 'src/app/shared/models/external/application.model';
import { IterableService } from '../../../../../services/iterable/iterable.service';
import { JobDetailsShortlistService } from '../../../../../services/job-details-shortlist/job-details-shortlist.service';
import { ApiService } from '../../../../../services/api/api.service';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { Job } from '../../../../../models/external/job.model';
import { Profile } from '../../../../../models/external/profile.model';
import { ProcessStatus, ProcessStep } from 'src/app/shared/models/external/misc.model';
import moment from 'moment';
import { Conversation } from '../../../../../models/external/conversation.model';
import { Prescreening } from 'src/app/shared/models/external/process/prescreening.model';
import { Process } from '../../../../../models/external/process.model';
import { INTG_STEPS } from 'src/app/shared/models/internal/process.model';
import { ApplicationState } from '../../../../../models/internal/application-state.interface';
import { Conversations } from '@allbirds-ui/allbirds-types';
import RCEStage = Conversations.RCEStage;
import {RecruitmentPhasesService} from '../recruitment-phases.service'

@Component({
  selector: 'prescreening-phase',
  templateUrl: './prescreening.component.html',
  styleUrls: [
    './prescreening.component.scss',
    '../recruitment-phases.component.scss'
  ]
})
export class PrescreeningComponent implements OnInit, OnDestroy, AfterViewChecked {

  // Determines which template to show.
  shownPhase: TemplateRef<any>;
  previousShownPhase: TemplateRef<any>;
  appSub: Subscription;
  jobOrderSub: Subscription;
  application: Application;
  statusLabel: string;
  applicationID: string;
  googleJobID: string;
  googleTalentID: string;
  jobOrder: Job;
  modalRef: BsModalRef;
  conversationID: string;
  profile: Profile;
  conversationSub: Subscription;
  scheduledPrompt: any;
  chatbotDisabled: boolean = false;
  chatbotHidden: boolean = false;
  disabledChatbotMsg: string = '';

  //Determines if the caution modal for interviewed candidates
  showingCautionModal:boolean = false

  @Input() applicationDisabled: boolean;
  @Input() applicationDisabledMsg: string;
  @Input() talentInterviewStatus:boolean;

  // Flag for determining if the received update on a conversation
  // was the initial document return from Firestore.
  initialConversationUpdate: boolean;

  // Stores the latest conversation status so we can compare it to
  // a newly-received conversation and determine if we need to update.
  conversationStatus: string;

  // Emits an event that triggers the markAsUnfit() call in recruitment-phases component.
  @Output() unfitClick = new EventEmitter();
  // The different templates for the different phases.
  @ViewChild('selectAction', { static: true }) selectActionView: TemplateRef<any>;
  @ViewChild('questionsSent', { static: true }) questionsSentView: TemplateRef<any>;
  @ViewChild('manualPresceen', { static: true }) manualPresceenView: TemplateRef<any>;
  @ViewChild('questionsReceived', { static: true }) questionsReceivedView: TemplateRef<any>;
  @ViewChild('reviewInterview', { static: true }) reviewInterview: TemplateRef<any>;
  @ViewChild('ctaContainer', {static: false}) ctaContainer: ElementRef;
  @ViewChild('ctaContainerBoundry', {static: false}) ctaContainerBoundry: ElementRef;

  constructor(
    private _jobOrderService: JobOrderService,
    private _modalService: ModalService,
    private _utils: UtilityService,
    private talentProcess: TalentProcessService,
    private googleConversationService: GoogleConversationService,
    private auth: AuthService,
    private toast: ToastService,
    private talentService: TalentService,
    private zone: NgZone,
    private _metrics: MetricsService,
    private _job: JobDetailsService,
    private _shortlist: JobDetailsShortlistService,
    private _api: ApiService,
    private _recruitmentPhasesService: RecruitmentPhasesService,
  ) {
  }

  ngOnInit() {
    this.listenToJobOrder();
    this.appSub = this.talentProcess.selectedApplication.subscribe(this.handleApplicationChange.bind(this));
    this.talentProcess.selectedProfile.subscribe((data) => {
      this.profile = data;
    });
  }

  ngOnDestroy() {
    UtilityService.destroySubscription(this.appSub);
    UtilityService.destroySubscription(this.jobOrderSub);
    UtilityService.destroySubscription(this.conversationSub);
  }


  ngAfterViewChecked() {
    // careful stuff here is expensive
    if (this.previousShownPhase !== this.shownPhase) {
      this.previousShownPhase = this.shownPhase;
      if (this.ctaContainer && this.ctaContainer.nativeElement) {
        const elements = [
          this.ctaContainer.nativeElement,
          this.ctaContainerBoundry.nativeElement
        ];
        this.talentProcess.setupIntersectionObserver(elements);
      }
    }
  }

  /**
   * Returns the proper TemplateRef to display on the UI depending on what sub-status
   * the talent is in.
   * @param status - [application].randstad_process.lastProcessStatus
   */
  getView(status: ProcessStatus): TemplateRef<any> {
    switch (status) {
      case ProcessStatus.P_START:
        return this.selectActionView;
      case ProcessStatus.P_SENT:
        return this.questionsSentView;
      case ProcessStatus.P_IN_PROGRESS:
      case ProcessStatus.P_AWAITING_RESULTS:
        return this.questionsReceivedView;
      case ProcessStatus.P_REVIEW_QUESTIONS:
      case ProcessStatus.P_DECIDE_PROCEED:
        return (
          this.application &&
          this.application.randstad_process &&
          this.application.randstad_process.prescreening &&
          this.application.randstad_process.prescreening.isManualPrescreening
        ) ? this.manualPresceenView : this.reviewInterview;
      case ProcessStatus.P_NOT_A_FIT:
      default:
        return null;
    }
  }

  /**
   * Handler function when the TalentProcess service emits a new selectedApplication.
   * @param application - talent application object
   */
  handleApplicationChange(application: Application) {
    // Need this flag so we don't re-fire conversation subscribing/unsubscribing.
    const isSameTalent = !!(this.application && (this.application.profile === application.profile));
    this.application = application;

    // Get the status label.
    if (this.application && this.auth.user) {
      this.statusLabel = this.talentProcess.getLabel(this.application, this.auth.user.Source, 'process', this._jobOrderService.storedJobOrder, this.auth.user);
    }

    // Set the application ID.
    if (this.application && this.application.randstad_process && this.application.randstad_process._id) {
      this.applicationID = this.application.randstad_process._id;
    }

    // Setup view.
    const lastProcessStatus = this.application && this.application.randstad_process && this.application.randstad_process.lastProcessStatus ? this.application.randstad_process.lastProcessStatus : ProcessStatus.P_START;
    this.shownPhase = this.getView(lastProcessStatus);

    // Subscribe to conversation.
    if (!isSameTalent) {
      this.conversationID = '';
      this.conversationStatus = '';
      UtilityService.destroySubscription(this.conversationSub);
      this.checkConversation(application);
    }
    console.log('handleApplicationChange, this.ctaContainer=', this.ctaContainer);
  }

  updateShortlist(conversation: any) {
    if (conversation && conversation.profile && conversation.job) {
      this._api.getTalentApplication(conversation.profile, false, conversation.job)
        .subscribe(app => {
          if (app) {
            this._shortlist.handleChatbotApplication(app);
            if (!app.randstad_process.rejected) {
              this.talentProcess.selectApplication(app);
            }
          }
        });
    }
  }

  /**
   * @deprecated(Tim)
   * Only leaving this here for now in case it needs to make a comeback -- but we're no longer parsing the conversation
   * for a scheduled prompt because if the talent chooses one and IS fails in integrations, then UI will wrongly show
   * that a conversation was successfully scheduled. Instead, we should be using randstad_process.interviewSchedule.timeSlotSelected
   * as the application in ES is updated w/ this value on success. If that field is present, we'd be on step 2 of the
   * process steps anyways.
   */
  interviewScheduled(conversation: Conversation) {
    // console.log(conversation);
    let scheduledPrompt;
    if (conversation.completedPrompts) {
      scheduledPrompt = conversation.completedPrompts.find(prompt => prompt.promptTemplateName === 'interview_schedule_time');
    }
    return scheduledPrompt;
  }

  /**
   * Given an application, checks to see if we should subscribe to the conversation on
   * Firestore based on (a) if there's a conversation ID, or (b) if manual pre-screening
   * wasn't performed. Case (b) is necessary because it's the only way to get a real-time
   * update in the case where you're viewing a talent as they initiate the chatbot.
   * @param application - talent application object
   */
  checkConversation(application: Application) {
    if (
      application &&
      application.randstad_process &&
      application.randstad_process.prescreening
    ) {
      const isManual = !!application.randstad_process.prescreening.manualScreeningAnswers;
      this.conversationID = application.randstad_process.prescreening.conversationID || undefined;
      if (this.conversationID || !isManual) {
        this.conversationStatus = '';
        this.initialConversationUpdate = true;
        this.conversationSub = this.googleConversationService
          .getConversation(application.job, application.profile)
          .subscribe(this.conversationHandler.bind(this));
      }
    }
  }

  conversationHandler(conversation: Conversation) {
    if (conversation && Object.keys(conversation).length) {
      const { name, status, rce } = conversation;
      const isFinished = status && (status === 'FINISHED');
      const isInProgress = rce && rce.stages_reached && rce.stages_reached.includes(RCEStage.SCREENING_STARTED);

      // Set class properties.
      this.conversationID = name;
      /**
       * Context: We need to perform some in-memory updates on the application to determine the view due to the
       * fact that the Google conversation status isn't a one-to-one mapping with our PROCESS_SUB_STATUS values
       * (e.g., when the chatbot link is opened, Google/FS chat status is "IN_PROGRESS" but ES application status
       * stays as "P_SENT" until the talent clicks "Yes").
       * 
       * Update: After ticket# DF044-7416 talent can have either lastProcessStatus as "P_START" or "P_SENT" with conversationID so instead of setting the lastProcessStatus
       * as "P_SENT" by default, we now keep the original value.
       */
      if (!!rce) {
        this.application.randstad_process.lastProcessStatus = isFinished
          ? ProcessStatus.P_REVIEW_QUESTIONS
          : isInProgress
            ? ProcessStatus.P_IN_PROGRESS
            : this.application.randstad_process.lastProcessStatus;
      } else {
        this.application.randstad_process.lastProcessStatus = isFinished ? ProcessStatus.P_REVIEW_QUESTIONS : ProcessStatus.P_IN_PROGRESS;
      }
      this.application.updateTime = moment();
      this.statusLabel = this.talentProcess.getLabel(this.application, this.auth.user.Source, 'process', this._jobOrderService.storedJobOrder, this.auth.user);
      this.shownPhase = this.getView(this.application.randstad_process.lastProcessStatus);
      /**
       * Context: if we don't use an initial conversation update flag, this logic will update the
       * application in the shortlist upon initial receipt of the conversation. We only need this
       * to fire when we receive an update AFTER already receiving the conversation initially.
       */
      if (!this.initialConversationUpdate && this.conversationStatus !== status) {
        this.updateShortlist(conversation);
      } else {
        // Emit local application update is used to allow us to modify the shortlist card in-memory
        // whilst not updating the subject that would cause the entire shortlist to be re-rendered.
        this._shortlist.emitLocalApplicationUpdate(this.application);
      }
      // Set flags.
      this.conversationStatus = status;
      this.initialConversationUpdate = false;
    }
  }

  listenToJobOrder() {
    this.jobOrderSub = this._jobOrderService.jobOrderObservable
      .subscribe(job => {
        this.jobOrder = job;
        this.checkExpired();
      });
  }

  submit(final: boolean) {
    const pipelineFlag = (this.jobOrder.allbirds_metadata.order_type == 'Pipeline') ? true : false;
    if (this.talentProcess.showToastIfRejected(this.application)) {
      return;
    }
    if (!final && this.chatbotDisabled) {
      return;
    }
    if (!final) {
      // if we dont have front office id or the job is not published
      // Removed validation per DF044-5749 @Tyrell
      // if (
      //   (this.application && !this.application.randstad_process.jobFrontOfficeOrderID && !this._jobOrderService.storedJobOrder.allbirds_metadata.front_office_id || !this._jobOrderService.storedJobOrder.allbirds_metadata.published_status)) {
      //   return this._utils.launchFeatureUnavailableModal('feature-unavailable.JOB_NOT_PUBLISHED');
      // }
      // if we dont have a google profile field
      if (!this.application.profile) {
        return this._utils.launchFeatureUnavailableModal('feature-unavailable.PROFILE_NO_ID');
      }
    }
    const key = this.application.randstad_process.lastProcessStep;
    const body = this.constructBody(final);
    let intgSteps: INTG_STEPS[] = [];
    if (!final) {
      //chatbot integration steps
      intgSteps.push(INTG_STEPS.ACTIVITY);
    }

    this.talentProcess.updateApplication(body, intgSteps, key, true, pipelineFlag).then((data) => {
      if (data) {
        if (final) {
          this.toast.showToast('prescreening.next_step');
          this._metrics.addEventToQueue(this.application, `the candidate was moved to the recruiter interview stage`);
        } else {
          this._metrics.addEventToQueue(this.application, `a prescreening invitation was sent to the candidate`);
          this.toast.showToast('prescreening.interview_sent');
          this.sendPrescreening();
        }
      } else {
        this.toast.showToast('prescreening.error_sending', { cssClass: ToastClass.DANGER });
      }
    }, err => {
      console.log(err);
      this.toast.showToast('prescreening.error_sending', { cssClass: ToastClass.DANGER });
    });
  }

  test() {
    console.log('Test!!!');
    this.talentProcess.selectedApplicationStateSubject.next({
      disabled: true,
      disabledMsg: 'Disabled now!',
      hidden: false
    });
  }

  constructBody(final: boolean): Application {
    const clonedApp = this.application.clone();
    if (final && this.application && this.application.randstad_process) {
      const process = this.getNextProcess(clonedApp);
      clonedApp.randstad_process.apply(process);
      clonedApp.randstad_process.prescreening.apply({
        agentID: this.auth.user.BackOfficeID
        // outcome: "Something",
      });
    } else {
      clonedApp.randstad_process.apply({
        lastProcessStatus: ProcessStatus.P_SENT,
        prescreening: new Prescreening({
          skipped: false,
          isManualPrescreening: false,
          initiatedDate: moment(),
          agentID: this.auth.user.BackOfficeID
        })
      });
    }
    return clonedApp;
  }

  sendPrescreening() {
    const event = IterableService.constructIterableEvent(this.application, this.profile, this._jobOrderService.storedJobOrder, this.auth.user);
    this._api.scheduleChatbotPrescreening({ events: [event] })
      .subscribe(data => {
        console.log(data);
        this.conversationSub = this.googleConversationService.getConversation(this.application.job, this.application.profile).subscribe(this.conversationHandler.bind(this));
      }, err => {
        console.log(err);
      });
  }

  openLogModal() {
    if(this._recruitmentPhasesService.talentInterviewStatus){
      this._recruitmentPhasesService.openWarningModal()
    }else{
      if (this.talentProcess.showToastIfRejected(this.application)) {
        return;
      }
      this._modalService.show(LogInterviewModalComponent);
    }
  }

  emitUnfitClick() {
    if (this.talentProcess.showToastIfRejected(this.application)) {
      return;
    }
    this.unfitClick.emit();
  }

  checkExpired() {
    const chatbotDisabled = this._jobOrderService.checkChatbotDisabled(this.jobOrder);
    this.chatbotDisabled = chatbotDisabled.disabled;
    this.chatbotHidden = chatbotDisabled.hidden;
    this.disabledChatbotMsg = chatbotDisabled.msg;
    // console.log('isDisabled?', this.chatbotDisabled, this.disabledChatbotMsg);
    return this.chatbotDisabled;
  }

  get chatbotDisabledState(): ApplicationState {
    return {
      disabled: this.chatbotDisabled,
      disabledMsg: this.disabledChatbotMsg,
      hidden: this.chatbotHidden,
    };
  }

  /**
   * A wrapper of the 'submit' function that allows us to provide additional validation for
   * the 'prescreening-by-chatbot' call.
   */
  attemptChatbot(): void {
    if (!this.chatbotDisabled) {
      return this.submit(false);
    }
  }

  /**
   * Used in the template to show different CTA text for different LOBs.
   */
  get isRT(): boolean {
    return this.auth.user.Source.checkLob('RT', 'RE','CR');
  }

  /**
   * Returns the next process step & status pair we should set the application to based
   * on if its an RGS/RT job and whether or not it was manual/automated prescreening.
   */
  getNextProcess(app: Application): Partial<Process> {
    const result: Partial<Process> = new Process({});
    const isSolutionsJob = this._jobOrderService.isSolutionsJob(this.jobOrder);
    const isManualPrescreening = (
      app &&
      app.randstad_process &&
      app.randstad_process.prescreening &&
      app.randstad_process.prescreening.isManualPrescreening
    ) || false;
    if (this.isRT && isManualPrescreening) {
      result.apply({ lastProcessStep: ProcessStep.REVIEW_AM });
      result.apply({ lastProcessStatus: isSolutionsJob ? ProcessStatus.RA_SUBMIT_TO_SS : ProcessStatus.RA_SUBMIT_TO_AM });
    } else {
      result.apply({ lastProcessStep: ProcessStep.INTERVIEW_RECRUITER });
      result.apply({ lastProcessStatus: ProcessStatus.IR_PLAN_INTERVIEW });
    }
    return result;
  }

}
