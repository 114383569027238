import { Conversations } from '@allbirds-ui/allbirds-types';
import { createModel, Mapping, Model } from './model';
import { ConversationPrompt } from './conversation-prompt.model';
import { Serial } from './decorators/serial.decorator';
import { Moment } from 'moment';
import { GrpcToMoment } from './serializers/moment.serializer';
export import RawConversation = Conversations.Conversation;

class ConversationMapping extends Mapping<RawConversation> {
  @Serial([ConversationPrompt]) completedPrompts: ConversationPrompt[] = [];
  @Serial(ConversationPrompt) nextPrompt: ConversationPrompt;
  @Serial(GrpcToMoment) startTime: Moment;
  @Serial(GrpcToMoment) endTime: Moment;
  // TODO(jason): where is below coming from?
  answeredPrompts: ConversationPrompt[] = [];
}

export interface Conversation extends Model<ConversationMapping> {}

export const Conversation = createModel<Conversation>(ConversationMapping);
