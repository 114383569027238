<!--
  The proper TemplateRef is rendered in the following <div> depending on what sub-step the talent
  is on as well as what the role of the user is.
-->
<div class="phase" [ngTemplateOutlet]="shownPhase"></div>

<ng-template #startInterview>
  <h4 class="phase-subtitle">{{statusLabel}}</h4>
  <div class="phase-body-section" *ngIf="conversationID">
    <prescreening-questions [conversationID]="conversationID"></prescreening-questions>
  </div>
  <div #ctaContainerBoundry id="ctaContainerBoundry"></div>
  <ul #ctaContainer class="action-buttons hz-btn-list">
    <li>
      <app-talent-process-cta
        [id]="'interview-not-a-fit'"
        (execute)="emitUnfitClick($event)"
        [invertColor]="true">
        {{'virtual-interview.not_a_fit' | translate}}
      </app-talent-process-cta>
    </li>
    <li>
      <!-- Split dropright button -->
      <div class="btn-group dropdown" dropdown *ngIf="!chatbotHidden">
        <app-talent-process-cta
          [id]="'interview-schedule-by-chatbot-button'"
          (execute)="submit()"
          [state]="applicationDisabledState">
          {{'virtual-interview.schedule_per_chatbot' | translate}}
        </app-talent-process-cta>
        <button dropdownToggle type="button" style="border-left:1px solid white"
          class="btn btn-primary dropdown-toggle dropdown-toggle-split" aria-controls="schedule-dropdown" data-puppeteer="schedule-interview-dropdown">
          <span class="sr-only">{{'virtual-interview.toggle_dropdown' | translate}}</span>
        </button>
        <div id="schedule-dropdown" *dropdownMenu class="dropdown-menu">
          <app-talent-process-cta
            [id]="'interview-log-interview-button'"
            [cssClasses]="'dropdown-item'"
            [invertColor]="true"
            [useButton]="false"
            (execute)="openLoggingModal()">
            {{ 'virtual-interview.log_interview' | translate }}
          </app-talent-process-cta>
        </div>
      </div>
      <div *ngIf="chatbotHidden">
        <app-talent-process-cta
          [id]="'interview-log-interview-button'"
          [cssClasses]="'btn rds--button--primary'"
          [invertColor]="true"
          [useButton]="false"
          (execute)="openLoggingModal()">
          {{ 'virtual-interview.log_interview' | translate }}
        </app-talent-process-cta>
      </div>
    </li>
  </ul>
</ng-template>

<ng-template #conductInterview>
  <h4 class="phase-subtitle">{{statusLabel}}</h4>
  <div class="phase-body-section">
    <div class="gray-box">
      <div class="img-container">
        <vertical-align>
          <img src="assets/icons/calendar.svg"  />
        </vertical-align>
      </div>
      <div class="text-container">
        <vertical-align>
          <p>Chatbot interview invitation was sent on
            {{application?.randstad_process?.interviewSchedule?.interviewScheduledOn | abDatetime}}. Waiting for
            results.</p>
        </vertical-align>
      </div>
    </div>
  </div>
  <div class="phase-body-section" *ngIf="conversationID">
    <prescreening-questions [conversationID]="conversationID"></prescreening-questions>
  </div>
  <div #ctaContainerBoundry id="ctaContainerBoundry"></div>
  <ul #ctaContainer class="action-buttons hz-btn-list">
    <li>
      <app-talent-process-cta
        [id]="'interview-not-a-fit'"
        (execute)="emitUnfitClick()"
        [invertColor]="true">
        {{'virtual-interview.not_a_fit' | translate}}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        [id]="'interview-log-interview-button'"
        (execute)="openLoggingModal()">
        {{ 'virtual-interview.log_interview' | translate }}
      </app-talent-process-cta>
    </li>
  </ul>
</ng-template>

<ng-template #reviewInterview>
  <div>
    <h4 class="phase-body-title pb-2">
      {{ application?.randstad_process?.interviewSchedule.interviewType | stringclean: ['titlecase', 'sansunderscore'] }}
      interview scheduled!
    </h4>
    <div class="gray-box">
      <div class="img-container">
        <vertical-align>
          <img src="assets/icons/calendar.svg"  />
        </vertical-align>
      </div>
      <div class="text-container">
        <vertical-align>
          <p class="statusLabel">{{statusLabel}}
          </p>
          <p *ngIf="application?.randstad_process?.interviewSchedule.inPersonInterviewLocation">
            <img src="assets/icons/location.svg" class="location-small"  />
            {{application?.randstad_process?.interviewSchedule.inPersonInterviewLocation}}
          </p>
        </vertical-align>
      </div>
      <div class="cancel">
        <span id="cancel" (click)="cancelInterviewModal()">cancel interview</span>
      </div>
    </div>
  </div>
  <div class="phase-body-section" *ngIf="conversationID">
    <prescreening-questions [conversationID]="conversationID"></prescreening-questions>
  </div>
  <ul class="action-buttons hz-btn-list">
    <li>
      <app-talent-process-cta
        [id]="'interview-not-a-fit'"
        (execute)="emitUnfitClick()"
        [invertColor]="true">
        {{'virtual-interview.not_a_fit' | translate}}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        [id]="'interview-log-interview-button'"
        (execute)="openLoggingModal()">
        {{ 'virtual-interview.log_interview' | translate }}
      </app-talent-process-cta>
    </li>
  </ul>
</ng-template>

<ng-template #interviewCanceled>
  <div>
    <div class="gray-box">
      <div class="img-container">
        <vertical-align>
          <img src="assets/icons/alert-red.svg"  />
        </vertical-align>
      </div>
      <div class="text-container">
        <vertical-align>
          <p class="statusLabel">{{statusLabel}}</p>
        </vertical-align>
      </div>
    </div>
  </div>
  <div class="phase-body-section" *ngIf="conversationID">
    <prescreening-questions [conversationID]="conversationID"></prescreening-questions>
  </div>
  <div #ctaContainerBoundry id="ctaContainerBoundry"></div>
  <ul #ctaContainer class="action-buttons hz-btn-list">
    <li class="invert"><button class="btn rds--button--primary" (click)="emitUnfitClick()">{{'virtual-interview.not_a_fit' | translate}}</button></li>
    <li>
      <!-- Split dropright button -->
      <div class="btn-group dropdown" dropdown>
        <app-talent-process-cta
          [id]="'interview-schedule-by-chatbot-button'"
          (execute)="submit()">
          {{'virtual-interview.reschedule_per_chatbot' | translate}}
        </app-talent-process-cta>
        <button dropdownToggle type="button" style="border-left:1px solid white"
                class="btn btn-primary dropdown-toggle dropdown-toggle-split" aria-controls="schedule-dropdown" data-puppeteer="schedule-interview-dropdown">
          <span class="sr-only">{{'virtual-interview.toggle_dropdown' | translate}}</span>
        </button>
        <div id="schedule-dropdown" *dropdownMenu class="dropdown-menu">
          <app-talent-process-cta
            [id]="'interview-log-interview-button'"
            [cssClasses]="'dropdown-item'"
            [invertColor]="true"
            [useButton]="false"
            (execute)="openLoggingModal()">
            {{ 'virtual-interview.log_interview' | translate }}
          </app-talent-process-cta>
        </div>
      </div>
    </li>
  </ul>
</ng-template>

<ng-template #noTimeSlot>
  <div>
    <div class="gray-box">
      <div class="img-container">
        <vertical-align>
          <img src="assets/icons/alert-red.svg"  />
        </vertical-align>
      </div>
      <div class="text-container">
        <vertical-align>
          <p class="statusLabel">{{statusLabel}}</p>
        </vertical-align>
      </div>
    </div>
  </div>
  <div class="phase-body-section" *ngIf="conversationID">
    <prescreening-questions [conversationID]="conversationID"></prescreening-questions>
  </div>
  <div #ctaContainerBoundry id="ctaContainerBoundry"></div>
  <ul #ctaContainer class="action-buttons hz-btn-list">
    <li>
      <app-talent-process-cta
        [id]="'interview-not-a-fit'"
        (execute)="emitUnfitClick()"
        [invertColor]="true">
        {{'virtual-interview.not_a_fit' | translate}}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        [id]="'interview-log-interview-button'"
        (execute)="openLoggingModal()">
        {{ 'virtual-interview.log_interview' | translate }}
      </app-talent-process-cta>
    </li>
  </ul>
</ng-template>

<ng-template #completeInterview>
  <h4 class="phase-subtitle">{{statusLabel}}</h4>
  <div class="phase-body-section">
    <recruiter-interview-manual-questions [application]="application"></recruiter-interview-manual-questions>
  </div>
  <div class="phase-body-section" *ngIf="conversationID">
    <prescreening-questions [conversationID]="conversationID"></prescreening-questions>
  </div>
  <div #ctaContainerBoundry id="ctaContainerBoundry"></div>
  <ul #ctaContainer class="action-buttons hz-btn-list">
    <li>
      <app-talent-process-cta
        [id]="'interview-not-a-fit'"
        (execute)="emitUnfitClick()"
        [invertColor]="true">
        {{'virtual-interview.not_a_fit' | translate}}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        [id]="'prepare-submission-button'"
        (execute)="final()">
        {{ 'virtual-interview.prepare_submission' | translate }}
      </app-talent-process-cta>
    </li>
  </ul>
</ng-template>

<ng-template #completePipelineInterview>
  <h4 class="phase-subtitle">{{statusLabel}}</h4>
  <div class="phase-body-section">
    <recruiter-interview-manual-questions [application]="application"></recruiter-interview-manual-questions>
  </div>
  <div class="phase-body-section" *ngIf="conversationID">
    <prescreening-questions [conversationID]="conversationID"></prescreening-questions>
  </div>
  <div #ctaContainerBoundry id="ctaContainerBoundry"></div>
  <ul #ctaContainer class="action-buttons hz-btn-list">
    <li>
      <app-talent-process-cta [id]="'interview-not-a-fit'" (execute)="emitUnfitClick()" [invertColor]="true">
        {{'virtual-interview.not_a_fit' | translate}}
      </app-talent-process-cta>
    </li>
    <li *ngIf="actions.length">
      <div class="btn-group dropdown" dropdown>
        <button [class.disabled]="disabled" [isDisabled]="!disabled"
          tooltip="You cannot {{actions[0].label}} because the talent is not from your line of business."
          class="btn rds--button--secondary add-to-job-btn" (click)="actions[0].onClick()">
          <span class="icon"></span>
          <span>{{actions[0].label}}</span>
        </button>
        <button *ngIf="disabled" type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split disabled"
          aria-controls="add-dropdown">
          <span class="sr-only">{{'talent-detail-pane.toggle_dropdown' | translate}}</span>
        </button>
        <button *ngIf="!disabled" type="button" dropdownToggle
          class="btn btn-primary dropdown-toggle dropdown-toggle-split" aria-controls="add-dropdown">
          <span class="sr-only">{{'talent-detail-pane.toggle_dropdown' | translate}}</span>
        </button>
        <ul id="add-dropdown" *dropdownMenu class="dropdown-menu">
          <li class="dropdown-item" (click)="action.onClick()" *ngFor="let action of actions.slice(1)">
            {{action.label}}
          </li>
        </ul>
      </div>
    </li>
  </ul>
</ng-template>
