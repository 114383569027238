export enum CLIENT_EVENT_TYPES {
  // When talent card is in view.
  IMPRESSION = 'IMPRESSION',

  // When talent card is clicked.
  VIEW = 'VIEW',

  // When talent is marked as "Not a Fit"
  NEGATIVE_FEEDBACK = 'NEGATIVE_FEEDBACK',

  // When a talent previously marked as "Not a Fit" is shortlisted.
  UNSET_NEGATIVE_FEEDBACK = 'UNSET_NEGATIVE_FEEDBACK',

  // When a talent is shortlisted.
  PROFILE_SHORTLIST = 'PROFILE_SHORTLIST',

  // When a talent is shortlisted from talent search or add to job modal.
  MODAL_PROFILE_SHORTLIST = 'MODAL_PROFILE_SHORTLIST',

  // The following event types are also defined by Google (but not
  // currently in scope for development).
  BOOKMARK = 'BOOKMARK',
  UNBOOKMARK = 'UNBOOKMARK',
  MARKED_AS_NOT_SHOW = 'MARKED_AS_NOT_SHOW',
  CONTACT_PHONE = 'CONTACT_PHONE',
  CONTACT_TEXT = 'CONTACT_TEXT',
  CONTACT_EMAIL = 'CONTACT_EMAIL',
  CONTACT_ATS_NOTE = 'CONTACT_ATS_NOTE',
  PROFILE_AVAILABILITY_DETAIL_VIEWED = 'PROFILE_AVAILABILITY_DETAIL_VIEWED',
  PROFILE_EVENT_TYPE_UNSPECIFIED = 'PROFILE_EVENT_TYPE_UNSPECIFIED'
}

interface ProfileEvent {
  type: string;
  recruiter?: string;
  profile?: string;
  profiles: string[];
  jobs?: string[];
}

// export interface ClientEvent {
//   requestId?: string;
//   eventId?: string;
//   createTime: string;
//   eventNotes: string;
//   profileEvent: ProfileEvent;
// }

export interface EventMetadata {
  profile: string;
  eventType: string;
}
