<div class="flex-row email-container">
  <div class="flex-column compose-email-container">
    <h3>Write a personal message</h3>
    <div class="text-editor-container" [formGroup]="emailMessageForm">
      <textarea
        type="text"
        formControlName="message"
        placeholder="your personal message will directly be visible in the preview on the right"
        rows="10">
      </textarea>
      <form-error fieldAlias="Write a personal message field" [control]="emailMessageForm.get('message')"></form-error>
    </div>
  </div>
  <div class="flex-column preview-email-container">
    <h3>Preview e-mail message</h3>
    <div class="preview-email flex-column">
      <div class="message-preview-header flex-column">
        <div class="logo-container">
          <img class="white-logo" src="assets/images/logo-white.svg" alt="randstad logo">
        </div>
        <div class="flex-row row-container">
          <div class="flex-column left-header">
            <div class="text-container flex-column">
              <span class="light-text">
                we are happy to meet you
              </span>
              <span class="dark-text">
                interview appointment
              </span>
            </div>
          </div>
          <!-- For background image only -->
          <div class="flex-column right-header"></div>
        </div>
      </div>
      <div class="flex-column message-preview">
        <div class="message-preview-greeting" ngNonBindable>
          hi {{firstname}},
        </div>
        <div class="message-preview-message">
          {{emailMessageForm.get('message').value}}
        </div>
        <div class="message-preview-buttons flex-row">
          <button class="btn btn-primary">schedule interview</button>
        </div>
      </div>
    </div>
  </div>
</div>
