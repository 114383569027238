import { Component, Input, OnInit } from '@angular/core';
import { Application } from 'src/app/shared/models/external/application.model';
import { TalentProcessService } from 'src/app/shared/services/talent-process/talent-process.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'manual-prescreening-results',
  templateUrl: './manual-prescreening-results.component.html',
  styleUrls: ['./manual-prescreening-results.component.scss']
})
export class ManualPrescreeningResultsComponent implements OnInit {
  @Input() application?: Application;
  statusLabel: string;
  manualScreeningNotes = '';
  skillDisplayName = '';
  manualScreeningTravel = '';
  manualScreeningStartDate = '';
  manualScreeningOpps = '';
  manualScreeningMinPayVal = '';
  manualScreeningMinPayType = '';
  manualScreeningType = '';
  manualScreeningAvailability = '';
  manualScreeningSkills: any[] = [];
  trVariable1: any;

  constructor(
    private _talentProcess: TalentProcessService,
    private _auth: AuthService
  ) { }

  ngOnInit() {
    this._talentProcess.selectedApplication.subscribe((app: Application) => {
      this.application = app;
      if (this.application
        && this.application.randstad_process
        && this.application.randstad_process.prescreening
        && this.application.randstad_process.prescreening.manualScreeningAnswers) {
        this.setVals();
      }
    });
    this.trVariable1 = { 'value': this.manualScreeningAvailability };
  }

  setVals() {
    if (this.application && this.application.randstad_process) {
      const manualScreeningAnswers = this.application.randstad_process.prescreening.manualScreeningAnswers;
      this.manualScreeningAvailability = manualScreeningAnswers.not_available_for;
      if(manualScreeningAnswers && manualScreeningAnswers.minPay) {
        this.manualScreeningMinPayVal = manualScreeningAnswers.minPay.value;
        this.manualScreeningMinPayType = manualScreeningAnswers.minPay.type;
      }
      this.manualScreeningNotes = manualScreeningAnswers.notes;
      this.manualScreeningOpps = manualScreeningAnswers.opportunities;
      this.manualScreeningStartDate = manualScreeningAnswers.startDate;
      this.manualScreeningTravel = manualScreeningAnswers.travel;
      this.manualScreeningType = manualScreeningAnswers.type;
      this.manualScreeningSkills = manualScreeningAnswers.skills;
      this.statusLabel = this._talentProcess.getLabel(this.application, this._auth.user.Source, 'process');
    }
  }

}
