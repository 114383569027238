
import { Applications } from '@allbirds-ui/allbirds-types';
import { Activity } from '../internal/activity.interface';
import { Process } from './process.model';
import { createModel, Identifiable, Mapping, Model } from './model';
import { GrpcToMoment } from './serializers/moment.serializer';
import { Moment } from 'moment';
import { Serial } from './decorators/serial.decorator';
import { Internal } from './decorators/internal.decorator';
import { ITalent } from './talent.interface';
import { Profile } from './profile.model';
import { GoogleProfile } from './google-profile.model';
export import RawApplication = Applications.AllbirdsApplication;

class ApplicationMapping extends Mapping<RawApplication> implements ITalent, Identifiable {
  randstad_: string;
  @Serial(Process) randstad_process: Process;
  @Serial(GrpcToMoment) createTime?: Moment;
  @Serial(GrpcToMoment) updateTime?: Moment;
  @Serial(GrpcToMoment) applicationDate?: Moment;
  @Internal() activities: Activity[] = [];
  @Internal() index: number;
  @Internal() score: number;
  @Internal() likelyAvailable: boolean;
  profile: any;

  isGoogleProfile(): this is GoogleProfile {
    return false;
  }

  isProfile(): this is Profile {
    return false;
  }

  isApplication(): this is Application {
    return true;
  }

  get key(): string {
    return this.randstad_process._id;
  }

  get lob(): string {
    if(this.randstad_process.profileInfo && this.randstad_process.profileInfo.externalSystem) {
      return this.randstad_process.profileInfo.externalSystem;
    }
    return this.randstad_process.candidateFrontOfficeID;
  }

  get profileId(): string {
    return this.profile;
  }
}

export interface Application extends Model<ApplicationMapping> {}

export const Application = createModel<Application>(ApplicationMapping);
