<p class="mt-2" *ngIf="error">
  {{'activity-feed.an_error_occurred' | translate}}
</p>

<div class="activity-feed" *ngIf="!error && showFeed">
  <!-- The vertical line that runs through user avatars. -->
  <div class="line-through"></div>

  <!-- For each step of the process... -->
  <ng-container *ngFor="let step of stepArray; let i = index; let lastStep = last;">
    <div class="container-fluid" *ngIf="step.activities.length">
      <!-- Step title. -->
      <div class="row step-title" [ngClass]="{ 'pt-0': isCurrentStep(step.stepField) }">
        <div class="col-1 p-0">
          <div class="icon-circle">
            <div class="icon">
              <img [src]="step.stepIcon" [alt]="step.stepTitle"  />
            </div>
          </div>
        </div>
        <div class="col-11">
          <vertical-align>
            <h3>{{ step.stepTitle | lowercase }}</h3>
          </vertical-align>
        </div>
      </div> <!-- / .row.step-title -->
    </div> <!-- / .container-fluid -->

    <!-- For each activity of a given step.. -->
    <div class="container-fluid"
         *ngFor="let activity of step.activities; let lastActivity = last">
      <div class="row activity-item">

        <!-- User avatar -->
        <div class="col-1 user-avatar">
          <img *ngIf="activity.author" [src]="activity.author.avatar" [alt]="activity.author.name"  />
          <img *ngIf="!activity.author" class="icon" src="assets/icons/notification.svg"  />
        </div>

        <!-- Activity body -->
        <div class="col-11">
          <div class="activity-item--body"
               [ngClass]="{ 'bottom-item': lastStep && lastActivity }">

            <!-- Activity descriptor. -->
            <activity-feed-action [activity]="activity" [candidateFullName]="candidateFullName"></activity-feed-action>

            <!-- Activity notes. -->
            <span class="activity-item--note" *ngIf="activity.notes">
              <span *ngIf="[
              ActivityFeedTypes.ClientInterviewScheduled,
              ActivityFeedTypes.ClientInterviewRescheduled
              ].includes(activity.type)">
                {{ talentProcess?.randstad_process?.clientInterview?.interviewDate | abDatetime }}
                <br />
              </span>
              <!-- Client submissions are HTML strings so we need to render the HTML in that case. -->
              <span [innerHTML]="activity.notes | safeHtml | snippet: 100" appLimitHtml></span>
            </span>

            <!-- Activity timestamp. -->
            <p class="timestamp" *ngIf="activity.date">{{ activity.date | abDatetime }}</p>

            <div class="modal-launch--container">
              <vertical-align>
                <button class="btn-reset" (click)="detailOnClick(activity)">
                  <img src="assets/icons/chevron.svg" alt="view"  />
                </button>
              </vertical-align>
            </div>
          </div> <!-- / .activity-item-body -->
        </div> <!-- / .col-11 -->
      </div> <!-- / .row.activity-item -->
    </div> <!-- / .container-fluid -->
  </ng-container>
</div> <!-- / .activity-feed -->
