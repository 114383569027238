import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { KEY_TO_ROUTE } from '../../talent-management-listings/talent-management.mappings';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { EditListModalComponent } from '../edit-list-modal/edit-list-modal.component';
import { DeleteListModalComponent } from '../../list-details/delete-list-modal/delete-list-modal.component';
import { Applications } from '@allbirds-ui/allbirds-types';
import { TranslateService } from '@ngx-translate/core';
import ProcessStep = Applications.ProcessStep;
import ProcessStatus = Applications.ProcessStatus;
import { User } from 'src/app/shared/models/external/user.model';
import { UserService } from 'src/app/shared/services/user/user.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import {
  TALENT_DASHBOARD_STATUS_METRICS,
  ShortlistFilterProps,
  TALENT_DASHBOARD_ONBOARDING_METRICS,
  TALENT_DASHBOARD_UNANSWERED_METRICS,
} from "src/app/shared/services/talent-process/process-statuses";

@Component({
  selector: 'recruitment-list-card',
  templateUrl: './recruitment-list-card.component.html',
  styleUrls: ['./recruitment-list-card.component.scss']
})
export class RecruitmentListCardComponent implements OnInit, OnChanges {
  @Input() disabled: boolean;
  @Input() key: ProcessStep | 'unanswered' | 'onboarding';
  @Input() title: string;
  @Input() count: number;
  @Input() icon: any;
  @Input() date: string;
  @Input() cardType: string;
  @Input() listID: string;
  @Input() currentSelectedListId: string;
  @Input() cancelNavigation: boolean;
  @Input() recruitersList: any[];
  @Input() ownerID: string;
  @Input() subStatus: any;
  @Input() showToolTip: boolean;
  @Input() showLabel: boolean;
  @Input() labelMsg: string;

  subStatusMetricsList: any[] = [];

  sharedUsersList: User[] = [];

  allowDelete: boolean;

  bsModalRef: BsModalRef;

  constructor(
    private _router: Router,
    private _modalService: ModalService,
    private translate: TranslateService,
    private _user: UserService,
    private _auth: AuthService
  ) {
  }



  ngOnInit(): void {

    if (this.title) this.title = this.translate.instant(this.title);
    if (this.recruitersList) this.setRecruitersList();
    if (this.key && this.key !== 'unanswered' && this.key !== 'onboarding') {
      const currentKey: ProcessStep = this.key;

      TALENT_DASHBOARD_STATUS_METRICS.get(currentKey).forEach((element: ShortlistFilterProps) => {
        
        //Skip talent-process statuses for CR users (prescreening & recruiter interview)
        if (this._auth.user.Source == 'CR' && (
          (element.status[0] === ProcessStatus.IR_AWAITING_INTERVIEW) ||
          (element.status[0] === ProcessStatus.IH_INTERVIEW_COMPLETED) ||
          (element.status[0] === ProcessStatus.IR_AWAITING_RESPONSE) ||
          (element.status[0] === ProcessStatus.IR_INTERVIEW_SCHEDULED) ||
          (element.status[0] === ProcessStatus.IR_RESCHEDULE_INCONVENIENT_INTERVIEW) ||
          (element.status[0] === ProcessStatus.IR_RESCHEDULE_DECLINED_INTERVIEW) ||
          (element.status[0] === ProcessStatus.IR_INTERVIEW_CANCELLED) ||
          (element.status[0] === ProcessStatus.P_SENT) || 
          (element.status[0] === ProcessStatus.P_IN_PROGRESS)
        )) return

        const metricsInfo: MetricInfo = new MetricInfo();
        metricsInfo.title = element.dashboardText;
        metricsInfo.status = element.status;
        metricsInfo.count = 0;
        metricsInfo.displayWarning = element.displayWarning;
        element.status.forEach((filter: ProcessStatus) => {
          if (this.subStatus[filter]) {
            metricsInfo.count += this.subStatus[filter];
          }
        });
        this.subStatusMetricsList.push(metricsInfo);

      });
    } else if (this.key === 'unanswered') {
        const unansweredMap = TALENT_DASHBOARD_UNANSWERED_METRICS;
        const keys = Array.from(unansweredMap.keys());
        keys.forEach((key) => {
          const {status, dashboardText} = unansweredMap.get(key);
          const metricsInfo: MetricInfo = new MetricInfo();
          metricsInfo.title = dashboardText;
          metricsInfo.status = status;
          metricsInfo.count = this.subStatus[key.toUpperCase()];
          metricsInfo.displayWarning = false;
          this.subStatusMetricsList.push(metricsInfo);
      });
    } else if (this.key === 'onboarding') {
      const onboardingMap = TALENT_DASHBOARD_ONBOARDING_METRICS;
      const keys = Array.from(onboardingMap.keys());
      keys.forEach((key) => {
        const {status, dashboardText} = onboardingMap.get(key);
        const metricsInfo: MetricInfo = new MetricInfo();
        metricsInfo.title = dashboardText;
        metricsInfo.status = status;
        metricsInfo.count = this.subStatus[key.toUpperCase()];
        metricsInfo.displayWarning = false;
        this.subStatusMetricsList.push(metricsInfo);
      });
    }
  }

  isWarning(subStatus: MetricInfo) {
    if (subStatus.displayWarning && subStatus.count > 0) {
      return true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ownerID) {
      this.allowDelete = this.ownerID === this._auth.user.BackOfficeID;
    }
  }

  /**
   * Redirects to the talent "drill-down" page when clicking a card.
   * @param stepKey - key of the step (should match a key of KEY_TO_ROUTE)
   */
  onCardClick(stepKey: ProcessStep | 'unanswered' | 'onboarding'): void {
    this._router.navigate(['talent', KEY_TO_ROUTE[stepKey]], { queryParams: { talent_tab: 'process' } });
  }

  onStatusClick(stepKey: ProcessStep | 'unanswered' | 'onboarding', status: string[]) {
    const statusParam = status.join(',');
    this._router.navigate(['talent', KEY_TO_ROUTE[stepKey]], { queryParams: { talent_tab: 'process' ,  filter: statusParam} });
  }

  onListCardClick(): void {
    if (this.cancelNavigation) {
      return;
    } /*When clicking from add to list modal, prevent the redirect */
    this._router.navigate(['talent/list', this.listID]);
  }

  preventRouterAction(event: MouseEvent): void {
    event.stopPropagation();
  }

  onRecentActivityCardClick() {

    this._router.navigate(['talent/reclist', this.listID],
      { queryParams: { talent_tab: 'history' ,  subtab: 'activities'}});

  }

  openEditListModal(): void {
    const allowNameEdit = this._auth.user.BackOfficeID === this.ownerID;

    const initialState = {
      title: this.title,
      id: this.listID,
      currentSelectedListId: this.currentSelectedListId,
      recruitersList: this.sharedUsersList,
      allowNameEdit
    };

    this.bsModalRef = this._modalService.show(EditListModalComponent, { initialState });
    this.bsModalRef.setClass('modal-sm');
  }

  openDeleteListModal(): void {
    const initialState = {
      id: this.listID,
      listNameAndCount: `‘${this.title} (${this.count})’`,
      currentSelectedListId: this.currentSelectedListId,
      listName: this.title
    };

    this.bsModalRef = this._modalService.show(DeleteListModalComponent, { initialState });
    this.bsModalRef.setClass('modal-sm');
  }

  setRecruitersList(): void {
    let backofficeIdArr: string[] = [];

    this.recruitersList.forEach(user => {
      backofficeIdArr.push(user.back_office_id);
    });

    this._user.getUsersById(backofficeIdArr)
      .then(() => {
        backofficeIdArr.forEach(id => {
          this.sharedUsersList.push(this._user.getFromCache(id));
        });
      })
  }

}


class MetricInfo {
    status: (ProcessStatus | 'APPLICANT' | 'ADDED_BY_ME' | 'ALL' | 'ILABOR' | string)[];
    title: string;
    count: number;
    displayWarning: boolean;

    public MetricInfo() {
      this.status = [];
      this.count = 0;
    }
}
