<div class="modal-header">
  <button type="button" class="btn-reset" aria-label="Close" (click)="closeDialog()">
    <i class="icon-WL_icon_close"></i>
  </button>
</div>

<div class="modal-body">

  <h2 class="mb-4">
    Enter web link(s)
  </h2>

  <div class="row mt-4 no-margin" style="margin-right: 0!important; margin-left: 0!important;">
    <form [formGroup]="urlFormGroup">
        <div *ngFor="let formT of urlForm.controls; let i = index" class="list-group list-group-flush">
          <div [formGroup]="formT" class="form-row">
            <div class="form-group col-7">
              <label>Enter Url</label>
              <input type="url" formControlName="path" class="form-control" [ngClass]="{ 'is-invalid': formT.controls.path.errors && formT.controls.path.touched}" />
              <div *ngIf="formT.controls.path.touched && formT.controls.path.errors" class="invalid-feedback">
                <div *ngIf="formT.controls.path.errors.required">Web link is required</div>
                <div *ngIf="formT.controls.path.errors.pattern">Web link is not valid</div>
                <div *ngIf="formT.controls.path.errors.duplicateValidator">Web link already exists, please check</div>
              </div>
            </div>
            <div class="form-group col-5">
              <label>&nbsp;</label>
              <select formControlName="category" class="custom-select" [ngClass]="{ 'is-invalid': formT.controls.category.errors && (formT.controls.category.touched || formT.controls.path.touched ) }" style="border-radius: 4px;"
                      required>
                <option [ngValue]="''">Select Category</option>
                <option *ngFor="let category of categories" [value]="category.name">
                  {{category.name}}
                </option>
              </select>
              <div *ngIf="formT.controls.category.touched && formT.controls.category.errors" class="invalid-feedback">
                <div *ngIf="formT.controls.category.errors.required">Please select a file type</div>
              </div>
            </div>
            <div class="form-group col" style="margin-top: 5px;">
              <input formControlName="descript" placeholder="Add optional description" class="form-control" maxlength="140" type="text">
            </div>
          </div>
        </div>
    </form>
  </div>

  <div class="row mt-4 pull-left add-new no-margin" (click)="addNew()">
    <div class="col-1 mt-2 no-margin">
      <img
        class="toggle-icon"
        src="assets/icons/plus.svg"
        alt="toggle"/>
    </div>
    <div class="col-6 mt-2 no-margin add-new-text">
      Add another web link
    </div>
  </div>

</div>

<div id="assign-job-footer" class="modal-footer">
  <button class="btn rds--button--primary" (click)="closeDialog()" >cancel</button>
  <button class="btn btn-primary" [disabled]="!validUrls().length" (click)="addLinks(); closeDialog();" (mouseover)="triggerValidation()" >add {{validUrls().length || ''}} web link(s)</button>
</div>
