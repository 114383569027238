
// line of business shortened as LOB. It it also called OPCO or Source in some places
// this function is checks if given variable matches the LOB parameters exist in our system

const lob = Object.freeze( <any> {
  RGS : ['RGSOA', 'RGSML', 'RGS', 'RGSOP'],
  RGSOA : ['RGSOA', 'RGS', 'RGSML', 'RGSOP'],
  RGSML : ['RGSML', 'RGS', 'RGSOA', 'RGSOP'],
  RGSOP: ['RGSOP', 'RGS', 'RGSML', 'RGSOA'],
  RT : ['RT', 'RD'],
  RE : ['RE'],
  RP : ['RP', 'RGS', 'RGSML', 'RGSOA', 'RGSOP'],
  BH: ['RT', 'RE','CR','RD'],
  CR: ['CR'],
});

const lobsAsArray: Array<string> = Object.keys(lob);

interface String {
  checkLob(...args: string[]): boolean;
  abIncludesLob(...args: string[]): boolean;
}
/**
 * This method checks if the string it's called on is included in the arrays
 * associated with any of the provided keys in the `lob` object.
 *
 * @this {string}
 * @param {...string} args - The keys to check against in the `lob` object.
 * @returns {boolean} Returns true if the string is found in any of the
 * associated arrays of the provided keys, otherwise false.
 */
String.prototype.checkLob = function(...args:string[]): boolean {
  //checkLob is typically chained to the lob data field (i.e. `this`) that is a string
  if (args.includes('BH')) {
    // Special handling for 'BH'
    for (let arg of args) {
      if (lob[arg]?.includes(this?.toString())) return true;
    }
    return false;
  } else {
    // Original logic for other cases
    const currentLobArray = lob[this.toString()];
    if (!currentLobArray) return false;
    return args.some(arg => currentLobArray.includes(arg));
  }
};
  

String.prototype.abIncludesLob = function(...args: string[]): boolean {
  let extracted = this;
  if (this.length) {
    if (this.includes('RGSOA')) {
      extracted = 'RGSOA';
    } else if (this.includes('RGSML')) {
      extracted = 'RGSML';
    } else {
      for (const s of lobsAsArray) {
        if (this.includes(s)) {
          extracted = s;
          break;
        }
      }
    }
  }
  return extracted.checkLob(...args);
};


