export const ACTIVITY_TYPES = {
  EMAIL: 'EMAIL',
  PHONE_CALL: 'PHONE_CALL',
  NOTE: 'NOTE'
};

export const INTERVIEW_TYPES = {
  IN_PERSON: 'In-person',
  PHONE: 'Phone',
  VIDEO: 'Video'
};
