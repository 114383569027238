<div class="modal-header">
  <button type="button" class="btn-reset" aria-label="Close" (click)="_bsModalRef.hide()">
    <i class="icon-WL_icon_close"></i>
  </button>
</div>

<div class="modal-body">
	<div class="notafit-container">
		<h2 class="notAFitTitle">{{'not-a-fit-modal.why_is_this' | translate}}</h2>
		<h6 class="subHeading">{{'not-a-fit-modal.tell_us_why' | translate}}</h6>
		<ul class="reasonList">
			<li *ngFor="let reason of reasons; let i = index">
				<input [attr.id]="'checkbox-' + i"
					class="rds--checkbox"
					type="checkbox"
					[(value)]="reasons[i]"
					[checked]="isChecked(reasons[i])"
					(click)="updateSelectedReasons($event)" />
				<label class="rds--checkbox__label" [attr.for]="'checkbox-' + i">
					{{NotAFitReason[reasons[i]]}}
				</label>
			</li>
			<li *ngIf="!hideOtherNoteField">
        <form [formGroup]="hmForm">
          <div class="form-group" class="col-lg-12 col-md-12">
            <label for="hmAvailability">{{'interview-hiring-manager.additional_interview_information' | translate}}</label>
            <app-ckeditor
              id="hmAvailability"
              class="text-box w-100"
              [parentForm]="hmForm"
              formConName="reasonNote"
              height="120"
              [enableMentions]="true">
            </app-ckeditor>
            <div *ngIf="showErrorNote" class="invalid-feedback">{{'interview-hiring-manager.interview_information_is' | translate}}</div>
          </div>
        </form>
			</li>
		</ul>
	</div>
</div>

<div class="modal-footer">
	<ul class="hz-btn-list">
		<li>
			<button type="button" class="btn" aria-label="cancel" (click)="_bsModalRef.hide()">{{'not-a-fit-modal.cancel' | translate}}</button>
		</li>
		<li>
			<button type="button" class="btn rds--button--primary" aria-label="submit" (click)="submit($event)">{{'not-a-fit-modal.submit' | translate}}</button>
		</li>
	</ul>
</div>
