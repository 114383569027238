import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { User } from '../../models/external/user.model';

@Component({
  selector: 'app-user-circle',
  templateUrl: './user-circle.component.html',
  styleUrls: ['./user-circle.component.scss']
})
export class UserCircleComponent implements OnInit, OnChanges {

  @Input() user: User;
  @Input() users: User[];
  @Input() adaptivePosition: boolean;
  @Input() popoverPlacement = 'top';
  @Input() useBodyAsContainer = true;
  @Input() showPopover: boolean;
  initials: string = '';
  usersNumber: number;
  // @Output() onClick: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    if (this.user && this.user.Initials) {
      this.initials = this.user.Initials;
    } else if (this.user) {
      const fullName = `${this.user.FirstName} ${this.user.LastName}`;
      const initialsMatch = fullName.match(/\b\w/g);
      this.initials = ((initialsMatch.shift() || '') + (initialsMatch.pop() || '')).toUpperCase();
    }

    if (this.users && this.users.length) {
      this.usersNumber = this.users.length;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.users) {
      this.usersNumber = changes.users.currentValue.length;
    }
  }

}
