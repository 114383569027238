export const PIPELINE_STATUSES: string[] = [
  'Unfilled',
  'Closed'
]

export const CLIENT_ORDER_STATUSES: string[] = [
  'Unfilled',
  'Active'
]
export interface SourceOption {
  value: string;
  title: string;
}
export interface SourceTypeOption {
  type: string;
  value: string;
  title: string;
}

export const RT_SOURCE: SourceOption[] = [
  {
    value: 'Direct Manager',
    title: 'Direct Manager'
  }, {
    value: 'HR with Manager Contact',
    title: 'HR with Manager Contact'
  }, {
    value: 'HR with No Manager Contact',
    title: 'HR with No Manager Contact'
  }, {
    value: 'System Req Manager Contact',
    title: 'System Req Manager Contact'
  }, {
    value: 'System Req No Manager Contact',
    title: 'System Req No Manager Contact'
  }, {
    value: 'Randstad Revenue Sharing',
    title: 'Randstad Revenue Sharing'
  },
  {
    value: 'Pre-System Requirement',
    title: 'Pre-System Requirement'
  }
];

export const RGS_SOURCE: SourceOption[] = [
  {
    value: 'PI',
    title: 'Production Increase'
  }, {
    value: 'WK',
    title: 'Work Overload'
  }, {
    value: 'TT',
    title: 'Temp To Perm'
  }, {
    value: 'PO',
    title: 'Project'
  }, {
    value: 'PK',
    title: 'Peak Period'
  }, {
    value: 'PL',
    title: 'Placement Call'
  }, {
    value: 'EX',
    title: 'Company Expansion'
  }, {
    value: 'PE',
    title: 'Perm Opening'
  }, {
    value: 'VT',
    title: 'Vacant'
  }, {
    value: 'AT',
    title: 'Attrition'
  }, {
    value: 'R2',
    title: 'Replacement'
  }, {
    value: 'RE',
    title: 'Regular Staff Pattern'
  }, {
    value: 'VA',
    title: 'Vacation Replacement'
  }, {
    value: 'OT',
    title: 'Other'
  }, {
    value: 'NO',
    title: 'No Reason Given'
  }, {
    value: 'CO',
    title: 'Competitor could not come thru'
  }, {
    value: 'ET',
    title: 'Extension'
  }, {
    value: 'TR',
    title: 'Company In Transition'
  }, {
    value: 'R1',
    title: 'Replacement: Other Service'
  }, {
    value: 'LO',
    title: 'Leave of Absence'
  }, {
    value: 'TB',
    title: 'To Be Confirmed'
  }, {
    value: 'IN',
    title: 'Interviewing for Permanent'
  }, {
    value: 'MA',
    title: 'Maternity Leave'
  }, {
    value: 'ML',
    title: 'Medical'
  }, {
    value: 'IL',
    title: 'Sick'
  }, {
    value: 'P2',
    title: 'Personal'
  }, {
    value: 'PR',
    title: 'Payrolling'
  }
];


export const SERVICE_LINES: SourceOption[] = [
  {
    value: 'Cloud',
    title: 'Cloud'
  }, {
    value: 'Customer Experience',
    title: 'Customer Experience'
  }, {
    value: 'Data & Analytics',
    title: 'Data & Analytics'
  }, {
    value: 'Digital & Product Engineering',
    title: 'Digital & Product Engineering'
  }, {
    value: 'RD Platform Services',
    title: 'RD Platform Services'
  }
];

export const SUB_SERVICE_LINES: SourceTypeOption[] = [
  {
    type:'Cloud',
    value: 'Cloud Operations',
    title: 'Cloud Operations'
  }, 
  {
    type:'Cloud',
    value: 'Cloud Migration',
    title: 'Cloud Migration'
  }, 
  {
    type:'Cloud',
    value: 'Cloud Modernization',
    title: 'Cloud Modernization'
  }, 
  {
    type:'Cloud',
    value: 'Cloud Native Development',
    title: 'Cloud Native Development'
  }, 
  {
    type:'Cloud',
    value: 'Cloud Advisory',
    title: 'Cloud Advisory'
  }, 
  {
    type:'Cloud',
    value: 'Cloud General',
    title: 'Cloud General'
  }, 
  {
    type:'Customer Experience',
    value: 'Design Experience (UX / UI)',
    title: 'Design Experience (UX / UI)'
  }, 
  {
    type:'Customer Experience',
    value: 'Omnichannel Experience',
    title: 'Omnichannel Experience'
  }, 
  {
    type:'Customer Experience',
    value: 'Marketing Experience',
    title: 'Marketing Experience'
  }, 
  {
    type:'Customer Experience',
    value: 'Platform Experience',
    title: 'Platform Experience'
  },
  {
    type:'Customer Experience',
    value: 'CX Advisory',
    title: 'CX Advisory'
  },
  {
    type:'Customer Experience',
    value: 'Cust. Experience General',
    title: 'Cust. Experience General'
  },
  {
    type:'Data & Analytics',
    value: 'Data Modernization',
    title: 'Data Modernization'
  }, 
  {
    type:'Data & Analytics',
    value: 'Analytics & AI',
    title: 'Analytics & AI'
  }, 
  {
    type:'Data & Analytics',
    value: 'Generative AI',
    title: 'Generative AI'
  }, 
  {
    type:'Data & Analytics',
    value: 'Automation',
    title: 'Automation'
  }, 
  {
    type:'Data & Analytics',
    value: 'Data Advisory',
    title: 'Data Advisory'
  }, 
  {
    type:'Data & Analytics',
    value: 'Data General',
    title: 'Data General'
  }, 
  {
    type:'Digital & Product Engineering',
    value: 'Embedded Hardware',
    title: 'Embedded Hardware'
  }, 
  {
    type:'Digital & Product Engineering',
    value: 'Embedded Software',
    title: 'Embedded Software'
  }, 
  {
    type:'Digital & Product Engineering',
    value: 'Connected Systems and IoT',
    title: 'Connected Systems and IoT'
  }, 
  {
    type:'Digital & Product Engineering',
    value: 'Application Development',
    title: 'Application Development'
  }, 
  {
    type:'Digital & Product Engineering',
    value: 'Certification and Validation',
    title: 'Certification and Validation'
  }, 
  {
    type:'Digital & Product Engineering',
    value: 'Dig and Prod Advisory',
    title: 'Dig and Prod Advisory'
  },
  {
    type:'Digital & Product Engineering',
    value: 'QEA',
    title: 'QEA'
  },
  {
    type:'Digital & Product Engineering',
    value: 'Digital and Prod Engineering General',
    title: 'Digital and Prod Engineering General'
  },
  {
    type:'RD Platform Services',
    value: 'Adobe',
    title: 'Adobe'
  },
  {
    type:'RD Platform Services',
    value: 'Salesforce',
    title: 'Salesforce'
  },
  {
    type:'RD Platform Services',
    value: 'ServiceNow',
    title: 'ServiceNow'
  },
  {
    type:'RD Platform Services',
    value: 'Workday',
    title: 'Workday'
  },
  {
    type:'RD Platform Services',
    value: 'Platform Svcs - General',
    title: 'Platform Svcs - General'
  }
];