import { Appointments } from "../misc.model";

export import RawAppointmentSchedule = Appointments.AppointmentSchedule;

export import RawAppointmentInvite = Appointments.AppointmentInvite;

export import RawAppointmentFeedback = Appointments.AppointmentFeedback;
import { Mapping, Model, createModel } from "../model";
import { IsoToMoment } from "../serializers/moment.serializer";
import { Serial } from "../decorators/serial.decorator";
import { Moment } from "moment";


/**
 * appointment property (the property name is ACTUALLY appointment and NOT appointmentSchedule or anything like that)
 * @class {AppointmentScheduleMapping}
 */
class AppointmentScheduleMapping extends Mapping<RawAppointmentSchedule> {
  @Serial(IsoToMoment) timeSlotSelected?: Moment;
}

export interface AppointmentSchedule extends Model<AppointmentScheduleMapping>{}
export const AppointmentSchedule = createModel<AppointmentSchedule>(AppointmentScheduleMapping);

