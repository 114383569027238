import { AuthService } from '../auth/auth.service';
import {NotificationData, NotificationTypes} from '../../models/internal/notifications-data.model';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import {JobForm} from "../../models/external/job-form.model";

const SERVER_URL = environment.apiUrl;

// TODO: Write test for service...

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  constructor(
    private _http: HttpClient,
    private _auth: AuthService
  ) {}

  public sendNotification(notificationObject: NotificationData) {
    return this._http.post(`${SERVER_URL}/notification`, notificationObject);
  }

  public getNotificationObject(jobForm: JobForm, jobDataChangesObj: { [key: string]: any }) {
      const emailRecipients =
        jobForm.assigned_to_user === jobForm.published_by_user
          ? [
            {
              recipient: jobForm.published_by_user,
              recipientEmail: jobForm.published_by_user_email
            }
          ].filter(r => r.recipient !== this._auth.user.FullName)
          : [
            {
              recipient: jobForm.assigned_to_user,
              recipientEmail: jobForm.assigned_to_user_email
            },
            {
              recipient: jobForm.published_by_user,
              recipientEmail: jobForm.published_by_user_email
            }
          ].filter(r => r.recipient !== this._auth.user.FullName);

      const notificationObject = {
        notificationType: NotificationTypes.JOB_STATUS_CHANGE,
        notificationObject: {
          front_office_id: jobForm.front_office_id,
          title: jobForm.internalTitle,
          assigned_to_user: jobForm.assigned_to_user,
          assigned_to_user_email: jobForm.assigned_to_user_email,
          published_by_user: jobForm.published_by_user,
          published_by_user_email: jobForm.published_by_user_email,
          customer_name: jobForm.customer_name,
          allbirds_job_id: jobForm.allbirds_job_id,
          executing_action_user: this._auth.user.FullName,
          executing_action_user_email: this._auth.user.EmailAddr,
          getAssociatedRecruiters: true,
          order_status: jobForm.order_status,
          user: {...this._auth.user},
          emailRecipients,
          job_data_changes: {
            ...jobDataChangesObj
          },
          vms_req_id: jobForm.vms_req_id,
          hiringManager: jobForm.hiringManager
        }
      };

      return notificationObject;
    }

    public getNotificationCRObject(jobForm: JobForm) {
      const emailRecipients =[{recipient: "Cella Posting Team", recipientEmail: "Cella_PostingTeam-DL@cellainc.com"}]
      const notificationObject = {
        notificationType: NotificationTypes.CR_JOB_CREATION,
        notificationObject: {
          front_office_id: jobForm.front_office_id,
          title: jobForm.internalTitle,
          assigned_to_user: jobForm.assigned_to_user,
          assigned_to_user_email: jobForm.assigned_to_user_email,
          published_by_user: jobForm.published_by_user,
          published_by_user_email: jobForm.published_by_user_email,
          customer_name: jobForm.customer_name,
          allbirds_job_id: jobForm.allbirds_job_id,
          executing_action_user: this._auth.user.FullName,
          executing_action_user_email: this._auth.user.EmailAddr,
          getAssociatedRecruiters: true,
          order_status: jobForm.order_status,
          user: {...this._auth.user},
          emailRecipients,
          vms_req_id: jobForm.vms_req_id,
          hiringManager: jobForm.hiringManager
        }
      };

      return notificationObject;
    }
}
