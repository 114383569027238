import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-file-upload-modal',
  templateUrl: './file-upload-modal.component.html',
  styleUrls: ['./file-upload-modal.component.scss']
})
export class FileUploadModalComponent implements OnInit {

  constructor(
    public _bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
  }

  close() { this._bsModalRef.hide(); }

}
