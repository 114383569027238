import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subject, Subscription } from 'rxjs';
import { UtilityService } from '../utility/utility.service';
import { ObjectUtilitiesService } from '../utility/object-utilities.service';
import { EntityChanges } from '@allbirds-ui/allbirds-types';

/**
 * To utilize this class, you must create a service as per usual and extend it.
 * In your constructor, you must call super with two parameters:
 *  (1) The collection name where changes are stored in FS.
 *  (2) The service that extends this class must inject the AngularFirestore service
 *      without using an access modifier.
 */
@Injectable()
export abstract class EntityChangeService<T> implements OnDestroy {

  // Stores the currently listened-to docId so that if listenForChanges
  // is called on the same docId, we don't destroy the existing listeners.
  private docId: string;

  // Subscriber for FS updates.
  private changeSubscription: Subscription;

  // Map of changed entities.
  public changedEntities: EntityChanges.ChangedEntityMap<T> = new Map();

  // Emits the changedEntities map every time a document change in FS is detected.
  private changeSubject = new Subject<EntityChanges.ChangedEntityMap<T>>();
  public onChanges = this.changeSubject.asObservable();

  protected constructor(
    private collectionName: string,
    private _fs: AngularFirestore
  ) { }

  ngOnDestroy(): void {
    UtilityService.destroySubscription(this.changeSubscription);
  }

  listenForChanges(id: string, captureInitialChanges: boolean = false) {
    if (id !== this.docId) {
      // Reset the map every time this is called.
      if (this.changedEntities) {
        this.changedEntities.clear();
      }
      // Destroy the subscription if one exists.
      if (this.changeSubscription) {
        UtilityService.destroySubscription(this.changeSubscription);
      }
      // Listen for changes on the FS document.
      this.changeSubscription = this._fs
        .doc<EntityChanges.EntityChangeDocument<T>>(`${this.collectionName}/${id}`)
        .valueChanges()
        .subscribe((res: EntityChanges.EntityChangeDocument<T>) => {
          if (!captureInitialChanges) {
            captureInitialChanges = true;
            return;
          }
          for (const e of res?.changedEntities) {
            this.setEntityChange(e.id, e);
          }
          this.emitChanges();
        }, (err) => {
          console.error('EntityChangeService failed to updated changed entities.', err);
        });
    }
    this.docId = id;
  }

  /**
   * Given the unique identifier used to store entity changes, returns the changed entity data if one exists.
   * @param id whatever unique identifier is used when storing the change in FS.
   */
  getEntityChange(id: string) {
    return this.changedEntities.get(id);
  }

  /**
   * Sets values on the changedEntities map.
   * @param id
   * @param changedData
   */
  setEntityChange(id: string, changedData: EntityChanges.ChangedEntity<T>) {
    return this.changedEntities.set(id, changedData);
  }

  /**
   * Returns true if the data field of the changed entity has different values
   * for the same properties on the passed-in entity.
   * @param id whatever unique identifier is used when storing the change in FS.
   * @param entity the in-memory entity you want to compare the FS entity with
   */
  hasEntityChanged(id: string, entity: T): boolean {
    const changedEntity = this.getEntityChange(id);
    if (changedEntity) {
      return !ObjectUtilitiesService.checkDeepEqual(changedEntity.data, entity);
    }
    return false;
  }

  emitChanges(): void {
    this.changeSubject.next(this.changedEntities);
  }
}
