<div class="appt-settings-container">
  <form [formGroup]="apptSettingsForm" class="form-container">
    <div class="flex-column">
      <h3>Appointment goal</h3>
      <div class="flex-row">
        <input
          formControlName="apptGoal"
          value="TALENT_CONTACT"
          id="goal-button-1"
          class="rds--radio-button"
          type="radio">
        <label
          for="goal-button-1"
          class="rds--radio-button__label">
          <span class="rds--radio-button__appearance"></span>
          <span>Talent connect appointment</span>
        </label>
      </div>
      <div class="flex-row">
        <input
          formControlName="apptGoal"
          value="RECRUITER_INTERVIEW"
          id="goal-button-2"
          class="rds--radio-button"
          type="radio">
        <label
          for="goal-button-2"
          class="rds--radio-button__label">
          <span class="rds--radio-button__appearance"></span>
          <span>Recruiter interview</span>
        </label>
      </div>
    </div>
    <div class="flex-column apptTypes">
      <h3>Appointment type</h3>
      <div class="flex-row apptTypesContainer" formArrayName="apptType">
        <div *ngFor="let apptType of apptTypeValues; let i = index" class="flex-row">
          <input
            type="checkbox"
            class="rds--checkbox"
            [id]="'checkbox-' + i"
            [formControlName]="i">
          <label
            [for]="'checkbox-' + i"
            class="rds--checkbox__label">
            {{apptType.label}}
          </label>
        </div>
      </div>
      <form-error fieldAlias="Appointment type" 
      [control]="apptSettingsForm.controls.apptType"></form-error>
    </div>
    <div *ngIf="showAddress" class="flex-column address-container">
      <span class="label">In person interview address</span>
      <input
        class="form-control input"
        formControlName="apptAddress"
        type="text">
      <form-error fieldAlias="In person interview address field" 
        [control]="apptSettingsForm.controls.apptAddress"></form-error>
    </div>
    <div class="flex-column">
      <h3>Interview duration</h3>
      <div class="flex-row">
        <input
          formControlName="interviewDuration"
          value="15"
          id="duration-button-1"
          class="rds--radio-button"
          type="radio">
        <label
          for="duration-button-1"
          class="rds--radio-button__label">
          <span class="rds--radio-button__appearance"></span>
          <span>15 minutes</span>
        </label>
      </div>
      <div class="flex-row">
        <input
          formControlName="interviewDuration"
          value="30"
          id="duration-button-2"
          class="rds--radio-button"
          type="radio">
        <label
          for="duration-button-2"
          class="rds--radio-button__label">
          <span class="rds--radio-button__appearance"></span>
          <span>30 minutes</span>
        </label>
      </div>
      <div class="flex-row">
        <input
          formControlName="interviewDuration"
          value="45"
          id="duration-button-3"
          class="rds--radio-button"
          type="radio">
        <label
          for="duration-button-3"
          class="rds--radio-button__label">
          <span class="rds--radio-button__appearance"></span>
          <span>45 minutes</span>
        </label>
      </div>
      <div class="flex-row">
        <input
          formControlName="interviewDuration"
          value="60"
          id="duration-button-4"
          class="rds--radio-button"
          type="radio">
        <label
          for="duration-button-4"
          class="rds--radio-button__label">
          <span class="rds--radio-button__appearance"></span>
          <span>60 minutes</span>
        </label>
      </div>
    </div>
  </form>
</div>
