import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

interface AccessibleLink {
  internal?: string;
  external?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ResolveService {

  constructor() { }

  /**
   * Returns a promise that resolves to true if the URL successfully resolves
   * or false if a network failure or something prevented the request from completing.
   * @param url - absolute URL of the resource to check.
   */
  static checkIfResolves (url: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if ('fetch' in window) {
        /**
         * Native Fetch API is used because of its unique differences from Angular's HTTP client.
         * See {@link https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API#Differences_from_AJAX}
         */
        fetch(url, { credentials: 'omit', mode: 'no-cors' })
          .then(() => {
            resolve(true);
          })
          .catch(() => {
            resolve(false);
          });
      } else {
        console.log('Fetch API not found. Defaulting to false.');
        resolve(false);
      }
    });
  }

  /**
   * Utility function for launching RFO. Will use the front office URL as
   * defined in the environment.[env].ts file. If an internal & external
   * link exists for the environment (currently only prod.), will attempt
   * to resolve the internal url before using the external.
   * @param suffix - everything after [BASE_RFO_URL]/psp/FO[ENV]/EMPLOYEE/RFO/c
   */
  static async goToRfo (suffix?: string): Promise<Window> {
    try {
      const frontOfficeUrl: AccessibleLink = environment.frontOfficeUrl;
      let destination = '';
      if (frontOfficeUrl.internal && frontOfficeUrl.external) {
        const didResolve = await ResolveService.checkIfResolves(frontOfficeUrl.internal);
        destination = didResolve ? frontOfficeUrl.internal : frontOfficeUrl.external;
      } else {
        destination = frontOfficeUrl.internal;
      }
      return window.open(`${destination}${suffix}`, '_blank');
    } catch (e) {
      console.error('[goToRfo] error:', e);
      return null;
    }
  }
}
