import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { CustomListService } from '../custom-list.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoadingSpinnerService } from 'src/app/shared/services/loading-spinner/loading-spinner.service';
import { ApiService } from '../../../../../shared/services/api/api.service';

@Component({
  selector: 'delete-list-modal',
  templateUrl: './delete-list-modal.component.html',
  styleUrls: ['./delete-list-modal.component.scss']
})
export class DeleteListModalComponent implements OnInit {
  id: string;
  shouldRedirect = false;
  listName: string;
  listNameAndCount: string;
  currentSelectedListId: string;
  deleteSubs: Subscription;
  trVariable1: any;

  constructor(
    private _api: ApiService,
    private _bsModalRef: BsModalRef,
    private _listService: CustomListService,
    private _router: Router,
    private modalService: ModalService,
    private _loading: LoadingSpinnerService) { }

  ngOnInit() {
    this.trVariable1 = {'value': this.listNameAndCount };
  }

  closeDialog(): void {
    this._bsModalRef.hide();
  }

  deleteList(): void {
    this._loading.show();
    this.closeDialog();

    this.deleteSubs = this._api.deleteList(this.id, this.listName).subscribe(data => {
      this._loading.hide();
      this._listService.setCurrentListOfLists();
      this._listService.setCurrentSharedListOfLists();
      if (this.shouldRedirect || (this.currentSelectedListId === this.id)) {
        this.closeAllModals();
        this._router.navigate(['/talent/dashboard']);
      }

      this.deleteSubs.unsubscribe();
    }, err => {
      console.log(err);
    });
  }

  private closeAllModals() {
    for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
      this.modalService.hide(i);
    }
  }
}
