import { TalentService } from 'src/app/shared/services/talent/talent.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from '../../../services/auth/auth.service';
import { FormBuilder } from '@angular/forms';
import { FileItem, FileLikeObject, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { ApiService } from '../../../services/api/api.service';
import moment from 'moment';
import { LoadingSpinnerService } from 'src/app/shared/services/loading-spinner/loading-spinner.service';
import { ToastService } from 'src/app/shared/services/toast/toast.service';

@Component({
  selector: 'app-add-files-modal',
  templateUrl: './add-files-modal.component.html',
  styleUrls: ['./add-files-modal.component.scss']
})
export class AddFilesModalComponent implements OnInit {

  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  attachments: any; // the attachments that are on job/entity already
  additionalParameters: any; // additional parameters to send to backend with uploader, will show up under body
  categories: any;
  outputAttachments: any = []; // output will be generated result of this component
  allowedMimeTypes: string[];
  unsupportedMessage: string;
  unsupportedItems: string[] = [];
  duplicateMessage: string;
  duplicateItems: string[] = [];
  lastErrorTime: any; // using this time to detect and reset errors because could not find any other way or event
  allowDuplicate: any;
  save: any;
  isErrored = false;
  uploadURL: string;
  parseResume: boolean;
  dropdownSelectedValue: any;
  isTalentCreation = false;
  isTalentEdit = false;
  talentCreationError = false;
  failedItem: string = '';
  profileEmail: string = '';
  optionalDescrip: string = '';
  // these are needed when component is loaded as part of the submit-to-am modal
  @Input() initialState: any; // passing an initial state this way indicates component is being embedded in another modal
  @Output() addToUploadedAttachments = new EventEmitter();
  @Output() removeUploadedAttachment = new EventEmitter();

  constructor(
    public _bsModalRef: BsModalRef, 
    private _auth: AuthService, 
    private formBuilder: FormBuilder,
    public _api: ApiService, 
    private _talent: TalentService, 
    private _loading: LoadingSpinnerService,
    private _toast: ToastService
  ) { }

  get isValid() { return this.validFiles().length === this.outputAttachments.length };

  ngOnInit() {
    if (this.initialState) {
      this.save = this.initialState?.save;
      this.categories = this.initialState?.categories;
      this.attachments = this.initialState?.attachments;
      this.additionalParameters = this.initialState?.additionalParameters;
      this.allowDuplicate = this.initialState?.allowDuplicate;
      this.uploadURL = this.initialState?.uploadURL;
      this.parseResume = this.initialState?.parseResume;
      this.isTalentCreation = this.initialState?.isTalentCreation;
    }
     const options: any = {
      url: this.uploadURL,
      additionalParameter: this.additionalParameters,
      authToken: localStorage.getItem('access_token')

    };

    if (!this.allowDuplicate) {
      options['filters'] = [{
        name: 'duplicate',
        fn: (item?: FileLikeObject): boolean => {
          const currentAttachments = [...this.outputAttachments, ...this.attachments];
          return !currentAttachments.some(attachment => attachment.name === item.name);
        }
      }];
    }

    if (this.allowedMimeTypes && this.allowedMimeTypes.length) {
      options['allowedMimeType'] = this.allowedMimeTypes;
    }
    this.uploader = new FileUploader(options);

    this.uploader.onAfterAddingFile = (file) => {
      this.dropped(file);
      this.resetError();
    };

    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
    };

    this.uploader.onWhenAddingFileFailed = (item, filter) => {
      this.resetError();
      this.isErrored = true;
      if (filter.name === 'duplicate') {
        this.duplicateItems.push(item.name);
        this.duplicateMessage = this.buildErrorMessage('', this.duplicateItems) + ' already exists, please check';
      } else if (filter.name === 'mimeType') {
        this.unsupportedItems.push(item.name);
        this.unsupportedMessage = this.buildErrorMessage('Unsupported file format for ', this.unsupportedItems);
        if (this.isTalentCreation) {
          this.talentCreationError = true;
          this.failedItem = item.name;
        }
      }
      this.lastErrorTime = moment();
    };

    this.hasBaseDropZoneOver = false;

    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCancelItem = (item, response, status, headers) => this.onCancelItem(item, response, status, headers);

  }

  closeDialog(): void {
    this._bsModalRef.hide();
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  dropped(attachment: any) {
    this.outputAttachments.push({ name: attachment.file.name, status: 'uploading' });
    if (this.isTalentCreation) {
      // this._loading.show();
      attachment.options.additionalParameter = { profileEmail: this.profileEmail };
    }
    attachment.upload();
  }

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    const data = JSON.parse(response);
    console.log(data)
    const fileIndex = this.attachmentIndex(item.file);

    this.outputAttachments[fileIndex]['path'] = this.isTalentCreation ? data.link.path : data.path;
    this.outputAttachments[fileIndex]['status'] = 'uploaded';
    this.outputAttachments[fileIndex]['parsedData'] = data;

    if (this.initialState) {
      this.outputAttachments[fileIndex]['willSubmit'] = true;
      this.outputAttachments[fileIndex]['willSaveToProfile'] = false;
      this.addToUploadedAttachments.emit(this.outputAttachments[fileIndex]);
    }
 
    // console.log(this.outputAttachments);
    if (this.isTalentCreation) {
      this._toast.showToastNoTranslation("Talent’s resume was successfully parsed. Please review the talent information.");
      // this.sendParsedResume(data, item.file.name);
    }
  }

  onCancelItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) {
    const fileIndex = this.attachmentIndex(item.file);
    this.outputAttachments[fileIndex]['status'] = 'canceled';
  }

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    if (this.isTalentCreation) {
      this._loading.hide();
      const errorMessage = JSON.parse(response);
      // NOTE: PLEASE DON'T REMOVE THE LOGIC BELOW DURING MERGE
      if (errorMessage.parseErr) {
        this._toast.showToastNoTranslation("We are unable to parse the talent’s resume. Please complete the form manually.");
        this._talent.setParsedTalentResume('', true);
      }
      this._bsModalRef.hide();
    }
    const fileIndex = this.attachmentIndex(item.file);
    this.outputAttachments[fileIndex]['status'] = 'failed';
  }

  onRemoveItem(item: FileItem): any {
    const fileIndex = this.attachmentIndex(item.file);
    this.outputAttachments.splice(fileIndex, 1);
    this.removeUploadedAttachment.emit(fileIndex);
  }

  buildErrorMessage(messageStart: string, errorArray: any) {
    let errorMessage = messageStart;
    for (let i = 0; i < errorArray.length; i++) {
      errorMessage += `"${errorArray[i]}" `;
      if (i + 1 !== errorArray.length) {
        errorMessage += ' and ';
      }
    }
    return errorMessage;
  }

  attachmentIndex(item: any) {
    return this.outputAttachments.findIndex((attachment: any) => attachment.name === item.name);
  }

  resetError() {
    const currentTime = moment();
    if (this.lastErrorTime) {
      const timeDiffInHours = currentTime.diff(this.lastErrorTime, 's');
      if (timeDiffInHours > 1) {
        this.isErrored = false;
        this.duplicateMessage = '';
        this.duplicateItems = [];
        this.unsupportedMessage = '';
        this.unsupportedItems = [];
      }
    }
    this.talentCreationError = false;
    this.failedItem = '';
  }

  categorySelectChange(item: FileItem, value: string) {
    const fileIndex = this.attachmentIndex(item.file);
    this.outputAttachments[fileIndex]['category'] = this.isSelectedCategoryNull(value) ? null : value;
  }

  isSelectedCategoryNull(value: any) {
    return !this.categories.some((category: any) => category.name === value);
  }

  validFiles() {
    return this.outputAttachments.filter((attachment: any) => {
      return attachment.path && attachment.category
    });
  }

  addFiles() {
    let type = 'file';
    if (this.isTalentCreation) type = 'resume'
    if (this.additionalParameters && this.additionalParameters.talentDetailsPane) {
      this.save(this.outputAttachments, this.isTalentCreation ? 'resume' : 'file');
    } else {
      console.log(this.validFiles())
      this.save.emit({ attachments: this.validFiles(), type });
    }
  }

  sendParsedResume(data: any, fileName: string) {
    if (data && this.parseResume) {
      data['fileName'] = fileName;
      this._talent.setParsedTalentResume(data);
    }
  }

  addOptionalDescrip(item: any, descript: any) {
    const fileIndex = this.attachmentIndex(item.file);
    this.outputAttachments[fileIndex]['descript'] = descript;
  }

  // these methods were added for use with submit-to-am modal per ticket DF044-6446 @Tyrell

  /**
   * Toggles 'willSubmit' property of item clicked 
   * @param item - file that has been clicked
   */
  handleWillSubmit(item: FileItem): void {
    const fileIndex = this.attachmentIndex(item.file);
    this.outputAttachments[fileIndex]['willSubmit'] = !this.outputAttachments[fileIndex]['willSubmit'];
  }

  /**
   * Checks if 'willSubmit' property of item clicked is true or false. 
   * Used to determine if checkbox is checked. 
   * @param item - file that has been clicked
   */
  isSubmitToAMChecked(item: FileItem): boolean {
    const fileIndex = this.attachmentIndex(item.file);
    return this.outputAttachments[fileIndex]['willSubmit'];
  }

  /**
   * Toggles 'willSaveToProfile' property of item clicked 
   * @param item - file that has been clicked
   */
  handleWillSaveToProfile(item: FileItem): void {
    const fileIndex = this.attachmentIndex(item.file);
    this.outputAttachments[fileIndex]['willSaveToProfile'] = !this.outputAttachments[fileIndex]['willSaveToProfile'];
  }

  /**
   * Checks if 'willSaveToProfile' property of item clicked is true or false. 
   * Used to determine if checkbox is checked. 
   * @param item - file that has been clicked
   */
  isSaveToProfileChecked(item: FileItem): boolean {
    const fileIndex = this.attachmentIndex(item.file);
    return this.outputAttachments[fileIndex]['willSaveToProfile'];
  }
}
