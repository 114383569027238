import { Applications } from '@allbirds-ui/allbirds-types';
import { createModel, Mapping, Model } from '../model';
import { IsoToMoment } from '../serializers/moment.serializer';
import { Moment } from 'moment';
import { Serial } from '../decorators/serial.decorator';
export import RawInterview = Applications.Interview;

class InterviewMapping extends Mapping<RawInterview> {
  @Serial(IsoToMoment) completionDate?: Moment;
  @Serial(IsoToMoment) initiatedDate?: Moment;
  @Serial(IsoToMoment) skippedDate?: Moment;
  // startDate is a plain-text field.
  // @Serial(IsoToMoment) startDate?: Moment;
}

export interface Interview extends Model<InterviewMapping> {}

export const Interview = createModel<Interview>(InterviewMapping);
