import { Component, OnInit, ViewChild, ElementRef, Renderer2, HostListener, Input, AfterViewInit, OnDestroy, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-split-panel',
  templateUrl: './split-panel.component.html',
  styleUrls: ['./split-panel.component.scss']
})
export class SplitPanelComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('leftPanel', {static: true}) leftPanelElement: ElementRef;
  @ViewChild('rightPanel', {static: true}) rightPanelElement: ElementRef;
  @ViewChild('separatorBtn', {static: true}) separatorElement: ElementRef;
  @Input() minRight = 0;
  @Input() minLeft = 0;
  @Input() isTabSelected: boolean;

  documentMouseMoveFunc: Function;
  documentMouseUpFunc: Function;
  separatorMouseDownFunc: Function;
  documentSelectStartFunc: Function;

  stacked = false;
  dragging = false;
  mouseDownInfo: any;
  isNarrowView: boolean;

  constructor(
    private renderer: Renderer2,
  ) { }

  ngOnInit() {
    this.scaleSplitPanels(true);
    this.checkNarrowView();
  }

  ngAfterViewInit(){
    this.createSeparatorListeners();
  }

  // if this split-panel view is part of a tabset, check the isTabSelected input and if true,
  // run the scaleSplitPanels method after DOM render
  ngOnChanges(changes: SimpleChanges){
    const { isTabSelected } = changes;
    if(isTabSelected && isTabSelected.currentValue){
      setTimeout(() => this.scaleSplitPanels());
    }
  }

  ngOnDestroy() {
    this.destroySeparatorListeners();
  }

  @HostListener('window:resize')
  onResizeEvent(){
    this.scaleSplitPanels();
    this.checkNarrowView();
  }

  get totalX(){
    return document.getElementById('panelContainer').offsetWidth - 2;
  }

  createSeparatorListeners(){
    this.separatorMouseDownFunc = this.renderer.listen(this.separatorElement.nativeElement, 'mousedown', (e: any) => {
      // create additional listeners only for left mouse click
      if(e.button !== 0) return;
      e.preventDefault();
      this.dragging = true;
      this.mouseDownInfo = {
        e: e,
        leftWidth: this.leftPanelElement.nativeElement.offsetWidth,
        rightWidth: this.rightPanelElement.nativeElement.offsetWidth
      };
      this.documentMouseMoveFunc = this.renderer.listen('document', 'mousemove', (e: MouseEvent) => {
        this.calculatePanelWidths(e);
        this.checkNarrowView();
      });
      this.documentSelectStartFunc = this.renderer.listen('document', 'selectstart', (e: Event) => e.preventDefault());
      this.documentMouseUpFunc = this.renderer.listen('document', 'mouseup', (e: MouseEvent) => {
        this.dragging = false;
        e.preventDefault();
        this.documentMouseMoveFunc();
        this.documentSelectStartFunc();
        this.documentMouseUpFunc();
      });
    });
  }

  calculatePanelWidths(e: MouseEvent){
    e.preventDefault();
    let deltaX = e.clientX - this.mouseDownInfo.e.x;
    const leftMax =  this.totalX - this.minRight;
    deltaX = Math.min(Math.max(deltaX, this.minLeft - this.mouseDownInfo.leftWidth), leftMax - this.mouseDownInfo.leftWidth);
    const currLeftWidth = this.mouseDownInfo.leftWidth + deltaX;

    this.leftPanelElement.nativeElement.style.width = currLeftWidth + 'px';
    this.rightPanelElement.nativeElement.style.width = this.totalX - currLeftWidth + 'px';
  }

  destroySeparatorListeners(){
      //call listeners with no params to cancel
      if(this.separatorMouseDownFunc) this.separatorMouseDownFunc();
      if(this.documentMouseMoveFunc) this.documentMouseMoveFunc();
      if(this.documentMouseUpFunc) this.documentMouseUpFunc();
      if(this.documentSelectStartFunc) this.documentSelectStartFunc();
  }

  //scales split panes when switching to different window size or when panes are resized with devtools open then closed
  scaleSplitPanels(init?: boolean): void {
    const prevStacked = init || this.stacked;
    this.checkMinTotal();
    if(this.stacked) return;
    const left = this.leftPanelElement.nativeElement.offsetWidth;
    const right = this.rightPanelElement.nativeElement.offsetWidth;
    let leftPercentage = left / (left + right);

    //if panes were stacked prior to resize, use min-widths to calculate default proportions then scale
    if(prevStacked) leftPercentage = this.minLeft / (this.minLeft + this.minRight);

    let leftWidth = leftPercentage * this.totalX;

    //if either of the panes are below their min-width, set that pane to the min-width
    if(leftWidth < this.minLeft) leftWidth = this.minLeft;
    else if(this.totalX - leftWidth < this.minRight) leftWidth = this.totalX - this.minRight;

    this.leftPanelElement.nativeElement.style.width = leftWidth + 'px';
    this.rightPanelElement.nativeElement.style.width = this.totalX - leftWidth + 'px';
  }

  checkMinTotal(){
    this.stacked = this.minLeft + this.minRight > this.totalX ? true : false;
  }

  //applicable for jobdetails component, use to apply class that mimics bootstrap container media queries
  checkNarrowView(){
    this.isNarrowView = this.rightPanelElement.nativeElement.offsetWidth <= 992 ? true : false;
  }
}
