import { ToastConfig } from '../../services/toast';
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  OnDestroy,
  Renderer2
} from '@angular/core';
import {BookmarkService} from '../../services/bookmark/bookmark.service';
import {Bookmark} from '../../models/internal/bookmark';
import { Router} from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {ModalService} from '../../services/modal/modal.service';
import {ToastClass, ToastService} from '../../services/toast';
import {RemoveAllModalComponent} from './remove-all-modal/remove-all-modal.component';
import {Subscription} from 'rxjs';
import {ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';
import {LoadingSpinnerService} from '../../services/loading-spinner/loading-spinner.service';

@Component({
  selector: 'app-bookmark',
  templateUrl: './bookmark.component.html',
  styleUrls: ['./bookmark.component.scss']
})
export class BookmarkComponent implements OnInit, OnDestroy {
  @Input() type: string;
  @Input() job: any = null;
  @Input() talent: any = null;
  isOpen = false;
  isFullScreen = false;
  subscriptionFS: Subscription;
  bookmarkList: Bookmark[];
  current_url: string;
  bsModalRef: BsModalRef;
  @ViewChild(ContextMenuComponent, {}) public basicMenu: ContextMenuComponent;
  sub: Subscription;

  constructor( public _bookmark: BookmarkService,
               private _router: Router,
               private _contextMenu: ContextMenuService,
               public loading: LoadingSpinnerService,
               private _modal: ModalService,
               private _toast: ToastService,
               private _renderer2: Renderer2) {
    // made this quick to click outside the small bookmark window to close it
    this._renderer2.listen('window','click', (event: Event) => {
      const bookmarkModal = document.getElementById('smallWindow');
      const bookmarkIcon = document.getElementById('bookmarkIcon');
      if (bookmarkModal && bookmarkModal.children && bookmarkModal.children[0]) {
        if (!(bookmarkModal.children[0].contains((event.target as any)) || bookmarkModal === (event.target as any)) && (event.target as any) !== bookmarkIcon.firstElementChild) {
          this.close();
        }
      }
    });
  }

  ngOnInit() {
    this.current_url = this._bookmark.getUrl();

    this.subscriptionFS = this._bookmark.bookmarkData.subscribe( (bookmarks: Bookmark[]) => {
      this.bookmarkList = bookmarks;
    });

  }

  ngOnDestroy() {
    this.subscriptionFS.unsubscribe();
  }

  switchOpen (): void {
    this.isOpen = !this.isOpen;
    this.isFullScreen = false;
  }

  open () {
    if (this.isOpen) {
      this.close();
    } else {
      this.isOpen = true;
      this.isFullScreen = false;
    }
  }

  close (event: any = null, element: any = null) {
    if (event) {
      const { x, y } = event;
      const divRect = element.getBoundingClientRect();
      if (x >= divRect.left && event.x <= divRect.right &&
        y >= divRect.top && y <= divRect.bottom) {
        return;
      }
    }
    this._contextMenu.closeAllContextMenus({eventType: 'cancel'});
    this.isOpen = false;
    this.isFullScreen = false;
  }

  makeFullScreen (): void {
    this.isFullScreen = true;
  }

  async addBookmark () {
    this.loading.show();
    const url = this._bookmark.getUrl();
    const bookmark = new Bookmark(null, url, new Date().toUTCString(), new Date().toUTCString(), null, this.job, this.talent, null);
    if(bookmark.talent?.candidateFullName === undefined){
      bookmark.talent = null
    }
    // this.getSnapshot();
    this._bookmark.addBookmark(bookmark).then( message => {
      this.loading.hide();
      const toastConfig: ToastConfig = {
        cssClass: ToastClass.SUCCESS,
        image: bookmark.image_url
      };
      this._toast.showToast(`Added to your Bookmarks.`, toastConfig);
    });
  }

  deleteBookmark () {
    const index = this.bookMarkIndex();
    const bookmark = this.bookmarkList[index];
    this.delete(bookmark);
  }

  delete (bookmark: any) {
    this._bookmark.deleteBookmark(bookmark).then( message => {
      const toastConfig = {
        cssClass: ToastClass.SUCCESS,
        actionLinks: [
          {
            text: 'Undo', action: () => {
              this._bookmark.addBookmark(bookmark).then(message => {
                const toastConfig = {
                  cssClass: ToastClass.SUCCESS,
                };
                this._toast.showToast(`Added to your Bookmarks.`, toastConfig);
              });
            }
          },
        ]
      };
      this._toast.showToast(`Bookmark removed.`, toastConfig);
    }, err => {
      console.log(err);
    });
  }

  deleteAllBookmarks () {
    const promiseList = [];
    for (const bookmark of this.bookmarkList) {
      promiseList.push(this._bookmark.deleteBookmark(bookmark));
    }
    Promise.all(promiseList).then((values) => {
      console.log(values);
    });
  }

  bookMarkIndex () {
    return this._bookmark.getBookmarkIndex();
  }

  bookmarkExist () {
    // add 1 to avoid 0 index position returning false
    return this._bookmark.getBookmarkIndex() + 1;
  }

  openDeleteAllModal() {
    const initialState: any = {
      deleteAllBookmarks: this.deleteAllBookmarks.bind(this),
    };
    this.bsModalRef = this._modal.show(RemoveAllModalComponent, { initialState});
    this.bsModalRef.setClass('modal-sm');
  }

  redirectFromMenu(bookmark: any) {
    this._bookmark.updateLastVisitBookmark(bookmark);
    window.open(bookmark.bookmark_url);
  }

}
