import { Subscription } from 'rxjs';
import { ApiService } from './../../services/api/api.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Job } from '../../models/external/job.model';
import { User } from '../../models/external/user.model';
import { UserService } from '../../services/user/user.service';
import { AuthService } from '../../services/auth/auth.service';

export interface Roles {
  publishedByUser: any;
  assignedToUser: any;
  collabUsers: any[];
  interviewerUsers: any[];
}

@Component({
  selector: 'app-manage-roles',
  templateUrl: './manage-roles.component.html',
  styleUrls: ['./manage-roles.component.scss']
})
export class ManageRolesComponent implements OnInit {
  @Input() jobData: Job;
  @Input() warning: any;
  @Input() showAssignee = true;
  @Input() modalView = false;
  @Output() roles: EventEmitter<Roles> = new EventEmitter<Roles>();
  createdByUserFullName: any;
  createdByUserEmail: any;
  publishedByUser: any | null;
  assignedToUser: any | null;
  collabUsersObjects: any[] = [];
  interviewerUsersObjects: any[] = [];
  fetchingUserInfo: boolean;
  searchTerm: string;
  jobOwnerTerm: string;
  jobAssigneeTerm: string;
  jobInterviewerTerm: string;
  collabUsers: any[] = [];
  isRT: boolean;
  isCR: boolean;
  apiSub: Subscription;
  disableAssignee = false;
  collabUserWarning: boolean;
  jobAssigneeWarning: boolean;
  jobOwnerWarning: boolean;

  interviewerUsers: any[] = [];
  interviewerUserWarning: boolean;
  maxInterviewerWarning: boolean;

  constructor(
    private _auth: AuthService,
    private _user: UserService,
    private _route: ActivatedRoute,
    private _api: ApiService,
  ) { }

  get userLob() {return this._auth.user && this._auth.user.Source; }

  ngOnInit() {
    this.isRT = this.userLob.checkLob('RT', 'RE','CR');
    this.isCR = this.userLob.checkLob('CR');
    this.onManageRolesInit(this.jobData);
  }

  // runs on init and sets the properties of the component
  onManageRolesInit(job: any) {
    if (job) {
      this.createdByUserFullName = job.allbirds_metadata.created_by_user;
      this.createdByUserEmail = job.allbirds_metadata.created_by_user_email;
      this.jobData = job;
      this.publishedByUser = {
        fullName: job.allbirds_metadata.published_by_user,
        EmailAddr: job.allbirds_metadata.published_by_user_email,
        backOfficeId: job.allbirds_metadata.published_by_user_back_office_id,
        oprid: job.allbirds_metadata.published_by_user_front_office_id
      };
      this.assignedToUser = {
        fullName: job.allbirds_metadata.assigned_to_user,
        EmailAddr: job.allbirds_metadata.assigned_to_user_email,
        backOfficeId: job.allbirds_metadata.assigned_to_user_back_office_id,
        oprid: job.allbirds_metadata.assigned_to_user_front_office_id
      };
      this.collabUsers = Array.from(job.allbirds_metadata.job_collaborators);
      this.interviewerUsers = Array.from(job.allbirds_metadata.job_interviewer);
      this.getUsers();
      this.getInterviewerUsers();
      this.disableAssigneeField();
    } else {
      const id = this._route.snapshot.paramMap.get('id');
      this.apiSub = this._api.getJob(id)
      .subscribe((jobData: any) => {
        this.createdByUserFullName = jobData.allbirds_metadata.created_by_user;
        this.createdByUserEmail = jobData.allbirds_metadata.created_by_user_email;
        this.jobData = jobData;
        this.publishedByUser = {
          fullName: jobData.allbirds_metadata.published_by_user,
          EmailAddr: jobData.allbirds_metadata.published_by_user_email,
          backOfficeId: jobData.allbirds_metadata.published_by_user_back_office_id,
          oprid: jobData.allbirds_metadata.published_by_user_front_office_id
        };
        this.assignedToUser = {
          fullName: jobData.allbirds_metadata.assigned_to_user,
          EmailAddr: jobData.allbirds_metadata.assigned_to_user_email,
          backOfficeId: jobData.allbirds_metadata.assigned_to_user_back_office_id,
          oprid: jobData.allbirds_metadata.assigned_to_user_front_office_id
        };
        this.collabUsers = Array.from(jobData.allbirds_metadata.job_collaborators);
        //if talent is CR, clear the interviewerUsers that already exist
        this.interviewerUsers = this.isCR ? [] : Array.from(jobData.allbirds_metadata.job_interviewer);
        this.getUsers();
        this.getInterviewerUsers();
        this.disableAssigneeField();
      });
    }
  }

  getUsers(isOnInit = true) {
    const collabUserIds = isOnInit
      ? this.jobData.allbirds_metadata.job_collaborators.map(collaborator => collaborator.user_back_office_id).filter(x => x)
      : this.collabUsers.map(collaborator => collaborator.user_back_office_id).filter(x => x);
    // If there was ID(s), then attempt to get it from the cache.
    if (collabUserIds && collabUserIds.length) { this.collabUsersObjects = collabUserIds.map((id: any) => {
      const user = this._user.getFromCache(id);
      if (user) {
        return {
          collabUser: user,
          user_back_office_id: user.BackOfficeID,
          user_email: user.EmailAddr,
          user_front_office_id: user.Oprid,
          user_name: user.FullName
        };
      }
    }); }
    // If there was IDs, but the user was not yet stored in the cache, then we must retrieve them.
    // We do this by first storing the IDs as keys of an object, converting said keys into an array,
    // and then passing the array to a function in the UserService that retrieves and caches the profile.
    const queryIDs: { [id: string]: boolean } = {};
    if (collabUserIds && collabUserIds.length && this.collabUsersObjects && this.collabUsersObjects.length) {
      this.collabUsersObjects.forEach((user: any, index) => {
        if (!user) {
          queryIDs[collabUserIds[index]] = true;
        }
      });
    }

    const queryIDArr = Object.keys(queryIDs);

    if (!queryIDArr || !queryIDArr.length) {
      this.fetchingUserInfo = false;
      this.sendRoles();
    } else {
      // console.log('Need to fetch:', queryIDArr);
      this._user.getUsersById(queryIDArr)
        .then(() => {
          this.collabUsersObjects = collabUserIds.map((id: any) => {
            const user = this._user.getFromCache(id);
            return {
              collabUser: user,
              user_back_office_id: user.BackOfficeID,
              user_email: user.EmailAddr,
              user_front_office_id: user.Oprid,
              user_name: user.FullName
            };
          });
          this.fetchingUserInfo = false;
          this.sendRoles();
        })
        .catch(() => {
          console.log('[getUsers] An error occurred fetching the users for manage roles.');
        });
    }
  }

  getInterviewerUsers(isOnInit = true) {
    let interviewerUserIds: any[];
    if (this.isCR) interviewerUserIds = [];
    else { 
      interviewerUserIds = isOnInit
      ? this.jobData.allbirds_metadata.job_interviewer.map(interviewer => interviewer.user_back_office_id).filter(x => x)
      : this.interviewerUsers.map(interviewer => interviewer.user_back_office_id).filter(x => x);
    }
    if (interviewerUserIds && interviewerUserIds.length) { this.interviewerUsersObjects = interviewerUserIds.map((id: any) => {
      const user = this._user.getFromCache(id);
      if (user) {
        return {
          interviewerUser: user,
          user_back_office_id: user.BackOfficeID,
          user_email: user.EmailAddr,
          user_front_office_id: user.Oprid,
          user_name: user.FullName
        };
      }
    }); }
    const queryIDs: { [id: string]: boolean } = {};
    if (interviewerUserIds && interviewerUserIds.length && this.interviewerUsersObjects && this.interviewerUsersObjects.length) {
      this.interviewerUsersObjects.forEach((user: any, index) => {
        if (!user) queryIDs[interviewerUserIds[index]] = true;
      });
    }
    const queryIDArr = Object.keys(queryIDs);
    if (!queryIDArr || !queryIDArr.length) {
      this.fetchingUserInfo = false;
      this.sendRoles();
    } else {
      this._user.getUsersById(queryIDArr)
        .then(() => {
          this.interviewerUsersObjects = interviewerUserIds.map((id: any) => {
            const user = this._user.getFromCache(id);
            return {
              interviewerUser: user,
              user_back_office_id: user.BackOfficeID,
              user_email: user.EmailAddr,
              user_front_office_id: user.Oprid,
              user_name: user.FullName
            };
          });
          this.fetchingUserInfo = false;
          this.sendRoles();
        })
        .catch(() => {
          console.log('[getInterviewerUsers] An error occurred fetching the interviewer users for manage roles.');
        });
    }
  }

  // sets the user base on the type string passed.
  // if job owner publishedByUser is set and if
  // assignee then assignedToUser is set.
  // the default adds the selected user to the collabUser array.
  setUser(selectedUser: User, userType: string) {
    switch (userType) {
      case 'interviewer':
        if (selectedUser.BackOfficeID && !this.interviewerUsers.some((user: any) => user.user_back_office_id === selectedUser.BackOfficeID)) {
          this.checkMaxInterviewers();
          if(!this.maxInterviewerWarning){
            this.interviewerUsers.unshift({
              interviewerUser: selectedUser,
              user_back_office_id: selectedUser.BackOfficeID,
              user_email: selectedUser.EmailAddr,
              user_front_office_id: selectedUser.Oprid,
              user_name: selectedUser.FullName
            });
            const isOnInit = false;
            this.getInterviewerUsers(isOnInit);
          }
          this.jobInterviewerTerm = '';
          this.sendRoles();
          break;
        }
        this.jobInterviewerTerm = '';
        break;
      case 'job owner':
        this.publishedByUser = {
          fullName: selectedUser.FullName,
          EmailAddr: selectedUser.EmailAddr,
          backOfficeId: selectedUser.BackOfficeID,
          oprid: selectedUser.Oprid
        };
        this.jobOwnerTerm = '';
        this.sendRoles();
        break;
      case 'assignee':
        this.assignedToUser = {
          fullName: selectedUser.FullName,
          EmailAddr: selectedUser.EmailAddr,
          backOfficeId: selectedUser.BackOfficeID,
          oprid: selectedUser.Oprid
        };
        this.jobAssigneeTerm = '';
        this.sendRoles();
        break;
      default:
        if (selectedUser.BackOfficeID && !this.collabUsers.some((user: any) => user.user_back_office_id === selectedUser.BackOfficeID)) {
          this.collabUsers.unshift({
            collabUser: selectedUser,
            user_back_office_id: selectedUser.BackOfficeID,
            user_email: selectedUser.EmailAddr,
            user_front_office_id: selectedUser.Oprid,
            user_name: selectedUser.FullName
          });
          const isOnInit = false;
          this.getUsers(isOnInit);
          this.searchTerm = '';
          this.sendRoles();
          break;
        }
      this.searchTerm = '';
      break;
    }
  }

  // Checks the results coming back from the app user lookup
  // and set the correct user warning if results empty
  checkResults(resultsObj: any, input: string) {
    this.collabUserWarning = false;
    this.jobAssigneeWarning = false;
    this.jobOwnerWarning = false;
    this.interviewerUserWarning = false;
    if (resultsObj && resultsObj.results && resultsObj.results.length === 0) {
      switch (input) {
        case 'interviewer_users':
          if (this.jobInterviewerTerm && this.jobInterviewerTerm.length > 2) {
            this.interviewerUserWarning = true;
          } else {
            this.interviewerUserWarning = false;
          }
          break;
        case 'collab_users':
          if (this.searchTerm && this.searchTerm.length > 2) {
            this.collabUserWarning = true;
          } else {
            this.collabUserWarning = false;
          }
          break;
        case 'job_assignee':
          if (this.jobAssigneeTerm && this.jobAssigneeTerm.length > 2) {
            this.jobAssigneeWarning = true;
          } else {
            this.jobAssigneeWarning = false;
          }
          break;
        case 'job_owner':
          if (this.jobOwnerTerm && this.jobOwnerTerm.length > 2) {
            this.jobOwnerWarning = true;
          } else {
            this.jobOwnerWarning = false;
          }
          break;
        default:
          break;
      }
    }

  }

  // emits roles up to the parent component.
  sendRoles() {
    const roles: Roles = {
      publishedByUser: this.publishedByUser,
      assignedToUser: this.assignedToUser,
      collabUsers: this.collabUsers,
      interviewerUsers: this.interviewerUsers
    };
    this.roles.emit(roles);
  }

  // removes a specific role
  removeRole(role: string) {
    if (role === 'publishedByUser') {
      this.publishedByUser = null;
    }

    if (role === 'assignedToUser') {
      this.assignedToUser = null;
    }
    this.sendRoles();
    this.warning = null;
  }

  // disables the assignee field if the conditions are met.
  disableAssigneeField() {
    const ACTIVE_STATUS_INDICATOR = ['Accepting Candidates', 'On Hold', 'Covered'];
    if (this.jobData
      && this.jobData.allbirds_metadata
      && !this.jobData.allbirds_metadata.front_office_id) {
        this.disableAssignee = true;
        return;
    }

    if (this.jobData
      && this.jobData.allbirds_metadata
      && !ACTIVE_STATUS_INDICATOR.includes(this.jobData.allbirds_metadata.order_status)) {
        this.disableAssignee = true;
        return;
    }

    if (this._auth.user.Role === 'RD/ARD'
    || this._auth.user.Role === 'Business Support'
    || this._auth.user.Permissions.assignJobToRecruiter) {
      this.disableAssignee = false;
      return;
    }
    this.disableAssignee = true;
    return;
  }

  // removes a collab user from the collab user array
  removeCollabUser(user: User, index: number) {
    this.collabUsers.splice(index, 1);
    this.collabUsersObjects.splice(index, 1);
    this.sendRoles();
  }

  removeInterviewerUser(user: User, index: number) {
    this.interviewerUsers.splice(index, 1);
    this.interviewerUsersObjects.splice(index, 1);
    this.checkMaxInterviewers();
    this.sendRoles();
  }

  checkMaxInterviewers(){
    this.maxInterviewerWarning = this.interviewerUsers.length >= 2 ? true : false;
  }

}
