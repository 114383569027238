import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filter, mergeMap } from 'rxjs/operators';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
  ActivationEnd
} from '@angular/router';
import { ThemeService } from './shared/theme/theme.service';
import  * as baseAuth from './shared/services/auth/auth.service';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from '../environments/environment';
import { BarChangerService } from './shared/services/barChanger/bar-changer.service';
import { LoadingSpinnerService } from './shared/services/loading-spinner/loading-spinner.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastService } from 'src/app/shared/services/toast';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PlatformAlertBannerComponent } from 'src/app/shared/components/platform-alert-banner/platform-alert-banner.component';
import { UtilityService } from './shared/services/utility/utility.service';
declare var require: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, AfterViewInit {
  public _opened = true;
  showSearch = false;
  profileJson: any;
  activeTab: string;
  navBar: number;
  user: any;
  show = false;
  production = false;
  navBarComponent: any;
  sideBarComponent: any;
  modalRef: BsModalRef;
  alertComponent: PlatformAlertBannerComponent;

  constructor(public translate: TranslateService,
    public router: Router,
    private theme: ThemeService,
    public auth: AuthService,
    private cdr: ChangeDetectorRef,
    private _barChanger: BarChangerService,
    public loading: LoadingSpinnerService,
    private afs: AngularFirestore,
    private _toast: ToastService,
    private _utility: UtilityService,
    private _auth: baseAuth.AuthService
  ) {
    if (environment.production) { this.production = true; }
  }

  ngOnInit() {
    // set theme color
    this.theme.setTheme('light');
    this.setUpInternationalization();
    this.auth.user$.subscribe((user) => {
      console.log('user', user);
      if(user){
        this._auth.authenticate();
      }
    })

    // handle Auth0 errors
    this.auth.error$.subscribe(error => {
      // Handle Auth0 Errors here
      console.error("AUTH0 ERROR: "+ error, error)
    });

    // this.auth.error$.pipe(
    //   filter((e) => e instanceof GenericError && e.error === 'login_required'),
    //   mergeMap(() => this.auth.loginWithRedirect())
    // ).subscribe();

    /*
      CODE FOR LATER
    */
    // swUpdate.available.subscribe(event => {
    //   swUpdate.activateUpdate().then(() => document.location.reload());
    // });
  }

  ngAfterViewInit() {
    this._barChanger.sideBarComponent.subscribe((data) => {
      this.sideBarComponent = data;
      this.cdr.detectChanges();
    });
    this._barChanger.navBarComponent.subscribe((data) => {
      this.navBarComponent = data;
      this.cdr.detectChanges();
    });
    // show spinner on route change
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
        // console.log('showing loading indicator')
        this.loading.show();
      }

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        // console.log('hiding loading indicator')
        this.loading.hide();
      }

      if (event instanceof NavigationCancel) {
        // Hide loading indicator
        // console.log('navigation was canceled, hiding loading indicator');
        // TODO: look for this event that keeps on happening when logging with Auth0
        // this._utility.launchFeatureUnavailableModal('app.no_permission');
        this.loading.hide();
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator
        this.loading.hide();
        // console.log('there was a routing error, hiding loading indicator')
        // Present error to user
        console.log(event.error);
        this._toast.showToastNoTranslation(event.error);
      }

      if(event instanceof ActivationEnd){
        // Hide loading indicator
        // console.log('hiding loading indicator')
        this.loading.hide();
      }
      this.cdr.detectChanges();
    });
  }

  toggle() {
    const theme = this.theme.getActiveTheme();
    if (theme && theme.name === 'light') {
      this.theme.setTheme('dark');
    } else {
      this.theme.setTheme('light');
    }
  }

  /**
   *Initialize setting for ngx-translate package
   Currently set up to english, german and french.
   The default language is English. It will try to
   read any of these languages from the user's browser,
   If not, it will default to english.
   */
  setUpInternationalization(): void {
    const languages = ['en', 'de', 'fr'];
    this.translate.addLangs(languages);
    this.translate.setDefaultLang('en');
    for (const lang of languages) {
      this.translate.setTranslation(lang, require('../assets/i18n/' + lang + '.json'));
    }
    const browserLang = this.translate.getBrowserLang();
    this.translate.use(browserLang.match(/en|de|fr/) ? browserLang : 'en');
  }
}
