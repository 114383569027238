<div id="sidebar-container" theme [class.notification-banner]="showAlertBanner">
  <div class="sidebar" [style.width]="leftAttr" style="z-index:1!important;">
    <div class="w-100 text-center">
      <!-- randstad icon will go here -->
      <img src="../../../assets/icons/randstad_klein_white.svg" class="p-3" />
    </div>
    <ul class="menu">
      <li class="text-center w-100">
        <a routerLink="jobs/dashboard" tooltip="Jobs" placement="right">
          <img src="../../../assets/icons/dashboard-white.svg" class="p-3" />
          <label [hidden]="!_opened">{{'sidebar.jobs' | translate}}</label>
        </a>
      </li>
      <li class="text-center w-100">
        <a routerLink="talent" tooltip="Talent" placement="right">
          <img src="../../../assets/icons/account-circle-white.svg" class="p-3" />
          <label [hidden]="!_opened">{{'sidebar.talent' | translate}}</label>
        </a>
      </li>
      <li class="text-center w-100">
        <a routerLink="talent/search" tooltip="Talent Search" placement="right">
          <img src="../../../assets/icons/search-white.svg" class="p-3" />
          <label [hidden]="!_opened">{{'sidebar.talent_search' | translate}}</label>
        </a>
      </li>
      <li *ngIf="hasVmsPermission" class="text-center w-100">
        <a routerLink="vms" tooltip="VMS Inbox" placement="right">
          <img (click)="reloadVmsInbox()" src="../../../../assets/icons/list-alt-white.svg"
          class="p-3 vmsListIcon" />
          <label [hidden]="!_opened">{{'sidebar.vms' | translate}}</label>
          <span id="vmsCount" class="badge badge-danger up">{{ _vmsService.VMSCount || 0 }}</span>
        </a>
      </li>
      <li *ngIf="showAdmin" class="text-center w-100">
        <a routerLink="admin" tooltip="Administration" placement="right">
          <img src="../../../assets/icons/supervisor-white.svg" class="p-3" />
          <label [hidden]="!_opened">{{'sidebar.administration' | translate}}</label>
        </a>
      </li>
    </ul>
    <div class="sub-menu">
      <div class="w-100 text-center bookmark mb-4 ml-1">
        <app-bookmark type="list"></app-bookmark>
      </div>
      <div class="w-100 text-center notifications" tooltip="Placeholder" placement="right">
        <img src="../../../assets/icons/notification_white.svg" alt="..." />
        <span class="badge badge-danger up">{{notifications.length}}</span>
      </div>
      <div *ngIf="auth && auth.user" class="w-100 text-center avatar">
        <!-- TODO: un-comment the app-user-prefs-menu element once we have more than 1 item to go into a menu -->
        <!-- TODO: Once the app-user-prefs-menu is added, remove this link -->
        <a routerLink="settings" [tooltip]="auth.user.FullName" placement="right">
          <img class="white-circle" [src]="auth.user.avatar" alt="..." />
        </a>
      </div>
    </div>
  </div>
</div>
