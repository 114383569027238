import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomListService } from '../custom-list.service';
import { LoadingSpinnerService } from 'src/app/shared/services/loading-spinner/loading-spinner.service';
import { flatMap, take } from 'rxjs/operators';
import { List } from '../../../../../shared/models/external/list.model';
import moment from 'moment';
import { ApiService } from '../../../../../shared/services/api/api.service';

@Component({
  selector: 'remove-from-list-modal',
  templateUrl: './remove-from-list-modal.component.html',
  styleUrls: ['./remove-from-list-modal.component.scss']
})
export class RemoveFromListModalComponent implements OnInit {

  talent: any;
  talentName = 'this candidate'; //placeholder in case talent name is undefined
  listId: string;
  trVariable1: any;

  constructor(
    private _api: ApiService,
    private _bsModalRef: BsModalRef,
    private _listService: CustomListService,
    private _loading: LoadingSpinnerService) { }

  ngOnInit() {
    this.setTalentName();
    this.trVariable1 = { 'value': this.talentName };
  }

  closeDialog(): void {
    this._bsModalRef.hide();
  }

  removeFromList(): void {
    this._loading.show();
    this.closeDialog();

    this._api.searchList(this.listId)
      .pipe(flatMap(list => {
        const updatedList = this.updateListWithRemovedTalent(list);
        const eventLog = {
          logEvent: 'talent was removed from list',
          talentName: this.talentName,
          talentId: this.talent.externalId || this.talent.name
        };
        return this._api.updateList(updatedList, eventLog);
      }))
      .pipe(take(1))
      .subscribe(data => {
        this._listService.refresh();
        this._listService.setCurrentListOfLists(); // If you add a talent and go back to talent dashboard, the counts will update
        this._loading.hide();
      }, err => {
        console.error(err);
        this._loading.hide();
      });

  }

  setTalentName(): void {
    if (this.talent && this.talent.personNames) {
      this.talentName = `‘${this.talent.personNames[0].structuredName.givenName} ${this.talent.personNames[0].structuredName.familyName}’`;
    }
  }

  private updateListWithRemovedTalent(list: List): List {
    let updatedList = list.clone();
    updatedList.talent = [...list.talent].filter(talent => talent.external_id !== this.talent.externalId);
    updatedList.last_update_date = moment();
    if (updatedList.talent) {
      updatedList.talent_count = updatedList.talent.length;
    }

    return updatedList;
  }

}
