import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import {Observable, Observer, Subscription} from 'rxjs';
import { FormControl } from '@angular/forms';
import { JobOrderService } from 'src/app/shared/services/job-order/job-order.service';
import { ToastService } from 'src/app/shared/services/toast';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { formatFullName } from 'src/app/shared/services/utility/formatters';
import { Job } from 'src/app/shared/models/external/job.model';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { TranslateService } from '@ngx-translate/core';

export interface SuggestEvent<T> {
  value: string;
  observer: Observer<T[]>;
  field: string;
}

@Component({
  selector: 'job-order-input',
  templateUrl: './job-order-input.component.html',
  styleUrls: ['./job-order-input.component.scss']
})
export class JobOrderInputComponent implements OnInit, OnDestroy, OnChanges {
  @Input() inputModel: any;

  // titles and labels
  @Input() label: any;
  @Input() rightLabel: string;
  @Input() title: any;
  @Input() lastTitle: any;
  @Input() placeholder: any;
  @Input() jobLob: string;
  // Field names and form control
  @Input() control: FormControl; // this is the reactive form obj
  @Input() field: any; // name of the field we are editing
  @Input() metadata: boolean; // whether it is an allbirds meta data field or not
  @Input() typeAheadField: any; // field name to search for in typeahead
  @Input() completed: any; // an arr containing completed inputs
  @Input() maxLength: any;
  @Input() maxTitleLength: any;

  // Flags
  @Input() pushesToArray: boolean;
  @Input() useAsIsFlag: boolean;
  @Input() restrictedValues: boolean;
  @Input() disableOnSelection: boolean;
  @Input() isPublished: boolean;
  @Input() locked: boolean;
  @Input() searching: any;
  @Input() showResultsOnFocus: boolean;
  @Input() selectionMade: boolean;
  @Input() checkCustomerCredit = true; // opt to disable credit check for search, defaults to true
  @Input() typeaheadOptionsLimit: any;

  // Emitters
  @Output() setState = new EventEmitter<TypeaheadMatch>();
  @Output() suggest = new EventEmitter<SuggestEvent<any>>();
  @Output() nextField = new EventEmitter<string>();
  @Output() prevField = new EventEmitter<string>();
  @Output() valueChange = new EventEmitter<string>();
  @Output() deselect = new EventEmitter();
  @Output() blurHappened = new EventEmitter();

  // ng-templates
  @ViewChild('skills', { static: true }) skills_ref: ElementRef;
  @ViewChild('workLocation', { static: true }) work_location_ref: ElementRef;
  @ViewChild('hiringManager', { static: true }) hiring_manager_ref: ElementRef;
  @ViewChild('addClient', { static: true }) add_client_ref: ElementRef;
  @ViewChild('customer', { static: true }) customer_ref: ElementRef;
  @ViewChild('default', { static: true }) default_ref: ElementRef;

  // Instance variables
  last: (Job | { item: any, header: boolean, value: string })[];
  noResult = false;
  errorCharacterMessage = '';
  errorWordMessage = '';
  results: Observable<any>;
  useAsIs: boolean;
  value: any;
  custom = false;
  show: boolean;
  jobOrder: Job;
  template: any;
  inputTemplate: any;
  getRecentTitlesSub: Subscription;
  getRecentContactsSub: Subscription;

  constructor(
    private _api: ApiService,
    private jobOrderService: JobOrderService,
    private toastService: ToastService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    /*
      Here we are checking if it is a reactive form input.
      If so we are setting the default value and this will be updated by reference.
      If it is being pushed to an array e.g. skills then we dont set a value
    */
    if (this.control && !this.pushesToArray) {
      this.value = this.control.value;
    }

    /*
    If disable on selection flag is set we want to check if there is a value on init.
    If there is a value on init we want to lock the input
   */
    if ((this.value && this.disableOnSelection) || (this.inputModel && this.disableOnSelection) || this.locked) {
      this.selectionMade = true;
    }
    this.inputTemplate = this.getInputTemplate(); // set template
    this.getSuggestResults(); // Create the results observable
    this.getTitleAndLast(); // get the last used values;
    this.getRecentContacts();
  }

  ngOnDestroy() {
    this.getRecentTitlesSub?.unsubscribe();
    this.getRecentContactsSub?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.locked && changes.locked.currentValue) {
      this.selectionMade = true;
    }
  }

  blurred() {
    if (this.control) {
      this.control.markAsTouched();
    }
  }


  typeaheadOnBlur(event: TypeaheadMatch): void {
    console.log(event);
    this.blurHappened.emit(event);
  }

  preventDefault(event: KeyboardEvent) {
    event.preventDefault();
  }

  getSuggestResults() {
    this.results = new Observable((observer: any) => {
      observer.next();
      this.noResult = null;
      const val = this.getVal() ? this.getVal().trim() : '';
      if (val || this.showResultsOnFocus) {
        const body: SuggestEvent<any> = {
          value: val,
          observer,
          field: this.field
        };
        // Runs on every search
        this.suggest.emit(body);
      }
    });
  }

  highlightQuery(str: string, query: string[]): string {
    const t0 = performance.now();
    if (str) {
      const queryStr = query.join(' ');
      const strNew = str.slice();
      if (str.toLocaleLowerCase().indexOf(queryStr) > -1) {
        const queryIndex = str.toLocaleLowerCase().indexOf(queryStr);
        const left_str = strNew.substring(0, queryIndex);
        const right_str = strNew.substring(str.substring(0, queryIndex).length + queryStr.length, str.length);
        const result = left_str + '<span class="highlight">' + strNew.substring(queryIndex, queryIndex + queryStr.length) + '</span>' + right_str;
        const t1 = performance.now();
        console.log(t1 - t0, 'milliseconds');
        return result;
      } else {
        return str;
      }
    }
  }

  getInputTemplate(): ElementRef<any> {
    if (this.field === 'hiringManager') {
      return this.hiring_manager_ref;
    } else if (this.field === 'skills') {
      return this.skills_ref;
    } else if (this.field === 'customer_name') {
      return this.add_client_ref;
    } else if (this.field === 'work_location') {
      return this.work_location_ref;
    } else if (this.field === 'bill_to_location') {
      return this.work_location_ref;
    } else if (this.field === 'addClient') {
      return this.add_client_ref;
    } else {
      return this.default_ref;
    }
  }

  getTitleAndLast() {
    if (
      this.lastTitle &&
      this.typeAheadField !== 'FullName'
    ) {
      const getRecentTitlesSub = this._api.getRecentTitles(this.field)
        .subscribe((data: any) => {
          if (data) {
            this.last = data;
          }
        }, (err: any) => {
          console.log(err);
        });
    }
  }

  getRecentContacts() {
    if (
      this.lastTitle &&
      this.typeAheadField === 'FullName'
    ) {
      const getRecentContactsSub = this._api.getRecentContacts()
        .subscribe(data => {
          /**
           * This is mapped this way because the existing implementation demands it
           * and I (Tim) did not want to refactor yet as this component is heavily reused.
           */
          this.last = data && data.suggests && data.suggests.map(c => ({ item: c, header: false, value: formatFullName(c) }));
        });
    }
  }

  disableTooltip(element: HTMLInputElement) {
    // if there is an overflowed text
    return element.scrollWidth <= element.clientWidth;
  }

  prev() {
    this.prevField.emit(this.field);
  }

  getVal() {
    /*
      This is used to distinguish between inputs with 2 way data binding
      and input coming from reactive forms.
    */
    return (this.control ? this.value : this.inputModel);
  }

  /*
  The next function is the method that is called to update the value
  in the job order form (and elastic) as well as the final value in the DOM
  */
  next(event: TypeaheadMatch, listSelection: boolean) {
    console.log('[next]', event);
    /*
      First we check the input value (this.value) to see if contains restricted values
      if it doesnt then we proceed.
    */
    if (this.check(this.getVal(), listSelection)) {
      // first we remove the errors and flags from view
      this.errorCharacterMessage = '';
      this.errorWordMessage = '';
      this.useAsIs = false;
      this.noResult = false;
      // Check customer credit.
      if (this.typeAheadField === 'FullName' && this.checkCustomerCredit) {
        const obj = event.hasOwnProperty('value') ? event.item : event;
        // console.log('[typeahead] hit', obj);
        if (obj && obj.CUST_STATUS) {
          const { isValid, errorMsg } = this.customerIsValid(obj);
          if (!isValid) {
            this.errorCharacterMessage = errorMsg;
            return;
          }
        }
      }
      // if (obj && obj.CUST_STATUS && obj.CUST_STATUS === 'Do Not Service') {
      //   this.errorCharacterMessage = `${obj.CustName} has insufficient/over-limit credit`;
      //   return;
      // }
      // then we set the state of the input and update elastic
      this.setState.emit(event);
      // and we move to the next field (or put field into non-edit mode)
      this.nextField.emit(this.field);
      // if this prop is true then we lock the input upon completion
      if (this.disableOnSelection) {
        this.disableInput();
      }
      // if the input is being pushed to an array we reset the value in the DOM
      if (this.pushesToArray) {
        this.value = '';
      }
    }
  }

  typeaheadNoResults($event: boolean) {
    if (!this.selectionMade) {
      this.noResult = $event;
    }
  }

  check(value: string, listSelection: boolean): boolean {
    if(this.field === 'internalTitle' || this.field === 'title') this.valueChange.emit(value);
    /*
      If it is a list selection then we know that it
      is in an acceptable format. so we skip the check.
    */
    if (listSelection) {
      return true;
    }
    /*
      We check if its a reactive form element.
      If it is then we need to update the model manually.
    */
    if (this.control) {
      this.value = value;
    }
    // Then we decide if we should should show the 'use as is' button

    const special = /[?!$%@\^&\*;:{}='_`~\[\]]/g;

    const forbidden = /\bcareer fair\b|\bjob fair\b|\bopen house\b|\bcareer expo\b|\bopen interviews\b|\btemp\b|\btemp to hire\b|\bdirect hire\b|\bperm\b|\bpermanent\b|\bposition\b|\bpositions\b|\bnow\b|\bneeded\b|\bopportunity\b|\bopportunities\b|\bimmediate\b|\bimmediately\b|\bhiring\b|\bjobs\b|\bnow hiring\b|\blevel i\b|\blevel i or ii\b|\blevel ii\b|\bapply\b/g;

    if (value === ''
      || value === undefined
      || value.trim().length === 0
      || special.test(value)) {
      this.useAsIs = false;
    } else {
      this.useAsIs = this.useAsIsFlag;
    }
    /*
      Now we create 2 arrays that contain restricted chars/phrases.
      We loop through the arrays and check if they are present in the user input.
      If it is we push it to an array to be displayed to the user
    */
    if (this.restrictedValues) {

      this.errorWordMessage = '';
      this.errorCharacterMessage = '';

      // Check special char
      const foundChar = value.match(special) || [];

      // Check word
      const foundWord = value.match(forbidden) || [];

      if (foundChar.length) {
        console.log('Forbidden Character[s] found: ', foundChar);
        let errorString = foundChar[0];
        let long = false;
        if (foundChar.length > 1) {
          long = true;
          const end = foundChar.length - 1;
          errorString = `${foundChar.slice(0, end).join(', ')} and ${foundChar[end]}`;
        }
        this.errorCharacterMessage = `The special character${long ? 's' : ''} ${errorString} ${long ? 'are' : 'is'} not allowed.`;
      }

      if (foundWord.length) {
        console.log('Forbidden Word[s] found: ', foundWord);
        let errorString = foundWord[0];
        let long = false;
        if (foundWord.length > 1) {
          long = true;
          const end = foundWord.length - 1;
          errorString = `${foundWord.slice(0, end).join(', ')} and ${foundWord[end]}`;
        }
        this.errorWordMessage = `The term${long ? 's' : ''} ${errorString} ${long ? 'are' : 'is'} not allowed.`;
      }

      return !(foundChar.length || foundWord.length);
    } else {
      return true;
    }
  }

  resetFlags() {
    this.errorWordMessage = '';
    this.errorCharacterMessage = '';
    this.noResult = null;
  }

  disableInput() {
    // Disable the input since input has been accepted by the user
    // either by "Use As Is" or by list selection.
    if (this.disableOnSelection) {
      this.selectionMade = true;
      this.resetFlags();
    }
  }

  enableInput(event?: any) {
    if(event) this.setState.emit(event);
    // Used to re-enable the input field when a selection is
    // cleared if disableOnSelection = true.
    if (this.disableOnSelection) {
      this.selectionMade = false;
      this.prev();
      this.clearInput();
      this.deselect.emit();
    }
  }

  clearInput() {
    this.resetFlags();
    this.value = '';
    this.inputModel = '';
    // this.setState.emit({
    //   item: {},
    //   value: ''
    // });
  }

  /**
   * @description Given a contact suggest item, returns the validity of the customer for job creation.
   * @param obj - contact suggest item
   * @returns { isValid, errorMsg }
   */
  customerIsValid(obj: any): { isValid: boolean, errorMsg: string } {
    // Check that the customer status is valid.
    const validStatus: boolean = obj.CUST_STATUS && obj.CUST_STATUS !== 'Do Not Service';
    // Check that the credit flag is valid.
    const validCredit: boolean = (obj.CREDIT_FLAG && obj.CREDIT_FLAG === 'Y') || obj.CREDIT_FLAG === '';
    // Define and return values.
    const isValid: boolean = validStatus && validCredit;
    const trInsufficient = this.translate.instant('job-order-input.insufficient', { 'value': obj.CustName });
    const trDonot = this.translate.instant('job-order-input.donotservice', { 'value': obj.CustName });


    const errorMsg: string = (validStatus && !validCredit)
      ? trInsufficient
      : (!validStatus && validCredit)
        ? trDonot
        : (!validStatus && !validCredit)
          ? trInsufficient
          : ''; // No errors.
    return { isValid, errorMsg };
  }

}
