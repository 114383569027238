import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JobUrlService {
  constructor() { }

  getJobUrl(jobId: string): string {
    return jobId ? `${this.getUrlBase()}/jobs/${jobId}` : '';
  }

  private getUrlBase(): string {
    const currentUrlBase = window.location.origin;

        return currentUrlBase.includes('localhost') ? 'https://dev-xplore.randstadusa.com' : currentUrlBase;
    }
}
