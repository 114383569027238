import { Profiles } from '@allbirds-ui/allbirds-types';
import {EnhancedProfile} from 'src/app/shared/models/internal/enhanced-profile.interface';
import { createModel, Identifiable, Mapping, Model } from './model';
import { ProfileMetadata } from './profile-metadata.model';
import { GrpcToMoment } from './serializers/moment.serializer';
import { Moment } from 'moment';
import { Serial } from './decorators/serial.decorator';
import { EmploymentRecord } from './employment-record.model';
import { Internal } from './decorators/internal.decorator';
import { ITalent } from './talent.interface';
import { Application } from './application.model';
import { GoogleProfile } from './google-profile.model';
import { EducationRecord } from './education-record.model';
import { PrimarySecondaryRecruiter } from '../internal/primary-secondary-recruiter.interface';
export import RawProfile = Profiles.AllbirdsProfile;

class ProfileMapping extends Mapping<RawProfile> implements ITalent, Identifiable {
  @Serial(ProfileMetadata) allbirds_metadata: ProfileMetadata;
  @Serial(GrpcToMoment) candidateUpdateTime?: Moment;
  @Serial(GrpcToMoment) createTime?: Moment;
  @Serial([EmploymentRecord]) employmentRecords?: EmploymentRecord[];
  @Serial([EducationRecord]) educationRecords?: EducationRecord[];
  @Internal() index: number;
  @Internal() score: number;
  @Internal() likelyAvailable: boolean;
  @Internal() _lookupMatched: boolean;
  name: string;
  externalSystem: any;
  externalSystemLob?: any;
  externalId: string;
  phoneNumbers: any;
  emailAddresses: any;
  personNames: any;
  attachments: any[];
  userLob: string;
  covaxData: boolean;
  covaxLink: string;
  isDeleted?: boolean;
  isDisqualified?: boolean;
  MergedIntoFoEmplID?: string;
  preferredPronoun?: string[];
  primaryRecruiter?: PrimarySecondaryRecruiter;
  secondaryRecruiter?: PrimarySecondaryRecruiter[];

  isGoogleProfile(): this is GoogleProfile {
    return false;
  }

  isProfile(): this is Profile {
    return true;
  }

  isApplication(): this is Application {
    return false;
  }

  get key(): string {
    return this.name;
  }

  get lob(): string {
    if (this.externalSystem) {
      return this.externalSystem;
    }
    else {
      return this.externalId;
    }
  }

  get profileId(): string {
    return this.name;
  }

  get enhancedProfile(): EnhancedProfile {
    const {emailAddresses, externalId} = this;
    const enhancedProfile = (this as unknown as EnhancedProfile);
    enhancedProfile.normEmail = emailAddresses && emailAddresses.length ? [emailAddresses[0].emailAddress] : [''];
    enhancedProfile.opco = externalId.abIncludesLob('RT') ? 'RT' : 'RGS';
    return enhancedProfile;
  }

  get candidatePhoneNumber(): string {
    if (this.phoneNumbers && this.phoneNumbers.length) {
      return this.phoneNumbers[0].number;
    }
    return '';
  }

  get candidateName(): string {
    if (this.personNames && this.personNames.length) {
      return this.personNames[0].structuredName && this.personNames[0].structuredName.preferredName;
    }
    return '';
  }

  get familyName(): string {
    if (this.personNames && this.personNames.length) {
      return this.personNames[0].structuredName && this.personNames[0].structuredName.familyName;
    }
    return '';
  }

  get givenName(): string {
    if (this.personNames && this.personNames.length) {
      return this.personNames[0].structuredName && this.personNames[0].structuredName.givenName;
    }
    return '';
  }

  get talentId(): string {
    if(this.allbirds_metadata && this.allbirds_metadata.allbirds_profile_id){
      return this.allbirds_metadata.allbirds_profile_id;
    }
    else {
      return '';
    }
  }

}

export interface Profile extends Model<ProfileMapping> {}

export const Profile = createModel<Profile>(ProfileMapping);
