import { createModel, Mapping, Model } from './model';
import { Jobs } from '@allbirds-ui/allbirds-types';
import { IsoToMoment } from './serializers/moment.serializer';
import { Moment } from 'moment';
import { Serial } from './decorators/serial.decorator';
export import RawJobMetadata = Jobs.AllbirdsJobMetadata;

class JobMetadataMapping extends Mapping<RawJobMetadata> {
  @Serial(IsoToMoment) draft_creation_date: Moment;
  @Serial(IsoToMoment) draft_update_date: Moment;
  @Serial(IsoToMoment) submission_deadline: Moment;
  @Serial(IsoToMoment) job_expire_date?: Moment;
  @Serial(IsoToMoment) client_internal_date: Moment;
  @Serial(IsoToMoment) client_interview_date: Moment;
}

export interface JobMetadata extends Model<JobMetadataMapping> {}

export const JobMetadata = createModel<JobMetadata>(JobMetadataMapping);
