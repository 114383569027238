import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ChatBots, Google} from '@allbirds-ui/allbirds-types';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Subscription} from 'rxjs';
import {AllbirdsAppointment} from 'src/app/shared/models/external/appointment.model';
import {ListTalent} from 'src/app/shared/models/external/list-talent.model';
import {TalentProcessService} from 'src/app/shared/services/talent-process/talent-process.service';
import {ToastClass, ToastService} from 'src/app/shared/services/toast';
import {AuthService} from 'src/app/shared/services/auth/auth.service';
import {TalentService} from 'src/app/shared/services/talent/talent.service';
import {ObjectUtilitiesService} from 'src/app/shared/services/utility/object-utilities.service';
import {LoadingSpinnerService} from 'src/app/shared/services/loading-spinner/loading-spinner.service';
import {CacheService} from 'src/app/shared/services/cache/cache.service';
import {PaginateService} from 'src/app/shared/services/paginate/paginate.service';
import {MetricsService} from 'src/app/shared/services/metrics/metrics.service';
import {JobDetailsService} from 'src/app/shared/services/job-details/job-details.service';
import {FormValidationService} from 'src/app/shared/services/form-validation/form-validation.service';
import {Application} from 'src/app/shared/models/external/application.model';
import {ApplicationRejectionData} from '../../../../../models/internal/application-rejection-data.interface';
import {JobDetailsShortlistService} from '../../../../../services/job-details-shortlist/job-details-shortlist.service';
import {ShouldWarnUnsaved} from '../../../../../services/modal/modal.service';
import {Profile} from '../../../../../models/external/profile.model';
import {CompensationEntry, CompensationUnit, ProcessStatus, ProcessStep, ProfileSkill} from 'src/app/shared/models/external/misc.model';
import moment from 'moment';
import {ApiService} from '../../../../../services/api/api.service';
import {Interview} from 'src/app/shared/models/external/process/interview.model';
import {InterviewSchedule} from 'src/app/shared/models/external/process/interview-schedule.model';
import {Prescreening} from 'src/app/shared/models/external/process/prescreening.model';
import {INTG_STEPS} from 'src/app/shared/models/internal/process.model';
import {TranslateService} from '@ngx-translate/core';
import {EmailRecipient, NotificationData, NotificationTypes} from '../../../../../models/internal/notifications-data.model';
import {catchError} from 'rxjs/operators';
import {JobOrderService} from '../../../../../services/job-order/job-order.service';
import {Job} from '../../../../../models/external/job.model';
import {NotificationsService} from '../../../../../services/notifications/notifications.service';
import InterviewType = ChatBots.InterviewType;
import {UtilityService} from '../../../../../services/utility/utility.service';

export interface LogInterviewForm {
  channel?: string;
  commuteDistance?: number;
  minPay_type?: string;
  minPay_value?: string;
  not_available_for?: string;
  notes?: string;
  opportunities?: string;
  shifts?: string[];
  skills?: Array<{ displayName: string }>;
  startDate?: string;
  travel?: string;
  type?: 'COMPLETED' | 'CANCELLED' | 'NO_SHOW';
}

export interface LogInterviewModalConfig {
  formValues?: LogInterviewForm;
  listTalent?: ListTalent;
  isAppointment?: boolean;
  submitHandler?: any;
  disableTypeControl?: boolean;
  profileInfo? : Profile
}

@Component({
  selector: 'app-log-interview-modal',
  templateUrl: './log-interview-modal.component.html',
  styleUrls: ['./log-interview-modal.component.scss']
})
export class LogInterviewModalComponent implements OnInit, OnDestroy, ShouldWarnUnsaved, AfterViewInit {
  appSub: Subscription;
  jobOrder: Job;
  profileSub: Subscription;
  application: Application;
  profile: Profile;
  notificationType: NotificationTypes;
  form: FormGroup;
  datePickerConfig = {
    dateInputFormat: 'MMM/DD/YYYY',
    showWeekNumbers: false
  };
  showSkillsError = false;
  conversation = false;
  conversationID: string;
  applicationId: string;
  skills: any[] = [];

  @ViewChild('prescreening') prescreeningTemplate: TemplateRef<any>;
  @ViewChild('interview') interviewTemplate: TemplateRef<any>;
  interviewTypeTemplate: TemplateRef<any>;
  showChannel: boolean = false;

  /* Supporting appointments (i.e. Interview without an application/job) */
  isAppointment: boolean = false;
  appointment: AllbirdsAppointment;
  listTalent: ListTalent;
  formValues: LogInterviewForm;
  profileInfo: Profile;
  submitHandler: any;

  // Allows disabling of interview outcome.
  disableTypeControl = false;

  constructor(
    private _api: ApiService,
    public modalRef: BsModalRef,
    private talentProcess: TalentProcessService,
    private toast: ToastService,
    private auth: AuthService,
    private fb: FormBuilder,
    private talentService: TalentService,
    private spinner: LoadingSpinnerService,
    private objectUtils: ObjectUtilitiesService,
    private cacheService: CacheService,
    private paginate: PaginateService<Application>,
    private _metrics: MetricsService,
    private jobDetails: JobDetailsService,
    private _shortlist: JobDetailsShortlistService,
    private _translate: TranslateService,
    private _jobOrder: JobOrderService,
    private _notificationService: NotificationsService,
    private _utility: UtilityService
  ) {
    this.form = this.fb.group({
      type: ['', [Validators.required]],
      not_available_for: ['Week', []],
      channel: ['', [Validators.required]],
      minPay_type: ['', [Validators.required]],
      minPay_value: ['', Validators.compose([Validators.required, Validators.min(0)])],
      opportunities: ['', [Validators.required]],
      startDate: ['', [Validators.required]],
      travel: ['', [Validators.required]],
      shifts: [[], []],
      skill: ['', []],
      notes: ['', [Validators.required]],
      commuteDistance: ['', [Validators.required]]
    });
  }

  ngOnInit() {
    this.conversation = false;
    if (!this.isAppointment) {
      this.appSub = this.talentProcess.selectedApplication.subscribe((data: any) => {
        this.application = data;
        if (data) {
          this.prepareApplicationForm(data);
        }
      });
      this.profileSub = this.talentProcess.selectedProfile.subscribe(profile => {
        this.profile = profile;
        if (profile) {
          this.populateDefaultDataFromProfile(profile);
        }
      });
    } else {
      this.prepareAppointmentForm();
    }
    this.jobOrder = this._jobOrder.storedJobOrder;
  }

  ngAfterViewInit() {
    if (!this.isAppointment && this.application && this.application.randstad_process) {
      const {lastProcessStep} = this.application.randstad_process;
      if (lastProcessStep === ProcessStep.PRESCREENING) {
        this.interviewTypeTemplate = this.prescreeningTemplate;
        this.showChannel = false;
      } else if (lastProcessStep === ProcessStep.INTERVIEW_RECRUITER || lastProcessStep === ProcessStep.INTERVIEW_SCHEDULE) {
        this.interviewTypeTemplate = this.interviewTemplate;
        this.showChannel = true;
      }
    } else if (this.isAppointment) {
      this.interviewTypeTemplate = this.interviewTemplate;
      this.showChannel = true;
    }
  }

  ngOnDestroy(): void {
    if (this.appSub) {
      this.appSub.unsubscribe();
    }
    if (this.profileSub) {
      this.profileSub.unsubscribe();
    }
  }

  prepareApplicationForm(data: Application) {
    if (data && data.randstad_process && data.randstad_process.prescreening) {
      if (data.randstad_process.prescreening.conversationID) {
        this.conversationID = data.randstad_process.prescreening.conversationID;
        this.conversation = true;
      }
      if (data.randstad_process.prescreening.manualScreeningAnswers) {
        this.populateDefaultDataFromApplication(data);
      }
    }

    if (data && data.randstad_process && data.randstad_process._id) {
      this.applicationId = data.randstad_process._id;
    }

    if (this.application && this.application.randstad_process && this.application.randstad_process.lastProcessStep === 'prescreening') {
      this.form.patchValue({ type: 'AVAILABLE' });
    } else if (this.application && this.application.randstad_process && this.application.randstad_process.lastProcessStep === 'interview') {
      this.form.patchValue({ type: 'COMPLETED' });
    }
  }

  populateDefaultDataFromApplication(data: Application) {
    if (typeof data.randstad_process.prescreening.manualScreeningAnswers.channel !== 'undefined') {
      this.form.patchValue({ channel: data.randstad_process.prescreening.manualScreeningAnswers.channel });
    }

    if (typeof data.randstad_process.prescreening.manualScreeningAnswers.opportunities !== 'undefined') {
      this.form.patchValue({ opportunities: data.randstad_process.prescreening.manualScreeningAnswers.opportunities });
    }

    if (typeof data.randstad_process.prescreening.manualScreeningAnswers.startDate !== 'undefined') {
      const startDate = data.randstad_process.prescreening.manualScreeningAnswers.startDate;
      this.form.patchValue({ startDate: startDate });
    }

    if (typeof data.randstad_process.prescreening.manualScreeningAnswers.commuteDistance !== 'undefined') {
      this.form.patchValue({ commuteDistance: data.randstad_process.prescreening.manualScreeningAnswers.commuteDistance });
    }

    if (typeof data.randstad_process.prescreening.manualScreeningAnswers.travel !== 'undefined') {
      this.form.patchValue({ travel: data.randstad_process.prescreening.manualScreeningAnswers.travel });
    }

    if (typeof data.randstad_process.prescreening.manualScreeningAnswers.shifts !== 'undefined') {
      this.form.patchValue({ shifts: data.randstad_process.prescreening.manualScreeningAnswers.shifts });
    }

    if (typeof data.randstad_process.prescreening.manualScreeningAnswers.notes !== 'undefined') {
      this.form.patchValue({ notes: data.randstad_process.prescreening.manualScreeningAnswers.notes });
    }

    if (data.randstad_process.prescreening.manualScreeningAnswers.skills
      && data.randstad_process.prescreening.manualScreeningAnswers.skills.length) {
      this.skills = data.randstad_process.prescreening.manualScreeningAnswers.skills;
    }

    if (this.objectUtils.checkNestedForPath(data, 'randstad_process.prescreening.manualScreeningAnswers.minPay')) {
      const minPayNum = parseFloat(data.randstad_process.prescreening.manualScreeningAnswers.minPay.value);
      this.form.patchValue({ minPay_type: data.randstad_process.prescreening.manualScreeningAnswers.minPay.type });
      this.form.patchValue({ minPay_value: minPayNum.toFixed(2) });
    }
  }

  populateDefaultDataFromProfile(data: Profile) {
    if (data.workAvailability && data.workAvailability.length) {
      const startDate = data.workAvailability[0].notes || null;
      this.form.patchValue({ startDate: startDate });
    }
    if (data.workPreference) {
      if (data.workPreference.jobObjective) {
        this.form.patchValue({ opportunities: data.workPreference.jobObjective });
      }
      if (data.workPreference.preferredCommuteDistanceMiles
        && data.workPreference.preferredCommuteDistanceMiles.value) {
        this.form.patchValue({
          commuteDistance: data.workPreference.preferredCommuteDistanceMiles.value
        });
      }
      if (data.workPreference.jobRequirementInterests
        && data.workPreference.jobRequirementInterests.length) {
        const travel = data.workPreference.jobRequirementInterests.find((x) => {
          if (x.jobRequirement && x.jobRequirement.jobRequirementType) {
            return (x.jobRequirement.jobRequirementType === 'PERCENT_TRAVEL');
          } else {
            return false;
          }

        });
        if (travel
          && travel.jobRequirement
          && travel.jobRequirement.context) {
          this.form.patchValue({ travel: travel.jobRequirement.context });
        }
      }
      if (data.workPreference.preferredCompensation
        && data.workPreference.preferredCompensation.length
        && data.workPreference.preferredCompensation[0].entries
        && data.workPreference.preferredCompensation[0].entries.length) {
        const comp = data.workPreference.preferredCompensation[0].entries[0];
        this.form.patchValue({ minPay_type: comp.unit });
        const amountUnitsNanos = (Number(comp.amount.units) + (comp.amount.nanos / 1000000000)).toFixed(2);
        this.form.patchValue({ minPay_value: amountUnitsNanos });
      }
    }
    if (data.skills && data.skills.length) {
      this.skills = data.skills.filter(x => x.displayName);
    }
  }

  prepareAppointmentForm() {
    if (this.formValues) {
      this.form.patchValue(this.formValues);
    } else if (this.listTalent && this.listTalent.appointment) {
      const {appointment} = this.listTalent;
      const {appointmentFeedback} = appointment;
      if (appointment.appointment && appointment.appointment.interviewType && appointment.appointment.interviewType.length) {
        const convertChannel = appointment.appointment.interviewType[0] === InterviewType.AUDIO
          ? 'PHONE' : appointment.appointment.interviewType[0];
        this.form.get('channel').setValue(convertChannel);
      }
      if (appointmentFeedback) {
        this.form.get('channel').setValue(appointmentFeedback.channel);
        this.form.get('commuteDistance').setValue(appointmentFeedback.commuteDistance);
        if (appointmentFeedback.minPay) {
          this.form.get('minPay_type').setValue(appointmentFeedback.minPay.type);
          this.form.get('minPay_value').setValue(appointmentFeedback.minPay.value);
        }
        this.form.get('notes').setValue(appointmentFeedback.notes);
        this.form.get('opportunities').setValue(appointmentFeedback.opportunities);
        this.form.get('skill').setValue(appointmentFeedback.skills);
        this.form.get('startDate').setValue(appointmentFeedback.startDate);
        this.form.get('travel').setValue(appointmentFeedback.travel);
        this.form.get('type').setValue(appointmentFeedback.type);
      } else {
        this.form.get('type').setValue('COMPLETED');
      }
    }
    if (this.disableTypeControl) {
      this.form.get('type').disable();
    }
  }

  checked(type: string) {
    const typeCtrl = this.form.get('type');
    if (typeCtrl && !typeCtrl.disabled) {
      this.form.patchValue({ type: type });
    }
  }

  addSkill() {
    let displayName = this.form.controls.skill.value.toLowerCase();
    const split = displayName.split(',');
    if (split.length) {
      for (let i = 0; i < split.length; i++) {
        displayName = split[i];
        this.pushSkill(displayName.trim());
      }
    } else {
      this.pushSkill(displayName.trim());
    }
  }

  pushSkill(displayName: string) {
    if (
      displayName &&
      displayName.length &&
      !this.skills.find(x => x.displayName.toLowerCase() === displayName.toLowerCase())
    ) {
      this.skills.push({ displayName });
      this.form.patchValue({ skill: '' });
    }
  }

  removeSkill(skill: ProfileSkill) {
    const index = this.skills.findIndex(x => x.displayName === skill.displayName);
    this.skills.splice(index, 1);
  }

  /*
    This function is used for submitting interview and prescreening results.
    It constructs the application body and then sends it to applicatons index in elastic.
    It also created a profile body and updates the profile index for this candidate.
  */
  submit() {
    const pipelineFlag = (this.jobOrder.allbirds_metadata.order_type == 'Pipeline') ? true : false;
    this.addSkill(); // add skills incase user didnt press enter
    const values = this.form.getRawValue();
    this.setValidators();
    if (this.form.valid) {
      if (!this.isAppointment && this.application && this.application.randstad_process) {
        const key = this.application.randstad_process.lastProcessStep;
        let body: Application ;
        const mentions = UtilityService.getMentionsRecipients(values.notes);
        if (this.application.randstad_process.lastProcessStep === ProcessStep.INTERVIEW_RECRUITER) {
          body = this.constructInterviewBody(values);
          this.notificationType = NotificationTypes.RECRUITER_INTERVIEW;
        } else {
          body = this.constructPrescreeningBody(values);
          this.notificationType = NotificationTypes.PRESCREENING;
        }
        this.spinner.show();
        // update application
        if (values.type === 'NO_SHOW') {
          // if its a no show then reject candidate
          const rejectionData: ApplicationRejectionData = {
            rejectReason: 'No show',
            rejectNote: values.notes,
            rejected: true,
            rejectedByAgentID: this.auth.user.BackOfficeID,
            rejectedTimestamp: moment(),
            lastProcessStatus: ProcessStatus.IR_NOT_A_FIT
          };
          const intgSteps: INTG_STEPS[] = [];
          if(this.auth.user.Source.checkLob('RT', 'RE')) {
            //Similar to prescreeningmanual, rejection will always be noted in BH
            intgSteps.push(INTG_STEPS.ACTIVITY);
          }

          this._shortlist.reject(this.application, rejectionData, intgSteps)
            .then(res => {
              this.paginate.selectTalent(res.application);
              const trvariable = { 'value': res.message };
              this.toast.showToastWithVariables('log-interview-modal.no_show1', trvariable);
              this._metrics.addEventToQueue(this.application, `the candidate was rejected due to being a no-show for an interview`);
              this.form.reset();
              this.modalRef.hide();
            })
            .catch(err => {
              this.toast.showToastNoTranslation(err.message, { cssClass: ToastClass.DANGER });
            })
            .finally(() => {
              this.spinner.hide();
            });
        } else {


          // Both prescreening-manual and recuiter-interview lead to a call to add activity to FO
          let intgSteps: INTG_STEPS[] = [INTG_STEPS.ACTIVITY]; //
          this.talentProcess.updateApplication(body, intgSteps, key, true, pipelineFlag).then((data) => {
            if (data) {
              this._metrics.addEventToQueue(this.application, `interview notes were logged for an interview with the candidate`);
              this.toast.showToast('log-interview-modal.logged');
              /**
               * Removed by DF044-3570. May be brought back later so leaving here for now.
               */
              // if(this.auth.isLob('RT')) {
              //   const currentStepInfo = RT_STEPS.get(ProcessStep.PRESCREENING);
              //   const trVariable = { 'value1': this.application.randstad_process.candidateFullName, 'value2': currentStepInfo.stepTitle };
              //   const toastString = 'talent-process_service.manual_advance';
              //   const toastConfig = {
              //     cssClass: ToastClass.SUCCESS
              //   }
              //   this.toast.showToastWithVariables(toastString, trVariable, toastConfig);
              // }
              // else {
              //   this.toast.showToast('log-interview-modal.logged');
              // }

            }
            // hide UI flags
            this.form.reset();
            this.modalRef.hide();
            this.spinner.hide();
          }, err => {
            console.log(err);
            this.toast.showToast('log-interview-modal.logging_error', { cssClass: ToastClass.DANGER });
            this.spinner.hide();
          });
        }
        // if they are logging positive feedback
        if (values.type !== 'NOT_AVAILABLE' && values.type !== 'NO_SHOW' && values.type !== 'CANCELLED') {
          // send to profile index
          const updates = this.constructProfileBody(values); // this is partial update fields
          const id = this.application.randstad_process.candidateFrontOfficeID;
          this._api.updateProfile(updates, id).subscribe((data) => {
            // this is full applcation udpdate for in memory only
            const profile = this.profile.clone().apply(updates);
            this.talentProcess.selectProfile(profile);
            this.cacheService.cacheProfile(this.profile.name, profile);
          });
        }
        this.sendNotifications(mentions, values.notes, body );
      } else if (this.isAppointment) {
        const rawValue = this.form.getRawValue();
        const mentions = UtilityService.getMentionsRecipients(rawValue.notes);
        const body = this.constructAppointmentBody(rawValue);
        this.notificationType = NotificationTypes.LOG_APPOINTMENT;
        this.sendNotifications(mentions, values.notes, null , body );

        delete rawValue.skill;
        rawValue.skills = this.skills;
        this.submitHandler(rawValue);
        this.form.reset();
        this.modalRef.hide();
      }
    } else {
      this.showAllErrors();
      this.toast.showToast('log-interview-modal.invalid', { cssClass: ToastClass.DANGER });
    }
  }

  createCompensationEntry(value: number, unit: CompensationUnit): CompensationEntry {
    const units = value | 0;
    const cents = value - units;
    const nanos = cents * 1e9;
    return {
      type: 'BASE',
      unit,
      amount: {
        currencyCode: 'USD',
        units,
        nanos
      },
      description: ''
    };
  }

  constructProfileBody(obj: any): Partial<Profile> {
    //keep previous information on workpreference from the current profile
    let workPreference = this.profile.workPreference || {} as Google.Talent.WorkPreference;
    workPreference.jobObjective = obj.opportunities;
    workPreference.preferredCompensation = [{
      entries: [
        this.createCompensationEntry(obj.minPay_value, obj.minPay_type)
      ]
    }];
    workPreference.preferredCommuteDistanceMiles = { value: obj.commuteDistance };
    workPreference.jobRequirementInterests = [
      {
        jobRequirement: {
          jobRequirementType: 'PERCENT_TRAVEL',
          context: obj.travel
        },
        isInterested: true,
        jobs: []
      }
    ];

    return {
      skills: this.uniqueSkills(),
      workAvailability: [{
        available: true,
        dayOfWeek: [],
        shifts: [],
        notes: obj.startDate
      }],
      workPreference: workPreference
    };
  }

  uniqueSkills() {
    const profileSkills = this.profile.skills || [];
    const skills = profileSkills.concat(this.skills);
    const set = new Set<string>();
    return skills.map(skill => {
      const { displayName } = skill;
      if (!displayName) {
        return undefined;
      }
      const key = displayName.toUpperCase();
      if (set.has(key)) {
        return undefined;
      }
      set.add(key);
      return skill;
    }).filter(skill => skill);
  }

  setValidators() {
    if (this.form.controls.type.value === 'CANCELLED'
      || this.form.controls.type.value === 'NOT_AVAILABLE'
      || this.form.controls.type.value === 'NO_SHOW') {
      const keys = Object.keys(this.form.value);
      for (let i = 0; i < keys.length; i++) {
        if (keys[i] !== 'notes') {
          FormValidationService.clearErrors(this.form.controls[keys[i]]);
        }
      }
    } else {
      if (!this.skills.length) {
        FormValidationService.setError(this.form.controls.skill, 'required');
      }
      if (this.application && this.application.randstad_process && this.application.randstad_process.lastProcessStep === 'prescreening') {
        FormValidationService.clearValidators(this.form.controls.channel);
      }
    }
  }

  showAllErrors() {
    this.showSkillsError = true;
    const keys = Object.keys(this.form.value);
    for (let i = 0; i < keys.length; i++) {
      this.form.controls[keys[i]].markAsTouched();
    }
  }

  // if its in virtual interview stage
  constructInterviewBody(values: any): Application {
    const clonedApp = this.application.clone();
    if (this.form.controls.type.value === 'CANCELLED'
      || this.form.controls.type.value === 'NO_SHOW') {
      clonedApp.randstad_process.apply({
        lastProcessStatus: values.type === 'CANCELLED' ? ProcessStatus.IR_INTERVIEW_CANCELLED : ProcessStatus.IR_NOT_A_FIT,
        interviewSchedule: null,
        interview: new Interview({
          type: values.type,
          agentID: this.auth.user.BackOfficeID,
          notes: values.notes
        })
      });
    } else {
      clonedApp.randstad_process.apply({
        lastProcessStatus: ProcessStatus.IR_CONSIDER_RESULTS
      });
      clonedApp.randstad_process.interviewSchedule =
        this.application && this.application.randstad_process && this.application.randstad_process.interviewSchedule
          ? this.application.randstad_process.interviewSchedule
          : new InterviewSchedule({ skipped: false });
      clonedApp.randstad_process.interview = new Interview({
        ...values,
        skills: this.skills,
        minPay: {
          value: values.minPay_value,
          type: values.minPay_type
        },
        agentID: this.auth.user.BackOfficeID,
        skipped: false,
        completionDate: moment()
      });
    }
    console.log("clonedApp = ", clonedApp);
    return clonedApp;
  }

  constructAppointmentBody(values: any) {
    let interview;
    if (this.form.controls.type.value === 'CANCELLED'
      || this.form.controls.type.value === 'NO_SHOW') {
     
         interview = new Interview({
          type: values.type,
          agentID: this.auth.user.BackOfficeID,
          notes: values.notes
        })
    
    } else {
     interview = new Interview({
        ...values,
        skills: this.skills,
        minPay: {
          value: values.minPay_value,
          type: values.minPay_type
        },
        agentID: this.auth.user.BackOfficeID,
        skipped: false,
        completionDate: moment()
      });
    }
    return interview;

  }

  sendNotifications(recipients: EmailRecipient[], comment: string, application?: Application, appointment?: Interview) {
    const emailAddressOnToLine = recipients? recipients.splice(0,1) : []
    const notificationBody: NotificationData = {
      notificationType: this.notificationType,
      notificationObject: {
        title: this.jobOrder.internalTitle,
        candidateFullName: this.profile ? `${this.profile?.personNames[0].structuredName.givenName} ${this.profile?.personNames[0].structuredName.familyName}` : `${this.profileInfo?.personNames[0].structuredName.givenName} ${this.profileInfo?.personNames[0].structuredName.familyName}`,
        published_by_user: this.jobOrder?.allbirds_metadata.published_by_user,
        published_by_user_email: this.jobOrder?.allbirds_metadata.published_by_user_email,
        allbirds_job_id: this.jobOrder?.allbirds_metadata.allbirds_job_id,
        executing_action_user: this.auth.user.FullName,
        executing_action_user_email: this.auth.user.EmailAddr,
        customer_name: this.jobOrder?.allbirds_metadata.customer_name,
        front_office_id: this.jobOrder?.allbirds_metadata.front_office_id,
        user: {...this.auth.user},
        emailRecipients: emailAddressOnToLine,
        emailCc:recipients,
        // noteType: this.getFriendlyNoteType(noteType),
        noteComment: comment,
        contextUrl: this._utility.getContextUrl(location.pathname, location.search),
        applicationObject: application,
        appointment: appointment,
        vms_req_id: this.jobOrder?.allbirds_metadata.vms_req_id,
        hiringManager: this.jobOrder?.hiringManager
      }
    };
    // this._loading.show();
    const sub = this._notificationService.sendNotification(notificationBody)
    .pipe(catchError((err) => {
      console.error(err);
      // this._loading.hide();
      return err;
    }))
    .subscribe(() => {
      sub.unsubscribe();
      // this._loading.hide();
    });
  }

  // if its presreening
  constructPrescreeningBody(values: any): Application {
    const clonedApp = this.application.clone();

    clonedApp.randstad_process.lastProcessStatus = ProcessStatus.P_DECIDE_PROCEED;
    if (!clonedApp.randstad_process.prescreening) {
      clonedApp.randstad_process.apply({ prescreening: new Prescreening({}) });
    }
    if (this.form.controls.type.value === 'NOT_AVAILABLE') {
      clonedApp.randstad_process.prescreening.apply({
        isManualPrescreening: true,
        agentID: this.auth.user.BackOfficeID,
        completionDate: moment(),
        manualScreeningAnswers: {
          type: values.type,
          notes: values.notes,
          not_available_for: values.not_available_for
        }
      });
    } else {
      clonedApp.randstad_process.prescreening.apply({
        isManualPrescreening: true,
        agentID: this.auth.user.BackOfficeID,
        completionDate: moment(),
        manualScreeningAnswers: {
          ...values,
          skills: this.skills,
          minPay: {
            value: values.minPay_value,
            type: values.minPay_type
          },
          not_available_for: null
        }
      });
    }
    /**
     * Deprecated by DF044-3507 and moved into prescreening.component.ts
     * May/may not be brought back so I'm leaving it in for now - Tim
     */
    // const isRT = this.auth.isLob('RT');
    // if(isRT) {
    //   if (this.application!.randstad_process!.lastProcessStep === ProcessStep.PRESCREENING) {
    //     clonedApp.randstad_process.lastProcessStep = ProcessStep.REVIEW_AM;
    //     if(this.jobDetails!.job!.employmentTypes!.toString() == 'SOLUTIONS') {
    //       clonedApp.randstad_process.lastProcessStatus = ProcessStatus.RA_SUBMIT_TO_SS;
    //     }
    //     else {
    //       clonedApp.randstad_process.lastProcessStatus = ProcessStatus.RA_SUBMIT_TO_AM;
    //     }
    //   }
    //   console.log("[LogInterviewComponent] clonedApp = ", clonedApp)
    // }
    return clonedApp;
  }

  isUnsaved(): boolean {
    return this.form.dirty;
  }

  get isPrescreeningContext(): boolean {
    return !this.isAppointment && (
      this.application &&
      this.application.randstad_process &&
      this.application.randstad_process.lastProcessStep === ProcessStep.PRESCREENING
    );
  }

  get isInterviewContext(): boolean {
    return this.isAppointment || (
      this.application &&
      this.application.randstad_process &&
      [ProcessStep.INTERVIEW_RECRUITER, ProcessStep.INTERVIEW_SCHEDULE].includes(this.application.randstad_process.lastProcessStep)
    );
  }

  get isApplicationContext(): boolean {
    return !!this.application;
  }

  get headerText(): string {
    return this.isApplicationContext
      ? this._translate.instant('log-interview-modal.log_prescreening_results')
      : this._translate.instant('log-interview-modal.log_an_interview');
  }
}
