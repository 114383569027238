import { User } from '../../models/external/user.model';

export function formatFullName(user: User) {
  return [user?.FirstName, user?.LastName].filter(v => v).join(' ');
}

export function formatInitials(user: User) {
  return [user?.FirstName, user?.LastName].filter(v => v).map(v => v.charAt(0)).join('');
}

export function formatAsRecruiterHierarchy(user: User) {
  return {
    backOfficeID: user.BackOfficeID,
    backupPersonEmail: user.EmailAddr,
    businessUnit: user.BusinessUnit,
    businessUnitDescription: user.BusinessUnit_Descr,
    email: user.EmailAddr,
    firstName: user.FirstName,
    frontOfficeID: user.Oprid,
    jobTitle: user.JobTitle,
    lastName: user.LastName,
    recruitingDirectorEmail: '',
    subUnit: '',
    subUnitDescription: '',
    systemName: '',
    userType: '',
  };
}


export function htmlToElement(html: string): HTMLElement {
  return new DOMParser().parseFromString(html, 'text/html').body;
}

// extract the unique ID of a talent from the google-CTS formatted "name field"
// i.e. "projects/us-abcts-dev-b476/tenants/21997dad-ae6f-433b-a80a-02521f9d0fe4/profiles/0bdbeaab-b21f-42b2-8deb-f05875cab47a"
// becomes "0bdbeaab-b21f-42b2-8deb-f05875cab47a"
export function extractIdFromName (name: string) :string {
  const nameSplit = name.split('/');
  return nameSplit[nameSplit.length - 1];
}

export function formatPossessive(user: User) {
  return `${formatFullName(user)}'s`;
}

export function dateToTimestamp (date: any) {
  if (!date) return null;
  if (date.seconds) {
    return date;
  }
  date = dateStringToDate(date);
  const seconds = date.getTime() / 1000;
  const secondsRounded = Math.floor(seconds);

  return {
    seconds: secondsRounded,
    nanos: Math.floor((seconds - secondsRounded) * 1e9)
  };
}

export function convertDateStringToMonthYearDay(dateString: string) {
  if (!dateString) {
      return {
          month: 0,
          year: 0,
          day: 0
      }
  }
  const date = new Date(dateString);
  const output = {
      month: date.getMonth() + 1,
      year: date.getFullYear(),
      day: date.getDate()
  }
  return output;
}

// adapted from https://weblog.west-wind.com/posts/2014/Jan/06/JavaScript-JSON-Date-Parsing-and-real-Dates
export function dateStringToDate (dateString: any) {
  const ISOdateStringRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;

  if (!dateString) return null;
  if (dateString.getTime) return dateString; // already a date
  if (dateString[0] === '"' || dateString[0] === "'")
  // strip off JSON quotes
  { dateString = dateString.substr(1, dateString.length - 2); }
  if (ISOdateStringRegex.exec(dateString)) return new Date(dateString);
  return null;
}
