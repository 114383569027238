import {ChatBots} from '@allbirds-ui/allbirds-types';
import {AllbirdsAppointment} from 'src/app/shared/models/external/appointment.model';
import InterviewType = ChatBots.InterviewType;
import { User } from '../../models/external/user.model';
import moment from 'moment';


export class AppointmentStatusProvider {
  constructor(private _appt: AllbirdsAppointment, private _interviewer?: User) { }

  get interviewer() {
    return (this._interviewer && this._interviewer.FirstName && this._interviewer.LastName)
      ? `${this._interviewer.FirstName} ${this._interviewer.LastName}`
      : '';
  }

  get inviteSentDateTime() {
    // Month DD, YYYY at HH:MM AM/PM timezone
    return (this._appt.appointmentInvite.timestamp && this._appt.appointmentInvite.timestamp.formatDatetime()) || 'unknown';
  }

  get inviteScheduledFor() {
    if(!moment.isMoment(this._appt.appointment.timeSlotSelected)){
      return moment(this._appt.appointment.timeSlotSelected).format('MM/DD/YYYY, hh:mm a');
    }
    // dd/mm/yyyy, hh:mm
    return (this._appt.appointment.timeSlotSelected && this._appt.appointment.timeSlotSelected.format('MM/DD/YYYY, hh:mm a')) || 'an unscheduled date';
  }

  get lastUpdatedDateTime() {
    // dd/mm/yyyy, hh:mm
    return (this._appt.lastUpdated && this._appt.lastUpdated.formatDatetime()) || 'unknown';
  }

  get interviewType() {
    const interviewType = this._appt.appointment.interviewType;
    if (interviewType && Array.isArray(interviewType)) {
      return interviewType.join(', ');
    } else if (interviewType) {
      return [interviewType];
    }
    return '';
  }

  get locationAddress() {
    if (this._appt.appointment.interviewType.includes(InterviewType.IN_PERSON)) {
      return `Location: ${this._appt.appointment.interviewAddress}`;
    }
    return '';
  }

  get cancelledBy() {
    if (this._appt?.cancelledBy) return this._appt?.cancelledBy;

    return 'recruiter';
  }
}
