/**
 * These entities come from the recruiter_hierarchy index. At its heart, this index was designed to
 * be a recruiter suggest but since we also pull branch/business unit information from the it, we use
 * partials of the Recruiter type to define the Branch & BusinessUnit types.
 */

export namespace RecruiterHierarchy {

  export interface Recruiter {
    backOfficeID?: string;
    backupPersonEmail?: string;
    businessUnit?: string;
    businessUnitDescription?: string;
    email?: string;
    firstName?: string;
    frontOfficeID?: string;
    jobTitle?: string;
    lastName?: string;
    recruitingDirectorEmail?: string;
    subUnit?: string;
    subUnitDescription?: string;
    systemName?: string;
    userType?: string;
    recruitingDirector_rollup_Email?: string[];
  }

  export type Branch = Partial<Recruiter> & {
    businessUnit: string;
    businessUnitDescription: string;
  };

  export type Unit = Partial<Recruiter> & {
    subUnit: string;
    subUnitDescription: string;
  };

  export type SuggestResponse = Recruiter | Branch | Unit;

  export enum SuggestType {
    CUSTOM = 'CUSTOM',
    RECRUITER = 'RECRUITER',
    BRANCH = 'BRANCH',
    UNIT = 'UNIT',

    // Enums that don't actually search anything.
    MY_TALENT = 'MY_TALENT',
    MY_UNIT = 'MY_UNIT',
    MY_BRANCH = 'MY_BRANCH',

    // RT specific suggest type
    MY_TEAM = 'MY_TEAM',
  }

  export interface SuggestModalConfig {
    type: SuggestType;
    display: {
      headline: string;
      body: string;
      inputPlaceholder: string;
    };
  }

  export interface FilterOption {
    label: string;
    type: SuggestType;
    selected?: SuggestResponse[];
  }

  /** -----------------------------------------------------------------------------
   * Schema to abide by when calling api.service.ts > getRecruiterHierarchy to access
   * the GET /users/recruiter-hierarchy API endpoint.
   * --------------------------------------------------------------------------- */
  interface GetRequestBase {
    operator: 'AND' | 'OR';
    backOfficeIds?: string[];
    frontOfficeIds?: string[];
    emailAddresses?: string[];
    businessUnits?: string[];
    subUnits?: string[];
    recruitingDirectorEmails?: string[];
  }
  type GetRequestWithBackOfficeIds = GetRequestBase & { backOfficeIds: string[]; };
  type GetRequestWithFrontOfficeIds = GetRequestBase & { frontOfficeIds: string[]; };
  type GetRequestWithEmailAddresses = GetRequestBase & { emailAddresses: string[]; };
  type GetRequestWithBusinessUnits = GetRequestBase & { businessUnits: string[]; };
  type GetRequestWithSubUnits = GetRequestBase & { subUnits: string[] };
  type GetRequestWithRecruitingDirectorEmails = GetRequestBase & { recruitingDirectorEmails: string[] };

  // Ensures that the request contains AT LEAST one of the fields used as conditionals in the query.
  export type GetRequest = GetRequestWithBackOfficeIds | GetRequestWithFrontOfficeIds | GetRequestWithEmailAddresses | GetRequestWithBusinessUnits | GetRequestWithSubUnits | GetRequestWithRecruitingDirectorEmails;

  // Response format for the GET users/recruiter-hierarchy endpoint.
  export interface GetResponse {
    recruiters: Recruiter[];
  }

  // Used for talent metrics requests.
  export type RecruiterScope = Partial<Recruiter> & {
    backOfficeID?: string,
    frontOfficeID?: string,
    email?: string
  };

  export interface Scope {
    recruiters?: RecruiterScope[];
    branches?: string[];
    units?: string[];
  }
}

