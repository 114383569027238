import { Component, OnDestroy, OnInit, Input, OnChanges, SimpleChange } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotFitModalComponent } from '../talent-process-modals/not-fit-modal/not-fit-modal.component';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { JobOrderService } from 'src/app/shared/services/job-order/job-order.service';
import { TalentProcessService } from 'src/app/shared/services/talent-process/talent-process.service';
import { FirestoreService } from 'src/app/shared/services/firestore/firestore.service';
import { MetricsService } from 'src/app/shared/services/metrics/metrics.service';
import { UtilityService } from 'src/app/shared/services/utility/utility.service';
import { JobDetailsService } from 'src/app/shared/services/job-details/job-details.service';
import { ToastClass, ToastService } from 'src/app/shared/services/toast';
import { Application } from 'src/app/shared/models/external/application.model';
import { UserService } from 'src/app/shared/services/user/user.service';
import { User } from 'src/app/shared/models/external/user.model';
import { ModalService } from '../../../../services/modal/modal.service';
import { Job } from '../../../../models/external/job.model';
import { ProcessStatus, ProcessStep } from 'src/app/shared/models/external/misc.model';
import { StepInfo } from '../../../../services/talent-process/process-steps';
import { ApplicationEntityChangeService } from '../../../../services/entity-change/application-entity-change.service';
import {RecruitmentPhasesService} from './recruitment-phases.service'
import { INTG_STEPS } from 'src/app/shared/models/internal/process.model';
import { Offer } from 'src/app/shared/models/external/process/offer.model';
import moment from 'moment';
import { JobDetailsShortlistService } from 'src/app/shared/services/job-details-shortlist/job-details-shortlist.service';
import { ShortlistActionResponse } from 'src/app/shared/models/internal/shortlist-action-response.interface';
import { PaginateService } from 'src/app/shared/services/paginate/paginate.service';
import { Talent } from 'src/app/shared/models/external/talent.interface';

@Component({
  selector: 'app-recruitment-phases',
  templateUrl: './recruitment-phases.component.html',
  styleUrls: ['./recruitment-phases.component.scss'],
  providers:[RecruitmentPhasesService]
})
export class RecruitmentPhasesComponent implements OnInit, OnDestroy {
  @Input() talentProfile: any;

  // Currently viewed job.
  jobOrder: Job;
  jobOrderSub: Subscription;

  // The selected application.
  talentApplication: Application;
  talentApplicationSub: Subscription;
  statusLabel: string;

  // firestore
  fireStoreApplicationSub: any;

  // Current step object.
  currentStep: any;

  // Steps map.
  steps: Map<ProcessStep, StepInfo>;
  stepsLength: number;
  pipelineSteps = ["prescreening", "interview"];

  // Array of skippable steps for the dropdown.
  skippableSteps: any[] = [];

  // Map for different HTML event target tagNames that are valid and should
  // trigger the feature unavailable modal.
  featureUnavailableTriggers = ['button', 'a', 'li', 'img'];

  conversationID: string;

  //Determine if talent has FO status as "INTERVIEW"
  talentInterviewStatus:boolean
  rejectedByAgent: User;
  candidateName: string = '';

  constructor(
    public _auth: AuthService,
    private _jobDetails: JobDetailsService,
    private _jobOrderService: JobOrderService,
    private _talentProcess: TalentProcessService,
    private _firestore: FirestoreService,
    private _metrics: MetricsService,
    private _modal: ModalService,
    private _utility: UtilityService,
    private _toast: ToastService,
    private _userService: UserService,
    private _applicationChange: ApplicationEntityChangeService,
    private _recruitmentPhasesService: RecruitmentPhasesService,
    private _shortlist: JobDetailsShortlistService,
    public _paginate: PaginateService<Talent>,

  ) { }

  ngOnInit() {
    this.steps = this._talentProcess.staticSteps;
    this.listenToJobOrder();
    this.listenToSelectedApplication();
    if (this.talentApplication && !this.talentApplication.externalId && this.talentApplication.randstad_process) {
      this.listenToFirestore(this.talentApplication.randstad_process._id);
    }
    this._recruitmentPhasesService.checkTalentFOTalentStatus(this.talentProfile);
    if (this.talentApplication?.randstad_process && this.talentApplication?.randstad_process.rejected) {
      this.getRejectedByAgent();
    }
  }

  ngOnDestroy() {
    this.talentApplicationSub.unsubscribe();
    this.jobOrderSub.unsubscribe();
    if (this.fireStoreApplicationSub) {
      this.fireStoreApplicationSub.unsubscribe();
    }
    this.removePhaseClickListener('JOB_NO_FO_ID');
    this.removePhaseClickListener('PROFILE_NO_EXTERNAL_ID');
  }

  ngOnChanges( changes:SimpleChange){
    this._recruitmentPhasesService.checkTalentFOTalentStatus(this.talentProfile)
  }

  listenToJobOrder() {
    this.jobOrderSub = this._jobOrderService.jobOrderObservable
      .subscribe(job => {
        this.jobOrder = job;
      });
  }

  listenToSelectedApplication() {
    this.talentApplicationSub = this._talentProcess.selectedApplication
      .subscribe(application => {
        this.conversationID = null;
        this.talentApplication = application && application.isApplication && application.isApplication() ? application.clone() : application ? new Application(application) : null;
        if (
          this.talentApplication
          && this.talentApplication.randstad_process
          && this.talentApplication.randstad_process.prescreening
          && this.talentApplication.randstad_process.prescreening.conversationID
        ) {
          this.conversationID = this.talentApplication.randstad_process.prescreening.conversationID;
        }
        this.checkJobStatus();
        this.setCurrentStep();
        this.setSkippableSteps();
        // if (this.talentApplication && this._auth && this._auth.user) {
        //   this.getStatusLabel();
        // }

        // Name
        if(this.talentApplication){
          if(this.talentApplication.randstad_){
            this.candidateName = this.talentApplication.randstad_;
          } else{
            if(this.candidateName){
              this.talentApplication.randstad_ =  this.candidateName;
            }
          }
        }
      });
  }

  listenToFirestore(applicationId: any) {
    this.fireStoreApplicationSub = this._firestore.listenApplication(applicationId)
      .subscribe(data => {
          if (data[0] && data[0].frontOfficeId && data[0].googleProfileURI === this.talentApplication.profile) {
            this.talentApplication.externalId = this.talentApplication.externalId || data[0].frontOfficeId;
            this._talentProcess.selectApplication(this.talentApplication);
          }
        },
        error => {
          console.log(error);
        });
  }

  getRejectedByAgent(){
    const { randstad_process } = this.talentApplication;
    const { rejectedByAgentID } = randstad_process;
    this.rejectedByAgent = this._userService.getFromCache(rejectedByAgentID)
    if (!this.rejectedByAgent) {
      this._userService.getUsersById([rejectedByAgentID]).then(() => {
        this.rejectedByAgent = this._userService.getFromCache(rejectedByAgentID);
      });
    }
  }

  getStatusLabel() {
    if (this.talentApplication && this.jobOrder) {
      if (this.talentApplication.randstad_process && this.talentApplication.randstad_process.rejected) {
        const { randstad_process } = this.talentApplication;
        const { rejectedByAgentID } = randstad_process;
        if (this._auth.user && rejectedByAgentID && this.rejectedByAgent) {
          this.statusLabel = this._talentProcess.getLabel(this.talentApplication, this._auth.user.Source, 'process', this.jobOrder, this.rejectedByAgent);
        } else {
          this.statusLabel = this._talentProcess.getLabel(this.talentApplication, this._auth.user.Source, 'process', this.jobOrder);
        }
      }
    }
    return this.statusLabel;
  }

  /**
   * Fired every time the job changes; if the job has no front office ID, calls
   * a function to add a click listener that disables process steps.
   */
  checkJobStatus() {
    if (
      !this.jobOrder ||
      !this.jobOrder.allbirds_metadata ||
      (!this.jobOrder.allbirds_metadata.front_office_id && !this.jobOrder.allbirds_metadata.pipeline_id)
    ) {
      this.addPhaseClickListener('JOB_NO_FO_ID');
    } else {
      this.removePhaseClickListener('JOB_NO_FO_ID');
      // if we have a job id then we need to check if the application has a profile id
      if (!this.talentApplication ||
        !this.talentApplication.randstad_process ||
        (!this.talentApplication.randstad_process.candidateFrontOfficeID) ||
        (!this.talentApplication.randstad_process.candidateFrontOfficeID.includes('RT')
          && !this.talentApplication.randstad_process.candidateFrontOfficeID.includes('RGS'))) {
        this.addPhaseClickListener('PROFILE_NO_EXTERNAL_ID');
      } else {
        this.removePhaseClickListener('PROFILE_NO_EXTERNAL_ID');
      }
    }
  }

  /**
   * Registers a click listener that calls the showFeatureUnavailable method on the containing DOM element.
   */
  addPhaseClickListener(value: string) {
    const node = document.getElementById('recruitmentPhases');
    if (node) {
      if (value === 'JOB_NO_FO_ID') {
        node.addEventListener('click', this.showFeatureUnavailable.bind(this), true);
      } else if (value === 'PROFILE_NO_EXTERNAL_ID') {
        node.addEventListener('click', this.showFeatureUnavailableNoProfileID.bind(this), true);
      }
    }
  }

  /**
   * Removes the click listener that fires the showFeatureUnavailable method.
   */
  removePhaseClickListener(value: string) {
    const node = document.getElementById('recruitmentPhases');
    if (node) {
      if (value === 'JOB_NO_FO_ID') {
        node.removeEventListener('click', this.showFeatureUnavailable.bind(this), true);
      } else if (value === 'PROFILE_NO_EXTERNAL_ID') {
        node.removeEventListener('click', this.showFeatureUnavailableNoProfileID.bind(this), true);
      }
    }
  }

  /**
   * Shows the feature unavailable modal if any buttons in the recruitment phases are pressed
   * while the currently-viewed job has no front office ID.
   *
   * @param event
   */
  showFeatureUnavailable(event: any) {
    const target = (event && event.target && event.target.tagName) ? event.target.tagName.toLowerCase() : '';
    if (this.featureUnavailableTriggers.includes(target)) {
      event.preventDefault();
      event.stopPropagation();
      this._utility.launchFeatureUnavailableModal('feature-unavailable.JOB_NO_FO_ID');
    }
  }

  /**
   * Shows the feature unavailable modal if any buttons in the recruitment phases are pressed
   * while the currently-viewed application has no front office ID.
   *
   * @param event
   */
  showFeatureUnavailableNoProfileID(event: any) {
    console.log(event);

    const target = (event && event.target && event.target.tagName) ? event.target.tagName.toLowerCase() : '';
    if (this.featureUnavailableTriggers.includes(target)) {
      event.preventDefault();
      event.stopPropagation();
      this._utility.launchFeatureUnavailableModal('feature-unavailable.PROFILE_NO_EXTERNAL_ID');
    }
  }

  setCurrentStep() {
    if (this.talentApplication && this.talentApplication.randstad_process) {
      const { lastProcessStep } = this.talentApplication.randstad_process;
      this.currentStep = this.steps.get(lastProcessStep);
    }
  }

  setSkippableSteps() {
    if (this.talentApplication && this.talentApplication.randstad_process) {
      const { lastProcessStep } = this.talentApplication.randstad_process;
      const skippableSteps: StepInfo[] = [];
      let found = false;
      // if(this.talentApplication.randstad_process.orde)
      this.steps.forEach((value, key) => {
        if (key === lastProcessStep) {
          found = true;
          return;
        }
        if (found) {
          if (this.jobOrder.allbirds_metadata.order_type === 'Pipeline') {
            if (this.pipelineSteps.includes(key)) {
              skippableSteps.push(value);
              return;
            }
            return;
          }
          skippableSteps.push(value);
        }
      });
      this.skippableSteps = skippableSteps;
    }
  }

  openNotFitModal($event: any) {
    // $event.preventDefault();
    const initialState = { talent: this.talentApplication, updateSubmission: $event?.updateSubmission || false, internalSubmission: $event?.internalSubmission || false, clientSubmission: $event?.clientSubmission || false, clientInterview: $event?.clientInterview || false };
    this._modal.show(NotFitModalComponent, { initialState });
  }

  skipStep(talent: any) {
    let isSolutionsJob: boolean = this.jobOrder && this.jobOrder.employmentTypes &&
      this.jobOrder.employmentTypes.toString() === 'SOLUTIONS' ? true : false;

    if (this.talentApplication.randstad_process.rejected) {
      return false;
    }
    this._metrics.addEventToQueue(this.talentApplication, `candidate skipped the ${
      this.talentApplication.randstad_process.lastProcessStep || 'prescreening'
    } process step.`);
    //console.log("Skipping step for talent ", talent  + " isSolutionsJob =", isSolutionsJob);
    return this._talentProcess.skipStep(talent, isSolutionsJob);
  }

  skipToStep(talent: any, step: any) {
    let isSolutionsJob: boolean = this.jobOrder && this.jobOrder.employmentTypes &&
      this.jobOrder.employmentTypes.toString() === 'SOLUTIONS';
    if (this.talentApplication.randstad_process.rejected) {
      return false;
    }
    this._metrics.addEventToQueue(this.talentApplication, `candidate was manually advanced to the ${step.stepTitle} phase.`);
    const index = this.skippableSteps.indexOf(step);
    this.skippableSteps.splice(index, 1)
    return this._talentProcess.skipToStep(talent, step.stepId, isSolutionsJob);
  }

  skipToInterviewHM(talent: any) {
    if (this.talentApplication.randstad_process.rejected) {
      return false;
    }
    this._metrics.addEventToQueue(this.talentApplication,
      `candidate was manually advanced to the ${this.steps.get(ProcessStep.INTERVIEW_HM).stepTitle} phase.`);
    return this._talentProcess.skipToStep(talent, this.steps.get(ProcessStep.INTERVIEW_HM).stepId);
  }

  getCurrentStepTitle() {
    const { lastProcessStep } = this.talentApplication.randstad_process;
    return this.steps.get(lastProcessStep).stepTitle;
  }

  getRejectedText() {
    let { candidateFullName, rejectedTimestamp } = this.talentApplication.randstad_process;
    // const userName = this._user.getNameFromBackOfficeID(rejectedByAgentID);
    const jobTitle = this.jobOrder.title;
    const date = rejectedTimestamp.formatDate();

    if(!candidateFullName){
      candidateFullName = this.talentApplication.randstad_;
    }
    return `${candidateFullName} was rejected for the job ${jobTitle} on ${date}.`;
  }

  /**
   * Returns true if the application is in a completed state (offer rejected or accepted).
   * @param application
   */
  applicationIsComplete(application: Application) {
    if (!application || !application.randstad_process || !application.randstad_process.lastProcessStatus) {
      return false;
    }
    return [ProcessStatus.O_ACCEPTED, ProcessStatus.O_REJECTED].includes(application.randstad_process.lastProcessStatus);
  }

  applicationDidChange(application: Application) {
    return this._applicationChange.didChange(application);
  }
  reinstate() {
    this._shortlist.reinstate(this.talentApplication)
      .then((dataa: ShortlistActionResponse) => {
        this._shortlist.updateInMemoryApplications([dataa.application]);
        this._paginate.selectTalent(dataa.application);
        const key = this.talentApplication.randstad_process.lastProcessStep;
        const body = this.constructBody(dataa.application);
        console.log({ body });
        const intgSteps : INTG_STEPS[] = [INTG_STEPS.SUBMISSION];
        this._talentProcess.updateApplication(body, intgSteps, key).then((data) => {
          if (data) {
            this._metrics.addEventToQueue(this.talentApplication, `an offer was made to the candidate`);
            this._toast.showToast('offer.success_logged');
          } else {
            this._toast.showToast('offer.error_logged');
          }
        }, err => {
          console.log(err);
          this._toast.showToast('offer.error_logged');
        });
      })

    }

  constructBody(d:Application): Application {
    const clonedApp = d;
    clonedApp.randstad_process.apply({
      lastProcessStatus: ProcessStatus.O_ACCEPTED,
      offer: new Offer({
        offerDate: moment(),
        agentID: this._auth.user.BackOfficeID
      })
    });
    return clonedApp;
  }

}
