<div class="screening-questions container-fluid p-0" *ngIf="conversationID && showConversation">
  <h6 class="screening-questions--title" (click)="openConversationModal(conversationAvailable)">
    {{'prescreening-questions.review_screening_questions.' | translate}}
    <span [ngClass]="badgeClass">{{conversationStatusDisplay}}</span>
    <img src="assets/icons/chevron.svg" alt="view"  />

  </h6>
</div>


<ng-template #conversationAvailable>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <i class="icon-WL_icon_close"></i>
    </button>
  </div>
  <div class="modal-body">
    <app-conversation [conversationID]="conversationID" [modal]="true"></app-conversation>
  </div>
  <div id="na-footer" class="modal-footer">
    <button type="button" class="btn rds--button--primary pull-right" (click)="modalRef.hide()">{{'prescreening-questions.close' | translate}}</button>
  </div>
</ng-template>
