import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { JobOrderService } from 'src/app/shared/services/job-order/job-order.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { Job } from 'src/app/shared/models/external/job.model';
import { ToastClass, ToastService } from 'src/app/shared/services/toast';
import { JobForm } from '../../../../../shared/models/external/job-form.model';
import moment from 'moment';
import { NotificationData, NotificationTypes } from 'src/app/shared/models/internal/notifications-data.model';
import { Roles } from 'src/app/shared/components/manage-roles/manage-roles.component';
import { OrderType } from 'src/app/shared/models/internal/job-form.interface';

@Component({
  selector: 'app-manage-roles-modal',
  templateUrl: './manage-roles-modal.component.html',
  styleUrls: ['./manage-roles-modal.component.scss']
})
export class ManageRolesModalComponent implements OnInit {
  title = '';
  customer_name = '';
  front_office_id = '';
  customer_priority?: number;
  jobData: any;
  isRT: boolean;
  publishedByUser: any | null;
  assignedToUser: any | null;
  collabUsers: any[] = [];
  interviewerUsers: any[] = [];
  formJobOrder: any;
  saving: boolean;
  jobOwnerChange = false;
  assigneeChange = false;
  collabUsersChange = false;
  interviewerUsersChange = false;
  apiSub: Subscription;

  constructor(
    private _api: ApiService,
    public _bsModalRef: BsModalRef,
    private jobOrderService: JobOrderService,
    private toastService: ToastService,
    private translate: TranslateService,
    private _notificationService: NotificationsService,
    private _auth: AuthService

  ) { }

  get userLob(): string { return this._auth.user && this._auth.user.Source; }
  get isClientOrder(): boolean { return this.jobData?.allbirds_metadata?.order_type !== OrderType.PIPELINE; }

  ngOnInit() {
    this.isRT = this.userLob.checkLob('RT', 'RE',"CR");
    this.setData(this.jobData);
    this._api.getJob(this.jobData.allbirds_metadata.allbirds_job_id, true).toPromise().then(this.setFormJobOrder);
  }

  setFormJobOrder = (jobForm: JobForm) => {
    this.formJobOrder = jobForm;
  }

  setData(jobData: Job) {
    if (jobData) {
      if (jobData.internalTitle) {
        this.title = jobData.internalTitle;
      }

      if (jobData.allbirds_metadata) {
        this.customer_name = jobData.allbirds_metadata.customer_name || '';
        this.customer_priority = jobData.allbirds_metadata.customer_priority;
        this.front_office_id = jobData.allbirds_metadata.front_office_id;
      }
    }
  }

  setRoles(roles: Roles) {
    this.publishedByUser = roles.publishedByUser;
    this.assignedToUser = roles.assignedToUser;
    this.collabUsers = roles.collabUsers;
    this.interviewerUsers = roles.interviewerUsers
    this.detectRoleChanges();
  }

  updateRoles() {
    const changedFields: string[] = [];
    if (this.jobOwnerChange && this.isRT) {
      this.formJobOrder.published_by_user = this.publishedByUser.fullName;
      this.formJobOrder.published_by_user_email = this.publishedByUser.EmailAddr;
      this.formJobOrder.published_by_user_back_office_id = this.publishedByUser.backOfficeId;
      this.formJobOrder.published_by_user_front_office_id = this.publishedByUser.oprid;

      changedFields.push('published_by_user');
      changedFields.push('published_by_user_email');
      changedFields.push('published_by_user_back_office_id');
      changedFields.push('published_by_user_front_office_id');
    }

    if (this.assigneeChange && this.isRT) {
      if (this.isFirstAssignment(this.formJobOrder)) {
        this.formJobOrder.job_expire_date = moment().add(15, 'days');
        this.jobData.allbirds_metadata.job_expire_date = this.formJobOrder.job_expire_date;
        changedFields.push('job_expire_date');
      }
      this.formJobOrder.assigned_to_user = this.assignedToUser.fullName;
      this.formJobOrder.assigned_to_user_back_office_id = this.assignedToUser.backOfficeId;
      this.formJobOrder.assigned_to_user_email = this.assignedToUser.EmailAddr;
      this.formJobOrder.assigned_to_user_front_office_id = this.assignedToUser.oprid;

      changedFields.push('assigned_to_user');
      changedFields.push('assigned_to_user_back_office_id');
      changedFields.push('assigned_to_user_email');
      changedFields.push('assigned_to_user_front_office_id');
    }

    if (this.collabUsersChange) {
      this.formJobOrder.job_collaborators = this.collabUsers;
      changedFields.push('job_collaborators');
    }

    if (this.interviewerUsersChange) {
      this.formJobOrder.job_interviewer = this.interviewerUsers;
      changedFields.push('job_interviewer');
    }

    this.apiSub = this._api.updateJob(this.formJobOrder, changedFields)
    .subscribe(async () => {
      await this.updateJobSuccess(); // update call is successful, update the field for job in memory so it can be used for next steps
      if (this.jobOwnerChange || this.assigneeChange || this.interviewerUsersChange
        // now collobusers Also sent to BH with bumbleebee changes
        || this.collabUsersChange) { // if it was owner or assignee change then we need to publish the job so FO updates
        this.publishJob(changedFields);
      } else { // we do not need to publish the job for collab or interviewer changes, just tell update was succesful
        this.successToast();
      }
  }, (err: any) => {
      const trError = this.translate.instant('manage-roles.update_error');
      this.toastService.showToastNoTranslation(trError, { cssClass: ToastClass.DANGER });
      console.log('job update failed.', err);
      this.saving = false;
    });
  }

  publishJob = (changedFields: string[]) => {
    const interviewObj = this.jobOrderService.getInterviewObject(this.jobData, this.publishedByUser);
    this.apiSub = this._api.publishJob(this.formJobOrder.allbirds_job_id, interviewObj, changedFields)
      .subscribe(() => {
        this.successToast();
      }, (err: any) => {
        const trError = this.translate.instant('assign-recuiter-modal.job_republish_failed');
        this.toastService.showToastNoTranslation(trError, { cssClass: ToastClass.DANGER });
        console.log('job republish failed.', err);
        this.saving = false;
      });
  }

  successToast = () => {
    if (this._bsModalRef) {
      this._bsModalRef.hide();
      this.saving = false;
      const trSuccess = this.translate.instant('manage-roles.success_message', {
        'value': this.title,
      });
      this.toastService.showToast(trSuccess, { cssClass: ToastClass.SUCCESS });
    }
  }

  updateJobSuccess = async () => {
    if (this.jobOwnerChange) {
      this.jobData.allbirds_metadata.published_by_user = this.publishedByUser.fullName;
      this.jobData.allbirds_metadata.published_by_user_email = this.publishedByUser.EmailAddr;
      this.jobData.allbirds_metadata.published_by_user_back_office_id = this.publishedByUser.backOfficeId;
      this.jobData.allbirds_metadata.published_by_user_front_office_id = this.publishedByUser.oprid;
    }
    if (this.assigneeChange) {
      this.jobData.allbirds_metadata.assigned_to_user = this.assignedToUser.fullName;
      this.jobData.allbirds_metadata.assigned_to_user_email = this.assignedToUser.EmailAddr;
      this.jobData.allbirds_metadata.assigned_to_user_back_office_id = this.assignedToUser.backOfficeId;
      this.jobData.allbirds_metadata.assigned_to_user_front_office_id = this.assignedToUser.oprid;
      this.sendNotification();
    }
    if (this.collabUsersChange) this.jobData.allbirds_metadata.job_collaborators = this.collabUsers;
    if (this.interviewerUsersChange) this.jobData.allbirds_metadata.job_interviewer = this.interviewerUsers;

    return this.jobOrderService.storeJobDetails(this.jobData);
  }

  checkPublishedUser() {
    return this.publishedByUser ? true : false;
  }

  sendNotification() {
    if (this.jobData && this.jobData.allbirds_metadata.front_office_id && this.assigneeChange) {
      const {
        published_by_user,
        published_by_user_email,
        assigned_to_user,
        assigned_to_user_email
      } = this.jobData.allbirds_metadata;
      if (this.userLob.checkLob('RT', 'RE',"CR")) {

        const emailRecipients = assigned_to_user !== published_by_user
         ? [
          {
            recipient: assigned_to_user,
            recipientEmail: assigned_to_user_email
          },
          {
            recipient: published_by_user,
            recipientEmail: published_by_user_email
          }
        ].filter(r => r.recipient !== this._auth.user.FullName)
        : [
          {
            recipient: assigned_to_user,
            recipientEmail: assigned_to_user_email
          }
        ].filter(r => r.recipient !== this._auth.user.FullName);
        const notificationObject: NotificationData = {
          notificationType: NotificationTypes.JOB_ASSIGNMENT_MADE,
          notificationObject: {
            title: this.title,
            assigned_to_user,
            assigned_to_user_email,
            published_by_user,
            published_by_user_email,
            front_office_id: this.front_office_id,
            customer_name: this.customer_name,
            allbirds_job_id: this.jobData.allbirds_metadata.allbirds_job_id,
            executing_action_user: this._auth.user.FullName,
            executing_action_user_email: this._auth.user.EmailAddr,
            user: {...this._auth.user},
            emailRecipients,
            vms_req_id: this.jobData.allbirds_metadata.vms_req_id,
            hiringManager: this.jobData.hiringManager
          }
        };
        const sub = this._notificationService
          .sendNotification(notificationObject)
          .subscribe(() => {
            sub.unsubscribe();
          });
      }
    }
  }

  confirmRoles() {
    if (this.jobOwnerChange || this.assigneeChange || this.collabUsersChange || this.interviewerUsersChange) {
      this.updateRoles();
      this.saving = true;
    } else {
      this._bsModalRef.hide();
    }
  }

  detectRoleChanges() {
    const currentCollabUsers: any = {};
    const newCollabUsers: any = {};
    const currentInterviewerUsers: any = {};
    const newInterviewerUsers: any = {};

    if (this.publishedByUser
      && this.jobData.allbirds_metadata.published_by_user_back_office_id !== this.publishedByUser.backOfficeId) {
      this.jobOwnerChange = true;
    } else {
      this.jobOwnerChange = false;
    }

    if (this.assignedToUser
      && this.jobData.allbirds_metadata.assigned_to_user_back_office_id !== this.assignedToUser.backOfficeId) {
      this.assigneeChange = true;
    } else {
      this.assigneeChange = false;
    }

    if (this.jobData.allbirds_metadata.job_collaborators.length !== this.collabUsers.length) {
      this.collabUsersChange = true;
      return;
    }
    this.jobData.allbirds_metadata.job_collaborators.forEach((c: any) => { currentCollabUsers[c.user_name] = c.user_email });
    this.collabUsers.forEach(c => { newCollabUsers[c.user_name] = c.user_email });
    for(let user in newCollabUsers) {
      if (!(user in currentCollabUsers)) {
        this.collabUsersChange = true;
        break;
      } else {
        this.collabUsersChange = false;
      }
    }

    if (this.jobData.allbirds_metadata.job_interviewer.length !== this.interviewerUsers.length) {
      this.interviewerUsersChange = true;
      return;
    }
    this.jobData.allbirds_metadata.job_interviewer.forEach((c: any) => { currentInterviewerUsers[c.user_name] = c.user_email });
    this.interviewerUsers.forEach(c => { newInterviewerUsers[c.user_name] = c.user_email });

    for(let user in newInterviewerUsers) {
      if (!(user in currentInterviewerUsers)) {
        this.interviewerUsersChange = true;
        break;
      } else {
        this.interviewerUsersChange = false;
      }
    }
  }

  isFirstAssignment = (formJob: any): boolean => {
    const assigned_to_user = (formJob.assigned_to_user || '').trim();
    return (
      (!assigned_to_user && formJob.assigned_to_user_flag === false) || // new job F-A =first assignment
      (assigned_to_user && formJob.assigned_to_user_flag === false) || // new job but assignment failed /F-A
      (!assigned_to_user && formJob.assigned_to_user_flag === null) // old job not assigned /F-A
    );
  };

}
