
export interface EmailInput {
    email: string;
    name: string;
}
export interface EmailIssueInfo {
    email: string;
    name: string;
    issue: string;
}
export interface EmailPreference {
    _id: string;
    markedspam: boolean;
    optout_j : boolean;
}

export interface EmailPreferences {
    emailPreferences: EmailPreference[];

}

export enum EMAIL_ISSUE {
    NO_EMAIL = 'Talent doesn\'t have email',
    DIFFERENT_LOB = 'Talent doesn\'t belong to your line of business',
    OPT_OUT = 'Talent opted out of recruiter communications about potential job openings',
    MARKED_SPAM = 'Talent marked email as spam',
    INVALID_PROFILE = 'Talent doesnt have a profile name (invalid data)',
    INVALID_PHONE = 'Talent doesnt have valid phone number',
    NOT_OPT_IN_TEXT = 'Talent opted out of sms/text messaging',
    HAS_DELETED_PROFILE = 'This users profile has been merged into the RFO system',
    APPOINTMENT_EXIST = 'Talent has an active appointment',
    INVALID_EMAIL='Talent doesnt have valid email address',
    DISQUALIFIED_USER = 'Please contact HR support to unlock this profile'
}

