<div class="modal-header">
    <h2>Upload new file</h2>
    <button type="button" class="btn-reset" aria-label="Close" (click)="closeModal()">
      <i class="icon-WL_icon_close"></i>
    </button>
  </div>
  
  <div class="modal-body">

      <div>What type of file would you like to add to the talent profile?</div>
      
    <ul class="m-4">
        <li (click)="openAttachmentModal('resume')">
            <div class="d-flex justify-content-between p-2">
                <div>Upload Resume(s)</div>
                <i class="icon icon-WL_icon_chevron-right"></i>
            </div>
            <hr>
        </li>
        <li (click)="openAttachmentModal('file')" >
            <div class="d-flex justify-content-between p-2">
                <div>Upload file(s)</div>
                <i class="icon icon-WL_icon_chevron-right"></i>
            </div>
            <hr>
        </li>
        <li (click)="openAttachmentModal('link')">
            <div class="d-flex justify-content-between p-2">
                <div>Add link(s)</div>
                <i class="icon icon-WL_icon_chevron-right"></i>
            </div>
            <hr>
        </li>
    </ul>
  
  </div>
  
  <div id="assign-job-footer" class="modal-footer" (click)="closeModal()">
    <button class="btn rds--button--primary" >cancel</button>
  </div>
  