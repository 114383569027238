import { Job } from '../external/job.model';

export class Card {
  public header: boolean;
  public job_order: Job;
  public published_by_user_name: string;
  public published_by_initials: string;
  public cardType: string;
  public selected = false;
  public order_created = false;
  public label?: string;

  constructor(
    job_order: any,
    header = false,
    cardType: CardType = CardType.JOB_DASHBOARD
  ) {
    this.job_order = job_order;
    this.header = header;
    this.cardType = cardType;
    if (job_order && job_order.allbirds_metadata) {
      this.order_created = job_order.allbirds_metadata.order_created;
      const { initials, name } = this.getInitialsFromEmail(job_order.allbirds_metadata.published_by_user_email);
      this.published_by_initials = initials;
      this.published_by_user_name = name;
    }
  }

  getInitialsFromEmail(email: string): any {
    if (!email) { return { initials: '', name: '' }; }
    const splitArr = email.split('.');
    if (splitArr[0] && splitArr[1]) {
      return {
        initials: splitArr[0][0].toUpperCase() + splitArr[1][0].toUpperCase(),
        name: splitArr[0] + ' ' + splitArr[1].split('@')[0]
      };
    }

    return { initials: '', name: '' };
  }
}

export enum CardType {
  JOB_DASHBOARD = 'JOB_DASHBOARD',
  JOB_SEARCH = 'JOB_SEARCH',
  TALENT_ADD_TO_JOB = 'TALENT_ADD_TO_JOB'
}
