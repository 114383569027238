<div dropdown [container]="container" [style.margin-bottom]="view === 'list-view' ? '10px' : '0px'">
  <div id="button-basic" dropdownToggle aria-controls="dropdown-basic">
    <div class="verticalEll">
      &#8942;
    </div>
  </div>

  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic" [ngClass]="{'rightAligned': alignment === 'right'}">

    <li role="menuitem"><a class="dropdown-item" (click)="manageAdBtnClick()"data-puppeteer="manage-ad">{{'job-details-header.manage_ad' | translate}}</a></li>
    <li role="menuitem"><a class="dropdown-item" (click)="editBtnClick()" data-puppeteer="edit-job">{{'job-details-header.edit_job' | translate}}</a></li>
    <li role="menuitem"*ngIf="viewCloneJobLink" ><a class="dropdown-item" (click)="checkCreditFlagOnClone()" data-puppeteer="clone-job">{{'job-details-header.clone_this_job' | translate}}</a></li>
    <li role="menuitem"><a class="dropdown-item" (click)="manageRolesBtnClick()">Manage Roles</a></li>
    <li role="menuitem" *ngIf="isRt"><a class="dropdown-item" (click)="closeJobBtnClick()">
      {{(isClosed ? 'job-details-header.update_close_job' : 'job-details-header.close_this_job') | translate}}
    </a></li>
    <li role="menuitem" *ngIf="isRt"><a class="dropdown-item" (click)="extendJobBtnClick()">{{'job-details-header.extend_reopen' | translate}}</a></li>
    <li role="menuitem"><a class="dropdown-item" (click)="sendEmailBtnClick()">{{'job-details-header.send_email' | translate}}</a></li>
    <li role="menuitem" *ngIf="isRt"><a class="dropdown-item" (click)="openUpdateJobStatusModal()">{{'job-details-header.update_job_status' | translate}}</a></li>
  </ul>
</div>
