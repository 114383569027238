import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastService } from '../../services/toast';

@Component({
  selector: 'app-attachment-list',
  templateUrl: './attachment-list.component.html',
  styleUrls: ['./attachment-list.component.scss']
})
export class AttachmentListComponent implements OnInit {

  @Input() attachment: any;
  @Input() index: number;
  @Output() deleteAttachment = new EventEmitter<any>();

  constructor(
    private _toast: ToastService,
    ) { }

  ngOnInit(): void {}

  deleteSelectedAttachment() {
    this.deleteAttachment.emit('delete');
  }
    
}
