import { Component, OnInit } from '@angular/core';
import { PlatformAlertService } from '../../services/platform-alert/platform-alert.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BannerLearnMoreModalComponent } from '../banner-learn-more-modal/banner-learn-more-modal.component';
import { PlatformAlert } from '../../models/internal/platform-alert.model';

@Component({
  selector: 'platform-alert-banner',
  templateUrl: './platform-alert-banner.component.html',
  styleUrls: ['./platform-alert-banner.component.scss']
})
export class PlatformAlertBannerComponent implements OnInit {
  message = '';
  title = '';
  explanation = '';
  learnMore = false;
  showAlertBanner = false;

  bsModalRef: BsModalRef;

  constructor(
    private _platformAlert: PlatformAlertService,
    private _modalService: BsModalService) { }

  ngOnInit() {
    this._platformAlert.alertObservable$
      .subscribe((data: PlatformAlert) => {
        if (data === null) {
          this.showAlertBanner = false;
        } else {
          this.message = data.message;
          this.learnMore = data.learnMore;
          this.title = data.title;
          this.explanation = data.explanation;
          this.showAlertBanner = true;
          this.moveUsabillaAboveBanner();
        }
      });
  }

  moveUsabillaAboveBanner(): void {
    const usabillaElement = document.querySelector('.usabilla_live_button_container');

    usabillaElement ? usabillaElement.classList.add('usabilla-margin-bottom') : this.listenForUsabillaToLoad();
  }

  listenForUsabillaToLoad(): void {
    const MY_CLASS_NAME = 'usabilla_live_button_container'; // Usabilla Feedback button has this class
    let observer = new MutationObserver(mutations => {
      for (let i = 0; i < mutations.length; i++) {
        for (let j = 0; j < mutations[i].addedNodes.length; j++) {
          // We're iterating through _all_ the elements as the parser parses them,
          // deciding if they're the one we're looking for.
          if ((mutations[i].addedNodes[j] as Element).className === MY_CLASS_NAME) {
            document.querySelector('.usabilla_live_button_container').classList.add('usabilla-margin-bottom');

            // We found our element, we're done:
            observer.disconnect();
          }
        }
      }
    });

    observer.observe(document.documentElement, {
      childList: true,
      subtree: true
    });
  }

  openNewListModal(): void {
    const initialState = {
      title: this.title,
      explanation: this.explanation
    };

    this.bsModalRef = this._modalService.show(BannerLearnMoreModalComponent, { initialState });
    this.bsModalRef.setClass('modal-sm');
  }
}
