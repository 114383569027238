<div class="modal-header flex-row">
  <div class="modal-header-text">
    Schedule an appointment
  </div>
  <button class="btn btn-reset" (click)="bsModalRef.hide()">
    <i class="icon-WL_icon_close"></i>
  </button>
</div>
<div class="modal-body">
  <div class="titleHeader flex-row">
    <button
      *ngIf="!isStepOne"
      class="btn back-btn"
      title="Back to step one"
      (click)="onContinueBackClick()">
      <i class="material-icons">keyboard_backspace</i>
    </button>
    <i *ngIf="stepOneComplete && !isStepOne" class="material-icons">check</i>
    <h2 *ngIf="isStepOne" class="header">1/2 Appointment settings - <span>2/2 Invitation e-mail</span></h2>
    <h2 *ngIf="!isStepOne" class="header"><span>1/2 Appointment settings</span> - 2/2 Invitation e-mail</h2>
  </div>
  <ng-container *ngTemplateOutlet="isStepOne ? stepOne : stepTwo"></ng-container>
</div>
<div class="modal-footer flex-row">
  <button
    class="btn rds--button--primary"
    (click)="bsModalRef.hide()">
    cancel
  </button>
  <button
    *ngIf="isStepOne"
    class="btn btn-primary"
    (click)="onContinueBackClick()">
    continue
  </button>
  <button
    *ngIf="!isStepOne"
    class="btn btn-primary"
    (click)="onSendClick()">
    send
  </button>
</div>

<ng-template #stepOne>
  <div class="parentFormGroup flex-column" [formGroup]="parentForm">
    <div class="recipients-list">
      <label class="recipientLabel" for="recipientList">
        {{'Selected talent (' + selectedTalent.length + ')'}}
      </label>
      <app-email-name-multi-input
        id="recipientList"
        label="Selected talent"
        [control]="parentForm.get('emailRecipients')"
        [canChangeInput]="false"
        [showPills]="true"
        [handleValidation]="true"
        [selectedProfiles]="selectedTalent"
        [emails]="recipientEmails"
        [operationContext]="operationContext" >
      </app-email-name-multi-input>
    </div>
    <app-appointment-settings
      [apptSettingsForm]="parentForm.controls.apptSettingsForm">
    </app-appointment-settings>
  </div>
</ng-template>

<ng-template #stepTwo>
  <app-appointment-email
    [emailMessageForm]="parentForm.controls.emailMessageForm">
  </app-appointment-email>
</ng-template>
