<label>{{title}}</label>
<div class="row">
  <div class="col-lg-7">
    <ng-template #popTemplate>
      <timepicker 
        removeMeridian
        [(ngModel)]="modelAsDate"
        (click)="onChange($event)"
        (change)="onChange($event)"
        [ngModelOptions]="{standalone: true}"
        [minuteStep]="30"
        [showMeridian]="true">
      </timepicker>
    </ng-template>
    <input type="text" mdInput [readonly]="true" [ngModel]="modelAsDate | date: 'h:mm' " [popover]="popTemplate"
      class="form-control" [outsideClick]="true" placement="bottom"  />
  </div>
  <div class="col-lg-3">
    <div class="btn-group" btnRadioGroup>
      <label btnRadio="AM" class="btn btn-primary meridianBtn" (click)="changeMeridian(model,'AM')"
        [ngClass]="{'selected': (modelAsDate | date: 'a') === 'AM'}" tabindex="0" role="button">AM</label>
      <label btnRadio="PM" class="btn btn-primary meridianBtn" (click)="changeMeridian(model,'PM')"
        [ngClass]="{'selected': (modelAsDate | date: 'a') === 'PM'}" tabindex="0" role="button">PM</label>
      </div>
  </div>
</div>