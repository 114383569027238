import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormValidationService } from '../form-validation/form-validation.service';
import { JobUrlService } from '../job-url/job-url.service';
import { defaultJob, Job } from '../../models/external/job.model';
import { JobPrompt } from '../../models/external/job-prompt.model';
import { ApiService } from '../api/api.service';
import { JobForm } from '../../models/external/job-form.model';
import { InterviewScheduleInformation } from '../../models/external/misc.model';
import { UtilityService } from '../utility/utility.service';
import moment from 'moment';
import { ActiveStatus, ClosedStatus } from '../../models/internal/process-statuses.interface';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from "../../services/auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class JobOrderService {
  private jobOrderSource = new BehaviorSubject<Job>(defaultJob.clone());
  jobOrderObservable = this.jobOrderSource.asObservable();
  private jobPromptSource = new BehaviorSubject<JobPrompt[]>([]);
  jobPromptObservable = this.jobPromptSource.asObservable();
  public customerChanged$: EventEmitter<any>;

  private jobOrderFormObj: FormGroup;

  interviewScheduled: number = 0;

  get jobOrderForm() { return this.jobOrderFormObj; }

  get storedJobOrder() { return this.jobOrderSource.getValue(); }

  constructor(
    private translate: TranslateService,
    private _api: ApiService,
    private _formBuilder: FormBuilder,
    private jobUrlService: JobUrlService,
    private utilityService: UtilityService,
    private _auth: AuthService
  ) {
    // default values
    this.customerChanged$ = new EventEmitter();
  }

  storeJobDetails(job: Job) {
    this.jobOrderSource.next(job);
  }

  resetDefaultJob() {
    this.jobOrderSource.next(defaultJob.clone());
  }

  storeJobPrompts(jobPrompts: JobPrompt[]) {
    this.jobPromptSource.next(null);
    this.jobPromptSource.next(jobPrompts);
  }

  public getStoredJobPrompts() {
    return this.jobPromptSource.getValue();
  }

  /***********************************************
   * Form methods.
   * *********************************************
   */
  createForm() {
    // reset form
    if (this.jobOrderFormObj) {
      this.jobOrderFormObj.reset(this.jobOrderFormObj.value);
    }
    // create form
    this.jobOrderFormObj = this._formBuilder.group({
      // Required.
      number_of_openings: [null, Validators.compose([Validators.required, FormValidationService.validateZero, FormValidationService.validateNumOfOpenings])],
      jobStartTime: [null, [Validators.required]],
      jobEndTime: [null, [Validators.required]],
      education_requirement: [null, [Validators.required]],
      service_line: [null, [Validators.required]],
      sub_service_line: [null, [Validators.required]],
      salary_type: [null, [Validators.required]],
      published_min_pay_rate: [null, Validators.compose([Validators.required, FormValidationService.validateZero])],
      published_max_pay_rate: [null, Validators.compose([Validators.required, FormValidationService.validateZero])],
      will_relocate: [null, [FormValidationService.validateNull]],
      allow_remote_work: [null, [Validators.required]],
      travel_requirements: [null, [Validators.required]],
      minimum_years_of_experience: [null, Validators.compose([Validators.required, FormValidationService.validateYearsOfExp])],
      experience_level: [null, [Validators.required]],
      job_category: [null, [Validators.required]],
      internal_job_title: [null, [Validators.required]],
      internal_job_code: [null, []],
      interview_info: [null, [Validators.required]],
      interview_info_rt_perm: [null, []],
      bill_to_location: [null, [Validators.required]],
      bill_to_address_seq: [null, []],
      skills: [[], []],
      shifts: [null, []],
      title: [null, []],
      compelling_intro: [null, Validators.compose([Validators.required, FormValidationService.validateRestricted])],
      internalTitle: [null, [Validators.required]],
      order_status: [null, [Validators.required]],
      order_source: [null, [Validators.required]],
      employmentTypes: [null, [Validators.required]],
      responsibilities: [null, Validators.compose([Validators.required, FormValidationService.validateRestricted])],
      work_hours_start: [null, Validators.compose([Validators.required, FormValidationService.validateNull])],
      work_hours_end: [null, Validators.compose([Validators.required, FormValidationService.validateNull])],
      weekly_hours: [null, [Validators.required]],
      contact_id: [null, []],
      contact_address1: [null, []],
      customer_id: [null, []],
      customer_industry: [null, []],
      customer_name: [null, [Validators.required]],
      customer_priority: [null, Validators.compose([Validators.required, FormValidationService.validateZero])],
      hiringManager: [null, [Validators.required]],
      company: [null],
      internalDescription: [null, [Validators.required]],
      // conditionally required
      bill_rate: [null, []],
      internal_max_pay_rate: [null, []],
      target_salary: [null, []],
      mark_up: [null, []],
      perm_fee: [null, []],
      calculated_gp: [null, []],
      // Non-required.
      order_created: [null, []],
      assigned_to_user_flag: [null, []],
      allbirds_job_id: [null, []],
      will_sponsor_visa: [null, []],
      published_status: [null, []],
      vms_req_id: [null, []],
      internal_notes: [null, []],
      exempt: [null, []],
      allow_dbc_candidates: [null, []],
      allow_third_party_vendors: [null, []],
      allow_independent_contractors: [null, []],
      exclusive_req: [null, []],
      submission_deadline: [null, []],
      client_interview_date: [null, []],
      education_must_have: [null, []],
      rev_share_flag: [null, []],
      publish_immediately: [null, []],
      placements: [null, []],
      draft_update_date: [null, []],
      draft_creation_date: [null, []],
      google_job_id: [null, []],
      type_of_work_environment: [null, []],
      weekend_work_required: [null, []],
      expected_overtime_hours: [null, []],
      heavy_lifting: [null, []],
      heavy_lifting_weight: [null, []],
      consistently_standing: [null, []],
      consistently_standing_hours: [null, []],
      temperature_controlled: [null, []],
      smoke_free_environment: [null, []],
      steel_toe_shoes_required: [null, []],
      work_location: [null, [Validators.required]],
      work_address: [null, [Validators.required]],
      work_zip: [null, Validators.compose([Validators.required, FormValidationService.validateZip])],
      work_city: [null, [Validators.required]],
      work_state: [null, [Validators.required]],
      work_country: [null, []],
      work_address_seq: [null, []],
      allow_auto_scheduler: [null, []],
      max_auto_schedules_allowed: [null, []],
      auto_scheduler_interview_channel: [null, []],
      auto_scheduler_location: [null, []],
      unpublished_by_user: [null, []],
      unpublished_by_user_email: [null, []],
      unpublished_by_user_back_office_id: [null, []],
      unpublished_by_user_front_office_id: [null, []],
      unpublish_date: [null, []],
      front_office_id: [null, []],
      published_by_user_back_office_id: [null, []],
      published_by_user_front_office_id: [null, []],
      published_by_user_email: [null, []],
      published_by_user: [null, []],
      user_branch_id: [null, []],
      assigned_to_user_back_office_id: [null, []],
      assigned_to_user_front_office_id: [null, []],
      assigned_to_user: [null, []],
      assigned_to_user_email: [null, []],
      job_expire_date: [null, []],
      lob: [null, []],
      job_collaborators: [null, []],
      vms_created_job: [null, []],
      nso_strategic_job: [null, []],
      nso_hub: [null, []],
      attachments: [null, []],
      interview_duration: [null,[]],
      job_interviewer: [null,[]],
      job_extension_counter: [null,[]],
      drug_screening: [null, []],
      order_type: [null, []],
      pipeline_id: [null, []],
      number_of_linked_jobs: [null, []],
      linked_ab_job_id: [null, []],
      order_of_linked_job: [null,[]],
      client_partner: [null, [Validators.required]],
      client_partner_id: [null, [Validators.required]]
    });
  }

  getJobForForm(id: string): any {
    return new Promise<void>((resolve, reject) => {
      this._api.getJob(id, true)
        .subscribe(jobForm => {
          const formattedRes = this.formatJobResponse(jobForm);

          if (!formattedRes.service_line) {
            formattedRes.service_line = null;
          }
          if (!formattedRes.sub_service_line) {
            formattedRes.sub_service_line = null;
          }

          this.jobOrderFormObj.setValue(formattedRes);
          resolve();
        }, err => {
          console.error(err);
          reject(err);
        });
    });
  }

  formatJobResponse(jobForm: JobForm): JobForm {
    return jobForm.clone().apply({
      work_location: this.constructAddress(jobForm)
    });
  }

  constructAddress(jobForm: JobForm): string {
    const { work_address, work_city, work_state, work_zip } = jobForm;
    return [work_address, work_city, work_state, work_zip].map(v => v && v.trim()).filter(v => v).join(', ');
  }

  // this check if customer has changed and emits an event for any component that needs to change values depending on that
  checkCustomerChange(fieldName: string, fieldValue: any) {
    if (fieldName === 'customer_id') {
      this.customerChanged$.emit(fieldValue);
    }
  }

  // NOTE: DECIDE IF WE STILL NEED THESE 2 methods
  adjustTimesForUTC(jobObject: any) {
    /*
    * The system saves times in UTC.  Browser side JS automatically changes the
    * time to UTC when posting.  The browser operates on dates in the timezone
    * that the system thinks it's in.  This function takes the current hours
    * and shifts it to match the current UTC so that when the browser automatically
    * changes the time to UTC the time stored on in the DB matches the time
    * displayed in the browser.
    */
    if (jobObject.work_hours_start) {
      jobObject.work_hours_start.setUTCHours(jobObject.work_hours_start.hours);
    }
    if (jobObject.work_hours_start) {
      jobObject.work_hours_end.setUTCHours(jobObject.work_hours_end.hours);
    }
    return jobObject;
  }

  adjustTimesForLocal(jobObject: any) {
    /*
    * The system saves times in UTC.  Browser side JS automatically changes the
    * time to local TZ when rendering.  The browser operates on dates in the timezone
    * that the system thinks it's in.  This function takes the current hours
    * and shifts it to match the current Local time zone so that when the browser
    * automatically changes the time from UTC the time stored on in the DB
    * matches the time displayed in the browser.
    */
    if (jobObject.work_hours_start) {
      jobObject.work_hours_start.setUTCHours(jobObject.work_hours_start.getUTCHours());
    }
    if (jobObject.work_hours_end) {
      jobObject.work_hours_end.setUTCHours(jobObject.work_hours_end.getUTCHours());
    }
    return jobObject;
  }

  publishedStatus(job: Job) { // logic has changed that decides if job has published or not
    const allbirds_metadata = job.allbirds_metadata;
    let finalCondition = allbirds_metadata.order_created
      && allbirds_metadata.published_status
      && !this.isJobExpired(job); // should not be expired

    if (!job.allbirds_metadata.lob.includes('RGS')) { // if it is not RGS add additional conditions
      finalCondition = finalCondition && (!ClosedStatus.includes(allbirds_metadata.order_status)) // should not be any closed status
        // Updated to not include 'On Hold' per DF044-6453
        // && allbirds_metadata.order_status !== 'On Hold')// should not be on hold
    }
    return finalCondition;
  }

  isJobExpired(job: Job) {
    const expire_date = job.expirationDate;
    return expire_date ? expire_date.isBefore(moment()) : false;
  }

  isJobTimerOn(job: Job) { // DF044-2506
    const publishedStatus = this.publishedStatus(job);
    const allbirds_metadata = job.allbirds_metadata;
    return publishedStatus || (['Placed', 'On Hold'].includes(allbirds_metadata['order_status']));
  }

  jobTimerText(job: Job, format: string) { // in order to understand the logic here please check ticket DF044-2506
    if (!job.hasExpireDate) {
      return this.translate.instant('job-details-header.unassigned');
    }
    const currentTime = moment();
    const expire_date = job.expirationDate.clone();
    const timeDiffInHours = expire_date.diff(currentTime, 'h');
    const timeDiffInDays = expire_date.diff(currentTime, 'd');
    const dayText = timeDiffInDays > 1 ? 'days' : 'day';
    if (timeDiffInHours > 23 && this.publishedStatus(job)) { // timer is more than a day
      return `${expire_date.diff(currentTime, 'd')} ${dayText} left`;
    } else if (this.publishedStatus(job)) { // timer is less than a day
      const diffInH = expire_date.diff(currentTime, 'h');
      const diffInM = expire_date.subtract(diffInH, 'hours').diff(currentTime, 'minutes');
      if (format.split(' ').length > 1) {
        return `${diffInH}h  ${diffInM}m`;
      }
      return `${diffInH}h`;
    } else { // job has expired or publish status is false
      const order_status = job.allbirds_metadata.order_status;
      if (ClosedStatus.includes(job.allbirds_metadata.order_status)) {
        return `0 day left`;
      }

      if ((ActiveStatus.includes(order_status)) && !this.isJobExpired(job)) {
        return `${expire_date.diff(currentTime, 'd')} ${dayText} left`;
      }

      if (!job.allbirds_metadata.publish_immediately && !this.isJobExpired(job)) {
        return job.allbirds_metadata.lob.includes('RGS') ? 'N/A' : `${expire_date.diff(currentTime, 'd')} ${dayText} left`;
      }

      if (!job.allbirds_metadata.publish_immediately) {
        return job.allbirds_metadata.lob.includes('RGS') ? 'N/A' : `0 day left`;
      }

      return '0 day left';
    }
  }

  getInterviewObject(job: any, user: any): InterviewScheduleInformation {
    const recruiterEmail = String(job.allbirds_metadata.lob).checkLob('RT', 'RE') ?
      // if it is an rt job, send email as assigned_to if it exists
      (job.allbirds_metadata.assigned_to_user_email ? job.allbirds_metadata.assigned_to_user_email : null)
      :
      // otherwise if rgs, send email as published_user (if it exists, otherwise send current user email).
      (job.allbirds_metadata.published_by_user_email ? job.allbirds_metadata.published_by_user_email : user.EmailAddr);

    const interviewerEmails = job.allbirds_metadata.job_interviewer
      ? job.allbirds_metadata.job_interviewer.filter((x: any) => x.user_email).map((interviewer:any) => interviewer.user_email)
      : [];

    return {
      jobReference: job.name,
      interviewScheduling: {
        isSchedulable: job.allbirds_metadata.allow_auto_scheduler,
        maxAllowedSlots: job.allbirds_metadata.max_auto_schedules_allowed,
        interviewOptions: job.allbirds_metadata.auto_scheduler_interview_channel,
        location: <any>{
          addr1: job.allbirds_metadata.auto_scheduler_location
        },
        clientNameId: job.allbirds_metadata.customer_name,
        abJobUrl: this.jobUrlService.getJobUrl(job.allbirds_metadata.allbirds_job_id),
        recruiterEmail: recruiterEmail,
        interviewDuration: job.allbirds_metadata.interview_duration || 30,
        interviewCollaborators: interviewerEmails
      }
    };
  }

  isJobAssigned(job: Job) {
    let isAssigned = false;
    if (job && job.allbirds_metadata.assigned_to_user && job.allbirds_metadata.assigned_to_user_front_office_id && job.allbirds_metadata.assigned_to_user_back_office_id) {
      isAssigned = true;
    }
    return isAssigned;
  }

  checkChatbotDisabled(job: Job, checkPublishedExpired?: boolean): { disabled: boolean, hidden: boolean, noRecruiter: boolean, expired: boolean, notPublished: boolean, msg: string } {
    const chatbotDisabled = { disabled: false, hidden: false, noRecruiter: false, expired: false, notPublished: false, msg: '' };
    if (job) {
      const { lob } = job.allbirds_metadata;
      // MODIFIED FOR DF044-5793
      if (lob.checkLob('RT', 'RE','CR')) {
        if (((!this.isJobAssigned(job) && !job.hasExpireDate) || !this.isJobAssigned(job)) && lob.checkLob('RT','RE')) {
          chatbotDisabled.disabled = true;
          chatbotDisabled.noRecruiter = true;
          chatbotDisabled.msg = 'Disabled because recruiter has not been assigned';
        // } else if (this.isJobAssigned(job) && job.expirationDate === null) {
        //   job.allbirds_metadata.job_expire_date = moment().add(15, 'days');
        //   job.allbirds_metadata.order_status = 'Accepting Candidates';
        } else if (lob.checkLob('CR') || this._auth.user.Source == 'CR'){
          chatbotDisabled.disabled = true;
          chatbotDisabled.hidden = true;
          chatbotDisabled.msg = 'You cannot send an invite as the user/job is from Cella line of business';
        }

      }
      if (lob == 'CR') {
          chatbotDisabled.disabled = true;
          chatbotDisabled.hidden = true;
          chatbotDisabled.msg = 'You cannot send an invite as the user/job is from Cella line of business';
      }


      if (checkPublishedExpired && !lob.checkLob('CR') ) {
        if (!this.publishedStatus(job)) {
          chatbotDisabled.disabled = true;
          chatbotDisabled.notPublished = true;
          chatbotDisabled.msg = 'Disabled because job has not been published';
        }
        if (this.isJobExpired(job) && !lob.checkLob('CR')) {
          chatbotDisabled.disabled = true;
          chatbotDisabled.expired = true;
          chatbotDisabled.msg = 'Disabled because job has expired';
        }
      }
    }
    return chatbotDisabled;
  }

  /**
   * Returns true if the job is a solutions job.
   * @param job - AB job order
   */
  isSolutionsJob (job: Job) {
    const employmentType = job && job.employmentTypes && job.employmentTypes.toString().toLowerCase() || '';
    return employmentType === 'solutions';
  }

}
