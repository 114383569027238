import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DEFAULT_CONFIG, ToastClass, ToastPosition } from './toast.config';
import { TranslateService } from '@ngx-translate/core';

export interface ToastLink {
  routerLink?: any[] | string;
  text: string;
  action?: Function;
}

export interface ToastConfig {
  cssClass?: ToastClass;
  duration?: number;
  position?: ToastPosition;
  showIcon?: boolean;
  actionLinks?: ToastLink[];
  image?: string;
}

export interface Toast extends ToastConfig {
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private toastEventSubject = new Subject<Toast>();
  public toastEvent = this.toastEventSubject.asObservable();

  constructor(private translate: TranslateService) { }

  showToast(messageKey: string, config?: ToastConfig) {
    const translatedString = this.translate.instant(messageKey);
    this.showToastNoTranslation(translatedString, config);
  }

  showToastNoTranslation(message: string, config?: ToastConfig) {
    const toastObj: Toast = {
      ...DEFAULT_CONFIG,
      ...config,
      message: message
    };
    this.toastEventSubject.next(toastObj);
  }

  showToastWithVariables(messageKey: string, variableMap: any, config?: ToastConfig) {
    const translatedString = this.translate.instant(messageKey, variableMap);
    this.showToastNoTranslation(translatedString, config);
  }
}
