<div class="modal-header">
    <button type="button" class="close-btn" aria-label="Close" (click)="closeDialog()">
        <i class="icon-WL_icon_close"></i>
    </button>
</div>

<div class="modal-body">
    <h2><span><img src="../../../../../../assets/icons/alert-yellow.svg" alt="alert"  /></span>{{'remove-from-list-modal.remove_candidate_from' | translate}}</h2>
    <p>{{'remove-from-list-modal.are_you' | translate: trVariable1}}</p>
</div>

<div id="assign-job-footer" class="modal-footer">
    <button class="btn rds--button--primary" (click)="closeDialog()">{{'remove-from-list-modal.cancel' | translate}}</button>
    <button class="btn btn-primary" (click)="removeFromList()">{{'remove-from-list-modal.remove' | translate}}</button>
</div>
