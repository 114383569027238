import { Lists } from '@allbirds-ui/allbirds-types';
import { createModel, Mapping, Model } from './model';
import { ListTalent } from './list-talent.model';
import { IsoToMoment } from './serializers/moment.serializer';
import { Moment } from 'moment';
import { Serial } from './decorators/serial.decorator';
export import RawList = Lists.List;

class ListMapping extends Mapping<RawList> {
  @Serial([ListTalent]) talent: ListTalent[];
  @Serial(IsoToMoment) creation_date: Moment;
  @Serial(IsoToMoment) last_update_date: Moment;
  tooltip?: string;
  listStatus?: any;
}

export interface List extends Model<ListMapping> {}

export const List = createModel<List>(ListMapping);
