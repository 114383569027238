import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {
  SUBMISSION_SOURCE_TYPES,
  SubmissionDetails
} from 'src/app/shared/components/talent-detail-pane/talent-process/talent-process-modals/submit-to-am-modal/submit-to-am.types';
import {ToastClass, ToastService} from 'src/app/shared/services/toast';
import {LoadingSpinnerService} from '../../../../../services/loading-spinner/loading-spinner.service';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { UrlStateService } from 'src/app/shared/services/url-state/url-state.service';
import { AuthService } from '../../../../../services/auth/auth.service';
import moment from 'moment';
import {TalentProcessService} from '../../../../../services/talent-process/talent-process.service';

@Component({
  selector: 'app-submit-to-am-modal',
  templateUrl: './submit-to-am-modal.component.html',
  styleUrls: ['./submit-to-am-modal.component.scss']
})
export class SubmitToAmModalComponent implements OnInit {
  submitFn: Function;
  candidateFullName: string;
  customer_name: string;
  hiringManager: string;
  title: string;
  jobId: string;
  addedBy: string;
  submitForm: FormGroup;
  submissionSourceVals: any = SUBMISSION_SOURCE_TYPES;
  defaultEmailBody: string;
  submissionDetails: SubmissionDetails;
  payTypeSub: any;
  payRateErrorMsg: string;
  isUploadFormVisible: boolean =  false;
  categories: any[] = [];
  attachments: any[] = []; // files that already exist on talent's profile
  additionalParameters: {};
  allowDuplicate: boolean = false;
  parseResume: boolean = false;
  isTalentCreation: boolean = true;
  initialState: {};
  uploadedAttachments: any[] = []; // files that are uploaded in the modal
  profile: any = {};

  @Output() save = new EventEmitter();

  get uploadURL() { return this._api.uploadURL(); }

  constructor(
    public _bsModalRef: BsModalRef,
    private _formBuilder: FormBuilder,
    private _toastService: ToastService,
    private translate: TranslateService,
    private _loading: LoadingSpinnerService,
    private _api: ApiService,
    private _urlStateService: UrlStateService,
    private _auth: AuthService,
    private _talentProcess: TalentProcessService
  ) { }

  ngOnInit() {
    this.createForm();
    this.getProfileAttachments();
  }

  createForm() {
    const payRate = this.submissionDetails && this.submissionDetails.payRate ? this.submissionDetails.payRate : null;
    const payType = this.submissionDetails && this.submissionDetails.payType ? this.submissionDetails.payType : 'HOURLY';
    const submissionSource = this.submissionDetails && this.submissionDetails.submissionSource ? this.submissionDetails.submissionSource : null;

    this.submitForm = this._formBuilder.group({
      payRate: [payRate, this.getPayRateValidators(payType)],
      payType: [payType, [Validators.required]],
      submissionSource: [submissionSource, [Validators.required, Validators.nullValidator]],
      emailBody: ['', [Validators.required]]
    });

    this.payTypeSub = this.submitForm.controls.payType.valueChanges.subscribe((payType: string) => {
      this.submitForm.controls.payRate.setValidators(this.getPayRateValidators(payType));
      this.submitForm.controls.payRate.updateValueAndValidity();
    });
  }

  /**
   * Gets attachments from talent's profile
   */
  getProfileAttachments(): void {
    // const profileID = `projects/us-abcts-dev-b476/tenants/21997dad-ae6f-433b-a80a-02521f9d0fe4/profiles/${this._urlStateService.getParams().st}`;
    const profile = this._talentProcess?.selectedProfileSubject?.value;
    const profileID = profile?.name;
    this._api.getApplicant({ profileID })
      .toPromise()
      .then(response => {
        const profile = response[0];
        this.profile = profile;
        const attachments = profile.attachments;
        this.attachments = attachments.map(attachment => ({ ...attachment, willSubmit: false}));
      })
      .catch(e => {
        console.error(e);
      });
  }

  getPayRateValidators(payType: string) {
    const validators = [Validators.required];
    if (payType === 'YEARLY') {
      this.payRateErrorMsg = `should be more than $10,000.00`;
      validators.push(Validators.min(10000));
    } else if (payType === 'HOURLY') {
      this.payRateErrorMsg = `should be less than or equal to $999.00`;
      validators.push(Validators.max(999));
    }
    return validators;
  }

  composeDefaultEmail() {
    let emailBody = this.submissionDetails && this.submissionDetails.emailBody ? this.submissionDetails.emailBody : null;
    if (!emailBody) {
      emailBody = `Submission of ${this.candidateFullName} to Job ID ${this.jobId} - ${this.title} for ${this.hiringManager}, ${this.customer_name} has been added by ${this.addedBy}`;
    }
    return emailBody;
  }

  submitFormClick() {
    this._loading.show();
    const {payRate, payType, submissionSource, emailBody} = this.submitForm.controls;

    this.uploadedAttachments = this.setUploadedFileType([...this.uploadedAttachments]);
    const submittableInternalFiles = this.checkFilesToSubmit(this.attachments);
    const submittableUploadedFiles = this.checkFilesToSubmit(this.uploadedAttachments);
    this.profile.attachments = this.checkFilesToSave();
    this.profile.userLob = 'RT';
    console.log(this.profile)
    const payload: SubmissionDetails = {
      payRate: payRate.value,
      payType: payType.value,
      submissionSource: submissionSource.value,
      emailBody: emailBody.value,
      files: [...submittableInternalFiles, ...submittableUploadedFiles]
    };

    this.submitForm.updateValueAndValidity();
    if (this.submitFn && this.submitForm.valid && this.isCategorySelected() && this.isResumeAttached(payload.files)) {
      // to be sure we prevent double click on that button
      // todo: remove the setTimeout later

      
      this.profile = this.parseProfile(this.profile)
      if(this.profile.allbirds_metadata?.assignment){
        if(this.profile.allbirds_metadata?.assignments[0]){
          console.log("talent has assignment")
        }
      }

      setTimeout((function() {
        // saves attachments to talent profile before submitting
        this._api.editTalentProfile(this.profile).subscribe((res: any) => {
          console.log('profile edit successful', res);
          this.submitFn(payload);
          this._loading.hide();
          this._bsModalRef.hide();
          this.payTypeSub.unsubscribe();
        },
        (err: any) => {
          console.log('error saving profile', err);
          this._loading.hide();
          this._bsModalRef.hide();
          this.payTypeSub.unsubscribe();
          if(err.error){
            console.log("this is the msg", err.error?.errorMessage)
            if(err.error?.errorMessage == "Invalid date for assignments"){
              this._toastService.showToast('There was an error editing the profile', {cssClass: ToastClass.DANGER})
            }
          }
        });
      }).bind(this), 1000);

    } else if (this.submitForm.invalid) {
      // Form is invalid, don't close the modal and provide a toast message
      this._toastService.showToast('The Submit to Account Manager form is invalid', {cssClass: ToastClass.DANGER});
      this.submitForm.markAllAsTouched();
      this._loading.hide();
    } else if (!this.isResumeAttached(payload.files)) {
      this._toastService.showToast('At least one (1) resume needs to be attached', {cssClass: ToastClass.DANGER});
      this.submitForm.markAllAsTouched();
      this._loading.hide();
    } else if (!this.isCategorySelected()) {
      this._toastService.showToast('Uploaded file(s) missing category', {cssClass: ToastClass.DANGER});
      this.submitForm.markAllAsTouched();
      this._loading.hide();
    }
  }

  showUploadForm(): void {
    this.isUploadFormVisible = true;
    this.categories = [
      { name: 'Formatted with logo' },
      { name: 'Formatted with no logo' },
      { name: 'Application' },
      { name: 'Bill Rate Confirmation' },
      { name: 'Certificate' },
      { name: 'Contract' },
      { name: 'Job Description' },
      { name: 'Reference' },
      { name: 'Right to Represent' },
      { name: 'Test Results' },
      { name: 'Portfolio/Work samples' },
      { name: 'Other' }
    ] ;
    this.initialState = {
      save: this.save,
      categories: this.categories,
      attachments: this.attachments,
      additionalParameters: this.additionalParameters,
      allowDuplicate: this.allowDuplicate,
      uploadURL: this.uploadURL,
      parseResume: this.parseResume,
      isTalentCreation: this.isTalentCreation
    }
  }

  handleWillSubmit(attachment: any): void {
    attachment.willSubmit = !attachment.willSubmit;
  }

  handleAddToUploadedAttachments(item: any) {
    item.attached_by_user = this._auth.user.FullName;
    item.attached_by_user_back_office_id = this._auth.user.BackOfficeID;
    item.attached_by_user_front_office_id = this._auth.user.EmplID;
    item.attached_by_user_email = this._auth.user.EmailAddr;
    item.upload_time = moment().toISOString();
    item.lastUpdatedDateTime = moment().toISOString();
    item.type = 'file';
    this.uploadedAttachments.push(item);
  }

  handleRemoveFromUploadedAttachments(fileIndex: number) {
    this.uploadedAttachments.splice(fileIndex, 1);
  }

  checkFilesToSubmit(files: any[]) {
    return files.filter(file => file?.willSubmit === true);
  }

  checkFilesToSave() {
    let filesToSave = this.uploadedAttachments.filter(file => file?.willSaveToProfile);
    return [...this.attachments, ...filesToSave];
  }

  formatDatetime(datetime: any) {
    return moment(datetime);
  }

  /**
   * Checks if category has been selected for each uploaded file before submitting
   * @returns true if all uploaded files have category selected; false otherwise
   */
  isCategorySelected(): boolean {
    return this.uploadedAttachments.every(attachment => attachment.category);
  }

  /**
   * Checks if resume has been selected before submitting
   * @param attachments - selected files
   * @returns true if at least one selected file is resume; false otherwise
   */
  isResumeAttached(attachments: any[]): boolean {
    return attachments.some((attachment) => {
      return (attachment?.type === 'resume' || attachment?.category === 'Resume')}
      );
  }

  /**
   * Adds type property to uploaded files
   * @param files - files being uploaded
   * @returns array of files with type property added
   */
  setUploadedFileType(files: any): any[] {
    return files.map((file: any) => {
      if (file?.category?.includes('Formatted') || file?.category?.includes('resume') || file?.category?.includes('Other')) {
        return { ...file, type: 'resume' };
      } else {
        return { ...file, type: 'file' };
      }
    });
  }

  parseProfile(profile: any) {
    //Using toJSON method with second param as true so we can get a JSON copy of the profile that contains all the fields (including internal ones)
    if (profile.toJSON) {
        let parsedProfile = profile.toJSON(undefined, { internals: true });
        return parsedProfile 
    } else {
        //in case the profile object does not has the toJSON method, then we parse using parse(stringify) to remove the sirealization issues we were getting
        let parsedProfile  = JSON.parse(JSON.stringify(profile));
        return parsedProfile 
    }
    
}
  // This is done to ensure keys are order in which you see in submit-to-am types file, without this it will push keys with lower case to the bottom of drop down
  preserveOrder(a: any, b: any){
    return a.key
  }
}
