<div class="modal-header" style="justify-content: flex-end">
  <button type="button" class="btn-reset" aria-label="Close" (click)="_bsModalRef.hide()">
    <i class="icon-WL_icon_close"></i>
  </button>
</div>

<div class="modal-body" style="overflow: auto">
  <h2>{{'file-upload-modal.replace_file' | translate}}</h2>
  <div class="drag-box">
    <vertical-align>
      <div class="drag-center-box">
        <img src="assets/icons/attach.svg"  />
        <h3>{{'file-upload-modal.drag_your_file' | translate}}</h3>
        <button class="btn-reset">{{'file-upload-modal.or_select_a' | translate}}</button>
      </div>
    </vertical-align>
    <p class="bottom-msg">{{'file-upload-modal.accepted_formats,' | translate}}</p>
  </div>
</div> <!-- / .modal-body -->

<div class="modal-footer" style="justify-content: flex-end">
  <ul class="hz-btn-list text-right">
    <li>
      <button type="button" class="btn rds--button--primary" aria-label="cancel" (click)="_bsModalRef.hide()">{{'file-upload-modal.cancel' | translate}}</button>
    </li>
  </ul>
</div>