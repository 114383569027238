import {Component, OnDestroy, OnInit} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MULTI_OPTION_MENU, OFFER_OPTION_MENU, SINGLE_OPTION_MENU, NOT_FIT_OPTION_MENU, UPDATE_OPTION_MENU, UPDATE_OPTION_MENU_IH, UPDATE_OPTION_MENU_HM } from './not-fit-model.config';
import { ToastClass, ToastService } from 'src/app/shared/services/toast';
import { TalentProcessService } from 'src/app/shared/services/talent-process/talent-process.service';
import { JobDetailsService } from 'src/app/shared/services/job-details/job-details.service';
import { LoadingSpinnerService } from 'src/app/shared/services/loading-spinner/loading-spinner.service';
import { PaginateService } from 'src/app/shared/services/paginate/paginate.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { Router } from '@angular/router';
import { TalentManagementListingsService } from 'src/app/modules/talent/pages/talent-management-listings/talent-management-listings.service';
import { Application } from 'src/app/shared/models/external/application.model';
import { JobDetailsShortlistService } from '../../../../../services/job-details-shortlist/job-details-shortlist.service';
import { ApplicationRejectionData } from '../../../../../models/internal/application-rejection-data.interface';
import { ShouldWarnUnsaved } from '../../../../../services/modal/modal.service';
import { ProcessStep, ProcessStatus } from 'src/app/shared/models/external/misc.model';
import moment from 'moment';
import { INTG_STEPS } from 'src/app/shared/models/internal/process.model';
import {FormBuilder, FormGroup} from '@angular/forms';
import {NotificationsService} from '../../../../../services/notifications/notifications.service';
import {UtilityService} from '../../../../../services/utility/utility.service';
import {EmailRecipient, NotificationData, NotificationTypes} from '../../../../../models/internal/notifications-data.model';
import {catchError} from 'rxjs/operators';
import {Job} from '../../../../../models/external/job.model';
import {JobOrderService} from '../../../../../services/job-order/job-order.service';
import {Profile} from '../../../../../models/external/profile.model';
import {Subscription} from 'rxjs';
import { ActivityFeedService } from 'src/app/shared/services/activity-feed/activity-feed.service';

@Component({
  selector: 'app-not-fit-modal',
  templateUrl: './not-fit-modal.component.html',
  styleUrls: ['./not-fit-modal.component.scss']
})
export class NotFitModalComponent implements OnInit, OnDestroy, ShouldWarnUnsaved {

  talent: any;
  updateSubmission: boolean = false;
  internalSubmission: boolean = false;
  clientSubmission: boolean = false;
  clientInterview: boolean = false;
  modal: any;
  talentApplication: Application;
  obj: { rejectReason: string, rejectNote: string } = {
    rejectReason: null,
    rejectNote: ''
  };
  initialObj: any;
  showError = false;
  rejectFn: Function;
  menu: any;
  interviewDate: Date;
  statusLabel: string;
  submittedBy: string;

  showingNotAFitReason:boolean = true
  // Different reject functions are called in different contexts.
  context: string;


  hmForm: FormGroup;
  jobOrder: Job;
  profile: Profile;
  profileSub: Subscription;
  datePickerConfig = {
    dateInputFormat: 'MMM/DD/YYYY',
    showWeekNumbers: false
  };
  minDate = moment();
  showErrorDate = false;

  constructor(
    public bsModalRef: BsModalRef,
    private _toast: ToastService,
    private _talentProcess: TalentProcessService,
    private _jobDetails: JobDetailsService,
    private _loading: LoadingSpinnerService,
    private paginate: PaginateService<Application>,
    private auth: AuthService,
    private _router: Router,
    private _talentListings: TalentManagementListingsService,
    private _shortlist: JobDetailsShortlistService,
    private _fb: FormBuilder,
    private _notificationService: NotificationsService,
    private _utility: UtilityService,
    private _jobOrder: JobOrderService,
    private _authService: AuthService,
    private _activityFeed: ActivityFeedService
  ) { }

  ngOnInit() {
    this.jobOrder = this._jobOrder.storedJobOrder;
    this.context = this._router.url.includes('jobs') ? 'JOB_DETAILS' : 'TALENT_MANAGEMENT';
    this.talentApplication = this._talentProcess.selectedApplicationSubject.getValue();
    const isRT = this.auth?.user?.Source.checkLob('RT', 'RE','CR')
    // change rejection drop down based on step
    if (this.talentApplication && this.talentApplication.randstad_process && !this.updateSubmission) {
      if (this.talentApplication.randstad_process.lastProcessStep === ProcessStep.PRESCREENING
        || this.talentApplication.randstad_process.lastProcessStep === ProcessStep.INTERVIEW_RECRUITER
        || this.talentApplication.randstad_process.lastProcessStep === ProcessStep.INTERVIEW_SCHEDULE) {
        this.showingNotAFitReason = false
        this.menu = SINGLE_OPTION_MENU;
        this.obj.rejectReason = SINGLE_OPTION_MENU[0];
      }else if((this.talentApplication.randstad_process.lastProcessStep === ProcessStep.REVIEW_HM
        ||this.talentApplication.randstad_process.lastProcessStep === ProcessStep.INTERVIEW_HM) && !isRT){
          this.menu = SINGLE_OPTION_MENU;
          this.obj.rejectReason = SINGLE_OPTION_MENU[0];
          this.showingNotAFitReason = false
      }else if (this.talentApplication.randstad_process.lastProcessStep === ProcessStep.OFFER) {
        this.menu = OFFER_OPTION_MENU;
        this.obj.rejectReason = OFFER_OPTION_MENU[0];
      } else {
        this.menu = NOT_FIT_OPTION_MENU;
      }
    }
    if(this.updateSubmission) {
      if(this.clientInterview)
      {
        this.menu = UPDATE_OPTION_MENU_IH;
        this.statusLabel = this._talentProcess.getLabel(this.talentApplication, this._authService.user.Source, 'process', this._jobOrder.storedJobOrder);
        let backOfficeId;
        if(this.talentApplication?.randstad_process?.internalSubmission?.skipped)
        {
          backOfficeId = this.talentApplication?.randstad_process?.clientInterview?.agentID;
        } else
        {
          backOfficeId = this.talentApplication?.randstad_process?.internalSubmission?.submittedByID;
        }
        const author = this._activityFeed.getAuthorDetails(backOfficeId);
        this.submittedBy = author.name;
      } else if (this.clientSubmission) {
        this.menu = UPDATE_OPTION_MENU_HM;
        let backOfficeId;
        if(this.talentApplication?.randstad_process?.internalSubmission?.skipped)
        {
          backOfficeId = this.talentApplication?.randstad_process?.clientSubmission?.submittedByID;
        } else
        {
          backOfficeId = this.talentApplication?.randstad_process?.internalSubmission?.submittedByID;
        }
        const author = this._activityFeed.getAuthorDetails(backOfficeId);
        this.submittedBy = author.name;
      }
       else {
        this.menu = UPDATE_OPTION_MENU;
        const backOfficeId = this.talentApplication?.randstad_process?.internalSubmission?.submittedByID;
        const author = this._activityFeed.getAuthorDetails(backOfficeId);
        this.submittedBy = author.name;
      }
    }

    this.initialObj = { ...this.obj };
    this.hmForm = this._fb.group({
      rejectNote: [['']],
    });
    this.profileSub = this._talentProcess.selectedProfile.subscribe(profile => {
      this.profile = profile;
    });
  }

  ngOnDestroy() {
    this.profileSub.unsubscribe();
    this.showingNotAFitReason = true
  }

  onSaveClick(): void {
    this.showError = true;
    // Make sure the form is filled out.
    const values = this.hmForm.getRawValue();
    this.obj.rejectNote = values.rejectNote;
    if (!this.obj.rejectReason || !this.obj.rejectNote) {
      return this._toast.showToast('not-fit-modal.invalid', { cssClass: ToastClass.DANGER });
    }
    // Construct the reject body.
    if(!this.updateSubmission){
    const notAFitProc = this.talentApplication.randstad_process.lastProcessStep;
    const params: ApplicationRejectionData = {
      rejectReason: this.obj.rejectReason,
      rejectNote: this.obj.rejectNote,
      rejected: true,
      rejectedByAgentID: this.auth.user.BackOfficeID,
      rejectedTimestamp: moment(),
      lastProcessStatus: this._talentProcess.getNotAFitSubStatus(notAFitProc,
        this.talentApplication.randstad_process.lastProcessStatus)
    };
    this.sendNotifications(this.obj.rejectNote, this.obj.rejectReason, this.talentApplication, params, NotificationTypes.OFFER_REJECTED);
    // Call different reject function depending on the calling context.
    if (this.context === 'JOB_DETAILS') {
      return this.jobDetailsReject(params);
    } else if (this.context === 'TALENT_MANAGEMENT') {
      return this.talentDashboardReject(params);
    }
  }
  else{
    const params: ApplicationRejectionData = {
      rejectReason: '',
      rejectNote: '',
      rejected: false,
    };
    if((this.clientInterview && this.obj.rejectReason == 'Additional Interview Requested') && !this.interviewDate)
    {
      return this._toast.showToast('not-fit-modal.invalid', { cssClass: ToastClass.DANGER });
    }
    else
    {
    return this.updateSubmissionClick(this.obj.rejectReason, this.obj.rejectNote, params);
    }
  }
  }

  updateSubmissionClick(reason: string, note: string, params?:any) {
    this._loading.show();
    const application = this.talentApplication.clone();
    if(this.internalSubmission){
      const internalSubmission: any = {};
      internalSubmission.outcome = application.randstad_process.internalSubmission.outcome;
      internalSubmission.outcomeDate = application.randstad_process.internalSubmission.outcomeDate;
      internalSubmission.submission = application.randstad_process.internalSubmission.submission;
      internalSubmission.submissionAttachmentURI = application.randstad_process.internalSubmission.submissionAttachmentURI;
      internalSubmission.submissionDate = application.randstad_process.internalSubmission.submissionDate;
      internalSubmission.submissionPayRate = application.randstad_process.internalSubmission.submissionPayRate;
      internalSubmission.submissionPayType = application.randstad_process.internalSubmission.submissionPayType;
      internalSubmission.submissionSource = application.randstad_process.internalSubmission.submissionSource;
      internalSubmission.submittedByID = application.randstad_process.internalSubmission.submittedByID;
      internalSubmission.submittedToID = application.randstad_process.internalSubmission.submittedToID;
      internalSubmission.submissionType = application.randstad_process.internalSubmission.submissionType;
      internalSubmission.externalId = application.randstad_process.lastProcessStatus === 'SS_REVIEW_COMPLETED' ? application.randstad_process.lastProcessStatus  : '';
      const priorSubmissions = application.randstad_process.internalSubmission.priorSubmissions || [];
      application.randstad_process.internalSubmission.priorSubmissions = [internalSubmission, ...priorSubmissions];
      application.randstad_process.internalSubmission.submissionType = reason;
      application.randstad_process.internalSubmission.submittedByID = this.auth.user.BackOfficeID;
      application.randstad_process.internalSubmission.submissionDate = moment();
      application.randstad_process.internalSubmission.submission = note;
      application.randstad_process.lastProcessStatus = ProcessStatus.RA_AM_UPDATE_SUB;
    };
    if(this.clientSubmission){
      const clientSubmission: any = {};
      clientSubmission.submission = application.randstad_process.clientSubmission.submission;
      clientSubmission.hiringManagerFeedback = application.randstad_process.clientSubmission.hiringManagerFeedback;
      clientSubmission.submittedByID = application.randstad_process.clientSubmission.submittedByID;
      clientSubmission.submittedTo = application.randstad_process.clientSubmission.submittedTo;
      clientSubmission.submissionDate = application.randstad_process.clientSubmission.submissionDate;
      clientSubmission.outcome = application.randstad_process.clientSubmission.outcome;
      clientSubmission.outcomeDate = application.randstad_process.clientSubmission.outcomeDate;
      clientSubmission.submissionAttachmentURI = application.randstad_process.clientSubmission.submissionAttachmentURI;
      clientSubmission.submissionEmailTo = application.randstad_process.clientSubmission.submissionEmailTo;
      clientSubmission.submissionEmailCc = application.randstad_process.clientSubmission.submissionEmailCc;
      clientSubmission.submissionEmailBcc = application.randstad_process.clientSubmission.submissionEmailBcc;
      clientSubmission.submissionEmailSubject = application.randstad_process.clientSubmission.submissionEmailSubject;
      clientSubmission.submissionType = application.randstad_process.clientSubmission.submissionType;
      clientSubmission.skipped = application.randstad_process.clientSubmission.skipped;
      clientSubmission.skippedBy = application.randstad_process.clientSubmission.skippedBy;
      clientSubmission.skippedDate = application.randstad_process.clientSubmission.skippedDate;

      const priorSubmissions = application.randstad_process.clientSubmission.priorSubmissions || [];
      application.randstad_process.clientSubmission.priorSubmissions = [clientSubmission, ...priorSubmissions];
      application.randstad_process.clientSubmission.submission = note;
      application.randstad_process.clientSubmission.submissionType = reason;
      application.randstad_process.clientSubmission.submissionDate = moment();
      application.randstad_process.clientSubmission.submittedByID = this.auth.user.BackOfficeID;
      application.randstad_process.clientSubmission.submissionAttachmentURI = '';
      application.randstad_process.clientSubmission.submissionEmailTo = [];
      application.randstad_process.clientSubmission.submissionEmailCc = [];
      application.randstad_process.clientSubmission.submissionEmailBcc = [];
      application.randstad_process.clientSubmission.submissionEmailSubject = "";
      application.randstad_process.lastProcessStatus = ProcessStatus.RH_UPDATE_SUB;

    }
    if(this.clientInterview)
    {
      const clientInterview: any ={};
      clientInterview.agentID = application.randstad_process.clientInterview.agentID;
      clientInterview.editing = application.randstad_process.clientInterview.editing;
      clientInterview.externalId = application.randstad_process.clientInterview.externalId;
      clientInterview.hiringManagerFeedback = application.randstad_process.clientInterview.hiringManagerFeedback;
      clientInterview.interviewDate = application.randstad_process.clientInterview.interviewDate;
      clientInterview.outcome = application.randstad_process.clientInterview.outcome;
      clientInterview.outcomeDate = application.randstad_process.clientInterview.outcomeDate;
      clientInterview.submission = application.randstad_process.clientInterview.submission;
      clientInterview.submissionDate = application.randstad_process.clientInterview.submissionDate;
      clientInterview.submittedTo = application.randstad_process.clientInterview.submittedTo;
      clientInterview.submissionType = application.randstad_process.clientInterview.submissionType;

      const priorSubmissions = application.randstad_process.clientInterview.priorSubmissions || [];
      application.randstad_process.clientInterview.priorSubmissions = [clientInterview, ...priorSubmissions];
      application.randstad_process.clientInterview.submission = note;
      application.randstad_process.clientInterview.submissionType = reason;
      application.randstad_process.clientInterview.submissionDate = moment();
      application.randstad_process.lastProcessStatus = ProcessStatus.IH_UPDATE_SUB;
      if(reason == 'Additional Interview Requested')
      {
      application.randstad_process.clientInterview.interviewDate = moment(this.interviewDate);
      }
    }
    this.sendNotifications(note, reason, application, params, NotificationTypes.UPDATE_SUBMISSION);

    const pipelineFlag = (this.jobOrder.allbirds_metadata.order_type == 'Pipeline') ? true : false;
    this._talentProcess.updateApplication(application, [INTG_STEPS.SUBMISSION], null, true, pipelineFlag)
    .then(res => {
      const trVariable = { 'value': application.randstad_process.candidateFullName };
      this._toast.showToastWithVariables('not-fit-modal.update_submission', trVariable);
      this.obj = this.initialObj;
      this.bsModalRef.hide();
    })
    .catch(err => {
      this._toast.showToastNoTranslation(err.message, { cssClass: ToastClass.DANGER });
    })
    .finally(() => {
      this._loading.hide();
    });
  }

  sendNotifications(comment: string, reason: string, application: Application, params: any, type:any) {
    const values = this.hmForm.getRawValue();
    const mentions = UtilityService.getMentionsRecipients(values.rejectNote);
    const app = application.clone();
    app.randstad_process.apply(params);
    const emailAddressOnToLine = mentions ? mentions.splice(0,1) : []
    const notificationBody: NotificationData = {
      notificationType: type,
      notificationObject: {
        title: this.jobOrder.internalTitle,
        candidateFullName: `${this.profile.personNames[0].structuredName.givenName} ${this.profile.personNames[0].structuredName.familyName}`,
        published_by_user: this.jobOrder.allbirds_metadata.published_by_user,
        published_by_user_email: this.jobOrder.allbirds_metadata.published_by_user_email,
        allbirds_job_id: this.jobOrder.allbirds_metadata.allbirds_job_id,
        executing_action_user: this.auth.user.FullName,
        executing_action_user_email: this.auth.user.EmailAddr,
        customer_name: this.jobOrder.allbirds_metadata.customer_name,
        front_office_id: this.jobOrder.allbirds_metadata.front_office_id,
        user: {...this.auth.user},
        emailRecipients: emailAddressOnToLine,
        emailCc:mentions,
        noteType: reason,
        noteComment: comment,
        contextUrl: this._utility.getContextUrl(location.pathname, location.search),
        applicationObject: app,
        vms_req_id: this.jobOrder.allbirds_metadata.vms_req_id,
        hiringManager: this.jobOrder.hiringManager
      }
    };
    // this._loading.show();
    const sub = this._notificationService.sendNotification(notificationBody)
    .pipe(catchError((err) => {
      console.error(err);
      // this._loading.hide();
      return err;
    }))
    .subscribe(() => {
      sub.unsubscribe();
      // this._loading.hide();
    });
  }

  jobDetailsReject(params: ApplicationRejectionData) {
    this._loading.show();
    const intgSteps: INTG_STEPS[] = [];
    if (this.doActivity()) {
      intgSteps.push(INTG_STEPS.ACTIVITY);
    }
    if (this.doSubmission()) {
      intgSteps.push(INTG_STEPS.SUBMISSION);
    }
    // console.log("[jobDetailsReject]", intgSteps);
    this._shortlist.reject(this.talentApplication, params, intgSteps)
      .then(res => {
        this.paginate.selectTalent(res.application);
        this._toast.showToastNoTranslation(res.message);
        this.obj = this.initialObj;
        this.bsModalRef.hide();
      })
      .catch(err => {
        this._toast.showToastNoTranslation(err.message, { cssClass: ToastClass.DANGER });
      })
      .finally(() => {
        this._loading.hide();
      });
  }

  talentDashboardReject(params: ApplicationRejectionData) {
    this._loading.show();
    const pipelineFlag = (this.jobOrder.allbirds_metadata.order_type == 'Pipeline') ? true : false;
    // Copy over talent application & add rejected data to randstad_process.
    const application = this.talentApplication.clone();
    application.randstad_process.apply(params);
    // Make the call.
    const intgSteps: INTG_STEPS[] = [];
    if (this.doActivity()) {
      intgSteps.push(INTG_STEPS.ACTIVITY);
    }
    if (this.doSubmission()) {
      intgSteps.push(INTG_STEPS.SUBMISSION);
    }
    this._talentProcess.updateApplication(application, intgSteps, null, true, pipelineFlag)
      .then(res => {
        const trVariable = { 'value': application.randstad_process.candidateFullName };
        this._toast.showToastWithVariables('not-fit-modal.not_fit', trVariable);
        this.obj = this.initialObj;
        this.bsModalRef.hide();
      })
      .catch(err => {
        this._toast.showToastNoTranslation(err.message, { cssClass: ToastClass.DANGER });
      })
      .finally(() => {
        this._loading.hide();
      });
  }

  isUnsaved(): boolean {
    const {
      rejectReason,
      rejectNote
    } = this.initialObj;
    return rejectReason !== this.obj.rejectReason ||
      rejectNote !== this.obj.rejectNote;
  }

  /**
   * NOTE: This function may be needed in the future if we restrict the Not a Fit reasons for RT rejection
   * on internalSubmission prior to the submission being made. (see DF044-3507 & 3491)
   */
  isPreSubmission(): boolean {
    if (
      this.auth.user.Source.checkLob('RT', 'RE','CR') &&
      this.talentApplication.randstad_process.lastProcessStep === ProcessStep.REVIEW_AM &&
      (!this.talentApplication.randstad_process.internalSubmission || !this.talentApplication.randstad_process.internalSubmission.submission)
    ) {
      return true;
    }
    return false;
  }

  /**
   * Returns true if the submitted rejection should create an "activity" in FO systems.
   * RGS rejections only are sent to FO on Offer rejections (which is handled in the RejectOfferModalComponent)
   */
  doActivity(): boolean {
    const step = this.talentApplication.randstad_process.lastProcessStep;
    const isRT = this.auth.user.Source.checkLob('RT', 'RE','CR');
    if (isRT) {
      // For RT, every rejection before an AM submission is an "activity".
      return [ProcessStep.PRESCREENING, ProcessStep.INTERVIEW_RECRUITER, ProcessStep.INTERVIEW_RECRUITER].includes(step) || this.isPreSubmission();
    }
    return false;
  }

  /**
   * Returns true if the submitted rejection should create a "submission" in FO systems.
   * RGS rejections only are sent to FO on Offer rejections (which is handled in the RejectOfferModalComponent)
   */
  doSubmission(): boolean {
    const step = this.talentApplication.randstad_process.lastProcessStep;
    const isRT = this.auth.user.Source.checkLob('RT', 'RE','CR');
    if (isRT) {
      // For RT, every rejection after an AM submission is made is treated as a "submission" update.
      return [ProcessStep.REVIEW_HM, ProcessStep.INTERVIEW_HM, ProcessStep.OFFER].includes(step) || (step === ProcessStep.REVIEW_AM && !this.isPreSubmission());
    }
    return false;
  }

  changeMeridian(time: Date, type: string) {
    let hours = time.getHours();
    const minutes = time.getMinutes();
    if ((type === 'PM')) {
      hours = hours + 12;
    } else if (type === 'AM') {
      hours = hours - 12;
    }
    this.interviewDate = new Date(time.setHours(hours, minutes));
  }

  /**
   * Round to the nearest 15 minutes when a date is selected
   * @param {Date} value - Date from date picker
   * @listens bsValueChange#interviewDate
   */
  onDatePicked(value: Date) {
    if (value && !this.interviewDate) {
      // round time to nearest 15 minutes
      const start = moment(value);
      const remainder = 15 - (start.minute() % 15);
      // What a hack, however, without the timeout this happens before the model is set from the datepicker component
      setTimeout(() => {
        this.interviewDate = moment(start).add(remainder, 'minutes').toDate();
      }, 1);
    }
  }
}
