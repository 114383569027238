import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Directive, ElementRef,
  Input,
  OnInit, Renderer2,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {Assignment} from 'src/app/shared/models/external/assignment.model';
import {Profile} from 'src/app/shared/models/external/profile.model';
import {AuthService} from 'src/app/shared/services/auth/auth.service';
import {RequestBody} from '../../../../models/api/application-request.interface';
import {ApiService} from '../../../../services/api/api.service';
import {
  TalentMetricsActivity,
  TalentMetricsActivityMapping,
  TalentMetricsActivityTypes,
  TalentMetricsFilterDisplay,
  TalentMetricsFilterTypeLabels,
  TalentMetricsLabels
} from '../talent-metrics.interface';
import TalentHistoryRequest = RequestBody.TalentHistoryRequest;
import {LoadingSpinnerService} from '../../../../services/loading-spinner/loading-spinner.service';
import moment from 'moment';
import {Router} from '@angular/router';

@Component({
  selector: 'app-talent-metrics-modal',
  templateUrl: './talent-metrics-modal.component.html',
  styleUrls: ['./talent-metrics-modal.component.scss']
})
export class TalentMetricsModalComponent implements OnInit, AfterViewChecked {
  filters: TalentMetricsFilterDisplay[] = [];
  activeFilter: TalentMetricsFilterTypeLabels;
  personName: string;
  activities: TalentMetricsActivity<any>[] = [];
  displayActivities: TalentMetricsActivity<any>[] = [];
  profile: Profile;
  history: TalentMetricsActivity<any>[];
  subs: Subscription = new Subscription();
  loading = false;
  @ViewChild('CommonActivity', {}) CommonActivity: TemplateRef<any>;
  @ViewChild('AssignmentActivity', {}) AssignmentActivity: TemplateRef<any>;
  @ViewChild('RecruiterInterviewAction', {}) RecruiterInterviewAction: TemplateRef<any>;
  @ViewChild('ReviewByAMAction', {}) ReviewByAMAction: TemplateRef<any>;
  @ViewChild('ReviewByClientAction', {}) ReviewByClientAction: TemplateRef<any>;
  @ViewChild('ClientInterviewAction', {}) ClientInterviewAction: TemplateRef<any>;
  @ViewChild('Activities', {}) Activities: TemplateRef<any>;
  @ViewChild('NoActivities', {}) NoActivities: TemplateRef<any>;
  @ViewChild('Loading', {}) Loading: TemplateRef<any>;

  columnList: any = [];

  @ViewChild('ActivitiesNew', {}) ActivitiesNew: TemplateRef<any>;
  @ViewChild('DateTime', {}) DateTime: TemplateRef<any>;
  @ViewChild('JobClientName', {}) JobClientName: TemplateRef<any>;
  @ViewChild('WorkSiteLocation', {}) WorkSiteLocation: TemplateRef<any>;
  @ViewChild('JobStatus', {}) JobStatus: TemplateRef<any>;
  @ViewChild('PayRate', {}) PayRate: TemplateRef<any>;
  @ViewChild('BillRate', {}) BillRate: TemplateRef<any>;
  @ViewChild('Recruiter', {}) Recruiter: TemplateRef<any>;
  @ViewChild('Remarks', {}) Remarks: TemplateRef<any>;
  @ViewChild('Note', {}) Note: TemplateRef<any>;
  @ViewChild('Salary', {}) Salary: TemplateRef<any>;
  @ViewChild('ReasonForEnding', {}) ReasonForEnding: TemplateRef<any>;
  @ViewChild('NoteBody', {}) NoteBody: ElementRef;


  constructor(
    private changeRef: ChangeDetectorRef,
    public bsModalRef: BsModalRef,
    private _auth: AuthService,
    private _api: ApiService,
    private _translate: TranslateService,
    private _loading: LoadingSpinnerService,
    private _router: Router,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.getActivities();
    this.personName = this.getPersonName();
  }
  ngAfterViewChecked(): void { this.changeRef.detectChanges(); }

  /**
   * Get the talents full name
   * @type {string}
   */
  getPersonName(): string {
    if (this.profile && this.profile.personNames && this.profile.personNames.length) {
      return `${this.profile.personNames[0].structuredName.givenName} ${this.profile.personNames[0].structuredName.familyName}`;
    } else {
      return '';
    }
  }

  /**
   * Get the filters to display. Must happen after we get the activities
   * @type {TalentMetricsFilterDisplay[]}
   */
  getFilters(): TalentMetricsFilterDisplay[] {
    const labels = this.profile.lob.abIncludesLob('RT', 'RE') ? TalentMetricsLabels.RT : TalentMetricsLabels.RGS;
    return labels.map((label) => {
      return {
        label: this._translate.instant(`talent-metrics-labels.${this._getActivityTypeFromLabel(label)}`),
        count: this.activities.filter(activity => activity.type === this._getActivityTypeFromLabel(label)).length
      };
    });
  }

  /**
   * Fetch the activities and map the assignments
   */
  getActivities() {
    this._loading.show();
    const requestPayload: TalentHistoryRequest = {
      talentGoogleId: this.profile.name,
      talentBackOfficeId: this.profile.externalId,
      from: 0,
      size: 5000
    };
    this.loading = true;
    this.subs.add(this._api.getUserRecruitmentHistory(requestPayload)
      .pipe(finalize(() => this.loading = false))
      .subscribe((resp) => {
        const {metrics} = resp;
        if (metrics) {
          let activities = metrics.slice();
          const assignmentActivities: TalentMetricsActivity<Assignment>[] = this._getAssignmentActivities();
          activities = [...activities, ...assignmentActivities];
          this.activities = activities.sort(this._activitiesSort);
          this.filters = this.getFilters();
          this.filterActivities();
          this.buildActivityColumnList(this.activeFilter);
        }
        this._loading.hide();
      }));
  }

  /**
   * Sort function for activities
   * @param {TalentMetricsActivity<any>} a
   * @param {TalentMetricsActivity<any>} b
   * @returns {number}
   */
  _activitiesSort(a: TalentMetricsActivity<any>, b: TalentMetricsActivity<any>) {
    let aDate: any;
    let bDate: any;
    if (a.type === TalentMetricsActivityTypes.Assignment) {
      aDate = new Date(a.data.startDate);
    } else {
      aDate = new Date(a.data.activityTimestamp || a.activityTimestamp);
    }
    if (b.type === TalentMetricsActivityTypes.Assignment) {
      bDate = new Date(b.data.startDate);
    } else {
      bDate = new Date(b.data.activityTimestamp || b.activityTimestamp);
    }
    return (bDate - aDate);
  }

  /**
   * Get activity items for assignments
   */
  _getAssignmentActivities() {
    const assignments = this.profile.allbirds_metadata.assignments || [];
    return assignments.map((assignment) => {
      return {
        type: TalentMetricsActivityTypes.Assignment,
        filterLabel: TalentMetricsFilterTypeLabels.Assignment,
        jobTitle: assignment.randstadJobTitle,
        jobEmployer: assignment.client,
        userName: '',
        userAvatar: '',
        data: assignment,
        rawAssignFlag : assignment.rawAssignFlag
      };
    });
  }

  /**
   * Given the string from the history.activityType field, will return a TalentMetricsActivityTypes
   * @param historyActivityType
   * @returns {TalentMetricsActivityTypes}
   */
  _getActivityType(historyActivityType: string): TalentMetricsActivityTypes {
    const activityMap: any = this.profile.lob.abIncludesLob('RT', 'RE')
      ? TalentMetricsActivityMapping.RT
      : TalentMetricsActivityMapping.RGS;
    let type = '';
    Object.keys(activityMap).forEach((key) => {
      if (activityMap[key].includes(historyActivityType)) {
        type = key;
      }
    });
    return type as TalentMetricsActivityTypes;
  }

  /**
   * Given a TalentmetricsFilterTypeLabels will return a TalentmetricsActivtyTypes
   * @param {TalentMetricsFilterTypeLabels} label
   * @returns {TalentMetricsActivityTypes}
   */
  _getActivityTypeFromLabel(label: TalentMetricsFilterTypeLabels): TalentMetricsActivityTypes {
    switch (label) {
      case TalentMetricsFilterTypeLabels.Applies:
        return TalentMetricsActivityTypes.Applies;
      case TalentMetricsFilterTypeLabels.ScheduledRecruiterInterview:
        return TalentMetricsActivityTypes.ScheduledRecruiterInterview;
      case TalentMetricsFilterTypeLabels.RecruiterInterview:
        return TalentMetricsActivityTypes.RecruiterInterview;
      case TalentMetricsFilterTypeLabels.ReviewByAM:
        return TalentMetricsActivityTypes.ReviewByAM;
      case TalentMetricsFilterTypeLabels.ReviewByClient:
        return TalentMetricsActivityTypes.ReviewByClient;
      case TalentMetricsFilterTypeLabels.ClientInterview:
        return TalentMetricsActivityTypes.ClientInterview;
      case TalentMetricsFilterTypeLabels.Assignment:
        return TalentMetricsActivityTypes.Assignment;
    }
  }

  /**
   * Set the activities based on the activeFilter
   */
  filterActivities() {
    this.loading = true;
    if (this.activeFilter) {
      this.displayActivities = this.activities.filter((activity) => {
        return activity.filterLabel === this.activeFilter;
      });
    } else {
      this.displayActivities = this.activities;
    }
    this.loading = false;
    const sortedDisplayActivities = this.displayActivities.sort(this._activitiesSort);
    return sortedDisplayActivities;
  }

  /**
   * Set the activeFilter based on the filterLabel which was clicked
   * @param {TalentMetricsFilterTypeLabels} filterLabel
   * @listens filter#click
   */
  toggleFilter(filterLabel: TalentMetricsFilterTypeLabels) {
    this.activeFilter = filterLabel === this.activeFilter ? filterLabel : filterLabel;
    this.filterActivities();
    this.buildActivityColumnList(this.activeFilter);
  }

  /**
   * Get the template for a single activity
   * @param {TalentMetricsActivity} activity
   */
  getActivityTemplate(activity: TalentMetricsActivity<any>) {
    return activity && activity.type && activity.type !== TalentMetricsActivityTypes.Assignment
      ? this.CommonActivity
      : this.AssignmentActivity;
  }

  /**
   * Get the template for the top level activities display
   */
  getActivitiesTemplate() {
    return this.loading
      ? this.Loading
      : this.displayActivities && this.displayActivities.length
        ? this.ActivitiesNew
        : this.NoActivities;
  }

  /**
   * Called from the UI to determine the ng-template to display
   * @param {TalentMetricsActivity} activity
   * @returns TemplateRef<any>
   */
  getActivityActionTemplate(activity: TalentMetricsActivity<any>) {
    switch (activity.type) {
      case TalentMetricsActivityTypes.RecruiterInterview:
        return this.RecruiterInterviewAction;
      case TalentMetricsActivityTypes.ReviewByAM:
        return this.ReviewByAMAction;
      case TalentMetricsActivityTypes.ReviewByClient:
        return this.ReviewByClientAction;
      case TalentMetricsActivityTypes.ClientInterview:
        return this.ClientInterviewAction;
    }
  }

  buildActivityColumnList(activity: any) {
    this.columnList = [];
    this.columnList.push({header: 'DATE AND TIME', template: this.DateTime});
    this.columnList.push({header: 'JOB AND CLIENT', template: this.JobClientName});
    if ([TalentMetricsFilterTypeLabels.Applies, TalentMetricsFilterTypeLabels.ScheduledRecruiterInterview].includes(activity)) {
      this.columnList.push({header: 'WORKSITE LOCATION', template: this.WorkSiteLocation});
      this.columnList.push({header: 'JOB STATUS', template: this.JobStatus});
      this.columnList.push({header: 'PAY RATE', template: this.PayRate});
    }
    if ([TalentMetricsFilterTypeLabels.ScheduledRecruiterInterview].includes(activity)) {
      this.columnList.push({header: 'RECRUITER', template: this.Recruiter});
    }
    if ([TalentMetricsFilterTypeLabels.Applies].includes(activity)) {
      this.columnList.push({header: 'BILL RATE', template: this.BillRate});
    }

    if ([TalentMetricsFilterTypeLabels.RecruiterInterview,
      TalentMetricsFilterTypeLabels.ReviewByAM,
      TalentMetricsFilterTypeLabels.ReviewByClient,
      TalentMetricsFilterTypeLabels.ClientInterview
    ].includes(activity)) {
      this.columnList.push({header: 'RECRUITER', template: this.Recruiter});
      this.columnList.push({header: 'NOTE', template: this.Note});
    }

    if ([TalentMetricsFilterTypeLabels.RecruiterInterview].includes(activity)) {
      this.columnList.push({header: 'DESIRED PAY RATE', template: this.PayRate});
    }

    if ([TalentMetricsFilterTypeLabels.Assignment].includes(activity)) {
      this.columnList.push({header: 'SALARY', template: this.Salary});
      this.columnList.push({header: 'REASON FOR ENDING', template: this.ReasonForEnding});
    }
  }

  /**
   * Fired when the expand button is clicked. Will add/update a property on the activity called
   * expanded
   * @param {number} idx
   * @listens Activity#click
   */
  expandActivityClick(idx: number, element?: ElementRef) {
    if (idx >= 0) {
      const act = this.displayActivities[idx];
      act.expanded = !act.expanded;
      if ( element) {
        if (act.expanded) {
          this.renderer?.removeClass(element, 'text-truncate');
        } else {
          this.renderer?.addClass(element, 'text-truncate');
        }
      }

    }
  }

  isActivityAssignment(activity: any) {
    return activity.type === TalentMetricsFilterTypeLabels.Assignment;
  }

  isAssignmentRawLabel(activity:any){
    if(activity?.rawAssignFlag === "Y") return true
    return false
  }  

  checkDateSeparator(index: any) {
    const now = moment();
    if (index === 0) {
      return moment(this.displayActivities[0].activityTimestamp).isBefore(now);
    }
    const potentialPast = moment(this.displayActivities[index].activityTimestamp);
    const potentialFuture = moment(this.displayActivities[index - 1].activityTimestamp);
    return potentialFuture.isAfter(now) && potentialPast.isBefore(now);
  }

  goToJobDetails(activity: any) {
    const jobId = activity.allbirds_job_id;
    const profileId = this.profile.name.substring(this.profile.name.lastIndexOf('/'), this.profile.name.length);
    const url = `${window.location.origin}/jobs/${jobId}?left_tab=job_details&st=${profileId}`;

    if (jobId && profileId) {
      console.log('Opening tab with: ', url);
      window.open(url, '_blank');
    }
  }

}



@Directive({
  selector: '[hideChevron]'
})
export class HideChevronDirective implements AfterViewInit {

  constructor(private el: ElementRef, private renderer: Renderer2) {

  }
  ngAfterViewInit() {
    if (this.el?.nativeElement?.innerHTML?.indexOf('This is a test') !== -1) {
      console.log('eee');
    }
    try {
      this.renderer.removeClass(this.el.nativeElement, 'text-truncated');
      if (this.el?.nativeElement?.clientHeight < 80) {
        this.renderer.removeChild(this.el.nativeElement, this.el.nativeElement.children[1]);
      } else if (this.el) {
        this.renderer.addClass(this.el.nativeElement, 'maxHeight');
        this.renderer.addClass(this.el.nativeElement, 'text-truncate');
      }
    } catch (e) {
      console.log(e);
    }

  }

}
