import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User } from '../../models/external/user.model';

@Component({
  selector: 'shared-user-lists',
  templateUrl: './shared-user-lists.component.html',
  styleUrls: ['./shared-user-lists.component.scss']
})
export class SharedUserListsComponent implements OnInit {
  @Input() recruiterList: User[];
  @Output() updatedRecruiterList = new EventEmitter<User>();

  constructor() { }

  ngOnInit() {
  }

  emitRemovedUserID(user: User): void {
    this.updatedRecruiterList.emit(user);
  }

}
