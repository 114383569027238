import { Users } from '@allbirds-ui/allbirds-types';
import {Application} from '../external/application.model';
import { Interview } from '../external/process/interview.model';

export const JOB_DATA_NOTIFICATION_TRIGGERS = [
  'published_min_pay_rate',
  'published_max_pay_rate',
  'skills',
  'internalDescription',
  'internal_notes',
  'work_zip',
  'work_city',
  'work_address',
  'work_state',
  'max_auto_schedules_allowed',
  'auto_scheduler_interview_channel'
];

export enum NotificationTypes {
  JOB_STATUS_CHANGE = 'jobStatusChange',
  JOB_ASSIGNMENT_MADE = 'jobAssignmentMade',
  JOB_NEEDS_ASSIGNMENT = 'jobNeedsAssignment',
  JOB_DATA_CHANGE = 'jobDataChange',
  SUBMISSION_ADDED = 'submissionAdded',
  SEND_USER_EMAIL = 'sendUserEmail',
  CHATBOT_COMPLETION = 'chatbotCompletion',
  CLIENT_SUBMISSION = 'clientSubmission',
  LOG_A_NOTE = 'logANote',
  LOG_APPOINTMENT = 'logAppointment',
  JOB_EXTENSION_MADE = 'jobExtensionMade',
  RECRUITER_INTERVIEW = 'recruiterInterview',
  PRESCREENING = 'prescreening',
  CLIENT_SUBMISSION_NOTE = 'clientSubmissionNote',
  CLIENT_INTERVIEW = 'clientInterview',
  OFFER_REJECTED = 'offerRejected',
  HM_FEEDBACK = 'hmFeedback',
  UPDATE_SUBMISSION = 'updateSubmission',
  CR_JOB_CREATION = 'crJobCreation'
}

export interface EmailRecipient {
  recipient?: string;
  recipientEmail?: string;
}

export interface JobData {
  employmentTypes: Array<string>;
  customer_name: string;
  order_status: string;
  city: string;
  state: string;
  pay_rate: string;
  target_bill_rate: string;
  description: string;
}

interface NotificationObject {
  user: Users.User;
  title?: string;
  assigned_to_user?: string;
  assigned_to_user_email?: string;
  published_by_user?: string;
  published_by_user_email?: string;
  front_office_id: string;
  customer_name?: string;
  allbirds_job_id?: string;
  getAssociatedRecruiters?: boolean;
  candidateFullName?: string;
  submission?: string;
  submissionPayRate?: number;
  submissionPayType?: string;
  submissionSource?: string;
  submissionResumeUrl?: string;
  job_data_changes?: {};
  executing_action_user: string;
  executing_action_user_email: string;
  emailRecipients: Array<EmailRecipient>;
  emailCc?: Array<EmailRecipient>;
  emailBcc?: Array<EmailRecipient>;
  emailSubject?: string;
  emailHtml?: string;
  emailSignature?: string;
  jobData?: JobData;
  noteType?: string;
  noteComment?: string;
  contextUrl?: string;
  employeeType?: string;
  vendorCompanyName?: string;
  vendorCompanyRandstadApproved?: string;
  transferToDBC?: string;
  desiredLocation?: string;
  dateAvailable?: string;
  currentCompany?: string;
  jobTitle?: string;
  yearsOfExperience?: string;
  vms_req_id?: string;
  hiringManager?: string;
  applicationObject?: Application;
  appointment?: Interview
}

export interface NotificationData {
  notificationType: NotificationTypes;
  notificationObject: NotificationObject;
}
