<!--
  The proper TemplateRef is rendered in the following <div> depending on what sub-step the talent
  is on as well as what the role of the user is.
-->
<div class="phase" [ngTemplateOutlet]="shownPhase"></div>
<!-- Awaiting hiring manager feedback -->
<ng-template #awaitingHMFeedback>
  <div class="phase-body-section pt-0">
    <h4>{{statusLabel}}</h4>
    <div class="pt-0" *ngIf="!application?.randstad_process?.clientInterview?.hiringManagerFeedback">
      <h4 class="d-inline">{{'interview-hiring-manager.interview_scheduled' | translate}}</h4>
      <img class="click pl-3" src="assets/icons/edit.svg" alt="edit" (click)="editInterviewSchedule()" />
      <p *ngIf="application?.randstad_process?.clientInterview?.interviewDate" class="mb-3 mt-3">
        <i class="icon icon-WL_icon_schedule color-primary calendar-icon"></i>
        {{ application?.randstad_process?.clientInterview?.interviewDate | abDatetime }}
      </p>
      <p class="mb-3 mt-3" [innerHTML]="application?.randstad_process?.clientInterview?.submission"><img style="margin-left: -4px;" src="assets/icons/note.svg" alt=" />">
      </p>
    </div>
    <div class="hiringManagerFeedback" *ngIf="application?.randstad_process?.clientInterview?.hiringManagerFeedback">
      <label for="hiringManagerFeedback">{{'interview-hiring-manager.hiring_manager_feedback' | translate}}</label>
      <div id="hiringManagerFeedback" class="hiringManagerFeedbackDisplay" [innerHTML]="application?.randstad_process?.clientInterview?.hiringManagerFeedback">
      </div>
    </div>
  </div>
  <div #ctaContainerBoundry id="ctaContainerBoundry"></div>
  <ul #ctaContainer class="action-buttons hz-btn-list">
    <li><app-talent-process-cta
      *ngIf="!application?.randstad_process?.clientInterview?.hiringManagerFeedback && userLob.checkLob('RT', 'RE','CR')"
        [id]="'reviewAm-update-submission-button'"
        [invertColor]="true"
        (execute)="emitUpdateSubmissionClick()">
        {{ 'review-account-manager.update_submission' | translate }}
      </app-talent-process-cta></li>
    <li>
      <app-talent-process-cta
        [id]="'interviewHm-not-a-fit'"
        [invertColor]="true"
        (execute)="emitUnfitClick()">
        {{'interview-hiring-manager.not_a_fit' | translate}}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        *ngIf="!application?.randstad_process?.clientInterview?.hiringManagerFeedback"
        [id]="'log-hiring-manager-feedback-button'"
        (execute)="openLogFeedbackModal()">
        {{'interview-hiring-manager.log_hiring_managers' | translate}}
      </app-talent-process-cta>
      <app-talent-process-cta
        *ngIf="application?.randstad_process?.clientInterview?.hiringManagerFeedback"
        [id]="'save-interview-button'"
        (execute)="accept()">
        {{'interview-hiring-manager.continue_to_offer' | translate}}
      </app-talent-process-cta>
    </li>
  </ul>
</ng-template>

<ng-template #submittedViewUpdate>
  <div *ngIf="application?.randstad_process?.clientInterview" class="phase-body-section">
    <div class="submissionDetails">
      <h4 class="phase-body-title"><b>{{ application?.randstad_process?.clientInterview?.submissionType }}</b></h4>
      <p *ngIf="application?.randstad_process?.clientInterview?.submissionType == 'Additional Interview Requested'" class="mb-3 mt-3">
        <i class="icon icon-WL_icon_schedule color-primary calendar-icon"></i>
        {{ application?.randstad_process?.clientInterview?.interviewDate | abDatetime }}
      </p>
      <div class="emailBody" [innerHTML]="application.randstad_process.clientInterview?.submission"></div>
    </div>
  </div>
  <div class="phase-body-section pt-0">
    <h4 class="phase-body-title"><b>Orginal Interview Schedule details</b></h4>
    <h4>{{statusLabel}}</h4>
    <div class="pt-0" *ngIf="!application?.randstad_process?.clientInterview?.hiringManagerFeedback">
      <h4 class="d-inline">{{'interview-hiring-manager.interview_scheduled' | translate}}</h4>
      <img class="click pl-3" src="assets/icons/edit.svg" alt="edit" (click)="editInterviewSchedule()" />
      <p  class="mb-3 mt-3">
        <i class="icon icon-WL_icon_schedule color-primary calendar-icon"></i>
        {{ priorInterviewDate }}
      </p>
      <p class="mb-3 mt-3" [innerHTML]="application?.randstad_process?.clientInterview?.priorSubmissions[originalSubmissionIndex].submission"><img style="margin-left: -4px;" src="assets/icons/note.svg" alt=" />">
      </p>
    </div>
    <div class="hiringManagerFeedback" *ngIf="application?.randstad_process?.clientInterview?.hiringManagerFeedback">
      <label for="hiringManagerFeedback">{{'interview-hiring-manager.hiring_manager_feedback' | translate}}</label>
      <div id="hiringManagerFeedback" class="hiringManagerFeedbackDisplay" [innerHTML]="application?.randstad_process?.clientInterview?.hiringManagerFeedback">
      </div>
    </div>
  </div>
  <div #ctaContainerBoundry id="ctaContainerBoundry"></div>
  <ul #ctaContainer class="action-buttons hz-btn-list">
    <li><app-talent-process-cta
      *ngIf="!application?.randstad_process?.clientInterview?.hiringManagerFeedback && !userLob.checkLob('RGS')"
        [id]="'reviewAm-update-submission-button'"
        [invertColor]="true"
        (execute)="emitUpdateSubmissionClick()">
        {{ 'review-account-manager.update_submission' | translate }}
      </app-talent-process-cta></li>
    <li>
      <app-talent-process-cta
        [id]="'interviewHm-not-a-fit'"
        [invertColor]="true"
        (execute)="emitUnfitClick()">
        {{'interview-hiring-manager.not_a_fit' | translate}}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        *ngIf="!application?.randstad_process?.clientInterview?.hiringManagerFeedback"
        [id]="'log-hiring-manager-feedback-button'"
        (execute)="openLogFeedbackModal()">
        {{'interview-hiring-manager.log_hiring_managers' | translate}}
      </app-talent-process-cta>
      <app-talent-process-cta
        *ngIf="application?.randstad_process?.clientInterview?.hiringManagerFeedback"
        [id]="'save-interview-button'"
        (execute)="accept()">
        {{'interview-hiring-manager.continue_to_offer' | translate}}
      </app-talent-process-cta>
    </li>
  </ul>
</ng-template>


<!-- Plan interview phase. -->
<ng-template #planInterview>
  <h4>{{statusLabel}}</h4>
  <div class="form-group">
    <div class="row mt-0">
      <div class="col-lg-5">
        <label for="interviewDate">{{'interview-hiring-manager.date' | translate}}</label>
        <div class="input-group search">
          <input
            name="interviewDate"
            [(ngModel)]="interviewDate"
            id="interviewDate"
            type="text"
            placeholder="{{'interview-hiring-manager.date_format' | translate}}"
            [minDate]="minDate.toDate()"
            [bsConfig]="datePickerConfig"
            (bsValueChange)="onDatePicked($event)"
            class="form-control calendar-input"
            bsDatepicker />
          <i class="icon icon-WL_icon_schedule color-primary calendar-icon"></i>
          <div *ngIf="showErrorDate" class="invalid-feedback">{{'interview-hiring-manager.interview_date_is' | translate}}</div>
        </div>
      </div>
      <div class="col-3">
        <ng-template #popTemplate>
          <timepicker removeMeridian
            [(ngModel)]="interviewDate"
            [ngModelOptions]="{standalone: true}"
            [showMeridian]="true"
            minuteStep="15">
          </timepicker>
        </ng-template>
        <label for="timePicker">{{'interview-hiring-manager.time' | translate}}</label>
        <div class="input-group search">
          <input
            type="text"
            mdInput
            [readonly]="true"
            id="timePicker"
            [ngModel]="interviewDate | date: 'h:mm' "
            [popover]="popTemplate"
            class="form-control timePicker"
            [outsideClick]="true"
            placement="bottom"
            container="body"  />
          <i class="icon icon-WL_icon_time color-primary"></i>
          <div *ngIf="showErrorDate" class="invalid-feedback">{{'interview-hiring-manager.interview_date_is' | translate}}</div>
        </div>
      </div>
      <div class="col-3 paddingT32">
        <div class="btn-group" btnRadioGroup>
          <label btnRadio="AM" class="btn btn-primary meridianBtn" (click)="changeMeridian(interviewDate,'AM')"
                 [ngClass]="{'selected': (interviewDate | date: 'a') === 'AM'}" tabindex="0" role="button">{{'interview-hiring-manager.am' | translate}}</label>
          <label btnRadio="PM" class="btn btn-primary meridianBtn" (click)="changeMeridian(interviewDate,'PM')"
                 [ngClass]="{'selected': (interviewDate | date: 'a') === 'PM'}" tabindex="0" role="button">{{'interview-hiring-manager.pm' | translate}}</label>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <form [formGroup]="hmForm" class="col-lg-12 col-md-12">
        <div class="form-group">
          <label for="hmAvailability">{{'interview-hiring-manager.additional_interview_information' | translate}}</label>
          <app-ckeditor
            id="hmAvailability"
            class="text-box w-100"
            [parentForm]="hmForm"
            formConName="interview"
            height="120"
            [enableMentions]="true">
          </app-ckeditor>
          <div *ngIf="showErrorNote" class="invalid-feedback">{{'interview-hiring-manager.interview_information_is' | translate}}</div>
        </div>
      </form>
    </div>
  </div>
  <div #ctaContainerBoundry id="ctaContainerBoundry"></div>
  <ul #ctaContainer class="action-buttons hz-btn-list">
    <li>
      <app-talent-process-cta
        [id]="'interviewHm-not-a-fit'"
        [invertColor]="true"
        (execute)="emitUnfitClick()">
        {{'interview-hiring-manager.not_a_fit' | translate}}
      </app-talent-process-cta>
    </li>
    <li>
      <app-talent-process-cta
        [id]="'save-interview-button'"
        (execute)="submitInterview()">
        {{'interview-hiring-manager.save_interview_schedule' | translate}}
      </app-talent-process-cta>
    </li>
  </ul>
</ng-template>
