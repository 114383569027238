import { Activities } from '@allbirds-ui/allbirds-types';
import { createModel, Mapping, Model } from './model';
import { IsoToMoment } from './serializers/moment.serializer';
import { Moment } from 'moment';
import { Serial } from './decorators/serial.decorator';
export import RawTalentActivity = Activities.Activity;

class TalentActivityMapping extends Mapping<RawTalentActivity> {
  @Serial(IsoToMoment) activityTimestamp?: Moment;
  @Serial(IsoToMoment) timestamp?: Moment;
}

export interface TalentActivity extends Model<TalentActivityMapping> {}

export const TalentActivity = createModel<TalentActivity>(TalentActivityMapping);
