import { Pipe, PipeTransform } from '@angular/core';
import { Moment } from 'moment';

@Pipe({
  name: 'abDatetime'
})
export class ABDatetimePipe implements PipeTransform {
  transform(input: Moment): any {
    if (!input) {
      return 'None';
    }
    return input.formatDatetime();
  }
}
