import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import moment from 'moment';

import { ApiService } from '../api/api.service';
import { CacheService } from '../cache/cache.service';
import { AuthService } from '../auth/auth.service';
import {
    dateToTimestamp,
    convertDateStringToMonthYearDay
} from '../utility/formatters';
import { WORK_AUTH_STATUS, usStates, RequiredReferralFields } from 'src/app/shared/services/talent-creation/talent-creation-types';
import { UtilityService } from '../utility/utility.service';

@Injectable({
    providedIn: 'root'
})
export class TalentCreationService {

    public talentProfile: any = null;
    public talentCreationState = new BehaviorSubject<'edit' | 'create'>(null);
    //   talentProfileObservable = this.talentProfile.asObservable();

    constructor(
        private _api: ApiService,
        private _cache: CacheService,
        private _auth: AuthService
    ) { }

    getTalentProfile(profileID: string) {
        this._api.getApplicant({ profileID })
            .toPromise()
            .then((response) => {
                const profile = response[0];
                this.storeProfile(profile);
            });
    }

    setTalentCreationState(talentCreationState: 'edit' | 'create') {
        this.talentCreationState.next(talentCreationState);
    }

    storeProfile(profile: any) {
        // Using toJSON method with second param as true so we can get a JSON copy of the profile that contains all the fields (including internal ones)
        if (profile.toJSON) {
            this.talentProfile = profile.toJSON(undefined, { internals: true });
            console.log('toJson is called', this.talentProfile.allbirds_metadata.assignments);
        } else {
            // in case the profile object does not has the toJSON method, then we parse using parse(stringify) to remove the sirealization issues we were getting
            this.talentProfile = JSON.parse(JSON.stringify(profile));
            console.log('JsonStringify is called', this.talentProfile.allbirds_metadata.assignments);
        }
    }

    clearStoreProfile(): void {
        this.talentProfile = null;
    }

    updateProfileObject(
        formValues: any, isEdit = false, desiredLocations: string[], resumeUpdateTime: any,
        resumeLastUpdatedDate: any, lastPosition: any, candidateEngagementTime: any,
        qualificationSummary: any, certifications: any, resumeUrl: string, userLob: 'RT' | 'RGS' | 'CR',
        jobObjective: any, candidateUpdateTime: any, newResumeUploadedOnEdit: boolean,
        additionalPhoneRecords: any, attachments: any, referredById: string, requiredReferralFields: RequiredReferralFields, primaryRecruiter: any, secondaryRecruiter: any, isCella: boolean
    ) {
        // console.log('formValues: ', formValues);
        // this.talentProfile = Object.assign({}, this.talentProfile);
        const { email, resume, basicInformation, preferences, skillsAndExperience, education, linkedinProfileUrl } = formValues;
        const { firstSection, secondSection, thirdSection, fourthSection, fifthSection } = basicInformation;
        const { firstName, middleName, lastName, source, employeeType, referredBy, recruitingSource, recruitingSourceId, referralNotes } = firstSection;
        const { primaryAddress, cityStateZip } = secondSection;
        const { vendorCompanyName, vendorCompanyRandstadApproved,vendorContactFirstName,vendorContactLastName,vendorContactEmailAddress,vendorContactPhoneNumber } = fifthSection;
        const forWorkAuthorizationStatus = fourthSection.workAuthorizationStatus;
        const workAuthorizationStatus = WORK_AUTH_STATUS[fourthSection.workAuthorizationStatus];
        const immigrationSpecialist = fourthSection.immigrationSpecialist;
        const dbcQualified = fourthSection.dbcQualified;
        const securityClearance = fourthSection.securityClearance;
        const militaryVeteran = fourthSection.militaryVeteran;

        const { skills, yearsOfExperience, priorWorkHistory } = skillsAndExperience;
        const { willingToTransferDBC, dateAvailable } = preferences;
        const phones = thirdSection;

        if (source) { this.talentProfile.source = source; }
        if (source && source !== 'unknown') {
            this.talentProfile.referral = {...this.talentProfile.referral, source};
        }

        if(this._auth?.user?.Source === 'CR') {
            if(!isEdit) this.talentProfile.externalSystemLob = 'CR';
            if(primaryRecruiter) this.talentProfile.primaryRecruiter = primaryRecruiter;
            if(secondaryRecruiter) this.talentProfile.secondaryRecruiter = secondaryRecruiter;
            if(formValues.preferredPronoun) this.talentProfile.preferredPronoun = formValues.preferredPronoun;
        }

        if(this._auth?.user?.Source === 'RT') {
            if(primaryRecruiter) this.talentProfile.primaryRecruiter = primaryRecruiter;
            if(secondaryRecruiter) this.talentProfile.secondaryRecruiter = secondaryRecruiter;
            if(formValues.preferredPronoun) this.talentProfile.preferredPronoun = formValues.preferredPronoun;
        }

        if(isCella && isEdit) {
            if(primaryRecruiter) this.talentProfile.primaryRecruiter = primaryRecruiter;
            if(secondaryRecruiter) this.talentProfile.secondaryRecruiter = secondaryRecruiter;
            if(formValues.preferredPronoun) this.talentProfile.preferredPronoun = formValues.preferredPronoun;
        }

        /** RGS and RT have different required fields, so split logic */
        if (userLob?.checkLob('RGS')) {
          if (source && source !== 'Referral') {
            // clear the referral related fields, only source
            this.talentProfile.referral =  { source };
          } else if (source === 'Referral') {
            // Is referral, add required RGS fields to payload
            if (requiredReferralFields.recruitingSource && recruitingSource) {
              this.talentProfile.referral = {...this.talentProfile.referral, sourceCategory: recruitingSource};
            }
            if (requiredReferralFields.referredBy && referredBy) {
              const [referrerFirstName, referrerLastName] = referredBy.split(' ');
              this.talentProfile.referral.firstName = referrerFirstName;
              this.talentProfile.referral.lastName = referrerLastName;
            } else {
              this.talentProfile.referral.firstName = '';
              this.talentProfile.referral.lastName = '';
            }
            if (requiredReferralFields.referredBy && referredById) {
              // strip prefix from talent ID for RGS
              const { idNumber } = UtilityService.splitFrontOfficeId(referredById)
              this.talentProfile.referral.employeeId = idNumber;
            } else {
              this.talentProfile.referral.employeeId = '';
            }
            if (requiredReferralFields.recruitingSourceId) {
              this.talentProfile.referral.recruitingSourceId = recruitingSourceId;
            } else {
              this.talentProfile.referral.recruitingSourceId = '';
            }
          }

          // delete fields used by RT
          delete this.talentProfile.referral.referralNotes;

        } else if (userLob === 'RT') {
          if (referredBy) {
            const [firstName, lastName] = referredBy.split(' ');
            this.talentProfile.referral.firstName = firstName;
            this.talentProfile.referral.lastName = lastName;
          }

          if (referralNotes) {
            this.talentProfile.referral.referralNotes = referralNotes;
          }

          if (referredById) {
            // do not strip talent ID, backend does this for RT
            this.talentProfile.referral.employeeId = referredById;
          }

          // delete fields used by RGS
          delete this.talentProfile.referral.sourceCategory;
          delete this.talentProfile.referral.recruitingSourceId;
        }

        if (yearsOfExperience) { this.talentProfile.yearsExperience = yearsOfExperience; } // TODO: doesn't always show up - CLEAR
        if (vendorCompanyName) { this.talentProfile.vendorCompanyName = vendorCompanyName; }
        if (vendorContactFirstName) {
            this.talentProfile.vendor = this.talentProfile.vendor ? this.talentProfile.vendor : {}
            this.talentProfile.vendor.vendorContactFirstName = vendorContactFirstName;
        }
        if (vendorContactLastName) {
            this.talentProfile.vendor = this.talentProfile.vendor ? this.talentProfile.vendor : {}
            this.talentProfile.vendor.vendorContactLastName = vendorContactLastName;
        }
        if (vendorContactEmailAddress) {
            this.talentProfile.vendor = this.talentProfile.vendor ? this.talentProfile.vendor : {}
            this.talentProfile.vendor.vendorContactEmailAddress = vendorContactEmailAddress;
        }
        if (vendorContactPhoneNumber) {
            this.talentProfile.vendor = this.talentProfile.vendor ? this.talentProfile.vendor : {}
            this.talentProfile.vendor.vendorContactPhoneNumber = vendorContactPhoneNumber;
        }
        if (vendorCompanyRandstadApproved) {
            this.talentProfile.vendorCompanyRandstadApproved = vendorCompanyRandstadApproved;
        }
        if (workAuthorizationStatus) {
            this.talentProfile.workAuthorizations = [workAuthorizationStatus];
        }
        if (forWorkAuthorizationStatus) {
            this.talentProfile.forWorkAuthorizationStatus = forWorkAuthorizationStatus;
        }
        if (immigrationSpecialist) {
          this.talentProfile.immigrationSpecialist = immigrationSpecialist;
        }
        if (dbcQualified) {
          this.talentProfile.dbcQualified = dbcQualified;
        }
        if (securityClearance) {
          this.talentProfile.securityClearances = [ { level: securityClearance } ];
        }
        if (militaryVeteran) {
          this.talentProfile.militaryVeteran = militaryVeteran === 'Yes';
        }
        if (linkedinProfileUrl) {
          this.talentProfile.linkedinProfileUrl = linkedinProfileUrl;
        }
        if (resume && resume.length) {
            let i;
            resume.forEach((r: any, index: number) => {
                if (r.category === 'Primary') {
                    i = index;
                    this.talentProfile.resume = {
                        structuredResume: r.structuredResume,
                        resumeType: 'OTHER_RESUME_TYPE',
                        userComments: r.descript
                    };
                }
            });
        }
        if ((attachments && attachments.length) || (resume && resume.length)) {
            const resumeWithoutPrimary = resume.filter((r: any) => {
                return r.category !== 'Primary';
            });
            this.talentProfile.attachments = resumeWithoutPrimary[0] ? resumeWithoutPrimary.concat(attachments) : attachments;
        }
        if (resumeUpdateTime) { this.talentProfile.resumeUpdateTime = resumeUpdateTime; }
        if (lastPosition) { this.talentProfile.lastPosition = lastPosition; }
        if (willingToTransferDBC || willingToTransferDBC === false) { this.talentProfile.transferToDbc = willingToTransferDBC; }
        if (dateAvailable) { this.talentProfile.dateAvailable = new Date(dateAvailable); } // TODO: doesn't always show up
        if (candidateEngagementTime) { this.talentProfile.candidateEngagementTime = candidateEngagementTime; }
        if (userLob) { this.talentProfile.userLob = userLob; }

        this.setCreationOrModificiationDetails(this.talentProfile, isEdit); // TODO: moment bug and serialization bug
        this.setEmail(email, this.talentProfile);
        this.setName(firstName, lastName, this.talentProfile, middleName);
        this.setPhones(phones, this.talentProfile, userLob, additionalPhoneRecords);
        this.setSkills(skills, this.talentProfile);
        this.setAddresses(primaryAddress, cityStateZip, this.talentProfile);
        this.setWorkPreference(employeeType, desiredLocations, jobObjective, this.talentProfile);
        this.setEmploymentHistory(priorWorkHistory, this.talentProfile); // TODO: serialization bug
        this.setEducationHistory(education, this.talentProfile); // TODO: serialization bug
        this.setAllbirdsMetadata(resumeUrl, resumeLastUpdatedDate, isEdit, this.talentProfile, userLob);
        if (newResumeUploadedOnEdit) { this.setAdditionalValuesAfterNewResumeOnEdit(qualificationSummary, certifications, jobObjective, candidateUpdateTime, resumeLastUpdatedDate, this.talentProfile); }
        if (!isEdit && userLob === 'RGS') { this.setPrimaryUser(this.talentProfile); }
        if (employeeType) { this.talentProfile.employeeTypes = [employeeType]; }

        return this.talentProfile;
    }

    // most of these functions assume that these arrays (names, emails, etc.) only ever have one value
    setEmail(email: string, profile: any) {
        profile.emailAddresses = [{
            emailAddress: email,
            usage: 'PERSONAL'
        }];
    }

    setName(firstName: string, lastName: string, profile: any, middleName?: string) {
        profile.personNames = [{
            structuredName: {
                givenName: firstName,
                middleInitial: middleName || '',
                familyName: lastName
            }
        }];
    }

    setCreationOrModificiationDetails(profile: any, isEdit = false) {
        const user = this._auth.user;
        let now = new Date().toISOString();
        now = dateToTimestamp(now);
        // profile.candidateUpdateTime = now;

        // specifically handle profile.createTime being cast to a Moment object for some reason
        if (profile.createTime) {
            if (!(typeof profile.createTime === 'string')) {
                if (moment.isMoment(profile.createTime)) {
                    profile.createTime = profile.createTime.toDate().toISOString();
                }
            }
            profile.createTime = dateToTimestamp(profile.createTime);
        }

        // if (profile.createTime && typeof profile.createTime === 'string') { //API SERVICE OLD WAY
        //     profile.createTime = dateToTimestamp(profile.createTime);
        // }

        if (!isEdit) {
            profile.createTime = now;
            profile.createdByUser = `${user.FirstName} ${user.LastName}`;
            profile.createdByUserEmail = user.EmailAddr;
            profile.createdByUserBackOfficeId = user.BackOfficeID;
            profile.createdByUserFrontOfficeId = user.Oprid;
        }

        profile.modifiedByUser = `${user.FirstName} ${user.LastName}`;
        profile.modifiedByUserEmail = user.EmailAddr;
        profile.modifiedByUserBackOfficeId = user.BackOfficeID;
        profile.modifiedByUserFrontOfficeId = user.Oprid;
        profile.candidateUpdateTime = profile.candidateUpdateTime || now;
        // profile.updateTime = now;
    }

    setPhones(phones: any, profile: any, userLob: any, additionalPhoneRecords: any) {
        if (phones && phones.length) {
            if (additionalPhoneRecords.length) {
                additionalPhoneRecords = additionalPhoneRecords.map((record: any) => {
                    return {
                        phoneNumber: record.number,
                        phoneType: record.type,
                        usage: record.usage
                    };
                });

                phones = [...phones, ...additionalPhoneRecords];
            }

            phones.forEach((record: any) => {
                if (record.usage) { return; } // To avoid overwrite of the current usage on edit for the additional phone records

                record.phoneType = (record.phoneType === null || record.phoneType === '') ? 'PHONE_TYPE_UNSPECIFIED' : record.phoneType;

                if (record.phoneNumber) {
                    // removing special characters from phone input.
                    record.phoneNumber = record.phoneNumber.replace(/\D/g, '');
                }

            });
            // remove the empty phone numbers
            phones = phones.map((r: any) => r.phoneNumber ? r : null).filter(Boolean);

            profile.phoneNumbers = phones.map((record: any) => {
                return {
                    number: record.phoneNumber,
                    usage: this.setPhoneUsageByLob(record, userLob),
                    type: record.phoneType === 'WORK-LANDLINE' ? 'LANDLINE' : record.phoneType // Special case for RGS work phone type
                };
            });
        }

        if (userLob.checkLob('RGS') && phones && !phones.length && !additionalPhoneRecords.length) {
            profile.phoneNumbers = [{
                'type': null,
                'number': null
            }];
        }
    }

    setSkills(skills: any, profile: any) {
        // set default value for skills level
        if (skills && skills.length) {
            skills.forEach((skill: any) => {
                skill.level = skill.level === '' ? 'SKILL_PROFICIENCY_LEVEL_UNSPECIFIED' : skill.level;
            });
            profile.skills = skills;
        }
    }

    setAddresses(primaryAddress: any, cityStateZip: any, profile: any) {
        // preserve languageCode, regionCode, and revision
        let languageCode = 'en-US';
        let regionCode = 'US';
        let revision = 0;
        if (
            profile.addresses &&
            profile.addresses.length &&
            profile.addresses[0].structuredAddress
        ) {
            languageCode = profile.addresses[0].structuredAddress.languageCode || languageCode;
            regionCode = profile.addresses[0].structuredAddress.regionCode || regionCode;
            revision = profile.addresses[0].structuredAddress.revision || 0;
        }

        profile.addresses = [{
            structuredAddress: {
                regionCode,
                postalCode: cityStateZip.zipCode,
                locality: cityStateZip.cityName,
                addressLines: Array.isArray(primaryAddress) ? primaryAddress : [primaryAddress || 'N/A'],
                languageCode,
                administrativeArea: cityStateZip.stateName,
                revision
            },
            current: {
                value: true
            },
            usage: ''
        }];

        // this logic seems to make it such that, if a primary address is ever set as 'N/A', it cannot be changed ever afterward.
        // this seems wrong, so we took it out with the TC&E refactor
        // JDS

        // if (isEdit) {
        //     if (profile.primaryAddress[0] === 'N/A') {
        //         parsedProfile.addresses[0].structuredAddress.addressLines = profile.primaryAddress;
        //     } else {
        //         parsedProfile.addresses[0].structuredAddress.addressLines = Array.isArray(profile.primaryAddress) ? profile.primaryAddress : [profile.primaryAddress];
        //     }
        //  }


        // JDS - why is this... this?
        profile.addresses[0].usage = profile.addresses[0].structuredAddress.addressLines[0] ? 'PERSONAL' : 'CONTACT_INFO_USAGE_UNSPECIFIED';
    }

    setWorkPreference(employeeType: string, desiredLocations: string[], jobObjective: any, profile: any) {
        if (!profile.workPreference) { profile.workPreference = {}; }

        // jobAddresses
        profile.workPreference.jobAddresses = desiredLocations && desiredLocations.length ?
            desiredLocations.map((location: any) => {
                return {
                    structuredAddress: {
                        administrativeArea: location,
                        languageCode: 'en-US',
                        regionCode: 'US'
                    }
                };
            }) : [];

        // employeeTypes
        if (employeeType) {
            profile.workPreference.employeeTypes = [employeeType];
        }

        // jobObjective
        // TO DO: confirm values with Nisha and fix jobObjective always setting to null
        if (jobObjective) {
            profile.workPreference.jobObjective = jobObjective;
        }
    }

    setEmploymentHistory(priorWorkHistory: any, profile: any) {
        profile.employmentRecords = priorWorkHistory
            .filter((record: any) => record.jobTitle || record.company || record.description || record.startDate || record.endDate)
            .map((record: any) => {
                return {
                    jobTitle: record.jobTitle,
                    employerName: record.company,
                    jobDescription: record.description,
                    startDate: record.startDate ? convertDateStringToMonthYearDay(record.startDate) : undefined,
                    endDate: record.endDate ? convertDateStringToMonthYearDay(record.endDate) : undefined,
                    isCurrent: { value: record.isCurrent || false } // Failing sometimes due to mapping errors (isCurrent === string?)
                };
            });
    }

    setEducationHistory(education: any[], profile: any) {
        profile.educationRecords = education
            .filter((record: any) => record.locality || record.administrativeArea || record.startDate || record.endDate || record.degreeType || record.schoolInstitutionName)
            .map((record: any) => {
                const educationRecord = {
                    address: {
                        structuredAddress: {
                            regionCode: 'US',
                            locality: record.locality,
                            administrativeArea: record.administrativeArea,
                            languageCode: 'en-US',
                            revision: 0
                        },
                        usage: 'SCHOOL'
                    },
                    startDate: record.startDate ? convertDateStringToMonthYearDay(record.startDate) : undefined,
                    endDate: record.endDate ? convertDateStringToMonthYearDay(record.endDate) : undefined,
                    structuredDegree: {
                        fieldsOfStudy: [] as any,
                        degreeType: record.degreeType
                    },
                    schoolName: record.schoolInstitutionName
                };

                return educationRecord;
            });
    }

    setAllbirdsMetadata(resumeUrl: string, resumeLastUpdatedDate: any, isEdit = false, profile: any, userLob: string) {
        if (!profile.allbirds_metadata) {
            profile.allbirds_metadata = {};
        }

        profile.allbirds_metadata.resumeLink = resumeUrl;
        if (resumeLastUpdatedDate) { profile.allbirds_metadata.resumeLastUpdatedDate = resumeLastUpdatedDate; } // TODO: why are we setting this here? - CLEAR?
        // We only do this for RT at the moment. For RGS on create we don't write FOTalentStatus and on edit the field should already be included
        // on allbirds_metadata and we don't manupulate it so we just pass it along.
        if (userLob === 'RT') {
            profile.allbirds_metadata.FOTalentStatus = profile.allbirds_metadata.FOTalentStatus || 'Available';
        }

        // if (isEdit) {
        //   if (profile.primeRecruiterrecruiterName) profile.allbirds_metadata.primeRecruiterrecruiterName = profile.primeRecruiterrecruiterName;
        //   if (profile.primeStatus) profile.allbirds_metadata.primeStatus = profile.primeStatus;
        //   if (profile.assignments) profile.allbirds_metadata.assignments = profile.assignments;
        //   if (profile.ab_references) profile.allbirds_metadata.references = profile.ab_references;
        //   if (profile.criminalBackgroundChecks) profile.allbirds_metadata.criminalBackgroundChecks = profile.criminalBackgroundChecks;
        //   if (profile.skillTests) profile.allbirds_metadata.skillTests = profile.skillTests;
        //   profile.allbirds_metadata.allbirds_profile_id = profile.allbirds_profile_id;
        //   profile.allbirds_metadata.elastic_id = profile.elastic_id;
        // }
    }

    setAdditionalValuesAfterNewResumeOnEdit(qualificationSummary: any, certifications: any, jobObjective: any, candidateUpdateTime: any, resumeLastUpdatedDate: any, profile: any) {
        if (!profile.workPreference) { profile.workPreference = {}; }
        profile.qualificationSummary = qualificationSummary;
        profile.certifications = certifications;
        profile.workPreference.jobObjective = jobObjective;
        profile.candidateUpdateTime = candidateUpdateTime;
        if (resumeLastUpdatedDate) { profile.allbirds_metadata.resumeLastUpdatedDate = resumeLastUpdatedDate; }
    }

    setPrimaryUser(profile: any) {
        const user = this._auth.user;

        profile.primaryUser = `${user.FirstName} ${user.LastName}`;
        profile.primaryUserFrontOfficeId = user.Oprid;
        profile.primaryUserBackOfficeId = user.BackOfficeID;
        profile.primaryByUserEmail = user.EmailAddr;
    }

    // TODO: will be used later per ticket https://randstadglobal.atlassian.net/browse/DF044-4793
    convertStateNameToAbbrev(state: string) {
        if (state && state.length > 2 && state !== 'Anywhere' && state !== 'Remote') {
            return usStates[state];
        }
        return state;
    }

    createNewProfile(): any {
        const newResume: any = {
            'addresses': [
                {
                    'structuredAddress': {
                        'addressLines': null,
                        'locality': null,
                        'administrativeArea': null,
                        'postalCode': null
                    }
                }
            ],
            'allbirds_metadata': {
                'resumeLastUpdatedDate': ''
            },
            'referral': {
                'firstName': '',
                'lastName': '',
                'referralNotes': '', // RT optional field
                'sourceCategory': '', // RGS 'Customer' | 'Outside Agency' | 'Randstad Other OPCO' | 'Randstad Talent'
                'employeeId': '', // Referral Talent employee ID
                'recruitingSourceId': '' // RGS if referrer is NOT 'Randstand Talent'
            },
            // Should we delete this properties
            // "candidateEngagementTime": "",
            // "candidateUpdateTime": {
            //     "seconds": "",
            //     "nanos": ""
            // },
            'certifications': [
                {
                    'displayName': null
                }
            ],
            'educationRecords': [
                {
                    'structuredDegree': {
                        'degreeType': '',
                        'fieldsOfStudy': ''
                    },
                    'schoolName': '',
                    'endDate': {
                        'year': null,
                        'month': null,
                        'day': null
                    },
                    'address': {
                        'structuredAddress': {
                            'administrativeArea': null,
                            'locality': ''
                        }
                    }
                }
            ],
            'emailAddresses': [
                {
                    'emailAddress': ''
                }
            ],
            'employmentRecords': [],
            // Should we delete this properties
            // "lastPosition": {
            //     "employerName": "",
            //     "jobTitle": "",
            //     "startDate": "",
            //     "endDate": ""
            // },
            'personNames': [
                {
                    'structuredName': {
                        'givenName': '',
                        'familyName': ''
                    }
                }
            ],
            'phoneNumbers': [
                // {
                //     "type": null,
                //     "number": null
                // }
            ],
            'qualificationSummary': [],
            'resume': {
                'structuredResume': '',
            },
            'attachments': [],
            'skills': [],
            'workPreference': {
                'jobObjective': [
                    null
                ]
            }
        };

        return newResume;
    }

    private setMonthYearDayDate(date: any): any {
        if (!date) { return undefined; }
        if (date.month) { return date; }
        if (moment.isMoment(date)) {
            date = date.toDate().toISOString();
        }
        return convertDateStringToMonthYearDay(date);
    }

    private setPhoneUsageByLob(record: any, lob: string): string {
        if (record.usage) { return record.usage; }

        let usage = '';

        if (lob === 'RT') { // this covers also RE lobs
            usage = this.setPhoneUsageRT(record.phoneType);
        } else if (lob === 'RGS') {
            usage = this.setPhoneUsageRGS(record.phoneType);
        }

        return usage;
    }

    private setPhoneUsageRT(type: string): string {
        let usage = '';

        switch (type) {
            case 'LANDLINE': // Home
                usage = 'PERSONAL';
                break;
            case 'PHONE_TYPE_UNSPECIFIED': // Work
                usage = 'WORK';
                break;
            case 'MOBILE': // Mobile
                usage = 'PERSONAL';
                break;
            default:
                break;
        }

        return usage;
    }

    private setPhoneUsageRGS(type: string): string {
        let usage = '';

        switch (type) {
            case 'LANDLINE': // Home
                usage = 'PERSONAL';
                break;
            case 'WORK-LANDLINE': // Work
                usage = 'WORK';
                break;
            case 'MOBILE': // Mobile
                usage = 'PERSONAL';
                break;
            default:
                break;
        }

        return usage;
    }
}
