import { Component, OnDestroy, OnInit } from '@angular/core';
import { steps } from './onboarding-phases';
import { TalentProcessService } from 'src/app/shared/services/talent-process/talent-process.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { ToastService } from 'src/app/shared/services/toast';
import { MetricsService } from 'src/app/shared/services/metrics/metrics.service';
import { INTG_STEPS } from 'src/app/shared/models/internal/process.model';

@Component({
  selector: 'onboarding-phase',
  templateUrl: './onboarding.component.html',
  styleUrls: [
    './onboarding.component.scss',
    '../recruitment-phases/recruitment-phases.component.scss'
  ]
})
export class OnboardingComponent implements OnInit, OnDestroy {
  collapsible = true;
  show = false;
  appSub: any;
  application: any;

  items: any;

  get userLob(): string { return  this.auth &&  this.auth.user && this.auth.user.Source; }

  constructor(
    private talentProcess: TalentProcessService,
    private auth: AuthService,
    private toast: ToastService,
    private _metrics: MetricsService
  ) {
    // console.log('OnboardingComponent constructed');
  }

  ngOnInit() {
    this.appSub = this.talentProcess.selectedApplication.subscribe((data: any) => {
      this.application = data;
      if (this.application && this.application.randstad_process && !this.application.randstad_process.onboarding) {
        this.application.randstad_process.onboarding = { status: 'not_started' };
      }
      // console.log(data);
      this.setSteps();
    });
  }

  ngOnDestroy() {
    this.appSub.unsubscribe();
  }

  setSteps() {
    if (this.userLob.checkLob('RGS')) {
      this.items = steps['RGS'];
      if (this.application.randstad_process.onboarding.status !== 'complete') { this.show = true; }
    } else {
      this.items = steps['RT'];
    }
  }

  completeOnboarding() {
    this.application.randstad_process.onboarding.status = 'complete';
    this._metrics.addEventToQueue(this.application, 'onboarding completed');
    this.updateApplication(false);
    this.show = false;
  }

  reopenOnboarding() {
    this.application.randstad_process.onboarding.status = 'started';
    this._metrics.addEventToQueue(this.application, 'onboarding reopened');
    this.updateApplication(false);
  }

  change(key: string) {
    // console.log(key);
    if (this.application.randstad_process.onboarding[key]) {
      this.application.randstad_process.onboarding[key] = null;
    } else {
      this.application.randstad_process.onboarding[key] = new Date();
    }
    this._metrics.addEventToQueue(this.application, `onboarding updated: ${key} ${
      this.application.randstad_process.onboarding[key] ?
        'marked complete' :
        'marked not complete'
    }`);
    this.updateApplication(true);
  }

  updateApplication(checkStatus: boolean) {
    if (checkStatus) { this.setStatus(); }
    const key = this.application.randstad_process.lastProcessStep;
    const intgSteps : INTG_STEPS[] = [];
    this.talentProcess.updateApplication(this.application, intgSteps, key)
      .then((data) => {
        if (data) {
          // this.toast.showToast("Onboarding status notes have been updated");
        } else {
          this.toast.showToast('onboarding.error_updation');
        }
      }, err => {
        console.log(err);
        this.toast.showToast('onboarding.error_updation');
      });
  }

  setStatus() {
    let status = 'not_started';
    let unfinished = false;
    Object.keys(this.items).map(step => {
      if (this.application.randstad_process.onboarding[step]) {
        status = 'started';
      } else {
        unfinished = true;
      }
    });
    if (status === 'started' && unfinished === false) {
      status = 'complete';
    }
    this.application.randstad_process.onboarding.status = status;
  }
}
