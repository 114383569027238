import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { UtilityService } from 'src/app/shared/services/utility/utility.service';
import { ObjectUtilitiesService } from 'src/app/shared/services/utility/object-utilities.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { TalentProcessService } from 'src/app/shared/services/talent-process/talent-process.service';
import { TalentService } from 'src/app/shared/services/talent/talent.service';
import { PreferredContactTypeDisplay } from 'src/app/shared/models/internal/talent-profile.model';
import { TalentProfileService } from './talent-profile.service';
import { drugScreenTypes } from './drug-screen-types';
import { EmploymentRecord } from '../../../models/external/employment-record.model';
import { User } from '../../../models/external/user.model';
import { BackgroundCheck, DegreeType, Money, ProfileSkill, Reference, SecurityClearance } from 'src/app/shared/models/external/misc.model';
import { Profile } from '../../../models/external/profile.model';
import { EducationRecord } from '../../../models/external/education-record.model';
import { ExplainService } from 'src/app/shared/services/explain/explain.service';
import { IterableService } from 'src/app/shared/services/iterable/iterable.service';

@Component({
  selector: 'app-talent-profile',
  templateUrl: './talent-profile.component.html',
  styleUrls: ['./talent-profile.component.scss']
})
export class TalentProfileComponent implements OnInit, OnDestroy {
  moment = moment;
  filterTypes = ['all', 'qualifications', 'recruitment info', 'preferences'];
  activeFilter = 'all';
  user: User;
  skills: ProfileSkill[] = [];
  skillsMoreToggle = false;
  workExperience: EmploymentRecord[] = [];
  workExperienceDescriptionToggle = true;
  industryExperience: any[] = [];
  environmentExperience: any[] = [];
  educationRecords: EducationRecord[] = [];
  certifications: any[] = [];
  authorizations: any[] = [];
  clearance: SecurityClearance = null;
  references: Reference[] = [];
  primeStatus = '';
  primeRecruiterName = '';
  primaryRecruiterName: string = '';
  secondaryRecruiters: any[] = [];
  criminalBackgroundChecks: BackgroundCheck[] = [];
  drugScreens: BackgroundCheck[] = [];
  skillTests: any[] = [];
  address = '';
  preferences: any[] = [];
  employmentTypes: any[] = [];
  shifts: any[] = [];
  compensation: any = {};
  commute: any = {};
  canRelocate: boolean = null;
  jobInterests: any[] = [];
  motivators: string = null;
  availability = '';
  jobTitlePreferences: any[] = [];
  elasticData: Profile;
  talentProcessSub: Subscription;
  preferredContactType: string = PreferredContactTypeDisplay.CONTACT_TYPE_UNSPECIFIED;

  preferredAddresses: any[] = [];
  externalSystem: string;
  externalSystemLob: string;

  keywordsToHighlight: string[] = [];

  skillsHighlightObjects: any[] = [];
  highlightSkillsLength: number = 0;
  defaultNoneHighlitedToShow: number = 10;
  vissiblePillsLength: number = 0;
  isRGSTalent = false;
  preferredPronoun: string[];
  frontOfficeTalentId: { prefix: string, idNumber: string };
  talentLob: string;


  constructor(
    private utils: UtilityService,
    private objectUtilities: ObjectUtilitiesService,
    private _auth: AuthService,
    private talentProcess: TalentProcessService,
    private talentService: TalentService,
    private zone: NgZone,
    private talentProfileService: TalentProfileService,
    private _explain: ExplainService
  ) { }

  ngOnInit() {
    this.user = this._auth.user;
    // /* reset elastic data */
    this.elasticData = null;
    /* retrieve elastic data */
    this.talentProcessSub = this.talentProcess.selectedProfile.subscribe(data => {
      // console.log('[profileTab] received', data);
      this.zone.run(() => {
        this.elasticData = data;
        this.setData();
      });
    });

    this.talentLob = UtilityService.extractTalentLobFromTalentId(this.frontOfficeTalentId?.prefix, this.externalSystem, this.externalSystemLob)
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    if (this.talentProcessSub) {
      this.talentProcessSub.unsubscribe();
    }
  }

  setData() {
    this.setIsRGSTalent();
    this.setSkills();
    this.setWorkExperience();
    this.setIndustryExperience();
    this.setEnvironment();
    this.setEducation();
    this.setCertifications();
    this.setAuthorizations();
    this.setSecurityClearance();
    this.setReferences();
    this.setPrimeStatus();
    this.setPrimeRecruiter();
    this.setPrimaryRecruiter();
    this.setSecondaryRecruiter();
    this.SetDrugScreenAndCriminalBackgroundChecks();
    this.setSkillTests();
    this.setEmploymentTypes();
    this.setShifts();
    this.setCompensation();
    this.setCommute();
    this.setCanRelocate();
    this.setJobInterests();
    this.setMotivators();
    this.setAvailability();
    this.setPreferredJobTitles();
    this.setPreferredAddresses();
    this.setPreferredContactType();
    this.setPreferredPronoun();
    this.setFrontOfficeTalentId();
    this.setExternalSystem();
    // this.setAddress();
  }

  setPreferredContactType() {
    if (this.elasticData && this.elasticData.preferredContactTypes && this.elasticData.preferredContactTypes.length > 0) {
      // Values should be separated by a string
      const elasticVal = this.elasticData.preferredContactTypes;
      this.preferredContactType = elasticVal.map(val => {
        return PreferredContactTypeDisplay[val];
      }).join(',');
    }
  }

  setIsRGSTalent(){
    if (this.elasticData) {
      const lob = IterableService.extractLobFromProfile(this.elasticData);
      this.isRGSTalent = lob.checkLob('RGS');
    }
  }

  setFrontOfficeTalentId() {
    if (this.elasticData?.externalId || this.elasticData?.allbirds_metadata?.allbirds_profile_id) {
      this.frontOfficeTalentId = this.elasticData?.externalId
        ? UtilityService.splitFrontOfficeId(this.elasticData.externalId) : UtilityService.splitFrontOfficeId(this.elasticData?.allbirds_metadata?.allbirds_profile_id);
    }
  }

  setShifts() {
    if (this.elasticData?.workAvailability?.length > 0 && this.elasticData.workAvailability[0].shifts) {
      this.shifts = this.elasticData.workAvailability[0].shifts;
    }
  }

  getMoneyAmount(money: Money): number {
    return Number(money.units) + money.nanos / 1000000000;
  }

  setCompensation() {

    if (this.objectUtilities.checkNestedForPath(this.elasticData, 'workPreference.minCompensation.entries')) {
      this.compensation.minimum = this.getMoneyAmount(this.elasticData.workPreference.minCompensation.entries[0].amount);
    }

    if (this.elasticData
      && this.elasticData.workPreference
      && this.elasticData.workPreference.preferredCompensation) {
      if (
        this.elasticData.workPreference.preferredCompensation[0]
        && this.elasticData.workPreference.preferredCompensation[0].entries[0]
        && this.elasticData.workPreference.preferredCompensation[0].entries[0].amount
        && this.elasticData.workPreference.preferredCompensation[0].entries[0].amount.units
      ) {
        this.compensation.preferred = this.getMoneyAmount(this.elasticData.workPreference.preferredCompensation[0].entries[0].amount);
        this.compensation.unit = this.elasticData.workPreference.preferredCompensation[0].entries[0].unit;
      }
    }
  }

  setCommute() {
    if (this.elasticData && this.elasticData.workPreference) {
      if (this.elasticData.workPreference.preferredCommuteDistanceMiles
        && this.elasticData.workPreference.preferredCommuteDistanceMiles.value) {
        this.commute.preferred = this.elasticData.workPreference.preferredCommuteDistanceMiles.value;
      } else {
        this.commute.preferred = null;
      }
      if (this.elasticData.workPreference.maxCommuteDistanceMiles) {
        this.commute.max = this.elasticData.workPreference.maxCommuteDistanceMiles.value;
      } else {
        this.commute.max = '';
      }
      if (this.objectUtilities.checkNestedForPath(this.elasticData, 'workPreference.maxCommute.seconds')) {
        try {
          const minutes = this.elasticData.workPreference.maxCommute.seconds / 60;
          this.commute.time = `${minutes} minutes`;
        } catch (err) {
          // todo: test this with real data
          console.error(err);
        }
      } else {
        this.commute.time = '';
      }
    } else {
      this.commute.preferred = null;
      this.commute.time = '';
      this.commute.max = '';
    }
  }

  setCanRelocate() {
    /* Test with populated canrelocate value  */
    if (this.objectUtilities.checkNestedForPath(this.elasticData, 'workPreference.canRelocate.value')) {
      this.canRelocate = this.elasticData.workPreference.canRelocate.value;
    } else {
      this.canRelocate = null;
    }
  }

  jobInterestsContains(...values: string[]): boolean {
    let found = false;
    if (this.jobInterests && this.jobInterests.length) {
      this.jobInterests.forEach((record) => {
        if (record && record.jobRequirement) {
          if (values.includes(record.jobRequirement.jobRequirementType) && record.isInterested) {
            found = true;
          }
        }
      });
    }
    return found;
  }

  setJobInterests() {
    /* Test with populated elastic array  */
    if (this.objectUtilities.checkNestedForPath(this.elasticData, 'workPreference.jobRequirementInterests')) {
      console.log(this.elasticData.workPreference.jobRequirementInterests);
      this.jobInterests = this.elasticData.workPreference.jobRequirementInterests;
    } else {
      this.jobInterests = [];
    }
  }

  setMotivators() {
    /* Test with populated elastic array  */
    if (this.objectUtilities.checkNestedForPath(this.elasticData, 'workPreference.jobObjective')) {
      this.motivators = this.elasticData.workPreference.jobObjective;
    } else {
      this.motivators = null;
    }
  }

  setAvailability() {
    this.availability = this.talentProfileService.getWorkAvailability(this.elasticData);
  }

  setPreferredJobTitles() {
    if (this.elasticData && this.elasticData?.workPreference?.jobTitles && this.elasticData?.workPreference?.jobTitles?.length) {
      this.jobTitlePreferences = this.elasticData.workPreference.jobTitles;
    }
    else {
      this.jobTitlePreferences = ['Not specified']
    }
  }

  setPreferredPronoun() {
    if (this.elasticData && this.elasticData?.preferredPronoun && this.elasticData?.preferredPronoun?.length) {
      this.preferredPronoun = this.elasticData.preferredPronoun;
    }
    else {
      this.preferredPronoun = ['Not specified']
    }
  }

  setExternalSystem() {
    const externalSystem = (this.elasticData as Profile)?.externalSystem ? (this.elasticData as Profile)?.externalSystem : '';
    const externalSystemLob = (this.elasticData as Profile)?.externalSystemLob ? (this.elasticData as Profile)?.externalSystemLob : '';
      if(externalSystem) {
          this.externalSystem = externalSystem;
      }
      if(externalSystemLob) {
          this.externalSystemLob = externalSystemLob;
      }
  }

  setEmploymentTypes() {
    if (this.objectUtilities.checkNestedForPath(this.elasticData, 'workPreference.employmentTypes')) {
      this.employmentTypes = Array.from(new Set(this.elasticData.workPreference.employmentTypes));
    } else {
      this.employmentTypes = [];
    }
  }

  setSkillTests() {
    /* Test with populated elastic array  */
    if (this.objectUtilities.checkNestedForPath(this.elasticData, 'allbirds_metadata.skillTest')) {
      this.skillTests = this.elasticData.allbirds_metadata.skillTest;
    } else {
      this.skillTests = [];
    }
  }

  SetDrugScreenAndCriminalBackgroundChecks(): void {
    const path = 'allbirds_metadata.backgroundCheck';

    if (this.objectUtilities.checkNestedForPath(this.elasticData, path)) {
      const backgroundCheck = this.elasticData.allbirds_metadata.backgroundCheck;

      for (const record of backgroundCheck) {
        if (record.backgroundCheckID in drugScreenTypes) {
          this.drugScreens.push(record);
        } else {
          this.criminalBackgroundChecks.push(record);
        }
      }
    }
  }

  setPrimaryRecruiter() {
    if (this.elasticData?.primaryRecruiter) {
      this.primaryRecruiterName = this.elasticData.primaryRecruiter.user_name;
    } else {
      this.primaryRecruiterName = '';
    }
  }
  setSecondaryRecruiter() {
    if (this.elasticData?.secondaryRecruiter && this.elasticData.secondaryRecruiter?.length > 0) {
      this.secondaryRecruiters = this.elasticData.secondaryRecruiter;
    }
  }
  setPrimeRecruiter() {
    /* Test with populated elastic prime recruiter array  */
    if (this.objectUtilities.checkNestedForPath(this.elasticData, 'allbirds_metadata.primeRecruiter')) {
      this.primeRecruiterName = this.elasticData.allbirds_metadata.primeRecruiterName;
    } else {
      this.primeRecruiterName = '';
    }
  }

  setPrimeStatus() {
    if (this.objectUtilities.checkNestedForPath(this.elasticData, 'allbirds_metadata.primeStatus')) {
      this.primeStatus = this.elasticData.allbirds_metadata.primeStatus;
    } else {
      this.primeStatus = '';
    }
  }

  setReferences() {
    /* Test with populated elastic references array  */
    if (this.objectUtilities.checkNestedForPath(this.elasticData, 'references') && this.elasticData.references.length > 0) {
      this.references = this.elasticData.references;
    } else {
      this.references = [];
    }
  }

  setSecurityClearance() {
    /* Test with populated elastic security clearance array  */
    if (this.elasticData?.securityClearances?.length > 0 && this.elasticData?.securityClearances[0]?.level) {
      this.clearance = this.elasticData.securityClearances[0];
      // console.log(this.clearance)
    } else {
      this.clearance = null;
    }
  }

  setAuthorizations() {
    if (this.elasticData && this.elasticData.workAuthorizations && this.elasticData.workAuthorizations.length > 0) {
      this.authorizations = this.elasticData.workAuthorizations;
    } else {
      this.authorizations = [];
    }
  }

  setCertifications() {
    /* Test with populated elastic certification array  */
    if (this.elasticData && this.elasticData.certifications && this.elasticData.certifications.length > 0) {
      this.certifications = this.elasticData.certifications;
    } else {
      this.certifications = [];
    }
  }

  setEducation() {
    const orderedDegrees: { [key in DegreeType]?: number } = {
      'DEGREE_TYPE_UNSPECIFIED': 1,
      'UPPER_SECONDARY_EDUCATION': 2,
      'ADULT_REMEDIAL_EDUCATION': 3,
      'ASSOCIATES_OR_EQUIVALENT': 4,
      'BACHELORS_OR_EQUIVALENT': 5,
      'MASTERS_OR_EQUIVALENT': 6,
      'DOCTORAL_OR_EQUIVALENT': 7
    };

    if (this.elasticData && this.elasticData.educationRecords && this.elasticData.educationRecords.length > 0) {
      this.educationRecords = this.elasticData.educationRecords.sort((degree1, degree2) => {

        let degreeType1Position = 0;
        if (degree1.structuredDegree && degree1.structuredDegree.degreeType) {
          degreeType1Position = orderedDegrees[degree1.structuredDegree.degreeType];
          if (typeof degreeType1Position === 'undefined') {
            /* this degree type is unknown */
            degreeType1Position = 0;
          }
        }

        let degreeType2Position = 0;
        if (degree2.structuredDegree && degree2.structuredDegree.degreeType) {
          degreeType2Position = orderedDegrees[degree2.structuredDegree.degreeType];
          if (typeof degreeType2Position === 'undefined') {
            /* this degree type is unknown */
            degreeType2Position = 0;
          }
        }

        return (degreeType2Position - degreeType1Position);
      });
    } else {
      this.educationRecords = [];
    }
  }

  setEnvironment() {
    /*todo: test with real es data*/
    if (this.elasticData && this.elasticData.workEnvironmentExperiences && this.elasticData.workEnvironmentExperiences.length > 0) {
      this.environmentExperience = this.elasticData.workEnvironmentExperiences;
    } else {
      this.environmentExperience = [];
    }
  }

  setIndustryExperience() {
    /*todo: test with real es data*/
    if (this.elasticData && this.elasticData.industryExperiences && this.elasticData.industryExperiences.length > 0) {
      this.industryExperience = this.elasticData.industryExperiences;
    } else {
      this.industryExperience = [];
    }
  }

  toggleWorkExperienceDescription(i: number) {
    this.workExperience[i].workExperienceDescriptionToggle = !this.workExperience[i].workExperienceDescriptionToggle;
  }

  setWorkExperience() {
    if (this.elasticData && this.elasticData.employmentRecords && this.elasticData.employmentRecords.length > 0) {
      this.workExperience = this.elasticData.employmentRecords;
    } else {
      this.workExperience = [];
    }

    this.workExperience = this.workExperience.sort(
      (experience1, experience2) => {
        try {
          const endDate1 = experience1.endDate;
          const startDate1 = experience1.startDate;
          const endDate2 = experience2.endDate;
          const startDate2 = experience2.startDate;
          if (endDate1.isSame(endDate2)) {
            return startDate2.diff(startDate1);
          }
          return endDate2.diff(endDate1);
        } catch (err) {
          const missingStr = `Encountered missing sort properties: ${this._getExperienceWarningMsg(experience1, experience2)}`;
          console.warn(missingStr);
          // This should push experiences missing a start/end date to the bottom since a comparison can't be done
          return 1;
        }
      }
    );

    this.workExperience.forEach(record => {
      record.workExperienceDescriptionToggle = this.workExperienceDescriptionToggle;
    });

  }

  private _getExperienceWarningMsg(experience1: EmploymentRecord, experience2: EmploymentRecord) {
    let missingStr = '';
    const missingObj: any = {};
    const exps = [experience1, experience2];
    const expKeys = ['startDate', 'endDate'];
    exps.forEach((exp: any, idx) => {
      // Since there is no id for an employment record, we just have to use the idx of the 2 experiences passed to
      // the array sort function
      const jobTitle = missingObj[exp.jobTitle] ? `${exp.jobTitle || 'unknown'}${idx}` : exp.jobTitle || 'unknown';
      expKeys.forEach((key) => {
        if (!exp[key]) {
          missingObj[jobTitle] = missingObj[jobTitle] ? missingObj[jobTitle] : [];
          missingObj[jobTitle].push(key);
        }
      });
    });
    Object.keys(missingObj).forEach((jobTitle, idx, arr) => {
      // join the missing properties with 'and'. If there are more missing keys append a comma appropriately
      missingStr += `${missingObj[jobTitle].join(' and ')} for ${jobTitle}${arr.length > 1 && (idx + 1) < arr.length ? ',\n' : ''}`;
    });
    return missingStr;
  }

  toggleSkills() {
    this.skillsMoreToggle = !this.skillsMoreToggle;
  }

  setSkills() {
    if (this._explain.keywords && this._explain.keywords.length) {
      this.keywordsToHighlight = this._explain.keywords;
    }

    if (this.elasticData && this.elasticData.skills && this.elasticData.skills.length > 0) {
      this.skills = this.mapSkillsToLiterals(this.elasticData.skills);
      this.setSkillsHighlight();
    } else {
      this.skills = [];
    }
  }

  mapSkillsToLiterals(skills: ProfileSkill[]): Array<any> {
    const arr = skills.map(x => {
      if (x.level === 'SKILL_PROFICIENCY_LEVEL_UNSPECIFIED') {
        return { ...x, level: 'Skill proficiency level unspecified' };
      } else if (x.level === 'UNSKILLED') {
        return { ...x, level: 'Unskilled' };
      } else if (x.level === 'FUNDAMENTAL_AWARENESS') {
        return { ...x, level: 'Fundamental awareness' };
      } else if (x.level === 'NOVICE') {
        return { ...x, level: 'Novice' };
      } else if (x.level === 'INTERMEDIATE') {
        return { ...x, level: 'Intermediate' };
      } else if (x.level === 'ADVANCED') {
        return { ...x, level: 'Advanced' };
      } else if (x.level === 'EXPERT') {
        return { ...x, level: 'Expert' };
      } else {
        return x;
      }
    });
    return arr;
  }

  /**
   * Sets the skill pills highlight and it sorts it.
   */
  setSkillsHighlight(): void {
    this.skillsHighlightObjects = [];
    let first: any = [];
    let others: any = [];
    const pattern = new RegExp(`(${this.keywordsToHighlight.join('|')})`, 'gi',);
    const sortByName = (a: any, b: any) => {
      let nameA = a.displayName.toUpperCase();
      let nameB = b.displayName.toUpperCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;

      return 0;
    }

    //We create an array of objects that we'll use from the template to highglight the pills
    this.skills.forEach(skill => {
      this.skillsHighlightObjects.push({
        displayName: skill.displayName,
        highlight: this.keywordsToHighlight.length ? pattern.test(skill.displayName) : false
      })
    });

    //We use first and others array to show first the highlighted pills and then the others
    if (this.keywordsToHighlight.length) {
      this.skillsHighlightObjects.forEach(obj => {
        if (pattern.test(obj.displayName)) first.push(obj)
        else others.push(obj)
      })

      //we sort individually the arrays to have individual alphabetical order
      first.sort(sortByName);
      others.sort(sortByName);

      this.highlightSkillsLength = first.length;
      this.skillsHighlightObjects = (first.concat(others));

      this.defaultNoneHighlitedToShow = others.length > 10 ? 10 : others.length;
      this.vissiblePillsLength = this.highlightSkillsLength + this.defaultNoneHighlitedToShow;

      if (this.highlightSkillsLength === 0) {
        this.defaultNoneHighlitedToShow = this.skillsHighlightObjects.length > 10 ? 10 : this.skillsHighlightObjects.length;
      }

    } else {
      this.skillsHighlightObjects.sort(sortByName);
      this.vissiblePillsLength = this.skillsHighlightObjects.length > 15 ? 15 : this.skillsHighlightObjects.length;
      this.defaultNoneHighlitedToShow = this.skillsHighlightObjects.length - this.vissiblePillsLength;
    }

  }
  // setAddress() {
  //   let structuredAddress = this.parsePreferredAddressFromElasticData();
  //   if (
  //     structuredAddress &&
  //     structuredAddress.locality &&
  //     structuredAddress.administrativeArea
  //   ) {
  //     this.preferredJobLocation = `${structuredAddress.locality}, ${structuredAddress.administrativeArea}`;
  //   }
  // }

  parseAddressFromElasticData() {
    let structuredAddress: any = {};
    if (
      this.elasticData &&
      this.elasticData.addresses &&
      this.elasticData.addresses[0] &&
      this.elasticData.addresses[0].structuredAddress
    ) {
      structuredAddress = this.elasticData.addresses[0].structuredAddress;
    }

    return structuredAddress;

  }

  toggleFilter(filter: string) {
    this.activeFilter = filter;
  }

  formatUTCDate(utcDateString: string) {
    let formattedDate = '';
    const utcDate = new Date(utcDateString);
    const validDateIndicator = !isNaN(utcDate.getTime());
    if (validDateIndicator) {
      formattedDate = `${this.moment().month(utcDate.getMonth()).format('MMM')} ${utcDate.getUTCDate()}, ${utcDate.getFullYear()}`;
    }
    return formattedDate;
  }

  formattedSpanningDates(record: EmploymentRecord | EducationRecord) {
    let formattedDateString = '';
    let formattedStart = '';
    let formattedEnd = '';


    if (record.startDate) {
      formattedStart = record.startDate.format('MMM YYYY');
    }
    if (record.endDate) {
      formattedEnd = record.endDate.format('MMM YYYY');
    }
    if (formattedStart || formattedEnd) {
      formattedDateString = `${formattedStart} - ${formattedEnd} `;
    }

    return formattedDateString;
  }

  // Field removed from UI due to lack of data but may come back so I'm not deleting it..
  setPreferredAddresses() {
    if (
      !this.elasticData ||
      !this.elasticData.workPreference ||
      !this.elasticData.workPreference.jobAddresses ||
      !this.elasticData.workPreference.jobAddresses.length
    ) {
      this.preferredAddresses = [];
      return;
    }
    // Parse all the preferred city/state combos.
    const { jobAddresses } = this.elasticData.workPreference;
    const addresses = [];
    let i = jobAddresses.length;
    while (i--) {
      if (jobAddresses[i].structuredAddress) {
        const city = jobAddresses[i].structuredAddress.locality || '';
        const state = UtilityService.getStateNameFromAbbreviation(jobAddresses[i].structuredAddress.administrativeArea) || '';
        if (state) {
          addresses.unshift(city ? `${city}, ${state}` : state);
        }
      }
    }
    this.preferredAddresses = addresses;
  }

  highlightText(str: string, keywords: string[]): string {
    if (str && keywords) {
      //This pattern to find the whole word case insensitive (FOR FUTURE ENHANCE)
      // const pattern = new RegExp('\\b(?:' + `${keywords.join('|')}`  + ')\\b', 'gi',);

      const pattern = new RegExp(`(${keywords.join('|')})`, 'gi',);
      return str.replace(pattern, match => `<span class="highlight">${match}</span>`);
    }

    return str;
  }

}
