import { Profile } from "./../../../../../shared/models/external/profile.model";
import { Application } from "./../../../../../shared/models/external/application.model";

import { Component, OnDestroy, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { CustomListService } from "../custom-list.service";
import { LoadingSpinnerService } from "src/app/shared/services/loading-spinner/loading-spinner.service";
import { concatMap, filter, flatMap, mergeAll, mergeMap, take } from "rxjs/operators";
import { ToastClass, ToastService } from "src/app/shared/services/toast";
import { Subscription, noop } from "rxjs";
import { List } from "src/app/shared/models/external/list.model";
import { ListTalent } from "src/app/shared/models/external/list-talent.model";
import moment from "moment";
import { ApiService } from "src/app/shared/services/api/api.service";
import { SuggestEvent } from "src/app/modules/jobs/pages/job-order/job-order-input/job-order-input.component";
import { TranslateService } from "@ngx-translate/core";
import { MassActionService } from "src/app/shared/services/mass-action/mass-action.service";
import { MASS_OPERATION_CONTEXT } from "src/app/shared/components/mass-email-modal/mass-operation-context";
import { RecentActivityListCardDetails } from "src/app/shared/models/internal/recent-activities-list.model";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TalentListProfileInfo } from "src/app/shared/models/external/process/talent-list-profile.model";

@Component({
  selector: "add-to-list-a-modal",
  templateUrl: "./add-to-list-a-modal.component.html",
  styleUrls: ["./add-to-list-a-modal.component.scss"],
})
export class AddToListAModalComponent implements OnInit, OnDestroy {
  talent: any;
  talentName = "this candidate"; // placeholder in case talent name is undefined
  displayedLists: List[];
  privateLists: List[];
  sharedLists: List[];
  isStepOne = true;
  currentId: string;
  currentList: string = 'private';
  listToAddId: string;
  remarksValue: any;
  disabledLists: string[] = [];
  initialListsSub: Subscription;
  suggestSub: Subscription;
  sharedListsSub: Subscription;
  query: string;
  trVariable1: any;
  isDeletedProfile: boolean;

  /**
   * Context is used to determine how to get the data from the list in context.
   * The mass operation lists can be obtained from the mass-action.service and
   * used as is inside the modal (without using a observer to get the changes)
   */
  context: MASS_OPERATION_CONTEXT;
  /**
   * true if this modal is meant to support adding many talents to a list
   */
  isMassAddToList = false;
  /**
   * List of talent to add to a selected list
   * @type {{[google_id: string]: ListTalent}}
   */
  talentList: { [p: string]: ListTalent };
  /**
   * The list of talent that wasn't found in the selected list and thus
   * can be added to the list
   * @type {ListTalent[]}
   */
  notInListTalents: ListTalent[] = [];
  public newListForm: FormGroup;

  /**
   * This is required for activities list mass action.
   */
  notInActivityList: RecentActivityListCardDetails[] = [];
  isNewList: boolean = false;
  listPattern = /^[a-zA-Z0-9 ]*$/;
  constructor(
    private _api: ApiService,
    private _bsModalRef: BsModalRef,
    private _listService: CustomListService,
    private _loading: LoadingSpinnerService,
    private _toast: ToastService,
    private translate: TranslateService,
    private _massAction: MassActionService
  ) { }

  ngOnInit() {
    this.newListForm = new FormGroup({
      listName: new FormControl('',
        [
          Validators.required,
          Validators.maxLength(30),
          Validators.pattern(this.listPattern)
        ])
    });
    this.setTalentName();
    this.setInicialLists();
    if (!this.isMassAddToList) {
      this.trVariable1 = { value: this.talentName };
    }
  }

  ngOnDestroy(): void {
    this.initialListsSub.unsubscribe();
    this.sharedListsSub.unsubscribe();
    if (this.suggestSub) {
      this.suggestSub.unsubscribe();
    }
  }

  closeDialog(): void {
    this._bsModalRef.hide();
  }

  removeFromList(): void {
    this.closeDialog();
  }

  toggleStep(): void {
    this.isStepOne = !this.isStepOne;
  }

  addToList(): void {
    // console.log('adding to list')
    this._loading.show();
    this.closeDialog();
    this._api
      .searchList(this.listToAddId)
      .pipe(
        mergeMap(async (list) => {
          return await this.updateList(list);
        })
      )
      .pipe(take(1))
      .subscribe((data) => {
          data.subscribe((data) => {
              this._loading.hide();
              this._listService.setCurrentListOfLists(); // If you add a talent and go back to talent dashboard, the counts will update
              this._listService.setCurrentSharedListOfLists();
              this.showSuccessToast(data._id);
            },
            (err) => {
              console.error(err);
              this._loading.hide();
            }
          );
      })
  }

  async updateList(list: List) {
    let updatedList: List;
    let eventLog: any = {
      logEvent: "talent was added from another list",
      originListId: this.currentId,
    };
    if (!this.isMassAddToList) {
      const newTalent = this._listService.buildNewTalent(this.talent);
      updatedList = this.updateListWithTalent(list, newTalent);
      if (updatedList.talent) {
        if (updatedList?.talent.length) updatedList.talent_count = updatedList.talent.length;
      }
      eventLog = {
        ...eventLog,
        talentName: `${newTalent.first_name} ${newTalent.last_name}`,
        talentId: newTalent.external_id || newTalent.google_id,
      };
    } else {
      let listStatus;
      if (this.context === MASS_OPERATION_CONTEXT.pipeline) {
        listStatus = await this.getListTalentStatus(this.listToAddId);
      } else if (
        this.context === MASS_OPERATION_CONTEXT.recentActivities ||
        this.context === MASS_OPERATION_CONTEXT.application
      ) {
        listStatus = await this.getListActivityStatus(this.listToAddId);
      } else if (this.context === MASS_OPERATION_CONTEXT.modelsPotential) {
        listStatus = await this.getListActivityStatus(this.listToAddId);
      }

      if (this.listToAddId && listStatus.notInList.length) {
        this.notInListTalents = listStatus.notInList;
        updatedList = this.updateListWithTalent(
          list,
          null,
          listStatus.notInList
        );
        eventLog = {
          ...eventLog,
          talentName: this.notInListTalents
            .map((talent) => `${talent.first_name} ${talent.last_name}`)
            .join(","),
          talentId: this.notInListTalents
            .map((talent) => talent.external_id || talent.google_id)
            .join(","),
        };
      }
    }
    if (updatedList?.talent.length) updatedList.talent_count = updatedList.talent.length;
    return this._api.updateList(updatedList, eventLog);
  }

  async getListTalentStatus(listId: string) {
    const selectedList = this.displayedLists.find((list) => list.id === listId);
    const inList: ListTalent[] = [];
    const talent = Object.entries(this.talentList);
    let notInList: ListTalent[] = [];
    let notInListProfileIds: string[] = [];
    let inListTalent: any;

    for(const [profileId, talentList] of talent ) {
      inListTalent = selectedList.talent.find((listTalent) => listTalent.external_id === talentList.external_id);
      inListTalent && inList.push(inListTalent) || notInListProfileIds.push(profileId);
    }

    let {nonDeletedProfs} = await this._massAction.getDeletedProfiles(notInListProfileIds);
    nonDeletedProfs.map((profile: TalentListProfileInfo) => {
        notInList.push(this._listService.buildNewTalentFromProfile(profile));
    })
    return { listId, inList, notInList };
  }

  async getListActivityStatus(listId: string) {
    const selectedList = this.displayedLists.find((list) => list.id === listId);
    let inListTalent: any;
    let inList: ListTalent[] = [];
    let notInList: ListTalent[] = [];

    if (this.context == MASS_OPERATION_CONTEXT.recentActivities || this.context == MASS_OPERATION_CONTEXT.application) {
      let notInListProfileIds: string[] = [];
      let selectedProfileVal;
      if (this.context == MASS_OPERATION_CONTEXT.application) {
        //Application
        selectedProfileVal = Object.values(this._massAction.selectedApplications);
        selectedProfileVal.map(profile => {
          try {
            inListTalent = selectedList.talent.find((listTalent) => listTalent.external_id === profile.randstad_process?.candidateFrontOfficeID);
          } catch (e) {
            console.log(e);
          }
          inListTalent && inList.push(inListTalent) || notInListProfileIds.push(profile.profile);
        })
      } else {
        //Recent activities
        selectedProfileVal = Object.values(this._massAction.selectedRecentActivities);
        selectedProfileVal.map(profile => {
          inListTalent = selectedList.talent.find((listTalent) => listTalent.external_id === profile.profile.externalId);
          inListTalent && inList.push(inListTalent) || notInListProfileIds.push(profile.profile.name);
        })
      }
      const {nonDeletedProfs} = await this._massAction.getDeletedProfiles(notInListProfileIds);
      nonDeletedProfs.map((profile: TalentListProfileInfo) => {
        notInList.push(this._listService.buildNewTalentFromProfile(profile));
      })
    }
    if (this.context == MASS_OPERATION_CONTEXT.modelsPotential) {
      Object.keys(this._massAction.selectedProfiles).forEach((id) => {
        const profile: Profile = this._massAction.selectedProfiles[id];
        if (profile) {
          //Find talent that exists in the lists selected
          inListTalent = selectedList.talent.find((listTalent) => listTalent.external_id === profile.externalId);
          if (inListTalent) inList.push(inListTalent);
          else {
            //Push talent that is not in the list that exists (so you can add them to it)
            notInList.push(this._listService.buildNewTalent(profile));
          }
        };
      })
    }
    return { listId, inList, notInList };
  };

  showSuccessToast(id: string): void {
    const trList = this.translate.instant("add-to-list-a-model.see_list");
    const trSuccess = {
      value: this.isMassAddToList
        ? this.notInListTalents.length
        : this.talentName,
    };
    const toastCfg = {
      cssClass: ToastClass.SUCCESS,
      actionLinks: [{ text: trList, routerLink: `talent/list/${id}` }],
    };
    this._toast.showToastWithVariables(
      "add-to-list-a-model.success_added",
      trSuccess,
      toastCfg
    );
  }

  selectListToAdd(id: string): void {
    this.listToAddId = id;
  }

  setDisabledLists(): void {
    this.displayedLists.forEach((list) =>
      this.checkIfTalentExistsInList(list.id)
    );
  }

  async checkIfTalentExistsInList(listId: string): Promise<void> {
    const selectedList = this.displayedLists.find((list) => list.id === listId);
    if (!this.isMassAddToList) {
      const talentToAdd = selectedList.talent.find(
        (listTalent) => listTalent.external_id === this.talent.externalId
      );
      if (talentToAdd) {
        this.disabledLists.push(selectedList.id);
      }
    } else if (this.talentList && this.isMassAddToList) {
      let listStatus;
      if (this.context == MASS_OPERATION_CONTEXT.pipeline) {
        listStatus = await this.getListTalentStatus(listId);
        const externalIds = Object.keys(this.talentList).map(
          (googleId) => this.talentList[googleId].external_id
        );
        if (listStatus.inList.length === externalIds.length) {
          this.disabledLists.push(selectedList.id);
        }
      } else if (this.context === MASS_OPERATION_CONTEXT.recentActivities) {
        listStatus = await this.getListActivityStatus(listId);

        if (
          listStatus.inList.length ===
          Object.keys(this._massAction.selectedRecentActivities).length
        ) {
          this.disabledLists.push(selectedList.id);
        }
      } else if (this.context === MASS_OPERATION_CONTEXT.application) {
        listStatus = await this.getListActivityStatus(listId);

        if (
          listStatus.inList.length ===
          Object.keys(this._massAction.selectedApplications).length
        ) {
          this.disabledLists.push(selectedList.id);
        }
      } else if (this.context === MASS_OPERATION_CONTEXT.modelsPotential) {
        listStatus = await this.getListActivityStatus(listId);

        if (
          listStatus.inList.length ===
          Object.keys(this._massAction.selectedProfiles).length
        ) {
          this.disabledLists.push(selectedList.id);
        }
      }
      selectedList.tooltip = this.getToolTipString(
        listStatus.notInList.length,
        listStatus.inList.length
      );
    }
  }

  getToolTipString(notinListLength: number, inListLength: number) {
    let toolTip = "";
    toolTip +=
      notinListLength > 1
        ? this.translate.instant("add-to-list-a-modal.add_many", {
          value1: notinListLength,
        }) + " "
        : this.translate.instant("add-to-list-a-modal.add_one", {
          value1: notinListLength,
        }) + " ";
    toolTip +=
      inListLength > 1
        ? this.translate.instant("add-to-list-a-modal.already_many", {
          value1: inListLength,
        })
        : this.translate.instant("add-to-list-a-modal.already_one", {
          value1: inListLength,
        });
    return toolTip;
  }

  setTalentName(): void {
    if (!this.isMassAddToList && this.talent && this.talent.personNames) {
      this.talentName = `${this.talent.personNames[0].structuredName.givenName} ${this.talent.personNames[0].structuredName.familyName}`;
    }
  }

  setInicialLists(): void {
    this.initialListsSub = this._listService.listOfListsObservable.subscribe(
      (data: List[]) => {
        if (data) {
          this.privateLists = data;
          this.displayedLists = this.privateLists;
          this.setDisabledLists();
        } else {
          this._listService.setCurrentListOfLists();
        }
      }
    );

    this.sharedListsSub = this._listService.sharedListObservable.subscribe(
      (data: List[]) => {
        if (data) {
          this.sharedLists = data;
        } else {
          this._listService.setCurrentSharedListOfLists();
        }
      }
    );
  }

  setLists(event: any): void {
    switch (event.target.value) {
      case "private":
        this.displayedLists = this.privateLists;
        this.currentList = 'private';
        this.setDisabledLists();
        break;
      case "shared":
        this.displayedLists = this.sharedLists;
        this.currentList = 'shared';
        this.setDisabledLists();
        break;
      default:
        break;
    }
  }

  setRemarksValue(val: any): void {
    this.remarksValue = val;
  }

  suggester(event: SuggestEvent<any>): void {
    this.suggestSub = this._api
      .getListNamesSuggestions(event.value, this.currentList)
      .pipe(filter((data) => !!data))
      .subscribe((data) => {
        event.observer.next(data);
      });
  }

  searchListByName(): void {
    const searchByNameSub = this._api
      .getListByName(this.query, this.currentList)
      .pipe(filter((data) => !!data))
      .subscribe((data) => {
        this.displayedLists = data;
        this.setDisabledLists();
        searchByNameSub.unsubscribe();
      });
  }

  setValue(event: TypeaheadMatch) {
    this.query = event.value;
    if (!this.query && this.currentList === 'private') {
      this.displayedLists = this.privateLists;
      return;
    }
    if (!this.query && this.currentList === 'shared') {
      this.displayedLists = this.sharedLists;
      return;
    }
  }

  private updateListWithTalent(
    list: List,
    talent: ListTalent,
    massTalent?: ListTalent[]
  ): List {
    const updatedList = list.clone();
    if (!updatedList.talent) {
      updatedList.talent = [];
    }
    if (!this.isMassAddToList) {
      const updatedTalent = talent.clone();
      if (this.remarksValue && this.remarksValue.generalNotes) {
        updatedTalent.general_notes = this.remarksValue.generalNotes;
      }
      if (this.remarksValue && this.remarksValue.nextAction) {
        updatedTalent.next_action = this.remarksValue.nextAction;
      }
      updatedList.talent.push(updatedTalent);
    } else if (this.isMassAddToList && massTalent && massTalent.length) {
      massTalent.forEach((talent) => {
        if (this.remarksValue && this.remarksValue.generalNotes) {
          talent.general_notes = this.remarksValue.generalNotes;
        }
        if (this.remarksValue && this.remarksValue.nextAction) {
          talent.next_action = this.remarksValue.nextAction;
        }
        updatedList.talent.push(talent);
      });
    }
    updatedList.last_update_date = moment();

    return updatedList;
  }

  initiateNewList(): void {
    this.isNewList = true;
  }

  cancelNewList(): void {
    this.isNewList = false;
  }

  saveNewList() {
    this._loading.show();
    const newListName = this.newListForm.controls.listName.value;
    let listObject = this._listService.buildNewListObject(newListName);
    this._api.createNewList(listObject).subscribe(
      (data) => {
        this._loading.hide();
        this._listService.setCurrentListOfLists();
        this._listService.setCurrentSharedListOfLists();
        this.newListForm.controls["listName"].setValue("");
        this.isNewList = false;
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
