import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'snippet'
})
export class SnippetPipe implements PipeTransform {
  transform(value: any, length: number): any {
    const snippetLength = length ? length : 60;
    if (value && value.length > snippetLength) {
      return value.split('').splice(0, snippetLength).join('') + '...';
    }
    return value;
  }
}
