import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ElementRef,
  ViewChild,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import {Bookmark} from '../../../models/internal/bookmark';
import {Router} from '@angular/router';
import {BookmarkService} from '../../../services/bookmark/bookmark.service';
import {ToastClass, ToastService} from '../../../services/toast';

@Component({
  selector: 'app-bookmark-card',
  templateUrl: './bookmark-card.component.html',
  styleUrls: ['./bookmark-card.component.scss']
})
export class BookmarkCardComponent implements OnInit {
  @Input() bookmark: Bookmark;
  @Input() type: string;
  @Output() closeWindow = new EventEmitter();
  titleArray: any = [];
  @ViewChild('testDiv', { static: true }) testDiv: ElementRef;
  addedTitleWidth = 0;
  remainingWidth = 0;


  constructor(private _router: Router, private _bookmark: BookmarkService,
              private _toast: ToastService,
              private renderer: Renderer2, private el: ElementRef) { }

  ngOnInit() {
    this.analyzeTitle();
  }

  redirect(event: any) {
    this._bookmark.updateLastVisitBookmark(this.bookmark);
    if (event.ctrlKey || event.metaKey ) {
      window.open(this.bookmark.bookmark_url);
    } else {
      this._router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
        this._router.navigateByUrl(this.bookmark.bookmark_url));
      this.closeWindow.emit();
    }

  }

  delete () {
    this._bookmark.deleteBookmark(this.bookmark).then( message => {
      const toastConfig = {
        cssClass: ToastClass.SUCCESS,
        actionLinks: [
          {
            text: 'Undo', action: () => {
              this.undo();
            }
          },
        ]
      };
      this._toast.showToast(`Bookmark removed.`, toastConfig);
    });
  }

  undo () {
    this._bookmark.addBookmark(this.bookmark).then( message => {
      const toastConfig = {
        cssClass: ToastClass.SUCCESS,
      };
      this._toast.showToast(`Added to your Bookmarks.`, toastConfig);
    });
  }

  analyzeTitle () {
    if (!this.bookmark) { return; }
    const { bookmark_url, job, talent } = this.bookmark;
    const path = bookmark_url.split('?')[0].split('/').splice(1, 2); // first get rid of the paramaters then split by / to get route
    if (job) {
      const indexOfId = path.indexOf(job.id); // then check if we have the job id in the route
      if (indexOfId !== -1) { path.splice(indexOfId, 1); } // remove the id from path, because we are not showing on bookmark title
    }
    this.titleArray.push(...path.map(x => this.objectTitle(x, this.titleWidth(null, x)))); // push the path to title array for UI
    if (job) { this.titleArray.push(this.objectTitle(job.title, this.titleWidth(null, job.title))); } // if job exist, push to array
    if (talent) { this.titleArray.push(this.objectTitle(talent.candidateFullName, this.titleWidth(null, talent.candidateFullName))); } // if talent exist push to array

    this.remainingWidth = (390 - 25 * (this.titleArray.length - 1));

    for (let i = this.titleArray.length; i--;) {
      const currentObj = this.titleArray[i];
      currentObj.width = this.titleWidth(null, currentObj.title);
      currentObj.width = currentObj.width < 26 ? 26 : currentObj.width;
      const width = currentObj.width > 200 ? 200 : currentObj.width;
      if (i === this.titleArray.length - 1) {
        this.addedTitleWidth += width;
        currentObj.calculatedWidth = width;
        this.remainingWidth -= width;
      } else {
        if (this.remainingWidth - width < 0) {
          this.addedTitleWidth += this.remainingWidth;
          currentObj.calculatedWidth = this.remainingWidth;
          this.remainingWidth = 0;
        } else {
          this.addedTitleWidth += width;
          currentObj.calculatedWidth = width;
          this.remainingWidth -= width;
        }
      }
    }

    if (this.remainingWidth > 0) {
      for (let i = this.titleArray.length; i--;) {
        const currentObj = this.titleArray[i];
        const diff = currentObj.width - currentObj.calculatedWidth;
        if ( this.remainingWidth - diff >= 0 ) {
          currentObj.calculatedWidth += diff;
          this.remainingWidth -= diff;
        } else {
          currentObj.calculatedWidth += this.remainingWidth;
          this.remainingWidth = 0;
        }
      }
    }
  }

  objectTitle(title: string, width: any) {
    return {title, width, calculatedWidth: 0};
  }

  titleWidth (index: any , titleSection: any) {
    if (this.testDiv) {
      const width = this.calculateWidthOfElement(titleSection);
      if (index === null) { return width; }
      if (this.titleArray.length <= 2) {
        if (width > 300) {
          return 150;
        }
        return width;
      } else {
        if ( index === 0 || index === 1 ) {
          return 0;
        } else {
          if (width > 300) {
            return 150;
          }
          return width;
        }
      }
    }
  }

  calculateWidthOfElement (innerText: string) {
    const div = this.renderer.createElement('div');
    div.id = 'testElement';
    this.renderer.setStyle(div, 'display', 'block');
    this.renderer.setStyle(div, 'float', 'left');
    this.renderer.setProperty(div, 'innerText', innerText);
    this.renderer.appendChild(this.testDiv.nativeElement, div);
    const width = div.offsetWidth;

    div.remove();
    return width;
  }
}
